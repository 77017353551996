import React from 'react';

import getDocumentType from "../../common/data/mappings/docTypeMapping";
import LibraryView from "./libraryView";
import Loader from "../misc/loader";
import {dispatchCustomEvent} from "../../misc/eventDispatcher";
import {deleteDocument, downloadDocuments,} from "../../misc/requestSender";
import {sendGaEvent, sendPageView} from "../../common/js/ga";
import {navigate} from "../../misc/utils";
import getCategoryType from "../../common/data/mappings/categoryMapping";
import StateComponent from "../misc/stateComponent";
import BdhDocument from "../../models/document";
import {addDataEventListener, DATA_TYPES, EVENT_TYPES, removeAllDataEventListeners} from "../../misc/dataEvent";

export const FILTERS = {
    TYPE_UNKNOWN: 'TYPE_UNKNOWN', NOT_LINKED: 'NOT_LINKED', LINKED: 'LINKED',
};

export const SEARCH_KEYS = {
    LABEL: 'LABEL', TYPE: 'TYPE',
};

export const SORT_KEYS = {
    NAME: 'file_name', UPDATED_AT: 'updated_at', TYPE: 'file_type', LINKED_TO: 'linked',
};

export const VIEWS = {
    BLOCK: 'BLOCK', LIST: 'LIST',
};

const GA_CATEGORY = 'Bibliotheek(nieuw)';

const LIMIT = 10;

export default class LibraryController extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            documents: [], selectedDocuments: [], // Array of ids
            view: VIEWS.LIST, loading: false, selectedCategory: 'all',
            isLoadingMore: false, total: 0, page: 0,
        };
        this.searchQuery = '';
        this.searchType = SEARCH_KEYS.LABEL;
        this.allDocuments = [];
        this.order = 'created_at';
        this.orderDirection = 'DESC';
        this.filterKey = '';
        this.filterValue = '';
        this.docTypes = [];
    }

    onClickDossier = dossier => {
        let id = dossier.id;
        if (isNotNull(id)) navigate(`/dossier-details/${id}`);
    };

    onSelectDocument = doc => {
        let id = doc.id;
        let gaLabel;
        let selectedDocuments = this.state.selectedDocuments;
        if (selectedDocuments.includes(id)) {
            selectedDocuments.splice(selectedDocuments.indexOf(id), 1);
            gaLabel = 'Uit';
        } else {
            selectedDocuments.push(id);
            gaLabel = 'Aan';
        }
        sendGaEvent(GA_CATEGORY, 'Selecteer document', gaLabel);
        this.changeState({selectedDocuments});
    };

    onSelectAll = checked => {
        if (checked) this.selectAllDocuments(); else this.deselectAllDocuments();
    };

    selectAllDocuments = () => {
        this.changeState({
            selectedDocuments: this.state.documents.map(doc => doc.id),
        });
    };

    deselectAllDocuments = () => {
        this.changeState({selectedDocuments: []});
    };

    showDocDetails = doc => {
        dispatchCustomEvent('openDocumentDetails', doc);
        sendGaEvent(GA_CATEGORY, 'Open document', doc.type);
    };

    onAddDocument = () => {
        sendGaEvent(GA_CATEGORY, 'Button click', 'Voeg document toe');
        navigate('/documenten-toevoegen');
    };

    onDownload = () => {
        const {selectedDocuments} = this.state;
        downloadDocuments({
            file_ids: selectedDocuments
        });
    };

    onShare = () => {
        dispatchCustomEvent('shareDocuments', {documents: this.state.selectedDocuments});
    };

    removeSelection = () => {
        this.changeState({selectedDocuments: []});
    };

    confirmDeleteDocuments = () => {
        const {selectedDocuments} = this.state;
        if (isNotNull(selectedDocuments)) {
            const isMultiple = selectedDocuments.length > 1;
            dispatchCustomEvent('showAlert', {
                title: `${(isMultiple ? 'Documenten' : 'Document')} verwijderen`,
                text: `Weet je zeker dat je ${(isMultiple ? 'deze documenten' : 'dit document')} wilt verwijderen?`,
                actions: [{label: 'Nee'}, {label: 'Ja', onClick: this.deleteDocuments},]
            });
        }
    };

    deleteDocuments = () => {
        sendGaEvent(GA_CATEGORY, 'Actie button click', 'Verwijderen');
        if (isNotNull(this.state.selectedDocuments)) {
            let ids = this.state.selectedDocuments;
            for (let i = 0; i < ids.length; i++) {
                let id = ids[i];
                deleteDocument({id});
            }
            this.removeDocuments(ids);
        }
    };

    removeDocuments = ids => {
        let documents = this.state.documents;
        documents = documents.filter(doc => !ids.includes(doc.id));
        this.changeState({documents, selectedDocuments: []});

        this.allDocuments = this.allDocuments.filter(doc => !ids.includes(doc.id));
        Object.freeze(this.allDocuments);
    };

    onSelectFilter = filter => {
        let filterLabel = 'Onbekend';
        let filterValue = '';
        if (filter === FILTERS.TYPE_UNKNOWN) {
            filterLabel = 'Op type onbekend';
            filterValue = getDocumentType('uncategorised').id;
            filter = 'type_id';
        } else if (filter === FILTERS.NOT_LINKED) {
            filter = 'not_linked';
            filterLabel = 'Op document zonder dossier';
        } else if (filter === FILTERS.LINKED) {
            filterLabel = 'Op document met dossier';
            filter = 'linked';
        }
        sendGaEvent(GA_CATEGORY, 'Selecteer filter', filterLabel);

        this.filterKey = filter;
        this.filterValue = filterValue;
        this.fetchDocuments();
    };

    onSort = (key, order = 'ASC') => {
        let sortLabel = 'Onbekend';
        if (key === SORT_KEYS.NAME)
            sortLabel = 'Naam';
        else if (key === SORT_KEYS.TYPE)
            sortLabel = 'Type';
        else if (key === SORT_KEYS.UPDATED_AT)
            sortLabel = 'Gewijzigd op';
        else if (key === SORT_KEYS.LINKED_TO)
            sortLabel = 'Gekoppeld aan';
        sortLabel = sortLabel + (order === 'ASC' ? ' oplopend' : ' aflopend');
        sendGaEvent(GA_CATEGORY, 'Sorteren', sortLabel);
        this.order = key;
        this.orderDirection = order;
        this.fetchDocuments();
    };

    onSearch = (query = this.searchQuery, searchType = this.searchType, documents = this.getAllDocuments()) => {
        if (isNotNull(query)) {
            let startsWithQuery = documents.filter(doc => {
                let searchValue = this.getSearchTypeValue(doc, searchType);
                return this.nameStartsWithQuery(searchValue, query)
            });
            let containsQuery = documents.filter(doc => {
                let searchValue = this.getSearchTypeValue(doc, searchType);
                return this.nameContainsQuery(searchValue, query)
            });
            documents = startsWithQuery.concat(containsQuery)/*.concat(remainder)*/;
        }
        this.changeState({documents});
    };

    onEnterSearch = (evt) => {
        if (evt.key === 'Enter') {
            this.fetchDocuments();
        }
    }

    getSearchTypeValue = (doc, searchType) => (searchType === SEARCH_KEYS.LABEL ? doc.label : getDocumentType(doc.type.name).label);

    onSelectSearchType = type => {
        let typeLabel = type === SEARCH_KEYS.LABEL ? 'Naam' : 'Type';
        sendGaEvent(GA_CATEGORY, 'Zoeken type', typeLabel);
        this.searchType = type;
        this.onSearch();
    };

    onSearchQueryChange = value => {
        sendGaEvent(GA_CATEGORY, 'Zoekbalk', 'Typen');
        this.searchQuery = value;
    };

    nameStartsWithQuery = (name, query) => {
        if (!areNotNull(name, query)) return true;
        name = name.trim().toLowerCase();
        query = query.trim().toLowerCase();
        const queryLength = query.length;
        return name.toLowerCase().slice(0, queryLength) === query;
    };

    nameContainsQuery = (name, query) => {
        if (!areNotNull(name, query)) return true;
        query = query.trim().toLowerCase();
        name = name.trim().toLowerCase();
        return !this.nameStartsWithQuery(name, query) && name.includes(query);
    };

    changeView = view => {
        let viewLabel = view === VIEWS.BLOCK ? 'Blok' : 'Lijst';
        sendGaEvent(GA_CATEGORY, 'Weergave klik', viewLabel);
        this.changeState({view, page: 0}, this.fetchDocuments);
    };

    onSearchInputClick = () => {
        sendGaEvent(GA_CATEGORY, 'Zoekbalk', 'Klik');
    };

    onClickUnlinked = (doc) => {
        dispatchCustomEvent('linkDossier', doc);
    };

    onEditDocument = doc => {
        const updateList = list => {
            for (let i = 0; i < list.length; i++) {
                let listDocument = list[i];
                if (listDocument.id === doc.id) {
                    list[i] = doc;
                    break;
                }
            }
            return list;
        };
        this.allDocuments = updateList(this.allDocuments.slice());
        let stateDocuments = updateList(this.state.documents.slice());
        this.changeState({documents: stateDocuments});
    };

    onDeleteDocument = doc => {
        const updateList = list => {
            list = list.filter(listDoc => listDoc.id !== doc.id);
            return list;
        };
        this.allDocuments = updateList(this.allDocuments.slice());
        let stateDocuments = updateList(this.state.documents.slice());
        this.changeState({documents: stateDocuments});
    };

    onDataEvent = (doc, eventType) => {
        if (eventType === EVENT_TYPES.UPDATE) this.onEditDocument(doc); else if (eventType === EVENT_TYPES.DELETE) this.onDeleteDocument(doc);
    };

    /**
     * Get a copy of all documents
     */
    getAllDocuments = () => this.allDocuments.slice();

    onChangePage = (page) => () => {
        this.changeState({page}, this.fetchDocuments);
    }

    createQueryString = (_page = null) => {
        const {page, view} = this.state;
        if (_page === null) _page = page;
        const withUrl = view === VIEWS.BLOCK ? '1' : '0';
        const limit = view === VIEWS.LIST ? 10 : 20;
        let query = `?limit=${limit}&page=${_page}&include-url=${withUrl}&order=${this.order}&order_by=${this.orderDirection}`;
        if (this.filterKey) query += `&filter=${this.filterKey}`;
        if (this.filterKey === 'doc_type') {
            if (this.docTypes.length) query += `&value=${this.docTypes.join(',')}`;
        } else if (this.filterValue) query += `&value=${this.filterValue}`;
        if(this.searchQuery) {
            if(this.searchType === SEARCH_KEYS.LABEL) query += `&search-name=${this.searchQuery}`;
            else if (this.searchType === SEARCH_KEYS.TYPE) query += `&search-type=${this.searchQuery}`;
        }
        return query;
    }

    fetchDocuments = () => {
        this.changeState({loading: true});
        const fetchedDocuments = [];
        const query = this.createQueryString();
        BdhDocument.getAll(query, (documents, response) => {
            const {total} = response.data;
            if (isNotNull(documents)) {
                documents.forEach(doc => {
                    if (isNull(doc.label)) doc.label = doc.type.label;
                    fetchedDocuments.push(doc);
                    if (fetchedDocuments.length === documents.length) {
                        this.allDocuments = fetchedDocuments;
                        this.changeState({documents: fetchedDocuments, loading: false, total});
                    }
                });
            } else {
                this.allDocuments = [];
                this.changeState({documents: [], loading: false});
            }
        });
    };

    onFetchMore = (startIndex) => {
        return new Promise((resolve) => {
            this.changeState({isLoadingMore: true});
            const page = Math.floor(startIndex / LIMIT);
            const fetchedDocuments = [];
            const query = this.createQueryString(page);
            BdhDocument.getAll(query, (documents, response) => {
                const {total} = response.data;
                if (isNotNull(documents)) {
                    documents.forEach(doc => {
                        if (isNull(doc.label)) doc.label = doc.type.label;
                        fetchedDocuments.push(doc);
                        if (fetchedDocuments.length === documents.length) {
                            this.allDocuments = this.allDocuments.concat(fetchedDocuments);
                            this.changeState({
                                documents: this.allDocuments,
                                loading: false,
                                isLoadingMore: false,
                                total
                            });
                        }
                    });
                } else {
                    this.allDocuments = [];
                    this.changeState({documents: [], loading: false});
                }
                resolve();
            });
        })
    }

    onClickLinked = (doc) => {
        dispatchCustomEvent('linkDossier', doc);
    };

    onSelectCategory = evt => {
        const selectedCategory = evt.target.value;
        this.filterKey = 'doc_type';
        if (selectedCategory === 'all') this.docTypes = [];
        else {
            const category = getCategoryType(selectedCategory);
            this.docTypes = category.docTypes;
        }
        this.fetchDocuments();
    };

    componentDidMount = () => {
        this.mount();
        sendPageView('/bibliotheek');
        this.fetchDocuments();
        if (window.matchMedia("(max-width: 599px)").matches) {
            this.changeState({view: VIEWS.BLOCK})
        }
        addDataEventListener([DATA_TYPES.DOCUMENT], [EVENT_TYPES.UPDATE, EVENT_TYPES.DELETE], 'libraryController', this.onDataEvent);
    };

    componentWillUnmount = () => {
        this.unMount();
        removeAllDataEventListeners('libraryController', this.onDataEvent);
    };

    render = () => {
        return (<>
            <LibraryView onAddDocument={this.onAddDocument} onDeleteDocuments={this.confirmDeleteDocuments}
                         onDownload={this.onDownload} onSelectFilter={this.onSelectFilter}
                         onSelectSearchType={this.onSelectSearchType} onClickUnlinked={this.onClickUnlinked}
                         onClickLinked={this.onClickLinked} onSelectCategory={this.onSelectCategory}
                         onSearchQueryChange={this.onSearchQueryChange} onChangeView={this.changeView}
                         selectedDocuments={this.state.selectedDocuments} initialSearchType={SEARCH_KEYS.LABEL}
                         selectedView={this.state.view} documents={this.state.documents}
                         onDocumentDetails={this.showDocDetails} onSelectDocument={this.onSelectDocument}
                         onSort={this.onSort} onSearchInputClick={this.onSearchInputClick}
                         onClickDossier={this.onClickDossier} selectAll={this.onSelectAll}
                         loading={this.state.loading} onShare={this.onShare} onLoadMore={this.onFetchMore}
                         isLoadingMore={this.state.isLoadingMore} total={this.state.total}
                         onChangePage={this.onChangePage} page={this.state.page}
                         limit={this.state.view === VIEWS.LIST ? 10 : 20} onEnterSearch={this.onEnterSearch}/>
            <Loader ref={refName => {
                this.loader = refName
            }} includeBackground={true} backgroundColor='transparent' text='Documenten laden...'
                    textBackground='transparent' maxTime={30000}/>

        </>);
    };
}