import {createStore, combineReducers} from 'redux';
import {createReducer} from './reducers';
import utils from '../utils';

/**
 * Create a redux store
 * @param initialReducers Object containing all reducers
 */
const configureStore = (initialReducers, initialState, enhancer) => {
    const store = createStore(combineReducers(initialReducers), initialState, enhancer);
    store.asyncReducers = initialReducers;
    return store;
};

const injectReducer = (store, name, reducer) => {
    store.asyncReducers[name] = reducer;
    store.replaceReducer(createReducer(store.asyncReducers));
};

const removeReducer = (store, name) => {
    if (store.asyncReducers.hasOwnProperty(name)) {
        delete store.asyncReducers[name];

        // Throws a warning/error in dev which we can ignore.
        store.replaceReducer(createReducer(store.asyncReducers));
        utils.log('Removed reducer with name: ' + name);
    } else {
        utils.warn('Could not find reducer with name: ' + name);
    }
};

const getStateValue = (state, valueName) => {
    if (state && state[valueName]) {
        return state[valueName];
    }
    return 'Not defined';
};

const store = {
    configureStore: configureStore,
    injectReducer: injectReducer,
    getStateValue: getStateValue,
    removeReducer: removeReducer,
};

export default store;