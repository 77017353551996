const analysisMapping = {
    health: {
        name: 'health',
        label: 'Zorgverzekering',
        icon: 'common-icon-health-insurance',
        implemented: true,
    },
    travel: {
        name: 'travel',
        label: 'Reisverzekering',
        icon: 'common-icon-travel-insurance',
        implemented: true,
    },
    building: {
        name: 'building',
        label: 'Opstalverzekering',
        icon: 'common-icon-home',
        implemented: false,
    },
    car: {
        name: 'car',
        label: 'Autoverzekering',
        icon: 'common-icon-car',
        implemented: false,
    },
    liability: {
        name: 'liability',
        label: 'Aansprakelijkheidsverzekering',
        icon: 'common-icon-suitcase',
        implemented: false,
    },
    contents: {
        name: 'contents',
        label: 'Inboedelverzekering',
        icon: 'common-icon-home',
        implemented: false,
    },
    legal: {
        name: 'legal',
        label: 'Rechtsbijstand',
        icon: 'common-icon-suitcase',
        implemented: false,
    },
};

export const getAnalysisType = name => {
    if (isNull(name) || !analysisMapping.hasOwnProperty(name)) return copy(analysisMapping);
    return copy(analysisMapping[name]);
};

export const getAnalysisSorted = () => {
    const analysis = Object.values(getAnalysisType());
    analysis.sort((analyseA, analyseB) => {
        const implA = analyseA.implemented, implB = analyseB.implemented, labelA = analyseA.label,
            labelB = analyseB.label, preferA = -1, preferB = 1, isEqual = 0;
        if (implA && !implB) return preferA;
        if (!implA && implB) return preferB;
        return labelA < labelB ? preferA : labelA > labelB ? preferB : isEqual;
    });
    return analysis;
};

const copy = object => Object.assign({}, object);