import axios from 'axios';
import utils, {debug} from './utils';
import errors from './errors';
import {isFunction} from "./utils";

export const GET = 'get', POST = 'post', PUT = 'put', DELETE = 'delete', PATCH = 'patch';
let cancelSources = {};
export const UNAUTHORIZED = 401;
export const FORBIDDEN = 403;
export const BAD_REQUEST = 400;
export const STATUS_SUCCESS = 'success';

/**
 * Send an Axios request. Defaults to a GET request.
 * @param requestConfig Object containing at least the URL. In case of a POST or PUT request, data is also required.
 * @param callback The callback function to call on success.
 */
export const sendRequest = (requestConfig, callback) => {
    if (requestConfigIsValid(requestConfig)) {
        requestConfig.setWaitCursor = utils.isNotNull(requestConfig.setWaitCursor) ? requestConfig.setWaitCursor : true;
        requestConfig.withCredentials = !requestConfig.withoutCredentials;
        if (utils.isNotNull(requestConfig.name)) {
            let cancelSource = axios.CancelToken.source();
            cancelSources[requestConfig.name] = cancelSource;
            requestConfig.cancelToken = cancelSource.token;
        }

        debug('Sending request with config: ', requestConfig);
        axios(requestConfig).then(response => {
            if (utils.isNotNull(requestConfig.name))
                delete cancelSources[requestConfig.name];
            if (isFunction(callback))
                callback(response)
        }, error => {
            if (axios.isCancel(error)) {
                utils.log('Request cancelled');
            } else
                requestConfig.onErrorCallback(error, errors.axiosError);
        });
    } else {
        // Data must be included
        errors.requestError('POST and PUT requests cannot be sent without data, use an empty object if there is ' +
            'no data to send.');
    }
};

/**
 * Cancel the request with the given name.
 */
export const cancelRequest = name => {
    if (utils.isNotNull(cancelSources[name])) {
        cancelSources[name].cancel();
        delete cancelSources[name];
    }
};

const setWaitCursor = (setCursor) => {
    if (setCursor) {
        const htmlNode = document.getElementsByTagName('html')[0];
        if (utils.isNotNull(htmlNode) && !htmlNode.classList.contains('wait')) {
            htmlNode.classList.add('wait');
        }
    }
};

const requestConfigIsValid = requestConfig => {
    if (utils.isNotNull(requestConfig)) {
        if (requestConfig.onErrorCallback === undefined || requestConfig.onErrorCallback === null) {
            errors.requestError('Request cannot be send without onErrorCallback in requestConfig.');
        }
        let method = requestConfig.method;
        return method === POST || method === PUT ? utils.isNotNull(requestConfig.data) : true;
    } else {
        errors.requestError('Given requestConfig contains errors:\n' + JSON.stringify(requestConfig));
    }
};

let request = {
    sendRequest: sendRequest,
    cancelRequest: cancelRequest,
    GET: GET,
    POST: POST,
    PUT: PUT,
    DELETE: DELETE,
};

export default request;
