import React from 'react';
import moment from 'moment';

import FinancialHelper from "./helper/financialHelper";
import {addCustomEventListener, dispatchCustomEvent, removeCustomEventListener} from "../../misc/eventDispatcher";
import FinancialOverview from "./financialOverview";
import {sendGaEvent, sendPageView} from "../../common/js/ga";
import MaterialFactory from "../material/materialFactory";
import {materialTypes} from "../material/materialTypes";
import {NOW} from "../misc/constants";
import {getOnboardingManager} from "../onboarding/manager/onboardingManagerHelper";
import StateComponent from "../misc/stateComponent";

const gaCategory = 'Financieel overzicht';

export default class FinancialOverviewController extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            items: [],
            selectedItems: [],
            date: NOW(),
            dialogOpen: false,
        };
    }

    deleteItems = () => {
        sendGaEvent(gaCategory, 'Verwijderen', this.state.selectedItems.length + ' items');
        this.financialHelper.deleteItemsFromDossier(this.state.selectedItems);
        this.changeState({selectedItems: [], dialogOpen: false});
    };

    confirmDelete = () => {
        this.changeState({dialogOpen: true});
    };

    itemIsInRange = item => {
        let selectedDate = moment(this.state.date);
        let selectedMonth = selectedDate.month(), selectedYear = selectedDate.year();
        let startDate = moment(item.start_date, ['DD-MM-YYYY', 'YYYY-MM-DD']), endDate = isNotNull(item.end_date) ?
            moment(item.end_date, ['DD-MM-YYYY', 'YYYY-MM-DD']) : null, frequency = item.frequency;
        let startMonth = startDate.month(), startYear = startDate.year();
        if (startMonth === selectedMonth && startYear === selectedYear) return true;

        if (selectedDate.isSameOrAfter(startDate, 'month') && (isNull(endDate) ||
            selectedDate.isSameOrBefore(endDate, 'month'))) {
            switch (frequency) {
                case 'one_time':
                    return false;
                case 'monthly':
                    return true;
                case 'quarterly':
                    return [startMonth, this.addMonths(startMonth, 3), this.addMonths(startMonth, 6),
                        this.addMonths(startMonth, 9)].includes(selectedMonth);
                case 'half_year':
                    return [startMonth, this.addMonths(startMonth, 6)].includes(selectedMonth);
                case 'yearly':
                    return selectedMonth === startMonth;
            }
        }
        return false;
    };

    addMonths = (month, monthsToAdd) => {
        let nextMonth = month + monthsToAdd;
        return nextMonth > 11 ? nextMonth - 12 : nextMonth;
    };

    setNextDate = (type) => {
        // Need to duplicate state moment, since it should be immutable, but moment isn't
        let selectedDate = moment(this.state.date);
        if (type === 'next') {
            sendGaEvent(gaCategory, 'Klik datum', 'Volgende maand');
            selectedDate.add(1, 'months');
        } else if (type === 'previous') {
            sendGaEvent(gaCategory, 'Klik datum', 'Vorige maand');
            selectedDate.subtract(1, 'months');
        }
        this.changeState({date: selectedDate});
    };

    setSelectedDate = (date) => {
        this.changeState({date: date})
    };

    toggleItem = item => {
        let selectedItems = this.state.selectedItems;
        if (selectedItems.includes(item)) {
            selectedItems.splice(selectedItems.indexOf(item), 1);
        } else selectedItems.push(item);
        this.changeState({selectedItems});
    };

    processItems = ({financialItems, defaultItems}) => {
        let items = (financialItems.concat(defaultItems)).filter(item => this.itemIsInRange(item));
        items = items.filter(item => isNotNull(item.category));
        this.changeState({items, loaded: true});
    };

    calculateBalance = () => {
        let items = this.state.items;
        let income = 0;
        let expense = 0;
        for (let i = 0; i < items.length; i++) {
            let item = items[i];
            if (item.moneyType === 'income') income += item.monthly_price;
            else expense += item.monthly_price;
        }
        return income - expense;
    };

    startLoad = () => {
        this.changeState({loaded: false});
        this.financialHelper.loadDossiers();
    };

    setLoading = loaded => {
        this.changeState({loaded});
    };

    closeDialog = () => {
        this.changeState({dialogOpen: false});
    };

    componentDidMount = () => {
        this.mount();
        sendPageView('/financieel-overzicht');
        this.financialHelper = new FinancialHelper({
            source: 'FinancialOverviewController',
            callback: this.processItems,
            onLoad: () => {
                this.setLoading(false);
            },
            afterLoad: () => {
                this.setLoading(true);
            },
        });

        this.startLoad();
        addCustomEventListener('updateFinancial', this.startLoad);

        const manager = getOnboardingManager('financial');
        if (manager.isActive())
            dispatchCustomEvent('financialOnboardingFlow', {type: 'activate'});
    };

    componentDidUpdate = (prevProps, prevState) => {
        let prevDate = prevState.date;
        let date = this.state.date;
        if (!prevDate.isSame(date)) {
            this.startLoad();
        }
    };

    componentWillUnmount = () => {
        this.unMount();
        this.financialHelper = null;
        removeCustomEventListener('updateFinancial', this.startLoad);
    };

    render = () => {
        return (
            <>
                <MaterialFactory componentType={materialTypes.DIALOG} title='Verwijderen?' open={this.state.dialogOpen}
                                 onClose={this.closeDialog} actions={[
                    {label: 'Nee', onClick: this.closeDialog},
                    {label: 'Ja', onClick: this.deleteItems},
                ]} text='Weet je zeker dat je deze inkomsten/uitgaven wilt verwijderen?'/>
                <FinancialOverview setMonth={this.setNextDate} setDate={this.setSelectedDate}
                                   deleteItems={this.confirmDelete} loaded={this.state.loaded}
                                   selectItem={this.toggleItem} balance={this.calculateBalance()}
                                   date={this.state.date} items={this.state.items}
                                   selectedItems={this.state.selectedItems}/>
            </>
        );
    };
}