import React from 'react';
import StateComponent from "../../../misc/stateComponent";
import {getMailTemplates, responseIsSuccess} from "../../../../misc/requestSender";
import AddTaskView from "./addTaskView";
import {NOW} from "../../../misc/constants";

export default class AddTaskController extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            templates: [],
        };
    }

    onSubmit = (data, callback) => {
        let {dryRun, template, mergeVars, filters, sendDate, sendDateDiff} = data;
        if (sendDate === '0')
            this.onSend(data, callback);
        else {
            sendDate = this.convertSendDate(sendDate, sendDateDiff);
            const taskName = 'send_mail-' + NOW().format('DD-MM-YYYY HH:mm');
            const body = {};
        }
    };

    onSend = (data, callback) => {

    };

    convertSendDate = (sendDate, sendDateDiff) => {
        if (sendDate === 'custom')
            return sendDateDiff;
        let now = NOW();
        now.add(parseInt(sendDate), 'minutes');
        return now.format('YYYY-MM-DDTHH:mm:ss');
    };

    getTemplates = () => {
        getMailTemplates({
            callback: (response => {
                if (responseIsSuccess(response)) {
                    const {templates} = response.data;
                    if (isNotNull(templates)) {
                        for (let i = 0; i < templates.length; i++) {
                            this.processTemplate(templates[i]);
                        }
                        templates.sort((templateA, templateB) => {
                            let preferA = -1, preferB = 1, isEqual = 0, nameA = templateA.name,
                                nameB = templateB.name;

                            if (isNotNull(nameA) && isNull(nameB)) return preferA;
                            if (isNull(nameA) && isNotNull(nameB)) return preferB;
                            if (isNull(nameA) && isNull(nameB)) return isEqual;

                            nameA = nameA.toLowerCase();
                            nameB = nameB.toLowerCase();
                            return nameA < nameB ? preferA : nameA > nameB ? preferB : isEqual;
                        });
                        this.changeState({templates});
                    }
                }
            })
        });
    };

    includeMergeVars = template => {
        const code = JSON.stringify(template.publish_code);
        let mergeVars = null;
        if (isNotNull(code)) {
            mergeVars = code.match(/\*\|[A-Za-z0-9]+\|\*/g);
        }
        template.mergeVars = mergeVars || [];
    };

    processTemplate = template => {
        this.includeMergeVars(template);
        template.name = template.publish_name;
    };

    componentDidMount = () => {
        this.mount();
        this.getTemplates();
    };

    componentWillUnmount = () => {
        this.unMount();
    };

    render = () => {
        const {templates} = this.state;
        return <AddTaskView onSubmit={this.onSubmit} templates={templates}/>;
    };
}