import './style/addFromLibrary.scss';

import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import {deviceIsMobile} from 'glob-common-js/lib/utils';

import GenericLoader from "../../../misc/genericLoader";
import MaterialFactory from "../../../material/materialFactory";
import {materialTypes} from "../../../material/materialTypes";

const styles = () => ({
    tableRoot: {
        maxHeight: '100%',
        width: '100%',
    }
});

export class AddFromLibrary extends React.Component {
    constructor(props) {
        super(props);
    }

    render = () => {
        const onMobile = deviceIsMobile();
        const padding = 'dense', {documents, onSelectDocument, selectedDocuments, classes} = this.props;
        return (
            <>
                <div className='clearBackground'/>
                <div className='addFromLibrary'>
                    <GenericLoader active={this.props.loading} label={this.props.loaderLabel}
                                   backgroundClassName='addFromLibraryLoaderBackground'/>
                    <div className='addFromLibraryHeader'>
                        <label className='addFromLibraryHeaderTitle'>Kies bestand uit bibliotheek</label>
                        <span className='common-icon-cross closeButton' onClick={this.props.onClose}/>
                    </div>
                    <div className='searchContainer'>
                        <select className='searchTypeSelection' onChange={this.props.onSelectQueryType}>
                            <option value='type'>Type</option>
                            <option value='name'>Naam</option>
                        </select>
                        <input id='addFromLibInput' type='text' className='searchQuery' autoFocus={true}
                               onChange={this.props.onEnterQuery}/>
                    </div>
                    <div className='documentsContainer'>
                        <Table className={classes.tableRoot}>
                            <TableHead>
                                <TableRow>
                                    <TableCell padding='checkbox'/>
                                    <TableCell padding={padding}>Naam document</TableCell>
                                    <TableCell padding={padding}>Type</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {documents.map((doc) => (
                                    <TableRow key={doc.id} selected={selectedDocuments.includes(doc.id)}>
                                        <TableCell padding='checkbox'>
                                            <MaterialFactory componentType={materialTypes.CHECKBOX}
                                                             checked={selectedDocuments.includes(doc.id)}
                                                             onChange={() => {
                                                                 onSelectDocument(doc.id);
                                                             }}/>
                                        </TableCell>
                                        <TableCell padding={padding}>{doc.label}</TableCell>
                                        <TableCell padding={padding}>{doc.type}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        {/*<Table multiSelectable={true} onRowSelection={this.props.onSelectDocument}>*/}
                        {/*<TableHeader displaySelectAll={false}>*/}
                        {/*<TableRow>*/}
                        {/*<TableHeaderColumn>Naam document</TableHeaderColumn>*/}
                        {/*{!onMobile && <TableHeaderColumn>Type</TableHeaderColumn>}*/}
                        {/*</TableRow>*/}
                        {/*</TableHeader>*/}
                        {/*<TableBody showRowHover={true} stripedRows={false} deselectOnClickaway={false}>*/}
                        {/*{this.props.documents.map((doc) => (*/}
                        {/*<TableRow key={doc.id} selected={this.props.selectedDocuments.includes(doc.id)}>*/}
                        {/*<TableRowColumn title={doc.label}>{doc.label}</TableRowColumn>*/}
                        {/*{!onMobile && <TableRowColumn title={doc.type}>{doc.type}</TableRowColumn>}*/}
                        {/*</TableRow>*/}
                        {/*))}*/}
                        {/*</TableBody>*/}
                        {/*</Table>*/}
                    </div>
                    <div className='addFromLibraryFooter'>
                        <MaterialFactory componentType={materialTypes.RAISED_BUTTON}
                                         onClick={this.props.onAddDocuments}
                                         disabled={isNull(this.props.selectedDocuments)}>
                            {'Kies document' + (this.props.selectedDocuments.length > 1 ? 'en' : '')}
                        </MaterialFactory>
                    </div>
                </div>
            </>
        )
    }
}

AddFromLibrary.propTypes = {
    onSelectDocument: PropTypes.func.isRequired,
    onSelectQueryType: PropTypes.func.isRequired,
    onEnterQuery: PropTypes.func.isRequired,
    onAddDocuments: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    selectedDocuments: PropTypes.arrayOf(PropTypes.string).isRequired,
    documents: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
    })).isRequired,
    loading: PropTypes.bool.isRequired,
    loaderLabel: PropTypes.string,
};

export default withStyles(styles)(AddFromLibrary);