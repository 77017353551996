import React from 'react';
import PropTypes from 'prop-types';

import StateComponent from "../stateComponent";
import MaterialFactory from "../../material/materialFactory";
import {materialTypes} from "../../material/materialTypes";
import GenericLoader from "../genericLoader";

export default class ProtectedAccess extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            error: null,
        };
    }

    onSubmit = () => {
        if (isNotNull(this.state.password))
            this.props.onSubmit(this.state.password, (message) => {
                this.changeState({error: message})
            });
    };

    onClose = () => {
        this.changeState({password: '', error: null});
        this.props.onClose();
    };

    onChangePassword = evt => {
        this.changeState({password: evt.target.value});
    };

    onFocusPassword = () => {
        this.changeState({error: null});
    };

    enterListener = evt => {
        const ENTER_CODE = 13;
        if (evt.keyCode === ENTER_CODE) {
            this.onSubmit();
            evt.preventDefault();
        }
    };

    renderDialogActions = () => ([
        {label: 'Ok', disabled: isNull(this.state.password) || this.props.loading, onClick: this.onSubmit},
        {label: 'Annuleer', onClick: this.onClose},
    ]);

    renderContent = () => (
        <form>
            <GenericLoader active={this.props.loading}/>
            <MaterialFactory componentType={materialTypes.PASSWORD} value={this.state.password}
                             onChange={this.onChangePassword} autoComplete='new-password' label='Wachtwoord'
                             keyDownListener={this.enterListener} helperText={this.state.error}
                             error={isNotNull(this.state.error)} onFocus={this.onFocusPassword}/>
        </form>
    );

    componentDidMount = () => {
        this.mount();
    };

    componentWillUnmount = () => {
        this.unMount();
    };

    render = () => (
        <MaterialFactory componentType={materialTypes.DIALOG} open={this.props.open} title='Wachtwoord invoeren'
                         text='Je wachtwoord is vereist voor het uitvoeren van deze actie.'
                         content={this.renderContent()} onClose={this.onClose} actions={this.renderDialogActions()}/>
    )
};

ProtectedAccess.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
};