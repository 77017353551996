import './style/dossierNotes.scss';

import React from 'react';
import PropTypes from 'prop-types';

import DossierNotesEditView from "./dossierNotesEditView";
import {sendGaEvent} from "../../../../common/js/ga";
import StateComponent from "../../../misc/stateComponent";
import {showSnackbar} from "../../../../misc/utils";

export default class DossierNotesController extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            value: props.dossier.notes,
            hasChanges: false,
        }
    }

    onChange = evt => {
        const {dossier} = this.props, value = evt.target.value, hasChanges = dossier.notes !== value;
        this.changeState({value, hasChanges});
    };

    onSave = () => {
        const {dossier} = this.props, {value} = this.state;
        sendGaEvent('Dossier details', 'Notitie click', 'Opslaan');
        dossier.notes = value;
        dossier.update(() => {
            sendGaEvent('Dossier details', 'Notitie gewijzigd', dossier.type.label);
            this.changeState({hasChanges: false});
            const message = 'Je notitie is ' + (isNull(value) ? 'verwijderd' : 'opgeslagen');
            showSnackbar(message);
        });
    };

    componentDidMount = () => {
        this.mount();
    };

    componentWillUnmount = () => {
        this.unMount();
    };

    render = () => {
        const {value, hasChanges} = this.state;
        return (
            <div className="dossierDetailBox">
                <div className="detailHeader">
                    <div className="detailTitle">
                        <p>Notities</p>
                    </div>
                </div>
                <div className='detailBody'>
                    <DossierNotesEditView value={value} onSave={this.onSave} onChange={this.onChange}
                                          hasChanges={hasChanges}/>
                </div>
            </div>
        );
    }
}

DossierNotesController.propTypes = {
    dossier: PropTypes.object.isRequired,
};