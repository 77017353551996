import React from 'react'
import PropTypes from 'prop-types';
import _ from 'lodash';

import {isFunction, stringContains, stringStartsWith} from 'glob-common-js/lib/utils';

import StateComponent from "../../misc/stateComponent";
import BdhSingleSelect from "./bdhSingleSelect";

export default class BdhSelectController extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            searchValue: '',
            filteredItems: props.items,
        };
    }

    onSelect = (item, evt) => {
        const {onSelect, multiple} = this.props;
        onSelect(item.value, multiple, evt);
        this.onSearch('');
    };

    onClear = value => {
        const {onClear} = this.props;
        if (isFunction(onClear))
            onClear(value);
    };

    onSearch = value => {
        const {items} = this.props;
        this.changeState({searchValue: value});
        const allItems = items.slice();
        if (isNull(value)) this.changeState({filteredItems: allItems});
        else {
            const startsWith = [], contains = [];
            allItems.forEach(item => {
                if (stringStartsWith(item.label, value)) startsWith.push(item);
                else if (stringContains(item.label, value)) contains.push(item);
            });
            this.changeState({filteredItems: startsWith.concat(contains)});
        }
    };

    onFocus = evt => {
        const {onFocus} = this.props;
        if (isFunction(onFocus))
            onFocus(evt);
    };

    onBlur = evt => {
        const {onBlur} = this.props;
        this.onSearch('');
        if (isFunction(onBlur))
            onBlur(evt);
    };

    componentDidMount = () => {
        this.mount();
    };

    componentDidUpdate = prevProps => {
        if (!_.isEqual(prevProps.items, this.props.items))
            this.onSearch(this.state.searchValue);
    };

    componentWillUnmount = () => {
        this.unMount();
    };

    render = () => {
        const {multiple, value, items, onClear} = this.props;
        const {searchValue, filteredItems} = this.state;
        if (multiple) return null;
        return <BdhSingleSelect {...this.props} onSelect={this.onSelect} onClear={this.onClear} onSearch={this.onSearch}
                                onFocus={this.onFocus} onBlur={this.onBlur} searchValue={searchValue} value={value}
                                items={filteredItems} allItems={items.slice()} renderClear={isFunction(onClear)}/>
    };
}

BdhSelectController.propTypes = {
    onSelect: PropTypes.func.isRequired,
    onClear: PropTypes.func,
    value: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
    ])),
    items: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool,]),
    })),
    multiple: PropTypes.bool,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    fullWidth: PropTypes.bool,
    variant: PropTypes.oneOf(['contained', 'explode']),
    containerClass: PropTypes.string,
};

BdhSelectController.defaultProps = {
    multiple: false,
    disabled: false,
    readOnly: false,
    fullWidth: false,
    variant: 'explode',
};