import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as Actions from '../../../redux/actions';

import {pathIsNotNull} from 'glob-common-js/lib/utils';
import DocumentDashboardContainer from "./documentDashboardContainer";
import BdhDocument from "../../../models/document";
import StateComponent from "../../misc/stateComponent";
import {addDataEventListener, DATA_TYPES, EVENT_TYPES, removeAllDataEventListeners} from "../../../misc/dataEvent";

export class DocumentDashboardContainerController extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            userDocuments: [],
            docFetching: true,
        };
    }

    onDataEvent = (doc, eventType) => {
        if (eventType === EVENT_TYPES.UPDATE)
            this.onEditEvent(doc);
        else if (eventType === EVENT_TYPES.DELETE)
            this.onDeleteEvent(doc);
    };

    onEditEvent = doc => {
        const documents = this.state.userDocuments;
        for (let i = 0; i < documents.length; i++) {
            const userDoc = documents[i];
            if (userDoc.id === doc.id) {
                documents.splice(i, 1, doc);
                this.changeState({userDocuments: documents});
                return;
            }
        }
    };

    onDeleteEvent = doc => {
        const documents = this.state.userDocuments.filter(userDoc => userDoc.id !== doc.id);
        this.changeState({userDocuments: documents});
    };

    getUserDocuments = () => {
        this.changeState({docFetching: true});
        BdhDocument.getAll(null, documents => {
            this.changeState({
                userDocuments: documents,
                docFetching: false,
            })
        });
    };

    getUserEmail = () => {
        const user = this.props.userState.user;
        if (pathIsNotNull(user, 'username')) {
            return user.username + '@bydehand.com';
        }
        return 'demo@bydehand.com';
    };

    componentDidMount = () => {
        this.mount();
        this.getUserDocuments();
        addDataEventListener([DATA_TYPES.DOCUMENT], [EVENT_TYPES.UPDATE,
            EVENT_TYPES.DELETE], 'documentDashboardContainerController', this.onDataEvent)
    };

    componentWillUnmount = () => {
        this.unMount();
        removeAllDataEventListeners('documentDashboardContainerController', this.onDataEvent);
    };

    render = () => {
        return (
            <DocumentDashboardContainer setReduxDocType={(docType) => {
                this.props.actions.setDocType(docType);
            }} documents={this.state.userDocuments} email={this.getUserEmail()}
                                        docToLink={this.state.documentToLink}
                                        docFetching={this.state.docFetching}
                                        selectedModule={this.props.moduleState.selectedModule}
                                        activeModules={this.props.moduleState.activeModules}
            />
        );
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});

const mapStateToProps = state => ({
    moduleState: state.module,
    userState: state.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentDashboardContainerController);