import './style/actionCodePopup.scss';

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Delete} from "@material-ui/icons";

import {hasHover} from 'glob-common-react/lib/utils/reactUtils';
import {toMoment} from 'glob-common-js/lib/utils';

import {WithBackground} from "../../../misc/reactUtils";
import ActionCode from "../../../models/actionCode";
import BdhContainedInput from "../../customControls/input/bdhContainedInput";
import BdhSelectController from "../../customControls/select/bdhSelectController";
import MaterialFactory from "../../material/materialFactory";
import {materialTypes} from "../../material/materialTypes";
import GenericLoader from "../../misc/genericLoader";

export default class ActionCodePopup extends React.Component {
    onClose = () => {
        const element = document.getElementById('actionCodePopup');
        if (!hasHover(element))
            this.props.onClose();
    };

    getValueItems = () => {
        const {actionCode} = this.props, periodKey = actionCode.periodKey, maxMapping = {
            days: 31,
            weeks: 52,
            months: 12,
            years: 99,
            default: 99,
        }, max = maxMapping[periodKey] || maxMapping.default;
        const values = [];
        for (let i = 1; i <= max; i++) {
            values.push({label: i.toString(), value: i.toString()});
        }
        return values;
    };

    getKeyItems = () => {
        const {actionCode} = this.props, periodValue = actionCode.periodValue, isSingle = periodValue === 1;
        return [
            {label: isSingle ? 'dag' : 'dagen', value: 'days'},
            {label: isSingle ? 'week' : 'weken', value: 'weeks'},
            {label: isSingle ? 'maand' : 'maanden', value: 'months'},
            {label: 'jaar', value: 'years'},
        ];
    };

    renderHeader = () => {
        const {isNewCode, onClose} = this.props, title = isNewCode ? 'Nieuwe code aanmaken' : 'Code aanpassen';
        return (
            <div className='headerContainer'>
                <label className='headerTitle'>{title}</label>
                <span className='common-icon-cross closeButton' onClick={onClose}/>
            </div>
        )
    };

    renderBody = () => {
        const {actionCode, onChangePeriod, updateUsers, onChangeUpdateUsers, isNewCode, loading} = this.props;
        return (
            <div className='actionCodeBody'>
                <GenericLoader active={loading}/>
                {this.renderInput('code', 'Code')}
                {this.renderInput('description', 'Omschrijving', {multiLine: true})}
                {this.renderInput('maxUses', 'Maximaal aantal gebruikers', {
                    type: 'number',
                    min: -1,
                    max: Number.MAX_SAFE_INTEGER
                })}
                <div className='periodContainer'>
                    <label className='periodLabel'>Actie periode</label>
                    <BdhSelectController onSelect={onChangePeriod('value')} items={this.getValueItems()}
                                         value={[actionCode.periodValue.toString()]} readOnly
                                         containerClass='valueSelection' variant='contained'/>
                    <BdhSelectController onSelect={onChangePeriod('key')} items={this.getKeyItems()}
                                         value={[actionCode.periodKey]} readOnly containerClass='keySelection'
                                         variant='contained'/>
                </div>
                {this.renderInput('validUntil', 'Geldig tot (dd-mm-yyyy)', {type: 'date'})}
                {!isNewCode && <div className='updateUsersContainer'>
                    <MaterialFactory componentType={materialTypes.CHECKBOX} checked={updateUsers}
                                     onChange={onChangeUpdateUsers}
                                     label='Update ook gebruikers met deze code'/>
                    <div className='infoContainer'>
                        <span className='common-icon-info infoIcon'/>
                        <p className='infoText'>Het wijzigen van de actie periode kan invloed hebben op bestaande
                            registraties met deze code. Vink deze optie aan om ook de actie periode van de al
                            bestaande registraties bij te werken. Deze wordt dan opnieuw berekend aan de hand van de
                            registratiedatum.</p>
                    </div>
                </div>}
            </div>
        )
    };

    renderInput = (name, label, otherProps = {}) => {
        const {onChange, actionCode} = this.props;
        let value = actionCode[name] || '';
        if (name === 'validUntil' && isNotNull(value)) value = toMoment(value).format('YYYY-MM-DD');
        return <BdhContainedInput onChange={onChange(name)} value={value} label={label}
                                  fullWidth containerClass='actionCodeField' {...otherProps}/>
    };

    renderFooter = () => {
        const {isNewCode, hasChanges, onSubmit, onClose, loading, onClickDelete} = this.props,
            cancelText = isNewCode || hasChanges ? 'Annuleren' : 'Sluiten';
        return (
            <div className='actionCodeFooter'>
                <Delete onClick={onClickDelete} className={classNames('deleteButton', isNewCode && 'hidden')}/>
                <div className='actionButtons'>
                    <MaterialFactory componentType={materialTypes.RAISED_BUTTON} onClick={onClose}
                                     className='cancelButton'>{cancelText}</MaterialFactory>
                    <MaterialFactory componentType={materialTypes.RAISED_BUTTON} onClick={onSubmit}
                                     disabled={(!isNewCode && !hasChanges) || loading}>Opslaan</MaterialFactory>
                </div>
            </div>
        )
    };

    render = () => {
        const {onClose} = this.props;
        return (
            <WithBackground onEscape={onClose} onClose={this.onClose}>
                <div className='actionCodePopup' id='actionCodePopup'>
                    {this.renderHeader()}
                    {this.renderBody()}
                    {this.renderFooter()}
                </div>
            </WithBackground>
        )
    };
}

ActionCodePopup.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onChangePeriod: PropTypes.func.isRequired,
    onChangeUpdateUsers: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
    actionCode: PropTypes.instanceOf(ActionCode).isRequired,
    hasChanges: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    isNewCode: PropTypes.bool.isRequired,
    updateUsers: PropTypes.bool.isRequired,
};