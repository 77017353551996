import React from 'react';
import PropTypes from 'prop-types';

import EvaluationButtonsController from '../misc/evaluationButtons/evaluationButtonsController';
import evaluationConst from '../misc/constants'
import {navigate} from "../../../../../misc/utils";
import {scrollToTop} from 'glob-common-js/lib/utils';
import InsurerGraph from "./insurerGraph";

export default class evaluationInsurerView extends React.Component {
    constructor(props) {
        super(props);
    }

    navigateAfterDecision = () => {
        navigate('/zorganalyse/evaluatie/samenvatting', true);
        scrollToTop();
    };

    render = () => {
        const {
            rulerAmount, gapSize, rulerStart, tdWidth, betterStart, betterLength, worseLength, currentPrice,
            currentPriceWidth, betterText, worseText, pigText, popupOffset, insurancePopup, setEval, evaluationDecision
        } = this.props;
        let evalButtons = [
            {
                buttonText: evaluationConst.SATISFIED,
                onClick: () => {
                    setEval(evaluationConst.SATISFIED);
                    this.navigateAfterDecision();
                },
                defaultSelected: evaluationDecision === evaluationConst.SATISFIED,
            },
            {
                buttonText: evaluationConst.DISSATISFIED,
                onClick: () => {
                    setEval(evaluationConst.DISSATISFIED);
                    this.navigateAfterDecision();
                },
                defaultSelected: evaluationDecision === evaluationConst.DISSATISFIED,
            },
            {
                buttonText: evaluationConst.UNSURE,
                onClick: () => {
                    setEval(evaluationConst.UNSURE);
                    this.navigateAfterDecision();
                },
                defaultSelected: evaluationDecision === evaluationConst.UNSURE,
            }
        ];
        return (
            <div className="insurerContainer">
                {/*Header*/}
                <div className="insurerHeader">
                    <h1 className="insurerHeaderTitle">Prijs</h1>
                    <p className="insurerHeaderDesc">We hebben de prijs van jouw verzekering vergeleken met alle
                        verzekeringen met een vergelijkbare
                        dekking</p>
                </div>
                <InsurerGraph rulerAmount={rulerAmount} gapSize={gapSize} rulerStart={rulerStart} tdWidth={tdWidth}
                              betterStart={betterStart} betterLength={betterLength} worseLength={worseLength}
                              currentPriceWidth={currentPriceWidth} currentPrice={currentPrice} betterText={betterText}
                              worseText={worseText} saveAmountText={pigText} currentArrowOffset={popupOffset}
                              currentArrowWidth={insurancePopup} includeCurrentText/>

                {/*Insurance quality information*/}
                <div className="insuranceQuality">
                    <h1 className="insuranceQualityTitle">Kwaliteit</h1>
                    <p>Naast de prijs is ook de kwaliteit en klantvriendelijkheid van belang. denk hierbij aan:​</p>
                    <ul>
                        <li>Afhandeling declaraties​</li>
                        <li>Ondersteuning bij vinden zorgverlener of wachtlijstbemiddeling​</li>
                        <li>Bereikbaarheid, service en vriendelijkheid medewerkers​</li>
                        <li>Informatievoorziening en werking online portal​</li>
                    </ul>
                </div>

                {/*Redux buttons*/}
                <div className="evaluationButtonContainer">
                    <EvaluationButtonsController headerTitle='Ben je tevreden met jouw verzekeraar?'
                                                 buttons={evalButtons}/>
                </div>

            </div>
        )
    }
}

evaluationInsurerView.propTypes = {
    rulerAmount: PropTypes.number.isRequired,
    gapSize: PropTypes.number.isRequired,
    rulerStart: PropTypes.number.isRequired,
    tdWidth: PropTypes.number.isRequired,
    betterStart: PropTypes.number.isRequired,
    betterLength: PropTypes.number.isRequired,
    worseLength: PropTypes.number.isRequired,
    currentPrice: PropTypes.number.isRequired,
    betterText: PropTypes.string.isRequired,
    worseText: PropTypes.string.isRequired,
    pigText: PropTypes.string.isRequired,
    popupOffset: PropTypes.number.isRequired,
};