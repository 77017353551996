import {isValidEmail} from 'glob-common-js/lib/utils';

export const validateFirstname = (firstname, errors) => {
    if (isNull(firstname))
        errors.firstname = 'Vul een voornaam in';
    return errors;
};

export const validateBsn = (bsn, errors) => {
    if (isNotNull(bsn)) {
        if (isNaN(bsn) || bsn.length !== 9) {
            errors.bsn = 'Voer een geldig BSN-nummer in';
        } else {
            let sum = 0;
            let multiplier = bsn.length;
            for (let i = 0; i < bsn.length; i++, multiplier--) {
                if (multiplier === 1) multiplier = -1;
                sum += multiplier * bsn[i];
            }
            if (sum % 11 !== 0) {
                errors.bsn = 'Voer een geldig BSN-nummer in';
            }
        }
    }
    return errors;
};

export const validateZipcode = (zipcode, errors) => {
    if (isNotNull(zipcode)) {
        const pattern = /^\d{4}\s?[A-Za-z]{2}$/;
        if (!pattern.test(zipcode))
            errors.zipcode = 'Voer een geldige postcode in';
    }
    return errors;
};

export const validateMobile = (mobile, twoFactor, errors) => {
    if (isNotNull(mobile)) {
        if (isNaN(mobile) || mobile.length !== 8) {
            errors.phone_mobile = 'Vul een geldig mobiel nummer in';
        }
    } else if (twoFactor)
        errors.phone_mobile = 'Vul een mobiel nummer in voor two-factor authenticatie';
    return errors;
};

export const validateUsername = (username, errors) => {
    if (isNull(username)) {
        errors.username = 'Vul een gebruikersnaam in';
    }
    return errors;
};

export const validateEmail = (email, errors) => {
    if (isNotNull(email)) {
        if (!isValidEmail(email)) {
            errors.email = 'Vul een geldig e-mailadres in';
        }
    } else {
        errors.email = 'Vul een e-mailadres in';
    }
    return errors;
};

export const validateFamilyMember = (member, errors = {}) => {
    errors = validateFirstname(member.firstname, errors);
    errors = validateBsn(member.bsn, errors);
    errors = validateZipcode(member.zipcode, errors);
    errors = validateEmail(member.email, errors);

    let count = 0;
    if (isNotNull(errors.firstname)) count++;
    if (isNotNull(errors.bsn)) count++;
    if (isNotNull(errors.zipcode)) count++;
    if (isNotNull(errors.email)) count++;
    errors.count = count;
    return errors;
};

export const validateAccount = (account, errors = {}) => {
    errors = validateFirstname(account.firstname, errors);
    errors = validateBsn(account.bsn, errors);
    errors = validateZipcode(account.zipcode, errors);
    errors = validateMobile(account.phone_mobile, account.two_step_auth, errors);
    errors = validateUsername(account.username, errors);
    errors = validateEmail(account.email, errors);

    let count = 0;
    if (isNotNull(errors.firstname)) count++;
    if (isNotNull(errors.bsn)) count++;
    if (isNotNull(errors.zipcode)) count++;
    if (isNotNull(errors.phone_mobile)) count++;
    if (isNotNull(errors.username)) count++;
    if (isNotNull(errors.email)) count++;
    errors.count = count;
    return errors;
};