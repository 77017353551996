import './style/addDocument.scss';

import React from 'react';
import PropTypes from 'prop-types';
import {CloudUpload} from "@material-ui/icons";

import {deviceIsMobile} from 'glob-common-js/lib/utils';

import BdhDocument from "../../../../models/document";
import {WithBackground} from "../../../../misc/reactUtils";
import MaterialFactory from "../../../material/materialFactory";
import {materialTypes} from "../../../material/materialTypes";
import BdhSelectController from "../../../customControls/select/bdhSelectController";
import CustomScrollbar from "../../../misc/customScrollbar";
import StateComponent from "../../../misc/stateComponent";
import GenericLoader from "../../../misc/genericLoader";
import BdhContainedInput from "../../../customControls/input/bdhContainedInput";
import imageExtensions from '../../../../common/data/mappings/imageExtensions';

export default class AddDocumentPopup extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            file: {
                url: null,
                extension: null,
                name: null,
            },
        }
    }


    onClickUpload = evt => {
        evt.stopPropagation();
        const input = document.getElementById('uploadFile');
        if (isNotNull(input)) input.click();
    };

    onDragFile = evt => {
        evt.preventDefault();
    };

    onFocusLabelInput = evt => {
        const target = evt.target;
        if (isNotNull(target) && isNotNull(target.value))
            target.setSelectionRange(0, target.value.length);
    };

    onClickFile = evt => {
        const {url, extension} = this.state.file;
        if (imageExtensions.includes(extension) || extension === 'pdf')
            open(url);
        else this.onClickUpload(evt);
    };

    setFileData = (url, extension, name) => {
        this.changeState({
            file: {
                url, extension, name
            }
        });
    };

    renderHeader = () => {
        const {doc, onClose} = this.props;
        return (
            <div className='headerContainer'>
                <label className='headerTitle'>{doc.type.label} toevoegen</label>
                <span className='common-icon-cross closeButton' onClick={onClose}/>
            </div>
        )
    };

    renderBody = () => {
        const {loading} = this.props;
        return (
            <div className='addDocumentBody'>
                <GenericLoader active={loading}/>
                {this.renderFileColumn()}
                {this.renderDetailColumn()}
            </div>
        )
    };

    renderFileColumn = () => {
        const {onDropFile, onChangeFile, fileUploaded} = this.props;
        return (
            <div className='col'>
                <label className='colTitle'>Uploaden</label>
                <div className='fileContainer' onDragOver={this.onDragFile} onDrop={onDropFile}>
                    <input type='file' id='uploadFile' className='fileInput' onChange={onChangeFile}/>
                    {fileUploaded ? this.renderFile() : this.renderEmptyFile()}
                </div>
            </div>
        )
    };

    renderEmptyFile = () => {
        return (
            <div className='emptyFileContainer'>
                <CloudUpload className='uploadIcon'/>
                <label className='emptyFileText'>Sleep je bestand hierheen</label>
                <label className='divider'>of</label>
                <MaterialFactory componentType={materialTypes.RAISED_BUTTON} className='fileButton'
                                 onClick={this.onClickUpload}>Kiezen...</MaterialFactory>
            </div>
        )
    };

    renderFile = () => {
        const {url, extension} = this.state.file;
        const isImage = imageExtensions.includes(extension);
        if (isImage)
            return (
                <div className='fileImageContainer' style={{backgroundImage: `url(${url})`}}
                     onClick={this.onClickFile}>
                    <MaterialFactory componentType={materialTypes.RAISED_BUTTON} className='fileButton'
                                     onClick={this.onClickUpload}>Ander bestand...</MaterialFactory>
                </div>
            );
        else return this.renderFileIcon();
    };

    renderFileIcon = () => {
        const {url, extension, name} = this.state.file;
        let iconName = 'document-2';
        if (/pdf/.test(extension)) iconName = 'pdf';
        else if (/docx?/.test(extension)) iconName = 'word';
        else if (/xlsx?/.test(extension)) iconName = 'excel';
        else if (/pptx?/.test(extension)) iconName = 'powerpoint';
        return (
            <div className='fileIconContainer' onClick={this.onClickFile}>
                <span className={`common-icon-${iconName} fileIcon`}/>
                <label className='fileName'>{name}</label>
                <MaterialFactory componentType={materialTypes.RAISED_BUTTON} className='fileButton'
                                 onClick={this.onClickUpload}>Ander bestand...</MaterialFactory>
            </div>
        )
    };

    renderDetailColumn = () => {
        const {onChangeLabel, doc} = this.props;
        return (
            <div className='col'>
                <CustomScrollbar height='20rem'>
                    <label className='colTitle'>Documentdetails</label>
                    <BdhContainedInput onChange={onChangeLabel} value={doc.label} label='Herkenbare documentnaam'
                                       containerClass='padded' onFocus={this.onFocusLabelInput} fullWidth/>
                    {Object.values(doc.fields).map(this.renderField)}
                </CustomScrollbar>
            </div>
        )
    };

    renderField = (field, key) => {
        const {onChangeField, onClearField} = this.props;
        if (isNotNull(field.options)) {
            const value = isNull(field.value) ? [] : [field.value];
            const items = field.options.map(option => ({value: option, label: option}));
            return <BdhSelectController key={key} onSelect={onChangeField(field.name)} value={value} items={items}
                                        label={field.label} onClear={onClearField(field.name)} variant='contained'
                                        containerClass='padded' fullWidth/>
        }
        const value = isNull(field.value) ? '' : field.value;
        return <BdhContainedInput key={key} value={value} onChange={onChangeField(field.name)} label={field.label}
                                  containerClass='padded' fullWidth/>;
    };

    renderFooter = () => {
        const {doc, fileUploaded, onClose, onSave, loading, email, onClickLibrary} = this.props;
        return (
            <div className='addDocumentFooter'>
                <div className='mailInfoContainer' id='mailDocumentContainer'>
                    <p className='mailText'>
                        Mail bestanden naar <a href={`mailto:${email}`} className='email'>{email}</a> om ze te
                        uploaden naar jouw <span className='link' onClick={onClickLibrary}>bibliotheek</span>.
                    </p>
                </div>
                <div className='buttonsContainer'>
                    <MaterialFactory componentType={materialTypes.FLAT_BUTTON} onClick={onClose}
                                     className='cancelButton'>Annuleren</MaterialFactory>
                    <MaterialFactory componentType={materialTypes.RAISED_BUTTON} onClick={onSave}
                                     disabled={!fileUploaded || isNull(doc.label) || loading}>Opslaan</MaterialFactory>
                </div>
            </div>
        )
    };

    componentDidMount = () => {
        this.mount();
    };

    componentWillUnmount = () => {
        this.unMount();
    };

    render = () => {
        const {onClose, onClickBackground} = this.props;
        return (
            <WithBackground onClose={onClickBackground} onEscape={onClose} blockScroll={deviceIsMobile()}>
                <div className='addDocumentView'>
                    {this.renderHeader()}
                    {this.renderBody()}
                    {this.renderFooter()}
                </div>
            </WithBackground>
        );
    };
};

AddDocumentPopup.propTypes = {
    onChangeLabel: PropTypes.func.isRequired,
    onChangeField: PropTypes.func.isRequired,
    onClearField: PropTypes.func.isRequired,
    onChangeFile: PropTypes.func.isRequired,
    onDropFile: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onClickBackground: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onClickLibrary: PropTypes.func.isRequired,
    doc: PropTypes.instanceOf(BdhDocument).isRequired,
    loading: PropTypes.bool.isRequired,
    email: PropTypes.string.isRequired,
    fileUploaded: PropTypes.bool.isRequired,
};