import {areNotNull, debug, isNotNull} from "./utils";

export let appSettings = {};

/**
 * Reads settings from a global settings script. If no script is provided within the HTML an error is thrown.
 */
export const initializeSettings = () => {
    if (isNotNull(window.settings)) {
        const settings = window.settings;
        const mode = settings.mode;
        const modeSettings = areNotNull(mode, window.modeSettings) ? window.modeSettings[mode] : {};
        appSettings = Object.assign({}, settings, modeSettings);
        Object.freeze(appSettings);
        removeSettingsScript();
        debug('Using settings: ' + JSON.stringify(appSettings, null, '\t'));
    } else
        throw new Error('Missing global settings object');
};

/**
 * Deletes the global settings script, if it exists and has been given the right name
 */
const removeSettingsScript = () => {
    const SCRIPT_NAME = 'settingsScript';
    const scriptTag = document.getElementById(SCRIPT_NAME);
    if (isNotNull(scriptTag) && isNotNull(scriptTag.parentElement)) {
        scriptTag.parentElement.removeChild(scriptTag);
    }
};

export default {
    initializeSettings: initializeSettings,
};