import './style/onboardingView.scss';

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import StateComponent from "../misc/stateComponent";
import PopupBuilder from "../misc/popupBuilder/popupBuilder";
import MaterialFactory from "../material/materialFactory";
import {materialTypes} from "../material/materialTypes";
import {sendGaEvent} from "../../common/js/ga";

const imagesLocation = require.context('../../common/images/onboarding', false);
const GA_CATEGORY = 'Onboarding keuze';
export default class OnboardingView extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            selectedCategory: null,
        };
    }

    onSelectCategory = name => () => {
        sendGaEvent(GA_CATEGORY, 'Selecteer flow', name);
        this.changeState({selectedCategory: name});
    };

    onSubmit = () => {
        this.props.onSubmit(this.state.selectedCategory);
    };

    getImage = imageName => {
        return imagesLocation(`./${imageName}`);
    };

    renderHeader = () => {
        return (
            <label className='onboardingHeader'>Aan de slag met je Personal Organizer</label>
        );
    };

    renderBody = () => {
        const {firstName} = this.props;
        return (
            <div className='onboardingBody'>
                <label className='bodyTitle'>Hoi {firstName}! Kies waar je mee wilt beginnen:</label>
                <div className='categoriesContainer'>
                    {this.renderCategories()}
                </div>
            </div>
        );
    };

    renderCategories = () => {
        const {selectedCategory} = this.state;
        const items = [
            {name: 'bewaren', title: 'Overzichtelijk documenten bewaren', img: 'mappen.jpg'},
            {name: 'financieel', title: 'Maak je financiële overzicht', img: 'laptop.png'},
            {name: 'besparen', title: 'Besparen op je vaste lasten', img: 'money_hand.jpg'},
            {name: 'agenda', title: 'Niets belangrijks meer vergeten', img: 'schrift.jpg'},
        ];
        return items.map((item, key) => {
            return (
                <div key={key} className={classNames('category', selectedCategory === item.name && 'selected')}
                     onClick={this.onSelectCategory(item.name)}>
                    <div className='titleContainer'>
                        <label className='categoryTitle'>{item.title}</label>
                    </div>
                    <div className='imageContainer'>
                        <div className='categoryImage' style={{backgroundImage: `url(${this.getImage(item.img)})`}}/>
                    </div>
                </div>
            )
        });
    };

    renderFooter = () => {
        const {onSkip} = this.props;
        const {selectedCategory} = this.state;
        return (
            <div className='onboardingFooter'>
                <MaterialFactory componentType={materialTypes.RAISED_BUTTON} className='skipLink'
                                 onClick={onSkip}>Overslaan</MaterialFactory>
                <MaterialFactory componentType={materialTypes.RAISED_BUTTON} disabled={isNull(selectedCategory)}
                                 onClick={this.onSubmit}>Beginnen</MaterialFactory>
            </div>
        )
    };

    componentDidMount = () => {
        this.mount();
    };

    componentWillReceiveProps = nextProps => {
        const {open} = this.props, nextOpen = nextProps.open;
        if (!open && nextOpen) this.changeState({selectedCategory: null});
    };

    componentWillUnmount = () => {
        this.unMount();
    };

    render = () => {
        const {open} = this.props;
        return (
            <PopupBuilder open={open} containerClass='onboardingView' bodyClass='onboardingBodyContainer'
                          footerClass='onboardingFooterContainer' content={{
                header: this.renderHeader(),
                body: this.renderBody(),
                footer: this.renderFooter(),
            }}/>
        );
    };
}

OnboardingView.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onSkip: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    firstName: PropTypes.string.isRequired,
};