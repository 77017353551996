const defaultProperties = {
    URLS: {
        PLATFORM: 'https://incntrl.bydehand.com/v1',
        DEV_PLATFORM: 'https://acceptatie.bydehand.com/api/v1',
        WEBSITE: 'https://bydehand.com',
        REGISTER: '/signup/registration',
        LOGIN: '/auth/login',
        GET_BELONGING: '/belonging/',
        FREE_TIME_CATEGORIES: '/subcategories/all?filter=free_time',
        TRAVEL_CATEGORY: '/belonging/types?name=travel',
        CHECKLIST_TYPE: '/belonging/types?category=',
        CHECKLIST_BELONGING: '/belonging',
        PUT_CHECKLIST: '/belonging/',
        POST_BELONGING: '/belonging',
        UPLOAD_FILE: '/vault/file',
        FILE_TYPE_ID: '/vault/filetype',
        VAULT: '/vault',
        VAULT_ALL: '/vault/all',
        GET_USER: '/account/',
        BELONGING_TYPES: '/belonging/types',
    }
};

const properties = {
    defaultProperties: defaultProperties,
};

export default properties;

