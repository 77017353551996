export const steps = {
    ERROR: -1,
    REGISTRATION: 0,
    ACTIVATION: {
        index: 1,
        MAIL_SEND: 'MAIL_SEND',
        ACTIVATING: 'ACTIVATING',
        INSURANCE_SCAN: 'INSURANCE_SCAN',
        PAYMENT_INTRO: 'PAYMENT_INTRO',
        START_PAYMENT: 'START_PAYMENT',
        CHECK_PAYMENT: 'CHECK_PAYMENT',
        PENDING_PAYMENT: 'PENDING_PAYMENT',
    },
    COMPLETION: {
        index: 2,
        FREE: 'FREE',
        DEFAULT: 'DEFAULT',
        TRIAL: 'TRIAL',
    },
}, types = {
    DEFAULT: 'default',
    FREE: 'free',
    STUDENT: 'student',
    TRIAL: 'trial',
}, defaultState = {
    active: false,
    step: steps.REGISTRATION,
    subStep: 0,
    type: types.DEFAULT,
    activated: false,
    user: null,
    errors: {
        registration: null,
        general: null,
    },
    loading: false,
};