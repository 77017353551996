import React from 'react';

import {capitalizeFirst, deviceIsMobile} from 'glob-common-js/lib/utils';

import StateComponent from "../../misc/stateComponent";
import ContactDetailView from "./contactDetailView";
import {responseIsSuccess} from "../../../misc/requestSender";
import {
    addDataEventListener,
    DATA_TYPES,
    dispatchContactEvent,
    EVENT_TYPES, removeAllDataEventListeners,
    removeDataEventListener
} from "../../../misc/dataEvent";
import {navigate, showMessage, showSnackbar} from "../../../misc/utils";
import {NOW} from "../../misc/constants";
import {
    addCustomEventListener,
    addCustomEventParam,
    dispatchCustomEvent,
    removeCustomEventListener
} from "../../../misc/eventDispatcher";
import {Contact} from "../../../models/contact";

export default class ContactDetailController extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            contact: new Contact(),
            active: false,
            loading: false,
            activeTab: 'data', // data || accounts || dossiers
            passwordConfirmedAt: null,
            isNew: false,
            hasChanges: false,
        };
        this.dossierId = null;
        this.closeable = true;
    }

    onOpenDossierLink = () => {
        this.closeable = false;
    };

    onCloseDossierLink = () => {
        this.closeable = true;
    };

    onChange = name => value => {
        const {contact} = this.state;
        contact[name] = value;
        this.changeState({contact, hasChanges: true});
    };

    onClose = () => {
        if (this.closeable)
            this.changeState({
                active: false,
                contact: new Contact(),
                loading: false,
                activeTab: 'data',
                hasChanges: false
            });
    };

    onSubmit = () => {
        const {contact} = this.state;
        if (isNotNull(this.dossierId))
            contact.addDossier(this.dossierId);
        this.changeState({loading: true, hasChanges: false});
        if (isNull(contact.id)) contact.save(this.submitCallback('save'));
        else contact.update(this.submitCallback('update'));
    };

    onChangeTab = tab => () => {
        this.changeState({activeTab: tab});
    };

    onConfirmPassword = () => {
        this.changeState({passwordConfirmedAt: NOW()});
    };

    onClickNewAccount = () => {
        const {contact} = this.state;
        contact.addAccount({label: '', username: '', password: ''});
        this.changeState({contact, hasChanges: true});
    };

    onChangeAccount = (account, key) => value => {
        const {contact} = this.state;
        for (const acc of contact.accounts) {
            if (acc.id === account.id) {
                acc[key] = value;
                this.changeState({contact, hasChanges: true});
                return;
            }
        }
    };

    onRemoveAccount = account => () => {
        const {contact} = this.state;
        contact.removeAccount(account);
        this.changeState({contact, hasChanges: true});
    };

    onDelete = () => {
        showMessage('Weet je zeker dat je dit contact wilt verwijderen?', [
            {label: 'Nee'}, {label: 'Ja', onClick: this.onConfirmDelete},
        ], 'Contact verwijderen');
    };

    onConfirmDelete = () => {
        const {contact} = this.state;
        this.changeState({loading: true});
        contact.delete(response => {
            if (responseIsSuccess(response)) {
                this.onClose();
                showSnackbar('Je contact is verwijderd');
                dispatchContactEvent(EVENT_TYPES.DELETE, contact);
            } else showMessage('Het verwijderen van je contact is niet gelukt. Probeer het later opnieuw of neem ' +
                'contact met ons op', null, 'Verwijderen mislukt');
        });
    };

    onClickDossier = dossier => () => {
        this.onClose();
        const dispatchEvent = location.pathname.includes('dossier-details');
        addCustomEventParam('showDossierContacts',true);
        navigate(`/dossier-details/${dossier.id}`);
        if (dispatchEvent) dispatchCustomEvent('dossierIdChanged', dossier.id);
    };

    onClickNewDossier = () => {
        dispatchCustomEvent('linkContact', this.state.contact);
    };

    onRemoveDossier = dossier => evt => {
        evt.stopPropagation();
        showMessage('Weet je zeker dat je dit dossier wilt ontkoppelen?', [
            {label: 'Nee'}, {label: 'Ja', onClick: this.onConfirmRemoveDossier(dossier)},
        ], 'Dossier ontkoppelen');
    };

    onConfirmRemoveDossier = dossier => () => {
        const {contact} = this.state;
        contact.removeDossier(dossier.id);
        this.changeState({loading: true});
        contact.update(() => {
            this.changeState({contact, loading: false});
            dispatchContactEvent(EVENT_TYPES.UPDATE, contact);
        })
    };

    onContactEvent = contact => {
        this.changeState({contact});
    };

    activate = ({dossierId, contact, tab}) => {
        this.changeState({active: true, loading: true});
        tab = isNull(tab) ? 'data' : tab;
        this.dossierId = dossierId;
        const isNew = isNull(contact) || isNull(contact.id);
        if (!isNew && !contact.dossiersLoaded)
            contact.loadDossiers(contact => {
                this.activateState(contact, isNew, tab);
            });
        else {
            if (isNull(contact))
                contact = new Contact({name: 'Nieuw contact'});
            this.activateState(contact, isNew, tab);
        }
    };

    activateState = (contact, isNew, tab) => {
        log('activating', {contact, isNew, tab});
        this.changeState({
            contact,
            isNew,
            activeTab: tab,
            hasChanges: isNew,
            loading: false,
        });
    };

    submitCallback = type => (contact, response) => {
        this.changeState({loading: false});
        if (responseIsSuccess(response)) {
            if (type === 'save') {
                contact.loadDossiers(contact => {
                    this.submitFinishCallback(contact, type);
                })
            } else this.submitFinishCallback(contact, type);

        } else {
            this.changeState({hasChanges: true});
            const action = type === 'save' ? 'opslaan' : 'wijzigen';
            showMessage(`Het ${action} van dit contact is niet gelukt. Probeer het later 
            opnieuw of neem contact met ons op.`, null, `${capitalizeFirst(action)} mislukt`);
        }
    };

    submitFinishCallback = (contact, type) => {
        this.changeState({contact, hasChanges: false});

        const eventType = type === 'save' ? EVENT_TYPES.CREATE : EVENT_TYPES.UPDATE;
        dispatchContactEvent(eventType, contact);

        const action = type === 'save' ? 'opgeslagen' : 'aangepast', text = `Je contact is ${action}.`;
        if (deviceIsMobile()) showMessage(text);
        else showSnackbar(text);
    };

    componentDidMount = () => {
        this.mount();
        addCustomEventListener('openContact', this.activate);
        addCustomEventListener('openLinkDossierContact', this.onOpenDossierLink);
        addCustomEventListener('closeLinkDossierContact', this.onCloseDossierLink);
        addDataEventListener(DATA_TYPES.CONTACT, [EVENT_TYPES.UPDATE,
            EVENT_TYPES.CREATE], 'contactDetailController', this.onContactEvent)
    };

    componentWillUnmount = () => {
        this.unMount();
        removeCustomEventListener('openContact', this.activate);
        removeCustomEventListener('openLinkDossierContact', this.onOpenDossierLink);
        removeCustomEventListener('closeLinkDossierContact', this.onCloseDossierLink);
        removeAllDataEventListeners('contactDetailController', this.onContactEvent);
    };

    render = () => {
        const {contact, active, activeTab, loading, passwordConfirmedAt, isNew, hasChanges} = this.state;
        if (active)
            return <ContactDetailView onChange={this.onChange} onClose={this.onClose} onSubmit={this.onSubmit}
                                      onChangeTab={this.onChangeTab} onConfirmPassword={this.onConfirmPassword}
                                      onClickNewAccount={this.onClickNewAccount} onChangeAccount={this.onChangeAccount}
                                      contact={contact} activeTab={activeTab} passConfirmedAt={passwordConfirmedAt}
                                      loading={loading} onRemoveAccount={this.onRemoveAccount}
                                      onDelete={this.onDelete} isNew={isNew} hasChanges={hasChanges}
                                      onClickDossier={this.onClickDossier} onClickNewDossier={this.onClickNewDossier}
                                      onRemoveDossier={this.onRemoveDossier}/>;
        return null;
    }
}