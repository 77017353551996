import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../../redux/actions';

import {isFunction} from 'glob-common-js/lib/utils';

import StateComponent from "../misc/stateComponent";
import {addCustomEventListener, dispatchCustomEvent, removeCustomEventListener} from "../../misc/eventDispatcher";
import CancellationView from "./cancellationView";
import {
    deleteAccount,
    deleteSubscription,
    deleteSubscriptionUser,
    login,
    responseIsSuccess,
    updateAccount,
    updateDaisyUser
} from "../../misc/requestSender";
import {SUCCESS} from "../../misc/constants";
import {sendGaEvent} from "../../common/js/ga";
import {navigate, showMessage, logToSentry} from "../../misc/utils";
import MaterialFactory from "../material/materialFactory";
import {materialTypes} from "../material/materialTypes";
import {setJwt} from "../../common/js/platform";

export const CANCEL_ACCOUNT = 'account', CANCEL_SUBSCRIPTION = 'sub';

const GA_CATEGORY = 'Account';

export class CancellationController extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            active: false,
            passwordError: '',
            type: CANCEL_ACCOUNT,
            dialogOpen: false,
        };
        this.twoFactorDisabled = false;
        this.dialogCallback = null;
    }

    onShowEvent = (type) => {
        if (type !== CANCEL_ACCOUNT && type !== CANCEL_SUBSCRIPTION)
            throw new Error(`Invalid cancelation type: ${type}. Must be one of 
            [${CANCEL_ACCOUNT}, ${CANCEL_SUBSCRIPTION}].`);
        this.disableTwoFactor();
        this.changeState({type, active: true});
    };

    onClose = () => {
        this.changeState({
            saving: false,
            active: false,
            passwordError: '',
        });
        this.enableTwoFactor();
    };

    onCloseDialog = () => {
        this.changeState({
            dialogOpen: false,
        });
        if (isFunction(this.dialogCallback))
            this.dialogCallback();
    };

    onCancel = (reason, password) => {
        this.changeState({saving: true});
        this.checkPassword(reason, password);
    };

    deleteAccount = (reason) => {
        const user = this.getUser();
        this.saveReason(reason, () => {
            deleteSubscriptionUser({
                callback: response => {
                    if (responseIsSuccess(response))
                        deleteAccount({
                            id: user.id,
                            callback: (response) => {
                                this.changeState({saving: false});
                                if (responseIsSuccess(response)) {
                                    sendGaEvent(GA_CATEGORY, 'Verwijderen', 'completed');
                                    setJwt(null);
                                    this.onClose();
                                    this.changeState({dialogOpen: true});
                                    this.dialogCallback = () => {
                                        this.props.actions.setUser({email: '', firstname: ''});
                                        navigate('/auth/login');
                                    };
                                } else
                                    dispatchCustomEvent('openSnackbar', {text: 'Het verwijderen van je account is niet gelukt'});
                            },
                        });
                    else {
                        this.changeState({saving: false});
                        showMessage('Het verwijderen van je account is niet gelukt. Probeer het later nog ' +
                            'eens of neem contact met ons op', [{label: 'Oke', onClick: this.onClose}]);
                    }
                },
            });
        });
    };

    cancelSubscription = (reason) => {
        this.saveReason(reason, () => {
            deleteSubscription({
                callback: response => {
                    this.changeState({saving: false});
                    if (responseIsSuccess(response)) {
                        const {user} = response.data;
                        this.props.actions.setSubscription(user);
                        this.onClose();
                        this.changeState({dialogOpen: true});
                        this.dialogCallback = () => {
                            navigate('/dashboard');
                        };
                    } else
                        dispatchCustomEvent('openSnackbar', {text: 'Het opzeggen van je abonnement is niet gelukt'});
                },
            })
        });
    };

    getUser = () => this.props.userState.user;

    getSubscription = () => this.props.userState.subscription;

    enableTwoFactor = () => {
        if (this.twoFactorDisabled) {
            const user = this.getUser();
            user.two_step_auth = 1;
            updateAccount({
                id: user.id,
                data: user,
            });
        }
    };

    disableTwoFactor = () => {
        const user = this.getUser();
        if (user.two_step_auth === 1) {
            user.two_step_auth = 0;
            updateAccount({
                id: user.id,
                data: user,
            });
        }
    };

    checkPassword = (reason, password) => {
        if (isNotNull(password)) {
            const user = this.getUser();
            login({
                keepJwt: true,
                email: user.email,
                password,
                callback: (response) => {
                    const {status} = response.data;
                    if (status === SUCCESS) {
                        const {type} = this.state;
                        if (type === CANCEL_ACCOUNT)
                            this.deleteAccount(reason, password);
                        else if (type === CANCEL_SUBSCRIPTION) {
                            this.cancelSubscription(reason, password);
                        }
                    } else
                        this.changeState({passwordError: 'Wachtwoord is niet juist.', saving: false,});
                },
            });
        }
    };

    saveReason = (reason, callback) => {
        try {
            const subscription = this.getSubscription();
            const tags = isNull(subscription.tags) ? {} : subscription.tags;
            const type = this.state.type;
            if (type === CANCEL_ACCOUNT)
                tags.cancel_account_feedback = reason;
            else if (type === CANCEL_SUBSCRIPTION)
                tags.cancel_subscription_feedback = reason;
            updateDaisyUser({
                data: {tags},
                callback: () => {
                    callback();
                },
            });
        } catch (ex){
            // Log error, but don't prevent the cancellation of the subscription
            logToSentry(ex);
        }
    };

    getTypeLabel = (accountExtra = '', subExtra = '', capitalize = false) => {
        const type = this.state.type;
        const label = (type === CANCEL_ACCOUNT ? 'account' + accountExtra : 'abonnement' + subExtra);
        if (capitalize)
            return label.substr(0, 1).toUpperCase() + label.substr(1, label.length);
        return label;
    };

    renderDialogActions = () => ([
        {
            label: 'Oke',
            onClick: this.onCloseDialog,
        },
    ]);

    componentDidMount = () => {
        this.mount();
        addCustomEventListener('showCancellation', this.onShowEvent);
    };

    componentWillUnmount = () => {
        this.unMount();
        removeCustomEventListener('showCancellation', this.onShowEvent);
    };

    render = () => {
        const {saving, passwordError, active, type, dialogOpen} = this.state;
        return (
            <>
                <MaterialFactory componentType={materialTypes.DIALOG} open={dialogOpen}
                                 title={this.getTypeLabel(' verwijderd', ' opgezegd', true)}
                                 onClose={this.onCloseDialog}
                                 text={`Je ${this.getTypeLabel(' is verwijderd', ' is opgezegd')}.`}
                                 actions={this.renderDialogActions()}/>
                <CancellationView onClose={this.onClose} saving={saving} active={active} onCancel={this.onCancel}
                                  type={type} passwordError={passwordError}/>
            </>
        );
    };
}

const mapStateToProps = state => ({
    userState: state.user,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CancellationController);