import './style/shareDocument.scss';

import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';

import {isValidEmail} from 'glob-common-js/lib/utils';

import StateComponent from "../../misc/stateComponent";
import PopupBuilder from "../../misc/popupBuilder/popupBuilder";
import MaterialFactory from "../../material/materialFactory";
import {materialTypes} from "../../material/materialTypes";
import CustomScrollbar from "../../misc/customScrollbar";
import GenericLoader from "../../misc/genericLoader";

export default class DocumentShare extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            recipients: [],
            values: {
                firstname: '',
                email: '',
                description: '',
            },
            errors: {
                firstname: null,
                email: null,
            }
        };
    }

    onChange = name => evt => {
        this.changeState({values: Object.assign({}, this.state.values, {[name]: evt.target.value})});
    };

    onShare = () => {
        const {onShare} = this.props;
        const recipients = this.state.recipients.slice();
        const description = Object.assign({}, this.state.values).description;
        this.reset();
        onShare(recipients, description)();
    };

    onAddRecipient = () => {
        const {firstname, email} = this.state.values;
        if (this.recipientIsValid(firstname, email)) {
            const recipients = this.state.recipients.slice();
            recipients.push({firstname, email});
            this.changeState({
                recipients,
                values: {firstname: '', email: ''},
                errors: {firstname: null, email: null}
            });
        }
    };

    onRemoveRecipient = (email) => () => {
        let recipients = this.state.recipients.slice();
        recipients = recipients.filter(rcpt => rcpt.email !== email);
        this.changeState({recipients});
    };

    reset = () => {
        this.changeState({
            recipients: [],
            values: {
                firstname: '',
                email: '',
                description: '',
            },
            errors: {
                firstname: null,
                email: null,
            }
        });
    };

    recipientIsValid = (firstname, email) => {
        let errors = {firstname: null, email: null};
        if (isNull(firstname))
            errors.firstname = 'Vul de voornaam in';
        if (!isValidEmail(email))
            errors.email = 'Vul een geldig e-mailadres in';
        this.changeState({errors});
        return errors.firstname === null && errors.email === null;
    };

    componentDidMount = () => {
        this.mount();
    };

    componentWillUnmount = () => {
        this.unMount();
    };

    renderHeader = () => {
        const {onClose} = this.props;
        return (
            <>
                <span className='common-icon-email mailIcon'/>
                <label className='shareDocumentTitle'>Documenten delen</label>
                <span className='common-icon-cross shareDocumentClose' onClick={onClose}/>
            </>
        )
    };

    renderBody = () => {
        const {values, errors} = this.state;
        return (
            <div className='shareDocumentBody'>
                <GenericLoader active={this.props.loading}/>
                <div className='addRecipientContainer'>
                    <MaterialFactory componentType={materialTypes.TEXT} value={values.firstname} label='Voornaam'
                                     onChange={this.onChange('firstname')} helperText={errors.firstname} required
                                     error={isNotNull(errors.firstname)} className='recipientFirstname'/>
                    <MaterialFactory componentType={materialTypes.TEXT} type='email' value={values.email} required
                                     label='E-mailadres' onChange={this.onChange('email')} helperText={errors.email}
                                     error={isNotNull(errors.email)} className='recipientEmail'/>
                </div>
                <MaterialFactory componentType={materialTypes.RAISED_BUTTON} onClick={this.onAddRecipient}
                                 disabled={isNull(values.firstname) || isNull(values.email)}>
                    Toevoegen
                </MaterialFactory>
                <label className='recipientsTitle'>Ontvangers</label>
                {this.renderAddedRecipients()}
                <MaterialFactory componentType={materialTypes.TEXT} multiline maxRows={3} value={values.description}
                                 label='Omschrijving' onChange={this.onChange('description')} fullWidth/>
            </div>
        )
    };

    renderAddedRecipients = () => {
        const {recipients} = this.state;
        return (
            <CustomScrollbar height='10rem' autoHide={false}>
                <List>
                    {recipients.map((rcpt, key) => (
                        <ListItem key={key}>
                            <ListItemText primary={rcpt.firstname} secondary={rcpt.email}/>
                            <span className='common-icon-cross removeRecipient' title='Verwijderen'
                                  onClick={this.onRemoveRecipient(rcpt.email)}/>
                        </ListItem>
                    ))}
                </List>
            </CustomScrollbar>
        );
    };

    renderFooter = () => {
        const {onClose, loading} = this.props;
        const {recipients} = this.state;
        return (
            <>
                <MaterialFactory componentType={materialTypes.RAISED_BUTTON}
                                 onClick={this.onShare}
                                 disabled={isNull(recipients) || loading}>
                    Verstuur
                </MaterialFactory>
                <MaterialFactory componentType={materialTypes.FLAT_BUTTON} onClick={onClose}>
                    Annuleren
                </MaterialFactory>
            </>
        )
    };

    render = () => {
        const {active} = this.props;
        return (
            <PopupBuilder open={active} content={{
                header: this.renderHeader(),
                body: this.renderBody(),
                footer: this.renderFooter()
            }} containerClass='shareDocument' headerClass='shareDocumentHeader' footerClass='shareDocumentFooter'/>
        )
    }
}

DocumentShare.propTypes = {
    onShare: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    active: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
};