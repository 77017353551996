import './style/bdhSelect.scss';

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {hasHover} from 'glob-common-react/lib/utils/reactUtils';
import {deviceIsMobile} from 'glob-common-js/lib/utils';

import StateComponent from "../../misc/stateComponent";
import BdhInput from "../input/bdhInput";
import CustomScrollbar from "../../misc/customScrollbar";
import BdhContainedInput from "../input/bdhContainedInput";

export default class BdhSingleSelect extends StateComponent {
    #container = null;
    #input = null;

    constructor(props) {
        super(props);
        this.state = {
            selectOpen: false,
            searchFocused: false,
        };
    }

    onFocus = evt => {
        const {disabled, readOnly} = this.props;
        if (!disabled) {
            this.changeState({selectOpen: true, searchFocused: !readOnly});
            this.addClickListener();
            this.props.onFocus(evt);
        }
    };

    onSelect = item => evt => {
        this.changeState({selectOpen: false});
        this.removeClickListener();
        this.props.onSelect(item, evt);
    };

    onNativeChange = evt => {
        const {items} = this.props;
        const select = evt.target, index = select.selectedIndex, options = select.options;
        for (let item of items) {
            if (item.value === options[index].value) {
                this.props.onSelect(item, evt);
                return;
            }
        }
        this.onClear();
    };

    onClear = () => {
        const {value, onClear} = this.props;
        onClear(value[0]);
        if (isNotNull(this.#input))
            this.#input.focus();
    };

    onClickArrow = () => {
        if (this.state.selectOpen)
            this.changeState({selectOpen: false});
        else if (isNotNull(this.#input))
            this.#input.focus();
    };

    addClickListener = () => {
        document.addEventListener('click', this.clickListener);
    };

    removeClickListener = () => {
        document.removeEventListener('click', this.clickListener);
    };

    clickListener = () => {
        if (!hasHover(this.#container)) {
            this.changeState({selectOpen: false}, () => {
                setTimeout(() => {
                    this.props.onSearch('')
                }, 200)
            });
            this.removeClickListener();
        }
    };

    getSelectedItem = () => {
        const {value, allItems} = this.props;
        if (isNotNull(value))
            for (let i = 0; i < allItems.length; i++) {
                if (allItems[i].value === value[0]) return allItems[i];
            }
        return null;
    };

    getItemContainerHeight = () => {
        const {itemContainerHeight, items} = this.props;
        if (isNotNull(itemContainerHeight)) return itemContainerHeight;
        return Math.min(items.length * 2, 15) + 'rem';
    };

    getFieldText = () => {
        const {selectOpen} = this.state, {readOnly, searchValue} = this.props, selected = this.getSelectedItem();
        if (readOnly && pathIsNotNull(selected, 'label')) return selected.label;
        if (selectOpen || isNull(selected)) return searchValue;
        if (pathIsNotNull(selected, 'label')) return selected.label;
        return '';
    };

    renderSearchField = () => {
        const {onSearch, inputProps, label, disabled, readOnly, fullWidth, variant} = this.props,
            text = this.getFieldText();
        return variant === 'explode' ? (
            <BdhInput ref={ref => {
                this.#input = ref
            }} onChange={onSearch} value={text} onFocus={this.onFocus} label={label} disabled={disabled}
                      variant={variant} containerClass='selectSearch' {...inputProps} readOnly={readOnly}
                      fullWidth={fullWidth}/>
        ) : <BdhContainedInput ref={ref => {
            this.#input = ref
        }} onChange={onSearch} value={text} onFocus={this.onFocus} label={label} disabled={disabled}
                               containerClass='selectSearch' {...inputProps} readOnly={readOnly}
                               fullWidth={fullWidth}/>;
    };

    renderClearButton = () => {
        const {selectOpen} = this.state;
        const {value, renderClear, items, searchValue} = this.props;
        if (isNull(value) || !renderClear) {
            if (items.length > 1 || isNotNull(searchValue))
                return (
                    <div className='toggleContainer' onClick={this.onClickArrow}>
                        <span className={classNames('common-icon-arrow-right', 'arrowToggle', selectOpen && 'open')}/>
                    </div>
                );
            return null;
        } else return (
            <div className='toggleContainer' onClick={this.onClear}>
                <span className='common-icon-cross crossToggle'/>
            </div>
        );
    };

    renderItems = () => {
        const {items} = this.props;
        return items.map((item, key) => {
            return (
                <div key={key} className='itemContainer' onClick={this.onSelect(item)}>
                    <label className='itemLabel'>{item.label}</label>
                </div>
            );
        });
    };

    renderSelect = () => {
        const {items, searchValue} = this.props;
        return (
            <>
                {(items.length > 1 || isNotNull(searchValue)) && <div className={classNames('selectItemsContainer')}>
                    <CustomScrollbar height={this.getItemContainerHeight()} autoHide={false}>
                        {this.renderItems()}
                    </CustomScrollbar>
                </div>}
            </>
        )
    };

    renderNative = () => {
        const {items, label, readOnly} = this.props;
        const selected = this.getSelectedItem(), value = isNull(selected) ? '' : selected.value;
        return (
            <div className='nativeSelectContainer'>
                <label className='nativeLabel'>{label}</label>
                <select className='nativeSelect' onChange={this.onNativeChange} value={value} disabled={readOnly}>
                    <option value='' style={{display: 'none'}}/>
                    {items.map((item, key) => <option key={key} value={item.value}>{item.label}</option>)}
                </select>
            </div>
        )
    };

    componentDidMount = () => {
        this.mount();
    };

    componentWillUnmount = () => {
        this.unMount();
        this.removeClickListener();
    };

    render = () => {
        const {selectOpen} = this.state;
        const {label, fullWidth, variant, containerClass} = this.props;
        return (
            <div ref={ref => {
                this.#container = ref
            }}
                 className={classNames('bdhSelect', 'single', selectOpen && 'open', isNotNull(label) && 'withLabel',
                     fullWidth && 'maxWidth', variant === 'contained' && 'contained', containerClass)}>
                {deviceIsMobile() ? this.renderNative() :
                    <>
                        <div className={classNames('selectValueContainer')}>
                            {this.renderSearchField()}
                            {this.renderClearButton()}
                        </div>
                        {this.renderSelect()}
                    </>}
            </div>
        )
    };
};

BdhSingleSelect.propTypes = {
    onSelect: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    searchValue: PropTypes.string.isRequired,
    value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])),
    items: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    })).isRequired,
    allItems: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    })).isRequired,
    inputProps: PropTypes.any,
    itemContainerHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    disabled: PropTypes.bool,
    renderClear: PropTypes.bool.isRequired,
    fullWidth: PropTypes.bool,
    variant: PropTypes.oneOf(['contained', 'explode']),
    containerClass: PropTypes.string,
};

BdhSingleSelect.defaultProps = {
    containerClass: '',
};