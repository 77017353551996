import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames';

import StateComponent from "../misc/stateComponent";
import PopupBuilder from "../misc/popupBuilder/popupBuilder";
import {JSSCancellation} from "./JSSCancellation";
import {CANCEL_ACCOUNT} from "./cancellationController";
import MaterialFactory from "../material/materialFactory";
import {materialTypes} from "../material/materialTypes";
import GenericLoader from "../misc/genericLoader";
import BdhPasswordInputController from "../customControls/input/password/bdhPasswordInputController";

export class CancellationView extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            reason: '',
            password: '',
        };
    }

    onChange = name => evt => {
        const value = evt.target.value;
        if (value.length <= 200)
            this.changeState({[name]: value});
    };

    onChangePassword = value => {
        this.changeState({password: value});
    };

    onClose = () => {
        this.changeState({reason: '', password: ''});
        this.props.onClose();
    };

    onSubmit = () => {
        const {reason, password} = this.state;
        this.props.onCancel(reason, password);
    };

    getTypeLabel = (accountExtra = '', subExtra = '') => {
        const type = this.props.type;
        return type === CANCEL_ACCOUNT ? 'account' + accountExtra : 'abonnement' + subExtra;
    };

    renderHeader = () => {
        const {classes, onClose} = this.props;
        const typeLabel = this.getTypeLabel(' verwijderen', ' opzeggen');
        const headerTitle = `ByDeHand-${typeLabel}`;
        return (
            <div className={classes.header}>
                <label className={classes.headerTitle}>{headerTitle}</label>
                <span className={classNames('common-icon-cross', classes.closeIcon)} onClick={onClose}/>
            </div>
        );
    };

    renderBody = () => {
        const {type, classes, saving, passwordError} = this.props;
        const {reason, password} = this.state;
        const reasonChars = reason.length;
        return (
            <div className={classes.body}>
                <GenericLoader active={saving}/>
                <p className={classes.par}>We vinden het jammer dat jij je
                    ByDeHand-{this.getTypeLabel(' wilt verwijderen', ' wilt opzeggen')}.</p>
                <p>Omdat we voortdurend bezig zijn met het verbeteren van ons platform is feedback
                    voor ons erg belangrijk. We zijn erg benieuwd waarom jij
                    je {this.getTypeLabel(' wilt verwijderen', ' wilt opzeggen')}.</p>
                <MaterialFactory componentType={materialTypes.TEXT}
                                 label={`Reden van ${type === CANCEL_ACCOUNT ? 'verwijderen' : 'opzeggen'}
                                 (${reasonChars}/200)`} value={reason} onChange={this.onChange('reason')} fullWidth
                                 multiline/>
                <p className={classNames(classes.extra)}>Bevestig
                    het {type === CANCEL_ACCOUNT ? 'verwijderen' : 'opzeggen'} van
                    je {this.getTypeLabel()} met je wachtwoord.</p>
                <BdhPasswordInputController label='Wachtwoord' value={password} onChange={this.onChangePassword}
                                            error={passwordError} variant='contained' fullWidth/>
            </div>
        );
    };

    renderFooter = () => {
        const {classes, saving} = this.props;
        const {password} = this.state;
        return (
            <div className={classes.footer}>
                <MaterialFactory componentType={materialTypes.RAISED_BUTTON} onClick={this.onSubmit}
                                 disabled={saving || isNull(password)}>
                    {this.getTypeLabel(' verwijderen', ' opzeggen')}
                </MaterialFactory>
            </div>
        )
    };

    componentDidMount = () => {
        this.mount();
    };

    componentWillUnmount = () => {
        this.unMount();
    };

    render = () => {
        const {active, classes} = this.props;
        return (
            <PopupBuilder open={active} containerClass={classes.container} content={{
                header: this.renderHeader(),
                body: this.renderBody(),
                footer: this.renderFooter(),
            }}/>

        )
    };
}

CancellationView.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    saving: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
    passwordError: PropTypes.string,
};

export default withStyles(JSSCancellation)(CancellationView);