import PropTypes from 'prop-types'
import React from 'react';

import getMapping from '../data/fakeButtonMapping';

import IconButton from 'glob-common-react/lib/buttons/iconButton';

export class FakeContentContainer extends React.Component {
    constructor(props) {
        super(props);
    }

    createFakeButton = (fake, key) => {
        let cssName = '';
        isNotNull(this.props.customCssName) ? cssName = this.props.customCssName : cssName = 'storeButton';
        return (
            <li key={key}>
                <IconButton
                    text={fake.buttonText}
                    extraCssName={cssName + ' fake'}
                    icon={isNotNull(fake.date) ? null : this.props.icon || 'common-icon-document'}
                    date={isNotNull(fake.date) ? fake.date : null}
                    onClick={typeof this.props.fakeClick === 'function' ? this.props.fakeClick : this.defaultFakeClick}
                />
            </li>
        );
    };

    createButtonData = () => {
        let type = this.props.fakeType;
        let max = type === 'document' || type === 'dossier' ? 4 : Number.MAX_SAFE_INTEGER;
        return getMapping(this.props.selectedModule, this.props.activeModules).filter(item => item.type === type).slice(0, max);
    };

    defaultFakeClick = () => {
        alert('Er is iets fout gegaan probeer het later opnieuw!');
    };

    render = () => {
        return (
            <div className='fakeContentContainer'>
                {this.createButtonData().map(this.createFakeButton)}
            </div>
        );
    }

}

FakeContentContainer.propTypes = {
    customCssName: PropTypes.string,
    fakeClick: PropTypes.func,
    fakeType: PropTypes.string.isRequired,
    icon: PropTypes.string,
    selectedModule: PropTypes.string,
    activeModules: PropTypes.arrayOf(PropTypes.string),
};

export default FakeContentContainer;