import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import AgendaPlanned from "./agendaPlanned";
import {dispatchCustomEvent} from "../../../../misc/eventDispatcher";
import {timeFormats} from "../../../../common/components/datePicker";
import {sendGaEvent} from "../../../../common/js/ga";
import {NOW} from "../../../misc/constants";

export const plannedFilters = {
    all: 'Alles',
    deadline: 'Deadlines',
    reminder: 'Herinneringen',
    meeting: 'Afspraken',
};

const GA_CATEGORY = 'Dashboard (Agenda - Ingepland)';
export default class AgendaPlannedController extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFilters: 'all',
        };
        this._isMounted = false;
    }

    changeState = stateChange => {
        this._isMounted && this.setState(stateChange);
    };

    onSelectFilter = filter => {
        const selectedFilters = this.state.selectedFilters;
        if (filter === 'all') {
            this.changeState({selectedFilters: 'all'});
        } else if (selectedFilters === 'all') {
            this.changeState({selectedFilters: [filter]});
        } else if (selectedFilters.includes(filter)) {
            this.removeFilterFromSelected(filter, selectedFilters);
        } else this.addFilterToSelected(filter, selectedFilters);
    };

    onItemClick = item => {
        sendGaEvent(GA_CATEGORY, 'Klik item', 'details');
        dispatchCustomEvent('openAgendaPopup', item);
    };

    addFilterToSelected = (filter, selectedFilters) => {
        sendGaEvent(GA_CATEGORY, 'Selecteer filter', filter);
        if (Array.isArray(selectedFilters) && selectedFilters.length === 2)
            selectedFilters = 'all';
        else selectedFilters.push(filter);
        this.changeState({selectedFilters});
    };

    removeFilterFromSelected = (filter, selectedFilters) => {
        sendGaEvent(GA_CATEGORY, 'Deselecteer filter', filter);
        if (selectedFilters === 'all') {
            selectedFilters = Object.keys(plannedFilters).filter(plannedFilter => plannedFilter !== filter);
        } else selectedFilters.splice(selectedFilters.indexOf(filter), 1);
        if (selectedFilters.length === 0) selectedFilters = 'all';
        this.changeState({selectedFilters});
    };

    getPlannedItems = () => {
        const agendaItems = this.props.agendaItems.filter(agendaItem => this.itemIsInRange(agendaItem) && this.itemIsFiltered(agendaItem));
        agendaItems.sort((itemA, itemB) => {
            const preferA = -1, preferB = 1, isEqual = 0;
            const startA = moment(itemA.start_date_item, timeFormats),
                startB = moment(itemB.start_date_item, timeFormats);
            return startA.isBefore(startB) ? preferA : startA.isAfter(startB) ? preferB : isEqual;
        });
        return agendaItems;
    };

    itemIsFiltered = item => {
        const selectedFilters = this.state.selectedFilters;
        const types = {
            0: 'deadline',
            2: 'reminder',
            3: 'meeting',
        };
        return selectedFilters === 'all' || selectedFilters.includes(types[parseInt(item.type)]);
    };

    itemIsInRange = item => {
        if (item.type === 0) return moment(item.end_date_item, timeFormats).isSameOrAfter(NOW(), 'day');
        else return moment(item.start_date_item, timeFormats).isSameOrAfter(NOW(), 'day');
    };

    componentDidMount = () => {
        this._isMounted = true;
    };

    componentWillUnmount = () => {
        this._isMounted = false;
    };

    render = () => (
        <AgendaPlanned onSelectFilter={this.onSelectFilter} onItemClick={this.onItemClick}
                       onDeleteItemClick={this.props.onDeleteItemClick} selectedFilters={this.state.selectedFilters}
                       agendaItems={this.getPlannedItems()}/>
    )
}

AgendaPlannedController.propTypes = {
    onDeleteItemClick: PropTypes.func.isRequired,
    agendaItems: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        start_date_item: PropTypes.string.isRequired,
        end_date_item: PropTypes.string.isRequired,
    })).isRequired,
};
