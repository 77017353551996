import React from 'react';
import ReactDOM from 'react-dom';
import {MuiPickersUtilsProvider} from 'material-ui-pickers';
import MomentUtils from 'material-ui-pickers/utils/moment-utils';

import {store} from '../../../startup';
import DossierDataController from '../../../components/dossier/details/data/dossierDataController';
import DossierNotesController from '../../../components/dossier/details/notes/dossierNotesController';
import DossierContactsController from '../../../components/dossier/details/contacts/dossierContactsController';
import DossierCarValue from '../../../components/dossier/details/tools/carValue';
import DossierHouseValue from '../../../components/dossier/details/tools/houseValue';
import HomeInsurance from "../../../components/dossier/details/tools/homeInsurance";

import RequestTenderController from "../../../components/dossier/tender/requestTenderController";
import {sendGaEvent} from "BdhCommon/js/ga";
import {getTenderInfo} from "../../../misc/requestSender";
import TravelContactController from "../../../components/dossier/travelContact/travelContactController";
import MaterialFactory from "../../../components/material/materialFactory";
import {materialTypes} from "../../../components/material/materialTypes";

const GA_CATEGORY = 'Dossier details';
export const renderTravelContact = (dossier) => {
    sendGaEvent(GA_CATEGORY, 'Bewaren tab click', 'Thuisblijvers');
    const element = getEmptyRenderElement(dossier);
    ReactDOM.render(<TravelContactController store={store} dossierId={dossier.id}/>, element);
};

export const renderDossierDetails = (dossier) => {
    sendGaEvent(GA_CATEGORY, 'Bewaren tab click', 'Gegevens');
    const element = getEmptyRenderElement(dossier);
    ReactDOM.render(
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <MaterialFactory componentType={materialTypes.THEME_PROVIDER}>
                <DossierDataController dossier={dossier} store={store}/>
            </MaterialFactory>
        </MuiPickersUtilsProvider>, element);
};

export const renderDossierDocuments = (dossier) => {
    sendGaEvent(GA_CATEGORY, 'Bewaren tab click', 'Documenten');
    const element = getEmptyRenderElement(dossier);
    ReactDOM.render(<DossierDocumentsController dossier={dossier} store={store}/>, element);
};

export const renderNotes = (dossier) => {
    sendGaEvent(GA_CATEGORY, 'Bewaren tab click', 'Notities');
    const element = getEmptyRenderElement(dossier);
    ReactDOM.render(<DossierNotesController dossier={dossier} store={store}/>, element);
};

export const renderContacts = (dossier) => {
    sendGaEvent(GA_CATEGORY, 'Tab click', 'Contacten');
    const element = getEmptyRenderElement(dossier);
    ReactDOM.render(<DossierContactsController dossier={dossier} store={store}/>, element);
};

export const renderCarValue = (dossier) => {
    sendGaEvent(GA_CATEGORY, 'Besparen tab click', 'Waarde');
    const element = getEmptyRenderElement(dossier);
    ReactDOM.render(<DossierCarValue dossier={dossier} store={store}/>, element);
};

export const renderOpstalAnalyze = (dossier) => {
    sendGaEvent(GA_CATEGORY, 'Besparen tab click', 'Opstal analyze');
    const element = getEmptyRenderElement(dossier);
    ReactDOM.render(<HomeInsurance dossier={dossier} store={store}/>, element);
};

const getEmptyRenderElement = (dossier) => {
    const element = document.getElementById('details' + dossier.id);
    ReactDOM.unmountComponentAtNode(element);
    if (element.classList.contains("detailNoBackground")) {
        element.classList.replace("detailNoBackground", "details");
    }
    return element;
};

export const showHouseValue = (dossier) => {
    sendGaEvent(GA_CATEGORY, 'Besparen tab click', 'Waarde');
    const element = getEmptyRenderElement(dossier);
    ReactDOM.render(<DossierHouseValue dossier={dossier} store={store}/>, element);
};

export const showCarValuePopup = (dossier) => {
    sendGaEvent(GA_CATEGORY, 'Besparen tab click', 'Waarde');
    const element = getEmptyRenderElement(dossier);
    ReactDOM.render(<DossierCarValue dossier={dossier} store={store}/>, element);
};

export const analyzeTravelInsurance = () => {
    window.open('https://www.bydehand.com/heb-jij-een-goede-reisverzekering/');
};

export const analyzeHealthInsurance = () => {
    window.open('https://www.bydehand.com/heb-jij-een-goede-zorgverzekering/');
};

export const startTenderOpstal = (dossier) => {
    startTender(dossier, 'Opstalverzekering');
};

export const startTenderTravelInsurance = (dossier) => {
    startTender(dossier, 'Reisverzekering');
};

export const startTenderInsurance = (dossier) => {
    startTender(dossier, 'Verzekering');
};

export const startTenderInboedel = (dossier) => {
    startTender(dossier, 'Inboedelverzekering');
};

export const startTenderCarInsurance = (dossier) => {
    startTender(dossier, 'Autoverzekering');
};

const startTender = (dossier, type) => {
    sendGaEvent(GA_CATEGORY, 'Besparen tab click', 'Offerte ' + type);
    getTenderInfo({
        id: dossier.id,
        callback: (response) => {
            const element = getEmptyRenderElement(dossier);
            ReactDOM.render(<RequestTenderController dossier={dossier} tenderInfo={response.data}
                                                     store={store}/>, element);
        }
    });
};