import './style/agendaPlanning.scss'

import React from 'react';
import PropTypes from 'prop-types';
import {Scrollbars} from 'react-custom-scrollbars';

import {deviceIsMobile} from 'glob-common-js/lib/utils';

export default class AgendaPlanning extends React.Component {
    constructor(props) {
        super(props);
    }

    onWheel = event => {
        if (event.deltaY !== 0 && !deviceIsMobile()) {
            // manually scroll horizontally instead of vertically
            this.scrollbar.scrollLeft(this.scrollbar.getValues().scrollLeft + event.deltaY);

            // prevent vertical scroll
            this.scrollbar.scrollToTop();
            event.preventDefault();
            event.stopPropagation();
        }
    };

    clickArrow = (evt, type) => {
        const itemSize = type === 'right' ? 140 : -140;
        this.scrollbar.scrollLeft(this.scrollbar.getValues().scrollLeft + itemSize);
        evt.target.classList.remove('pulsing');
    };

    toggleWheelEvent = (enable) => {
        const node = document.getElementById('agendaPlanningWheeler');
        if (isNotNull(node)) {
            const toggleListener = enable ? node.addEventListener || node.attachEvent :
                node.removeEventListener || node.detachEvent;
            toggleListener('onwheel', this.onWheel, {passive: true});
        }
    };

    renderPlanningItems = () => {
        return this.props.items.map((item, key) => (
            <div key={key} className='planningItem' onClick={() => {
                this.props.onClickItem(item);
            }}>
                <div className="planningImage"
                     style={{backgroundImage: 'url(' + require('Images/' + item.image) + ')'}}/>
                <label className='planningLabel'>{item.label}</label>
                <span className='common-icon-plus planningAdd'/>
            </div>
        ));
    };

    componentDidMount = () => {
        this.toggleWheelEvent();
    };

    componentWillUnmount = () => {
        this.toggleWheelEvent();
    };

    render = () => (
        <div id='agendaPlanningWheeler' style={{height: '100%'}}>
            <div className='common-icon-arrow-right navArrow right pulsing' onClick={(evt) => {
                this.clickArrow(evt, 'right')
            }}/>
            <div className='common-icon-arrow-left navArrow left pulsing' onClick={(evt) => {
                this.clickArrow(evt, 'left')
            }}/>
            <Scrollbars ref={refName => {
                this.scrollbar = refName
            }} style={{width: '100%', height: '100%'}}>
                <div className='agendaPlanning'>
                    {this.renderPlanningItems()}
                </div>
            </Scrollbars>
        </div>
    );
}

AgendaPlanning.propTypes = {
    onClickItem: PropTypes.func.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
    })).isRequired,
};