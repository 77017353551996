import './style/errorBoundary.scss'

import React from 'react';
import PropTypes from 'prop-types';

import {logToSentry} from '../misc/utils';

export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
        }
    }

    changeState = (stateChange) => {
        if (this._isMounted) {
            this.setState(stateChange);
        }
    };

    componentDidCatch = (error, info) => {
        this.changeState({hasError: true});
        logToSentry(error, info);
    };

    componentDidMount = () => {
        this._isMounted = true;
    };

    componentWillUnmount = () => {
        this._isMounted = false;
    };

    render = () => {
        if (this.state.hasError) {
            return (
                <div className='errorBoundary'>
                    <h1>Er is iets fout gegaan</h1>
                    <div className="line">
                        <p className='text'>We doen ons best om het probleem zo snel mogelijk op te lossen.</p>
                    </div>
                    <div className="line">
                        <p className='text'>Excuses voor het ongemak.</p>
                    </div>
                    <div className="line">
                        <a className='text' href="https://www.bydehand.com/contact/">Neem contact op</a>
                    </div>
                    <div className="line">
                        <label className='text'>
                            Of ga <a className='text' href='https://www.bydehand.com'>terug</a> naar de homepage
                        </label>
                    </div>
                </div>
            )
        }
        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    changeHeaderProps: PropTypes.func,
};

export default ErrorBoundary;