import React from 'react';
import ReactDOM from 'react-dom';
import utils, {isNotNull} from 'glob-common-js/lib/utils';
import Popup from './popup';

const getValueFromId = id => {
    if (utils.isNotNull(id)) {
        return document.getElementById(id).value;
    }
    return null;
};

const closeDocumentModalListener = () => {
    const documentChooser = document.getElementsByClassName('documentModal')[0];
    if (utils.isNotNull(documentChooser)) {
        if (documentChooser.parentElement.querySelector(':hover') !== documentChooser) {
            closeDocumentChooser();
        }
    }
};

const openDocumentChooser = () => {
    document.getElementById('documentInput').value = null;
    document.getElementById('documentName').value = null;
    const chooser = document.getElementsByClassName('documentModal')[0];
    if (!chooser.classList.contains('show')) {
        chooser.classList.add('show');
        chooser.style.top = '50%';
        chooser.style.transform = 'translateX(-50%) translateY(-50%)';

        // Add the background
        const background = document.createElement('div');
        background.classList.add('modal-backdrop', 'show', 'whiteBackground');
        document.getElementsByTagName('body')[0].appendChild(background);
        document.addEventListener('click', closeDocumentModalListener);
    }
};

const closeDocumentChooser = () => {
    document.getElementById('uploadSave').disabled = true;
    document.getElementById('documentInput').value = null;
    document.getElementById('documentName').value = null;

    const chooser = document.getElementsByClassName('documentModal')[0];
    chooser.classList.remove('show');
    const backDrop = document.getElementsByClassName('modal-backdrop')[0];
    backDrop.parentNode.removeChild(backDrop);
    document.getElementById('step2&3').classList.add('opacityLow');
    document.removeEventListener('click', closeDocumentModalListener);
};

const showPopup = (title, message) => {
    const div = document.createElement('div');
    div.id = 'popupDiv';
    document.getElementsByTagName('body')[0].appendChild(div);
    const popup = <Popup title={title} message={message}/>;
    ReactDOM.render(popup, div);
    window.setTimeout(function () {
        div.parentNode.removeChild(div);
    }, 5000);
};

export const hasHover = element => {
    if (isNotNull(element) && isNotNull(element.parentElement)) {
        return element.parentElement.querySelector(':hover') === element;
    }
    return false;
};

let ReactUtils = {
    getValueFromId: getValueFromId,
    openDocumentChooser: openDocumentChooser,
    closeDocumentChooser: closeDocumentChooser,
    showPopup: showPopup,
    hasHover: hasHover,
};

export default ReactUtils;