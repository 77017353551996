/**
 *  Component for displaying multiple types of additional insurances, which may have their own
 *  behaviour and restrictions.
 */
import './style/additionalSelect.scss';

import React from 'react';
import PropTypes from 'prop-types';

import StateComponent from "../../../misc/stateComponent";

export default class AdditionalSelectView extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
        };

        this.clickListener = () => {
            const dropdown = this.getElementsByClassName('additionals')[0];
            if (pathIsNotNull(dropdown, 'parentElement')
                && dropdown.parentElement.querySelector(':hover') !== dropdown) {
                this.changeState({active: false});
            }
        }
    }

    getElementById = id => {
        return document.getElementById(this.props.id + id);
    };

    getElementsByClassName = className => {
        const element = this.getElementById('');
        if (isNotNull(element))
            return this.getElementById('').getElementsByClassName(className);
        return [];
    };

    showError = () => {
        let errors = this.getElementsByClassName('customErrors')[0];
        if (isNotNull(errors)) {
            errors.classList.remove('hidden');
            errors.scrollIntoView();
        }
    };

    hideError = () => {
        let errors = this.getElementsByClassName('customErrors')[0];
        if (isNotNull(errors)) {
            errors.classList.add('hidden');
        }
    };

    onSelectDefault = (additional) => {
        this.deselectSelectedDefault(true);
        this.setSelectionText(additional.label);
        this.hideError();
        this.changeState({active: false});

        let additionalName = additional.name;
        if (additionalName === 'empty') {
            this.setSelectionText('Geen');
            this.props.onSelectDefault(null);
            let selected = this.getElementById('additional' + additional.name);
            if (isNotNull(selected)) {
                selected.classList.add('orange');
            }
        } else {
            // If name === empty, just clear the selection
            let emptySelected = document.querySelector('.orange');
            isNotNull(emptySelected) ? emptySelected.classList.remove('orange') : null;

            let selected = this.getElementById('additional' + additional.name);
            if (isNotNull(selected)) {
                selected.classList.add('selected');
            }
            this.props.onSelectDefault(additional);
        }
    };

    setSelectionText = text => {
        let selectionText = this.getElementById('selectionText');
        if (isNotNull(selectionText)) {
            selectionText.innerHTML = text;
        }
    };

    removeSelection = () => {
        this.deselectSelectedDefault(true);
        this.setSelectionText(this.props.selectText);
    };

    onGroupSelect = (additional, group, evt) => {
        this.deselectSelectedDefault(false);
        this.deselectFromSameGroup(group);
        this.hideError();

        let {shouldBeSelected, amountSelected} = this.props.onSelectGroup(additional, group, evt);
        let selectionText;
        if (amountSelected > 0) {
            selectionText = amountSelected + ' module' + (amountSelected > 1 ? 's' : '') + ' geselecteerd';
        } else {
            selectionText = this.props.selectText;
        }
        this.setSelectionText(selectionText);

        let additionalContainer = this.getElementById('additional' + additional.name);
        if (isNotNull(additionalContainer)) {
            // Check the checkbox if needed (must be done this way since the boxes are radio buttons)
            this.getElementById('check' + additional.name).checked = shouldBeSelected;
            if (shouldBeSelected) {
                additionalContainer.classList.add('selected');
            } else {
                additionalContainer.classList.remove('selected');
            }

            // If the insurance is no longer selected, reset the discount value to 0.0%
            if (!shouldBeSelected) {
                let discountInput = additionalContainer.getElementsByClassName('additionalDiscount')[0];
                if (isNotNull(discountInput)) {
                    discountInput.value = 0.0;
                }
            }
        }
    };

    deselectFromSameGroup = (group) => {
        let selectedGroupInsurances = this.getElementsByClassName('additionalContainer group selected');
        // let selectedGroupInsurances = document.querySelectorAll('div.additionalContainer.group.selected');
        for (let i = 0; i < selectedGroupInsurances.length; i++) {
            let selectedInsurance = selectedGroupInsurances[i];
            if (selectedInsurance.dataset.group === group) {
                selectedInsurance.classList.remove('selected');
            }
        }
    };

    onSelectClick = () => {
        this.changeState({
            active: !this.state.active,
        })
    };

    deselectSelectedDefault = (defaultClicked) => {
        let selectedDefault = this.getElementsByClassName('additionalContainer default selected')[0];
        // let selectedDefault = document.querySelector('div.additionalContainer.default.selected');
        if (isNotNull(selectedDefault)) {
            selectedDefault.classList.remove('selected');

            // Reset discount value
            let discountInput = selectedDefault.getElementsByClassName('additionalDiscount')[0];
            if (isNotNull(discountInput)) {
                discountInput.value = 0.0;
            }
        }

        // If clicked insurance is from type default, also deselect any selected group insurances
        if (defaultClicked) {
            let selectedGroups = this.getElementsByClassName('additionalContainer group selected');
            let amountSelected = selectedGroups.length;
            for (let i = 0; i < amountSelected; i++) {
                // Always need to get index 0, since remove the selected class from an element also removes it from the list.
                let selectedGroup = selectedGroups[0];
                if (isNotNull(selectedGroup)) {
                    let checkbox = selectedGroup.getElementsByClassName('groupBoxes')[0];
                    let discountInput = selectedGroup.getElementsByClassName('additionalDiscount')[0];

                    // Deselect insurance and its radiobutton
                    selectedGroup.classList.remove('selected');
                    if (isNotNull(discountInput)) {
                        discountInput.value = 0.0;
                    }
                    if (isNotNull(checkbox)) {
                        checkbox.checked = false;
                    }
                }
            }
        }
    };

    renderDefaultAdditionals = () => {
        let defaultAdditionals = this.props.defaultAdditionals;
        let hasDefaultSelected = isNotNull(this.props.defaultSelected) && typeof this.props.defaultSelected === 'object'
            && !Array.isArray(this.props.defaultSelected);
        if (isNotNull(defaultAdditionals)) {
            return (
                <div className="defaultAdditionals">
                    <div className="additionalHeaderContainer">
                        <label className="selectHeader">{this.props.defaultSelectLabel}</label>
                    </div>
                    {defaultAdditionals.map((additional, key) => (
                        <div key={key} id={this.props.id + 'additional' + additional.name}
                             className={'additionalContainer default' + (hasDefaultSelected &&
                             additional.label === this.props.defaultSelected.label ? ' selected' : '')}
                             onClick={(evt) => {
                                 this.onSelectDefault(additional, evt);
                             }}>
                            <div className="defaultAdditional">
                                <label>{additional.label}</label>
                            </div>
                        </div>
                    ))}
                </div>
            );
        }
        return null;
    };

    renderGroupAdditionals = () => {
        let groupAdditionals = this.props.groupAdditionals;
        if (areNotNull(groupAdditionals, Object.keys(groupAdditionals))) {
            return (
                <div className="groupAdditionals">
                    <div className="additionalHeaderContainer">
                        <label className="selectHeader">Losse modules</label>
                    </div>
                    {Object.keys(groupAdditionals).map((key, mapKey) => {
                        let group = groupAdditionals[key];
                        return this.createGroup(group, mapKey);
                    })}
                </div>
            );
        }
        return null;
    };

    createGroup = (group, key) => {
        let groupName = group.name;
        let additionals = group.additionals;
        if (isNotNull(additionals)) {
            return (
                <div key={key} className="group">
                    <div className="additionalHeaderContainer">
                        <label className="groupName">{groupName}</label>
                    </div>
                    {additionals.map((additional, key) => (this.createGroupAdditional(additional, group, key)))}
                </div>
            );
        }
        return null;
    };

    createGroupAdditional = (additional, group, key) => {
        let groupName = group.name;
        let hasDefaultSelected = isNotNull(this.props.defaultSelected) && Array.isArray(this.props.defaultSelected);
        let isDefaultSelected = false;
        let defaultSelected;
        if (hasDefaultSelected) {
            let defaultAdditionals = this.props.defaultSelected;
            for (let i = 0; i < defaultAdditionals.length; i++) {
                let defaultAdditional = defaultAdditionals[i];
                if (defaultAdditional.label === additional.name) {
                    isDefaultSelected = true;
                    defaultSelected = defaultAdditional;
                    break;
                }
            }
        }
        return (
            <div data-group={groupName} key={key} id={this.props.id + 'additional' + additional.name}
                 className={'additionalContainer group' + (isDefaultSelected ? ' selected' : '')}>
                <div className="groupAdditional">
                    <input id={this.props.id + 'check' + additional.name}
                           className='condition-checkbox groupBoxes'
                           type='radio'
                           name={groupName} onClick={() => {
                        this.onGroupSelect(additional, groupName);
                    }} defaultChecked={isDefaultSelected}/>
                    <label
                        htmlFor={this.props.id + 'check' + additional.name}>{additional.label}</label>
                </div>
            </div>
        );
    };

    componentDidUpdate = () => {
        if (this.state.active) {
            document.addEventListener('click', this.clickListener);
        } else {
            document.removeEventListener('click', this.clickListener);
        }
    };

    componentDidMount = () => {
        this.mount();
    };

    componentWillUnmount = () => {
        this.unMount();
    };

    render = () => {
        return (
            <div id={this.props.id} className="fieldGroup">
                <label className="fieldLabel withErrors">{this.props.fieldLabel}</label>
                <div className="customErrors hidden">Selecteer een optie</div>
                <div className='additionalSelectContainer'>
                    <div className="additionalSelect">
                        <div className={'selectionBox' + (this.state.active ? ' active' : '')}
                             onClick={this.onSelectClick}>
                            <label id={this.props.id + 'selectionText'}
                                   className='selectionText'>{this.props.initialSelectText}</label>
                        </div>
                        <div className={'additionals' + (this.state.active ? ' active' : '')}>
                            {this.renderDefaultAdditionals()}
                            {this.renderGroupAdditionals()}
                        </div>
                    </div>
                    <div className='discountContainer'>
                        <span>Korting:</span>
                        <input id={this.props.id + 'discount'} className='additionalDiscount' type="number" step="0.01"
                               defaultValue={this.props.defaultDiscount.value} min={0.00}/>
                        <select id={this.props.id + 'discountType'} className={'discountTypeSelector'}
                                defaultValue={this.props.defaultDiscount.type}>
                            <option value={''}>%</option>
                            <option value={'EURO'}>&euro;</option>
                        </select>
                    </div>
                </div>
            </div>
        );
    }
}

AdditionalSelectView.propTypes = {
    defaultAdditionals: PropTypes.arrayOf(PropTypes.object).isRequired,
    groupAdditionals: PropTypes.object.isRequired,
    onSelectDefault: PropTypes.func.isRequired,
    onSelectGroup: PropTypes.func.isRequired,
    fieldLabel: PropTypes.string.isRequired,
    selectText: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    defaultSelectLabel: PropTypes.string.isRequired,
    defaultSelected: PropTypes.oneOfType([
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            discount: PropTypes.number.isRequired,
        }),
        PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string.isRequired,
            discount: PropTypes.number.isRequired,
        })),
    ]),
    initialSelectText: PropTypes.string.isRequired,
    defaultDiscount: PropTypes.shape({
        value: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
    }),
};