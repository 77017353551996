import {getCookie, getSetting, setCookie, debug} from 'glob-common-js/lib/utils';
import {store} from "../../startup";
import {TEST_ACCOUNTS} from "../../misc/cookieUtil";
import {getParsedJwt} from "./platform";

let trackingId = undefined;
const notSet = '(not set)';
let gaClientId = notSet;

let intervalTime = 5000;
let intervalId;

const init = trackId => {
    trackingId = trackId;
    // Load ga, use an interval to initialize ga with the tracking id after loading is completed
    if (gaLoaded()) {
        if (isNotNull(intervalId)) {
            clearInterval(intervalId);
        }
        ga('create', trackingId, 'auto');

        ga(function (tracker) {
            // Send the GA data through our own server if its being blocked by ad blockers.
            // Ref: https://stackoverflow.com/questions/29119361/google-analytics-proxy
            // The method below will override the default send method such that we can send the GA data to our own server that will
            // proxy the data to GA.
            let sub = getSetting('mode') === 'prod' ? 'www' : 'test';
            tracker.set('sendHitTask', function (model) {
                let payLoad = model.get('hitPayload');
                let request = new XMLHttpRequest();
                let req = 'https://' + sub + '.bydehand.com/analytics/collect' + '?' + payLoad + '&ua=' + encodeURIComponent(navigator.userAgent);
                request.open('GET', req, true);
                request.send();
            });
        });
    } else {
        loadGA();
        intervalId = window.setInterval(() => {
            init(trackingId)
        }, intervalTime);
    }
};

export const sendPageView = (customLocation = undefined) => {
    if (gaInitialized()) {
        if (isNotNull(customLocation)) {
            ga('send', 'pageview', customLocation);
        } else {
            ga('send', 'pageview');
        }
    } else throw new Error('Google Analytics has not yet been initialized. Please use the init(trackId) function to ' +
        'initialize Google Analytics.');
};

export const sendGaEvent = (category, action, label) => {
    if (gaInitialized()) {
        let isTestAccount = false;
        const user = store.getState().user.user;
        if (isNotNull(user)) {
            const {email} = user;
            for (let i = 0; i < TEST_ACCOUNTS.length; i++) {
                if (TEST_ACCOUNTS[i].test(email)) {
                    isTestAccount = true;
                    break;
                }
            }
        }
        if (isTestAccount && !getSetting('forceGA'))
            debug('Blocked GA event for test user: ', {category, action, label});
        else {
            addGACustomDimensions();
            ga('send', 'event', category, action, label);
        }
    } else throw new Error('Google Analytics has not yet been initialized. Please use the init(trackId) function to ' +
        'initialize Google Analytics.');
};

const addGACustomDimensions = () => {
    ga('set', 'dimension1', createUserId());
    ga('set', 'dimension2', createTimeStamp());
    ga('set', 'dimension3', createClientID());
    ga('set', 'dimension4', createSessionID());
};


const createUserId = () => {
    let jwt = getParsedJwt();
    if (isNotNull(jwt)) {
        let userId = jwt.sub;
        if (isNotNull(userId)) {
            return userId;
        }
    }
    return notSet;
};

const createTimeStamp = () => {
    let now = new Date();
    const pad = (value) => {
        return ("0" + value).slice(-2)
    };
    return now.getFullYear() + '-' + pad(now.getMonth() + 1) + '-' + pad(now.getDate()) + ' ' + pad(now.getHours()) + ':' + pad(now.getMinutes()) + ':' + pad(now.getSeconds());
};

const createClientID = () => {
    if (gaClientId === notSet) {
        try {
            let trackers = ga.getAll();
            gaClientId = trackers[0].get('clientId');
            return gaClientId;
        } catch (ex) {
            // GA probably blocked by user.
        }
    }
    return gaClientId;
};

const createSessionID = () => {
    let sessionId = getCookie("sessionId");
    if (sessionId === undefined) {
        sessionId = new Date().getTime() + '.' + Math.random().toString(36).substring(5);
        setCookie("sessionId", sessionId);
    }
    return sessionId;
};

const gaInitialized = () => (gaLoaded() && isNotNull(trackingId));

const gaLoaded = () => (window.ga);

const loadGA = () => {
    const loadFunction = (i, s, o, g, r, a, m) => {
        i['GoogleAnalyticsObject'] = r;
        i[r] = i[r] || function () {
            (i[r].q = i[r].q || []).push(arguments)
        }, i[r].l = 1 * new Date();
        a = s.createElement(o),
            m = s.getElementsByTagName(o)[0];
        a.async = 1;
        a.src = g;
        m.parentNode.insertBefore(a, m)
    };
    // We load our own GA js, to overcome it's being blocked by ad-blockers.
    loadFunction(window, document, 'script', '//pub.bydehand.com/ana-lyti-cs.cache.js', 'ga');
};

const analytics = {
    init: init,
    sendPageView: sendPageView,
    sendGaEvent: sendGaEvent
};

export default analytics;
