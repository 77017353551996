import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as Actions from '../../../redux/actions';

import getDossierType, {getById} from 'BdhCommon/data/mappings/dossierTypeMapping';
import getCategoryType from 'BdhCommon/data/mappings/categoryMapping';
import DossierDashboardContainer from "./dossierDashboardContainer";
import {getAllDossiers} from "../../../misc/requestSender";
import {addCustomEventListener, removeCustomEventListener} from "../../../misc/eventDispatcher";
import StateComponent from "../../misc/stateComponent";

export class DossierDashboardContainerController extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            dossiers: [],
            dosFetching: true,
            dossiersLoaded: 0,
        }
    }

    getUserDossiers = () => {
        this.changeState({dosFechting: true});
        let selectedModule = this.props.moduleState.selectedModule;
        let queryParams = 'order=created_at';
        if (isNotNull(selectedModule)) {
            let category = getCategoryType(selectedModule);
            let dossierTypeIds = category.dosTypes.map(dosType => getDossierType(dosType).id);
            queryParams = queryParams + '&filter=type_id&value=' + dossierTypeIds;
        }
        getAllDossiers({
            queryParams, asInstance: true,
            callback: (dossiers) => {
                log('Found dossiers', dossiers);
                dossiers = dossiers.filter(dossier => {
                    return !dossier.type.config.exclude &&
                        (isNull(dossier.externalData.isDossier) || dossier.externalData.isDossier);
                });
                dossiers = dossiers.map(dossier => {
                    if (isNull(dossier.name))
                        dossier.name = dossier.type.label;
                    return dossier;
                });
                this.changeState({
                    dosFetching: false,
                    dossiers: dossiers.slice(0, 4),
                    dossiersLoaded: 0,
                });
            }
        });
    };

    componentDidMount = () => {
        this.mount();
        this.getUserDossiers();
        addCustomEventListener('updatePlatform', this.getUserDossiers);
    };

    componentWillUnmount = () => {
        this.unMount();
        removeCustomEventListener('updatePlatform', this.getUserDossiers);
    };

    render = () => {
        const {dosFetching, dossiers} = this.state, {actions, moduleState} = this.props;
        return (
            <DossierDashboardContainer dosFetching={dosFetching} dossiers={dossiers}
                                       setReduxDossier={actions.setDossier} selectedModule={moduleState.selectedModule}
                                       activeModules={moduleState.activeModules}/>
        );
    };
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});

const mapStateToProps = state => ({
    moduleState: state.module,
});

export default connect(mapStateToProps, mapDispatchToProps)(DossierDashboardContainerController);
