import OnboardingManager, {getNextStep, getPrevStep} from "./onboardingManager";
import {dispatchCustomEvent} from "../../../misc/eventDispatcher";
import {sendGaEvent} from "../../../common/js/ga";

export const saveOnboardingSteps = {
    INTRO_STEP: 'INTRO_STEP',
    SELECT_ANALYSIS: 'SELECT_ANALYSIS',
    CONFIRM_START: 'CONFIRM_START',
    NOTIFY_HELP: 'NOTIFY_HELP',
};

const GA_CATEGORY = 'Onboarding besparen', eventName = 'saveOnboardingFlow';

export default class SaveManager extends OnboardingManager {
    #type = 'save';
    #step = saveOnboardingSteps.INTRO_STEP;
    #defaultTop = 0;
    #scrolling = false;
    #scrollId = '';

    getType = () => this.#type;

    setStep = step => {
        if (this.isActive()) {
            const old = this.#step;
            this.#step = step;
            this.#onStepChange(old);
        } else this.warnNotActive();
    };

    setNextStep = (params) => {
        if (this.isActive()) {
            const oldStep = this.#step;
            this.#step = getNextStep(saveOnboardingSteps, this.#step);
            this.#onStepChange(oldStep, params);
        } else this.warnNotActive();
    };

    setPrevStep = () => {
        if (this.isActive()) {
            const oldStep = this.#step;
            this.#step = getPrevStep(saveOnboardingSteps, this.#step);
            this.#onStepChange(oldStep);
        } else this.warnNotActive();
    };

    getActiveStep = () => {
        return this.#step;
    };

    start = () => {
        if (this.isActive()) {
            dispatchCustomEvent(eventName, {type: 'activate'});
        } else this.warnNotActive();
    };

    addScrollListener = (defaultTop, id) => {
        this.#defaultTop = remToPx(defaultTop);
        this.#scrollId = id;
        window.addEventListener('scroll', this.scrollListener);
    };

    removeScrollListener = () => {
        if (isNotNull(this.#scrollId)) {
            const element = document.getElementById(this.#scrollId);
            if (isNotNull(element)) element.style.removeProperty('top');
            this.#scrollId = null;
        }
        this.#defaultTop = 0;
        window.removeEventListener('scroll', this.scrollListener);
    };

    scrollListener = evt => {
        if (!this.#scrolling) {
            window.requestAnimationFrame(() => {
                const popup = document.getElementById(this.#scrollId);
                if (isNotNull(popup)) {
                    const scrollPosition = window.scrollY, adjustedTop = this.#defaultTop - scrollPosition;
                    popup.style.top = adjustedTop + 'px';
                }
                this.#scrolling = false;
            });
            this.#scrolling = true;
        }
    };

    sendEvent = (action, label) => {
        sendGaEvent(GA_CATEGORY, action, label);
    };

    #onStepChange = (oldStep, params = null) => {
        this.clearHighlightIds();
        this.removeScrollListener();
        dispatchCustomEvent(eventName, {type: 'setStep', data: {step: this.#step}});
        switch (this.#step) {
            case saveOnboardingSteps.CONFIRM_START:
                dispatchCustomEvent(eventName, {type: 'enableTabNext'});
                break;
            case false:
                dispatchCustomEvent(eventName, {type: 'deactivate'});
                this.cleanup();
                break;
        }
    };
}