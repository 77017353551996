import React from 'react';
import StateComponent from "../misc/stateComponent";
import {getAllDossiers} from "../../misc/requestSender";
import getModules from "../../common/data/mappings/moduleMapping";
import getDossierType, {getById} from "../../common/data/mappings/dossierTypeMapping";
import getCategoryType from "../../common/data/mappings/categoryMapping";
import OverviewView from "./overviewView";
import {navigate} from "../../misc/utils";

export default class OverviewController extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            tiles: [],
        }
    }

    onClickTile = name => {
        navigate('/dossier-overzicht?category=' + name, true);
    };

    loadData = () => {
        getAllDossiers({
            callback: this.divideCategories,
        })
    };

    divideCategories = (dossiers) => {
        const categories = getModules();
        const sorted = [];
        forEach(categories, category => {
            const name = category.name;
            const categoryType = getCategoryType(name);
            const dossierTypes = categoryType.dosTypes;
            const dossierTypeIds = dossierTypes.map(type => getDossierType(type).id);
            dossiers = dossiers.filter(dossier => {
                if (isNull(dossier.external_data) || isNull(dossier.external_data.isDossier)) return true;
                return dossier.external_data.isDossier && getById(dossier.type_id).name !== 'financial_overview';
            });
            const sortedDossiers = dossiers.filter(dossier => dossierTypeIds.includes(dossier.type_id));
            sorted.push({
                name,
                label: categoryType.label,
                icon: categoryType.icon,
                dossierCount: sortedDossiers.length,
            });
        });
        this.changeState({tiles: sorted});
    };

    componentDidMount = () => {
        this.mount();
        this.loadData();
    };

    componentWillUnmount = () => {
        this.unMount();
    };

    render = () => {
        const {tiles} = this.state;
        return <OverviewView onClickTile={this.onClickTile} tiles={tiles}/>
    };
}