import checklistTypes from "../../../components/checklist/checklistTypes";

export default {
    name: 'Levenstestament',
    icon: 'common-icon-document-2',
    route: '/levenstestament-checklist',
    type: checklistTypes.LIFETESTAMENT,
    GA_CATEGORY: 'Levenstestament checklist',
    categories: {
        businessperson: {
            name: 'businessperson',
            label: 'Uw vertrouwenspersoon - zakelijk',
            icon: 'common-icon-document-2',
            boxes: [
                {
                    name: 'whotrust',
                    label: 'Wie vertrouwt u uw zakelijke belangen toe?',
                    documentType: null,
                    link: null,
                    valueType: 'string',
                    valueLabel: 'naam',
                },
                {
                    name: 'replaceperson',
                    label: 'Wie zou uw vertrouwenspersoon kunnen vervangen als dat nodig mocht zijn?',
                    documentType: null,
                    link: null,
                    valueType: 'string',
                    valueLabel: 'naam',
                },
                {
                    name: 'moretrust',
                    label: 'Als u meer vertrouwenspersonen wilt benoemen, denk dan na over de taakverdeling',
                    documentType: null,
                    link: null,
                },
            ],
        },
        expectationbusinessperson: {
            name: 'expectationbusinessperson',
            label: 'Rol vertrouwenspersoon - zakelijk',
            icon: 'common-icon-document-2',
            boxes: [
                {
                    name: 'savings',
                    label: 'Uw spaargeld en vermogen beheren',
                    documentType: null,
                    link: null,
                    valueType: 'bool',
                },
                {
                    name: 'tax',
                    label: 'Uw belastingaangifte regelen',
                    documentType: null,
                    link: null,
                    valueType: 'bool',
                },
                {
                    name: 'pgb',
                    label: 'Een persoonsgebonden budget (PGB) beheren',
                    documentType: null,
                    link: null,
                    valueType: 'bool',
                },
                {
                    name: 'inheritance',
                    label: 'Schenken aan uw kinderen en kleinkinderen ',
                    documentType: null,
                    valueType: 'bool',
                    link: {
                        label: 'Meer over schenken',
                        url: 'https://www.notaris.nl/schenken',
                    },
                },
                {
                    name: 'charities',
                    label: 'Namens u schenken aan goede doelen',
                    documentType: null,
                    link: null,
                    valueType: 'bool',
                },
            ],
        },
        businessinterest: {
            name: 'businessinterest',
            label: 'uw zakelijke belangen',
            icon: 'common-icon-document-2',
            boxes: [
                {
                    name: 'savings2',
                    label: 'Spaargeld en effecten',
                    documentType: null,
                    link: null,
                    valueType: 'bool',
                },
                {
                    name: 'House',
                    label: 'Eigen woning',
                    documentType: null,
                    link: null,
                    valueType: 'bool',
                },
                {
                    name: 'Vacationhouse',
                    label: 'Vakantiewoning',
                    documentType: null,
                    link: null,
                    valueType: 'bool',
                },
                {
                    name: 'claims',
                    label: 'Vorderingen',
                    documentType: null,
                    link: null,
                    valueType: 'bool',
                },
                {
                    name: 'debts',
                    label: 'Schulden',
                    documentType: null,
                    link: null,
                    valueType: 'bool',
                },
                {
                    name: 'tax2',
                    label: 'belastingaangifte',
                    documentType: null,
                    link: null,
                    valueType: 'bool',
                },
                {
                    name: 'pension',
                    label: 'Pensioenen',
                    documentType: null,
                    valueType: 'bool',
                    link: {
                        label: 'Mijn pensioenoverzicht',
                        url: 'https://www.mijnpensioenoverzicht.nl/',
                    },
                },
                {
                    name: 'other',
                    label: 'Anders',
                    documentType: null,
                    link: null,
                    valueType: 'string',
                    valueLabel: 'toelichting...',
                },
            ],
        },
        personalperson: {
            name: 'personalperson',
            label: 'Uw vertrouwenspersoon - persoonlijk',
            icon: 'common-icon-document-2',
            boxes: [
                {
                  name: 'personalwhotrust',
                  label: 'Wie vertrouwt u uw medische en persoonlijke belangen toe?',
                  documentType: null,
                  link: null,
                  valueType: 'string',
                  valueLabel: 'naam',
                },
                {
                  name: 'personalreplaceperson',
                  label: 'Wie zou uw vertrouwenspersoon kunnen vervangen als dat nodig mocht zijn?',
                  documentType: null,
                  link: null,
                  valueType: 'string',
                  valueLabel: 'naam',
                },
            ],
        },
        advancedirective: {
            name: 'advancedirective',
            label: 'Uw wilsverklaring',
            icon: 'common-icon-document-2',
            boxes: [
                {
                  name: 'notreat',
                  label: 'Ik heb of wil een niet-behandelverklaring',
                  documentType: null,
                  valueType: 'bool',
                  link: {
                      label: 'meer informatie',
                      url: 'https://levenseinde.patientenfederatie.nl',
                  },
                },
                {
                  name: 'euthanasia',
                  label: 'Ik heb of wil een euthenasieverzoek',
                  documentType: null,
                  valueType: 'bool',
                  link: null,
                },
                {
                  name: 'dementia',
                  label: 'Ik heb of wil een dementieverklaring',
                  documentType: null,
                  valueType: 'bool',
                  link: null,
                },
                {
                  name: 'lifewish',
                  label: 'Ik heb of wil een NPV-levenswensverklaring',
                  documentType: null,
                  valueType: 'bool',
                  link: {
                      label: 'meer informatie',
                      url: 'http://npvzorg.nl/levenswensverklaring',
                  },
                },
                {
                  name: 'donor',
                  label: 'Ik heb of wil een donorcodicil',
                  documentType: null,
                  valueType: 'bool',
                  link: {
                      label: 'meer informatie',
                      url: 'https://www.rijksoverheid.nl/onderwerpen/orgaandonatie-en-weefseldonatie',
                  },
                },
                {
                  name: 'science',
                  label: 'Ik wil mijn lichaam ter beschikking stellen aan de wetenschap',
                  documentType: null,
                  valueType: 'bool',
                  link: null,
                },
            ],
        },
        personalinterests: {
            name: 'personalinterests',
            label: 'Uw persoonlijke belangen',
            icon: 'common-icon-document-2',
            boxes: [
                {
                  name: 'personalcare',
                  label: 'Heeft u wensen voor uw persoonlijke verzorging?',
                  documentType: null,
                  link: null,
                  valueType: 'bool',
                },
                {
                  name: 'pets',
                  label: 'Heeft u wensen voor uw huisdieren?',
                  documentType: null,
                  link: null,
                  valueType: 'bool',
                },
                {
                  name: 'activities',
                  label: 'Wilt u een aantal activiteiten zo lang mogelijk behouden?',
                  documentType: null,
                  link: null,
                  valueType: 'bool',
                },
                {
                  name: 'religious',
                  label: 'Heeft u wensen op religieus gebied?',
                  documentType: null,
                  link: null,
                  valueType: 'bool',
                },
                {
                  name: 'nursing',
                  label: 'Heeft u wensen wanneer u naar een verzorgingshuis of een verpleeghuis gaat?',
                  documentType: null,
                  link: null,
                  valueType: 'bool',
                },
                {
                  name: 'funeral',
                  label: 'Heeft u wensen voor uw uitvaart?',
                  documentType: null,
                  link: null,
                  valueType: 'bool',

                },
            ],
        },
        reward: {
            name: 'reward',
            label: 'Beloning vertrouwenspersoon',
            icon: 'common-icon-document-2',
            boxes: [
                {
                  name: 'cost',
                  label: 'Wilt u de kosten die uw vertrouwenspersoon maakt vergoeden?',
                  documentType: null,
                  link: null,
                  valueType: 'bool',
                },
                {
                  name: 'rewarding',
                  label: 'Wilt u uw vertrouwenspersoon een beloning geven?',
                  documentType: null,
                  link: null,
                  valueType: 'bool',
                },
            ],
        },
        supervision: {
            name: 'supervision',
            label: 'Toezicht - rekening en verantwoording',
            icon: 'common-icon-document-2',
            boxes: [
                {
                  name: 'supervisionwho',
                  label: 'Aan wie moet uw vertrouwenspersoon rekening en verantwoording afleggen als u zelf niet meer in staat bent om dat te overzien?',
                  documentType: null,
                  link: null,
                  valueType: 'string',
                  valueLabel: 'naam',
                },
                {
                  name: 'supervisionwhen',
                  label: 'Hoe vaak wilt u dat verantwoording wordt afgelegd?',
                  documentType: null,
                  link: null,
                  valueType: 'string',
                  valueLabel: 'frequentie',
                },
                {
                  name: 'supervisionwhat',
                  label: 'Weet u wat er in de rekening en verantwoording moet staan?',
                  documentType: null,
                  link: null,
                  valueType: 'bool',
                },
                {
                  name: 'supervisioncost',
                  label: 'Betaalt u de kosten van de toezichthouder?',
                  documentType: null,
                  link: null,
                  valueType: 'bool',
                },
                {
                  name: 'supervisionspecialist',
                  label: 'Betaalt u de kosten van advies van deskundigen?',
                  documentType: null,
                  link: null,
                  valueType: 'bool',
                },
            ],
        },
        consultation: {
            name: 'consultation',
            label: 'Toezicht op uitvoering, vooraf overleg',
            icon: 'common-icon-document-2',
            boxes: [
                {
                  name: 'consultationwho',
                  label: 'Wilt u dat de vertrouwenspersoon eerst overlegt met een familielid?',
                  documentType: null,
                  link: null,
                  valueType: 'string',
                  valueLabel: 'naam',
                },
                {
                  name: 'consultationexpenses',
                  label: 'Overleg als uitgaven boven een bepaald bedrag?',
                  documentType: null,
                  link: null,
                  valueType: 'bool',
                },
                {
                  name: 'consultationcare',
                  label: 'Overleg over inkoop zorg?',
                  documentType: null,
                  link: null,
                  valueType: 'bool',
                },
                {
                  name: 'consultationnursing',
                  label: 'Overleg over opname in verzorg- of verpleeghuis?',
                  documentType: null,
                  link: null,
                  valueType: 'bool',
                },
                {
                  name: 'consultationhome',
                  label: 'Overleg over aanpassing woning?',
                  documentType: null,
                  link: null,
                  valueType: 'bool',
                },
                {
                  name: 'consultationsell',
                  label: 'Overleg over verkoop woning?',
                  documentType: null,
                  link: null,
                  valueType: 'bool',
                },
                {
                  name: 'consultationtax',
                  label: 'Overleg over belastingbesparende maatregelen, schenkingen?',
                  documentType: null,
                  link: null,
                  valueType: 'bool',
                },
                {
                  name: 'consultationother',
                  label: 'anders...',
                  documentType: null,
                  link: null,
                  valueType: 'string',
                  valueLabel: 'vul in...',
                },
            ],
        },
        insight: {
            name: 'insight',
            label: 'Wie mag uw levenstestament inzien?',
            icon: 'common-icon-document-2',
            boxes: [
                {
                  name: 'insightbank',
                  label: 'Mijn Bank',
                  documentType: null,
                  link: null,
                  valueType: 'string',
                  valueLabel: 'naam',
                },
                {
                  name: 'insightdoctor',
                  label: 'Mijn huisarts',
                  documentType: null,
                  link: null,
                  valueType: 'string',
                  valueLabel: 'naam',
                },
                {
                  name: 'insightspecialist',
                  label: 'Mijn specialist',
                  documentType: null,
                  link: null,
                  valueType: 'string',
                  valueLabel: 'naam',
                },
                {
                  name: 'insightpartner',
                  label: 'Mijn partner',
                  documentType: null,
                  link: null,
                  valueType: 'string',
                  valueLabel: 'naam',
                },
                {
                  name: 'insightchildren',
                  label: 'Mijn kinderen',
                  documentType: null,
                  link: null,
                  valueType: 'string',
                  valueLabel: 'naam',
                },
                {
                  name: 'insightrelatives',
                  label: 'Overige familieleden',
                  documentType: null,
                  link: null,
                  valueType: 'string',
                  valueLabel: 'naam',
                },
                {
                  name: 'insightsupervisor',
                  label: 'De toezichthouder die ik heb aangewezen',
                  documentType: null,
                  link: null,
                  valueType: 'string',
                  valueLabel: 'naam',
                },
                {
                  name: 'insightnotary',
                  label: 'Mijn notaris',
                  documentType: null,
                  link: null,
                  valueType: 'string',
                  valueLabel: 'naam',
                },
                {
                  name: 'insightadministrator',
                  label: 'Mijn bewindvoerder, curator of mentor',
                  documentType: null,
                  link: null,
                  valueType: 'string',
                  valueLabel: 'naam',
                },
                {
                  name: 'insightother',
                  label: 'anders...',
                  documentType: null,
                  link: null,
                  valueType: 'string',
                  valueLabel: 'vul in...',
                },
            ],
        },
    }
};
