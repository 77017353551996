import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames';

import StateComponent from "../misc/stateComponent";
import {JSSCategorySelection} from "./style/JSSCategorySelection";
import {materialTypes} from "../material/materialTypes";
import MaterialFactory from "../material/materialFactory";
import getCategoryType from "../../common/data/mappings/categoryMapping";
import getModules from "../../common/data/mappings/moduleMapping";

export class CategorySelection extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            selectedCategory: 'all',
        };
    }

    componentDidMount = () => {
        this.mount();
    };

    componentWillUnmount = () => {
        this.unMount();
    };

    onSelectCategory = evt => {
        this.changeState({selectedCategory: evt.target.value});
        this.props.onSelectCategory(evt);
    };

    getCategories = () => (
        [{
            label: 'Alle categorieën',
            value: 'all'
        }].concat(getModules().map(category => ({
                label: this.renderCategoryLabel(category),
                value: category.name
            })
        ))
    );

    renderCategoryLabel = category => (
        <div className='categoryLabelContainer'>
            <span className={'categoryIcon ' + getCategoryType(category.name).headerIcon + (
                this.props.selectedCategory === category.name ? ' selected' : '')}/>
            <label>{category.label}</label>
        </div>
    );

    renderSelectedValues = value => {
        if (value === 'all') return 'Alle categorieën';
        else return getCategoryType(value).label;
    };

    render = () => {
        const {classes, extraClassName} = this.props;
        const {selectedCategory} = this.state;
        return (
            <MaterialFactory componentType={materialTypes.SELECT} SelectProps={{
                renderValue: this.renderSelectedValues,
                MenuProps: {
                    classes: {paper: classes.menuRoot},
                }
            }} items={this.getCategories()} onChange={this.onSelectCategory} value={selectedCategory}
                             label='Filter op categorie' className={classNames(classes.selectRoot, extraClassName)}/>
        )
    };
}

CategorySelection.propTypes = {
    onSelectCategory: PropTypes.func.isRequired,
    extraClassName: PropTypes.string,
};
export default withStyles(JSSCategorySelection)(CategorySelection);