import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../../redux/actions';

import {isFunction, pathIsNotNull} from 'glob-common-js/lib/utils';

import LinkDossierController from "../library/linkDossierController";
import AddFinancialItemController from "../financial/create/addFinancialItemController";
import StartFlowController from "../start/startFlowController";
import TwoFactorController from "../authentication/twoFactorPopup/twoFactorController";
import UploadTipsController from "../start/upload/uploadTipsController";
import BrowserSupport from "./browserSupport";
import SecurityPopup from "./securityPopup";
import ExternalLinkWarning from "./externalLinkWarning";
import {addCustomEventListener, removeCustomEventListener} from "../../misc/eventDispatcher";
import AgendaPopupController from "../agenda/popup/agendaPopupController";
import ContactPopup from "./contactPopup";
import StateComponent from "./stateComponent";
import AddBirthdayController from "../subscription/birthday/addBirthdayController";
import MaterialFactory from "../material/materialFactory";
import {materialTypes} from "../material/materialTypes";
import AddDossierPopupController_v2 from "../dossier/add/popup/v2/addDossierPopupController";
import ProtectedAccessController from "./protectedAccess/protectedAccessController";
import DocumentShareController from "../document/share/documentShareController";
import UpdateGrantController from "../account/access/v2/updateGrantController";
import AddGrantController from "../account/access/v2/addGrantController";
import CancellationController from "../cancellation/cancellationController";
import Alert from "./alert";
import OnboardingController from "../onboarding/onboardingController";
import StoreOnboardingController from "../onboarding/store/storeOnboardingController";
import FinancialOnboardingController from "../onboarding/financial/financialOnboardingController";
import AgendaOnboardingController from "../onboarding/agenda/agendaOnboardingController";
import SaveOnboardingController from "../onboarding/save/saveOnboardingController";
import DocumentDetailController from "../document/details/documentDetailController";
import AddDocumentPopupController from "../document/addDocument/popup/addDocumentPopupController";
import ContactDetailController from "../contact/add/contactDetailController";
import ContactLinkController from "../contact/contactLink/contactLinkController";
import ActionCodePopupController from "../actionCode/popup/actionCodePopupController";

/**
 * Container for platform popups. Some popups are only rendered when the user is logged in.
 */
export class PopupController extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            snackbarText: '',
        };
        this.snackbarCallback = null;
    }

    setSnackBarText = ({text, callback}) => {
        this.snackbarCallback = callback;
        this.changeState({snackbarText: text});
    };

    renderUserDependantComponents = () => {
        let user = this.props.userState.user;
        if (pathIsNotNull(user, 'email'))
            return (
                <>
                    <AddDossierPopupController_v2/>
                    <AddFinancialItemController/>
                    <AddDocumentPopupController/>
                    <AgendaPopupController/>
                    <LinkDossierController/>
                    <DocumentDetailController/>
                    <StartFlowController/>
                    <UploadTipsController/>
                    <SecurityPopup user={user}/>
                    <ProtectedAccessController/>
                    <DocumentShareController/>
                    <AddGrantController/>
                    <UpdateGrantController/>
                    <CancellationController/>
                    <OnboardingController/>
                    <StoreOnboardingController/>
                    <FinancialOnboardingController/>
                    <AgendaOnboardingController/>
                    <SaveOnboardingController/>
                    <ContactDetailController/>
                </>
            );
        return null;
    };

    renderIndependantComponents = () => (
        <>
            <TwoFactorController/>
            <BrowserSupport/>
            <ExternalLinkWarning/>
            <ContactPopup/>
            <MaterialFactory componentType={materialTypes.SNACKBAR} open={isNotNull(this.state.snackbarText)}
                             message={this.state.snackbarText} onClose={() => {
                if (isFunction(this.snackbarCallback))
                    this.snackbarCallback();
                this.setSnackBarText({text: '', callback: null});
            }}/>
            <AddBirthdayController/>
            <Alert/>
            <ContactLinkController/>
            <ActionCodePopupController/>
        </>
    );

    componentDidMount = () => {
        this.mount();
        addCustomEventListener('openSnackbar', this.setSnackBarText);
    };

    componentWillUnmount = () => {
        this.unMount();
        removeCustomEventListener('openSnackbar', this.setSnackBarText);
    };

    render = () => {
        return (
            <>
                {this.renderUserDependantComponents()}
                {this.renderIndependantComponents()}
            </>
        )
    }
}

const mapStateToProps = state => ({
    userState: state.user,
    appCallbackState: state.appCallback,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PopupController);
