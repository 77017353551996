import React from 'react';
import PropTypes from 'prop-types';

const bdhLogo = require("../../../common/images/logo_bydehand.jpg");

export default class DocumentTypeView extends React.Component {
    constructor(props) {
        super(props);
    }

    onClickDocumentType = documentType => () => {
        if (String(documentType.id).substring(0, 10)==='REPLACE_ID'){
          documentType.id=documentType.replaceID
        }
        this.props.onTypeClick(documentType);
    };

    createTableData = () => {
        return this.props.documentTypes.map((documentType, key) => {
            return (
                <tr className='documentTypeRow' key={key} onClick={this.onClickDocumentType(documentType)}>
                    <td className='documentTypeName'>{documentType.label}</td>
                    <td><span className='common-icon-plus documentTypePlus'/></td>
                </tr>
            )
        });
    };

    render = () => {
        return (
            <div className='documentTypeContainer'>
                {this.props.documentTypes.length > 0 &&
                <div className='documentTypeList scrollbar style-1'>
                    <table>
                        <tbody>
                        {this.createTableData()}
                        </tbody>
                    </table>
                </div>}
                {this.props.documentTypes.length === 0 &&
                <img className='bdhPlaceHolder' src={bdhLogo} alt="ByDeHand.com"/>}
                {this.props.documentCount > 0 &&
                <button className='toDashboard' onClick={this.props.toDashboard}>Naar dashboard</button>}
            </div>
        );
    }
}

DocumentTypeView.propTypes = {
    documentTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
    onTypeClick: PropTypes.func.isRequired,
    toDashboard: PropTypes.func.isRequired,
};
