import React from 'react';
import {withRouter} from 'react-router-dom';

import {toMoment} from 'glob-common-js/lib/utils';

import {addCustomEventListener, dispatchCustomEvent, removeCustomEventListener} from "../../../misc/eventDispatcher";
import {getFinancialType, getMoneyType} from "../../../common/data/mappings/financialTypeMapping_v3";
import AddFinancialItem from "./addFinancialItem";
import FinancialHelper from "../helper/financialHelper";
import {navigate} from "../../../misc/utils";
import {EMPTY_FUNCTION} from "../../misc/constants";
import {getOnboardingManager} from "../../onboarding/manager/onboardingManagerHelper";

export class AddFinancialItemController extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            item: null,
            type: null,
            moneyType: null,
            active: false,
            completed: false,
            loading: false,
        };
        this._isMounted = false;
    }

    changeState = stateChange => {
        this._isMounted && this.setState(stateChange);
    };

    onLoad = () => {
        this.changeState({loading: true});
    };

    afterLoad = () => {
        this.changeState({loading: false});
    };

    onSubmit = model => {
        model.itemType = getFinancialType(model.type);
        this.financialHelper.submitItem(model);
    };

    onClose = () => {
        this.changeState({item: null, type: null, moneyType: null, active: false, completed: false,});
    };

    onReset = () => {
        this.changeState({completed: false});
    };

    submitCallback = () => {
        const manager = getOnboardingManager('financial');
        if (manager.isActive()) {
            this.onClose();
            manager.setNextStep();
        } else {
            this.changeState({completed: true});
            dispatchCustomEvent('updatePlatform');
        }
    };

    toFinancial = () => {
        this.onClose();
        navigate('/financieel-overzicht');
    };

    eventListener = ({item, type, moneyType}) => {
        if (isNotNull(item)) {
            moneyType = this.getMoneyType(item);
        } else if (isNotNull(type)) {
            moneyType = this.getMoneyType(null, type);
        }

        item = this.convertDates(item);
        type = type || null;
        this.changeState({
            active: true,
            item,
            type,
            moneyType,
        });
    };

    convertDates = item => {
        if (isNull(item)) return item;
        const start_date_string = item.start_date, end_date_string = item.end_date;
        if (isNotNull(start_date_string)) item.start_date = toMoment(start_date_string);
        if (isNotNull(end_date_string)) item.end_date = toMoment(end_date_string)
        return item;
    };

    getMoneyType = (item, type) => {
        let financialType = isNotNull(item) ? item.financialType : isNotNull(type) ? type.name : null;
        return getMoneyType(financialType);
    };

    componentDidMount = () => {
        this._isMounted = true;
        this.financialHelper = new FinancialHelper({
            source: 'AddFinancialItemController',
            callback: EMPTY_FUNCTION,
            onLoad: this.onLoad,
            afterLoad: this.afterLoad,
            submitCallback: this.submitCallback,
            name: 'popup',
        });
        this.financialHelper.loadDossiers();
        addCustomEventListener('triggerFinancialPopup', this.eventListener);
    };

    componentWillUnmount = () => {
        this._isMounted = false;
        this.financialHelper = null;
        removeCustomEventListener('triggerFinancialPopup', this.eventListener);
    };

    render = () => {
        if (this.state.active)
            return (
                <AddFinancialItem onSubmit={this.onSubmit} onClose={this.onClose} moneyType={this.state.moneyType}
                                  item={this.state.item} type={this.state.type} completed={this.state.completed}
                                  loading={this.state.loading} toFinancial={this.toFinancial}
                                  onReset={this.onReset}/>
            );
        return null;
    };
}

export default withRouter(AddFinancialItemController);