import './style/designer.scss';

import React from 'react';
import Search from '@material-ui/icons/Search';

import StateComponent from "../misc/stateComponent";
import BdhInput from "../customControls/input/bdhInput";
import BdhSelectController from "../customControls/select/bdhSelectController";

export default class Designer extends StateComponent {
    state = {
        inputValue: '',
        selectValue: [],
    };

    onChange = value => {
        this.changeState({inputValue: value});
    };

    onSelect = (value, isMultiple) => {
        let selectValue = this.state.selectValue.slice();
        if (isMultiple) {
            if (selectValue.includes(value))
                selectValue.splice(selectValue.indexOf(value), 1);
            else selectValue.push(selectValue);
        } else selectValue = [value];

        this.changeState({selectValue});
    };

    onClearSelect = value => {
        const {selectValue} = this.state;
        this.changeState({selectValue: selectValue.filter(item => item !== value)});
    };

    getSelectItems = () => [
        {label: 'Item A', value: 'A'},
        {label: 'Item BA', value: 'BA'},
        {label: 'Item B', value: 'B'},
        {label: 'Item C', value: 'C'},
        {label: 'Item D', value: 'D'},
        {label: 'Item E', value: 'E'},
        {label: 'Item F', value: 'F'},
        {label: 'Item G', value: 'G'},
        {label: 'Item H', value: 'H'},
        {label: 'Item I', value: 'I'},
        {label: 'Item J', value: 'J'},
        {label: 'Item K', value: 'K'},
        {label: 'Item L', value: 'L'},
        {label: 'Item M', value: 'M'},
        {label: 'Item N', value: 'N'},
        {label: 'Item O', value: 'O'},
        {label: 'Item P', value: 'P'},
        {label: 'Item Q', value: 'Q'},
        {label: 'Item R', value: 'R'},
    ];

    getSearchOrnament = () => ([{
        element: <Search className='searchOrnament'/>
    }]);

    renderLabel = text => (
        <label className='elementLabel'>{text}</label>
    );

    componentDidMount = () => {
        this.mount();
    };

    componentWillUnmount = () => {
        this.unMount();
    };

    render = () => {
        const {inputValue, selectValue} = this.state;
        const withTransition = false;
        return <div className='designerView'>
            <div className='col'>
                {this.renderLabel('Met label + transitie')}
                <BdhInput onChange={this.onChange} value={inputValue} label='ByDeHand input'/>
                {this.renderLabel('Met label zonder transitie')}
                <BdhInput onChange={this.onChange} value={inputValue} label='ByDeHand input'
                          withLabelTransition={withTransition}/>
                {this.renderLabel('Zonder label')}
                <BdhInput onChange={this.onChange} value={inputValue}/>
                {this.renderLabel('Disabled')}
                <BdhInput onChange={this.onChange} value={inputValue} disabled label='Disabled'
                          withLabelTransition={withTransition}/>
                {this.renderLabel('Readonly')}
                <BdhInput onChange={this.onChange} value='Readonly' readOnly label='Readonly input'
                          withLabelTransition={withTransition}/>
                {this.renderLabel('Zoek veld')}
                <BdhInput onChange={this.onChange} value={inputValue} label='Zoeken...'
                          ornaments={this.getSearchOrnament()}/>
                {this.renderLabel('Met error')}
                <BdhInput onChange={this.onChange} value={inputValue} label='Error' error='Voorbeeld error'/>
            </div>
            <div className='col'>
                {this.renderLabel('Contained met transitie')}
                <BdhInput variant='contained' onChange={this.onChange} value={inputValue} label='ByDeHand input'/>
                {this.renderLabel('Contained input')}
                <BdhInput variant='contained' onChange={this.onChange} value={inputValue} label='ByDeHand input'
                          withLabelTransition={false}/>
                {this.renderLabel('Contained zonder label')}
                <BdhInput variant='contained' onChange={this.onChange} value={inputValue}/>
                {this.renderLabel('Disabled')}
                <BdhInput onChange={this.onChange} value={inputValue} disabled label='Disabled' variant='contained'/>
                {this.renderLabel('Readonly')}
                <BdhInput onChange={this.onChange} value='Readonly' readOnly label='Readonly input'
                          variant='contained'/>
                {this.renderLabel('Zoek veld')}
                <BdhInput onChange={this.onChange} value={inputValue} label='Zoeken...'
                          ornaments={this.getSearchOrnament()} variant='contained'/>
                {this.renderLabel('Met error')}
                <BdhInput variant='contained' onChange={this.onChange} value={inputValue} label='Error'
                          error='Voorbeeld error'/>
            </div>
            <div className='col'>
                {this.renderLabel('Select')}
                <BdhSelectController value={selectValue} items={this.getSelectItems()} onClear={this.onClearSelect}
                                     onSelect={this.onSelect} label='Selecteer'/>
            </div>
        </div>
    }
}
