import React from 'react';

import PropTypes from 'prop-types';

import {isNotNull} from 'glob-common-js/lib/utils';


import './style/healthAnalysisPopup.scss';
import {sendEvent} from "../healthAnalysisUtils";
import MaterialFactory from "../../../../material/materialFactory";
import {materialTypes} from "../../../../material/materialTypes";

export default class HealthAnalysisPopupView extends React.Component {
    constructor(props) {
        super(props);
        this.explanationArr = [
            {
                title: 'Jouw zorgverzekering 2019',
                number: '1',
                value: 'Invoeren van de gegevens van je huidige zorgverzekering'
            },
            {
                title: 'Evaluatie',
                number: '2',
                value: 'Stap voor stap beoordelen of de dekking, prijs, kwaliteit van de verzekeraar en eigen risico ' +
                    'nog passen bij jouw situatie'
            },
            {
                title: 'Aanpassingen',
                number: '3',
                value: 'Indien nodig, bepalen welke wijzigingen voor jou gewenst is en bepalen wat de juiste ' +
                    'verzekering voor jou is'
            },
            {
                title: 'Jouw zorgverzekering 2020',
                number: '4',
                value: 'Inrichten van jouw dossier zorgverzekering 2020 met alle benodigde informatie en handige ' +
                    'tools op één plek'
            },
        ];
    }

    createExplanation = (explanation, key) => {
        let className = 'explanationCircle';
        let number = explanation.number;
        if (explanation.number === '1') {
            className = 'explanationCircle checked';
            number = <i className='common-icon-check'/>;
        }

        return (
            <div className='explanation' key={key}>
                <div className='explanationTitle'>{explanation.title}</div>
                <div id={'circle' + key} className='explanationRoadmap'>
                    <div className={className}>
                        {number}
                    </div>
                    <div className='explanationLine'>{key === this.explanationArr.length - 1 ? null :
                        <span className='block'/>}</div>
                </div>
                <div
                    className='explanationValue'>{explanation.value}
                </div>
            </div>);
    };

    createMobileExplanation = (explanation, key) => {
        let className = 'explanationCircle';
        let number = explanation.number;
        if (explanation.number === '1') {
            className = 'explanationCircle checked';
            number = <i className='common-icon-check'/>;
        }

        return (
            <div key={key} className='mobileExplanation'>
                <div className='explanationHeader'>
                    <div id={'circle' + key} className='explanationRoadMap'>
                        <div className={className}>
                            {number}
                        </div>
                        <div className='explanationLine'>{key === this.explanationArr.length - 1 ? null :
                            <span className='block'/>}</div>
                    </div>
                    <div className='explanationTitle'>{explanation.title}</div>
                </div>
                <div
                    className='explanationValue'>{explanation.value}
                </div>
            </div>);
    };

    hidePopup = () => {
        sendEvent('Uitleg', 'verder');
        let invisibleModal = document.querySelector('.invisbleModal');
        if (isNotNull(invisibleModal)) {
            invisibleModal.classList.add('hide');

        }
        let popup = document.querySelector('.healthAnalysisPopup');
        if (isNotNull(popup)) {
            popup.classList.add('hide');
        }
        this.props.setPopupShown(true);
    };

    render = () => {

        return (
            <div className='healthAnalysisPopupContainer'>
                <div className='invisibleModal'/>
                <div className='healthAnalysisPopup'>
                    <span className='common-icon-cross closeButton' onClick={this.hidePopup}/>
                    <div className='popupHeader'>
                        <label className='headerTitle'>Zo werkt de ByDeHand Zorgverzekering analyse</label>
                    </div>
                    <div className='popupContent'>
                        <div className='popupBox'>
                            <div className='explanations'>
                                {this.explanationArr.map(this.createExplanation)}
                                {this.explanationArr.map(this.createMobileExplanation)}
                            </div>
                            <MaterialFactory componentType={materialTypes.RAISED_BUTTON} onClick={this.hidePopup}>
                                Start de evaluatie</MaterialFactory>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

HealthAnalysisPopupView.propTypes = {
    setPopupShown: PropTypes.func.isRequired,
};
