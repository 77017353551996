import './style/addFinancialItem.scss';

import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepContent from '@material-ui/core/StepContent';

import {getFinancialType, getItemTypesForMoneyType} from "../../../common/data/mappings/financialTypeMapping_v3";
import {sendGaEvent} from "../../../common/js/ga";
import MaterialFactory from "../../material/materialFactory";
import {materialTypes} from "../../material/materialTypes";
import {NOW} from "../../misc/constants";
import {getOnboardingManager} from "../../onboarding/manager/onboardingManagerHelper";

const GA_CATEGORY = 'Financieel overzicht';
export default class AddFinancialItem extends React.Component {
    constructor(props) {
        super(props);
        this.defaultState = {
            id: null,
            description: '',
            amount: '',
            frequency: 'one_time',
            start_date: NOW().format('YYYY-MM-DD'),
            end_date: null,
            type: '',
            createDossier: false,
            dossierId: null,
        };

        this.state = {
            activeStep: 0,
            visitedSteps: [],
            descriptionError: null,
            amountError: null,
            typeError: null,
            store: this.defaultState,
        };
        this._isMounted = false;
    }

    changeState = stateChange => {
        this._isMounted && this.setState(stateChange);
    };

    onSubmit = () => {
        let model = this.state.store;
        if (this.validate(model)) {
            const manager = getOnboardingManager('financial');
            if (manager.isActive())
                manager.sendEvent('Klik', 'Opslaan');
            else
                sendGaEvent(GA_CATEGORY, 'Toevoegen', model.type);
            this.props.onSubmit(model);
        }
    };

    validate = (model) => {
        let description = model.description;
        let amount = model.amount;
        let type = model.type;

        let isValid = true;
        let typeLabel = this.props.moneyType === 'income' ? ' dit inkomen ' : ' deze uitgave ';
        if (isNull(description)) {
            this.changeState({descriptionError: 'Geef' + typeLabel + 'een omschrijving', activeStep: 0});
            isValid = false;
        } else if (isNull(amount)) {
            this.changeState({amountError: 'Geef' + typeLabel + 'een bedrag', activeStep: 1});
            isValid = false;
        } else if (isNull(type)) {
            this.changeState({typeError: 'Geef' + typeLabel + 'een type', activeStep: 3});
            isValid = false;
        }
        return isValid;
    };

    onClose = () => {
        this.reset();
        const manager = getOnboardingManager('financial');
        if (manager.isActive()) {
            manager.sendEvent('Klik', 'Sluit toevoegen');
            manager.setPrevStep();
        } else {
            sendGaEvent(GA_CATEGORY, 'Sluit popup', 'Sluit popup');
        }
        this.props.onClose();
    };

    onKeyDown = evt => {
        let enterCode = 13;
        if (evt.keyCode === enterCode) {
            evt.preventDefault();
            this.nextStep();
        }
    };

    onEnterFinish = evt => {
        let enterCode = 13;
        if (evt.keyCode === enterCode) {
            evt.preventDefault();
            this.onSubmit()
        }
    };

    reset = () => {
        this.changeState({
            activeStep: 0,
            visitedSteps: [],
            store: this.defaultState,
        });
        this.props.onReset();
    };

    initWithItem = () => {
        let item = this.props.item;
        let initialState = {
            id: item.id || this.defaultState.id,
            description: item.description || this.defaultState.description,
            amount: item.monthly_price || this.defaultState.amount,
            frequency: item.frequency || this.defaultState.frequency,
            start_date: item.start_date,
            end_date: item.end_date,
            type: item.financialType,
            createDossier: false,
            dossierId: item.dossierId || this.defaultState.dossierId,
        };
        this.initStore(initialState);
    };

    initWithType = () => {
        let type = this.props.type;
        let initialState = Object.assign({}, this.defaultState, type.defaults, {
            type: type.subtype || type.name,
        });
        this.initStore(initialState);
    };

    initStore = data => {
        this.changeState({store: data});
    };

    changeField = (name, value) => {
        let stateChange = {
            store: {
                ...this.state.store,
                [name]: value,
            }
        };

        if (name === 'description')
            stateChange.descriptionError = null;
        else if (name === 'amount')
            stateChange.amountError = null;
        else if (name === 'type')
            stateChange.typeError = null;
        this.changeState(stateChange);
    };

    changeFrequency = value => {
        this.changeState({
            store: {
                ...this.state.store,
                frequency: value,
            }
        })
    };

    setStep = index => {
        this.changeState({activeStep: index});
    };

    nextStep = () => {
        let activeStep = this.state.activeStep;
        this.changeState({activeStep: activeStep + 1});
    };

    getMoneyLabel = () => {
        return this.props.moneyType === 'income' ? 'inkomen' : 'uitgave';
    };

    getMoneyAction = () => {
        return this.props.moneyType === 'income' ? 'ontvang' : 'betaal';
    };

    getTypes = () => {
        let types = getItemTypesForMoneyType(this.props.moneyType);
        types = types.map(type => ({value: type.subtype || type.name, label: type.label}));
        types.sort((typeA, typeB) => {
            const preferA = -1, preferB = 1, isEqual = 0;
            let labelA = typeA.label, labelB = typeB.label;
            return labelA < labelB ? preferA : labelA > labelB ? preferB : isEqual;
        });
        return types;
    };

    getOnboardingTexts = () => {
        const itemType = getOnboardingManager('financial').getItemType();
        if (itemType === 'in') return [
            '1) Start met de omschrijving van dit inkomen. Geef het een herkenbare naam zodat je het later makkelijk ' +
            'terug kunt vinden.',
            '2) Vul het bedrag in dat je ontvangt.',
            '3) Vul de frequentie van dit inkomen in. Ontvang je dit inkomen wekelijks, maandelijks en op een ' +
            'vaste datum?',
            '4) Bevestig het type van dit inkomen en kies of je een dossier aan wilt maken.',
        ];
        return [
            '1) Start met de omschrijving van deze uitgave. Geef het een herkenbare naam zodat je het later makkelijk ' +
            'terug kunt vinden.',
            '2) Vul het bedrag in dat je uit hebt gegeven.',
            '3) Vul de frequentie van deze uitgave in. Geef je deze uitgave wekelijks, maandelijks en op een ' +
            'vaste datum uit?',
            '4) Bevestig het type van deze uitgave en kies of je er een dossier van wilt maken.',
        ];
    };

    setEnterListener = () => {
        const {activeStep} = this.state;
        document.removeEventListener('keydown', this.onKeyDown);
        document.removeEventListener('keydown', this.onEnterFinish);
        if (activeStep < 3)
            document.addEventListener('keydown', this.onKeyDown);
        else addEventListener('keydown', this.onEnterFinish);
    };

    getEndDate = () => {
        const store = this.state.store;
        return isNotNull(store.end_date) ? store.end_date : null;
    };

    renderHeaderLabel = () => {
        let moneyType = this.props.moneyType;
        let moneyLabel = moneyType === 'income' ? 'inkomen' : 'uitgave';
        if (isNotNull(this.props.item)) {
            let financialType = getFinancialType(this.props.item.financialType);
            let typeLabel = financialType.label;
            return 'Pas ' + moneyLabel + ' ' + typeLabel + ' aan';
        } else if (isNotNull(this.props.type)) {
            let typeLabel = this.props.type.label;
            return 'Voeg een ' + moneyLabel + ' ' + typeLabel + ' toe';
        } else if (isNotNull(moneyType))
            return 'Voeg ' + moneyLabel + ' toe';
        return null;
    };

    renderStep = (index, label, content) => {
        return (
            <Step completed={this.state.visitedSteps.includes(index)} active={this.state.activeStep === index}>
                <StepButton onClick={() => {
                    this.setStep(index)
                }}>
                    {label}
                </StepButton>
                <StepContent>
                    {content}
                </StepContent>
            </Step>
        )
    };

    renderTextField = (id, label, model, errorText, type = 'text', hintText) => (
        <MaterialFactory componentType={materialTypes.TEXT} id={id} label={label} helperText={errorText}
                         error={isNotNull(errorText)} value={this.state.store[model]} className='financialTextField'
                         type={type} placeholder={hintText} autoFocus onChange={(evt) => {
            this.changeField(model, evt.target.value)
        }}/>
    );

    renderOnboardingText = () => {
        const {activeStep} = this.state, texts = this.getOnboardingTexts();
        return <p className='onboardingText'>{texts[activeStep]}</p>;
    };

    componentDidMount = () => {
        this._isMounted = true;
        document.addEventListener('keydown', this.onKeyDown);
        if (isNotNull(this.props.item)) {
            this.initWithItem();
        } else if (isNotNull(this.props.type)) {
            this.initWithType();
        }
    };

    componentDidUpdate = (prevProps, prevState) => {
        let prevIndex = prevState.activeStep;
        let prevVisited = prevState.visitedSteps;
        if (prevIndex !== this.state.activeStep) {
            if (!prevVisited.includes(prevIndex))
                this.changeState({visitedSteps: prevVisited.concat(prevIndex)});
            this.setEnterListener();
        }
    };

    componentWillUnmount = () => {
        this._isMounted = false;
        document.removeEventListener('keydown', this.onKeyDown);
        document.removeEventListener('keydown', this.onEnterFinish);
    };

    render = () => {
        const onboardingActive = getOnboardingManager('financial').isActive();
        return (
            <>
                {!onboardingActive && <div className='clearBackground'/>}
                <div className={classNames('addFinancialItem', onboardingActive && 'onboarding')}>
                    {this.props.loading &&
                    <div className='financialLoaderOverlay'>
                        <MaterialFactory componentType={materialTypes.CIRCULAR_PROGRESS}/>
                    </div>}
                    <div className='financialHeader'>
                        <h2 className='headerLabel'>{this.renderHeaderLabel()}</h2>
                        <span className='financialClose common-icon-cross' onClick={this.onClose}/>
                    </div>
                    <div className='financialBody'>
                        {onboardingActive && this.renderOnboardingText()}
                        {this.props.completed ?
                            <div className='completedStep'>
                                <div className='completedRow'>
                                    <label className='completedTitle'>Klaar</label>
                                    <label className='completedTitle'>Je {this.getMoneyLabel()} is opgeslagen</label>
                                </div>
                                <div className='completedRow'>
                                    <MaterialFactory componentType={materialTypes.RAISED_BUTTON}
                                                     className='completedButton' onClick={() => {
                                        sendGaEvent(GA_CATEGORY, 'klik', 'Naar financieel overzicht');
                                        this.props.toFinancial();
                                    }}>Naar overzicht</MaterialFactory>
                                    <MaterialFactory componentType={materialTypes.RAISED_BUTTON}
                                                     className='completedButton' onClick={() => {
                                        sendGaEvent(GA_CATEGORY, 'klik', 'Nog een ' + this.getMoneyLabel() +
                                            ' toevoegen');
                                        this.reset();
                                    }}>{'Voeg nog een ' + this.getMoneyLabel() + ' toe'}</MaterialFactory>
                                </div>
                            </div> :
                            <Stepper orientation='vertical' nonLinear>
                                {this.renderStep(0, 'Omschrijving',
                                    <>
                                        <p className='stepSubTitle'>Wat is de omschrijving?</p>
                                        {this.renderTextField('financialDescription', null, 'description',
                                            this.state.descriptionError, 'text')}
                                    </>)}
                                {this.renderStep(1, 'Bedrag',
                                    <>
                                        {this.renderTextField('financialAmount', 'Bedrag', 'amount',
                                            this.state.amountError, 'number', '\u20ac 0,00')}
                                    </>)}
                                {this.renderStep(2, 'Frequentie',
                                    <div className='frequencyContainer'>
                                        <p className='stepSubTitle'>Hoe vaak {this.getMoneyAction()} je
                                            het?</p>
                                        <MaterialFactory componentType={materialTypes.SELECT}
                                                         className='frequencySelect' label='Frequentie'
                                                         value={this.state.store.frequency} items={[
                                            {value: 'one_time', label: 'Eenmalig'},
                                            {value: 'monthly', label: 'Maandelijks'},
                                            {value: 'quarterly', label: 'Ieder kwartaal'},
                                            {value: 'half_year', label: 'Ieder half jaar'},
                                            {value: 'yearly', label: 'Jaarlijks'},
                                        ]} fullWidth onChange={(evt) => {
                                            this.changeFrequency(evt.target.value);
                                        }}/>
                                        <p className='stepSubTitle'>Op welke dag {this.getMoneyAction()} je
                                            het?</p>
                                        <div className='dateContainer'>
                                            <MaterialFactory componentType={materialTypes.DATE_PICKER}
                                                             value={this.state.store.start_date}
                                                             onChange={date => {
                                                                 this.changeField('start_date', date);
                                                             }} label='Begindatum' className='frequencyColumn'/>
                                            <MaterialFactory componentType={materialTypes.DATE_PICKER}
                                                             value={this.getEndDate()} onChange={date => {
                                                this.changeField('end_date', date);
                                            }} label='Einddatum'
                                                             style={{
                                                                 display: this.state.store.frequency === 'one_time'
                                                                     ? 'none' : 'inline-flex'
                                                             }} className='frequencyColumn'/>
                                        </div>
                                        <MaterialFactory componentType={materialTypes.RAISED_BUTTON}
                                                         onClick={this.nextStep}>Volgende stap</MaterialFactory>
                                    </div>)}
                                {this.renderStep(3, 'Type',
                                    <>
                                        <p className='stepSubTitle'>Welk type {this.getMoneyLabel()} is het?</p>
                                        <MaterialFactory componentType={materialTypes.SELECT}
                                                         helperText={this.state.typeError}
                                                         error={isNotNull(this.state.typeError)}
                                                         value={this.state.store.type}
                                                         items={this.getTypes()} onChange={(evt) => {
                                            this.changeField('type', evt.target.value);
                                        }} fullWidth/>
                                        {!onboardingActive && <div className='financialDossierCheck'>
                                            <span className='common-icon-folder-open2 folderIcon'/>
                                            <MaterialFactory componentType={materialTypes.CHECKBOX}
                                                             className='dossierCheck' labelPlacement='start'
                                                             label='Ik wil een dossier aanmaken'
                                                             checked={this.state.store.createDossier}
                                                             onChange={(evt) => {
                                                                 this.changeField('createDossier', evt.target.checked)
                                                             }}/>
                                        </div>}
                                        <MaterialFactory componentType={materialTypes.RAISED_BUTTON}
                                                         onClick={this.onSubmit}>Klaar</MaterialFactory>
                                    </>)}
                            </Stepper>}
                    </div>
                </div>
            </>
        )
    };
}

AddFinancialItem.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    toFinancial: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
    completed: PropTypes.bool.isRequired,
    item: PropTypes.shape({
        description: PropTypes.string.isRequired,
        amount: PropTypes.number,
        monthly_price: PropTypes.number,
        frequency: PropTypes.oneOf(['one_time', 'monthly', 'quarterly', 'half_year', 'yearly']).isRequired,
        start_date: PropTypes.instanceOf(moment).isRequired,
        end_date: PropTypes.instanceOf(moment),
        createDossier: PropTypes.bool,
        dossierId: PropTypes.string,
        financialType: PropTypes.string.isRequired,
    }),
    type: PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    }),
    moneyType: PropTypes.oneOf(['income', 'expense']).isRequired,
    loading: PropTypes.bool.isRequired,
};