import './style/addFamilyMember.scss';

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepContent from '@material-ui/core/StepContent';

import {toMoment} from 'glob-common-js/lib/utils';

import {validateFamilyMember} from "../edit/accountValidator";
import {addCustomEventListener, removeCustomEventListener} from "../../../misc/eventDispatcher";
import MaterialFactory from "../../material/materialFactory";
import {materialTypes} from "../../material/materialTypes";
import {NOW} from "../../misc/constants";
import GenericLoader from "../../misc/genericLoader";

export default class AddFamilyMember extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: null,
            submitting: false,
            active: false,
            activeStep: 0,
            dirty: false,
            values: {
                firstname: '',
                infix: '',
                lastname: '',
                gender: 0,
                dob: null,
                family_type: 'partner',
                bsn: '',
                email: '',
                phone_number: '',
                phone_mobile: '',
                address: {
                    street: '',
                    house_number: '',
                    house_number_suffix: '',
                    zipcode: '',
                    city: '',
                },
            },
            sameAddress: true,
            errors: {
                firstname: null,
                bsn: null,
                email: null,
                zipcode: null,
            }
        };
        this._isMounted = false;
        this.member = null;
    }

    changeState = stateChange => {
        this._isMounted && this.setState(stateChange);
    };

    onSubmit = () => {
        let member = Object.assign({}, this.state.values);
        member = this.addEmail(member);
        member.dob = toMoment(member.dob).format('MM-DD-YYYY');
        let errors = validateFamilyMember(member);
        if (errors.count > 0) {
            this.changeState({errors});
        } else {
            this.changeState({submitting: true});
            this.props.onSubmit(member, this.onCancel, this.state.mode === 'edit');
        }
    };

    addEmail = member => {
        member.email = 'family_' + new Date().getTime() + '@member.com';
        return member;
    };

    onCancel = () => {
        this.changeState({
            active: false,
            submitting: false,
            activeStep: 0,
            values: {
                firstname: '',
                infix: '',
                lastname: '',
                gender: 0,
                dob: NOW().format('YYYY-MM-DD'),
                family_type: 'partner',
                bsn: '',
                email: '',
                phone_number: '',
                phone_mobile: '',
                address: {
                    street: '',
                    house_number: '',
                    house_number_suffix: '',
                    zipcode: '',
                    city: '',
                }
            },
            errors: {
                firstname: null,
                bsn: null,
                email: null,
                zipcode: null,
            }
        });
    };

    onDelete = () => {
        this.props.onDelete(this.member, this.onCancel);
    };

    renderError = () => {
        let errors = this.state.errors;
        if (errors.count > 0) {
            if (errors.count === 1) return '1 veld is niet goed ingevuld';
            else return errors.count + ' velden zijn niet goed ingevuld';
        }
        return null;
    };

    setStep = index => {
        this.changeState({activeStep: index});
    };

    changeField = (name, value) => {
        let values = this.state.values;
        values[name] = value;
        this.changeState({
            values,
            dirty: true,
        });
    };

    changeAddressField = (name, value) => {
        let address = this.state.values.address;
        address[name] = value;
        this.changeState({
            values: {
                ...this.state.values,
                address,
            },
            dirty: true,
        });
    };

    getFamilyTypes = () => {
        return [
            {value: 'partner', label: 'Partner'},
            {value: 'child', label: 'Kind'},
            {value: 'parent', label: 'Ouder'},
            {value: 'sibling', label: 'Broer/Zus'},
            {value: 'grandchild', label: 'Kleinkind'},
        ];
    };

    checkAddress = (evt) => {
        const isChecked = evt.target.checked;
        let street = '', house_number = '', house_number_suffix = '', zipcode = '', city = '';
        if (isChecked) {
            const {propStreet, propHouse_number, propHouse_number_suffix, propZipcode, propCity} = this.props.address;
            street = propStreet;
            house_number = propHouse_number;
            house_number_suffix = propHouse_number_suffix;
            zipcode = propZipcode;
            city = propCity;
        }
        this.changeState({
            sameAddress: isChecked,
            values: {
                ...this.state.values,
                address: {
                    street,
                    house_number,
                    house_number_suffix,
                    zipcode,
                    city,
                },
            },
            dirty: true,
        });
    };

    renderTextField = (name, label, errorText = null, type = 'text') => (
        <MaterialFactory componentType={materialTypes.TEXT} label={label} type={type}
                         helperText={errorText} error={isNotNull(errorText)} value={this.state.values[name]} fullWidth
                         onChange={(evt) => {
                             this.changeField(name, evt.target.value);
                         }}/>
    );

    renderAddressField = (name, label, className = '', errorText = null) => (
        <MaterialFactory componentType={materialTypes.TEXT} label={label} readOnly={this.state.sameAddress}
                         className={className} value={this.state.values.address[name]} helperText={errorText}
                         error={isNotNull(errorText)} fullWidth onChange={(evt) => {
            this.changeAddressField(name, evt.target.value);
        }}/>
    );

    activate = member => {
        if (isNotNull(member) && isNotNull(Object.keys(member))) {
            this.member = Object.assign({}, member);
            let address = this.props.address;
            let sameAddress = _.isEqual(address, member.address);
            this.member.dob = isNotNull(this.member.dob) ? toMoment(this.member.dob) : NOW();
            this.member.dob = this.member.dob.format('YYYY-MM-DD');
            this.changeState({active: true, values: this.member, mode: 'edit', dirty: false, sameAddress});
        } else this.changeState({
            active: true, mode: 'add', dirty: true, values: {
                ...this.state.values,
                address: this.props.address,
            }
        });
    };

    componentDidMount = () => {
        this._isMounted = true;
        addCustomEventListener('addFamily', this.activate);
    };

    componentWillUnmount = () => {
        this._isMounted = false;
        removeCustomEventListener('addFamily', this.activate);
    };

    render = () => {
        if (this.state.active)
            return (
                <div className='addFamilyMember'>
                    <GenericLoader active={this.state.submitting}/>
                    <div className='addFamilyHeader'>
                        <label
                            className='headerLabel'>{'Familielid ' + (this.state.mode === 'add' ? 'toevoegen' : 'details')}</label>
                        {this.state.mode === 'edit' &&
                        <span className='trash common-icon-trash' onClick={this.onDelete}/>}
                    </div>
                    <div className='addFamilyBody'>
                        <Stepper orientation='vertical' nonLinear activeStep={this.state.activeStep}>
                            <Step>
                                <StepButton onClick={() => {
                                    this.setStep(0)
                                }}>Persoonlijke gegevens</StepButton>
                                <StepContent>
                                    <div className='familyDetails'>
                                        {this.renderTextField('firstname', 'Voornaam', this.state.errors.firstname)}
                                        {this.renderTextField('infix', 'Tussenvoegsel')}
                                        {this.renderTextField('lastname', 'Achternaam')}
                                        <MaterialFactory componentType={materialTypes.SELECT}
                                                         label='Geslacht' value={this.state.values.gender}
                                                         items={[{value: 0, label: 'Man'}, {value: 1, label: 'Vrouw'}]}
                                                         fullWidth onChange={(evt) => {
                                            this.changeField('gender', evt.target.value);
                                        }}/>
                                        <MaterialFactory componentType={materialTypes.DATE_PICKER} fullWidth
                                                         label='Geboortedatum' value={this.state.values.dob}
                                                         onChange={date => {
                                                             this.changeField('dob', date);
                                                         }}/>
                                        <MaterialFactory componentType={materialTypes.SELECT} label='Type familielid'
                                                         value={this.state.values.family_type}
                                                         items={this.getFamilyTypes()} fullWidth onChange={(evt) => {
                                            this.changeField('family_type', evt.target.value);
                                        }}/>
                                        {this.renderTextField('bsn', 'BSN-nummer', this.state.errors.bsn)}
                                    </div>
                                </StepContent>
                            </Step>
                            <Step>
                                <StepButton onClick={() => {
                                    this.setStep(1)
                                }}>Contactgegevens</StepButton>
                                <StepContent>
                                    <div className='familyDetails'>
                                        {/*{this.renderTextField('email', 'E-mailadres', this.state.errors.email, 'email')}*/}
                                        {this.renderTextField('phone_number', 'Telefoonnummer')}
                                        {this.renderTextField('phone_mobile', 'Mobiel nummer')}
                                        <MaterialFactory componentType={materialTypes.CHECKBOX} label='Zelfde adres'
                                                         checked={this.state.sameAddress} onChange={this.checkAddress}/>
                                        <div className='addressRow'>
                                            {this.renderAddressField('street', 'Straat', 'familyStreet')}
                                            {this.renderAddressField('house_number', 'Huisnummer', 'familyHouseNumber')}
                                            {this.renderAddressField('house_number_suffix', 'Toevoeging', 'familyHouseNumber')}
                                        </div>
                                        {this.renderAddressField('zipcode', 'Postcode', null, this.state.errors.zipcode)}
                                        {this.renderAddressField('city', 'Woonplaats')}
                                    </div>
                                </StepContent>
                            </Step>
                        </Stepper>
                    </div>
                    <div className='addFamilyFooter'>
                        {this.renderError()}
                        <div className='buttonContainer'>
                            <MaterialFactory componentType={materialTypes.RAISED_BUTTON}
                                             onClick={this.onCancel}>Annuleren</MaterialFactory>
                            <MaterialFactory componentType={materialTypes.RAISED_BUTTON}
                                             onClick={this.onSubmit}
                                             disabled={!this.state.dirty}>Opslaan</MaterialFactory>
                        </div>
                    </div>
                </div>
            );
        return null;
    };
}

AddFamilyMember.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    address: PropTypes.shape({
        street: PropTypes.string,
        house_number: PropTypes.string,
        house_number_suffix: PropTypes.string,
        zipcode: PropTypes.string,
        city: PropTypes.string,
    }),
};