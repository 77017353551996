import checklistTypes from "../../../components/checklist/checklistTypes";

const costs = {
    name: 'costs',
    label: 'Bepaal autokosten',
    documentType: null,
    link: {
        label: 'Bereken autokosten',
        url: 'https://www.anwb.nl/auto/autokosten#/kenteken',
    },
    valueType: 'string',
    valueLabel: 'Autokosten',
};
const purchase_contract = {
    name: 'purchase_contract',
    label: 'Koopovereenkomst opstellen',
    documentType: 'preliminary_contract',
    link: {
        label: 'Voorbeeld koopovereenkomst',
        url: 'https://www.anwb.nl/binaries/content/assets/anwb/pdf/rechtshulp/modelcontracten/koopcontracten/koopovereenkomst-gebruikt-motorvoertuig.pdf',
    },
};
export default {
    name: 'Auto kopen',
    icon: 'common-icon-car',
    route: '/auto-checklist',
    type: checklistTypes.BUY_CAR,
    GA_CATEGORY: 'Autochecklist',
    categories: {
        choose_car: {
            name: 'choose_car',
            label: 'Auto kiezen',
            icon: 'common-icon-car',
            boxes: [
                {
                    name: 'seats',
                    label: 'Bepaal ruimte en zitplaatsen',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'height',
                    label: 'Bepaal instaphoogte van de auto',
                    documentType: null,
                    link: null,
                    valueType: 'string',
                    valueLabel: 'Instaphoogte',
                },
                {
                    name: 'brand',
                    label: 'Bepaal merk, model, uitvoering en opties',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'fuel_usage',
                    label: 'Bepaal brandstofverbruik',
                    documentType: null,
                    link: null,
                    valueType: 'string',
                    valueLabel: 'Brandstofverbruik'
                },
                {
                    name: 'environment',
                    label: 'Bepaal milieu en energielabel',
                    documentType: null,
                    link: {
                        label: 'Info labels',
                        url: 'https://www.anwb.nl/auto/kopen/auto-kiezen/milieu-en-energielabel',
                    },
                    valueType: 'string',
                    valueLabel: 'Energielabel',
                },
                {
                    name: 'safety',
                    label: 'Bepaal veiligheidsvoorzieningen',
                    documentType: null,
                    link: null,
                },
                costs,
                {
                    name: 'new',
                    label: 'Een nieuwe of een tweedehands auto',
                    documentType: null,
                    link: null,
                    valueType: 'bool',
                    toggleOptions: ['Nieuw', 'Tweedehands'],
                },
                {
                    name: 'trade',
                    label: 'Doorrijden of inruilen',
                    documentType: null,
                    link: {
                        label: 'Controleer kosten',
                        url: 'https://www.anwb.nl/auto/autokosten',
                    },
                    valueType: 'bool',
                    toggleOptions: ['Doorrijden', 'Inruilen'],
                },
                {
                    name: 'wages',
                    label: 'Controleer wegenbelasting, BPM en bijtelling',
                    documentType: null,
                    link: null,
                },
            ]
        },
        research: {
            name: 'research',
            label: 'Onderzoeken',
            icon: 'common-icon-car',
            boxes: [
                {
                    name: 'maintenance',
                    label: 'Onderhoudsboek controleren',
                    documentType: 'maintenance_contract',
                    link: {
                        label: 'Info onderhoudsboek',
                        url: 'https://www.100procentonderhouden.nl/alles-over-onderhoud/onderhoudsboekjes/',
                    },
                },
                {
                    name: 'distance',
                    label: 'Controleer de kilometerstand',
                    documentType: null,
                    link: {
                        label: 'Tellerrapport opvragen',
                        url: 'https://www.rdw.nl/particulier/voertuigen/auto/tellerstanden/tellerstand-controleren',
                    },
                    valueType: 'string',
                    valueLabel: 'Kilometerstand',
                },
                costs,
            ]
        },
        manage: {
            name: 'manage',
            label: 'Regelen',
            icon: 'common-icon-car',
            boxes: [
                {
                    name: 'worth',
                    label: 'Autokoerslijst',
                    documentType: null,
                    link: {
                        label: 'Dagwaarde berekenen',
                        url: 'https://www.anwb.nl/auto/koerslijst',
                    },
                },
                {
                    name: 'delivery',
                    label: 'Leveringstermijn afspreken',
                    documentType: null,
                    link: null,
                    valueType: 'string',
                    valueLabel: 'Leveringstermijn',
                },
                purchase_contract,
                {
                    name: 'warranty',
                    label: 'Garantie',
                    documentType: 'sales_receipt',
                    link: {
                        label: 'Informatie garantie',
                        url: 'https://www.anwb.nl/juridisch-advies/koop-en-onderhoud/de-koop-sluiten/fabriek-en-bovaggarantie',
                    },
                    valueType: 'string',
                    valueLabel: 'Garantie',
                },
                {
                    name: 'licence',
                    label: 'Overschrijven van het kentekenbewijs',
                    documentType: 'vehicle_registration',
                    link: {
                        label: 'Kenteken overschrijven',
                        url: 'https://www.rdw.nl/particulier/voertuigen/auto/kopen/kentekenbewijs-op-naam-zetten',
                    },
                }
            ]
        },
        details_car: {
            name: 'details_car',
            label: 'Gegevens auto',
            icon: 'common-icon-car',
            boxes: [
                {
                    name: 'green_card',
                    label: 'Groene kaart',
                    documentType: 'green_card',
                    link: null,
                },
                {
                    name: 'licence',
                    label: 'Kentekenbewijs',
                    documentType: 'vehicle_registration',
                    link: null,
                },
                {
                    name: 'insurance',
                    label: 'Polis autoverzekering',
                    documentType: 'car_insurance_policy',
                    link: null,
                },
                purchase_contract,
            ]
        }
    }
};