import './style/insuranceStep.scss';

import React from 'react';
import PropTypes from 'prop-types';

import {remToPx} from 'glob-common-js/lib/utils';

import MaterialFactory from "../../../material/materialFactory";
import {materialTypes} from "../../../material/materialTypes";
import GenericLoader from "../../../misc/genericLoader";

const QUOTE_PICTURE = 'https://www.bydehand.com/wp-content/uploads/2016/10/IMG_6620-300x270.jpg';
export default class InsuranceScanView extends React.Component {
    onClickDecision = decision => () => {
        this.props.onClickDecision(decision);
    };

    render = () => {
        const {saving} = this.props;
        return (
            <>
                <div className='insuranceScanView'>
                    <GenericLoader active={saving}/>
                    <label className='headerTitle'>Ontvang een gratis verzekeringsscan t.w.v. &euro; 50,-</label>
                    <div className='quoteContainer'>
                        <img src={QUOTE_PICTURE} className='quotePicture' width={remToPx(6)} height={remToPx(6)}/>
                        <div className='quoteTextContainer'>
                            <label className='quoteTitle'>Adriaan (financieel analist):</label>
                            <p className='quote'>"Gemiddeld kan een gezin € 400,- per jaar besparen op verzekeringen.
                                Weet
                                jij hoeveel jij kunt besparen?”</p>
                        </div>
                    </div>
                    <p className='scanDescription'>
                        De verzekeringsscan wordt persoonlijk gemaakt door een onafhankelijke verzekeringsexpert en
                        wordt
                        tijdelijk gratis aangeboden bij het activeren van een ByDeHand-account!
                    </p>
                    <p className='scanQuestion'>Wil je gebruik maken van deze gratis verzekeringsscan?*</p>
                    <div className='decisionContainer'>
                        <MaterialFactory componentType={materialTypes.RAISED_BUTTON}
                                         onClick={this.onClickDecision('yes')}>Ja,
                            graag</MaterialFactory>
                        <MaterialFactory componentType={materialTypes.RAISED_BUTTON}
                                         onClick={this.onClickDecision('no')}>Nee,
                            bedankt</MaterialFactory>
                    </div>
                </div>
                <p className='insurances'>* Opstalverzekering, inboedelverzekering, autoverzekering, reisverzekering,
                    rechtsbijstand, aansprakelijkheidsverzekering</p>
            </>
        )
    };
}

InsuranceScanView.propTypes = {
    onClickDecision: PropTypes.func.isRequired,
    saving: PropTypes.bool.isRequired,
};