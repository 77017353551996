import './style/healthAnalysis.scss';

import React from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

import {getAdditional, getByBasic} from 'BdhCommon/data/mappings/healthInsuranceMapping';

import CurrentInsuranceController from './currentInsurance/currentInsuranceController';
import EvaluationController from './evaluation/evaluationController';
import EditInsuranceController from './editInsurance/editInsuranceController';
import NextInsuranceController from './nextInsurance/nextInsuranceController';
import MenuController from "./menu/menuController";
import CustomRedirect from '../../misc/customRedirect';
import {getFromRegistry, registerObserver} from "./misc/registry";
import StateComponent from "../../misc/stateComponent";
import QuickScanController from "./quickScan/quickScanController";

export class HealthAnalysisView extends StateComponent {
    constructor(props) {
        registerObserver(registry => {
            this.changeState({currentInsurance: registry.healthInsurance.evaluation});
        });
        let currentInsurance = getFromRegistry(['healthInsurance', 'evaluation']);
        super(props);
        this.state = {
            currentInsurance,
            currentLocation: null,
        }
    }

    createHeader = () => {
        const pageTitleParts = this.createPageTitleParts();
        const currentLocation = window.location.pathname;
        const isLastStep = currentLocation.includes('jouw-verzekering');
        const pageTitle = pageTitleParts[0] + (!isLastStep && pageTitleParts.length > 1 ? ' ' + pageTitleParts[1] : '')
            + ':';
        return (
            <div className="contentHeader">
                <label className="pageTitle">{pageTitle}</label>
                {this.createHeaderInsuranceBar()}
            </div>
        );
    };

    createHeaderInsuranceBar = () => {
        let currentInsurance = this.state.currentInsurance;
        if (isNotNull(currentInsurance) && isNotNull(currentInsurance.basic) && isNotNull(currentInsurance.basic.risk)) {
            let headerLabel = 'Jouw verzekering: ' + currentInsurance.basic.insurance;
            const currentLocation = window.location.pathname;
            if (currentLocation.includes('jouw-verzekering')) {
                const pageTitleParts = this.createPageTitleParts();
                headerLabel = pageTitleParts[1];
            } else {
                if (isNotNull(currentInsurance.additionals)) {
                    if (currentInsurance.additionals.length === 1 && isNotNull(getAdditional(currentInsurance.additionals[0].name))) {
                        headerLabel += ' + ' + getAdditional(currentInsurance.additionals[0].name).label;
                    } else if (currentInsurance.additionals.length === 2) {
                        headerLabel += ' + ' + currentInsurance.additionals.map(additional => (
                            getAdditional(additional.name).label)).join(' + ');
                    } else {
                        headerLabel += ' + ' + currentInsurance.additionals.length + ' + losse modules';
                    }
                }
                headerLabel += '. Eigen risico \u20ac ' + currentInsurance.basic.risk + ',-';
            }

            return (
                <div id='headerInsuranceContainer'
                     className='headerInsuranceContainer'>
                    <label className="headerInsurance">{headerLabel}</label>
                </div>
            );
        }
        return null;
    };

    createPageTitleParts = () => {
        let pageTitleMap = {
            'huidige-verzekering': 'Huidige verzekering',
            'evaluatie': 'Evaluatie',
            'basis-verzekering': 'Basisverzekering',
            'aanvullende-verzekering': 'Aanvullende verzekering',
            'eigen-risico': 'Eigen risico',
            'jouw-verzekeraar': 'Jouw verzekeraar',
            'samenvatting': 'Samenvatting',
            'aanpassen': 'Aanpassingen',
            'dekking': 'Gewenste dekking',
            'vergelijken': 'Vergelijken',
            'jouw-verzekering': 'Jouw verzekering 2020',
            'afsluiten': 'Afsluiten',
            'dossier-inrichten': 'Dossier inrichten',
        };
        let location = this.props.location.pathname;
        let titleParts = location.substring('/zorganalyse'.length + 1, location.length).split('/');
        let pageTitleParts = [pageTitleMap[titleParts[0]]];
        if (titleParts.length > 1) pageTitleParts.push(pageTitleMap[titleParts[1]]);
        return pageTitleParts;
    };

    createContent = (component) => (
        <div>
            <div className="menu">
                <MenuController healthState={this.props.healthState}/>
            </div>
            <div className="contentContainer">
                {this.createHeader()}
                <div className="content">
                    {component}
                </div>
            </div>
        </div>
    );

    activateFbShare = () => {
        let popup = document.getElementsByClassName('fbSharePopupContainer')[0];
        if (isNotNull(popup)) {
            popup.classList.add('active');
            popup = popup.getElementsByClassName('fbSharePopup')[0];
            let doc = document.documentElement;
            let htmlElement = document.getElementsByTagName('html')[0];
            let fontSize = window.getComputedStyle(htmlElement, null).getPropertyValue('font-size');
            fontSize = fontSize.slice(0, fontSize.length - 2);
            let top = ((window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)) / fontSize;
            popup.style.top = (top + 2) + 'rem';
        }
    };

    componentDidMount = () => {
        this.mount();
    };

    componentWillUnmount = () => {
        this.unMount();
    };

    render = () => {
        return (
            <div className='healthInsuranceAnalysis'>
                <Switch>
                    <Route exact path={'/zorganalyse/'} render={() => {
                        return <CustomRedirect to={'/zorganalyse/huidige-verzekering'}/>;
                        // return <Redirect to={'/zorganalyse/huidige-verzekering'}/>;
                    }}/>
                    <Route exact path={'/quickscanzorgverzekering'} render={() => {
                        return <QuickScanController/>;
                    }}/>
                    <Route path='/zorganalyse/huidige-verzekering/:goal?' render={() => { /* Goal = registrer or login*/
                        return <CurrentInsuranceController/>;
                    }}/>
                    <Route path='/zorganalyse/evaluatie' render={() => {
                        return this.createContent(<EvaluationController/>);
                    }}/>
                    <Route path='/zorganalyse/aanpassen' render={() => {
                        return this.createContent(<EditInsuranceController activateFbShare={this.activateFbShare}/>);
                    }}/>
                    <Route path='/zorganalyse/jouw-verzekering' render={() => {
                        return this.createContent(<NextInsuranceController/>);
                    }}/>
                </Switch>
            </div>
        )
    }
}

HealthAnalysisView.propTypes = {
    currentInsurance: PropTypes.shape({
        insurer: PropTypes.string.isRequired,
        basic: PropTypes.string.isRequired,
        additional: PropTypes.arrayOf(PropTypes.string),
        dental: PropTypes.string,
        risk: PropTypes.number.isRequired,
    }),
};

export default withRouter(HealthAnalysisView);
