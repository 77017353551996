import React from 'react';

import {deviceIsMobile} from 'glob-common-js/lib/utils';

import StateComponent from "../../misc/stateComponent";
import {financialOnboardingSteps} from "../manager/financialManager";
import {addCustomEventListener, removeCustomEventListener} from "../../../misc/eventDispatcher";
import {getOnboardingManager} from "../manager/onboardingManagerHelper";
import FinancialOnboardingView from "./financialOnboardingView";
import MobileFinancialOnboardingView from "./mobileFinancialOnboardingView";

export default class FinancialOnboardingController extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            step: financialOnboardingSteps.INTRO_STEP,
        }
    }

    onEvent = params => {
        const {type, data} = params;
        switch (type) {
            case 'activate':
                const step = pathIsNotNull(data, 'step') ? data.step : financialOnboardingSteps.INTRO_STEP;
                this.changeState({step, active: true});
                break;
            case 'setStep':
                this.changeState({step: data.step});
                break;
            case 'deactivate':
                this.changeState({active: false});
                break;
        }
    };

    onNextStep = (removeHighlights = false) => () => {
        const manager = getOnboardingManager('financial');
        if (removeHighlights)
            manager.removeAllHighlights();
        manager.setNextStep();
    };

    onClose = () => {
        this.changeState({active: false});
    };

    componentDidMount = () => {
        this.mount();
        addCustomEventListener('financialOnboardingFlow', this.onEvent);
    };

    componentWillUnmount = () => {
        this.unMount();
        removeCustomEventListener('financialOnboardingFlow', this.onEvent);
    };

    render = () => {
        const {active, step} = this.state;
        if (active) {
            if (deviceIsMobile())
                return <MobileFinancialOnboardingView onClose={this.onClose}/>;

            return <FinancialOnboardingView step={step} onNextStep={this.onNextStep} ref={refName => {
                this.view = refName
            }}/>;
        }
        return null;
    };
}