import './style/editInsurance.scss';

import React from 'react';
import PropTypes from 'prop-types';

import {EditInsuranceView} from "./editInsuranceView";

export default class EditInsuranceController extends React.Component {
    constructor(props) {
        super(props);
    }

    render = () => {
        return (
            <EditInsuranceView activateFbShare={this.props.activateFbShare}/>
        )
    };
};

EditInsuranceController.propTypes = {
    activateFbShare: PropTypes.func.isRequired,
};