import './style/forgotPassword.scss';

import React from 'react';
import PropTypes from 'prop-types';
import {applyMiddleware, createStore} from 'redux';
import {combineForms} from 'react-redux-form';
import thunk from 'redux-thunk';

import {isValidEmail} from 'glob-common-js/lib/utils';

import {navigate} from "../../../misc/utils";
import GenericLoader from "../../misc/genericLoader";
import MaterialFactory from "../../material/materialFactory";
import {materialTypes} from "../../material/materialTypes";
import BdhInput from "../../customControls/input/bdhInput";

const logo = require('BdhCommon/images/logo_bydehand.svg');

export default class ForgotPasswordView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: this.props.defaultEmail,
            error: null,
        };
        this._isMounted = false;

        this.initialState = {
            email: '',
        };
        this.store = createStore(combineForms({user: this.initialState}), applyMiddleware(thunk));
    }

    changeState = stateChange => {
        this._isMounted && this.setState(stateChange);
    };

    onSubmit = () => {
        const email = this.state.email;
        if (!isValidEmail(email))
            this.changeState({error: 'Vul een geldig e-mailadres in'});
        else {
            this.props.onSubmit(email);
        }
    };

    onChange = (value) => {
        this.changeState({email: value});
    };

    onKeyDown = evt => {
        const enterCode = 13;
        if (evt.keyCode === enterCode) {
            this.onSubmit();
        }
    };

    onBackClick = () => {
        navigate('/auth/login');
    };

    toggleEnterListener = (add = true) => {
        const field = document.getElementById('forgotPassMail');
        if (isNotNull(field)) {
            const toggleListener = add ? field.addEventListener || field.attachEvent :
                field.removeEventListener || field.detachEvent;
            toggleListener('keydown', this.onKeyDown);
        }
    };

    renderConfirmation = () => (
        <div className='confirmRequest'>
            <div className='logoContainer'>
                <img className='logo' src={logo}/>
            </div>
            <div className='textContainer'>
                <p className='bold'>Gelukt!</p>
                <p className='bold'>Open je mail en klik op de link om je wachtwoord te wijzigen!</p>
                <p className='mTop'>Het kan even duren voor de mail aankomt. Heb je geen mail ontvangen? Controleer voor
                    de zekerheid je spam box.</p>
            </div>
            <div className='navButtonContainer'>
                {this.renderMailButton()}
                <button className='navButton' onClick={this.onBackClick}>Terug</button>
            </div>
        </div>
    );

    renderMailButton = () => {
        let regMail = this.state.email;
        if (isNotNull(regMail) && regMail.includes('@')) {
            let domain = regMail.substr(regMail.indexOf('@') + 1, regMail.length);
            switch (domain) {
                case'gmail.com':
                    return (<a className='navButton' href='//mail.google.com' target='_blank'>Open Gmail</a>);
                case 'hotmail.com':
                case 'hotmail.nl':
                    return (<a className='navButton' href='//outlook.live.com' target='_blank'>Open Hotmail</a>);
                case 'outlook.com':
                case 'outlook.nl':
                case 'live.nl':
                    return (<a className='navButton' href='//outlook.live.com' target='_blank'>Open Outlook</a>);
            }
        }
        return (
            <>
                <a className='navButton' href='//mail.google.com' target='_blank'>Open Gmail</a>
                <a className='navButton' href='//outlook.live.com' target='_blank'>Open Outlook</a>
                <a className='navButton' href='//outlook.live.com' target='_blank'>Open Hotmail</a>
            </>
        );
    };

    componentDidMount = () => {
        this._isMounted = true;
        this.toggleEnterListener();
    };

    componentWillUnmount = () => {
        this._isMounted = false;
        this.toggleEnterListener(false);
    };

    render = () => {
        const {email, error} = this.state;
        return (
            <div className='forgotPassword'>
                <div className='formContainer'>
                    <GenericLoader active={this.props.loading} backgroundClassName='forgotPasswordLoader'/>
                    <label className='formTitle'>Wachtwoord vergeten</label>
                    {this.props.requestConfirmed ?
                        this.renderConfirmation() :
                        <>
                            <p className='description'>Vul hieronder het e-mailadres in waarmee je je geregistreerd heb.
                                Je krijgt dan een e-mail met instructies om een nieuw wachtwoord in te stellen. </p>
                            <div className='emailForm'>
                                <BdhInput label='E-mailadres' onChange={this.onChange} value={email} error={error}
                                          onKeyDown={this.onKeyDown} fullWidth onFocus={() => {
                                    this.changeState({error: null});
                                }} containerClass='emailField'/>
                                {/*<MaterialFactory componentType={materialTypes.TEXT} label='E-mailadres'*/}
                                {/*onChange={this.onChange} value={this.state.email}*/}
                                {/*helperText={this.state.error} error={isNotNull(this.state.error)}*/}
                                {/*id='forgotPassMail' fullWidth*/}
                                {/*margin='normal'*/}
                                {/*onFocus={() => {*/}
                                {/*this.changeState({error: null});*/}
                                {/*}}/>*/}
                                <MaterialFactory componentType={materialTypes.RAISED_BUTTON}
                                                 onClick={this.onSubmit}>Versturen</MaterialFactory>
                            </div>
                            {this.props.containsError &&
                            <label className='errors'>Er is iets niet goed gegaan</label>}
                        </>
                    }
                </div>
            </div>
        )
    }
}

ForgotPasswordView.propTypes = {
    requestConfirmed: PropTypes.bool.isRequired,
    containsError: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    defaultEmail: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
};