import './style/checklistView.scss';

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {withStyles} from '@material-ui/core/styles';

import {deviceIsMobile, toMoment} from 'glob-common-js/lib/utils';

import {sendGaEvent} from "../../common/js/ga";
import CustomScrollbar from "../misc/customScrollbar";
import {materialTypes} from "../material/materialTypes";
import MaterialFactory from "../material/materialFactory";
import {NOW} from "../misc/constants";
import StateComponent from "../misc/stateComponent";
import ChecklistMobileView from "./v2/mobile/checklistMobileView";
import ChecklistDesktopView from "./v2/desktop/checklistDesktopView";
import {dispatchCustomEvent} from "../../misc/eventDispatcher";

import { PDFDownloadLink } from "@react-pdf/renderer";
import ChecklistPDF from './checklistPDF'

const printPDFButton = {
  textDecoration: "none",
  padding: "10px",
  color: "#FFFFFF",
  backgroundColor: "#009FE3",
  borderTopLeftRadius: "4px",
  borderTopRightRadius: "4px",
  borderBottomRightRadius: "4px",
  borderBottomLeftRadius: "4px"
}


const styles = () => ({
    selectRoot: {
        minWidth: '10rem',
    },
    paperRoot: {
        width: '75%',
        overflowX: 'visible',
        height: '25rem',
        overflowY: 'auto',
        '@media (max-width: 599px)': {
            display: 'none',
        }
    },
    tableRoot: {
        width: '100%',
    },
    tableBodyRoot: {
        height: '30rem',
        overflowY: 'auto',
    }
});

class ChecklistView extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            frameActive: false,
            popoverElement: null,
            reminder: {
                open: false,
                date: NOW().format('YYYY-MM-DDTHH:mm:ss'),
                description: '',
                name: '',
                email: {
                    toggled: false,
                    value: props.email,
                },
                sms: {
                    toggled: false,
                    value: props.phone_mobile,
                },
            },
        };
    }

    onClickInfo = () => {
        sendGaEvent(this.props.GA_CATEGORY, 'Open filmpje', 'Documenten');
        this.changeState({frameActive: true});
    };

    onCloseInfo = () => {
        sendGaEvent(this.props.GA_CATEGORY, 'Sluit filmpje', 'Documenten');
        this.changeState({frameActive: false});
    };

    onOpenReminder = label => evt => {
        this.changeState({
            popoverElement: evt.currentTarget,
            reminder: {
                ...this.state.reminder,
                open: true,
                name: label,
            }
        })
    };

    onReminderDateChange = date => {
        const stateDate = toMoment(this.state.reminder.date), hours = stateDate.hours(), minutes = stateDate.minutes();
        date = moment(date);
        date.hours(hours);
        date.minutes(minutes);
        this.changeState({
            reminder: {
                ...this.state.reminder,
                date: date.format('YYYY-MM-DDTHH:mm:ss'),
            }
        });
    };

    onReminderValueChange = (name, value) => {
        this.changeState({
            reminder: {
                ...this.state.reminder,
                [name]: value,
            }
        });
    };

    onSelectChecklist = evt => {
        const {onNewChecklist, onSelectChecklist} = this.props;
        const {value} = evt.target;
        if (value === 'new_dossier') onNewChecklist();
        else onSelectChecklist(value);
    };

    handleRequestClose = () => {
        this.changeState({
            popoverElement: null,
            reminder: {
                ...this.state.reminder,
                open: false,
                name: '',
            },
        });
    };

    onToggleReminder = type => {
        const {reminder} = this.state;
        this.changeState({
            reminder: {
                ...reminder,
                [type]: {
                    ...reminder[type],
                    toggled: !reminder[type].toggled,
                }
            }
        })
    };

    onReminderDetailChange = (type, name, value) => {
        const {reminder} = this.state;
        this.changeState({
            reminder: {
                ...reminder,
                [type]: {
                    ...reminder[type],
                    [name]: value,
                }
            }
        })
    };

    onSaveReminder = () => {
        const {reminder} = this.state, data = {
            name: reminder.name,
            description: reminder.description,
            remindDate: reminder.date,
            sms: reminder.sms,
            email: reminder.email,
        };
        this.props.onSaveReminder(data, this.handleRequestClose);
    };

    onSaveChecklist = () => {
        const {hasChanges, onSaveChecklist} = this.props;
        if (hasChanges) onSaveChecklist(true);
        else dispatchCustomEvent('openSnackbar', {text: 'Checklist is opgeslagen'});
    };

    getDocuments = () => {
        let {currentChecklist, checklists} = this.props;
        for (let i = 0; i < checklists.length; i++)
            if (checklists[i].id === currentChecklist)
                return checklists[i].documents;
        return [];
    };

    renderChecklistSelector = () => {
        const {checklists, currentChecklist, classes} = this.props;
        let values = [{
            label: 'Maak een nieuwe checklist...',
            value: 'new_dossier',
        }];
        if (isNotNull(checklists))
            values = checklists.map(checklist => ({
                label: checklist.name,
                value: checklist.id,
            })).concat(values);
        return (
            <MaterialFactory componentType={materialTypes.SELECT} label='Kies een checklist'
                             value={currentChecklist === 'new' ? '' : currentChecklist}
                             items={values} onChange={this.onSelectChecklist}
                             classes={{root: classes.selectRoot}}/>
        );
    };

    renderChecklistName = () => {
        const {currentChecklist, checklists} = this.props;
        if (currentChecklist !== 'new' && isNotNull(checklists)) {
            for (let i = 0; i < checklists.length; i++)
                if (checklists[i].id === currentChecklist) return checklists[i].name;
        }
        return null;
    };

    renderDocument = doc => (
        <div key={doc.id} className='checklistDocument' onClick={() => {
            this.props.onDocumentClick(doc)
        }}>
            <div className='iconContainer'>
                <span className='common-icon-document-2 documentIcon'/>
            </div>
            <label className='documentLabel' title={doc.name}>{doc.name}</label>
            <label className='documentTypeLabel' title={doc.type.label}>{doc.type.label}</label>
        </div>
    );

    renderPopover = () => {
        const {reminder, popoverElement} = this.state;
        return (
            <MaterialFactory componentType={materialTypes.MENU} onClose={this.handleRequestClose} open={reminder.open}
                             anchorEl={popoverElement}>
                <MaterialFactory componentType={materialTypes.MENU_ITEM} className='extraHeight'>
                    <div className='dateField'>
                        <MaterialFactory componentType={materialTypes.DATE_PICKER} label='Datum'
                                         value={reminder.date} onChange={this.onReminderDateChange}/>
                        <MaterialFactory componentType={materialTypes.TIME_PICKER} label='Tijd'
                                         value={reminder.date} onChange={date => {
                            this.onReminderValueChange('date', date);
                        }}/>
                    </div>
                </MaterialFactory>
                <MaterialFactory componentType={materialTypes.MENU_ITEM}>
                    <MaterialFactory componentType={materialTypes.TEXT} label='Omschrijving (optioneel)'
                                     maxLength={200} multiline fullWidth onChange={(evt) => {
                        this.onReminderValueChange('description', evt.target.value);
                    }}/>
                </MaterialFactory>
                <MaterialFactory componentType={materialTypes.MENU_ITEM}>
                    <div className='reminderIconContainer' onClick={() => {
                        this.onToggleReminder('email');
                    }}>
                        <span className='common-icon-email reminderIcon'/>
                        {reminder.email.toggled && <span className='common-icon-check checkIcon'/>}
                    </div>
                    <div className='reminderIconContainer' onClick={() => {
                        this.onToggleReminder('sms');
                    }}>
                        <span className='common-icon-sms2 reminderIcon'/>
                        {reminder.sms.toggled && <span className='common-icon-check checkIcon'/>}
                    </div>
                </MaterialFactory>
                {reminder.email.toggled &&
                <MaterialFactory componentType={materialTypes.MENU_ITEM}>
                    <MaterialFactory componentType={materialTypes.TEXT} label='E-mailadres'
                                     value={reminder.email.value} fullWidth onChange={(evt) => {
                        this.onReminderDetailChange('email', 'value', evt.target.value);
                    }}/>
                </MaterialFactory>}
                {reminder.sms.toggled &&
                <MaterialFactory componentType={materialTypes.MENU_ITEM}>
                    <MaterialFactory componentType={materialTypes.TEXT} label='Mobiel nummer'
                                     value={reminder.sms.value} fullWidth onChange={(evt) => {
                        this.onReminderDetailChange('sms', 'value', evt.target.value);
                    }}/>
                </MaterialFactory>}
                <MaterialFactory componentType={materialTypes.MENU_ITEM}>
                    <div className='popoverButtonContainer'>
                        <MaterialFactory componentType={materialTypes.FLAT_BUTTON} color='primary'
                                         onClick={this.onSaveReminder}>Ok</MaterialFactory>
                        <MaterialFactory componentType={materialTypes.FLAT_BUTTON}
                                         onClick={this.handleRequestClose}>Sluit</MaterialFactory>
                    </div>
                </MaterialFactory>
            </MaterialFactory>
        );
    };

    componentWillReceiveProps = nextProps => {
        const {email, phone_mobile} = this.props, nextEmail = nextProps.email, next_phone = nextProps.phone_mobile;
        if (email !== nextEmail || phone_mobile !== next_phone)
            this.changeState({
                reminder: {
                    ...this.state.reminder,
                    sms: {
                        ...this.state.reminder.sms,
                        value: phone_mobile,
                    },
                    email: {
                        ...this.state.reminder.email,
                        value: email,
                    }
                }
            });
    };

    componentDidMount = () => {
        this.mount();
    };

    componentWillUnmount = () => {
        this.unMount();
    };

    render = () => {
        const {
            onNewChecklist, currentChecklist, onDeleteChecklist, selectedValues, categories, onChange,
            onUploadDocument, onClickLink, onClickBack, hasChanges, checkboxValues, onChangeBoxValue
        } = this.props;
        const {frameActive} = this.state;
        const documents = this.getDocuments();
        const checklistName = this.renderChecklistName()
        const viewProps = {
            categories,
            selectedItems: selectedValues,
            checkboxValues,
            onCloseExplanation: this.onCloseInfo,
            onOpenExplanation: this.onClickInfo,
            explanationActive: frameActive,
            onCheckItem: onChange,
            onClickDocument: onUploadDocument,
            onClickReminder: this.onOpenReminder,
            onClickLink,
            onChangeBoxValue,
            checklistId: currentChecklist,
            checklistName,
        };

        return (
            <div className='checklistView'>
                <div className='backContainer'>
                    <MaterialFactory componentType={materialTypes.RAISED_BUTTON} onClick={onClickBack}>
                        &#8592; Terug naar overzicht</MaterialFactory>
                </div>
                <div className='checklistHeader'>
                    <div className='selectorContainer'>
                        {this.renderChecklistSelector()}
                        <span className='common-icon-plus addButton' onClick={onNewChecklist}/>
                        <MaterialFactory componentType={materialTypes.RAISED_BUTTON} onClick={this.onSaveChecklist}
                                         className='saveButton' disabled={!hasChanges}>
                            {hasChanges ? 'Opslaan' : 'Opgeslagen'}</MaterialFactory>
                    </div>
                    {true && <PDFDownloadLink
                                document={<ChecklistPDF data = {viewProps} />}
                                fileName="checklist.pdf"
                                style={printPDFButton} >
                                {({ blob, url, loading, error }) => loading ? "Loading document..." : "PRINT PDF"        }
                    </PDFDownloadLink>}
                    {currentChecklist === 'new' ?
                        <div className='saveInfo'>
                            <span className='common-icon-info saveInfoIcon'/>
                            <label className='saveInfoMessage'>Je aangevinkte acties worden opgeslagen in een
                                nieuwe checklist zodra je de pagina verlaat.</label>
                        </div> :
                        <div className='nameContainer'>
                            <label className='selectedChecklistName'>{this.renderChecklistName()}</label>
                            <span className='common-icon-trash deleteButton' onClick={onDeleteChecklist}/>
                        </div>
                    }
                </div>
                <div className='checklistBody'>
                    {this.renderPopover()}
                    {deviceIsMobile() ? <ChecklistMobileView {...viewProps}/> : <ChecklistDesktopView {...viewProps}/>}
                </div>
                {isNotNull(documents) &&
                <div className='documentsContainer'>
                    <label className='documentContainerHeader'>Gekoppelde documenten</label>
                    <CustomScrollbar height='15rem'>
                        <div className='documents'>
                            {documents.map(this.renderDocument)}
                        </div>
                    </CustomScrollbar>
                </div>}
            </div>
        )
    };
}

ChecklistView.propTypes = {
    onChange: PropTypes.func.isRequired,
    onClickLink: PropTypes.func.isRequired,
    onUploadDocument: PropTypes.func.isRequired,
    onSelectChecklist: PropTypes.func.isRequired,
    onNewChecklist: PropTypes.func.isRequired,
    onDeleteChecklist: PropTypes.func.isRequired,
    onDocumentClick: PropTypes.func.isRequired,
    onSaveReminder: PropTypes.func.isRequired,
    onClickBack: PropTypes.func.isRequired,
    onSaveChecklist: PropTypes.func.isRequired,
    onChangeBoxValue: PropTypes.func.isRequired,
    categories: PropTypes.array.isRequired,
    checklists: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        files: PropTypes.array.isRequired,
    })).isRequired,
    GA_CATEGORY: PropTypes.string.isRequired,
    currentChecklist: PropTypes.string.isRequired,
    selectedValues: PropTypes.arrayOf(PropTypes.string).isRequired,
    checkboxValues: PropTypes.object.isRequired,
    email: PropTypes.string,
    phone_mobile: PropTypes.string,
    hasChanges: PropTypes.bool.isRequired,
};

ChecklistView.defaultProps = {
    email: '',
    phone_mobile: '',
};

export default withStyles(styles)(ChecklistView);
