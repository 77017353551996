import * as Cookies from 'js-cookie';
import moment from 'moment';

import sendRequest from './request';
import {appSettings} from "./settings";

const uuid = require('uuid/v4');

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


/**
 * Log a message to the console when the build mode is not production.
 *
 * The override parameter is used to override the mode check. If true, this function always logs to the console.
 */
export const log = (message, params, override = false) => {
    if (getSetting('mode') !== 'prod' || override) {
        if (isNotNull(params)) console.log(message, params);
        else console.log(message);
    }
};

/**
 * Warn a message when the build mode is not production.
 * @param message
 * @param params Optional log params
 * @param override Overrides the mode check if true.
 */
export const warn = (message, params, override = false) => {
    if (getSetting('mode') !== 'prod' || override) {
        if (isNotNull(params)) console.warn(message, params);
        else console.warn(message);
    }
};

/**
 * Debug a message when the build mode is not production.
 * @param message
 * @param params Optional log params
 * @param override Overrides the mode check if true.
 */
export const debug = (message, params, override = false) => {
    if (getSetting('mode') !== 'prod' || override) {
        if (isNotNull(params)) console.debug('DEBUG: ' + message, params);
        else console.debug('DEBUG: ' + message);
    }
};

export const filterListDuplicates = (array) => {
    if (Array.isArray(array)) {
        let filteredList = [];
        for (let i = 0; i < array.length; i++) {
            if (filteredList.indexOf(array[i])) {
                filteredList.push(array[i]);
            }
        }
        return filteredList;
    } else {
        utils.warn('Given list is not defined or not an array');
        return array;
    }
};
/**
 * Test if given variable is not undefined or null, and if variable has a length if the length is bigger than 0.
 * @param variable
 * @returns {boolean}
 */
export const isNotNull = variable => {
    return variable !== undefined && variable !== null && (variable.hasOwnProperty('length') ? variable.length > 0 : true);
};

export const isNull = variable => !isNotNull(variable);


/**
 * Checks if all the supplied arguments are not undefined or null using the isNotNull function.
 */
export const areNotNull = (...variables) => {
    if (Array.isArray(variables)) {
        for (let i = 0; i < variables.length; i++) {
            if (!isNotNull(variables[i])) return false;
        }
        return true;
    }
    return false;
};

export const pathIsNotNull = (obj, path) => {
    if (isNotNull(obj) && isNotNull(path)) {
        let steps = path.split('.');
        for (let i = 0; i < steps.length; i++) {
            if (!isNotNull(obj) || !obj.hasOwnProperty(steps[i])) return false;
            obj = obj[steps[i]];
        }
        return isNotNull(obj);
    }
    return false;
};

export const objectHasProperties = (object, ...properties) => {
    for (let i = 0; i < properties.length; i++) {
        if (!object.hasOwnProperty(properties[i])) {
            return false;
        }
    }
    return true;
};

export const isPhone = (screenWidth) => {
    return isNotNull(screenWidth) && screenWidth <= 599;
};

export const deviceIsMobile = () => {
    return window.matchMedia('(max-width: 599px)').matches;
};

export const getCookie = name => {
    return Cookies.get(name);
};

export const setCookie = (name, value) => {
    Cookies.set(name, value);
};

export const removeCookie = (name, options) => {
    Cookies.remove(name, options);
    if (isNotNull(getCookie(name))) {
        log('Removing cookie ' + name + ' failed.');
    } else {
        log('Cookie ' + name + ' succesfully removed.');
    }
};

export const getSetting = name => {
    if (isNotNull(appSettings)) {
        if (appSettings.hasOwnProperty(name))
            return appSettings[name];
        else throw new Error('Global settings object does not have field \'' + name + '\'');
    } else throw new Error(
        'Global settings are not initialized. Use initializeSettings() (settings.js) to initialize the settings.');
};

// Safari doesn't support the Object.values function, so we need this check/polyfill for the Object.values function.
export const getObjectValues = object => {
    if (Object.values === undefined) {
        return Object.keys(object).map(key => (object[key]));
    }
    return Object.values(object);
};

/**
 * Ref: https://stackoverflow.com/questions/11233498/json-stringify-without-quotes-on-properties
 * It will do the same as JSON.stringify() but will not put any quotes around the key.
 */
export const simpleJsonStringify = obj_from_json => {
    if (typeof obj_from_json !== "object" || Array.isArray(obj_from_json)) {
        // not an object, stringify using native function
        return JSON.stringify(obj_from_json);
    }

    // Implements recursive object serialization according to JSON spec
    // but without quotes around the keys.
    let props = Object
        .keys(obj_from_json)
        .map(key => `${key}:${simpleJsonStringify(obj_from_json[key])}`)
        .join(",");
    return `{${props}}`;
};

export const capitalizeFirst = string => {
    return string.substring(0, 1).toUpperCase() + string.substring(1, string.length);
};

export const stringStartsWith = (string, value) => {
    value = value.trim().toLowerCase();
    let valueLength = value.length;
    return string.toLowerCase().slice(0, valueLength) === value;
};

export const stringContains = (string, value) => {
    return string.toLowerCase().indexOf(value.toLowerCase()) > -1;
};

export const pad = (value) => {
    return ("0" + value).slice(-2)
};

let nextKey = 0;

export const generateRandomKey = () => {
    return nextKey++;
};

let scrollCallback = null;
export const scrollToTop = (callback) => {
    window.scrollTo({top: 0, behavior: "smooth"});
    // if (!isFunction(scrollCallback) && isFunction(callback)) scrollCallback = callback;
    // const c = document.documentElement.scrollTop || document.body.scrollTop;
    // if (c > 0) {
    //     window.requestAnimationFrame(scrollToTop);
    //     window.scrollTo(0, c - c / 8);
    // } else if (isFunction(scrollCallback)) {
    //     scrollCallback();
    //     scrollCallback = null;
    // }
};

export const scrollToBottom = (padding, callback) => {
    scrollToBottomInternal(padding, callback)();
};

const scrollToBottomInternal = (padding = 0, callback) => () => {
    const c = document.documentElement.scrollTop || document.body.scrollTop,
        end = Math.max(document.body.scrollHeight, document.body.offsetHeight,
            document.documentElement.clientHeight, document.documentElement.scrollHeight,
            document.documentElement.offsetHeight) - window.innerHeight;
    window.scrollTo({top: end, behavior: "smooth"});
    // if (c < end - padding) {
    //     window.requestAnimationFrame(scrollToBottomInternal(padding, callback));
    //     window.scrollTo(0, c + Math.max(c / 8, 8));
    // } else if (isFunction(callback)) {
    //     callback();
    // }

};

export const scrollToY = (y, callback = null) => {
    const body = document.documentElement || document.body, c = body.scrollTop,
        limit = Math.max(document.body.scrollHeight, document.body.offsetHeight,
            document.documentElement.clientHeight, document.documentElement.scrollHeight,
            document.documentElement.offsetHeight) - window.innerHeight;
    if (y > limit) y = limit;
    if (c < y) scrollDownToY(y, callback)();
    else if (c > y) scrollUpToY(y, callback)();
};

const scrollUpToY = (y, callback) => () => {
    const body = document.documentElement || document.body, c = body.scrollTop;
    if (isFunction(callback)) {
        if (c > y) {
            const distance = Math.abs(c - y), speed = Math.max(Math.min(distance / 20, 8), 1), nextC = c - c / speed;
            window.requestAnimationFrame(scrollUpToY(y));
            window.scrollTo(0, nextC);
        } else callback();
    } else
        body.scrollTo({top: y, behavior: "smooth"});
};

const scrollDownToY = (y, callback) => () => {
    const body = document.documentElement || document.body, c = body.scrollTop;
    if (isFunction(callback)) {
        if (c < y) {
            const distance = Math.abs(c - y), speed = 8 / (1 / distance),
                nextC = c < 1 ? 8 : c + Math.max(c / speed, 8);
            window.requestAnimationFrame(scrollDownToY(y));
            window.scrollTo(0, nextC);
        } else callback();
    } else
        body.scrollTo({top: y, behavior: "smooth"});
};

const getObjectByName = (name, data) => {
    if (isNotNull(name)) {
        if (data.hasOwnProperty(name)) {
            return data[name];
        } else {
            warn('Object does not contain an entry with name \'' + name + '\'.');
        }
    } else {
        return null;
    }
};

// Review Kadir: Dit is alleen mogelijk voor een input type 'text', maar voor getallen gebruiken we input type 'number'
// 9 van de 10 keer zal dit dus niet werken. Om deze issue op te lossen moet je meer aan een nieuw component denken,
// die zich gedraagt als number input en dit soort formatting kan toepassen.
export const numberSeperator = (number, showSeperated) => {
    // Review Kadir: een split en dan een join, waarom geen replace?
    let numberString = number.toString().split('.').join(""), isNotComma = numberString.indexOf(','),
        // Review Kadir: Heb je deze regex getest? Volgens deze regex is bijvoorbeeld 9.10,1 een getal,
        // bovendien heeft javascript hier gewoon een functie voor (isNan)
        isNumber = /^(?:[\d-]*,?[\d-]*\.?[\d-]*|[\d-]*\.[\d-]*,[\d-]*)$/;

    if (isNumber.test(numberString)) {
        if (isNotNull(showSeperated) && !showSeperated) {
            // Review Kadir, zelfde als hierboven, waarom niet een replace ipv een split + join
            return number.split('.').join("").replace(',', '.');
        } else {
            // Review Kadir, let op webstorm warnings a.u.b., onderstaande regex heeft een onnodige backslash
            return numberString.replace(/\d(?=(?:\d{3})+(?:\,|$)+)/g, (digits, i) => {
                return (isNotComma < 0 || i < isNotComma) ? (digits + '.') : digits;
            });
        }
    } else {
        // Review Kadir, wat als ik in een land ben waar getallen met punten gescheiden worden?
        // Zie https://www.ctrl.blog/entry/html5-input-number-localization
        warn('Input may only contain numbers and only one comma.');
    }
};

export const generateSeperatorInput = (id) => {
    let input = document.getElementById(id);
    if (isNotNull(input)) {
        let number = input.value;
        let formattedNumber = numberSeperator(number);
        if (isNotNull(formattedNumber)) {
            input.value = formattedNumber;
        }
    }
};

export const roundTo = (number, digits) => {
    let negative = false;
    if (digits === undefined) {
        digits = 0;
    }
    if (number < 0) {
        negative = true;
        number = number * -1;
    }
    let multiplicator = Math.pow(10, digits);
    number = parseFloat((number * multiplicator).toFixed(11));
    number = (Math.round(number) / multiplicator).toFixed(digits);
    if (negative) {
        number = (number * -1).toFixed(2);
    }
    return number;
};

export const toMoney = (price, withEuroSign = true, forceDigits = false) => {
    if (!isNotNull(price)) return price;
    if (!forceDigits && (price === 0 || price % 1 === 0)) {
        return (withEuroSign ? "\u20ac " : "") + price + ",-";
    } else {
        return (withEuroSign ? "\u20ac " : "") + roundTo(price, 2).replace(".", ",");
    }
};

export const getFullDate = (date, format = "YYYY-MM-DD") => {
    const generateDate = (input1, input2, input3) => {
        return input1 + "-" + input2 + "-" + input3;
    };

    const pad = number => {
        return ('0' + number).slice((-2));
    };

    let month = pad((new Date(date).getMonth() + 1));
    let day = pad(new Date(date).getDate());
    let year = new Date(date).getFullYear();

    switch (format) {
        case "YYYY-MM-DD":
            return generateDate(year, month, day);
        case "YYYY-DD-MM":
            return generateDate(year, day, month);
        case "DD-MM-YYYY":
            return generateDate(day, month, year);
        case "DD-YYYY-MM":
            return generateDate(day, year, month);
        case "MM-YYYY-DD":
            return generateDate(month, year, day);
        case "MM-DD-YYYY":
            return generateDate(month, day, year);
        default:
            return generateDate(year, month, day)
    }

};

export const isFunction = variable => {
    return typeof variable === 'function';
};

const getCurrentOgTags = () => {
    let metas = document.getElementsByTagName('meta');
    let openGraphArr = [];
    for (let i = 0; i < metas.length; i++) {
        let meta = metas[i];
        if (isNotNull(meta.getAttribute("property"))) {
            meta.getAttribute("property").indexOf('og') !== -1 ? openGraphArr.push(meta) : null;
        }
    }
    return openGraphArr;
}

const deleteOgTags = (tagsArr) => {
    return tagsArr.map((tag) => (tag.parentNode.removeChild(tag)));
}

export const createOgTags = (tagsArr) => {
    let currentOgTags = getCurrentOgTags();
    deleteOgTags(currentOgTags);

    if (isNotNull(tagsArr)) {
        return tagsArr.map((tag) => {
            let property = tag.property;
            property = property.indexOf('og:') === -1 ? 'og:' + property : property;
            let metaElm = document.createElement('meta');
            metaElm.setAttribute("property", property);
            if (isNotNull(tag.content)) {
                metaElm.content = tag.content;
            } else {
                throw new Error('OGtag: Content must be filled');
            }
            isNotNull(tag.content) && isNotNull(tag.property) ? document.getElementsByTagName('head')[0].appendChild(metaElm) : null;
        })
    }

    let reloadUrl = window.location.href;

    const requestConfig = createRequestConfig({
        url: 'https://developers.facebook.com/tools/debug/sharing/?q=' + reloadUrl,
        method: request.GET,
    });

    sendRequest(
        requestConfig, (response) => {
            log(response);
        }
    )
};

export const forEach = (arrayLike, callable) => {
    if (isNotNull(arrayLike)) {
        for (let i = 0; i < arrayLike.length; i++) {
            callable(arrayLike[i]);
        }
    }
};

export const pxToRem = (pxValue) => {
    let htmlElement = document.getElementsByTagName('html')[0];
    let fontSize = window.getComputedStyle(htmlElement, null).getPropertyValue('font-size');
    fontSize = parseInt(fontSize.slice(0, fontSize.length - 2), 10);
    return pxValue / fontSize;
};

export const remToPx = remValue => {
    let htmlElement = document.getElementsByTagName('html')[0];
    let fontSize = window.getComputedStyle(htmlElement, null).getPropertyValue('font-size');
    fontSize = parseInt(fontSize.slice(0, fontSize.length - 2), 10);
    return remValue * fontSize;
};

export const generateUUID = () => {
    // Crypto is not supported by some browsers, so we use another method if crypto is not supported.
    if (window.crypto)
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    return uuid();
};

export const isValidEmail = email => isNotNull(email) && emailRegex.test(email);

export const getListObject = (list, value, ...path) => {
    log('Get list object: ', {list, value, path});
    if (!Array.isArray(list) || !isNotNull(value)) return null;
    if (isNotNull(path)) {
        if (Array.isArray(path)) {
            if (path.length === 1) {
                path = path[0];
                for (let i = 0; i < list.length; i++) {
                    const obj = list[i];
                    if (isNotNull(obj) && obj.hasOwnProperty(path) && obj[path] === value)
                        return obj;
                }
            }

            for (let i = 0; i < list.length; i++) {
                const obj = list[i];
                if (isNotNull(Object.keys(obj))) {
                    const val = findInObject(obj, path);
                    if (isNotNull(val) && val === value)
                        return obj;
                }
            }
        } else {
            for (let i = 0; i < list.length; i++) {
                const obj = list[i];
                if (isNotNull(obj) && obj.hasOwnProperty(path) && obj[path] === value)
                    return obj;
            }
        }
    } else {
        for (let i = 0; i < list.length; i++) {
            if (list[i] === value) return list[i];
        }
    }
    return null;
};

const findInObject = (object, path) => {
    if (!areNotNull(object, path)) return null;
    if (Array.isArray(path)) {
        const param = path[0];
        if (path.length === 1) return findInObject(object, param);
        if (object.hasOwnProperty(param))
            return findInObject(object[param], path.slice(1));
    } else if (object.hasOwnProperty(path)) {
        return object[path];
    }
    return null;
};

export const stringsMatch = (compareToString, ignoreCase, ...strings) => {
    if (!areNotNull(compareToString, strings)) return false;
    let regex = new RegExp(`^${compareToString}$`);
    if (ignoreCase) regex = new RegExp(regex.source, regex.flags + 'i');

    strings.forEach(string => {
        if (!regex.test(string)) return false;
    });
    return true;
};

export const getScreenHeight = () => window.innerHeight || document.documentElement.clientHeight
    || document.body.clientHeight;


const TIME_FORMATS = [
    'MM/DD/YYYY HH:mm',
    'MM-DD-YYYY HH:mm',
    'DD/MM/YYYY HH:mm',
    'DD-MM-YYYY HH:mm',
    'YYYY-MM-DD HH:mm',
    'YYYY/MM/DD HH:mm',
    'DD/MM/YYYYTHH:mm',
    'DD-MM-YYYYTHH:mm',
    'YYYY-MM-DDTHH:mm',
    'YYYY/MM/DDTHH:mm',
    'YYYY-MM-DD',
    'YYYY/MM/DD',
    'MM-DD-YYYY',
    'MM/DD/YYYY',
    'DD-MM-YYYY',
    'DD/MM/YYYY',
];

export const toMoment = (date, format = TIME_FORMATS) => {
    if (moment.isMoment(date))
        return moment(date);
    if (isNotNull(date))
        return moment(date, format);
    return moment();
};

let utils = {
    getFullDate: getFullDate,
    objectHasProperties: objectHasProperties,
    toMoney: toMoney,
    roundTo: roundTo,
    isNotNull: isNotNull,
    areNotNull: areNotNull,
    isNull: isNull,
    pathIsNotNull: pathIsNotNull,
    getCookie: getCookie,
    setCookie: setCookie,
    removeCookie: removeCookie,
    getSetting: getSetting,
    getObjectValues: getObjectValues,
    simpleJsonStringify: simpleJsonStringify,
    capitalizeFirst: capitalizeFirst,
    stringStartsWith: stringStartsWith,
    stringContains: stringContains,
    pad: pad,
    log: log,
    warn: warn,
    debug: debug,
    filterListDuplicates: filterListDuplicates,
    generateRandomKey: generateRandomKey,
    getObjectByName: getObjectByName,
    createOgTags: createOgTags,
    pxToRem: pxToRem,
    remToPx: remToPx,
    generateUUID: generateUUID,
    isPhone: isPhone,
    numberSeperator: numberSeperator,
    generateSeperatorInput: generateSeperatorInput,
    deviceIsMobile: deviceIsMobile,
    isValidEmail: isValidEmail,
    getListObject: getListObject,
    stringsMatch: stringsMatch,
    getScreenHeight: getScreenHeight,
    toMoment: toMoment,
};

export default utils;