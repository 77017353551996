import React from 'react';
import {withRouter} from 'react-router-dom';

import ChangePasswordView from "./changePasswordView";
import ErrorBoundary from "../../errorBoundary";
import {changePassword, responseIsSuccess} from "../../../misc/requestSender";
import {sendGaEvent, sendPageView} from "../../../common/js/ga";
import {navigate, showMessage} from "../../../misc/utils";
import StateComponent from "../../misc/stateComponent";

const GA_CATEGORY = 'Wachtwoord wijzigen';

export class ChangePasswordController extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
        this.passwordKey = null;
    }

    onSubmit = password => {
        sendGaEvent(GA_CATEGORY, 'Klik opslaan', 'opslaan');
        this.changeState({loading: true});
        let data = {
            activation_key: this.passwordKey,
            password,
        };
        changePassword({
            data,
            callback: (response) => {
                this.changeState({loading: false});
                const actions = [];
                let message = '';
                if (responseIsSuccess(response)) {
                    actions.push({label: 'Naar inloggen', onClick: this.onConfirmChange});
                    message = 'Je wachtwoord is gewijzigd, je kan nu met je nieuwe wachtwoord inloggen.';
                } else {
                    actions.push({label: 'Oke'});
                    message = 'Het wijzigen van je wachtwoord is niet gelukt, probeer het later opnieuw of neem ' +
                        'contact met ons op.';
                }
                showMessage(message, actions, 'Wachtwoord wijzigen');
            }
        });
    };

    onConfirmChange = () => {
        navigate('/auth/login');
    };

    componentDidMount = () => {
        this.mount();
        sendPageView('/wachtwoord-wijzigen');
        this.passwordKey = this.props.match.params.passwordKey;
    };

    componentWillUnmount = () => {
        this.unMount();
    };

    render = () => {
        const {loading} = this.state;
        return (
            <ErrorBoundary>
                <ChangePasswordView onSubmit={this.onSubmit} loading={loading}/>
            </ErrorBoundary>
        );
    }

}

export default withRouter(ChangePasswordController);