import './style/evaluationButtons.scss';

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';

import {isNotNull} from 'glob-common-js/lib/utils';

import MaterialFactory from "../../../../../material/materialFactory";
import {materialTypes} from "../../../../../material/materialTypes";

export default class EvaluationButtonsView extends React.Component {
    constructor(props) {
        super(props);
    }

    onButtonClick = (button) => (evt) => {
        evt.persist();
        this.setEvalButtonSelected(evt);
        _.debounce(() => {
            button.onClick();
        }, 300)();
    };

    createButton = (button, key) => {
        const className = classNames('evaluationButton', button.defaultSelected && 'selected');
        return (
            <MaterialFactory componentType={materialTypes.RAISED_BUTTON} key={key} className={className}
                             onClick={this.onButtonClick(button)}>
                {button.buttonText}
            </MaterialFactory>
        );
    };

    setEvalButtonSelected = (evt) => {
        let selectedButton = document.querySelector(".selected");
        if (isNotNull(selectedButton)) {
            selectedButton.classList.remove('selected');
        }
        let target = evt.target;
        if (isNotNull(target)) {
            if (target.tagName.toLowerCase() === 'span')
                target = target.parentElement;
            target.classList.toggle('selected');
        }
    };

    render = () => {
        return (
            <div className='evaluationButtonSection'>
                {isNotNull(this.props.headerTitle) ?
                    <label className='evaluationTitle'>{this.props.headerTitle}</label> : null}
                <div className='evaluationButtons'>
                    {isNotNull(this.props.buttons) ? this.props.buttons.map(this.createButton) : null}
                </div>
            </div>
        );
    }
};

EvaluationButtonsView.propTypes = {
    headerTitle: PropTypes.string,
    buttons: PropTypes.array.isRequired,
};