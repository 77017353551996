import {deviceIsMobile, pxToRem, remToPx, scrollToTop, scrollToY} from 'glob-common-js/lib/utils';

import {dispatchCustomEvent} from "../../../misc/eventDispatcher";
import OnboardingManager, {getNextStep, getPrevStep} from "./onboardingManager";
import {navigate} from "../../../misc/utils";
import {sendGaEvent} from "../../../common/js/ga";

export const storeOnboardingSteps = {
    INTRO_STEP: 'INTRO_STEP',
    SELECT_DOSSIER: 'SELECT_DOSSIER',
    ADD_DOSSIER: 'ADD_DOSSIER',
    CONFIRM_DOSSIER: 'CONFIRM_DOSSIER',
    SELECT_DOCUMENT: 'SELECT_DOCUMENT',
    ADD_DOCUMENT: 'ADD_DOCUMENT',
    CONFIRM_DOCUMENT: 'CONFIRM_DOCUMENT',
    EXPLAIN_DETAILS: 'EXPLAIN_DETAILS',
    NOTIFY_HELP: 'NOTIFY_HELP',
};

const GA_CATEGORY = 'Onboarding bewaren', eventName = 'storeOnboardingFlow';

export default class StoreManager extends OnboardingManager {
    #type = 'store';
    #step = storeOnboardingSteps.INTRO_STEP;
    #showingAllDossiers = false;
    #defaultTop = 0;
    #scrolling = false;
    #scrollId = '';

    getType = () => this.#type;

    shouldShowAllDossiers = () => this.#showingAllDossiers;

    toggleAllDossiers = () => {
        this.#showingAllDossiers = !this.#showingAllDossiers;
        dispatchCustomEvent('onboardingReloadDossiers');
    };

    setStep = step => {
        if (this.isActive()) {
            const old = this.#step;
            this.#step = step;
            this.#onStepChange(old);
        } else this.warnNotActive();
    };

    setNextStep = (params) => {
        if (this.isActive()) {
            const oldStep = this.#step;
            this.#step = getNextStep(storeOnboardingSteps, this.#step);
            this.#onStepChange(oldStep, params);
        } else this.warnNotActive();
    };

    setPrevStep = () => {
        if (this.isActive()) {
            const oldStep = this.#step;
            this.#step = getPrevStep(storeOnboardingSteps, this.#step);
            this.#onStepChange(oldStep);
        } else this.warnNotActive();
    };

    getActiveStep = () => {
        return this.#step;
    };

    start = () => {
        if (this.isActive()) {
            dispatchCustomEvent(eventName, {type: 'activate'});
        } else this.warnNotActive();
    };

    locateDossierDetailsPopup = () => {
        this.addScrollListener(32, 'storeOnboardingPopup');
    };

    locateTutorialPopup = () => {
        if (deviceIsMobile()) {
            const popup = document.getElementById('storeOnboardingPopup');
            if (isNotNull(popup)) {
                popup.style.top = '12rem';
            }
        }
    };

    locateConfirmDocument = () => {
        if (deviceIsMobile()) {
            const dossierDocumentsElement = document.getElementById('dossierDetailsDocuments'),
                popup = document.getElementById('storeOnboardingPopup');
            if (areNotNull(dossierDocumentsElement, popup)) {
                const top = dossierDocumentsElement.getBoundingClientRect().top - remToPx(14);
                popup.style.top = top + 'px';
                this.addScrollListener(pxToRem(top + window.scrollY), 'storeOnboardingPopup');
            }
        }
    };

    addScrollListener = (defaultTop, id) => {
        this.#defaultTop = remToPx(defaultTop);
        this.#scrollId = id;
        window.addEventListener('scroll', this.scrollListener);
    };

    removeScrollListener = () => {
        if (isNotNull(this.#scrollId)) {
            const element = document.getElementById(this.#scrollId);
            if (isNotNull(element)) element.style.removeProperty('top');
            this.#scrollId = null;
        }
        this.#defaultTop = 0;
        window.removeEventListener('scroll', this.scrollListener);
    };

    scrollListener = evt => {
        if (!this.#scrolling) {
            window.requestAnimationFrame(() => {
                const popup = document.getElementById(this.#scrollId);
                if (isNotNull(popup)) {
                    const scrollPosition = window.scrollY, adjustedTop = this.#defaultTop - scrollPosition;
                    popup.style.top = adjustedTop + 'px';
                }
                this.#scrolling = false;
            });
            this.#scrolling = true;
        }
    };

    sendEvent = (action, label) => {
        sendGaEvent(GA_CATEGORY, action, label);
    };

    #addEscapeListener = () => {
        window.addEventListener('keydown', this.#escapeListener);
    };

    #removeEscapeListener = () => {
        window.removeEventListener('keydown', this.#escapeListener);
    };

    #escapeListener = evt => {
        const ESCAPE_CODE = 27;
        if (evt.keyCode === ESCAPE_CODE)
            this.setPrevStep();
    };

    #onStepChange = (oldStep, params = null) => {
        this.clearHighlightIds();
        this.removeScrollListener();
        this.#removeEscapeListener();
        dispatchCustomEvent(eventName, {type: 'setStep', data: {step: this.#step}});
        switch (this.#step) {
            case storeOnboardingSteps.SELECT_DOSSIER:
                this.highlightElement('dossierOverviewContainer');
                if (deviceIsMobile())
                    this.addScrollListener(10, 'storeOnboardingPopup');
                break;
            case storeOnboardingSteps.ADD_DOSSIER:
                this.#addEscapeListener();
                dispatchCustomEvent(eventName, {type: 'selectDossierType', data: {type: params}});
                break;
            case storeOnboardingSteps.ADD_DOCUMENT:
                dispatchCustomEvent('activateAddDocument');
                break;
            case storeOnboardingSteps.SELECT_DOCUMENT:
                if (deviceIsMobile()) {
                    setTimeout(() => {
                        scrollToY(remToPx(15));
                    }, 200);
                    this.addScrollListener(12, 'onboardingSelectDocuments');
                }
                break;
            case storeOnboardingSteps.CONFIRM_DOCUMENT:
                this.locateConfirmDocument();
                break;
            case storeOnboardingSteps.EXPLAIN_DETAILS:
                if (deviceIsMobile())
                    scrollToTop(this.locateDossierDetailsPopup);
                break;
            case storeOnboardingSteps.NOTIFY_HELP:
                this.locateTutorialPopup();
                break;
            case false:
                dispatchCustomEvent(eventName, {type: 'deactivate'});
                this.cleanup();
                navigate('/dossier-overzicht');
                break;
        }
    };
};