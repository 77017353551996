import './style/editTenderData.scss'

import React from 'react';
import PropTypes from 'prop-types';
import {applyMiddleware, createStore} from 'redux';
import {combineForms, Control, Form} from 'react-redux-form';
import thunk from 'redux-thunk';

import {isNotNull} from 'glob-common-js/lib/utils';

const inputTypeMapping = {
    'NUMERIC': 'number',
    'DATETIME': 'datetime-local',
    'STRING': 'text',
    'DATE': 'date'
};

export class EditTenderData extends React.Component {
    constructor(props) {
        super(props);

        this.initialState = {};

        // Add dossier fields
        for (let i = 0; i < this.props.fieldData.length; i++) {
            let field = this.props.fieldData[i];
            this.initialState[field.name] = field.value;
        }
        this.store = createStore(combineForms({model: this.initialState}), applyMiddleware(thunk));
    }

    onSubmit = (model) => {
        this.props.onSubmit(model);
    };

    createField = ({type, label, name, required, value, options}, key) => {
        let className = 'inputField';
        value = isNotNull(value) ? type === 'PRICE' ? this.convertNumber(value) : value : '';
        return (
            <div key={key} className="field">
                <label className="titleLabel">{label}</label>
                {type === 'PRICE' ?
                    <Control.input model={'.' + name} type='number' step='0.01' required={required === 1}
                                   className={className} defaultValue={value}/> :
                    isNotNull(options) ?
                        <Control.select model={'.' + name} required={required === 1} className={className}
                                        defaultValue={value}>
                            {JSON.parse(options).map(this.createOption)}
                        </Control.select>
                        :
                        <Control.input model={'.' + name} type={inputTypeMapping[type]} required={required === 1}
                                       className={className} defaultValue={value}/>
                }
            </div>
        );
    };

    convertNumber = numberText => {
        return numberText.replace(',', '.');
    };

    createOption = (option, key) => (
        <option key={key} value={option}>{option}</option>
    );

    render = () => {
        return (
            <Form store={this.store} model='model' onSubmit={this.onSubmit} className='tenderEditForm'>
                <div className='fields'>
                    {this.props.fieldData.map(this.createField)}
                </div>
                <div className="buttonContainerTender">
                    <button type='submit' className='simpleButton'>Opslaan</button>
                </div>
            </Form>
        )
    };
}

EditTenderData.propTypes = {
    fieldData: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default EditTenderData;