import '../style/coverage.scss';

import React from 'react';
import PropTypes from 'prop-types';

import {capitalizeFirst, isNotNull} from 'glob-common-js/lib/utils';

import {selectableOptions} from "./data/editPossibilieties";
import MaterialFactory from "../../../../material/materialFactory";
import {materialTypes} from "../../../../material/materialTypes";
import GenericLoader from "../../../../misc/genericLoader";

export default class CoverageView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            coverageAmount: Object.keys(selectableOptions).length,
            showResetOptions: false,
        }
    }

    getSelectedValue = (name) => {
        let selectedData = this.props.selectedData;
        return selectableOptions[name][selectedData[name]];
    };

    createDefaultSelector = (name, key = null) => {
        let optionsToSelect = selectableOptions[name];
        let selectedIndex = this.props.selectedData[name];
        let label = this.createLabelFromName(name);
        let cssClass = '';
        let coverageValue = '';

        if (name === 'risk' || name === 'covered_no_contract' || name === 'policy_type') {
            coverageValue = selectableOptions[name][selectedIndex];
        } else {
            if (selectedIndex < 1) {
                cssClass = 'red';
                coverageValue = 'Geen dekking'
            } else {
                coverageValue = selectableOptions[name][selectedIndex];
            }
        }

        let content = (
            <div>
                <div className='headerContainer'>
                    <div className='textContainer'>
                        <label className='boxTitle'>{label}</label>
                        <label>{coverageValue}</label>
                    </div>
                    <span className='common-icon-edit editIcon' onClick={() => {
                        this.activateEdit(name)
                    }}/>
                </div>
                <div className='optionSelectionContainer'>
                    <label>Ik wil vergoeding voor:</label>
                    {this.createOptions(optionsToSelect, selectedIndex, name)}
                </div>
                <span className='closeButton common-icon-cross' onClick={() => {
                    this.activateEdit(name)
                }}/>
            </div>
        );
        return isNotNull(key) ?
            <div key={key} id={'selection' + name}
                 className={'selectionBox ' + cssClass}>{content}</div> :
            <div id={'selection' + name}
                 className={'selectionBox ' + cssClass}>{content}</div>;
    };

    activateEdit = name => {
        let boxToActivate = document.getElementById('selection' + name);
        if (isNotNull(boxToActivate)) {
            boxToActivate.classList.toggle('active');
        }
    };

    createLabelFromName = (name) => {
        if (name === 'risk') return 'Eigen risico';
        if (name === 'policy_type') return 'Polis type';
        if (name === 'covered_no_contract') return 'Dekking geen contract';
        return capitalizeFirst(name).replace(/_/g, ' ');
    };

    selectOption = (i, optionFor) => {
        this.props.selectOption(i, optionFor);
        this.activateEdit(optionFor);
    };

    createOptions = (options, selectedOption, optionFor) => {
        let optionsToSelect = [];
        for (let i = 0; i < options.length; i++) {
            let option = options[i];
            if (isNotNull(option)) {
                optionsToSelect.push(
                    <div key={'option' + i + option} className={'option' + (i === selectedOption ? ' selected' : '')}
                         onClick={() => {
                             this.selectOption(i, optionFor)
                         }}>
                        {option}
                    </div>
                )
            }
        }
        return optionsToSelect;
    };

    createCoveragesBody = () => {
        let keys = Object.keys(selectableOptions);
        let max = Math.min(keys.length, this.state.coverageAmount + 3); // Add 3 to compensate skipping the first 3 indexes
        keys = keys.slice(3, max);
        return keys.map((key, mapKey) => (this.createDefaultSelector(key, mapKey)));
    };

    toggleAmount = () => {
        let amount = 8;
        if (this.state.coverageAmount === 8) {
            amount = Object.keys(selectableOptions).length;
        }
        this.setState({coverageAmount: amount});
    };

    toggleResetChoices = () => {
        this.setState({showResetOptions: !this.state.showResetOptions});
    };

    render = () => {
        return (
            <div className='coverageView'>
                <div className='coverageHeaderTextContainer'>
                    <p className='coveragHeaderText'>
                        Wanneer jij denkt dat je in 2020 andere zorg nodig hebt is het verstandig om je dekking af te
                        stemmen op je verwachte zorgbehoefte. Vul op deze pagina in waarvoor je wél of juist niet
                        dekking wil hebben.
                    </p>
                </div>
                <div className='coverageBody'>
                    <div className='boxHeader'>
                        <label>Basisverzekering</label>
                    </div>
                    <div className='boxContent'>
                        <div className='coveragesContainer'>
                            {this.createDefaultSelector('policy_type')}
                            {this.createDefaultSelector('risk')}
                            {this.createDefaultSelector('covered_no_contract')}
                        </div>
                    </div>
                    <div className='coveragesContainer'>
                        <div className='boxHeader'>
                            <label>Aanvullende verzekering</label>
                        </div>
                        <div className='boxContent'>
                            <div className='resetContainer'>
                                <label className='toggleAmountText'
                                       onClick={this.toggleAmount}>{this.state.coverageAmount === 8 ? 'Toon meer dekkingen...' :
                                    'Toon alleen de meest voorkomende dekkingen...'}</label>
                                <MaterialFactory componentType={materialTypes.RAISED_BUTTON}
                                                 onClick={this.toggleResetChoices}>
                                    Reset dekkingen</MaterialFactory>
                                <div
                                    className={'triggerChoiceContainer' + (this.state.showResetOptions ? ' active' : '')}>
                                    <div className='triggerChoiceHeader'>
                                        <label className='triggerChoiceTitle'>Naar wat wil je de dekkingen
                                            resetten?</label>
                                        <span className='closeButton common-icon-cross'
                                              onClick={this.toggleResetChoices}/>
                                    </div>
                                    <div className='choiceContainer'>
                                        <MaterialFactory componentType={materialTypes.RAISED_BUTTON}
                                                         className='choiceButton' onClick={() => {
                                            this.toggleResetChoices();
                                            this.props.resetToInsurance();
                                        }}>
                                            Mijn verzekering</MaterialFactory>
                                        <MaterialFactory componentType={materialTypes.RAISED_BUTTON}
                                                         className='choiceButton' onClick={() => {
                                            this.toggleResetChoices();
                                            this.props.resetSelectedData();
                                        }}>
                                            Geen dekkingen</MaterialFactory>
                                    </div>
                                </div>
                            </div>
                            <div className='coveragesContainer'>
                                {this.createCoveragesBody()}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='coverageViewFooter'>
                    <MaterialFactory componentType={materialTypes.RAISED_BUTTON} onClick={this.props.toCompare}
                                     disabled={this.props.requesting}>
                        Vergelijken</MaterialFactory>
                    <GenericLoader active={this.props.requesting} backgroundClassName='coverageLoaderBackground'
                                   containerClassName='coverageLoader' size={20} textClassName='coverageLoaderText'
                                   label='Alternatieven zoeken...'/>
                </div>
            </div>
        );
    }
}

CoverageView.propTypes = {
    selectedData: PropTypes.object.isRequired,
    selectOption: PropTypes.func.isRequired,
    toCompare: PropTypes.func.isRequired,
    resetSelectedData: PropTypes.func.isRequired,
    resetToInsurance: PropTypes.func.isRequired,
    requesting: PropTypes.bool.isRequired,
};
