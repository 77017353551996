const moduleConstants = {
    STUDY: 'study',
    CARE: 'care',
    PERSONAL: 'personal',
    MONEY_INCOME: 'money_income',
    VALUABLE_POSSESSION: 'valuable_possession',
    FREE_TIME: 'free_time_sub',
    OUT_HOUSE: 'out_house',
    FAMILY: 'family',
    HOUSE: 'house',
    TRANSPORTATION_MEAN: 'transportation_mean',
};

export default moduleConstants;