import './style/explanationFrame.scss';

import React from 'react';
import PropTypes from 'prop-types';

export default class ExplanationFrame extends React.Component {
    constructor(props) {
        super(props);
    }

    render = () => {
        if (this.props.active)
            return (
                <>
                    <div className='blackBackground' onClick={this.props.onClose}/>
                    <div className={'explanationContainer' + (this.props.placeRight ? ' right' : '')}>
                        <span className='common-icon-cross closeButton' onClick={this.props.onClose}/>
                        <iframe id="ytplayer" type="text/html" width="500" height="281.25"
                                src="https://www.youtube.com/embed/SCX4f-W7ZpI?autoplay=1&controls=0&loop=1&rel=0"
                                frameBorder="0" allow="autoplay" allowFullScreen/>
                    </div>
                </>
            );
        return null;
    };
}

ExplanationFrame.propTypes = {
    onClose: PropTypes.func.isRequired,
    active: PropTypes.bool.isRequired,
    placeRight: PropTypes.bool,
};

ExplanationFrame.defaultProps = {
    placeRight: false,
};