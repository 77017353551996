import './style/documentDetail.scss';

import React from 'react';
import PropTypes from 'prop-types';

import {toMoment, deviceIsMobile} from 'glob-common-js/lib/utils';

import BdhDocument from "../../../models/document";
import {WithBackground} from "../../../misc/reactUtils";
import BdhInput from "../../customControls/input/bdhInput";
import CustomScrollbar from "../../misc/customScrollbar";
import getDocumentType from "../../../common/data/mappings/docTypeMapping";
import MaterialFactory from "../../material/materialFactory";
import {materialTypes} from "../../material/materialTypes";
import BdhSelectController from "../../customControls/select/bdhSelectController";
import GenericLoader from "../../misc/genericLoader";

export default class DocumentDetailView extends React.Component {
    onClearType = () => {
        const {onChange} = this.props;
        onChange('type')('');
    };

    prepareOptions = options => options.map(option => ({value: option, label: option}));

    renderHeader = () => {
        const {onClose, doc} = this.props;
        return (
            <div className='headerContainer'>
                <label className='headerTitle' title={doc.label}>{doc.label}</label>
                <span className='common-icon-cross closeButton' onClick={onClose}/>
            </div>
        )
    };

    renderBody = () => {
        const {doc, onChange, loading, urlLoading} = this.props;
        const fields = Object.values(doc.fields);
        return (
            <div className='documentDetailBody'>
                <GenericLoader active={loading}/>
                <div className='col fileName'>
                    <BdhInput onChange={onChange('label')} value={doc.label} label='Bestandsnaam *' fullWidth/>
                    <div className='fileContainer'>
                        <GenericLoader active={urlLoading}/>
                        {this.renderFile()}
                    </div>
                    {this.renderLinkedDossiers()}
                </div>
                <div className='col'>
                    <CustomScrollbar height='20rem'>
                        <label className='colTitle'>Bestand details</label>
                        {this.renderTypeField()}
                        {this.renderReadOnlyDate('createdAt', 'Toegevoegd op')}
                        {this.renderReadOnlyDate('updatedAt', 'Laatst gewijzigd op')}
                        <BdhInput value={doc.extension} readOnly label='Bestandsformaat' fullWidth/>
                        {isNotNull(fields) && <label className='colTitle pad'>Velden</label>}
                        {fields.map(this.renderField)}
                    </CustomScrollbar>
                </div>
            </div>
        );
    };

    renderField = (field, key) => {
        const {onChangeField} = this.props;
        const {label, type, options, required} = field;
        let {value} = field;
        if (isNotNull(options))
            return this.renderSelectField(field, key);

        const fieldValue = isNull(value) ? '' : value;
        const fieldLabel = required ? `${label} *` : label;
        return <BdhInput key={key} value={fieldValue} onChange={onChangeField(field)} label={fieldLabel} fullWidth/>;
    };

    renderSelectField = (field, key) => {
        const {onChangeField} = this.props;
        const {options, label, value} = field;
        const selectValue = isNull(value) ? [] : [value];
        const fieldOptions = this.prepareOptions(options);
        return <BdhSelectController key={key} value={selectValue} onSelect={onChangeField(field)} items={fieldOptions}
                                    label={label} fullWidth/>
    };

    renderTypeField = () => {
        const {doc, onChange} = this.props,
            documentTypes = Object.values(getDocumentType()).map(docType => ({
                value: docType.name,
                label: docType.label
            }));
        documentTypes.sort((docA, docB) => {
            const preferA = -1, preferB = 1, isEqual = 0;
            let labelA = docA.label.toLowerCase(), labelB = docB.label.toLowerCase();
            return labelA < labelB ? preferA : labelA > labelB ? preferB : isEqual;
        });
        const docType = doc.type;
        const options = isNull(docType.name) ? [] : docType.name === 'uncategorised' ? documentTypes : [{
            value: docType.name,
            label: docType.label
        }], value = isNull(docType.name) ? [] : [docType.name];
        return <BdhSelectController value={value} items={options} onSelect={onChange('type')} label='Documenttype'
                                    readOnly={docType.name !== 'uncategorised'} fullWidth/>;
    };

    renderReadOnlyDate = (name, label) => {
        const {doc} = this.props, value = isNotNull(doc[name]) ? doc[name].format('DD-MM-YYYY') : '';
        return <BdhInput readOnly value={value} label={label} fullWidth/>
    };

    renderFile = () => {
        const {doc, onClickFile} = this.props;
        const url = doc.url;
        if (isNotNull(url)) {
            if (doc.fileIsImage)
                return (
                    <div className='fileLink' onClick={onClickFile}>
                        <img src={url} alt={doc.label} className='filePreview'/>
                    </div>
                );
            else
                return (
                    <div className='fileLinkContainer' onClick={onClickFile}>
                        {isNotNull(url) && <>
                            <span className='common-icon-document-2 docIcon'/>
                            <label className='linkText'>Bekijk bestand</label>
                        </>}
                    </div>
                )
        }
        return null;
    };

    renderLinkedDossiers = () => {
        const {doc, onClickLinkedDossier} = this.props, dossiers = doc.dossiers;
        if (isNull(dossiers)) return null;
        return (
            <div className='linkedDossiersContainer'>
                <label className='linkedLabel'>Gekoppeld aan dossiers:</label>&nbsp;
                {dossiers.map((dossier, key) => {
                    const withComma = key < dossiers.length - 1;
                    return <span key={key} className='dossierLink'
                                 onClick={onClickLinkedDossier(dossier)}>{dossier.label + (withComma ? ',' : '')}&nbsp;</span>;
                })}
            </div>
        )
    };

    renderFooter = () => {
        const {onClickLink, hasChanges, onSubmit, onClickDelete, loading} = this.props;
        return (
            <div className='documentDetailFooter'>
                <div>
                    <MaterialFactory componentType={materialTypes.RAISED_BUTTON} onClick={onClickLink}
                                     disabled={loading}>Koppel aan
                        dossier</MaterialFactory>
                    <MaterialFactory componentType={materialTypes.FLAT_BUTTON} disabled={loading}
                                     onClick={onClickDelete}>Verwijderen</MaterialFactory>
                </div>
                <div>
                    <MaterialFactory componentType={materialTypes.RAISED_BUTTON} disabled={loading || !hasChanges}
                                     onClick={onSubmit}>Opslaan</MaterialFactory>
                </div>
            </div>
        );
    };

    render = () => {
        const {onClickBackground, onClose} = this.props;
        return (
            <WithBackground onClose={onClickBackground} onEscape={onClose} blockScroll={deviceIsMobile()}>
                <div className='documentDetailView'>
                    {this.renderHeader()}
                    {this.renderBody()}
                    {this.renderFooter()}
                </div>
            </WithBackground>
        );
    };
};

DocumentDetailView.propTypes = {
    onChange: PropTypes.func.isRequired,
    onChangeField: PropTypes.func.isRequired,
    onClickLink: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onClickBackground: PropTypes.func.isRequired,
    onClickFile: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
    onClickLinkedDossier: PropTypes.func.isRequired,
    doc: PropTypes.instanceOf(BdhDocument).isRequired,
    loading: PropTypes.bool.isRequired,
    urlLoading: PropTypes.bool.isRequired,
    hasChanges: PropTypes.bool.isRequired,
};