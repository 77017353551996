import {debug} from 'glob-common-js/lib/utils';

import StoreManager from "./storeManager";
import FinancialManager from "./financialManager";
import AgendaManager from "./agendaManager";
import SaveManager from "./saveManager";

let onboardingManager = null;

const emptyManager = {
    isActive: () => false,
    removeActivateListener: () => false,
    addActivateListener: () => false,
    cleanup: () => false,
};

export const activateOnboarding = type => {
    return getOnboardingManager(type).activate();
};

export const deactivateOnboarding = () => {
    if (isNotNull(onboardingManager)) {
        onboardingManager.cleanup();
        onboardingManager = null;
    }
};

export const getOnboardingManager = type => {
    if (isNull(type)) {
        return isNotNull(onboardingManager) ? onboardingManager : emptyManager;
    }
    if (isNull(onboardingManager) || onboardingManager.getType() !== type) {
        if (isNotNull(onboardingManager) && onboardingManager.isActive()) return emptyManager;
        switch (type) {
            case 'store':
                onboardingManager = new StoreManager();
                break;
            case 'financial':
                onboardingManager = new FinancialManager();
                break;
            case 'agenda':
                onboardingManager = new AgendaManager();
                break;
            case 'save':
                onboardingManager = new SaveManager();
                break;
        }
    }
    debug('Returning onboardingManager', {
        onboardingManager,
        type: onboardingManager.getType(),
        active: onboardingManager.isActive()
    });
    return onboardingManager;
};

export const onboardingManagerHasType = type => isNotNull(onboardingManager) && onboardingManager.getType() === type;