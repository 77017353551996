import React from 'react';
import PropTypes from 'prop-types';
// import {getDocByLabel, getKeyByLabel} from 'BdhCommon/data/mappings/docTypeMapping';

export default class MobileContainer extends React.Component {
    constructor(props) {
        super(props);
    }

    createDocType = (docType, key) => {
        return (
            <tr key={key}>
                <td>{docType.label}</td>
                <td><span className='addButton common-icon-plus' onClick={() => {
                    this.props.onAddClick(docType)
                }}/></td>
            </tr>
        );

    };

    render = () => {

        return (
            <div className={'docTypeMobileContainer' + (this.props.isSelected ? ' visible' : '')}>
                <table>
                    <tbody>
                    {this.props.docTypes.map(this.createDocType)}
                    </tbody>
                </table>
            </div>
        )
    }
}


MobileContainer.propTypes = {
    onAddClick: PropTypes.func.isRequired,
    docTypes: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
    })).isRequired,
    isSelected: PropTypes.bool.isRequired,
};