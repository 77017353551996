import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../../../redux/actions';

import {generateUUID} from 'glob-common-js/lib/utils';

import StateComponent from "../../misc/stateComponent";
import {addCustomEventListener, dispatchCustomEvent, removeCustomEventListener} from "../../../misc/eventDispatcher";
import {storeOnboardingSteps} from "../manager/storeManager";
import {getOnboardingManager} from "../manager/onboardingManagerHelper";
import StoreOnboardingView from "./storeOnboardingView";
import {createDossier} from "../../../misc/requestSender";
import getDossierType from "../../../common/data/mappings/dossierTypeMapping";

class StoreOnboardingController extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            step: storeOnboardingSteps.INTRO_STEP,
            dossierType: '',
            dossierName: '',
        };
        this.view = null;
    }

    onEvent = params => {
        const {type, data} = params;
        switch (type) {
            case 'activate':
                this.changeState({active: true, step: storeOnboardingSteps.INTRO_STEP});
                break;
            case 'setStep':
                const {step} = data;
                this.changeState({step});
                break;
            case 'deactivate':
                this.changeState({active: false});
                break;
            case 'selectDossierType':
                this.changeState({dossierType: data.type});
                break;
            case 'hide':
                this.view.onHide();
                break;
            case 'show':
                this.view.onShow();
                break;
        }
    };

    onNextStep = (removeHighlights = false) => () => {
        const manager = getOnboardingManager('store');
        if (removeHighlights)
            manager.removeAllHighlights();
        manager.setNextStep();
    };

    onSubmitDossier = (name, zipcode, houseNumber, license) => {
        const {dossierType} = this.state;
        const dossierTypeId = getDossierType(dossierType).id;
        const dossier = dossierType === 'house' ? this.createHouseDossier(name, dossierTypeId, zipcode, houseNumber) :
            this.createDossier(name, dossierTypeId);
        createDossier({
            data: dossier,
            callback: dossier => {
                this.changeState({dossierName: name});
                this.props.actions.setDossier(dossier.id);
                this.onNextStep()();
            }
        });
    };

    createCarDossier = (name, dossierTypeId, extraField) => {
        const externalData = {
            values: [{
                id: generateUUID(),
                licence_plate: extraField,
                name,
            }]
        }, fields = {
            licence_plate: {
                exclude: false,
                label: 'Kenteken',
                name: 'licence_plate',
                options: null,
                required: true,
                type: 'STRING',
                value: extraField,
            },
            name: {
                exclude: false,
                label: 'Naam',
                name: 'name',
                options: null,
                required: true,
                type: 'STRING',
                value: name,
            }
        }, values = {
            licence_plate: extraField,
            name,
        };
        return {
            // external_data: externalData,
            fields,
            name,
            type: dossierTypeId,
            values,
        }
    };

    createHouseDossier = (name, dossierTypeId, zipcode, houseNumber) => {
        const fields = {
            house_number: {
                exclude: false,
                label: 'Huisnummer',
                name: 'house_number',
                options: null,
                required: true,
                type: 'NUMERIC',
                value: houseNumber,
            },
            house_number_suffix: {
                exclude: false,
                label: 'Toevoeging',
                name: 'house_number_suffix',
                options: null,
                required: true,
                type: 'STRING',
                value: '',
            },
            name: {
                exclude: false,
                label: 'Naam',
                name: 'name',
                options: null,
                required: true,
                type: 'STRING',
                value: name,
            },
            zipcode: {
                exclude: false,
                label: 'Postcode',
                name: 'zipcode',
                options: null,
                required: true,
                type: 'STRING',
                value: zipcode,
            },
        }, values = {
            house_number: houseNumber,
            house_number_suffix: '',
            name,
            zipcode,
        };
        return {
            fields,
            name,
            type: dossierTypeId,
            values,
        };
    };

    createDossier = (name, dossierTypeId) => ({
        name, type: dossierTypeId
    });

    componentDidMount = () => {
        this.mount();
        addCustomEventListener('storeOnboardingFlow', this.onEvent);
    };

    componentWillUnmount = () => {
        this.unMount();
        removeCustomEventListener('storeOnboardingFlow', this.onEvent);
    };

    render = () => {
        const {active, step, dossierName, dossierType} = this.state;
        if (active)
            return <StoreOnboardingView ref={refName => {
                this.view = refName
            }} step={step} onNextStep={this.onNextStep} dossierName={dossierName} dossierType={dossierType}
                                        onSubmitDossier={this.onSubmitDossier}/>;
        return null;
    };
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch),
});

export default connect(null, mapDispatchToProps)(StoreOnboardingController);