import {navigate} from "../../../misc/utils";
import checklistTypes from "../../../components/checklist/checklistTypes";

export default {
    name: 'Scheiden',
    icon: 'common-icon-arrow_bothways',
    route: '/scheiden-checklist',
    type: checklistTypes.DIVORCE,
    GA_CATEGORY: 'Checklist scheiden',
    categories: {
        divorce: {
            name: 'divorce',
            label: 'Scheiding',
            icon: 'common-icon-arrow_bothways',
            boxes: [
                {
                    name: 'legal',
                    label: 'Ga na wat er is wettelijk is vastgelegd',
                    documentType: null,
                    link: {
                        label: 'Informatie over scheiden',
                        url: 'https://www.rijksoverheid.nl/binaries/rijksoverheid/documenten/brochures/2016/12/05/u-gaat-scheiden/U+gaat+scheiden+2016+november++97271.pdf',
                    },
                },
                {
                    name: 'alimony',
                    label: 'Partneralimentatie regelen',
                    documentType: null,
                    link: {
                        label: 'Uitleg partneralimentatie',
                        url: 'https://www.wijzeringeldzaken.nl/uit-elkaar-gaan/alimentatie/',
                    },
                },
                {
                    name: 'pension',
                    label: 'Verdeel pensioen',
                    documentType: null,
                    link: {
                        label: 'Informatie over pensioensverdeling',
                        url: 'https://www.rijksoverheid.nl/onderwerpen/scheiden/vraag-en-antwoord/verdelen-ouderdomspensioen-na-scheiding',
                    }
                },
                {
                    name: 'house',
                    label: 'Gezamenlijke woning? Contacteer je hypotheekverstrekker of verhuurder',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'taxes',
                    label: 'Check de gevolgen voor je belastingaangifte en - teruggave',
                    documentType: null,
                    link: {
                        label: 'Uitleg Fiscaal Partnerschap',
                        url: 'https://www.belastingdienst.nl/wps/wcm/connect/bldcontentnl/belastingdienst/prive/relatie_familie_en_gezondheid/relatie/fiscaal_partnerschap/',
                    }
                },
                {
                    name: 'allowances',
                    label: 'Heb je recht op toeslagen?',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'budget',
                    label: 'Maak een nieuwe begroting',
                    documentType: null,
                    link: {
                        label: 'Naar financieel overzicht',
                        url: () => {
                            navigate('/financieel-overzicht')
                        }
                    },
                },
                {
                    name: 'accounts',
                    label: 'Verdeel de gezamenlijke rekeningen',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'insurances',
                    label: 'Welke verzekeringen hebben jullie samen?',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'possessions',
                    label: 'Verdeel bezittingen en schulden',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'contract',
                    label: 'Leg je afspraken vast in een echtscheidingsconvenant',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'child_alimony',
                    label: 'Kinderalimentatie',
                    documentType: null,
                    link: {
                        label: 'Informatie kinderalimentatie',
                        url: 'https://www.wijzeringeldzaken.nl/uit-elkaar-gaan/hoe-werkt-kinderalimentatie/',
                    },
                },
                {
                    name: 'plan',
                    label: 'Ouderschapsplan',
                    documentType: 'miscellaneous_indoors',
                    link: {
                        label: 'Informatie over ouderschapsplan',
                        url: 'https://www.rijksoverheid.nl/onderwerpen/scheiden/vraag-en-antwoord/ouderschapsplan',
                    },
                },
                {
                    name: 'income',
                    label: 'Inkomensregelingen',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'parenthood',
                    label: 'Co-ouderschap',
                    documentType: 'miscellaneous_indoors',
                    link: null,
                }
            ]
        }
    }
};