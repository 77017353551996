import checklistTypes from "../../../components/checklist/checklistTypes";

export default {
    name: 'Curriculum Vitae opstellen',
    icon: 'common-icon-document-2',
    route: '/cv-checklist',
    type: checklistTypes.CV,
    GA_CATEGORY: 'CV checklist',
    categories: {
        contents: {
            name: 'contents',
            label: 'Inhoud',
            icon: 'common-icon-document-2',
            boxes: [
                {
                    name: 'personal',
                    label: 'Staan de juiste personalia gegevens op je CV?',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'profile',
                    label: 'Staat er een duidelijk profiel op je CV?',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'study',
                    label: 'Staan je relevante opleidingen op je CV?',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'work',
                    label: 'Staat je relevante werkervaring op je CV?',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'courses',
                    label: 'Staan de relevant gevolgde cursussen op je CV?',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'extracurricular',
                    label: 'Staan je extracurriculaire activiteiten op je CV?',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'skills',
                    label: 'Staan je unieke vaardigheden op je CV?',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'languages',
                    label: 'Staan de talen die jij spreekt op je CV?',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'interests',
                    label: 'Staan je professionele en persoonlijke interesses op je  CV?',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'references',
                    label: 'Staan er referenties op je  CV ?',
                    documentType: null,
                    link: null,
                },
            ],
        },
        check: {
            name: 'check',
            label: 'Controleer',
            icon: 'common-icon-document-2',
            boxes: [
                {
                    name: 'skills_present',
                    label: 'Komen jouw unieke eigenschappen voldoende naar voren?',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'successes',
                    label: 'Staan de door jou behaalde successen er ook in?',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'talents',
                    label: 'Heb je je talenten opgenomen en goed omschreven?',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'grammar',
                    label: 'Staan er geen spel- of stijlfouten in?',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'size',
                    label: 'Is je cv niet te lang (maximaal 2/3 A4)?',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'terms',
                    label: 'Staat er geen jargon in?',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'saved',
                    label: 'Heb je je CV ook opgeslagen als PDF?',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'function',
                    label: 'Is je cv voldoende afgestemd op de functie / opdracht waarop je solliciteert?',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'readability',
                    label: 'Is het goed leesbaar en ziet de opmaak er professioneel uit?',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'contact',
                    label: 'Heb je op elke pagina je naam en contactgegevens staan?',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'email',
                    label: 'Gebruik je een zakelijk e-mailadres?',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'font',
                    label: 'Heb je een duidelijk lettertype gekozen?',
                    documentType: null,
                    link: null,
                },
            ],
        },
        extra: {
            name: 'extra',
            label: 'Extra',
            icon: 'common-icon-document-2',
            boxes: [
                {
                    name: 'cv-check',
                    label: 'Doe de CV check',
                    documentType: null,
                    link: {
                        label: 'Gratis CV check',
                        url: 'https://www.werkstudent.nl/cvcheck/',
                    }
                }
            ]
        }
    }
};