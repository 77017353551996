import {warn, remToPx, debug} from 'glob-common-js/lib/utils';

export const getNextStep = (steps, currentStep) => {
    const values = Object.values(steps);
    if (currentStep === false) return values[1];
    const currentIndex = values.indexOf(currentStep);
    if (currentIndex === values.length - 1) return false;
    if (currentIndex === -1) throw new Error(`Current step not found: '${currentStep}'`);
    return values[currentIndex + 1];
};

export const getPrevStep = (steps, currentStep) => {
    const values = Object.values(steps);
    const currentIndex = values.indexOf(currentStep);
    if (currentIndex === 0) return currentStep;
    if (currentIndex === -1) throw new Error(`Current step not found: '${currentStep}'`);
    return values[currentIndex - 1];
};

export default class OnboardingManager {
    #highlightedElements = [];
    #onboardingActive = false;
    #activateListeners = [];
    #deactivateListeners = [];

    // Scroll listener params
    #defaultTop = 0;
    #scrollId = '';
    #scrolling = false;

    activate = () => {
        this.#onboardingActive = true;
        this.#notifyActivateListeners();
        this.#stripUrlQuery();
        return this;
    };

    #stripUrlQuery = () => {
        const newUrl = location.hostname + location.pathname;
        history.replaceState({}, document.title, newUrl);
    };

    addActivateListener = (name, func) => {
        for (let i = 0; i < this.#activateListeners.length; i++) {
            const listener = this.#activateListeners[i];
            if (listener.name === name && listener.func === func) return this;
        }
        this.#activateListeners.push({name, func});
        return this;
    };

    removeActivateListener = (name, func) => {
        for (let i = 0; i < this.#activateListeners.length; i++) {
            const listener = this.#activateListeners[i];
            if (listener.name === name && listener.func === func) {
                this.#activateListeners.splice(i, 1);
                break;
            }
        }
        return this;
    };

    addDeactivateListener = (name, func) => {
        for (let i = 0; i < this.#deactivateListeners.length; i++) {
            const listener = this.#deactivateListeners[i];
            if (listener.name === name && listener.func === func) return this;
        }
        this.#deactivateListeners.push({name, func});
        return this;
    };

    removeDeactivateListener = (name, func) => {
        for (let i = 0; i < this.#deactivateListeners.length; i++) {
            const listener = this.#deactivateListeners[i];
            if (listener.name === name && listener.func === func) {
                this.#deactivateListeners.splice(i, 1);
                break;
            }
        }
        return this;
    };

    addScrollListener = (defaultRem, id) => {
        this.#defaultTop = remToPx(defaultRem);
        this.#scrollId = id;
        window.addEventListener('scroll', this.#scrollListener);
    };

    removeScrollListener = () => {
        if (isNotNull(this.#scrollId)) {
            this.removeTopFromElement(this.#scrollId);
            this.#scrollId = null;
        }
        this.#defaultTop = 0;
        window.removeEventListener('scroll', this.#scrollListener);
    };

    addDisabledClass = id => {
        const element = document.getElementById(id);
        if (isNotNull(element) && !element.classList.contains('disabled'))
            element.classList.add('disabled');
    };

    removeDisabledClass = (id) => {
        const element = document.getElementById(id);
        if (isNotNull(element))
            element.classList.remove('disabled');
    };

    togglePageScroll = (activate = true) => {
        const body = document.documentElement || document.body;
        if (isNotNull(body)) {
            if (activate && !body.classList.contains('noScroll'))
                body.classList.add('noScroll');
            else if (!activate) body.classList.remove('noScroll');
        } else warn('Body element not found');
    };

    highlightElement = (id, pulse = false) => {
        const element = this.#findElement(id);
        if (isNotNull(element)) {
            let elementHighlighted = false;
            if (!element.classList.contains('highlighted')) {
                element.classList.add('highlighted');
                elementHighlighted = true;
            }
            if (pulse && !element.classList.contains('pulse')) {
                element.classList.add('pulse');
                elementHighlighted = true;
            }

            if (elementHighlighted)
                this.#highlightedElements.push(id);
        }
        return this;
    };

    removeHighlight = id => {
        const element = this.#findElement(id);
        if (isNotNull(element))
            element.classList.remove('highlighted', 'pulse');
        return this;
    };

    removeHighlights = ids => {
        this.#typeError(!Array.isArray(ids), 'Ids must be an array');
        for (let i = 0; i < ids.length; i++) {
            this.removeHighlight(ids[i]);
        }
        return this;
    };

    removeAllHighlights = () => {
        this.removeHighlights(this.#highlightedElements);
        this.clearHighlightIds();
        return this;
    };

    clearHighlightIds = () => {
        this.#highlightedElements = [];
    };

    cleanup = () => {
        this.removeAllHighlights();
        this.#notifyDeactivateListeners();
        this.#activateListeners = [];
        this.#deactivateListeners = [];
        this.#onboardingActive = false;
        this.togglePageScroll(false);
    };

    isActive = () => this.#onboardingActive;

    warnNotActive = () => {
        warn('Onboarding manager has not yet been activated');
    };

    removeTopFromElement = id => {
        const element = document.getElementById(id);
        if (isNotNull(element))
            element.style.removeProperty('top');
    };

    removePositionFromElement = id => {
        const element = document.getElementById(id);
        if (isNotNull(element)) {
            element.style.removeProperty('top');
            element.style.removeProperty('bottom');
            element.style.removeProperty('left');
            element.style.removeProperty('right');
        }
    };

    #scrollListener = evt => {
        if (!this.#scrolling) {
            window.requestAnimationFrame(() => {
                const popup = document.getElementById(this.#scrollId);
                if (isNotNull(popup)) {
                    const scrollPosition = window.scrollY, adjustedTop = this.#defaultTop - scrollPosition;
                    popup.style.top = adjustedTop + 'px';
                }
                this.#scrolling = false;
            });
            this.#scrolling = true;
        }
    };

    #findElement = (id) => {
        this.#typeError(isNull(id), 'Cannot hightlight element without id');
        this.#typeError(typeof id !== 'string', 'Id must be a string');
        return document.getElementById(id);
    };

    #typeError = (condition, reason) => {
        if (condition)
            throw new TypeError(reason);
    };

    #notifyActivateListeners = () => {
        for (let i = 0; i < this.#activateListeners.length; i++) {
            const listener = this.#activateListeners[i];
            listener.func();
        }
    };

    #notifyDeactivateListeners = () => {
        for (let i = 0; i < this.#deactivateListeners.length; i++) {
            const listener = this.#deactivateListeners[i];
            listener.func();
        }
    };
}