import '../style/basic.scss';

import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as Actions from '../../../../../redux/actions';

import {capitalizeFirst, isNotNull} from 'glob-common-js/lib/utils';

import EvaluationView from './evaluationBasicView';
import {getFromRegistry} from "../../misc/registry";
import {sendEvent} from "../../misc/healthAnalysisUtils";
import {sendPageView} from "BdhCommon/js/ga";

export class EvaluationBasicController extends React.Component {
    constructor(props) {
        super(props);
    }

    addEvalToRedux = (decision) => {
        if (isNotNull(decision)) {
            sendEvent('Evaluatie Basis', decision);
            this.props.actions.setEvaluationBasic(decision);
        }
    };

    getBasicInfo = () => {
        let basicInfo = getFromRegistry(['healthInsurance', 'evaluation', 'basic']);
        // Fix inconsistencies in capital letter usage
        basicInfo.policy_type = capitalizeFirst(basicInfo.policy_type);
        return basicInfo;
    };

    componentDidMount = () => {
        sendPageView('/zorganalyse/evaluatie/basis-verzekering');
    };

    render = () => {
        return (
            <EvaluationView setEvaluation={this.addEvalToRedux}
                            evaluationDecision={this.props.healthState.evaluation.basic}
                            basicInsurance={this.getBasicInfo()}
            />
        )
    }
}

const mapStateToProps = state => ({
    healthState: state.healthInsuranceAnalysis,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(EvaluationBasicController);