import './style/agendaPlanned.scss';

import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';

import {timeFormats} from "../../../../common/components/datePicker";
import {plannedFilters} from "./agendaPlannedController";
import CustomScrollbar from "../../../misc/customScrollbar";

export default class AgendaPlanned extends React.Component {
    constructor(props) {
        super(props);
    }

    getItemIcon = type => {
        let icon = 'common-icon-calender ';
        switch (parseInt(type)) {
            case 0:
                icon += 'red';
                break;
            case 2:
                icon += 'orange';
                break;
            case 3:
            default:
                icon += 'blue';
                break;
        }
        return icon;
    };

    filterIsSelected = filter => {
        const selectedFilters = this.props.selectedFilters;
        if (selectedFilters === 'all') return filter === 'all';
        else return Array.isArray(selectedFilters) && selectedFilters.includes(filter);
    };

    createPlannedDate = item => {
        if (item.type === 0)
            return moment(item.end_date_item, timeFormats).format('DD-MM-YYYY HH:mm');
        else return moment(item.start_date_item, timeFormats).format('DD-MM-YYYY HH:mm');
    };

    getIconTitle = type => {
        switch (parseInt(type)) {
            case 0:
                return 'Deadline';
            case 2:
                return 'Herinnering';
            case 3:
                return 'Afspraak';
        }
    };

    renderPlannedItems = () => {
        const agendaItems = this.props.agendaItems;
        if (isNotNull(agendaItems)) {
            return agendaItems.map(this.renderPlannedItem);
        }
        return null;
    };

    renderPlannedItem = (item) => {
        return (
            <div key={item.id} className='plannedAgendaItem'>
                <div className='clickableArea' onClick={() => {
                    this.props.onItemClick(item)
                }}>
                    <span title={this.getIconTitle(item.type)}
                          className={'plannedIcon ' + this.getItemIcon(item.type)}/>
                    <label className='plannedDate'>{this.createPlannedDate(item)}</label>
                    <label className='plannedLabel'>{item.name}</label>
                </div>
                <div className='common-icon-cross plannedDelete' onClick={() => {
                    this.props.onDeleteItemClick(item.id);
                }}/>
            </div>
        )
    };

    renderFilters = () => {
        const filterKeys = Object.keys(plannedFilters);
        return filterKeys.map(this.renderFilter)
    };

    renderFilter = (filterKey, key) => {
        const label = plannedFilters[filterKey];
        const isSelected = this.filterIsSelected(filterKey);
        return (
            <label key={key} className={'filter' + (isSelected ? ' selected' : '')} onClick={() => {
                this.props.onSelectFilter(filterKey);
            }}>
                {label}
            </label>
        )
    };

    render = () => {
        const selectedFilters = this.props.selectedFilters;
        const filtersAmount = selectedFilters === 'all' ? 0 : selectedFilters.length;
        return (
            <div className='agendaPlanned'>
                <CustomScrollbar height='12rem'>
                    <div className='plannedItemsContainer'>
                        {this.renderPlannedItems()}
                    </div>
                </CustomScrollbar>
                <div className='plannedFilterContainer'>
                    <div className='filterContainer'>
                        {this.renderFilters()}
                    </div>
                    <div className='selectedFilters'>
                        <label>{filtersAmount + ' filters geselecteerd'}</label>
                        <div className='common-icon-arrow-left filterArrow'/>
                    </div>
                </div>
            </div>
        )
    };
}

AgendaPlanned.propTypes = {
    onSelectFilter: PropTypes.func.isRequired,
    onItemClick: PropTypes.func.isRequired,
    onDeleteItemClick: PropTypes.func.isRequired,
    selectedFilters: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]).isRequired,
    agendaItems: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })).isRequired,
};