import React from 'react';
import StateComponent from "../../misc/stateComponent";
import ActionCode from "../../../models/actionCode";
import ActionCodePopup from "./actionCodePopup";
import {responseIsSuccess} from "../../../misc/requestSender";
import {dispatchActionCodeEvent, EVENT_TYPES} from "../../../misc/dataEvent";
import {showMessage, showSnackbar} from "../../../misc/utils";
import {addCustomEventListener, removeCustomEventListener} from "../../../misc/eventDispatcher";

export default class ActionCodePopupController extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            loading: false,
            actionCode: new ActionCode(),
            hasChanges: false,
            isNewCode: true,
            updateUsers: false,
        };
    }

    onActivate = actionCode => {
        actionCode = isNull(actionCode) ? new ActionCode({subscription_period: {years: 1}}) : actionCode.copy;
        this.changeState({
            active: true,
            loading: false,
            actionCode: actionCode,
            hasChanges: false,
            isNewCode: isNull(actionCode.id),
            updateUsers: false,
        });
    };

    onClose = () => {
        this.changeState({active: false, loading: false, hasChanges: false, isNewCode: false});
    };

    onSubmit = () => {
        const {isNewCode} = this.state;
        if (isNewCode) this.onSave();
        else this.onUpdate();
    };

    onSave = () => {
        const {actionCode} = this.state;
        this.changeState({loading: true});
        actionCode.save((actionCode, response) => {
            if (responseIsSuccess(response)) {
                dispatchActionCodeEvent(EVENT_TYPES.CREATE, actionCode);
                showSnackbar('De actie code is aangemaakt');
                this.onClose();
            } else
                showMessage(`Het aanmaken van de actie code is niet gelukt. Reden: ${response.data.message}`,
                    null, 'Aanmaken niet gelukt');
        });
    };

    onUpdate = () => {
        const {actionCode, updateUsers} = this.state;
        this.changeState({loading: true});
        actionCode.update((actionCode, response) => {
            if (responseIsSuccess(response)) {
                dispatchActionCodeEvent(EVENT_TYPES.UPDATE, actionCode);
                showSnackbar(`De actie code is aangepast, gebruikers geüpdatet: ${response.data.updated_users}`);
                this.onClose();
            } else showMessage(`Het aanpassen van de actie code is niet gelukt. Reden: ${response.data.message}`,
                null, 'Aanpassen niet gelukt');
        }, updateUsers);
    };

    onChange = key => value => {
        const {actionCode} = this.state;
        actionCode[key] = value;
        this.changeState({actionCode, hasChanges: true});
    };

    onChangePeriod = key => value => {
        const {actionCode} = this.state;
        if (key === 'key')
            actionCode.periodKey = value;
        else if (key === 'value')
            actionCode.periodValue = value;
        this.changeState({actionCode, hasChanges: true}, this.syncPeriodValue);
    };

    onChangeUpdateUsers = evt => {
        this.changeState({updateUsers: evt.target.checked});
    };

    onClickDelete = () => {
        showMessage('Weet je zeker dat je deze actie code wilt verwijderen?', [{label: 'Nee'},
            {label: 'Ja', onClick: this.onConfirmDelete}], 'Actie code verwijderen');
    };

    onConfirmDelete = () => {
        const {actionCode} = this.state;
        actionCode.delete((actionCode, response) => {
            if (responseIsSuccess(response)) {
                dispatchActionCodeEvent(EVENT_TYPES.DELETE, actionCode);
                this.onClose();
                showSnackbar('De actie code is verwijderd');
            } else showMessage(`Het verwijderen van de actie code is niet gelukt. Reden: ${response.data.message}`,
                null, 'Verwijderen niet gelukt');
        });
    };

    syncPeriodValue = () => {
        const {actionCode} = this.state, value = actionCode.periodValue, key = actionCode.periodKey, maxMapping = {
            days: 31,
            weeks: 52,
            months: 12,
            years: 99,
        };
        if (value > maxMapping[key]) {
            actionCode.periodValue = maxMapping[key];
            this.changeState({actionCode});
        }
    };

    componentDidMount = () => {
        this.mount();
        addCustomEventListener('openActionCode', this.onActivate);
    };

    componentWillUnmount = () => {
        this.unMount();
        removeCustomEventListener('openActionCode', this.onActivate);
    };

    render = () => {
        const {active, loading, actionCode, hasChanges, isNewCode, updateUsers} = this.state;
        if (active)
            return <ActionCodePopup onClose={this.onClose} onSubmit={this.onSubmit} onChange={this.onChange}
                                    onChangePeriod={this.onChangePeriod} onChangeUpdateUsers={this.onChangeUpdateUsers}
                                    actionCode={actionCode} hasChanges={hasChanges} loading={loading}
                                    isNewCode={isNewCode} updateUsers={updateUsers}
                                    onClickDelete={this.onClickDelete}/>;
        return null;
    }
}