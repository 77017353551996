import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../../redux/actions';
import moment from 'moment';

import {createDossier, getAccount} from "../../misc/requestSender";
import {steps} from "./registrationConstants";
import {checkOnboardingDecision, navigate} from "../../misc/utils";
import {getAuthParameter} from "../authentication/authenticationHelper";
import {addCustomEventListener, removeCustomEventListener} from "../../misc/eventDispatcher";
import {sendGaEvent, sendPageView} from "../../common/js/ga";
import RegistrationView from "./registrationView";
import {NOW} from "../misc/constants";
import {getFromRegistry, updateRegistry} from "../insurance/healthAnalysis/misc/registry";
import getDossierType from "../../common/data/mappings/dossierTypeMapping";

const GA_CATEGORY = 'Registreren (Aanmelden)';

export class RegistrationController extends React.Component {
    constructor(props) {
        super(props);
        const pathParam = props.match.params.pathParam;
        const checkPayment = isNotNull(pathParam) && pathParam === 'betaling';
        this.state = {
            active: false,
            step: checkPayment ? steps.ACTIVATION.index : 0,
            subStep: checkPayment ? steps.ACTIVATION.CHECK_PAYMENT : '',
            error: null,
            errorType: null,
            user: {},
            flex: {
                birthday: false,
                isDefault: false,
            },
            loading: false,
            type: 'default',
            transactionInfo: {id: null, amount: null},
        };
        this._isMounted = false;
    }

    changeState = stateChange => {
        this._isMounted && this.setState(stateChange);
    };

    onSetStep = (step, subStep) => {
        this.changeState({
            step,
            subStep: subStep || this.state.subStep,
        });
    };

    onSetTransactionInfo = info => {
        this.changeState({transactionInfo: info});
    };

    onSetLoading = loading => {
        this.changeState({loading});
    };

    onClickLogin = () => {
        sendGaEvent(GA_CATEGORY, 'Klik', 'Heb je al een account?');
        navigate('/auth/login');
    };

    onClickBack = () => {
        sendGaEvent(GA_CATEGORY, 'Klik', 'Terug naar de website');
        window.location = 'https://bydehand.com';
    };

    onError = (cause, errorType = null) => {
        this.changeState({
            step: steps.ERROR,
            error: cause,
            errorType,
        });
    };

    setUser = (user, redux = false) => {
        this.changeState({user});
    };

    deactivate = (destination) => {
        this.changeState({
            active: false,
        });
        if (destination === 'health')
            navigate('/zorganalyse', true);
        else {
            const target = getAuthParameter('target');
            const action = getAuthParameter('action');
            if (isNotNull(target)) {
                navigate('/' + target, true);
            } else if (isNotNull(action)) {
                if (action === 'full_health_analysis') {
                    if (window.localStorage) {
                        const storage = window.localStorage;
                        let healthData = storage.getItem('bdhHealthAnalysis');
                        storage.removeItem('bdhHealthAnalysis');
                        if (isNotNull(healthData)) {
                            healthData = JSON.parse(healthData);
                            if (moment(healthData.expires).isSameOrAfter(NOW()))
                                this.navigateToHealthAnalysis(healthData);
                            else navigate('/quickscanzorgverzekering', true);
                        } else navigate('/quickscanzorgverzekering', true);
                    } else navigate('/quickscanzorgverzekering', true);
                }
            } else {
                checkOnboardingDecision(this.state.user);
                navigate('/dashboard', true);
            }
        }
    };

    navigateToHealthAnalysis = (healthData) => {
        delete healthData.expires;
        createDossier({
            data: {
                name: 'Zorgverzekeringsanalyse 2019',
                values: {},
                external_data: {
                    analysisData: healthData,
                },
                type: getDossierType('health_insurance_analysis').id,
            }
        });
        const registry = Object.assign({}, getFromRegistry('healthInsurance'), healthData);
        updateRegistry('healthInsurance', registry);
        navigate('/zorganalyse/evaluatie/basis-verzekering');
    };

    activate = props => {
        this.changeState({
            active: true,
            step: props.step || steps.REGISTRATION,
            subStep: props.subStep || '',
            user: props.user || {},
            flex: Object.assign({}, this.state.flex, props.flex),
        });
    };

    activateSelf = () => {
        const pathParam = this.props.match.params.pathParam;
        if (isNotNull(pathParam)) {
            if (pathParam === 'betaling') {
                getAccount({
                    callback: response => {
                        this.changeState({
                            step: steps.ACTIVATION.index,
                            subStep: steps.ACTIVATION.CHECK_PAYMENT,
                            user: response.data,
                        });
                    }
                });
            } else {
                this.changeState({
                    step: steps.ACTIVATION.index,
                    subStep: steps.ACTIVATION.ACTIVATING,
                })
            }
        } else this.activate({});
    };

    setStyles = (isMounted) => {
        const pageContent = document.querySelector('div.pageContent');
        const mainContent = document.getElementById('mainContent');
        if (isNotNull(pageContent)) {
            // Force toggle to either add or remove the pinned style
            pageContent.classList.toggle('pinned', !isMounted);
            pageContent.classList.toggle('noTop');
        }
        if (isNotNull(mainContent)) {
            if (isMounted && !mainContent.classList.contains('fullWidth'))
                mainContent.classList.add('fullWidth');
            else if (!isMounted)
                mainContent.classList.remove('fullWidth');
        }
    };

    componentDidMount = () => {
        sendPageView('/registreren');
        this.setStyles(true);
        this._isMounted = true;
        this.activateSelf();
        addCustomEventListener('activateRegistration', this.activate);
    };

    componentWillUnmount = () => {
        this._isMounted = false;
        this.setStyles(false);
        removeCustomEventListener('activateRegistration', this.activate);
    };

    render = () => (
        <RegistrationView onSetStep={this.onSetStep} onError={this.onError} onSetLoading={this.onSetLoading}
                          onSetUser={this.setUser} onClickLogin={this.onClickLogin} step={this.state.step}
                          subStep={this.state.subStep} onSetTransactionInfo={this.onSetTransactionInfo}
                          user={this.state.user} error={this.state.error} onDeactivate={this.deactivate}
                          flex={this.state.flex} loading={this.state.loading} onClickBack={this.onClickBack}
                          errorType={this.state.errorType} transactionInfo={this.state.transactionInfo}/>
    );
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch),
});

const mapStateToProps = state => ({
    subscriptionState: state.user.subscription,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RegistrationController));
