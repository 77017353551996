import '../style/editAccount.scss';

import React from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import * as actions from '../../../redux/actions';

import EditAccount from "./editAccount";
import {deleteSubscription, updateAccount, updateMobile, updateProfileImage} from "../../../misc/requestSender";
import {navigate, setReduxUser} from "../../../misc/utils";
import {addCustomEventListener, dispatchCustomEvent, removeCustomEventListener} from "../../../misc/eventDispatcher";
import {sendPageView} from "../../../common/js/ga";
import {materialTypes} from "../../material/materialTypes";
import MaterialFactory from "../../material/materialFactory";

export class EditAccountController extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            submitting: false,
        };
        this._isMounted = false;
        this.model = null;
    }

    changeState = stateChange => {
        this._isMounted && this.setState(stateChange);
    };

    onCancel = () => {
        navigate('/account-gegevens');
    };

    onSubmit = (model) => {
        this.model = model;
        this.updateMobile();
    };

    updateMobile = () => {
        this.changeState({submitting: true});
        let phone_mobile = this.model.phone_mobile;
        let oldMobile = Object.assign({}, this.props.userState.user).phone_mobile || '';
        oldMobile = oldMobile.startsWith('06') ? oldMobile.substr(2) : oldMobile;
        if (phone_mobile === oldMobile) {
            this.updateAccount();
            this.updateProfileImage();
        } else {
            phone_mobile = isNotNull(phone_mobile) ? '06' + phone_mobile : phone_mobile;
            updateMobile({
                phone_mobile, callback: () => {
                    this.changeState({submitting: false});
                    if (isNotNull(phone_mobile))
                        this.open2Fa(phone_mobile);
                    else {
                        this.updateAccount();
                        this.updateProfileImage();
                    }
                }
            })
        }
    };

    updateAccount = () => {
        this.changeState({submitting: true});
        let data = this.createSubmitData(this.model);
        updateAccount({
            id: this.props.userState.user.id, data, callback: (response) => {
                setReduxUser(response.data);
                this.changeState({submitting: false});
                this.onCancel();
            }, errorCallback: () => {
                this.changeState({submitting: false});
            }
        });
    };

    updateProfileImage = () => {
        const profileImage = this.model.profileImage;
        if (profileImage === null || (profileImage.hasOwnProperty('size') && isNotNull(profileImage.size))) {
            updateProfileImage({
                profileImage, callback: (response) => {
                    const user = response.data.user;
                    setReduxUser(user, true);
                }
            });
        }
    };

    open2Fa = (phone_mobile) => {
        dispatchCustomEvent('2fa', {submitCallback: this.confirm2Fa, phone_mobile, mode: 'update'})
    };

    confirm2Fa = (response, phone_mobile) => {
        dispatchCustomEvent('close2Fa');
        this.updateAccount();
        this.updateProfileImage();
    };

    createSubmitData = model => {
        return {
            firstname: model.firstname,
            infix: model.infix || null,
            lastname: model.lastname || null,
            dob: moment(model.dob, 'YYYY-MM-DDTHH:mm:ss').format('MM-DD-YYYY'),
            bsn: model.bsn || null,
            address: model.address || {
                city: null,
                house_number: null,
                house_number_suffix: null,
                street: null,
                zipcode: null,
            },
            username: model.username,
            phone_number: isNotNull(model.phone_number) ? model.phone_number.replace(/-/g, '') : null,
            phone_mobile: isNotNull(model.phone_mobile) ? '06' + model.phone_mobile : null,
            email: model.email,
            gender: model.gender,
            two_step_auth: model.two_step_auth,
            external_data: this.props.userState.user.external_data || {},
        };
    };

    onCancelSubscription = () => {
        deleteSubscription({
            callback: () => {
                navigate('/dashboard');
                window.location.reload();
            }
        });
    };

    deactivateLoader = () => {
        this.changeState({submitting: false});
    };

    componentDidMount = () => {
        this._isMounted = true;
        sendPageView('/account-aanpassen');
        addCustomEventListener('grantUnauthorized', this.deactivateLoader)
    };

    componentWillUnmount = () => {
        this._isMounted = false;
        removeCustomEventListener('grantUnauthorized', this.deactivateLoader)
    };

    render = () => {
        return (
            <>
                {this.state.submitting &&
                <div className='editAccountSubmitLoader'>
                    <div className='whiteBackground'/>
                    <div className='submitLoader'>
                        <MaterialFactory componentType={materialTypes.CIRCULAR_PROGRESS}/>
                        <label className='loaderLabel'>Aanpassingen opslaan</label>
                    </div>
                </div>}
                <EditAccount onCancel={this.onCancel} onSubmit={this.onSubmit}
                             onCancelSubscription={this.onCancelSubscription} account={this.props.userState.user}/>
            </>
        )
    };
}

const mapStateToProps = state => ({
    userState: state.user,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditAccountController));
