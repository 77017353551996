import {store} from "../../startup";


import {addToActionStore} from "./smartActionManager";
import {getSmartAction} from "../../common/data/mappings/smartActionMapping";
import {actionTypeConstants} from '../../common/data/constants/smartActionConstants';
import {timeItemTypes} from "../../common/data/constants/timeItemConstants";
import {createTimeItem} from "../../misc/requestSender";
import {dispatchCustomEvent} from "../../misc/eventDispatcher";

const triggerAction = (triggerCode) => {
    if (isNotNull(triggerCode)) {
        let actions = getSmartAction(triggerCode).actions;
        if (isNotNull(actions)) {
            for (let i = 0; i < actions.length; i++) {
                let action = actions[i];
                executeAction(action);
            }
        }
    }
};

const executeAction = action => {
    switch (action.type) {
        case actionTypeConstants.ADD_AGENDAITEM:
            addAgendaItem(action.config);
            break;
    }
};

const addAgendaItem = config => {
    if (isNotNull(config)) {
        let mustAddAlert = isNotNull(config.reminder) && isNotNull(config.reminder.alerts);
        let timeItem = prepareTimeItem(config);
        dispatchCustomEvent('openAgendaPopup', timeItem);
    } else {
        throw new TypeError('Config for adding agenda item is not defined');
    }
};

const prepareTimeItem = config => {
        let reminder = {
            email: {
                active: false,
                remindOn: 0,
                contactInfo: '',
            },
            sms: {
                active: false,
                remindOn: 0,
                contactInfo: '',
            }
        };
        if (isNotNull(config.reminder)) {
            let reminderIndex = config.reminder.time[1] === 'months' ? 3 : 1;
            let email = isNotNull(store.getState().user.user) ? store.getState().user.user.email : null;
            reminder.email = {
                active: isNotNull(config.reminder.email) && config.reminder.email,
                remindOn: reminderIndex,
                contactInfo: email,
            };
            let mobile = isNotNull(store.getState().user.user) ? store.getState().user.user.phone_mobile : null;
            reminder.sms = {
                active: isNotNull(config.reminder.sms) && config.reminder.sms,
                remindOn: reminderIndex,
                contactInfo: mobile || '',
            }
        }
        return {
            name: config.label,
            type: config.type.value,
            start_date_item: isNull(config.startDate) || config.startDate === 'NOW' ? new Date() : config.startDate,
            end_date_item: isNull(config.endDate) || config.endDate === 'NOW' ? new Date() : config.endDate,
            description: config.description,
            reminder,
            isNew: true,
        }
    }
;

const addAlert = alertConfig => {
    let alert = createAlert(alertConfig);
    createTimeItem({
        data: alert,
        callback: (response) => {
            alert.id = response.data.id;
            addToActionStore(`alerts.${alert.id}`, alert);
        }
    });
};

const createAlert = alertConfig => {
    let flex = {
        isRead: false,
        status: 'active',
        communication: [],
    };

    return {
        name: alertConfig.id,
        type: timeItemTypes.alert.value,
        start_date_item: alertConfig.startDate,
        end_date_item: alertConfig.endDate,
        flex,
    }
};

const smartActionActions = {
    triggerAction,
};

export default smartActionActions;