import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {financialTypeMapping_v3, getCategoryForItem,} from "../../../common/data/mappings/financialTypeMapping_v3";
import {dispatchCustomEvent} from "../../../misc/eventDispatcher";
import FinancialDataView from "./financialDataView";
import {getOnboardingManager} from "../../onboarding/manager/onboardingManagerHelper";
import {sendGaEvent} from "../../../common/js/ga";

export default class FinancialDataController extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            categories: {},
        };

        this._isMounted = false;
    }

    changeState = stateChange => {
        this._isMounted && this.setState(stateChange);
    };

    onAddItem = type => {
        const manager = getOnboardingManager('financial');
        if (manager.isActive()) {
            manager.sendEvent('Klik', `Keuze item (${type.label})`);
            manager.removeAllHighlights();
            manager.setNextStep();
        } else
            sendGaEvent('Financieel overzicht', 'Klik', `Item toevoegen (${type.label})`);
        dispatchCustomEvent('triggerFinancialPopup', {type});
    };

    onClickItem = item => {
        dispatchCustomEvent('triggerFinancialPopup', {item});
    };

    getTotalAmount = type => {
        let categories = this.state.categories;
        let keys = Object.keys(categories);
        let total = 0;
        for (let i = 0; i < keys.length; i++) {
            let categoryItems = categories[keys[i]].items;
            let categoryType = financialTypeMapping_v3[keys[i]].type;
            if (categoryType === type) {
                for (let j = 0; j < categoryItems.length; j++) {
                    let item = categoryItems[j];
                    total += item.monthly_price;
                }
            }
        }
        return total;
    };

    getCategories = type => {
        let keys = Object.keys(this.state.categories);
        let categories = [];
        for (let i = 0; i < keys.length; i++) {
            let category = this.state.categories[keys[i]];
            if (category.type === type)
                categories.push(category);
        }
        return categories;
    };

    prepareCategories = () => {
        let categories = this.addSuggestions(this.sortItems(this.addExistingItems(this.createCategoryMap())));
        this.changeState({categories});
    };

    addExistingItems = (map) => {
        let items = this.props.items;
        for (let i = 0; i < items.length; i++) {
            let item = items[i];
            let type = item.financialType || item.category.name;
            let finanType = getCategoryForItem(type);
            if (isNotNull(finanType)) {
                let category = finanType.name;
                map[category].items.push(item);
                map[category].total += item.monthly_price;
            }
        }
        return map;
    };

    sortItems = map => {
        let keys = Object.keys(map);
        for (let i = 0; i < keys.length; i++) {
            let category = keys[i];
            map[category].items.sort((itemA, itemB) => {
                const preferA = -1, preferB = 1, isEqual = 0;
                let descA = itemA.description, descB = itemB.description;
                return descA > descB ? preferA : descA < descB ? preferB : isEqual;
            });
        }
        return map;
    };

    addSuggestions = (map) => {
        let keys = Object.keys(map);
        for (let i = 0; i < keys.length; i++) {
            let category = keys[i];
            let suggestions = financialTypeMapping_v3[category].itemTypes;
            suggestions.sort((sugA, sugB) => {
                const preferA = -1, preferB = 1, isEqual = 0;
                let labelA = sugA.label, labelB = sugB.label;
                return labelA > labelB ? preferA : labelA < labelB ? -1 : isEqual;
            });
            map[category].suggestions = suggestions;
        }
        return map;
    };

    createCategoryMap = () => {
        let categoryMap = {};
        let keys = Object.keys(financialTypeMapping_v3);
        for (let i = 0; i < keys.length; i++) {
            if (isNotNull(keys[i])) {
                let category = financialTypeMapping_v3[keys[i]];
                categoryMap[keys[i]] = {
                    type: category.type,
                    label: category.label,
                    total: 0,
                    items: [],
                    suggestions: [],
                    stroke: category.stroke,
                };
            }
        }
        return categoryMap;
    };

    componentDidMount = () => {
        this._isMounted = true;
        this.prepareCategories();
    };

    componentDidUpdate = prevProps => {
        if (!_.isEqual(prevProps.items, this.props.items))
            this.prepareCategories();
    };

    componentWillUnmount = () => {
        this._isMounted = false;
    };

    render_v2 = () => {
        const {type, selectItem, selectedItems, loaded} = this.props;
        return (
            <FinancialDataView onSuggestionClick={this.onAddItem} onItemClick={this.onClickItem}
                               selectItem={selectItem} selectedItems={selectedItems}
                               type={type}
                               totalAmount={this.getTotalAmount(type)}
                               categories={this.getCategories(type)} loaded={loaded}/>
        )
    };

    render = () => {
        // return this.render_v2();
        return (
            <>
                <FinancialDataView onSuggestionClick={this.onAddItem} onItemClick={this.onClickItem}
                                   selectItem={this.props.selectItem} selectedItems={this.props.selectedItems}
                                   type={'income'}
                                   totalAmount={this.getTotalAmount('income')}
                                   categories={this.getCategories('income')} loaded={this.props.loaded}/>
                <FinancialDataView onSuggestionClick={this.onAddItem} onItemClick={this.onClickItem}
                                   selectItem={this.props.selectItem} selectedItems={this.props.selectedItems}
                                   type={'expense'}
                                   totalAmount={this.getTotalAmount('expense')}
                                   categories={this.getCategories('expense')} loaded={this.props.loaded}/>
            </>
        );
    };
}

FinancialDataController.propTypes = {
    selectItem: PropTypes.func.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
        category: PropTypes.object.isRequired,
        monthly_price: PropTypes.number.isRequired,
    })).isRequired,
    selectedItems: PropTypes.arrayOf(PropTypes.object).isRequired,
    loaded: PropTypes.bool.isRequired,
};