import './style/addDocument.scss';

import React from 'react';
import PropTypes from 'prop-types';

import DocumentCountView from "./documentCountView";
import SearchDocumentTypeView from "./searchDocumentTypeView";
import DocumentTypeView from "./documentTypeView";
import ModuleController from "../../modules/moduleController";

export default class AddDocumentView extends React.Component {
    constructor(props) {
        super(props);

    }

    onSuggestionClick = (selectedValue) => {
        this.props.onSearchBarSuggestionClick(selectedValue);
        this.props.activateMobileSuggestions();
    };

    hideMobileSuggestions = () => {
        let suggestBar = document.getElementById('suggestBar');
        if (isNotNull(suggestBar) && isNull(suggestBar.value)) {
           this.props.hideMobileSuggestions();
        }
    };

    render = () => {
        return (
            <div className='addDocumentPage'>
                <div className='contentContainer'>
                    <div className='row'>
                        <div className='box small'>
                            <DocumentCountView documentCount={this.props.documentCount}
                                               onCountClick={this.props.onDocumentCountClick}/>
                        </div>
                        <div className='box big'>
                            <SearchDocumentTypeView onInputChange={this.hideMobileSuggestions}
                                                    documentTypes={this.props.searchBarTypes}
                                                    onSuggestionClick={this.onSuggestionClick}/>
                            <div id='searchMobileSuggestions'
                                 className={'mobileSuggestions' + (this.props.showMobileSuggestions ? ' show' : '')}>
                                <DocumentTypeView documentTypes={this.props.mobileSuggestionsDocs}
                                                  onTypeClick={this.props.onDocumentTypeClick}
                                                  documentCount={this.props.documentCount}
                                                  toDashboard={this.props.toDashboard}/>
                            </div>
                        </div>
                    </div>
                    <div className='row keepTogether fixHeight'>
                        <div className='box small'>
                            <ModuleController selectedModule={this.props.selectedModule}
                                              onSelect={this.props.onSelectModule} withMobileContainer={true}
                                              onAddDocumentClick={this.props.onDocumentTypeClick}
                                              documentTypes={this.props.documentTypes}/>
                        </div>
                        <div className='box big hideMobile'>
                            <DocumentTypeView documentTypes={this.props.documentTypes}
                                              onTypeClick={this.props.onDocumentTypeClick}
                                              documentCount={this.props.documentCount}
                                              toDashboard={this.props.toDashboard}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

AddDocumentView.propTypes = {
    documentCount: PropTypes.number.isRequired,
    onDocumentCountClick: PropTypes.func.isRequired,
    searchBarTypes: PropTypes.array.isRequired,
    onSearchBarSuggestionClick: PropTypes.func.isRequired,
    selectedModule: PropTypes.string,
    onSelectModule: PropTypes.func.isRequired,
    documentTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
    onDocumentTypeClick: PropTypes.func.isRequired,
    toDashboard: PropTypes.func.isRequired,
    searchBarDocumentType: PropTypes.object,
};