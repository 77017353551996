import React from 'react';

import PropTypes from 'prop-types';

import EvaluationButtonsView from './evaluationButtonsView';

export default class EvaluationButtonsController extends React.Component {
    constructor(props) {
        super(props);
    }

    render = () => {
        return (
            <EvaluationButtonsView headerTitle={this.props.headerTitle} buttons={this.props.buttons}/>
        );
    }
}

EvaluationButtonsController.propTypes = {
    headerTitle: PropTypes.string,
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            buttonText: PropTypes.string.isRequired,
            onClick: PropTypes.func.isRequired,
        })
    ).isRequired,
};