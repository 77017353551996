import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../../redux/actions';
import moment from 'moment';

import {deviceIsMobile} from 'glob-common-js/lib/utils';

import {sendPageView} from "../../common/js/ga";
import {timeFormats} from "../../common/components/datePicker";
import StartFlowView from "./startFlowView";
import {updateAccount} from "../../misc/requestSender";
import {setReduxUser} from "../../misc/utils";
import {addCustomEventListener, dispatchCustomEvent, removeCustomEventListener} from "../../misc/eventDispatcher";
import {NOW} from "../misc/constants";
import StateComponent from "../misc/stateComponent";

export class StartFlowController extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            dob: this.getDob() || '1990-01-01',
            active: false,
        };
    }

    onClose = () => {
        // Save dob if needed
        if (isNull(this.getDob())) {
            let user = this.props.userState.user;
            if (isNotNull(user)) {
                updateAccount({
                    id: user.id, data: user, callback: (response) => {
                        setReduxUser(response.data);
                    }
                });
            }
        }
        this.props.actions.startFlow(false);
        if (!deviceIsMobile())
            dispatchCustomEvent('startHighlight');
    };

    onActivate = () => {
        sendPageView('Start flow');
        this.changeState({active: true});
    };

    getDob = () => {
        let user = this.props.userState.user;
        if (isNotNull(user)) return user.dob;
        return null;
    };

    changeDob = (date) => {
        this.changeState({dob: date});
    };

    determineMode = () => {
        let dob = this.state.dob;
        if (isNotNull(dob)) {
            let dateOfBirth = moment(dob, timeFormats);
            let age = Math.abs(dateOfBirth.diff(NOW(), 'years'));
            if (age <= 19) return '18-19';
            if (age <= 25) return '20-25';
            return '25+';
        }
        return '25+';
    };

    componentDidMount = () => {
        this.mount();
        addCustomEventListener('launchStartFlow', this.onActivate);
    };

    componentWillUnmount = () => {
        this.unMount();
        removeCustomEventListener('launchStartFlow', this.onActivate);
    };

    render = () => (
        <StartFlowView user={this.props.userState.user} mode={this.determineMode()} changeDob={this.changeDob}
                       dob={this.state.dob} startFlow={this.props.miscState.startFlow || this.state.active}
                       onClose={this.onClose}/>
    );
}

const mapStateToProps = state => ({
    userState: state.user,
    miscState: state.misc,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(StartFlowController);