const menuItems = [
    {
        label: 'Jouw zorgverzekering 2019',
        url: null,
        subItems: [],
    },
    {
        label: 'Evaluatie',
        url: '/evaluatie',
        subItems: [
            {
                label: 'Basisverzekering',
                url: '/basis-verzekering',
                name: 'basic',
            },
            {
                label: 'Aanvullende verzekering',
                url: '/aanvullende-verzekering',
                name: 'additional',
            },
            {
                label: 'Eigen risico',
                url: '/eigen-risico',
                name: 'risk',
            },
            {
                label: 'Jouw verzekeraar',
                url: '/jouw-verzekeraar',
                name: 'insurer',
            },
            {
                label: 'Samenvatting',
                url: '/samenvatting',
                name: 'menu',
            },
        ],
    },
    {
        label: 'Aanpassingen',
        url: '/aanpassen',
        subItems: [
            {
                label: 'Gewenste dekking',
                url: '/dekking',
            },
            {
                label: 'Vergelijken',
                url: '/vergelijken',
            },
        ],
    },
    {
        label: 'Jouw verzekering 2020',
        url: '/jouw-verzekering',
        subItems: [
            {
                label: 'Afsluiten',
                url: '/afsluiten',
            },
            {
                label: 'Dossier inrichten',
                url: '/dossier-inrichten',
            },
        ],
    },
];

export default menuItems;
