import '../style/libraryListView.scss';

import React from 'react';
import PropTypes from 'prop-types';

import imageExtensions from "BdhCommon/data/mappings/imageExtensions";
import GenericLoader from "../../misc/genericLoader";
import CustomScrollbar from "../../misc/customScrollbar";
import {materialTypes} from "../../material/materialTypes";
import MaterialFactory from "../../material/materialFactory";
import LoadableList from "../../misc/loadableList";
import {remToPx} from "../../../misc/utils/appUtil";

export default class LibraryListView extends React.Component {
    constructor(props) {
        super(props);
    }

    // renderDocument = (doc) => {
    //     if (isNotNull(doc)) {
    //         let icon = this.determineIconName(doc.extension);
    //         let documentType = doc.type.label;
    //         let updatedAt = this.getUpdatedAt(doc);
    //         return (
    //             <div className='libraryListRow' key={doc.id} onClick={() => {
    //                 this.props.onDocumentDetails(doc);
    //             }}>
    //                 <div className='checkboxContainer'>
    //                     <MaterialFactory componentType={materialTypes.CHECKBOX} onClick={evt => {
    //                         evt.stopPropagation()
    //                     }} checked={this.props.selectedDocuments.includes(doc.id)} onChange={(evt) => {
    //                         evt.stopPropagation();
    //                         this.props.onSelectDocument(doc)
    //                     }}/>
    //                 </div>
    //                 <div className='clickableArea'>
    //                     <div className='iconContainer'>
    //                         <span className={icon}/>
    //                     </div>
    //                     <div className='labelContainer' title={doc.label}>
    //                         <label className='documentName'>{doc.label}</label>
    //                     </div>
    //                     <div className='updatedContainer' title={updatedAt}>
    //                         <label className='updatedLabel'>{updatedAt}</label>
    //                     </div>
    //                     <div className='typeContainer' title={documentType}>
    //                         <label className='documentType'>{documentType}</label>
    //                         {doc.type.name === 'uncategorised' && <span className='unknownDocument'>!</span>}
    //                     </div>
    //                 </div>
    //                 <div className='linkedContainer'>
    //                     {this.renderLinkedContainer(doc)}
    //                 </div>
    //             </div>
    //         )
    //     }
    //     return null;
    // };

    renderLinkedContainer = doc => {
        let dossiers = doc.belongings;
        if (dossiers.length === 0)
            return (
                <>
                    <label className='linkedDossier' onClick={evt => {
                        evt.stopPropagation();
                        this.props.onClickUnlinked(doc)
                    }}>Niet gekoppeld</label>
                    <span className='unknownDocument' onClick={evt => {
                        evt.stopPropagation();
                        this.props.onClickUnlinked(doc)
                    }}>!</span>
                </>
            );
        if (dossiers.length === 1)
            return (
                <>
                    <label className='linkedDossier' onClick={evt => {
                        evt.stopPropagation();
                        this.props.onClickDossier(dossiers[0])
                    }}>{this.getDossierName(doc)}</label>
                    <div className='common-icon-edit dossierEdit' onClick={evt => {
                        evt.stopPropagation();
                        this.props.onClickLinked(doc)
                    }}/>
                </>
            );
        if (dossiers.length > 1)
            return (
                <>
                    <label>{this.getDossierName(doc)}</label>
                    <div className='common-icon-arrow-right dossierArrow'/>
                    <div className='common-icon-edit dossierEdit' onClick={evt => {
                        evt.stopPropagation();
                        this.props.onClickLinked(doc)
                    }}/>
                    <div className='linkedDossierContainer'>
                        {dossiers.map(belonging => (
                            <label key={belonging.id} className='linkedDossier' onClick={evt => {
                                evt.stopPropagation();
                                this.props.onClickDossier(belonging)
                            }}>{belonging.name}</label>
                        ))}
                    </div>
                </>
            );
    };

    getUpdatedAt = doc => {
        if (isNotNull(doc.updatedAt)) return doc.updatedAt.format('DD-MM-YYYY HH:mm');
        return 'Niet bekend';
    };

    determineIconName = extension => {
        if (isNotNull(extension)) {
            if (/docx?/.test(extension)) return 'common-icon-word';
            else if (/xlsx?/.test(extension)) return 'common-icon-excel';
            else if (/pptx?/.test(extension)) return 'common-icon-powerpoint';
            else if (/pdf/.test(extension)) return 'common-icon-pdf';
            else if (imageExtensions.includes(extension.toLowerCase())) return 'common-icon-image';
        }
        return 'common-icon-document-2';
    };

    getDossierName = doc => {
        let belongings = doc.belongings;
        if (belongings.length > 1)
            return belongings.length + ' dossiers';
        if (belongings.length === 1)
            return doc.belongings[0].name;
    };

    renderDocuments = () => {
        const {documents, total, onLoadMore, isLoadingMore} = this.props;
        const hasMore = documents.length < total;
        return (
            <LoadableList onLoadMore={onLoadMore} renderRow={this.renderRow} hasMore={hasMore}
                          isLoadingMore={isLoadingMore} items={documents}
                          itemSize={remToPx(3)}/>
        )
    }

    renderRow = (props) => {
        const {documents, onDocumentDetails, onSelectDocument} = this.props;
        const {style, index} = props;
        const renderDocument = documents[index];
        const icon = this.determineIconName(renderDocument.extension);
        const documentType = renderDocument.type.label;
        const updatedAt = this.getUpdatedAt(renderDocument);
        return (
            <div className='libraryListRow' key={renderDocument.id} onClick={() => {
                onDocumentDetails(renderDocument);
            }} style={style}>
                <div className='checkboxContainer'>
                    <MaterialFactory componentType={materialTypes.CHECKBOX} onClick={evt => {
                        evt.stopPropagation()
                    }} onChange={(evt) => {
                        evt.stopPropagation();
                        onSelectDocument(renderDocument)
                    }}/>
                </div>
                <div className='clickableArea'>
                    <div className='iconContainer'>
                        <span className={icon}/>
                    </div>
                    <div className='labelContainer' title={renderDocument.label}>
                        <label className='documentName'>{renderDocument.label}</label>
                    </div>
                    <div className='updatedContainer' title={updatedAt}>
                        <label className='updatedLabel'>{updatedAt}</label>
                    </div>
                    <div className='typeContainer' title={documentType}>
                        <label className='documentType'>{documentType}</label>
                        {renderDocument.type.name === 'uncategorised' && <span className='unknownDocument'>!</span>}
                    </div>
                </div>
                <div className='linkedContainer'>
                    {this.renderLinkedContainer(renderDocument)}
                </div>
            </div>
        )
    }

    render = () => {
        return (
            <div className='libraryListView'>
                <GenericLoader active={this.props.loading}/>
                <CustomScrollbar height='30rem'>
                    <div className='listRows'>
                        <div className='libraryListRow selectAll'>
                            <div className='checkboxContainer'>
                                <MaterialFactory componentType={materialTypes.CHECKBOX} label='Selecteer alles'
                                                 onChange={(evt) => this.props.selectAll(evt.target.checked)}/>
                            </div>
                        </div>
                        {this.renderDocuments()}
                    </div>
                </CustomScrollbar>
            </div>
        )
    }
}

LibraryListView.propTypes = {
    onSelectDocument: PropTypes.func.isRequired,
    onDocumentDetails: PropTypes.func.isRequired,
    onClickDossier: PropTypes.func.isRequired,
    onClickUnlinked: PropTypes.func.isRequired,
    selectAll: PropTypes.func.isRequired,
    selectedDocuments: PropTypes.arrayOf(PropTypes.string).isRequired,
    documents: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        update_at: PropTypes.string,
        extension: PropTypes.string.isRequired,
        belongings: PropTypes.arrayOf(PropTypes.object).isRequired,
        type: PropTypes.shape({
            name: PropTypes.string.isRequired,
        })
    })).isRequired,
    loading: PropTypes.bool.isRequired,
    total: PropTypes.number.isRequired,
    onLoadMore: PropTypes.func.isRequired,
    isLoadingMore: PropTypes.bool.isRequired
};