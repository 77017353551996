export default {
    TRAVEL: 'travel',
    BUY_CAR: 'buy_car',
    GET_CHILD: 'get_child',
    DIVORCE: 'divorce',
    CV: 'CV',
    FUNERAL: 'FUNERAL',
    LOAN: 'LOAN',
    BUY_HOUSE: 'BUY_HOUSE',
    LIFETESTAMENT: 'LIFETESTAMENT',
}
