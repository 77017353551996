import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import moment from "moment";

import {isFunction} from 'glob-common-js/lib/utils';

import BdhPasswordInput from "./bdhPasswordInput";
import StateComponent from "../../../misc/stateComponent";
import {materialTypes} from "../../../material/materialTypes";
import MaterialFactory from "../../../material/materialFactory";
import {login, responseIsSuccess, updateAccount} from "../../../../misc/requestSender";
import {NOW} from "../../../misc/constants";

class BdhPasswordInputController extends StateComponent {
    twoFactorEnabled = false;
    passwordConfirmedAt = null;

    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            password: '',
            passwordError: null,
            modalLoading: false,
            valueVisible: false,
        };
    }

    onChangePassword = value => {
        this.changeState({password: value});
    };

    onCloseModal = () => {
        this.changeState({password: '', passwordError: null, modalLoading: false, modalOpen: false});
        if (this.twoFactorEnabled)
            this.enableTwoFactor();
    };

    onConfirmPassword = () => {
        const {password} = this.state, {userState, onConfirmPassword} = this.props, user = userState.user;
        if (isNotNull(password)) {
            this.changeState({modalLoading: true});
            login({
                keepJwt: true,
                email: user.email,
                password,
                callback: response => {
                    this.changeState({modalLoading: false});
                    if (responseIsSuccess(response)) {
                        this.changeState({valueVisible: true});
                        this.passwordConfirmedAt = NOW();
                        if (isFunction(onConfirmPassword)) onConfirmPassword();
                        this.onCloseModal();
                    } else this.changeState({passwordError: 'Wachtwoord is niet juist'});
                },
            });
        } else this.changeState({passwordError: 'Vul je wachtwoord in'});
    };

    onToggleVisibility = evt => {
        evt.stopPropagation();
        const {valueVisible} = this.state, {passProtect, value} = this.props;
        if (valueVisible || !passProtect || isNull(value) || this.isConfirmed())
            this.changeState({valueVisible: !valueVisible});
        else this.requirePassword();
    };

    onFocusPassword = () => {
        this.changeState({passwordError: null});
    };

    requirePassword = () => {
        const {userState} = this.props;
        this.twoFactorEnabled = userState.user.two_step_auth;
        if (this.twoFactorEnabled)
            this.disableTwoFactor();
        this.changeState({modalOpen: true})
    };

    isConfirmed = () => {
        const {passwordConfirmedAt} = this.props;
        const confirmMoment = passwordConfirmedAt === undefined ? this.passwordConfirmedAt : passwordConfirmedAt;
        return isNotNull(confirmMoment) && confirmMoment.diff(NOW(), 'minutes') <= 5;
    };

    disableTwoFactor = () => {
        const {userState} = this.props, user = userState.user;
        user.two_step_auth = 0;
        updateAccount({
            id: user.id,
            data: user,
        });
    };

    enableTwoFactor = () => {
        const {userState} = this.props, user = userState.user;
        user.two_step_auth = 1;
        updateAccount({
            id: user.id,
            data: user,
        });
    };

    getInputProps = () => {
        const {valueVisible} = this.state;
        let propsCopy = Object.assign({}, this.props, {
            valueVisible,
            onToggleVisibility: this.onToggleVisibility,
        });
        propsCopy = this.deleteProp(propsCopy, 'passProtect');
        propsCopy = this.deleteProp(propsCopy, 'userState');
        propsCopy = this.deleteProp(propsCopy, 'onConfirmPassword');
        propsCopy = this.deleteProp(propsCopy, 'dispatch');
        propsCopy = this.deleteProp(propsCopy, 'key');
        propsCopy = this.deleteProp(propsCopy, 'passwordConfirmedAt');
        propsCopy = this.deleteProp(propsCopy, 'onOpenConfirmation');
        propsCopy = this.deleteProp(propsCopy, 'onCloseConfirmation');
        return propsCopy;
    };

    deleteProp = (props, name) => {
        if (props.hasOwnProperty(name)) delete props[name];
        return props;
    };

    getModalActions = () => ([
        {label: 'Annuleren', onClick: this.onCloseModal},
        {label: 'Verzend', onClick: this.onConfirmPassword}
    ]);

    renderModalContent = () => {
        const {password, passwordError} = this.state;
        return (
            <div className='modalContent'>
                <p key='message' className='modalMessage'>Bevestig je account om dit wachtwoord te tonen.</p>
                <BdhPasswordInputController key='field' onChange={this.onChangePassword} value={password}
                                            error={passwordError} label='Wachtwoord' onFocus={this.onFocusPassword}
                                            fullWidth/>
            </div>
        )
    };

    componentDidMount = () => {
        this.mount();
    };

    componentWillUnmount = () => {
        this.unMount();
    };

    render = () => {
        const {modalOpen} = this.state;
        const {onOpenConfirmation, onCloseConfirmation} = this.props;
        return <>
            <MaterialFactory componentType={materialTypes.DIALOG} open={modalOpen} title={'Wachtwoord tonen'}
                             actions={this.getModalActions()} onClose={this.onCloseModal} onEnter={onOpenConfirmation}
                             onExit={onCloseConfirmation} content={this.renderModalContent()}/>
            <BdhPasswordInput {...this.getInputProps()}/>
        </>
    };
}

BdhPasswordInputController.propTypes = {
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onKeyDown: PropTypes.func,
    onConfirmPassword: PropTypes.func,
    onOpenConfirmation: PropTypes.func,
    onCloseConfirmation: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    label: PropTypes.string,
    containerClass: PropTypes.string,
    labelClass: PropTypes.string,
    inputContainerClass: PropTypes.string,
    inputClass: PropTypes.string,
    id: PropTypes.string,
    key: PropTypes.string,
    withLabelTransition: PropTypes.bool,
    ornaments: PropTypes.arrayOf(PropTypes.shape({
        placement: PropTypes.oneOf(['left', 'right']),
        element: PropTypes.node.isRequired,
    })),
    variant: PropTypes.oneOf(['contained', 'explode']),
    error: PropTypes.string,
    fullWidth: PropTypes.bool,
    passProtect: PropTypes.bool,
    passwordConfirmedAt: PropTypes.instanceOf(moment),
};

BdhPasswordInputController.defaultProps = {
    disabled: false,
    readOnly: false,
    containerClass: '',
    labelClass: '',
    inputContainerClass: '',
    inputClass: '',
    type: 'text',
    min: 0,
    max: 0,
    step: 0,
    withLabelTransition: true,
    ornaments: [],
    variant: 'explode',
    fullWidth: false,
    passProtect: false,
};

const mapStateToProps = state => ({
    userState: state.user,
});

export default connect(mapStateToProps)(BdhPasswordInputController);