import './style/subscriptionIntro.scss';

import React from 'react';
import PropTypes from 'prop-types';
import moment from "moment";

import {getSetting, pathIsNotNull} from 'glob-common-js/lib/utils';

import {sendGaEvent, sendPageView} from "../../../../common/js/ga";
import {steps} from "../../registrationConstants";
import {timeFormats} from "../../../../common/components/datePicker";
import MaterialFactory from "../../../material/materialFactory";
import {materialTypes} from "../../../material/materialTypes";
import {NOW} from "../../../misc/constants";

const subImage = require('../../../../common/images/transparantmac.png');

const GA_CATEGORY = 'Registreren (Abonnementinfo)';
const activatedOldUsers = getSetting('activateOldUsers');
export default class SubscriptionIntroView extends React.Component {
    constructor(props) {
        super(props);
    }

    onClickFaq = () => {
        sendGaEvent(GA_CATEGORY, 'Klik', 'faq');
        window.open('https://bydehand.com/faq');
    };

    onClickActivate = () => {
        sendGaEvent(GA_CATEGORY, 'Klik', 'Activeer proefabonnement');
        fbq('track', 'AddPaymentInfo');
        this.props.setSubStep(steps.ACTIVATION.START_PAYMENT);
    };

    isShowTrial = () => {
        if (!activatedOldUsers) return true;

        const subscriptionInfo = this.props.subscriptionInfo;
        return pathIsNotNull(subscriptionInfo, 'details') && isNotNull(subscriptionInfo.end) &&
            subscriptionInfo.details === 'free_period_first' &&
            moment(subscriptionInfo.end, timeFormats).isSameOrAfter(NOW(), 'day');
    };

    toggleExtraClassName = (force) => {
        const popup = document.querySelector('div.registrationView');
        if (isNotNull(popup))
            popup.classList.toggle('payment', force);
    };

    renderMessageTitle = () => {
        return this.isShowTrial() ? this.renderTrialTitle() : this.renderSubscriptionTitle();
    };

    renderTrialTitle = () => 'Probeer ByDeHand nu twee weken gratis!';

    renderSubscriptionTitle = () => 'Je ByDeHand-abonnement is verlopen! Wat nu?';

    renderMessageBody = () => {
        return this.isShowTrial() ? this.renderTrialBody() : this.renderSubscriptionBody();
    };

    renderTrialBody = () => (
        <>
            <ul className='subList'>
                <li>Twee weken lang alles van ByDeHand uitproberen.</li>
                <li>Een éénmalige iDEAL transactie van € 0,01 zodat wij je gegevens kunnen verifiëren.</li>
                <li>Hiermee geef je ons toestemming om na twee weken maandelijks € 2,50 af te schrijven.</li>
                <li>Toch niets voor jou? Geen verplichtingen, opzeggen kan op elk moment!</li>
                <li>Meer informatie over je proefabonnement? Check dan onze <span className='faqLink'
                                                                                  onClick={this.onClickFaq}>FAQ</span>!
                </li>
            </ul>
            <MaterialFactory componentType={materialTypes.RAISED_BUTTON}
                             onClick={this.onClickActivate} className='proceedButton'>Activeer
                proefabonnement</MaterialFactory>
        </>
    );

    renderSubscriptionBody = () => (
        <>
            <div className='columnContainer'>
                <p className='column text'>
                    Beste {this.props.firstName},<br/><br/>
                    Bedankt dat je ByDeHand gebruikt!<br/><br/>
                    Een abonnement op je ByDeHand-account kost € 2,50 per maand en is op ieder moment
                    opzegbaar.<br/><br/>Meer informatie hierover vind je op de website.<br/><br/>
                    Klik hieronder op 'Activeer account' om verder te gaan.
                </p>
            </div>
            <MaterialFactory componentType={materialTypes.RAISED_BUTTON}
                             onClick={this.onClickActivate} className='proceedButton'>Activeer account</MaterialFactory>
            <MaterialFactory componentType={materialTypes.FLAT_BUTTON}
                             onClick={this.props.onDeleteAccount} className='proceedButton'>
                Verwijder account
            </MaterialFactory>
        </>
    );

    componentDidMount = () => {
        this.toggleExtraClassName(true);
        ga('set', 'page', '/registratie/3-betalen-intro');
        sendPageView();
    };

    render = () => (
        <div className='subscriptionIntro'>
            <label className='headerTitle'>{this.renderMessageTitle()}</label>
            {this.renderMessageBody()}
        </div>
    )
};

SubscriptionIntroView.propTypes = {
    onDeleteAccount: PropTypes.func.isRequired,
    setSubStep: PropTypes.func.isRequired,
    subscriptionInfo: PropTypes.shape({
        details: PropTypes.string.isRequired,
    }).isRequired,
    firstName: PropTypes.string.isRequired,
};