import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from "../../../../redux/actions";
import moment from 'moment';

import {getSetting, setCookie} from 'glob-common-js/lib/utils';

import {getSbjsData, getSbjsLocation} from "../../../../misc/cookieUtil";
import {
    addSubscriptionUser,
    checkUsername, getSubscriptionStatus,
    register,
    responseIsSuccess, updateAccount,
    validateDaisyUser
} from "../../../../misc/requestSender";
import {steps} from "../../registrationConstants";
import RegistrationStep from "./registrationStep";
import {setSentryUser} from "../../../../misc/utils";
import {timeFormats} from "../../../../common/components/datePicker";
import {NOW} from "../../../misc/constants";
import {sendGaEvent} from "../../../../common/js/ga";
import {ACTIVE, PAYMENT_PENDING, PAYMENT_WAITING} from "../../../../misc/authenticationConstants";
import {setJwt} from "../../../../common/js/platform";
import {getAuthParameter} from "../../../authentication/authenticationHelper";

const STATUS_SUCCESS = 'success';

class RegistrationStepController extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
        };
        this._isMounted = false;
    }

    changeState = stateChange => {
        this._isMounted && this.setState(stateChange);
    };

    checkSkipMailOnActionCode = actionCode => {
        let skipMailPlatformVersion = false;
        if (actionCode==='CAV-levenstestament') {
            skipMailPlatformVersion = true;
        }
        return skipMailPlatformVersion
    }
    onRegister = values => {
        this.props.onSetLoading(true);
        this.changeState({error: null});
        fbq('track', 'AddToCart');
        const actionCode = getAuthParameter('action_code', false);
        let skipMailPlatformVersion = this.checkSkipMailOnActionCode(actionCode);

        register({
            data: this.createRegistrationData(values,skipMailPlatformVersion),
            callback: response => {
                this.registerCallback(response, values,skipMailPlatformVersion);
            },
        })
    };

    createRegistrationData = (values,skipMailPlatformVersion)  => ({
        firstname: values.firstname,
        email: values.email,
        password: values.password,
        password_repeat: values.password,
        terms_accepted: values.terms ? 'true' : 'false',
        skip_validation: skipMailPlatformVersion ? 'true' : getSetting('skipMailValidation'),
        infix: '',
        lastname: '',
        gender: 0,
        referral: null,
        tag_0: getSbjsData('src', {email: values.email}) + ' / ' + getSbjsData('mdm', {email: values.email}),
        tag_1: window.isAndroid ? 'Android' : window.isIos ? 'iOS' : 'Website',
        tag_2: getSbjsLocation(),
    });

    registerCallback = (response, values,skipMailPlatformVersion) => {
        if (response.data.status === STATUS_SUCCESS) {
            if (getSetting('skipMailValidation')) {
                this.registerDaisyUser(values, response.data.token, response.data.user);
            } else if (skipMailPlatformVersion) {
                 this.registerDaisyUser(values, response.data.token, response.data.user);
            } else if (isNotNull(response.data.activation_key)) {
                this.validateUsername(response, values);
            } else this.props.onError('Registreren is niet gelukt (0)');
        } else {
            this.props.onSetLoading(false);
            this.changeState({error: 'Dit e-mailadres is al in gebruik'});
        }
    };

    validateUsername = (response, values) => {
        let username = response.data.username;
        let token = response.data.token;
        checkUsername({
            username, jwt_token: token,
            callback: () => {
                this.registerDaisyUser(values, token)
            }
        });
    };

    registerDaisyUser = (values, token = null, user = null) => {
        const actionCode = getAuthParameter('action_code');
        let skipMailPlatformVersion = this.checkSkipMailOnActionCode(actionCode);

        addSubscriptionUser({
            birthday: values.dob.format('YYYY-MM-DDTHH:mm'),
            user_name: values.email,
            trial: getSetting('trial'),
            jwt_token: token,
            tags: this.createRegistrationTags(values.email),
            email: values.email,
            firstname: values.firstname,
            action_code: actionCode,
            callback: (response) => {
                this.props.onSetLoading(false);
                if (responseIsSuccess(response)) {
                    const {onSetUser, onSetStep} = this.props;
                    // Activation mail sent, proceed to next step
                    if (getSetting('skipMailValidation')) {
                        this.accountActivated(user);
                    } else if (skipMailPlatformVersion) {
                        this.accountActivated(user);
                    } else {
                        onSetUser(Object.assign({}, this.props.user, {email: values.email}));
                        onSetStep(steps.ACTIVATION.index, steps.ACTIVATION.MAIL_SEND);
                    }
                } else {
                    // TODO Delete LabelA account
                    this.props.onError('Registratie is niet voltooid (1)');
                }
            }
        });
    };

    accountActivated = user => {
        validateDaisyUser({
            callback: () => {
                getSubscriptionStatus({
                    callback: (response) => {
                        if (responseIsSuccess(response)) {
                            const subUser = response.data.user;
                            this.props.actions.setSubscription(subUser);
                            const isYounger = this.setUserDetails(user, subUser);
                            this.determineNextStep(subUser, isYounger);
                        }
                    }
                });
            }
        });
    };

    determineNextStep = (user, isYounger) => {
        const error = index => {
            this.props.onError(`Account activatie is niet gelukt. (${index})`);
        };
        if (pathIsNotNull(user, 'subscription') && isNotNull(user.status)) {
            const {subscription, status} = user;
            if (isNotNull(status)) {
                sendGaEvent('Betalen', 'Registreren', 'completed');
                const allowedStatuses = [ACTIVE, PAYMENT_PENDING, PAYMENT_WAITING];
                if (allowedStatuses.includes(status)) {
                    const period = subscription.period;
                    if (isNotNull(period)) {
                        const action = this.getActionFromPeriod(period);
                        if (action !== false) {
                            return action();
                        }
                    }
                    if (isYounger) {
                        this.props.onSetStep(steps.COMPLETION.index, steps.COMPLETION.FREE);
                        return;
                    }
                    error(3);
                }
                this.props.onSetStep(steps.ACTIVATION.index, steps.ACTIVATION.PAYMENT_INTRO);
            } else
                error(2);
        } else
            error(1);
    };

    getActionFromPeriod = (period) => {
        //console.log('registrationStepController - getActionFromPeriod')
        switch (period.details) {
            case 'free_period_first':
                return () => {
                    sendGaEvent('Betalen', 'Registreren', 'completed proefperiode');
                    this.props.onSetStep(steps.COMPLETION.index, steps.COMPLETION.FREE);
                };
            case 'free_period_birthday':
                return this.completeAsYounger;
            case 'free_action_code':
                return this.completeWithCode;
            default:
                return false;
        }
    };

    completeAsYounger = () => {
        sendGaEvent('Betalen', 'Registreren', 'completed jongeren');
        this.props.onSetStep(steps.COMPLETION.index, steps.COMPLETION.FREE);
    };

    completeWithCode = () => {
        sendGaEvent('Betalen', 'Registreren', 'completed action code');
        this.props.onSetStep(steps.COMPLETION.index, steps.COMPLETION.FREE);
    };

    setUserDetails = (user, userData) => {
        if (isNull(user.dob))
            user.dob = moment(userData.birthday, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ss');
        setSentryUser(user);
        this.props.onSetUser(user);

        let dateOfBirth = moment(user.dob, timeFormats);
        let isYounger = Math.abs(moment(dateOfBirth, timeFormats).diff(NOW(), 'years')) <= 25;
        user.external_data = Object.assign({}, user.external_data, {
            targetGroup: isYounger ? 'younger' : 'older',
        });
        updateAccount({data: user, id: user.id});
        return isYounger;
    };

    createRegistrationTags = (email) => ({
        'source/medium': getSbjsData('src', {email}) + ' / ' + getSbjsData('mdm', {email}),
        'campagne': getSbjsData('cmp'),
        'registratiewijze': window.isAndroid ? 'Android' : window.isIos ? 'iOS' : 'Website',
        'registratiepagina': getSbjsLocation(),
        'content': getSbjsData('cnt'),
        'term': getSbjsData('trm'),
    });

    componentDidMount = () => {
        this._isMounted = true;
    };

    componentWillUnmount = () => {
        this._isMounted = false;
    };

    render = () => {
        return <RegistrationStep onClickLogin={this.props.onClickLogin} onRegister={this.onRegister}
                                 emailError={this.state.error}/>
    };
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch),
});

export default connect(null, mapDispatchToProps)(RegistrationStepController);

RegistrationStepController.propTypes = {
    onSetStep: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    onSetLoading: PropTypes.func.isRequired,
    onSetUser: PropTypes.func.isRequired,
    onClickLogin: PropTypes.func.isRequired,
};
