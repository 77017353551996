import {getPlatformVersion} from "../../../misc/utils";

let months = ['JAN', 'FEB', 'MAA', 'APR', 'MEI', 'JUN', 'JUL', 'AUG', 'SEP', 'OKT', 'NOV', 'DEC'];
let date = new Date();

const mapping = {
    study: [
        {
            buttonText: 'Studentenkaart',
            type: 'document',
            icon: '',
        },
        {
            buttonText: 'Bewijs van inschrijving',
            type: 'document',
            icon: '',
        },
        {
            buttonText: 'Cijferlijst',
            type: 'document',
            icon: '',
        },
        {
            buttonText: 'Diploma',
            type: 'document',
            icon: '',
        },
        {
            buttonText: 'Studie',
            type: 'dossier',
            icon: 'common-icon-home',
        },
        {
            buttonText: 'Middelbare school ',
            type: 'dossier',
            icon: 'common-icon-tax',
        },
        {
            buttonText: 'Stage',
            type: 'dossier',
            icon: 'common-icon-banking-spending',
        },
        {
            buttonText: 'Nevenactiviteiten',
            type: 'dossier',
            icon: 'common-icon-personal-info',
        },
        {
            buttonText: 'Nieuwe zorgverzekering analyse online!',
            type: 'alert',
            icon: '',
        },
        {
            buttonText: 'Visum aanvragen voor China',
            type: 'alert',
            icon: '',
        },
        {
            buttonText: 'APK BMW 3 verloopt',
            type: 'alert',
            icon: '',
        },
        {
            buttonText: 'Visum aanvragen',
            type: 'actie',
            icon: '',
        },
        {
            buttonText: 'Boardingpass Londen printen',
            type: 'actie',
            icon: '',
        },
        {
            buttonText: 'Afspraak nieuwe APK maken',
            type: 'actie',
            icon: '',
        },
        {
            buttonText: 'Taxatie afspraak Huis',
            type: 'agenda',
            date: {day: date.getDay() + 1, month: months[date.getMonth()]},
            icon: '',
        },
        {
            buttonText: 'Belasting aangifte doen',
            type: 'agenda',
            date: {day: date.getDay() + 8, month: months[date.getMonth()]},
            icon: '',
        },
        {
            buttonText: 'Zorgverzekeringanalyse aanvragen',
            type: 'agenda',
            date: {day: date.getDay() + 16, month: months[date.getMonth()]},
            icon: '',
        },
    ],
    personal: [
        {
            buttonText: 'ID-kaart',
            type: 'document',
            icon: '',
        },
        {
            buttonText: 'CV',
            type: 'document',
            icon: '',
        },
        {
            buttonText: 'VOG',
            type: 'document',
            icon: '',
        },
        {
            buttonText: 'Klantenkaart',
            type: 'document',
            icon: '',
        },
        {
            buttonText: 'Persoonlijke gegevens',
            type: 'dossier',
            icon: 'common-icon-home',
        },
        {
            buttonText: 'Persoonlijke pasjes',
            type: 'dossier',
            icon: 'common-icon-tax',
        },
    ],
    valuable_possession: [
        {
            buttonText: 'Aankoopbon / Garantiebewijs',
            type: 'document',
            icon: '',
        },
        {
            buttonText: 'Handleiding',
            type: 'document',
            icon: '',
        },
        {
            buttonText: 'Mobiele telefoon',
            type: 'dossier',
            icon: 'common-icon-home',
        },
        {
            buttonText: 'Laptop',
            type: 'dossier',
            icon: 'common-icon-tax',
        },
        {
            buttonText: 'Inrichting, meubilair',
            type: 'dossier',
            icon: 'common-icon-tax',
        },
        {
            buttonText: 'Tablet',
            type: 'dossier',
            icon: 'common-icon-tax',
        },
    ],
    money_income: [
        {
            buttonText: 'Arbeidsovereenkomst',
            type: 'document',
            icon: '',
        },
        {
            buttonText: 'Aansprakelijkheidsverzekering',
            type: 'document',
            icon: '',
        },
        {
            buttonText: 'Belastingaangifte',
            type: 'document',
            icon: '',
        },
        {
            buttonText: 'Telefoonabonnement',
            type: 'document',
            icon: '',
        },
        {
            buttonText: 'Baan',
            type: 'dossier',
            icon: 'common-icon-home',
        },
        {
            buttonText: 'Belasting',
            type: 'dossier',
            icon: 'common-icon-tax',
        },
        {
            buttonText: 'Abonnementen',
            type: 'dossier',
            icon: 'common-icon-tax',
        },
        {
            buttonText: 'Aansprakelijkheidsverzekering',
            type: 'dossier',
            icon: 'common-icon-tax',
        },
    ],
    out_house: [
        {
            buttonText: 'Huurcontract',
            type: 'document',
            icon: '',
        },
        {
            buttonText: 'Tv/Internet contract',
            type: 'document',
            icon: '',
        },
        {
            buttonText: 'Energie contract',
            type: 'document',
            icon: '',
        },
        {
            buttonText: 'Water contract',
            type: 'document',
            icon: '',
        },
        {
            buttonText: 'Huurwoning',
            type: 'dossier',
            icon: 'common-icon-home',
        },
    ],
    free_time_sub: [
        {
            buttonText: 'Visum',
            type: 'document',
            icon: '',
        },
        {
            buttonText: 'Vliegticket',
            type: 'document',
            icon: '',
        },
        {
            buttonText: 'Museumkaart',
            type: 'document',
            icon: '',
        },
        {
            buttonText: 'Bioscoop pas',
            type: 'document',
            icon: '',
        },
        {
            buttonText: 'Reis',
            type: 'dossier',
            icon: 'common-icon-home',
        },
        {
            buttonText: 'Reis',
            type: 'dossier',
            icon: 'common-icon-home',
        },
        {
            buttonText: 'Mijn hobby',
            type: 'dossier',
            icon: 'common-icon-home',
        },
        {
            buttonText: 'Sport',
            type: 'dossier',
            icon: 'common-icon-home',
        },
        {
            buttonText: 'Kamperen',
            type: 'dossier',
            icon: 'common-icon-home',
        },
    ],
    care: [
        {
            buttonText: 'Polis zorgverzekering',
            type: 'document',
            icon: '',
        },
        {
            buttonText: 'Zorgpas',
            type: 'document',
            icon: '',
        },
        {
            buttonText: 'Vaccinatiebewijs',
            type: 'document',
            icon: '',
        },
        {
            buttonText: 'Donorpas',
            type: 'document',
            icon: '',
        },
        {
            buttonText: 'Zorgverzekering',
            type: 'dossier',
            icon: 'common-icon-home',
        },
        {
            buttonText: 'Huisarts',
            type: 'dossier',
            icon: 'common-icon-home',
        },
        {
            buttonText: 'Tandarts',
            type: 'dossier',
            icon: 'common-icon-home',
        },
        {
            buttonText: 'Medische hulpmiddelen',
            type: 'dossier',
            icon: 'common-icon-home',
        },
    ],
    lifeWill: [
        {
            buttonText: 'Testament',
            type: 'document',
            icon: '',
        },
        {
            buttonText: 'Donorcodicil',
            type: 'document',
            icon: '',
        },
        {
            buttonText: 'Verzekeringspolis',
            type: 'document',
            icon: '',
        },
        {
            buttonText: 'Huwelijkse voorwaarden',
            type: 'document',
            icon: '',
        },
        {
            buttonText: 'Mijn woning',
            type: 'dossier',
            icon: 'common-icon-home',
        },
        {
            buttonText: 'Mijn pensioen',
            type: 'dossier',
            icon: 'common-icon-home',
        },
        {
            buttonText: 'Belastingen',
            type: 'dossier',
            icon: 'common-icon-home',
        },
        {
            buttonText: 'Persoonlijke documenten',
            type: 'dossier',
            icon: 'common-icon-home',
        },
    ]
};

const getMapping = (module = null, activeModules = null) => {
    if (getPlatformVersion()==='lifeWill'){
      return mapping['lifeWill']
    } else {
      return isNotNull(module) && mapping.hasOwnProperty(module) ? mapping[module] : getAllMappings(activeModules);
    }
};

const getAllMappings = (activeModules) => {
    let result = [];
    let keys = Object.keys(mapping);
    for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        if (isNull(activeModules) || activeModules.indexOf(key) > -1)
            result = result.concat(mapping[key]);
    }
    return result;
};
export default getMapping;
