import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../../redux/actions';
import PropTypes from 'prop-types';

import {isFunction} from 'glob-common-js/lib/utils';

import {sendGaEvent} from "../../common/js/ga";
import {createTimeItem, getAllDossiers, getDocument, responseIsSuccess, updateDocument} from "../../misc/requestSender";
import {navigate, showMessage, showSnackbar, toMoment, getPlatformVersion} from "../../misc/utils";
import getDossierType from "../../common/data/mappings/dossierTypeMapping";
import {dispatchCustomEvent} from "../../misc/eventDispatcher";
import getDocumentType from "../../common/data/mappings/docTypeMapping";
import {materialTypes} from "../material/materialTypes";
import MaterialFactory from "../material/materialFactory";
import ChecklistView from "./checklistView";
import checklistTypes from "./checklistTypes";
import StateComponent from "../misc/stateComponent";
import ErrorBoundary from "../errorBoundary";
import {parse} from "../../misc/queryParser";
import Dossier from "../../models/dossier";
import {
    addDataEventListener,
    DATA_TYPES,
    EVENT_TYPES,
    removeAllDataEventListeners
} from "../../misc/dataEvent";

class ChecklistController extends StateComponent {
    constructor(props) {
        super(props);
        this.dataMapping = this.getDataMapping();
        this.state = {
            checklists: [],
            currentChecklist: 'new',
            checklistName: this.dataMapping.name,
            selectedValues: [],
            checkboxValues: {},
            deleteDialogOpen: false,
            addDialogOpen: false,
            hasChanges: false,
        };
        this.saveInterval = null;
    }

    onAddNewChecklist = () => {
        sendGaEvent(this.dataMapping.GA_CATEGORY, 'Klik', 'Nieuwe checklist');
        this.changeState({
            checklistName: '',
            addDialogOpen: true,
        });
    };

    onSaveNewChecklist = (notify = true, isAutomatic = false, callback = null) => {
        sendGaEvent(this.dataMapping.GA_CATEGORY, 'Nieuwe checklist', 'Opslaan');
        const {selectedValues, checkboxValues} = this.state;
        new Dossier({
            name: this.getNewChecklistName(),
            type: getDossierType('checklist_travel'),
            external_data: {
                isDossier: false,
                checklist: {
                    values: isAutomatic ? selectedValues : [],
                    checkboxValues: isAutomatic ? checkboxValues : {},
                    type: this.dataMapping.type,
                },
            },
        }).save(this.saveNewChecklistCallback(notify, callback));
    };

    onClickDelete = () => {
        const {currentChecklist} = this.state;
        if (isNotNull(currentChecklist) && currentChecklist !== 'new')
            this.changeState({deleteDialogOpen: true});
    };

    onChangeBox = (name, afterChange) => () => {
        sendGaEvent(this.dataMapping.GA_CATEGORY, 'Klik', 'Aan-/Afvinken');
        const selectedValues = this.state.selectedValues.slice();
        if (selectedValues.includes(name))
            selectedValues.splice(selectedValues.indexOf(name), 1);
        else selectedValues.push(name);
        this.changeState({selectedValues, hasChanges: true}, afterChange);
    };

    onChangeBoxValue = (name, afterChange) => (value) => {
        sendGaEvent(this.dataMapping.GA_CATEGORY, 'Klik', `Verander waarde ${name}`);
        const checkboxValues = Object.assign({}, this.state.checkboxValues);
        checkboxValues[name] = value;
        this.changeState({checkboxValues, hasChanges: true}, afterChange);
    };

    onSaveReminder = (data, callback) => {
        createTimeItem({
            data: this.createReminderData(data),
            callback: this.saveReminderCallback(callback),
        })
    };

    onClickLink = link => () => {
        if (pathIsNotNull(link, 'url')) {
            const url = link.url;
            sendGaEvent(this.dataMapping.GA_CATEGORY, 'Klik', 'Link');
            if (isFunction(url)) url();
            else externalLink(url);
        }
    };

    onSelectChecklist = id => {
        const {checklists, hasChanges} = this.state;
        if (isNotNull(id))
            Dossier.get(id, dossier => {
                let values = [];
                let checkboxValues = {};
                if (isNotNull(dossier)) {
                    for (let i = 0; i < checklists.length; i++) {
                        const checklist = checklists[i];
                        if (pathIsNotNull(dossier.externalData, 'checklist') && checklist.id === id) {
                            values = this.getValues(dossier);
                            checkboxValues = this.getCheckboxValues(dossier);
                            this.replaceChecklist(i, dossier);
                            break;
                        }
                    }
                    if (hasChanges)
                        this.saveDossier(false, () => {
                            this.changeState({currentChecklist: id, selectedValues: values, checkboxValues});
                        });
                    else this.changeState({currentChecklist: id, selectedValues: values, checkboxValues});
                } else showMessage('Het laden van de checklist is niet gelukt. Probeer het later opnieuw of ' +
                    'neem contact met ons op.');
            });
    };

    onUploadDocument = type => () => {
        sendGaEvent(this.dataMapping.GA_CATEGORY, 'Document toevoegen', type);
        const documentType = getDocumentType(type);
        dispatchCustomEvent('addDocument', {typeId: documentType.id, saveCallback: this.linkDocument});
    };

    onClickDocument = doc => {
        sendGaEvent(this.dataMapping.GA_CATEGORY, 'Klik', 'Document details');
        dispatchCustomEvent('openDocumentDetails', doc);
    };

    onChangeChecklistName = evt => {
        this.changeState({checklistName: evt.target.value});
    };

    onClickBack = () => {
      if (getPlatformVersion()==='lifeWill') {
        navigate('/dashboard');
      } else {
        navigate('/checklists');
      }
    };

    linkDocument = doc => {
        const linkFunc = dossier => {
            doc.load(doc => {
                const dossiers = doc.dossiers;
                dossiers.push(dossier);
                doc.dossiers = dossiers;
                doc.update(this.linkDocumentCallback);
            });
        };
        const {currentChecklist} = this.state;
        if (currentChecklist === 'new')
            this.onSaveNewChecklist(false, true, linkFunc);
        else linkFunc({id: currentChecklist});
    };

    linkDocumentCallback = () => {
        const {currentChecklist} = this.state;
        Dossier.get(currentChecklist, dossier => {
            this.replaceChecklist(null, dossier);
            showSnackbar('Het document is toegevoegd aan je checklist.');
        });
    };

    saveDossier = (notify = false, callback = null) => {
        const {currentChecklist, checklistName, selectedValues, checklists, hasChanges, checkboxValues} = this.state;
        if (hasChanges && isNotNull(currentChecklist)) {
            if (currentChecklist === 'new' && isNotNull(checklistName))
                this.onSaveNewChecklist(notify, true);
            else {
                const dossier = this.getChecklist(currentChecklist);
                dossier.externalData.checklist = dossier.externalData.checklist || {};
                dossier.externalData.checklist.values = selectedValues;
                dossier.externalData.checklist.checkboxValues = checkboxValues;
                dossier.update(() => {
                    for (let i = 0; i < checklists.length; i++) {
                        const checklist = checklists[i];
                        if (checklist.id === dossier.id) {
                            this.replaceChecklist(i, dossier);
                            break;
                        }
                    }
                    this.changeState({
                        currentChecklist: dossier.id,
                        hasChanges: false,
                    });
                    if (isFunction(callback)) callback();
                    if (notify) dispatchCustomEvent('openSnackbar', {text: 'Checklist is opgeslagen'});
                });
            }
        } else if (notify)
            dispatchCustomEvent('openSnackbar', {text: 'Checklist is opgeslagen'});
    };

    saveReminderCallback = callback => response => {
        if (responseIsSuccess(response)) {
            dispatchCustomEvent('openSnackbar', {text: 'Je herinnering is ingesteld'});
        } else showMessage('Het aanmaken van de herinnering is niet gelukt. Probeer het later opnieuw of neem ' +
            'contact met ons op.', null, 'Herinnering mislukt');
        callback();
    };

    createReminderData = (data) => {
        const {remindDate} = data, startDate = toMoment(remindDate);
        startDate.add(1, 'hours');
        return {
            name: data.name,
            start_date_item: startDate.format('YYYY-MM-DDTHH:mm'),
            end_date_item: startDate.format('YYYY-MM-DDTHH:mm'),
            description: data.description,
            flex: {
                communication: this.createCommunication(data),
                name: this.createUserName(),
            },
            frequency: 0, // Only once
            location: '',
            type: 2, // Reminder
        }
    };

    createCommunication = data => {
        const communication = [];
        if (data.sms.toggled)
            communication.push({
                remind_on: data.sms.value,
                enabled: true,
                remind_date: -3600,
                type: 'sms',
            });
        if (data.email.toggled)
            communication.push({
                remind_on: data.email.value,
                enabled: true,
                remind_date: -3600,
                type: 'email',
            });
        return communication;
    };

    createUserName = () => {
        const {user} = this.props.userState;
        if (isNotNull(user))
            return user.firstname + (isNotNull(user.infix) ? ' ' + user.infix : '') +
                (isNotNull(user.lastname) ? ' ' + user.lastname : '');
        return '';
    };

    getNewChecklistName = () => {
        const {checklists} = this.state, checklistNames = checklists.map(checklist => checklist.name);
        let originalName = this.state.checklistName, name = this.state.checklistName, nameCounter = 0;
        while (checklistNames.includes(name))
            name = originalName + ` (${++nameCounter})`;
        return name;
    };

    saveNewChecklistCallback = (notify, callback) => dossier => {
        this.closeDialog('addDialogOpen')();
        if (isNotNull(dossier)) {
            dispatchCustomEvent('updateChecklists');
            this.addChecklist(dossier);
            this.changeState({
                checklistName: this.dataMapping.name,
                currentChecklist: dossier.id,
                selectedValues: dossier.externalData.checklist.values,
                hasChanges: false,
            });
            if (notify)
                dispatchCustomEvent('openSnackbar', {text: 'Checklist is aangemaakt'});
            if (isFunction(callback))
                callback(dossier);
        } else showMessage('Het aanmaken van de checklist is niet gelukt. Probeer het later opnieuw of neem ' +
            'contact met ons op.', null, 'Toevoegen mislukt');
    };

    getChecklist = id => {
        const {checklists} = this.state;
        if (isNotNull(checklists) && id !== 'new')
            for (let i = 0; i < checklists.length; i++)
                if (checklists[i].id === id) return checklists[i];
        return new Dossier({
            name: 'new',
            id: 'new_id',
            values: {},
        });
    };

    closeDialog = name => () => {
        this.changeState({[name]: false, checklistName: this.dataMapping.name});
    };

    deleteChecklist = () => {
        const {GA_CATEGORY} = this.dataMapping;
        const {currentChecklist} = this.state;
        sendGaEvent(GA_CATEGORY, 'Checklist verwijderen', 'Verwijderen');
        if (currentChecklist !== 'new')
            this.getChecklist(currentChecklist).delete(this.deleteDossierCallback);
    };

    deleteDossierCallback = response => {
        this.closeDialog('deleteDialogOpen')();
        if (responseIsSuccess(response)) {
            const {currentChecklist} = this.state;
            const checklists = this.removeChecklist(currentChecklist);
            const activeChecklist = isNotNull(checklists) ? checklists[0] : null;
            const activeChecklistId = isNotNull(activeChecklist) ? activeChecklist.id : 'new';
            this.changeState({
                checklists,
                currentChecklist: activeChecklistId,
                selectedValues: this.getValues(activeChecklist),
                checkboxValues: this.getCheckboxValues(activeChecklist),
                hasChanges: false,
            });
        } else showMessage('Het verwijderen van de checklist is niet gelukt, probeer het later ' +
            'opnieuw of neem contact met ons op.', null, 'Verwijderen mislukt');
    };

    getDataMapping = () => {
        const {mappingFileName} = this.props;
        return require(`../../data/checklists/mappings/${mappingFileName}`).default;
    };

    getCategories = () => {
        const {selectedValues} = this.state;
        const categories = Object.values(this.dataMapping.categories);
        categories.forEach(category => {
            let completedItems = 0;
            const {boxes} = category, totalItems = boxes.length;
            boxes.forEach(box => {
                if (selectedValues.includes(box.name)) completedItems++;
            });
            category.completion = parseFloat((completedItems / totalItems).toFixed(2));
        });
        return categories;
    };

    getValues = (checklist = null) => {
        if (isNotNull(checklist)) {
            const checklistData = checklist.externalData.checklist;
            if (pathIsNotNull(checklistData, 'values'))
                return checklistData.values;

            if (pathIsNotNull(checklistData, 'travel'))
            // Legacy travel checklist support
                return checklistData.travel;
        }
        return [];
    };

    getUserData = () => {
        const {user} = this.props.userState;
        let email = '', phone_mobile = '';
        if (pathIsNotNull(user, 'email')) email = user.email;
        if (pathIsNotNull(user, 'phone_mobile')) phone_mobile = user.phone_mobile;
        return {email, phone_mobile};
    };

    addChecklist = checklist => {
        const checklists = this.state.checklists.slice();
        if (isNull(checklist.files)) checklist.files = [];
        checklists.push(checklist);
        this.changeState({checklists});
    };

    removeChecklist = id => {
        return this.state.checklists.filter(checklist => checklist.id !== id);
    };

    replaceChecklist = (index = null, checklist) => {
        const checklists = this.state.checklists.slice();
        if (isNull(index))
            index = this.getChecklistIndex(checklist.id);
        if (index < 0)
            return checklists;

        checklists.splice(index, 1, checklist);
        this.changeState({checklists});
        return checklists;
    };

    getChecklistIndex = id => {
        const {checklists} = this.state;
        for (let i = 0; i < checklists.length; i++) {
            if (checklists[i].id === id) return i;
        }
        return -1;
    };

    loadChecklists = () => {
        getAllDossiers({
            queryParams: 'filter=type_id&value=' + getDossierType('checklist_travel').id, asInstance: true,
            callback: dossiers => {
                dossiers = this.getTypeFiltered(dossiers);
                if (isNull(dossiers))
                    this.changeState({checklists: [], currentChecklist: 'new', selectedValues: []});
                else {
                    const numberOfChecklists = dossiers.length;
                    const loadedChecklists = [];
                    const checklistLoaded = (dossier) => {
                        loadedChecklists.push(dossier);
                        if (loadedChecklists.length >= numberOfChecklists) {
                            loadedChecklists.sort((dossierA, dossierB) => {
                                const preferA = -1, preferB = 1, isEqual = 0;
                                const nameA = dossierA.name, nameB = dossierB.name;
                                return nameA < nameB ? preferA : nameA > nameB ? preferB : isEqual;
                            });
                            const activeChecklist = this.getActiveChecklist(loadedChecklists);

                            this.changeState({
                                checklists: loadedChecklists,
                                currentChecklist: activeChecklist.id,
                                selectedValues: this.getValues(activeChecklist),
                                checkboxValues: this.getCheckboxValues(activeChecklist),
                            });
                        }
                    };
                    dossiers.forEach(dossier => {
                        Dossier.get(dossier.id, checklistLoaded);
                    });
                }
            }
        })
    };

    getCheckboxValues = checklist => {
        if (pathIsNotNull(checklist, 'externalData.checklist.checkboxValues'))
            return checklist.externalData.checklist.checkboxValues;
        return {};
    };

    getActiveChecklistId = (checklist) => {
        const queryParams = parse(location.search);
        if (pathIsNotNull(queryParams, 'id')) return queryParams.id;
        return checklist.id;
    };

    getActiveChecklist = (checklists) => {
        const id = this.getActiveChecklistId(checklists[0]);
        for (let i = 0; i < checklists.length; i++) {
            if (checklists[i].id === id) return checklists[i];
        }
        return {id};
    };

    getTypeFiltered = dossiers => {
        const validChecklists = [];
        dossiers.forEach(dossier => {
            if (pathIsNotNull(dossier.externalData, 'checklist')) {
                const checklistData = dossier.externalData.checklist;
                if (this.checklistIsOfType(checklistData) || this.checklistIsLegacy(checklistData))
                    validChecklists.push(dossier);
            }
        });
        return validChecklists;
    };

    checklistIsOfType = checklistData => {
        return pathIsNotNull(checklistData, 'type') && checklistData.type === this.dataMapping.type;
    };

    checklistIsLegacy = checklistData => {
        if (isNull(checklistData)) return false;
        return this.dataMapping.type === checklistTypes.TRAVEL && isNotNull(checklistData.travel);
    };

    reloadChecklist = () => {
        const {currentChecklist} = this.state;
        this.onSelectChecklist(currentChecklist);
    };

    renderDeleteDialogActions = () => ([
        {label: 'Nee', onClick: this.closeDialog('deleteDialogOpen')},
        {label: 'Ja', onClick: this.deleteChecklist},
    ]);

    renderAddDialogActions = () => ([
        {label: 'Annuleren', onClick: this.closeDialog('addDialogOpen')},
        {label: 'Opslaan', onClick: this.onSaveNewChecklist, disabled: isNull(this.state.checklistName)},
    ]);

    componentDidMount = () => {
        this.mount();
        addDataEventListener([DATA_TYPES.DOCUMENT], [EVENT_TYPES.UPDATE,
            EVENT_TYPES.DELETE], 'checklistController', this.reloadChecklist);
        this.saveInterval = setInterval(this.saveDossier, 5000);
        this.loadChecklists();
    };

    componentWillUnmount = () => {
        this.unMount();
        removeAllDataEventListeners('checklistController', this.reloadChecklist);
        if (isNotNull(this.saveInterval)) clearInterval(this.saveInterval);
        this.saveDossier();
    };

    render = () => {
        const {
            deleteDialogOpen, addDialogOpen, checklistName, currentChecklist, selectedValues, checklists,
            hasChanges, checkboxValues
        } = this.state;
        const {GA_CATEGORY} = this.dataMapping;
        const {email, phone_mobile} = this.getUserData();
        return (
            <ErrorBoundary>
                <MaterialFactory componentType={materialTypes.DIALOG} title='Checklist verwijderen'
                                 onClose={this.closeDialog('deleteDialogOpen')} open={deleteDialogOpen}
                                 actions={this.renderDeleteDialogActions()} text='Weet je zeker dat
                je deze checklist wilt verwijderen?'/>
                <MaterialFactory componentType={materialTypes.DIALOG} title='Nieuwe checklist'
                                 onClose={this.closeDialog('addDialogOpen')} open={addDialogOpen}
                                 actions={this.renderAddDialogActions()} content={
                    <>
                        <label className='addChecklistName'>
                            Vul een naam in en klik op opslaan om een nieuwe checklist aan te maken.
                        </label>
                        <MaterialFactory componentType={materialTypes.TEXT} label='Checklist naam' autoFocus
                                         value={checklistName} onChange={this.onChangeChecklistName}/>
                    </>}/>
                <ChecklistView categories={this.getCategories()} checklists={checklists}
                               currentChecklist={currentChecklist} email={email} GA_CATEGORY={GA_CATEGORY}
                               onChange={this.onChangeBox} onClickBack={this.onClickBack} onClickLink={this.onClickLink}
                               onDeleteChecklist={this.onClickDelete} onDocumentClick={this.onClickDocument}
                               onNewChecklist={this.onAddNewChecklist} onSaveReminder={this.onSaveReminder}
                               onSelectChecklist={this.onSelectChecklist} onUploadDocument={this.onUploadDocument}
                               phone_mobile={phone_mobile} selectedValues={selectedValues} hasChanges={hasChanges}
                               onSaveChecklist={this.saveDossier} onChangeBoxValue={this.onChangeBoxValue}
                               checkboxValues={checkboxValues} />
            </ErrorBoundary>
        )
    }
}

ChecklistController.propTypes = {
    mappingFileName: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
    userState: state.user,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistController);
