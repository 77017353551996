import './style/changePassword.scss';

import React from 'react';
import PropTypes from 'prop-types';
import PasswordRestrictions from "BdhCommon/react/platform/passwordRestrictions";
import GenericLoader from "../../misc/genericLoader";
import MaterialFactory from "../../material/materialFactory";
import {materialTypes} from "../../material/materialTypes";
import StateComponent from "../../misc/stateComponent";

export default class ChangePasswordView extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            error: null,
        };
    }

    onSubmit = () => {
        const password = this.state.password;
        if (isNotNull(password) && this.passwordValidator(password))
            this.props.onSubmit(password);
        else this.changeState({error: 'Vul een geldig wachtwoord in'});
    };

    onChange = (evt) => {
        this.changeState({password: evt.target.value});
    };

    onKeyDown = evt => {
        const enterCode = 13;
        if (evt.keyCode === enterCode)
            this.onSubmit();
    };

    onBlur = () => {
        this.changeState({
            showRestrictions: false,
        });
    };

    onFocus = () => {
        this.changeState({error: null, showRestrictions: true,});
    };

    passwordValidator = password => {
        return /\d/.test(password) && /[A-Za-z]/.test(password) && password.length >= 8 && password.length <= 16;
    };

    toggleEnterListener = (add = true) => {
        const node = document.getElementById('changePasswordNew');
        if (isNotNull(node)) {
            const listener = add ? node.addEventListener || node.attachEvent :
                node.removeEventListener || node.detachEvent;
            listener('keydown', this.onKeyDown);
        }
    };

    componentDidMount = () => {
        this.mount();
        this.toggleEnterListener();
    };

    componentWillUnmount = () => {
        this.unMount();
        this.toggleEnterListener(false);
    };

    render = () => {
        return (
            <div className='changePassword'>
                <div className='changePasswordContainer'>
                    <GenericLoader active={this.props.loading} backgroundClassName='changePasswordLoader'/>
                    <label className='formTitle'>Wachtwoord wijzigen</label>
                    <p className='description'>Vul hieronder het nieuwe wachtwoord voor je account in.</p>
                    <div className='passwordForm'>
                        <div className='passwordField'>
                            <MaterialFactory componentType={materialTypes.PASSWORD} value={this.state.password}
                                             label='Nieuw wachtwoord' helperText={this.state.error}
                                             onChange={this.onChange} onFocus={this.onFocus} onBlur={this.onBlur}
                                             id='changePasswordNew'/>
                            <PasswordRestrictions ref="passRestrictions" password={this.state.password}
                                                  restrictionsVisible={this.state.showRestrictions} passRules={[
                                {
                                    label: '1. Minstens 1 nummer',
                                    test: password => (/\d/.test(password))
                                },
                                {
                                    label: '2. Minstens 1 letter',
                                    test: password => (/[A-Za-z]/.test(password))
                                },
                                {
                                    label: '3. Minstens 8 karakters',
                                    test: password => (password.length >= 8)
                                },
                                {
                                    label: '4. Maximaal 16 karakters',
                                    test: password => (password.length <= 16)
                                }
                            ]}/>
                        </div>
                        <MaterialFactory componentType={materialTypes.RAISED_BUTTON} onClick={this.onSubmit}>
                            Opslaan
                        </MaterialFactory>
                    </div>
                </div>
            </div>
        )
    }
}

ChangePasswordView.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};

