import moment from 'moment';
import {NOW} from "../../../components/misc/constants";

const startJanuary = moment(NOW()).month(0).date(1);

const endDecember = moment(NOW()).month(11).date(31);

const nieuw = '#cadae8';

export const financialTypeMapping_v3 = {
    work: {
        name: 'work',
        label: 'Werk',
        type: 'income',
        stroke: "#183857",
        itemTypes: [
            {
                name: 'job',
                type: 'dossier',
                label: 'Salaris',
                defaults: {
                    frequency: 'monthly',
                }
            },
            {
                name: 'holiday_income',
                type: 'financial',
                label: 'Vakantiegeld',
                defaults: {
                    description: 'Vakantiegeld',
                }
            },
            {
                name: 'bonus',
                type: 'financial',
                label: 'Bonussen',
                defaults: {
                    description: 'Bonus',
                }
            },
            {
                name: 'intership',
                type: 'dossier',
                label: 'Stagevergoeding',
                defaults: {
                    frequency: 'monthly',
                }
            },
        ]
    },
    allowances: {
        name: 'allowances',
        label: 'Toeslagen',
        type: 'income',
        stroke: "#23517f",
        itemTypes: [
            {
                name: 'rent_allowance',
                type: 'financial',
                label: 'Huurtoeslag',
                defaults: {
                    description: 'Huurtoeslag',
                    frequency: 'monthly',
                }
            },
            {
                name: 'care_allowance',
                type: 'financial',
                label: 'Zorgtoeslag',
                defaults: {
                    description: 'Zorgtoeslag',
                    amount: 99,
                    frequency: 'monthly',
                }
            },
            {
                name: 'misc_allowance',
                type: 'financial',
                label: 'Overige toeslagen',
                defaults: {
                    description: 'Overige toeslag',
                    frequency: 'monthly',
                }
            }
        ]
    },
    misc_income: {
        name: 'misc_income',
        label: 'Overige inkomsten',
        type: 'income',
        stroke: "#2e6ba7",
        itemTypes: [
            {
                name: 'parental_contribution',
                type: 'financial',
                label: 'Ouderlijke bijdrage',
                defaults: {
                    description: 'Ouderlijke bijdrage',
                }
            },
            {
                name: 'misc_study',
                type: 'financial',
                subtype: 'stufi',
                label: 'Studiefinanciering',
                defaults: {
                    description: 'Studiefinanciering',
                    amount: 269.45,
                    frequency: 'monthly',
                }
            },
            {
                name: 'loan',
                type: 'financial',
                label: 'Lening',
                defaults: {
                    description: 'Lening',
                }
            },
            {
                name: 'tax_year',
                type: 'dossier',
                label: 'Belastingteruggave',
            },
            {
                name: 'extra_earnings',
                type: 'financial',
                label: 'Bijverdiensten',
                defaults: {
                    description: 'Bijverdiensten',
                }
            },
            {
                name: 'benefits',
                type: 'financial',
                label: 'Uitkeringen',
                defaults: {
                    description: 'Uitkering',
                    frequency: 'monthly',
                }
            },
            {
                name: 'misc_income',
                type: 'financial',
                label: 'Overige inkomsten',
                defaults: {
                    description: 'Overige inkomsten',
                }
            }
        ]
    },
    subscriptions: {
        name: 'subscriptions',
        label: 'Abonnementen',
        type: 'expense',
        stroke: "#183857",
        itemTypes: [
            {
                name: 'sport',
                type: 'dossier',
                label: 'Sport(vereniging)',
                defaults: {
                    frequency: 'monthly',
                }
            },
            {
                name: 'subscriptions',
                type: 'dossier',
                subtype: 'netflix',
                label: 'Netflix',
                defaults: {
                    description: 'Netflix standaard',
                    amount: 10.99,
                    frequency: 'monthly',
                }
            },
            {
                name: 'subscriptions',
                type: 'dossier',
                subtype: 'spotify',
                label: 'Spotify',
                defaults: {
                    description: 'Spotify premium',
                    amount: 9.99,
                    frequency: 'monthly',
                }
            },
            {
                name: 'subscriptions',
                type: 'dossier',
                subtype: 'mobile',
                label: 'Telefoon',
                defaults: {
                    description: 'Telefoon',
                    frequency: 'monthly',
                }
            },
            {
                name: 'subscriptions',
                type: 'dossier',
                subtype: 'tv',
                label: 'Internet / TV',
                defaults: {
                    description: 'Internet / TV',
                    frequency: 'monthly',
                }
            },
            {
                name: 'subscriptions',
                type: 'dossier',
                subtype: 'newspaper',
                label: 'Krant / Tijdschrift',
                defaults: {
                    description: 'Krant / Tijdschrift',
                    frequency: 'monthly',
                }
            },
            {
                name: 'misc_public_transport',
                type: 'dossier',
                label: 'Openbaar vervoer',
                defaults: {
                    frequency: 'monthly',
                }
            },
            {
                name: 'misc_subscriptions',
                type: 'financial',
                label: 'Overige abonnementen',
                defaults: {
                    description: 'Overig abonnement',
                    frequency: 'monthly',
                }
            }
        ]
    },
    living: {
        name: 'living',
        label: 'Wonen',
        type: 'expense',
        stroke: "#23517f",
        itemTypes: [
            {
                name: 'mortgage',
                type: 'financial',
                label: 'Hypotheek',
                defaults: {
                    description: 'Hypotheek',
                    frequency: 'monthly',
                }
            },
            {
                name: 'rental_house',
                type: 'dossier',
                label: 'Huur',
                defaults: {
                    frequency: 'monthly',
                }
            },
            {
                name: 'energy',
                type: 'dossier',
                label: 'Energie',
                defaults: {
                    frequency: 'monthly',
                }
            },
            {
                name: 'water',
                type: 'dossier',
                label: 'Water',
                defaults: {
                    frequency: 'monthly',
                }
            },
            {
                name: 'board',
                type: 'financial',
                label: 'Kostgeld',
                defaults: {
                    description: 'Kostgeld',
                    frequency: 'monthly',
                }
            },
            {
                name: 'maintenance',
                type: 'financial',
                label: 'Onderhoud',
                defaults: {
                    description: 'Onderhoud',
                }
            }
        ]
    },
    insurances: {
        name: 'insurances',
        label: 'Verzekeringen',
        type: 'expense',
        stroke: "#2e6ba7",
        itemTypes: [
            {
                name: 'health_insurance_care',
                type: 'dossier',
                label: 'Zorgverzekering',
                defaults: {
                    frequency: 'monthly',
                    start_date: startJanuary.toDate(),
                    end_date: endDecember.toDate(),
                }
            },
            {
                name: 'travel_insurance',
                type: 'dossier',
                label: 'Reisverzekering',
                defaults: {
                    frequency: 'monthly',
                }
            },
            {
                name: 'contents_insurance',
                type: 'dossier',
                label: 'Inboedelverzekering',
                defaults: {
                    frequency: 'monthly',
                }
            },
            {
                name: 'liability_insurance',
                type: 'dossier',
                label: 'Aansprakelijkheidsverzekering',
                defaults: {
                    frequency: 'monthly',
                }
            },
            {
                name: 'home_insurance',
                type: 'financial',
                label: 'Opstalverzekering',
                defaults: {
                    description: 'Opstalverzekering',
                    frequency: 'monthly',
                }
            },
            {
                name: 'car_insurance',
                type: 'dossier',
                label: 'Autoverzekering',
                defaults: {
                    frequency: 'monthly',
                }
            },
            {
                name: 'life_insurance',
                type: 'financial',
                label: 'Levensverzekering',
                defaults: {
                    frequency: 'monthly',
                }
            }
        ]
    },
    study: {
        name: 'study',
        label: 'Studie',
        type: 'expense',
        stroke: "#3e84ca",
        itemTypes: [
            {
                name: 'study',
                type: 'dossier',
                label: 'Collegegeld',
                defaults: {
                    amount: 2060,
                }
            },
            {
                name: 'books',
                type: 'financial',
                label: 'Boeken',
            },
            {
                name: 'tutoring',
                type: 'financial',
                label: 'Bijles',
                defaults: {
                    description: 'Bijles',
                    frequency: 'monthly',
                }
            },
            {
                name: 'misc_study_exp',
                type: 'financial',
                label: 'Overige studie',
            }
        ]
    },
    subsistence: {
        name: 'subsistence',
        label: 'Levensonderhoud',
        type: 'expense',
        stroke: "#669ed5",
        itemTypes: [
            {
                name: 'groceries',
                type: 'financial',
                label: 'Boodschappen',
                defaults: {
                    description: 'Boodschappen',
                }
            },
            {
                name: 'clothes',
                type: 'financial',
                label: 'Kleding',
                defaults: {
                    description: 'Kleding',
                }
            },
            {
                name: 'free_time',
                type: 'financial',
                label: 'Uitgaan / Vrije tijd',
                defaults: {
                    description: 'Uitgaan / Vrije tijd',
                }
            },
            {
                name: 'personal_care',
                type: 'financial',
                label: 'Persoonlijke verzorging',
                defaults: {
                    description: 'Persoonlijke verzoring',
                }
            },
            {
                name: 'misc_subsistence',
                type: 'financial',
                label: 'Overig levensonderhoud',
                defaults: {
                    description: 'Overig levensonderhoud',
                }
            }
        ]
    },
    fixed_expense: {
        name: 'fixed_expense',
        label: 'Vaste lasten',
        type: 'expense',
        stroke: '#8eb7e0',
        itemTypes: [
            {
                name: 'misc_taxes',
                type: 'dossier',
                label: 'Belasting',
            },
            {
                name: 'loan-out',
                type: 'financial',
                label: 'Lening',
                defaults: {
                    description: 'Lening',
                }
            },
        ]
    },
    misc_expense: {
        name: 'misc_expense',
        label: 'Overige uitgaven',
        type: 'expense',
        stroke: "#b6d1eb",
        itemTypes: [
            {
                name: 'medical',
                type: 'financial',
                label: 'Zorgkosten',
                defaults: {
                    description: 'Zorgkosten',
                }
            },
            {
                name: 'vehicle',
                type: 'financial',
                label: 'Scooter / Auto',
                defaults: {
                    description: 'Scooter / Auto',
                }
            },
            {
                name: 'holiday',
                type: 'financial',
                label: 'Vakantie',
                defaults: {
                    description: 'Vakantie',
                }
            },
            {
                name: 'gifts',
                type: 'financial',
                label: 'Cadeaus',
            },
            {
                name: 'charity',
                type: 'financial',
                label: 'Goede doelen',
                defaults: {
                    description: 'Goed doel',
                }
            },
            {
                name: 'hobby',
                type: 'financial',
                label: 'Hobby',
            },
            {
                name: 'misc_expense',
                type: 'financial',
                label: 'Overige uitgaven',
                defaults: {
                    description: 'Overige uitgave',
                }
            }
        ]
    }
};

export const getFinancialType = name => {
    name = legacySupport(name);
    let categories = Object.keys(financialTypeMapping_v3);
    for (let i = 0; i < categories.length; i++) {
        let category = categories[i];
        let items = financialTypeMapping_v3[category].itemTypes;
        for (let j = 0; j < items.length; j++) {
            if ((items[j].name === name || items[j].subtype === name))
                return items[j];
        }
    }
};

export const dossierIsFinancial = typeName => {
    if (typeName === 'financial_overview') return true;
    let categories = Object.keys(financialTypeMapping_v3);
    for (let i = 0; i < categories.length; i++) {
        let category = categories[i];
        let items = financialTypeMapping_v3[category].itemTypes;
        for (let j = 0; j < items.length; j++) {
            if (items[j].name === typeName || items[j].subtype === typeName)
                return true;
        }
    }
    return false;
};

export const getMoneyType = (typeName) => {
    typeName = legacySupport(typeName);
    let categories = Object.keys(financialTypeMapping_v3);
    for (let i = 0; i < categories.length; i++) {
        let category = financialTypeMapping_v3[categories[i]];
        let items = category.itemTypes;
        for (let j = 0; j < items.length; j++) {
            let item = items[j];
            if (item.name === typeName || (isNotNull(item.subtype) && item.subtype === typeName))
                return category.type;
        }
    }
};

export const getCategoryForItem = itemName => {
    itemName = legacySupport(itemName);
    let categories = Object.keys(financialTypeMapping_v3);
    for (let i = 0; i < categories.length; i++) {
        let category = financialTypeMapping_v3[categories[i]];
        let items = category.itemTypes;
        for (let j = 0; j < items.length; j++) {
            let item = items[j];
            if (item.name === itemName || (isNotNull(item.subtype) && item.subtype === itemName))
                return category;
        }
    }
};

export const getItemTypesForMoneyType = type => {
    let items = [];
    let categories = Object.keys(financialTypeMapping_v3);
    for (let i = 0; i < categories.length; i++) {
        let category = financialTypeMapping_v3[categories[i]];
        if (category.type === type) {
            items = items.concat(category.itemTypes);
        }
    }
    return items;
};

const legacySupport = itemName => {
    const legacy = {
        salary: 'job',
        internship_compensation: 'intership',
        student_grants: 'misc_study',
        tax_returns: 'tax_year',
        phone: 'mobile',
        internet: 'tv',
        newspapaer: 'newspaper',
        public_transport: 'misc_public_transport',
        rent: 'rental_house',
        health_insurance: 'health_insurance_care',
        tuition: 'study',
    };
    if (legacy.hasOwnProperty(itemName)) return legacy[itemName];
    return itemName;
};
