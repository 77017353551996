import './style/currentInsurance.scss';

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as Actions from '../../../../redux/actions';

import {getCookie, scrollToTop} from 'glob-common-js/lib/utils';
import {POST} from 'glob-common-js/lib/request';
import {createRequestConfig} from 'BdhCommon/js/platform';
import {sendPageView} from "BdhCommon/js/ga";
import * as healthHelpers from 'BdhCommon/data/mappings/healthInsuranceMapping';
import getDossierType from 'BdhCommon/data/mappings/dossierTypeMapping';

import {logToSentry, navigate, sendRequest, setSentryUser} from "../../../../misc/utils";
import {sendEvent} from "../misc/healthAnalysisUtils";
import {getFromRegistry, registerObserver, setRequestData, updateRegistry} from "../misc/registry";
import TwoFactorController from "../../../authentication/twoFactorPopup/twoFactorController";
import {createDossier, login, register} from "../../../../misc/requestSender";
import {getSbjsData} from "../../../../misc/cookieUtil";
import CurrentInsuranceView_2018 from "./v2018/currentInsuranceView_2018";
import GenericLoader from "../../../misc/genericLoader";
import {getJwt, getJwtToken} from "../../../../common/js/platform";

export class CurrentInsuranceController extends React.Component {
    constructor(props) {
        super(props);
        registerObserver(this.registryUpdated);
        this.state = {
            allowMultipleAdditionals: false,
            dataLoaded: false,
            selectables: {
                insurers: [],
                basics: [],
                additionals: [],
                dentals: [],
            },
            selected: {
                insurer: null,
                basic: null,
                additionals: null,
                dentals: null,
                yearOfBirth: 1970,
                risk: 385,
            },
            errorMessage: null,
            goal: 'inloggen',
            initialData: this.getInitialSelectedValues(),
            password: null,
            email: null,
            show: false,
            analyzing: false,
        };
        this.userIsLoggedIn = isNotNull(getJwtToken());
        this.details = null;
    }

    /**
     * Changes the component state if the component is still mounted
     * @param stateChange The state change to be made
     */
    changeState = (stateChange) => {
        if (this._isMounted) {
            this.setState(stateChange);
        }
    };

    onForgotPassword = () => {
        let email = document.getElementById('healthEmail');
        if (isNotNull(email)) {
            this.props.actions.setUserEmail(email.value);
        }
        navigate('/wachtwoord-vergeten', true);
    };

    /**
     * Function used by the registry to update the data.
     */
    registryUpdated = registry => {
        this.changeState({initialData: registry.healthInsurance.currentInsurance});
    };

    /**
     * Submit the insurance form
     * @param details Insurance details
     */
    onSubmit = (details) => {
        this.startLoader();
        this.requestAnalysis(details);
        this.props.actions.resetEvaluation();
    };

    /**
     * Sends the insurance data to the backend to do the excel analysis.
     * When the request is performed successfully, the user is logged in or registered.
     * In case the user was already logged in, this step is skipped and the registry data is updated.
     * In case of any error, the error is shown and the analysis flow is stopped.
     * @param details Insurance details
     */
    requestAnalysis = (details) => {
        this.details = details;
        let data = this.createExcelData(details);
        sendRequest(createRequestConfig({
            url: '/healthinsurance/analysis',
            method: POST,
            data,
            apiVersion: 3,
        }), (response) => {
            this.endLoader();
            if (isNotNull(response.data.Error)) {
                // Error has occurred, warn user.
                let message = response.data.Error.Description;
                this.changeState({errorMessage: message});
                logToSentry(new Error('Health analysis backend: ' + message + '. DataController20: ' + JSON.stringify(data)));
            } else {
                const registryData = this.createRegistryData(details, response.data, data);
                updateRegistry('healthInsurance', registryData);
                if (this.userIsLoggedIn) {
                    this.props.actions.setCurrentInsurance({
                        insurer: details.insurer,
                        basic: details.basic[0].label,
                        additional: isNotNull(details.additionals) ? details.additionals.map(additional => additional.label) : [],
                        dental: isNotNull(details.dental) ? details.dental[0].label : null,
                        risk: parseInt(details.risk),
                    });
                    this.createAnalysisDossier(registryData);
                    navigate('/zorganalyse/evaluatie/basis-verzekering', true);
                    scrollToTop();
                } else {
                    if (this.state.goal === 'registreren') {
                        this.register(details);
                    } else {
                        this.login(details, registryData);
                    }
                }
            }
        });
    };

    /**
     * Create the JSON object to send to the backend.
     * Merges additional insurances and dental insurances into one list.
     * @param details Details of the selected insurance
     */
    createExcelData = details => {
        let basic = details.basic[0];
        let additionals = details.additionals || [];
        let dentals = details.dental || [];

        // Add discount and name to basic, to match the expected request json
        basic.discount = parseFloat(basic.discount);
        basic.name = basic.label;

        // Merge additionals and dentals, and parse the discount to floats.
        additionals = additionals.map(additional => {
            additional.discount = parseFloat(additional.discount);
            additional.name = additional.label;
            return additional;
        }).concat(dentals.map(dental => {
            dental.discount = parseFloat(dental.discount);
            dental.name = dental.label;
            return dental;
        }));
        return {
            insurer: details.insurer,
            basic,
            additionals,
            risk: parseInt(details.risk),
            year_of_birth: parseInt(details.yearOfBirth),
        }
    };

    /**
     * Creates all registry data and updates the registry with it.
     * @param requestData DataController20 used to send the requests
     * @param responseData DataController20 returned from the requests
     * @param excelRequestData Initial excel request data. This gets stored so that we can send another request when
     * needed.
     */
    createRegistryData = (requestData, responseData, excelRequestData) => {
        let registry = getFromRegistry('healthInsurance');
        registry.currentInsuranceRequest = excelRequestData;
        registry.currentInsurance = this.createRegistryCurrentInsurance(requestData);
        registry.evaluation = this.createRegistryEvaluation(requestData, responseData);
        registry.nextInsurance = this.createRegistryNextInsurance(requestData, responseData);
        registry.editInsurance = this.createRegistryEditInsurance(requestData, responseData, registry);
        return registry;
    };

    /**
     * Creates the currentInsurance part of the registry from the request data.
     * @param requestData
     */
    createRegistryCurrentInsurance = requestData => {
        let additionals = requestData.additionals.filter(additional => (additional.type !== 'TAND'));
        let additionalDiscount = isNotNull(additionals) ? additionals[0].discount : 0;
        let additionalDiscountType = isNotNull(additionals) ? additionals[0].discount_type : '';
        let dental = this.getDentalFromDetails(requestData);
        return ({
            insurer: requestData.insurer,
            basic: {
                label: requestData.basic[0].label,
                discount: requestData.basic[0].discount,
                discount_type: requestData.basic[0].discount_type,
                name: requestData.basic[0].label,
            },
            additionals: additionals.map(additional => ({
                label: additional.label,
                discount: additional.discount,
                name: additional.label,
                type: additional.type,
                group: additional.group,
            })),
            additionalDiscount,
            additionalDiscountType,
            dental,
            yearOfBirth: requestData.yearOfBirth,
            risk: requestData.risk,
            isLoaded: true,
        });
    };

    /**
     * Retrieves the dental insurance from the dental list, if present. Extracts only the fields that are required
     * by the backend.
     * @param requestData
     */
    getDentalFromDetails = requestData => {
        let dentals = isNotNull(requestData.dental) ? requestData.dental.map(additional => ({
            label: additional.label,
            discount: additional.discount,
            discount_type: additional.discount_type,
            name: additional.label,
            type: additional.type,
        })) : null;
        return isNotNull(dentals) ? dentals[0] : null;
    };

    /**
     * Creates the evaluation part of the registry from the request- and response data.
     * @param requestData
     * @param responseData
     */
    createRegistryEvaluation = (requestData, responseData) => ({
        insurer: {
            label: requestData.insurer,
            cheapest: responseData.insurer.cheapest,
            mostExpensive: responseData.insurer.most_expensive,
            price: responseData.insurer.price,
            stepSize: responseData.insurer.step_size,
            stripeCount: responseData.insurer.stripe_count,
            textGreen: responseData.insurer.text_green,
            textRed: responseData.insurer.text_red,
            textSavePig: responseData.insurer.text_save_pig,
        },
        basic: {
            insurance: responseData.basic.insurance,
            price_basic: responseData.basic.price_basic,
            price_basic_incl_discount: responseData.basic.price_basic_incl_discount,
            policy_type: responseData.basic.policy_type,
            covered_no_contract: responseData.basic.covered_no_contract,
            risk: responseData.basic.risk,
        },
        additionals: responseData.additionals,
        risk: {
            485: responseData.risk['485'],
            585: responseData.risk['585'],
            685: responseData.risk['685'],
            785: responseData.risk['785'],
            885: responseData.risk['885'],
            change_value: responseData.risk.change_value,
        },
        year_of_birth: requestData.yearOfBirth,
    });

    /**
     * Creates the next insurance part of the registry from the request- and response data.
     * @param requestData
     * @param responseData
     */
    createRegistryNextInsurance = (requestData, responseData) => ({
        insurer: requestData.insurer,
        basic: {
            name: responseData.basic.insurance,
            type: responseData.basic.policy_type,
            covered_no_contract: responseData.basic.covered_no_contract,
        },
        additionals: responseData.additionals.map(additional => additional.name),
        risk: responseData.basic.risk,
        monthprice: responseData.alternatives[0].monthprice
    });

    /**
     * Creates the edit insurance part of the registry with the request- and response data.
     * @param requestData
     * @param responseData
     * @param registry
     */
    createRegistryEditInsurance = (requestData, responseData, registry) => {
        // Convert the response indexes to usable objects.
        let coverages = Object.keys(responseData.index).map(key => ({
            part: key,
            default_selected: responseData.index[key]
        }));

        return {
            basic: {
                label: responseData.basic.insurance,
                value: responseData.basic.policy_type,
                default_selected: responseData.index.policy_type,
                default_selected_contract: responseData.index.covered_no_contract,
            },
            risk: {
                value: '\u20ac ' + responseData.basic.risk,
                default_selected: responseData.index.risk,
            },
            alternatives: responseData.alternatives.sort((altA, altB) => {
                let rankA = altA.ranking;
                let rankB = altB.ranking;
                return rankA > rankB ? 1 : rankA < rankB ? -1 : 0;
            }),
            coverages: coverages,
            originalCoverages: coverages,
            comparingCurrent: registry.editInsurance.comparingCurrent,
        };
    };

    /**
     * Uses a redux action that sets the user in the redux state
     * @param user Response data from login request with all the user data
     */
    setUser = user => {
        this.props.actions.setUser({
            firstname: user.firstname,
            email: user.email
        });
        setSentryUser(user);
    };

    login = (details, registryData) => {
        let user = details.user;
        if (isNotNull(user)) {
            this.startLoader();
            let data = {
                email: user.email,
                password: user.password,
            };
            this.changeState(data);
            login({
                email: user.email, password: user.password,
                callback: (response) => {
                    this.endLoader();
                    if (response.data.status === 'success') {
                        if (response.data.message === 'message(s) queued') {
                            this.setState({
                                show: true,
                            });
                        } else {
                            sendEvent('Inloggen', 'completed');
                            sendEvent('Input - geb jaar', details.yearOfBirth);
                            sendEvent('Input - verzekeraar', details.insurer);
                            this.createAnalysisDossier(registryData);
                            this.setUser(response.data.user);
                            this.props.actions.setCurrentInsurance({
                                insurer: details.insurer,
                                basic: details.basic[0].label,
                                additional: isNotNull(details.additionals) ? details.additionals.map(additional => additional.label) : [],
                                dental: isNotNull(details.dental) ? details.dental[0].label : null,
                                risk: parseInt(details.risk),
                            });
                            navigate('/zorganalyse/evaluatie/basis-verzekering', true);
                            scrollToTop();
                        }
                    } else {
                        let errors = document.getElementsByClassName('registerErrorMessage')[0];
                        if (isNotNull(errors)) {
                            errors.classList.add('show');
                        }
                    }
                }
            });
        }
    };

    createAnalysisDossier = (registryData) => {
        createDossier({
            data: {
                name: 'Zorgverzekeringsanalyse 2019',
                values: {},
                external_data: {
                    analysisData: registryData,
                },
                type: getDossierType('health_insurance_analysis').id,
            }
        });
    };

    /**
     * On success handler for the 2-factor component.
     */
    onTwoFactorSuccess = () => {
        let details = this.details;
        sendEvent('Inloggen', 'completed');
        sendEvent('Input - geb jaar', details.yearOfBirth);
        sendEvent('Input - verzekeraar', details.insurer);
        this.changeState({email: null, password: null});
        this.createDossier(details);
    };

    /**
     * Registers the user and continues the flow on success.
     * @param details Request data containing the user information.
     */
    register = (details) => {
        register({
            data: this.createUserData(details),
            callback: (response) => {
                this.endLoader();
                if (response.data.status === 'success') {
                    sendEvent('Registreren', 'completed');
                    sendEvent('Input - geb jaar', details.yearOfBirth);
                    sendEvent('Input - verzekeraar', details.insurer);
                    this.createDossier(details);
                } else {
                    let errors = document.getElementsByClassName('registerErrorMessage')[0];
                    if (isNotNull(errors)) {
                        errors.classList.add('show');
                    }
                }
            }
        });
    };

    /**
     * Creates the expected user data for the register request.
     * @param details Request data containing the user information.
     */
    createUserData = details => {
        let user = details.user;
        let utmString = getSbjsData('src') + ' / ' + getSbjsData('mdm');
        return {
            firstname: user.firstname,
            lastname: '',
            infix: '',
            gender: details.gender === 'Vrouw' ? 1 : 0,
            email: user.email,
            password: user.password,
            password_repeat: user.password,
            terms_accepted: user.terms,
            skip_validation: true,
            referral: null,
            tag_0: utmString,
            tag_1: window.isAndroid ? 'Android' : window.isIos ? 'iOS' : 'Website',
            tag_2: 'Zorgverzekeringsanalyse',
        }
    };

    /**
     * Creates an analysis dossier with the request data. When the dossier has been created, the user is directed to the
     * evaluation of the analysis.
     * @param requestData The request data containing the insurance information.
     */
    createDossier = (requestData) => {
        let data = this.createDossierData(requestData);
        createDossier({
            data,
            callback: () => {
            }
        });
    };

    /**
     * Creates the dossier data as expected by the backend.
     * @param requestData The request data containing the needed information.
     */
    createDossierData = requestData => {
        return {
            name: 'Analyse zorgverzekering 2018 ' + requestData.insurer,
            values: {},
            external_data: {
                values: [
                    {
                        year: 2018,
                        health_insurer: requestData.insurer,
                        basic_insurance: requestData.basic[0].name,
                        extra_insurance: requestData.additionals,
                        tooth_insurance: requestData.dental,
                    }
                ],
                healthInsuranceAnalysis: {
                    additionals: requestData.additionals,
                    dateOfBirth: requestData.dateOfBirth,
                    risk: requestData.risk,
                },
            },
            type: getDossierType('health_insurance_analysis').id,
        }
    };

    /**
     * On click handler for the insurer field.
     * @param insurer The newly selected insurer.
     */
    onSelectInsurer = (insurer) => {
        let selected = this.state.selected;
        let updateParams = {
            insurer,
            basic: selected.basic,
            additionals: selected.additionals,
            dentals: selected.dentals,
        };
        this.updateSelectableData(updateParams);
    };

    /**
     * On click handler for the basic insurance field.
     * @param basic The newly selected basic insurance.
     */
    onSelectBasic = (basic) => {
        let selected = this.state.selected;
        let updateParams = {
            insurer: selected.insurer,
            basic,
            additionals: selected.additionals,
            dentals: selected.dentals,
        };
        this.updateSelectableData(updateParams);
    };

    /**
     * On click handler for the additional insurance(s) field.
     * @param additionals The newly selected additional insurance(s).
     */
    onSelectAdditional = (additionals) => {
        let selected = this.state.selected;
        let updateParams = {
            insurer: selected.insurer,
            basic: selected.basic,
            additionals,
            dentals: selected.dental,
        };
        this.updateSelectableData(updateParams);
    };

    /**
     * Updates the selectable insurance parts, depending on the currently selected insurance parts.
     */
    updateSelectableData = ({insurer, basic, additionals, dentals}) => {
        let basicData = this.updateSelectableBasics(insurer, basic);
        basic = basicData.basic;
        let additionalsData = this.updateSelectableAdditionals(basic, additionals);
        let selectableAdditionals = additionalsData.selectableAdditionals;
        let selectableDentals = additionalsData.selectableDentals;
        this.changeState({
            selectables: {
                insurers: this.state.selectables.insurers,
                basics: basicData.selectableBasics,
                additionals: selectableAdditionals,
                dentals: selectableDentals,
            },
            selected: {
                insurer,
                basic,
                additionals,
                dentals,
            }
        });
    };

    /**
     * Update the selectable basic insurances, depending on the insurer.
     * @param insurer
     * @param basic
     * @returns {{selectableBasics: *, basic: *}}
     */
    updateSelectableBasics = (insurer, basic) => {
        let selectableBasics = healthHelpers.getBasicsByInsurer(insurer);
        // Reset the basic insurance if the selected one is not allowed anymore
        if (isNotNull(basic) && selectableBasics.map(basic => basic.label).indexOf(basic.label) === -1) {
            basic = null;
        }
        return {selectableBasics, basic};
    };

    updateSelectableAdditionals = (basic, additionals) => {
        let allSelectableAdditionals = healthHelpers.getAdditionals({
            basic,
            additionals
        });
        let selectableAdditionals = allSelectableAdditionals.filter(additional => additional.type !== 'TAND');
        let selectableDentals = allSelectableAdditionals.filter(additional => additional.type === 'TAND');

        return {selectableAdditionals, selectableDentals};
    };

    loadInitialData = () => {
        let currentInsurance = getFromRegistry(['healthInsurance', 'currentInsurance']);
        let selectableInsurers = healthHelpers.getInsurers().sort((insurerA, insurerB) => {
            let nameA = insurerA.toLowerCase();
            let nameB = insurerB.toLowerCase();
            return nameA > nameB ? 1 : nameA < nameB ? -1 : 0;
        });
        let selectedInsurer = currentInsurance.isLoaded ? currentInsurance.insurer : selectableInsurers[0];
        let selectableBasics = healthHelpers.getBasicsByInsurer(selectedInsurer);

        let selectedBasic = currentInsurance.isLoaded ? currentInsurance.basic : null,
            selectableAdditionals = [], selectableDentals = [];
        let selectedAdditionals = currentInsurance.isLoaded ? currentInsurance.additionals : null;
        let selectedDentals = currentInsurance.isLoaded ? currentInsurance.dental : null;

        let additionals = healthHelpers.getAdditionals({basic: selectedBasic, additionals: selectedAdditionals});
        for (let i = 0; i < additionals.length; i++) {
            let additional = additionals[i];
            if (additional.type === 'TAND') {
                selectableDentals.push(additional);
            } else {
                selectableAdditionals.push(additional);
            }
        }

        this.changeState({
            dataLoaded: true,
            selectables: {
                insurers: selectableInsurers,
                basics: currentInsurance.isLoaded ? selectableBasics : [],
                additionals: currentInsurance.isLoaded ? selectableAdditionals : [],
                dentals: currentInsurance.isLoaded ? selectableDentals : [],
            },
            selected: {
                insurer: selectedInsurer,
                basic: selectedBasic,
                additionals: selectedAdditionals,
                dentals: selectedDentals,
            },
        });
    };

    getInitialSelectedValues = () => {
        return getFromRegistry(['healthInsurance', 'currentInsurance']);
    };

    startLoader = () => {
        this.changeState({analyzing: true});
    };

    endLoader = () => {
        this.changeState({analyzing: false});
    };

    closeError = () => {
        document.getElementsByClassName('dropdownArrow')[0].classList.remove('up');
        document.getElementsByClassName('actualMessageContainer')[0].classList.remove('active');
        this.changeState({errorMessage: null});
    };

    setGoal = goal => {
        this.changeState({goal});
    };

    hidePopup = () => {
        this.setState({
            show: false
        });
    };

    determineAuthDisplay = () => {
        let currentPath = this.props.history.location.pathname;
        if (currentPath.indexOf('inloggen') === -1) {
            // this.setGoal('registreren');
        } else {
            this.setGoal('inloggen');
        }
        sendPageView('/zorganalyse/huidige-verzekering');
    };

    componentDidMount = () => {
        this._isMounted = true;
        this.loadInitialData();
        this.determineAuthDisplay();
    };

    componentWillUnmount = () => {
        this._isMounted = false;
    };

    dropdownClick = () => {
        document.getElementsByClassName('dropdownArrow')[0].classList.toggle('up');
        document.getElementsByClassName('actualMessageContainer')[0].classList.toggle('active');
    };

    render = () => {
        let registry = getFromRegistry(['healthInsurance', 'currentInsurance']);
        if (this.state.dataLoaded) {
            let selectables = this.state.selectables;
            return (
                <>
                    <GenericLoader active={this.state.analyzing} backgroundClassName='insuranceLoaderBackground'
                                   label='Zorgverzekering analyseren...' textClassName='insuranceLoaderText'/>
                    <CurrentInsuranceView_2018 onSubmit={this.onSubmit} onSelectBasic={this.onSelectBasic}
                                               onSelectAdditional={this.onSelectAdditional}
                                               onSelectInsurer={this.onSelectInsurer}
                                               insurers={selectables.insurers} basics={selectables.basics}
                                               additionals={selectables.additionals} dentals={selectables.dentals}
                                               goal={'inloggen'} setGoal={this.setGoal}
                                               initialSelected={this.state.initialData}
                                               isInitialLoaded={registry.isLoaded} userIsLoggedIn={this.userIsLoggedIn}
                                               onForgotPassword={this.onForgotPassword}/>
                    <div className={"errorContainer" + (isNotNull(this.state.errorMessage) ? ' active' : '')}>
                        <div className="errorBackground"/>
                        <div className="errorMessagePopup">
                            <div className="errorMessageHeaderContainer">
                                <label className='errorMessageHeader'>Foutje</label>
                            </div>
                            <div className="errorMessageBody">
                                <p className='staticMessage'>Er is helaas een fout opgetreden, we doen er alles aan om
                                    dit
                                    zo snel mogelijk op te lossen.</p>
                                <div className="showDetailsContainer" onClick={this.dropdownClick}>
                                    <label>Details</label>
                                    <span className="dropdownArrow"/>
                                </div>
                                <div className="actualMessageContainer">
                                    <p className="actualMessage">{this.state.errorMessage}</p>
                                </div>
                            </div>
                            <div className="errorMessageFooter">
                                <button className='errorMessageButton' onClick={this.closeError}>
                                    Ga terug
                                </button>
                            </div>
                        </div>
                    </div>
                    <TwoFactorController
                        password={this.state.password}
                        email={this.state.email}
                        onTwoFactorSuccess={this.onTwoFactorSuccess}
                        show={this.state.show}
                        hidePopup={this.hidePopup}
                    />
                </>
            );
        }
        return null;
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});

export default connect(null, mapDispatchToProps)(withRouter(CurrentInsuranceController));
