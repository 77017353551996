import './style/library.scss';

import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';

import {FILTERS, SEARCH_KEYS, SORT_KEYS, VIEWS} from "./libraryController";
import LibraryListView from "./list/libraryListView";
import LibraryBlockView from "./block/libraryBlockView";
import CategorySelection from "../category/categorySelection";
import {JSSLibraryView} from "./style/JSSLibraryView";
import BdhSearchInput from "../customControls/input/bdhSearchInput";

export class LibraryView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFilter: 'Filters',
            sorting: {
                key: null,
                order: 'ASC',
            }
        };

        this._isMounted = false;
    }

    changeState = stateChange => {
        if (this._isMounted) this.setState(stateChange);
    };

    onSort = (key) => {
        const isSameKey = this.state.sorting.key === key;
        let order = this.state.sorting.order;

        if (isSameKey) order = order === 'ASC' ? 'DESC' : 'ASC';
        else if (key === SORT_KEYS.UPDATED_AT) order = 'DESC';

        this.changeState({sorting: {key, order}});
        this.props.onSort(key, order);
    };

    onSelectCategory = evt => {
        this.changeState({selectedCategory: evt.target.value});
    };

    onSearchTypeChange = evt => {
        if (isNotNull(evt) && isNotNull(evt.target)) {
            let select = evt.target;
            let options = select.options;
            let selectedIndex = select.selectedIndex;
            this.props.onSelectSearchType(options[selectedIndex].value);
        }
    };

    toggleFilterContainer = () => {
        let filterContainer = document.querySelector('div.libraryFilterContainer');
        if (isNotNull(filterContainer)) filterContainer.classList.toggle('active');
    };

    clearFilter = evt => {
        this.changeState({selectedFilter: 'Filters'});
        this.props.onSelectFilter(null);
        evt.stopPropagation();
    };

    includeFilterClickListener = () => {
        let filterContainer = document.querySelector('div.libraryFilterContainer');
        if (isNotNull(filterContainer) && !this.hasHover(filterContainer))
            filterContainer.classList.remove('active');
    };

    hasHover = element => {
        return (isNull(element) || isNull(element.parentElement)) ||
            element.parentElement.querySelector(':hover') === element;
    };

    // ====================
    // Header
    // ====================
    renderHeader = () => {
        const {
            selectedDocuments, documents, selectedView, onChangeView, onDownload, onDeleteDocuments,
            onAddDocument, onSearchQueryChange, onShare
        } = this.props;
        const {selectedFilter} = this.state;
        const countTitle = documents.length === 1 ? 'document' : 'documenten';
        return (
            <div className='libraryHeader'>
                <div className='row navBar'>
                    <label
                        className='documentCount'>{`${documents.length} ${countTitle} in je bibliotheek`}
                    </label>
                    <div className='viewSelectionContainer'>
                        <label className='viewSelectionLabel'>Toon als:</label>
                        <span
                            className={'viewSelection blockView common-icon-th-large' + (selectedView === VIEWS.BLOCK ?
                                ' selected' : '')} onClick={() => {
                            onChangeView(VIEWS.BLOCK)
                        }}/>
                        <span
                            className={'viewSelection list common-icon-th-list' + (selectedView === VIEWS.LIST ?
                                ' selected' : '')} onClick={() => {
                            onChangeView(VIEWS.LIST)
                        }}/>
                    </div>
                </div>
                <div className='row navBar'>
                    <div className='flexBox'>
                        <div className='libraryActionsContainer'>
                            <div className='libraryActionsButton'>Acties <div
                                className='common-icon-arrow-right arrow'/>
                            </div>
                            <div className='libraryActions'>
                                <div className='libraryAction' onClick={onShare}>
                                    Deel {isNotNull(selectedDocuments) ?
                                    selectedDocuments.length === 1 ? 'document' : 'documenten' : 'alles'}
                                </div>
                                <div className='libraryAction' onClick={onDownload}>
                                    Download {isNotNull(selectedDocuments) ?
                                    selectedDocuments.length === 1 ? 'document' : 'documenten' : 'alles'}
                                </div>
                                <div
                                    className={'libraryAction' + (selectedDocuments.length === 0 ? ' disabled' : '')}
                                    onClick={onDeleteDocuments}>
                                    Verwijderen
                                </div>
                            </div>
                        </div>
                        <button className='libraryButton' onClick={onAddDocument}>Voeg document toe</button>
                    </div>
                    <div className='flexBox filterBox'>
                        {this.renderCategorySelection()}
                        <div className='libraryFilterContainer' onClick={this.toggleFilterContainer}>
                            <div className='selectedFilter'>{selectedFilter}</div>
                            <div className='filterContainer'>
                                {this.renderFilter('Op type onbekend', FILTERS.TYPE_UNKNOWN)}
                                {this.renderFilter('Op document zonder dossier', FILTERS.NOT_LINKED)}
                                {this.renderFilter('Op document met dossier', FILTERS.LINKED)}
                            </div>
                            {this.state.selectedFilter === 'Filters' &&
                                <div className='common-icon-caret-down selectFilter'/>}
                            {this.state.selectedFilter !== 'Filters' &&
                                <span className='clearFilter common-icon-cross' onClick={this.clearFilter}/>}
                        </div>
                        <div className='librarySearchContainer'>
                            <select className='searchTypeSelect' onChange={this.onSearchTypeChange}
                                    defaultValue={this.props.initialSearchType}>
                                <option className='searchType' value={SEARCH_KEYS.LABEL}>Naam</option>
                                <option className='searchType' value={SEARCH_KEYS.TYPE}>Type</option>
                            </select>
                            <BdhSearchInput onChange={onSearchQueryChange} containerClass='searchQuery'
                                            label='Zoeken...' onKeyDown={this.props.onEnterSearch}/>
                        </div>
                    </div>
                </div>
                <div className='row sortRow'>
                    <div className={'sortItem ' + 'sortEmptyContainer'}/>
                    <div className={'sortItem ' + 'sortIconTypeContainer'}/>
                    {this.renderSortContainer('sortNameContainer', 'Naam', SORT_KEYS.NAME)}
                    {this.renderSortContainer('sortUpdatedContainer', 'Gewijzigd op', SORT_KEYS.UPDATED_AT)}
                    {this.renderSortContainer('sortTypeContainer', 'Documenttype', SORT_KEYS.TYPE)}
                    {this.renderSortContainer('sortLinkContainer', 'Gekoppeld aan', SORT_KEYS.LINKED_TO)}
                </div>
            </div>
        );
    };

    renderSortContainer = (className, label, key) => {
        return (
            <div className={'sortItem ' + className} onClick={() => {
                this.onSort(key)
            }}>
                <label>{label}</label>
                {this.state.sorting.key === key &&
                    <span
                        className={'common-icon-caret-down' + (this.state.sorting.order === 'ASC' ? ' up' : '')}/>}
            </div>
        );
    };

    renderCategorySelection = () => {
        const {classes, onSelectCategory} = this.props;
        return <CategorySelection onSelectCategory={onSelectCategory} extraClassName={classes.selectRoot}/>
    };

    // ====================
    // Content
    // ====================

    renderContent = () => {
        let view = this.props.selectedView;
        if (view === VIEWS.LIST) return this.renderListView();
        else if (view === VIEWS.BLOCK) return this.renderBlockView();
        return null;
    };

    renderBlockView = () => (
        <LibraryBlockView onSelectDocument={this.props.onSelectDocument}
                          onDocumentDetails={this.props.onDocumentDetails}
                          selectedDocuments={this.props.selectedDocuments} onChangePage={this.props.onChangePage}
                          documents={this.props.documents} loading={this.props.loading} total={this.props.total}
                          limit={this.props.limit} page={this.props.page}/>
    );

    renderListView = () => (
        <LibraryListView onSelectDocument={this.props.onSelectDocument}
                         onDocumentDetails={this.props.onDocumentDetails} loading={this.props.loading}
                         selectedDocuments={this.props.selectedDocuments} documents={this.props.documents}
                         onClickDossier={this.props.onClickDossier} onClickUnlinked={this.props.onClickUnlinked}
                         onClickLinked={this.props.onClickLinked} selectAll={this.props.selectAll}
                         isLoadingMore={this.props.isLoadingMore} total={this.props.total}
                         onLoadMore={this.props.onLoadMore}/>
    );

    renderFilter = (label, filterKey) => (
        <div className='libraryFilter' onClick={() => {
            this.changeState({selectedFilter: label});
            this.props.onSelectFilter(filterKey)
        }}>
            {label}
        </div>
    );

    componentDidMount = () => {
        this._isMounted = true;
        document.addEventListener('click', this.includeFilterClickListener);
    };

    componentWillUnmount = () => {
        this._isMounted = false;
        document.removeEventListener('click', this.includeFilterClickListener);
    };

    render = () => {
        return (
            <div className='libraryView'>
                {this.renderHeader()}
                {this.renderContent()}
            </div>
        )
    }
}

LibraryView.propTypes = {
    onAddDocument: PropTypes.func.isRequired,
    onDeleteDocuments: PropTypes.func.isRequired,
    onDownload: PropTypes.func.isRequired,
    onShare: PropTypes.func.isRequired,
    onSelectFilter: PropTypes.func.isRequired,
    onSelectSearchType: PropTypes.func.isRequired,
    onSearchQueryChange: PropTypes.func.isRequired,
    onChangeView: PropTypes.func.isRequired,
    onSelectDocument: PropTypes.func.isRequired,
    onDocumentDetails: PropTypes.func.isRequired,
    onSort: PropTypes.func.isRequired,
    onSearchInputClick: PropTypes.func.isRequired,
    onClickDossier: PropTypes.func.isRequired,
    onClickUnlinked: PropTypes.func.isRequired,
    onSelectCategory: PropTypes.func.isRequired,
    selectAll: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    documents: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        update_at: PropTypes.string,
        extension: PropTypes.string.isRequired,
        belongings: PropTypes.arrayOf(PropTypes.object).isRequired,
        type: PropTypes.shape({
            name: PropTypes.string.isRequired,
        })
    })).isRequired,
    selectedDocuments: PropTypes.arrayOf(PropTypes.string).isRequired,
    initialSearchType: PropTypes.string.isRequired,
    selectedView: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    onLoadMore: PropTypes.func.isRequired,
    isLoadingMore: PropTypes.bool.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    onEnterSearch: PropTypes.func.isRequired,
};

export default withStyles(JSSLibraryView)(LibraryView);