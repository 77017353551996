import './style/autoSuggestBar.scss';

import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import utils , {isFunction} from 'glob-common-js/lib/utils';

export class AutoSuggestBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            suggestions: [],
        };
        this.collection = this.createCollection();
    }

    changeState = (stateChange) => {
        if (this._isMounted) {
            this.setState(stateChange);
        }
    };

    createCollection = () => {
        return this.props.collection.map(item => (
            typeof item === 'object' ? item : typeof item === 'string' ? {label: item} : {label: item.toString()}
        ));
    };

    renderSuggestion = suggestion => {
        let inputValue = this.state.value;
        if (utils.stringStartsWith(suggestion.label, inputValue)) {
            return (
                <label>
                    <span style={{fontWeight: 700}}>
                        {suggestion.label.slice(0, inputValue.length)}
                    </span>
                    <span>{suggestion.label.slice(inputValue.length, suggestion.label.length)}</span>
                </label>
            )
        } else {
            return (
                <label>
                    {suggestion.label}
                </label>
            );
        }
    };


    /**
     * Fires when user selects a suggestion
     */
    getSuggestionValue = suggestion => {
        this.props.onSuggestionClick(suggestion.label);
        let value = suggestion.label;
        this.changeState({
            value,
        });
        return value;
    };

    getSuggestions = value => {
        value = value.trim().toLowerCase();
        return this.collection.filter(item =>
            utils.stringStartsWith(item.label, value) // Items starting with the value
        ).concat(this.collection.filter(item =>
            // Items containing the value, but not starting with the value
            !utils.stringStartsWith(item.label, value) && utils.stringContains(item.label, value)
        ));
    };

    onSuggestionsFetchRequested = ({value}) => {
        this.changeState({
            suggestions: this.getSuggestions(value),
        })
    };

    onChange = (event, {newValue}) => {
        if (isFunction(this.props.onInputChange)){
            this.props.onInputChange(event);
        }
        this.changeState({
            value: newValue,
        })
    };

    onInputClick = evt => {
        if (utils.isNotNull(this.props.onInputClick)) {
            this.props.onInputClick(evt);
        }
    };

    onSuggestionsClearRequested = () => {
        this.changeState({
            suggestions: []
        });
    };

    // noinspection JSUnusedGlobalSymbols
    componentDidMount = () => {
        this._isMounted = true;
    };

    // noinspection JSUnusedGlobalSymbols
    componentWillUnmount = () => {
        this._isMounted = false;
    };

    // noinspection JSUnusedGlobalSymbols
    render = () => {
        let {value, suggestions} = this.state;
        let inputProperties = {
            id: this.props.inputId || 'suggestBar',
            placeholder: this.props.inputPlaceholder || '',
            value,
            onChange: this.onChange,
            onClick: this.onInputClick,
        };
        return (
            <div className='autoSuggestBar'>
                <Autosuggest suggestions={suggestions} onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                             onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                             getSuggestionValue={this.getSuggestionValue} renderSuggestion={this.renderSuggestion}
                             inputProps={inputProperties} theme={this.props.theme}/>
            </div>
        )
    }
}

AutoSuggestBar.propTypes = {
    collection: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.shape({label: PropTypes.string}),
        PropTypes.string,
    ])).isRequired,
    onSuggestionClick: PropTypes.func.isRequired,
    onInputClick: PropTypes.func,
    inputId: PropTypes.string,
    inputPlaceholder: PropTypes.string,
    onInputChange: PropTypes.func,
    theme: PropTypes.shape({
        suggestion: PropTypes.string.isRequired,
        suggestionsContainer: PropTypes.string.isRequired,
        suggestionsContainerOpen: PropTypes.string.isRequired,
        input: PropTypes.string.isRequired,
        suggestionsList: PropTypes.string.isRequired,
        suggestionHighlighted: PropTypes.string.isRequired,
    }),
};

AutoSuggestBar.defaultProps = {
    theme: {
        suggestion: 'suggestion',
        suggestionsContainer: 'suggestionsContainer',
        suggestionsContainerOpen: 'suggestionsContainer open',
        input: 'suggestionsInput',
        suggestionsList: 'suggestionsList',
        suggestionHighlighted: 'suggestionHighlighted',
    }
};

export default AutoSuggestBar;