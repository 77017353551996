import JSSConstants from '../../../../../style/JSSConstants';

const drawerWidth = '14rem';

export const JSSMaterialMenu = theme => ({
    root: {
        flexGrow: 1,
        zIndex: 1,
        position: 'relative',
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: '#F2F2F2',
        color: 'black',
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth})`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    xlAuto: {
        [theme.breakpoints.up('xl')]: {
            marginLeft: 'auto',
        }
    },
    popper: {
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
        [theme.breakpoints.down('sm')]: {
            marginRight: 0,
        },
    },
    pageTitle: {
        flex: 1,
    },
    hide: {
        display: 'none !important',
    },
    accountContainer: {
        // marginRight: '7rem',
        // marginLeft: 'auto',
        display: 'flex',
        // alignItems: 'center',
    },
    drawerPaper: {
        position: 'fixed',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        borderRight: '1px solid rgba(0, 0, 0, 0.25)',
        backgroundColor: '#F2F2F2',
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing.unit * 7,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing.unit * 9,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        position: 'relative',
        flexGrow: 1,
        maxWidth: '100vw',
        padding: theme.spacing.unit * 3,
        boxSizing: 'border-box',
        [theme.breakpoints.down('sm')]: {
            maxWidth: 'calc(100% - 4.4rem)',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    contentMenuClosed: {
       marginLeft: '4rem',
    },
    contentMenuOpen: {
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing.unit * 6,
        }
    },
    list: {
        flexGrow: 1,
        paddingTop: 0,
    },
    listIcon: {
        minWidth: '2rem',
        color: 'black',
        textAlign: 'center',
    },
    listText: {
        color: 'black',
        fontWeight: 500,
        marginLeft: 'auto',
        fontSize: '.8rem',
    },
    socialContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        marginBottom: '1rem',
    },
    logo: {
        width: '4rem',
        height: '4rem',
        marginBottom: '1rem',
    },
    menuText: {
        fontSize: '1rem',
    },
    menuImage: {
        borderRadius: '50%',
        width: '2.5rem',
        height: '2.5rem',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        marginRight: theme.spacing.unit,
    },
    socialButtonsContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: '100%',
    },
    socialButton: {
        fontSize: '1.2rem',
        padding: '.7rem',
        margin: '0 .5rem',
        cursor: 'pointer',
        border: '1px solid #434b50',
        borderRadius: '50%',
        transition: theme.transitions.create(['color', 'border'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        '&:hover': {
            color: JSSConstants["color-blue-1"],
            border: `1px solid ${JSSConstants["color-blue-1"]}`,
        }
    },
});