import React from 'react';
import {connect} from 'react-redux';
import path from 'path';

import {isFunction} from 'glob-common-js/lib/utils';
import {hasHover} from 'glob-common-react/lib/utils/reactUtils';

import StateComponent from "../../../misc/stateComponent";
import BdhDocument from "../../../../models/document";
import {getDocTypeById} from "../../../../common/data/mappings/docTypeMapping";
import {addCustomEventListener, removeCustomEventListener} from "../../../../misc/eventDispatcher";
import {navigate, showMessage, showSnackbar} from "../../../../misc/utils";
import {getOnboardingManager} from "../../../onboarding/manager/onboardingManagerHelper";
import AddDocumentPopup from "./addDocumentPopup";
import {dispatchDocumentEvent, EVENT_TYPES} from "../../../../misc/dataEvent";

class AddDocumentPopupController extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            active: false,
            doc: null,
            fileUploaded: false,
        };
        this.file = null;
        this.view = null;
        this.mailTimer = null;
        this.saveCallback = null;
    }

    onClose = (closedByUser = true) => {
        this.changeState({
            loading: false,
            active: false,
            doc: null,
        });
        this.setFile(null);
        this.hideMailInfo();
        if (closedByUser) {
            const manager = getOnboardingManager('store');
            if (manager.isActive())
                manager.setPrevStep();
        }
    };

    onClickBackground = () => {
        const view = document.querySelector('div.addDocumentView');
        if (isNull(view) || !hasHover(view))
            this.onClose(true);
    };

    onChangeLabel = value => {
        const {doc} = this.state;
        doc.label = value;
        this.changeState({doc});
    };

    onChangeField = name => value => {
        const {doc} = this.state, fields = doc.fields;
        if (fields.hasOwnProperty(name)) {
            fields[name].value = value;
            doc.fields = fields;
            this.changeState({doc});
        }
    };

    onClearField = name => () => {
        this.onChangeField(name)(null);
    };

    onChangeFile = () => {
        const input = document.getElementById('uploadFile');
        if (isNotNull(input))
            this.setFile(input.files[0]);
    };

    onDropFile = evt => {
        evt.preventDefault();
        this.setFile(evt.dataTransfer.files[0])
    };

    onSave = () => {
        const {doc} = this.state;
        if (isNotNull(this.file)) {
            this.changeState({loading: true});
            doc.save(this.file, (uploaded, doc) => {
                this.changeState({loading: false});
                if (uploaded) {
                    dispatchDocumentEvent(EVENT_TYPES.CREATE, doc);
                    showSnackbar('Je document is geüpload');
                    this.onClose(false);
                    if (isFunction(this.saveCallback))
                        this.saveCallback(doc);
                } else showMessage('Het toevoegen van het document is niet gelukt. Probeer het later opnieuw of ' +
                    'neem contact met ons op', null, 'Upload mislukt');
            });
        }
    };

    onClickLibrary = () => {
        this.onClose(false);
        navigate('/bibliotheek');
    };

    setFile = file => {
        const {doc} = this.state;
        this.file = file;
        if (isNotNull(file)) {
            if (isNull(doc.label)) doc.label = file.name;
            this.changeState({doc, fileUploaded: true});
            if (isNotNull(this.view)) {
                const url = URL.createObjectURL(file), extension = path.extname(file.name).slice(1).toLowerCase(),
                    name = file.name;
                this.view.setFileData(url, extension, name);
            }
        } else {
            this.changeState({fileUploaded: false});
            if (isNotNull(this.view))
                this.view.setFileData(null, null, null);
        }
    };

    activate = ({typeId, saveCallback}) => {
        this.saveCallback = saveCallback;
        const doc = new BdhDocument({type: getDocTypeById(typeId)});
        this.changeState({
            active: true,
            doc,
        });
        this.startMailTimer();
    };

    startMailTimer = () => {
        setTimeout(this.showMailInfo, 1000);
    };

    showMailInfo = () => {
        const container = document.getElementById('mailDocumentContainer');
        if (isNotNull(container) && !container.classList.contains('visible'))
            container.classList.add('visible');
    };

    hideMailInfo = () => {
        const container = document.getElementById('mailDocumentContainer');
        if (isNotNull(container))
            container.classList.remove('visible');
    };

    getEmail = () => {
        const {userState} = this.props;
        if (pathIsNotNull(userState, 'user.username'))
            return userState.user.username + '@bydehand.com';
        return '';
    };

    componentDidMount = () => {
        this.mount();
        addCustomEventListener('addDocument', this.activate);
    };

    componentWillUnmount = () => {
        this.unMount();
        removeCustomEventListener('addDocument', this.activate);
        if (isNotNull(this.mailTimer))
            clearTimeout(this.mailTimer);
        this.hideMailInfo();
    };

    render = () => {
        const {active, doc, loading, fileUploaded} = this.state;
        if (active)
            return <AddDocumentPopup ref={ref => {
                this.view = ref
            }} onClose={this.onClose} onClickBackground={this.onClickBackground} onSave={this.onSave} doc={doc}
                                     loading={loading} email={this.getEmail()} onChangeField={this.onChangeField}
                                     onChangeFile={this.onChangeFile} onChangeLabel={this.onChangeLabel}
                                     onDropFile={this.onDropFile} onClearField={this.onClearField}
                                     onClickLibrary={this.onClickLibrary} fileUploaded={fileUploaded}/>;
        return null;
    };
}

const mapStateToProps = state => ({
    userState: state.user,
});

export default connect(mapStateToProps)(AddDocumentPopupController);