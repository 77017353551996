import checklistTypes from "../../../components/checklist/checklistTypes";

export default {
    name: 'Huis verkopen',
    icon: 'common-icon-home',
    route: '/huis-verkopen-checklist',
    type: checklistTypes.BUY_HOUSE,
    GA_CATEGORY: 'Huis verkopen checklist',
    categories: {
        prepare: {
            name: 'prepare',
            label: 'Voorbereiding',
            icon: 'common-icon-home',
            boxes: [
                {
                    name: 'price',
                    label: 'Vraagprijs en ondergrens vaststellen',
                    documentType: null,
                    link: null,
                    valueType: 'string',
                    valueLabel: 'Vraagprijs',
                },
                {
                    name: 'repair',
                    label: 'Noodzakelijke reparaties verrichten',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'garden',
                    label: 'Tuin onderhouden',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'contents',
                    label: 'Interieur voorbereiden op bezichtiging',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'clean',
                    label: 'Huis opruimen',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'affiche',
                    label: 'Huis te koop-affiche achter het raam plakken',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'agenda',
                    label: 'Reserveer bezichtigingsdagen in je agenda',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'pictures',
                    label: 'Foto’s en/of video-opnamen maken van huis, tuin en directe omgeving',
                    documentType: 'miscellaneous_indoors',
                    link: null,
                },
                {
                    name: 'energy_label',
                    label: 'Aanvragen definitief energielabel',
                    documentType: 'miscellaneous_indoors',
                    link: null,
                },
                {
                    name: 'ads_budget',
                    label: 'Bereken je totale advertentiebudget',
                    documentType: null,
                    link: null,
                    valueType: 'string',
                    valueLabel: 'Advertentiebudget',
                }
            ]
        },
        advertise: {
            name: 'advertise',
            label: 'Adverteren',
            icon: 'common-icon-document-2',
            boxes: [
                {
                    name: 'channels',
                    label: 'Advertentiekanalen kiezen (krant, makelaarsblad, internet enz.)',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'create_ad',
                    label: 'Advertentie opstellen',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'select_pictures',
                    label: 'Foto’s en/of video-opnamen selecteren',
                    documentType: 'miscellaneous_indoors',
                    link: null,
                },
                {
                    name: 'information',
                    label: 'Informatiepakketjes maken voor belangstellenden',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'place',
                    label: 'Advertenties plaatsen',
                    documentType: null,
                    link: null,
                },
            ]
        },
        sale: {
            name: 'sale',
            label: 'Verkoopfase',
            icon: 'common-icon-euro',
            boxes: [
                {
                    name: 'date',
                    label: 'Datum prikken voor eventueel \'Open Huis\'',
                    documentType: null,
                    link: null,
                    valueType: 'string',
                    valueLabel: 'Datum',
                },
                {
                    name: 'visit',
                    label: 'Bezichtigingen plannen',
                    documentType: null,
                    link: null,
                    valueType: 'string',
                    valueLabel: 'Datum',
                },
                {
                    name: 'negotiate',
                    label: 'Onderhandelen met serieuze belangstellenden',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'sign_model',
                    label: 'Modelkoopovereenkomst ondertekenen',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'credit',
                    label: 'Eventueel overbruggingskrediet regelen',
                    documentType: null,
                    link: null,
                },
            ]
        },
        transmission: {
            name: 'transmission',
            label: 'Overdracht',
            icon: 'common-icon-home',
            boxes: [
                {
                    name: 'notary',
                    label: 'In overleg met kopers afspraak maken bij notaris',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'move',
                    label: 'Verhuizing regelen',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'insurance',
                    label: 'Informeren naar datum opzegging opstal- en inboedelverzekering',
                    documentType: null,
                    link: null,
                    valueType: 'string',
                    valueLabel: 'Datum',
                },
                {
                    name: 'meter',
                    label: 'Op overdrachtsdatum meterstanden noteren',
                    documentType: null,
                    link: null,
                    valueType: 'string',
                    valueLabel: 'Meterstand',
                }
            ]
        },
    }
}