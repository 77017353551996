import './style/fbShare.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as Actions from '../../../../redux/actions';

import {sendEvent} from "../misc/healthAnalysisUtils";
import MaterialFactory from "../../../material/materialFactory";
import {materialTypes} from "../../../material/materialTypes";

let fbShareExample = require('./img/facebookShareExample.png');

export class FbShareView extends React.Component {
    constructor(props) {
        super(props);
        this.onCloseAction = null;
    }

    onClose = () => {
        sendEvent('Delen', 'verder');
        if (isNotNull(this.props.actions)) {
            this.props.actions.showFb(false);
        }
        let popup = ReactDOM.findDOMNode(this.fbPopup);
        if (isNotNull(popup)) {
            popup.classList.remove('active');
        }
        if (typeof this.onCloseAction === 'function') {
            this.onCloseAction();
        }
    };

    activate = (onCloseAction) => {
        this.onCloseAction = onCloseAction;
        let popup = ReactDOM.findDOMNode(this.fbPopup);
        if (isNotNull(popup)) {
            popup.classList.add('active');
            // popup = popup.getElementsByClassName('fbSharePopup')[0];
            // let doc = document.documentElement;
            // let htmlElement = document.getElementsByTagName('html')[0];
            // let fontSize = window.getComputedStyle(htmlElement, null).getPropertyValue('font-size');
            // fontSize = fontSize.slice(0, fontSize.length - 2);
            // let top = ((window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)) / fontSize;
            // popup.style.top = -10 + 'rem';
        }
    };

    /**
     * Renders the like and share buttons.
     * If facebook is already loaded, the init function is used to load the buttons.
     * If not (e.g. on page refresh), the facebook sdk is loaded, which also renders the buttons.
     */
    initFacebook = () => {
        if (window.FB) {
            FB.init({
                appId: '725828190884966',
                xfbml: true,
                version: 'v2.11'
            });
        } else {
            (function (d, s, id) {
                let js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s);
                js.id = id;
                js.src = 'https://connect.facebook.net/nl_NL/sdk.js#xfbml=1&version=v2.11';
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'))
        }
    };

    componentDidUpdate = () => {
        if ((isNotNull(this.props.healthState) && this.props.healthState.showFb) || this.props.showFb) {
            this.activate();
        }
    };

    componentDidMount = () => {
        if ((isNotNull(this.props.healthState) && this.props.healthState.showFb) || this.props.showFb) {
            this.activate();
        }
        this.initFacebook();
    };

    render = () => {
        return (
            <div ref={(fbPopup) => (this.fbPopup = fbPopup)} className='fbSharePopupContainer show'>
                <div className="popupBackground">
                    <div className='fbSharePopup'>
                        <div className='fbShareHeader'>
                            <h2 className='fbShareTitle'>Goede keuze gemaakt?</h2>
                            <span className='fbShareClose common-icon-cross' onClick={this.onClose}/>
                        </div>
                        <div className="fbShareBody">
                            <p className='description'>
                                We hopen dat je met onze analyse een goed en bewust besluit over je zorgverzekering hebt
                                kunnen
                                maken.
                            </p>
                            <p className="description">
                                En wellicht heb je ook vrienden die hier ook bij geholpen zijn. Help je vrienden (en
                                ons) en
                                deel ons op Facebook.
                            </p>
                            <img src={fbShareExample} className='fbShareExample' alt="fbShareExample"/>
                            <div className='fbButtonsContainer'>
                                <div id='fbShareButton' style={{marginBottom: '.2rem'}} className="fb-share-button"
                                     data-href="https://persoonlijk.bydehand.com/zorganalyse"
                                     data-layout="button" data-size="large" data-mobile-iframe="true">
                                    <a
                                        className="fb-xfbml-parse-ignore" target="_blank"
                                        href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fpersoonlijk.bydehand.com%2Fzorganalyse&amp;src=sdkpreparse">Delen</a>
                                </div>
                                <div id='fbLikeButton' className="fb-like"
                                     data-href="https://www.facebook.com/BenJijByDeHand"
                                     data-layout="standard"
                                     data-action="like" data-size="large" data-show-faces="true" data-share="false"/>
                            </div>
                        </div>
                        <div className="fbShareFooter">
                            <MaterialFactory componentType={materialTypes.RAISED_BUTTON} onClick={this.onClose}>
                                Verder</MaterialFactory>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});

const mapStateToProps = state => ({
    healthState: state.healthInsuranceAnalysis,
});

export default connect(mapStateToProps, mapDispatchToProps)(FbShareView);