import React from 'react';
import PropTypes from 'prop-types';

import {generateUUID} from 'glob-common-js/lib/utils';

import StateComponent from "../../misc/stateComponent";
import EditDossierView from "./editDossierView";
import {addCustomEventListener, removeCustomEventListener} from "../../../misc/eventDispatcher";

export default class EditDossierController extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            fields: [],
            submitting: false,
        };
    }

    onSubmit = values => {
        const {dossier} = this.props;
        this.changeState({submitting: true});
        values.id = generateUUID();
        dossier.name = values.name;
        delete values.name;
        dossier.values = values;
        dossier.externalData = Object.assign({}, dossier.externalData, {values: [values]});
        dossier.update();
    };

    onCancel = () => {
        this.changeState({fields: [], submitting: false});
        this.props.onCancel(this.props.dossier);
    };

    prepareFields = () => {
        const {dossier} = this.props;
        const fields = dossier.fields;
        const preparedFields = [];
        for (let field of fields) {
            if (field.editable === false)
                continue;
            let validator = null;
            if (field.required)
                validator = value => isNotNull(value) ? null : 'Vul dit veld in';
            preparedFields.push({
                name: field.name,
                label: field.label,
                type: field.type,
                value: this.getFieldValue(field.name),
                options: field.options,
                adornment: field.adornment,
                validator,
            });
        }
        return preparedFields;
    };

    getFieldValue = (name) => {
        const {dossier} = this.props;
        const {external_data, fields} = dossier;
        let value = this.getValueFromExternalData(external_data, name);
        if (isNull(value))
            value = this.getValueFromFields(name);
        return value || '';
    };

    getValueFromExternalData = (externalData, name) => {
        if (pathIsNotNull(externalData, 'values')) {
            const values = externalData.values[0];
            for (let i = 0; i < values.length; i++) {
                const value = values[i];
                if (isNotNull(value[name])) return value[name];
            }
        }
        return '';
    };

    getValueFromFields = name => {
        const {dossier} = this.props;
        const fields = dossier.fields;
        for (let i = 0; i < fields.length; i++) {
            const field = fields[i];
            if (field.name === name) return field.value;
        }
        return '';
    };

    deactivateLoader = () => {
        this.changeState({submitting: false});
    };

    componentDidMount = () => {
        this.mount();
        addCustomEventListener('grantUnauthorized', this.deactivateLoader);
    };

    componentWillUnmount = () => {
        this.unMount();
        removeCustomEventListener('grantUnauthorized', this.deactivateLoader);
    };

    render = () => {
        const {submitting} = this.state;
        const {dossier} = this.props;
        return <EditDossierView onSubmit={this.onSubmit} submitting={submitting} fields={this.prepareFields()}
                                onCancel={this.onCancel} dossierName={dossier.name}/>;
    };
}

EditDossierController.propTypes = {
    onCancel: PropTypes.func.isRequired,
    dossier: PropTypes.shape({
        name: PropTypes.string.isRequired,
        fields: PropTypes.arrayOf(PropTypes.shape({
            type: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        })).isRequired,
        external_data: PropTypes.shape({
            values: PropTypes.array,
        }),
        type: PropTypes.shape({
            name: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
};