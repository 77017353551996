import React from 'react';
import PropTypes from 'prop-types';
import {applyMiddleware, createStore} from 'redux';
import {combineForms, Control, Errors, Form} from 'react-redux-form';
import thunk from 'redux-thunk';

import {isFunction, warn} from 'glob-common-js/lib/utils';

import AdditionalSelectController from "./additionalSelectController";
import {getFromRegistry, updateRegistry} from "../misc/registry";
import MaterialFactory from "../../../material/materialFactory";
import {materialTypes} from "../../../material/materialTypes";
import {NOW} from "../../../misc/constants";
import {sendEvent} from "../misc/healthAnalysisUtils";
import {navigate} from "../../../../misc/utils";
import StateComponent from "../../../misc/stateComponent";

const EMPTY_VALUE = 'EMPTY_VALUE';
const requiredValidator = val => (val && val.length && val !== EMPTY_VALUE);
const emailValidator = val => requiredValidator(val) && /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(val);

export default class CurrentInsuranceForm extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            insurerSelected: false,
            basicSelected: false,
        };
        this.initialState = {
            insurer: props.isInitialLoaded && isNotNull(props.initialSelected) ? props.initialSelected.insurer : '  ',
            yearOfBirth: props.isInitialLoaded && isNotNull(props.initialSelected) ? props.initialSelected.yearOfBirth : 1970,
            risk: props.isInitialLoaded && isNotNull(props.initialSelected) ? props.initialSelected.risk : 385,
            user: {
                firstname: '',
                email: '',
                password: '',
            }
        };
        this.store = createStore(combineForms({insurance: this.initialState}), applyMiddleware(thunk));
    }

    onSubmit = (insurance) => {
        let additionals = this.additionalSelector.getSelectedAdditionals();
        let basic = this.basicSelector.getSelectedAdditionals();
        let dental = this.dentalSelector.getSelectedAdditionals();
        let mergedInsurance = {
            additionals,
            basic,
            dental,
            ...insurance,
        };

        if (isNotNull(basic)) {
            isFunction(this.props.onSubmitEvent) ? this.props.onSubmitEvent() : this.onSubmitDefaultEvent();
            this.props.onSubmit(mergedInsurance);
        }
    };

    onSubmitDefaultEvent = () => {
        sendEvent('klik', 'Begin analyse');
    };

    createInputField = (label, type, model, placeholder, required, onChange = null, onBlur = null, onFocus = null) => {
        if (required) label = label + ' *';
        const messages = type === 'email' ? {emailValidator: 'Vul een geldig e-mailadres in'} :
            {requiredValidator: 'Vul dit veld in'};
        return (
            <div className="fieldGroup">
                <label className='fieldLabel withErrors'>{label}</label>
                <Errors className='errors' model={model} show='touched' messages={messages}/>
                <Control.input className='insuranceField textField' type={type} model={model} placeholder={placeholder}
                               validators={type === 'email' ? {emailValidator} : {requiredValidator}}
                               onChange={(evt) => {
                                   if (typeof onChange === 'function') {
                                       onChange(evt);
                                   }
                               }} onBlur={() => {
                    if (typeof onBlur === 'function') onBlur();
                }} onFocus={() => {
                    if (typeof onFocus === 'function') onFocus();
                }}/>
            </div>
        )
    };

    createSelectField = (label, model, values, onChange = null, required = true, defaultValue = values[0]) => {
        if (required) label = label + ' *';
        let insurerValidator = val => {
            val = val.trim();
            return isNotNull(val) && val !== EMPTY_VALUE;
        };
        let validator = required ? model === ".insurer" ? {validator: insurerValidator} : {validator: requiredValidator} : {};
        return (
            <div className='fieldGroup'>
                <label className={'fieldLabel' + (required ? ' withErrors' : '')}>{label}</label>
                <Errors className='errors' model={model} show='touched'
                        messages={{validator: 'Selecteer een optie'}}/>
                <Control.select className='insuranceField' model={model} onChange={(evt) => {
                    if (typeof onChange === 'function') {
                        let selected = evt.target.options[evt.target.selectedIndex].value;
                        onChange(selected);
                    }
                }} validators={validator} defaultValue={defaultValue} required={required}>
                    {model === ".insurer" ?
                        <option value={EMPTY_VALUE} hidden>Selecteer je huidige
                            zorgverzekeraar</option> : null}
                    {values.map((value, key) => (
                        <option key={key} value={value}>{value}</option>
                    ))}
                </Control.select>
            </div>
        );
    };

    /**
     * Generate list of birth years.
     */
    createYearsOfBirth = () => {
        let startYear = 1900;
        let endYear = (NOW().year() + 1) - 18;
        let years = [];
        while (startYear <= endYear) years.push(startYear++);
        return years;
    };

    changePassword = evt => {
        this.setState({password: evt.target.value});
    };

    createLoginForm = () => {
        if (this.props.userIsLoggedIn) return null;
        return (
            <div>
                <div className='labelContainer'>
                    <label className='loginLabel'>Inloggegevens</label>
                    <label className="authNavigate" onClick={() => {
                        navigate('/registratie', true);
                    }}>Heb je nog geen account?</label>
                </div>
                <div className="fieldGroup">
                    <label className='fieldLabel withErrors'>Email adres</label>
                    <Errors className='errors' model='.user.email' show='touched'
                            messages={{emailValidator: 'Vul een geldig e-mailadres in'}}/>
                    <Control.input id='healthEmail' className='insuranceField textField' type='email'
                                   model='.user.email'
                                   placeholder='Email adres' validators={{emailValidator}} onFocus={() => {
                        let errors = document.getElementsByClassName('registerErrorMessage')[0];
                        if (isNotNull(errors)) {
                            errors.classList.remove('show');
                        }
                    }}/>
                </div>
                <div className="passwordField">
                    {this.createInputField('Wachtwoord', 'password', '.user.password', '', true, this.changePassword,
                        null, null)}
                    <span className='forgotPass'
                          onClick={this.props.onForgotPassword}>Wachtwoord vergeten?</span>
                </div>
                <div className='registerErrorMessage'>
                    <label>Onjuiste combinatie van email en wachtwoord</label>
                </div>
            </div>
        )
    };

    resetSelect = (reference, registryEntry) => {
        if (isNotNull(this[reference]) && typeof this[reference].removeSelection === 'function') {
            this[reference].removeSelection();
            let registry = getFromRegistry('healthInsurance');
            registry.currentInsurance[registryEntry] = null;
            updateRegistry('healthInsurance', registry);
        } else {
            warn(reference + ' is not a ref');
        }
    };

    getInitialAdditionalText = () => {
        let additionals = this.props.initialSelected.additionals;
        if (this.props.isInitialLoaded && isNotNull(additionals)) {
            if (Array.isArray(additionals)) {
                for (let i = 0; i < additionals.length; i++) {
                    if (!this.initialSelectedIsValid(additionals[i], this.props.additionals)) {
                        return 'Selecteer een aanvullende verzekering';
                    }
                }
                return additionals.length === 1 ? additionals[0].label : additionals.length + ' modules geselecteerd';
            } else if (typeof additionals === 'object' && additionals.hasOwnProperty('label')) {
                if (this.initialSelectedIsValid(additionals, this.props.additionals)) {
                    return additionals.label;
                }
            }
        }
        return 'Selecteer een aanvullende verzekering';
    };

    getInitialDentalText = () => {
        let dentals = this.props.initialSelected.dental;
        if (this.props.isInitialLoaded && isNotNull(dentals)) {
            if (Array.isArray(dentals)) {
                for (let i = 0; i < dentals.length; i++) {
                    if (!this.initialSelectedIsValid(dentals[i], this.props.dentals)) {
                        return 'Selecteer een tandverzekering';
                    }
                }
                return dentals.length === 1 ? dentals[0].label : dentals.length + ' verzekeringen geselecteerd';
            } else if (typeof dentals === 'object' && dentals.hasOwnProperty('label')) {
                if (this.initialSelectedIsValid(dentals, this.props.dentals)) {
                    return dentals.label;
                }
            }
        }
        return 'Selecteer een tandverzekering';
    };

    getInitialBasicText = () => (
        this.props.isInitialLoaded && isNotNull(this.props.initialSelected.basic) &&
        this.initialSelectedIsValid(this.props.initialSelected.basic, this.props.basics) ? this.props.initialSelected.basic.label :
            'Selecteer een basisverzekering'
    );

    initialSelectedIsValid = (selected, selectables) => {
        return selectables.map(selectable => selectable.label).indexOf(selected.label) > -1;
    };

    selectInsurer = evt => {
        this.props.onSelectInsurer(evt);
        this.changeState({insurerSelected: true});
        if (this.props.isInitialLoaded) {
            this.validateBasic();
            this.validateAdditional();
            this.validateDental();
        } else {
            this.resetSelect('basicSelector', 'basic');
            this.resetSelect('additionalSelector', 'additionals');
            this.resetSelect('dentalSelector', 'dental');
        }
    };

    selectBasic = (basic) => {
        this.props.onSelectBasic(basic);
        this.changeState({basicSelected: true});
        if (this.props.isInitialLoaded) {
            this.validateAdditional();
            this.validateDental();
        } else {
            this.resetSelect('additionalSelector', 'additionals');
            this.resetSelect('dentalSelector', 'dental');
        }
    };

    selectAdditional = () => {
        let selectedAdditionals = this.additionalSelector.getSelectedAdditionals();
        this.props.onSelectAdditional(selectedAdditionals);
        if (this.props.isInitialLoaded) {
            this.validateDental();
        } else {
            this.resetSelect('dentalSelector', 'dental');
        }
    };

    validateBasic = () => {
        let selectedBasic = this.basicSelector.getSelectedAdditionalsIntern()[0];
        let basics = this.props.basics;
        let valid = false;
        if (isNotNull(selectedBasic)) {
            for (let i = 0; i < basics.length; i++) {
                if (basics[i].label === selectedBasic.name) {
                    valid = true;
                    break;
                }
            }
        }
        if (!valid) {
            this.resetSelect('basicSelector', 'basic');
        }
    };

    validateAdditional = () => {
        let selectedAdditionals = this.additionalSelector.getSelectedAdditionalsIntern();
        let additionals = this.props.additionals;
        let valid = true;
        for (let i = 0; i < selectedAdditionals.length; i++) {
            if (valid) {
                valid = false;
            } else {
                this.resetSelect('additionalSelector', 'additionals');
                return;
            }

            let selectedAdditional = selectedAdditionals[i];
            if (isNotNull(selectedAdditional)) {
                for (let j = 0; j < additionals.length; j++) {
                    let additional = additionals[j];
                    if (additional.label === selectedAdditional.name) {
                        valid = true;
                        break;
                    }
                }
            }
        }
        if (!valid) {
            this.resetSelect('additionalSelector', 'additionals');
        }
    };

    validateDental = () => {
        let selectedDental = this.dentalSelector.getSelectedAdditionalsIntern();
        let dentals = this.props.dentals;
        let valid = false;
        if (isNotNull(selectedDental)) {
            for (let i = 0; i < dentals.length; i++) {
                let dental = dentals[i];
                if (dental.label === selectedDental.name) {
                    valid = true;
                    break;
                }
            }
        }
        if (!valid) this.resetSelect('dentalSelector', 'dental');
    };

    componentDidMount = () => {
        this.mount();
    };

    componentWillUnmount = () => {
        this.unMount();
    };

    render = () => {
        const {
            submitText, insurers, basics, isInitialLoaded, initialSelected, additionals, dentals, withLogin
        } = this.props;
        return (
            <Form className='currentInsuranceForm' store={this.store} model='insurance' onSubmit={this.onSubmit}>
                <h2 className='formHeader'>Vul hier gegevens van je huidige zorgverzekering in</h2>
                {withLogin && this.createLoginForm()}
                {this.createSelectField('Wat is jouw huidige zorgverzekeraar?', '.insurer', insurers,
                    this.selectInsurer, true, this.initialState.insurer)}
                <AdditionalSelectController ref={(basiscSelector) => (this.basicSelector = basiscSelector)}
                                            additionals={basics} withoutEmpty={true}
                                            fieldLabel={'Wat voor basisverzekering heb je? *'} id={'basicSelector'}
                                            onSelect={this.selectBasic} defaultSelectLabel={'Basis'}
                                            selectText={'Selecteer een basisverzekering'}
                                            initialSelectText={this.getInitialBasicText()}
                                            required={true}
                                            defaultDiscount={isInitialLoaded ? {
                                                value: initialSelected.basic.discount,
                                                type: initialSelected.basic.discount_type,
                                            } : {value: '0.00', type: ''}}
                                            defaultSelected={isInitialLoaded ? initialSelected.basic : null}/>
                <AdditionalSelectController ref={(additionalSelector) => (this.additionalSelector = additionalSelector)}
                                            fieldLabel={'Heb je een aanvullende verzekering?'}
                                            additionals={additionals} id={'additionalSelector'}
                                            onSelect={this.selectAdditional}
                                            selectText={'Selecteer een aanvullende verzekering'}
                                            initialSelectText={this.getInitialAdditionalText()}
                                            defaultDiscount={isInitialLoaded ? {
                                                value: initialSelected.additionalDiscount,
                                                type: initialSelected.additionalDiscountType,
                                            } : {value: '0.00', type: ''}}
                                            defaultSelected={isInitialLoaded ? initialSelected.additionals : null}/>
                <AdditionalSelectController ref={(dentalSelector) => (this.dentalSelector = dentalSelector)}
                                            fieldLabel={'Tandartsverzekering'}
                                            additionals={dentals} id={'dentalSelector'}
                                            defaultSelectLabel='Tand'
                                            selectText={'Selecteer een tandverzekering'}
                                            initialSelectText={this.getInitialDentalText()}
                                            defaultDiscount={isInitialLoaded && isNotNull(initialSelected.dental) ? {
                                                value: initialSelected.dental.discount,
                                                type: initialSelected.dental.discount_type,
                                            } : {value: '0.00', type: ''}}
                                            defaultSelected={isInitialLoaded ? initialSelected.dental : null}/>
                {this.createSelectField('Geboortejaar', '.yearOfBirth', this.createYearsOfBirth(), null, true, this.initialState.yearOfBirth.toString())}
                {this.createSelectField('Huidig eigen risico', '.risk', [385, 485, 585, 685, 785, 885], null, true, this.initialState.risk.toString())}
                <MaterialFactory componentType={materialTypes.RAISED_BUTTON}
                                 disabled={!this.state.insurerSelected || !this.state.basicSelected}
                                 type='submit'>{submitText}</MaterialFactory>
            </Form>
        )
    }
}

CurrentInsuranceForm.propTypes = {
    onSelectInsurer: PropTypes.func.isRequired,
    onSelectBasic: PropTypes.func.isRequired,
    onSelectAdditional: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onSubmitEvent: PropTypes.func,
    onForgotPassword: PropTypes.func,
    insurers: PropTypes.arrayOf(PropTypes.string).isRequired,
    basics: PropTypes.arrayOf(PropTypes.object).isRequired,
    additionals: PropTypes.arrayOf(PropTypes.object).isRequired,
    dentals: PropTypes.arrayOf(PropTypes.object).isRequired,
    initialSelected: PropTypes.object.isRequired,
    isInitialLoaded: PropTypes.bool.isRequired,
    submitText: PropTypes.string.isRequired,
    withLogin: PropTypes.bool.isRequired,
    userIsLoggedIn: PropTypes.bool.isRequired,
};

CurrentInsuranceForm.defaultProps = {
    withLogin: false,
    allowMultipleExtra: false,
    isInitialLoaded: false,
    submitText: 'Begin analyse',
};