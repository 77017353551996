import './style/dossierData.scss';

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {timeFormats} from "../../../../common/components/datePicker";
import {materialTypes} from "../../../material/materialTypes";
import MaterialFactory from "../../../material/materialFactory";
import EditDossierController from "../../edit/editDossierController";
import {getOnboardingManager} from "../../../onboarding/manager/onboardingManagerHelper";

export class DossierDataView extends React.Component {
    constructor(props) {
        super(props);
    }

    getFieldValue = field => {
        const {dossier} = this.props;
        let value = null;
        if (pathIsNotNull(dossier, 'external_data.values'))
            value = dossier.external_data.values[0][field.name];
        else value = field.value;

        if (isNotNull(field.options)) {
            return this.getOptionLabel(field.options, value);
        }
        return value;
    };

    getOptionLabel = (options, value) => {
        options = Array.isArray(options) ? options : JSON.parse(options);
        for (let i = 0; i < options.length; i++) {
            const option = options[i];
            if (option.hasOwnProperty('value') && option.hasOwnProperty('label') && option.value === value)
                return option.label;
            else if (option === value)
                return option;
        }
    };

    renderDataField = (field, key) => {
        let fieldValue = this.getFieldValue(field);
        if (isNotNull(fieldValue)) {
            switch (field.type) {
                case 'PRICE':
                    fieldValue = '\u20ac ' + fieldValue;
                    break;
                case 'DATE':
                    fieldValue = moment(fieldValue, timeFormats).format('DD-MM-YYYY');
                    break;
                case 'DATETIME':
                    fieldValue = moment(fieldValue, timeFormats).format('DD-MM-YYYY HH:mm');
                    break;
            }
            return (
                <div key={key} className='dataFieldGroup'>
                    <label className='fieldTitle'>{field.label}</label>
                    <label className='fieldValue'>{fieldValue}</label>
                </div>
            );
        }
        return null;
    };

    renderEdit = () => (
        <div className='dossierEdit'>
            <EditDossierController dossier={this.props.dossier} onCancel={this.props.onCancel}/>
        </div>
    );

    renderData = () => (
        <div className='dossierData'>
            <div className='fieldContainer'>
                {this.props.dossier.fields.map(this.renderDataField)}
                {isNotNull(this.props.dossier.type.extraDetails) ?
                    this.props.dossier.type.extraDetails.map(this.renderDataField) : null}
            </div>
        </div>
    );

    render = () => {
        const dossierLabel = isNotNull(this.props.dossier) ? this.props.dossier.label : null;
        const onboardingActive = getOnboardingManager('store').isActive();
        return (
            <div className='dossierDetailBox'>
                <div className='dossierDataHeader'>
                    <label
                        className='dossierType'>{isNotNull(this.props.dossier) ? this.props.dossier.typeLabel : null}</label>
                    <div className='titleContainer'>
                        <p className='dossierLabel' title={dossierLabel}>{dossierLabel}</p>
                        <div className='dossierButtonsContainer'>
                            <button className={'editButton ' + (this.props.editing ? '' : 'visible')}
                                    onClick={this.props.onEdit}>Wijzigen
                            </button>
                            <MaterialFactory componentType={materialTypes.FLAT_BUTTON} disabled={onboardingActive}
                                             onClick={this.props.onDelete} size='small'>Verwijderen</MaterialFactory>
                        </div>
                    </div>
                </div>
                <div className='dossierDataBody'>
                    {this.props.editing ? this.renderEdit() : isNotNull(this.props.dossier) ? this.renderData() : null}
                </div>
            </div>
        );
    }
}

DossierDataView.propTypes = {
    onCancel: PropTypes.func.isRequired,
    dossier: PropTypes.shape({
        label: PropTypes.string.isRequired,
        values: PropTypes.object.isRequired,
        fields: PropTypes.arrayOf(PropTypes.object).isRequired,
        type: PropTypes.shape({
            extraDetails: PropTypes.arrayOf(PropTypes.object),
        }).isRequired,
    }),
    editing: PropTypes.bool.isRequired,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    store: PropTypes.object.isRequired,
};

export default DossierDataView;