import './style/financialDashboardView.scss';

import React from 'react';
import PropTypes from 'prop-types';
import Calendar from 'rc-calendar';
import nl_NL from 'rc-calendar/lib/locale/nl_NL'
import moment from 'moment';
import Chart from 'chart.js';
import _ from 'lodash';

import {remToPx, toMoney} from 'glob-common-js/lib/utils';
import {hasHover} from 'glob-common-react/lib/utils/reactUtils';

import DashboardInfoContainer from "../../dashboard/info/dashboardInfoContainer";
import {materialTypes} from "../../material/materialTypes";
import MaterialFactory from "../../material/materialFactory";
import StateComponent from "../../misc/stateComponent";
import {navigate} from "../../../misc/utils";

// Puts percentage labels onto the doughnut chart
require('chart.piecelabel.js');

export default class FinancialDashboardView extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            calendarMode: 'month',
        };
    }

    onClickNav = () => {
        navigate('/financieel-overzicht');
    };

    getCategories = type => {
        return this.props.categories.filter(category => category.type === type);
    };

    getTotal = type => {
        let categories = this.getCategories(type);
        let total = 0;
        for (let i = 0; i < categories.length; i++) {
            total += categories[i].total;
        }
        return total;
    };

    getBudget = () => {
        return 'Saldo: ' + toMoney(this.getTotal('income') - this.getTotal('expense'));
    };

    toggleCalendar = () => {
        let calendarElem = document.getElementById('calendarComp');
        if (isNotNull(calendarElem)) {
            let contained = calendarElem.classList.contains('show');
            calendarElem.classList.toggle('show');
            if (!contained)
                window.addEventListener('click', this.calendarListener);
            else
                window.removeEventListener('click', this.calendarListener);
        }
    };

    calendarListener = () => {
        let calendarElem = document.getElementById('calendarComp');
        let dateText = document.querySelector('label.dateText');
        if (areNotNull(calendarElem, dateText) && !hasHover(calendarElem) && !hasHover(dateText)) {
            calendarElem.classList.remove('show');
        }
    };

    onDisplayChange = (date, mode) => {
        let disallowedModes = ['time', 'date'];
        if (disallowedModes.indexOf(mode) === -1) {
            this.changeState({calendarMode: mode})
        }
    };

    onChange = (param) => {
        let current = this.props.date;
        let month = current.month();
        let selectedMonth = param.month();
        if (month !== selectedMonth) {
            let calendarElem = document.getElementById('calendarComp');
            if (isNotNull(calendarElem)) {
                calendarElem.classList.toggle('show');
            }
            this.props.setDate(param);
        }
    };

    createChart = (id, categories) => {
        let canvas = document.getElementById(id);
        if (isNotNull(canvas)) {
            let backgroundColor = categories.map(category => category.stroke);
            return new Chart(canvas, {
                type: 'doughnut',
                data: {
                    datasets: [{
                        data: categories.map(category => category.total),
                        backgroundColor,
                    }],
                    labels: categories.map(category => category.label),
                },
                options: {
                    legend: {
                        display: false,
                    },
                    tooltips: {
                        bodyFontSize: remToPx(.8),
                        callbacks: {
                            label: (toolTipItem, data) => {
                                let dataset = data.datasets[0];
                                let label = data.labels[toolTipItem.index] || '';
                                if (isNotNull(label)) {
                                    return label + ': ' + toMoney(dataset.data[toolTipItem.index]);
                                }
                                return label;
                            }
                        }
                    },
                    pieceLabel: {
                        render: 'percentage',
                        precision: 0,
                        fontColor: '#fff',
                        fontSize: remToPx(2),
                    },
                }
            });
        }
        return null;
    };

    componentDidMount = () => {
        this.mount();
    };

    componentDidUpdate = prevProps => {
        if (!_.isEqual(prevProps.categories, this.props.categories)) {
            this.incomeCanvas = this.createChart('incomeCanvas', this.getCategories('income'));
            this.expenseCanvas = this.createChart('expenseCanvas', this.getCategories('expense'));
        }
    };

    componentWillUnmount = () => {
        this.unMount();
        this.incomeCanvas && this.incomeCanvas.destroy();
        this.expenseCanvas && this.expenseCanvas.destroy();
        window.removeEventListener('click', this.calendarListener);
    };

    render = () => {
        const {date, loaded, setMonth, toFinancial} = this.props;
        const {calendarMode} = this.state;
        return (
            <div className='box financialBox'>
                <header>
                    <label className='dashboardTitle clickable' onClick={this.onClickNav}>Financieel
                        overzicht</label>
                    <DashboardInfoContainer
                        infoText={'Voeg al je vaste/variabele inkomsten en uitgaven toe om zo meer overzicht te ' +
                        'krijgen in je financiën. Je ziet in een oogopslag wat je elke maand uitgeeft en wat er binnen ' +
                        'komt.'}/>
                    <label className='budgetLabel'>{this.getBudget()}</label>
                </header>
                <div className='financialDashboard'>
                    {!loaded &&
                    <div className='financialLoader'>
                        <MaterialFactory componentType={materialTypes.CIRCULAR_PROGRESS}/>
                    </div>}
                    <div className='financialHeader'>
                        <div className='dateSelector'>
                        <span className='leftArrow common-icon-arrow-left' onClick={() => {
                            setMonth('previous')
                        }}/>
                            <label className='dateText'
                                   onClick={this.toggleCalendar}>{date.format('MMMM') + ' ' + date.year()}</label>
                            <span className='rightArrow common-icon-arrow-right' onClick={() => {
                                setMonth('next')
                            }}/>
                            <div className='calendarContainer'>
                                <div id='calendarComp' className='financialCalendar'>
                                    <Calendar showDateInput={false} defaultValue={date}
                                              onPanelChange={this.onDisplayChange} onChange={this.onChange}
                                              locale={nl_NL} mode={calendarMode} className={'datePickerCalendar'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='financialBody'>
                        <div className='chartContainer'>
                            <label className='chartTitle'>Inkomsten</label>
                            <div className='canvasContainer'>
                                <canvas id='incomeCanvas'/>
                                <label className='typeTotal'>{toMoney(this.getTotal('income'))}</label>
                            </div>
                        </div>
                        <div className='chartContainer'>
                            <label className='chartTitle'>Uitgaven</label>
                            <div className='canvasContainer'>
                                <canvas id='expenseCanvas'/>
                                <label className='typeTotal'>{toMoney(this.getTotal('expense'))}</label>
                            </div>
                        </div>
                    </div>
                    <label className='navLabel' onClick={toFinancial}>Bekijk je inkomsten en uitgaven</label>
                </div>
            </div>
        )
    };
}

FinancialDashboardView.propTypes = {
    setMonth: PropTypes.func.isRequired,
    setDate: PropTypes.func.isRequired,
    toFinancial: PropTypes.func.isRequired,
    date: PropTypes.instanceOf(moment).isRequired,
    categories: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        total: PropTypes.number.isRequired,
        stroke: PropTypes.string.isRequired,
    })).isRequired,
};