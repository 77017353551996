import './style/completion.scss';

import React from 'react';
import PropTypes from 'prop-types';
import MaterialFactory from "../../material/materialFactory";
import {materialTypes} from "../../material/materialTypes";

const logo = require('BdhCommon/images/logo_bydehand.svg');

export default class Completion20 extends React.Component {
    constructor(props) {
        super(props);
    }

    render = () => {
        return (
            <div className='completion_20'>
                <div className='textContainer'>
                    <p className='par'>Geweldig!</p>
                    <p className='par'>
                        Aan de hand van de ingevulde gegevens hebben we een aantal zaken voor je geregeld. Zo hebben we
                        je
                        eerste dossiers aangemaakt, inkomsten en uitgaven ingevuld in je financiële overzicht en
                        agendapunten toegevoegd waarin je precies ziet wanneer je bij- of afschriften plaatsvinden.
                    </p>
                    <p className='par'>
                        Klik hieronder op ‘AAN DE SLAG’ en vindt alles terug op jouw persoonlijke dashboard.
                    </p>
                </div>
                <div className='footerContainer'>
                    <MaterialFactory componentType={materialTypes.RAISED_BUTTON}
                                     onClick={this.props.onClose}>Aan de slag</MaterialFactory>
                    <img src={logo} className='logo'/>
                </div>
            </div>
        )
    }
}

Completion20.propTypes = {
    onClose: PropTypes.func.isRequired,
};