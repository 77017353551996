const additionalIconMapping = {
    'fysiotherapie': 'common-icon-physio',
    'tandarts': 'common-icon-dentist',
    'alternatieve zorg': 'common-icon-ying-yang',
    'Spoedeisende hulp buitenland': 'common-icon-first-aid-kit',
    'brillen / lenzen': 'common-icon-glasses',
    'kraamzorg': 'common-icon-pregnancy-care',
    'orthodontie vanaf 18 jaar': 'common-icon-orthodontist',
    'acne behandeling': 'common-icon-acne',
    'geneesmiddelen': 'common-icon-medicine',
    'hulpmiddelen (overig)': 'common-icon-medical-supply',
    'herstellingsoord / zorghotel': 'common-icon-recovery-place',
};

export default additionalIconMapping;