import './style/contactPopup.scss';

import React from 'react';
import {sendGaEvent} from "../../common/js/ga";
import {addCustomEventListener, removeCustomEventListener} from "../../misc/eventDispatcher";

const GA_CATEGORY = 'Contact popup';
export default class ContactPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
        };
        this._isMounted = false;
    }

    changeState = stateChange => {
        this._isMounted && this.setState(stateChange);
    };

    activate = () => {
        this.changeState({active: true});
    };

    onWebsiteClick = () => {
        sendGaEvent(GA_CATEGORY, 'Klik', 'Naar website');
        window.location = '//bydehand.com';
    };

    onNumberClick = () => {
        sendGaEvent(GA_CATEGORY, 'Klik', 'Telefoonnummer');
    };

    onEmailClick = () => {
        sendGaEvent(GA_CATEGORY, 'Klik', 'E-mail');
        window.open('mailto:info@bydehand.eu');
    };

    onClose = () => {
        sendGaEvent(GA_CATEGORY, 'Klik', 'Sluiten');
        this.changeState({active: false});
    };

    renderChannelContainer = (title, channelInfo, message) => (
        <div className='channelContainer'>
            <div className='channelBox'>
                <div className='channelHeader'>
                    <label className='channelTitle'>{title}</label>
                </div>
                <div className='channelBody'>
                    {channelInfo}
                </div>
            </div>
            <p className='channelMessage'>{message}</p>
        </div>
    );

    componentDidMount = () => {
        this._isMounted = true;
        addCustomEventListener('openContactPopup', this.activate);
    };

    componentWillUnmount = () => {
        this._isMounted = false;
        removeCustomEventListener('openContactPopup', this.activate);
    };

    render = () => {
        if (this.state.active)
            return (
                <>
                    <div className='blackBackground' onClick={this.onClose}/>
                    <div className='contactPopup'>
                        <div className='contactPopupHeader'>
                            <label className='contactPopupTitle'>Vragen?</label>
                            <span className='common-icon-cross closeButton' onClick={this.onClose}/>
                        </div>
                        <div className='contactPopupBody'>
                            <p className='contactMessage'>
                                Heb je een vraag of suggestie over het gebruik van het platform? Dan kun je ons bereiken
                                via de volgende kanalen:
                            </p>
                            <div className='channelContainers'>
                                {this.renderChannelContainer('Bel of Whatsapp',
                                    <a href='tel:+31682570420'
                                       className='channelInfo'
                                       onClick={this.onNumberClick}>06-82570420</a>, 'WhatsApp je vraag of suggestie ' +
                                    'naar 06-82570420 en krijg binnen 48 uur antwoord.')}
                                {this.renderChannelContainer('E-mailadres',
                                    <a href='mailto:info@bydehand.eu' className='channelInfo'
                                       onClick={this.onEmailClick}>info@bydehand.eu</a>, 'Mail je vraag of suggestie naar ' +
                                    'info@bydehand.eu en krijg binnen 24 uur antwoord.')}
                            </div>
                            <div className='websiteContainer'>
                                <span className='common-icon-arrow-left arrow' onClick={this.onWebsiteClick}/>
                                <label className='websiteLabel' onClick={this.onWebsiteClick}>Naar de website</label>
                            </div>
                        </div>
                    </div>
                </>
            );
        return null;
    };
}