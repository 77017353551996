import React from 'react';
import StateComponent from "../../misc/stateComponent";
import AgendaOnboardingView from "./agendaOnboardingView";
import {agendaOnboardingSteps} from "../manager/agendaManager";
import {addCustomEventListener, removeCustomEventListener} from "../../../misc/eventDispatcher";

export default class AgendaOnboardingController extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            step: agendaOnboardingSteps.INTRO_STEP,
        };
    }

    onEvent = params => {
        const {type, data} = params;
        switch (type) {
            case 'activate':
                const step = pathIsNotNull(data, 'step') ? data.step : agendaOnboardingSteps.INTRO_STEP;
                this.changeState({step, active: true});
                break;
            case 'setStep':
                this.changeState({step: data.step});
                break;
            case 'deactivate':
                this.changeState({active: false});
                break;
        }
    };

    componentDidMount = () => {
        this.mount();
        addCustomEventListener('agendaOnboardingFlow', this.onEvent);
    };

    componentWillUnmount = () => {
        this.unMount();
        removeCustomEventListener('agendaOnboardingFlow', this.onEvent);
    };

    render = () => {
        const {active, step} = this.state;
        if (active)
            return <AgendaOnboardingView step={step}/>;
        return null;
    }
}