import './style/agendaOnboarding.scss';

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import StepLabel from '@material-ui/core/StepLabel';

import {agendaOnboardingSteps} from "../manager/agendaManager";
import {appear} from "../onboardingController_v2";
import MaterialFactory from "../../material/materialFactory";
import {materialTypes} from "../../material/materialTypes";
import {getOnboardingManager} from "../manager/onboardingManagerHelper";
import StateComponent from "../../misc/stateComponent";

export default class AgendaOnboardingView extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            selectedChecklist: null,
        }
    }

    onSelectType = type => () => {
        const manager = getOnboardingManager('agenda');
        manager.sendEvent('Klik', `${type} button`);
        manager.setNextStep(type);
    };

    onClickChecklist = name => () => {
        this.changeState({selectedChecklist: name});
    };

    onConfirmAgenda = () => {
        const manager = getOnboardingManager('agenda');
        manager.sendEvent('Klik', 'Aan de slag');
        manager.setNextStep();
    };

    onNextStepWithGa = (action, label) => () => {
        const manager = getOnboardingManager('agenda');
        manager.sendEvent(action, label);
        manager.setNextStep();
    };

    renderStep = () => {
        const {step} = this.props;
        switch (step) {
            case agendaOnboardingSteps.INTRO_STEP:
            case agendaOnboardingSteps.CHECKLIST_INTRO:
                return this.renderSteps();
            case agendaOnboardingSteps.CHECKLIST_EXPLANATION:
                return this.renderChecklistExplanation();
            case agendaOnboardingSteps.CLICK_ADD_AGENDA:
                return this.clickAddAgenda();
            case agendaOnboardingSteps.ADD_AGENDA:
                return this.renderAddAgenda();
            case agendaOnboardingSteps.CONFIRM_AGENDA:
                return this.renderConfirmAgenda();
            case agendaOnboardingSteps.NOTIFY_HELP:
                return this.renderNotifyHelp();
        }
    };

    renderSteps = () => {
        const {step} = this.props;
        const steps = {
            [agendaOnboardingSteps.INTRO_STEP]: 0,
            [agendaOnboardingSteps.CHECKLIST_INTRO]: 1,
        };
        appear('agendaOnboardingContainer');
        return (
            <div className='onboardingContent' id='agendaOnboardingContainer'>
                <div className='agendaOnboardingPopup' id='agendaOnboardingPopup'>
                    <div className='onboardingHeader'>
                        <label className='headerTitle'>Niets belangrijks meer vergeten</label>
                    </div>
                    <div className='onboardingBody'>
                        <Stepper orientation='vertical' activeStep={steps[step]}>
                            <Step>
                                <StepLabel>Introductie</StepLabel>
                                <StepContent>
                                    {this.renderIntro()}
                                </StepContent>
                            </Step>
                            <Step>
                                <StepLabel>Beginnen</StepLabel>
                                <StepContent>
                                    {this.renderAddAgenda()}
                                </StepContent>
                            </Step>
                            <Step>
                                <StepLabel>Aan de slag</StepLabel>
                            </Step>
                        </Stepper>
                    </div>
                </div>
            </div>
        )
    };

    renderIntro = () => {
        return (
            <div className='agendaIntroContainer'>
                <label className='introTitle'>Welkom op ByDeHand</label>
                <div className='introTypeContainer'>
                    <span className='common-icon-calender typeIcon'/>
                    <div>
                        <label className='typeTitle'>Zet herinneringen in je agenda</label>
                        <ul className='typeInfoList'>
                            <li>Zet belangrijke herinneringen in je agenda en krijg een melding wanneer je iets moet
                                regelen.
                            </li>
                        </ul>
                    </div>
                    <MaterialFactory componentType={materialTypes.RAISED_BUTTON} size='small'
                                     onClick={this.onSelectType('agenda')}>Agenda</MaterialFactory>
                </div>
                {/*<div className='introTypeContainer'>*/}
                {/*<span className='common-icon-file-checklist typeIcon'/>*/}
                {/*<div className='descriptionContainer'>*/}
                {/*<label className='typeTitle'>Compleet dankzij de checklist</label>*/}
                {/*<ul>*/}
                {/*<li>Vink af wat je hebt afgerond en zie je wat je nog moet doen.</li>*/}
                {/*</ul>*/}
                {/*</div>*/}
                {/*<MaterialFactory componentType={materialTypes.RAISED_BUTTON} size='small'*/}
                {/*onClick={this.onSelectType('checklist')}>Checklists</MaterialFactory>*/}
                {/*</div>*/}
            </div>
        )
    };

    renderChecklistIntro = () => {

    };

    renderChecklistExplanation = () => {
        return (
            <div className='checklistExplanationContainer'>
                <label className='introTitle'>Checklist</label>
                <label className='introSubTitle'>Kies hieronder de checklist waarmee je wilt beginnen:</label>
                <div className='checklistChoiceContainer'>
                    {this.renderChecklistChoices()}
                </div>
            </div>
        )
    };

    renderChecklistChoices = () => {
        const checklists = [
            {label: '18 / Begin studie', name: '18'},
            {label: 'Student', name: 'student'},
            {label: 'Young professional', name: 'prof'},
        ];
        return checklists.map((check, key) => {
            const {label, name} = check;
            const {selectedChecklist} = this.state;
            const className = classNames('checklistButton', selectedChecklist === name && 'selected');
            return (
                <div key={key} className={className} onClick={this.onClickChecklist(name)}>
                    <label className='checklistLabel'>{label}</label>
                </div>
            )
        })
    };

    clickAddAgenda = () => {
        return (
            <div className='onboardingContent'>
                <div className='agendaOnboardingPopup absolute sbr centerX fw' id='agendaOnboardingPopup'>
                    <div className='onboardingHeader lp'>
                        <label className='headerTitle'>Agenda</label>
                    </div>
                    <div className='onboardingBody padded'>
                        <p className='no_wrap'>Klik op <span className='common-icon-plus agendaAddButton'/> om een
                            agenda item toe te voegen.
                        </p>
                    </div>
                </div>
            </div>
        )
    };

    renderAddAgenda = () => (
        <div className='onboardingContent'>
            <div className='agendaOnboardingPopup absolute sbr centerX fw' id='agendaOnboardingPopup'>
                <div className='onboardingHeader lp'>
                    <label className='headerTitle'>Agenda</label>
                </div>
                <div className='onboardingBody padded'>
                    <p className='no_wrap'>Vul de velden in en druk op opslaan.</p>
                </div>
            </div>
        </div>
    );

    renderConfirmAgenda = () => (
        <div className='onboardingContent'>
            <div className='agendaOnboardingPopup absolute sbr centerX fw' id='agendaOnboardingPopup'>
                <div className='onboardingHeader lp'>
                    <label className='headerTitle'>Agenda</label>
                </div>
                <div className='onboardingBody col padded'>
                    <p className='confirmAgendaText'>Je hebt je eerste agendapunt toegevoegd. Ga nu aan de slag en
                        voeg meer agendapunten toe.
                    </p>
                    <MaterialFactory componentType={materialTypes.RAISED_BUTTON} size='small'
                                     onClick={this.onConfirmAgenda}>Aan de slag</MaterialFactory>
                </div>
            </div>
        </div>
    );

    renderNotifyHelp = () => {
        const manager = getOnboardingManager('agenda');
        manager.highlightElement('menuPaper');
        manager.highlightElement('menuTutorial');
        appear('onboardingNotifyHelp');
        return (
            <div className='onboardingContent' id='onboardingNotifyHelp'>
                <div className='agendaOnboardingPopup showTutorial absolute sbr' id='agendaOnboardingPopup'>
                    <div className='onboardingHeader lp'>
                        <label className='headerTitle'>Neem me ByDeHand</label>
                    </div>
                    <div className='onboardingBody'>
                        <div className='onboardingNotifyHelp'>
                            <p className='notifyHelpMessage'>Mocht je in de toekomst nog gebruiken willen maken van
                                deze hulp dan kun je deze guide altijd raadplegen via de knop <i>
                                    Neem me ByDeHand</i> in het menu.</p>
                            <MaterialFactory componentType={materialTypes.RAISED_BUTTON} size='small'
                                             onClick={this.onNextStepWithGa('Klik', 'Begrepen neem me ByDeHand',
                                                 true)}>Begrepen</MaterialFactory>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    render = () => {
        return (
            <>
                <div className='onboardingBackground'/>
                {this.renderStep()}
            </>
        )
    };
};

AgendaOnboardingView.propTypes = {
    step: PropTypes.string.isRequired,
};