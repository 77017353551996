import React from 'react';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as Actions from '../../../../../redux/actions';

import HealthAnalysisPopupView from "./healthAnalysisPopupView";

export class HealthAnalysisPopupController extends React.Component {
    constructor(props) {
        super(props);
    }

    setPopupShown = (bool) => {
        if(this.props.healthState.explaningPopupShown !== bool)
        {
            this.props.actions.setExplaningPopupShown(bool);
        }
    };

    render = () => {
        if (this.props.healthState.explaningPopupShown) {
            return null;
        } else {
            return (
                <HealthAnalysisPopupView setPopupShown={this.setPopupShown} />
            );
        }
    }
}

const mapStateToProps = state => ({
    healthState: state.healthInsuranceAnalysis,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(HealthAnalysisPopupController);