import React from 'react';
import PropTypes from 'prop-types';
import utils, {isNotNull} from 'glob-common-js/lib/utils';

/**
 * A simple button that contains an icon and some text
 * Properties:
 * @param cssName: the outer style, if not present, "icon-button" is used.
 * @param id: set as id on the outer html element.
 * @param date: if present, used instead of the icon.
 * @param icon: the icon class name.
 * @param name: the name that is passed to the onclick function.
 * @param onClick: informed when the button is clicked.
 */
class IconButton extends React.Component {
    constructor(props) {
        super(props);
    }

    onClick = (evt) => {
        this.props.onClick(this.props.name, evt);
    };

    setIconType = () => {
        if (utils.isNotNull(this.props.date)) {
            return <div className='date'><span className='dateItem day'>{this.props.date.day}</span> <span
                className='dateItem month'>{this.props.date.month}</span></div>
        }
        else if (utils.isNotNull(this.props.icon)) {
            return <span className={"icon " + this.props.icon}/>;
        }
    };

    createStyle = () => {
        return (utils.isNotNull(this.props.cssName) ? this.props.cssName : 'icon-button ') + (utils.isNotNull(this.props.extraCssName) ? this.props.extraCssName : '');
    };

    render = () => {
        return (
            <div id={this.props.id} className={this.createStyle()} onClick={this.onClick}>
                {this.setIconType()}
                {isNotNull(this.props.text) && <h4>{this.props.text}</h4>}
            </div>
        );
    }
}

export default IconButton;

IconButton.propTypes = {
    cssName: PropTypes.string,
    extraCssName: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    date: PropTypes.object,
    icon: PropTypes.string,
    text: PropTypes.string
};