import './style/loginView.scss';

import React from 'react';
import PropTypes from 'prop-types';

import {isValidEmail} from 'glob-common-js/lib/utils';

import GenericLoader from "../../misc/genericLoader";
import MaterialFactory from "../../material/materialFactory";
import {materialTypes} from "../../material/materialTypes";
import BdhInput from "../../customControls/input/bdhInput";
import {ENTER_KEY} from "../../../misc/keyMap";
import BdhPasswordInputController from "../../customControls/input/password/bdhPasswordInputController";

export default class LoginView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            values: {
                email: '',
                password: '',
            },
            errors: {
                general: null,
                email: null,
                password: null,
            },
        };
        this._isMounted = false;
    }

    changeState = stateChange => {
        this._isMounted && this.setState(stateChange);
    };

    onSubmit = () => {
        let allowed_emails = [
            // René
            "renevdijk78@gmail.com",
            "renevdijk@hotmail.com",
            "renevandijk@bydehand.eu",
            //maarten
            "maarten.rutgers@icloud.com",
            "maarten.rutgers@planet.nl",
            "secretaris@dooyewaardstichting.nl",
            //leon
            "leonvanlange@gmail.com",
            "jeannetvanlange@gmail.com",
            // test en demo
            "pchhendrix@gmail.com",
            "wouterhendrix79@gmail.com",
            "demo@bydehand.eu",
            "mijnbydehand@gmail.com",
            //acceptatie
            "renevdijk+20220205@hotmail.com",
            "renevdijk+202003131@hotmail.com",
            //klanten
            "noortjejonkers@gmail.com",
            // Dave
            "davevanrijn@bydehand.eu"
        ];
        let email_allowed = this.state.values.email.startsWith('renevdijk') || allowed_emails.includes(this.state.values.email);
        // let email_allowed=false;
        // for (let i = 0; i < allowed_emails.length; i++) {
        //   //console.log('check email: ',allowed_emails[i]);
        //   if (allowed_emails[i]==this.state.values.email) {
        //     //console.log('email allowed: ',allowed_emails[i]);
        //     email_allowed=true;
        //   }
        // }
        // if (this.state.values.email.substring(0, 9) == 'renevdijk') {
        //     //console.log('left rene');
        //     email_allowed = true;
        // }
        if (email_allowed) {
            //console.log('start login');
            const values = Object.assign({}, this.state.values);
            if (this.valuesAreValid(values))
                this.props.onLogin(values, this.onSubmitFailed);
        }
    };

    onForgotPassword = () => {
        this.props.onForgotPassword(this.state.values.email);
    };

    onSubmitFailed = cause => {
        this.changeState({errors: {general: cause, email: null, password: null}});
    };

    onFieldFocus = () => {
        this.changeState({errors: {general: null, email: null, password: null}});
    };

    onChange = name => value => {
        let values = Object.assign({}, this.state.values);
        values[name] = value;
        this.changeState({values});
    };

    onKeyDown = evt => {
        evt.stopPropagation();
        if (evt.keyCode === ENTER_KEY) {
            this.onSubmit();
        }
    };

    valuesAreValid = values => {
        const emailValid = isValidEmail(values.email);
        const passwordValid = this.passwordIsValid(values.password);
        if (emailValid && passwordValid)
            return true;
        else if (!emailValid)
            this.changeState({errors: {general: null, email: 'Ongeldig e-mailadres', password: null}});
        else if (!passwordValid)
            this.changeState({errors: {general: null, email: null, password: 'Vul een wachtwoord in'}});
        return false;
    };

    passwordIsValid = password => {
        return isNotNull(password);
    };

    componentDidMount = () => {
        this._isMounted = true;
    };

    componentWillUnmount = () => {
        this._isMounted = false;
    };

    render = () => {
        const {values, errors} = this.state;
        return (
            <div className='loginView'>
                <GenericLoader active={this.props.loading} backgroundClassName='loginLoaderBackground'/>
                <div className='loginHeader'>
                    <label className='headerLabel'>Inloggen</label>
                </div>
                <form className='loginBody'>
                    <div className='registerLabelContainer'>
                        <label className='registerLabel' onClick={this.props.onNewAccount}>Heb je nog geen
                            account?</label>
                    </div>
                    <div className='locksContainer'>
                        <div className='lockContainer'>
                            <span className='common-icon-lock2 lock'/>
                            <label>Je gegevens worden nooit met derden gedeeld</label>
                        </div>
                        <div className='lockContainer'>
                            <span className='common-icon-lock2 lock'/>
                            <label>Beveiligde 256-bit SSL verbinding</label>
                        </div>
                    </div>
                    <label style={{color: 'red'}}>ByDeHand is gestopt. Je kunt niet meer inloggen.</label>
                    <label style={{color: 'red'}}>voor vragen: info@bydehand.eu</label>
                    <BdhInput variant='contained' label='E-mailadres' type='email' value={values.email}
                              containerClass='loginField' onKeyDown={this.onKeyDown}
                              inputContainerClass='loginInputContainer'
                              error={errors.email} autoComplete='email' fullWidth onChange={this.onChange('email')}
                              onFocus={this.onFieldFocus}/>
                    <BdhPasswordInputController variant='contained' value={values.password} label='Wachtwoord'
                                                autoComplete='current-password'
                                                error={errors.password || errors.general}
                                                inputContainerClass='loginInputContainer'
                                                fullWidth onFocus={this.onFieldFocus} onKeyDown={this.onKeyDown}
                                                onChange={this.onChange('password')}/>
                    <div className='forgotPasswordContainer'>
                        <label className='forgotPasswordLabel' onClick={this.onForgotPassword}>
                            Wachtwoord vergeten?
                        </label>
                    </div>
                    <MaterialFactory componentType={materialTypes.RAISED_BUTTON}
                                     onClick={this.onSubmit}>Inloggen</MaterialFactory>
                </form>
            </div>
        );
    };
}

LoginView.propTypes = {
    onLogin: PropTypes.func.isRequired,
    onNewAccount: PropTypes.func.isRequired,
    onForgotPassword: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};
