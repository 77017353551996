import moment from 'moment';

import {getCookie} from 'glob-common-js/lib/utils';
import {NOW} from "../misc/constants";

export const setAuthParameter = (name, value) => {
    switch (name) {
        case 'new':
            setNewParameter(value);
            return;
        case 'target':
            setTargetParameter(value);
            return;
        case 'action':
            setActionParameter(value);
            return;
        case 'action_code':
            setCodeParameter(value);
            return;
    }
};

export const removeAuthParameter = (name) => {
    if (window.localStorage) {
        window.localStorage.removeItem(name);
    } else {
        document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
    }
};

export const getAuthParameter = (name, deleteAfter = true) => {
    switch (name) {
        case 'new':
            return getNewParameter(deleteAfter);
        case 'target':
            return getTargetParameter(deleteAfter);
        case 'action':
            return getActionParameter(deleteAfter);
        case 'action_code':
            return getCodeParameter(deleteAfter);
    }
};

/*
 =========================
 New Parameter
 =========================
 */
const setNewParameter = isNew => {
    const ITEM_NAME = 'bdh_new';
    if (window.localStorage) {
        window.localStorage.setItem(ITEM_NAME, isNew + ':' + NOW().format());
    } else {
        let expires = NOW();
        expires.add(30, 'minutes');
        document.cookie = ITEM_NAME + '=' + isNew + '; max-age=3600; path=/';
    }
};

const getNewParameter = () => {
    const ITEM_NAME = 'bdh_new';
    if (window.localStorage) {
        let data = window.localStorage.getItem(ITEM_NAME);
        removeAuthParameter(ITEM_NAME);
        if (isNotNull(data)) {
            data = data.split(':');
            // Only return the parameter if it is still valid (within 60 minutes). Otherwise delete the parameter from
            // localStorage
            const isValid = Math.abs(moment(data[1]).diff(NOW(), 'minutes')) < 60;
            if (isValid) return data[0];
        }
    } else {
        const cookie = getCookie(ITEM_NAME);
        removeAuthParameter(ITEM_NAME);
        if (isNotNull(cookie)) return cookie;
    }
    return null;
};

/*
 =========================
 Target Parameter
 =========================
 */
const setTargetParameter = (target) => {
    const ITEM_NAME = 'bdh_target';
    if (window.localStorage) {
        window.localStorage.setItem(ITEM_NAME, target + ':' + NOW().format());
    } else {
        let expires = moment(NOW());
        expires.add(30, 'minutes');
        document.cookie = ITEM_NAME + '=' + target + '; max-age=1800; path=/';
    }
};

const getTargetParameter = () => {
    const ITEM_NAME = 'bdh_target';
    if (window.localStorage) {
        let data = window.localStorage.getItem(ITEM_NAME);
        removeAuthParameter(ITEM_NAME);
        if (isNotNull(data)) {
            data = data.split(':');
            // Only return the parameter if it is still valid (within 60 minutes). Otherwise delete the parameter from
            // localStorage
            const isValid = Math.abs(moment(data[1]).diff(NOW(), 'minutes')) < 60;
            if (isValid) return data[0];
        }
    } else {
        const cookie = getCookie(ITEM_NAME);
        removeAuthParameter(ITEM_NAME);
        if (isNotNull(cookie)) return cookie;
    }
    return null;
};

/*
 =========================
 Action Parameter
 =========================
 */
const setActionParameter = action => {
    const ITEM_NAME = 'bdh_action';
    if (window.localStorage) {
        window.localStorage.setItem(ITEM_NAME, action + ':' + NOW().format());
    } else {
        document.cookie = ITEM_NAME + '=' + action + '; max-age=1800; path=/';
    }
};

const getActionParameter = (deleteAfter = true) => {
    const ITEM_NAME = 'bdh_action';
    if (window.localStorage) {
        let data = window.localStorage.getItem(ITEM_NAME);
        if (deleteAfter)
            removeAuthParameter(ITEM_NAME);
        if (isNotNull(data)) {
            data = data.split(':');
            // Only return the parameter if it is still valid (within 60 minutes). Otherwise delete the parameter from
            // localStorage
            const isValid = Math.abs(moment(data[1]).diff(NOW(), 'minutes')) < 60;
            if (isValid) return data[0];
        }
    } else {
        const cookie = getCookie(ITEM_NAME);
        if (deleteAfter)
            removeAuthParameter(ITEM_NAME);
        if (isNotNull(cookie)) return cookie;
    }
    return null;
};

/*
 =========================
 Action code Parameter
 =========================
 */


const setCodeParameter = code => {
    const ITEM_NAME = 'bdh_activation_code';
    if (window.localStorage)
        window.localStorage.setItem(ITEM_NAME, code + ':' + NOW().format());
    else {
        document.cookie = ITEM_NAME + '=' + code + '; max-age=1800; path=/';
    }
};

const getCodeParameter = (deleteAfter = true) => {
    const ITEM_NAME = 'bdh_activation_code';
    if (window.localStorage) {
        let data = window.localStorage.getItem(ITEM_NAME);
        if (deleteAfter)
            removeAuthParameter(ITEM_NAME);
        if (isNotNull(data)) {
            data = data.split(':');
            // Only return the parameter if it is still valid (within 60 minutes). Otherwise delete the parameter from
            // localStorage
            const isValid = Math.abs(moment(data[1]).diff(NOW(), 'minutes')) < 60;
            if (isValid) return data[0];
        }
    } else {
        const cookie = getCookie(ITEM_NAME);
        if (deleteAfter)
            removeAuthParameter(ITEM_NAME);
        if (isNotNull(cookie)) return cookie;
    }
    return null;
};