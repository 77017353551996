import React from 'react';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as Actions from '../../redux/actions';

import {
    filterListDuplicates,
    isFunction,
    pathIsNotNull,
    stringContains,
    stringStartsWith,
} from 'glob-common-js/lib/utils';

import getCategoryType from 'BdhCommon/data/mappings/categoryMapping';
import getDossierType, {getById} from 'BdhCommon/data/mappings/dossierTypeMapping';
import {sendGaEvent} from "BdhCommon/js/ga";
import DossierContainer from './dossierContainer';
import {getAllDossiers} from "../../misc/requestSender";
import {addCustomEventListener, dispatchCustomEvent, removeCustomEventListener} from "../../misc/eventDispatcher";
import {getAllDosTypes} from "../../common/data/mappings/categoryMapping";
import {navigate, getPlatformVersion} from "../../misc/utils";
import {getOnboardingManager} from "../onboarding/manager/onboardingManagerHelper";
import {addDataEventListener, DATA_TYPES, EVENT_TYPES, removeAllDataEventListeners} from "../../misc/dataEvent";

export class DossierContainerController extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dossiers: [],
            dossiersLoaded: 0,
            fetching: true,
        };
        this.types = null;
        this.dossiers = [];
    }

    changeState = (stateChange) => {
        if (this._isMounted) {
            this.setState(stateChange);
        }
    };

    onDossierClick = (dossier, evt) => {
        if (this.props.fromUser) {
            sendGaEvent('Dossier toevoegen', 'Bestaand dossier click', dossier.type.label);
            this.dossierDetails(dossier);
        } else {
            const manager = getOnboardingManager('store');
            if (manager.isActive()) {
                manager.sendEvent('Klik', `Keuze dossier (${dossier.label})`);
                manager.removeAllHighlights().setNextStep(dossier.type.name);
            } else {
                if (typeof this.props.dossierPopupClick === 'function') {
                    this.props.dossierPopupClick(dossier, evt);
                } else {
                    this.addDossier(dossier);
                    sendGaEvent('Dossier toevoegen', 'Nieuw dossier click', dossier.label);
                }
            }
        }
    };

    onOnboardingActivate = () => {
        this.getDossiers();
    };

    addDossier = dossier => {
        dispatchCustomEvent('addDossier', dossier.type.name);
    };

    dossierDetails = dossier => {
        this.props.actions.setDossier(dossier);
        navigate('/dossier-details');
    };

    getDossiersFromCategories = category => {
        let dossierTypes = [];
        if (isNotNull(category)) {
            dossierTypes = getCategoryType(category).dosTypes;
        }
        return filterListDuplicates(dossierTypes);
    };

    fetchDossiersFromUser = (initial = false, category) => {
        let dossierTypeIds = this.getDossiersFromCategories(category).map(dossierType => (getDossierType(dossierType).id));
        const asInstance = true;
        getAllDossiers({
            name: 'fetchDossiers', asInstance, callback: (dossiers) => {
                if (asInstance)
                    this.dossiersInstanceFetchCallback(dossiers, dossierTypeIds, initial);
                else
                    this.dossiersFetchedCallback(dossiers, dossierTypeIds, initial);
            }
        });
    };

    dossiersFetchedCallback = (dossiers, dossierTypeIds, initial) => {
        dossiers = dossiers.map(dossier => {
            let typeId = dossier.type_id || dossier.type;
            if (!typeof typeId === 'string' && typeId.hasOwnProperty('id'))
                typeId = typeId.id;
            dossier.type = getById(typeId);
            dossier.label = dossier.name;
            return dossier;
        });

        // Remove dossiers which are not supposed to be shown
        dossiers = dossiers.filter(dossier => {
            if (dossier.type.config.isAnalysis) return false;
            if (isNull(dossier.external_data) || isNull(dossier.external_data.isDossier)) return true;
            return dossier.external_data.isDossier && dossier.type.name !== 'financial_overview'
        });
        if (isNotNull(dossierTypeIds)) {
            dossiers = dossiers.filter(dossier => dossierTypeIds.indexOf(dossier.type.id) > -1);
        }
        // Let the parent component know that there are dossiers to show.
        if (isFunction(this.props.updateCount) && initial) {
            this.props.updateCount(dossiers.length);
        }
        dossiers.sort((dossierA, dossierB) => {
            const labelA = this.getDossierLabelLowerCase(dossierA), labelB = this.getDossierLabelLowerCase(dossierB);
            const preferA = -1, preferB = 1, isEqual = 0;
            return labelA < labelB ? preferA : labelA > labelB ? preferB : isEqual;
        });
        this.dossiers = dossiers;
        this.filterDossiers();
        this.changeState({
            fetching: false,
        });
    };

    dossiersInstanceFetchCallback = (dossiers, dossierTypeIds, initial) => {
        const {updateCount} = this.props;

        dossiers = dossiers.filter(dossier => {
            if (dossier.type.config.isAnalysis) return false;
            if (isNull(dossier.externalData) || isNull(dossier.externalData.isDossier)) return true;
            return dossier.externalData.isDossier && dossier.type.name !== 'financial_overview';
        });

        if (isNotNull(dossierTypeIds))
            dossiers = dossiers.filter(dossier => dossierTypeIds.includes(dossier.type.id));

        if (isFunction(updateCount) && initial)
            updateCount(dossiers.length);

        dossiers.sort((dossierA, dossierB) => {
            const labelA = dossierA.name.toLowerCase(), labelB = dossierB.name.toLowerCase();
            const preferA = -1, preferB = 1, isEqual = 0;
            return labelA < labelB ? preferA : labelA > labelB ? preferB : isEqual;
        });

        this.dossiers = dossiers;
        this.filterDossiers();
        this.changeState({
            fetching: false,
        });
    };

    getDossierLabelLowerCase = dossier => {
        return pathIsNotNull(dossier, 'label') ? dossier.label.toLowerCase() : dossier.label;
    };

    fetchDossiersFromMapping = selectedCategory => {
        this.changeState({fetching: true});
        const manager = getOnboardingManager('store');
        let dossierTypes = [];
        if (isNotNull(selectedCategory)) {
            dossierTypes = getCategoryType(selectedCategory).dosTypes;
        } else if (manager.isActive()) {
            const priorityTypes = ['job', 'house', 'rental_house', 'energy', 'water', 'study', 'sport',
                'subscriptions', 'personal_hobby'];
            if (manager.shouldShowAllDossiers()) {

                // TODO Temporarily filter out car dossier since processing the licence plate doesn't work optimally
                dossierTypes = getAllDosTypes().filter(dossierType => dossierType !== 'car');

                dossierTypes.sort((typeA, typeB) => {
                    const preferA = -1, preferB = 1, isEqual = 0;
                    if (priorityTypes.includes(typeA) && !priorityTypes.includes(typeB)) return preferA;
                    if (!priorityTypes.includes(typeA) && priorityTypes.includes(typeB)) return preferB;
                    if (priorityTypes.includes(typeA) && priorityTypes.includes(typeB))
                        return priorityTypes.indexOf(typeA) - priorityTypes.indexOf(typeB);
                    return dossierTypes.indexOf(typeA) - dossierTypes.indexOf(typeB);
                })
            } else
                dossierTypes = priorityTypes;
        } else dossierTypes = getAllDosTypes();
        if (getPlatformVersion()==='lifeWill'){
          var dossiersLifeWill = ["personal_data","house", "rental_house","tax_year","job","subscriptions","car","cycle_transport",
          "misc_savings",'misc_insurance','miscellaneous_care','misc_gen'];
          dossierTypes = dossierTypes.filter(dossierType => dossiersLifeWill.includes(dossierType));
        }
        let dossiers = [];
        for (let i = 0; i < dossierTypes.length; i++) {
            let dosName = dossierTypes[i];
            let dossierType = getDossierType(dosName);
            if (!dossierType.config.exclude) {
                dossiers.push({
                    id: dossierType.id,
                    type: dossierType,
                    label: dossierType.label,
                });
            }
        }
        this.dossiers = dossiers;
        this.changeState({
            dossiers,
            fetching: false,
        });
    };

    filterDossiers = () => {
        let query = this.props.filter;
        let dossiers = this.dossiers;
        if (isNotNull(query)) {
            let startsWith = [], contains = [];
            for (let i = 0; i < dossiers.length; i++) {
                let dossier = dossiers[i];
                let label = isNotNull(dossier.label) ? dossier.label : '';
                if (stringStartsWith(label, query)) startsWith.push(dossier);
                else if (stringContains(label, query)) contains.push(dossier);
            }
            this.changeState({dossiers: startsWith.concat(contains)});
        } else {
            this.changeState({dossiers: this.dossiers});
        }
    };

    getDossiers = (initial) => {
        const {selectedCategory, fromUser} = this.props;
        const manager = getOnboardingManager('store');
        let category = manager.isActive() ? 'all' : selectedCategory;
        const isFromUser = !manager.isActive() && fromUser;
        if (category === 'all')
            isFromUser ? this.fetchDossiersFromUser(initial) :
                this.fetchDossiersFromMapping();
        else
            isFromUser ? this.fetchDossiersFromUser(initial, category) :
                this.fetchDossiersFromMapping(category);
    };

    updatePlatform = () => {
        this.getDossiers(true);
    };

    dataEvent = () => {
        if (this.props.fromUser)
            this.updatePlatform();
    };

    componentDidUpdate = prevProps => {
        if (JSON.stringify(prevProps.selectedCategory) !== JSON.stringify(this.props.selectedCategory))
            this.getDossiers();
        else if (prevProps.filter !== this.props.filter) {
            this.filterDossiers();
        }
    };

    componentDidMount = () => {
        this._isMounted = true;
        addCustomEventListener('updatePlatform', this.updatePlatform);
        addCustomEventListener('onboardingReloadDossiers', this.onOnboardingActivate);
        addDataEventListener(DATA_TYPES.DOSSIER, [EVENT_TYPES.CREATE, EVENT_TYPES.UPDATE,
            EVENT_TYPES.DELETE], 'dossierContainerController', this.dataEvent);
        setTimeout(this.updatePlatform, 100);
    };

    componentWillUnmount = () => {
        this._isMounted = false;
        removeCustomEventListener('updatePlatform', this.updatePlatform);
        removeCustomEventListener('onboardingReloadDossiers', this.getDossiers);
        removeAllDataEventListeners('dossierContainerController', this.dataEvent);
    };

    render = () => {
        const manager = getOnboardingManager('store');
        const fromUser = !manager.isActive() && this.props.fromUser;
        return this.state.fetching || this.state.dossiers.length === 0 ? null :
            <DossierContainer fromUser={fromUser} dossiers={this.state.dossiers}
                              onDossierClick={this.onDossierClick}/>
    };
}

DossierContainerController.propTypes = {
    fromUser: PropTypes.bool.isRequired,
    selectedCategory: PropTypes.string.isRequired,
    updateCount: PropTypes.func,
    selectedModule: PropTypes.string,
    filter: PropTypes.string,
};

DossierContainerController.defaultProps = {
    selectedModule: null,
};

const mapStateToProps = state => ({
    moduleState: state.module,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DossierContainerController));
