import './style/startHighlights.scss';

import React from 'react';

import {remToPx} from 'glob-common-js/lib/utils';

import {addCustomEventListener, dispatchCustomEvent, removeCustomEventListener} from "../../../misc/eventDispatcher";
import {sendGaEvent} from "../../../common/js/ga";
import getDossierType from "../../../common/data/mappings/dossierTypeMapping";
import MaterialFactory from "../../material/materialFactory";
import {materialTypes} from "../../material/materialTypes";
import {navigate} from "../../../misc/utils";

export default class StartHighlights extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'default',
            active: false,
            step: 0,
        };
        this._isMounted = false;
        this.params = {
            dossiersAdded: [],
            financialAdded: false,
            agendaAdded: false,
            checked: false,
        };
    }

    changeState = stateChange => {
        this._isMounted && this.setState(stateChange);
    };

    activate = () => {
        const {dossiersAdded, financialAdded, checked, agendaAdded} = this.params;
        if (isNotNull(dossiersAdded) || financialAdded || checked || agendaAdded) {
            this.changeState({active: true, step: 0});
            window.highlightsActive = true;
        } else this.deactivate(true);
    };

    setParams = params => {
        this.params = params;
        if (isNotNull(params.type))
            this.changeState({type: params.type});
    };

    deactivate = (openTips = false) => {
        this.changeState({active: false, step: 0});
        window.highlightsActive = false;
        if (openTips && this.state.type !== 'health')
            dispatchCustomEvent('openUploadTips');
    };

    onNextStep = (label) => {
        sendGaEvent('Start highlights', 'Klik ok', label);
        this.removeHighlights();
        let lastStep = 0;
        if (isNotNull(this.params.dossiersAdded)) lastStep++;
        if (this.params.financialAdded) lastStep++;
        if (this.params.checked) lastStep++;
        if (this.params.agendaAdded) lastStep++;
        let step = this.state.step + 1;
        if (step >= lastStep) {
            this.deactivate(true);
        } else {
            this.changeState({step});
        }
    };

    highlightDossiers = () => {
        let dossiersAdded = this.params.dossiersAdded;
        if (isNotNull(dossiersAdded)) {
            let box = document.getElementById('dashboardBoxDossiers');
            if (isNotNull(box) && !box.classList.contains('startHighlight')) {
                box.classList.add('startHighlight');
                this.positionDossierHighlight();
            }
        }
    };

    highlightChecklist = () => {
        let checked = this.params.checked;
        if (checked) {
            let checklist = document.querySelector('div.box.decide');
            if (isNotNull(checklist) && !checklist.classList.contains('startHighlight')) {
                checklist.classList.add('startHighlight');
                this.positionChecklistHighlight();
            }
        }
    };

    highlightFinancial = () => {
        let financialAdded = this.params.financialAdded;
        if (financialAdded) {
            let financial = document.querySelector('div.box.financialBox');
            if (isNotNull(financial) && !financial.classList.contains('startHighlight')) {
                financial.classList.add('startHighlight');
                this.positionFinancialHighlight();
            }
        }
    };

    highlightAgenda = () => {
        const agendaAdded = this.params.agendaAdded;
        if (agendaAdded) {
            const agendaBox = document.querySelector('div.box.agendaView');
            if (isNotNull(agendaBox) && !agendaBox.classList.contains('startHighlight')) {
                agendaBox.classList.add('startHighlight');
                this.positionAgendaHighlight();
            }
        }
    };

    positionDossierHighlight = (dossiersAdded) => {
        dossiersAdded = isNotNull(dossiersAdded) ? dossiersAdded : isNotNull(this.params.dossiersAdded);
        let text = document.getElementById('dossierHighlight');
        if (isNotNull(text)) {
            if (dossiersAdded) {
                if (!text.classList.contains('active'))
                    text.classList.add('active');
                let box = document.getElementById('dashboardBoxDossiers');
                let rect = box.getBoundingClientRect();
                let top = rect.y;
                if (this.state.type === 'health')
                    top = top - remToPx(4);
                text.style.top = top + 'px';
                text.style.left = (rect.x + rect.width - remToPx(13)) + 'px';
            } else text.classList.remove('active')
        }

    };

    positionChecklistHighlight = (checked) => {
        checked = isNotNull(checked) ? checked : this.params.checked;
        let text = document.getElementById('checklistHighlight');
        if (isNotNull(text)) {
            if (checked) {
                if (!text.classList.contains('active'))
                    text.classList.add('active');
                let checklist = document.querySelector('div.box.decide');
                let rect = checklist.getBoundingClientRect();
                let textRect = text.getBoundingClientRect();
                text.style.top = (rect.top - textRect.height - remToPx(6)) + 'px';
                text.style.left = (rect.left - remToPx(14) + (rect.width / 2) - (textRect.width / 2)) + 'px';
            } else text.classList.remove('active')
        }
    };

    positionFinancialHighlight = (financialAdded) => {
        financialAdded = isNotNull(financialAdded) ? financialAdded : this.params.financialAdded;
        let text = document.getElementById('financialHighlight');
        if (isNotNull(text)) {
            if (financialAdded) {
                if (!text.classList.contains('active'))
                    text.classList.add('active');
                let financial = document.querySelector('div.box.financialBox');
                let rect = financial.getBoundingClientRect();
                let textRect = text.getBoundingClientRect();
                text.style.left = ((rect.x - textRect.width) - remToPx(17)) + 'px';
                text.style.top = rect.y + 'px';
            } else text.classList.remove('active')

        }
    };

    positionAgendaHighlight = (agendaAdded) => {
        agendaAdded = isNotNull(agendaAdded) ? agendaAdded : this.params.agendaAdded;
        const text = document.getElementById('agendaHighlight');
        if (isNotNull(text)) {
            if (agendaAdded) {
                if (!text.classList.contains('active'))
                    text.classList.add('active');
                const agendaBox = document.querySelector('div.box.agendaView');
                if (isNotNull(agendaBox)) {
                    const rect = agendaBox.getBoundingClientRect();
                    const textRect = text.getBoundingClientRect();
                    text.style.left = (rect.x - (textRect.width / 2)) + 'px';
                    text.style.top = ((rect.y - textRect.height) - remToPx(7)) + 'px';
                }
            } else text.classList.remove('active');
        }
    };

    removeHighlights = () => {
        this.removeDossierHighlights();
        this.removeChecklistHighlight();
        this.removeFinancialHighlight();
        this.removeAgendaHighlight();
        this.positionDossierHighlight(false);
        this.positionChecklistHighlight(false);
        this.positionFinancialHighlight(false);
        this.positionAgendaHighlight(false);
    };

    removeDossierHighlights = () => {
        let box = document.getElementById('dashboardBoxDossiers');
        if (isNotNull(box))
            box.classList.remove('startHighlight');
    };

    removeChecklistHighlight = () => {
        let checklist = document.querySelector('div.box.decide');
        if (isNotNull(checklist))
            checklist.classList.remove('startHighlight');
    };

    removeFinancialHighlight = () => {
        let financial = document.querySelector('div.box.financialBox');
        if (isNotNull(financial))
            financial.classList.remove('startHighlight');
    };

    removeAgendaHighlight = () => {
        const agendaBox = document.querySelector('div.box.agendaView');
        if (isNotNull(agendaBox)) agendaBox.classList.remove('startHighlight');
    };

    getDossiersText = () => {
        const type = this.state.type;
        if (type === 'health')
            return 'We hebben een zorgverzekeringsdossier voor je aangemaakt. Hierin vind je alle informatie over je ' +
                'verzekering (eigen risico, dekking, etc.), je contactgegevens van je verzekeraar zodat je de ' +
                'verzekering kunt afsluiten en je bijbehorende documenten (polisvoorwaarden) zodat je compleet bent.';
        let dossiersAdded = this.params.dossiersAdded;
        let text = null;
        if (isNotNull(dossiersAdded)) {
            text = 'We hebben voor je ';
            for (let i = 0; i < dossiersAdded.length; i++) {
                let dossierName = dossiersAdded[i];
                text += getDossierType(dossierName).label.toLowerCase();
                if (i < dossiersAdded.length - 1) {
                    if (i < dossiersAdded.length - 2) {
                        text += ', ';
                    } else {
                        text += ' en ';
                    }
                }
            }
            text += ' een dossier aangemaakt. Klik op een dossier en vul deze met je documenten en gegevens.';
        }
        return text;
    };

    getAgendaText = () => {
        const type = this.state.type;
        if (type === 'health')
            return 'We hebben alvast een agenda item toegevoegd voor 1 december 2020, zodat je niet vergeet om ook ' +
                'volgend jaar de juiste zorgverzekering te kiezen!';
        return 'Agenda tekst';
    };

    getFinancialText = () => {
        const type = this.state.type;
        if (type === 'health')
            return 'We hebben de maandelijkse zorgkosten toegevoegd in je financiële overzicht voor januari. Door ' +
                'je (vaste) inkomsten en uitgaven toe te voegen krijg je snel zicht in je maandelijkse balans.';
        return 'We hebben een begin gemaakt met jouw maandelijkse inkomsten. Vul deze aan en voeg je uitgaven ook ' +
            'toe voor een compleet overzicht.';
    };

    getHighlightTitle = highlightName => {
        const {type} = this.params;
        switch (highlightName) {
            case 'financial':
                return type === 'health' ? 'Maandelijkse zorgkosten' : 'Financieel';
            case 'dossier':
                return type === 'health' ? 'Dossier zorgverzekering' : 'Dossiers';
            case 'agenda':
                return type === 'health' ? 'Zorgverzekeringsanalyse 2020' : 'Agenda';
        }
    };

    renderOkButton = (label, onClick, className = '') => {
        return <MaterialFactory componentType={materialTypes.RAISED_BUTTON} onClick={onClick} className={className}>
            {label}
        </MaterialFactory>;
    };

    componentDidMount = () => {
        this._isMounted = true;
        addCustomEventListener('startHighlight', this.activate);
        addCustomEventListener('setHighlightParams', this.setParams)
    };

    componentDidUpdate = () => {
        let step = this.state.step;
        let steps = [];
        if (isNotNull(this.params.dossiersAdded)) steps.push(this.highlightDossiers);
        if (this.params.financialAdded) steps.push(this.highlightFinancial);
        if (this.params.checked) steps.push(this.highlightChecklist);
        if (this.params.agendaAdded) steps.push(this.highlightAgenda);
        if (step < steps.length && this.state.active)
            steps[step]();
    };

    componentWillUnmount = () => {
        removeCustomEventListener('startHighlight', this.activate);
        removeCustomEventListener('setHighlightParams', this.setParams);
        this.removeHighlights();
        this.deactivate();
        this._isMounted = false;
    };

    render = () => {
        return (
            <>
                {this.state.active &&
                <div className='highlightBackground'/>}
                <div id='financialHighlight'
                     className={'highlightText' + (this.state.type === 'health' ? ' wider' : '')}>
                    <div className='highlightTitleContainer'>
                        <label>{this.getHighlightTitle('financial')}</label>
                    </div>
                    <p className='highlightMessage'>
                        {this.getFinancialText()}
                    </p>
                    {this.renderOkButton('Ok', () => {
                        this.onNextStep('Financieel')
                    }, 'nextButton')}
                </div>
                <div id='checklistHighlight' className='highlightText'>
                    <div className='highlightTitleContainer'>
                        <label>Checklist</label>
                    </div>
                    <p className='highlightMessage'>
                        We hebben alvast een aantal punten van je checklists afgevinkt. Klik op een checklist en zorg
                        dat je alles hebt gedaan!
                    </p>
                    {this.renderOkButton('Ok', () => {
                        this.onNextStep('Checklist')
                    }, 'nextButton')}
                </div>
                <div id='dossierHighlight' className={'highlightText' + (this.state.type === 'health' ? ' wider' : '')}>
                    <div className='highlightTitleContainer'>
                        <label>{this.getHighlightTitle('dossier')}</label>
                    </div>
                    <p className='highlightMessage'>
                        {this.getDossiersText()}
                    </p>
                    {this.renderOkButton('Ok', () => {
                        this.onNextStep('Dossiers')
                    }, 'nextButton')}
                </div>
                <div id='agendaHighlight' className='highlightText wider'>
                    <div className='highlightTitleContainer'>
                        <label>{this.getHighlightTitle('agenda')}</label>
                    </div>
                    <p className='highlightMessage'>
                        {this.getAgendaText()}
                    </p>
                    <div className='moreButtonsContainer'>
                        {this.renderOkButton('Naar dashboard', () => {
                            this.removeHighlights();
                            this.deactivate(false);
                        }, 'nextButton')}
                        {this.renderOkButton('Naar zorgdossier', () => {
                            this.deactivate(false);
                            navigate('/dossier-overzicht');
                        }, 'nextButton')}
                    </div>
                </div>
            </>
        );
    }
}
