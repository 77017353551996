import './priceCompare.scss';
import React from 'react';
import PropTypes from 'prop-types';
import {toMoney} from 'glob-common-js/lib/utils';


export default class PriceCompareView extends React.Component {
    constructor(props) {
        super(props);

        let allPrices = this.props.prices;
        let cheapest = Math.min(...allPrices);
        let mostExpensive = Math.max(...allPrices);
        let price = parseFloat(this.props.myPrice);
        let cheaperOptions = 0;
        let moreExpensiveOptions = 0;
        for (let i = 0; i < allPrices.length; i++) {
            (allPrices[i] < price) ? cheaperOptions++ : moreExpensiveOptions++
        }
        let saveMoney = price - cheapest;

        this.json = {
            prices: allPrices,
            start: cheapest,
            end: mostExpensive,
            amount: 9,
            better: {
                lowest: cheapest,
                cheaper: cheaperOptions
            },
            current: {
                price: price,
                saveMoney: saveMoney
            },
            worse: {
                highest: mostExpensive,
                moreExpensive: moreExpensiveOptions
            },
            graph: {}
        };

        let graphWidth = 100;
        let currentPriceWidth = 10;
        let insurancePopup = 27;

        if (window.matchMedia("(max-width: 375px)").matches) {
            this.json.amount -= 1;
            currentPriceWidth = 20;
        }

        let compare = this.json;
        if (compare.better.lowest > compare.current.price) {
            compare.better.lowest = compare.current.price;
            compare.start = compare.current.price;
        }
        if (compare.worse.highest < compare.current.price) {
            compare.worse.highest = compare.current.price;
            compare.end = compare.current.price;
        }

        let gapSize = (compare.end - compare.start) / (compare.amount - 3);
        let tdWidth = graphWidth / (compare.amount - 1);

        let betterStart = ((compare.better.lowest - (compare.start - gapSize)) / gapSize) * tdWidth;
        let betterDiffrence = compare.current.price - compare.better.lowest;
        let betterLength = ((betterDiffrence / gapSize) * tdWidth) - (currentPriceWidth / 2);

        let worseDifference = compare.worse.highest - compare.current.price;
        let worseLength = ((worseDifference / gapSize) * tdWidth) - (currentPriceWidth / 2);

        let popupOffset = ((betterStart + betterLength + (currentPriceWidth / 2)) - (insurancePopup / 2));

        if (worseLength > (graphWidth - (currentPriceWidth + tdWidth + tdWidth))) {
            betterStart = (graphWidth - (currentPriceWidth + tdWidth + worseLength)) + 1;
            popupOffset += 1;
            worseLength -= 1;
        }

        this.json.graph = {
            gapSize: gapSize,
            popupOffset: popupOffset,
            betterStart: betterStart,
            tdWidth: tdWidth,
            rulerAmount: 9,
            rulerStart: cheapest,
            betterLength: betterLength,
            insurancePopup: insurancePopup,
            worseLength: worseLength,
            currentPriceWidth: currentPriceWidth,
            currentPrice: price
        };
    }


    generateRuler = () => {
        const tdValue = () => {
            let gap = this.json.graph.gapSize;
            let value = this.json.graph.rulerStart - gap;
            let td = [];
            for (let i = 0; i < this.json.graph.rulerAmount; i++) {
                let price = Math.round(value);
                if (price < 0) {
                    price = 0;
                }
                td.push(
                    <td key={i} style={{
                        width: this.json.graph.tdWidth + "%",
                        left: -(this.json.graph.tdWidth / 2) +  "%"
                    }}>{toMoney(price)}</td>
                );
                value = value + gap;
            }
            return td;
        };

        const tdStripe = () => {
            let td = [];
            for (let i = 0; i < this.json.graph.rulerAmount; i++) {
                td.push(
                    <td key={i}>|</td>
                )
            }
            return td;
        };

        return (
            <table>
                <tbody>
                <tr className="firstRow">
                    {tdValue()}
                </tr>
                <tr className="lastRow">
                    {tdStripe()}
                </tr>
                </tbody>
            </table>
        )
    };


    render = () => {
        return (
            <div className="priceCompareContainer">
                {/*Your insurance popup*/}
                <div className="insurerPopup"
                     style={{
                         marginLeft: this.json.graph.popupOffset + "%",
                         width: this.json.graph.insurancePopup + "%"
                     }}>
                    <div className="insurerPopupBody">
                        <h1>Jouw {this.props.compareName}</h1>
                    </div>
                    <div>
                        <i className="insurerPopupArrow"/>
                    </div>
                </div>

                {/*Graph slider*/}
                <div className="sliderBody">
                    {this.generateRuler()}
                    <div className="insuranceSlider">
                        <div className="betterInsurance"
                             style={{
                                 marginLeft: this.json.graph.betterStart + "%",
                                 width: this.json.graph.betterLength + "%"
                             }}/>
                        <div className="currentInsurance" style={{width: this.json.graph.currentPriceWidth + "%"}}>
                            <p className="currentInsurancePrice">{toMoney(this.json.graph.currentPrice)}</p>
                        </div>
                        <div className="worseInsurance" style={{width: this.json.graph.worseLength + "%"}}/>
                    </div>
                </div>

                {/*Cheaper and more expensive amounts*/}
                <div className="insurancesAmount">
                    <div className="insurancesBetter">
                        <i className="insurancesBetterArrow"/>
                        <p>Er zijn {this.json.better.cheaper} goedkopere opties. {this.props.betterText}</p>
                    </div>
                    <div className="insurancesWorse">
                        <i className="insurancesWorseArrow"/>
                        <p>Er zijn {this.json.worse.moreExpensive} duurdere opties. {this.props.worseText}</p>
                    </div>
                </div>

                {/*piggybank picture with saving text*/}
                <div className="insurancePiggyBank">
                    <div className='piggyBankTextContainer'>
                        <p>Bespaar {toMoney(this.json.current.saveMoney)} als je direct overstapt naar de goedkoopste optie {this.props.pigText}</p>
                    </div>
                </div>
            </div>
        )
    }
}

PriceCompareView.propTypes = {
    prices: PropTypes.array.isRequired,
    myPrice: PropTypes.number.isRequired,
    compareName: PropTypes.string
};

