import './style/securityPopup.scss';

import React from 'react';
import PropTypes from 'prop-types';

import {isFunction} from 'glob-common-js/lib/utils';
import {addCustomEventListener, removeCustomEventListener} from "../../misc/eventDispatcher";
import StateComponent from "./stateComponent";

export default class SecurityPopup extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
        };
        this.shouldActivate = false;
    }

    activate = (force = false) => {
        if (this.shouldActivate || force) {
            this.changeState({active: true});
            document.body.classList.add('noScroll');
        }
    };

    deactivate = () => {
        this.changeState({active: false});
        document.body.classList.remove('noScroll');
        if (isFunction(this.props.callback)) {
            this.props.callback();
        }
    };

    setShouldActivate = () => {
        let user = this.props.user;
        this.shouldActivate = isNotNull(user) && (isNull(user.external_data) ||
            isNull(user.external_data.targetGroup));
    };

    renderBodyColumn = (icon, title, text) => (
        <div className='securityColumn'>
            <span className={icon}/>
            <label className='columnTitle'>{title}</label>
            <p className='columnText'>{text}</p>
        </div>
    );

    componentDidMount = () => {
        this.mount();
        this.setShouldActivate();
        addCustomEventListener('openSecurity', this.activate);
    };

    componentDidUpdate = () => {
        this.setShouldActivate();
    };

    componentWillUnmount = () => {
        this.unMount();
        removeCustomEventListener('openSecurity', this.activate);
    };

    render = () => {
        return (
            <>
                <div className={'blackSecurityBackground' + (this.state.active ? ' active' : '')}/>
                <div ref={refName => {
                    this.popup = refName
                }} className={'securityPopup' + (this.state.active ? ' active' : '')}>
                    <div className='securityHeader'>
                        <h3 className='securityTitle'>Veiligheid!</h3>
                        <p className='securityHeaderPar'>
                            Met ByDeHand sla je al je belangrijke documenten veilig en geordend op in een digitale
                            kluis. De
                            veiligheid van deze kluis heeft daarom voor ons de allerhoogste prioriteit!
                        </p>
                    </div>
                    <div className='securityBody'>
                        {this.renderBodyColumn('common-icon-shield2', 'Dataprivacy', 'ByDeHand maakt gebruik van ' +
                            'veiligheidsfeatures waarmee je data beschermd wordt, zoals het ISO 27001 Information Security ' +
                            'Management Protocol. Daarnaast zijn we ook HIPAA conform.')}
                        {this.renderBodyColumn('common-icon-lock2', 'Opslagveiligheid', 'De cloud van ByDeHand werkt samen met ' +
                            'Amazon Web Services, bekend om het gebruiken van de beste dataveiligheidsmaatregelen en vertrouwd ' +
                            'door bedrijven wereldwijd.')}
                        {this.renderBodyColumn('common-icon-user3', 'Accountveiligheid', 'Gebruikers hebben toegang tot het ' +
                            'ByDeHand platform via single sign-on (SSO) of multi-factor authentication (MFA), wat leidt tot ' +
                            'complete discretie over toegang binnen het bedrijf.')}
                    </div>
                    <div className='securityFooter'>
                        <button className='securityExit' onClick={this.deactivate}>Begrepen!</button>
                    </div>
                </div>
            </>
        );
    }
}

SecurityPopup
    .propTypes = {
    user: PropTypes.object,
    callback: PropTypes.func,
};