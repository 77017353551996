import './style/browserSupport.scss';

import React from 'react';
import {browserIsSupported} from "../../misc/utils";

export default class BrowserSupport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            detailsOpen: false,
        };
        this._isMounted = false;
    }

    changeState = stateChange => {
        this._isMounted && this.setState(stateChange);
    };

    onClose = () => {
        this.changeState({isOpen: false});
    };

    onBrowserClick = (url) => {
        window.externalLink(url);
    };

    onOpenDetails = () => {
        this.changeState({detailsOpen: true});
    };

    renderBrowserContainer = (url, icon, name) => (
        <div className='browserLink'
             onClick={() => {
                 this.onBrowserClick(url);
             }}>
            <span className={'common-icon-' + icon}/>
            <label className='browserName'>{name}</label>
        </div>
    );

    componentDidMount = () => {
        this._isMounted = true;
        let isSupported = browserIsSupported();
        if (!isSupported)
            this.changeState({isOpen: true});
    };

    componentWillUnmount = () => {
        this._isMounted = false;
    };

    render = () => {
        if (this.state.isOpen)
            return (
                <div className={'browserSupport' + (this.state.detailsOpen ? ' full' : '')}>
                    <div className='messageContainer'>
                        <label className='supportLabel'>Het ByDeHand platform is niet geoptimaliseerd voor
                            de browser(versie) die je gebruikt. Het is mogelijk dat bepaalde onderdelen niet
                            (goed) werken.</label>
                        <div className='linksContainer'>
                            <span className='hideBanner' onClick={this.onClose}>Begrepen</span>
                            <span className='supportInfo'
                                  onClick={this.onOpenDetails}>Meer informatie</span>
                            <span className='hideBanner' onClick={this.onClose}>x</span>
                        </div>
                        <p id='agentContainer'/>
                    </div>
                    <div className='browserSupportDescription'>
                        <p>
                            Om zeker te zijn van de beste ervaring op het ByDeHand platform raden we aan om
                            gebruik te maken van de nieuwste versies van één van onderstaande browsers.
                            Klik hieronder op een browser om naar de website van de browser te gaan.
                        </p>
                        <div className='browsersContainer'>
                            {this.renderBrowserContainer('//google.nl/chrome/index.html', 'chrome', 'Chrome')}
                            {this.renderBrowserContainer('//mozilla.org/nl/firefox/new/', 'firefox', 'FireFox')}
                            {this.renderBrowserContainer('//support.apple.com/nl_NL/downloads/safari', 'safari', 'Safari')}
                            {this.renderBrowserContainer('//opera.com/nl', 'opera', 'Opera')}
                        </div>
                    </div>
                </div>
            );
        return null;
    }
}