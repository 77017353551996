import './style/subscriptionDetails.scss';

import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import {isFunction} from 'glob-common-js/lib/utils';

import {timeFormats} from "../../common/components/datePicker";

export class SubscriptionDetails extends React.Component {
    constructor(props) {
        super(props);
    }

    renderSubDetails = (subscriptionDetails) => {
        if (isNotNull(subscriptionDetails)) {
            const {type, lastPaid, period, createdAt, status} = subscriptionDetails;
            return (
                <>
                    {this.renderSubscriptionData(period, type, lastPaid, status)}
                    <div className='detailGroup'>
                        <label className='groupLabel'>Account gemaakt op</label>
                        <label className='groupValue'>{moment(createdAt, timeFormats).format('DD MMMM YYYY')}</label>
                    </div>
                </>
            )
        }
        return null;
    };

    isCancelable = (type, period) => {
        if (type === 'pay') return true;
        if (pathIsNotNull(period, 'details')) {
            return ['free_period_first', 'paid'].includes(period.details);
        }
        return false;
    };

    renderSubscriptionData = (period, type, lastPaid, status) => {
        if (isNotNull(period)) {
            const detailLabels = {
                free_period_first: 'Gratis proefperiode',
                free_period_birthday: 'Jongeren account',
                free_klantpanel: 'Deelnemer klantpanel',
                free_historic: 'Gratis account',
                free_action_code: 'Actiecode account',
                default: 'Abonnement ByDeHand \u20ac 2,50 p/m',
            };
            const statusLabel = status === 'CANCELED' ? ' (opgezegd)' : '';
            const detail = period.details, detailLabel = (detailLabels[detail] || detailLabels.default) + statusLabel;
            const start = period.start,
                startLabel = isNotNull(start) ? moment(start, timeFormats).format('DD MMMM YYYY') : null;
            const end = period.end,
                endLabel = isNotNull(end) ? moment(end, timeFormats).format('DD MMMM YYYY') : null;
            return (
                <>
                    <div className='detailGroup nm'>
                        <label className='groupLabel'>Abonnement</label>
                        <label className='groupValue'>{detailLabel}</label>
                    </div>
                    {isNotNull(startLabel) && this.renderSubscriptionDetail('Gestart op: ' + startLabel)}
                    {isNotNull(endLabel) && this.renderSubscriptionDetail('Eindigt na: ' + endLabel)}
                    {isNotNull(lastPaid) && this.renderSubscriptionDetail('Laatste betaling: ' +
                        moment(lastPaid, timeFormats).format('DD MMMM YYYY'))}
                </>
            )
        }
        return null;
    };

    renderSubscriptionDetail = label => (
        <div className='subscriptionDetail'>
            <label>{label}</label>
        </div>
    );

    createSubscriptionDetails = () => {
        const user = this.props.userState.user, subscriptionData = this.props.userState.subscription;
        if (areNotNull(user, subscriptionData)) {
            const {subscription, payment_info, status} = subscriptionData;
            if (isNotNull(subscription)) {
                const {type, period} = subscription;
                let lastPaid = null;
                const isFreePeriod = pathIsNotNull(period, 'details') && period.details === 'free_period_first';
                if (type === 'pay' && !isFreePeriod && pathIsNotNull(payment_info, 'payment.last.date')) {
                    lastPaid = payment_info.payment.last.date;
                    period.end = null;
                }

                return {
                    status,
                    type,
                    lastPaid,
                    period,
                    createdAt: user.created_at,
                };
            }
        }
        return null;
    };

    render = () => {
        const subscriptionDetails = this.createSubscriptionDetails();
        if (isNotNull(subscriptionDetails))
            return (
                <div className='subscriptionDetails'>
                    {isFunction(this.props.onStopSubscription) &&
                    this.isCancelable(subscriptionDetails.type, subscriptionDetails.period) &&
                    <span className='stopSub' onClick={this.props.onStopSubscription}>Opzeggen?</span>}
                    {this.renderSubDetails(subscriptionDetails)}
                </div>
            );
        return null;
    }
}

SubscriptionDetails.propTypes = {
    onStopSubscription: PropTypes.func,
};

const mapStateToProps = state => ({
    userState: state.user,
});

export default connect(mapStateToProps)(SubscriptionDetails);