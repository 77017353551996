import './style/dossierOverview.scss';

import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

import {deviceIsMobile} from 'glob-common-js/lib/utils';

import DossierContainerController from './dossierContainerController';
import MaterialFactory from "../material/materialFactory";
import {materialTypes} from "../material/materialTypes";
import getCategoryType from "../../common/data/mappings/categoryMapping";
import {navigate, getPlatformVersion} from "../../misc/utils";
import {getOnboardingManager} from "../onboarding/manager/onboardingManagerHelper";
import BdhSearchInput from "../customControls/input/bdhSearchInput";

const styles = theme => ({
    selectRoot: {
        width: '12rem',
        '@media (max-width: 599px)': {
            marginRight: theme.spacing.unit * 2,
            marginBottom: theme.spacing.unit,
        }
    },
    menuRoot: {
        maxHeight: '20rem',
    }
});

export class DossierOverviewView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userDossiers: 0,
            searchQuery: '',
        };
    }

    changeState = (stateChange) => {
        if (this._isMounted) {
            this.setState(stateChange);
        }
    };

    onSelectMode = mode => () => {
        this.props.selectMode(mode);
    };

    onActivateOnboarding = () => {
        this.forceUpdate();
    };

    updateDossierCount = count => {
        this.changeState({
            userDossiers: count,
        })
    };

    onSearch = value => {
        this.changeState({searchQuery: value});
    };

    onClearSearch = () => {
        this.onSearch('');
    };

    renderCategoryLabel = category => (
        <div className='categoryLabelContainer'>
            <span className={'categoryIcon ' + getCategoryType(category.name).headerIcon + (
                this.props.selectedCategory === category.name ? ' selected' : '')}/>
            <label>{category.label}</label>
        </div>
    );

    renderNavigationHeader = () => {
      if (getPlatformVersion()==='lifeWill'){
        return (
            <MaterialFactory componentType={materialTypes.RAISED_BUTTON} onClick={() => {
                navigate('/dashboard', true)
              }}><ChevronLeft/>Naar dashboard</MaterialFactory>
        );
      } else {
        return (
            <MaterialFactory componentType={materialTypes.RAISED_BUTTON} onClick={() => {
                navigate('/overzicht', true)
              }}><ChevronLeft/>Naar overzicht</MaterialFactory>
        );
      }
    }


    componentDidMount = () => {
        this._isMounted = true;
        getOnboardingManager('store').addActivateListener('dossierOverviewView', this.onActivateOnboarding);
    };

    componentWillUnmount = () => {
        this._isMounted = false;
        getOnboardingManager('store').removeActivateListener('dossierOverviewView', this.onActivateOnboarding);
    };

    render = () => {
        const mode = this.props.selectedMode;
        const {userDossiers, searchQuery} = this.state;
        const {selectedModule, selectedCategory, showUserDossiers} = this.props;
        const onboardingActive = getOnboardingManager('store').isActive();
        const userDossiersSelected = mode === 'user' && userDossiers > 0 && !onboardingActive;
        return (
            <div className='dossierPage'>
                <div style={{marginBottom: '1rem'}}>
                    {this.renderNavigationHeader()}
                </div>
                <div className="overallHeader">
                    <div className='titleContainer extraPad'>
                        <MaterialFactory componentType={materialTypes.RAISED_BUTTON} onClick={this.onSelectMode('user')}
                                         className={'dossierOverviewButton' + (userDossiersSelected ? ' selected' : '')}>
                            {'Jouw dossiers (' + userDossiers + ')'}</MaterialFactory>
                        <MaterialFactory componentType={materialTypes.RAISED_BUTTON} onClick={this.onSelectMode('new')}
                                         className={'dossierOverviewButton' + (userDossiersSelected ? '' : ' selected')}>
                            Maak nieuwe dossiers</MaterialFactory>
                    </div>
                </div>
                <div className='dossiersContainer'>
                    {typeof this.props.showUserDossiers !== 'function' &&
                    <div className={'userDossiers' + (userDossiersSelected ? ' show' : '')}>
                        <div className='titleContainer'>
                            <h3 className='title'>Jouw dossiers:</h3>
                            <div className='searchContainer'>
                                <BdhSearchInput onChange={this.onSearch} value={searchQuery}
                                                containerClass='searchDossier' label='Zoek een dossier...'
                                                onClearSearch={this.onClearSearch}/>
                            </div>
                        </div>
                        <DossierContainerController updateCount={this.updateDossierCount} fromUser={true}
                                                    selectedModule={selectedModule} filter={searchQuery}
                                                    selectedCategory={selectedCategory}/>
                    </div>}
                    {!userDossiersSelected &&
                    <div className={'possibleDossiers show'} id='dossierOverviewContainer'>
                        <div className='titleContainer'>
                            <h3 className='title'>Maak nieuwe dossiers aan!</h3>
                            <div className='searchContainer'>
                                <BdhSearchInput onChange={this.onSearch} value={searchQuery}
                                                containerClass='searchDossier' label='Zoek een dossier...'
                                                onClearSearch={this.onClearSearch}/>
                            </div>
                        </div>
                        <DossierContainerController dossierPopupClick={showUserDossiers} fromUser={false}
                                                    selectedModule={selectedModule} filter={searchQuery}
                                                    selectedCategory={selectedCategory}/>
                    </div>
                    }
                </div>
            </div>
        );
    }
}

DossierOverviewView.propTypes = {
    onSelectCategory: PropTypes.func.isRequired,
    selectMode: PropTypes.func.isRequired,
    selectedModule: PropTypes.string,
    onSelectModule: PropTypes.func.isRequired,
    showUserDossiers: PropTypes.func,
    selectedMode: PropTypes.oneOf(['user', 'new']).isRequired,
    selectedCategory: PropTypes.string.isRequired,
};

DossierOverviewView.defaultProps = {
    showUserDossiers: true,
};

export default withStyles(styles)(DossierOverviewView);
