import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../../../redux/actions';
import moment from 'moment';

import {addCustomEventListener, dispatchCustomEvent, removeCustomEventListener} from "../../../misc/eventDispatcher";
import UploadTips from "./uploadTips";
import {timeFormats} from "../../../common/components/datePicker";
import {uploadMapping} from "./uploadMapping";
import getDocumentType from "../../../common/data/mappings/docTypeMapping";
import getDossierType from "../../../common/data/mappings/dossierTypeMapping";
import {sendGaEvent} from "../../../common/js/ga";
import {navigate} from "../../../misc/utils";
import {NOW} from "../../misc/constants";

const GA_CATEGORY = 'Upload tips';

export class UploadTipsController extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            documentTypes: null,
            dossierTypes: null,
            type: null,
        };
        this._isMounted = false;
    }

    changeState = stateChange => {
        this._isMounted && this.setState(stateChange);
    };

    activate = () => {
        let user = this.props.userState.user;
        if (isNotNull(user)) {
            let dob = user.dob;
            let type;
            if (isNotNull(dob)) {
                let age = Math.abs(moment(dob, timeFormats).diff(NOW(), 'years'));
                if (age <= 25) type = 'student';
                else type = 'young_prof';
            } else type = 'young_prof';

            let config = uploadMapping[type];
            let documentTypes = this.getDocumentTypes(config);
            let dossierTypes = this.getDossierTypes(config);
            document.body.classList.add('noScroll');
            this.changeState({
                active: true,
                documentTypes,
                dossierTypes,
                type,
            });
            sendGaEvent(GA_CATEGORY, 'Open', type);
        }
    };

    getDocumentTypes = config => {
        return config.documents.map(name => {
            let documentType = getDocumentType(name);
            return {name, label: documentType.label};
        });
    };

    getDossierTypes = config => {
        return config.dossiers.map(name => {
            let dossierType = getDossierType(name);
            return {name, label: dossierType.label};
        });
    };

    getTypeLabel = () => {
        let type = this.state.type;
        if (isNotNull(type)) return {student: 'studenten', young_prof: 'young professionals'}[type];
        return 'Type onbekend';
    };

    onUploadDocument = documentType => {
        sendGaEvent(GA_CATEGORY, 'Klik upload document (' + this.getTypeLabel() + ')', documentType.label);
        navigate('/documenten-toevoegen');
        this.props.actions.appAddDocument(getDocumentType(documentType.name), () => {
            dispatchCustomEvent('updatePlatform');
        });
        this.onClose(false);
    };

    onUploadDossier = dossierType => {
        sendGaEvent(GA_CATEGORY, 'Klik dossier aanmaken (' + this.getTypeLabel() + ')', dossierType.label);
        navigate('/dossier-overzicht');
        dispatchCustomEvent('addDossier', dossierType.name);
        this.onClose(false);
    };

    onClose = (event = true) => {
        if (event)
            sendGaEvent(GA_CATEGORY, 'Sluiten', this.getTypeLabel());

        this.changeState({active: false, documentTypes: null, dossierTypes: null});
        document.body.classList.remove('noScroll');
    };

    componentDidMount = () => {
        this._isMounted = true;
        addCustomEventListener('openUploadTips', this.activate);
    };

    componentWillUnmount = () => {
        this._isMounted = false;
        removeCustomEventListener('openUploadTips', this.activate);
    };

    render = () => {
        if (this.state.active) return <UploadTips onUploadDocument={this.onUploadDocument} type={this.getTypeLabel()}
                                                  onUploadDossier={this.onUploadDossier} onClose={this.onClose}
                                                  documentTypes={this.state.documentTypes}
                                                  dossierTypes={this.state.dossierTypes}/>;
        return null;
    };
}

const mapStateToProps = state => ({
    userState: state.user,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadTipsController);