import './style/actionCode.scss';

import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import {Delete, Add} from "@material-ui/icons";

import {toMoment, getSetting} from 'glob-common-js/lib/utils';

import ActionCode from "../../models/actionCode";
import BdhSelectController from "../customControls/select/bdhSelectController";
import BdhSearchInput from "../customControls/input/bdhSearchInput";
import CustomScrollbar from "../misc/customScrollbar";
import {materialTypes} from "../material/materialTypes";
import MaterialFactory from "../material/materialFactory";
import GenericLoader from "../misc/genericLoader";

export default class ActionCodesView extends React.Component {
    onClearSearch = () => {
        this.props.onSearch('');
    };

    onSelectCode = code => evt => {
        this.preventCodeClick(evt);
        this.props.onSelectCode(code);
    };

    preventCodeClick = evt => {
        evt.stopPropagation();
    };

    getSortItems = () => ([
        {label: 'Code', value: 'code'},
        {label: 'Aangemaakt op', value: 'createdAt'},
        {label: 'Aangepast op', value: 'updatedAt'},
        {label: 'Omschrijving', value: 'description'},
        {label: 'Max aantal gebruikers', value: 'maxUses'},
        {label: 'Aantal keer gebruikt', value: 'timesUsed'},
        {label: 'Geldig tot', value: 'validUntil'},
        {label: 'Periode duur', value: 'period'}
    ]);

    getMaxUses = code => {
        const maxUses = code.maxUses;
        return maxUses === -1 ? 'Onbeperkt' : maxUses;
    };

    getPeriod = code => {
        const period = code.period, key = Object.keys(period)[0], value = Object.values(period)[0];
        if (areNotNull(key, value)) {
            const translated = {
                days: {single: 'dag', multiple: 'dagen'},
                weeks: {single: 'week', multiple: 'weken'},
                months: {single: 'maand', multiple: 'maanden'},
                years: {single: 'jaar', multiple: 'jaar'},
            }, name = translated[key][(value === 1 ? 'single' : 'multiple')];
            return `${value} ${name}`;
        }
        return '';
    };

    getValid = code => {
        const valid = code.validUntil;
        return isNull(valid) ? 'Onbeperkt' : toMoment(valid).format('DD-MM-YYYY');
    };

    getUrl = code => {
        const mode = getSetting('mode'), port = location.port,
            domain = `${location.protocol}//${location.hostname}${isNull(port) ? '' : `:${port}`}${['accept',
                'test'].includes(mode) ? '/daisy' : ''}`;
        return `${domain}/registratie?code=${code.code}`;
    };

    renderCountRow = () => {
        const {onSort, order} = this.props;
        const count = this.props.actionCodes.length, countName = count === 1 ? 'actie code' : 'actie codes';
        return (
            <div className='row count'>
                <label className='countLabel'>{`${count} ${countName}`}</label>
                <BdhSelectController onSelect={onSort} value={[order]} items={this.getSortItems()} variant='contained'
                                     label='Sorteren op' readOnly/>
            </div>
        )
    };

    renderActionsRow = () => {
        const {selectedCodes, onClickDelete, onClickNew, onSearch, searchValue} = this.props;
        return (
            <div className='row actions'>
                <div className='buttonsContainer'>
                    <Button disabled={isNull(selectedCodes)} onClick={onClickDelete} className='deleteButton'
                            color='primary' variant='contained' size='small'>
                        <Delete className='buttonIcon'/>
                        Verwijderen
                    </Button>
                    <Button onClick={onClickNew} color='primary' variant='contained' size='small'>
                        <Add className='buttonIcon'/>
                        Nieuwe code aanmaken
                    </Button>
                </div>
                <BdhSearchInput onChange={onSearch} value={searchValue} onClearSearch={this.onClearSearch}
                                label='Zoeken'/>
            </div>
        )
    };

    renderCodesTable = () => {
        return (
            <div className='codesTable'>
                {this.renderTableHead()}
                {this.renderTableBody()}
            </div>
        )
    };

    renderTableHead = () => (
        <div className='tableRow head'>
            <div className='cell check'/>
            <div className='cell code'>
                <label className='cellValue'>Code</label>
            </div>
            <div className='cell maxUses'>
                <label className='cellValue'>Max aantal gebruikers</label>
            </div>
            <div className='cell timesUsed'>
                <label className='cellValue'>Keer gebruikt</label>
            </div>
            <div className='cell period'>
                <label className='cellValue'>Periode duur</label>
            </div>
            <div className='cell validUntil'>
                <label className='cellValue'>Geldig tot</label>
            </div>
            <div className='cell link'>
                <label className='cellValue'>URL</label>
            </div>
        </div>
    );

    renderTableBody = () => {
        const {actionCodes, selectedCodes, onClickCode} = this.props;
        return (
            <CustomScrollbar height='25rem'>
                {actionCodes.map(code => (
                    <div key={code.id} className='tableRow' onClick={onClickCode(code)}>
                        <div className='cell check'>
                            <MaterialFactory componentType={materialTypes.CHECKBOX}
                                             checked={selectedCodes.includes(code.id)}
                                             onChange={this.onSelectCode(code)} onClick={this.preventCodeClick}/>
                        </div>
                        <div className='cell code'>
                            <label className='cellValue'>{code.code}</label>
                        </div>
                        <div className='cell maxUses'>
                            <label className='cellValue'>{this.getMaxUses(code)}</label>
                        </div>
                        <div className='cell timesUsed'>
                            <label className='cellValue'>{code.timesUsed}</label>
                        </div>
                        <div className='cell period'>
                            <label className='cellValue'>{this.getPeriod(code)}</label>
                        </div>
                        <div className='cell validUntil'>
                            <label className='cellValue'>{this.getValid(code)}</label>
                        </div>
                        <div className='cell link'  onClick={this.preventCodeClick}>
                            <label className='cellValue'>{this.getUrl(code)}</label>
                        </div>
                    </div>
                ))}
            </CustomScrollbar>
        )
    };

    render = () => {
        const {loading} = this.props;
        return (
            <div className='actionCodesView'>
                <GenericLoader active={loading}/>
                {this.renderCountRow()}
                {this.renderActionsRow()}
                {this.renderCodesTable()}
            </div>
        )
    };
}

ActionCodesView.propTypes = {
    onClickCode: PropTypes.func.isRequired,
    onSelectCode: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
    onClickNew: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    onSort: PropTypes.func.isRequired,
    actionCodes: PropTypes.arrayOf(PropTypes.instanceOf(ActionCode)).isRequired,
    selectedCodes: PropTypes.arrayOf(PropTypes.string).isRequired,
    order: PropTypes.oneOf(['code', 'description', 'maxUses', 'timesUsed', 'validUntil', 'period',
        'createdAt', 'updatedAt']).isRequired,
    searchValue: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
};