import './style/startFlowView.scss';

import React from 'react';
import PropTypes from 'prop-types';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import moment from 'moment';

import DataController20 from "./20_25/dataController20";
import Intro20 from "./20_25/intro20";
import Completion20 from "./20_25/completion20";
import Intro25 from "./25+/intro25";
import DataController25 from "./25+/dataController25";
import {timeFormats} from "../../common/components/datePicker";
import {sendGaEvent} from "../../common/js/ga";
import CustomScrollbar from "../misc/customScrollbar";
import MaterialFactory from "../material/materialFactory";
import {materialTypes} from "../material/materialTypes";

const GA_CATEGORY = 'Start flow';

export default class StartFlowView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeStep: 0,
            visitedSteps: [],
            active: props.startFlow,
            loading: false,
        };
        this._isMounted = false;
    }

    changeState = stateChange => {
        this._isMounted && this.setState(stateChange);
    };

    setStep = (nextStep) => {
        if (nextStep === 0 || this.state.visitedSteps.includes(nextStep - 1) || this.state.activeStep === nextStep - 1)
            this.changeState({activeStep: nextStep});
        else if (isNotNull(this.state.visitedSteps))
            this.changeState({activeStep: Math.max(this.state.visitedSteps)});
        else this.changeState({activeStep: 0});
    };

    onClose = () => {
        sendGaEvent(GA_CATEGORY, 'Stap 4', 'Aan de slag');
        this.changeState({active: false});
        this.props.onClose();
    };

    onSave = () => {
        this.changeState({loading: true});
    };

    stopLoading = () => {
        this.changeState({loading: false});
    };

    renderStepContent = step => {
        switch (step) {
            case 0:
                return this.renderStepZero();
            case 1:
                return this.renderStepOne();
            case 2:
                return this.renderStepTwo();
            case 3:
                return this.renderStepThree();
        }
    };

    renderStepZero = () => {
        const {dob, changeDob} = this.props;
        const nextStep = 1;
        return (
            <div className='dobStep'>
                <label className='dobTitle'>Vul je geboortedatum in om te beginnen</label>
                <MaterialFactory componentType={materialTypes.DATE_PICKER} label='Geboortedatum' value={dob}
                                 style={{maxWidth: '100%'}} onChange={changeDob}/>
                <MaterialFactory componentType={materialTypes.RAISED_BUTTON} onClick={() => {
                    sendGaEvent(GA_CATEGORY, 'Stap 1', 'Starten');
                    this.setStep(nextStep);
                }}>Starten</MaterialFactory>
            </div>
        )
    };

    renderStepOne = () => {
        let mode = this.props.mode;
        const nextStep = 2;
        switch (mode) {
            case '18-19':
            case '20-25':
                return <Intro20 nextStep={() => {
                    sendGaEvent(GA_CATEGORY, 'Stap 2', 'Beginnen');
                    this.setStep(nextStep)
                }}/>;
            case '25+':
                return <Intro25 nextStep={() => {
                    sendGaEvent(GA_CATEGORY, 'Stap 2', 'Beginnen');
                    this.setStep(nextStep);
                }}/>;
        }
    };

    renderStepTwo = () => {
        let mode = this.props.mode;
        const nextStep = 3;
        switch (mode) {
            case '18-19':
            case '20-25':
                return <DataController20 nextStep={() => {
                    sendGaEvent(GA_CATEGORY, 'Stap 3', 'Opslaan');
                    this.setStep(nextStep)
                }} user={this.props.user} onSave={this.onSave} onStopLoading={this.stopLoading}/>;
            case '25+':
                return <DataController25 nextStep={() => {
                    sendGaEvent(GA_CATEGORY, 'Stap 3', 'Opslaan');
                    this.setStep(nextStep)
                }} user={this.props.user} onSave={this.onSave} onStopLoading={this.stopLoading}/>;
        }
    };

    renderStepThree = () => {
        return <Completion20 onClose={this.onClose}/>;
    };

    componentDidMount = () => {
        this._isMounted = true;
        if (this.props.startFlow) {
            sendGaEvent('Start flow', 'Activeer', 'Open flow');
        }
    };

    componentDidUpdate = (prevProps, prevState) => {
        let prevIndex = prevState.activeStep;
        let prevVisited = prevState.visitedSteps;
        if (this.state.active && !prevVisited.includes(prevIndex) && prevIndex !== this.state.activeStep) {
            this.changeState({visitedSteps: prevVisited.concat(prevIndex)});
        }

        if (!prevProps.startFlow && this.props.startFlow) {
            sendGaEvent('Start flow', 'Activeer', 'Open flow');
            this.changeState({active: true});
        }
    };

    componentWillUnmount = () => {
        this._isMounted = false;
    };

    render = () => {
        // TODO Compact
        return (
            <div
                className={'startFlowView' + (this.state.active ? ' active' : '')}>
                <div className='blackBackground'/>
                <div className='startFlow'>
                    <div className='startFlowHeader'>
                        <label className='startFlowTitle'>Welkom op ByDeHand</label>
                    </div>
                    <CustomScrollbar height='30rem'>
                        <div className='startFlowBody'>
                            {this.state.loading &&
                            <div className='startFlowLoader'>
                                <div className='whiteBackground'/>
                                <div className='startFlowLoaderContainer'>
                                    <MaterialFactory componentType={materialTypes.CIRCULAR_PROGRESS}/>
                                    <label className='startFlowLoaderText'>Gegevens opslaan</label>
                                </div>
                            </div>}
                            <Stepper orientation='vertical' activeStep={this.state.activeStep}>
                                <Step completed={this.state.visitedSteps.includes(0)}>
                                    <StepLabel>Geboortedatum</StepLabel>
                                    <StepContent>
                                        {this.renderStepContent(0)}
                                    </StepContent>
                                </Step>
                                <Step completed={this.state.visitedSteps.includes(1)}>
                                    <StepLabel>Introductie</StepLabel>
                                    <StepContent>
                                        {this.renderStepContent(1)}
                                    </StepContent>
                                </Step>
                                <Step completed={this.state.visitedSteps.includes(2)}>
                                    <StepLabel>Jouw gegevens</StepLabel>
                                    <StepContent>
                                        {this.renderStepContent(2)}
                                    </StepContent>
                                </Step>
                                <Step completed={this.state.visitedSteps.includes(3)}>
                                    <StepLabel>Aan de slag</StepLabel>
                                    <StepContent>
                                        {this.renderStepContent(3)}
                                    </StepContent>
                                </Step>
                            </Stepper>
                        </div>
                    </CustomScrollbar>
                </div>
            </div>
        )
    };
}

StartFlowView.propTypes = {
    onClose: PropTypes.func.isRequired,
    changeDob: PropTypes.func.isRequired,
    mode: PropTypes.oneOf(['18-19', '20-25', '25+']),
    user: PropTypes.object,
    dob: PropTypes.string.isRequired,
    startFlow: PropTypes.bool.isRequired,
};