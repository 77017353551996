import React from 'react';

import {capitalizeFirst, stringStartsWith, stringContains, toMoment} from 'glob-common-js/lib/utils';

import StateComponent from "../misc/stateComponent";
import ActionCodesView from "./actionCodesView";
import {showMessage, showSnackbar} from "../../misc/utils";
import {
    addDataEventListener,
    DATA_TYPES,
    dispatchActionCodeEvent,
    EVENT_TYPES,
    removeAllDataEventListeners
} from "../../misc/dataEvent";
import ActionCode from "../../models/actionCode";
import {dispatchCustomEvent} from "../../misc/eventDispatcher";

const preferA = -1, preferB = 1, isEqual = 0;
export default class ActionCodesController extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            actionCodes: [],
            selectedCodes: [],
            loading: true,
            order: 'code',
            searchValue: '',
        };
        this.actionCodes = [];
    }

    onDataEvent = (actionCode, eventType) => {
        switch (eventType) {
            case EVENT_TYPES.CREATE:
                this.onCreateEvent(actionCode);
                break;
            case EVENT_TYPES.UPDATE:
                this.onUpdateEvent(actionCode);
                break;
            case EVENT_TYPES.DELETE:
                this.onDeleteEvent(actionCode);
                break;
        }
    };

    onCreateEvent = actionCode => {
        this.actionCodes.push(actionCode);
        this.filterActionCodes();
    };

    onUpdateEvent = actionCode => {
        for (let i = 0; i < this.actionCodes.length; i++) {
            if (this.actionCodes[i].id === actionCode.id) {
                this.actionCodes.splice(i, 1, actionCode);
                break;
            }
        }
        this.filterActionCodes();
    };

    onDeleteEvent = actionCode => {
        this.actionCodes = this.actionCodes.filter(code => code.id !== actionCode.id);
        this.filterActionCodes();
    };

    onClickCode = code => () => {
        dispatchCustomEvent('openActionCode', code);
    };

    onClickNew = () => {
        dispatchCustomEvent('openActionCode');
    };

    onSearch = value => {
        this.changeState({searchValue: value}, this.filterActionCodes);
    };

    onSort = value => {
        this.changeState({order: value}, this.filterActionCodes);
    };

    onSelectCode = code => {
        let selectedCodes = this.state.selectedCodes.slice();
        if (selectedCodes.includes(code.id))
            selectedCodes = selectedCodes.filter(codeId => codeId !== code.id);
        else selectedCodes.push(code.id);
        this.changeState({selectedCodes});
    };

    onClickDelete = () => {
        const quantifier = this.state.selectedCodes.length === 1 ? 'actie code' : 'actie codes';
        showMessage(`Weet je zeker dat je deze ${quantifier} wilt verwijderen?`, [{label: 'Nee'},
            {label: 'Ja', onClick: this.onConfirmDelete}], `${capitalizeFirst(quantifier)} verwijderen`)
    };

    onConfirmDelete = () => {
        const {selectedCodes} = this.state;
        let codesDeleted = 0;
        const deleteCallback = code => () => {
            dispatchActionCodeEvent(EVENT_TYPES.DELETE, code);
            if (++codesDeleted >= selectedCodes.length) {
                this.changeState({loading: false, selectedCodes: []});
                showSnackbar(`De actie code${selectedCodes.length > 1 ? 's zijn' : ' is'} verwijderd`);
            }
        };
        this.changeState({loading: true});
        for (const selected of selectedCodes) {
            const code = this.getCode(selected);
            if (isNull(code))
                deleteCallback(code);
            else code.delete(deleteCallback(code));
        }
    };

    getCode = id => {
        for (const code of this.actionCodes)
            if (code.id === id) return code;
        return null;
    };

    filterActionCodes = () => {
        const codes = this.syncSelection(this.sortCodes(this.searchCodes(this.actionCodes)));
        this.changeState({actionCodes: codes});
    };

    syncSelection = codes => {
        const {selectedCodes} = this.state;
        const ids = codes.map(code => code.id);
        let i = selectedCodes.length;
        while (i--) {
            if (!ids.includes(selectedCodes[i]))
                selectedCodes.splice(i, 1);
        }
        this.changeState({selectedCodes});
        return codes;
    };

    searchCodes = codes => {
        codes = codes.slice();
        const codeCodes = this.searchByKey(codes, 'code'), descriptionCodes = this.searchByKey(codes, 'description');
        return codeCodes.concat(descriptionCodes);
    };

    sortCodes = codes => {
        const {order} = this.state;
        codes = codes.slice();
        codes.sort((codeA, codeB) => {
            if (order === 'period') return this.sortByPeriod(codeA, codeB);
            if (['createdAt', 'updatedAt', 'validUntil'].includes(order)) return this.sortByDate(codeA, codeB);
            if (['code', 'description'].includes(order)) return this.sortByString(codeA, codeB);
            if (['maxUses', 'timesUsed'].includes(order)) return this.sortByNumber(codeA, codeB);
        });
        return codes;
    };

    sortByDate = (codeA, codeB) => {
        const {order} = this.state;
        const valueA = codeA[order], valueB = codeB[order];
        if (isNotNull(valueA) && isNull(valueB)) return preferA;
        if (isNull(valueA) && isNotNull(valueB)) return preferB;
        if (isNull(valueA) && isNull(valueB)) return isEqual;

        const momentA = toMoment(valueA), momentB = toMoment(valueB);
        if (momentA.isAfter(momentB)) return preferA;
        if (momentA.isBefore(momentB)) return preferB;
        return isEqual;
    };

    sortByString = (codeA, codeB) => {
        const {order} = this.state;
        const valueA = codeA[order].toLowerCase(), valueB = codeB[order].toLowerCase();
        return valueA.localeCompare(valueB);
    };

    sortByNumber = (codeA, codeB) => {
        const {order} = this.state;
        const valueA = codeA[order], valueB = codeB[order];
        return valueB - valueA;
    };

    sortByPeriod = (codeA, codeB) => {
        const weights = {
            days: 0,
            weeks: 1,
            months: 2,
            years: 3,
        };
        const periodA = this.getPeriodDetails(codeA.period), keyA = periodA.key, valueA = periodA.value,
            periodB = this.getPeriodDetails(codeB.period), keyB = periodB.key, valueB = periodB.value;
        if (keyA === keyB) return valueA - valueB;
        return weights[keyA] - weights[keyB];
    };

    getPeriodDetails = period => ({
        key: Object.keys(period)[0],
        value: Object.values(period)[0],
    });

    searchByKey = (codes, key) => {
        const startsWith = [], contains = [], {searchValue} = this.state;
        let i = codes.length;
        while (i--) {
            const code = codes[i];
            let value = code[key];
            if (key !== 'createdAt') value = value.toLowerCase();
            if (isNotNull(value)) {
                let isFiltered = false;
                if (stringStartsWith(value, searchValue)) {
                    startsWith.push(code);
                    isFiltered = true;
                } else if (stringContains(value, searchValue)) {
                    contains.push(code);
                    isFiltered = true;
                }
                if (isFiltered)
                    codes.splice(i, 1);
            }
        }
        return startsWith.concat(contains);
    };

    loadCodes = () => {
        this.changeState({loading: true});
        ActionCode.getAll(codes => {
            this.actionCodes = codes;
            this.filterActionCodes();
            this.changeState({loading: false});
        })
    };

    componentDidMount = () => {
        this.mount();
        this.loadCodes();
        addDataEventListener(DATA_TYPES.CODE, [EVENT_TYPES.CREATE, EVENT_TYPES.UPDATE,
            EVENT_TYPES.DELETE], 'actionCodesController', this.onDataEvent);
    };

    componentWillUnmount = () => {
        this.unMount();
        removeAllDataEventListeners('actionCodesController', this.onDataEvent);
    };

    render = () => {
        const {selectedCodes, loading, order, searchValue, actionCodes} = this.state;
        return (
            <ActionCodesView onClickCode={this.onClickCode} onSelectCode={this.onSelectCode}
                             onClickDelete={this.onClickDelete} onClickNew={this.onClickNew} onSearch={this.onSearch}
                             onSort={this.onSort} actionCodes={actionCodes} selectedCodes={selectedCodes}
                             order={order} searchValue={searchValue} loading={loading}/>
        )
    }
}