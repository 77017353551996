import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as Actions from '../../redux/actions';

import moduleConstants from 'BdhCommon/data/constants/moduleConstants';


import ModuleView from './moduleView';
import {getExternalData, updateExternalData} from "../../misc/requestSender";
import {getPlatformVersion} from "../../misc/utils";

export class ModuleController extends React.Component {
    constructor(props) {
        super(props);
    }

    activateAllModules = () => {
        let activeModules = this.props.moduleState.activeModules;

        forEach(Object.keys(moduleConstants), (key) => {
            activeModules = isNotNull(activeModules) ? activeModules.concat(moduleConstants[key]) : [moduleConstants[key]];
        });

        this.setState({
            activeModules
        });
    };

    changeActiveModules = (activeModules) => {
        this.props.actions.setActiveModules(activeModules);
        getExternalData({
            callback: (response) => {
                let externalData = response.data.external_data || {};
                externalData.modules = activeModules;
                updateExternalData({
                    external_data: externalData,
                    callback: () => {
                        // External data updated
                    }
                })
            }
        })
    };

    editModules = (module, active) => {
        let activeModules = this.props.moduleState.activeModules;

        if (active) {
            let index = activeModules.indexOf(module);
            activeModules.splice(index, 1);
            this.changeActiveModules(isNotNull(activeModules) ? activeModules : []);
        }
        else {
            this.changeActiveModules(isNotNull(activeModules) ? activeModules.concat(module) : [module]);
        }
    };

    render = () => {
      if (getPlatformVersion()==="lifeWill") {
        return <p></p>
      } else {
        return <ModuleView activeModules={this.props.moduleState.activeModules}
                           allModules={Object.keys(moduleConstants)} selectedModule={this.props.selectedModule}
                           onSelect={this.props.onSelect} editModules={this.editModules}
                           activateAllModules={this.activateAllModules}
                           withMobileContainer={this.props.withMobileContainer}
                           onAddDocumentClick={this.props.onAddDocumentClick} documentTypes={this.props.documentTypes}/>
      }
    }
}

ModuleController.propTypes = {
    onSelect: PropTypes.func.isRequired,
    selectedModule: PropTypes.string,
    withMobileContainer: PropTypes.bool,
    onAddDocumentClick: PropTypes.func,
};

ModuleController.defaultProps = {
    withMobileContainer: false,
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});

const mapStateToProps = state => ({
    moduleState: state.module,
});

export default connect(mapStateToProps, mapDispatchToProps)(ModuleController);
