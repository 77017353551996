import './style/loadingView.scss';

import React from 'react';
import PropTypes from 'prop-types';
import {materialTypes} from "../../../material/materialTypes";
import MaterialFactory from "../../../material/materialFactory";
import {sendPageView} from "../../../../common/js/ga";

export default class LoadingView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            time: 0,
        };
        this._isMounted = false;
        this.timer = null;
    }

    changeState = stateChange => {
        this._isMounted && this.setState(stateChange);
    };

    runTimer = () => {
        this.timer = setTimeout(() => {
            const time = this.state.time + 1;
            if (time <= 300) {
                this.changeState({time});
                this.runTimer();
            }
        }, 1000);
    };

    getTime = () => {
        const time = 300 - this.state.time;
        const pad = number => {
            return ('0' + number).slice(-2);
        };
        const minutes = time === 0 ? 0 : parseInt(time / 60);
        const seconds = time === 0 ? 0 : time % 60;
        return pad(minutes) + ':' + pad(seconds);
    };

    getProgress = value => value * 100 / 300;

    componentDidMount = () => {
        ga('set', 'page', '/registratie/2-activeren');
        sendPageView();
        this._isMounted = true;
        if (this.props.showTimer) this.runTimer();
    };

    componentWillUnmount = () => {
        this._isMounted = false;
        clearTimeout(this.timer);
    };

    render = () => (
        <div className='activatingView'>
            <MaterialFactory componentType={materialTypes.CIRCULAR_PROGRESS}/>
            <p className='activatingMessage'>{this.props.message}</p>
            {this.props.showTimer && <div className='timeProgressContainer'>
                <label className='timeLabel'>{this.getTime()}</label>
                <MaterialFactory componentType={materialTypes.LINEAR_PROGRESS}
                                 value={this.getProgress()} variant='determinate'/>
            </div>}
        </div>
    )
}

LoadingView.propTypes = {
    message: PropTypes.string.isRequired,
    showTimer: PropTypes.bool,
};

LoadingView.defaultProps = {
    showTimer: false,
};