import '../style/dossier.scss';

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as Actions from '../../../../../redux/actions';

import {deviceIsMobile} from 'glob-common-js/lib/utils';

import {sendPageView} from "BdhCommon/js/ga";
import {getCatByLabel} from 'BdhCommon/data/mappings/categoryMapping';
import {getInsurerFromMapping} from 'BdhCommon/data/mappings/healthInsuranceMapping';
import getDossierType from 'BdhCommon/data/mappings/dossierTypeMapping';
import DossierView from "./dossierView";
import {getFromRegistry} from "../../misc/registry";
import {sendEvent} from "../../misc/healthAnalysisUtils";
import FbShareView from "../../fbShare/fbShareView";
import {createDossier} from "../../../../../misc/requestSender";
import {navigate} from "../../../../../misc/utils";
import StateComponent from "../../../../misc/stateComponent";
import GenericLoader from "../../../../misc/genericLoader";
import {dispatchCustomEvent} from "../../../../../misc/eventDispatcher";

export class DossierController extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            requesting: false,
        }
    }

    createDossier = () => {
        sendEvent('Dossier 2020', 'Maak dossier');
        let insuranceData = getFromRegistry(['healthInsurance', 'nextInsurance']);
        if (isNotNull(insuranceData)) {
            this.changeState({requesting: true});
            let data = this.createDossierData(insuranceData);
            createDossier({
                data,
                callback: (dossier) => {
                    this.changeState({requesting: false});
                    if (deviceIsMobile()) {
                        this.props.actions.setDossier(dossier.id);
                        navigate('/dossier-details', true);
                    } else {
                        navigate('/dashboard', true);
                        window.setTimeout(() => {
                            dispatchCustomEvent('setFinancialDashboardDate', '2020-01-01');
                            dispatchCustomEvent('setHighlightParams', {
                                dossiersAdded: true,
                                financialAdded: true,
                                type: 'health'
                            });
                            dispatchCustomEvent('startHighlight');
                        }, 1000);
                    }
                }
            });
        }
    };

    createDossierData = (insuranceData) => {
        // Recursive function to get nested values from an object
        const getValue = (obj, keys) => {
            if (obj === null) obj = insuranceData;

            if (Array.isArray(keys) && keys.length > 0) {
                if (keys.length === 1) {
                    return getValue(obj, keys[0]);
                }
                let nextKey = keys[0];
                if (areNotNull(nextKey, obj[nextKey])) {
                    let nextObj = obj[nextKey];
                    keys.splice(0, 1);
                    return getValue(nextObj, keys);
                }
                return null;
            } else if (isNotNull(obj[keys])) {
                return obj[keys];
            }
            return null;
        };

        let insurer = getValue(null, 'insurer');
        let insurerData = getInsurerFromMapping(insurer);
        let basic = getValue(null, ['basic', 'name']);
        let risk = getValue(null, 'risk');
        let additionals = getValue(null, 'additionals') || [];
        return {
            name: insurer + ' 2020 zorgverzekering',
            type: getDossierType('health_insurance_care').id,
            external_data: {
                values: [
                    {
                        company: insurer,
                        health_insurance: basic,
                        discount_basic: null,
                        health_supplementary: additionals.filter(add => isNotNull(add) && add !== 'geen').join(', '),
                        discount_extra: null,
                        health_dentist: null,
                        discount_dental: null,
                        health_own_risk: risk || 385,
                        start_date: '2020-01-01',
                        end_date: '2020-12-31',
                        monthly_price: getValue(null, 'monthprice'),
                        frequency: 'monthly',
                        pay_day: null,
                    }
                ],
                dossierContacts: [
                    {
                        name: insurer,
                        website: this.isNotNullCheck(insurerData['url']),
                        telephone: this.isNotNullCheck(insurerData['phoneNumber']),
                        email: this.isNotNullCheck(insurerData['email']),
                        address: this.isNotNullCheck(insurerData['address']),
                        loginName: '',
                        comment: 'Zorgverzekeraar 2020',
                    }
                ]
            },
        }
    };


    isNotNullCheck = (variable) => {
        if (isNotNull(variable)) {
            return variable;
        }
        return '';
    };

    componentDidMount = () => {
        sendPageView('/zorganalyse/jouw-verzekering/dossier-inrichten');
        this.mount();
    };

    componentWillUnmount = () => {
        this.unMount();
    };

    render = () => {
        return (
            <div>
                <GenericLoader active={this.state.requesting} backgroundClassName='dossierControllerLoader'/>
                <DossierView onCreateClick={this.createDossier}/>
                {/*<RatingController showPopup={!this.props.healthState.ratingShown}/>*/}
                <FbShareView/>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    healthState: state.healthInsuranceAnalysis
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DossierController));
