import './style/contactDetail.scss';

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from "moment";
import {Edit, Link, Mail} from "@material-ui/icons";

import {deviceIsMobile, isValidEmail} from 'glob-common-js/lib/utils';
import {hasHover} from 'glob-common-react/lib/utils/reactUtils';

import {Contact} from "../../../models/contact";
import {WithBackground} from "../../../misc/reactUtils";
import BdhContainedInput from "../../customControls/input/bdhContainedInput";
import BdhPasswordInputController from "../../customControls/input/password/bdhPasswordInputController";
import GenericLoader from "../../misc/genericLoader";
import MaterialFactory from "../../material/materialFactory";
import {materialTypes} from "../../material/materialTypes";
import CustomScrollbar from "../../misc/customScrollbar";

export default class ContactDetailView extends React.Component {
    backgroundClickEnabled = true;

    onClickBackground = () => {
        const popup = document.getElementById('addContactPopup');
        if (isNotNull(popup) && !hasHover(popup) && this.backgroundClickEnabled)
            this.props.onClose();
    };

    onFocusField = evt => {
        const target = evt.target;
        if (isNotNull(target) && isNotNull(target.value))
            target.setSelectionRange(0, target.value.length);
    };

    onOpenPasswordConfirmation = () => {
        this.backgroundClickEnabled = false;
    };

    onClosePasswordConfirmation = () => {
        this.backgroundClickEnabled = true;
    };

    onClickWebsite = evt => {
        evt.stopPropagation();
        const {contact} = this.props;
        let website = contact.website;
        let regex = /javascript|script|\(\)|;/;
        if (!regex.test(website)) {
            if (this.websiteIsUrl(website)) {
                if (!/^https?:\/\//.test(website)) website = 'https://' + website;
            } else website = `https://www.google.nl/search?q=${website}`;
            window.open(website, '_blank');
        }
    };

    onClickEmail = evt => {
        evt.stopPropagation();
        const {contact} = this.props, email = contact.email;
        if (isValidEmail(email))
            location.href = `mailto:${email}`;
    };

    websiteIsUrl = website => {
        return /\.[A-Za-z]{2,}/.test(website);
    };

    getOrnament = text => ([{element: <span className='textOrnament'>{text}</span>, placement: 'right'}]);

    getWebsiteOrnament = () => {
        const {contact} = this.props;
        if (isNull(contact.website)) return [];
        return [{placement: 'right', element: <Link className='linkIcon pulsing' onClick={this.onClickWebsite}/>}]
    };

    getEmailOrnaments = () => {
        const {contact} = this.props;
        if (isValidEmail(contact.email)) return [
            {placement: 'right', element: <Mail className='linkIcon' onClick={this.onClickEmail}/>}
        ];
        return [];
    };

    renderHeader = () => {
        const {contact, onClose} = this.props;
        return (
            <div className='headerContainer'>
                <label className='headerTitle'>{contact.name}</label>
                <span className='common-icon-cross closeButton' onClick={onClose}/>
            </div>
        )
    };

    renderBody = () => {
        const {loading, onClose, onSubmit, onDelete, isNew, hasChanges} = this.props;
        return (
            <div className='contactBody'>
                <GenericLoader active={loading}/>
                {this.renderTabs()}
                <div className='tabBlockContainer'>
                    <CustomScrollbar height='23rem'>
                        {this.renderActiveTab()}
                    </CustomScrollbar>
                    <div className='buttonsContainer'>
                        <span className={classNames('common-icon-trash', 'deleteButton', isNew && 'hide')}
                              onClick={onDelete}/>
                        <div className='submitButtons'>
                            <MaterialFactory componentType={materialTypes.FLAT_BUTTON} className='closeButton'
                                             onClick={onClose}>Sluiten</MaterialFactory>
                            <MaterialFactory componentType={materialTypes.RAISED_BUTTON}
                                             onClick={onSubmit} disabled={!hasChanges}>Opslaan</MaterialFactory>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    renderActiveTab = () => {
        const {activeTab} = this.props;
        switch (activeTab) {
            case 'data':
                return this.renderDataTab();
            case 'accounts':
                return this.renderAccountsTab();
            case 'dossiers':
                return this.renderDossiersTab();
        }
    };

    renderTabs = () => {
        const {activeTab, onChangeTab} = this.props;
        return (
            <div className='tabsContainer'>
                <label className={classNames('tab', activeTab === 'data' && 'active')}
                       onClick={onChangeTab('data')}>Algemeen</label>
                <label className={classNames('tab', activeTab === 'accounts' && 'active')}
                       onClick={onChangeTab('accounts')}>Inloggegevens</label>
                <label className={classNames('tab', activeTab === 'dossiers' && 'active')}
                       onClick={onChangeTab('dossiers')}>Dossier koppelingen</label>
            </div>
        );
    };

    renderDataTab = () => {
        return (
            <div className='contactData'>
                {this.renderField('name', 'Naam', true, '', false, [])}
                {this.renderEmailField()}
                {this.renderField('phone', 'Telefoonnummer', true, '', false,
                    this.getOrnament('Zakelijk'))}
                {this.renderField('mobile', '', true, '', false,
                    this.getOrnament('Mobiel'))}
                {this.renderWebsiteField()}
                <div className='fieldsContainer'>
                    {this.renderField('street', 'Straat', deviceIsMobile(), 'streetField')}
                    {this.renderField('houseNumber', 'Huisnummer', false, 'houseNumberField')}
                    {this.renderField('houseNumberSuffix', 'Toevoeging', false, 'suffixField')}
                </div>
                <div className='fieldsContainer'>
                    {this.renderField('zipCode', 'Postcode', deviceIsMobile(), 'zipcodeField')}
                    {this.renderField('city', 'Woonplaats', deviceIsMobile(), 'cityField')}
                </div>
                {this.renderField('comment', 'Opmerking', true, '', true)}
            </div>
        );
    };

    renderWebsiteField = () => {
        const {contact, onChange} = this.props;
        return <BdhContainedInput onChange={onChange('website')} value={contact.website} onFocus={this.onFocusField}
                                  label='Website' ornaments={this.getWebsiteOrnament()} fullWidth/>;
    };

    renderEmailField = () => {
        const {contact, onChange} = this.props;
        return <BdhContainedInput onChange={onChange('email')} value={contact.email} onFocus={this.onFocusField}
                                  label='E-mailadres' ornaments={this.getEmailOrnaments()} fullWidth/>
    };

    renderField = (name, label, fullWidth = true, className = '', multiLine = false, ornaments = [], onFocus = this.onFocusField) => {
        const {onChange, contact} = this.props;
        className += ' paddedField';
        return <BdhContainedInput containerClass={className} onChange={onChange(name)} value={contact[name]}
                                  onFocus={onFocus} label={label} fullWidth={fullWidth} multiLine={multiLine}
                                  ornaments={ornaments}/>;
    };

    renderAccountsTab = () => {
        const {contact, onClickNewAccount} = this.props, accounts = contact.accounts;
        return (
            <div className='contactAccounts'>
                {accounts.map(this.renderAccount)}
                <div className='accountContainer new' onClick={onClickNewAccount}>
                    <label>Nieuw account toevoegen</label>
                    <span className='addIcon'>+</span>
                </div>
            </div>
        );
    };

    renderAccount = (account, key) => {
        const {onChangeAccount, onConfirmPassword, passConfirmedAt, onRemoveAccount} = this.props;
        return (
            <div className='accountContainer' key={key}>
                <p className='common-icon-cross deleteAccount' title='Verwijder' onClick={onRemoveAccount(account)}/>
                <BdhContainedInput value={account.label} label='Naam' onChange={onChangeAccount(account, 'label')}
                                   fullWidth/>
                <BdhContainedInput value={account.username} label='Gebruikersnaam'
                                   onChange={onChangeAccount(account, 'username')} fullWidth/>
                <BdhPasswordInputController onChange={onChangeAccount(account, 'password')} value={account.password}
                                            fullWidth passProtect onConfirmPassword={onConfirmPassword}
                                            label='Wachtwoord' passwordConfirmedAt={passConfirmedAt}
                                            variant='contained' onOpenConfirmation={this.onOpenPasswordConfirmation}
                                            onCloseConfirmation={this.onClosePasswordConfirmation}/>
            </div>
        )
    };

    renderDossiersTab = () => {
        const {contact, onClickNewDossier} = this.props, dossiers = contact.dossiers;
        return (
            <div className='contactLinks'>
                {dossiers.map(this.renderContactDossier)}
                <div className='linkContainer new' onClick={onClickNewDossier}>
                    <label>Koppelingen beheren</label>
                    <Edit className='editIcon'/>
                </div>
            </div>
        );
    };

    renderContactDossier = (dossier, key) => {
        const {onClickDossier, onRemoveDossier} = this.props;
        return (
            <div key={key} className='linkContainer' onClick={onClickDossier(dossier)}>
                <p className='common-icon-cross deleteDossier' title='Verwijder' onClick={onRemoveDossier(dossier)}/>
                <label className='dossierName' title={dossier.label}>{dossier.label}</label>
                <label className='dossierType' title={dossier.type.label}>{dossier.type.label}</label>
                <label className='navButton'>Naar dossier</label>
            </div>
        );
    };

    render = () => {
        const {onClose} = this.props;
        return (
            <WithBackground onEscape={onClose} onClose={this.onClickBackground}>
                <div className='contactDetailsPopup' id='addContactPopup'>
                    {this.renderHeader()}
                    {this.renderBody()}
                </div>
            </WithBackground>
        )
    };
};

ContactDetailView.propTypes = {
    onChange: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onChangeTab: PropTypes.func.isRequired,
    onConfirmPassword: PropTypes.func.isRequired,
    onClickNewAccount: PropTypes.func.isRequired,
    onClickNewDossier: PropTypes.func.isRequired,
    onChangeAccount: PropTypes.func.isRequired,
    onRemoveAccount: PropTypes.func.isRequired,
    onRemoveDossier: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onClickDossier: PropTypes.func.isRequired,
    contact: PropTypes.instanceOf(Contact).isRequired,
    activeTab: PropTypes.oneOf(['data', 'accounts', 'dossiers']).isRequired,
    loading: PropTypes.bool.isRequired,
    passConfirmedAt: PropTypes.instanceOf(moment),
    isNew: PropTypes.bool.isRequired,
    hasChanges: PropTypes.bool.isRequired,
};