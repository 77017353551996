export const actionConstants = {
    AGENDA_PASPOORT1: 'AGENDA_PASPOORT1',
    AGENDA_RIJBEWIJS1: 'AGENDA_RIJBEWIJS1',
    AGENDA_ID1: 'AGENDA_ID1',
    AGENDA_OV1: 'AGENDA_OV1',
    AGENDA_TENTAMEN1: 'AGENDA_TENTAMEN1',
    AGENDA_INSCHRIJVING1: 'AGENDA_INSCHRIJVING1',
    AGENDA_HERINSCHRIJVING1: 'AGENDA_HERINSCHRIJVING1',
    AGENDA_PINPAS1: 'AGENDA_PINPAS1',
    AGENDA_CREDITCARD1: 'AGENDA_CREDITCARD1',
    AGENDA_MEDISCH1: 'AGENDA_MEDISCH1',
    AGENDA_AFSCHRIJVING1: 'AGENDA_AFSCHRIJVING1',
    AGENDA_HUISTAAK1: 'AGENDA_HUISTAAK1',
    AGENDA_GARANTIE1: 'AGENDA_GARANTIE1',
    AGENDA_OV_STUDENT1: 'AGENDA_OV_STUDENT1',
    AGENDA_POLISGELD1: 'AGENDA_POLISGELD1',
    AGENDA_ABONNEMENT1: 'AGENDA_ABONNEMENT1',
    AGENDA_ENERGIECONTRACT1: 'AGENDA_ENERGIECONTRACT1',
    AGENDA_POLISINBOEDEL1: 'AGENDA_POLISINBOEDEL1',
    AGENDA_CONTRACT1: 'AGENDA_CONTRACT1',
    AGENDA_BETALING: 'AGENDA_BETALING',
    AGENDA_ABONNEMENTVT1: 'AGENDA_ABONNEMENTVT1',
};

export const actionTypeConstants = {
    ADD_AGENDAITEM: 'ADD_AGENDAITEM',
};