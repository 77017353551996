import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {sendGaEvent, sendPageView} from "../../../../common/js/ga";
import MaterialFactory from "../../../material/materialFactory";
import {materialTypes} from "../../../material/materialTypes";
import {NOW} from "../../../misc/constants";

const GA_CATEGORY = 'Registreren (Aan de slag - jongeren)';
export default class CompletionFree extends React.Component {
    constructor(props) {
        super(props);
    }

    onComplete = (destination) => () => {
        const gaLabel = ['startAnalyse', 'health'].includes(destination) ? 'Zorganalyse' : 'Dashboard';
        sendGaEvent(GA_CATEGORY, 'Klik', gaLabel);
        this.props.onComplete(destination);
    };

    renderText = () => {
        if (this.props.flex.birthday)
            return <p className='message'>
                Beste {this.props.firstName},<br/>
                De setup van je account is voltooid!
            </p>;
        else return <p className='message'>
            Bedankt voor je aanmelding bij ByDeHand! Je kunt nu gebruik maken van je eigen Personal Organizer. Neem dus
            snel een kijkje en ga aan de slag!
        </p>
    };

    renderButtons = () => {
        if (window.localStorage) {
            const storage = window.localStorage;
            let healthData = storage.getItem('bdhHealthAnalysis');
            if (isNotNull(healthData)) {
                healthData = JSON.parse(healthData);
                if (moment(healthData.expires).isSameOrAfter(NOW())) {
                    return <MaterialFactory componentType={materialTypes.RAISED_BUTTON} className='completeButton'
                                            onClick={this.onComplete('startAnalyse')}>Analyseer je
                        zorgverzekering</MaterialFactory>
                }
            }
        }
        return (
            <>
                <MaterialFactory componentType={materialTypes.RAISED_BUTTON} className='completeButton'
                                 onClick={this.onComplete('dashboard')}>Aan de slag</MaterialFactory>
                {/*<MaterialFactory componentType={materialTypes.RAISED_BUTTON} className='completeButton'*/}
                                 {/*onClick={this.onComplete('health')}>Analyseer je*/}
                    {/*zorgverzekering</MaterialFactory>*/}
            </>
        )
    };

    componentDidMount = () => {
        ga('set', 'page', '/registratie/5-voltooid-gratis');
        sendPageView();
    };

    render = () => {
        return (
            <div className='completionMessage'>
                <span className='common-icon-check completionCheck'/>
                {this.renderText()}
                <div className='completeButtonsContainer'>
                    {this.renderButtons()}
                </div>
                <label className='faqText'>Heb je nog vragen? Kijk dan op onze <span className='faqLink'
                                                                                     onClick={this.props.onClickFaq}>
                    FAQ</span>.</label>
            </div>
        );
    };
}

CompletionFree.propTypes = {
    onClickFaq: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
    firstName: PropTypes.string,
    flex: PropTypes.object,
};