import '../style/compare.scss';

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as Actions from '../../../../../redux/actions';


import CompareView from './compareView';
import {getFromRegistry} from "../../misc/registry";
import {navigate} from "../../../../../misc/utils";
import {sendPageView} from "BdhCommon/js/ga";

export class CompareController extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount = () => {
        sendPageView('/zorganalyse/aanpassen/vergelijken');
    };

    getAlternatives = () => {
        return getFromRegistry(['healthInsurance', 'editInsurance', 'alternatives']);
    };

    navigateUrl = () => {
        this.props.actions.showFb(true);
        navigate('/zorganalyse/jouw-verzekering/afsluiten', true);
    };

    render = () => {
        return (
            <div>
                <CompareView navigate={this.navigateUrl} alternatives={this.getAlternatives()}/>
                {/*<RatingController showPopup={!this.props.healthState.ratingShown}/>*/}
            </div>
        )
    }
}

CompareController.propTypes = {
    activateFbShare: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    healthState: state.healthInsuranceAnalysis
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CompareController);