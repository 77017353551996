import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import InputAdornment from '@material-ui/core/InputAdornment';

import {pathIsNotNull, toMoment} from 'glob-common-js/lib/utils';

import {validateAccount} from "./accountValidator";
import SubscriptionDetails from "../subscriptionDetails";
import MaterialFactory from "../../material/materialFactory";
import {materialTypes} from "../../material/materialTypes";
import {NOW} from "../../misc/constants";
import {dispatchCustomEvent} from "../../../misc/eventDispatcher";
import {CANCEL_SUBSCRIPTION} from "../../cancellation/cancellationController";

const months = ['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'Oktober',
    'November',
    'December'];
export default class EditAccount extends React.Component {
    constructor(props) {
        super(props);

        const initState = this.initState();
        this.state = {
            values: initState.values,
            errors: {
                firstname: null,
                bsn: null,
                zipcode: null,
                phone_mobile: null,
                username: null,
                email: null,
            },
            profileImage: initState.profileImage,
            cancelDialogOpen: false,
        };
        this.profileImageChanged = false;
        this._isMounted = false;
    }

    changeState = stateChange => {
        this._isMounted && this.setState(stateChange);
    };

    initState = () => {
        let account = Object.assign({}, this.props.account);
        let initState = {};
        if (isNotNull(account)) {
            const addressField = name => pathIsNotNull(account.address, name) ? account.address[name] : '';
            account.phone_mobile = this.getPhoneMobile(account);
            let dob = isNotNull(account.dob) ? toMoment(account.dob) : NOW();
            initState = {
                firstname: account.firstname || '',
                infix: account.infix || '',
                lastname: account.lastname || '',
                dob: dob.format('YYYY-MM-DD'),
                gender: account.gender || 0,
                bsn: account.bsn || '',
                address: {
                    city: addressField('city'),
                    house_number: addressField('house_number'),
                    house_number_suffix: addressField('house_number_suffix'),
                    street: addressField('street'),
                    zipcode: addressField('zipcode'),
                },
                email: account.email || '',
                phone_mobile: account.phone_mobile || '',
                phone_number: account.phone_number || '',
                two_step_auth: account.two_step_auth || 0,
                username: account.username || '',
            };
        }
        this.changeState({
            values: initState,
            profileImage: account.profile_image,
        });
        return {values: initState, profileImage: account.profile_image};
    };

    getPhoneMobile = user => {
        if (pathIsNotNull(user, 'phone_mobile')) {
            let mobile = user.phone_mobile;
            if (mobile.startsWith('06')) return mobile.substr(2);
            return mobile;
        }
        return null;
    };

    onSubmit = () => {
        let values = this.state.values;
        values.profileImage = this.profileImageChanged ? this.state.profileImage : false;
        let errors = validateAccount(values);
        if (errors.count > 0) {
            // Form contains errors
            this.changeState({errors});
        } else {
            let zipcode = values.address.zipcode;
            if (isNotNull(zipcode) && zipcode.includes(' ')) {
                zipcode = zipcode.replace(/ /g, '');
                values.address.zipcode = zipcode;
            }
            this.props.onSubmit(values);
        }
    };

    onSelectProfileImage = evt => {
        this.profileImageChanged = true;
        const file = evt.target.files[0];
        this.changeState({
            profileImage: file,
        })
    };

    onChangeField = (name, value) => {
        this.changeState({
            values: {
                ...this.state.values,
                [name]: value,
            },
            errors: {
                firstname: null,
                bsn: null,
                zipcode: null,
                phone_mobile: null,
                username: null,
            }
        })
    };

    onChangeAddressField = (name, value) => {
        let address = this.state.values.address;
        address[name] = value;
        this.changeState({
            values: {
                ...this.state.values,
                address,
            },
            errors: {
                firstname: null,
                bsn: null,
                zipcode: null,
                phone_mobile: null,
                username: null,
            }
        })
    };

    getProfileImageUrl = () => {
        const profileImage = this.state.profileImage;
        if (isNull(profileImage)) return null;
        if (typeof profileImage === 'string') return profileImage;
        return URL.createObjectURL(profileImage);
    };

    onCloseCancelDialog = () => {
        this.changeState({cancelDialogOpen: false});
    };

    onCancelSubscription = () => {
        this.onCloseCancelDialog();
        this.props.onCancelSubscription();
    };

    openCancelSubscription = () => {
        dispatchCustomEvent('showCancellation', CANCEL_SUBSCRIPTION);
    };

    renderError = () => {
        let count = this.state.errors.count;
        if (count > 0) {
            let text = count;
            if (count === 1) text += ' veld is niet goed ingevuld';
            else text += ' velden zijn niet goed ingevuld';
            return (
                <span className='errorText'>{text}</span>
            );
        }
        return null;
    };

    renderTextField = (name, label, errorText = null, type = 'text') => (
        <MaterialFactory componentType={materialTypes.TEXT} label={label} helperText={errorText}
                         error={isNotNull(errorText)} value={this.state.values[name]} type={type} fullWidth
                         onChange={(evt) => {
                             this.onChangeField(name, evt.target.value);
                         }}/>
    );

    renderCancelActions = () => ([
        {label: 'Abonnement opzeggen', onClick: this.onCancelSubscription},
        {label: 'Annuleren', onClick: this.onCloseCancelDialog},
    ]);

    renderPersonal = () => {
        const {errors, values} = this.state;
        return (
            <div className='editAccountBlock'>
                <label className='blockTitle'>Persoonlijke gegevens</label>
                {this.renderTextField('firstname', 'Voornaam', errors.firstname)}
                {this.renderTextField('infix', 'Tussenvoegsel')}
                {this.renderTextField('lastname', 'Achternaam')}
                <MaterialFactory componentType={materialTypes.DATE_PICKER} fullWidth margin='normal'
                                 value={values.dob} onChange={(date) => {
                    this.onChangeField('dob', date);
                }} label='Geboortedatum' keyboard/>
                <MaterialFactory componentType={materialTypes.SELECT} label='Geslacht'
                                 value={values.gender}
                                 items={[{value: 0, label: 'Man'}, {value: 1, label: 'Vrouw'}]}
                                 fullWidth onChange={(evt) => {
                    this.onChangeField('gender', evt.target.value);
                }}/>
                {this.renderTextField('bsn', 'BSN-nummer', errors.bsn)}
                {this.renderAddressRow()}
                <MaterialFactory componentType={materialTypes.TEXT} label='Postcode'
                                 helperText={errors.zipcode}
                                 error={isNotNull(errors.zipcode)}
                                 value={values.address.zipcode} fullWidth
                                 onChange={(evt) => {
                                     this.onChangeAddressField('zipcode', evt.target.value);
                                 }}/>
                <MaterialFactory componentType={materialTypes.TEXT} label='Woonplaats'
                                 value={values.address.city} fullWidth
                                 onChange={(evt) => {
                                     this.onChangeAddressField('city', evt.target.value);
                                 }}/>
            </div>
        )
    };

    renderAddressRow = () => {
        const {values} = this.state;
        return (
            <div className='addressRow'>
                <MaterialFactory componentType={materialTypes.TEXT} label='Straat'
                                 className='streetField'
                                 value={values.address.street}
                                 onChange={(evt) => {
                                     this.onChangeAddressField('street', evt.target.value);
                                 }}/>
                <MaterialFactory componentType={materialTypes.TEXT} type='number' label='Huisnummer'
                                 className='houseNumberField' value={values.address.house_number}
                                 onChange={(evt) => {
                                     this.onChangeAddressField('house_number', evt.target.value);
                                 }}/>
                <MaterialFactory componentType={materialTypes.TEXT}
                                 label='Toevoeging' className='houseNumberField'
                                 value={values.address.house_number_suffix}
                                 onChange={(evt) => {
                                     this.onChangeAddressField('house_number_suffix', evt.target.value);
                                 }}/>
            </div>
        )
    };

    renderContact = () => {
        const {errors, values} = this.state;
        return (
            <div className='editAccountBlock'>
                <label className='blockTitle'>Contactgegevens</label>
                {this.renderTextField('email', 'E-mailadres', errors.email, 'email')}
                {this.renderTextField('phone_number', 'Telefoon vast')}
                <MaterialFactory componentType={materialTypes.TEXT} InputProps={{
                    startAdornment: <InputAdornment position="start">06-</InputAdornment>,
                }} label='Telefoon mobiel' className='mobileField' fullWidth helperText={errors.phone_mobile}
                                 error={isNotNull(errors.phone_mobile)} value={values.phone_mobile} onChange={(evt) => {
                    this.onChangeField('phone_mobile', evt.target.value);
                }}/>
            </div>
        );
    };

    renderTwoAuth = () => {
        const {values} = this.state;
        return (
            <div className='editAccountBlock'>
                <label className='blockTitle'>Two-factor authenticatie</label>
                <p className='blockText'>
                    Met two-factor authenticatie zorg je voor extra beveiliging van je
                    account.
                </p>
                <MaterialFactory componentType={materialTypes.CHECKBOX} className='twoStepBox'
                                 label='Two-factor authenticatie inschakelen' labelPlacement='end'
                                 checked={values.two_step_auth === 1}
                                 onChange={(evt) => {
                                     this.onChangeField('two_step_auth', evt.target.checked ? 1 : 0);
                                 }}/>
            </div>
        );
    };

    renderProfileImage = () => {
        const {profileImage} = this.state;
        return (
            <div className='editAccountBlock'>
                <label className='blockTitle'>Profielfoto</label>
                <div className='profileImageContainer'>
                    {isNotNull(profileImage) ?
                        <>
                            <span className='common-icon-trash deleteProfileImage' title='Verwijderen' onClick={() => {
                                this.changeState({profileImage: null});
                                this.profileImageChanged = true;
                            }}/>
                            <span style={{backgroundImage: `url(${this.getProfileImageUrl()})`}}
                                  className='profileImage'/>
                        </> :
                        <span className='common-icon-profile profileIcon'/>}
                    <input type='file' accept='image/*' style={{display: 'none'}}
                           id='raisedFileUpload' onChange={this.onSelectProfileImage}/>
                    <label htmlFor='raisedFileUpload'>
                        <MaterialFactory componentType={materialTypes.RAISED_BUTTON} component='span'>
                            Aanpassen
                        </MaterialFactory>
                    </label>
                </div>
            </div>
        );
    };

    renderBDHMail = () => {
        const {errors, values} = this.state;
        return (
            <div className='editAccountBlock'>
                <label className='blockTitle'>ByDeHand mail account</label>
                <p className='blockText'>Geen gedoe meer met het handmatig toevoegen van al je
                    bonnetjes en documenten. Mail je documenten naar jouw persoonlijke ByDeHand mail
                    en ze staan meteen in je Bibliotheek.</p>
                <MaterialFactory componentType={materialTypes.TEXT} label='Gebruikersnaam' className='usernameField'
                                 helperText={errors.username} error={isNotNull(errors.username)}
                                 value={values.username} InputProps={{
                    endAdornment: <InputAdornment position="end">@bydehand.com</InputAdornment>
                }} onChange={(evt) => {
                    this.onChangeField('username', evt.target.value);
                }}/>
            </div>
        );
    };

    componentDidMount = () => {
        this._isMounted = true;
    };

    componentDidUpdate = prevProps => {
        let prevUser = prevProps.account, user = this.props.account;
        if (pathIsNotNull(user, 'email') && isNull(prevUser, 'email')) {
            this.initState();
        }
    };

    componentWillUnmount = () => {
        this._isMounted = false;
    };

    render = () => {
        return (
            pathIsNotNull(this.props.account, 'email') ?
                <>
                    {/*<MaterialFactory componentType={materialTypes.DIALOG} title='Abonnement opzeggen'*/}
                    {/*open={this.state.cancelDialogOpen} onClose={this.onCloseCancelDialog}*/}
                    {/*actions={this.renderCancelActions()} text='Weet je zeker dat je je abonnement wilt*/}
                    {/*opzeggen? Hierna heb je geen toegang meer tot je account en gegevens.'/>*/}
                    <div className='editAccount'>
                        <div className='formContainer'>
                            <div className='formBlock'>
                                {this.renderPersonal()}
                                {this.renderContact()}
                            </div>
                            <div className='formBlock'>
                                {this.renderTwoAuth()}
                                {this.renderProfileImage()}
                                {this.renderBDHMail()}
                                <div className='editAccountBlock'>
                                    <label className='blockTitle'>Abonnementsgegevens</label>
                                    <SubscriptionDetails onStopSubscription={this.openCancelSubscription}/>
                                </div>
                            </div>
                        </div>
                        <div className='formFooter'>
                            {this.renderError()}
                            <div className='buttonContainer'>
                                <MaterialFactory componentType={materialTypes.RAISED_BUTTON}
                                                 onClick={this.props.onCancel}>Annuleren</MaterialFactory>
                                <MaterialFactory componentType={materialTypes.RAISED_BUTTON}
                                                 onClick={this.onSubmit}>Opslaan</MaterialFactory>
                            </div>
                        </div>
                    </div>
                </>
                :
                <div className='editAccountLoader'>
                    <MaterialFactory componentType={materialTypes.CIRCULAR_PROGRESS}/>
                    <label className='editAccountLoadLabel'>Account laden</label>
                </div>
        )
    }
}

EditAccount.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancelSubscription: PropTypes.func.isRequired,
    account: PropTypes.shape({
        address: PropTypes.shape({
            city: PropTypes.string,
            house_number: PropTypes.string,
            house_number_suffix: PropTypes.string,
            street: PropTypes.string,
            zipcode: PropTypes.string,
        }),
        bsn: PropTypes.string,
        dob: PropTypes.string,
        firstname: PropTypes.string.isRequired,
        gender: PropTypes.oneOf([0, 1]).isRequired,
        infix: PropTypes.string,
        lastname: PropTypes.string,
        phone_mobile: PropTypes.string,
        phone_number: PropTypes.string,
        two_step_auth: PropTypes.oneOf([0, 1]).isRequired,
        username: PropTypes.string.isRequired,
    }),
};