import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';

import {generateUUID} from 'glob-common-js/lib/utils';

import {JSSPopupBuilder} from "./style/JSSPopupBuilder";

class PopupBuilder extends React.Component {
    constructor(props) {
        super(props);
        this.overflowY = null;
    }

    removeOverflow = () => {
        this.overflowY = document.documentElement.style.overflowY || 'unset';
        document.documentElement.style.overflowY = 'hidden';
    };

    restoreOverflow = () => {
        if (isNotNull(this.overflowY))
            document.documentElement.style.overflowY = this.overflowY;
    };

    renderBackground = () => {
        const {withBackground, backgroundColor, classes, backgroundClass, open} = this.props;
        if (withBackground)
            return <div className={classNames(classes.backgroundRoot, backgroundClass)}
                        style={{backgroundColor}}/>;
        return null;
    };

    renderHeader = () => {
        const {headerClass, content, classes} = this.props;
        const {header} = content;
        return (
            <div className={classNames(classes.headerRoot, headerClass)}>
                {header}
            </div>
        );
    };

    renderBody = () => {
        const {bodyClass, content, classes} = this.props;
        const {body} = content;
        return (
            <div className={classNames(classes.bodyRoot, bodyClass)}>
                {body}
            </div>
        );
    };

    renderFooter = () => {
        const {footerClass, content, classes} = this.props;
        const {footer} = content;
        return (
            <div className={classNames(classes.footerRoot, footerClass)}>
                {footer}
            </div>
        )
    };

    componentDidUpdate = (prevProps) => {
        if (!prevProps.open && this.props.open) {
            this.removeOverflow();
        } else if (prevProps.open && !this.props.open) {
            this.restoreOverflow();
        }
    };

    componentWillUnmount = () => {
        this.restoreOverflow();
    };

    render = () => {
        const {classes, open, containerClass, content, id} = this.props;
        return open ? (
            <>
                {this.renderBackground()}
                <div className={classNames(classes.containerRoot, containerClass)} id={id}>
                    {isNotNull(content.loader) && content.loader}
                    {this.renderHeader()}
                    {this.renderBody()}
                    {this.renderFooter()}
                </div>
            </>
        ) : null;
    }
}

PopupBuilder.propTypes = {
    open: PropTypes.bool.isRequired,
    withBackground: PropTypes.bool,
    backgroundColor: PropTypes.string,
    clickAwayEnabled: PropTypes.bool,
    backgroundClass: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    containerClass: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    headerClass: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    bodyClass: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    footerClass: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    content: PropTypes.shape({
        header: PropTypes.node.isRequired,
        body: PropTypes.node.isRequired,
        footer: PropTypes.node.isRequired,
        loading: PropTypes.node,
    }).isRequired,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    id: PropTypes.string,
};

PopupBuilder.defaultProps = {
    withBackground: true,
    clickAwayEnabled: true,
    backgroundColor: 'black',
    id: generateUUID(),
};

const mapStateToProps = state => ({
    userState: state.user,
});

export default connect(mapStateToProps)(withStyles(JSSPopupBuilder)(PopupBuilder));