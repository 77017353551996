import './style/testMail.scss';

import React from 'react';
import PropTypes from 'prop-types';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel';

import {getListObject} from 'glob-common-js/lib/utils';

import StateComponent from "../../misc/stateComponent";
import {NOW} from "../../misc/constants";
import {GET_LIST} from "./testMailController";
import MaterialFactory from "../../material/materialFactory";
import {materialTypes} from "../../material/materialTypes";
import GenericLoader from "../../misc/genericLoader";

export default class TestMail extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            task: GET_LIST,
            filters: [],
            filter_type: 'and',
            critParams: {
                key: 'created_at',
                value: '',
                operator: '>',
            },
            taskParams: {
                rule_name: '',
                rule_body: {},
                send_date: NOW().toDate(),
                function_type: GET_LIST,
                frequency_type: 'instant',
                frequency: {},
            },
        };
    }

    onChange = name => evt => {
        this.changeState({[name]: evt.target.value});
    };

    onChangeTaskParam = name => evt => {
        const {taskParams} = this.state;
        taskParams[name] = evt.target.value;
        this.changeState({taskParams});
    };

    onChangeCritKey = evt => {
        const key = evt.target.value;
        let value = '';
        switch (key) {
            case 'status':
                value = 'active';
                break;
            case 'registered':
            case 'mail_subscribed':
                value = 1;
                break;
        }
        this.changeState({
            critParams: {key, value, operator: '='},
        });
    };

    onChangeCritParam = name => evt => {
        const critParams = this.state.critParams;
        critParams[name] = evt.target.value;
        this.changeState({critParams});
    };

    onAddFilter = () => {
        const {filters, critParams} = this.state;
        filters.push(Object.assign({}, critParams));
        this.changeState({
            filters,
        });
    };

    onSubmit = () => {
        const {task, filters, taskParams, filter_type} = this.state;
        this.props.onSubmit(task, filters, taskParams, filter_type);
    };

    onReset = () => {
        this.changeState({filters: []});
    };

    getTaskItems = () => ([
        {value: GET_LIST, label: 'Gebruikers ophalen'},
        // {value: CREATE_TASK, label: 'Taak toevoegen'},
    ]);

    getOperatorItems = () => {
        const filter = this.state.critParams.key;
        let operators = [{value: '=', label: 'Is gelijk aan'}, {value: '!=', label: 'Is niet gelijk aan'}];

        if (filter === 'created_at')
            operators = operators.concat([
                {value: '>', label: 'Na'},
                {value: '<', label: 'Voor'}
            ]);
        return operators;
    };

    getFilters = () => ([
        {value: 'created_at', label: 'Datum geregistreerd'},
        {value: 'id', label: 'ID'},
        {value: 'email', label: 'E-mailadres'},
        {value: 'username', label: 'Gebruikersnaam'},
        {value: 'status', label: 'Status'},
        {value: 'registered', label: 'Account geactiveerd'},
        {value: 'mail_subscribed', label: 'Ontvangt mail'},
        {value: 'type', label: 'Type'},
    ]);

    getTrueFalse = () => ([
        {value: 1, label: 'Ja'},
        {value: 0, label: 'Nee'},
    ]);

    getStatusItems = () => ([
        {value: 'active', label: 'Actief'},
        {value: 'first_login', label: 'Niet actief'},
        {value: 'inactive', label: 'Inactief'},
        {value: 'deleted', label: 'Verwijderd'},
    ]);

    getFilterValue = filter => {
        const {key, value} = filter;
        switch (key) {
            case 'registered':
            case 'mail_subscribed':
                return value === 1 ? 'Ja' : 'Nee';
            case 'status':
                return getListObject(this.getStatusItems(), value, 'value').label;
            default:
                return value;
        }
    };

    renderListTask = () => {
        const {filters, critParams, filter_type} = this.state;
        const {key, operator, value} = critParams;
        return (
            <div className='listTask'>
                <div className='critSelection'>
                    <MaterialFactory componentType={materialTypes.SELECT} items={this.getFilters()}
                                     onChange={this.onChangeCritKey} value={key} label='Type'/>
                    <MaterialFactory componentType={materialTypes.SELECT} items={this.getOperatorItems()}
                                     value={operator} onChange={this.onChangeCritParam('operator')} label='Regel'/>
                    {this.renderFilterField()}
                    <MaterialFactory componentType={materialTypes.RAISED_BUTTON}
                                     disabled={!areNotNull(key, operator, value)}
                                     onClick={this.onAddFilter}>Toevoegen</MaterialFactory>
                </div>
                <div className='selectedCriteria'>
                    <div className='filterContainer head'>
                        <label
                            className='filterLabel'>Filtertype
                        </label>
                        <label
                            className='operatorLabel'>Regel</label>
                        <label className='filterValue'>Waarde</label>
                    </div>
                    {filters.map((filter, key) => {
                        return <div key={key} className='filterContainer'>
                            <label
                                className='filterLabel'>{getListObject(this.getFilters(), filter.key, 'value').label}
                            </label>
                            <label
                                className='operatorLabel'>{getListObject(this.getOperatorItems(), filter.operator,
                                'value').label}</label>
                            <label className='filterValue'>{this.getFilterValue(filter)}</label>
                        </div>
                    })}
                </div>
                <div className='resetContainer'>
                    <MaterialFactory componentType={materialTypes.FLAT_BUTTON}
                                     onClick={this.onReset} disabled={isNull(filters)}>Reset</MaterialFactory>
                </div>
                <RadioGroup value={filter_type} onChange={this.onChange('filter_type')} className='radioContainer'>
                    <FormControlLabel control={<Radio/>} label='Voldoet aan alle regels' value='and'/>
                    <FormControlLabel control={<Radio/>} label='Voldoet aan 1 van de regels' value='or'/>
                </RadioGroup>
                <MaterialFactory componentType={materialTypes.RAISED_BUTTON} onClick={this.onSubmit}
                                 className='execute'>
                    Uitvoeren
                </MaterialFactory>
            </div>
        )
    };

    renderListResult = () => {
        const {listResult} = this.props;
        if (isNotNull(listResult)) {
            return (
                <div className='listResult'>
                    <div className='resultRow head'>
                        <label className='resultIndex'>#</label>
                        <label className='resultId'>ID</label>
                        <label className='resultCreated'>Geregistreerd op</label>
                        <label className='resultEmail'>E-mailadres</label>
                        <label className='resultStatus'>Status</label>
                        <label className='resultSub'>Ontvangt mail</label>
                    </div>
                    {listResult.map((result, key) => (
                        <div key={key} className='resultRow'>
                            <label className='resultIndex'>{key + 1}</label>
                            <label className='resultId'>{result.id}</label>
                            <label className='resultCreated'>{result.created_at}</label>
                            <label className='resultEmail'>{result.email}</label>
                            <label className='resultStatus'>
                                {getListObject(this.getStatusItems(), result.status, 'value').label}
                            </label>
                            <label className='resultSub'>{result.mail_subscribed === 1 ? 'Ja' : 'Nee'}</label>
                        </div>
                    ))}
                </div>
            )
        }
        return null;
    };

    renderFilterField = () => {
        const filter = this.state.critParams.key;
        const value = this.state.critParams.value;
        switch (filter) {
            case 'id':
            case 'email':
            case 'username':
            case 'type':
            case 'created_at':
                return <MaterialFactory componentType={materialTypes.TEXT} value={value}
                                        onChange={this.onChangeCritParam('value')} label='Waarde'/>;
            case 'status':
                return <MaterialFactory componentType={materialTypes.SELECT} items={this.getStatusItems()}
                                        value={value} onChange={this.onChangeCritParam('value')} label='Waarde'/>;
            case 'registered':
            case 'mail_subscribed':
                return <MaterialFactory componentType={materialTypes.SELECT} items={this.getTrueFalse()} value={value}
                                        onChange={this.onChangeCritParam('value')} label='Waarde'/>
        }
    };

    renderAddTask = () => {
        return (
            <div className='addTask'>

            </div>
        )
    };

    componentDidMount = () => {
        this.mount();
    };

    componentWillUnmount = () => {
        this.unMount();
    };

    render = () => {
        const {task} = this.state;
        const {loading} = this.props;
        return (
            <div className='testMail'>
                <GenericLoader active={loading}/>
                <div className='taskSelection'>
                    <label className='taskLabel'>Doel:</label>
                    <MaterialFactory componentType={materialTypes.SELECT} items={this.getTaskItems()} value={task}
                                     onChange={this.onChange('task')}/>
                </div>
                {task === GET_LIST ?
                    <>
                        {this.renderListTask()}
                        {this.renderListResult()}
                    </> : this.renderAddTask()}
            </div>
        )
    };
}

TestMail.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    listResult: PropTypes.arrayOf(PropTypes.shape({
        email: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        mail_subscribed: PropTypes.bool,
        status: PropTypes.string.isRequired,
        type: PropTypes.string,
        username: PropTypes.string.isRequired,
    })).isRequired,
};