import './style/dossierDetails.scss';

import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import AddCircleOutlined from '@material-ui/icons/AddCircleOutlineOutlined';
import classNames from 'classnames';

import {deviceIsMobile, generateUUID, isFunction, pathIsNotNull} from 'glob-common-js/lib/utils';

import {sendGaEvent} from "../../../common/js/ga";
import ExplanationFrame from "../../document/explanationFrame";
import MaterialFactory from "../../material/materialFactory";
import {materialTypes} from "../../material/materialTypes";
import CustomScrollbar from "../../misc/customScrollbar";
import {getOnboardingManager} from "../../onboarding/manager/onboardingManagerHelper";
import Dossier from "../../../models/dossier";
import StateComponent from "../../misc/stateComponent";
import GenericLoader from "../../misc/genericLoader";
import {getCustomEventParam, removeCustomEventParam} from "../../../misc/eventDispatcher";

const styles = () => ({
    tableRoot: {
        height: '100%',
        width: '100%',
        display: 'block',
        overflowX: 'hidden',
    },
    clickableCell: {
        cursor: 'pointer',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textDecoration: 'none',
        textOverflow: 'ellipsis',
    },
    addIcon: {
        color: '#009fe3',
        transition: 'color .2s ease',
        cursor: 'pointer',
    },
    hoverTransition: {
        '&:hover': {
            color: '#0eb6ff',
        }
    },
    menuRoot: {
        display: 'flex',
        flexDirection: 'column',
    }
});
const GA_CATEGORY = 'Dossier details';

export class DossierDetailsView extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            actionsActive: false,
            anchorEl: null,
            libraryOpen: false,
            frameActive: false,
            activeElement: 'data',
        };
    };

    clickAction = (action, type) => {
        this.changeState({activeElement: action.action});
        if (isFunction(action.action))
            this.props.clickAction(action, type);
    };

    openFrame = () => {
        sendGaEvent(GA_CATEGORY, 'Open filmpje', 'Documenten');
        this.changeState({frameActive: true});
    };

    closeFrame = () => {
        sendGaEvent(GA_CATEGORY, 'Sluit filmpje', 'Documenten');
        this.changeState({frameActive: false});
    };

    onClickDocumentAction = type => () => {
        this.onClosePopover();
        const {onDeleteDocuments, onUnlinkDocuments, onDownloadDocuments, onShareDocuments} = this.props;
        const actions = {
            delete: onDeleteDocuments,
            unlink: onUnlinkDocuments,
            download: onDownloadDocuments,
            share: onShareDocuments,
        };
        actions[type]();
    };

    onClickDocument = (doc) => (evt) => {
        const target = evt.target;
        // Only open document when not clicked on checkbox
        if (isNull(target) || isNull(target.type) || target.type !== 'checkbox')
            if (!getOnboardingManager('store').isActive())
                this.props.onClickDocument(doc);
    };

    onSelectDocument = doc => (evt) => {
        this.props.onSelectDocument(doc);
    };

    onActionsClick = (evt) => {
        evt.preventDefault();
        this.changeState({actionsActive: true, anchorEl: evt.currentTarget});
    };

    onClosePopover = () => {
        this.changeState({actionsActive: false, anchorEl: null});
    };

    onClickLibrary = () => {
        sendGaEvent('Dossier details', 'Toevoegen uit bibliotheek', 'Open popup');
        this.props.onClickLibrary();
    };

    toggleFirstAction = () => {
        const {dossier, clickAction} = this.props, {actions} = dossier.type;
        if (isNotNull(actions)) {
            if (getCustomEventParam('showDossierContacts')) {
                for (const action of actions) {
                    if (action.label === 'Contacten') {
                        clickAction(action);
                        return;
                    }
                }
                if (isNotNull(actions[0]))
                    actions[0].action(dossier);
            } else if (isNotNull(actions[0]))
                actions[0].action(dossier);
        }
    };

    renderDossierActions = () => {
        const actions = this.props.dossier.type.actions;
        if (isNotNull(actions))
            return actions.map(this.renderDossierAction);
        return [];
    };

    renderDossierAction = (action, key) => {
        const activeActions = Object.values(this.props.activeActions);
        const className = classNames('dossierAction', activeActions.includes(action.label) && 'active',
            isNotNull(action.css) && action.css, action.disabled && 'disabled');
        return (
            <div key={key} className={className}
                 onClick={() => {
                     if (!action.disabled)
                         this.clickAction(action)
                 }}>
                {action.label}
            </div>
        );
    };

    renderDocumentsColumn = () => {
        let activeDocumentColumn = this.props.activeActions.documents;
        if (activeDocumentColumn === 'Gekoppelde documenten') return this.renderDocuments();
        else if (activeDocumentColumn === 'Nieuwe documenten') return this.renderNewDocuments();
    };

    renderDocuments = () => {
        const {classes, selectedDocuments, dossier} = this.props, {actionsActive, anchorEl} = this.state;
        const documents = dossier.documents;
        const onboardingActive = getOnboardingManager('store').isActive();
        if (isNotNull(documents)) {
            return (
                <div className='dossierDocumentsContainer'>
                    <div className='dossierDocumentsRow head'>
                        <div className='documentActionCol'>
                            <MaterialFactory onClick={this.onActionsClick} disabled={onboardingActive}
                                             componentType={materialTypes.RAISED_BUTTON}>Acties</MaterialFactory>
                        </div>
                        <div className='documentNameCol'>
                            <label className='documentLabel'>Naam</label>
                        </div>
                        <div className='documentTypeCol'>
                            <label className='documentLabel'>Documenttype</label>
                        </div>
                    </div>
                    <CustomScrollbar height='22rem'>
                        {documents.map((doc, key) => (
                            <div key={key} className='dossierDocumentsRow' onClick={this.onClickDocument(doc)}>
                                <div className='documentActionCol'>
                                    <MaterialFactory componentType={materialTypes.CHECKBOX}
                                                     checked={selectedDocuments.includes(doc.id)}
                                                     onChange={this.onSelectDocument(doc)}/>
                                </div>
                                <div className='documentNameCol'>
                                    <label className='documentLabel' title={doc.name}>{doc.name}</label>
                                </div>
                                <div className='documentTypeCol'>
                                    <label className='documentLabel' title={doc.type.label}>{doc.type.label}</label>
                                </div>
                            </div>
                        ))}
                    </CustomScrollbar>
                    <MaterialFactory componentType={materialTypes.MENU} open={actionsActive} anchorEl={anchorEl}
                                     onClose={this.onClosePopover} className={classes.menuRoot}>
                        <MaterialFactory componentType={materialTypes.MENU_ITEM}
                                         onClick={this.onClickDocumentAction('share')}>
                            Deel {isNotNull(selectedDocuments) ? selectedDocuments.length === 1 ? ' document' :
                            ' documenten' : ' alles'}
                        </MaterialFactory>
                        <MaterialFactory componentType={materialTypes.MENU_ITEM}
                                         onClick={this.onClickDocumentAction('download')}>
                            Download {isNotNull(selectedDocuments) ? selectedDocuments.length === 1 ? ' document' :
                            ' documenten' : ' alles'}
                        </MaterialFactory>
                        <MaterialFactory componentType={materialTypes.MENU_ITEM} onClick={
                            this.onClickDocumentAction('unlink')} disabled={isNull(selectedDocuments)}>
                            Ontkoppelen
                        </MaterialFactory>
                        <MaterialFactory componentType={materialTypes.MENU_ITEM} disabled={isNull(selectedDocuments)}
                                         onClick={this.onClickDocumentAction('delete')}>
                            Verwijderen</MaterialFactory>
                    </MaterialFactory>
                </div>
            )
        }
        return null;
    };

    renderNewDocuments = () => {
        const {dossier} = this.props, {frameActive} = this.state;
        let documentTypes = (dossier instanceof Dossier) ? dossier.getField('documentTypes', []).slice()
            : dossier.documentTypes.slice();
        documentTypes.sort((typeA, typeB) => {
            const preferA = -1, preferB = 1, isEqual = 0;
            let labelA = typeA.label, labelB = typeB.label;
            return labelA < labelB ? preferA : labelA > labelB ? preferB : isEqual;
        });
        const manager = getOnboardingManager('store');
        const scrollHeight = deviceIsMobile() ? '22rem' : '23rem';
        return (
            <div className='dossierNewDocumentsContainer'>
                <div className='fromLibraryContainer'>
                    <div className='infoContainer'>
                        <span className='common-icon-info infoIcon' onClick={this.openFrame}/>
                        <ExplanationFrame onClose={this.closeFrame} active={frameActive} placeRight/>
                    </div>
                    {!manager.isActive() && <MaterialFactory componentType={materialTypes.RAISED_BUTTON}
                                                             onClick={this.onClickLibrary}>
                        Kies bestand uit bibliotheek
                    </MaterialFactory>}
                </div>
                {isNotNull(documentTypes) && <CustomScrollbar height={scrollHeight} autoHide={false}>
                    <div className='documentTypesContainer'>
                        {this.renderNewDocumentRow({label: 'Type'}, true)}
                        {documentTypes.map(documentType => (
                            this.renderNewDocumentRow(documentType, false, documentType.id))
                        )}
                    </div>
                </CustomScrollbar>}
            </div>
        );
    };

    renderNewDocumentRow = (documentType, isHead, key) => {
        const {classes, onAddDocumentType} = this.props, wrapperProps = {
                className: classNames('newDocumentRow', isHead && 'head'),
            },
            renderWrapper = content => isHead ?
                <div{...wrapperProps}>{content}</div> :
                <div onClick={onAddDocumentType(documentType)} key={key} {...wrapperProps}>{content}</div>;
        return renderWrapper(
            <>
                <div className='btnCol'>
                    {!isHead && <AddCircleOutlined className={classNames(classes.addIcon, classes.hoverTransition)}/>}
                </div>
                <div className='labelCol'>
                    <label className='docTypeLabel'>{documentType.label}</label>
                </div>
            </>
        );
    };

    componentDidMount = () => {
        this.mount();
        this.toggleFirstAction();
    };

    componentDidUpdate = prevProps => {
        const {dossier} = this.props;
        if (isNull(prevProps.dossier.id) && isNotNull(dossier.id))
            this.toggleFirstAction();
    };

    componentWillUnmount = () => {
        this.unMount();
        removeCustomEventParam('showDossierContacts');
    };

    render = () => {
        const {dossier, loading} = this.props;
        const manager = getOnboardingManager('store');
        return (
            <div className='dossierDetails'>
                <GenericLoader active={loading}/>
                <div className='dossierDetailsHeader'>
                    <label className='dossierLabel'>{dossier.label}</label>
                    <div className='typeContainer'>
                        <span className={'typeIcon ' + dossier.type.icon}/>
                        <label className='typeLabel'>{dossier.type.label}</label>
                    </div>
                </div>
                <div className='dossierContainer'>
                    <div className='dossierColumn' id='dossierDetailsData'>
                        <div className='dossierActionsContainer'>
                            {this.renderDossierActions()}
                        </div>
                        <div className='detailsContainer'>
                            <div id={'details' + dossier.id}/>
                        </div>
                    </div>
                    <div className='dossierColumn' id='dossierDetailsDocuments'>
                        <div className='dossierActionsContainer'>
                            {this.renderDossierAction({
                                label: 'Gekoppelde documenten',
                                type: 'documents',
                                action: () => void (0),
                                css: 'half',
                                disabled: manager.isActive(),
                            }, generateUUID())}
                            {this.renderDossierAction({
                                label: 'Nieuwe documenten',
                                type: 'documents',
                                action: () => void (0),
                                css: 'half',
                                disabled: manager.isActive(),
                            }, generateUUID())}
                        </div>
                        {this.renderDocumentsColumn()}
                    </div>
                </div>
            </div>
        )
    };
}

DossierDetailsView.propTypes = {
    clickAction: PropTypes.func.isRequired,
    onClickDocument: PropTypes.func.isRequired,
    onSelectDocument: PropTypes.func.isRequired,
    onDeleteDocuments: PropTypes.func.isRequired,
    onUnlinkDocuments: PropTypes.func.isRequired,
    onAddDocumentType: PropTypes.func.isRequired,
    onClickLibrary: PropTypes.func.isRequired,
    onDownloadDocuments: PropTypes.func.isRequired,
    onShareDocuments: PropTypes.func.isRequired,
    selectedDocuments: PropTypes.arrayOf(PropTypes.string).isRequired,
    dossier: PropTypes.shape({
        label: PropTypes.string.isRequired,
        documents: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            type: PropTypes.shape({
                label: PropTypes.string.isRequired,
            }).isRequired,
        })),
        type: PropTypes.shape({
            name: PropTypes.string.isRequired,
            icon: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            actions: PropTypes.array,
        }).isRequired,
        documentTypes: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        })),
    }).isRequired,
    activeActions: PropTypes.shape({
        data: PropTypes.string.isRequired,
        documents: PropTypes.string.isRequired,
    }).isRequired,
    loading: PropTypes.bool.isRequired,
};
export default withStyles(styles)(DossierDetailsView);
