import './style/agendaView.scss';

import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import {withStyles} from '@material-ui/core/styles';

import {DAY_INDEX, PLANNED_INDEX, PLANNING_INDEX} from "./agendaController";
import AgendaDayController from "./day/agendaDayController";
import AgendaPlannedController from "./planned/agendaPlannedController";
import AgendaPlanningController from "./planning/agendaPlanningController";
import {materialTypes} from "../../material/materialTypes";
import MaterialFactory, {createTab} from "../../material/materialFactory";

const styles = () => ({
    tabRoot: {
        minWidth: '33.33%',
    },
});

export class AgendaView extends React.Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
    }

    renderTab = (label, value) => {
        return createTab({label, value, classes: {root: this.props.classes.tabRoot}});
    };

    renderDialogButtons = () => ([
        {label: 'Nee', onClick: this.props.onCloseDialog},
        {label: 'Ja', onClick: this.props.onDeleteItem},
    ]);

    renderMobileContent = () => {
        const {activeTab, onSelectTab, agendaItems, onAddItemClick, onDeleteItemClick} = this.props;
        return (
            <div className='agendaMobileContent'>
                <MaterialFactory componentType={materialTypes.TABS} value={activeTab} onChange={onSelectTab}>
                    {this.renderTab('Vandaag', DAY_INDEX)}
                    {this.renderTab('Ingepland', PLANNED_INDEX)}
                    {this.renderTab('Inplannen', PLANNING_INDEX)}
                </MaterialFactory>
                <div className='agendaContent'>
                    {activeTab === DAY_INDEX &&
                    <AgendaDayController timeItems={agendaItems} onAddItemClick={onAddItemClick}/>}
                    {activeTab === PLANNED_INDEX &&
                    <AgendaPlannedController agendaItems={agendaItems} onDeleteItemClick={onDeleteItemClick}/>}
                    {activeTab === PLANNING_INDEX && <AgendaPlanningController/>}
                </div>
            </div>
        )
    };

    renderDesktopContent = () => (
        <div className='agendaDesktopContent'>
            <MaterialFactory componentType={materialTypes.TABS} value={this.props.activeTab}
                             onChange={this.props.onSelectTab}>
                {this.renderTab('Vandaag', DAY_INDEX)}
                {this.renderTab('Ingepland', PLANNED_INDEX)}
                {this.renderTab('Inplannen', PLANNING_INDEX)}
            </MaterialFactory>
            <SwipeableViews index={this.props.activeTab} onChangeIndex={this.props.onSelectTab}>
                <div className='agendaContent'>
                    <AgendaDayController timeItems={this.props.agendaItems}
                                         onAddItemClick={this.props.onAddItemClick}/>
                </div>
                <div className='agendaContent'>
                    <AgendaPlannedController agendaItems={this.props.agendaItems}
                                             onDeleteItemClick={this.props.onDeleteItemClick}/>
                </div>
                <div className='agendaContent'>
                    <AgendaPlanningController/>
                </div>
            </SwipeableViews>
        </div>
    );

    render = () => {
        return (
            <>
                <div className='agendaView box noPad' id='dashboardAgendaContainer'>
                    {this.renderDesktopContent()}
                    {this.renderMobileContent()}
                    {this.props.activeTab !== PLANNING_INDEX &&
                    <div className='agendaFooter'>
                        <span className='common-icon-plus addAgendaItem' id='addAgendaItemButton'
                              onClick={this.props.onAddItemClick}/>
                    </div>}
                </div>
                <MaterialFactory componentType={materialTypes.DIALOG} title='Verwijderen' open={this.props.dialogOpen}
                                 actions={this.renderDialogButtons()} onClose={this.props.onCloseDialog}
                                 text='Weet je zeker dat je dit agenda punt wilt verwijderen?'/>
            </>
        )
    };
}

AgendaView.propTypes = {
    onAddItemClick: PropTypes.func.isRequired,
    onSelectTab: PropTypes.func.isRequired,
    onDeleteItem: PropTypes.func.isRequired,
    onDeleteItemClick: PropTypes.func.isRequired,
    onCloseDialog: PropTypes.func.isRequired,
    dialogOpen: PropTypes.bool.isRequired,
    agendaItems: PropTypes.array.isRequired,
    activeTab: PropTypes.number.isRequired,
};

export default withStyles(styles)(AgendaView);