import PropTypes from 'prop-types'
import React from 'react';
import {MuiThemeProvider} from '@material-ui/core/styles'
import {KeyboardArrowRight} from '@material-ui/icons';

import EditContactController from "./editContactController";
import {dispatchCustomEvent} from "../../../../misc/eventDispatcher";
import {materialTypes} from "../../../material/materialTypes";
import MaterialFactory from "../../../material/materialFactory";
import {muiTheme} from "../../../material/muiTheme";
import {getOnboardingManager} from "../../../onboarding/manager/onboardingManagerHelper";
import {Contact} from "../../../../models/contact";
import CustomScrollbar from "../../../misc/customScrollbar";


export default class DossierContactsView extends React.Component {
    addContact = () => {
        const {dossier} = this.props;
        dispatchCustomEvent('openContact', {dossierId: dossier.id});
    };

    editContact = () => {
        const {selectedContacts} = this.props;
        dispatchCustomEvent('openContact', {contact: selectedContacts[0]});
        // this.props.changeContact();
    };

    showMore = id => {
        let element = document.getElementById('contact ' + id);
        if (isNotNull(element)) {
            element.classList.toggle('compact');
        }
    };

    renderContact_deprecated = (contact, key) => {
        return (
            <div id={'contact ' + contact.id} key={key} className='contactDetails compact'>
                <div onClick={() => {
                    this.props.contactFieldClick(contact)
                }}>
                    <div className='hideMore'/>
                    <div className='contactDetailsHeader'>
                        <label className='contactName'>{contact.name}</label>
                    </div>
                    <div className='contactDetailsBody'>
                        {this.renderContactDetail('Website', contact.website, true)}
                        {this.renderContactDetail('Telefoonnummer', contact.phone)}
                        {this.renderContactDetail('E-mailadres', contact.email)}
                        {this.renderContactDetail('Straat', contact.street)}
                        {this.renderContactDetail('Huisnummer', contact.houseNumber)}
                        {this.renderContactDetail('Huisnummer toevoeging', contact.houseNumberSuffix)}
                        {this.renderContactDetail('Postcode', contact.zipCode)}
                        {this.renderContactDetail('Stad', contact.city)}
                        {this.renderContactDetail('Opmerking', contact.comment)}
                    </div>
                </div>
                <span className='common-icon-arrow-right showMore' onClick={() => {
                    this.showMore(contact.id)
                }}/>
            </div>
        )
    };

    renderContact = (contact, key) => {
        const {onClickContact} = this.props;
        return (
            <div key={key} className='contactDetails' onClick={onClickContact(contact)}>
                <span className='common-icon-person contactIcon'/>
                <div className='contactDataContainer'>
                    <label className='contactName'>{contact.name}</label>
                    {isNotNull(contact.email) && <label className='contactEmail'>{contact.email}</label>}
                </div>
                <KeyboardArrowRight className='contactArrow'/>
            </div>
        )
    };

    renderContactDetail = (label, detail, isWebsite = false) => {
        if (isNotNull(detail))
            return (
                <div className='contactDetail'>
                    <label className='detailLabel'>{label}:</label>
                    {isWebsite &&
                    <span onClick={() => {
                        this.openLink(detail)
                    }} className='detailValue website' title={detail}>{detail}</span>}
                    {!isWebsite &&
                    <p className='detailValue' title={detail}>{detail}</p>}
                </div>
            );
        return null;
    };

    // XSS Safe link opening
    openLink = link => {
        let regex = /javascript|script|\(\)|;/;
        if (!regex.test(link)) {
            window.open('//' + link);
        }
    };


    render = () => {
        const onboardingActive = getOnboardingManager('store').isActive();
        const {selectedContacts, deleteContact, contacts, contact, dossier, selectedModule, onUpdateDossier} = this.props;
        return (
            <MuiThemeProvider theme={muiTheme}>
                <div className="dossierContactBox">
                    <CustomScrollbar height='21.2rem' autoHide={false}>
                        {contacts.map(this.renderContact)}
                    </CustomScrollbar>
                    <div className='contactsFooter'>
                        <MaterialFactory componentType={materialTypes.RAISED_BUTTON} disabled={onboardingActive}
                                         onClick={this.addContact}>Contact toevoegen</MaterialFactory>
                    </div>
                    <EditContactController type='add' contact={contact} dossier={dossier}
                                           selectedModule={selectedModule} onUpdateDossier={onUpdateDossier}/>
                </div>
            </MuiThemeProvider>
        )
    }
}

DossierContactsView.propTypes = {
    onClickContact: PropTypes.func.isRequired,
    changeContact: PropTypes.func.isRequired,
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(Contact)).isRequired,
    selectedContacts: PropTypes.array.isRequired,
    deleteContact: PropTypes.func.isRequired,
    editContact: PropTypes.func.isRequired,
    selectedModule: PropTypes.object,
    contactFieldClick: PropTypes.func.isRequired,
    onUpdateDossier: PropTypes.func.isRequired,
};