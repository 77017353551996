import React from 'react';
import PropTypes from 'prop-types';
import {Redirect, withRouter} from 'react-router-dom';

const CustomRedirect = withRouter((props) => {
    let to = props.to;
    let queryParams = props.location.search;
    return <Redirect to={to + queryParams} {...props}/>;
});

CustomRedirect.propTypes = {
    to: PropTypes.string.isRequired,
};

export default CustomRedirect;

