import React from 'react';

import {stringStartsWith, stringContains, toMoment} from 'glob-common-js/lib/utils';

import StateComponent from "../../misc/stateComponent";
import ContactPageView from "./contactPageView";
import {Contact} from "../../../models/contact";
import {responseIsSuccess} from "../../../misc/requestSender";
import {showMessage, showSnackbar} from "../../../misc/utils";
import {dispatchCustomEvent} from "../../../misc/eventDispatcher";
import {
    addDataEventListener,
    DATA_TYPES,
    dispatchContactEvent,
    EVENT_TYPES,
    removeAllDataEventListeners
} from "../../../misc/dataEvent";

export default class ContactPageController extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            contacts: [],
            count: 0,
            selectedContacts: [],
            searchValue: '',
            order: 'createdAt',
        };
        this.contacts = [];
        this.dossiers = [];
    }

    onDataEvent = (contact, eventType) => {
        switch (eventType) {
            case EVENT_TYPES.CREATE:
                this.onCreateEvent(contact);
                break;
            case EVENT_TYPES.UPDATE:
                this.onUpdateEvent(contact);
                break;
            case EVENT_TYPES.DELETE:
                this.onDeleteEvent(contact);
                break;
        }
    };

    onCreateEvent = contact => {
        this.contacts.push(contact);
        this.changeState({count: this.contacts.length});
        this.filterContacts();
    };

    onUpdateEvent = contact => {
        this.contacts = this.contacts.filter(cont => cont.id !== contact.id);
        this.contacts.push(contact);
        this.filterContacts();
    };

    onDeleteEvent = contact => {
        this.contacts = this.contacts.filter(cont => cont.id !== contact.id);
        this.changeState({count: this.contacts.length});
        this.filterContacts();
    };

    onSort = value => {
        this.changeState({order: value}, this.filterContacts);
    };

    onSearch = value => {
        this.changeState({searchValue: value}, this.filterContacts);
    };

    onEdit = contactId => {
        const contact = this.getContact(contactId);
        if (isNotNull(contact))
            dispatchCustomEvent('openContact', {contact});
    };

    onDelete = contacts => {
        if (isNotNull(contacts)) {
            const count = Array.isArray(contacts) ? contacts.length : 1,
                text = count === 1 ? 'dit contact' : 'deze contacten', title = count === 1 ? 'Contact' : 'Contacten';
            showMessage(`Weet je zeker dat je ${text} wilt verwijderen?`, [
                {label: 'Nee'}, {label: 'Ja', onClick: this.onConfirmDelete(contacts)}
            ], `${title} verwijderen`)
        }
    };

    onConfirmDelete = contacts => () => {
        if (isNotNull(contacts)) {
            if (Array.isArray(contacts)) this.deleteMultipleContacts(contacts);
            else {
                const contact = this.getContact(contacts);
                contact.delete(response => {
                    if (responseIsSuccess(response)) {
                        showSnackbar('Je contact is verwijderd');
                        dispatchContactEvent(EVENT_TYPES.DELETE, contact);
                    }
                });
            }
        }
    };

    onNewContact = () => {
        dispatchCustomEvent('openContact', {});
    };

    onSelectContact = contact => {
        const {selectedContacts} = this.state;
        if (selectedContacts.includes(contact.id))
            selectedContacts.splice(selectedContacts.indexOf(contact.id), 1);
        else selectedContacts.push(contact.id);
        this.changeState({selectedContacts});
    };

    onClickAccount = contact => evt => {
        if (isNotNull(contact.accounts)) {
            evt.stopPropagation();
            dispatchCustomEvent('openContact', {contact, tab: 'accounts'});
        }
    };

    getContact = id => {
        for (const contact of this.contacts) {
            if (contact.id === id) return contact;
        }
        return {};
    };

    deleteMultipleContacts = contacts => {
        let contactsDeleted = 0;
        for (const contactId of contacts) {
            const contact = this.getContact(contactId);
            contact.delete(() => {
                dispatchContactEvent(EVENT_TYPES.DELETE, contact);
                if (++contactsDeleted >= contacts.length) {
                    showSnackbar('Je contacten zijn verwijderd');
                }
            });
        }
    };

    sortContacts = contacts => {
        const {order} = this.state;
        contacts = contacts.slice();
        contacts.sort((contactA, contactB) => {
            if (['accounts', 'dossiers'].includes(order)) return this.sortByCount(contactA, contactB);
            else if (order === 'createdAt') return this.sortByCreated(contactA, contactB);
            let valueA = contactA[order].toLowerCase(), valueB = contactB[order].toLowerCase(), preferA = -1,
                preferB = 1, isEqual = 0;
            if (order === 'name') {
                preferA = 1;
                preferB = -1;
            }
            return valueA < valueB ? preferA : valueA > valueB ? preferB : isEqual;
        });
        return contacts;
    };

    sortByCount = (contactA, contactB) => {
        const {order} = this.state;
        const countA = contactA[order].length, countB = contactB[order].length;
        return countA - countB;
    };

    sortByCreated = (contactA, contactB) => {
        let createdA = contactA.createdAt, createdB = contactB.createdAt, preferA = -1, preferB = 1, isEqual = 0;
        if (isNotNull(createdA) && isNull(createdB)) return preferA;
        if (isNull(createdA) && isNotNull(createdB)) return preferB;
        if (isNull(createdA) && isNull(createdB)) return isEqual;
        createdA = toMoment(createdA);
        createdB = toMoment(createdB);
        if (createdA.isBefore(createdB)) return preferA;
        if (createdA.isAfter(createdB)) return preferB;
        return isEqual;
    };

    syncSelection = contacts => {
        const {selectedContacts} = this.state;
        const ids = contacts.map(contact => contact.id);
        let i = selectedContacts.length;
        while (i--) {
            if (!ids.includes(selectedContacts[i]))
                selectedContacts.splice(i, 1);
        }
        this.changeState({selectedContacts});
        return contacts;
    };

    searchContacts = contacts => {
        contacts = contacts.slice();
        const nameContacts = this.searchByKey(contacts, 'name'), phoneContacts = this.searchByKey(contacts, 'phone'),
            createdContacts = this.searchByKey(contacts, 'createdAt');
        return nameContacts.concat(phoneContacts).concat(createdContacts);
    };

    searchByKey = (contacts, key) => {
        const startsWith = [], contains = [], {searchValue} = this.state;
        let i = contacts.length;
        while (i--) {
            const contact = contacts[i];
            let value = contact[key];
            if (key !== 'createdAt') value = value.toLowerCase();
            if (isNotNull(value)) {
                let isFiltered = false;
                if (stringStartsWith(value, searchValue)) {
                    startsWith.push(contact);
                    isFiltered = true;
                } else if (stringContains(value, searchValue)) {
                    contains.push(contact);
                    isFiltered = true;
                }
                if (isFiltered)
                    contacts.splice(i, 1);
            }
        }
        return startsWith.concat(contains);
    };

    filterContacts = () => {
        const contacts = this.syncSelection(this.searchContacts(this.sortContacts(this.contacts)));
        this.changeState({contacts});
    };

    loadContacts = () => {
        Contact.getAll((contacts, response) => {
            if (responseIsSuccess(response)) {
                this.contacts = contacts;
                this.changeState({count: contacts.length});
                this.filterContacts();
            } else showMessage('Het laden van je contacten is niet gelukt. Probeer het later opnieuw of neem ' +
                'contact met ons op', null, 'Contacten laden mislukt')
        });
    };

    componentDidMount = () => {
        this.mount();
        this.loadContacts();
        addDataEventListener(DATA_TYPES.CONTACT, [EVENT_TYPES.CREATE, EVENT_TYPES.UPDATE,
            EVENT_TYPES.DELETE], 'contactPageController', this.onDataEvent);
    };

    componentWillUnmount = () => {
        this.unMount();
        removeAllDataEventListeners('contactPageController', this.onDataEvent);
    };

    render = () => {
        const {contacts, count, selectedContacts, order, searchValue} = this.state;
        return (
            <ContactPageView onSort={this.onSort} onEdit={this.onEdit} onDelete={this.onDelete}
                             onNewContact={this.onNewContact} onSearch={this.onSearch} count={count}
                             onSelectContact={this.onSelectContact} contacts={contacts}
                             onClickAccount={this.onClickAccount} selectedContacts={selectedContacts} order={order}
                             searchValue={searchValue}/>
        )
    };
}