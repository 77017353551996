import React from 'react';

import {isFunction} from 'glob-common-js/lib/utils';

import StateComponent from "../../../misc/stateComponent";
import AddGrant from "./addGrant";
import {addCustomEventListener, dispatchCustomEvent, removeCustomEventListener} from "../../../../misc/eventDispatcher";
import {addGrant} from "../../../../misc/requestSender";

export default class AddGrantController extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            email: '',
            read: true,
            manage: true,
            share: true,
            error: null,
            loading: false,
        };
        this.onSuccessCallback = null;
    }

    onChangeMail = evt => {
        this.changeState({email: evt.target.value});
    };

    onChangeRight = name => evt => {
        this.changeState({[name]: evt.target.checked});
        this.onResetError();
    };

    onSubmit = () => {
        this.changeState({loading: true});
        const rights = this.prepareRights();
        const {email} = this.state;
        addGrant({
            email,
            rights,
            callback: (response) => {
                this.changeState({loading: false});
                const {status, message} = response.data;
                if (status === 'success') {
                    this.onClose();
                    dispatchCustomEvent('openSnackbar', {text: 'Machtiging tot account toegevoegd'});
                    if (isFunction(this.onSuccessCallback))
                        this.onSuccessCallback();
                } else {
                    let errorMessage = 'Machtiging toevoegen is niet gelukt';
                    if (message === 'Grant to user does not exist')
                        errorMessage = 'Gebruiker niet gevonden';
                    else if (message === 'Supplied user has already been granted access')
                        errorMessage = 'Gebruiker heeft al machtiging';
                    this.changeState({error: errorMessage});
                }
            },
        })
    };

    onClose = () => {
        this.changeState({active: false, email: '', read: true, manage: true, share: true, error: null});
    };

    onResetError = () => {
        this.changeState({error: null});
    };

    prepareRights = () => {
        const {read, manage, share} = this.state;
        const rights = [];
        if (read) rights.push('read');
        if (manage) rights.push('manage');
        if (share) rights.push('share');
        return rights;
    };

    activate = (onSuccess) => {
        this.onSuccessCallback = onSuccess;
        this.changeState({active: true});
    };

    componentDidMount = () => {
        this.mount();
        addCustomEventListener('addGrant', this.activate);
    };

    componentWillUnmount = () => {
        this.unMount();
        removeCustomEventListener('addGrant', this.activate);
    };

    render = () => {
        const {active, email, read, manage, share, error, loading} = this.state;
        return <AddGrant onClose={this.onClose} active={active} onSubmit={this.onSubmit} loading={loading}
                         email={email} onChangeMail={this.onChangeMail} onChangeRight={this.onChangeRight} read={read}
                         manage={manage} share={share} error={error} onResetError={this.onResetError}
                         actionText='Machtiging toevoegen'/>
    }
}