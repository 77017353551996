import './style/agendaPopup.scss';

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';

import {getSetting, toMoment} from 'glob-common-js/lib/utils';

import StateComponent from "../../misc/stateComponent";
import MaterialFactory from "../../material/materialFactory";
import {materialTypes} from "../../material/materialTypes";
import PopupBuilder from "../../misc/popupBuilder/popupBuilder";
import GenericLoader from "../../misc/genericLoader";
import {getOnboardingManager} from "../../onboarding/manager/onboardingManagerHelper";

const itemTypes = {
    0: 'Belangrijke deadline',
    2: 'Herinnering',
    3: 'Afspraak',
}, frequencies = {
    0: 'Eenmalig',
    1: 'Dagelijks',
    2: 'Wekelijks',
    3: 'Maandelijks',
    4: 'Jaarlijks',
}, remindTimes = {
    3600: 'Een uur van tevoren',
    86400: 'Een dag van tevoren',
    604800: 'Een week van tevoren',
    2629743: 'Een maand van tevoren',
};
export default class AgendaPopup extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            popover: {
                open: false,
                element: null,
                type: 'email',
            }
        };
    }

    onClickReminder = (evt, type) => {
        evt.preventDefault();
        this.changeState({
            popover: {
                element: evt.currentTarget,
                open: true,
                type,
            },
        });
        this.props.onChangeReminderDetail(type, 'toggled', true);
    };

    handleRequestClose = () => {
        this.changeState({popover: Object.assign({}, this.state.popover, {open: false})});
    };

    processOnboarding = () => {
        const manager = getOnboardingManager('agenda');
        manager.highlightElement('agendaPopup');
    };

    renderTextField = (name, label, type, value, errorText = null, isArea = false) => (
        <MaterialFactory componentType={materialTypes.TEXT} label={label} value={value} type={type}
                         helperText={errorText} error={isNotNull(errorText)} multiline={isArea} maxLength={1000}
                         fullWidth onChange={(evt) => {
            this.props.onChangeValue(name, evt.target.value);
        }} onFocus={this.props.onResetErrors} margin='dense'/>
    );

    renderSelectField = name => {
        return name === 'type' ? this.renderTypeField() : this.renderFrequencyField();
    };

    renderTypeField = () => {
        const type = this.props.values.type;
        const items = [];
        const keys = Object.keys(itemTypes);
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            items.push({value: key, label: itemTypes[key]});
        }
        return (
            <MaterialFactory componentType={materialTypes.SELECT} label='Type' value={type.toString()}
                             items={items} fullWidth onChange={(evt) => {
                this.props.onChangeValue('type', parseInt(evt.target.value));
            }} margin='dense'/>
        );
    };

    renderFrequencyField = () => {
        const frequency = this.props.values.frequency;
        const items = [];
        const keys = Object.keys(frequencies);
        for (let i = 0; i < keys.length; i++) {
            items.push({value: keys[i], label: frequencies[keys[i]]});
        }
        return (
            <MaterialFactory componentType={materialTypes.SELECT} label='Frequentie' value={frequency.toString()}
                             items={items} fullWidth onChange={(evt) => {
                this.props.onChangeValue('frequency', parseInt(evt.target.value));
            }} margin='dense'/>
        );
    };

    renderDateField = (name, label, value) => {
        const {values, onChangeValue} = this.props;
        return (
            <div className='agendaDateField'>
                <MaterialFactory componentType={materialTypes.DATE_PICKER} label={label} margin='dense'
                                 value={value} className='datePicker' onChange={date => {
                    const fullDate = toMoment(values[name]), hours = fullDate.hour(), minutes = fullDate.minute();
                    date = moment(date);
                    date.hour(hours);
                    date.minute(minutes);
                    onChangeValue(name, date.format('YYYY-MM-DDTHH:mm:ss'));
                }}/>
                <MaterialFactory componentType={materialTypes.TIME_PICKER} value={value} label='Tijd' margin='dense'
                                 className='timePicker' onChange={date => {
                    onChangeValue(name, date);
                }}/>
            </div>
        )
    };

    renderReminderContainer = () => {
        const reminderValue = this.props.values.reminder;
        return (
            <div className='reminderContainer'>
                <MaterialFactory componentType={materialTypes.TOGGLE} label='Herinner mij'
                                 checked={reminderValue.toggled} onChange={(evt) => {
                    this.props.onChangeReminderValue('toggled', evt.target.checked);
                }}/>
                <div className={'reminderDetailsContainer' + (reminderValue.toggled ? ' open' : '')}>
                    {this.renderReminderIcons()}
                    {this.renderPopover()}
                </div>
            </div>
        )
    };

    renderReminderIcons = () => {
        const {sms, email} = this.props.values.reminder;
        return (
            <>
                <div className='detailIconContainer' onClick={(evt) => {
                    this.onClickReminder(evt, 'email');
                }}>
                    <span className='common-icon-email detailIcon'/>
                    {email.toggled &&
                    <div className='checkContainer'>
                        <span className='common-icon-check checkedIcon'/>
                    </div>}
                </div>
                <div className='detailIconContainer' onClick={(evt) => {
                    this.onClickReminder(evt, 'sms');
                }}>
                    <span className='common-icon-sms2 detailIcon'/>
                    {sms.toggled &&
                    <div className='checkContainer'>
                        <span className='common-icon-check checkedIcon'/>
                    </div>}
                </div>
            </>
        )
    };

    renderPopover = () => {
        const reminderValue = this.props.values.reminder;
        let times = Object.keys(remindTimes).map(key => {
            return {value: key, label: remindTimes[key]};
        });
        return (
            <MaterialFactory componentType={materialTypes.MENU} open={this.state.popover.open}
                             anchorEl={this.state.popover.element} onClose={this.handleRequestClose}>
                <MaterialFactory componentType={materialTypes.MENU_ITEM}>
                    <MaterialFactory componentType={materialTypes.TEXT}
                                     label={this.state.popover.type === 'email' ? 'E-mailadres' :
                                         'Mobiel nummer'} value={reminderValue[this.state.popover.type].value}
                                     type={this.state.popover.type === 'email' ? 'email' : 'text'}
                                     fullWidth onChange={(evt) => {
                        this.props.onChangeReminderDetail(this.state.popover.type, 'value', evt.target.value);
                    }} margin='dense'/>
                </MaterialFactory>
                <MaterialFactory componentType={materialTypes.MENU_ITEM}>
                    <MaterialFactory componentType={materialTypes.SELECT} label='Wanneer?'
                                     value={reminderValue[this.state.popover.type].time} items={times}
                                     onChange={(evt) => {
                                         this.props.onChangeReminderDetail(this.state.popover.type, 'time',
                                             evt.target.value);
                                     }} margin='dense'/>
                </MaterialFactory>
                <div className='buttonContainer'>
                    <MaterialFactory componentType={materialTypes.FLAT_BUTTON} color='primary' onClick={() => {
                        this.props.onChangeReminderDetail(this.state.popover.type, 'toggled', false);
                        this.handleRequestClose();
                    }}>Zet uit</MaterialFactory>
                    <MaterialFactory componentType={materialTypes.FLAT_BUTTON} onClick={() => {
                        this.handleRequestClose();
                    }}>Sluit</MaterialFactory>
                </div>
            </MaterialFactory>
        )
    };

    renderHeader = () => {
        const {onDelete, onCancel, values} = this.props;
        const onboardingActive = getOnboardingManager('agenda').isActive();
        const headerTitle = itemTypes[values.type] + ' ' + (this.props.type === 'new' ? 'aanmaken' : 'details');
        const trashClass = classNames('common-icon-trash', 'trashIcon', this.props.type === 'new' && ' hidden');
        const closeClass = classNames('common-icon-cross', 'closeButton', onboardingActive && 'hidden');
        return (
            <div className='agendaHeader'>
                <span className={trashClass} onClick={onDelete}/>
                <label className='agendaHeaderLabel'>{headerTitle}</label>
                <span className={closeClass} onClick={onCancel}/>
            </div>
        )
    };

    renderBody = () => {
        const {errors, values} = this.props;
        return (
            <div className='agendaBody'>
                <div>
                    {this.renderTextField('name', 'Naam', 'text', values.name, errors.name)}
                    {this.renderDateField('startDate', 'Startdatum', values.startDate)}
                    {this.renderDateField('endDate', 'Einddatum', values.endDate)}
                    {this.renderSelectField('frequency')}
                    {this.renderSelectField('type')}
                    {this.renderTextField('location', 'Locatie', 'text', values.location)}
                    {this.renderTextField('description', 'Omschrijving', 'text', values.description, null, true)}
                    {getSetting('alertsEnabled') && this.renderReminderContainer()}
                </div>
            </div>
        );
    };

    renderFooter = () => {
        const {errors, onCancel, onSubmit, type, isDirty} = this.props;
        const onboardingActive = getOnboardingManager('agenda').isActive();
        return (
            <div className='agendaFooter'>
                {isNotNull(errors.name) &&
                <label className='errorLabel'>{errors.name}</label>}
                <div className='buttonContainer'>
                    <MaterialFactory componentType={materialTypes.RAISED_BUTTON} disabled={onboardingActive}
                                     onClick={onCancel}>Annuleren</MaterialFactory>
                    <MaterialFactory componentType={materialTypes.RAISED_BUTTON} onClick={onSubmit}
                                     disabled={type === 'detail' && !isDirty}>
                        Opslaan
                    </MaterialFactory>
                </div>
            </div>
        );
    };

    componentDidMount = () => {
        this.mount();
    };

    componentDidUpdate = (prevProps) => {
        const {active} = this.props, prevActive = prevProps.active;
        if (!prevActive && active && getOnboardingManager('agenda').isActive())
            this.processOnboarding();
    };

    componentWillUnmount = () => {
        this.unMount();
    };

    render = () => {
        const {active, loading} = this.props;
        const onboardingActive = getOnboardingManager('agenda').isActive();
        const containerClass = classNames('agendaPopup', onboardingActive && 'onboarding');
        return <PopupBuilder id='agendaPopup' open={active} content={{
            loader: <GenericLoader active={loading}/>,
            header: this.renderHeader(),
            body: this.renderBody(),
            footer: this.renderFooter(),
        }} containerClass={containerClass} withBackground={!onboardingActive}/>
    };
}

AgendaPopup.propTypes = {
    onChangeValue: PropTypes.func.isRequired,
    onChangeReminderValue: PropTypes.func.isRequired,
    onChangeReminderDetail: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onResetErrors: PropTypes.func.isRequired,
    active: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    type: PropTypes.oneOf(['new', 'detail']).isRequired,
    isDirty: PropTypes.bool.isRequired,
    values: PropTypes.shape({}).isRequired,
    errors: PropTypes.shape({}).isRequired,
};