import moment from 'moment';
import {applyMiddleware} from 'redux';
import {createLogger} from 'redux-logger';
import * as Sentry from '@sentry/browser';

import "scroll-behavior-polyfill";
import 'url-search-params-polyfill';

import {
    areNotNull,
    debug,
    deviceIsMobile,
    forEach,
    getSetting,
    isNotNull,
    isNull,
    log,
    pathIsNotNull,
} from 'glob-common-js/lib/utils';
import {initializeSettings} from 'glob-common-js/lib/settings';
import storeUtils from 'glob-common-js/lib/redux/store';

import {initSbjs} from "./misc/cookieUtil";
import {initializeDocTypes} from "./common/data/mappings/docTypeMapping";
import {getJwt, getParsedJwt} from "./common/js/platform";
import allReducers from './redux/reducer';
import initialState from "./redux/initialState";
import ga from "./common/js/ga";

initializeSettings();

const mode = getSetting('mode');
const isProd = mode === 'prod';
debug('Mode: ' + mode);

// Add common used functions to window
window.log = log;
window.isNotNull = isNotNull;
window.isNull = isNull;
window.pathIsNotNull = pathIsNotNull;
window.forEach = forEach;
window.areNotNull = areNotNull;
window.sentryEnabled = mode !== 'dev';


// Initialize Google Analytics
ga.init(getSetting('gaTrackingId'));

// Sentry
if (sentryEnabled) {
    const sentryUrl = getSetting('mode') === 'prod' ? 'https://41443b8f5d38455795e60f4343c670e0@sentry.io/228634' :
        'https://9860277b023b41e3b8e7cbeeecb65a88@sentry.io/228638';
    Sentry.init({dsn: sentryUrl, blacklistUrls: ['/clickstream/']});
}

// Load Lucky Orange
window.__lo_site_id = 67105;
(function () {
    let wa = document.createElement('script');
    wa.type = 'text/javascript';
    wa.async = true;
    wa.src = 'https://d10lpsik1i8c69.cloudfront.net/w.js';
    let s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(wa, s);
})();

// Read the sbjs cookies
initSbjs();

// Set moment locale
moment.locale('nl-NL');

// Initialize Redux
export const store = isProd ? storeUtils.configureStore(allReducers, initialState) :
    storeUtils.configureStore(allReducers, initialState, applyMiddleware(createLogger({level: 'debug'})));

// Initialize document types
initializeDocTypes();

window.testJwt = () => {
    if (getSetting('mode') !== 'prod') {
        const jwtToken = getParsedJwt();
        if (isNotNull(jwtToken)) {
            debug(JSON.stringify(jwtToken));
        }
    }
};

if (deviceIsMobile()) window.scrollTo(0, 1);