import React from 'react';
import PropTypes from 'prop-types';
import {MuiThemeProvider} from '@material-ui/core/styles'
import MaterialFactory from "../../../material/materialFactory";
import {materialTypes} from "../../../material/materialTypes";
import {muiTheme} from "../../../material/muiTheme";

export default class DossierNotesEditView extends React.Component {
    constructor(props) {
        super(props);
    }

    render = () => {
        const {hasChanges, value, onChange, onSave} = this.props;
        return (
            <MuiThemeProvider theme={muiTheme}>
                <div className='dossierNotes'>
                    <div className="notes">
                        <textarea value={value} onChange={onChange}/>
                    </div>
                    <div className="noteFooter">
                        <MaterialFactory componentType={materialTypes.RAISED_BUTTON} disabled={!hasChanges}
                                         onClick={onSave}>{hasChanges ? 'Opslaan' : 'Opgeslagen'}</MaterialFactory>
                    </div>
                </div>
            </MuiThemeProvider>
        );
    }
}

DossierNotesEditView.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    hasChanges: PropTypes.bool.isRequired,
};