import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as Actions from '../../../../../redux/actions';

import {isNotNull} from 'glob-common-js/lib/utils';

import EvaluationAdditionalView from './evaluationAdditionalView';
import {getFromRegistry} from "../../misc/registry";
import {sendEvent} from "../../misc/healthAnalysisUtils";
import {sendPageView} from "BdhCommon/js/ga";

export class EvaluationAdditionalController extends React.Component {
    constructor(props) {
        super(props);
    }

    addEvalToRedux = (decision) => {
        if (isNotNull(decision)) {
            sendEvent('Evaluatie Aanvullend', decision);
            this.props.actions.setEvaluationAdditional(decision);
        }
    };

    getAdditionalsData = () => {
        return getFromRegistry(['healthInsurance','evaluation', 'additionals']);
    };

    componentDidMount = () => {
        sendPageView('/zorganalyse/evaluatie/aanvullende-verzekering');
    };

    render = () => {
        return (
            <EvaluationAdditionalView evaluationDecision={this.props.healthState.additional}
                                      setEval={this.addEvalToRedux}
                                      additionalData={this.getAdditionalsData()}/>
        );
    }
}

const mapStateToProps = state => ({
    healthState: state.healthInsuranceAnalysis.evaluation,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(EvaluationAdditionalController);