import React from 'react';
import PropTypes from 'prop-types';
import AdditionalSelectView from "./additionalSelectView";

import {isNotNull} from 'glob-common-js/lib/utils';

export default class AdditionalSelectController extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            propsProcessed: false,
            selectedAdditional: props.defaultSelected,
            defaultAdditionals: [],
            groupAdditionals: [],
        };
        this.selectedGroups = {};
        this.selectedAdditional = null;
    }

    changeState = (stateChange) => {
        if (this._isMounted) {
            this.setState(stateChange);
        }
    };

    removeSelection = () => {
        this.selectedGroups = {};
        this.changeState({selectedAdditional: null});
        this.selectedAdditional = null;
        if (isNotNull(this.view) && typeof this.view.removeSelection === 'function') {
            this.view.removeSelection();
        }
    };

    getSelectedAdditionals = () => {
        // If none is selected, just return null
        if (isNull(this.selectedAdditional) && Object.keys(this.selectedGroups).length === 0) {
            if (this.props.required && isNotNull(this.view)) {
                this.view.showError();
            }
            return [];
        }
        return this.getSelectedAdditionalsIntern();
    };

    getSelectedAdditionalsIntern = () => {
        if (Object.keys(this.selectedGroups).length === 0) {
            let selected = this.addDiscountToAdditional(this.selectedAdditional);
            return Array.isArray(selected) ? selected : [selected];
        } else {
            let keys = Object.keys(this.selectedGroups);
            return keys.map(key => (this.addDiscountToAdditional(this.selectedGroups[key])));
        }
    };

    addDiscountToAdditional = (additional) => {
        if (isNotNull(additional) && isNotNull(additional.name)) {
            let discountInput = document.getElementById(this.props.id + 'discount');
            let discountTypeSelector = document.getElementById(this.props.id + 'discountType');
            if (isNotNull(discountInput)) {
                additional.discount = discountInput.value;
            }
            if (isNotNull(discountTypeSelector)) {
                additional.discount_type = discountTypeSelector.options[discountTypeSelector.selectedIndex].value;
            } else {
                additional.discount_type = '';
            }
        }
        return additional;
    };

    onSelectDefault = (additional) => {
        this.selectedGroups = {};
        let groupBoxes = document.getElementsByClassName('groupBoxes');
        // Reset group radio boxes
        for (let i = 0; i < groupBoxes.length; i++) {
            groupBoxes[i].checked = false;
        }
        this.selectedAdditional = additional;
        this.changeState({
            selectedAdditional: additional,
            groupsSelected: 0
        });
        if (typeof this.props.onSelect === 'function') {
            this.props.onSelect(additional);
        }
    };

    onSelectGroup = (additional, groupName) => {
        let shouldBeSelected;
        if (this.selectedGroups.hasOwnProperty(groupName)) {
            let selectedInGroup = this.selectedGroups[groupName];
            if (selectedInGroup.name === additional.name) {
                delete this.selectedGroups[groupName];
                shouldBeSelected = false;
            } else {
                this.selectedGroups[groupName] = additional;
                shouldBeSelected = true;
            }
        } else {
            this.selectedGroups[groupName] = additional;
            shouldBeSelected = true;
        }

        let amountSelected = Object.keys(this.selectedGroups).length;

        // Reload selectable insurances
        if (typeof this.props.onSelect === 'function') {
            if (shouldBeSelected) {
                this.props.onSelect(additional);
            } else if (amountSelected === 0) {
                this.props.onSelect(null);
            }
        }

        return {
            shouldBeSelected,
            amountSelected,
        };
    };

    processProps = (useDefault) => {
        let defaultAdditionals = this.props.additionals.filter(additional => additional.type !== 'LOS');
        let groupAdditionals = this.props.additionals.filter(additional => additional.type === 'LOS');

        // Add the empty option, if there are any options
        if (defaultAdditionals.length > 0 && !this.props.withoutEmpty) {
            defaultAdditionals.splice(0, 0, {
                name: 'empty',
                label: 'Geen'
            });
        }
        let groups = {};
        for (let i = 0; i < groupAdditionals.length; i++) {
            let additional = groupAdditionals[i];
            let groupName = additional.group;
            if (!groups.hasOwnProperty(groupName)) {
                groups[groupName] = {
                    name: groupName,
                    additionals: []
                };
            }
            groups[groupName].additionals.push(additional);
        }
        if (useDefault && isNotNull(this.props.defaultSelected)) {
            let selected = this.props.defaultSelected;
            if (Array.isArray(selected)) {
                for (let i = 0; i < selected.length; i++) {
                    this.selectedGroups[selected[i].group] = selected[i];
                }
            } else {
                this.selectedAdditional = selected;
            }
        }
        this.changeState({
            propsProcessed: true,
            defaultAdditionals,
            groupAdditionals: groups,
        })
    };

    componentDidUpdate = (prevProps) => {
        if (this.props.additionals !== prevProps.additionals) {
            this.processProps(false);
        }
    };

    componentDidMount = () => {
        this._isMounted = true;
        this.processProps(true);
    };

    componentWillUnmount = () => {
        this._isMounted = false;
    };

    render = () => {
        if (this.state.propsProcessed &&
            (this.state.defaultAdditionals.length > 0 || Object.keys(this.state.groupAdditionals).length > 0)) {
            return (
                <AdditionalSelectView ref={(view) => (this.view = view)}
                                      defaultAdditionals={this.state.defaultAdditionals}
                                      groupAdditionals={this.state.groupAdditionals}
                                      onSelectDefault={this.onSelectDefault}
                                      onSelectGroup={this.onSelectGroup} groupsSelected={this.state.groupsSelected}
                                      fieldLabel={this.props.fieldLabel} selectText={this.props.selectText}
                                      id={this.props.id} defaultSelectLabel={this.props.defaultSelectLabel}
                                      required={this.props.required} defaultSelected={this.props.defaultSelected}
                                      initialSelectText={this.props.initialSelectText}
                                      defaultDiscount={this.props.defaultDiscount}
                />
            );
        }
        return null;
    }
}

AdditionalSelectController.propTypes = {
    additionals: PropTypes.arrayOf(PropTypes.object).isRequired,
    fieldLabel: PropTypes.string.isRequired,
    selectText: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    onSelect: PropTypes.func,
    withoutEmpty: PropTypes.bool,
    defaultSelectLabel: PropTypes.string,
    required: PropTypes.bool,
    defaultSelected: PropTypes.oneOfType([
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            discount: PropTypes.number.isRequired,
        }),
        PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string.isRequired,
            discount: PropTypes.number.isRequired,
        })),
    ]),
    initialSelectText: PropTypes.string.isRequired,
    defaultDiscount: PropTypes.shape({
        value: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
    }),
};

AdditionalSelectController.defaultProps = {
    withoutEmpty: false,
    defaultSelectLabel: 'Aanvullend',
    required: false,
};