import {debug, generateUUID, isFunction, pathIsNotNull, toMoment, warn} from 'glob-common-js/lib/utils';

import {getAllDossiers} from "../../../misc/requestSender";
import {
    dossierIsFinancial,
    getCategoryForItem,
    getMoneyType
} from "../../../common/data/mappings/financialTypeMapping_v3";
import getDossierType from "../../../common/data/mappings/dossierTypeMapping";
import {dispatchCustomEvent} from "../../../misc/eventDispatcher";
import {sendGaEvent} from "../../../common/js/ga";
import {EMPTY_FUNCTION} from "../../misc/constants";
import Dossier from "../../../models/dossier";

export default class FinancialHelper {
    dossiers = [];
    loadCallback = null;

    constructor(props) {
        this.props = props;
        this.source = props.source;
        this.loadCallback = isFunction(props.callback) ? props.callback : this.warnNoCallback;
        this.onLoad = isFunction(props.onLoad) ? props.onLoad : EMPTY_FUNCTION;
        this.afterLoad = isFunction(props.afterLoad) ? props.afterLoad : EMPTY_FUNCTION;
        this.submitCallback = isFunction(props.submitCallback) ? props.submitCallback : EMPTY_FUNCTION;
        this.gaCategory = props.gaCategory || 'Onbekend';
    }

    loadDossiers = () => {
        debug(`Loading financial dossiers for source ${this.source}`);
        this.onLoad();
        let financialItems = [], defaultItems = [];
        this.dossiers = [];
        getAllDossiers({
            asInstance: true,
            callback: (dossiers) => {
                this.afterLoad();
                if (isNotNull(dossiers)) {
                    let defaultDossiers = [], financialDossiers = [];
                    for (let i = 0; i < dossiers.length; i++) {
                        const dossier = dossiers[i];
                        const type = dossier.type;
                        if (dossierIsFinancial(type.name) && this.dossierContainsValues(dossier)) {
                            this.dossiers.push(dossier);
                            if (type.name === 'financial_overview')
                                financialDossiers.push(dossier);
                            else
                                defaultDossiers.push(dossier);
                        }
                    }
                    financialItems = this.prepareFinancialItems(financialDossiers);
                    defaultItems = this.prepareDossierItems(defaultDossiers);
                }
                this.loadCallback({financialItems, defaultItems});
            }
        });
    };

    dossierContainsValues = dossier => {
        if (pathIsNotNull(dossier, 'externalData.values')) {
            let values = dossier.externalData.values;
            for (let i = 0; i < values.length; i++) {
                let value = values[i];
                value.financialType = value.financialType || value.type || dossier.type.name;
                if (value.hasOwnProperty('monthly_price') && value.hasOwnProperty('start_date')) return true;
            }
        }
        return false;
    };

    prepareDossierItems = dossiers => {
        let items = [];
        if (isNotNull(dossiers)) {
            for (let i = 0; i < dossiers.length; i++) {
                const dossier = dossiers[i];
                const dossierType = dossier.type.name;
                const values = dossier.externalData.values;
                for (let j = 0; j < values.length; j++) {
                    const value = Object.assign({}, values[j]);
                    if (!value.excludeFromFinancial) {
                        const financialType = value.financialType || dossierType.name;
                        value.moneyType = getMoneyType(financialType);
                        value.dossierId = dossier.id;
                        value.dossierType = dossierType;
                        value.category = getCategoryForItem(financialType);
                        value.monthly_price = parseFloat(value.monthly_price);
                        value.label = value.label || dossier.name;
                        value.description = value.label || dossier.name;
                        items.push(value);
                    }
                }
            }
        }
        return items;
    };

    prepareFinancialItems = financialDossiers => {
        let items = [];
        if (isNotNull(financialDossiers)) {
            for (let i = 0; i < financialDossiers.length; i++) {
                const dossier = financialDossiers[i];
                const dossierType = 'financial_overview';
                const values = dossier.externalData.values;
                for (let j = 0; j < values.length; j++) {
                    let value = values[j];
                    if (!value.excludeFromFinancial) {
                        const financialType = value.financialType || dossierType.name;
                        value.moneyType = getMoneyType(financialType);
                        value.dossierId = dossier.id;
                        value.dossierType = dossierType;
                        value.category = getCategoryForItem(financialType);
                        value.monthly_price = parseFloat(value.monthly_price);
                        items.push(value);
                    }
                }
            }
        }
        return items;
    };

    deleteItemsFromDossier = items => {
        this.onLoad();
        let grouped = {};
        for (let i = 0; i < items.length; i++) {
            let item = items[i];
            sendGaEvent(this.gaCategory, 'Verwijderen', item.financialType);
            let dossierId = item.dossierId;
            if (grouped.hasOwnProperty(dossierId)) grouped[dossierId].push(item);
            else grouped[dossierId] = [item];
        }

        let ids = Object.keys(grouped);
        let updated = 0;

        const onUpdate = () => {
            if (++updated >= ids.length) {
                this.afterLoad();
                // dispatchCustomEvent('updateFinancial');
                this.loadDossiers();
            }
        };

        for (let i = 0; i < ids.length; i++) {
            let id = ids[i];
            let itemsToDelete = grouped[id].map(item => item.id);
            for (let j = 0; j < this.dossiers.length; j++) {
                const dossier = this.dossiers[j];
                let dossierId = dossier.id;
                if (dossierId === id) {
                    dossier.externalData.values =
                        dossier.externalData.values.filter(value => !itemsToDelete.includes(value.id));
                    if (dossier.externalData.values.length === 0 && !dossier.externalData.isDossier)
                        dossier.delete(onUpdate);
                    else dossier.update(onUpdate);
                    break;
                }
            }
        }
    };

    submitItem = item => {
        this.onLoad();
        if (isNotNull(item.dossierId) && this.dossierStillExists(item.dossierId)) this.updateItemInDossier(item);
        else this.createNewItem(item);
    };

    dossierStillExists = id => {
        let dossiers = this.dossiers;
        for (let i = 0; i < dossiers.length; i++) {
            if (dossiers[i].id === id) return true;
        }
        return false;
    };

    updateItemInDossier = item => {
        const dossierId = item.dossierId;
        debug('Looking for dossier id', dossierId);
        for (let i = 0; i < this.dossiers.length; i++) {
            let dossier = this.dossiers[i];
            debug('Looking in dossier', dossier);
            if (dossier.id === dossierId) {
                let values = dossier.externalData.values;
                for (let j = 0; j < values.length; j++) {
                    let value = values[j];
                    if (value.id === item.id) {
                        values[j] = this.createItemData(item);
                        dossier.externalData.values = values;
                        return this.updateFinancialDossier(dossier);
                    }
                }
            }
        }
    };

    createNewItem = item => {
        let itemType = item.itemType;
        let dossierType = getDossierType(itemType.type === 'dossier' ? itemType.name :
            itemType.type === 'financial' ? 'financial_overview' : null);
        if (isNotNull(dossierType)) {
            if (dossierType.config.extendFinancial)
                this.createInDossier(item, dossierType);
            else this.createNewDossier(item, dossierType);
        }
    };

    createInDossier = (item, dossierType) => {
        let existingDossiers=false;
        for (let i = 0; i < this.dossiers.length; i++) {
            let dossier = this.dossiers[i];
            if (dossier.type.id === dossierType.id) {
                existingDossiers=true;
                const values = dossier.externalData.values;
                values.push(this.createItemData(item));
                this.updateFinancialDossier(dossier, () => {
                    this.alertSnackbar(item);
                });
            }
        }
        // No dossiers found to extend, create a new one
        if (existingDossiers=== false) {
          this.createNewDossier(item, dossierType);
        }
    };

    createNewDossier = (item, dossierType) => {
        const dossierName = dossierType.config.extendFinancial ? dossierType.label : item.description;
        new Dossier({
            name: dossierName,
            type: dossierType,
            external_data: {isDossier: item.createDossier, values: [this.createItemData(item)]}
        }).save(() => {
            this.afterLoad();
            this.submitCallback();
            dispatchCustomEvent('updateFinancial');
            this.alertSnackbar(item);
            this.loadDossiers();
        });
    };

    createItemData = item => ({
        id: item.id || generateUUID(),
        description: item.description,
        label: item.description,
        financialType: item.itemType.subtype || item.itemType.name,
        start_date: toMoment(item.start_date).format('YYYY-MM-DD'),
        end_date: isNotNull(item.end_date) ? toMoment(item.end_date).format('YYYY-MM-DD') : '',
        monthly_price: item.amount,
        frequency: item.frequency,
    });

    updateFinancialDossier = (dossier, callback = null) => {
        dossier.update(() => {
            this.afterLoad();
            this.submitCallback();
            dispatchCustomEvent('updateFinancial');
            if (isFunction(callback))
                callback();
            this.loadDossiers();
        });
    };

    alertSnackbar = item => {
        let frequency = item.frequency;
        let date = toMoment(item.start_date).format('MMMM YYYY');
        let text;
        if (frequency === 'one_time') {
            text = item.description + ' toegevoegd in ' + date;
        } else {
            text = item.description + ' toegevoegd vanaf ' + date;
        }
        dispatchCustomEvent('openSnackbar', {text});
    };

    warnNoCallback = () => {
        warn('FinancialHelper instance needs a load callback.');
    };
}
