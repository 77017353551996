import './style/overviewView.scss';

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class OverviewView extends React.Component {
    constructor(props) {
        super(props);
    }

    onTileClick = name => () => {
        const {onClickTile} = this.props;
        onClickTile(name);
    };

    renderTile = (tile, key) => {
        return (
            <div key={key} className='overviewTile' onClick={this.onTileClick(tile.name)}>
                <div className='tileHeader'>
                    <label className='tileTitle'>{tile.label}</label>
                </div>
                <div className='tileBody'>
                    <span className={classNames('tileIcon', tile.icon)}/>
                    <label className='tileDossierCount'>Aantal dossiers: {tile.dossierCount}</label>
                </div>
            </div>
        )
    };

    render = () => {
        const {tiles} = this.props;
        return (
            <div className='overviewView'>
                <div className='tileContainer'>
                    {tiles.map(this.renderTile)}
                </div>
            </div>
        )
    };
}

OverviewView.propTypes = {
    onClickTile: PropTypes.func.isRequired,
    tiles: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
        dossierCount: PropTypes.number.isRequired,
    })),
};
