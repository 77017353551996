import './datePicker.scss';
import 'rc-time-picker/assets/index.css';
import 'rc-calendar/assets/index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import {Control, Errors} from 'react-redux-form';
import Calendar from 'rc-calendar';
import nl_NL from 'rc-calendar/lib/locale/nl_NL'
import Panel from 'rc-time-picker/lib/Panel';

import {isFunction} from 'glob-common-js/lib/utils';
import {NOW} from "../../components/misc/constants";

export const timeFormats = [
    'MM/DD/YYYY HH:mm',
    'MM-DD-YYYY HH:mm',
    'DD/MM/YYYY HH:mm',
    'DD-MM-YYYY HH:mm',
    'YYYY-MM-DD HH:mm',
    'YYYY/MM/DD HH:mm',
    'DD/MM/YYYYTHH:mm',
    'DD-MM-YYYYTHH:mm',
    'YYYY-MM-DDTHH:mm',
    'YYYY/MM/DDTHH:mm',
    'YYYY-MM-DD',
    'YYYY/MM/DD',
    'MM-DD-YYYY',
    'MM/DD/YYYY',
    'DD-MM-YYYY',
    'DD/MM/YYYY',
];

export default class DatePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            calendarValue: this.convertToCalendarValue(props.defaultValue),
            mode: 'date',
        };
    }

    dateValidator = value => {
        return isNull(value) || (!(value instanceof Date) && this.validateUserInput(value));
    };

    validateUserInput = value => {
        if (isNotNull(value) && typeof value === 'string')
            value = value.trim();
        if (!this.isValidDateFormat(value)) return false;
        if (this.props.type === 'date') {
            return moment(value, timeFormats.slice(4)).isValid();
        } else if (this.props.type === 'datetime') {
            if (value.includes(' ')) {
                return moment(value, timeFormats.slice(0, 4)).isValid();
            }
            return moment(value, timeFormats.slice(4)).isValid();
        }
    };

    isValidDateFormat = value => /\d{2}-\d{2}-[12]\d{3}$|\d{2}-\d{2}-[12]\d{3} \d{2}:\d{2}|[12]\d{3}-\d{2}-\d{2}$|[12]\d{3}-\d{2}-\d{2} \d{2}:\d{2}|\d{2}\/\d{2}\/[12]\d{3}$|\d{2}\/\d{2}\/[12]\d{3} \d{2}:\d{2}|[12]\d{3}\/\d{2}\/\d{2}$|[12]\d{3}\/\d{2}\/\d{2} \d{2}:\d{2}/.test(value);

    getDefaultValue = () => {
        let defaultValue = this.props.defaultValue;
        if (isNotNull(defaultValue)) {
            if (!moment.isMoment(defaultValue)) {
                if (defaultValue instanceof Date) defaultValue = moment(defaultValue);
                else if (typeof defaultValue === 'string') defaultValue = moment(defaultValue, timeFormats);
            }
            return this.props.type === 'date' ? defaultValue.format('DD-MM-YYYY') : defaultValue.format('DD-MM-YYYY HH:mm');
        }
        return '';
    };

    convertToCalendarValue = value => {
        if (isNotNull(value)) {
            if (!moment.isMoment(value)) {
                if (value instanceof Date) value = moment(value);
                else if (typeof value === 'string') value = moment(value, timeFormats);
            }
            return value;
        }
        return NOW();
    };

    onCalendarChange = momentValue => {
        if (isNotNull(momentValue)) {
            this.setState({calendarValue: momentValue});
            this.props.onCalendarChange(momentValue);
        }
    };

    onInputClick = () => {
        let container = ReactDOM.findDOMNode(this.calendarContainer);
        if (isNotNull(container)) {
            container.classList.toggle('show');
            this.setState({mode: 'date'});
        }
    };

    onInputBlur = evt => {
        if (isNotNull(evt) && isNotNull(evt.target)) {
            let value = evt.target.value;
            if (this.validateUserInput(value)) {
                this.setState({calendarValue: this.convertToCalendarValue(value)});
                if (isFunction(this.props.onInputBlur)) {
                    this.props.onInputBlur(evt);
                }
            }
        }
    };

    onSelectDate = (value) => {
        this.onCalendarChange(value);
        if (this.props.type === 'datetime') {
            this.setState({mode: 'time'});
        } else {
            this.onInputClick();
        }
    };

    onDisplayChange = (date, mode) => {
        this.setState({mode});
        if (typeof this.props.onDisplayChange === 'function') {
            this.props.onDisplayChange(date, mode);
        }
    };

    renderTimePickerPanels = () => {
        if (this.props.type === 'datetime')
            return <Panel defaultValue={this.state.calendarValue} showSecond={false}/>;
        return null;
    };

    clickListener = () => {
        let container = ReactDOM.findDOMNode(this.calendarContainer);
        let input = ReactDOM.findDOMNode(this.input);
        if (areNotNull(container, input)) {
            if (!this.hasHover(container) && !this.hasHover(input)) {
                container.classList.remove('show');
                this.setState({mode: 'date'});
            }
        }
    };

    hasHover = element => {
        return (isNull(element) || isNull(element.parentElement)) || element.parentElement.querySelector(':hover') === element;
    };

    componentDidMount = () => {
        document.addEventListener('click', this.clickListener);
        let container = ReactDOM.findDOMNode(this.calendarContainer);
        if (isNotNull(container)) {
            let okButton = container.querySelector('a.rc-calendar-ok-btn');
            if (isNotNull(okButton)) {
                okButton.addEventListener('click', this.onInputClick);
            }
        }
    };

    componentWillUnmount = () => {
        document.removeEventListener('click', this.clickListener);
    };

    render = () => {
        return (
            <div className={'dateTimeForm ' + this.props.containerClass}>
                <div className='datePicker'>
                    <div className='inputContainer'>
                        <Control.input ref={refName => {
                            this.input = refName
                        }} validators={this.props.isRequired ? {validator: this.dateValidator} : {}}
                                       onBlur={this.onInputBlur} className={'datePickerField ' + this.props.inputClass}
                                       type='text' model={this.props.model} onClick={this.onInputClick}
                                       defaultValue={this.getDefaultValue()}
                                       placeholder={this.props.type === 'date' ? 'dd-mm-jjjj' : 'dd-mm-jjjj uu:mm'}
                                       required={this.props.isRequired}/>
                        <Errors className="errors dateError" model={this.props.model} show="touched"
                                messages={{
                                    validator: 'Ongeldige datum',
                                }}/>
                        <div ref={refName => {
                            this.calendarContainer = refName
                        }} className='calendarContainer'>
                            <Calendar showDateInput={false}
                                      defaultValue={this.convertToCalendarValue(this.state.calendarValue)}
                                      value={this.convertToCalendarValue(this.state.calendarValue)}
                                      onChange={this.onCalendarChange} onPanelChange={this.onDisplayChange}
                                      locale={nl_NL} mode={this.state.mode} onSelect={this.onSelectDate}
                                      className={'datePickerCalendar'} timePicker={this.renderTimePickerPanels()}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

DatePicker.propTypes = {
    containerClass: PropTypes.string,
    inputClass: PropTypes.string,
    model: PropTypes.string.isRequired,
    defaultValue: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.instanceOf(moment),
        PropTypes.string
    ]),
    onCalendarChange: PropTypes.func.isRequired,
    type: PropTypes.oneOf(['date', 'datetime']),
    onInputBlur: PropTypes.func,
    isRequired: PropTypes.bool,
};
DatePicker.defaultProps = {
    containerClass: '',
    inputClass: '',
    type: 'date',
    isRequired: true,
};