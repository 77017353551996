import './style/materialMenu.scss';

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import MenuList from '@material-ui/core/MenuList';

import {deviceIsMobile, getSetting} from 'glob-common-js/lib/utils';

import StateComponent from "../../misc/stateComponent";
import MaterialFactory from "../materialFactory";
import {materialTypes} from "../materialTypes";
import {dispatchCustomEvent} from "../../../misc/eventDispatcher";
import {sendGaEvent} from "../../../common/js/ga";
import {JSSMaterialMenu} from "./style/JSSMaterialMenu";
import {isGrantedLogin} from "../../../misc/utils";
import {getOnboardingManager} from "../../onboarding/manager/onboardingManagerHelper";

const logo = require('BdhCommon/images/logo_bydehand.svg');

export class MaterialMenu extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            anchor: null,
            subMenuOpen: false,
            items: [],
            placement: 'bottom'
        }
    }

    onAccountClick = () => {
        const {onNavigateItem, onLogout} = this.props;
        const logoutLabel = isGrantedLogin() ? 'Terug naar eigen account' : 'Uitloggen';
        const items = [
            {label: 'Accountgegevens', onClick: onNavigateItem('/account-gegevens')},
            {label: 'Machtigingen', onClick: onNavigateItem('/machtigingen')},
            {label: logoutLabel, onClick: onLogout}
        ];
        const target = document.querySelector('div.accountContainer');
        this.onOpenSubMenu({target}, items, 'bottom');
    };

    onAccountDoubleClick = () => {
        const {onNavigateItem} = this.props;
        this.onCloseSubMenu();
        onNavigateItem('/account-gegevens')();
    };

    onOpenSubMenu = (evt, items, placement = 'right') => {
        this.changeState({
            anchor: evt.target,
            subMenuOpen: true,
            items,
            placement,
        });
    };

    onNavigateSocial = socialName => {
        sendGaEvent('Menu', 'Klik social', socialName);
        this.onCloseMobile();
        switch (socialName) {
            case 'facebook':
                externalLink('//www.facebook.com/BenJijByDeHand/');
                break;
            case 'linkedin':
                externalLink('//www.linkedin.com/company/ByDeHand/');
                break;
            case 'instagram':
                externalLink('//www.instagram.com/benjijbydehand/');
                break;
        }
    };

    onCloseSubMenu = () => {
        this.changeState({
            anchor: null,
            subMenuOpen: false,
            items: [],
        });
    };

    onCloseMobile = () => {
        if (deviceIsMobile()) this.props.onClose();
    };

    onClickMenuItem = onClick => (evt) => {
        if (!getOnboardingManager().isActive())
            onClick(evt);
    };

    renderAppBar = () => {
        const {classes, onOpen, menuOpen, firstName, profileImage} = this.props;
        const mode = getSetting('mode');
        return (
            <AppBar position='fixed' className={classNames(classes.appBar, menuOpen && classes.appBarShift)}>
                <Toolbar disableGutters>
                    <IconButton color='inherit' aria-label='Open menu' onClick={onOpen}
                                className={classNames(classes.menuButton, menuOpen && classes.hide)}>
                        <MenuIcon/>
                    </IconButton>
                    <span className='pageTitleIcon'/>
                    <Typography id='pageTitle' className={classes.pageTitle} variant="h6" color="inherit" noWrap>
                        ByDeHand
                    </Typography>
                    <Typography style={{color:"#ED1C24"}} variant="h5">
                        ByDeHand is gestopt. Alle data wordt verwijderd.
                    </Typography>
                    <div className='rightContent'>
                        {mode !== 'prod' &&
                        <div className='modeWarning' onDoubleClick={evt => {
                            evt.target.style.display = 'none';
                        }}>{(mode === 'dev' ? 'Dev' : mode === 'test' ? 'Test' :
                            'Acceptatie') + ' versie!'}</div>}
                        {isNotNull(firstName) &&
                        <div className='accountContainer' onClick={this.onAccountClick}
                             onDoubleClick={this.onAccountDoubleClick}>
                            {isNotNull(profileImage) &&
                            <span style={{backgroundImage: `url(${profileImage})`}} className='profilePicture'/>}
                            <label className='firstName'>{this.renderAppBarFirstnames()}</label>
                        </div>}
                    </div>
                </Toolbar>
            </AppBar>
        );
    };

    renderAppBarFirstnames = () => {
        const {firstName, grantedFirstname} = this.props;
        if (isNotNull(grantedFirstname)) {
            return grantedFirstname + ' --- ' + firstName;
        }
        return firstName;
    };


    renderDrawer = () => {
        const {classes, menuOpen, onClose, onOpen, onNavigateItem, onLogout, profileImage, platformDisplayVersion} = this.props;
        const logoutLabel = isGrantedLogin() ? 'Terug naar eigen account' : 'Uitloggen';
        return (
            <SwipeableDrawer variant='permanent' PaperProps={{id: 'menuPaper'}} classes={{
                paper: classNames(classes.drawerPaper, !menuOpen && classes.drawerPaperClose),
            }} onOpen={onOpen} onClose={onClose} open={menuOpen} disableSwipeToOpen={false} swipeAreaWidth={60}>
                <div className={classes.toolbar}>
                    <IconButton onClick={onClose}>
                        <ChevronLeftIcon/>
                    </IconButton>
                </div>
                <Divider/>
                {this.renderDrawerVersion(platformDisplayVersion, classes)}
                <div className={classNames(classes.socialContainer, !menuOpen && classes.hide)}>
                    <img className={classes.logo} src={logo} alt='ByDeHand.com'/>
                    <div className={classes.socialButtonsContainer}>
                        <span className={classNames(classes.socialButton, 'common-icon-facebook')} onClick={() => {
                            this.onNavigateSocial('facebook')
                        }}/>
                        <span className={classNames(classes.socialButton, 'common-icon-linkedin')} onClick={() => {
                            this.onNavigateSocial('linkedin')
                        }}/>
                        <span className={classNames(classes.socialButton, 'common-icon-instagram')} onClick={() => {
                            this.onNavigateSocial('instagram')
                        }}/>
                    </div>
                </div>
            </SwipeableDrawer>
        )
    };

    renderDrawerVersion = (platformDisplayVersion, classes) => {
        switch(platformDisplayVersion)
        {
         case 'standard':
           return (
             <List className={classes.list}>
                 {this.renderDrawerItemDashboard()}
                 {this.renderDrawerItemData()}
                 {this.renderDrawerItemFinancial()}
                 {this.renderDrawerItemAnalyses()}
                 {this.renderDrawerItemSupport()}
                 {this.renderDrawerItemInfo()}
                 {this.renderDrawerItemAccount()}
             </List>
           );
         case 'lifeWill':
           return (
             <List className={classes.list}>
                 {this.renderDrawerItemDashboard()}
                 {this.renderDrawerItemDataV02()}
                 {this.renderDrawerItemInfo()}
                 {this.renderDrawerItemAccount()}
             </List>
           );
         default:
           return (
             <List className={classes.list}>
                 {this.renderDrawerItemDashboard()}
                 {this.renderDrawerItemData()}
                 {this.renderDrawerItemFinancial()}
                 {this.renderDrawerItemAnalyses()}
                 {this.renderDrawerItemSupport()}
                 {this.renderDrawerItemInfo()}
                 {this.renderDrawerItemAccount()}
             </List>
           );
       }
    }

    renderDrawerItemDashboard = () => {
      const {onNavigateItem} = this.props;
      return (
        this.renderDrawerItem('Dashboard', 'common-icon-car-speed-gauge', onNavigateItem('/dashboard'),
            'drawerDahboard')
      );
    }

    renderDrawerItemData = () => {
      const {onNavigateItem} = this.props;
      return (
        this.renderDrawerItem('Mijn gegevens', 'common-icon-folder-open2', () => {
            this.onOpenSubMenu({target: document.getElementById('drawerCloset')}, [
                {
                    label: 'Overzicht', onClick: () => {
                        onNavigateItem('/overzicht')();
                    }
                },
                {
                    label: 'Dossiers', onClick: () => {
                        onNavigateItem('/dossier-overzicht', true)();
                    }
                },
                {
                    label: 'Documenten', onClick: () => {
                        onNavigateItem('/bibliotheek')();
                    }
                },
                {
                    label: 'Contacten', onClick: () => {
                        onNavigateItem('/contacten')();
                    }
                }
            ])
        }, 'drawerCloset')
      );
    }

    renderDrawerItemDataV02 = () => {
      const {onNavigateItem} = this.props;
      return (
        this.renderDrawerItem('Mijn gegevens', 'common-icon-folder-open2', () => {
            this.onOpenSubMenu({target: document.getElementById('drawerCloset')}, [
                {
                    label: 'Dossiers', onClick: () => {
                        onNavigateItem('/dossier-overzicht', true)();
                    }
                },
                {
                    label: 'Documenten', onClick: () => {
                        onNavigateItem('/bibliotheek')();
                    }
                },
                {
                    label: 'Contacten', onClick: () => {
                        onNavigateItem('/contacten')();
                    }
                }
            ])
        }, 'drawerCloset')
      );
    }


    renderDrawerItemFinancial = () => {
      const {onNavigateItem} = this.props;
      return (
        this.renderDrawerItem('Financieel', 'common-icon-euro', (evt) => {
            this.onOpenSubMenu({target: document.getElementById('drawerFinancial')}, [
                {
                    label: 'Financieel overzicht', onClick: () => {
                        onNavigateItem('/financieel-overzicht')();
                    }
                },
                {
                    label: 'Inkomen toevoegen', onClick: () => {
                        this.onCloseMobile();
                        dispatchCustomEvent('triggerFinancialPopup', {moneyType: 'income'});
                    }
                },
                {
                    label: 'Uitgave toevoegen', onClick: () => {
                        this.onCloseMobile();
                        dispatchCustomEvent('triggerFinancialPopup', {moneyType: 'expense'});
                    }
                },
            ]);
        }, 'drawerFinancial')
      );
    }

    renderDrawerItemAnalyses = () => {
      const {onNavigateItem} = this.props;
      return (
        this.renderDrawerItem('Analyses & checklists', 'common-icon-file-checklist', () => {
            this.onOpenSubMenu({target: document.getElementById('drawerAnalyses')}, [
                {
                    label: 'Analyses', onClick: () => {
                        this.onCloseMobile();
                        onNavigateItem('/analyses')();
                    }
                },
                {
                    label: 'Checklists', onClick: () => {
                        this.onCloseMobile();
                        onNavigateItem('/checklists')();
                    }
                },
            ]);
        }, 'drawerAnalyses')
      );
    }

    renderDrawerItemSupport = () => {
      return (
        this.renderDrawerItem('Neem me ByDeHand', 'common-icon-student', (evt) => {
            this.onOpenSubMenu({target: document.getElementById('menuTutorial')}, [
                {
                    label: 'Uitleg', onClick: () => {
                        dispatchCustomEvent('startOnboarding');
                    }
                },
                {
                    label: 'Meest gebruikt', onClick: () => {
                        this.onCloseMobile();
                        dispatchCustomEvent('openUploadTips');
                    }
                },
            ]);
        }, 'menuTutorial')
      );
    }

    renderDrawerItemInfo = () => {
      return (
        this.renderDrawerItem('Info', 'common-icon-info', (evt) => {
            this.onOpenSubMenu({target: document.getElementById('drawerInfo')}, [
                {
                    label: 'Veiligheid', onClick: () => {
                        this.onCloseMobile();
                        dispatchCustomEvent('openSecurity', true);
                    }
                },
                {
                    label: 'Algemene voorwaarden', onClick: () => {
                        this.onCloseMobile();
                        window.externalLink('//bydehand.com/algemene-voorwaarden');
                    }
                },
                {
                    label: 'Privacy & cookies', onClick: () => {
                        this.onCloseMobile();
                        window.externalLink('//bydehand.com/privacy');
                    }
                },
                {
                    label: 'Contact', onClick: () => {
                        this.onCloseMobile();
                        dispatchCustomEvent('openContactPopup');
                    }
                }
            ]);
        }, 'drawerInfo')
      );
    }

    renderDrawerItemAccount = () => {
      const {classes, menuOpen, onClose, onOpen, onNavigateItem, onLogout, profileImage} = this.props;
      const logoutLabel = isGrantedLogin() ? 'Terug naar eigen account' : 'Uitloggen';
      return (
        isNotNull(profileImage) ? this.renderDrawerImageItem('Account', profileImage, () => {
                this.onOpenSubMenu({target: document.getElementById('drawerAccount')}, [
                    {label: 'Accountgegevens', onClick: onNavigateItem('/account-gegevens')},
                    {label: 'Machtigingen', onClick: onNavigateItem('/machtigingen')},
                    {label: logoutLabel, onClick: onLogout},
                ]);
            }, 'drawerAccount') :
            this.renderDrawerItem('Account', 'common-icon-person', () => {
                this.onOpenSubMenu({target: document.getElementById('drawerAccount')}, [
                    {label: 'Accountgegevens', onClick: onNavigateItem('/account-gegevens')},
                    {label: 'Machtigingen', onClick: onNavigateItem('/machtigingen')},
                    {label: logoutLabel, onClick: onLogout},
                ]);
            }, 'drawerAccount')
      );
    }


    renderDrawerItem = (label, icon, onClick, id) => (
        <ListItem id={id} button onClick={this.onClickMenuItem(onClick)} divider>
            <ListItemIcon className={this.props.classes.listIcon}>
                <span className={classNames(icon, 'menuIcon')}/>
            </ListItemIcon>
            <ListItemText classes={{primary: this.props.classes.listText}} inset primary={label}/>
        </ListItem>
    );

    renderDrawerImageItem = (label, img, onClick, id) => (
        <ListItem id={id} button onClick={onClick}>
            <ListItemIcon className={this.props.classes.listIcon}>
                <span style={{backgroundImage: `url(${img})`}} className={this.props.classes.menuImage}/>
            </ListItemIcon>
            <ListItemText classes={{primary: this.props.classes.listText}} inset primary={label}/>
        </ListItem>
    );

    renderPopper = () => (
        <Popper open={this.state.subMenuOpen} anchorEl={this.state.anchor} className={this.props.classes.popper}
                transition placement={this.state.placement}>
            {({TransitionProps, placement}) => (
                <Grow {...TransitionProps} id="menu-list-grow">
                    <Paper>
                        <ClickAwayListener onClickAway={this.onCloseSubMenu}>
                            <MenuList>
                                {this.state.items.map(this.renderMenuItem)}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    );

    renderMenuItem = (item, key) => (
        <MaterialFactory componentType={materialTypes.MENU_ITEM} key={key} onClick={() => {
            item.onClick();
            this.onCloseSubMenu();
        }}>
            <Typography className={this.props.classes.menuText}>
                {item.label}
            </Typography>
        </MaterialFactory>
    );

    componentDidMount = () => {
        this.mount();
    };

    componentWillUnmount = () => {
        this.unMount();
    };

    render = () => {
        const {classes, children, menuOpen, firstName} = this.props;
        const isLoggedIn = isNotNull(firstName);
        return (
            <div className={classNames(classes.root, 'materialMenu')}>
                {isLoggedIn && this.renderAppBar()}
                {isLoggedIn && this.renderDrawer()}
                {isLoggedIn && this.renderPopper()}
                <main id='mainContent'
                      className={classNames(classes.content, menuOpen && classes.appBarShift, menuOpen && classes.xlAuto,
                          isLoggedIn && classes.contentMenuOpen, !menuOpen && classes.contentMenuClosed)}>
                    {isNotNull(firstName) && <div className={classes.toolbar}/>}
                    {children.map((child, key) => {
                        if (child instanceof Object) {
                            child = Object.assign({}, child, {key});
                        }
                        return child;
                    })}
                </main>
            </div>
        )
    };
}

MaterialMenu.propTypes = {
    onLogout: PropTypes.func.isRequired,
    onOpen: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onNavigateItem: PropTypes.func.isRequired,
    onNavigateExternal: PropTypes.func.isRequired,
    menuOpen: PropTypes.bool.isRequired,
    firstName: PropTypes.string.isRequired,
    profileImage: PropTypes.string,
    grantedFirstname: PropTypes.string,
};

export default withStyles(JSSMaterialMenu)(MaterialMenu);
