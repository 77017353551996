import React from 'react';
import moment from 'moment';

import FinancialHelper from "../helper/financialHelper";
import FinancialDashboardView from "./financialDashboardView";
import {addCustomEventListener, removeCustomEventListener} from "../../../misc/eventDispatcher";
import {navigate} from "../../../misc/utils";
import {sendGaEvent} from "../../../common/js/ga";
import {NOW} from "../../misc/constants";
import StateComponent from "../../misc/stateComponent";


const GA_CATEGORY = 'Dashboard';
export default class FinancialDashboardController extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            date: NOW(),
            loaded: false,
        };
    }

    startLoad = () => {
        this.changeState({loaded: false});
        this.financialHelper.loadDossiers();
    };

    processItems = ({financialItems, defaultItems}) => {
        let items = financialItems.concat(defaultItems);
        let categories = {};
        if (isNotNull(items)) {
            for (let i = 0; i < items.length; i++) {
                let item = items[i];
                if (this.itemIsInRange(item)) {
                    let category = item.category;
                    if (isNotNull(category)) {
                        if (!categories.hasOwnProperty(category.name))
                            categories[category.name] = {
                                type: category.type,
                                label: category.label,
                                total: item.monthly_price,
                                stroke: category.stroke,
                            };
                        else {
                            categories[category.name].total += item.monthly_price;
                        }
                    }
                }
            }
        }
        this.changeState({categories: Object.values(categories), loaded: true});
    };

    itemIsInRange = item => {
        let selectedDate = moment(this.state.date);
        let selectedMonth = selectedDate.month(), selectedYear = selectedDate.year();
        let startDate = moment(item.start_date, ['DD-MM-YYYY', 'YYYY-MM-DD']), endDate = isNotNull(item.end_date) ?
            moment(item.end_date, ['DD-MM-YYYY', 'YYYY-MM-DD']) : null, frequency = item.frequency;
        let startMonth = startDate.month(), startYear = startDate.year();
        if (startMonth === selectedMonth && startYear === selectedYear) return true;

        if (selectedDate.isSameOrAfter(startDate, 'month') && (isNull(endDate) ||
            selectedDate.isSameOrBefore(endDate, 'month'))) {
            switch (frequency) {
                case 'one_time':
                    return false;
                case 'monthly':
                    return true;
                case 'quarterly':
                    return [startMonth, this.addMonths(startMonth, 3), this.addMonths(startMonth, 6),
                        this.addMonths(startMonth, 9)].includes(selectedMonth);
                case 'half_year':
                    return [startMonth, this.addMonths(startMonth, 6)].includes(selectedMonth);
                case 'yearly':
                    return selectedMonth === startMonth;
            }
        }
        return false;
    };

    addMonths = (month, monthsToAdd) => {
        let nextMonth = month + monthsToAdd;
        return nextMonth > 11 ? nextMonth - 12 : nextMonth;
    };

    setMonth = (type) => {
        // Need to duplicate state moment, since it should be immutable, but moment isn't
        let selectedDate = moment(this.state.date);
        let action = 'Dashboard click' + (window.highlightsActive ? ' highlights' : '');
        if (type === 'next') {
            sendGaEvent(GA_CATEGORY, action, 'Financieel volgende maand');
            selectedDate.add(1, 'months');
        } else if (type === 'previous') {
            sendGaEvent(GA_CATEGORY, action, 'Financieel vorige maand');
            selectedDate.subtract(1, 'months');
        }
        this.changeState({date: selectedDate});
    };

    setSelectedDate = (date) => {
        let action = 'Dashboard click' + (window.highlightsActive ? ' highlights' : '');
        sendGaEvent(GA_CATEGORY, action, 'Financieel kies maand');
        this.changeState({date})
    };

    navigateToFinancial = () => {
        let action = 'Dashboard click' + (window.highlightsActive ? ' highlights' : '');
        sendGaEvent(GA_CATEGORY, action, 'Naar financieel overzicht');
        navigate('/financieel-overzicht');
    };

    changeDateEvent = date => {
        this.changeState({date: moment(date)});
    };

    componentDidMount = () => {
        this.mount();
        this.financialHelper = new FinancialHelper({
            callback: this.processItems,
            source: 'FinancialDashboardController'
        });
        this.startLoad();
        addCustomEventListener('updateFinancial', this.startLoad);
        addCustomEventListener('updatePlatform', this.startLoad);
        addCustomEventListener('setFinancialDashboardDate', this.changeDateEvent);
    };

    componentDidUpdate = (prevProps, prevState) => {
        let selectedDate = this.state.date;
        if (!prevState.date.isSame(selectedDate)) {
            this.startLoad();
        }
    };

    componentWillUnmount = () => {
        this.unMount();
        this.financialHelper = null;
        removeCustomEventListener('updateFinancial', this.startLoad);
        removeCustomEventListener('updatePlatform', this.startLoad);
        removeCustomEventListener('setFinancialDashboardDate', this.changeDateEvent);
    };

    render = () => {
        return <FinancialDashboardView setMonth={this.setMonth} setDate={this.setSelectedDate}
                                       toFinancial={this.navigateToFinancial}
                                       date={this.state.date}
                                       categories={this.state.categories}
                                       loaded={this.state.loaded}/>
    };
}