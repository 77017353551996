/**
 * Redux store actions used to change the Redux store data.
 */

import {
    appCallbackConstants,
    appConstants,
    documentConstants,
    dossierConstants,
    financialConstants,
    healthConstants,
    introductionConstants,
    miscConstants,
    moduleConstants,
    tenderConstants,
    userConstants,
} from './constants';
import initialState from './initialState';

// Destructure initialState to separate reducers
const {dossier, tender, healthInsuranceAnalysis} = initialState;

//----------------------------
// User actions
//----------------------------

/**
 * Set the user details
 */
export const setUser = user => ({
    type: userConstants.SET_USER,
    user
});

export const setFamilyMemberId = id => ({
    type: userConstants.SET_FAMILY_MEMBER_ID,
    id
});

export const setRegistrationMail = mail => ({
    type: userConstants.SET_REGISTRATION_MAIL,
    mail,
});

export const setSubscription = subscription => ({
    type: userConstants.SET_SUBSCRIPTION,
    subscription,
});

export const setGrantedUser = grantedUser => ({
    type: userConstants.SET_GRANTED_USER,
    grantedUser,
});

//----------------------------
// Dossier actions
//----------------------------

export const setDossierType = dossierType => ({
    type: dossierConstants.SET_DOSSIER_TYPE,
    dossierType
});

export const resetDossierType = () => {
    return setDossierType(initialState.dossier.dossierType);
};

export const setDossierId = id => ({
    type: dossierConstants.SET_DOSSIER_ID,
    id
});

export const resetDossierId = () => ({
    type: dossierConstants.SET_DOSSIER_ID,
    id: dossier.dossierId,
});

export const setDossier = dossier => ({
    type: dossierConstants.SET_DOSSIER,
    dossier
});

export const setEditDossier = dossier => ({
    type: dossierConstants.SET_EDIT_DOSSIER,
    dossier
});

export const resetEditDossier = () => ({
    type: dossierConstants.SET_EDIT_DOSSIER,
    dossier: dossier.editDossier,
});

export const setAddedDossiers = dossiers => ({
    type: dossierConstants.SET_ADDED_DOSSIERS,
    dossiers,
});

export const selectDossierSubcategory = subCategory => ({
    type: dossierConstants.SELECT_SUBCATEGORY,
    subCategory,
});

export const resetDossierSubcategory = () => selectDossierSubcategory('none');

export const selectDossierCategory = category => ({
    type: dossierConstants.SELECT_CATEGORY,
    category
});

export const resetDossierCategory = () => selectDossierCategory('none');

export const setDossierDocumentType = documentType => ({
    type: dossierConstants.SET_DOSSIER_DOCUMENT_TYPE,
    documentType,
});

export const resetDossierDocumentType = () => setDossierDocumentType(initialState.dossier.dossierDocumentType);

//----------------------------
// Document actions
//----------------------------

export const setDocIDS = ids => ({
    type: documentConstants.SET_DOC_IDS,
    ids
});

export const setDocType = id => ({
    type: documentConstants.SET_DOCTYPE,
    id
});

export const setAddedDocs = docs => ({
    type: documentConstants.SET_ADDED_DOCS,
    docs
});

export const setDocDisplayLabel = label => ({
    type: documentConstants.SET_DOC_DISPLAY_LABEL,
    label
});

export const setUploadedDoc = docData => ({
    type: documentConstants.SET_UPLOADED_DOC,
    docData,
});

export const resetUploadedDoc = () => {
    return setUploadedDoc(initialState.document.uploadedDoc);
};

export const setDocumentDetailId = id => ({
    type: documentConstants.SET_DOCUMENT_DETAIL_ID,
    id,
});

export const resetDocumentDetailId = () => {
    return setDocumentDetailId(null);
};

export const setDocumentToLink = doc => ({
    type: documentConstants.SET_DOCUMENT_TO_LINK,
    doc,
});

export const resetDocumentToLink = () => setDocumentToLink(null);

//----------------------------
// Tender actions
//----------------------------

export const setTenderInfo = info => ({
    type: tenderConstants.SET_TENDER_INFO,
    info
});

export const resetTenderInfo = () => ({
    type: tenderConstants.SET_TENDER_INFO,
    tenderInfo: tender.tenderInfo,
});


//----------------------------
// Misc actions
//----------------------------

export const selectCategory = name => ({
    type: miscConstants.SELECT_CATEGORY,
    name
});

export const selectSubCategory = name => ({
    type: miscConstants.SELECT_SUB_CATEGORY,
    name
});

export const setConfirmInfo = info => ({
    type: miscConstants.SET_CONFIRM_INFO,
    info,
});

export const setPopupShow = bool => ({
    type: miscConstants.SHOW_LOGIN_POPUP,
    bool,
});

export const resetConfirmInfo = () => (
    setConfirmInfo(initialState.misc.confirmInfo)
);

export const setUserEmail = email => ({
    type: miscConstants.SET_EMAIL,
    email,
});

export const startFlow = start => ({
    type: miscConstants.START_FLOW,
    start,
});

//-----------------------------------
// Health insurance analysis actions
//-----------------------------------

export const setCurrentInsurance = insurance => ({
    type: healthConstants.SET_CURRENT_INSURANCE,
    insurance,
});

export const resetCurrentInsurance = () => (
    setCurrentInsurance(healthInsuranceAnalysis.currentInsurance)
);

export const setEvaluation = evaluation => ({
    type: healthConstants.SET_EVALUATION,
    evaluation,
});

export const resetEvaluation = () => (
    setEvaluation(healthInsuranceAnalysis.evaluation)
);

export const setEvaluationBasic = basic => ({
    type: healthConstants.SET_BASIC_EVALUATION,
    basic,
});

export const setEvaluationAdditional = additional => ({
    type: healthConstants.SET_ADDITIONAL_EVALUATION,
    additional,
});

export const setEvaluationInsurer = insurer => ({
    type: healthConstants.SET_INSURER_EVALUATION,
    insurer,
});

export const setEvaluationRisk = risk => ({
    type: healthConstants.SET_RISK_EVALUATION,
    risk,
});

export const setEvaluationSummary = summary => ({
    type: healthConstants.SET_SUMMARY_EVALUATION,
    summary,
});

export const setExplaningPopupShown = popupShown => ({
    type: healthConstants.SET_EXPLANINGPOPUP_SHOWN,
    popupShown,
});

export const setRatingShown = ratingShown => ({
    type: healthConstants.SET_RATING_SHOWN,
    ratingShown,
});

export const showFb = show => ({
    type: healthConstants.SHOW_FB,
    show,
});

//-----------------------------------
// App actions
//-----------------------------------

export const setFileToUpload = fileData => ({
    type: appConstants.SET_FILE_TO_UPLOAD,
    fileData,
});

export const resetFileToUpload = () => ({
    type: appConstants.SET_FILE_TO_UPLOAD,
    fileData: initialState.app.fileToUpload,
});

//-----------------------------------
// Module actions
//-----------------------------------

export const setActiveModules = modules => ({
    type: moduleConstants.ACTIVE_MODULES,
    modules,
});

export const selectModule = module => ({
    type: moduleConstants.SELECT_MODULE,
    module,
});

export const resetModule = () => selectModule(initialState.module.selectedModule);

//-----------------------------------
// App callback actions
//-----------------------------------

export const resetAppAgendaItem = () => ({
    type: appCallbackConstants.RESET_AGENDA_ITEM,
    config: initialState.appCallback.agenda,
});

export const appAddAgendaItem = (defaultData, callback) => ({
    type: appCallbackConstants.ADD_AGENDA_ITEM,
    config: {mode: 'add', defaultData, callback},
});

export const appEditAgendaItem = (defaultData, callback) => ({
    type: appCallbackConstants.EDIT_AGENDA_ITEM,
    config: {mode: 'edit', defaultData, callback},
});

export const resetAppDocument = () => ({
    type: appCallbackConstants.RESET_DOCUMENT,
    config: initialState.appCallback.document,
});

export const appAddDocument = (documentType, callback, showExisting = false, onDocumentClick = null) => ({
    type: appCallbackConstants.ADD_DOCUMENT,
    config: {mode: 'add', documentType, callback, showExisting, onDocumentClick},
});

export const setAgendaItemToAdd = agendaItem => ({
    type: appCallbackConstants.SET_AGENDA_ITEM_TO_ADD,
    agendaItem,
});

export const resetAgendaItemToAdd = () => {
    return setAgendaItemToAdd(null);
};

//-----------------------------------
// Introduction actions
//-----------------------------------

export const activateIntroduction = active => ({
    type: introductionConstants.ACTIVATE,
    active,
});

export const setAgendaTab = tab => ({
    type: introductionConstants.SET_AGENDA_TAB,
    tab,
});

//-----------------------------------
// Financial actions
//-----------------------------------

export const resetFinancialDossiers = () => ({
    type: financialConstants.RESET_FINANCIAL_DOSSIERS,
});

export const setFinancialDossiers = dossiers => ({
    type: financialConstants.SET_FINANCIAL_DOSSIERS,
    dossiers,
});

export const addFinancialDossier = dossier => ({
    type: financialConstants.ADD_FINANCIAL_DOSSIER,
    dossier,
});

export const updateFinancialDossier = dossier => ({
    type: financialConstants.UPDATE_FINANCIAL_DOSSIER,
    dossier,
});

export const deleteFinancialDossier = id => ({
    type: financialConstants.DELETE_FINANCIAL_DOSSIER,
    id,
});
