import React from 'react';
import PropTypes from 'prop-types';

import EvaluationButonsController from '../misc/evaluationButtons/evaluationButtonsController'
import evaluationConst from '../misc/constants'
import HealthAnalysisPopupController from "../../misc/healthAnalysisPopup/healthAnalysisPopupController";
import {navigate} from "../../../../../misc/utils";
import {generateRandomKey, roundTo, scrollToTop} from 'glob-common-js/lib/utils';

export class EvaluationBasicView extends React.Component {
    constructor(props) {
        super(props);
    }

    populateBasicInsurance = (name, policyType, isCovered, isntCovered, startPrice, endPrice, popup) => {
        return (
            <div
                className={"basicCoverageContainer" + (name === policyType ? ' selected' : '')}>
                <div className="basicCoverageMenu">
                    <div className="basicHeader">
                        <div className="basicHeaderTitlebar">
                            <h1 className="basicHeaderTitle">{name}</h1>
                            <i className="basicInfoIcon">
                                <i className="common-icon-info"/>
                                {popup}
                            </i>
                        </div>
                    </div>
                    <div className="basicBody">
                        <div className="basicBodyText">
                            {isCovered}
                        </div>
                        <div className="basicBodyText basicNotCovered">
                            {isntCovered}
                        </div>
                        <div className="basicBodyPrice">
                            <p>{"€ " + startPrice + " tot"} <br/> {"€ " + endPrice + " p.m."}</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    generateDropDownMenu = (question, explenation) => {
        const dropdownEvent = () => {
            document.getElementById(dropdownKey).classList.toggle("invisible");
            document.getElementById(arrowKey).classList.toggle("up");
        };

        let dropdownKey = generateRandomKey();
        let arrowKey = generateRandomKey();
        return (
            <div className="dropdownMenu" onClick={dropdownEvent}>
                <div className="dropdownMenuHead">
                    <h1 className="dropdownMenuHeadTitle">{question}</h1>
                    <i id={arrowKey} className="dropdownMenuHeadArrow"/>
                </div>
                <div id={dropdownKey} className="dropdownMenuBody invisible">
                    <div>{explenation}</div>
                </div>
            </div>
        )
    };

    navigateAfterDecision = () => {
        navigate('/zorganalyse/evaluatie/aanvullende-verzekering', true);
        scrollToTop();
    };

    createBasicCoveragePopup = (kind, firstText, secondText, optionalText, index) => {
        return (
            <div className={"basicCoveragePopup " + index}>
                <p>{kind}</p>
                <p>{firstText}</p>
                <p>{secondText}</p>
                {optionalText === '' ? null : <p>{optionalText}</p>}
                <i className="basicCoveragePopupArrow"/>
            </div>
        );
    };

    render = () => {
        let evalButtons = [
            {
                buttonText: 'Ja, dit past bij mij',
                onClick: () => {
                    this.props.setEvaluation(evaluationConst.SATISFIED);
                    this.navigateAfterDecision();
                },
                defaultSelected: this.props.evaluationDecision === evaluationConst.SATISFIED,
            },
            {
                buttonText: 'Nee, dit past niet (meer) bij mij',
                onClick: () => {
                    this.props.setEvaluation(evaluationConst.DISSATISFIED);
                    this.navigateAfterDecision();
                },
                defaultSelected: this.props.evaluationDecision === evaluationConst.DISSATISFIED,
            },
            {
                buttonText: 'Dat weet ik (nog) niet',
                onClick: () => {
                    this.props.setEvaluation(evaluationConst.UNSURE);
                    this.navigateAfterDecision();
                },
                defaultSelected: this.props.evaluationDecision === evaluationConst.UNSURE,
            }
        ];

        let insurance = this.props.basicInsurance;
        let insuranceLabel = insurance.insurance;
        let price = insurance.price_basic_incl_discount;
        let policyType = insurance.policy_type;
        let coveredText = insurance.covered_no_contract;
        let selectedPolicy;
        switch (policyType) {
            case 'Restitutiepolis':
                selectedPolicy = 'firstInsurance';
                break;
            case 'Combinatiepolis':
                selectedPolicy = 'secondInsurance';
                break;
            case 'Naturapolis':
                selectedPolicy = 'thirdInsurance';
                break;
            case 'Budgetpolis':
                selectedPolicy = 'fourthInsurance';
                break;
        }
        return (
            <div className="basicInsurance">
                {/*Insurance information*/}
                <div>
                    <p>De dekking van de basisverzekering is wettelijk bepaald en dus bij alle verzekeringen gelijk.
                        Echter er zitten wel grote verschillen in het aantal gecontracteerde zorgverleners en in
                        hoeverre je zelf moet bijbetalen.</p>
                </div>

                {/*Your insurance popup*/}
                <div id="insurancePopup" className={"currentInsurance " + selectedPolicy}>
                    <div className="currentInsurancePopup">
                        <div className="currentInsuranceHeader">
                            <h1 className="currentInsuranceHeaderTitle">Jouw verzekering</h1>
                        </div>
                        <div className="currentInsuranceBody">
                            <h1 className="currentInsuranceBodyTitle">{insuranceLabel}</h1>
                            <p className='currentInsuranceText currentInsuranceType'>{policyType}</p>
                            <p className='currentInsuranceText'>{coveredText}</p>
                            <p className='currentInsuranceText'>
                                Prijs: &euro; {roundTo(price, 2).replace('.', ',')} per maand</p>
                        </div>
                    </div>
                    <div>
                        <i className="currentInsurancePopupArrow"/>
                    </div>
                </div>

                {/*adjustment bar*/}
                <div className="priceBar">
                    <div className="priceBarDesc">
                        <p className="priceBarHigh">Hogere kosten, veel aanbod</p>
                        <p className="priceBarLow">Lagere kosten, minder aanbod</p>
                    </div>
                    <i className="lineArrow left"/>
                    <i className="lineArrow right"/>
                    <hr/>
                </div>

                {/*Basicinsurance tabs*/}
                <div className="basicCoverage">
                    {this.populateBasicInsurance("Restitutiepolis", policyType, <p>Bij <b>alle</b> gecontracteerde
                            zorgverleners
                            volledige vergoeding</p>, <p>Bij alle niet-gecontracteerde zorgverzekeraars <b>volledige </b>
                            vergoeding</p>
                        , "116,50", "135,80", this.createBasicCoveragePopup(
                            <span>
                                Bij een <b>restitutiepolis</b> krijg je een volledige vergoeding en heb je volledige keuzevrijheid tussen zorgaanbieders:
                            </span>,
                            <span>
                                <b>Gecontracteerde zorgverleners:</b> bij zorgverleners waarmee jouw verzekeraar een contract heeft gesloten wordt je zorg <b>volledig vergoed.</b>
                            </span>,
                            <span>
                                <b>Niet-gecontracteerde zorgverleners:</b> bij zorgverleners waarmee jouw verzekeraar geen contract heeft gesloten wordt je zorg ook <b>volledig vergoed.</b>
                            </span>,
                            '', 'first')
                    )}
                    {this.populateBasicInsurance("Combinatiepolis", policyType, <p>Bij <b>ruim</b> aantal
                        gecontracteerde
                        zorgverleners volledige vergoeding</p>, <p>Bij niet-gecontracteerde zorgverleners betaal je zelf
                        de kosten boven de
                        standaardprijzen</p>, "114,95", "121,80", this.createBasicCoveragePopup(
                        <span>
                            De <b>combinatiepolis</b> combineert een naturapolis met een restitutiepolis.
                            </span>,
                        <span>
                            <b>Gecontracteerde zorgverleners:</b> bij zorgverleners waarmee jouw verzekeraar een contract heeft gesloten wordt je zorg <b>volledig vergoed.</b>
                            </span>,
                        <span>
                            <b>Niet-gecontracteerde zorgverleners:</b> bij zorgverleners waarmee jouw verzekeraar geen contract heeft gesloten wordt je zorg ook vergoed tot <b>maximaal het tarief dat met gecontracteerde zorgverleners is afgesproken.</b> Je loopt hier dus het risico dat je zelf een deel moet betalen.
                            </span>,
                        <span>
                                In de polisvoorwaarden of in de zorgzoeker op de website vind je welke zorgverleners gecontracteerd zijn en welke niet.
                            </span>,
                        'second')
                    )}
                    {this.populateBasicInsurance("Naturapolis", policyType, <p>Bij <b>ruim</b> aantal gecontracteerde
                        zorgverleners
                        volledige vergoeding</p>, <p>Bij niet-gecontracteerde zorgverleners wordt 65-90% van het
                        totaalbedrag vergoed</p>, "107,90", "126,84", this.createBasicCoveragePopup(
                        <span>
                            De <b>naturapolis</b> geeft slechts een gedeeltelijke vergoeding in geval er geen contract is met een zorgverlener.
                            </span>,
                        <span>
                            <b>Gecontracteerde zorgverleners:</b> bij zorgverleners waarmee jouw verzekeraar een contract heeft gesloten wordt je zorg <b>volledig vergoed.</b>
                            </span>,
                        <span>
                            <b>Niet-gecontracteerde zorgverleners:</b> bij zorgverleners waarmee jouw verzekeraar geen contract heeft gesloten wordt slechts een <b>gedeelte vergoed.</b> Bij deze zorgverleners moet je dus zelf een deel betalen.
                            </span>,
                        <span>
                                In de polisvoorwaarden of in de zorgzoeker op de website vind je welke zorgverleners gecontracteerd zijn en welke niet.
                            </span>,
                        'third')
                    )}
                    {this.populateBasicInsurance("Budgetpolis", policyType, <p>Bij <b>beperkt</b> aantal gecontracteerde
                        zorgverleners volledige vergoeding</p>, <p>Bij niet-gecontracteerde zorgverleners wordt 65%-90%
                        van het totaalbedrag
                        vergoed</p>, "101,95", "115,95", this.createBasicCoveragePopup(
                        <span>
                            De <b>budgetpolis</b> is een variant van de naturapolis en geeft slechts een gedeeltelijke vergoeding in geval er geen contract is met een zorgverlener. In vergelijking tot de naturapolis moet je bij veel meer zorgverleners een gedeelte zelf betalen.
                            </span>,
                        <span>
                            <b>Gecontracteerde zorgverleners:</b> bij zorgverleners waarmee jouw verzekeraar een contract heeft gesloten wordt je zorg <b>volledig vergoed</b>. Bij een budgetpolis is slechts een beperkt deel van de zorgverleners (ook ziekenhuizen) gecontracteerd.
                            </span>,
                        <span>
                            <b>Niet-gecontracteerde zorgverleners:</b> bij zorgverleners waarmee jouw verzekeraar geen contract heeft gesloten wordt slechts een <b>gedeelte vergoed</b>. Bij deze zorgverleners moet je dus zelf een deel betalen.
                            </span>,
                        <span>
                                In de polisvoorwaarden of in de zorgzoeker op de website vind je welke zorgverleners gecontracteerd zijn en welke niet.
                            </span>,
                        'fourth'))}
                </div>

                {/*dropdown questions*/}
                <div className="dropdownMenus">
                    {this.generateDropDownMenu("Welke ziektekosten vallen onder de dekking van mijn basisverzekering?",
                        "Het is in de Zorgverzekeringswet vastgelegd dat de volgende zorgkosten gedekt worden door je basisverzekering: huisartsbezoek, ziekenhuiszorg, tandartskosten tot 18 jaar, kraamzorg en verloskundige hulp, fysiotherapie tot 18 jaar en fysiotherapie voor chronische aandoeningen, geneesmiddelen, psychologische hulp, wijkverpleging en apotheekkosten.")}
                    {this.generateDropDownMenu("Hoe zit het met het eigen risico?",
                        <div>
                            <span className='marginText'>Als je gebruik maakt van zorg die valt onder de basisverzekering dan moet je het eerste deel van de gemaakte kosten zelf betalen. Dit heet het eigen risico.</span>
                            <span className='marginText'>Door de overheid is bepaald dat elke volwassene een verplicht eigen risico heeft van € 385,-.
Je kunt er voor kiezen het eigen risico te verhogen tot maximaal € 885,-. Het vrijwillig eigen risico is dan € 500,-. Het voordeel van een hoger eigen risico is dat de maandelijkse premie lager is. Het nadeel is dat je meer kosten zelf moet betalen in geval je gebruik maakt van die zorg.
</span>
                            <span>Voor niet alle zorg uit de basisverzekering geldt het eigen risico.
Uitgezonderd zijn huisarts, verloskunde en kraamzorg, wijkverpleging en specifieke kosten bij diabetes mellitus type 2, COPD, CVR en orgaandonatie
Het eigen risico geldt ook niet voor aanvullende en tandarts verzekeringen
</span>
                        </div>)}
                    {this.generateDropDownMenu("Wat zijn (niet) gecontacteerde zorgverleners?", "Verzekeraars sluiten contracten met ziekenhuizen en andere zorgaanbieders over de kwaliteit en de prijs voor zorg aan patiënten. Wanneer een patiënt van deze gecontracteerde zorg gebruik maakt zal deze zorg volledig worden vergoed. In geval je gebruik maakt van zorg van een zorgverlener waar geen contract mee is, moet je mogelijk een deel zelf betalen. Hoeveel je in dat geval zelf moet bijbetalen verschilt per (type) verzekering. Als je zeker wilt zijn dat je niet hoeft bij te betalen moet je een restitutie-polis kiezen. Het nadeel hiervan is dat de maandelijkse premie hoger is. "
                    )}
                </div>

                {/*Redux buttons*/}
                <div>
                    <div className="basicSatisfied">
                        <EvaluationButonsController
                            headerTitle={'Ben je tevreden met de dekking van jouw basisverzekering?'}
                            buttons={evalButtons}/>
                    </div>
                </div>

                {/*Health analyses popup*/}
                <HealthAnalysisPopupController/>
            </div>
        )
    }
}

export default EvaluationBasicView;

EvaluationBasicView.propTypes = {
    setEvaluation: PropTypes.func.isRequired,
    evaluationDecision: PropTypes.string.isRequired,
    basicInsurance: PropTypes.shape({
        insurance: PropTypes.string.isRequired,
        price_basic: PropTypes.number,
        price_basic_incl_discount: PropTypes.number,
        policy_type: PropTypes.string.isRequired,
        covered_no_contract: PropTypes.string.isRequired,
    }).isRequired,
};
