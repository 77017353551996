import React from 'react';
import StateComponent from "../../misc/stateComponent";
import {shareDocuments} from "../../../misc/requestSender";
import DocumentShare from "./documentShare";
import {addCustomEventListener, removeCustomEventListener} from "../../../misc/eventDispatcher";

export default class DocumentShareController extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            loading: false,
        };
        this.documents = [];
        this.dossiers = [];
    }

    activate = ({documents, dossiers}) => {
        this.documents = documents || [];
        this.dossiers = dossiers || [];
        this.changeState({active: true});
    };

    onShareDocuments = (recipients, description) => () => {
        this.changeState({loading: true});
        shareDocuments({
            recipients,
            description,
            file_ids: this.documents,
            dossier_ids: this.dossiers,
            callback: response => {
                this.onClose();
            }
        });
    };

    onClose = () => {
        this.changeState({active: false, loading: false});
        this.documents = [];
        this.dossiers = [];
    };

    componentDidMount = () => {
        this.mount();
        addCustomEventListener('shareDocuments', this.activate);
    };

    componentWillUnmount = () => {
        this.unMount();
        removeCustomEventListener('shareDocuments', this.activate);
    };

    render = () => (
        <DocumentShare onShare={this.onShareDocuments} active={this.state.active} onClose={this.onClose}
                       loading={this.state.loading}/>
    )
}