import './style/registrationView.scss';

import React from 'react';
import PropTypes from 'prop-types';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import {steps} from "./registrationConstants";
import RegistrationStepController from "./steps/registration/registrationStepController";
import ActivationStepController from "./steps/activation/activationStepController";
import CompletionController from "./steps/completion/completionController";
import GenericLoader from "../misc/genericLoader";
import ErrorStep from "./steps/error/errorStep";

const logo = require('../../common/images/logo_bydehand_trans.png');

export default class RegistrationView extends React.Component {
    constructor(props) {
        super(props);
    }

    renderStep = () => {
        switch (this.props.step) {
            case steps.ERROR:
                return <ErrorStep onClickBack={this.props.onClickBack} errorMessage={this.props.error}
                                  setStep={this.props.onSetStep} errorType={this.props.errorType}/>;
            case steps.REGISTRATION:
                return <RegistrationStepController onSetStep={this.props.onSetStep} onError={this.props.onError}
                                                   onSetLoading={this.props.onSetLoading}
                                                   onSetUser={this.props.onSetUser}
                                                   onClickLogin={this.props.onClickLogin}/>;
            case steps.ACTIVATION.index:
                return <ActivationStepController onSetStep={this.props.onSetStep} onError={this.props.onError}
                                                 subStep={this.props.subStep} user={this.props.user}
                                                 setTransactionInfo={this.props.onSetTransactionInfo}
                                                 setUser={this.props.onSetUser} flex={this.props.flex}/>;
            case steps.COMPLETION.index:
                return <CompletionController subStep={this.props.subStep} user={this.props.user}
                                             transactionInfo={this.props.transactionInfo}
                                             onDeactivate={this.props.onDeactivate} flex={this.props.flex}/>;
            default:
                return null;
        }
    };

    renderHeaderTitle = () => {
        const titles = {
            [steps.ERROR]: 'Foutje',
            [steps.REGISTRATION]: 'Aanmelden',
            [steps.ACTIVATION.index]: 'Activeren',
            [steps.COMPLETION.index]: 'Starten',
            'default': 'Aanmelden',
        };
        let title = titles[this.props.step] || titles.default;
        return <label className='headerTitle'>{title}</label>
    };

    render = () => {
        const hasError = this.props.step === steps.ERROR;
        return (
            <div className='registrationView'>
                <GenericLoader active={this.props.loading} backgroundClassName='registrationLoaderContainer'/>
                <div className='registrationViewHeader'>
                    <div className='backContainer' onClick={this.props.onClickBack}>
                        <span className='common-icon-back backIcon'/>
                        <label className='backText'>Terug naar de website</label>
                    </div>
                    {this.renderHeaderTitle()}
                    <div className='benefitsContainer'>
                        <label className='benefit'>Veilig bewaren</label>
                        <span className='benefitDivider'>-</span>
                        <label className='benefit'>Beter beslissen</label>
                        <span className='benefitDivider'>-</span>
                        <label className='benefit'>Meer besparen</label>
                    </div>
                    <div className='logoContainer'>
                        <img className='headerLogo' src={logo} alt='ByDeHand.com'/>
                    </div>
                </div>
                <div className='registrationViewBody'>
                    {!hasError &&
                    <div className='stepperContainer'>
                        <Stepper activeStep={this.props.step} alternativeLabel>
                            <Step>
                                <StepLabel>Aanmelden</StepLabel>
                            </Step>
                            <Step>
                                <StepLabel>Activeren</StepLabel>
                            </Step>
                            <Step>
                                <StepLabel>Starten</StepLabel>
                            </Step>
                        </Stepper>
                    </div>
                    }
                    <div className='registrationContent'>
                        {this.renderStep()}
                    </div>
                </div>
            </div>
        )
    };
}

RegistrationView.propTypes = {
    onClickBack: PropTypes.func.isRequired,
    onDeactivate: PropTypes.func.isRequired,
    onSetStep: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    onSetLoading: PropTypes.func.isRequired,
    onSetUser: PropTypes.func.isRequired,
    onClickLogin: PropTypes.func.isRequired,
    onSetTransactionInfo: PropTypes.func.isRequired,
    step: PropTypes.number.isRequired,
    subStep: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    user: PropTypes.object,
    flex: PropTypes.object,
    error: PropTypes.string,
    errorType: PropTypes.string,
    transactionInfo: PropTypes.object,
};