import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment-recur';

import {toMoment} from 'glob-common-js/lib/utils';

import AgendaDay from "./agendaDay";
import {dispatchCustomEvent} from "../../../../misc/eventDispatcher";
import {timeFormats} from "../../../../common/components/datePicker";
import {sendGaEvent} from "../../../../common/js/ga";
import {NOW} from "../../../misc/constants";
import {getOnboardingManager} from "../../../onboarding/manager/onboardingManagerHelper";

const GA_CATEGORY = 'Agenda dag';
export default class AgendaDayController extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDate: NOW().format('YYYY-MM-DD'),
        };
        this._isMounted = false;
    }

    changeState = stateChange => {
        this._isMounted && this.setState(stateChange);
    };

    onSelectDate = (date) => {
        sendGaEvent(GA_CATEGORY, 'Kies datum', 'Kies');
        this.changeState({selectedDate: date});
    };

    onClickAgendaItem = item => {
        if (!getOnboardingManager('agenda').isActive()) {
            sendGaEvent(GA_CATEGORY, 'Klik agenda item', 'klik');
            dispatchCustomEvent('openAgendaPopup', item);
        }
    };

    loadAgendaItems = () => {
        const items = this.props.timeItems;
        let agendaItems = items.filter((item) => ![4, 5].includes(parseInt(item.type)) && this.itemIsInRange(item));
        agendaItems.sort((itemA, itemB) => {
            const preferA = -1, preferB = 1, isEqual = 0;
            const startA = moment(itemA.start_date_item, timeFormats),
                startB = moment(itemB.start_date_item, timeFormats);
            return startA.isBefore(startB) ? preferA : startA.isAfter(startB) ? preferB : isEqual;
        });
        return agendaItems;
    };

    itemIsInRange = item => {
        const startDate = item.start_date_item;
        return moment(startDate, timeFormats).isSame(toMoment(this.state.selectedDate), 'day')
            || this.timeItemIsRepeated(item);
    };

    /**
     * Function to determine if a timeitem is repeated on the selected date, using moment-recur.
     * REF https://github.com/c-trimm/moment-recur
     */
    timeItemIsRepeated = (timeItem) => {
        let startDate = toMoment(timeItem.start_date_item);
        let compareDate = toMoment(this.state.selectedDate);
        let frequency = parseInt(timeItem.frequency);

        switch (frequency) {
            case 0:
                return false;
            case 1:
                return true;
            case 2:
                return startDate.recur().every(1, 'weeks').matches(compareDate);
            case 3:
                return startDate.recur().every(1, 'months').matches(compareDate);
            case 4:
                return startDate.recur().every(1, 'years').matches(compareDate);
            default:
                return false;
        }
    };

    componentDidMount = () => {
        this._isMounted = true;
    };

    componentWillUnmount = () => {
        this._isMounted = false;
    };

    render = () => {
        const agendaItems = this.loadAgendaItems();
        return (
            <AgendaDay onSelectDate={this.onSelectDate} onClickAgendaItem={this.onClickAgendaItem}
                       agendaItems={agendaItems} selectedDate={this.state.selectedDate}
                       onAddItemClick={this.props.onAddItemClick}/>
        )
    }
}

AgendaDayController.propTypes = {
    onAddItemClick: PropTypes.func.isRequired,
    timeItems: PropTypes.arrayOf(PropTypes.shape({
        start_date_item: PropTypes.string.isRequired,
        type: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    })).isRequired,
};