import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {isFunction} from 'glob-common-js/lib/utils';
import {ESCAPE_KEY} from "./keyMap";

export class WithBackground extends React.Component {
    originalOverflow = 'initial';
    originalOverflowY = 'initial';

    escapeListener = evt => {
        const {onEscape} = this.props;
        if (evt.keyCode === ESCAPE_KEY && isFunction(onEscape)) onEscape(evt);
    };

    addScrollBlock = () => {
        const {blockScroll} = this.props;
        if (blockScroll) {
            const element = document.documentElement;
            if (element.style.hasOwnProperty('overflowY'))
                this.originalOverflowY = element.style.overflowY;
            else if (element.style.hasOwnProperty('overflow'))
                this.originalOverflow = element.style.overflow;
            element.style.overflowY = 'hidden';
            document.addEventListener('touchmove', this.preventScroll, false)
        }
    };

    preventScroll = evt => {
        evt.preventDefault();
        evt.returnValue = false;
    };

    removeScrollBlock = () => {
        const {blockScroll} = this.props;
        if (blockScroll) {
            const element = document.documentElement;
            element.style.overflow = this.originalOverflow;
            element.style.overflowY = this.originalOverflowY;
            document.removeEventListener('touchmove', this.preventScroll, false);
        }
    };

    componentDidMount = () => {
        if (isFunction(this.props.onEscape))
            document.addEventListener('keydown', this.escapeListener);
        this.addScrollBlock();
    };

    componentWillUnmount = () => {
        document.removeEventListener('keydown', this.escapeListener);
        this.removeScrollBlock();
    };

    render = () => {
        const {transparent, onClose, children} = this.props;
        return (
            <>
                <div className={classNames('popupBackground', transparent && 'transparent')}/>
                <div className='popupContent' onClick={onClose}>
                    {children}
                </div>
            </>
        )
    };
}

WithBackground.propTypes = {
    onClose: PropTypes.func,
    onEscape: PropTypes.func,
    transparent: PropTypes.bool,
    blockScroll: PropTypes.bool,
};

WithBackground.defaultProps = {
    transparent: false,
    blockScroll: false,
};