import React from 'react';
import StateComponent from "../stateComponent";
import ProtectedAccess from "./protectedAccess";
import {addCustomEventListener, removeCustomEventListener} from "../../../misc/eventDispatcher";
import {login, updateAccount} from "../../../misc/requestSender";

export default class ProtectedAccessController extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            loading: false,
        };
        this.user = null;
        this.callback = null;
        this.twoFactorDisabled = false;
    }

    onSubmit = (password, onError) => {
        this.changeState({loading: true});
        login({
            email: this.user.email,
            password,
            callback: (response) => {
                this.changeState({loading: false});
                if (response.data.status === 'success') {
                    this.enableTwoFactor();
                    this.onClose();
                    this.callback(password);
                } else onError('Ongeldig wachtwoord');
            }
        })
    };

    onClose = () => {
        this.changeState({open: false});
        this.user = null;
    };

    disableTwoFactor = user => {
        if (user.two_step_auth === 1)
            updateAccount({
                id: user.id,
                data: Object.assign({}, user, {two_step_auth: 0}),
                callback: () => {
                    this.twoFactorDisabled = true;
                }
            });
    };

    enableTwoFactor = () => {
        if (this.twoFactorDisabled) {
            const user = this.user;
            updateAccount({
                id: user.id,
                data: Object.assign({}, user, {two_step_auth: 1}),
            })
        }
    };

    openListener = ({user, callback}) => {
        this.changeState({open: true});
        this.user = user;
        this.callback = callback;
        this.disableTwoFactor(user);
    };

    componentDidMount = () => {
        this.mount();
        addCustomEventListener('protectPassword', this.openListener);
    };

    componentWillUnmount = () => {
        this.unMount();
        removeCustomEventListener('protectPassword', this.openListener);
    };

    render = () => (
        <ProtectedAccess onClose={this.onClose} open={this.state.open} onSubmit={this.onSubmit}
                         loading={this.state.loading}/>
    )
}