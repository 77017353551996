import '../style/libraryBlockView.scss';

import React from 'react';
import PropTypes from 'prop-types';
import DocumentButton from "../../document/buttons/documentButton";
import GenericLoader from "../../misc/genericLoader";
import classNames from "classnames";

export default class LibraryBlockView extends React.Component {
    constructor(props) {
        super(props);
    }

    renderDocumentBlock = doc => {
        return (
            <DocumentButton key={doc.id} document={doc} onCheckClick={this.props.onSelectDocument}
                            onClick={this.props.onDocumentDetails}/>
        );
    };

    renderFooter = () => {
        const {total, page, limit, onChangePage} = this.props;
        const maxPage = Math.ceil(total / limit);
        const prevDisabled = page === 0;
        const nextDisabled = page === maxPage - 1;
        const start = Math.min(total, page * limit + 1);
        const end = Math.min(total, (page + 1) * limit);
        return (
            <div className='footer-container'>
                <div className='pagination'>
                    <label className='count'>{start} t/m {end} van {total}</label>
                    <span className={classNames('common-icon-arrow-left pager', prevDisabled && 'disabled')}
                          onClick={onChangePage(page - 1)}/>
                    <span className={classNames('common-icon-arrow-right pager', nextDisabled && 'disabled')}
                          onClick={onChangePage(page + 1)}/>
                </div>
            </div>
        )
    }

    render = () => {
        return (
            <div className='libraryBlockView'>
                <GenericLoader active={this.props.loading}/>
                <div className='documents-container'>
                    {this.props.documents.map(this.renderDocumentBlock)}
                </div>
                {this.renderFooter()}
            </div>
        )
    };
}

LibraryBlockView.propTypes = {
    onSelectDocument: PropTypes.func.isRequired,
    onDocumentDetails: PropTypes.func.isRequired,
    selectedDocuments: PropTypes.arrayOf(PropTypes.string).isRequired,
    documents: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        update_at: PropTypes.string,
        extension: PropTypes.string.isRequired,
        belongings: PropTypes.arrayOf(PropTypes.object).isRequired,
        type: PropTypes.shape({
            name: PropTypes.string.isRequired,
        })
    })).isRequired,
    loading: PropTypes.bool.isRequired,
    onChangePage: PropTypes.func.isRequired,
    total: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
};