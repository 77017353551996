import checklistTypes from "../../../components/checklist/checklistTypes";

const boarding_printed = {
        name: 'boarding_printed',
        label: 'Boarding pass geprint',
        documentType: 'airline_ticket',
        link: null,
    },
    online_checkin = {
        name: 'online_checkin',
        label: 'Online inchecken',
        documentType: null,
        link: null,
    };

export default {
    name: 'Reischecklist',
    icon: 'common-icon-travel-beach',
    route: '/reis-checklist',
    type: checklistTypes.TRAVEL,
    GA_CATEGORY: 'Reischecklist (platform)',
    categories: {
        travel_documents: {
            name: 'travel_documents',
            label: 'Reisdocumenten',
            icon: 'common-icon-pasport',
            boxes: [
                {
                    name: 'passport_valid',
                    label: 'Paspoort geldig',
                    documentType: 'passport_personal',
                    link: {
                        label: 'FAQ paspoort',
                        url: '//www.rijksoverheid.nl/onderwerpen/paspoort-en-identiteitskaart/vraag-en-antwoord',
                    },
                },
                {
                    name: 'id_valid',
                    label: 'ID-kaart geldig',
                    documentType: 'id_card_personal',
                    link: {
                        label: 'Check landen ID-kaart',
                        url: '//www.rijksoverheid.nl/onderwerpen/paspoort-en-identiteitskaart/vraag-en-antwoord/naar-welke-landen-reizen-met-nederlandse-identiteitskaart',
                    },
                },
                {
                    name: 'visa_required',
                    label: 'Visum nodig',
                    documentType: 'travel_visa',
                    link: {
                        label: 'Check landen visum',
                        url: '//visum.nl/visa-quick-check',
                    },
                },
                online_checkin,
                boarding_printed,
                {
                    name: 'booking_accommodation',
                    label: 'Reserveringsbewijs accommodatie',
                    documentType: 'booking_accommodation',
                    link: null,
                },
                {
                    name: 'reservation_rental_car_camper',
                    label: 'Reserveringsbewijs huurauto/camper',
                    documentType: 'reservation_rental_car_camper',
                    link: null,
                },
            ],
        },
        insurances: {
            name: 'insurances',
            label: 'Verzekeringen',
            icon: 'common-icon-travel-insurance',
            boxes: [
                {
                    name: 'check_travel_insurance',
                    label: 'Controleer je reisverzekering',
                    documentType: 'travel_insurance',
                    link: {
                        label: 'Check je reisverzekering',
                        url: () => {
                            window.location = 'https://www.bydehand.com/heb-jij-een-goede-reisverzekering/';
                        }
                    },
                },
                {
                    name: 'sos_details',
                    label: 'Heb je de gegevens van de SOS hulpdienst?',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'cancellation_insurance',
                    label: 'Heb je een annuleringsverzekering?',
                    documentType: 'cancellation_insurance',
                    link: null,
                },
                {
                    name: 'travel_details',
                    label: 'Heb je je reisverzekeringgegevens?',
                    documentType: 'travel_insurance',
                    link: null,
                },
            ]
        },
        financial: {
            name: 'financial',
            label: 'Geldzaken',
            icon: 'common-icon-money-bag',
            boxes: [
                {
                    name: 'debit_card',
                    label: 'Pinpas actief/geldig',
                    documentType: 'bank_card_personal',
                    link: null,
                },
                {
                    name: 'credit_card',
                    label: 'Creditcard actief/geldig',
                    documentType: 'credit_card_personal',
                    link: null,
                },
                {
                    name: 'mobile_subscription',
                    label: 'Mobiel abonnement buitenland',
                    documentType: 'subscription_details',
                    link: null,
                },
            ],
        },
        medical: {
            name: 'medical',
            label: 'Medisch',
            icon: 'common-icon-first-aid-kit',
            boxes: [
                {
                    name: 'health_card',
                    label: 'Zorgpas',
                    documentType: 'health_card',
                    link: null,
                },
                {
                    name: 'vaccinations',
                    label: 'Vaccinaties / inentingen',
                    documentType: 'vaccination_card_holiday',
                    link: {
                        label: 'Check vaccinaties per land',
                        url: '//www.ggdreisvaccinaties.nl/',
                    },
                },
                {
                    name: 'medical_declaration',
                    label: 'Medische (schengen)verklaring',
                    documentType: null,
                    link: {
                        label: 'Check medicijn verklaring',
                        url: '//www.hetcak.nl/regelingen/medicijnen-mee-op-reis',
                    },
                },
                {
                    name: 'medicine',
                    label: 'Medicijnen en bijsluiters',
                    documentType: 'leaflet_drug_care',
                    link: null
                }
            ],
        },
        home: {
            name: 'home',
            label: 'Thuis',
            icon: 'common-icon-home',
            boxes: [
                {
                    name: 'homestayer',
                    label: 'Thuisblijver informatie versturen',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'mail',
                    label: 'Post',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'plants',
                    label: 'Planten',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'pets',
                    label: 'Huisdieren',
                    documentType: null,
                    link: null,
                },
            ],
        },
        car: {
            name: 'car',
            label: 'Met de auto',
            icon: 'common-icon-car',
            boxes: [
                {
                    name: 'drivers_licence',
                    label: 'Rijbewijs geldig',
                    documentType: 'drivers_licence_personal',
                    link: {
                        label: 'FAQ rijbewijs',
                        url: '//www.rijksoverheid.nl/onderwerpen/rijbewijs/vraag-en-antwoord/mag-ik-met-mijn-nederlandse-rijbewijs-in-het-buitenland-aan-het-verkeer-deelnemen',
                    },
                },
                {
                    name: 'international_drivers_licence',
                    label: 'Internationaal rijbewijs',
                    documentType: null,
                    link: {
                        label: 'FAQ internationaal rijbewijs',
                        url: '//www.anwb.nl/vakantie/reisvoorbereiding/internationaal-rijbewijs',
                    },
                },
                {
                    name: 'roadside_service',
                    label: 'Internationale pechhulp',
                    documentType: 'roadside',
                    link: null,
                },
                {
                    name: 'registration_licence',
                    label: 'Kentekenbewijs',
                    documentType: 'vehicle_registration',
                    link: null,
                },
                {
                    name: 'green_card',
                    label: 'Groene kaart',
                    documentType: 'green_card',
                    link: null,
                },
                {
                    name: 'car_insurance',
                    label: 'Autoverzekering',
                    documentType: 'car_insurance_policy',
                    link: null,
                },
                {
                    name: 'toll_certificate',
                    label: 'Tolbewijzen / vignetten',
                    documentType: null,
                    link: {
                        label: 'Bestel vignetten',
                        url: '//www.anwb.nl/vakantie/reisvoorbereiding/reisdocumenten-en-vignetten',
                    },
                },
                {
                    name: 'winter_tires',
                    label: 'Winterbanden (winter)',
                    documentType: null,
                    link: null,
                },
            ],
        },
        before_leaving: {
            name: 'before_leaving',
            label: 'Vertrek',
            icon: 'common-icon-clock',
            boxes: [
                online_checkin,
                boarding_printed,
                {
                    name: 'plan_route',
                    label: 'Plan route / afsluitingen / files',
                    documentType: null,
                    link: {
                        label: 'ANWB verkeer',
                        url: '//www.anwb.nl/verkeer/buitenland',
                    },
                },
                {
                    name: 'heat',
                    label: 'Verwarming en gas uitzetten',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'light',
                    label: 'Verlichting instellen',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'alarm',
                    label: 'Alarm instellen',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'locks',
                    label: 'Deuren / ramen op slot',
                    documentType: null,
                    link: null,
                }
            ]
        }
    }
};