import '../style/summary.scss';

import React from 'react';
import {connect} from 'react-redux';

import {isNotNull} from 'glob-common-js/lib/utils';
import {sendPageView} from "BdhCommon/js/ga";
import {getInsurerFromMapping} from 'BdhCommon/data/mappings/healthInsuranceMapping';

import SummaryView from "./summaryView";
import {getFromRegistry} from "../../misc/registry";
import FbShareView from "../../fbShare/fbShareView";

export class SummaryController extends React.Component {
    constructor(props) {
        super(props);
    }

    getInsuranceDetails = () => {
        let nextInsurance = getFromRegistry(['healthInsurance', 'nextInsurance']);
        let insurer = getInsurerFromMapping(nextInsurance.insurer);
        nextInsurance.url = isNotNull(insurer) ? insurer.url : null;
        return nextInsurance;
    };

    componentDidMount = () => {
        sendPageView('/zorganalyse/jouw-verzekering/afsluiten');
    };

    render = () => {
        return (
            <div>
                <SummaryView insurance={this.getInsuranceDetails()}/>
                {/*<RatingController showPopup={!this.props.healthState.ratingShown}/>*/}
                <FbShareView/>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    healthState: state.healthInsuranceAnalysis
});

export default connect(mapStateToProps)(SummaryController);