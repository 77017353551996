import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormHelperText from '@material-ui/core/FormHelperText';
import RootRef from '@material-ui/core/RootRef';

import {generateUUID, isFunction} from 'glob-common-js/lib/utils';

import StateComponent from "../misc/stateComponent";

export default class MaterialPasswordField extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
        };
        this.input = React.createRef();
    }

    toggleVisible = () => {
        this.changeState({visible: !this.state.visible});
    };

    preventMouseDown = evt => {
        evt.preventDefault();
    };

    addKeyDownListener = () => {
        if (isFunction(this.props.keyDownListener)) {
            const node = ReactDOM.findDOMNode(this.input.current);
            if (isNotNull(node)) {
                const addEvent = node.addEventListener || node.attachEvent;
                addEvent('mousedown', this.props.keyDownListener);
            }
        }
    };

    removeKeyDownListener = () => {
        if (isFunction(this.props.keyDownListener)) {
            const node = ReactDOM.findDOMNode(this.input.current);
            if (isNotNull(node)) {
                const removeEvent = node.removeEventListener || node.detachEvent;
                removeEvent('mousedown', this.props.keyDownListener);
            }
        }
    };

    componentDidMount = () => {
        this.mount();
        this.addKeyDownListener();
    };

    componentWillUnmount = () => {
        this.unMount();
        this.removeKeyDownListener();
    };

    render = () => {
        const id = generateUUID();
        const props = Object.assign({}, this.props), {label, helperText, margin, keyDownListener} = props;
        delete props.margin;
        delete props.helperText;
        delete props.keyDownListener;
        return (
            <FormControl error={isNotNull(helperText)} margin={margin} {...props}>
                {isNotNull(label) && <InputLabel htmlFor={'pass-field-' + id}>{label}</InputLabel>}
                <RootRef rootRef={this.input}>
                    <Input id={'pass-field-' + id} type={this.state.visible ? 'text' : 'password'} endAdornment={
                        <InputAdornment position='end'>
                            <IconButton onClick={this.toggleVisible} onMouseDown={this.preventMouseDown}>
                                {this.state.visible ? <Visibility/> : <VisibilityOff/>}
                            </IconButton>
                        </InputAdornment>
                    } {...props}/>
                </RootRef>
                {isNotNull(helperText) && <FormHelperText>{helperText}</FormHelperText>}
            </FormControl>
        )
    }
}

MaterialPasswordField.propTypes = {
    keyDownListener: PropTypes.func,
};