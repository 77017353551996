import {moduleMapping} from '../common/data/mappings/moduleMapping';
import {isNotNull} from "glob-common-js/lib/utils";

const getInitialModules = () => {
    let activeModules = moduleMapping.filter(module => isNotNull(module.name));
    return activeModules.map(module => module.name);
};

const initialState = {
    user: {
        user: null,
        familyId: '',
        registrationMail: null,
        subscription: null,
        grantedUser: null,
    },
    dossier: {
        dossierType: {
            label: '',
            id: '',
            display: ''
        },
        dossierId: '',
        editDossier: null,
        addedDossiers: [],
        dossier: null,
        selectedSubcategory: 'none',
        selectedDossierCategory: 'none',
        dossierDocumentType: null,
    },
    document: {
        docType: '',
        docIDS: [],
        docDisplayLabel: '',
        uploadedDoc: null,
        addedDocs: [],
        documentDetailId: null,
        documentToLink: null,
    },
    tender: {
        tenderInfo: undefined,
    },
    misc: {
        confirmInfo: {
            text: null,
            title: null,
        },
        showLoginPopup: false,
        emailInput: '',
        startFlow: false,
    },
    healthInsuranceAnalysis: {
        ratingShown: false,
        explaningPopupShown: false,
        currentInsurance: {
            insurer: '',
            basic: '',
            extra: [],
            dental: '',
            risk: 685,
        },
        evaluation: {
            basic: '',
            additional: '',
            risk: '',
            insurer: '',
        },
        evaluationSummary: '',
        showFb: true,
    },
    app: {
        fileToUpload: {
            uri: null,
            label: null,
            kind: 'regular',
        }
    },
    module: {
        activeModules: getInitialModules(),
        selectedModule: null,
    },
    appCallback: {
        agendaItemToAdd: null,
        agenda: {
            mode: null, // null || add || edit (show if !null)
            defaultData: {},
            callback: null,
        },
        document: {
            mode: null, // null || add (show if !null)
            documentType: {name: 'none', label: 'none'},
            callback: null,
        }
    },
    introduction: {
        active: false,
        activeAgendaTab: null, // date || deadline || planning
    },
    financial: {
        dossiersLoaded: false,
        dossiers: [],
    },
};

export default initialState;
