export const materialTypes = {
    THEME_PROVIDER: 'THEME_PROVIDER',
    RAISED_BUTTON: 'RAISED_BUTTON',
    FLAT_BUTTON: 'FLAT_BUTTON',
    DATE_PICKER: 'DATE_PICKER',
    TIME_PICKER: 'TIME_PICKER',
    TEXT: 'TEXT',
    SELECT: 'SELECT',
    CHECKBOX: 'CHECKBOX',
    TOGGLE: 'TOGGLE',
    DIALOG: 'DIALOG',
    PASSWORD: 'PASSWORD',
    CIRCULAR_PROGRESS: 'CIRCULAR_PROGRESS',
    LINEAR_PROGRESS: 'LINEAR_PROGRESS',
    MENU_ITEM: 'MENU_ITEM',
    POPOVER: 'POPOVER',
    MENU: 'MENU',
    SNACKBAR: 'SNACKBAR',
    GRID_LIST: 'GRID_LIST',
    GRID_TILE: 'GRID_TILE',
    TABS: 'TABS',
    TAB: 'TAB',
};