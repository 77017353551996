import './style/dashboardContainer.scss';

import React from 'react';
import PropTypes from 'prop-types'
import {withRouter} from 'react-router-dom';
import moment from 'moment';
import DocumentDashboardContainerController from "./document/documentDashboardContainerController";
import DossierDashboardContainerController from "./dossier/dossierDashboardContainerController";
import FinancialDashboardController from "../financial/dashboard/financialDashboardController";
import StartHighlights from "../start/highlights/startHighlights";
import {getAuthParameter} from "../authentication/authenticationHelper";
import AgendaController from "./agenda/agendaController";
import StateComponent from "../misc/stateComponent";
import {timeFormats} from "../../common/components/datePicker";
import MaterialFactory from "../material/materialFactory";
import {materialTypes} from "../material/materialTypes";

export class DashboardContainer extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            highlightActive: false,
        }
    }

    onHighlightTravelChecklist = () => {
        const container = document.querySelector('div.dashboardSaveBox');
        if (isNotNull(container)) {
            this.changeState({highlightActive: true});
            if (!container.classList.contains('highlighted'))
                container.classList.add('highlighted');
        }
    };

    onStopHighlight = () => {
        this.changeState({highlightActive: false});
        const container = document.querySelector('div.dashboardSaveBox');
        if (isNotNull(container))
            container.classList.remove('highlighted');
    };

    getAge = () => {
        const {user} = this.props;
        if (isNotNull(user)) {
            const dob = user.dob;
            if (isNotNull(dob)) {
                return Math.abs(moment(dob, timeFormats).diff(moment(), 'years'));
            }
        }
        return 0;
    };

    renderChecklistBox = () => {
        const {onNavigate} = this.props;
        return (
            <div className='dashboardBox'>
                <div className='headerContainer'>
                    <label className='headerTitle'>Checklists</label>
                </div>
                <div className='content'>
                    <label className='contentTitle'>Nieuwe levensgebeurtenis?</label>
                    <p className='contentText'>Check hier wat je allemaal moet doen en vergeet niks te regelen</p>
                    <span className='common-icon-file-checklist icon'/>
                    <MaterialFactory componentType={materialTypes.RAISED_BUTTON} onClick={onNavigate('/checklists')}>naar
                        checklists</MaterialFactory>
                </div>
            </div>
        )
    };

    renderTutorialBox = () => {
        const {onClickTutorial} = this.props, logo = require('../../common/images/logo_bydehand.jpg');
        return (
            <div className='dashboardBox'>
                <div className='headerContainer'>
                    <label className='headerTitle'>Neem me ByDeHand</label>
                </div>
                <div className='content'>
                    <label className='contentTitle'>Hulp nodig met ByDeHand?</label>
                    <p className='contentText'>Klik hier voor meer uitleg over het platform en de werking van alle
                        functionaliteiten.</p>
                    <img src={logo} alt='ByDeHand' className='logo'/>
                    <MaterialFactory componentType={materialTypes.RAISED_BUTTON} onClick={onClickTutorial}>start
                        uitleg</MaterialFactory>
                </div>
            </div>
        )
    };

    componentDidMount = () => {
        this.mount();
        const action = getAuthParameter('action');
        if (isNotNull(action) && action === 'light_checklist')
            this.onHighlightTravelChecklist();
    };

    componentWillUnmount = () => {
        this.unMount();
    };

    render = () => {
        return (
            <>
                {this.state.highlightActive && <div className='highlightBackground'/>}
                <div id='dashboardPage' className='dashboardPage'>
                    <div className='dashboardColumn storeSmarter'>
                        <DocumentDashboardContainerController/>
                        <DossierDashboardContainerController/>
                    </div>
                    <div className='dashboardColumn decidingConscious'>
                        <FinancialDashboardController/>
                        <AgendaController/>
                    </div>
                    <div className='dashboardColumn saveMore'>
                        {this.state.highlightActive &&
                        <div className='highlightMessageContainer'>
                            <div className='highlightHeader'>
                                <label className='highlightTitle'>Op reis</label>
                            </div>
                            <div className='highlightBody'>
                                <p className='highlightMessage'>
                                    Verhaaltje over reis.0d52f23b-883a-491a-834e-cc6301e6960f

                                </p>
                                <button className='highlightButton' onClick={this.onStopHighlight}>Sluit</button>
                            </div>
                        </div>}
                        <div className='box dashboardSaveBox'>
                            {this.renderChecklistBox()}
                        </div>
                        <div className='box'>
                            {this.renderTutorialBox()}
                        </div>
                    </div>
                    <StartHighlights/>
                </div>
            </>
        );
    };

}

export default withRouter(DashboardContainer);

DashboardContainer.propTypes = {
    onNavigate: PropTypes.func.isRequired,
    onClickTutorial: PropTypes.func.isRequired,
    user: PropTypes.object,
};