import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {getSetting, pathIsNotNull} from 'glob-common-js/lib/utils';

import {sendGaEvent, sendPageView} from "../../../../common/js/ga";
import {materialTypes} from "../../../material/materialTypes";
import MaterialFactory from "../../../material/materialFactory";
import {NOW} from "../../../misc/constants";

const CAMPAIGN_ID = '28623', PRODUCT_ID = '42839', DESCRIPTION = 'Sale ByDeHand';
const GA_CATEGORY = 'Registreren (Aan de slag - betaald)';
export default class CompletionDefault extends React.Component {
    constructor(props) {
        super(props);
    }

    onComplete = (destination) => () => {
        const gaLabel = ['startAnalyse', 'health'].includes(destination) ? 'Zorganalyse' : 'Dashboard';
        sendGaEvent(GA_CATEGORY, 'Klik', gaLabel);
        this.props.onComplete(destination);
    };

    notifyTradeTracker = () => {
        const mode = getSetting('mode');
        if (mode === 'prod' && this.hasTransactionDetails()) {
            const ORDER_ID = this.props.transactionInfo.id, ORDER_AMOUNT = this.props.transactionInfo.amount,
                EMAIL = this.props.email;
            let ttConversionOptions = [];
            ttConversionOptions.push({
                type: 'sales',
                campaignID: CAMPAIGN_ID,
                productID: PRODUCT_ID,
                transactionID: ORDER_ID,
                transactionAmount: ORDER_AMOUNT,
                quantity: '1',
                email: EMAIL,
                descrMerchant: DESCRIPTION,
                descrAffiliate: DESCRIPTION,
                currency: 'EUR'
            });
            this.sendToTradeTracker(ttConversionOptions);
        }
    };

    hasTransactionDetails = () => {
        return pathIsNotNull(this.props.transactionInfo, 'id') && isNotNull(this.props.transactionInfo.amount);
    };

    sendToTradeTracker = ttConversionOptions => {
        let campaignID = 'campaignID' in ttConversionOptions ? ttConversionOptions.campaignID :
            ('length' in ttConversionOptions && ttConversionOptions.length ? ttConversionOptions[0].campaignID : null);
        let tt = document.createElement('script');
        tt.type = 'text/javascript';
        tt.async = true;
        tt.src = '//tm.tradetracker.net/conversion?s=' + encodeURIComponent(campaignID) + '&t=m';
        let s = document.getElementsByTagName('script');
        s = s[s.length - 1];
        s.parentNode.insertBefore(tt, s);
    };

    renderButtons = () => {
        if (window.localStorage) {
            const storage = window.localStorage;
            let healthData = storage.getItem('bdhHealthAnalysis');
            if (isNotNull(healthData)) {
                healthData = JSON.parse(healthData);
                if (moment(healthData.expires).isSameOrAfter(NOW())) {
                    return <MaterialFactory componentType={materialTypes.RAISED_BUTTON} className='completeButton'
                                            onClick={this.onComplete('startAnalyse')}>Analyseer je
                        zorgverzekering</MaterialFactory>
                }
            }
        }
        return (
            <>
                <MaterialFactory componentType={materialTypes.RAISED_BUTTON} className='completeButton'
                                 onClick={this.onComplete('dashboard')}>Aan de slag</MaterialFactory>
                {/*<MaterialFactory componentType={materialTypes.RAISED_BUTTON} className='completeButton'*/}
                                 {/*onClick={this.onComplete('health')}>Analyseer je*/}
                    {/*zorgverzekering</MaterialFactory>*/}
            </>
        )
    };


    componentDidMount = () => {
        this.notifyTradeTracker();
        ga('set', 'page', '/registratie/5-voltooid-betaald');
        sendPageView();
    };

    render = () => {
        return (
            <>
                <div className='completionMessage'>
                    <span className='common-icon-check completionCheck'/>
                    <p className='message'>Beste {this.props.firstName},<br/>
                        De setup van je account is voltooid en je abonnement is geactiveerd!
                    </p>
                    <div className='completeButtonsContainer'>
                        {this.renderButtons()}
                    </div>
                    <label className='faqText'>
                        Heb je nog vragen? Kijk dan op onze <span className='faqLink' onClick={this.props.onClickFaq}>
                    FAQ</span>.</label>
                </div>
            </>
        )
    };
}

CompletionDefault.propTypes = {
    onClickFaq: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
    firstName: PropTypes.string.isRequired,
    flex: PropTypes.object,
    transactionInfo: PropTypes.object,
    email: PropTypes.string.isRequired,
};
