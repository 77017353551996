import './style/checklistPage.scss';

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import MaterialFactory from "../../material/materialFactory";
import {materialTypes} from "../../material/materialTypes";
import {EXISTING, NEW} from "./checklistPageController";
import BdhSearchInput from "../../customControls/input/bdhSearchInput";

export default class ChecklistPage extends React.Component {
    getPageTitle = () => {
        const {view} = this.props;
        return view === NEW ? 'Een checklist starten' : 'Mijn checklists';
    };

    renderNavigationButton = (view, text) => (
        <MaterialFactory componentType={materialTypes.RAISED_BUTTON}
                         className={classNames('viewButton', this.props.view !== view && 'inactive')}
                         onClick={this.props.onClickView(view)}>{text}</MaterialFactory>
    );

    renderChecklist = (checklist, key) => {
        const {id, name, type} = checklist, {icon} = type,
            {selectedChecklists, onSelectChecklist, onClickChecklist} = this.props,
            isSelected = selectedChecklists.includes(id), isNew = id === 'new';
        return (
            <div key={key}
                 className={classNames('checklistContainer', !isNew && 'existing', isSelected && 'selected')}
                 onClick={onClickChecklist(checklist)}>
                {!isNew && <div className='checklistSelector' onClick={onSelectChecklist(id)}>
                    {isSelected && <span className={classNames('common-icon-check', 'checklistCheck')}/>}
                </div>}
                <div className='checklistInnerBlock'>
                    <span className={classNames(icon, 'checklistIcon')}/>
                </div>
                <label className='checklistLabel'>{name}</label>
            </div>
        )
    };

    renderNoChecklists = () => this.props.view === EXISTING ? (
        <div className='noChecklistsContainer'>
            <label className='noChecklistsLabel'>Je hebt nog geen checklists gestart</label>
            <MaterialFactory componentType={materialTypes.RAISED_BUTTON} onClick={this.props.onClickView(NEW)}>
                Een checklist starten</MaterialFactory>
        </div>
    ) : null;

    render = () => {
        const {
            checklists, searchValue, onSearch, selectedChecklists, onClickDelete, onChangeName, view,
            checklistCount, onClearSearch
        } = this.props;
        return (
            <div className='checklistPage'>
                <div className='navigationContainer'>
                    {this.renderNavigationButton(EXISTING, `Jouw checklists (${checklistCount})`)}
                    {this.renderNavigationButton(NEW, 'Een checklist starten')}
                </div>
                <div className='searchContainer'>
                    <label className='pageTitle'>{this.getPageTitle()}</label>
                    <BdhSearchInput value={searchValue} onChange={onSearch} label='Zoeken...'
                                    containerClass='searchInput' onClearSearch={onClearSearch}/>
                </div>
                {view === EXISTING &&
                <div className={classNames('actionContainer', isNull(selectedChecklists) && 'inactive')}>
                    <div className='actionButton'>Acties</div>
                    <div className='checklistActionsContainer'>
                        <div className={classNames('checklistAction', selectedChecklists.length > 1 && 'disabled')}
                             onClick={onChangeName}>
                            <label className='actionLabel'>Wijzig naam</label>
                        </div>
                        <div className='checklistAction' onClick={onClickDelete}>
                            <label className='actionLabel'>Verwijderen</label>
                        </div>
                    </div>
                </div>}
                <div className='checklistsContainer'>
                    {isNull(checklists) ? this.renderNoChecklists() : checklists.map(this.renderChecklist)}
                </div>
            </div>
        )
    };
}

ChecklistPage.propTypes = {
    onClickChecklist: PropTypes.func.isRequired,
    onSelectChecklist: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
    onChangeName: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    onClickView: PropTypes.func.isRequired,
    onClearSearch: PropTypes.func.isRequired,
    checklists: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        type: PropTypes.shape({
            label: PropTypes.string.isRequired,
            icon: PropTypes.string.isRequired,
        }).isRequired,
    })).isRequired,
    selectedChecklists: PropTypes.arrayOf(PropTypes.string).isRequired,
    searchValue: PropTypes.string.isRequired,
    view: PropTypes.number.isRequired,
    checklistCount: PropTypes.number.isRequired,
};