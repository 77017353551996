import {createMuiTheme} from '@material-ui/core/styles'
import JSSConstants from '../../../style/JSSConstants';

export const muiTheme = createMuiTheme({
    palette: {
        primary: {
            main: JSSConstants["color-blue-1"],
            contrastText: '#ffffff'
        },
        background: {
            default: JSSConstants["color-blue-1"]
        },
    },
    overrides: {
        // MuiFormLabel: {
        //     '&$focused': {
        //         color: JSSConstants["color-blue-1"] + ' !important'
        //     }
        // },
        MuiInput: {
            underline: {
                '&:after': {
                    borderBottom: '2px solid ' + JSSConstants["color-blue-1"],
                }
            }
        },
        MuiTab: {
            root: {
                backgroundColor: JSSConstants["color-blue-1"]
            },
            label: {
                color: 'white',
            },
            textColorInherit: {
                opacity: 1,
            }
        },
        MuiSnackbarContent: {
            root: {
                backgroundColor: "rgb(49, 49, 49)",
                color: 'white',
            }
        }
    },
    typography: {
        useNextVariants: true,
    },
});