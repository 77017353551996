import './style/accountAccess_v2.scss';

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import MaterialFactory from "../../../material/materialFactory";
import {materialTypes} from "../../../material/materialTypes";
import {JSSAccountAccess_v2} from "./style/JSSAccountAccess_v2";

const PADDING = 'dense';

export class AccountAccess_v2 extends React.Component {
    constructor(props) {
        super(props);
    }

    renderGrantsTable = () => {
        const {grants, classes, variant} = this.props;
        return (
            <Paper className={classes.paperRoot}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell padding={PADDING}/>
                            <TableCell padding={PADDING}>E-mailadres</TableCell>
                            <TableCell padding={PADDING}>Naam</TableCell>
                            <TableCell padding={PADDING}>Rechten</TableCell>
                            <TableCell/>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isNotNull(grants) ? grants.map(this.renderGrant) :
                            <TableRow>
                                <TableCell padding={PADDING}/>
                                <TableCell
                                    padding={PADDING}>{variant === 'to' ? 'Niemand heeft toegang tot jouw account'
                                    : 'Je hebt geen toegang tot andere accounts'}
                                </TableCell>
                                <TableCell padding={PADDING}/>
                                <TableCell padding={PADDING}/>
                                <TableCell padding={PADDING}/>
                                <TableCell padding={PADDING}/>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </Paper>
        )
    };

    renderGrant = (grant) => {
        const {classes, variant} = this.props;
        const icon = variant === 'to' ? this.renderEditIcon(grant) : this.renderDeleteIcon(grant);
        return (
            <TableRow key={grant.id} className={classes.bodyRoot}>
                <TableCell padding={PADDING}><span className='grantProfileIcon common-icon-profile'/></TableCell>
                <TableCell padding={PADDING}>{grant.email}</TableCell>
                <TableCell padding={PADDING}>{this.renderName(grant)}</TableCell>
                <TableCell padding={PADDING}>{this.renderRights(grant)}</TableCell>
                <TableCell padding={PADDING}>{this.renderGrantAction(grant)}</TableCell>
                <TableCell padding={PADDING}>{icon}</TableCell>
            </TableRow>
        )
    };

    renderGrantAction = grant => {
        const {variant, onLoginGrant, onAcceptGrant} = this.props;
        let {accepted} = grant;
        accepted = accepted === 1;
        let label, onClick, type = materialTypes.RAISED_BUTTON;
        if (variant === 'from') {
            if (accepted) {
                label = 'Inloggen';
                onClick = onLoginGrant(grant);
            } else {
                return (
                    <div className='requestedLabel clickable' onClick={onAcceptGrant(grant)}
                         title={'Klik hier om de aanvraag te accepteren'}>
                        <label>Aangevraagd</label>
                        <span className='warningIcon'/>
                    </div>
                );
            }
        } else if (variant === 'to') {
            label = accepted ? 'Geaccepteerd' : 'Aangevraagd';
            const className = accepted ? 'acceptedLabel' : 'requestedLabel';
            return <label className={className}>{label}</label>;
        }
        return <MaterialFactory componentType={type} onClick={onClick} disabled={!accepted}>{label}</MaterialFactory>
    };

    renderRights = ({rights}) => {
        const rightMap = {
            read: 'Lezen',
            manage: 'Beheer',
            share: 'Delen',
        };
        const translatedRights = rights.map(right => rightMap[right]);
        return translatedRights.join('/');
    };

    renderName = ({firstname, infix, lastname}) => {
        return firstname + (isNotNull(infix) ? ' ' + infix : '') + (isNotNull(lastname) ? ' ' + lastname : '');
    };

    renderEditIcon = (grant) => (
        <span className='common-icon-edit grantActionIcon edit' onClick={this.props.onEditGrant(grant)}/>
    );

    renderDeleteIcon = (grant) => (
        <span className='common-icon-trash grantActionIcon delete' onClick={this.props.onDeleteGrant(grant)}/>
    );

    render = () => {
        const {onClickVariant, onInvite, classes, variant} = this.props;
        return (
            <div className='accountAccess_v2'>
                <div className='accountAccessHeader'>
                    <label className='accountAccessTitle'>Accountbeheer</label>
                    <p className='accountAccessIntro'>Verleen familieleden of anderen toegang tot jouw account. Het is
                        mogelijk om zelf te bepalen welke rechten anderen op jouw ByDeHand-account hebben.</p>
                </div>
                <div className='actionBar'>
                    <div className='variantActionContainer'>
                        <MaterialFactory
                            componentType={variant === 'from' ? materialTypes.RAISED_BUTTON : materialTypes.FLAT_BUTTON}
                            onClick={onClickVariant('from')}>Jouw machtigingen</MaterialFactory>
                        <MaterialFactory
                            componentType={variant === 'to' ? materialTypes.RAISED_BUTTON : materialTypes.FLAT_BUTTON}
                            onClick={onClickVariant('to')}>Machtigingen op jouw account</MaterialFactory>
                    </div>
                    <MaterialFactory componentType={materialTypes.RAISED_BUTTON} onClick={onInvite}
                                     className={classNames(variant === 'from' && classes.hidden)}>
                        Nodig gebruiker uit</MaterialFactory>
                </div>
                {this.renderGrantsTable()}
            </div>
        )
    };
}

AccountAccess_v2.propTypes = {
    onLoginGrant: PropTypes.func.isRequired,
    onAcceptGrant: PropTypes.func.isRequired,
    onDeleteGrant: PropTypes.func.isRequired,
    onEditGrant: PropTypes.func.isRequired,
    onClickVariant: PropTypes.func.isRequired,
    onInvite: PropTypes.func.isRequired,
    variant: PropTypes.oneOf(['to', 'from']).isRequired,
    grants: PropTypes.arrayOf(PropTypes.shape({
        profile_image: PropTypes.string,
        firstname: PropTypes.string.isRequired,
        infix: PropTypes.string,
        lastname: PropTypes.string,
        email: PropTypes.string.isRequired,
        accepted: PropTypes.oneOf([0, 1]),
    })).isRequired,
};

export default withStyles(JSSAccountAccess_v2)(AccountAccess_v2);