import {toMoment, warn} from 'glob-common-js/lib/utils';
import {
    addActionCode, deleteActionCode,
    getActionCode,
    getAllActionCodes,
    responseIsSuccess,
    updateActionCode
} from "../misc/requestSender";

export default class ActionCode extends Object {
    static get = (id, callback) => {
        getActionCode({
            id, callback: response => {
                if (responseIsSuccess(response))
                    callback(new ActionCode(response.data.action_code), response);
                else callback(null, response);
            }
        });
    };

    static getAll = callback => {
        getAllActionCodes({
            callback: response => {
                if (responseIsSuccess(response)) {
                    const {action_codes} = response.data;
                    callback(action_codes.map(action_code => new ActionCode(action_code)), response);
                } else callback(null, response);
            }
        });
    };

    #fields = {
        id: '',
        code: '',
        maxUses: -1,
        timesUsed: 0,
        description: '',
        subscriptionPeriod: {},
        validUntil: null,
        createdAt: null,
        updatedAt: null,
    };

    constructor(props) {
        super(props);
        if (isNull(props)) props = {};
        this.#initializeFields(props)
    }

    hasOwnProperty = name => {
        return isNotNull(this[name]) || this.#fields[name];
    };

    get copy() {
        return new ActionCode({
            id: this.id,
            code: this.code,
            max_uses: this.maxUses,
            times_used: this.timesUsed,
            description: this.description,
            subscription_period: this.period,
            valid_until: this.validUntil,
            created_at: this.createdAt,
            updated_at: this.updatedAt,
        });
    }

    get id() {
        return this.#getNullSafe('id');
    };

    get code() {
        return this.#getNullSafe('code');
    }

    set code(code) {
        this.#fields.code = code;
    }

    get maxUses() {
        return this.#getNullSafe('maxUses', -1);
    }

    set maxUses(maxUses) {
        if (!isNaN(maxUses))
            this.#fields.maxUses = parseInt(maxUses)
    }

    get timesUsed() {
        return this.#getNullSafe('timesUsed', 0);
    }

    get description() {
        return this.#getNullSafe('description');
    }

    set description(description) {
        this.#fields.description = description;
    }

    get period() {
        return this.#getNullSafe('subscriptionPeriod', {});
    }

    set period(period) {
        const key = Object.keys(period)[0], value = Object.values(period)[0];
        if (['days', 'weeks', 'months', 'years'].includes(key) && !isNaN(value))
            this.#fields.subscriptionPeriod = {[key]: parseInt(value)};
        else warn('Invalid period data', {key, isNumber: `${value}: ${!isNaN(value)}`});
    }

    get validUntil() {
        return this.#fields.validUntil;
    }

    set validUntil(validUntil) {
        this.#fields.validUntil = validUntil;
    }

    get createdAt() {
        return this.#fields.createdAt;
    }

    get updatedAt() {
        return this.#fields.updatedAt || this.createdAt;
    }

    get periodKey() {
        const period = this.period;
        if (isNull(period) || isNull(Object.keys(period)))
            return '';
        return Object.keys(period)[0];
    }

    set periodKey(key) {
        const validKeys = ['days', 'weeks', 'months', 'years'];
        if (validKeys.includes(key))
            this.period = {[key]: this.periodValue};
        else warn(`Period key must be one of [${validKeys.join(', ')}]`);
    }

    get periodValue() {
        const period = this.period;
        if (isNull(period) || isNull(Object.values(period)))
            return '';
        return Object.values(period)[0];
    }

    set periodValue(value) {
        if (isNaN(value) || parseInt(value) < 0)
            warn('Period value must be a positive integer');
        else this.period = {[this.periodKey]: value};
    }

    save = callback => {
        const data = this.#createRequestData();
        addActionCode({
            data, callback: response => {
                if (responseIsSuccess(response)) {
                    const {action_code} = response.data;
                    this.#initializeFields(action_code);
                }
                callback(this, response);
            }
        });
    };

    update = (callback, updateUsers = false) => {
        const data = this.#createRequestData();
        data.update_users = updateUsers;
        updateActionCode({
            id: this.id, data, callback: response => {
                if (responseIsSuccess(response))
                    this.#initializeFields(response.data.action_code);
                callback(this, response);
            }
        });
    };

    delete = callback => {
        deleteActionCode({id: this.id, callback});
    };

    #createRequestData = () => ({
        code: this.code,
        max_uses: this.maxUses,
        description: this.description,
        subscription_period: this.period,
        valid_until: isNull(this.validUntil) ? null : toMoment(this.validUntil).format('YYYY-MM-DD'),
    });

    #initializeFields = props => {
        this.#initializeField('id', props.id);
        this.#initializeField('code', props.code);
        this.#initializeField('maxUses', props.max_uses);
        this.#initializeField('timesUsed', props.times_used);
        this.#initializeField('description', props.description);
        this.#initializeField('subscriptionPeriod', props.subscription_period);
        this.#initializeField('validUntil', props.valid_until);
    };

    #initializeField = (key, value) => {
        if (isNotNull(value))
            this.#fields[key] = value;
    };

    #getNullSafe = (key, defaultValue = '') => {
        if (isNotNull(this.#fields[key])) return this.#fields[key];
        return defaultValue;
    }
}