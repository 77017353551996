import './style/financialOnboarding.scss';

import React from 'react';
import PropTypes from 'prop-types';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import StepLabel from '@material-ui/core/StepLabel';
import classNames from 'classnames';

import {deviceIsMobile} from 'glob-common-js/lib/utils';

import StateComponent from "../../misc/stateComponent";
import {financialOnboardingSteps} from "../manager/financialManager";
import {appear} from "../onboardingController_v2";
import {materialTypes} from "../../material/materialTypes";
import MaterialFactory from "../../material/materialFactory";
import {getOnboardingManager} from "../manager/onboardingManagerHelper";

export default class FinancialOnboardingView extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            linkInfoVisible: false,
        };
    }

    onNextStepWithGa = (action, label, removeHighlights = false) => () => {
        getOnboardingManager('financial').sendEvent(action, label);
        this.props.onNextStep(removeHighlights)();
    };

    onChooseIntro = type => () => {
        const label = type === 'in' ? 'Inkomen toevoegen' : 'Uitgave toevoegen';
        getOnboardingManager('financial').setItemType(type);
        this.onNextStepWithGa('Klik', label, false)();
    };

    onChooseMode = mode => () => {
        const label = mode === 'manual' ? 'Handmatig' : 'Bankkoppeling';
        const manager = getOnboardingManager('financial');
        manager.sendEvent('Klik', label);
        if (mode === 'manual') manager.setNextStep();
        else this.changeState({linkInfoVisible: true});
    };

    onCloseSelectItem = () => {
        const manager = getOnboardingManager('financial');
        manager.removeAllHighlights();
        manager.setStep(financialOnboardingSteps.INTRO_STEP);
    };

    onRemoveLinkInfo = () => {
        this.changeState({linkInfoVisible: false});
    };

    renderStep = () => {
        const {step} = this.props;
        switch (step) {
            case financialOnboardingSteps.INTRO_STEP:
            case financialOnboardingSteps.SELECT_MODE:
            case financialOnboardingSteps.SELECT_ITEM:
            case financialOnboardingSteps.CONFIRM_ITEM:
                return this.renderSteps();
            case financialOnboardingSteps.NOTIFY_HELP:
                return this.renderNotifyHelp();
            default:
                return null;
        }
    };

    renderSteps = () => {
        const {step} = this.props;
        const steps = {
            [financialOnboardingSteps.INTRO_STEP]: 0,
            [financialOnboardingSteps.SELECT_MODE]: 0,
            [financialOnboardingSteps.SELECT_ITEM]: 1,
            [financialOnboardingSteps.CONFIRM_ITEM]: 2,
        };
        const itemType = getOnboardingManager('financial').getItemType();
        appear('financialOnboardingContainer');

        const extraClass = !deviceIsMobile() && [financialOnboardingSteps.SELECT_ITEM,
            financialOnboardingSteps.CONFIRM_ITEM].includes(step) ? itemType === 'out' ? 'start extraPad' : 'end' : '';
        const className = classNames('onboardingContent', extraClass);
        return (
            <div className={className} id='financialOnboardingContainer'>
                <div className='financialOnboardingPopup' id='financialOnboardingPopup'>
                    <div className='onboardingHeader'>
                        <label className='headerTitle'>Maak je financieel overzicht</label>
                        {step === financialOnboardingSteps.SELECT_ITEM && this.renderCloseButton()}
                    </div>
                    <div className='onboardingBody'>
                        <Stepper orientation='vertical' activeStep={steps[step]}>
                            <Step>
                                <StepLabel>Introductie</StepLabel>
                                <StepContent>
                                    {step === financialOnboardingSteps.INTRO_STEP ? this.renderIntro() :
                                        this.renderSelectMode()}
                                </StepContent>
                            </Step>
                            <Step>
                                <StepLabel>{isNull(itemType) ? 'Toevoegen' : itemType === 'in' ?
                                    'Inkomen toevoegen' : 'Uitgave toevoegen'}</StepLabel>
                                <StepContent>
                                    {this.renderSelectItem()}
                                </StepContent>
                            </Step>
                            <Step>
                                <StepLabel>Klaar</StepLabel>
                                <StepContent>
                                    {this.renderConfirmItem()}
                                </StepContent>
                            </Step>
                        </Stepper>
                    </div>
                </div>
            </div>
        )
    };

    renderCloseButton = () => {
        return (
            <span className='common-icon-cross closeButton' onClick={this.onCloseSelectItem}/>
        )
    };

    renderIntro = () => {
        return (
            <div className='onboardingIntroContainer'>
                <p>
                    Vul al je inkomsten en uitgaven in en binnen een paar minuten weet je precies wat je maandelijkse
                    balans is.
                </p>
                <p>
                    Klik op <i>Inkomen toevoegen</i> of <i>Uitgave toevoegen</i> en wij nemen je de eerste keer graag
                    even bij de hand.
                </p>
                <div className='introBtnsContainer'>
                    <MaterialFactory componentType={materialTypes.RAISED_BUTTON} className='introBtn'
                                     onClick={this.onChooseIntro('in')}>Inkomen toevoegen</MaterialFactory>
                    <MaterialFactory componentType={materialTypes.RAISED_BUTTON} className='introBtn'
                                     onClick={this.onChooseIntro('out')}>Uitgave toevoegen</MaterialFactory>
                </div>
            </div>
        )
    };

    renderSelectMode = () => {
        return (
            <div className='financialModeContainer'>
                <p>
                    Vul al je inkomsten en uitgaven in en binnen een paar minuten weet je precies wat je maandelijkse
                    balans is.
                </p>
                <div className='modeContainer'>
                    <span className='common-icon-edit modeIcon'/>
                    <div className='modeDescription'>
                        <label className='modeTitle'>Handmatige invoer</label>
                        <p>Voeg alleen de inkomsten en uitgaven toe die jij wilt.</p>
                    </div>
                    <MaterialFactory componentType={materialTypes.RAISED_BUTTON} size='small'
                                     onClick={this.onChooseMode('manual')}>Handmatig invoeren</MaterialFactory>
                </div>
                <div className='modeContainer'>
                    <span className='common-icon-arrow_bothways modeIcon'/>
                    <div className='modeDescription'>
                        <label className='modeTitle'>Koppel binnenkort je bank aan ByDeHand</label>
                        <p>Laat je betalingen automatisch verwerken in ByDeHand.</p>
                    </div>
                    <MaterialFactory componentType={materialTypes.FLAT_BUTTON} size='small' className='modeBtn'
                                     onClick={this.onChooseMode('link')}>Meer informatie</MaterialFactory>
                </div>
                {this.renderLinkInfo()}
            </div>
        )
    };

    renderLinkInfo = () => {
        const {linkInfoVisible} = this.state;
        if (linkInfoVisible) return (
            <div className='linkInfoContainer'>
                <div className='linkInfoHeader'>
                    <label className='linkInfoTitle'>Binnenkort mogelijk op ByDeHand</label>
                    <span className='common-icon-cross closeLinkInfo' onClick={this.onRemoveLinkInfo}/>
                </div>
                <p className='linkInfoText'>Vanaf 2019 is PSD2 van kracht en kun je bedrijven toegang geven tot je
                    betaalrekening. Hiermee kunnen bedrijven bijvoorbeeld rekeninginformatie ophalen of het saldo op je
                    rekening checken.
                    <br/><br/>
                    Wees niet bang:<br/>
                    Als je geen toegang geeft, blijft alles zoals het is. Geef je wel toestemming? Dan kun je elk moment
                    deze toestemming weer intrekken.</p>
            </div>
        );
        return null;
    };

    renderSelectItem = () => {
        const itemType = getOnboardingManager('financial').getItemType();
        const className = classNames('onboardingSelectItem', itemType === 'out' && 'left');
        const itemLabel = itemType === 'in' ? 'het inkomen dat' : 'de uitgave die';
        return (
            <div className={className}>
                <p>Klik hiernaast op een categorie en op {itemLabel} je toe wilt voegen aan je overzicht.</p>
            </div>
        )
    };

    renderConfirmItem = () => {
        return (
            <div className='onboardingConfirmPopup'>
                <p>
                    Je hebt je eerste financiële item toegevoegd! Ga nu aan de slag en vul nog meer inkomsten en
                    uitgaven toe en je weet binnen een paar minuten wat je maandelijkse balans is.
                </p>
                <MaterialFactory componentType={materialTypes.RAISED_BUTTON}
                                 onClick={this.onNextStepWithGa('Klik', 'Aan de slag', true)}>
                    Aan de slag</MaterialFactory>
            </div>
        );
    };

    renderNotifyHelp = () => {
        const manager = getOnboardingManager('financial');
        manager.highlightElement('menuPaper');
        manager.highlightElement('menuTutorial');
        appear('onboardingNotifyHelp');
        return (
            <div className='onboardingContent' id='onboardingNotifyHelp'>
                <div className='financialOnboardingPopup showTutorial absolute sbr' id='financialOnboardingPopup'>
                    <div className='onboardingHeader lp'>
                        <label className='headerTitle'>Neem me ByDeHand</label>
                    </div>
                    <div className='onboardingBody'>
                        <div className='onboardingNotifyHelp'>
                            <p className='confirmDocumentMessage'>Mocht je in de toekomst nog gebruiken willen maken van
                                deze hulp dan kun je deze guide altijd raadplegen via de knop <i>
                                    Neem me ByDeHand</i> in het menu.</p>
                            <MaterialFactory componentType={materialTypes.RAISED_BUTTON} size='small'
                                             onClick={this.onNextStepWithGa('Klik', 'Begrepen',
                                                 true)}>Begrepen</MaterialFactory>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    componentDidMount = () => {
        this.mount();
    };

    componentWillUnmount = () => {
        this.unMount();
    };

    render = () => {
        return (
            <>
                <div className='onboardingBackground'/>
                {this.renderStep()}
            </>
        )
    };
}

FinancialOnboardingView.propTypes = {
    onNextStep: PropTypes.func.isRequired,
    step: PropTypes.string.isRequired,
};