import './style/subscriptionPayment.scss';

import React from 'react';
import PropTypes from 'prop-types';

import {getSetting, deviceIsMobile} from 'glob-common-js/lib/utils';

import {steps} from "../../registrationConstants";
import {sendGaEvent, sendPageView} from "../../../../common/js/ga";
import MaterialFactory from "../../../material/materialFactory";
import {materialTypes} from "../../../material/materialTypes";

const imagesLocation = require.context('../../../../common/images/issuers', false);
const GA_CATEGORY = 'Registreren (Bankselectie)';

const activatedOldUsers = getSetting('activateOldUsers');
export default class SubscriptionPaymentView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            issuer: null,
        };
        this._isMounted = false;
    }

    changeState = stateChange => {
        this._isMounted && this.setState(stateChange);
    };

    onStartPayment = () => {
        if (isNotNull(this.state.issuer)) {
            // TODO GA
            sendGaEvent(GA_CATEGORY, 'Klik', 'Start iDEAL');
            fbq('track', 'CompleteRegistration');
            this.props.startPayment(this.state.issuer);
        }
    };

    onClickBack = () => {
        // TODO GA
        sendGaEvent(GA_CATEGORY, 'Klik', 'Vorige pagina');
        this.props.setSubStep(steps.ACTIVATION.PAYMENT_INTRO);
    };

    getImage = name => {
        name = name.replace(/ /g, '_').toLowerCase();
        // Get the image for the bank. If we have no image for a bank an error is thrown, so we catch the error and
        // return a default image instead.
        try {
            return {found: true, image: imagesLocation('./' + name + '.png')};
        } catch (err) {
            return {found: false, image: imagesLocation('./default_image.png')};
        }
    };

    getSubscriptionType = () => {
        const subscriptionInfo = this.props.subscriptionInfo;
        if (isNotNull(subscriptionInfo)) {
            if (isNotNull(subscriptionInfo.details) && subscriptionInfo.details === 'free_period_birthday')
                return 'freePeriod';
        }
        return 'trial';
    };

    isDefaultSubscription = () => (this.getSubscriptionType() === 'freePeriod' || this.props.flex.isDefault)
        && activatedOldUsers;


    toggleExtraClassName = (force) => {
        const popup = document.querySelector('div.registrationView');
        if (isNotNull(popup))
            popup.classList.toggle('payment', force);
    };

    renderIssuers = () => {
        return this.props.issuers.map(this.renderIssuer);
    };

    renderIssuer = ({name, id}) => {
        const {found, image} = this.getImage(name);
        return (
            <MaterialFactory componentType={materialTypes.GRID_TILE} key={id}
                             className={'issuerContainer' + (isNotNull(this.state.issuer) && this.state.issuer.id === id ?
                                 ' selected' : '')}
                             onClick={() => {
                                 // TODO GA
                                 sendGaEvent(GA_CATEGORY, 'Selecteer bank', name);
                                 this.changeState({issuer: {name, id}});
                             }}>
                <div className='imageContainer'>
                    <img style={{}} src={image}/>
                    {!found && <label className='issuerLabel'>{name}</label>}
                </div>
            </MaterialFactory>
        );
    };

    renderTitle = () => (
        this.isDefaultSubscription() ? 'ByDeHand abonnement' :
            '2 weken proefperiode'
    );

    renderSubTitle = () => (
        this.isDefaultSubscription() ? 'Slechts \u20ac2,50 per maand. Kies hieronder je bank.' :
            'Na de verificatietransactie van \u20ac0,01 start je proefperiode. Kies hieronder je bank.'
    );

    componentDidMount = () => {
        ga('set', 'page', '/registratie/4-betalen');
        sendPageView();
        this._isMounted = true;
        this.toggleExtraClassName(true);
    };

    componentWillUnmount = () => {
        this._isMounted = false;
        this.toggleExtraClassName(false);
    };

    render = () => (
        <div className='subscriptionPayment'>
            <label className='infoText'>{this.renderTitle()}</label>
            <label className='infoDescription'>{this.renderSubTitle()}</label>
            <div className='backContainer' onClick={this.onClickBack}>
                <span className='backButton common-icon-back'/>
                <label className='backText'>Vorige pagina</label>
            </div>
            <MaterialFactory componentType={materialTypes.GRID_LIST} className='bankContainer' cellHeight='auto'
                             cols={deviceIsMobile() ? 2 : 3}>
                {this.renderIssuers()}
            </MaterialFactory>
            <MaterialFactory componentType={materialTypes.RAISED_BUTTON}
                             onClick={this.onStartPayment} disabled={isNull(this.state.issuer)}>Start
                iDEAL</MaterialFactory>
        </div>
    )
}

SubscriptionPaymentView.propTypes = {
    startPayment: PropTypes.func.isRequired,
    setSubStep: PropTypes.func.isRequired,
    issuers: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
    })).isRequired,
    subscriptionInfo: PropTypes.shape({
        details: PropTypes.string,
    }),
    flex: PropTypes.any,
};