import './style/checklistMobile.scss';

import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ProgressBar from 'progressbar.js';
import AddAlarm from '@material-ui/icons/AddAlarm';

import StateComponent from "../../../misc/stateComponent";
import MaterialFactory from "../../../material/materialFactory";
import {materialTypes} from "../../../material/materialTypes";
import BdhInput from "../../../customControls/input/bdhInput";
import BdhSelectController from "../../../customControls/select/bdhSelectController";

export default class ChecklistMobileView extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            openCategory: this.getFirstCategoryName(),
        };
        this.progressCircles = {};
    }

    onPressCategory = name => () => {
        const {openCategory} = this.state;
        // TODO GA
        let nextCategory;
        if (openCategory === name) nextCategory = null;
        else nextCategory = name;
        this.changeState({openCategory: nextCategory});
    };

    onChangeToggle = name => evt => {
        this.props.onChangeBoxValue(name)(evt.target.checked);
    };

    onChangeSelect = name => (selectValue, isMultiple) => {
        const {checkboxValues, onChangeBoxValue} = this.props;
        let value = checkboxValues[name];
        if (isMultiple) {
            if (value.includes(selectValue))
                value = value.filter(val => val !== selectValue);
            else value.push(selectValue);
        } else value = [selectValue];
        onChangeBoxValue(name)(value);
    };

    onClearSelect = name => () => {
        this.props.onChangeBoxValue(name)([]);
    };

    updateProgress = name => () => {
        const {categories} = this.props;
        categories.forEach(category => {
            const {boxes, completion} = category;
            for (let i = 0; i < boxes.length; i++) {
                if (boxes[i].name === name && this.progressCircles.hasOwnProperty(category.name)) {
                    const circle = this.progressCircles[category.name];
                    circle.animate(completion, {
                        step: (state, circle) => {
                            circle.path.setAttribute('stroke', state.color);
                            circle.path.setAttribute('stroke-width', state.width);
                            circle.setText(parseInt(completion * 100) + '%');
                        }
                    });
                    break;
                }
            }
        });
    };

    getFirstCategoryName = () => {
        const {categories} = this.props;
        if (isNotNull(categories))
            return categories[0].name;
        return null;
    };

    triggerProgressCircles = () => {
        const {categories} = this.props;
        categories.forEach(category => {
            const {completion, name, boxes} = category;
            const container = document.getElementById(`completion-${name}-container`);
            if (isNotNull(container)) {
                container.innerHTML = null;
                const circle = new ProgressBar.Circle(container, {
                    color: '#434b50',
                    strokeWidth: 12,
                    trailColor: '#ccc',
                    trailWidth: 10,
                    easing: 'easeInOut',
                    duration: 1400,
                    text: {
                        autoStyleContainer: false,
                        value: this.getSelectedAmount(boxes),
                    },
                    from: {color: '#009fe3', width: 12},
                    to: {color: '#009fe3', width: 12},
                    step: (state, circle) => {
                        circle.path.setAttribute('stroke', state.color);
                        circle.path.setAttribute('stroke-width', state.width);
                        circle.setText(parseInt(completion * 100) + '%');
                    }
                });
                circle.text.style.fontFamily = '"Raleway", Helvetica, sans-serif';
                circle.text.style.fontSize = '.7rem';
                circle.animate(completion);
                this.progressCircles[category.name] = circle;
            }
        });
    };

    getSelectedAmount = boxes => {
        const {selectedItems} = this.props;
        let amountSelected = 0;
        boxes.forEach(box => {
            if (selectedItems.includes(box.name)) amountSelected++;
        });
        return amountSelected;
    };

    getItemsStyle = (category, isOpen) => {
        if (!isOpen) return {height: '0', transition: 'height 0s'};
        const {boxes} = category, ITEM_HEIGHT = 5, height = boxes.length * ITEM_HEIGHT + 'rem';
        return {height, transition: 'height .5s ease'};
    };

    getValueCellValue = checkbox => {
        const {valueType, name, selectType} = checkbox;
        const {checkboxValues} = this.props;
        let value = checkboxValues[name];
        if (isNotNull(value)) {
            if (valueType === 'select' && !Array.isArray(value))
                return [value];
            return value;
        }

        switch (valueType) {
            case 'bool':
                return false;
            case 'string':
                return '';
            case 'select':
                return [];
            default:
                return '';
        }
    };

    renderCategories = () => {
        const {categories} = this.props, {openCategory} = this.state;
        return (
            <div className='categoriesContainer'>
                {categories.map((category, key) => {
                    const isOpen = category.name === openCategory;
                    const itemsStyle = this.getItemsStyle(category, isOpen);
                    return (
                        <React.Fragment key={key}>
                            <div className={classNames('categoryContainer', isOpen && 'selected')}
                                 onClick={this.onPressCategory(category.name)}>
                                <span className={classNames('categoryIcon', category.icon)}/>
                                <label className='categoryName'>{category.label}</label>
                                {this.renderCompletion(category)}
                            </div>
                            <div className='itemsContainer' style={itemsStyle}>
                                {this.renderBoxes(category, isOpen)}
                            </div>
                        </React.Fragment>
                    )
                })}
            </div>
        )
    };

    renderBoxes = ({boxes}, isOpen) => {
        return boxes.map((box, key) => {
            const {selectedItems, onCheckItem, onClickLink, onClickDocument, onClickReminder} = this.props,
                {name, documentType, link, label} = box, isSelected = selectedItems.includes(name);
            return (
                <div key={key} className={classNames('checklistBox', isOpen && 'open')}>
                    <MaterialFactory componentType={materialTypes.CHECKBOX} checked={isSelected}
                                     onClick={onCheckItem(name, this.updateProgress(name))}/>
                    <div className='labelLinkContainer'>
                        <label className='boxLabel'>{label}</label>
                        {this.renderFieldOrLink(box)}
                    </div>
                    {isNotNull(documentType) && <span className={classNames('common-icon-file-add', 'addDocument')}
                                                      onClick={onClickDocument(documentType)}/>}
                    <AddAlarm onClick={onClickReminder(label)} className='reminderButton'/>
                </div>
            );
        });
    };

    renderFieldOrLink = box => {
        const {valueType, link} = box, {onClickLink} = this.props;
        if (isNotNull(valueType))
            return this.renderField(box);
        if (isNotNull(link)) return <label className='boxLink' onClick={onClickLink(link.url)}>{link.label}</label>;
        return null;
    };

    renderField = box => {
        const {valueType, name, valueLabel, selectType, selectItems, toggleOptions} = box, {onChangeBoxValue} = this.props;
        let value = this.getValueCellValue(box);
        switch (valueType) {
            case 'bool':
                const options = toggleOptions || ['Ja', 'Nee'];
                const toggleLabel = value === true ? options[0] : options[1];
                return <MaterialFactory componentType={materialTypes.TOGGLE} checked={value} label={toggleLabel}
                                        onChange={this.onChangeToggle(name)}/>;
            case 'string':
                return <BdhInput onChange={onChangeBoxValue(name)} value={value} label={valueLabel}
                                 variant='contained'/>;
            case 'select':
                return <BdhSelectController value={value} onSelect={this.onChangeSelect(name)}
                                            onClear={this.onClearSelect(name)} items={selectItems}
                                            multiple={selectType === 'multiple'}/>
        }
    };

    renderCompletion = category => {
        const {completion} = category;
        let extraComp = null;
        if (completion === 1)
            extraComp = <span className={classNames('completionIcon', 'common-icon-check')}/>;
        return (
            <>
                {extraComp}
                <div className={classNames('completionContainer', isNotNull(extraComp) && 'hidden')}>
                    <div id={`completion-${category.name}-container`} className='progressContainer'/>
                </div>
            </>
        );
    };

    componentDidMount = () => {
        this.mount();
        this.triggerProgressCircles();
    };

    componentDidUpdate = prevProps => {
        if (prevProps.checklistId !== this.props.checklistId)
            this.triggerProgressCircles();
    };

    componentWillUnmount = () => {
        this.unMount();
    };

    render = () => {
        return (
            <div className='checklistMobileView'>
                {this.renderCategories()}
            </div>
        )
    };
}

ChecklistMobileView.propTypes = {
    onOpenExplanation: PropTypes.func.isRequired,
    onCloseExplanation: PropTypes.func.isRequired,
    onCheckItem: PropTypes.func.isRequired,
    onClickDocument: PropTypes.func.isRequired,
    onClickLink: PropTypes.func.isRequired,
    onClickReminder: PropTypes.func.isRequired,
    categories: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
        boxes: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            documentType: PropTypes.string,
            link: PropTypes.shape({
                label: PropTypes.string.isRequired,
                url: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
            }),
        })).isRequired,
        completion: PropTypes.number.isRequired,
    })).isRequired,
    selectedItems: PropTypes.arrayOf(PropTypes.string).isRequired,
    explanationActive: PropTypes.bool.isRequired,
    checklistId: PropTypes.string,
};