import './houseValue.scss';

import React from 'react';

import {pathIsNotNull, toMoney} from 'glob-common-js/lib/utils';

// TODO Improve component (material)
export class DossierHouseValue extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedYear: null,
        };
        this.yearArr = Object.keys(this.props.dossier.history);
    }

    populateHouseValueTable = (year) => {
        const valueYear = this.props.dossier.history[year];
        const marketValuesObj = pathIsNotNull(valueYear, 'marketvalue') ? valueYear.marketvalue : {};
        const wozValuesObj = pathIsNotNull(valueYear, 'wozvalue') ? valueYear.wozvalue : {};
        const rebuildValuesObj = pathIsNotNull(valueYear, 'rebuildvalue') ? valueYear.rebuildvalue : {};

        return (
            <div className='houseValueTable'>
                <div className='tableValueRow'>
                    <div className='tableValueType invisibleText'>test</div>
                    <div className='tableValueRowValue bold'>Kwartaal 1</div>
                    <div className='tableValueRowValue bold'>Kwartaal 2</div>
                    <div className='tableValueRowValue bold'>Kwartaal 3</div>
                    <div className='tableValueRowValue bold'>Kwartaal 4</div>
                </div>
                <div className='tableValueRow background'>
                    <div className='tableValueType'>Marktwaarde</div>
                    {Object.values(marketValuesObj).map(this.renderHouseValueRowValue)}
                </div>
                <div className='tableValueRow background white'>
                    <div className='tableValueType'>WOZ waarde</div>
                    {Object.values(wozValuesObj).map(this.renderHouseValueRowValue)}
                </div>
                <div className='tableValueRow background white'>
                    <div className='tableValueType'>Herbouwwaarde</div>
                    {Object.values(rebuildValuesObj).map(this.renderHouseValueRowValue)}
                </div>
            </div>
        );
    };

    renderHouseValueRowValue = (value, key) => {
        key = value + Math.floor((Math.random() * 10) + 1);
        isNotNull(value) ? toMoney(value) : value = 'Onbekend';
        return (
            <div className='tableValueRowValue line' key={key}>{value}</div>
        );
    };

    setSelectedYear = () => {
        let yearSelect = document.getElementById('houseValueYearSelect');
        this.setState({selectedYear: yearSelect.value});
    };

    componentDidMount = () => {
        let keyArr = this.yearArr;
        this.setState({selectedYear: keyArr[0]});
    };

    render = () => {

        return (
            <div className='dossierHouseValueContainer'>
                <label className='containerTitle'>Waarde</label>
                <div className='containerDesc'>
                    <p>In onderstaande tabel worden de marktwaarde, WOZ waarde en herbouwwaarde van je woning
                        weergegeven.</p>
                    <b>Marktwaarde</b>
                    <p>Dit is een indicatie van de prijs die je kunt ontvangen bij de verkoop van je woning.</p>
                    <b>WOZ waarde</b>
                    <p>Dit is een indicatie van de waarde van de opstal, zoals die in het kader van de wet WOZ is
                        vastgelegd. Wanneer de WOZ-waarde een stuk hoger is dan de marktwaarde, kun je bezwaar maken.
                        Met een lagere WOZ-waarde betaal je namelijk minder onroerendezaakbelasting (OZB). Je kunt één
                        keer per jaar bezwaar maken bij de gemeente, binnen 6 weken na ontvangst van je
                        WOZ-beschikking.</p>
                    <b>Herbouwwaarde</b>
                    <p>Dit is een indicatie van hoeveel het kost de woning te herbouwen. Als de waarde waarvoor je de
                        opstal hebt verzekerd beduidend afwijkt van de herbouwwaarde, ben je bij een incident
                        onderverzekerd (je krijgt niet voldoende geld vergoed) of oververzekerd (je betaalt teveel
                        verzekeringspremie).
                        De gegevens zijn afkomstig van infofolio.</p>
                </div>
                <div className='selectContainer'>
                    <label className='yearTitle'>Jaar</label>
                    <select className='yearSelect' id='houseValueYearSelect' onChange={this.setSelectedYear}>
                        {this.yearArr.map((year, key) => <option key={key} value={year}>{year}</option>)}
                    </select>
                </div>
                {isNotNull(this.state.selectedYear) ? this.populateHouseValueTable(this.state.selectedYear) :
                    <label>Er zijn nog geen details bekend bij ons.</label>}
            </div>
        )
    }
}

export default DossierHouseValue;
