import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import HealthAnalysisView from './healthAnalysisView';
import ErrorBoundary from "../../errorBoundary";
import {sendPageView} from "../../../common/js/ga";

export class HealthAnalysisController extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount = () => {
        sendPageView('/zorganalyse');
    };

    render = () => {
        return (
            <ErrorBoundary>
                <HealthAnalysisView healthState={this.props.healthState} state={this.state}
                                    currentInsurance={this.props.healthState.currentInsurance}/>
            </ErrorBoundary>
        );
    }
}

const mapStateToProps = state => ({
    healthState: state.healthInsuranceAnalysis
});

export default withRouter(connect(mapStateToProps)(HealthAnalysisController));