import {isFunction, isNotNull} from "glob-common-js/lib/utils";
import store from './smartActionStore';
import smartActions from './smartActions';

let subscribers = [];
let managerStore = store.storage;

/**
 * Subscribe to the smart action manager
 * @param name
 * @param onUpdate callback to trigger on an update
 */
export const subscribe = (name, onUpdate) => {
    if (isNotNull(name) && isFunction(onUpdate)) {
        if (!store.hasOwnProperty(name)) {
            subscribers.push({name, onUpdate});
        } else throw new Error('Smart action manager already contains a subscriber with name \'' + name + '\'');
    } else {
        throw new Error('Subscriber must provide a name and onUpdate callback');
    }
};

/**
 * Unsubscribe from the smart action manager
 * @param name
 */
export const unsubscribe = name => {
    if (isNotNull(name) && store.hasOwnProperty(name)) {
        delete store[name];
    } else {
        throw new Error('Action manager does not have an entry with name \'' + name + '\'');
    }
};

export const addToActionStore = (key, value) => {
    managerStore = store.addToStorage(key, value);
    updateSubscribers();
};

/**
 * Get a value from the action store
 * @param key String or Array containing the key(s) to get the value from
 */
export const getFromActionStore = (key) => {
    return store.getFromStorage(key);
};

/**
 * Update store
 * @param key String or Array containing the key(s) which need to be updated
 * @param value
 */
export const updateActionStore = (key, value) => {
    managerStore = store.updateStorage(key, value);
    updateSubscribers();
};

/**
 * Delete an item from the store
 * @param key String or Array containing the key(s) which need to be deleted
 */
export const deleteFromActionStore = key => {
    managerStore = store.removeFromStorage(key);
    updateSubscribers();
};

export const triggerAction = (triggerCode) => {
    smartActions.triggerAction(triggerCode);
};

const updateSubscribers = () => {
    for (let i = 0; i < subscribers.length; i++) {
        subscribers[i].onUpdate(managerStore);
    }
};