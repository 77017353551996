import React from 'react';
import PropTypes from 'prop-types';
import InsuranceScanView from "./insuranceScanView";
import {updateAccount} from "../../../../misc/requestSender";
import StateComponent from "../../../misc/stateComponent";
import {sendGaEvent, sendPageView} from "../../../../common/js/ga";
import {steps} from "../../registrationConstants";

const GA_CATEGORY = 'Registreren (Verzekeringsscan)';
export default class InsuranceScanController extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            saving: false,
        };
    }

    onClickDecision = decision => {
        sendGaEvent(GA_CATEGORY, 'Klik', decision === 'yes' ? 'Ja, graag' : 'Nee, bedankt');
        this.changeState({saving: true});
        let {user, setSubStep} = this.props;
        user = this.addDecisionToExternalData(user, decision);
        updateAccount({
            id: user.id,
            data: user,
            callback: (response) => {
                this.changeState({saving: false});
                setSubStep(steps.ACTIVATION.PAYMENT_INTRO);
            },
        })
    };

    addDecisionToExternalData = (user, decision) => {
        user.external_data = user.external_data || {};
        user.external_data.insuranceScan = {accepted: decision === 'yes'};
        return user;
    };

    componentDidMount = () => {
        this.mount();
        ga('set', 'page', '/registratie/3-verzekering-scan');
        sendPageView();
    };

    componentWillUnmount = () => {
        this.unMount();
    };

    render = () => {
        return <InsuranceScanView saving={this.state.saving} onClickDecision={this.onClickDecision}/>
    }
};

InsuranceScanController.propTypes = {
    setSubStep: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
};