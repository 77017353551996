import {filterListDuplicates, isNotNull, warn} from 'glob-common-js/lib/utils';

const mapping2018 = {
    MNZ1: {
        label: "Menzis",
        basic: [{name: "Menzis Basis Voordelig", label: "Menzis Basis Voordelig"}],
        additional: [{type: "COMBI", name: "Menzis Aanvullend", label: "Menzis Aanvullend"}, {
            type: "COMBI",
            name: "Menzis Extra Aanvullend",
            label: "Menzis Extra Aanvullend"
        }]
    },
    MNZ2: {
        label: "Menzis",
        basic: [{name: "Menzis Basis", label: "Menzis Basis"}, {name: "Menzis Basis Vrij", label: "Menzis Basis Vrij"}],
        additional: [{type: "AANV", name: "Menzis ExtraVerzorgd 1", label: "Menzis ExtraVerzorgd 1"}, {
            type: "AANV",
            name: "Menzis ExtraVerzorgd 2",
            label: "Menzis ExtraVerzorgd 2"
        }, {type: "AANV", name: "Menzis ExtraVerzorgd 3", label: "Menzis ExtraVerzorgd 3"}, {
            type: "TAND",
            name: "Menzis Tandverzorgd 250",
            label: "Menzis Tandverzorgd 250"
        }, {type: "TAND", name: "Menzis Tandverzorgd 500", label: "Menzis Tandverzorgd 500"}, {
            type: "TAND",
            name: "Menzis Tandverzorgd 750",
            label: "Menzis Tandverzorgd 750"
        }, {type: "COMBI", name: "Menzis JongerenVerzorgd", label: "Menzis JongerenVerzorgd"}]
    },
    PMNZ1: {
        label: "PMA Menzis",
        basic: [{name: "PMA Menzis Basis Voordelig", label: "PMA Menzis Basis Voordelig"}],
        additional: [{type: "COMBI", name: "PMA Menzis Aanvullend", label: "PMA Menzis Aanvullend"}, {
            type: "COMBI",
            name: "PMA Menzis Extra Aanvullend",
            label: "PMA Menzis Extra Aanvullend"
        }]
    },
    PMNZ2: {
        label: "PMA Menzis",
        basic: [{name: "PMA Menzis Basis", label: "PMA Menzis Basis"}, {
            name: "PMA Menzis Basis Vrij",
            label: "PMA Menzis Basis Vrij"
        }],
        additional: [{
            type: "AANV",
            name: "PMA Menzis ExtraVerzorgd 1",
            label: "PMA Menzis ExtraVerzorgd 1"
        }, {type: "AANV", name: "PMA Menzis ExtraVerzorgd 2", label: "PMA Menzis ExtraVerzorgd 2"}, {
            type: "AANV",
            name: "PMA Menzis ExtraVerzorgd 3",
            label: "PMA Menzis ExtraVerzorgd 3"
        }, {type: "TAND", name: "PMA Menzis Tandverzorgd 250", label: "PMA Menzis Tandverzorgd 250"}, {
            type: "TAND",
            name: "PMA Menzis Tandverzorgd 500",
            label: "PMA Menzis Tandverzorgd 500"
        }, {type: "TAND", name: "PMA Menzis Tandverzorgd 750", label: "PMA Menzis Tandverzorgd 750"}, {
            type: "COMBI",
            name: "PMA Menzis JongerenVerzorgd",
            label: "PMA Menzis JongerenVerzorgd"
        }]
    },
    ANDZ: {
        label: "Anderzorg",
        basic: [{name: "Anderzorg Basisverzekering", label: "Anderzorg Basisverzekering"}],
        additional: [
           {type: "LOS", name: "Anderzorg Fysio 6",      label: "Anderzorg Fysio 6",      group: "FYS"},
           {type: "LOS", name: "Anderzorg Tand 75% 250", label: "Anderzorg Tand 75% 250", group: "TAND"},
           {type: "LOS", name: "Anderzorg Tand 75% 500", label: "Anderzorg Tand 75% 500", group: "TAND"},
           {type: "LOS", name: "Anderzorg  Buitenland",  label: "Anderzorg  Buitenland",  group: "BUIT"}
         ]
    },
    HEMA: {
        label: "HEMA",
        basic: [{name: "HEMA Basisverzekering", label: "HEMA Basisverzekering"}],
        additional: [{type: "AANV", name: "HEMA Aanvullend 1", label: "HEMA Aanvullend 1"}, {
            type: "AANV",
            name: "HEMA Aanvullend 2",
            label: "HEMA Aanvullend 2"
        }, {type: "AANV", name: "HEMA Aanvullend 3", label: "HEMA Aanvullend 3"}, {
            type: "TAND",
            name: "HEMA Tand 1",
            label: "HEMA Tand 1"
        }, {type: "TAND", name: "HEMA Tand 2", label: "HEMA Tand 2"}, {
            type: "TAND",
            name: "HEMA Tand 3",
            label: "HEMA Tand 3"
        }]
    },
    VGZ: {
        label: "VGZ",
        basic: [{name: "VGZ Ruime Keuze", label: "VGZ Ruime Keuze"}, {
            name: "VGZ Eigen Keuze",
            label: "VGZ Eigen Keuze"
        }],
        additional: [
          {type: "AANV", name: "VGZ Aanvullend Goed", label: "VGZ Aanvullend Goed"},
          {type: "AANV", name: "VGZ Aanvullend Beter",label: "VGZ Aanvullend Beter"},
          {type: "AANV", name: "VGZ Aanvullend Best", label: "VGZ Aanvullend Best"},
          {type: "TAND", name: "VGZ Tand Goed",       label: "VGZ Tand Goed"      },
          {type: "TAND", name: "VGZ Tand Beter",      label: "VGZ Tand Beter"},
          {type: "TAND", name: "VGZ Tand Best",       label: "VGZ Tand Best"}
        ]
    },
    IZA: {
        label: "IZA",
        basic: [{name: "IZA Ruime Keuze", label: "IZA Ruime Keuze"}, {
            name: "IZA Eigen Keuze",
            label: "IZA Eigen Keuze"
        }],
        additional: [{type: "AANV", name: "IZA Extra Zorg 1", label: "IZA Extra Zorg 1"}, {
            type: "AANV",
            name: "IZA Extra Zorg 2",
            label: "IZA Extra Zorg 2"
        }, {type: "AANV", name: "IZA Extra Zorg 3", label: "IZA Extra Zorg 3"}, {
            type: "AANV",
            name: "IZA Extra Zorg 4",
            label: "IZA Extra Zorg 4"
        }, {type: "TAND", name: "IZA Extra Tand 1", label: "IZA Extra Tand 1"}, {
            type: "TAND",
            name: "IZA Extra Tand 2",
            label: "IZA Extra Tand 2"
        }, {type: "TAND", name: "IZA Extra Tand 3", label: "IZA Extra Tand 3"}]
    },
    IZZ: {
        label: "IZZ",
        basic: [{name: "IZZ Zorgbewustpolis", label: "IZZ Zorgbewustpolis"}, {
            name: "IZZ Zorg-op-maatpolis",            label: "IZZ Zorg-op-maatpolis"        },
            {name: "IZZ Zorgkeuzepolis", label: "IZZ Zorgkeuzepolis"}],
        additional: [{type: "COMBI", name: "IZZ Zorg voor Zorg", label: "IZZ Zorg voor Zorg"}, {
            type: "COMBI",            name: "IZZ Zorg voor Zorg Extra 1",            label: "IZZ Zorg voor Zorg Extra 1"
        }, {type: "COMBI", name: "IZZ Zorg voor Zorg Extra 2", label: "IZZ Zorg voor Zorg Extra 2"}, {
            type: "COMBI",            name: "IZZ Zorg voor Zorg Extra 3",            label: "IZZ Zorg voor Zorg Extra 3"
        }, {type: "COMBI", name: "IZZ Bijzonder Bewust", label: "IZZ Bijzonder Bewust"}, {
            type: "lOS",            name: "IZZ Fysio Goed",            label: "IZZ Fysio Goed",            group: "FYS"
        }, {type: "lOS", name: "IZZ Fysio Beter", label: "IZZ Fysio Beter", group: "FYS"}, {
            type: "lOS",            name: "IZZ Tand Goed",            label: "IZZ Tand Goed",            group: "TAND"
        }, {type: "lOS", name: "IZZ Tand Beter", label: "IZZ Tand Beter", group: "TAND"},
         {type: "lOS", name: "IZZ Buitenland", label: "IZZ Buitenland", group: "BUIT"}]
    },
    IZZCZ: {
        label: "IZZ",
        basic: [{name: "IZZ Natura Select (CZ)", label: "IZZ Natura Select (CZ)"}, {
            name: "IZZ Natura (CZ)",            label: "IZZ Natura (CZ)"        },
            {name: "IZZ Restitutie (CZ)", label: "IZZ Restitutie (CZ)"}],
        additional: [{
            type: "COMBI",            name: "IZZ Zorg voor Zorg Goed (CZ)",            label: "IZZ Zorg voor Zorg Goed (CZ)"
        }, {type: "COMBI", name: "IZZ Zorg voor de Zorg (CZ)", label: "IZZ Zorg voor de Zorg (CZ)"}, {
            type: "COMBI",            name: "IZZ Zorg voor de Zorg Compleet (CZ)",            label: "IZZ Zorg voor de Zorg Compleet (CZ)"
        }]
    },
    UNV1: {
        label: "Univé",
        basic: [{name: "Unive Zorg Select", label: "Unive Zorg Select"}],
        additional: [
          {type: "AANV", name: "Unive Aanvullend Goed", label: "Unive Aanvullend Goed"},
          {type: "AANV",            name: "Unive Aanvullend Beter",            label: "Unive Aanvullend Beter"},
          {type: "AANV", name: "Unive Aanvullend Best", label: "Unive Aanvullend Best"},
          {type: "TAND",            name: "Unive Tand Goed",            label: "Unive Tand Goed"},
          {type: "TAND", name: "Unive Tand Beter", label: "Unive Tand Beter"},
          {type: "TAND",            name: "Unive Tand Best",            label: "Unive Tand Best"},
          {type: "LOS", name: "Unive Fysio 9", label: "Unive Fysio 9", group: "FYS"},
          {type: "LOS",            name: "Unive Fysio 18",            label: "Unive Fysio 18",group: "FYS"},
          {type: "LOS", name: "Unive Tand 250", label: "Unive Tand 250", group: "TAND"},
          {type: "LOS",            name: "Unive Tand 500",            label: "Unive Tand 500",            group: "TAND"},
          {type: "LOS",            name: "Unive Wereld",            label: "Unive Wereld",            group: "BUIT"},
          {type: "LOS", name: "Unive Tand Ongevallen", label: "Unive Tand Ongevallen", group: "TONG"}]
    },
    UNV2: {
        label: "Univé",
        basic: [{name: "Unive Zorg geregeld", label: "Unive Zorg geregeld"},
                {name: "Unive Zorg Vrij",     label: "Unive Zorg Vrij"
        }],
        additional: [
          {type: "AANV", name: "Unive Aanvullend Goed", label: "Unive Aanvullend Goed"}, {
            type: "AANV", name: "Unive Aanvullend Beter", label: "Unive Aanvullend Beter"
        }, {type: "AANV", name: "Unive Aanvullend Best", label: "Unive Aanvullend Best"}, {
            type: "TAND", name: "Unive Tand Goed",       label: "Unive Tand Goed"
        }, {type: "TAND", name: "Unive Tand Beter", label: "Unive Tand Beter"}, {
            type: "TAND", name: "Unive Tand Best",  label: "Unive Tand Best"
        }]
    },
    UMC: {
        label: "UMC",
        basic: [{name: "UMC Basis", label: "UMC Basis"}],
        additional: [
          {type: "AANV", name: "UMC Extra Zorg 1", label: "UMC Extra Zorg 1"},
          {type: "AANV", name: "UMC Extra Zorg 2", label: "UMC Extra Zorg 2"},
          {type: "AANV", name: "UMC Extra Zorg 3", label: "UMC Extra Zorg 3"},
          {type: "TAND", name: "UMC Extra Tand 1", label: "UMC Extra Tand 1"},
          {type: "TAND", name: "UMC Extra Tand 2", label: "UMC Extra Tand 2"},
          {type: "TAND", name: "UMC Extra Tand 3", label: "UMC Extra Tand 3"}]
    },
    AMER: {
        label: "De Amersfoortse",
        basic: [{name: "De Amersfoortse Ruime Keuze", label: "De Amersfoortse Ruime Keuze"},
                {name: "De Amersfoortse Eigen Keuze", label: "De Amersfoortse Eigen Keuze"}],
        additional: [
          {type: "AANV", name: "De Amersfoortse Start", label: "De Amersfoortse Start"}, {
            type: "AANV", name: "De Amersfoortse Extra",      label: "De Amersfoortse Extra"
        }, {type: "AANV", name: "De Amersfoortse Uitgebreid", label: "De Amersfoortse Uitgebreid"}, {
            type: "TAND", name: "De Amersfoortse Tandarts Start", label: "De Amersfoortse Tandarts Start"  }, {
            type: "TAND", name: "De Amersfoortse Tandarts Extra", label: "De Amersfoortse Tandarts Extra"  }, {
            type: "TAND", name: "De Amersfoortse Tandarts Uitgebreid", label: "De Amersfoortse Tandarts Uitgebreid"}]
    },
    DTZ: {
        label: "Ditzo",
        basic: [{name: "Ditzo Goede Keuze", label: "Ditzo Goede Keuze"},
                {name: "Ditzo Vrije Keuze", label: "Ditzo Vrije Keuze"}],
        additional: [{type: "AANV", name: "Ditzo Zorggoed", label: "Ditzo Zorggoed"}, {
            type: "AANV",            name: "Ditzo Zorgbeter",            label: "Ditzo Zorgbeter"        },
            {type: "AANV", name: "Ditzo Zorgbewust", label: "Ditzo Zorgbewust"}, {
            type: "TAND",            name: "Ditzo Tandgoed",            label: "Ditzo Tandgoed"
        }, {type: "TAND", name: "Ditzo Tandbeter", label: "Ditzo Tandbeter"}, {
            type: "TAND",            name: "Ditzo Tandbest",            label: "Ditzo Tandbest"
        }]
    },


    ZKR: {
        label: "Zekur",
        basic: [{name: "Gewoon ZEKUR", label: "Gewoon ZEKUR"}, {name: "Gewoon ZEKUR Vrij", label: "Gewoon ZEKUR Vrij"}],
        additional: [{
            type: "COMBI",
            name: "ZEKUR Complete aanvullende verzekering",
            label: "ZEKUR Complete aanvullende verzekering"
        }, {type: "LOS", name: "ZEKUR Fysio", label: "ZEKUR Fysio", group: "FYS"}, {
            type: "LOS",
            name: "ZEKUR Tand",
            label: "ZEKUR Tand",
            group: "TAND"
        }, {
            type: "LOS",
            name: "ZEKUR Buitenland en Reisverzekering",
            label: "ZEKUR Buitenland en Reisverzekering",
            group: "BUIT"
        }]
    },
    FLKS: {
        label: "FLKS",
        basic: [{name: "FLKS Basis", label: "FLKS Basis"}],
        additional: [{type: "COMBI", name: "FLKS Aanvullend", label: "FLKS Aanvullend"}]
    },
    HOLZ: {
        label: "Hollandzorg",
        basic: [{name: "Hollandzorg basisverzekering", label: "Hollandzorg basisverzekering"}],
        additional: [{type: "AANV", name: "Hollandzorg Start", label: "Hollandzorg Start"}, {
            type: "AANV",
            name: "Hollandzorg Extra",
            label: "Hollandzorg Extra"
        }, {type: "AANV", name: "Hollandzorg Plus", label: "Hollandzorg Plus"}, {
            type: "AANV",
            name: "Hollandzorg Top",
            label: "Hollandzorg Top"
        }, {type: "TAND", name: "Hollandzorg Tandextra", label: "Hollandzorg Tandextra"}, {
            type: "TAND",
            name: "Hollandzorg Tandplus",
            label: "Hollandzorg Tandplus"
        }]
    },
    BWZ: {
        label: "Bewuzt",
        basic: [{name: "Bewuzt basisverzekering", label: "Bewuzt basisverzekering"}],
        additional: [{type: "LOS", name: "Bewuzt Tand goed", label: "Bewuzt Tand goed", group: "TAND"}, {
            type: "LOS",            name: "Bewuzt Tand beter",            label: "Bewuzt Tand beter",            group: "TAND"
        }, {type: "LOS", name: "Bewuzt Fysio goed", label: "Bewuzt Fysio goed", group: "FYS"}, {
            type: "LOS",            name: "Bewuzt Fysio beter",            label: "Bewuzt Fysio beter",            group: "FYS"
        }, {type: "LOS", name: "Bewuzt Buitenland", label: "Bewuzt Buitenland", group: "BUIT"}]
    },
    CZ: {
        label: "CZ",
        basic: [{name: "CZ Zorg-op-maatpolis", label: "CZ Zorg-op-maatpolis"}, {
            name: "CZ Zorgbewustpolis",
            label: "CZ Zorgbewustpolis"
        }, {name: "CZ Zorgkeuzepolis", label: "CZ Zorgkeuzepolis"}],
        additional: [{type: "AANV", name: "CZ Aanvullend Start", label: "CZ Aanvullend Start"}, {
            type: "AANV",
            name: "CZ Aanvullend Basis",
            label: "CZ Aanvullend Basis"
        }, {type: "AANV", name: "CZ Aanvullend Plus", label: "CZ Aanvullend Plus"}, {
            type: "AANV",
            name: "CZ Aanvullend Top",
            label: "CZ Aanvullend Top"
        }, {type: "AANV", name: "CZ Aanvullend 50+", label: "CZ Aanvullend 50+"}, {
            type: "TAND",
            name: "CZ Tandarts",
            label: "CZ Tandarts"
        }, {type: "TAND", name: "CZ Tandarts Uitgebreid", label: "CZ Tandarts Uitgebreid"}, {
            type: "COMBI",
            name: "CZ Aanvullend Jongeren",
            label: "CZ Aanvullend Jongeren"
        }, {type: "COMBI", name: "CZ Aanvullend Gezinnen", label: "CZ Aanvullend Gezinnen"}]
    },
    DLL: {
        label: "Nationale Nederlanden",
        basic: [{name: "Nationale Nederlanden Restitutie", label: "Nationale Nederlanden Restitutie"}],
        additional: [
          {type: "AANV", name: "NN Comfort", label: "NN Comfort"},
          {type: "AANV",            name: "NN Compleet",            label: "NN Compleet"},
          {type: "AANV", name: "NN Extra", label: "NN Extra"},
          { type: "AANV",            name: "NN Start",            label: "NN Start"},
            {  type: "COMBI",            name: "NN Combi",            label: "NN Combi"},
            {type: "TAND", name: "NN TandenGaaf 1000", label: "NN TandenGaaf 1000"}, {
            type: "TAND",            name: "NN TandenGaaf 1500",            label: "NN TandenGaaf 1500"        },
             {type: "TAND", name: "NN TandenGaaf 250", label: "NN TandenGaaf 250"}, {
            type: "TAND",            name: "NN TandenGaaf 500",            label: "NN TandenGaaf 500"
        }]
    },
    OHRA: {
        label: "OHRA",
        basic: [{name: "OHRA Basisverzekering", label: "OHRA Basisverzekering"}],
        additional: [{type: "AANV", name: "OHRA Aanvullend", label: "OHRA Aanvullend"}, {
            type: "AANV",
            name: "OHRA Extra Aanvullend",
            label: "OHRA Extra Aanvullend"
        }, {type: "AANV", name: "OHRA Sterk", label: "OHRA Sterk"}, {
            type: "TAND",
            name: "OHRA Tand Sterk",
            label: "OHRA Tand Sterk"
        }, {type: "TAND", name: "OHRA Tandengaaf 250", label: "OHRA Tandengaaf 250"}, {
            type: "TAND",
            name: "OHRA Tandengaaf 500",
            label: "OHRA Tandengaaf 500"
        }, {type: "AANV", name: "OHRA Uitgebreid", label: "OHRA Uitgebreid"}]
    },
    DSW: {
        label: "DSW",
        basic: [{name: "DSW Basisverzekering", label: "DSW Basisverzekering"}],
        additional: [{type: "COMBI", name: "DSW AV-Standaard", label: "DSW AV-Standaard"}, {
            type: "COMBI",
            name: "DSW AV-Top",
            label: "DSW AV-Top"
        }, {type: "COMBI", name: "DSW AV-Compact", label: "DSW AV-Compact"}, {
            type: "COMBI",
            name: "DSW AV-Student",
            label: "DSW AV-Student"
        }]
    },
    STH: {
        label: "Stad Holland",
        basic: [{name: "Stad Holland Basisverzekering", label: "Stad Holland Basisverzekering"}],
        additional: [{type: "COMBI", name: "Stad holland Compact AV", label: "Stad holland Compact AV"}, {
            type: "COMBI",            name: "Stad holland Extra Uitgebreid AV",            label: "Stad holland Extra Uitgebreid AV"
        }, {type: "COMBI", name: "Stad holland Jongeren AV", label: "Stad holland Jongeren AV"}, {
            type: "COMBI",            name: "Stad holland Standaard AV",            label: "Stad holland Standaard AV"
        }, {type: "COMBI", name: "Stad holland Uitgebreid AV", label: "Stad holland Uitgebreid AV"}]
    },
    SAL: {
        label: "Salland",
        basic: [{name: "Salland Basisverzekering", label: "Salland Basisverzekering"}],
        additional: [
            {type: "AANV", name: "Salland Start", label: "Salland Start"},
            {type: "AANV", name: "Salland Extra", label: "Salland Extra"        },
            {type: "AANV", name: "Salland Plus",  label: "Salland Plus"        },
            {type: "AANV", name: "Salland Top", label: "Salland Top"},
            {type: "TAND", name: "Salland TandExtra", label: "Salland TandExtra"        },
            {type: "TAND", name: "Salland TandPlus", label: "Salland TandPlus"},
            {type: "TAND", name: "Salland TandTop",  label: "Salland TandTop"
        }]
    },
    ZORD: {
        label: "Zorgdirect",
        basic: [{name: "Zorgdirect Basisverzekering", label: "Zorgdirect Basisverzekering"}],
        additional: [{type: "AANV", name: "Zorgdirect Start", label: "Zorgdirect Start"}, {
            type: "AANV",
            name: "Zorgdirect Slim",
            label: "Zorgdirect Slim"
        }, {type: "AANV", name: "Zorgdirect Extra", label: "Zorgdirect Extra"}, {
            type: "AANV",
            name: "Zorgdirect Plus",
            label: "Zorgdirect Plus"
        }, {type: "AANV", name: "Zorgdirect Top", label: "Zorgdirect Top"}, {
            type: "TAND",
            name: "Zorgdirect TandExtra",
            label: "Zorgdirect TandExtra"
        }, {type: "TAND", name: "Zorgdirect TandPlus", label: "Zorgdirect TandPlus"}, {
            type: "TAND",
            name: "Zorgdirect TandTop",
            label: "Zorgdirect TandTop"
        }]
    },
    ONVZ: {
        label: "ONVZ",
        basic: [{name: "ONVZ Vrije Keuze Basisverzekering", label: "ONVZ Vrije Keuze Basisverzekering"}],
        additional: [{type: "AANV", name: "ONVZ Wereldfit", label: "ONVZ Wereldfit"}, {
            type: "AANV",
            name: "ONVZ Startfit",
            label: "ONVZ Startfit"
        }, {type: "AANV", name: "ONVZ Extrafit", label: "ONVZ Extrafit"}, {
            type: "AANV",
            name: "ONVZ Benfit",
            label: "ONVZ Benfit"
        }, {type: "AANV", name: "ONVZ Optifit", label: "ONVZ Optifit"}, {
            type: "AANV",
            name: "ONVZ Topfit",
            label: "ONVZ Topfit"
        }, {type: "COMBI", name: "ONVZ Superfit", label: "ONVZ Superfit"}, {
            type: "TAND",
            name: "ONVZ Tandfit A",
            label: "ONVZ Tandfit A"
        }, {type: "TAND", name: "ONVZ Preventief", label: "ONVZ Preventief"}, {
            type: "TAND",
            name: "ONVZ Tandfit B",
            label: "ONVZ Tandfit B"
        }, {type: "TAND", name: "ONVZ Tandfit C", label: "ONVZ Tandfit C"}, {
            type: "TAND",
            name: "ONVZ Tandfit D",
            label: "ONVZ Tandfit D"
        }]
    },
    VVAA: {
        label: "Vvaa",
        basic: [{name: "Vvaa Basisverzekering", label: "Vvaa Basisverzekering"}],
        additional: [{type: "AANV", name: "Vvaa Zorg op reis", label: "Vvaa Zorg op reis"}, {
            type: "AANV",
            name: "Vvaa Start",
            label: "Vvaa Start"
        }, {type: "AANV", name: "Vvaa Plus", label: "Vvaa Plus"}, {
            type: "AANV",
            name: "Vvaa Optimaal",
            label: "Vvaa Optimaal"
        }, {type: "AANV", name: "Vvaa Top", label: "Vvaa Top"}, {
            type: "AANV",
            name: "Vvaa Student",
            label: "Vvaa Student"
        }, {type: "TAND", name: "Vvaa Tandplus A", label: "Vvaa Tandplus A"}, {
            type: "TAND",
            name: "Vvaa Tandplus B",
            label: "Vvaa Tandplus B"
        }, {type: "TAND", name: "Vvaa Tand Preventief", label: "Vvaa Tand Preventief"}, {
            type: "TAND",
            name: "Vvaa Tandplus C",
            label: "Vvaa Tandplus C"
        }, {type: "TAND", name: "Vvaa Tandplus D", label: "Vvaa Tandplus D"}]
    },
    PNO: {
        label: "PNOzorg",
        basic: [{name: "PNO Basisverzekering", label: "PNO Basisverzekering"}],
        additional: [{type: "AANV", name: "PNO Start", label: "PNO Start"}, {
            type: "AANV",
            name: "PNO Beter",
            label: "PNO Beter"
        }, {type: "AANV", name: "PNO Plus", label: "PNO Plus"}, {
            type: "AANV",
            name: "PNO Optimaal",
            label: "PNO Optimaal"
        }, {type: "AANV", name: "PNO Top", label: "PNO Top"}, {
            type: "TAND",
            name: "PNO Tandplus A",
            label: "PNO Tandplus A"
        }, {type: "TAND", name: "PNO Tandplus B", label: "PNO Tandplus B"}, {
            type: "TAND",
            name: "PNO Tand Preventief",
            label: "PNO Tand Preventief"
        }, {type: "TAND", name: "PNO Tandplus C", label: "PNO Tandplus C"}, {
            type: "TAND",
            name: "PNO Tandplus D",
            label: "PNO Tandplus D"
        }]
    },
    ZK: {
        label: "Zilveren Kruis",
        basic: [{
            name: "Zilveren Kruis Basis Budget",
            label: "Zilveren Kruis Basis Budget"
        }, {
            name: "Zilveren Kruis Basis Exclusief",
            label: "Zilveren Kruis Basis Exclusief"
        }, {name: "Zilveren Kruis Basis Zeker", label: "Zilveren Kruis Basis Zeker"}],
        additional: [{
            type: "AANV",
            name: "Zilveren Kruis Aanvullend 3ster",
            label: "Zilveren Kruis Aanvullend 3ster"
        }, {
            type: "AANV",
            name: "Zilveren Kruis Aanvullend 4ster",
            label: "Zilveren Kruis Aanvullend 4ster"
        }, {
            type: "AANV",
            name: "Zilveren Kruis Aanvullend ster",
            label: "Zilveren Kruis Aanvullend ster"
        }, {
            type: "AANV",
            name: "Zilveren Kruis Basis Plus Module",
            label: "Zilveren Kruis Basis Plus Module"
        }, {type: "TAND", name: "Zilveren Kruis Tand 2ster", label: "Zilveren Kruis Tand 2ster"}, {
            type: "TAND",
            name: "Zilveren Kruis Tand 3ster",
            label: "Zilveren Kruis Tand 3ster"
        }, {type: "TAND", name: "Zilveren Kruis Tand 4ster", label: "Zilveren Kruis Tand 4ster"}, {
            type: "TAND",
            name: "Zilveren Kruis Tand Basis",
            label: "Zilveren Kruis Tand Basis"
        }, {type: "TAND", name: "Zilveren Kruis Tand ster", label: "Zilveren Kruis Tand ster"}, {
            type: "AANV",
            name: "Zilveren Kruis Aanvullend 2ster",
            label: "Zilveren Kruis Aanvullend 2ster"
        }]
    },
    FRIES: {
        label: "de Friesland",
        basic: [{
            name: "de Friesland Alles Verzorgd Polis",            label: "de Friesland Alles Verzorgd Polis"
        },
         {name: "de Friesland Zelf Bewust Polis", label: "de Friesland Zelf Bewust Polis"}],
        additional: [{type: "AANV", name: "de Friesland budget", label: "de Friesland budget"}, {
            type: "AANV",
            name: "de Friesland extra",
            label: "de Friesland extra"
        }, {type: "AANV", name: "de Friesland optimaal", label: "de Friesland optimaal"}, {
            type: "AANV",
            name: "de Friesland standaard",
            label: "de Friesland standaard"
        }, {type: "TAND", name: "de Friesland Tand Extra", label: "de Friesland Tand Extra"}, {
            type: "TAND",
            name: "de Friesland Tand Optimaal",
            label: "de Friesland Tand Optimaal"
        }, {type: "TAND", name: "de Friesland Tand standaard", label: "de Friesland Tand standaard"}]
    },
    FBTO: {
        label: "FBTO",
        basic: [{name: "FBTO Naturapolis", label: "FBTO Naturapolis"}, {
            name: "FBTO Restitutiepolis",
            label: "FBTO Restitutiepolis"
        }],
        additional: [{
            type: "LOS",
            name: "FBTO Spieren & Gewrichten 200",
            label: "FBTO Spieren & Gewrichten 200",
            group: "FYS"
        }, {
            type: "LOS",
            name: "FBTO Spieren & Gewrichten 350",
            label: "FBTO Spieren & Gewrichten 350",
            group: "FYS"
        }, {type: "LOS", name: "FBTO Tand 250 - 75%", label: "FBTO Tand 250 - 75%", group: "TAND"}, {
            type: "LOS",
            name: "FBTO Tand 250 - 100%",
            label: "FBTO Tand 250 - 100%",
            group: "TAND"
        }, {type: "LOS", name: "FBTO Tand 500", label: "FBTO Tand 500", group: "TAND"}, {
            type: "LOS",
            name: "FBTO Tand 750",
            label: "FBTO Tand 750",
            group: "TAND"
        }, {type: "LOS", name: "FBTO Europa", label: "FBTO Europa", group: "BUIT"}, {
            type: "LOS",
            name: "FBTO Wereld",
            label: "FBTO Wereld",
            group: "BUIT"
        }, {
            type: "LOS",
            name: "FBTO Alternatieve Geneeswijzen 200",
            label: "FBTO Alternatieve Geneeswijzen 200",
            group: "ALT"
        }, {
            type: "LOS",
            name: "FBTO Alternatieve Geneeswijzen 350",
            label: "FBTO Alternatieve Geneeswijzen 350",
            group: "ALT"
        }, {
            type: "LOS",
            name: "FBTO Gezichtszorg & Orthodontie",
            label: "FBTO Gezichtszorg & Orthodontie",
            group: "G&O"
        }, {type: "LOS", name: "FBTO Tandongevallen", label: "FBTO Tandongevallen", group: "TONG"}, {
            type: "LOS",
            name: "FBTO Gezinsplanning",
            label: "FBTO Gezinsplanning",
            group: "GEZ"
        }, {type: "LOS", name: "FBTO Zorg & Herstel", label: "FBTO Zorg & Herstel", group: "Z&H"}, {
            type: "LOS",
            name: "FBTO Conditie & Fitheid",
            label: "FBTO Conditie & Fitheid",
            group: "C&F"
        }, {type: "LOS", name: "FBTO Beter in je vel", label: "FBTO Beter in je vel", group: "BIJV"}]
    },
    INTER: {
        label: "Interpolis",
        basic: [{name: "Interpolis ZorgActief Basisverzekering", label: "Interpolis ZorgActief Basisverzekering"}],
        additional: [{type: "AANV", name: "Interpolis ZonderMeer", label: "Interpolis ZonderMeer"}, {
            type: "AANV", name: "Interpolis MeerZeker",            label: "Interpolis MeerZeker"
        }, {type: "AANV", name: "Interpolis ZonderZorgen", label: "Interpolis ZonderZorgen"}, {
            type: "TAND", name: "GebitActief 250",         label: "GebitActief 250"
        }, {type: "TAND", name: "GebitActief 500", label: "GebitActief 500"}, {
            type: "TAND", name: "GebitActief 1000",        label: "GebitActief 1000"}]
    },
    INTCOMP: {
        label: "Interpolis",
        basic: [{name: "Interpolis Zorgcompact Basisverzekering", label: "Interpolis Zorgcompact Basisverzekering"}],
        additional: [{
            type: "COMBI",
            name: "Interpolis ZorgCompact aanvullend",
            label: "Interpolis ZorgCompact aanvullend"
        }]
    },
    PRLF: {
        label: "Pro life",
        basic: [{
            name: "Pro life Principe Polis Budget",            label: "Pro life Principe Polis Budget"
        }, {  name: "Pro life Principe Polis",            label: "Pro life Principe Polis"}],
        additional: [{type: "AANV", name: "Pro life Smallpolis", label: "Pro life Smallpolis"}, {
            type: "AANV",
            name: "Pro life Mediumpolis",
            label: "Pro life Mediumpolis"
        }, {type: "AANV", name: "Pro life Largepolis", label: "Pro life Largepolis"}, {
            type: "AANV",
            name: "Pro life Extra Largepolis",
            label: "Pro life Extra Largepolis"
        }, {type: "TAND", name: "Pro life Tandsmall", label: "Pro life Tandsmall"}, {
            type: "TAND",
            name: "Pro life Tandmedium",
            label: "Pro life Tandmedium"
        }, {type: "TAND", name: "Pro life Tandlarge", label: "Pro life Tandlarge"}]
    },
    ZZ: {
        label: "ZieZo",
        basic: [{name: "ZieZo Basis", label: "ZieZo Basis"}, {name: "ZieZo Selectief", label: "ZieZo Selectief"}],
        additional: [{type: "AANV", name: "Ziezo Aanvullend 1", label: "Ziezo Aanvullend 1"},
        {type: "TAND", name: "Ziezo Tand 1", label: "Ziezo Tand 1"}]
    },
    STGZ: {
        label: "Studentengoedverzekerd",
        basic: [{
            name: "Studentengoedverzekerd Basis Budget",
            label: "Studentengoedverzekerd Basis Budget"
        }, {
            name: "Studentengoedverzekerd Basis Exclusief",
            label: "Studentengoedverzekerd Basis Exclusief"
        }, {name: "Studentengoedverzekerd Basis Zeker", label: "Studentengoedverzekerd Basis Zeker"}],
        additional: [{
            type: "AANV",
            name: "Studentengoedverzekerd Aanvullend 3ster",
            label: "Studentengoedverzekerd Aanvullend 3ster"
        }, {
            type: "AANV",
            name: "Studentengoedverzekerd Aanvullend 4ster",
            label: "Studentengoedverzekerd Aanvullend 4ster"
        }, {
            type: "AANV",
            name: "Studentengoedverzekerd Aanvullend ster",
            label: "Studentengoedverzekerd Aanvullend ster"
        }, {
            type: "AANV",
            name: "Studentengoedverzekerd Basis Plus Module",
            label: "Studentengoedverzekerd Basis Plus Module"
        }, {
            type: "TAND",
            name: "Studentengoedverzekerd Tand 2ster",
            label: "Studentengoedverzekerd Tand 2ster"
        }, {
            type: "TAND",
            name: "Studentengoedverzekerd Tand 3ster",
            label: "Studentengoedverzekerd Tand 3ster"
        }, {
            type: "TAND",
            name: "Studentengoedverzekerd Tand 4ster",
            label: "Studentengoedverzekerd Tand 4ster"
        }, {
            type: "TAND",
            name: "Studentengoedverzekerd Tand Basis",
            label: "Studentengoedverzekerd Tand Basis"
        }, {
            type: "TAND",
            name: "Studentengoedverzekerd Tand ster",
            label: "Studentengoedverzekerd Tand ster"
        }, {
            type: "AANV",
            name: "StudentengoedverzekerdAanvullend 2ster",
            label: "StudentengoedverzekerdAanvullend 2ster"
        }]
    },
    ZEZ1: {
        label: "Zorg en Zekerheid",
        basic: [{name: "ZorgenZekerheid Zorg Gemak Polis", label: "ZorgenZekerheid Zorg Gemak Polis"}],
        additional: [{type: "COMBI", name: "ZorgenZekerheid AV-Gemak", label: "ZorgenZekerheid AV-Gemak"}]
    },
    ZEZ2: {
        label: "Zorg en Zekerheid",
        basic: [{
            name: "ZorgenZekerheid Zorg Vrij Polis",
            label: "ZorgenZekerheid Zorg Vrij Polis"
        }, {name: "ZorgenZekerheid Zorg Zeker Polis", label: "ZorgenZekerheid Zorg Zeker Polis"}],
        additional: [{
            type: "COMBI",
            name: "ZorgenZekerheid AV-Basis",
            label: "ZorgenZekerheid AV-Basis"
        }, {type: "COMBI", name: "ZorgenZekerheid AV-GeZZin", label: "ZorgenZekerheid AV-GeZZin"}, {
            type: "COMBI",            name: "ZorgenZekerheid AV-GeZZin Compact",            label: "ZorgenZekerheid AV-GeZZin Compact"
        }, {type: "COMBI", name: "ZorgenZekerheid AV-Plus", label: "ZorgenZekerheid AV-Plus"}, {
            type: "COMBI",            name: "ZorgenZekerheid AV-Standaard",            label: "ZorgenZekerheid AV-Standaard"
        }, {type: "COMBI", name: "ZorgenZekerheid AV-Sure", label: "ZorgenZekerheid AV-Sure"}, {
            type: "COMBI",            name: "ZorgenZekerheid AV-Top",            label: "ZorgenZekerheid AV-Top"
        }]
    },
    NAAC1: {
        label: "National Academic",
        basic: [{name: "National Academic Basisverzekering", label: "National Academic Basisverzekering"}],
        additional: [{
            type: "AANV",
            name: "National Academic Aanvullend 1",
            label: "National Academic Aanvullend 1"
        }, {
            type: "AANV",
            name: "National Academic Aanvullend 2",
            label: "National Academic Aanvullend 2"
        }, {
            type: "AANV",
            name: "National Academic Aanvullend 3",
            label: "National Academic Aanvullend 3"
        }, {type: "TAND", name: "National Academic Tandzorg 1", label: "National Academic Tandzorg 1"}, {
            type: "TAND",
            name: "National Academic Tandzorg 2",
            label: "National Academic Tandzorg 2"
        }, {type: "TAND", name: "National Academic Tandzorg 3", label: "National Academic Tandzorg 3"}]
    },
    NAAC2: {
        label: "National Academic",
        basic: [{name: "National Academic Studentenverzekering", label: "National Academic Studentenverzekering"}],
        additional: [{
            type: "AANV",            name: "National Academic Ready-2-Go",            label: "National Academic Ready-2-Go"
        }, {type: "AANV", name: "National Academic Love-2-Move", label: "National Academic Love-2-Move"}, {
            type: "AANV",            name: "National Academic All-in-1",            label: "National Academic All-in-1"
        }, {type: "TAND", name: "National Academic Smile", label: "National Academic Smile"},
        {type: "LOS", name: "National Academy Fysio 6" , label: "National Academy Fysio 6" , group: "FYS"},
        {type: "LOS", name: "National Academy Fysio 9" , label: "National Academy Fysio 9" , group: "FYS"},
        {type: "LOS", name: "National Academy Tand 150 - 75%" , label: "National Academy Tand 150 - 75%" , group: "TAND"},
        {type: "LOS", name: "National Academy Tand 150 - 100%" , label: "National Academy Tand 150 - 100%" , group: "TAND"},
        {type: "LOS", name: "National Academy Tand 250 - 75%" , label: "National Academy Tand 250 - 75%" , group: "TAND"},
        {type: "LOS", name: "National Academy Tand 250 - 100%" , label: "National Academy Tand 250 - 100%" , group: "TAND"},
        {type: "LOS", name: "National Academy Europa" , label: "National Academy Europa" , group: "BUIT"},
        {type: "LOS", name: "National Academy Wereld" , label: "National Academy Wereld" , group: "BUIT"},
        {type: "LOS", name: "National Academy Anticonceptie" , label: "National Academy Anticonceptie" , group: "ANT"}]
    },
    PROM1: {
        label: "Promovendum",
        basic: [{name: "Promovendum Basisverzekeirng", label: "Promovendum Basisverzekeirng"}],
        additional: [{type: "AANV", name: "Promovendum Primair", label: "Promovendum Primair"}, {
            type: "AANV",
            name: "Promovendum Royaal",
            label: "Promovendum Royaal"
        }, {type: "AANV", name: "Promovendum Ideaal", label: "Promovendum Ideaal"}, {
            type: "AANV",
            name: "Promovendum Excellent",
            label: "Promovendum Excellent"
        }, {type: "TAND", name: "Promovendum Tand Primair", label: "Promovendum Tand Primair"}, {
            type: "TAND",
            name: "Promovendum Tand Royaal",
            label: "Promovendum Tand Royaal"
        }, {type: "TAND", name: "Promovendum Tand Optimaal", label: "Promovendum Tand Optimaal"}]
    },
    PROM2: {
        label: "Promovendum",
        basic: [{name: "Promovendum Studentenverzekering", label: "Promovendum Studentenverzekering"}],
        additional: [
        {type: "LOS", name: "Promovendum Fysio 6" , label: "Promovendum Fysio 6" , group: "FYS"},
        {type: "LOS", name: "Promovendum Fysio 9" , label: "Promovendum Fysio 9" , group: "FYS"},
        {type: "LOS", name: "Promovendum Tand 150 - 75%" , label: "Promovendum Tand 150 - 75%" , group: "TAND"},
        {type: "LOS", name: "Promovendum Tand 150 - 100%" , label: "Promovendum Tand 150 - 100%" , group: "TAND"},
        {type: "LOS", name: "Promovendum Tand 250 - 75%" , label: "Promovendum Tand 250 - 75%" , group: "TAND"},
        {type: "LOS", name: "Promovendum Tand 250 - 100%" , label: "Promovendum Tand 250 - 100%" , group: "TAND"},
        {type: "LOS", name: "Promovendum Europa" , label: "Promovendum Europa" , group: "BUIT"},
        {type: "LOS", name: "Promovendum Wereld" , label: "Promovendum Wereld" , group: "BUIT"},
        {type: "LOS", name: "Promovendum Anticonceptie" , label: "Promovendum Anticonceptie" , group: "ANT"}]
    },
    BES: {
        label: "Besured",
        basic: [{name: "Besured Basisverzekering", label: "Besured Basisverzekering"}],
        additional: [
          {type: "LOS", name: "Besured Fysio 6" , label: "Besured Fysio 6" , group: "FYS"},
          {type: "LOS", name: "Besured Fysio 9" , label: "Besured Fysio 9" , group: "FYS"},
          {type: "LOS", name: "Besured Tand 150 - 75%" , label: "Besured Tand 150 - 75%" , group: "TAND"},
          {type: "LOS", name: "Besured Tand 150 - 100%" , label: "Besured Tand 150 - 100%" , group: "TAND"},
          {type: "LOS", name: "Besured Tand 250 - 75%" , label: "Besured Tand 250 - 75%" , group: "TAND"},
          {type: "LOS", name: "Besured Tand 250 - 100%" , label: "Besured Tand 250 - 100%" , group: "TAND"},
          {type: "LOS", name: "Besured Europa" , label: "Besured Europa" , group: "BUIT"},
          {type: "LOS", name: "Besured Wereld" , label: "Besured Wereld" , group: "BUIT"},
          {type: "LOS", name: "Besured Anticonceptie" , label: "Besured Anticonceptie" , group: "ANT"}]
    },
    JUST: {
        label: "Just",
        basic: [{name: "Just Basic", label: "Just Basic"}],
        additional: [{type: "AANV", name: "Just Live", label: "Just Live"}, {
            type: "TAND",
            name: "Just Smile",
            label: "Just Smile"
        }]
    },
    AON: {
        label: "IAK/AON",
        basic: [{
            name: "IAK/AON Zorg Plan Natura",     label: "IAK/AON Zorg Plan Natura"
        }, {name: "IAK/AON Zorg Plan Restitutie", label: "IAK/AON Zorg Plan Restitutie"}],
        additional: [{type: "AANV", name: "Vitaal 1", label: "Vitaal 1"}, {
            type: "AANV", name: "Vitaal 2",  label: "Vitaal 2"
        }, {type: "AANV", name: "Vitaal 3", label: "Vitaal 3"}, {
            type: "AANV", name: "Vitaal 4", label: "Vitaal 4"
        }, {type: "TAND", name: "Tand 1", label: "Tand 1"}, {
            type: "TAND", name: "Tand 2", label: "Tand 2"
        }, {type: "TAND", name: "Tand 3", label: "Tand 3"}, {
            type: "TAND", name: "Tand 4",  label: "Tand 4"
        }]
    },
    INTW: {
        additional: [{type: "COMBI", name: "inTwente AV-Standaard", label: "inTwente AV-Standaard"}, {
            type: "COMBI",
            name: "inTwente AV-Top",
            label: "inTwente AV-Top"
        }, {type: "COMBI", name: "inTwente AV-Compact", label: "inTwente AV-Compact"}, {
            type: "COMBI",
            name: "inTwente AV-Student",
            label: "inTwente AV-Student"
        }]
    }
};

/**
 * Get basic insurances based on the specified insurer.
 * If no (correct) params are given, all basic insurances are returned.
 *
 * @param insurer Insurer name
 */
export const getBasics = (insurer) => {
    if (isNotNull(insurer)) {
        if (isNotNull(insurer)) {
            return getBasicsByInsurer(insurer);
        }
        return getAllBasics();
    }
    return getAllBasics();
};

/**
 * Get the basic insurances belonging to specified insurer.
 *
 * @param insurer Insurer name
 */
export const getBasicsByInsurer = insurer => {
    if (isNotNull(insurer)) {
        let basics = [];
        let keys = Object.keys(getByName());
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            if (getByName(key).label === insurer) {
                basics = basics.concat(getByName(key).basic);
            }
        }
        return filterDuplicates(basics);
    } else {
        throw new Error('Cannot fetch HealthInsuranceMapping entry due to missing insurer argument.');
    }
};
/**
 * Fetch all basic insurances in the mapping.
 */
const getAllBasics = () => {
    let keys = Object.keys(getByName());
    let basics = [];
    for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        basics = basics.concat(getByName(key).basic);
    }
    return filterListDuplicates(basics);
};

/**
 * Get all additional insurances based on the selected additional insurance name or basic insurance name. If neither of
 * them are specified, no additional insurances are returned.
 *
 * @param additional Additional insurance instance
 * @param basic Basic insurance instance
 */
export const getAdditionals = ({additional, basic}) => {
    if (isNotNull(basic)) {
        if (isNotNull(additional)) {
            return getSelectableAdditionals(basic, additional);
        }
        return getByBasic(basic).additional;
    }
    return [];
};

/**
 * Get an additional insurance by its name.
 *
 * @param name Additional insurance name
 */
export const getAdditional = name => {
    let keys = Object.keys(getByName());
    for (let i = 0; i < keys.length; i++) {
        let entry = getByName(keys[i]);
        for (let j = 0; j < entry.additional.length; j++) {
            if (entry.additional[j].name === name) return entry.additional[j];
        }
    }
    return null;
};

export const getBasic = name => {
    let keys = Object.keys(getByName());
    for (let i = 0; i < keys.length; i++) {
        let entry = getByName(keys[i]);
        for (let j = 0; j < entry.basic.length; j++) {
            if (entry.basic[j].name === name) return entry.basic[j];
        }
    }
};

/**
 * Get all insurers
 */
export const getInsurers = () => {
    let insurers = [];
    let keys = Object.keys(getByName());
    for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        let label = getByName(key).label;
        if (insurers.indexOf(label) === -1) insurers.push(label);
    }
    return filterListDuplicates(insurers);
};

/**
 * Get the entry identifier belonging to a basic insurance
 *
 * @param basic Basic insurance instance
 */
export const getKeyByBasic = basic => {
    if (isNotNull(basic)) {
        let keys = Object.keys(getByName());
        for (let i = 0; i < keys.length; i++) {
            let entry = getByName(keys[i]);

            if (isNotNull(entry.basic)) {
                for (let j = 0; j < entry.basic.length; j++) {
                    if (entry.basic[j].name === basic.name) return keys[i];
                }
            }
        }
        warn('Cannot find an insurance with basic insurance \'' + basic.name + '\'.');
    } else {
        throw new Error('Cannot fetch HealthInsuranceMapping entry due to missing label argument.');
    }
};

/**
 * Get the insurance object belonging to a basic insurance.
 *
 * @param basic Basic insurance instance
 */
export const getByBasic = basic => {
    if (isNotNull(basic)) {
        return getByName(getKeyByBasic(basic));
    } else {
        throw new Error('Cannot fetch HealthInsuranceMapping entry due to missing basic argument.');
    }
};

/**
 * Get the selectable insurances based on already selected values
 *
 * @param basic Basic insurance instance (required)
 * @param additional Additional insurance instance (optional)
 */
export const getSelectableAdditionals = (basic, additional) => {
    if (isNotNull(basic)) {
        let name = getKeyByBasic(basic);
        let additionals = getByBasic(basic).additional;
        if (isNotNull(additional)) {
            // Only remove dental insurances when a combi insurance or separate module(s) are selected.
            switch (additional.type) {
                case 'COMBI':
                case 'LOS':
                    additionals = additionals.filter(additional => additional.type !== 'TAND');
                    break;
            }
        } else {
            return getByName(name).additional;
        }
        return filterListDuplicates(additionals);
    } else {
        throw new Error('Cannot fetch insurances due to missing basic argument.');
    }
};

/**
 * Get all additional insurances with type COMBI with given insurance code
 *
 * @param code Insurer code
 */
export const getCombiAdditionals = code => (
    getByName(code).additional.filter(additional => additional.type === 'COMBI')
);

/**
 * Get all extra insurances with type TAND with given insurance code
 *
 * @param code Insurer code
 */
export const getDentalAdditionals = code => (
    getByName(code).additional.filter(additional => additional.type === 'TAND')
);

/**
 * Get all extra insurances with type AANV with given insurance code
 *
 * @param code Insurer code
 */
export const getAanvAdditionals = code => (
    getByName(code).additional.filter(additional => additional.type === 'AANV')
);

/**
 * Get all extra insurances with type LOS with given insurance code
 *
 * @param code Insurer code
 */
export const getLosAdditionals = code => (
    getByName(code).additional.filter(additional => additional.type === 'LOS')
);

const filterDuplicates = listOrObject => {
    if (Array.isArray(listOrObject)) {
        return filterListDuplicates(listOrObject);
    } else {
        // Argument is object
        let filteredObject = {};
        let keys = Object.keys(listOrObject);
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            if (!filteredObject.hasOwnProperty(key)) {
                filteredObject[key] = listOrObject[key];
            }
        }
        return filteredObject;
    }
};

const insurers = {
    "Menzis": {
        "insurer": "Menzis",
        "phoneNumber": "088 222 40 40",
        "email": "https://www.menzis.nl/vraagindienen",
        "address": "postadres: Menzis, Postbus 75000, 7500 KC ENSCHEDE",
        "url": "https://applicaties.menzis.nl/"
    },
    "PMA Menzis": {
        "insurer": "PMA Menzis",
        "phoneNumber": "0180 320 264",
        "email": "klantenservice@pma.info",
        "address": "postadres: Menzis PMA, Postbus 313, 2910 AH Nieuwerkerk a/d Ijssel",
        "url": "https://applicaties.menzis.nl/"
    },
    "Anderzorg": {
        "insurer": "Anderzorg",
        "phoneNumber": "088 222 44 22 ",
        "email": "https://www.anderzorg.nl/klantenservice",
        "address": "postadres: Anderzorg, Postbus 1177, 7500 BD Enschede ",
        "url": "https://applicaties.anderzorg.nl/"
    },
    "HEMA": {
        "insurer": "HEMA",
        "phoneNumber": "088 222 4114 ",
        "email": "info@zorgverzekerdbijhema.nl",
        "address": null,
        "url": "https://applicaties.hemazorgverzekering.nl/login"
    },
    "VGZ": {
        "insurer": "VGZ",
        "phoneNumber": "088 131 16 51 ",
        "email": "https://www.vgz.nl/klantenservice/contact/e-mail?sessionId=ce9d59bd-5a6e-4a3d-90a5-8afe86303d30",
        "address": "https://www.vgz.nl/declareren",
        "url": "https://www.vgz.nl/inloggen?icmp=ws-117"
    },
    "IZA": {
        "insurer": "IZA",
        "phoneNumber": "0900 80 36",
        "email": "https://www.iza.nl/klantenservice#contact",
        "address": "postadres: IZA Zorgverzekeraar, Postbus 25030, 5600 RS Eindhoven ",
        "url": "https://www.iza.nl/inloggen"
    },
    "IZZ": {
        "insurer": "IZZ",
        "phoneNumber": "0900 02 74",
        "email": "https://www.vgzvoordezorg.nl/Klantenservice/contact/vraag-stellen?sessionId=195ca14f-c8df-4ffa-90ce-df1911acdae0",
        "address": "postadres: VGZ voor de Zorg, Postbus 25030, 5600 RS Eindhoven ",
        "url": "https://www.vgzvoordezorg.nl/inloggen"
    },
    "Univ\u00E9": {
        "insurer": "Univ\u00E9",
        "phoneNumber": " 072 52 77 595",
        "email": "https://www.unive.nl/klantenservice/email",
        "address": "postadres: Univ\u00E9 Verzekeringen, afdeling declaraties, postbus 25030, 5600 RS Eindhoven ",
        "url": "https://www.unive.nl/mijnunivezorg/inloggen"
    },
    "UMC": {
        "insurer": "UMC",
        "phoneNumber": "0900 80 18",
        "email": "https://www.umczorgverzekering.nl/klantenservice/contact/contactformulier?sessionId=95f006b3-bf6e-4b73-b5f4-66d77ea861ae",
        "address": "postadres: UMC Zorgverzekeraar NV, postbus 25030, 5600 RS Eindhoven ",
        "url": "https://www.umczorgverzekering.nl/inloggen"
    },
    "De Amersfoortse": {
        "insurer": "De Amersfoortse",
        "phoneNumber": "033 464 2061",
        "email": null,
        "address": null,
        "url": "https://www.amersfoortse.nl/inloggen"
    },
    "Ditzo": {
        "insurer": "Ditzo",
        "phoneNumber": null,
        "email": null,
        "address": null,
        "url": "https://mijnzorg.ditzo.nl/Authenticatie/Inloggen"
    },
    "Zekur": {
        "insurer": "Zekur",
        "phoneNumber": "0900 25 93 587 ",
        "email": "https://www.zekur.nl/klantenservice/mailen",
        "address": "postadres: ZEKUR, postbus 25030, 5600 RS eindhoven ",
        "url": "https://www.zekur.nl/inloggen-zorg"
    },
    "FLKS": {
        "insurer": "FLKS",
        "phoneNumber": "088 3535777",
        "email": "https://www.flks.nl/service-en-contact",
        "address": "postadres: Aevitea postbus 2705 6401 DE Heerlen",
        "url": "https://obz.aevitae.com/OnlineBeheerZorgverzekering/flks/Account/Login"
    },
    "Hollandzorg": {
        "insurer": "Hollandzorg",
        "phoneNumber": "570 687 123",
        "email": "https://www.hollandzorg.com/nl/particulier/klantenservice/contact/contactformulier",
        "address": "postadres: Hollandzorg postbus 166 7400 AD Deventer",
        "url": "https://www.hollandzorg.com/nl/mijn/account/safenetlogin"
    },
    "Bewuzt": {
        "insurer": "Bewuzt",
        "phoneNumber": "088 131 01 39",
        "email": "https://www.bewuzt.nl/service-en-contact",
        "address": "postadres: Bewuzst postbus 25150 5600 RS Eindhoven",
        "url": "https://www.bewuzt.nl/service-en-contact"
    },
    "CZ": {
        "insurer": "CZ",
        "phoneNumber": "088 555 77 77 ",
        "email": "https://www.cz.nl/klantenservice/stuur-uw-vraag",
        "address": "postadres: CZ postbus 90152, 500 LD Tilburg ",
        "url": "https://mijn.cz.nl/?_ga=2.101715778.2126825970.1510563045-1769997214.1509613377"
    },
    "CZdirect": {
        "insurer": "CZdirect",
        "phoneNumber": "013 593 84 00",
        "email": "https://www.cz.nl/klantenservice/formulieren/stel-je-vraag-aan-czdirect?_ga=2.259737738.1885139847.1510563149-1769997214.1509613377&_gac=1.260213375.1510563149.CjwKCAiAoqXQBRA8EiwAIIOWsoZa2Y2u5N49kYXRPobjFULa2T_9w-7Etzi-j8pTr3oGtT4FyxqekRoCO9sQAvD_BwE",
        "address": "postadres: CZ postbus 90152, 500 LD Tilburg ",
        "url": "https://mijn.cz.nl/"
    },
    "Delta Lloyd": {
        "insurer": "Delta Lloyd",
        "phoneNumber": "026 353 53 53",
        "email": "https://www.deltalloyd.nl/prive/klantenservice/contactformulier.jsp",
        "address": "postadres: Delta Lloyd Zorgverzekering, postbus 4016, 5004 JA Tilburg",
        "url": "https://mijn.dlzv.nl/"
    },
    "OHRA": {
        "insurer": "OHRA",
        "phoneNumber": "026 400 40 40 ",
        "email": "https://www.ohra.nl/klantenservice/contact/contactformulier.jsp",
        "address": "postadres: OHRA Zorgverzekeringen N.V., postbus 5062, 5004 EB Tilburg ",
        "url": "https://www.ohra.nl/klantenservice/contact/bellen.jsp"
    },
    "DSW": {
        "insurer": "DSW",
        "phoneNumber": "010 2 466 466 ",
        "email": "https://www.dsw.nl/Consumenten/contact/contactformulier",
        "address": "postadres: DSW, postbus 173, 3100 AD Schiedam",
        "url": "https://mijn.dsw.nl/Authenticatie/Inloggen?ReturnUrl=%2F"
    },
    "Stad Holland": {
        "insurer": "Stad Holland",
        "phoneNumber": "010 2 466 480",
        "email": "https://www.stadholland.nl/Consumenten/contact/contactformulier",
        "address": "postadres: ”Stad Holland Zorgverzekeraar” U.A., postbus 295, 2100 AG Schiedam ",
        "url": "https://mijn.stadholland.nl/Authenticatie/Inloggen?ReturnUrl=%2F"
    },
    "InTwente": {
        "insurer": "InTwente",
        "phoneNumber": "053 5 748 348",
        "email": "https://www.intwente.nl/Consumenten/contact/contactformulier",
        "address": "postadres: InTwente, postbus 1070, 7500 BB Enschede",
        "url": "https://www.intwente.nl/Consumenten/nieuws/digid"
    },
    "Salland": {
        "insurer": "Salland",
        "phoneNumber": "0570 68 74 84 ",
        "email": "https://www.salland.nl/service-contact/contactformulier",
        "address": "postadres: Salland zorgverzekering, Postbus 166, 7400 AD Deventer",
        "url": "https://www.salland.nl/mijn-salland"
    },
    "Zorgdirect": {
        "insurer": "Zorgdirect",
        "phoneNumber": "088 687 74 74 ",
        "email": "https://www.zorgdirect.nl/klantenservice/contact/contactformulier",
        "address": "postadres: Zorgdirect, ",
        "url": "https://www.zorgdirect.nl/mijn-zorgdirect"
    },
    "ONVZ": {
        "insurer": "ONVZ",
        "phoneNumber": "030 639 62 22",
        "email": "https://onvz.custhelp.com/app/contact/",
        "address": "postadres: ONVZ zorgverzekeraar, postbus 392, 3990 GD Houten ",
        "url": "https://www.onvz.nl/account/login"
    },
    "Vvaa": {
        "insurer": "Vvaa",
        "phoneNumber": "030 247 47 89",
        "email": "https://www.vvaa.nl/service/contactformulier",
        "address": "postadres: VvAA hoofdkantoor, postbus 8153, 3503 RD Utrecht",
        "url": "https://web.vvaazorgverzekering.nl/Account/Login"
    },
    "PNO": {
        "insurer": "PNOzorg",
        "phoneNumber": "030 639 62 62",
        "email": "https://pnozorg.custhelp.com/app/dynamicflows/pno_flow/w_id/60",
        "address": "postadres: PNOzorg postbust 459 3990 GG Houten ",
        "url": "https://web.pnozorgverzekering.nl/Account/Login"
    },
    "Zilveren Kruis": {
        "insurer": "Zilveren Kruis",
        "phoneNumber": "071 751 00 52 ",
        "email": "https://www.zilverenkruis.nl/Consumenten/contact/Paginas/default.aspx#modalnoemail",
        "address": "postadres: Zilveren Kruis Postbus 444 2300 AK Leiden",
        "url": "https://www.zilverenkruis.nl/Consumenten/mijnzilverenkruis/Paginas/digid.aspx"
    },
    "Avero Achmea": {
        "insurer": "Avero Achmea",
        "phoneNumber": "071 751 00 22",
        "email": "https://www.averoachmea.nl/consument/service/Paginas/Contact-met-Avero-Achmea.aspx",
        "address": "postadres: Aechmea, Postbust 101 7300 AC Apeldoorn",
        "url": "https://www.averoachmea.nl/consument/gezondheid/directregelen/Paginas/Zorggebruik.aspx"
    },
    "Ik!": {
        "insurer": "Ik!",
        "phoneNumber": "040 261 19 99",
        "email": "klantenservice@ik.nl",
        "address": "postadres: ik! Postbus 90165 5600 RV Eindhoven",
        "url": "https://www.ik.nl/mijn-ik"
    },
    "de Friesland": {
        "insurer": "de Friesland",
        "phoneNumber": "058 291 31 31",
        "email": null,
        "address": "postadres: DeFriesland,Postbus 270, 8901 BB leeuwarden ",
        "url": "https://www.defriesland.nl/consumenten/mijn-de-friesland/inloggen.aspx"
    },
    "FBTO": {
        "insurer": "FBTO",
        "phoneNumber": "058 234 56 78",
        "email": "https://www.fbto.nl/hulp-en-contact/formulieren/paginas/contact-formulier.aspx",
        "address": "postadres: FBTO, postbus 318, 8901 BC Leeuwarden",
        "url": "https://inloggen.fbto.nl/adfs/ls/?wa=wsignin1.0&wtrealm=http://i-portaal.achmea.nl/adfs/services/trust&wctx=d6dfe17d-807b-46a9-9e0a-eee931dc40df"
    },
    "Interpolis": {
        "insurer": "Interpolis",
        "phoneNumber": "013 462 20 25",
        "email": "zorg@interpolis..nl",
        "address": "postadres: interpolis, postbus 90106, 5000 LA Tilburg",
        "url": null
    },
    "OZF": {
        "insurer": "OZF",
        "phoneNumber": null,
        "email": "https://www.ozf.nl/consumenten/service/contact/Paginas/Contactformulier.aspx",
        "address": "postadres: zorgverzekeraar ozf, postbus 94, 7550 AB Hengelo ",
        "url": "https://www.ozf.nl/consumenten/service/contact/paginas/default.aspx"
    },
    "Pro life": {
        "insurer": "Pro life",
        "phoneNumber": "033 422 81 88",
        "email": "https://www.prolife.nl/service-en-contact/Paginas/ik-heb-een-vraag.aspx",
        "address": "postadres: Pro Life Zorgverzekering, postbus 70001, 3000 KB rotterdam ",
        "url": "https://www.prolife.nl/mijn-pro-life/paginas/digid.aspx"
    },
    "ZieZo": {
        "insurer": "ZieZo",
        "phoneNumber": "071 751 00 52 ",
        "email": "https://www.zilverenkruis.nl/Consumenten/contact/Paginas/default.aspx#modalnoemail",
        "address": "postadres: Zilveren Kruis Postbus 444 2300 AK Leiden",
        "url": "https://www.zilverenkruis.nl/Consumenten/mijnzilverenkruis/Paginas/digid.aspx"
    },
    "Studentengoedverzekerd": {
        "insurer": "Studentengoedverzekerd",
        "phoneNumber": "071 751 00 36",
        "email": null,
        "address": null,
        "url": null
    },
    "Zorg en Zekerheid": {
        "insurer": "Zorg en Zekerheid",
        "phoneNumber": "071 5 825 825",
        "email": "https://www.zorgenzekerheid.nl/service-en-contact/vraag-stellen.htm",
        "address": "postadres: Zorg en zekerheid, postbus 400, 2300 AK Leiden ",
        "url": "https://apps.zorgenzekerheid.nl/landing/static/landingspage-digid-actiepagina.html"
    },
    "Nationale Nederlanden": {
        "insurer": "Nationale Nederlanden",
        "phoneNumber": "026 353 53 53",
        "email": "https://www.nn.nl/Particulier/Klantenservice/Contactformulier-Particulieren.htm",
        "address": "NN",
        "url": "https://www.nn.nl/Particulier/Zorgverzekering.htm"
    },  
    "National Academic": {
        "insurer": "National Academic",
        "phoneNumber": "078 632 75 75",
        "email": "https://www.nationalacademic.nl/contact?gclid=CjwKCAiAoqXQBRA8EiwAIIOWsln3zcPnn30VCXUAYSaOFrgS7K-S3QYTWc3i5sVmR0SHmaNDwloFUxoCuxQQAvD_BwE",
        "address": "postadres: National Academic, postbus 362 3300 AJ Dordrecht",
        "url": "https://mijn.nationalacademic.nl/inloggen"
    },
    "Promovendum": {
        "insurer": "Promovendum",
        "phoneNumber": "078 648 13 00",
        "email": "https://www.promovendum.nl/contact",
        "address": "postadres: Promovendum, postbus 362, 3300 AJ Dordrecht",
        "url": "https://mijn.promovendum.nl/inloggen"
    },
    "Besured": {
        "insurer": "Besured",
        "phoneNumber": "078 611 81 11",
        "email": "https://www.besured.nl/contact",
        "address": "postadres: Besured Zorgservice, postbus 6213, 4000 HE Tiel",
        "url": "https://www.besured.nl/mijn-besured/inloggen"
    },
};

export const getInsurerFromMapping = name => {
    let keys = Object.keys(insurers);
    for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        if (key.toLowerCase().trim() === name.toLowerCase().trim()) {
            return insurers[key];
        }
    }
    warn('Cannot find insurer with name \'' + name + '\'.');
    return null;
};

/**
 * Get an entry from the mapping by identifier
 *
 * @param name Entry identifier
 */
const getByName = name => {
    if (isNotNull(name)) {
        if (mapping2018.hasOwnProperty(name)) {
            return mapping2018[name];
        } else {
            warn('HealthInsuranceMapping does not contain an entry with name \'' + name + '\'.');
        }
    } else {
        return mapping2018;
    }
};

export default getByName;
