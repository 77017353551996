import {createRequestConfig} from 'BdhCommon/js/platform';

export const moduleConstants = {
    STUDY: 'study',
    CARE: 'care',
    PERSONAL: 'personal',
    MONEY_INCOME: 'money_income',
    VALUABLE_POSSESSION: 'valuable_possession',
    FREE_TIME: 'free_time_sub',
    OUT_HOUSE: 'out_house',
    FAMILY: 'family',
    HOUSE: 'house',
    TRANSPORTATION_MEAN: 'transportation_mean',
    MISC: 'misc',
};

export const moduleMapping = [
    {label: 'Studie', name: moduleConstants.STUDY},
    {label: 'Persoonlijk', name: moduleConstants.PERSONAL},
    {label: 'Zorg', name: moduleConstants.CARE},
    {label: 'Geld & inkomen', name: moduleConstants.MONEY_INCOME},
    {label: 'Bezittingen', name: moduleConstants.VALUABLE_POSSESSION},
    {label: 'Vrije tijd', name: moduleConstants.FREE_TIME},
    {label: 'Gezin', name: moduleConstants.FAMILY},
    {label: 'Wonen', name: moduleConstants.HOUSE},
    {label: 'Vervoer', name: moduleConstants.TRANSPORTATION_MEAN},
    {label: 'Overig', name: moduleConstants.MISC}
];

const getModules = () => {
    moduleMapping.sort((modA, modB) => {
        const labelA = modA.label, labelB = modB.label, preferA = -1, preferB = 1, isEqual = 0;
        // Put misc module last, sort the rest alphabetically
        return labelA === 'Overig' ? preferB : labelB === 'Overig' ? preferA : labelA > labelB ? preferB :
            labelA < labelB ? preferA : isEqual;
    });

    return moduleMapping;
};

export default getModules;