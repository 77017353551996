import React from 'react';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actions from '../../redux/actions';

import {pathIsNotNull} from 'glob-common-js/lib/utils';

import AccountDetails from "./accountDetails";
import ErrorBoundary from "../errorBoundary";
import {getFamily, updateAccount} from "../../misc/requestSender";
import {addCustomEventListener, dispatchCustomEvent, removeCustomEventListener} from "../../misc/eventDispatcher";
import AddFamilyController from "./family/addFamilyController";
import {navigate} from "../../misc/utils";
import {ACTIVE, PAYMENT_PENDING, PAYMENT_WAITING} from "../../misc/authenticationConstants";

export class AccountDetailsController extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            family: [],
            twoStep: false,
            deleteAllowed: true,
        };
        this._isMounted = false;
    }

    changeState = stateChange => {
        this._isMounted && this.setState(stateChange);
    };

    onClickFamily = (familyMember) => {
        dispatchCustomEvent('addFamily', familyMember);
    };

    onAddFamily = () => {
        dispatchCustomEvent('addFamily');
    };

    onEdit = () => {
        navigate('/account-aanpassen');
    };

    /**
     * Function to (temporary) disable the two step verification. We want to disable it when deleting the account,
     * since confirming the delete is only used to be sure the user really wants to delete the account, and not for
     * security reasons.
     */
    onSetTwoStep = isEnabled => {
        let user = Object.assign({}, this.props.userState.user);
        if (pathIsNotNull(user, 'email')) {
            this.changeState({deleteAllowed: false});
            user.two_step_auth = isEnabled ? 1 : 0;
            updateAccount({
                id: user.id,
                data: user,
                callback: () => {
                    this.changeState({deleteAllowed: true});
                }
            });
        }
    };

    onClickAccountAccess = () => {
        navigate('/machtigingen');
    };

    loadFamily = () => {
        getFamily({
            callback: (response) => {
                let family = response.data;
                this.changeState({family});
            }
        })
    };

    hasSubscription = () => {
        const subscriptionData = this.props.userState.subscription;
        if (isNull(subscriptionData)) return false;

        return subscriptionData.type === 'PAY' && [PAYMENT_WAITING, PAYMENT_PENDING,
            ACTIVE].includes(subscriptionData.status);
    };

    componentDidMount = () => {
        this._isMounted = true;
        this.loadFamily();
        addCustomEventListener('updateFamily', this.loadFamily);
        // this.testGrant();
    };

    componentWillUnmount = () => {
        this._isMounted = false;
        removeCustomEventListener('updateFamily', this.loadFamily);
    };

    render = () => {
        return (
            <ErrorBoundary>
                {pathIsNotNull(this.props.userState.user, 'email') &&
                <AccountDetails onClickFamily={this.onClickFamily} onAddFamily={this.onAddFamily}
                                family={this.state.family} onEdit={this.onEdit}
                                account={this.props.userState.user} onSetTwoStep={this.onSetTwoStep}
                                deleteAllowed={this.state.deleteAllowed} hasSubscription={this.hasSubscription()}
                                onClickAccountAccess={this.onClickAccountAccess}/>}
                <AddFamilyController/>
            </ErrorBoundary>
        );
    }
}

const mapStateToProps = state => ({
    userState: state.user,
});

export default connect(mapStateToProps)(withRouter(AccountDetailsController));