import './style/additional.scss';

import React from 'react';
import PropTypes from 'prop-types';

import {capitalizeFirst, isNotNull, roundTo, scrollToTop} from 'glob-common-js/lib/utils';
import {getAdditional} from 'BdhCommon/data/mappings/healthInsuranceMapping';

import {navigate} from "../../../../../misc/utils";
import EvaluationButtonsController from '../misc/evaluationButtons/evaluationButtonsController'
import evaluationConst from '../misc/constants';
import additionalIconMapping from './data/additionalIconMapping';

export default class EvaluationAdditionalView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            coveredCoveragesLength: 4,
        };
    }

    changeState = (stateChange) => {
        if (this._isMounted) {
            this.setState(stateChange);
        }
    };

    createAdditional = (additional, key) => {
        let keys = Object.keys(additionalIconMapping);
        let additionalName = getAdditional(additional.name).label;
        return (
            <div className='additional' key={key}>
                <div className='general'>
                    <div className='row'>
                        <div className='additionalTitle'>
                            <span className='title'>Aanvullende verzekering</span>
                            <span className='value'>{additionalName}</span>
                        </div>
                        <div className='additionalPrice'>
                            <span className='title'>Maandelijkse premie</span>
                            <span className='value'>&euro; {roundTo(additional.price, 2).replace('.', ',')}</span>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='additionalIcons'>{keys.map((key) => {
                            let iconName = additionalIconMapping[key];
                            return this.createAdditionalIcon(iconName, key, additional)
                        })}</div>
                        <div className='showDetailsSection' onClick={(evt) => {
                            this.toggleAdditionalDetails(evt, additional.name)
                        }}>
                            <label className='showDetailsText' id={'showDetailsText ' + additional.name}>Bekijk
                                dekking</label>
                            <div className='common-icon-caret-down showDetails'/>
                        </div>
                    </div>
                </div>
                <div id={'details-' + additional.name} className='details'>
                    <div className='covered'>
                        <p className='coveredHead'>Je hebt <b>dekking</b> voor:</p>
                        {this.createCoveredCoverages(additional)}
                        {additional.parts_covered.length > 4 ? <div className='showMoreCoverages' onClick={(evt) => {
                            this.changeState({coveredCoveragesLength: additional.parts_covered.length});
                            evt.target.innerHTML = '';
                        }}>Meer...</div> : null}
                    </div>
                    <div className='notCovered'>
                        <p className='notCoveredHead'>Je hebt <b>geen dekking</b> voor:</p>
                        {this.createNotCoveredCoverages(additional)}
                    </div>
                </div>
            </div>)
    };

    toggleAdditionalDetails = (evt, additionalName) => {
        let detailID = 'details-' + additionalName;
        let details = document.getElementById(detailID);

        const shouldShowDetails = this.toggleCaret(evt);
        let detailsShowingText = document.getElementById('showDetailsText ' + additionalName);
        if (shouldShowDetails) {
            detailsShowingText.innerHTML = 'Verberg dekking';
        } else {
            detailsShowingText.innerHTML = 'Bekijk dekking';
        }
        if (isNotNull(details)) {
            details.classList.toggle('show');
        }
    };

    toggleCaret = evt => {
        const target = evt.target;
        if (isNotNull(target)) {
            const className = target.className;
            if (className !== 'showDetailsSection')
                this.toggleCaret({target: target.parentElement});
            else {
                const caret = target.querySelector('.showDetails');
                if (isNotNull(caret)) {
                    caret.classList.toggle('up');
                    return caret.classList.contains('up');
                }
            }
        }
        return false;
    };

    createAdditionalIcon = (iconName, coverage, additional) => {
        let className = iconName + ' additionalIcon' + (this.partIsCovered(additional, coverage) ? ' blue' : '');
        return <span key={coverage} className={className}/>;
    };

    createCoveredCoverages = additional => {
        let covered = additional.parts_covered;
        if (isNotNull(covered)) {
            let sliceMax = Math.min(covered.length, this.state.coveredCoveragesLength);
            return covered.slice(0, sliceMax).map((coverage, key) => (
                <span key={key} className='coverage'>
                        <p className='coverageTitle'>{capitalizeFirst(coverage.part)}</p>
                        <p className='coverageValue'>{coverage.text}</p>
                    </span>
            ));
        }
        return null;
    };

    createNotCoveredCoverages = additional => {
        let notCovered = additional.parts_not_covered.split(', ');
        let amountNotCovered = notCovered.length;
        return (
            <span className='coverage'>
                <p className='coverageTitle'>{notCovered.slice(0, 3).join(', ')}
                    {notCovered.length > 3 ?
                        <span onClick={(evt) => {
                            evt.target.parentElement.innerHTML = additional.parts_not_covered;
                        }} className='showMore'>{' en ' + (amountNotCovered - 3) + ' andere zorgkosten...'}</span>
                        : null}
                </p>
            </span>
        );
    };

    partIsCovered = (additional, part) => {
        return additional.parts_not_covered.toLowerCase().indexOf(part.toLowerCase() === -1);
    };

    navigateAfterDecision = () => {
        navigate('/zorganalyse/evaluatie/eigen-risico', true);
        scrollToTop();
    };

    getTotalPrice = () => {
        let total = 0;
        if (isNotNull(this.props.additionalData)) {
            for (let i = 0; i < this.props.additionalData.length; i++) {
                total += parseFloat(this.props.additionalData[i].price);
            }
        }
        return total;
    };

    componentDidMount = () => {
        this._isMounted = true;
    };

    componentWillUnmount = () => {
        this._isMounted = false;
    };

    render = () => {
        let evalButtons = [
            {
                buttonText: 'Ja, dit past bij mij',
                onClick: () => {
                    this.props.setEval(evaluationConst.SATISFIED);
                    this.navigateAfterDecision();
                },
                defaultSelected: this.props.evaluationDecision === evaluationConst.SATISFIED,
            },
            {
                buttonText: 'Nee, dit past niet (meer) bij mij',
                onClick: () => {
                    this.props.setEval(evaluationConst.DISSATISFIED);
                    this.navigateAfterDecision();
                },
                defaultSelected: this.props.evaluationDecision === evaluationConst.DISSATISFIED,
            },
            {
                buttonText: 'Dat weet ik (nog) niet',
                onClick: () => {
                    this.props.setEval(evaluationConst.UNSURE);
                    this.navigateAfterDecision();
                },
                defaultSelected: this.props.evaluationDecision === evaluationConst.UNSURE,
            }
        ];

        return (
            <div className='additionalPage'>
                <p className='pageDesc'>De basisverzekering vergoedt niet alle kosten. Met een aanvullende verzekering
                    kun je jezelf verzekeren voor ziektekosten die buiten het basispakket vallen.
                    Bekijk welke dekking jouw verzekering in 2020 biedt door deze aan te klikken.
                </p>
                <div className='additionals'>
                    {isNotNull(this.props.additionalData) ? this.props.additionalData.map(this.createAdditional) : null}
                </div>
                <div className='totalAdditionals'>
                    <p className='title'>Totale maandelijkse premie aanvullende verzekeringen</p>
                    <p>&euro; {roundTo(this.getTotalPrice(), 2).replace('.', ',')}</p>
                </div>
                <EvaluationButtonsController headerTitle='Past deze aanvullende dekking nog bij jouw situatie ?'
                                             buttons={evalButtons}/>
            </div>
        );
    }
}

EvaluationAdditionalView.propTypes = {
    setEval: PropTypes.func.isRequired,
    evaluationDecision: PropTypes.string,
    additionalData: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        parts_covered: PropTypes.arrayOf(PropTypes.shape({
            part: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
        })).isRequired,
        parts_not_covered: PropTypes.string.isRequired,
    })).isRequired,
};
