/**
 * Test the data mappings for valid content and references.
 */

import utils from 'glob-common-js/lib/utils';
import getDocumentType from 'BdhCommon/data/mappings/docTypeMapping';
import getCategoryType from 'BdhCommon/data/mappings/categoryMapping';
import getDossierType from 'BdhCommon/data/mappings/dossierTypeMapping';

export function testMappings() {
    testDocTypeMapping();
    testDosTypeMapping();
    testCatMapping();
    console.debug('All tests done');
}

/**
 * Test the docType mapping
 */
function testDocTypeMapping() {
    let keys = Object.keys(getDocumentType());
    for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        let docType = getDocumentType(key);
        console.assert(utils.isNotNull(docType.label), 'DocType \'' + key + '\' is missing a label.');
        console.assert(docType.dosLinks !== undefined && docType.dosLinks !== null, 'DocType \'' + docType.label + '\' is missing dosLinks');

        let dosLinks = docType.dosLinks;
        for (let j = 0; j < dosLinks.length; j++) {
            let dosLink = dosLinks[j];
            console.assert(utils.isNotNull(getDossierType(dosLink)), 'DocType ' + docType.label + ' has invalid dosLink ' + dosLink + '.');
        }
    }
}

/**
 * Test the dossierTypeMapping
 */
function testDosTypeMapping() {
    let keys = Object.keys(getDossierType());
    for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        let dosType = getDossierType(key);
        console.assert(utils.isNotNull(dosType.id), 'DosType \'' + key + '\' is missing a id');
        console.assert(utils.isNotNull(dosType.label), 'DosType \'' + key + '\' is missing a label');
        console.assert(utils.isNotNull(dosType.config.exclude), 'DosType \'' + key + '\' is missing a exclude field');
        console.assert(key === 'financial_overview' || utils.isNotNull(dosType.keepActions), 'DosType \'' + key + '\' is missing keep actions');
        console.assert(key === 'financial_overview' || utils.isNotNull(dosType.saveActions), 'DosType \'' + key + '\' is missing store actions');
    }
}

/**
 * Test the categoryMapping
 */
function testCatMapping() {
    let keys = Object.keys(getCategoryType());
    for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        let cat = getCategoryType(key);
        console.assert(utils.isNotNull(cat.label), 'Category \'' + key + '\' is missing buttonText');
        console.assert(cat.subCategories !== undefined && cat.subCategories !== null, 'Category \'' + key + '\' is missing subCategories');
        console.assert(cat.docTypes !== undefined && cat.docTypes !== null, 'Category \'' + key + '\' is missing docTypes');
        console.assert(cat.dosTypes !== undefined && cat.dosTypes !== null, 'Category \'' + key + '\' is missing dosTypes');
        console.assert(utils.isNotNull(cat.gaAction), 'Category \'' + key + '\' is missing a gaAction');

        let docTypes = cat.docTypes;
        for (let j = 0; j < docTypes.length; j++) {
            let docType = docTypes[j];
            if (docType === 'miscellaneous_indoors')
                console.assert(utils.isNotNull(getDocumentType(docType)), 'Category ' + key + ' has invalid docType ' + docType + '.');
            else
                console.assert(getDocumentType(docType).name !== 'miscellaneous_indoors', 'Category ' + key + ' has invalid docType ' + docType + '.');
        }

        let dosTypes = cat.dosTypes;
        for (let j = 0; j < dosTypes.length; j++) {
            let dosType = dosTypes[j];
            console.assert(utils.isNotNull(getDossierType(dosType)), 'Category ' + key + ' has invalid dosType ' + dosType + '.');
        }
    }
}