import React, {Component} from 'react';
import { Page, Text, View, Image, Document, StyleSheet } from '@react-pdf/renderer';

const check = require('../../common/images/Check.png');
const noCheck = require('../../common/images/NoCheck.png');
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#E4E4E4'
  },
  checklistHeader: {
    height: 50,
    backgroundColor: '#009FE3',
    color: '#FFFFFF',
    fontWeight: "bold",
    textAlign: "center",
    justifyContent: "center",
    display: "flex"
 },
  categorie: {
    backgroundColor: '#FFFFFF',
    display: "flex",
    flexDirection: "column"
 },
  categorieHeader: {
    height: 30,
    backgroundColor: '#009FE3',
    color: '#FFFFFF',
    fontWeight: "bold"
 },
 blanco: {
   height:10,
   backgroundColor: '#FFFFFF'
 },
 itemRow: {
   display: "flex",
   flexDirection: "row",
   borderBottomColor: "grey",
   borderBottomStyle: "solid",
   borderBottomWidth: 1
 },
 checkbox: {
    height: 15,
    width: 15
 },
 itemLabel: {
   backgroundColor: "#FFFFFF",
   color: "#000000",
   fontSize: 12,
   width: "40%"
 },
 itemValue: {
   backgroundColor: "#FFFFFF",
   color: "#000000",
   fontSize: 12,
   marginLeft:12
 }
});

export default class ChecklistPDF extends Component {

  //constructor(props){
  //  super(props);
  //}

  getValueCellValue = item => {
      const {name, } = item;
      const {checkboxValues} = this.props.data;
      let value = checkboxValues[name];
      if (value===true) {
        return "ja"
      } else {
        return value
      }
  };


  // Create Document Component
  render() {
    const {categories, selectedItems} = this.props.data
    const checklistnaam = this.props.data.checklistName//checklist.naam
    const checklistCategorieen = categories

    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.checklistHeader}>
            <Text>{checklistnaam}</Text>
          </View>
          <View style = {styles.blanco}/>
          <View style = {styles.categorie}>
            {checklistCategorieen.map((categorie) => {
              return (
                <View key={categorie.name}>
                  <View style = {styles.blanco}/>
                  <Text style={styles.categorieHeader}>{categorie.label}</Text>
                  {categorie.boxes.map((item) => {
                    return (
                      <View key={item.name} style = {styles.itemRow}>
                        <Image style={styles.checkbox}
                              source={selectedItems.includes(item.name)
                                      ? check
                                      : noCheck
                                      }
                        />
                        <Text style = {styles.itemLabel}>{item.label}</Text>
                        <Text style = {styles.itemValue}>{this.getValueCellValue(item)}</Text>
                      </View>
                    );
                  })}
                </View>
              );
            })}
          </View>
        </Page>
      </Document>

    )
  };
}
