import initialState from './initialState';
import _ from 'lodash';
import {
    appCallbackConstants,
    appConstants,
    documentConstants,
    dossierConstants,
    financialConstants,
    healthConstants,
    introductionConstants,
    miscConstants,
    moduleConstants,
    tenderConstants,
    userConstants,
} from './constants';

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case userConstants.SET_USER:
            return Object.assign({}, state, {
                user: action.user,
                subscription: action.user === null ? null : state.user,
            });
        case userConstants.SET_FAMILY_MEMBER_ID:
            return Object.assign({}, state, {
                familyId: action.id,
            });
        case userConstants.SET_REGISTRATION_MAIL:
            return Object.assign({}, state, {
                registrationMail: action.mail,
            });
        case userConstants.SET_SUBSCRIPTION:
            return Object.assign({}, state, {
                subscription: action.subscription,
            });
        case userConstants.SET_GRANTED_USER:
            return Object.assign({}, state, {
                grantedUser: action.grantedUser,
            });
    }
    return state;
};

const dossierReducer = (state = initialState, action) => {
    switch (action.type) {
        case dossierConstants.SET_DOSSIER_TYPE:
            return Object.assign({}, state, {
                dossierType: action.dossierType
            });
        case dossierConstants.SET_DOSSIER_ID:
            return Object.assign({}, state, {
                dossierId: action.id,
            });
        case dossierConstants.SET_EDIT_DOSSIER:
            return Object.assign({}, state, {
                editDossier: action.dossier,
            });
        case dossierConstants.SET_ADDED_DOSSIERS:
            return Object.assign({}, state, {
                addedDossiers: action.dossiers,
            });
        case dossierConstants.SET_DOSSIER:
            return Object.assign({}, state, {
                dossier: action.dossier
            });
        case dossierConstants.SELECT_CATEGORY:
            return Object.assign({}, state, {
                selectedCategory: action.category,
            });
        case dossierConstants.SELECT_SUBCATEGORY:
            return Object.assign({}, state, {
                selectedSubcategory: action.subCategory,
            });
        case dossierConstants.SET_DOSSIER_DOCUMENT_TYPE:
            return Object.assign({}, state, {
                dossierDocumentType: action.documentType,
            });
    }
    return state;
};

const documentReducer = (state = initialState, action) => {
    switch (action.type) {
        case documentConstants.SET_DOCTYPE:
            return Object.assign({}, state, {
                docType: action.id
            });
        case documentConstants.SET_DOC_DISPLAY_LABEL:
            return Object.assign({}, state, {
                docDisplayLabel: action.label,
            });
        case documentConstants.SET_UPLOADED_DOC:
            return Object.assign({}, state, {
                uploadedDoc: action.docData,
            });
        case documentConstants.SET_ADDED_DOCS:
            return Object.assign({}, state, {
                addedDocs: action.docs,
            });
        case documentConstants.SET_DOCUMENT_DETAIL_ID:
            return Object.assign({}, state, {
                documentDetailId: action.id,
            });
        case documentConstants.SET_DOCUMENT_TO_LINK:
            return Object.assign({}, state, {
                documentToLink: action.doc,
            });
    }
    return state;
};

const tenderReducer = (state = initialState, action) => {
    switch (action.type) {
        case tenderConstants.SET_TENDER_INFO:
            return Object.assign({}, state, {
                tenderInfo: action.info,
            });
        case tenderConstants.RESET_TENDER_INFO:
            return Object.assign({}, state, {
                tenderInfo: undefined,
            });
    }
    return state;
};

const miscReducer = (state = initialState, action) => {
    switch (action.type) {
        case miscConstants.SELECT_CATEGORY:
            return Object.assign({}, state, {
                selectedCategory: action.name
            });
        case miscConstants.SELECT_SUB_CATEGORY:
            return Object.assign({}, state, {
                selectedSubCategory: action.name,
            });
        case miscConstants.SELECT_DOSSIER_CATEGORY:
            return Object.assign({}, state, {
                selectedDossierCategory: action.name
            });
        case miscConstants.SELECT_DOSSIER_SUB_CATEGORY:
            return Object.assign({}, state, {
                selectedDossierSubCategory: action.name,
            });
        case miscConstants.SET_CONFIRM_INFO:
            return Object.assign({}, state, {
                confirmInfo: action.info,
            });
        case miscConstants.SHOW_LOGIN_POPUP:
            return Object.assign({}, state, {
                showLoginPopup: action.bool,
            });
        case miscConstants.RESET_CONFIRM_INFO:
            return Object.assign({}, state, {
                confirmInfo: {
                    text: null,
                    title: null
                }
            });
        case miscConstants.SET_EMAIL:
            return Object.assign({}, state, {
                emailInput: action.email,
            });
        case miscConstants.START_FLOW:
            return Object.assign({}, state, {
                startFlow: action.start,
            });
    }
    return state;
};

const healthAnalysisReducer = (state = initialState, action) => {
    switch (action.type) {
        case healthConstants.SET_CURRENT_INSURANCE:
            return Object.assign({}, state, {
                currentInsurance: action.insurance,
            });
        case healthConstants.SET_EVALUATION:
            return Object.assign({}, state, {
                evaluation: action.evaluation,
            });
        case healthConstants.SET_BASIC_EVALUATION:
            return _.merge({}, state, {
                evaluation: {basic: action.basic}
            });
        case healthConstants.SET_ADDITIONAL_EVALUATION:
            return _.merge({}, state, {
                evaluation: {additional: action.additional}
            });
        case healthConstants.SET_INSURER_EVALUATION:
            return _.merge({}, state, {
                evaluation: {insurer: action.insurer}
            });
        case healthConstants.SET_RISK_EVALUATION:
            return _.merge({}, state, {
                evaluation: {risk: action.risk},
            });
        case healthConstants.SET_SUMMARY_EVALUATION:
            return _.merge({}, state, {
                evaluationSummary: action.summary,
            });
        case healthConstants.SET_EXPLANINGPOPUP_SHOWN:
            return Object.assign({}, state, {
                explaningPopupShown: action.popupShown,
            });
        case healthConstants.SET_RATING_SHOWN:
            return Object.assign({}, state, {
                ratingShown: action.ratingShown,
            });
        case healthConstants.SHOW_FB:
            return Object.assign({}, state, {
                showFb: action.show,
            });
    }
    return state;
};

const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case appConstants.SET_FILE_TO_UPLOAD:
            return Object.assign({}, state, {
                fileToUpload: action.fileData,
            });
    }
    return state;
};

const moduleReducer = (state = initialState, action) => {
    switch (action.type) {
        case moduleConstants.ACTIVE_MODULES:
            return Object.assign({}, state, {
                activeModules: action.modules,
            });
        case moduleConstants.SELECT_MODULE:
            return Object.assign({}, state, {
                selectedModule: action.module,
            });
    }
    return state;
};

const appCallbackReducer = (state = initialState, action) => {
    switch (action.type) {
        case appCallbackConstants.RESET_AGENDA_ITEM:
        case appCallbackConstants.ADD_AGENDA_ITEM:
        case appCallbackConstants.EDIT_AGENDA_ITEM:
            return Object.assign({}, state, {
                agenda: action.config,
            });
        case appCallbackConstants.RESET_DOCUMENT:
        case appCallbackConstants.ADD_DOCUMENT:
            return Object.assign({}, state, {
                document: action.config,
            });
        case appCallbackConstants.SET_AGENDA_ITEM_TO_ADD:
            return Object.assign({}, state, {
                agendaItemToAdd: action.agendaItem,
            });
    }
    return state;
};

const introductionReducer = (state = initialState, action) => {
    switch (action.type) {
        case introductionConstants.ACTIVATE:
            return Object.assign({}, state, {active: action.active});
        case introductionConstants.SET_AGENDA_TAB:
            return Object.assign({}, state, {activeAgendaTab: action.tab});
    }
    return state;
};

const financialReducer = (state = initialState, action) => {
    let dossiers;
    switch (action.type) {
        case financialConstants.SET_FINANCIAL_DOSSIERS:
            return Object.assign({}, state, {
                dossiers: action.dossiers,
                dossiersLoaded: true,
            });
        case financialConstants.ADD_FINANCIAL_DOSSIER:
            return Object.assign({}, state, {
                dossiers: state.dossiers.concat(action.dossier),
            });
        case financialConstants.UPDATE_FINANCIAL_DOSSIER:
            dossiers = state.dossiers.slice();
            let dossier = action.dossier;
            for (let i = 0; i < dossiers.length; i++) {
                if (dossiers[i].id === dossier.id) {
                    dossiers.splice(i, 1, dossier);
                    break;
                }
            }
            return Object.assign({}, state, {
                dossiers,
            });
        case financialConstants.DELETE_FINANCIAL_DOSSIER:
            dossiers = state.dossiers.slice();
            for (let i = 0; i < dossiers.length; i++) {
                if (dossiers[i].id === action.id) {
                    dossiers.splice(i, 1);
                    break;
                }
            }
            return Object.assign({}, state, {
                dossiers,
            });
        case financialConstants.RESET_FINANCIAL_DOSSIERS:
            return Object.assign({}, state, {
                dossiers: [], dossiersLoaded: false,
            })
    }
    return state;
};

export default {
    user: userReducer,
    dossier: dossierReducer,
    document: documentReducer,
    tender: tenderReducer,
    misc: miscReducer,
    healthInsuranceAnalysis: healthAnalysisReducer,
    app: appReducer,
    module: moduleReducer,
    appCallback: appCallbackReducer,
    introduction: introductionReducer,
    financial: financialReducer,
};
