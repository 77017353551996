import './style/saveOnboarding.scss';

import React from 'react';
import PropTypes from 'prop-types';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import StepLabel from '@material-ui/core/StepLabel';

import {saveOnboardingSteps} from "../manager/saveManager";
import {appear} from "../onboardingController_v2";
import MaterialFactory from "../../material/materialFactory";
import {materialTypes} from "../../material/materialTypes";
import {getOnboardingManager} from "../manager/onboardingManagerHelper";

export default class SaveOnboardingView extends React.Component {
    onNextWithGa = (action, label) => () => {
        const manager = getOnboardingManager('save');
        manager.sendEvent(action, label);
        manager.setNextStep();
    };

    renderStep = () => {
        const {step} = this.props;
        switch (step) {
            case saveOnboardingSteps.INTRO_STEP:
            case saveOnboardingSteps.CONFIRM_START:
                return this.renderStepper();
            case saveOnboardingSteps.SELECT_ANALYSIS:
                return this.renderSelectAnalysis();
            case saveOnboardingSteps.NOTIFY_HELP:
                return this.renderNotifyHelp();
        }
    };

    renderStepper = () => {
        appear('saveOnboardingContainer');
        const steps = {
            [saveOnboardingSteps.INTRO_STEP]: 0,
            [saveOnboardingSteps.CONFIRM_START]: 1,
        };
        const {step} = this.props;
        return (
            <div className='onboardingContent' id='saveOnboardingContainer'>
                <div className='saveOnboardingPopup fw' id='saveOnboardingPopup'>
                    <div className='onboardingHeader'>
                        <label className='headerTitle'>Besparen op vaste lasten</label>
                    </div>
                    <div className='onboardingBody'>
                        <Stepper orientation='vertical' activeStep={steps[step]}>
                            <Step>
                                <StepLabel>Introductie</StepLabel>
                                <StepContent>{this.renderIntro()}</StepContent>
                            </Step>
                            <Step>
                                <StepLabel>Start analyse</StepLabel>
                                <StepContent>{this.renderConfirmStart()}</StepContent>
                            </Step>
                            <Step>
                                <StepLabel>Klaar</StepLabel>
                            </Step>
                        </Stepper>
                    </div>
                </div>
            </div>
        );
    };

    renderIntro = () => {
        return (
            <div className='onboardingIntroContainer'>
                <p className='mb'>Een gemiddeld gezin kan <b>€ 400,-</b> besparen op zijn verzekeringen.</p>
                <p>Met ByDeHand analyseer je de volgende verzekeringen:</p>
                <ul className='mb'>
                    <li>Zorgverzekering</li>
                    <li>Reisverzekering</li>
                </ul>
                <p>En binnenkort ook:</p>
                <ul className='mb'>
                    <li>Autoverzekering</li>
                    <li>Opstalverzekering</li>
                    <li>Rechtsbijstand</li>
                    <li>Aansprakelijkheidsverzekering</li>
                    <li>Inboedelverzekering</li>
                </ul>
                <MaterialFactory componentType={materialTypes.RAISED_BUTTON} className='proceedBtn'
                                 onClick={this.onNextWithGa('Klik', 'Verder')}>
                    Verder</MaterialFactory>
            </div>
        )
    };

    renderSelectAnalysis = () => {
        return (
            <div className='onboardingContent'>
                <div className='saveOnboardingPopup absolute sbr' id='saveOnboardingPopup'>
                    <div className='onboardingHeader lp'>
                        <label className='headerTitle'>Besparen op vaste lasten</label>
                    </div>
                    <div className='onboardingBody padded'>
                        <p>Klik op de verzekering die je wilt analyseren en start met besparen.</p>
                    </div>
                </div>
            </div>
        )
    };

    renderConfirmStart = () => (
        <div className='onboardingStartContainer'>
            <p>Je hebt gekozen voor de zorgverzekeringsanalyse. De analyse is geopend in een <b>nieuw tabblad</b>.</p>
            <p>Heb je de analyse afgerond, klik dan op verder.</p>
            <MaterialFactory componentType={materialTypes.RAISED_BUTTON} disabled={this.props.tabNextDisabled}
                             onClick={this.onNextWithGa('Klik', 'Verder')}>
                Verder</MaterialFactory>
        </div>
    );

    renderNotifyHelp = () => {
        const manager = getOnboardingManager('save');
        manager.highlightElement('menuPaper');
        manager.highlightElement('menuTutorial');
        appear('onboardingNotifyHelp');
        return (
            <div className='onboardingContent' id='onboardingNotifyHelp'>
                <div className='saveOnboardingPopup showTutorial absolute sbr' id='saveOnboardingPopup'>
                    <div className='onboardingHeader lp'>
                        <label className='headerTitle'>Neem me ByDeHand</label>
                    </div>
                    <div className='onboardingBody'>
                        <div className='onboardingConfirmDocument'>
                            <p className='confirmDocumentMessage'>Mocht je in de toekomst nog gebruiken willen maken van
                                deze hulp dan kun je deze guide altijd raadplegen via de knop <i>
                                    Neem me ByDeHand</i> in het menu.</p>
                            <MaterialFactory componentType={materialTypes.RAISED_BUTTON}
                                             onClick={this.onNextWithGa('Begrepen', 'Neem me ByDeHand')}
                                             size='small'>Begrepen</MaterialFactory>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    render = () => {
        return (
            <>
                <div className='onboardingBackground'/>
                {this.renderStep()}
            </>
        )
    };
};

SaveOnboardingView.propTypes = {
    step: PropTypes.string.isRequired,
    tabNextDisabled: PropTypes.bool.isRequired,
};
