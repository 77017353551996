import _ from 'lodash';

import * as dosActions from './dossierActions';
import moduleConstants from '../constants/moduleConstants';

import {getSetting, isNotNull, warn} from 'glob-common-js/lib/utils';
import {fields} from "./fieldMapping";

const customizeField = (field, custom) => _.merge({}, field, custom);

const dossierTypeMapping = {
    phone_electronics: {
        id: "f634fdfa-51cf-4370-ab3b-54b26e99b549",
        name: 'phone_electronics',
        label: "Smartphone",
        icon: "common-icon-mobile-2",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {
            offerte_insurance: {
                label: 'Offerte verzekering',
                action: dosActions.startTenderInsurance,
            }
        },
        fields: [
            fields.purchase_date,
            fields.purchase_value,
            fields.warranty_period,
        ],
    },
    laptop_electronics: {
        id: "cd3b6e2d-7faf-48ea-9c91-d39c9ef042df",
        name: 'laptop_electronics',
        label: "Laptop",
        icon: "common-icon-laptop",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            },
        },
        saveActions: {
            offerte_insurance: {
                label: 'Offerte verzekering',
                action: dosActions.startTenderInsurance,
            },
        },
        fields: [
            fields.purchase_date,
            fields.purchase_value,
            fields.warranty_period,
        ],
    },
    television_electronics: {
        id: "f925ca25-872c-4366-b42d-32ba8d28699a",
        name: 'television_electronics',
        label: "Televisie",
        icon: "common-icon-tv",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {
            offerte_insurance: {
                label: 'Offerte verzekering',
                action: dosActions.startTenderInsurance,
            },
        },
        fields: [
            fields.purchase_date,
            fields.purchase_value,
            fields.warranty_period,
        ],
    },
    music_system_electronics: {
        id: "7b836058-d474-4031-bd8b-4b6801d8d00a",
        name: 'music_system_electronics',
        label: "Muziek apparatuur",
        icon: "common-icon-gramophone",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {
            offerte_insurance: {
                label: 'Offerte verzekering',
                action: dosActions.startTenderInsurance,
            },
        },
        fields: [
            fields.purchase_date,
            fields.purchase_value,
            fields.warranty_period,
        ],
    },
    camera_electronics: {
        id: "7322f281-e367-46f8-9a22-36d4881ae193",
        name: 'camera_electronics',
        label: "Fototoestel",
        icon: "common-icon-camera",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {
            offerte_insurance: {
                label: 'Offerte verzekering',
                action: dosActions.startTenderInsurance,
            },
        },
        fields: [
            fields.purchase_date,
            fields.purchase_value,
            fields.warranty_period,
        ],
    },
    electro_gen: {
        id: "c8b24be8-f4f5-44d7-b8d3-e4a06daf7eeb",
        name: 'electro_gen',
        label: "Elektronica (algemeen)",
        icon: "common-icon-electronics",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {
            offerte_insurance: {
                label: 'Offerte verzekering',
                action: dosActions.startTenderInsurance,
            },
        },
        fields: [
            fields.purchase_date,
            fields.purchase_value,
            fields.warranty_period,
        ],
    },
    car: {
        id: "8f4fc917-c63e-47b1-a72f-9e08a5008f5f",
        name: 'car',
        label: "Auto",
        icon: "common-icon-car",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {
            // show_value_info: {
            //     label: 'Waarde',
            //     action: dosActions.showCarValuePopup,
            // },
            // offerte_car_insurance: {
            //     label: 'Offerte autoverzekering',
            //     action: dosActions.startTenderCarInsurance,
            // },
            // analyze_car_insurance: {
            //     label: 'Analyse autoverzekering',
            //     action: 'analyzeCar'
            // }
        },
        extraDetails: [
            {
                name: 'value',
                label: 'Waarde',
                type: 'PRICE',
            },
            {
                name: 'taxationvalue',
                label: 'Nieuwwaarde',
                type: 'PRICE',
            },
        ],
        fields: [
            fields.licence_plate,
            fields.brand,
            fields.model,
            fields.color,
            fields.body_type,
            // fields.value,
            fields.build,
        ]
    },
    cycle_transport: {
        id: "fcd34c52-455c-469d-b43f-4ada0ec89473",
        name: 'cycle_transport',
        label: "Fiets, e-bike",
        icon: "common-icon-bike",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [
            fields.purchase_date,
            fields.purchase_value,
            fields.warranty_period,
        ],
    },
    moped_transport: {
        id: "77008a9b-6b27-4fba-97e7-fa17ff49fa1b",
        name: 'moped_transport',
        label: "Brommer",
        icon: "common-icon-moped",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [
            fields.purchase_date,
            fields.purchase_value,
            fields.warranty_period,
        ],
    },
    motorbike_transport: {
        id: "47ce03c6-094e-48dc-bb6c-e69607f2b0dc",
        name: 'motorbike_transport',
        label: "Motor",
        config: {
            exclude: false,
            isFinancial: false,
        },
        icon: "common-icon-motor-helmet",
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [
            fields.purchase_date,
            fields.purchase_value,
            fields.warranty_period,
        ],
    },
    boat_transport: {
        id: "e5a8d0b9-6c93-43cc-a3f5-d29a458c467a",
        name: 'boat_transport',
        label: "Boot",
        icon: "common-icon-sailing-boat",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [
            fields.purchase_date,
            fields.purchase_value,
            fields.warranty_period,
        ],
    },
    glasses_care: {
        id: "cc30facd-4963-4687-bce7-f37433adc8af",
        name: 'glasses_care',
        label: "Bril",
        icon: "common-icon-glasses",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [
            fields.purchase_date,
            fields.purchase_value,
            fields.warranty_period,
        ],
    },
    hearing_aid_care: {
        id: "37cdf6c5-f975-4c80-99e0-f34a90402ca9",
        name: 'hearing_aid_care',
        label: "Gehoor apparaat",
        icon: "common-icon-hearing-aid",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [
            fields.purchase_date,
            fields.purchase_value,
            fields.warranty_period,
        ],
    },
    boiler_indoors: {
        id: "7f0b3477-84d7-41a4-8860-825cf93e22c2",
        name: 'boiler_indoors',
        label: "CV-ketel",
        icon: "common-icon-boiler",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [
            fields.purchase_date,
            fields.purchase_value,
            fields.warranty_period,
        ],
    },
    furniture: {
        id: "a2748fce-8180-44e7-8ee6-0dcbeed3bedd",
        name: 'furniture',
        label: "Inrichting, meubilair",
        icon: "common-icon-couche",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [
            fields.purchase_date,
            fields.purchase_value,
            fields.warranty_period,
        ],
    },
    kitchen_appliances: {
        id: "dae0ca24-4c8a-47ab-8430-d29dcd579218",
        name: 'kitchen_appliances',
        label: "Keuken apparatuur",
        icon: "common-icon-kitchen-mixer",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [
            fields.purchase_date,
            fields.purchase_value,
            fields.warranty_period,
        ],
    },
    household_appliances: {
        id: "94eefd9f-1de5-4450-8d64-ffb39b83dbc2",
        name: 'household_appliances',
        label: "Huishoudelijk apparaat",
        icon: "common-icon-vacuum-cleaner",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [
            fields.purchase_date,
            fields.purchase_value,
            fields.warranty_period,
        ],
    },
    caravan_holiday: {
        id: "4521a830-f850-47f8-8e3e-0d8d72e657be",
        name: 'caravan_holiday',
        label: "Caravan, vouwwagen",
        icon: "common-icon-caravan",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [
            fields.purchase_date,
            fields.purchase_value,
            fields.warranty_period,
        ],
    },
    camping_equipment_holiday: {
        id: "476607e8-ef3a-4138-bd12-77c637b03abe",
        name: 'camping_equipment_holiday',
        label: "Kampeer uitrusting",
        icon: "common-icon-camping-equipment",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [
            fields.purchase_date,
            fields.purchase_value,
            fields.warranty_period,
        ],
    },
    tent_holiday: {
        id: "5eb7e348-201a-4b6d-b4ec-433a2cf0f96f",
        name: 'tent_holiday',
        label: "Tent",
        icon: "common-icon-tent",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [
            fields.purchase_date,
            fields.purchase_value,
            fields.warranty_period,
        ],
    },
    sports_gear: {
        id: "91fc0b4e-50c1-49d1-95dd-6957e0f4d80e",
        name: 'sports_gear',
        label: "Sport uitrusting, -apparatuur",
        icon: "common-icon-sport-shirt",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [
            fields.purchase_date,
            fields.purchase_value,
            fields.warranty_period,
        ],
    },
    skis_sport: {
        id: "1aeea615-6b9d-4278-85dc-67efe33f347c",
        name: 'skis_sport',
        label: "Ski's",
        icon: "common-icon-ski",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [
            fields.purchase_date,
            fields.purchase_value,
            fields.warranty_period,
        ],
    },
    sport_clothes_sport: {
        id: "271e5839-0a6d-4844-96fe-fdb605f4a3c3",
        name: 'sport_clothes_sport',
        label: "Sportkleding",
        icon: "common-icon-sport-shirt",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [
            fields.purchase_date,
            fields.purchase_value,
            fields.warranty_period,
        ],
    },
    house: {
        id: "38a431a2-3333-4826-abdd-901e6ff621ef",
        name: 'house',
        label: "Koopwoning",
        icon: "common-icon-home",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            },
            // show_insurance: {
            //     label: 'Opstalverzekering',
            //     action: dosActions.renderOpstalAnalyze,
            // }
        },
        saveActions: {
            show_value_info: {
                label: 'Waarde',
                action: dosActions.showHouseValue,
            },
            // offerte_opstal: {
            //     label: 'Offerte Opstalverzekering',
            //     action: dosActions.startTenderOpstal,
            // },
            // offerte_inboedel: {
            //     label: 'Offerte Inboedelverzekering',
            //     action: dosActions.startTenderInboedel,
            // }
        },
        extraDetails: [
            {
                name: 'street',
                label: 'Straat',
                type: 'STRING',
            },
            {
                name: 'city',
                label: 'Stad',
                type: 'STRING',
            },
        ],
        fields: [
            fields.zipcode,
            fields.house_number,
            fields.house_number_suffix,
        ]
    },
    personal_data: {
        id: "a84f9241-1b77-42c5-85d1-e7b9c6ea47b6",
        name: 'personal_data',
        label: "Persoonlijke documenten",
        icon: "common-icon-personal-info",
        config: {
            exclude: false,
            isFinancial: false,
        },
        withoutName: true,
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
    },
    medical_data: {
        id: "a42dc490-fb3b-4173-9b78-7d09452ef7bf",
        name: 'medical_data',
        label: "Medische zaken",
        icon: "common-icon-medical",
        config: {
            exclude: false,
            isFinancial: true,
            extendFinancial: true,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [
            fields.doctor_gen,
            fields.hospital,
            fields['"health_insurance"'],
        ],
    },
    personal_hobby: {
        id: "317ec99c-0f2c-4208-bc4f-fb7a2263a230",
        name: 'personal_hobby',
        label: "Mijn hobby",
        icon: "common-icon-glue",
        config: {
            exclude: false,
            isFinancial: true,
            extendFinancial: true,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [
            fields.description
        ],
    },
    tax_year: {
        id: "31dfcae5-da39-4f1e-bc42-49c1faf3f54b",
        name: 'tax_year',
        label: 'Belastingjaar',
        icon: "common-icon-tax",
        config: {
            exclude: false,
            isFinancial: true,
            extendFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [
            fields.year,
            fields.start_date,
            fields.monthly_price,
            customizeField(fields.frequency, {value: 'yearly'}),
            customizeField(fields.company, {value: '', exclude: true}),
            fields.pay_day,
        ]
    },
    job: {
        id: "dd117ac3-ff9c-4929-9b11-f431a5d5b040",
        name: 'job',
        label: "Baan",
        icon: "common-icon-work-office",
        config: {
            exclude: false,
            isFinancial: true,
            extendFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [
            fields.start_date,
            fields.end_date,
            customizeField(fields.monthly_price, {label: 'Salaris', adornment: '\u20ac'}),
            customizeField(fields.frequency, {value: 'monthly'}),
            customizeField(fields.company, {label: 'Werkgever'}),
            fields.pay_day,
        ]
    },
    study: {
        id: "e2e9c589-5882-4e8c-ba1a-faae347a2215",
        name: 'study',
        label: "Opleiding",
        icon: "common-icon-education",
        config: {
            exclude: false,
            isFinancial: true,
            extendFinancial: true,
        },
        modules: [moduleConstants.STUDY,],
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [
            fields.studie,
            fields.level,
            fields.profile,
            fields.instelling,
            fields.start_date,
            fields.exp_end_date,
            customizeField(fields.monthly_price, {label: 'Collegegeld'}),
        ],
    },
    health_insurance_care: {
        id: "cdd09f01-016e-47d8-abfd-22cbbc77b4e2",
        name: 'health_insurance_care',
        label: "Zorgverzekering",
        icon: "common-icon-health-insurance",
        config: {
            exclude: false,
            isFinancial: true,
            extendFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {
            // analyze_health_insurance: {
            //     label: 'Analyse Zorgverzekering',
            //     action: dosActions.analyzeHealthInsurance,
            // }
        },
        fields: [
            customizeField(fields.company, {label: 'Verzekeraar'}),
            fields.health_insurance,
            fields.discount_basic,
            fields.health_supplementary,
            fields.discount_extra,
            fields.health_dentist,
            fields.discount_dental,
            fields.health_own_risk,
            fields.start_date,
            fields.end_date,
            fields.monthly_price,
            customizeField(fields.frequency, {value: 'monthly'}),
            fields.pay_day,
        ]
    },
    travel: {
        id: "1b574952-45c8-4f9f-9f24-3dbb83ba1f83",
        name: 'travel',
        label: "Reis",
        icon: "common-icon-travel-insurance",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            },
            travel_contact: {
                label: 'Thuisblijvers',
                action: dosActions.renderTravelContact,
            }
        },
        saveActions: {
            analyze_travel_insurance: {
                label: 'Analyse Reisverzekering',
                action: dosActions.analyzeTravelInsurance,
            },
            offerte_travel_insurance: {
                label: 'Offerte Reisverzekering',
                action: dosActions.startTenderTravelInsurance,
            }
        },
        fields: [
            fields.travel_departure_date,
            fields.travel_return_date,
            fields.travel_transport,
            fields.travel_country_destination,
        ],
    },
    checklist_travel: {
        id: ['accept'].includes(getSetting('mode')) ? 'eb114d5b-c9e6-4888-8cee-87963bf6756b' :
            '72c2315a-79d9-4fa3-b535-07e3dd20e8ab',
        name: 'checklist_travel',
        label: "Reischecklist",
        icon: "common-icon-check",
        config: {
            exclude: true,
            isFinancial: false,
            isAnalysis: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [],
    },
    scooter_transport: {
        id: "9541a4af-9539-4a6d-bb4f-b064222dd257",
        name: 'scooter_transport',
        label: "Scooter",
        icon: "common-icon-moped-front",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [
            fields.purchase_date,
            fields.purchase_value,
            fields.warranty_period,
        ],
    },
    tablet_electronics: {
        id: "43984d56-aec8-4540-bd7e-f233f667947f",
        name: 'tablet_electronics',
        label: "Tablet",
        icon: "common-icon-tablet",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {
            offerte_insurance: {
                label: 'Offerte verzekering',
                action: dosActions.startTenderInsurance,
            },
        },
        fields: [
            fields.purchase_date,
            fields.purchase_value,
            fields.warranty_period,
        ],
    },
    snowboard_sport: {
        id: "61889ad1-9aea-4f66-bce9-e0137278b3ec",
        name: 'snowboard_sport',
        label: "Snowboard",
        icon: "common-icon-ski",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [
            fields.purchase_date,
            fields.purchase_value,
            fields.warranty_period,
        ],
    },
    sport_shoes_sport: {
        id: "624b54f9-7355-4ad1-a748-876c9f1bdd12",
        name: 'sport_shoes_sport',
        label: "Sportschoenen",
        icon: "common-icon-sport-shoes",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [
            fields.purchase_date,
            fields.purchase_value,
            fields.warranty_period,
        ],
    },
    misc_gen: {
        id: "26856044-f631-47bc-9b5b-963f8d58233c",
        name: 'misc_gen',
        label: "Overige",
        icon: "common-icon-pasport",
        creatingIcon: "common-icon-plus",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [],
    },
    misc_family: {
        id: "033bbe54-80f3-48f4-b8f2-a5acbc357e2b",
        name: 'misc_family',
        label: "Overige gezin ",
        icon: "common-icon-pasport",
        creatingIcon: "common-icon-plus",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [],
    },
    misc_care: {
        id: "c51871d3-9bd7-4858-8842-ef9daddc6bcc",
        name: 'misc_care',
        label: "Overige gezondheid",
        icon: "common-icon-pasport",
        creatingIcon: "common-icon-plus",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [],
    },
    miscellaneous_care: {
        id: "75fc7878-f22e-423e-bb2a-3c9e744fed27",
        name: 'miscellaneous_care',
        label: "Mijn wilsverklaring",
        icon: "common-icon-document-2",
        creatingIcon: "common-icon-document-2",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [],
    },
    misc_house: {
        id: "5522ee2b-dd18-4527-bd4e-f4ed797aa86c",
        name: 'misc_house',
        label: "Overige woning",
        icon: "common-icon-home",
        creatingIcon: "common-icon-plus",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [],
    },
    misc_contents: {
        id: "a2a0c6b3-8035-437e-9dbf-b8c81b9d627d",
        name: 'misc_contents',
        label: "Overige inboedel",
        icon: "common-icon-home",
        creatingIcon: "common-icon-plus",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [],
    },
    misc_energy: {
        id: "e19afbcc-c8dd-4a1f-8c70-00daad369c09",
        name: 'misc_energy',
        label: "Overige energie en RTV-internet",
        icon: "common-icon-home",
        creatingIcon: "common-icon-plus",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [],
    },
    misc_transport_mean: {
        id: "ea96aa6b-f71b-4224-bc08-b5515382835e",
        name: 'misc_transport_mean',
        label: "Overige vervoersmiddel",
        icon: "common-icon-car",
        creatingIcon: "common-icon-plus",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [],
    },
    misc_public_transport: {
        id: "49d15a7e-2b8d-4a48-a153-e1993f115863",
        name: 'misc_public_transport',
        label: "Openbaar vervoer",
        icon: "common-icon-car",
        creatingIcon: "common-icon-plus",
        config: {
            exclude: false,
            isFinancial: false,
        },
        withoutName: true,
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [],
    },
    misc_travel: {
        id: "2326a314-e8aa-4ab0-beaf-92c0fcc692a9",
        name: 'misc_travel',
        label: "Overige op reis",
        icon: "common-icon-car",
        creatingIcon: "common-icon-plus",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [],
    },
    misc_travel_vacation: {
        id: "9be89973-258b-4cf2-b192-e19070bd204d",
        name: 'misc_travel_vacation',
        label: "Overige op reis, vakantie",
        icon: "common-icon-travel-beach",
        creatingIcon: "common-icon-plus",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [],
    },
    misc_sport_games_hobby: {
        id: "9fc84d30-648c-4e8f-83e4-2c383aaa7e3a",
        name: 'misc_sport_games_hobby',
        label: "Overige sport, spel en hobby",
        icon: "common-icon-travel-beach",
        creatingIcon: "common-icon-plus",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [],
    },
    misc_work: {
        id: "abef85b5-9945-4ca2-8389-11b3abec208a",
        name: 'misc_work',
        label: "Overige werk",
        icon: "common-icon-suitcase",
        creatingIcon: "common-icon-plus",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [],
    },
    misc_study: {
        id: "e553f532-eff3-4dba-9321-d6a01a2ee75a",
        name: 'misc_study',
        label: "Overige studie",
        icon: "common-icon-suitcase",
        creatingIcon: "common-icon-plus",
        config: {
            exclude: false,
            isFinancial: true,
            extendFinancial: true,
        },
        modules: [moduleConstants.STUDY,],
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [],
    },
    misc_company: {
        id: "c64c2fec-7248-4893-8a13-5dab7f462c6b",
        name: 'misc_company',
        label: "Overige bedrijf ZZP",
        icon: "common-icon-suitcase",
        creatingIcon: "common-icon-plus",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [],
    },
    misc_taxes: {
        id: "291bd897-b92c-4ac1-b093-f3f3311be68c",
        name: 'misc_taxes',
        label: "Overige belastingen",
        icon: "common-icon-banking-spending",
        creatingIcon: "common-icon-plus",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [],
    },
    misc_insurance: {
        id: "a7f2235e-3b3f-4e70-a1b9-9ee473571c68",
        name: 'misc_insurance',
        label: "Verzekeringen",
        icon: "common-icon-banking-spending",
        creatingIcon: "common-icon-banking-spending",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [],
    },
    misc_fixed_costs: {
        id: "83ef7a80-4764-42bb-90df-b720ef1d8d2e",
        name: 'misc_fixed_costs',
        label: "Overige vaste lasten, abonnementen",
        icon: "common-icon-banking-spending",
        creatingIcon: "common-icon-plus",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [],
    },
    misc_savings: {
        id: "6aa4f629-7db8-4f1a-a980-30137d7f9444",
        name: 'misc_savings',
        label: "Pensioen",
        icon: "common-icon-banking-spending",
        creatingIcon: "common-icon-banking-spending",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [],
    },
    misc_purchases: {
        id: "4197de7e-c2ee-456b-8ca6-779ed7df9558",
        name: 'misc_purchases',
        label: "Overige aankopen en bezittingen",
        icon: "common-icon-banking-spending",
        creatingIcon: "common-icon-plus",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [],
    },
    health_insurance_analysis: {
        id: 'bf354eb2-3285-4df8-81a7-27a2374b4f04',
        name: 'health_insurance_analysis',
        label: "Analyse zorgverzekering",
        icon: "common-icon-health-insurance",
        creatingIcon: "common-icon-health-insurance",
        config: {
            exclude: true,
            isFinancial: false,
            isAnalysis: true,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [],
    },
    intership: {
        id: 'fc6fedf3-1176-49cd-a554-b40186628616',
        name: 'intership',
        label: "Stage",
        icon: "common-icon-education",
        creatingIcon: "common-icon-education",
        config: {
            exclude: false,
            isFinancial: true,
            extendFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [
            fields.start_date,
            customizeField(fields.end_date, {label: 'Verwachte einddatum'}),
            customizeField(fields.monthly_price, {label: 'Stagevergoeding'}),
            customizeField(fields.frequency, {value: 'monthly'}),
            customizeField(fields.company, {label: 'Stagebedrijf'}),
            fields.pay_day,
        ]
    },
    secondary_activity: {
        id: '2e13e804-c7a4-4592-b354-05474dffc046',
        name: 'secondary_activity',
        label: "Nevenactiviteit",
        icon: "common-icon-education",
        creatingIcon: "common-icon-education",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [
            fields.remark_description,
            fields.start_date,
        ]
    },
    passes: {
        id: 'bb552f4e-02a5-4896-94a8-97e25d7887c4',
        name: 'passes',
        label: "Pasjes",
        icon: "common-icon-banking-spending",
        creatingIcon: "common-icon-banking-spending",
        config: {
            exclude: true,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [],
    },
    liability_insurance: {
        id: '91d8ffc2-c459-41d6-9389-989ae87290f2',
        name: 'liability_insurance',
        label: "Aansprakelijkheids- verzekering",
        icon: "common-icon-document-2",
        creatingIcon: "common-icon-document-2",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [
            fields.start_date,
            fields.end_date,
            fields.monthly_price,
        ]
    },
    subscriptions: {
        id: '79af6d09-dfed-43b3-9d58-1ca0e05b1c12',
        name: 'subscriptions',
        label: "Abonnementen",
        icon: "common-icon-document-2",
        creatingIcon: "common-icon-document-2",
        config: {
            exclude: false,
            isFinancial: true,
            extendFinancial: true,
        },
        withoutName: true,
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [
            fields.type,
            fields.start_date,
            fields.end_date,
            fields.monthly_price,
            customizeField(fields.frequency, {value: 'monthly'}),
            customizeField(fields.company, {value: '', exclude: true}),
            fields.pay_day,
        ]
    },
    cancel_insurance: {
        id: '04b3408f-21b0-4a76-932e-354d3b4780f2',
        name: 'cancel_insurance',
        label: "Annuleringsverzekering",
        icon: "common-icon-document-2",
        creatingIcon: "common-icon-document-2",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [
            fields.start_date,
            fields.end_date,
            fields.monthly_price,
        ]
    },
    car_insurance: {
        id: 'ee097256-af97-47d2-8456-bdc2bb76c9fb',
        name: 'car_insurance',
        label: "Autoverzekering",
        icon: "common-icon-banking-spending",
        creatingIcon: "common-icon-banking-spending",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [
            fields.start_date,
            fields.end_date,
            fields.monthly_price,
        ]
    },
    pay_pass: {
        id: '183452b9-a1a8-4c2d-a275-efceaa8a93db',
        name: 'pay_pass',
        label: "Betaalpas",
        icon: "common-icon-banking-spending",
        creatingIcon: "common-icon-banking-spending",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [],
    },
    energy: {
        id: '37b4542e-f5ad-4784-9c97-c43880bd33f9',
        name: 'energy',
        label: "Energie",
        icon: "common-icon-document-2",
        creatingIcon: "common-icon-document-2",
        config: {
            exclude: false,
            isFinancial: true,
            extendFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [
            fields.start_date,
            fields.end_date,
            customizeField(fields.company, {label: 'Leverancier'}),
            fields.monthly_price,
            fields.frequency,
            fields.pay_day,
        ],
    },
    contents: {
        id: 'a6a96b00-15cf-4983-8508-e82ff2085f0e',
        name: 'contents',
        label: "Inboedel",
        icon: "common-icon-home",
        creatingIcon: "common-icon-home",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [],
    },
    contents_insurance: {
        id: 'b7aca723-2500-4d94-898a-9655d3ca2e8a',
        name: 'contents_insurance',
        label: "Inboedelverzekering",
        icon: "common-icon-banking-spending",
        creatingIcon: "common-icon-banking-spending",
        config: {
            exclude: false,
            isFinancial: true,
            extendFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [
            fields.start_date,
            fields.end_date,
            fields.monthly_price,
            customizeField(fields.frequency, {value: 'monthly'}),
            customizeField(fields.company, {label: 'Verzekeraar'}),
            fields.pay_day,
        ]
    },
    camping: {
        id: '4ad1fdae-92f3-4a31-bff8-3990771ca6f3',
        name: 'camping',
        label: "Kamperen",
        icon: "common-icon-travel-beach",
        creatingIcon: "common-icon-travel-beach",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [
            fields.travel_departure_date,
            fields.travel_return_date,
            fields.travel_transport,
            fields.travel_country_destination,
        ]
    },
    travel_insurance: {
        id: 'cbce3c3f-8c62-448e-a481-ec09fe9d3120',
        name: 'travel_insurance',
        label: "Reisverzekering",
        icon: "common-icon-travel-insurance",
        creatingIcon: "common-icon-travel-insurance",
        config: {
            exclude: false,
            isFinancial: true,
            extendFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [
            fields.start_date,
            fields.end_date,
            fields.monthly_price,
            fields.frequency,
            customizeField(fields.company, {label: 'Verzekeraar'}),
            fields.pay_day,
        ]
    },
    sport: {
        id: 'd6611919-623d-4f96-a1f4-db0d4b39988d',
        name: 'sport',
        label: "Sport",
        icon: "common-icon-sport-shoes",
        creatingIcon: "common-icon-sport-shoes",
        config: {
            exclude: false,
            isFinancial: true,
            extendFinancial: true,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [
            customizeField(fields.company, {label: 'Lid bij'}),
            fields.start_date,
            fields.end_date,
            fields.frequency,
            fields.monthly_price,
        ],
    },
    water: {
        id: 'e8e344a5-1f8f-4f88-bdb3-106bfa1c250a',
        name: 'water',
        label: "Water",
        icon: "common-icon-document-2",
        creatingIcon: "common-icon-document-2",
        config: {
            exclude: false,
            isFinancial: true,
            extendFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [
            fields.start_date,
            fields.end_date,
            fields.monthly_price,
            fields.frequency,
            customizeField(fields.company, {label: 'Leverancier'}),
            fields.pay_day,
        ]
    },
    rental_house: {
        id: '412dce7c-6cb8-401e-8e40-1f197cdcb3eb',
        name: 'rental_house',
        label: "Huurwoning",
        icon: "common-icon-home",
        creatingIcon: "common-icon-home",
        config: {
            exclude: false,
            isFinancial: true,
            extendFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [
            fields.street,
            fields.house_number,
            fields.house_number_suffix,
            fields.zipcode,
            fields.city,
            fields.start_date,
            fields.end_date,
            customizeField(fields.monthly_price, {label: 'Huur'}),
            customizeField(fields.frequency, {value: 'monthly'}),
            customizeField(fields.company, {label: 'Verhuurder'}),
            fields.pay_day,
        ],
    },
    general_practitioner: {
        id: 'c5199434-3d69-4246-96b4-7dc0f0b14628',
        name: 'general_practitioner',
        label: "Huisarts",
        icon: "common-icon-health-insurance",
        creatingIcon: "common-icon-health-insurance",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [
            fields.practice,
        ],
    },
    dentist: {
        id: '72973446-b61a-459e-b971-b57125e4c722',
        name: 'dentist',
        label: "Tandarts",
        icon: "common-icon-dentist",
        creatingIcon: "common-icon-dentist",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [
            fields.practice,
        ],
    },
    specialist: {
        id: 'a84316d1-c5ad-405e-9150-84287e4e69b5',
        name: 'specialist',
        label: "Specialist",
        icon: "common-icon-health-insurance",
        creatingIcon: "common-icon-health-insurance",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [
            fields.practice,
        ],
    },
    medical_tools: {
        id: '5a594830-051b-4bdd-8de4-92f56f23b974',
        name: 'medical_tools',
        label: "Medische hulpmiddelen",
        icon: "common-icon-health-insurance",
        creatingIcon: "common-icon-health-insurance",
        config: {
            exclude: false,
            isFinancial: false,
        },
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
        fields: [],
    },
    financial_overview: {
        id: 'a80e854d-ca16-4435-850c-282eeadcf24a',
        name: 'financial_overview',
        label: 'Financieel overzicht',
        icon: null,
        creatingIcon: null,
        config: {
            exclude: true,
            isFinancial: true,
            extendFinancial: true,
        },
        fields: [
            fields.start_date,
            fields.end_date,
            fields.monthly_price,
            fields.frequency,
            fields.company,
            fields.pay_day,
        ],
        keepActions: {
            show_details: {
                label: 'Gegevens',
                action: dosActions.renderDossierDetails,
            },
            show_docs: {
                label: 'Documenten',
                action: dosActions.renderDossierDocuments,
            },
            show_notes: {
                label: 'Notities',
                action: dosActions.renderNotes,
            },
            show_contacts: {
                label: 'Contacten',
                action: dosActions.renderContacts,
            }
        },
        saveActions: {},
    }
};

const getDefaultType = () => {
    let type = Object.assign({}, dossierTypeMapping['misc_gen']);
    type.icon = 'common-icon-world';
    return type;
};

/**
 * Get an entry in the dossierTypeMapping, return the complete mapping when no name is given.
 * @param name Optional
 */
const getDossierType = name => {
    if (isNotNull(name)) {
        if(name === 'default') return getDefaultType();
        let value = dossierTypeMapping[name];
        if (isNotNull(value)) {
            return value;
        }
        warn('DossierTypeMapping does not contain an entry with name \'' + name + '\'.');
        // Returning misc_gen to support older types which we don't use anymore, but may still exist in accounts.
        return getDefaultType();
    } else {
        return dossierTypeMapping;
    }
};

export const getById = id => {
    let keys = Object.keys(getDossierType());
    //log('Looking in keys', keys);
    for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        let entry = getDossierType(key);
        if (entry.id === id) {
            return entry;
        }
    }
    warn('DossierTypeMapping does not contain an entry with id \'' + id + '\'.');
    // Returning misc_gen to support older types which we don't use anymore, but may still exist in accounts.
    return getDefaultType();
};

export const getByLabel = label => {
    let keys = Object.keys(getDossierType());
    for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        let entry = getDossierType(key);
        if (entry.label === label) {
            return entry;
        }
    }
    warn('DossierTypeMapping does not contain an entry with label \'' + label + '\'.');
    // Returning misc_gen to support older types which we don't use anymore, but may still exist in accounts.
    return getDefaultType();
};

export const getKeyByLabel = label => {
    let keys = Object.keys(getDossierType());
    for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        if (getDossierType(key).label === label) {
            return key;
        }
    }
    warn('DossierTypeMapping does not contain an entry with label \'' + label + '\'.');
    // Returning misc_gen to support older types which we don't use anymore, but may still exist in accounts.
    return 'misc_gen';
};

export const getKeyById = id => {
    let keys = Object.keys(getDossierType());
    for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        if (getDossierType(key).id === id) return key;
    }
    warn('DossierTypeMapping does not contain an entry with id \'' + id + '\'.');
    // Returning misc_gen to support older types which we don't use anymore, but may still exist in accounts.
    return 'misc_gen';
};

export default getDossierType;
