export const JSSAccountAccess_v2 = theme => ({
    paperRoot: {
        height: '20rem',
        marginTop: '.1rem',
    },
    bodyRoot: {
        maxHeight: '20rem',
        overflowY: 'auto',
    },
    hidden: {
        display: 'none',
    }
});