import './style/addGrant_v2.scss';

import React from 'react';
import PropTypes from 'prop-types';

import {isFunction} from 'glob-common-js/lib/utils';

import PopupBuilder from "../../../misc/popupBuilder/popupBuilder";
import MaterialFactory from "../../../material/materialFactory";
import {materialTypes} from "../../../material/materialTypes";
import GenericLoader from "../../../misc/genericLoader";

export default class AddGrant extends React.Component {
    constructor(props) {
        super(props);
    }

    onChangeMail = (evt) => {
        const {onChangeMail} = this.props;
        if (isFunction(onChangeMail))
            onChangeMail(evt);
    };

    onDelete = () => {
        const {onDelete} = this.props;
        if (isFunction(onDelete))
            onDelete();
    };

    onChangeRight = name => evt => {
        if (name !== 'read')
            this.props.onChangeRight(name)(evt);
    };

    renderHeader = () => {
        const {actionText, onClose} = this.props;
        return (
            <>
                <span className='common-icon-cross grantCloseButton hidden'/>
                <label className='addGrantTitle'>{actionText}</label>
                <span className='common-icon-cross grantCloseButton' onClick={onClose}/>
            </>
        );
    };

    renderBody = () => {
        const {email, error, onResetError, fieldsDisabled, mailDisabled, loading} = this.props;
        return (
            <>
                <GenericLoader active={loading}/>
                <MaterialFactory componentType={materialTypes.TEXT} type='email' value={email} fullWidth
                                 onChange={this.onChangeMail} label='E-mailadres' helperText={error}
                                 error={isNotNull(error)} onFocus={onResetError}
                                 disabled={fieldsDisabled || mailDisabled}/>
                <label className='rightsLabel'>Rechten verlenen</label>
                {this.renderRightBox('read', 'Lezen')}
                {this.renderRightBox('manage', 'Beheer')}
                {this.renderRightBox('share', 'Delen')}
            </>
        )
    };

    renderRightBox = (name, label) => {
        const checked = this.props[name];
        const {fieldsDisabled, onChangeRight} = this.props;
        return <MaterialFactory componentType={materialTypes.CHECKBOX} checked={checked}
                                onChange={this.onChangeRight(name)} label={label} disabled={fieldsDisabled}/>
    };

    renderFooter = () => {
        const {email, onSubmit, actionText, includeDelete, fieldsDisabled, hasChanged} = this.props;
        return (
            <>
                {includeDelete &&
                <MaterialFactory componentType={materialTypes.RAISED_BUTTON} className='grantAction deleteButton'
                                 onClick={this.onDelete}>Machtiging intrekken</MaterialFactory>}
                {!fieldsDisabled &&
                <MaterialFactory componentType={materialTypes.RAISED_BUTTON} className='grantAction' onClick={onSubmit}
                                 disabled={isNull(email) || !hasChanged}>{actionText}</MaterialFactory>}
            </>
        );
    };

    render = () => {
        const {active, onClose} = this.props;
        return <PopupBuilder open={active} onClose={onClose} headerClass='addGrantHeader' bodyClass='addGrantBody'
                             footerClass='addGrantFooter' content={{
            header: this.renderHeader(),
            body: this.renderBody(),
            footer: this.renderFooter(),
        }}/>
    }
}

AddGrant.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onChangeRight: PropTypes.func.isRequired,
    onResetError: PropTypes.func.isRequired,
    onChangeMail: PropTypes.func,
    onDelete: PropTypes.func,
    email: PropTypes.string.isRequired,
    read: PropTypes.bool.isRequired,
    manage: PropTypes.bool.isRequired,
    share: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    hasChanged: PropTypes.bool,
    fieldsDisabled: PropTypes.bool,
    mailDisabled: PropTypes.bool,
    includeDelete: PropTypes.bool,
    actionText: PropTypes.string.isRequired,
    error: PropTypes.string,
};

AddGrant.defaultProps = {
    fieldsDisabled: false,
    includeDelete: false,
    mailDisabled: false,
    hasChanged: true,
};