import './style/dashboardInfoContainer.scss';

import React from 'react';
import PropTypes from 'prop-types';

export default class DashboardInfoContainer extends React.Component {
    constructor(props) {
        super(props);
    }

    render = () => {
        const iconClass = 'common-icon-info infoIcon ' + this.props.iconClass;
        const messageClass = 'messageContainer' + (this.props.popLeft ? ' left' : '') + ' ' + this.props.messageClass;
        const containerClass = 'dashboardInfoContainer ' + this.props.containerClass;
        return (
            <div className={containerClass}>
                <span className={iconClass} onClick={this.props.onClick}/>
                <div className={messageClass}>
                    <p className='infoMessage'>{this.props.infoText}</p>
                </div>
            </div>
        )
    };
}

const dummyFunction = () => {
    // Empty function
};

DashboardInfoContainer.propTypes = {
    onClick: PropTypes.func,
    infoText: PropTypes.string.isRequired,
    popLeft: PropTypes.bool,
    iconClass: PropTypes.string,
    messageClass: PropTypes.string,
    containerClass: PropTypes.string,
};

DashboardInfoContainer.defaultProps = {
    onClick: dummyFunction,
    popLeft: false,
    iconClass: '',
    messageClass: '',
    containerClass: '',
};