import React from 'react';
import {bindActionCreators} from 'redux';
import * as actions from '../../../../../redux/actions';
import {connect} from 'react-redux';

import {generateUUID} from 'glob-common-js/lib/utils';

import StateComponent from "../../../../misc/stateComponent";
import AddDossierPopup from "./addDossierPopup";
import {navigate} from "../../../../../misc/utils";
import getDossierType from "../../../../../common/data/mappings/dossierTypeMapping";
import {
    addCustomEventListener,
    dispatchCustomEvent,
    removeCustomEventListener
} from "../../../../../misc/eventDispatcher";
import {sendGaEvent} from "../../../../../common/js/ga";
import {fields} from "../../../../../common/data/mappings/fieldMapping";
import Dossier from "../../../../../models/dossier";

export class AddDossierPopupController extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            dossierType: {
                label: '',
                icon: '',
            },
            fields: {},
            errors: {},
            submitted: false,
            submitting: false,
            dossierId: null,
        };
        this.dossierTypeName = null;
    }

    onChange = (name, value) => {
        const fields = Object.assign({}, this.state.fields);
        fields[name].value = value;
        this.changeState({
            fields,
        });
    };

    onSubmit = () => {
        const fields = this.state.fields;
        if (this.validateFields(fields)) {
            this.changeState({submitting: true});
            const dossierType = getDossierType(this.dossierTypeName);
            const {values, labelAValues} = this.prepareSubmitValues(fields);
            const dossier = new Dossier({
                name: values.name,
                fields,
                type: dossierType,
                external_data: isNotNull(values) ? {
                    values: [values],
                } : {},
                values: isNotNull(labelAValues) ? labelAValues : {},
            });
            dossier.save(this.submitCallback, this.onError);
        }
    };

    onClose = () => {
        // TODO GA?
        this.changeState({
            active: false,
            fields: {},
            errors: {},
            dossierType: {label: '', icon: ''},
            submitted: false
        });
    };

    onClickDossierDetails = () => {
        const {dossierId} = this.state;
        this.onClose();
        if (isNotNull(dossierId)) {
            this.props.actions.setDossier(dossierId);
            navigate('/dossier-details');
        }
    };

    onError = () => {
        this.changeState({submitting: false, submitted: false});
    };

    submitCallback = (dossier) => {
        const dossierType = dossier.type;
        let category = this.props.moduleState.selectedModule;
        let categoryLabel = isNotNull(category) ? getDossierType(category).label : 'Geen module bekend';
        sendGaEvent(categoryLabel, 'dossier toegevoegd', dossierType.label);

        this.changeState({submitting: false, submitted: true, dossierId: dossier.id});

        if (dossierType.config.isFinancial) {
            this.props.actions.addFinancialDossier(dossier);
        }

        const documentToLink = this.props.documentState.documentToLink;
        if (isNotNull(documentToLink)) {
            this.linkDocument(documentToLink, dossier);
        } else dispatchCustomEvent('updatePlatform');
    };

    linkDocument = (documentToLink, dossier) => {
        if (isNotNull(documentToLink)) {
            documentToLink.addDossier(dossier);
            documentToLink.update(() => {
                this.props.actions.resetDocumentToLink();
                dispatchCustomEvent('updatePlatform');
            });
        }
    };

    validateFields = fields => {
        const errors = this.state.errors;
        let containsErrors = false;
        forEach(Object.values(fields), field => {
            if ((field.required || field.required === 1) && isNull(field.value)) {
                errors[field.name] = 'Vul dit veld in';
                containsErrors = true;
            }
        });
        this.changeState({errors});
        return !containsErrors;
    };

    prepareSubmitValues = (fields) => {
        let values = {
            id: generateUUID(),
        };
        forEach(Object.values(fields), field => {
            values[field.name] = field.value;
        });
        let labelAValues = Object.assign({}, values);
        delete labelAValues.id;
        return {values, labelAValues};
    };

    prepareValues = (activating = false) => {
        const dossierType = getDossierType(this.dossierTypeName), typeFields = dossierType.fields,
            preparedFields = {}, errors = {name: null};
        preparedFields.name = Object.assign({}, fields.name, {value: '', exclude: false});
        errors.name = null;
        if (isNotNull(typeFields))
            for (let field of typeFields) {
                if (field.editable === false)
                    continue;
                const {name, label, type, options, required, exclude} = field;
                errors[name] = null;
                preparedFields[name] = {
                    name,
                    label,
                    type,
                    required,
                    options,
                    value: ['DATE', 'DATETIME'].includes(type) ? null : '',
                    exclude: exclude || false,
                }
            }
        this.changeState({fields: preparedFields, errors, active: activating ? true : this.state.active});
    };

    activate = (dossierTypeName) => {
        this.dossierTypeName = dossierTypeName;
        this.prepareValues(true);
        const {label, icon} = getDossierType(dossierTypeName);
        this.changeState({
            dossierType: {
                label,
                icon,
            }
        });
    };

    escapeListener = evt => {
        const ESCAPE_CODE = 27;
        if (evt.keyCode === ESCAPE_CODE) {
            this.onClose();
        }
    };

    addEscapeListener = () => {
        window.addEventListener('keydown', this.escapeListener);
    };

    removeEscapeListener = () => {
        window.removeEventListener('keydown', this.escapeListener);
    };

    componentDidMount = () => {
        this.mount();
        addCustomEventListener('addDossier', this.activate);
    };

    componentDidUpdate = (prevProps, prevState) => {
        const {active} = this.state, prevActive = prevState.active;
        if (!prevActive && active) this.addEscapeListener();
        else if (prevActive && !active) this.removeEscapeListener();
    };

    componentWillUnmount = () => {
        this.unMount();
        this.removeEscapeListener();
        removeCustomEventListener('addDossier', this.activate);
    };

    render = () => {
        const {active, dossierType, fields, errors, submitted, submitting, dossierId} = this.state;
        return (
            <AddDossierPopup onChange={this.onChange} onSubmit={this.onSubmit} onClose={this.onClose}
                             dossierId={dossierId} onClickDossierDetails={this.onClickDossierDetails}
                             submitted={submitted} dossierType={dossierType} fields={Object.values(fields)}
                             errors={errors} submitting={submitting} active={active}/>
        );
    }
}

const mapStateToProps = state => ({
    moduleState: state.module,
    documentState: state.document,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddDossierPopupController);