import properties from './properties';
import * as Cookies from 'js-cookie';
import {getSetting, debug, setCookie, getCookie, removeCookie} from 'glob-common-js/lib/utils';
import errors from 'glob-common-js/lib/errors';
import request from 'glob-common-js/lib/request';

const urls = properties.defaultProperties.URLS;
// Response success status
const STATUS_SUCCESS = 'success';
let jwtToken = null;

export const setJwt = (token, preserveOnNull = false) => {
    console.log('SETTING JWT:', {token});
    jwtToken = token;
    if (isNotNull(token))
        Cookies.set('JWT', token, {sameSite: 'strict', secure: true, expires: 1,});
    // setCookie('JWT', token);
    else if (!preserveOnNull) destroyCookies();
    saveJwtToStorage(token, preserveOnNull);
};

const destroyCookies = () => {
    removeCookie('JWT', {
        domain: '.bydehand.com',
        path: '/'
    });
    removeCookie('JWT');
};

const getJwt = () => {
    if (isNotNull(jwtToken)) return jwtToken;
    else return getJwtFromStorage();
};

const parseJwt = token => {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
};

export const getParsedJwt = () => {
    const token = getJwtToken();
    return isNotNull(token) ? parseJwt(token) : null;
};

export const getJwtToken = () => {
    const jsToken = getJwt();
    return isNotNull(jsToken) ? jsToken : getCookie('JWT');
};

const saveJwtToStorage = (token, preserveOnNull = false) => {
    if (isNull(token) && !preserveOnNull) removeJwtFromStorage();
    else if (isNotNull(token)) {
        debug('Saving jwt to localstorage');
        try {
            if (window.localStorage) {
                const storage = window.localStorage;
                storage.setItem('JWT', JSON.stringify(token));
            }
        } catch {
            // Localstorage not supported
        }
    }
};

const removeJwtFromStorage = () => {
    try {
        if (window.localStorage) {
            debug('Removing jwt from localstorage');
            window.localStorage.removeItem('JWT');
        }
    } catch {
        // Localstorage not supported
    }
};

const getJwtFromStorage = () => {
    try {
        if (window.localStorage) {
            return JSON.parse(window.localStorage.getItem('JWT'));
        }
        return null;
    } catch {
        return null;
    }
};

/**
 * Upload a file to the platform
 * @param params Object containing the file information.
 */
export const uploadFile = (params) => {
    testRequestParams(params, ['file']);
    if (isNotNull(params.fileID)) {
        getFileTypeID(params);
    } else {
        let data;
        data = new FormData();
        data.append('file', params.file);
        const requestConfig = createRequestConfig({
            url: urls.UPLOAD_FILE,
            method: request.POST,
            data
        });
        sendRequest(requestConfig, function (response) {
            if (response.data.status === STATUS_SUCCESS) {
                params.fileID = response.data.file_id;
                getFileTypeID(params);
            }
        });
    }
};

const getFileTypeID = params => {
    testRequestParams(params, ['fileType']);
    const requestConfig = createRequestConfig({url: urls.FILE_TYPE_ID});
    sendRequest(requestConfig, function (response) {
        const docType = params.fileType;
        const fileTypes = response.data;
        for (let i in fileTypes) {
            if (fileTypes.hasOwnProperty(i)) {
                let fileType = fileTypes[i];
                if (fileType.label === docType) {
                    params.fileTypeID = fileType.id;
                    params.fileTypeName = fileType.name;
                    params.fileTypeLabel = fileType.label;
                    createDocument(params);
                    return;
                }
            }
        }
    })
};

const createDocument = params => {
    testRequestParams(params, ['label', 'callback']);
    const document = {
        vault: {
            label: params.label,
            file_type_id: params.fileTypeID,
            new_file_id: params.fileID,
            fields: [],
            belongings: params.belongings || [],
            families: []
        }
    };
    const requestConfig = createRequestConfig({
        url: urls.VAULT,
        method: request.POST,
        data: document
    });

    sendRequest(requestConfig, function (response) {
        if (response.data.status === STATUS_SUCCESS) {
            params.callback(params);
        }
    });
};

export const API_VERSIONS = {LABELA_1: 'v1', LABELA_2: 'v2', DAISY: 3};

/**
 * Create the request base url depending on apiVersion.
 */
const createRequestUrl = (options) => {
    const apiVersion = options.apiVersion || API_VERSIONS.LABELA_1; // Defaults to 1 (v1)
    if (apiVersion === API_VERSIONS.DAISY) {
        return getSetting('backendUrl') + '/v1';
    }
    return getSetting('platformUrl') + (options.withoutApi ? '' : `/${apiVersion}`);
};

/**
 * Test if the given params Object contains a value for all given field names.
 * @param params The object to test
 * @param names Required field names in the params object.
 */
const testRequestParams = (params, names) => {
    for (let i = 0; i < names.length; i++) {
        if (!params.hasOwnProperty(names[i]) || isNull(params[names[i]])) {
            errors.requestError('Params do not contain all required fields:\nParams: '
                + JSON.stringify(params) + '\nRequired fields: ' + JSON.stringify(names));
            return false;
        }
    }
    return true;
};

/**
 * Create the required axios request config. Defaults to method GET and data null.
 * @param options
 */
export const createRequestConfig = (options) => {
    if (isNotNull(options) && isNotNull(options.url)) {
        options = includeGrantSignal(options);
        return includeAuthHeader({
            baseURL: createRequestUrl(options || 1),
            url: options.url,
            method: options.method || request.GET,
            data: options.data || null,
            withoutCredentials: isNotNull(options.withoutCredentials) ? options.withoutCredentials : false,
            ...options,
        });
    } else {
        errors.requestError('RequestConfig options must contain an url.');
    }
};

/**
 * Include the ByDeHand authorization header to the request config, if present.
 * @param config
 */
const includeAuthHeader = config => {
    if (isNull(config.headers)) {
        config.headers = {};
    }
    const jwt = getJwtToken();
    if (isNotNull(jwt)) {
        config.headers['Authorization'] = 'JWT ' + jwt;
    }
    if (isNotNull(config.customHeaders)) {
        let headers = config.customHeaders;
        let keys = Object.keys(headers);
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            if (!config.headers.hasOwnProperty(key))
                config.headers[key] = headers[key];
        }
    }
    return config;
};

/**
 * Temporary workaround to protect Daisy endpoints, remove when safely implemented in backend
 * @param options
 * @returns {*}
 */
const includeGrantSignal = (options) => {
    const decodedJwt = getParsedJwt();
    if (options.apiVersion === 3) {
        options.params = Object.assign({}, options.params, {
            isGranted: isNotNull(decodedJwt) && decodedJwt.hasOwnProperty('grant_id') ? decodedJwt.grant_id : null,
        });
    }
    return options;
};

const sendRequest = (requestConfig, callback) => {
    requestConfig.onErrorCallback = (error) => {
        console.log(error);
    };
    request.sendRequest(requestConfig, callback);
};