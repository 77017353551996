import './style/checklistDesktop.scss';

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ProgressBar from 'progressbar.js';
import {AddAlarm, InfoOutlined} from '@material-ui/icons';

import StateComponent from "../../../misc/stateComponent";
import MaterialFactory from "../../../material/materialFactory";
import {materialTypes} from "../../../material/materialTypes";
import ExplanationFrame from "../../../document/explanationFrame";
import CustomScrollbar from "../../../misc/customScrollbar";
import BdhInput from "../../../customControls/input/bdhInput";
import BdhSelectController from "../../../customControls/select/bdhSelectController";

export default class ChecklistDesktopView extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            selectedCategory: this.getFirstCategoryName(),
        };
        this.progressCircles = {};
    }

    onClickCategory = name => () => {
        // TODO GA
        this.changeState({selectedCategory: name});
    };

    onChangeToggle = name => evt => {
        this.props.onChangeBoxValue(name)(evt.target.checked);
    };

    onChangeSelect = name => (selectValue, isMultiple) => {
        const {checkboxValues, onChangeBoxValue} = this.props;
        let value = checkboxValues[name];
        if (isMultiple) {
            if (value.includes(selectValue))
                value = value.filter(val => val !== selectValue);
            else value.push(selectValue);
        } else value = [selectValue];
        onChangeBoxValue(name)(value);
    };

    onClearSelect = name => () => {
        this.props.onChangeBoxValue(name)([]);
    };

    getFirstCategoryName = () => {
        const {categories} = this.props;
        if (isNotNull(categories)) {
            return categories[0].name;
        }
        return null;
    };

    updateProgress = name => () => {
        const {categories} = this.props;
        categories.forEach(category => {
            const {boxes, completion} = category;
            for (let i = 0; i < boxes.length; i++) {
                if (boxes[i].name === name && this.progressCircles.hasOwnProperty(category.name)) {
                    const circle = this.progressCircles[category.name];
                    circle.animate(completion, {
                        step: (state, circle) => {
                            circle.path.setAttribute('stroke', state.color);
                            circle.path.setAttribute('stroke-width', state.width);
                            circle.setText(parseInt(completion * 100) + '%');
                        }
                    });
                    break;
                }
            }
        });
    };

    getSelectedCategory = () => {
        const {categories} = this.props, {selectedCategory} = this.state;
        for (let i = 0; i < categories.length; i++) {
            if (categories[i].name === selectedCategory) return categories[i];
        }
        return {boxes: []};
    };

    triggerProgressCircles = () => {
        const {categories} = this.props;
        categories.forEach(category => {
            const {completion, name, boxes} = category;
            const container = document.getElementById(`completion-${name}-container`);
            if (isNotNull(container)) {
                container.innerHTML = null;
                const circle = new ProgressBar.Circle(container, {
                    color: '#434b50',
                    strokeWidth: 12,
                    trailColor: '#ccc',
                    trailWidth: 10,
                    easing: 'easeInOut',
                    duration: 1400,
                    text: {
                        autoStyleContainer: false,
                        value: this.getSelectedAmount(boxes),
                    },
                    from: {color: '#009fe3', width: 12},
                    to: {color: '#009fe3', width: 12},
                    step: (state, circle) => {
                        circle.path.setAttribute('stroke', state.color);
                        circle.path.setAttribute('stroke-width', state.width);
                        circle.setText(parseInt(completion * 100) + '%');
                    }
                });
                circle.text.style.fontFamily = '"Raleway", Helvetica, sans-serif';
                circle.text.style.fontSize = '.7rem';
                circle.animate(completion);
                this.progressCircles[category.name] = circle;
            }
        });
    };

    getSelectedAmount = boxes => {
        const {selectedItems} = this.props;
        let amountSelected = 0;
        boxes.forEach(box => {
            if (selectedItems.includes(box.name)) amountSelected++;
        });
        return amountSelected;
    };

    getValueCellValue = checkbox => {
        const {valueType, name, selectType} = checkbox;
        const {checkboxValues} = this.props;
        let value = checkboxValues[name];
        if (isNotNull(value)) {
            if (valueType === 'select' && !Array.isArray(value))
                return [value];
            return value;
        }

        switch (valueType) {
            case 'bool':
                return false;
            case 'string':
                return '';
            case 'select':
                return [];
            default:
                return '';
        }
    };

    renderCategories = () => {
        const {categories} = this.props, {selectedCategory} = this.state;
        return (
            <div className='categoriesContainer'>
                {categories.map((category, key) => {
                    const isSelected = category.name === selectedCategory;
                    return (
                        <div key={key} className={classNames('categoryContainer', isSelected && 'selected')}
                             onClick={this.onClickCategory(category.name)}>
                            <span className={classNames('categoryIcon', category.icon)}/>
                            <label className='categoryName'>{category.label}</label>
                            {this.renderCompletion(category)}
                        </div>
                    )
                })}
            </div>
        )
    };

    renderCompletion = category => {
        const {completion} = category;
        let extraComp = null;
        if (completion === 1)
            extraComp = <span className={classNames('completionIcon', 'common-icon-check')}/>;
        return (
            <>
                {extraComp}
                <div className={classNames('completionContainer', isNotNull(extraComp) && 'hidden')}>
                    <div id={`completion-${category.name}-container`} className='progressContainer'/>
                </div>
            </>
        );
    };

    renderTable = () => {
        const {onCloseExplanation, explanationActive, onOpenExplanation} = this.props;
        const {boxes} = this.getSelectedCategory();
        return (
            <div className='checklistItemsTable'>
                <div className='row header'>
                    <div className='checkCell'/>
                    <div className='nameCell'>
                        <label className='cellLabel'>Naam</label>
                    </div>
                    <div className='documentCell'>
                        <label className='cellLabel'>Document toevoegen</label>
                        <span className='common-icon-info checklistInfo' onClick={onOpenExplanation}/>
                        <ExplanationFrame onClose={onCloseExplanation} active={explanationActive}
                                          placeRight/>
                    </div>
                    <div className='valueCell'>
                        <label className='cellLabel'>Gegevens opslaan</label>
                    </div>
                    <div className='reminderCell'>
                        <label className='cellLabel'>Herinner mij</label>
                    </div>
                </div>
                <div className='checkboxContainer'>
                    {boxes.map(this.renderBox)}
                </div>
            </div>
        )
    };

    renderBox = (box, key) => {
        const {onCheckItem, selectedItems, onClickDocument, onClickLink, onClickReminder} = this.props,
            {name, documentType, link, label, subLabel} = box, isSelected = selectedItems.includes(name);
        return (
            <div className='row' key={key}>
                <div className='checkCell'>
                    <MaterialFactory componentType={materialTypes.CHECKBOX}
                                     onClick={onCheckItem(name, this.updateProgress(name))}
                                     checked={isSelected}/>
                </div>
                <div className={classNames('nameCell', isNotNull(link) && 'withLink')}
                     onClick={onClickLink(link)}>
                    <label className='cellValue'>{label}</label>
                    {this.renderSubLabel(subLabel)}
                </div>
                <div className='documentCell'>
                    {isNotNull(documentType) ?
                        <span className={classNames('common-icon-file-add addDocument')}
                              onClick={onClickDocument(documentType)}/>
                        : <label className='missingTag'>-</label>}
                </div>
                <div className='valueCell'>
                    {this.renderValueCell(box)}
                </div>
                <div className='reminderCell'>
                    <AddAlarm onClick={onClickReminder(label)} className='reminderButton'/>
                </div>
            </div>
        )
    };

    renderSubLabel = subLabel => {
        if (isNull(subLabel)) return null;
        return (
            <div className='subLabelContainer'>
                <InfoOutlined className='infoIcon'/>
                <div className='textContainer'>
                    <label>{subLabel}</label>
                </div>
            </div>
        );
    };

    renderValueCell = checkbox => {
        const {valueType, name, valueLabel, selectType, selectItems, toggleOptions} = checkbox;
        const {onChangeBoxValue} = this.props;
        if (isNotNull(valueType)) {
            let value = this.getValueCellValue(checkbox);
            switch (valueType) {
                case 'bool':
                    const options = toggleOptions || ['Ja', 'Nee'];
                    const toggleLabel = value === true ? options[0] : options[1];
                    return <MaterialFactory componentType={materialTypes.TOGGLE} checked={value} label={toggleLabel}
                                            onChange={this.onChangeToggle(name)}/>;
                case 'string':
                    return <BdhInput onChange={onChangeBoxValue(name)} value={value} label={valueLabel}
                                     variant='contained'/>;
                case 'select':
                    return <BdhSelectController value={value} onSelect={this.onChangeSelect(name)}
                                                onClear={this.onClearSelect(name)} items={selectItems}
                                                multiple={selectType === 'multiple'}/>
            }
        }
        return null;
    };

    componentDidMount = () => {
        this.mount();
        this.triggerProgressCircles();
    };

    componentDidUpdate = prevProps => {
        if (prevProps.checklistId !== this.props.checklistId)
            this.triggerProgressCircles();
    };

    componentWillUnmount = () => {
        this.unMount();
        Object.values(this.progressCircles).forEach(circle => {
            circle.destroy();
        });
        this.progressCircles = null;
    };

    render = () => {
        return (
            <div className='checklistDesktopView'>
                {this.renderCategories()}
                {this.renderTable()}
            </div>
        )
    };
}

ChecklistDesktopView.propTypes = {
    onOpenExplanation: PropTypes.func.isRequired,
    onCloseExplanation: PropTypes.func.isRequired,
    onCheckItem: PropTypes.func.isRequired,
    onClickDocument: PropTypes.func.isRequired,
    onClickLink: PropTypes.func.isRequired,
    onClickReminder: PropTypes.func.isRequired,
    categories: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
        boxes: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            documentType: PropTypes.string,
            link: PropTypes.shape({
                url: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
            }),
        })).isRequired,
        completion: PropTypes.number.isRequired,
    })).isRequired,
    selectedItems: PropTypes.arrayOf(PropTypes.string).isRequired,
    checkboxValues: PropTypes.object.isRequired,
    explanationActive: PropTypes.bool.isRequired,
    checklistId: PropTypes.string,
};