const urlConstants = {
    GET: {
        DOSSIER: '/belonging/',
        DOSSIER_TYPES: '/belonging/types',
        DOCUMENTS: '/vault/all',
        DOCUMENT: '/vault/all/',
        DOCUMENT_URL: '/vault/url/',
        FILE_TYPE_ID: '/vault/filetype',
        ACCOUNT: '/account',
        USER: '/account/',
        LOGOUT: '/auth/logout',
        ALL_DOSSIERS: '/belonging',
        TIMEITEMS: '/timeitems',
        TIMEITEM: '/timeitem/',
        TENDER: '/tender/belonging/',
        EXTERNAL_DATA: '/account/external_data',
        FAMILY: '/family',
        ISSUERS: '/payment/issuers',
        USER_ACCESS: '/payment/access',
        SUBSCRIPTION_USER: '/user',
        USER_FLEX: '/user/flex',
        VALIDATE: '/user/validate',
        ACTIVATE_ACCOUNT: '/redirect_activation/',
        TEST_EMAIL: '/misc/emailcheck/',
        CAR_ANALYZE: '/rolls/',
        GRANT: '/access_grant',
        GRANTED_USER: '/granted_user',
        MAIL_TEMPLATES: '/mail/templates',
        CONTACT: '/contact/',
        CONTACTS: '/contacts',
        DOSSIER_CONTACTS: '/dossier/contacts/',
        ACTION_CODE: '/code/',
        ALL_ACTION_CODES: '/codes',
        ADMIN_CHECK: '/admin/check',
        ADMIN_USERS: '/admins/users',
    },
    POST: {
        DOSSIER: '/belonging',
        DOCUMENT: '/vault',
        FILE: '/vault/file',
        DOWNLOAD_DOCUMENTS: '/vault/download',
        SHARE_DOCUMENTS: '/vault/share',
        LOGIN: '/auth/login',
        REGISTER: '/signup/registration',
        ACCOUNT: '/account/',
        RESET_PASSWORD: '/auth/password/request_reset',
        CHANGE_PASSWORD: '/auth/password/change',
        CHECK_USERNAME: '/signup/username',
        MOBILE_NUMBER: '/account/update_mobile',
        TIMEITEM: '/timeitem',
        TIMEITEMS: '/timeitems',
        FAMILY: '/family',
        TENDER: '/tender/belonging/',
        CONFIRM_LOGIN: '/auth/confirm_login',
        TRAVEL_CONTACT: '/belonging/{id}/email',
        SUBSCRIPTION_USER: '/user',
        SUBSCRIPTION: '/payment/recurring',
        GRANT: '/access_grant',
        LOGIN_GRANT: '/login_grant',
        ACCEPT_GRANT: '/accept_grant',
        LOGOUT_GRANT: '/logout_grant',
        PROFILE_IMAGE: '/account/profile_picture',
        MAIL_LIST: '/mail',
        TASK: '/mail/task',
        MAIL_SEND: '/mail/send',
        WELCOME: '/user/welcome',
        CONTACT: '/contact',
        DOSSIER_CONTACTS: '/dossier/contacts',
        CONTACTS: '/contacts',
        ACTION_CODE: '/code',
        MAILING: '/admin/mailing',
    },
    PUT: {
        DOSSIER: '/belonging/',
        DOCUMENT: '/vault/all/',
        LINK_DOCUMENT: '/vault/',
        ACCOUNT: '/account/',
        CONFIRM_MOBILE: '/account/update_mobile/',
        TIMEITEM: '/timeitem/',
        FAMILY: '/family/',
        EXTERNAL_DATA: '/account/external_data',
        SUBSCRIPTION_USER: '/user',
        GRANT: '/access_grant',
        CONTACT: '/contact/',
        ACTION_CODE: '/code/',
    },
    PATCH: {
        TIMEITEMS: '/timeitems',
    },
    DELETE: {
        DOCUMENT: '/vault/',
        DOSSIER: '/belonging/',
        ACCOUNT: '/account/',
        TIMEITEM: '/timeitem/',
        USER: '/user',
        FAMILY: '/family/',
        SUBSCRIPTION: '/payment/recurring',
        GRANT: '/access_grant',
        CONTACT: '/contact/',
        DOSSIER_CONTACTS: '/dossier/contacts/',
        ACTION_CODE: '/code/',
    }
};

export const createIdUrl = (method, name, id) => {
    let url = urlConstants[method][name];
    url = url.replace('{id}', id);
    return url;
};

export default urlConstants;