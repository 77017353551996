import {generateUUID, toMoment, isFunction} from 'glob-common-js/lib/utils';

import Dossier from "./dossier";
import {
    addContact, deleteContact, deleteContacts, unlinkDossierContacts,
    getContact,
    getContacts,
    getDossierContacts,
    responseIsSuccess,
    updateContact
} from "../misc/requestSender";

export class Contact extends Object{
    static get = (id, callback) => {
        getContact({
            id, callback: response => {
                if (responseIsSuccess(response)) {
                    const {contact} = response.data;
                    callback(new Contact(contact), response);
                } else callback(null, response);
            }
        });
    };

    static getAll = callback => {
        getContacts({
            callback: response => {
                if (responseIsSuccess(response)) {
                    const contacts = response.data.contacts.map(contact => new Contact(contact));
                    callback(contacts, response);
                } else callback([], response);
            }
        });
    };

    static getFromDossier = (dossierId, callback) => {
        getDossierContacts({
            id: dossierId, callback: response => {
                if (responseIsSuccess(response)) {
                    const contacts = response.data.contacts.map(contact => new Contact(contact));
                    callback(contacts, response);
                } else callback([], response);
            }
        });
    };

    static deleteAll = (ids, callback) => {
        deleteContacts({ids, callback});
    };

    static deleteByDossier = (dossierId, callback) => {
        unlinkDossierContacts({id: dossierId, callback});
    };

    hasOwnProperty = name => {
        return isNotNull(this[name]) || this.#fields[name];
    };

    #fields = {
        id: '',
        name: '',
        email: '',
        phone: '',
        mobile: '',
        website: '',
        street: '',
        houseNumber: '',
        houseNumberSuffix: '',
        zipCode: '',
        city: '',
        comment: '',
        flex: {},
        dossiers: [],
        accounts: [],
        createdAt: null,
        updatedAt: null,
    };
    #dossiersLoaded = false;

    constructor(props) {
        super(props);
        this.#initializeFields(props);
    }

    get id() {
        return this.#getNullSafe('id');
    }

    set id(id) {
        this.#fields.id = id;
    }

    get name() {
        return this.#getNullSafe('name');
    }

    set name(name) {
        this.#fields.name = name;
    }

    get email() {
        return this.#getNullSafe('email');
    }

    set email(email) {
        this.#fields.email = email;
    }

    get phone() {
        return this.#getNullSafe('phone');
    }

    set phone(phone) {
        this.#fields.phone = phone;
    }

    get mobile() {
        return this.#getNullSafe('mobile');
    }

    set mobile(mobile) {
        this.#fields.mobile = mobile;
    }

    get website() {
        return this.#getNullSafe('website');
    }

    set website(website) {
        this.#fields.website = website;
    }

    get street() {
        return this.#getNullSafe('street');
    }

    set street(street) {
        this.#fields.street = street;
    }

    get houseNumber() {
        return this.#getNullSafe('houseNumber');
    }

    set houseNumber(number) {
        this.#fields.houseNumber = number;
    }

    get houseNumberSuffix() {
        return this.#getNullSafe('houseNumberSuffix');
    }

    set houseNumberSuffix(suffix) {
        this.#fields.houseNumberSuffix = suffix;
    }

    get zipCode() {
        return this.#getNullSafe('zipCode');
    }

    set zipCode(zipCode) {
        this.#fields.zipCode = zipCode;
    }

    get city() {
        return this.#getNullSafe('city');
    }

    set city(city) {
        this.#fields.city = city;
    }

    get comment() {
        return this.#getNullSafe('comment')
    }

    set comment(comment) {
        this.#fields.comment = comment;
    }

    get flex() {
        return this.#getNullSafe('flex', {});
    }

    set flex(flex) {
        this.#fields.flex = flex;
    }

    get dossiers() {
        return this.#getNullSafe('dossiers', []);
    }

    set dossiers(dossiers) {
        this.#fields.dossiers = dossiers;
    }

    get accounts() {
        return this.#getNullSafe('accounts', []);
    }

    set accounts(accounts) {
        this.#fields.accounts = accounts;
    }

    get createdAt() {
        const createdAt = this.#fields.createdAt;
        if (isNotNull(createdAt)) return toMoment(createdAt).format('DD-MM-YYYY HH:mm');
        return null;
    }

    get updatedAt() {
        const updatedAt = this.#fields.updatedAt;
        if (isNotNull(updatedAt)) return toMoment(updatedAt).format('DD-MM-YYYY HH:mm');
        return null;
    }

    get dossiersLoaded() {
        return this.#dossiersLoaded;
    }

    addDossier = (dossier, callback = null) => {
        if (!this.dossierIsLinked(dossier)) {
            const dossiers = this.dossiers;
            if (this.#dossiersLoaded && typeof dossier === 'string')
                Dossier.get(dossier, dossier => {
                    dossiers.push(dossier);
                    this.dossiers = dossiers;
                    if (isFunction(callback)) callback(this);
                });
            else {
                dossiers.push(dossier);
                this.dossiers = dossiers;
                if (isFunction(callback)) callback(this);
            }
        }
    };

    dossierIsLinked = dossier => {
        const dossiers = this.dossiers;
        for (const doss of dossiers) {
            if (this.#getDossierId(doss) === this.#getDossierId(dossier)) return true;
        }
        return false;
    };

    removeDossier = id => {
        this.dossiers = this.dossiers.filter(dossier => this.#getDossierId(dossier) !== id);
    };

    loadDossiers = callback => {
        if (isNull(this.dossiers)) {
            this.#dossiersLoaded = true;
            callback(this);
        } else {
            const loadedDossiers = [];
            const toLoad = this.dossiers.length;
            this.dossiers.forEach(dossier => {
                const id = this.#getDossierId(dossier);
                Dossier.get(id, dossier => {
                    loadedDossiers.push(dossier);
                    if (loadedDossiers.length === toLoad) {
                        this.dossiers = loadedDossiers;
                        this.#dossiersLoaded = true;
                        callback(this);
                    }
                });
            });
        }
    };

    addAccount = account => {
        const accounts = this.accounts;
        accounts.push({
            id: account.id || generateUUID(),
            label: account.label,
            username: account.username,
            password: account.password,
        });
        this.accounts = accounts;
    };

    removeAccount = account => {
        this.accounts = this.accounts.filter(acc => acc.id !== account.id);
    };

    save = callback => {
        addContact({
            data: this.#createRequestData(), callback: response => {
                if (responseIsSuccess(response))
                    this.#initializeFields(response.data.contact);
                callback(this, response);
            }
        });
    };

    update = callback => {
        updateContact({
            id: this.id, data: this.#createRequestData(), callback: response => {
                callback(this, response);
            }
        })
    };

    delete = callback => {
        deleteContact({
            id: this.id, callback,
        })
    };

    #getDossierId = dossier => {
        if (typeof dossier === 'string') return dossier;
        if ('id' in dossier) return dossier.id;
        return null;
    };

    #createRequestData = () => ({
        id: this.id,
        name: this.name,
        email: this.email,
        phone: this.phone,
        mobile: this.mobile,
        website: this.website,
        street: this.street,
        house_number: this.houseNumber,
        house_number_suffix: this.houseNumberSuffix,
        zip_code: this.zipCode,
        city: this.city,
        comment: this.comment,
        flex: this.flex,
        accounts: this.accounts,
        dossiers: this.dossiers.map(dossier => this.#getDossierId(dossier)),
    });

    #initializeFields = props => {
        if (isNotNull(props)) {
            this.#initializeField('id', props.id);
            this.#initializeField('name', props.name);
            this.#initializeField('email', props.email);
            this.#initializeField('phone', props.phone);
            this.#initializeField('mobile', props.mobile);
            this.#initializeField('website', props.website);
            this.#initializeField('street', props.street);
            this.#initializeField('houseNumber', props.house_number);
            this.#initializeField('houseNumberSuffix', props.house_number_suffix);
            this.#initializeField('zipCode', props.zip_code);
            this.#initializeField('city', props.city);
            this.#initializeField('comment', props.comment);
            this.#initializeField('flex', props.flex);
            this.#initializeField('dossiers', props.dossiers);
            this.#initializeField('accounts', props.accounts);
            this.#initializeField('createdAt', props.created_at);
            this.#initializeField('updatedAt', props.updatedAt);
        }
    };

    #initializeField = (name, value, skipNullCheck = false) => {
        if (isNotNull(value) || skipNullCheck) this.#fields[name] = value;
    };

    #getNullSafe = (name, defaultValue = '') => {
        if (isNotNull(this.#fields[name])) return this.#fields[name];
        return defaultValue;
    }
}