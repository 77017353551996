import React from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import {deleteTimeItem, getAllTimeItems} from "../../../misc/requestSender";
import {timeFormats} from "../../../common/components/datePicker";
import AgendaView from "./agendaView";
import {addCustomEventListener, dispatchCustomEvent, removeCustomEventListener} from "../../../misc/eventDispatcher";
import {sendGaEvent} from "../../../common/js/ga";
import {NOW} from "../../misc/constants";
import {getOnboardingManager} from "../../onboarding/manager/onboardingManagerHelper";
import StateComponent from "../../misc/stateComponent";
import {agendaOnboardingSteps} from "../../onboarding/manager/agendaManager";

export const DAY_INDEX = 0, PLANNED_INDEX = 1, PLANNING_INDEX = 2;

const GA_CATEGORY = 'Dashboard (Agenda)';

export class AgendaController extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            agendaItems: [],
            activeTab: DAY_INDEX,
            dialogOpen: false,
        };
        this.itemId = null;
    }

    onSelectTab = (evt, tab) => {
        if (!getOnboardingManager('agenda').isActive())
            this.changeState({activeTab: tab});
    };

    onAddItemClick = () => {
        const manager = getOnboardingManager('agenda');
        if (manager.isActive()) {
            if (manager.getActiveStep() === agendaOnboardingSteps.CLICK_ADD_AGENDA){
                manager.sendEvent('Klik', 'Plus agenda item');
                manager.setNextStep();
            }
        } else {
            sendGaEvent(GA_CATEGORY, 'Klik', 'Toevoegen');
            dispatchCustomEvent('openAgendaPopup');
        }
    };

    onDeleteItem = () => {
        this.onCloseDialog();
        if (isNotNull(this.itemId))
            deleteTimeItem({
                id: this.itemId,
                callback: () => {
                    sendGaEvent(GA_CATEGORY, 'Item verwijderen', 'Verwijderd');
                    this.loadAgendaItems();
                    dispatchCustomEvent('openSnackbar', {text: 'Het agenda item is verwijderd'});
                }
            });
    };

    onDeleteItemClick = id => {
        sendGaEvent(GA_CATEGORY, 'Klik', 'Item verwijderen');
        this.itemId = id;
        this.changeState({dialogOpen: true});
    };

    onCloseDialog = () => {
        this.changeState({dialogOpen: false});
    };

    loadAgendaItems = (determineStep = false) => {
        getAllTimeItems({
            callback: response => {
                const timeItems = response.data;
                if (isNotNull(timeItems) && Array.isArray(timeItems))
                    this.processTimeItems(timeItems, determineStep);
                else this.changeState({agendaItems: []});
            }
        })
    };

    processTimeItems = (timeItems, determineStep) => {
        timeItems = timeItems.filter(timeItem => this.shouldIncludeTimeItem(timeItem));
        const activeTab = this.determineActiveTab(timeItems, determineStep);
        this.changeState({agendaItems: timeItems, activeTab});
    };

    shouldIncludeTimeItem = timeItem => {
        const ignoreItemTypes = [4, 5];
        return !ignoreItemTypes.includes(parseInt(timeItem.type));
    };

    determineActiveTab = (timeItems, determineStep) => {
        if (!determineStep) return this.state.activeTab;
        if (isNotNull(timeItems)) {
            for (let i = 0; i < timeItems.length; i++) {
                let timeItem = timeItems[i];
                let startDate = moment(timeItem.start_date_item, timeFormats);
                if (startDate.isSame(NOW(), 'day')) return DAY_INDEX;
            }
            return PLANNED_INDEX;
        }
        return PLANNING_INDEX;
    };

    activateOnboarding = () => {
        this.changeState({activeTab: DAY_INDEX});
    };

    componentDidMount = () => {
        this.mount();
        this.loadAgendaItems(true);
        addCustomEventListener('updateTimeItems', this.loadAgendaItems);
        addCustomEventListener('updatePlatform', this.loadAgendaItems);
        getOnboardingManager('agenda').addActivateListener('AgendaController', this.activateOnboarding);
    };

    componentWillUnmount = () => {
        this.unMount();
        removeCustomEventListener('updateTimeItems', this.loadAgendaItems);
        removeCustomEventListener('updatePlatform', this.loadAgendaItems);
        getOnboardingManager('agenda').removeActivateListener('AgendaController', this.activateOnboarding);
    };

    render = () => (
        <AgendaView onSelectTab={this.onSelectTab} onDeleteItem={this.onDeleteItem}
                    onDeleteItemClick={this.onDeleteItemClick} agendaItems={this.state.agendaItems}
                    activeTab={this.state.activeTab} onAddItemClick={this.onAddItemClick}
                    dialogOpen={this.state.dialogOpen} onCloseDialog={this.onCloseDialog}/>
    );
}

const mapStateToProps = state => ({
    userState: state.user,
});

export default connect(mapStateToProps)(AgendaController);

