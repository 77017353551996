import {isNotNull, warn} from 'glob-common-js/lib/utils';

export const selectableOptions = {
    policy_type: [
        'Budgetpolis',
        'Naturapolis',
        'Combinatiepolis',
        'Restitutiepolis',
    ],
    covered_no_contract: [
        "100% van de kosten wordt vergoed in geval er geen contract is.",
        "95% van de kosten wordt vergoed in geval er geen contract is.",
        "90% van de kosten wordt vergoed in geval er geen contract is.",
        "85% van de kosten wordt vergoed in geval er geen contract is.",
        "80% van de kosten wordt vergoed in geval er geen contract is.",
        "75% van de kosten wordt vergoed in geval er geen contract is.",
        "70% van de kosten wordt vergoed in geval er geen contract is.",
        "65% van de kosten wordt vergoed in geval er geen contract is.",
        "60% van de kosten wordt vergoed in geval er geen contract is.",
    ],
    risk: [
        '385',
        '485',
        '585',
        '685',
        '785',
        '885',
    ],
    alternatieve_zorg: [
        'Geen dekking',
        'Vergoeding minimaal € 250,-',
        'Vergoeding minimaal € 500,-',
        'Vergoeding minimaal € 750,-',
    ],
    brillen_lenzen: [
        'Geen dekking',
        'Vergoeding minimaal € 100,-',
        'Vergoeding minimaal € 200,-',
        'Vergoeding minimaal € 300,-',
    ],
    fysiotherapie: [
        'Geen dekking',
        'minimaal 9 behandelingen',
        'minimaal 18 behandelingen',
        'minimaal 27 behandelingen',
        'minimaal 36 behandelingen',
    ],
    kraamzorg: [
        'Geen dekking',
        'Beperkte vergoeding',
        'Ruime vergoeding',
    ],
    spoedeisende_hulp_buitenland: [
        'Geen dekking',
        'Vergoeding alleen Europa',
        'Vergoeding wereldwijd',
    ],
    vaccinaties: [
        'Geen dekking',
        'Beperkte vergoeding',
        'Ruime vergoeding',
    ],
    tandarts: [
        'Geen dekking',
        'Vergoeding minimaal € 250,-',
        'Vergoeding minimaal € 500,-',
        'Vergoeding minimaal € 750,-',
        'Vergoeding minimaal € 1.000,-',
    ],
    orthodontie_tot_18_jaar: [
        'Geen dekking',
        'Vergoeding minimaal € 500,-',
        'Vergoeding minimaal € 1.000,-',
        'Vergoeding minimaal € 1.500,-',
        'Vergoeding minimaal € 2.000,-',
    ],
    orthodontie_vanaf_18_jaar: [
        'Geen dekking',
        'Vergoeding minimaal € 500,-',
        'Vergoeding minimaal € 1.000,-',
    ],
    mondzorg_ongevallen: [
        'Geen dekking',
        'Vergoeding minimaal € 1.000,-',
        'Vergoeding minimaal € 5.000,-',
    ],
    spoedeisende_zorg_tanden: [
        'Geen dekking',
        'Beperkte vergoeding',
        'Gemiddelde vergoeding',
        'Ruime vergoeding',
    ],
    acne_behandeling: [
        "Geen dekking",
        "Vergoeding minimaal € 100,-",
        "Vergoeding minimaal € 300,-",
        "Vergoeding minimaal € 500,-",
    ],
    anticonceptie: [
        "Geen dekking",
        "Vergoeding minimaal € 100,-",
        "Vergoeding minimaal € 200,-",
    ],
    camouflagetherapie: [
        "Geen dekking",
        "Vergoeding minimaal € 100,-",
        "Vergoeding minimaal € 300,-",
        "Vergoeding minimaal € 500,-",
    ],
    cursussen_en_preventie: [
        "Geen dekking",
        "Wel vergoedingen",
    ],
    dieet_advisering: [
        "Geen dekking",
        "Beperkte vergoeding",
        "Ruime vergoeding",
    ],
    geneesmiddelen: [
        'Geen dekking',
        'Vergoeding minimaal € 150,-',
        'Vergoeding minimaal € 300,-',
        'Vergoeding minimaal € 450,-',
    ],
    herstellingsoord_zorghotel: [
        "Geen dekking",
        "Beperkte vergoeding",
        "Gemiddelde vergoeding",
        "Ruime vergoeding",
    ],
    homeopatische_middelen: [
        "Geen dekking",
        "Vergoeding minimaal € 250,-",
        "Vergoeding minimaal € 500,-",
    ],
    hulpmiddelen_overig: [
        "Geen dekking",
        "Beperkte vergoeding",
        "Ruime vergoeding",
    ],
    logeerkosten: [
        "Geen dekking",
        "Beperkte vergoeding",
        "Gemiddelde vergoeding",
        "Ruime vergoeding",
    ],
    mantelzorg: [
        "Geen dekking",
        "Beperkte vergoeding",
        "Gemiddelde vergoeding",
        "Ruime vergoeding",
    ],
    nazorg_bij_kanker: [
        "Geen dekking",
        "Beperkte vergoeding",
        "Gemiddelde vergoeding",
        "Ruime vergoeding",
    ],
    ontharen_lasertherapie: [
        "Geen dekking",
        "Vergoeding minimaal € 200,-",
        "Vergoeding minimaal € 500,-",
        "Vergoeding minimaal € 1.000,-",
    ],
    sportmedisch_onderzoek: [
        "Geen dekking",
        "Beperkte vergoeding",
        "Ruime vergoeding",
    ],
    sterilisatie_man: [
        "Geen dekking",
        "Vergoeding minimaal € 300,-",
        "Vergoeding minimaal € 500,-",
    ],
    sterilisatie_vrouw: [
        "Beperkte vergoeding",
        "Ruime vergoeding",
    ],
    steunzolen: [
        "Geen dekking",
        "Beperkte vergoeding",
        "Ruime vergoeding",
    ],
    stottertherapie: [
        "Geen dekking",
        "Vergoeding minimaal € 200,-",
        "Vergoeding minimaal € 400,-",
        "Vergoeding minimaal € 600,-",
    ],
    voetzorg_voetbehandeling: [
        "Geen dekking",
        "Vergoeding minimaal € 100,-",
        "Vergoeding minimaal € 250,-",
        "Vergoeding minimaal € 500,-",
    ]
};

export const editPossibilities = {
    "aangepast lingerie na borst amputatie":
        [
            "Geen dekking",
            "Ik wil vergoedingen voor 9 behandelingen",
            "Ik wil vergoedingen voor 16 behandelingen",
            "Ik wil vergoedingen voor 32 behandelingen"
        ],
    "ADL":
        [
            "Geen dekking",
            "Ik wil vergoedingen voor 9 behandelingen",
            "Ik wil vergoedingen voor 16 behandelingen",
            "Ik wil vergoedingen voor 32 behandelingen"
        ],
    "audiologische zorg":
        [
            "Geen dekking",
            "Ik wil vergoedingen voor 9 behandelingen",
            "Ik wil vergoedingen voor 16 behandelingen",
            "Ik wil vergoedingen voor 32 behandelingen"
        ],
    "bewegen in warm water":
        [
            "Geen dekking",
            "Ik wil vergoedingen voor 9 behandelingen",
            "Ik wil vergoedingen voor 16 behandelingen",
            "Ik wil vergoedingen voor 32 behandelingen"
        ],
    "borstvoeding":
        [
            "Geen dekking",
            "Ik wil vergoedingen voor 9 behandelingen",
            "Ik wil vergoedingen voor 16 behandelingen",
            "Ik wil vergoedingen voor 32 behandelingen"
        ],
    "buitenland overleden repatriering":
        [
            "Geen dekking",
            "Ik wil vergoedingen voor 9 behandelingen",
            "Ik wil vergoedingen voor 16 behandelingen",
            "Ik wil vergoedingen voor 32 behandelingen"
        ],
    "conditie fitheid /beweegzorg":
        [
            "Geen dekking",
            "Ik wil vergoedingen voor 9 behandelingen",
            "Ik wil vergoedingen voor 16 behandelingen",
            "Ik wil vergoedingen voor 32 behandelingen"
        ],
    "eerstelijns psychologische zorg":
        [
            "Geen dekking",
            "Ik wil vergoedingen voor 9 behandelingen",
            "Ik wil vergoedingen voor 16 behandelingen",
            "Ik wil vergoedingen voor 32 behandelingen"
        ],
    "griepvaciniatie":
        [
            "Geen dekking",
            "Ik wil vergoedingen voor 9 behandelingen",
            "Ik wil vergoedingen voor 16 behandelingen",
            "Ik wil vergoedingen voor 32 behandelingen"
        ],
    "hoortoetstellen":
        [
            "Geen dekking",
            "Ik wil vergoedingen voor 9 behandelingen",
            "Ik wil vergoedingen voor 16 behandelingen",
            "Ik wil vergoedingen voor 32 behandelingen"
        ],
    "huidbehandeling":
        [
            "Geen dekking",
            "Ik wil vergoedingen voor 9 behandelingen",
            "Ik wil vergoedingen voor 16 behandelingen",
            "Ik wil vergoedingen voor 32 behandelingen"
        ],
    "kinderopvang ziekenhuisopname":
        [
            "Geen dekking",
            "Ik wil vergoedingen voor 9 behandelingen",
            "Ik wil vergoedingen voor 16 behandelingen",
            "Ik wil vergoedingen voor 32 behandelingen"
        ],
    "kunstgebid":
        [
            "Geen dekking",
            "Ik wil vergoedingen voor 9 behandelingen",
            "Ik wil vergoedingen voor 16 behandelingen",
            "Ik wil vergoedingen voor 32 behandelingen"],
    "lactatiekundig advies":
        [
            "Geen dekking",
            "Ik wil vergoedingen voor 9 behandelingen",
            "Ik wil vergoedingen voor 16 behandelingen",
            "Ik wil vergoedingen voor 32 behandelingen"
        ],
    "mama print":
        [
            "Geen dekking",
            "Ik wil vergoedingen voor 9 behandelingen",
            "Ik wil vergoedingen voor 16 behandelingen",
            "Ik wil vergoedingen voor 32 behandelingen"
        ],
    "medisch fitness":
        [
            "Geen dekking",
            "Ik wil vergoedingen voor 9 behandelingen",
            "Ik wil vergoedingen voor 16 behandelingen",
            "Ik wil vergoedingen voor 32 behandelingen"
        ],
    "meerlingen uitkering":
        [
            "Geen dekking",
            "Ik wil vergoedingen voor 9 behandelingen",
            "Ik wil vergoedingen voor 16 behandelingen",
            "Ik wil vergoedingen voor 32 behandelingen"
        ],
    "Oncotype DX":
        [
            "Geen dekking",
            "Ik wil vergoedingen voor 9 behandelingen",
            "Ik wil vergoedingen voor 16 behandelingen",
            "Ik wil vergoedingen voor 32 behandelingen"
        ],
    "ooglid correctie":
        [
            "Geen dekking",
            "Ik wil vergoedingen voor 9 behandelingen",
            "Ik wil vergoedingen voor 16 behandelingen",
            "Ik wil vergoedingen voor 32 behandelingen"
        ],
    "overgangsconsulent":
        [
            "Geen dekking",
            "Ik wil vergoedingen voor 9 behandelingen",
            "Ik wil vergoedingen voor 16 behandelingen",
            "Ik wil vergoedingen voor 32 behandelingen"
        ],
    "patientenvereniging":
        [
            "Geen dekking",
            "Ik wil vergoedingen voor 9 behandelingen",
            "Ik wil vergoedingen voor 16 behandelingen",
            "Ik wil vergoedingen voor 32 behandelingen"
        ],
    "plastische ingreep":
        [
            "Geen dekking",
            "Ik wil vergoedingen voor 9 behandelingen",
            "Ik wil vergoedingen voor 16 behandelingen",
            "Ik wil vergoedingen voor 32 behandelingen"
        ],
    "podotherapie":
        [
            "Geen dekking",
            "Ik wil vergoedingen voor 9 behandelingen",
            "Ik wil vergoedingen voor 16 behandelingen",
            "Ik wil vergoedingen voor 32 behandelingen"
        ],
    "psoriasus behandeling":
        [
            "Geen dekking",
            "Ik wil vergoedingen voor 9 behandelingen",
            "Ik wil vergoedingen voor 16 behandelingen",
            "Ik wil vergoedingen voor 32 behandelingen"
        ],
    "reiskosten ziekenbezoek bij opname >1mnd":
        [
            "Geen dekking",
            "Ik wil vergoedingen voor 9 behandelingen",
            "Ik wil vergoedingen voor 16 behandelingen",
            "Ik wil vergoedingen voor 32 behandelingen"
        ],
    "reuma":
        [
            "Geen dekking",
            "Ik wil vergoedingen voor 9 behandelingen",
            "Ik wil vergoedingen voor 16 behandelingen",
            "Ik wil vergoedingen voor 32 behandelingen"
        ],
    "runningtherapie":
        [
            "Geen dekking",
            "Ik wil vergoedingen voor 9 behandelingen",
            "Ik wil vergoedingen voor 16 behandelingen",
            "Ik wil vergoedingen voor 32 behandelingen"
        ],
    "softbrace/spalk":
        [
            "Geen dekking",
            "Ik wil vergoedingen voor 9 behandelingen",
            "Ik wil vergoedingen voor 16 behandelingen",
            "Ik wil vergoedingen voor 32 behandelingen"
        ],
    "steunpecasrium":
        [
            "Geen dekking",
            "Ik wil vergoedingen voor 9 behandelingen",
            "Ik wil vergoedingen voor 16 behandelingen",
            "Ik wil vergoedingen voor 32 behandelingen"
        ],
    "telecomunicatie kosten":
        [
            "Geen dekking",
            "Ik wil vergoedingen voor 9 behandelingen",
            "Ik wil vergoedingen voor 16 behandelingen",
            "Ik wil vergoedingen voor 32 behandelingen"
        ],
    "terminale zorg":
        [
            "Geen dekking",
            "Ik wil vergoedingen voor 9 behandelingen",
            "Ik wil vergoedingen voor 16 behandelingen",
            "Ik wil vergoedingen voor 32 behandelingen"
        ],
    "test strip insuline":
        [
            "Geen dekking",
            "Ik wil vergoedingen voor 9 behandelingen",
            "Ik wil vergoedingen voor 16 behandelingen",
            "Ik wil vergoedingen voor 32 behandelingen"
        ],
    "therapeutische vakantiekampen kinderen":
        [
            "Geen dekking",
            "Ik wil vergoedingen voor 9 behandelingen",
            "Ik wil vergoedingen voor 16 behandelingen",
            "Ik wil vergoedingen voor 32 behandelingen"],
    "thuiszorg":
        [
            "Geen dekking",
            "Ik wil vergoedingen voor 9 behandelingen",
            "Ik wil vergoedingen voor 16 behandelingen",
            "Ik wil vergoedingen voor 32 behandelingen"
        ],
    "uv b lichtapparatuur":
        [
            "Geen dekking",
            "Ik wil vergoedingen voor 9 behandelingen",
            "Ik wil vergoedingen voor 16 behandelingen",
            "Ik wil vergoedingen voor 32 behandelingen"
        ],
    "vaat pigment behandeling":
        [
            "Geen dekking",
            "Ik wil vergoedingen voor 9 behandelingen",
            "Ik wil vergoedingen voor 16 behandelingen",
            "Ik wil vergoedingen voor 32 behandelingen"
        ],
    "vakantiereizen zieke/gehandicapte":
        [
            "Geen dekking",
            "Ik wil vergoedingen voor 9 behandelingen",
            "Ik wil vergoedingen voor 16 behandelingen",
            "Ik wil vergoedingen voor 32 behandelingen"
        ],
    "val preventie":
        [
            "Geen dekking",
            "Ik wil vergoedingen voor 9 behandelingen",
            "Ik wil vergoedingen voor 16 behandelingen",
            "Ik wil vergoedingen voor 32 behandelingen"
        ],
    "verloskamer":
        [
            "Geen dekking",
            "Ik wil vergoedingen voor 9 behandelingen",
            "Ik wil vergoedingen voor 16 behandelingen",
            "Ik wil vergoedingen voor 32 behandelingen"
        ],
    "zelf management chronische aandoening":
        [
            "Geen dekking",
            "Ik wil vergoedingen voor 9 behandelingen",
            "Ik wil vergoedingen voor 16 behandelingen",
            "Ik wil vergoedingen voor 32 behandelingen"
        ],
    "zwangerschap cursus":
        [
            "Geen dekking",
            "Ik wil vergoedingen voor 9 behandelingen",
            "Ik wil vergoedingen voor 16 behandelingen",
            "Ik wil vergoedingen voor 32 behandelingen"
        ],
    "elen":
        [
            "Geen dekking",
            "Vergoeding minimaal € 150,-",
            "Vergoeding minimaal € 300,-",
            "Vergoeding minimaal € 450,-"
        ]
};

export const getByName = name => {
    if (isNotNull(name)) {
        if (editPossibilities.hasOwnProperty(name)) {
            return editPossibilities[name];
        } else {
            warn('EditPossibilityMapping does not contain an entry with name \'' + name + '\'.');
        }
    }
};

export const getNestedPosibilities = name => {
    if (isNotNull(name)) {
        return editPossibilities['type verzekering'][name].possibilities;
    }
};