import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {generateUUID} from 'glob-common-js/lib/utils';

import DataView25 from "./dataView25";
import {createDossier} from "../../../misc/requestSender";
import getDossierType from "../../../common/data/mappings/dossierTypeMapping";
import {dispatchCustomEvent} from "../../../misc/eventDispatcher";
import StateComponent from "../../misc/stateComponent";
import {NOW} from "../../misc/constants";

export default class DataController25 extends StateComponent {
    constructor(props) {
        super(props);

        this.state = {submitting: false,};
        this.saveActions = 0;
        this.saveActionsCompleted = 0;
    }

    onSave = (data) => {
        this.props.onSave();
        this.changeState({submitting: true});
        this.determineSaveActions(data);
        this.saveDossiers(data);
    };

    determineSaveActions = data => {
        let house = data.house, work = data.work, car = data.car, energy = data.energy, dossiersAdded = [],
            checked = false, financialAdded = false;

        if (house.toggled && isNotNull(house.value)) {
            dossiersAdded.push('house');
            this.saveActions++;
        }
        if (isNotNull(work)) {
            dossiersAdded.push('job');
            financialAdded = true;
            this.saveActions++;
        }
        if (car.toggled && isNotNull(car.value)) {
            dossiersAdded.push('car');
            this.saveActions++;
        }
        if (isNotNull(energy.value)) {
            dossiersAdded.push('energy');
            financialAdded = true;
            this.saveActions++;
        }
        dispatchCustomEvent('setHighlightParams', {dossiersAdded, checked, financialAdded});
    };

    saveDossiers = data => {
        // Save house, job, car and energy
        this.saveHouseDossier(data.house, data.rent, data.houseNumber, data.houseNumberSuffix);
        this.saveJobDossier(data.work, data.salary);
        this.saveCarDossier(data.car);
        this.saveEnergyDossier(data.energy, data.energy_price, data.energy_day);
    };

    saveHouseDossier = (house, rent, houseNumber, houseNumberSuffix) => {
        if (areNotNull(house.value, houseNumber.value)) {
            if (house.toggled) {
                this.saveRentalHouseDossier(house, rent, houseNumber, houseNumberSuffix);
            } else {
                const type = getDossierType('house');
                createDossier({
                    data: {
                        name: 'Woonhuis',
                        type: type.id,
                        fields: [
                            {
                                id: '66f0c5e5-b53c-45dd-85ab-041e3d535064',
                                name: 'zipcode',
                                value: house.value,
                            },
                            {
                                id: 'b2d534ca-0b3f-4eea-a6e5-56e3d17fb571',
                                name: 'house_number',
                                value: houseNumber.value,
                            },
                            {
                                id: 'd473b7a3-e722-46b1-9c91-283aca713f46',
                                name: 'house_number_suffix',
                                value: houseNumberSuffix.value
                            }
                        ],
                        external_data: {
                            isDossier: true,
                            values: [
                                {
                                    id: generateUUID(),
                                    zipcode: house.value,
                                    house_number: houseNumber.value,
                                    houseNumberSuffix: houseNumberSuffix.value
                                }
                            ]
                        },
                        values: {},
                    }, callback: this.notifySaveAction,
                })
            }
        } else {
            this.notifySaveAction();
        }
    };

    saveRentalHouseDossier = (house, rent, houseNumber, houseNumberSuffix) => {
        const type = getDossierType('rental_house');
        let startDate = moment(NOW()).month(0).date(1);
        createDossier({
            data: {
                name: 'Huurhuis',
                type: type.id,
                external_data: {
                    isDossier: true,
                    values: [
                        {
                            id: generateUUID(),
                            description: 'Huur',
                            label: 'Huur',
                            financialType: 'rental_house',
                            zipcode: house.value,
                            house_number: houseNumber.value,
                            houseNumberSuffix: houseNumberSuffix.value,
                            monthly_price: rent.value,
                            start_date: startDate.format('DD-MM-YYYY'),
                            end_date: null,
                            frequency: 'monthly',
                        }
                    ]
                },
                values: {},
            }, callback: this.notifySaveAction,
        })
    };

    saveJobDossier = (job, salary) => {
        if (isNotNull(job.value)) {
            const type = getDossierType('job');
            let startDate = moment(NOW()).month(0).date(1);
            createDossier({
                data: {
                    name: job.value,
                    type: type.id,
                    external_data: {
                        isDossier: true,
                        values: [
                            {
                                id: generateUUID(),
                                description: job.value,
                                financialType: 'job',
                                company: job.value,
                                start_date: startDate.format('DD-MM-YYYY'),
                                end_date: null,
                                monthly_price: salary.value,
                                frequency: 'monthly',
                                label: job.value,
                            }
                        ]
                    },
                    values: {},
                }, callback: this.notifySaveAction,
            })
        } else {
            this.notifySaveAction();
        }
    };

    saveCarDossier = car => {
        if (car.toggled && isNotNull(car.value)) {
            const type = getDossierType('car');
            createDossier({
                data: {
                    name: 'Auto',
                    type: type.id,
                    fields: [
                        {
                            id: '21c3e801-dd2f-4ab5-ae0a-2284cd457cc5',
                            name: 'licence_plate',
                            value: car.value,
                        }
                    ],
                    external_data: {
                        isDossier: true,
                        values: [
                            {
                                id: generateUUID(),
                                licence_plate: car.value,
                            }
                        ]
                    },
                    values: {},
                }, callback: this.notifySaveAction,
            });
        } else {
            this.notifySaveAction();
        }
    };

    saveEnergyDossier = (energy, energyPrice, energyDay) => {
        if (isNotNull(energy.value)) {
            const type = getDossierType('energy');
            let startDate = moment(NOW()).month(0).date(energyDay);
            createDossier({
                data: {
                    name: energy.value,
                    type: type.id,
                    external_data: {
                        isDossier: true,
                        values: [
                            {
                                id: generateUUID(),
                                description: energy.value,
                                financialType: 'energy',
                                start_date: startDate.format('DD-MM-YYYY'),
                                end_date: null,
                                monthly_price: energyPrice.value,
                                frequency: 'monthly',
                                label: energy.value,
                            }
                        ]
                    },
                    values: {},
                }, callback: this.notifySaveAction,
            })
        } else {
            this.notifySaveAction();
        }
    };

    notifySaveAction = () => {
        this.saveActionsCompleted++;
        if (this.saveActionsCompleted >= this.saveActions) {
            this.saveActionsCompleted = 0;
            this.saveActions = 0;
            dispatchCustomEvent('updatePlatform');
            this.changeState({submitting: false});
            this.props.onStopLoading();
            this.props.nextStep();
        }
    };

    componentDidMount = () => {
        this.mount();
    };

    componentWillUnmount = () => {
        this.unMount();
    };

    render = () => (
        <DataView25 ref={refName => {
            this.view = refName
        }} onSubmit={this.onSave} submitting={this.state.submitting}/>
    )
}

DataController25.propTypes = {
    nextStep: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onStopLoading: PropTypes.func.isRequired,
};