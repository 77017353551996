import utils from './utils';

const gaNotLoaded = () => {
    throw new Error('Google Analytics has not yet been initialized. Please use the init(trackId) function to ' +
        'initialize Google Analytics.');
};

const requestError = message => {
    throw new Error('Sending the request failed with the following message:\n\'' + message + '\'');
};

const axiosError = (error, requestConfig) => {
    return new Error('Axios ran into an error whilst executing a request.\nError: ' + JSON.stringify(error) + '\nRequest: '
        + JSON.stringify(requestConfig));
};

const logToSentry = (ex, context) => {
    if (utils.getSetting('mode') === 'prod' && window.raven !== undefined) {
        Raven.captureException(ex, {
            extra: context
        });
    }
    window.console && console.error && console.error(ex);
};

let errors = {
    gaNotLoaded: gaNotLoaded,
    axiosError: axiosError,
    requestError: requestError,
    logToSentry: logToSentry
};

export default errors;