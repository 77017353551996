import './style/financialData.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Chart from 'chart.js';
import Scrollbars from 'react-custom-scrollbars';
import {remToPx, toMoney} from 'glob-common-js/lib/utils';
import {sendGaEvent} from "../../../common/js/ga";
import MaterialFactory from "../../material/materialFactory";
import {materialTypes} from "../../material/materialTypes";
import {getOnboardingManager} from "../../onboarding/manager/onboardingManagerHelper";

// Puts percentage labels onto the doughnut chart
require('chart.piecelabel.js');

const GA_CATEGORY = 'Financieel overzicht';
export default class FinancialDataView extends React.Component {
    constructor(props) {
        super(props);
    }

    getTypeLabel = () => this.props.type === 'income' ? 'Inkomsten' : 'Uitgaven';

    onClickCategory = (category) => {
        let element = document.getElementById('financialCategory ' + category.label);
        if (isNotNull(element)) {
            const manager = getOnboardingManager('financial');
            if (manager.isActive())
                manager.sendEvent('Klik', `Keuze categorie (${category.label})`);
            else
                sendGaEvent(GA_CATEGORY, 'Klik categorie', category.label);
            const openCategories = document.querySelectorAll('div.financialCategoryContainer.open');
            for (let i = 0; i < openCategories.length; i++) {
                if (openCategories[i] !== element) openCategories[i].classList.remove('open');
            }
            element.classList.toggle('open');
        }
    };

    selectItem = item => {
        this.props.selectItem(item);
    };

    onClickItem = item => {
        sendGaEvent(GA_CATEGORY, 'Klik item', item.label);
        this.props.onItemClick(item);
    };

    renderCategory = (category, key) => {
        return (
            <div key={key} className='financialCategoryContainer' id={'financialCategory ' + category.label}>
                <div className='categoryContainer' onClick={() => {
                    this.onClickCategory(category);
                }}>
                    <div className='categoryStart'/>
                    <label className='categoryLabel'>{category.label}</label>
                    <label className='categoryTotal'>{toMoney(category.total)}</label>
                </div>
                <Scrollbars className='financialScrollbar' autoHide autoHideDuration={500}>
                    <div className='financialItemsContainer'>
                        {category.items.map(this.renderItem)}
                        <label className='suggestionsLabel'>Suggesties</label>
                        {category.suggestions.map(this.renderSuggestion)}
                    </div>
                </Scrollbars>
            </div>
        )
    };

    renderItem = (item, key) => {
        const manager = getOnboardingManager('financial');
        if (manager.isActive() && manager.shouldHideItems()) return null;
        return (
            <div key={key} className='financialItem'>
                <MaterialFactory componentType={materialTypes.CHECKBOX} className='financialItemCheck'
                                 checked={this.props.selectedItems.includes(item)} onChange={() => {
                    this.selectItem(item);
                }}/>
                <label className='itemLabel' onClick={() => {
                    this.onClickItem(item)
                }}>{item.label}</label>
                <label className='itemAmount' onClick={() => {
                    this.props.onItemClick(item)
                }}>{toMoney(parseFloat(item.monthly_price))}</label>
            </div>
        )
    };

    renderSuggestion = (suggestion, key) => (
        <div key={key} className='financialSuggestion' onClick={(evt) => {
            this.props.onSuggestionClick(suggestion);
        }}>
            <div className='addSuggestionContainer'>
                <label className='addButton'>+</label>
            </div>
            <div className='suggestionLabelContainer'>
                <label className='suggestionLabel'>{suggestion.label}</label>
            </div>
            <div className='suggestionEndContainer'/>
        </div>
    );

    renderChart = () => {
        let canvas = ReactDOM.findDOMNode(this.chartCanvas);
        if (isNotNull(canvas)) {
            let backgroundColor = this.props.categories.map(category => category.stroke);

            let categoryIds = this.props.categories.map(category => {
                if (category.total > 0) return 'financialCategory ' + category.label;
                return null;
            }).filter(category => isNotNull(category));

            new Chart(canvas, {
                type: 'doughnut',
                data: {
                    datasets: [{
                        data: this.props.categories.map(category => category.total),
                        backgroundColor,
                    }],
                    labels: this.props.categories.map(category => category.label),
                },
                options: {
                    legend: {
                        display: false,
                    },
                    tooltips: {
                        bodyFontSize: remToPx(2),
                        callbacks: {
                            label: (toolTipItem, data) => {
                                let dataset = data.datasets[0];
                                let label = data.labels[toolTipItem.index] || '';
                                if (isNotNull(label)) {
                                    return label + ': ' + toMoney(dataset.data[toolTipItem.index]);
                                }
                                return label;
                            }
                        }
                    },
                    pieceLabel: {
                        render: 'percentage',
                        precision: 0,
                        fontColor: '#fff',
                        fontSize: remToPx(2),
                    },
                    onHover: (evt, hoveredElements) => {
                        this.onChartHover(hoveredElements, categoryIds)
                    },
                }
            });
        }
    };

    onChartHover = (hoveredElements, categoryIds) => {
        if (isNotNull(hoveredElements)) {
            let element = hoveredElements[0];
            if (isNotNull(element._index)) {
                let idToHover = categoryIds[element._index];
                let node = document.getElementById(idToHover);
                if (isNotNull(node)) {
                    this.removeCategoryHover(node);
                    if (!node.classList.contains('chartHovered'))
                        node.classList.add('chartHovered');
                }
            }
        } else this.removeCategoryHover(null);
    };

    removeCategoryHover = (node) => {
        const currentHovered = document.querySelectorAll('div.financialCategoryContainer.chartHovered');
        for (let i = 0; i < currentHovered.length; i++) {
            if (currentHovered[i] !== node) currentHovered[i].classList.remove('chartHovered');
        }
    };

    activateOnboarding = () => {
        this.forceUpdate();
    };

    componentDidMount = () => {
        getOnboardingManager('financial').addActivateListener('financialDataView', this.activateOnboarding);
    };

    componentDidUpdate = prevProps => {
        if (!_.isEqual(prevProps.categories, this.props.categories) && isNotNull(this.props.categories)) {
            this.renderChart();
        }
    };

    componentWillUnmount = () => {
        getOnboardingManager('financial').removeActivateListener('financialDataView', this.activateOnboarding);
    };

    render = () => {
        const {type} = this.props;
        const id = type === 'income' ? 'financialCategoriesIn' : 'financialCategoriesOut';
        return (
            <div className='financialDataView'>
                {!this.props.loaded &&
                <div className='financialLoaderOverlay'>
                    <MaterialFactory componentType={materialTypes.CIRCULAR_PROGRESS}/>
                </div>}
                <div className='financialDataHeader'>
                    <label className='financialType'>{this.getTypeLabel()}</label>
                    <label className='financialTotal'>{toMoney(this.props.totalAmount)}</label>
                </div>
                <div className='financialDataBody' id={id}>
                    {this.props.categories.map(this.renderCategory)}
                </div>
                <div className='financialDonutContainer'>
                    <canvas ref={refName => {
                        this.chartCanvas = refName
                    }} width={75} height={75}/>
                    <label className='typeTotalLabel'>{toMoney(this.props.totalAmount)}</label>
                </div>
            </div>
        )
    };
}

FinancialDataView.propTypes = {
    onSuggestionClick: PropTypes.func.isRequired,
    onItemClick: PropTypes.func.isRequired,
    selectItem: PropTypes.func.isRequired,
    selectedItems: PropTypes.arrayOf(PropTypes.object).isRequired,
    type: PropTypes.oneOf(['income', 'expense']).isRequired,
    totalAmount: PropTypes.number.isRequired,
    categories: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        total: PropTypes.number.isRequired,
        items: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            monthly_price: PropTypes.number.isRequired,
        })).isRequired,
        suggestions: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        })).isRequired,
    })).isRequired,
    loaded: PropTypes.bool.isRequired,
};