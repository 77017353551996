import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../../../redux/actions';

import {deviceIsMobile, pathIsNotNull} from 'glob-common-js/lib/utils';

import StateComponent from "../../misc/stateComponent";
import {isGrantedLogin, navigate, setReduxUser, getPlatformVersion} from "../../../misc/utils";
import MaterialMenu from "./materialMenu";
import {logout, logoutGrant} from "../../../misc/requestSender";

export class MaterialMenuController extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            menuOpen: !deviceIsMobile() && pathIsNotNull(props.user, 'email'),
        }
    }

    onOpen = () => {
        this.changeState({menuOpen: true});
    };

    onClose = () => {
        this.changeState({menuOpen: false});
    };

    onNavigateItem = (url, strip = true) => () => {
        //TODO GA
        if (deviceIsMobile()) this.onClose();
        navigate(url, strip);
    };

    onNavigateExternal = url => () => {
        //TODO GA
        window.externalLink(url);
    };

    onLogout = () => {
        if (deviceIsMobile()) this.onClose();
        if (isGrantedLogin())
            this.onLogoutGrant();
        else
            logout({
                callback: this.logoutNavigation
            });
    };

    onLogoutGrant = () => {
        logoutGrant({
            callback: (response) => {
                const {status, message, user} = response.data;
                if (status === 'success') {
                    if (message === 'logged out')
                        this.logoutNavigation();
                    else {
                        setReduxUser(user);
                        navigate('/auth/login');
                    }
                } else this.logoutNavigation();
            }
        });
    };

    logoutNavigation = () => {
        navigate('/auth/login');
        this.props.actions.setUser(null);
        this.props.actions.resetModule();
        this.props.actions.resetFinancialDossiers();
    };

    getFirstName = () => {
        const user = this.props.userState.user;
        if (pathIsNotNull(user, 'firstname')) return user.firstname;
        return '';
    };

    getGrantedFirstname = () => {
        const grantedUser = this.props.userState.grantedUser;
        return (pathIsNotNull(grantedUser, 'firstname') ? grantedUser.firstname : '');
    };

    getProfileImage = () => {
        const user = this.props.userState.user;
        if (pathIsNotNull(user, 'profile_image')) return user.profile_image;
        return '';
    };

    componentDidMount = () => {
        this.mount();
    };

    componentDidUpdate = prevProps => {
        const prevUser = prevProps.userState.user;
        const user = this.props.userState.user;
        if (pathIsNotNull(user, 'email') && !pathIsNotNull(prevUser, 'email'))
            this.changeState({menuOpen: !deviceIsMobile()});
        else if (!pathIsNotNull(user, 'email') && pathIsNotNull(prevUser, 'email'))
            this.changeState({menuOpen: false});
    };

    componentWillUnmount = () => {
        this.unMount();
    };


    render = () => (
        <MaterialMenu menuOpen={this.state.menuOpen} firstName={this.getFirstName()} onClose={this.onClose}
                      grantedFirstname={this.getGrantedFirstname()} onLogout={this.onLogout}
                      onNavigateExternal={this.onNavigateExternal} onOpen={this.onOpen}
                      onNavigateItem={this.onNavigateItem} profileImage={this.getProfileImage()}
                      platformDisplayVersion={getPlatformVersion()} {...this.props}/>
    );
}

const mapStateToProps = state => ({
    userState: state.user,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MaterialMenuController);
