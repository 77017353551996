import './style/financialOverview.scss';

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Calendar from 'rc-calendar';
import nl_NL from 'rc-calendar/lib/locale/nl_NL';

import {hasHover} from 'glob-common-react/lib/utils/reactUtils';
import {getSetting, toMoney} from 'glob-common-js/lib/utils';

import {sendGaEvent} from "../../common/js/ga";
import FinancialDataController from "./data/financialDataController";
import {deleteDossier, getAllDossiers} from "../../misc/requestSender";
import MaterialFactory from "../material/materialFactory";
import {materialTypes} from "../material/materialTypes";

export default class FinancialOverview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            calendarMode: 'month',
            itemsSelected: 0,
        };
        this._isMounted = false;
    }

    changeState = stateChange => {
        if (this._isMounted) this.setState(stateChange);
    };

    toggleCalendar = () => {
        let calendarElem = this.calendar;
        if (isNotNull(calendarElem)) {
            calendarElem.classList.toggle('show');
            document.addEventListener('click', this.calendarClickListener);
        }
    };

    calendarClickListener = () => {
        let calendar = this.calendar;
        if (isNotNull(calendar)) {
            if (!hasHover(calendar) && calendar.classList.contains('show')) {
                calendar.classList.remove('show');
                document.removeEventListener('click', this.calendarClickListener);
            }
        }
    };

    onDisplayChange = (date, mode) => {
        let disallowedModes = ['time', 'date'];
        if (!disallowedModes.includes(mode)) {
            this.changeState({calendarMode: mode})
        }
    };

    onChange = (date) => {
        let current = this.props.date;
        let month = current.month();
        let selectedMonth = date.month();
        sendGaEvent('Financieel overzicht', 'Kalender klik', 'Maand ' + selectedMonth + 1);
        if (month !== selectedMonth) {
            let calendar = this.calendar;
            if (isNotNull(calendar)) {
                calendar.classList.toggle('show');
                document.removeEventListener('click', this.calendarClickListener);
            }
            this.props.setDate(date);
        }
    };

    componentDidMount = () => {
        this._isMounted = true;
    };

    componentWillUnmount = () => {
        this._isMounted = false;
    };

    render = () => {
        let date = this.props.date;
        return (
            <div className='financialOverview'>
                <div className='financialHeader'>
                    <div className='financialHeaderRow'>
                        <div className='dateSelector'>
                            <span className='leftArrow common-icon-arrow-left' onClick={() => {
                                this.props.setMonth('previous')
                            }}/>
                            <label
                                className='dateText'
                                onClick={this.toggleCalendar}>{date.format('MMMM') + ' ' + date.year()}</label>
                            <span className='rightArrow common-icon-arrow-right' onClick={() => {
                                this.props.setMonth('next')
                            }}/>
                            <div ref={refName => {
                                this.calendar = refName
                            }} className='financialCalendar'>
                                <Calendar showDateInput={false} defaultValue={this.props.date}
                                          onPanelChange={this.onDisplayChange} onChange={this.onChange}
                                          locale={nl_NL} mode={this.state.calendarMode}
                                          className={'datePickerCalendar'}/>
                            </div>
                        </div>
                        <MaterialFactory componentType={materialTypes.RAISED_BUTTON}
                                         onClick={this.props.deleteItems} className='deleteFinancialItems'
                                         disabled={this.props.selectedItems.length === 0}>Verwijderen</MaterialFactory>
                    </div>
                    <div className='balanceContainer'>
                        <label className='balance' onClick={evt => {
                            if (evt.detail === 3 && getSetting('mode') !== 'prod') {
                                getAllDossiers({
                                    callback: (dossiers) => {
                                        for (let i = 0; i < dossiers.length; i++) {
                                            let dossier = dossiers[i];
                                            let id = dossier.id;
                                            deleteDossier({id});
                                        }
                                    }
                                });
                            }
                        }}>Saldo {toMoney(this.props.balance)}</label>
                    </div>
                </div>
                <div className='financialBody'>
                    <FinancialDataController items={this.props.items} selectItem={this.props.selectItem}
                                             selectedItems={this.props.selectedItems} loaded={this.props.loaded}/>
                </div>
            </div>
        )
    };
};

FinancialOverview.propTypes = {
    setMonth: PropTypes.func.isRequired,
    setDate: PropTypes.func.isRequired,
    deleteItems: PropTypes.func.isRequired,
    selectItem: PropTypes.func.isRequired,
    balance: PropTypes.number.isRequired,
    date: PropTypes.instanceOf(moment).isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
        category: PropTypes.object.isRequired,
        monthly_price: PropTypes.number.isRequired,
    })).isRequired,
    selectedItems: PropTypes.arrayOf(PropTypes.object).isRequired,
    loaded: PropTypes.bool.isRequired,
};