import './style/errorStep.scss';

import React from 'react';
import PropTypes from 'prop-types';

import {removeCookie} from 'glob-common-js/lib/utils';

import {navigate, setReduxUser} from "../../../../misc/utils";
import {sendGaEvent} from "../../../../common/js/ga";
import {steps} from "../../registrationConstants";
import MaterialFactory from "../../../material/materialFactory";
import {materialTypes} from "../../../material/materialTypes";

const GA_CATEGORY = 'Registreren (Foutpagina)';
export default class ErrorStep extends React.Component {
    constructor(props) {
        super(props);
    }

    onClickErrorMessage = () => {
        const container = document.querySelector('div.errorMessageContainer');
        if (isNotNull(container))
            container.classList.toggle('open');
    };

    onClickContact = () => {
        sendGaEvent(GA_CATEGORY, 'Klik', 'Neem contact op');
        window.open('https://bydehand.com/contact');
    };

    onRetryPayment = () => {
        sendGaEvent(GA_CATEGORY, 'Klik', 'Probeer betaling opnieuw');
        this.props.setStep(steps.ACTIVATION.index, steps.ACTIVATION.PAYMENT_INTRO);
    };

    onClickBack = () => {
        sendGaEvent(GA_CATEGORY, 'Klik', 'Terug naar login');
        this.clearUserInfo();
        navigate('/auth/login');
    };

    onClickWebsite = () => {
        this.props.onClickBack();
    };

    clearUserInfo = () => {
        setReduxUser({});
        removeCookie('JWT');
    };

    render = () => {
        const errorType = this.props.errorType;
        return (
            <div className='errorStep'>
                <p className='generalMessage'>
                    Er is iets fout gegaan tijdens je registratie.
                </p>
                <div className='errorMessageContainer open' onClick={this.onClickErrorMessage}>
                    <label className='detailsLabel'>Details</label>
                    <p className='errorMessage'>{this.props.errorMessage}</p>
                </div>
                <div className='errorButtonsContainer'>
                    {isNotNull(errorType) && errorType === 'payment_failed' &&
                    <MaterialFactory componentType={materialTypes.RAISED_BUTTON}
                                     className='errorButton mb' onClick={this.onRetryPayment}>Probeer
                        opnieuw</MaterialFactory>}
                    <MaterialFactory componentType={materialTypes.RAISED_BUTTON}
                                     className='errorButton mb' onClick={this.onClickContact}>Neem contact
                        op</MaterialFactory>
                    <MaterialFactory componentType={materialTypes.RAISED_BUTTON}
                                     onClick={this.onClickBack} className='errorButton mb'>Terug naar
                        login</MaterialFactory>
                    <MaterialFactory componentType={materialTypes.RAISED_BUTTON}
                                     onClick={this.onClickWebsite} className='errorButton'>Terug naar de
                        website</MaterialFactory>
                </div>
            </div>
        )
    };
}

ErrorStep.propTypes = {
    onClickBack: PropTypes.func.isRequired,
    setStep: PropTypes.func.isRequired,
    errorMessage: PropTypes.string.isRequired,
    errorType: PropTypes.string,
};