import moment from 'moment';
import {saveAs} from 'file-saver';

import {DELETE, PATCH, POST, PUT} from 'glob-common-js/lib/request';
import {debug, getSetting, isFunction, removeCookie} from 'glob-common-js/lib/utils';

import {grantNotAuthorizedCallback, sendRequest} from "./utils";
import {API_VERSIONS, createRequestConfig, setJwt} from "../common/js/platform";
import urls, {createIdUrl} from './requestConstants';
import {timeFormats} from "../common/components/datePicker";

import {store} from "../startup";
import * as actions from '../redux/actions';
import {NOW} from "../components/misc/constants";
import {ERROR, INSUFFICIENT_RIGHTS, SUCCESS, SUCCESS_CAP} from "./constants";
import Dossier from "../models/dossier";

const {LABELA_1, LABELA_2, DAISY} = API_VERSIONS;
//===========================
// Authorization
//===========================

/**
 * Ref https://bydehand.docs.apiary.io/#reference/0/authentication/logi
 */
export const login = params => {
    if (!params.keepJwt)
        deleteJwt();
    checkParams(params, 'email string', 'password string', 'callback function');
    sendRequest(createRequestConfig({
        url: urls.POST.LOGIN, method: POST, data: {
            email: params.email,
            password: params.password,
        }, name: params.name,
    }), (response) => {
        const {status, message, token} = response.data;
        setJwt(token, params.keepJwt);
        if (status === 'success' && message !== 'message(s) queued')
            setJwt(token);
        if (isFunction(params.callback))
            params.callback(response);
    })
};

/**
 * Ref https://bydehand.docs.apiary.io/#reference/0/authentication/logout
 */
export const logout = params => {
    checkParams(params, 'callback function');
    sendRequest(createRequestConfig({url: urls.GET.LOGOUT, name: params.name,}), (response) => {
        removeCookie('JWT');
        setJwt(null);
        params.callback(response);
    });
};

/**
 * Ref https://bydehand.docs.apiary.io/#reference/0/authentication/reset-password
 */
export const resetPassword = params => {
    checkParams(params, 'email string', 'callback function');
    sendRequest(createRequestConfig({
        url: urls.POST.RESET_PASSWORD,
        method: POST,
        data: {email: params.email}, name: params.name,
    }), params.callback);
};

/**
 * Ref https://bydehand.docs.apiary.io/#reference/0/authentication/change-password
 * Optional: Request name (used to cancel the request)
 */
export const changePassword = params => {
    checkParams(params, 'data any', 'callback function');
    checkParams(params.data, 'activation_key string', 'password string');
    checkOptionalParams(params, 'name string');
    sendRequest(createRequestConfig({
        url: urls.POST.CHANGE_PASSWORD,
        method: POST,
        data: {
            activation_key: params.data.activation_key,
            password: params.data.password,
            password_repeat: params.data.password
        },
        name: params.name,
    }), params.callback);
};

/**
 * Ref https://bydehand.docs.apiary.io/#reference/0/registration/username-availability
 */
export const checkUsername = params => {
    checkParams(params, 'username string', 'jwt_token string', 'callback function');
    checkOptionalParams(params, 'keepToken bool');
    if (!params.keepToken)
        deleteJwt();
    sendRequest(createRequestConfig({
        url: urls.POST.CHECK_USERNAME,
        method: POST,
        data: {username: params.username}, name: params.name,
        customHeaders: {Authorization: 'JWT ' + params.jwt_token},
    }), params.callback);
};

/**
 * Ref https://bydehand.docs.apiary.io/#reference/0/registration/register-account
 * Optional: infix, lastname, gender, tag_0, tag_1, tag_2
 */
export const register = params => {
    deleteJwt();
    checkParams(params, 'data any', 'callback function');
    checkParams(params.data, 'firstname string', 'email string', 'password string');
    checkOptionalParams(params, 'infix string', 'lastname string', 'gender number', 'tag_0 string', 'tag_1 string',
        'tag_2 string');
    // noinspection LocalVariableNamingConventionJS
    let skip_validation = //isNotNull(getSetting('skipMailValidation')) ? getSetting('skipMailValidation') :
        isNotNull(params.data.skip_validation) ? params.data.skip_validation : false;
    let data = {
        ...params.data,
        password_repeat: params.data.password,
        terms_accepted: true,
        skip_validation,
        referral: null,
        infix: params.data.infix || '',
        lastname: params.data.lastname || '',
        gender: params.data.gender || 0,
        tag_0: params.data.tag_0 || '',
        tag_1: params.data.tag_1 || '',
        tag_2: params.data.tag_2 || '',
    };
    if (['dev', 'test'].includes(getSetting('mode'))) {
        delete data.tag_0;
        delete data.tag_1;
        delete data.tag_2;
    }
    sendRequest(createRequestConfig({
        url: urls.POST.REGISTER, method: POST, data, name: params.name,
    }), (response) => {
        if (pathIsNotNull(response, 'data.token'))
            setJwt(response.data.token);
        params.callback(response);
    })
};

/**
 * Activate the account with the activation key.
 * @param params
 */
export const activateAccount = params => {
    deleteJwt();
    checkParams(params, 'activationKey string', 'callback function');
    sendRequest(createRequestConfig({
        url: urls.GET.ACTIVATE_ACCOUNT + params.activationKey,
        withoutApi: true
    }), params.callback);
};

/**
 * Ref https://bydehand.docs.apiary.io/#reference/0/authentication/confirm-2fa-token
 * Optional: request name (used to cancel the request)
 */
export const confirmLogin = params => {
    checkParams(params, 'email string', 'password string', 'auth_token string', 'callback function');
    checkOptionalParams(params, 'name string');
    sendRequest(createRequestConfig({
        url: urls.POST.CONFIRM_LOGIN,
        data: {email: params.email, password: params.password, auth_token: params.auth_token, auth_method: 'sms'},
        method: POST,
        name: params.name,
    }), (response) => {
        setJwt(response.data.token);

        if (isFunction(params.callback))
            params.callback(response)
    });
};

//===========================
// Dossiers
//===========================

/**
 * Ref https://bydehand.docs.apiary.io/#reference/0/belonging/list-all-belongings
 * Optional: queryParams
 */
export const getAllDossiers = params => {
    checkParams(params, 'callback function');
    checkOptionalParams(params, 'queryParams string', 'asInstance boolean');
    let queryParams = isNotNull(params.queryParams) ? '?' + params.queryParams : '';
    sendRequest(createRequestConfig({url: urls.GET.ALL_DOSSIERS + queryParams, name: params.name,}), (response) => {
        let dossiers = response.data;
        for (let i = 0; i < dossiers.length; i++) {
            dossiers[i] = sortDossierValues(dossiers[i]);
            if (params.asInstance) dossiers[i] = new Dossier(dossiers[i]);
        }
        params.callback(dossiers, response);
    });
};

export const sortDossierValues = dossier => {
    if (isNotNull(dossier) && isNotNull(dossier.external_data) && isNotNull(dossier.external_data.values) &&
        Array.isArray(dossier.external_data.values) && dossier.external_data.values.length > 1) {
        dossier.external_data.values.sort((valueA, valueB) => {
            const preferA = -1, preferB = 1, isEqual = 0;
            let startA = valueA.start_date;
            let startB = valueB.start_date;

            if (isNotNull(startA) && isNull(startB)) return preferA;
            if (isNull(startA) && isNotNull(startB)) return preferB;
            if (isNull(startA) && isNull(startB)) return isEqual;
            let momentA = moment(startA, timeFormats);
            let momentB = moment(startB, timeFormats);
            return momentA.isAfter(momentB) ? preferA : momentA.isBefore(momentB) ? preferB : isEqual;
        });
    }
    return dossier;
};

/**
 * Ref https://bydehand.docs.apiary.io/#reference/0/belonging/list-a-belonging
 */
export const getDossier = params => {
    checkParams(params, 'id string', 'callback function');
    sendRequest(createRequestConfig({url: urls.GET.DOSSIER + params.id, name: params.name,}), (response) => {
        let dossier = response.data;
        params.callback(sortDossierValues(dossier));
    });
};

/**
 * Ref https://bydehand.docs.apiary.io/#reference/0/belonging/delete-a-belonging
 * Optional: callback
 */
export const deleteDossier = params => {
    checkParams(params, 'id string');
    checkOptionalParams(params, 'callback function');
    sendRequest(createRequestConfig({
        url: urls.DELETE.DOSSIER + params.id,
        method: DELETE,
        name: params.name,
    }), () => {
        store.dispatch(actions.deleteFinancialDossier(params.id));
        unlinkDossierContacts({id: params.id, callback: params.callback});
    });
};

/**
 * Ref https://bydehand.docs.apiary.io/#reference/0/belonging/update-a-belonging
 * Optional: external_data
 */
export const updateDossier = params => {
    checkParams(params, 'data any', 'id string');
    checkParams(params.data, 'title string', 'type any');
    checkOptionalParams(params.data, 'external_data any', 'callback function');
    sendRequest(createRequestConfig({
        url: urls.PUT.DOSSIER + params.id, method: PUT, data: {
            belonging: {
                ...params.data,
            }
        }, name: params.name,
    }), (response) => {
        const dossier = sortDossierValues(response.data);
        if (isFunction(params.callback)) {
            params.callback(dossier);
        }
    });
};

/**
 * Ref https://bydehand.docs.apiary.io/#reference/0/belonging/create-belonging
 * Optional: Request name (used to cancel the request), callback
 */
export const createDossier = params => {
    checkParams(params, 'data any');
    checkParams(params.data, 'name string', 'type string');
    checkOptionalParams(params, 'name string', 'callback function', 'errorCallback function');
    params.data.values = params.data.values || {};
    sendRequest(createRequestConfig({
        url: urls.POST.DOSSIER,
        method: POST,
        data: {belonging: params.data},
        name: params.name,
    }), (response) => {
        const dossier = sortDossierValues(response.data.belonging);
        if (isFunction(params.callback)) {
            params.callback(dossier, response);
        }
    }, params.errorCallback);
};

/**
 * Ref https://bydehand.docs.apiary.io/#reference/0/belonging-types/belonging-types
 * Optional: queryParams (id, category, name)
 */
export const getDossierTypes = params => {
    checkParams(params, 'callback function');
    checkOptionalParams(params, 'queryParams string');
    let queryParams = params.queryParams || '';
    sendRequest(createRequestConfig({url: urls.GET.DOSSIER_TYPES + queryParams, name: params.name,}), params.callback);
};

export const sendTravelContactInfo = params => {
    checkParams(params, 'callback function', 'id string', 'data any');
    checkParams(params.data, 'recipients array', 'type string', 'blocks any');
    checkOptionalParams(params.data, 'message string');

    sendRequest(createRequestConfig({
        url: createIdUrl('POST', 'TRAVEL_CONTACT', params.id),
        method: POST,
        data: params.data
    }), params.callback);
};

//===========================
// Account
//===========================

export const sendWelcome = params => {
    checkParams(params, 'firstname string');
    checkOptionalParams(params, 'callback function');
    sendRequest(createRequestConfig({
        url: urls.POST.WELCOME,
        method: POST,
        apiVersion: DAISY,
        data: {firstname: params.firstname},
    }), params.callback);
};

/**
 * Ref https://bydehand.docs.apiary.io/#reference/0/account/get-account
 */
export const getAccount = params => {
    checkParams(params, 'callback function');
    checkOptionalParams(params, 'unauthorizedCallback function');
    sendRequest(createRequestConfig({
        url: urls.GET.ACCOUNT,
        name: params.name,
    }), params.callback, params.unauthorizedCallback);
};

/**
 * Ref https://bydehand.docs.apiary.io/#reference/0/account/delete-account
 */
export const deleteAccount = params => {
    checkParams(params, 'id string', 'callback function');
    sendRequest(createRequestConfig({
        url: urls.DELETE.ACCOUNT + params.id,
        method: DELETE,
        name: params.name,
    }), params.callback);

};

/**
 * Ref https://bydehand.docs.apiary.io/#reference/0/account/update-account
 */
export const updateAccount = params => {
    checkParams(params, 'data any', 'id string');
    checkParams(params.data, 'firstname string', 'email string');
    checkOptionalParams(params, 'callback function', 'username string');
    let dob = params.data.dob;
    dob = isNotNull(dob) ? moment(dob, timeFormats).format('MM-DD-YYYY') : '';
    sendRequest(createRequestConfig({
        url: urls.PUT.ACCOUNT + params.id, method: PUT, data: {
            account: {
                ...params.data,
                // Optional
                external_data: params.data.external_data || {},
                address: params.data.address || {},
                dob,
                lastname: params.data.lastname || '',
                phone_number: params.data.phone_number || '',
                phone_mobile: params.data.phone_mobile || null,
                bsn: params.data.bsn || '',
                gender: isNotNull(params.data.gender) ? params.data.gender : '', // Empty string gets ignored by backend
                infix: params.data.infix || '',
                two_step_auth: isNotNull(params.data.two_step_auth) ? params.data.two_step_auth : '', // Empty string gets ignored by backend
            }
        }, name: params.name,
    }), (response) => {
        let dob = response.data.dob;
        if (isNotNull(dob)) {
            dob = moment(dob, 'MM-DD-YYYY').format('YYYY-MM-DDTHH:mm:ss');
            response.data.dob = dob;
        }
        if (isFunction(params.callback))
            params.callback(response);
    }, params.errorCallback);
};

export const updateProfileImage = params => {
    checkParams(params, 'profileImage any');
    checkOptionalParams(params, 'callback function');
    const data = new FormData();
    data.append('file', params.profileImage);
    sendRequest(createRequestConfig({
        url: urls.POST.PROFILE_IMAGE,
        method: POST,
        data
    }), params.callback)
};

/**
 * Ref https://bydehand.docs.apiary.io/#reference/0/account/get-external-data
 */
export const getExternalData = params => {
    checkParams(params, 'callback function');
    sendRequest(createRequestConfig({url: urls.GET.EXTERNAL_DATA}), params.callback);
};

/**
 * Ref https://bydehand.docs.apiary.io/#reference/0/account/update-external-data
 */
export const updateExternalData = params => {
    checkParams(params, 'external_data any', 'callback function');
    sendRequest(createRequestConfig({
        url: urls.PUT.EXTERNAL_DATA,
        method: PUT,
        data: {external_data: params.external_data}
    }), params.callback);
};

/**
 * Ref https://bydehand.docs.apiary.io/#reference/0/account/update-mobile-number
 */
export const updateMobile = params => {
    checkParams(params, 'phone_mobile string', 'callback function');
    sendRequest(createRequestConfig({
        url: urls.POST.MOBILE_NUMBER,
        method: POST,
        data: {phone_mobile: params.phone_mobile}, name: params.name,
    }), params.callback);
};

/**
 * Ref https://bydehand.docs.apiary.io/#reference/0/account/confirm-mobile-number
 */
export const confirmMobile = params => {
    checkParams(params, 'id string', 'auth_token string', 'phone_mobile string', 'callback function');
    sendRequest(createRequestConfig({
        url: urls.PUT.CONFIRM_MOBILE + params.id, method: PUT, data: {
            auth_token: params.auth_token,
            phone_mobile: params.phone_mobile,
        }, name: params.name,
    }), params.callback);
};

/**
 * Ref https://bydehand.docs.apiary.io/#reference/0/account/get-family
 */
export const getFamily = params => {
    checkParams(params, 'callback function');
    sendRequest(createRequestConfig({url: urls.GET.FAMILY, name: params.name,}), params.callback);
};

/**
 * Ref https://bydehand.docs.apiary.io/#reference/0/account/update-family
 */
export const updateFamily = params => {
    checkParams(params, 'id string', 'data any', 'callback function');
    sendRequest(createRequestConfig({
        url: urls.PUT.FAMILY + params.id,
        method: PUT,
        data: {family: params.data},
        name: params.name,
    }), params.callback);
};

/**
 * TODO Create documentation ref
 */
export const deleteFamily = params => {
    checkParams(params, 'id string', 'callback function');
    sendRequest(createRequestConfig({
        url: urls.DELETE.FAMILY + params.id,
        method: DELETE,
    }), params.callback);
};

/**
 * Ref https://bydehand.docs.apiary.io/#reference/0/account/add-family
 */
export const addFamily = params => {
    checkParams(params, 'data any', 'callback function');
    sendRequest(createRequestConfig({
        url: urls.POST.FAMILY,
        method: POST,
        data: {family: params.data},
        name: params.name,
    }), params.callback);
};

//===========================
// Documents
//===========================

/**
 * Ref https://bydehand.docs.apiary.io/#reference/0/vault/vault-overview
 * Optional: queryParams
 */
export const getAllDocuments = params => {
    checkParams(params, 'callback function');
    checkOptionalParams(params, 'queryParams string');
    let queryParams = params.queryParams || '';
    sendRequest(createRequestConfig({url: urls.GET.DOCUMENTS + queryParams, name: params.name,}), params.callback);
};

/**
 * Ref https://bydehand.docs.apiary.io/#reference/0/vault/vault-item-detail
 */
export const getDocument = params => {
    checkParams(params, 'id string', 'callback function');
    sendRequest(createRequestConfig({url: urls.GET.DOCUMENT + params.id, name: params.name,}), params.callback);
};

/**
 * Used to update a document and/or link dossier(s) to a document
 * @param params
 */
export const updateDocument = params => {
    checkParams(params, 'data any', 'id string', 'callback function');
    checkParams(params.data, 'label string', 'type any', 'belonging_ids array');
    let external_data = params.data.external_data || {};
    external_data.updated_at = NOW().format('YYYY-MM-DDTHH:mm:ss');
    sendRequest(createRequestConfig({
        url: urls.PUT.DOCUMENT + params.id, method: PUT, data: {
            vault: {
                ...params.data,
                label: params.data.label,
                type: params.data.type,
                belonging_ids: params.data.belonging_ids,
                external_data,
            }
        }, name: params.name,
    }), (response) => {
        const doc = response.data;
        if (isFunction(params.callback))
            params.callback(response);
    });
};

/**
 * Uploading a file and creating a document with it. This is done by sending two requests:
 * Upload file: Ref https://bydehand.docs.apiary.io/#reference/0/vault/upload-file
 * Create document: Ref https://bydehand.docs.apiary.io/#reference/0/vault/create-document
 *
 * Will throw an error when uploading the file fails.
 */
export const uploadDocument = params => {
    checkParams(params, 'data any', 'file any', 'callback function');
    checkParams(params.data, 'belonging_ids array', 'file_type_id string', 'label string');
    let data = new FormData();
    data.append('file', params.file);
    data.append('type_id', params.data.file_type_id);
    sendRequest(createRequestConfig({
        url: urls.POST.FILE, method: POST, data, name: params.name,
    }), response => {
        if (response.data.status === 'success') {
            // noinspection LocalVariableNamingConventionJS
            let new_file_id = response.data.file_id;
            params.responseData = {file_id: new_file_id, label: params.data.label};
            sendRequest(createRequestConfig({
                url: urls.POST.DOCUMENT, method: POST, data: {
                    vault: {
                        ...params.data,
                        new_file_id,
                        families: [],
                    }
                }, name: params.name,
            }), (response) => {
                params.callback(params, response)
            });
        } else {
            throw new Error('Uploading file failed.');
        }
    });
};

export const postFile = params => {
    checkParams(params, 'file any', 'type_id string', 'callback function');
    const data = new FormData();
    data.append('file', params.file);
    data.append('type_id', params.type_id);
    sendRequest(createRequestConfig({
        url: urls.POST.FILE,
        data,
        method: POST,
    }), params.callback);
};

export const createDocument = params => {
    checkParams(params, 'file_id string', 'belonging_ids array', 'type_id string', 'label string');
    sendRequest(createRequestConfig({
        url: urls.POST.DOCUMENT,
        method: POST,
        data: {
            vault: {
                belonging_ids: params.belongings_ids,
                external_data: params.external_data,
                families: [],
                fields: [],
                file_type_id: params.type_id,
                new_file_id: params.file_id,
                label: params.label,
            }
        },
    }), params.callback);
};

/**
 * Ref https://bydehand.docs.apiary.io/#reference/0/vault/download-a-file
 */
export const getDocumentUrl = params => {
    checkParams(params, 'id string', 'callback function');
    checkOptionalParams(params, 'errorCallback function');
    sendRequest(createRequestConfig({
        url: urls.GET.DOCUMENT_URL + params.id,
        name: params.name,
    }), params.callback, params.errorCallback);
};

/**
 * Ref https://bydehand.docs.apiary.io/#reference/0/vault/delete-vault-item
 * Optional: callback
 */
export const deleteDocument = params => {
    checkParams(params, 'id string');
    checkOptionalParams(params, 'callback function');
    sendRequest(createRequestConfig({
        url: urls.DELETE.DOCUMENT + params.id,
        method: DELETE,
        name: params.name,
    }), (response) => {
        if (isFunction(params.callback))
            params.callback(response);
    });
};

export const downloadDocuments = params => {
    checkOptionalParams(params, 'callback function', 'file_ids array', 'dossier_ids array');
    toggleWaitCursor(true);
    const data = createDownloadData(params);
    sendRequest(createRequestConfig({
        url: urls.POST.DOWNLOAD_DOCUMENTS,
        method: POST,
        data,
        responseType: 'blob',
    }), (response) => {
        toggleWaitCursor(false);
        const {type} = response.data;
        if (type === 'application/json')
            grantNotAuthorizedCallback(INSUFFICIENT_RIGHTS);
        else {
            const dataBlob = new Blob([response.data], {type: 'application/zip'});
            const fileName = 'ByDeHand documenten.zip';
            saveAs(dataBlob, fileName);
        }
    });
};

export const shareDocuments = params => {
    checkParams(params, 'recipients array');
    checkOptionalParams(params, 'callback function', 'description string');
    sendRequest(createRequestConfig({
        url: urls.POST.SHARE_DOCUMENTS,
        method: POST,
        data: Object.assign({}, createDownloadData(params), {
            recipients: params.recipients,
            description: params.description,
        }),
    }), params.callback)
};

const toggleWaitCursor = (enable) => {
    debug('Toggle wait cursor');
    document.documentElement.classList.toggle('wait', enable);
};

const createDownloadData = params => {
    let data = {};
    data = addDownloadParam(data, params, 'file_ids');
    data = addDownloadParam(data, params, 'dossier_ids');
    return data;
};

const addDownloadParam = (data, params, name) => {
    if (isNotNull(params[name]))
        data[name] = params[name];
    return data;
};

//===========================
// Tender
//===========================

export const getTenderInfo = params => {
    checkParams(params, 'id string', 'callback function');
    sendRequest(createRequestConfig({
        url: urls.GET.TENDER + params.id,
        apiVersion: LABELA_2,
        name: params.name,
    }), params.callback);
};

export const sendTender = params => {
    checkParams(params, 'dossierId string', 'data any', 'callback function');
    checkParams(params.data, 'type string', 'contact_method string', 'metadata any', 'user any');
    sendRequest(createRequestConfig({
        url: urls.POST.TENDER + params.dossierId,
        method: POST,
        apiVersion: LABELA_2,
        data: params.data
    }), params.callback);
};

export const getCarAnalyze = params => {
    checkParams(params, 'id string', 'callback function');
    sendRequest(createRequestConfig({
        url: urls.GET.CAR_ANALYZE + params.id,
    }), params.callback)
};

//===========================
// TimeItems
//===========================

/**
 * Ref https://bydehanddaisy.docs.apiary.io/#reference/0/timeitems/create-a-timeitem
 * Optional: queryParams
 */
export const getAllTimeItems = params => {
    checkParams(params, 'callback function');
    checkOptionalParams(params, 'queryParams string');
    let queryParams = params.queryParams || '';
    sendRequest(createRequestConfig({
        url: urls.GET.TIMEITEMS + queryParams,
        apiVersion: DAISY,
        name: params.name,
    }), params.callback);
};

/**
 * Ref https://bydehanddaisy.docs.apiary.io/#reference/0/timeitems/list-timeitem
 */
export const getTimeItem = params => {
    checkParams(params, 'id string', 'callback function');
    sendRequest(createRequestConfig({
        url: urls.GET.TIMEITEM + params.id,
        apiVersion: DAISY,
        name: params.name,
    }), params.callback);
};

/**
 * Ref https://bydehanddaisy.docs.apiary.io/#reference/0/timeitems/create-a-timeitem
 * Optional: description, end_date_item, flex, frequency (default 0) and location
 */
export const createTimeItem = params => {
    checkParams(params, 'data any', 'callback function');
    checkParams(params.data, 'name string', 'start_date_item string', 'type number');
    checkOptionalParams(params, 'description string', 'end_date_item string', 'flex any', 'frequency number',
        'location string');
    sendRequest(createRequestConfig({
        url: urls.POST.TIMEITEM,
        apiVersion: DAISY,
        method: POST,
        data: {
            ...params.data,
            description: params.data.description || '',
            end_date_item: params.data.end_date_item || '',
            flex: params.data.flex || {},
            frequency: params.data.frequency || 0,
            location: params.data.location || '',
        },
    }), params.callback);
};

/**
 * Create multiple time items
 * TODO REF
 */
export const createTimeItems = params => {
    checkParams(params, 'timeItems array');
    checkOptionalParams(params, 'callback function');
    sendRequest(createRequestConfig({
        url: urls.POST.TIMEITEMS,
        apiVersion: DAISY,
        method: POST,
        data: params.timeItems,
    }), params.callback);
};

/**
 * Ref https://bydehanddaisy.docs.apiary.io/#reference/0/timeitems/update-timeitem
 * Optional: description, end_date_item, flex, frequency (default 0) and location
 */
export const updateTimeItem = params => {
    params.data.type = typeof params.data.type === 'number' ? params.data.type.toString() : params.data.type;
    checkParams(params, 'id string', 'data any', 'callback function');
    checkParams(params.data, 'name string', 'start_date_item string', 'type string');
    checkOptionalParams(params, 'description string', 'end_date_item string', 'flex any', 'frequency number',
        'location string');
    sendRequest(createRequestConfig({
        url: urls.PUT.TIMEITEM + params.id, apiVersion: DAISY, method: PUT, data: {
            ...params.data,
            description: params.data.description || '',
            end_date_item: params.data.end_date_item || '',
            flex: params.data.flex || {},
            frequency: params.data.frequency || 0,
            location: params.data.location || '',
        },
    }), params.callback);
};

/**
 * Update multiple timeitems
 */
export const updateTimeItems = params => {
    checkParams(params, 'timeitems array');
    checkOptionalParams(params, 'callback function');
    sendRequest(createRequestConfig({
        url: urls.PATCH.TIMEITEMS,
        method: PATCH,
        apiVersion: DAISY,
        data: params.timeitems,
    }), params.callback);
};

/**
 * Ref https://bydehanddaisy.docs.apiary.io/#reference/0/timeitems/delete-timeitem
 * Optional: callback
 */
export const deleteTimeItem = params => {
    checkParams(params, 'id string');
    checkOptionalParams(params, 'callback function');
    sendRequest(createRequestConfig({
        url: urls.DELETE.TIMEITEM + params.id,
        apiVersion: DAISY,
        method: DELETE, name: params.name,
    }), params.callback);
};

//===========================
// SUBSCRIPTION
//===========================

export const getAllIssuers = params => {
    checkParams(params, 'callback function');
    sendRequest(createRequestConfig({
        url: urls.GET.ISSUERS,
        apiVersion: DAISY,
    }), params.callback)
};

export const getDaisyUser = params => {
    getSubscriptionStatus(params);
};

export const getSubscriptionStatus = params => {
    checkParams(params, 'callback function');
    sendRequest(createRequestConfig({
        url: urls.GET.SUBSCRIPTION_USER,
        apiVersion: DAISY,
    }), params.callback);
};

export const validateDaisyUser = params => {
    checkOptionalParams(params, 'callback function');
    sendRequest(createRequestConfig({
        url: urls.GET.VALIDATE,
        apiVersion: DAISY,
    }), params.callback);
};

export const updateDaisyUser = params => {
    checkParams(params, 'data any');
    checkOptionalParams(params, 'callback function');
    sendRequest(createRequestConfig({
        url: urls.PUT.SUBSCRIPTION_USER,
        apiVersion: DAISY,
        method: PUT,
        data: params.data,
    }), params.callback);
};

export const addSubscriptionUser = params => {
    checkParams(params, 'user_name string', 'birthday string', 'email string');
    checkOptionalParams(params, 'name string', 'firstname string', 'callback function', 'jwt_token string',
        'action_code string');
    sendRequest(createRequestConfig({
        url: urls.POST.SUBSCRIPTION_USER,
        apiVersion: DAISY,
        method: POST,
        data: {
            user_name: params.user_name,
            name: params.name,
            birthday: params.birthday,
            trial: params.trial,
            tags: params.tags,
            email: params.email,
            firstname: params.firstname,
            action_code: params.action_code
        },
        customHeaders: isNotNull(params.jwt_token) ? {Authorization: 'JWT ' + params.jwt_token} : {},
    }), params.callback);
};

export const addSubscription = params => {
    checkParams(params, 'callback function', 'data any');
    checkParams(params.data, 'issuer_id string', 'issuer_name string');
    checkOptionalParams(params.data, 'user_name string');
    sendRequest(createRequestConfig({
        url: urls.POST.SUBSCRIPTION,
        apiVersion: DAISY,
        method: POST,
        data: params.data,
    }), params.callback);
};

export const deleteSubscription = params => {
    checkParams(params, 'callback function');
    sendRequest(createRequestConfig({
        url: urls.DELETE.SUBSCRIPTION,
        method: DELETE,
        apiVersion: DAISY
    }), params.callback);
};

export const deleteSubscriptionUser = (params) => {
    checkOptionalParams(params, 'delete_reason string', 'callback function');
    sendRequest(createRequestConfig({
        url: urls.DELETE.USER,
        method: DELETE,
        data: params.data,
        apiVersion: DAISY,
    }), params.callback);
};

export const getUserFlex = params => {
    checkParams(params, 'callback function');
    sendRequest(createRequestConfig({
        url: urls.GET.USER_FLEX,
        apiVersion: DAISY,
    }), params.callback);
};

export const getUserAccess = params => {
    checkParams(params, 'callback function');
    sendRequest(createRequestConfig({
        url: urls.GET.USER_ACCESS,
        apiVersion: DAISY,
    }), params.callback);
};

//===========================
// GRANTED ACCESS
//===========================

export const getAccountAccessGrants = params => {
    checkParams(params, 'callback function');
    sendRequest(createRequestConfig({
        url: urls.GET.GRANT,
    }), params.callback)
};

export const addGrant = params => {
    checkParams(params, 'email string');
    checkOptionalParams(params, 'callback function', 'rights array');
    sendRequest(createRequestConfig({
        url: urls.POST.GRANT,
        data: {grant_to: params.email, rights: params.rights || ['read', 'write']},
        method: POST,
    }), params.callback);
};

export const updateGrant = params => {
    checkParams(params, 'data any');
    checkParams(params.data, 'granted_id string');
    checkOptionalParams(params, 'callback function');
    sendRequest(createRequestConfig({
        url: urls.POST.GRANT,
        data: params.data,
        method: PUT,
    }), params.callback);
};

export const acceptGrant = params => {
    checkParams(params, 'granted_by string');
    checkOptionalParams(params, 'callback function');
    sendRequest(createRequestConfig({
        url: urls.POST.ACCEPT_GRANT,
        data: {granted_by: params.granted_by},
        method: POST,
    }), params.callback);
};

export const deleteGrant = params => {
    checkParams(params, 'granted_to string', 'granted_by string');
    checkOptionalParams(params, 'callback function');
    sendRequest(createRequestConfig({
        url: urls.DELETE.GRANT,
        data: {granted_to: params.granted_to, granted_by: params.granted_by},
        method: DELETE,
    }), params.callback);
};

export const loginGrant = params => {
    checkParams(params, 'granted_user_id string', 'callback function');
    sendRequest(createRequestConfig({
        url: urls.POST.LOGIN_GRANT,
        data: {granted_user_id: params.granted_user_id},
        method: POST,
    }), (response) => {
        if (response.data.status === 'success')
            setJwt(response.data.token);
        if (isFunction(params.callback))
            params.callback(response)
    });
};

export const getGrantedUser = params => {
    checkParams(params, 'callback function');
    sendRequest(createRequestConfig({
        url: urls.GET.GRANTED_USER,
    }), params.callback);
};

export const logoutGrant = params => {
    checkParams(params, 'callback function');
    sendRequest(createRequestConfig({
        url: urls.POST.LOGOUT_GRANT,
        method: POST,
        data: {}
    }), (response) => {
        const {status, message, token} = response.data;
        store.dispatch(actions.setGrantedUser(null));
        if ((status === ERROR && message === 'Grant user not found') || (status === SUCCESS && message === 'logged out'))
            removeCookie('JWT');
        else if (status === SUCCESS && isNotNull(token))
            setJwt(token);

        if (isFunction(params.callback))
            params.callback(response)
    })
};

//===========================
// Temporary
//===========================
export const getMailList = params => {
    checkParams(params, 'filters array', 'filter_type string', 'callback function');
    sendRequest(createRequestConfig({
        url: urls.POST.MAIL_LIST,
        method: POST,
        data: {
            filters: params.filters,
            filter_type: params.filter_type,
        }
    }), params.callback)
};

export const getTemplates = params => {
    checkParams(params, 'callback function');
    sendRequest(createRequestConfig({
        url: urls.GET.MAIL_TEMPLATES
    }), params.callback)
};

export const sendTemplateMail = params => {
    checkParams(params, 'template string', 'dryRun boolean');
    checkOptionalParams(params, 'filters array', 'mergeVars array', 'callback function', 'subject string',
        'tags array', 'attachments array', 'filter_type string', 'testMode boolean');
    sendRequest(createRequestConfig({
        url: urls.POST.MAIL_SEND,
        method: POST,
        data: {
            template_name: params.template, // Name of the template to send
            filters: params.filters, // Array of rules to determine recipients
            filter_type: params.filter_type, // Optional filter type
            merge_vars: params.mergeVars, // Optional merge vars
            subject: params.subject, // Optional subject
            tags: params.tags, // Optional tags
            attachments: params.attachments, // Optional attachments
            dryRun: params.dryRun,
            testMode: params.testMode || false, // Whether to send mail as a test or not
        }
    }), params.callback)
};

export const getMailTemplates = params => {
    checkParams(params, 'callback function');
    sendRequest(createRequestConfig({
        url: urls.GET.MAIL_TEMPLATES
    }), params.callback);
};

export const addTask = params => {
    checkParams(params, 'data any');
    checkParams(params.data, 'name string', 'body any', 'activationDate string', 'function string',
        'frequencyType string', 'frequency any');
    checkOptionalParams(params, 'callback function');
    const {data} = params;
    sendRequest(createRequestConfig({
        url: urls.POST.TASK,
        method: POST,
        data: {
            name: data.name,
            rule_body: data.body,
            activation_date: data.activationDate,
            function_type: data.function,
            frequency_type: data.frequencyType,
            frequency: data.frequency,
        }
    }), params.callback)
};

//===========================
// CONTACTS
//===========================
export const addContact = params => {
    checkParams(params, 'data any');
    checkOptionalParams(params, 'callback function');
    sendRequest(createRequestConfig({
        url: urls.POST.CONTACT,
        apiVersion: DAISY,
        method: POST,
        data: params.data,
    }), params.callback);
};

export const getContact = params => {
    checkParams(params, 'id string', 'callback function');
    sendRequest(createRequestConfig({
        url: urls.GET.CONTACT + params.id,
        apiVersion: DAISY,
    }), params.callback);
};

export const getContacts = params => {
    checkParams('callback function');
    sendRequest(createRequestConfig({
        url: urls.GET.CONTACTS,
        apiVersion: DAISY,
    }), params.callback);
};

export const getDossierContacts = params => {
    checkParams(params, 'id string', 'callback function');
    sendRequest(createRequestConfig({
        url: urls.GET.DOSSIER_CONTACTS + params.id,
        apiVersion: DAISY,
    }), params.callback);
};

export const updateContact = params => {
    checkParams(params, 'id string', 'data any');
    checkOptionalParams(params, 'callback function');
    sendRequest(createRequestConfig({
        url: urls.PUT.CONTACT + params.id,
        apiVersion: DAISY,
        method: PUT,
        data: params.data,
    }), params.callback);
};

export const deleteContact = params => {
    checkParams(params, 'id string');
    checkOptionalParams(params, 'callback function');
    sendRequest(createRequestConfig({
        url: urls.DELETE.CONTACT + params.id,
        apiVersion: DAISY,
        method: DELETE,
    }), params.callback);
};

export const deleteContacts = params => {
    checkParams(params, 'ids array');
    checkOptionalParams(params, 'callback function');
    sendRequest(createRequestConfig({
        url: urls.POST.CONTACTS,
        apiVersion: DAISY,
        method: POST,
        data: {ids: params.ids}
    }))
};

export const unlinkDossierContacts = params => {
    checkParams(params, 'id string');
    checkOptionalParams(params, 'callback function');
    sendRequest(createRequestConfig({
        url: urls.DELETE.DOSSIER_CONTACTS + params.id,
        apiVersion: DAISY,
        method: DELETE,
    }), params.callback);
};

//===========================
// Action code
//===========================
export const addActionCode = params => {
    checkParams(params, 'data any', 'callback function');
    checkParams(params.data, 'code string', 'max_uses integer', 'description string',
        'subscription_period any');
    checkOptionalParams(params.data, 'valid_until string');
    sendRequest(createRequestConfig({
        url: urls.POST.ACTION_CODE,
        apiVersion: DAISY,
        method: POST,
        data: params.data,
    }), params.callback)
};

export const getActionCode = params => {
    checkParams(params, 'id string', 'callback function');
    sendRequest(createRequestConfig({
        url: urls.GET.ACTION_CODE + params.id,
        apiVersion: DAISY,
    }), params.callback);
};

export const getAllActionCodes = params => {
    checkParams(params, 'callback function');
    sendRequest(createRequestConfig({
        url: urls.GET.ALL_ACTION_CODES,
        apiVersion: DAISY,
    }), params.callback);
};

export const updateActionCode = params => {
    checkParams(params, 'data any', 'callback function', 'id string');
    checkOptionalParams(params, 'code string', 'max_uses integer', 'description string',
        'subscription_period any', 'valid_until string');
    sendRequest(createRequestConfig({
        url: urls.PUT.ACTION_CODE + params.id,
        apiVersion: DAISY,
        method: PUT,
        data: params.data,
    }), params.callback)
};

export const deleteActionCode = params => {
    checkParams(params, 'id string', 'callback function');
    sendRequest(createRequestConfig({
        url: urls.DELETE.ACTION_CODE + params.id,
        apiVersion: DAISY,
        method: DELETE,
    }), params.callback)
};

//===========================
// Mailing list / Admin
//===========================

/**
 * Generate a mailing list using various parameters.
 * Parameters must be an object containing keys of the backend user model.
 */
export const generateMailingList = params => {
    checkParams(params, 'callback function');
    checkOptionalParams(params, 'parameters any');
    sendRequest(createRequestConfig({
        url: urls.POST.MAILING,
        apiVersion: DAISY,
        method: POST,
        data: params.parameters,
    }), params.callback);
};

export const checkAdminUser = params => {
    checkOptionalParams(params, 'callback function');
    sendRequest(createRequestConfig({
        url: urls.GET.ADMIN_CHECK,
        apiVersion: DAISY,
    }), params.callback);
};

export const getAdminUsers = params => {
    checkParams(params, 'callback function');
    sendRequest(createRequestConfig({
        url: urls.GET.ADMIN_USERS,
        withoutApi: true,
    }), params.callback);
};

//===========================
// HELPERS
//===========================

export const responseIsSuccess = response => {
    if (isNull(response)) throw new Error('Response is undefined or null');
    const {status, message} = response.data;
    if (isNotNull(status)) {
        return status === SUCCESS || status === SUCCESS_CAP;
    }
    return isNull(message);
};

/**
 * Checks existence and type of required parameters.
 */
const checkParams = (params, ...requiredFields) => {
    if (isNull(params)) throw new Error('Params is null');
    for (let i = 0; i < requiredFields.length; i++) {
        let fieldInfo = requiredFields[i].split(' ');
        let field = fieldInfo[0];
        let type = fieldInfo[1];

        if (!params.hasOwnProperty(field)) throw new Error('Params does not contain required field \'' + field + '\'.');
        let fieldValue = params[field];

        const types = {
            'any': () => {
            },
            'function': () => {
                if (!isFunction(fieldValue))
                    throw new TypeError('Param \'' + field + '\' is not a function: ' + fieldValue);
            },
            'array': () => {
                if (!Array.isArray(fieldValue))
                    throw new TypeError('Param \'' + field + '\' is not an array: ' + fieldValue);
            },
            'integer': () => {
                if (!Number.isInteger(fieldValue))
                    throw new TypeError('Param \'' + field + '\' is not an integer: ' + fieldValue);
            },
            'default': () => {
                if (typeof fieldValue !== type)
                    throw new TypeError('Param \'' + field + '\' is not of type ' + type + ': ' + fieldValue);
            }
        };
        (types[type] || types.default)();
    }
};

const checkOptionalParams = (params, ...optionalFields) => {
    if (isNull(params)) throw new Error('Params is null');
    for (let i = 0; i < optionalFields.length; i++) {
        let fieldInfo = optionalFields[i].split(' ');
        let fieldName = fieldInfo[0];
        let fieldType = fieldInfo[1];
        let fieldValue = params[fieldName];

        if (!params.hasOwnProperty(fieldName) || isNull(fieldValue)) continue;

        const types = {
            'any': () => {
            },
            'function': () => {
                if (!isFunction(fieldValue))
                    throw new TypeError('Optional param \'' + fieldName + '\' is not a function.');
            },
            'array': () => {
                if (!Array.isArray(fieldValue))
                    throw new TypeError('Optional param \'' + fieldName + '\' is not an array.');
            },
            'integer': () => {
                if (!Number.isInteger(fieldValue))
                    throw new TypeError('Param \'' + field + '\' is not an integer: ' + fieldValue);
            },
            'default': () => {
                if (typeof fieldValue !== fieldType)
                    throw new TypeError('Optional param \'' + fieldName + '\' is not of type ' + fieldType + ': '
                        + fieldValue);
            }
        };
        (types[fieldType] || types.default)();
    }
};

const deleteJwt = () => {
    setJwt(null);
};
