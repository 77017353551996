import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../../../redux/actions';
import InputAdornment from '@material-ui/core/InputAdornment';

import {getSetting, isFunction, isValidEmail, pathIsNotNull, setCookie} from 'glob-common-js/lib/utils';

import StateComponent from "../../misc/stateComponent";
import ErrorBoundary from "../../errorBoundary";
import {
    acceptGrant,
    addGrant,
    deleteGrant,
    getAccountAccessGrants,
    loginGrant,
    updateAccount,
    updateMobile
} from "../../../misc/requestSender";
import {addCustomEventListener, dispatchCustomEvent, removeCustomEventListener} from "../../../misc/eventDispatcher";
import {navigate, setReduxUser} from "../../../misc/utils";
import MaterialFactory from "../../material/materialFactory";
import {materialTypes} from "../../material/materialTypes";
import AccountAccess_v2 from "./v2/accountAccess_v2";
import {ERROR, SUCCESS} from "../../../misc/constants";
import {setJwt} from "../../../common/js/platform";

export class AccountAccessController extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            grantTo: [],
            grantFrom: [],
            loading: false,
            loadingGrantTo: false,
            loadingGrantFrom: false,
            dialogOpen: false,
            dialogProps: {
                twoStepOpen: false,
                deleteOpen: false,
                acceptOpen: false,
            },
            phone_mobile: this.getPhoneMobile(),
            variant: 'from',
        };
        this.grant = null;
        this.variant = null;
        this.twoStepContent = null;
        this.twoStepCallback = null;
        this.loadInterval = null;
    }

    getPhoneMobile = () => {
        const user = this.props.userState.user;
        if (pathIsNotNull(user, 'phone_mobile')) {
            return user.phone_mobile.replace(/^06/, '');
        }
        return '';
    };

    onClickVariant = variant => () => {
        this.changeState({variant});
    };

    onEditGrant = grant => () => {
        const {variant} = this.state;
        dispatchCustomEvent('updateGrant', {grant, variant, onSuccess: this.loadGrants})
    };

    onInvite = () => {
        dispatchCustomEvent('addGrant', this.loadGrants);
    };

    acceptGrant = grant => () => {
        this.grant = grant;
        this.changeState({dialogProps: Object.assign({}, this.state.dialogProps, {acceptOpen: true})});
    };

    onAcceptGrant = (grant = null, force = false) => () => {
        if (isNotNull(grant)) this.grant = grant;
        this.onCloseDialog(false);

        const user = this.props.userState.user;
        if (isNotNull(this.grant) && (user.two_step_auth === 1 || force))
            acceptGrant({
                granted_by: this.grant.id,
                callback: (response) => {
                    if (response.data.status === 'success') {
                        dispatchCustomEvent('openSnackbar', {text: 'Toegang tot account is geaccepteerd'});
                        this.loadGrants();
                    } else dispatchCustomEvent('openSnackbar', {text: 'Toegang accepteren is niet gelukt'});
                }
            });
        else if (isNull(this.grant))
            dispatchCustomEvent('openSnackbar', {text: 'Er is iets misgegaan met de acceptatie (0)'});
        else if (user.two_step_auth === 0) {
            this.twoStepCallback = () => {
                this.onAcceptGrant(grant)();
            };
            this.triggerTwoStepDialog();
        }
    };

    onDeleteGrant = (grant, variant) => () => {
        this.grant = grant;
        this.variant = variant;
        this.changeState({
            dialogProps: {
                deleteOpen: true,
                twoStepOpen: false,
                acceptOpen: false,
            }
        });
    };

    onLoginGrant = grant => () => {
        loginGrant({
            granted_user_id: grant.id,
            callback: (response) => {
                const {status, token, user, message, granted_user} = response.data;
                if (status === SUCCESS) {
                    setJwt(token);
                    setReduxUser(user);
                    this.props.actions.setGrantedUser(granted_user);
                    navigate('/dashboard');
                } else if (status === ERROR && message === 'Two step must be enabled') {
                    this.twoStepCallback = () => {
                        this.onLoginGrant(grant);
                    };
                    this.triggerTwoStepDialog();
                } else dispatchCustomEvent('openSnackbar', {text: 'Het inloggen is niet gelukt'});
            }
        })
    };

    onAddGrant = (data, onSuccess, onError) => {
        const {email, rights} = data;
        if (!isValidEmail(email)) {
            if (isNotNull(email))
                onError('Ongeldig e-mailadres');
        } else
            addGrant({
                email,
                rights: this.prepareRights(rights),
                callback: (response) => {
                    if (response.data.status === 'success') {
                        onSuccess();
                        dispatchCustomEvent('openSnackbar', {text: 'Toegang tot account toegevoegd'});
                        this.loadGrants();
                    } else onError('Toegang verlenen niet gelukt');
                }
            })
    };

    onCloseDialog = (nullify = true) => {
        this.changeState({
            dialogProps: {
                deleteOpen: false,
                twoStepOpen: false,
                acceptOpen: false,
            }
        });
        if (nullify) {
            this.variant = null;
            this.grant = null;
        }
    };

    onChangePhoneMobile = evt => {
        this.changeState({
            phone_mobile: evt.target.value,
        });
    };

    prepareRights = rightsObject => {
        const rights = Object.keys(rightsObject);
        const preparedRights = [];
        forEach(rights, right => {
            if (rightsObject[right]) preparedRights.push(right);
        });
        return preparedRights;
    };

    triggerTwoStepDialog = () => {
        this.changeState({
            dialogProps: {
                twoStepOpen: true,
                deleteOpen: false,
                acceptOpen: false,
            }
        });
    };

    deleteGrant = () => {
        this.onCloseDialog(false);
        const variant = this.variant, grant = this.grant;
        const userId = this.props.userState.user.id;
        const granted_to = variant === 'to' ? grant.id : userId;
        const granted_by = variant === 'to' ? userId : grant.id;
        deleteGrant({
            granted_to,
            granted_by,
            callback: (response) => {
                if (response.data.status === 'success') {
                    dispatchCustomEvent('openSnackbar', {text: 'Toegang tot account is verwijderd'});
                    this.variant = null;
                    this.grant = null;
                    this.loadGrants();
                } else dispatchCustomEvent('openSnackbar', {text: 'Verwijderen van toegang is niet gelukt'});
            }
        })
    };

    loadGrants = (startLoad = false) => {
        if (startLoad)
            this.changeState({
                loading: true,
            });
        getAccountAccessGrants({
            callback: (response) => {
                const data = response.data;
                this.changeState({
                    grantTo: this.prepareResponseData(data.granted_to),
                    grantFrom: this.prepareResponseData(data.granted_from),
                    loading: false,
                });
            }
        });
    };

    prepareResponseData = grantedList => {
        if (isNotNull(grantedList)) {
            return grantedList.map(grant => ({
                email: grant.email,
                id: grant.id,
                firstname: grant.firstname,
                infix: grant.infix,
                lastname: grant.lastname,
                accepted: grant.accepted,
                rights: grant.rights,
                profile_image: grant.profile_image,
            }));
        }
        return [];
    };

    enableTwoStep = () => {
        let {phone_mobile} = this.state;
        if (isNotNull(phone_mobile)) {
            this.onCloseDialog(false);
            const user = this.props.userState.user;
            phone_mobile = '06' + phone_mobile;
            if (phone_mobile !== user.phone_mobile) {
                updateMobile({
                    phone_mobile,
                    callback: () => {
                        dispatchCustomEvent('2fa', {submitCallback: this.confirm2Fa, phone_mobile, mode: 'update'})
                    }
                })
            } else this.updateAccount(phone_mobile);
        }
    };

    confirm2Fa = (response, phone_mobile) => {
        dispatchCustomEvent('close2Fa');
        this.updateAccount(phone_mobile);
    };

    updateAccount = (phone_mobile) => {
        let user = Object.assign({}, this.props.userState.user);
        user.two_step_auth = 1;
        user.phone_mobile = phone_mobile;
        updateAccount({
            id: user.id,
            data: user,
            callback: (response) => {
                const user = response.data;
                setReduxUser(user);
                if (isFunction(this.twoStepCallback))
                    this.twoStepCallback();
            },
        });
    };

    addPhoneMobileListener = () => {
        const node = document.getElementById('changePhoneMobile');
        if (isNotNull(node)) {
            const addEvent = node.addEventListener || node.attachEvent;
            addEvent('keydown', this.phoneMobileListener);
        }
    };

    removePhoneMobileListener = () => {
        const node = document.getElementById('changePhoneMobile');
        if (isNotNull(node)) {
            const removeEvent = node.removeEventListener || node.detachEvent;
            removeEvent('keydown', this.phoneMobileListener);
        }
    };

    phoneMobileListener = evt => {
        const ENTER_CODE = 13;
        if (evt.keyCode === ENTER_CODE && isNotNull(this.state.phone_mobile)) {
            this.removePhoneMobileListener();
            this.enableTwoStep();
            evt.preventDefault();
        }
    };

    unauthorizedListener = () => {
        navigate('/account-gegevens');
    };

    renderDeleteConfirmActions = () => ([
        {label: 'Ja', onClick: this.deleteGrant},
        {label: 'Nee', onClick: this.onCloseDialog}
    ]);

    renderAcceptConfirmActions = () => ([
        {label: 'Ja', onClick: this.onAcceptGrant()},
        {label: 'Nee', onClick: this.onCloseDialog}
    ]);

    renderTwoStepActions = () => ([
        {label: 'Zet aan', disabled: isNull(this.state.phone_mobile), onClick: this.enableTwoStep},
        {label: 'Annuleren', onClick: this.onCloseDialog}
    ]);

    renderTwoStepContent = () => (
        <form>
            <MaterialFactory id='changePhoneMobile' componentType={materialTypes.TEXT} InputProps={{
                startAdornment: <InputAdornment position="start">06-</InputAdornment>,
            }} value={this.state.phone_mobile} label='Mobiel nummer'
                             onChange={this.onChangePhoneMobile} autoFocus/>
        </form>
    );

    componentDidMount = () => {
        this.mount();
        this.loadGrants(true);
        this.loadInterval = window.setInterval(() => {
            this.loadGrants();
        }, 5000);
        addCustomEventListener('grantUnauthorized', this.unauthorizedListener)
    };

    componentDidUpdate = (prevProps, prevState) => {
        const prevUser = prevProps.userState.user;
        const user = this.props.userState.user;
        if (!pathIsNotNull(prevUser, 'phone_mobile') && pathIsNotNull(user, 'phone_mobile'))
            this.changeState({
                phone_mobile: this.getPhoneMobile(),
            });

        if (!prevState.dialogProps.twoStepOpen && this.state.dialogProps.twoStepOpen)
        // Timeout needed for when React renders slower than the state update
            setTimeout(this.addPhoneMobileListener, 100);
    };

    componentWillUnmount = () => {
        if (isNotNull(this.loadInterval)) {
            window.clearInterval(this.loadInterval);
            this.loadInterval = null;
        }
        this.unMount();
        this.removePhoneMobileListener();
        removeCustomEventListener('grantUnauthorized', this.unauthorizedListener)
    };

    renderV2 = () => {
        this.twoStepContent = this.renderTwoStepContent();
        const {grantFrom, grantTo, loading, dialogProps, variant} = this.state;
        return (
            <ErrorBoundary>
                <MaterialFactory componentType={materialTypes.DIALOG} title='Toegang verwijderen'
                                 open={dialogProps.deleteOpen} onClose={this.onCloseDialog}
                                 actions={this.renderDeleteConfirmActions()}
                                 text='Weet je zeker dat je de toegang van dit account wilt verwijderen?'/>
                <MaterialFactory componentType={materialTypes.DIALOG} title='Toegang accepteren'
                                 open={dialogProps.acceptOpen} onClose={this.onCloseDialog}
                                 actions={this.renderAcceptConfirmActions()} text='Wil je deze toegang accepteren?'/>
                <MaterialFactory componentType={materialTypes.DIALOG} title='Two-factor authenticatie'
                                 open={dialogProps.twoStepOpen} onClose={this.onCloseDialog}
                                 actions={this.renderTwoStepActions()}
                                 text='Om een machtiging te accepteren moet two-factor authenticatie in je account
                                 aan staan. Vul je hieronder je mobiel nummer in om de two-factor authenticatie
                                 in te schakelen.'
                                 content={this.renderTwoStepContent()}/>
                <AccountAccess_v2 grants={variant === 'to' ? grantTo : grantFrom} loading={loading}
                                  onEditGrant={this.onEditGrant} onInvite={this.onInvite} variant={variant}
                                  onClickVariant={this.onClickVariant} onAcceptGrant={this.acceptGrant}
                                  onDeleteGrant={this.onDeleteGrant} onLoginGrant={this.onLoginGrant}/>
            </ErrorBoundary>
        )
    };

    render = () => {
        return this.renderV2();
        // this.twoStepContent = this.renderTwoStepContent();
        // const {grantFrom, grantTo, loadingGrantTo, loadingGrantFrom, dialogProps} = this.state;
        // return (
        //     <ErrorBoundary>
        //         <MaterialFactory componentType={materialTypes.DIALOG} title='Toegang verwijderen'
        //                          open={dialogProps.deleteOpen} onClose={this.onCloseDialog}
        //                          actions={this.renderDeleteConfirmActions()}
        //                          text='Weet je zeker dat je de toegang van dit account wilt verwijderen?'/>
        //         <MaterialFactory componentType={materialTypes.DIALOG} title='Two-factor authenticatie'
        //                          open={dialogProps.twoStepOpen} onClose={this.onCloseDialog}
        //                          actions={this.renderTwoStepActions()}
        //                          text='Om een machtiging te accepteren moet two-factor authenticatie in je account
        //                          aan staan. Vul je hieronder je mobiel nummer in om de two-factor authenticatie
        //                          in te schakelen.'
        //                          content={this.renderTwoStepContent()}/>
        //         <AccountAccess grantFrom={grantFrom} grantTo={grantTo} grantFromLoading={loadingGrantFrom}
        //                        grantToLoading={loadingGrantTo} onAcceptGrant={this.onAcceptGrant}
        //                        onDeleteGrant={this.onDeleteGrant} onLoginGrant={this.onLoginGrant}
        //                        onAddGrant={this.onAddGrant}/>
        //     </ErrorBoundary>
        // )
    };
}

const mapStateToProps = state => ({
    userState: state.user,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountAccessController);