import './style/passRestrictions.scss';

import React from 'react';

import StateComponent from "../../../components/misc/stateComponent";

class PasswordRestrictions extends StateComponent {
    constructor(props) {
        super(props);
    };

    setRestrictionColor = (index, color) => {
        document.getElementById('passRule' + index).style.color = color;
    };

    testPassword = password => {
        let passRules = this.props.passRules;
        let valid = true;
        for (let i = 0; i < passRules.length; i++) {
            let rule = passRules[i];
            if (rule.test(password)) {
                this.setRestrictionColor(i, 'black');
            } else {
                this.setRestrictionColor(i, 'red');
                valid = false;
            }
        }
        return valid;
    };

    createPassRule = (passRule, key) => (<p key={key} id={"passRule" + key}>{passRule.label}</p>);

    setPassword = password => {
        this.changeState({password});
    };

    componentDidMount = () => {
        this.mount();
        this.testPassword(this.props.password);
    };

    componentDidUpdate = () => {
        this.testPassword(this.props.password);
    };

    componentWillUnmount = () => {
        this.unMount();
    };

    render() {
        return (
            <div className={"formPassRestrictions" + (this.props.restrictionsVisible ? ' show' : '')}>
                <p className="textBold">Wachtwoord moet voldoen aan:</p>
                {this.props.passRules.map(this.createPassRule)}
            </div>
        )
    }
}

export default PasswordRestrictions;