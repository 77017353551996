import './style/linkDossier.scss';

import React from 'react';
import PropTypes from 'prop-types';
import Loader from "../misc/loader";
import CustomScrollbar from "../misc/customScrollbar";
import MaterialFactory from "../material/materialFactory";
import {materialTypes} from "../material/materialTypes";
import {PopupBuilder} from "../misc/popupBuilder/popupBuilder";

export default class LinkDossierView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            view: 'block', // block || list
        };
        this._isMounted = false;
    }

    changeState = stateChange => {
        this.setState(stateChange);
    };

    enableLoader = () => {
        this.loader.enable();
    };

    disableLoader = callback => {
        this.loader.disable(callback);
    };

    changeView = view => {
        this.changeState({view});
    };

    renderBody = () => {
        return this.state.view === 'block' ? this.renderBlockBody() : this.renderListBody();
    };

    renderBlockBody = () => {
        let dossiers = this.props.dossiersToLink.slice();
        const linked = this.props.selectedDossiers;
        dossiers.sort((dossierA, dossierB) => {
            const preferA = -1, preferB = 1, isEqual = 0;
            if (linked.includes(dossierA) && !linked.includes(dossierB))
                return preferA;
            if (!linked.includes(dossierA) && linked.includes(dossierB))
                return preferB;
            if ((linked.includes(dossierA) && linked.includes(dossierB)) ||
                (!linked.includes(dossierA) && !linked.includes(dossierB))) {
                const labelA = dossierA.label, labelB = dossierB.label;
                return labelA < labelB ? preferA : labelA > labelB ? preferB : isEqual;
            }
            return isEqual;
        });
        return (
            <div className='linkDossierBlockBody'>
                <div className='blockBodyHead'>
                    <span className='common-icon-check checkIcon'/>
                    <label>Gekoppeld</label>
                </div>
                <CustomScrollbar height='13rem'>
                    <div className='linkDossierBlockDossiers'>
                        {dossiers.map(this.renderDossierBlock)}
                    </div>
                </CustomScrollbar>
            </div>
        )
    };

    renderDossierBlock = (dossier, key) => {
        const selected = this.props.selectedDossiers.includes(dossier);
        return (
            <div key={key} className='dossierBlock' onClick={() => {
                this.props.onSelectDossier(dossier)
            }}>
                <span className={dossier.type.icon + ' dossierIcon'}/>
                <label className='dossierLabel'>{dossier.label}</label>
                <div className={'checkOverlay' + (selected ? ' show' : '')}>
                    <span className='common-icon-check overlayIcon'/>
                </div>
            </div>
        )
    };

    renderListBody = () => (
        <div className='linkDossierListView'>
            <CustomScrollbar height='14rem'>
                <div className='linkDossierListBody'>
                    <table className='linkDossierTable'>
                        <tbody>
                        <tr>
                            <th/>
                            <th>Dossiernaam</th>
                            <th>Dossiertype</th>
                            <th>Gekoppeld</th>
                        </tr>
                        {this.props.dossiersToLink.map(this.renderDossierListRow)}
                        </tbody>
                    </table>
                </div>
            </CustomScrollbar>
        </div>
    );

    renderDossierListRow = (dossier, key) => {
        return (
            <tr key={key} className='dossierListRow' onClick={() => {
                this.props.onSelectDossier(dossier)
            }}>
                <td>
                    <span className={dossier.type.icon + ' dossierIcon'}/>
                </td>
                <td>
                    <label className='dossierLabel'>{dossier.label}</label>
                </td>
                <td>
                    <label className='dossierTypeLabel'>{dossier.type.label}</label>
                </td>
                <td>
                    <MaterialFactory componentType={materialTypes.CHECKBOX} className='dossierCheck'
                                     checked={this.props.selectedDossiers.includes(dossier)} onChange={(evt) => {
                        evt.preventDefault();
                    }}/>
                </td>
            </tr>
        )
    };

    renderHeader = () => {
        const {documentLabel, onNewDossier, searchDossier} = this.props;
        const {view} = this.state;
        return (
            <div className='linkDossierHeader'>
                <div className='headerTitleContainer'>
                    <label className='headerTitle'>Dossiers koppelen</label>
                </div>
                <div className='headerRow'>
                    <div className='docInfoContainer'>
                        <span className='common-icon-document docIcon'/>
                        <p className='linkText'>Selecteer de dossiers waaraan je <span
                            className='docLabel'>{documentLabel}</span> wilt koppelen.</p>
                    </div>
                    <div className='viewSelectionContainer'>
                        <label className='viewSelectionLabel'>Toon als:</label>
                        <span className={'viewSelection blockView common-icon-th-large' + (view === 'block' ?
                            ' selected' : '')} onClick={() => {
                            this.changeView('block');
                        }}/>
                        <span className={'viewSelection list common-icon-th-list' + (view === 'list' ?
                            ' selected' : '')} onClick={() => {
                            this.changeView('list');
                        }}/>
                    </div>
                </div>
                <div className='headerRow'>
                    <div className='addDossierContainer' onClick={onNewDossier}>
                        <span className='common-icon-folder-open2 dossierIcon'/>
                        <label>Maak een nieuw dossier aan</label>
                    </div>
                    <div className='searchDossierContainer'>
                        <MaterialFactory componentType={materialTypes.TEXT} id='searchLinkDossier'
                                         onChange={searchDossier} className='searchDossier'
                                         label='Zoek een dossier'/>
                    </div>
                </div>
            </div>
        );
    };

    componentDidMount = () => {
        this._isMounted = true;
    };

    componentWillUnmount = () => {
        this._isMounted = false;
    };

    render = () => {
        return (
            <>
                <div className='clearBackground'/>
                <div className='linkDossierView'>
                    <div className='linkDossierHeader'>
                        <div className='headerTitleContainer'>
                            <label className='headerTitle'>Dossiers koppelen</label>
                        </div>
                        <div className='headerRow'>
                            <div className='docInfoContainer'>
                                <span className='common-icon-document docIcon'/>
                                <p className='linkText'>Selecteer de dossiers waaraan je <span
                                    className='docLabel'
                                    title={this.props.documentLabel}>{this.props.documentLabel}</span> wilt
                                    koppelen.</p>
                            </div>
                            <div className='viewSelectionContainer'>
                                <label className='viewSelectionLabel'>Toon als:</label>
                                <span
                                    className={'viewSelection blockView common-icon-th-large' + (this.state.view === 'block' ?
                                        ' selected' : '')} onClick={() => {
                                    this.changeView('block');
                                }}/>
                                <span
                                    className={'viewSelection list common-icon-th-list' + (this.state.view === 'list' ?
                                        ' selected' : '')} onClick={() => {
                                    this.changeView('list');
                                }}/>
                            </div>
                        </div>
                        <div className='headerRow'>
                            <div className='addDossierContainer' onClick={this.props.onNewDossier}>
                                <span className='common-icon-folder-open2 dossierIcon'/>
                                <label>Maak een nieuw dossier aan</label>
                            </div>
                            <div className='searchDossierContainer'>
                                <MaterialFactory componentType={materialTypes.TEXT} id='searchLinkDossier'
                                                 onChange={this.props.searchDossier} className='searchDossier'
                                                 label='Zoek een dossier'/>
                            </div>
                        </div>
                    </div>
                    <div className='linkDossierBody'>
                        {this.renderBody()}
                    </div>
                    <div className='linkDossierFooter'>
                        <MaterialFactory componentType={materialTypes.RAISED_BUTTON}
                                         onClick={this.props.onCancel}>Annuleren</MaterialFactory>
                        <MaterialFactory componentType={materialTypes.RAISED_BUTTON}
                                         onClick={this.props.onSave}>Opslaan</MaterialFactory>
                    </div>
                    <Loader ref={refName => {
                        this.loader = refName
                    }} includeBackground={true} backgroundColor='white'
                            text='Opslaan' locate='absolute'/>
                </div>
            </>
        )
    };
}

LinkDossierView.propTypes = {
    onNewDossier: PropTypes.func.isRequired,
    searchDossier: PropTypes.func.isRequired,
    onSelectDossier: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    documentLabel: PropTypes.string.isRequired,
    dossiersToLink: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedDossiers: PropTypes.arrayOf(PropTypes.object),
};