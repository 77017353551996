import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import {isNotNull} from 'glob-common-js/lib/utils';
import {sendGaEvent} from "BdhCommon/js/ga";
import imageExtensions from "../../../common/data/mappings/imageExtensions";
import getDocumentType, {getDocByLabel} from "../../../common/data/mappings/docTypeMapping";

export class DocumentButton extends React.Component {
    constructor(props) {
        super(props);
    }

    checkboxClick = () => {
        sendGaEvent('Bibliotheek(nieuw)', this.props.document.type, 'Selecteren');
        let button = ReactDOM.findDOMNode(this.button);
        if (isNotNull(button)) {
            if (button.classList.contains('checkboxClicked')) {
                button.classList.remove('checkboxClicked');
                if (this.props.legacyMode) {
                    this.props.onCheckClick(false, this.props.document.id);
                } else {
                    this.props.onCheckClick(this.props.document);
                }
            } else {
                button.classList.add('checkboxClicked');
                if (this.props.legacyMode) {
                    this.props.onCheckClick(true, this.props.document.id);
                } else {
                    this.props.onCheckClick(this.props.document);
                }
            }
        }
    };

    getIconClass = () => {
        if (this.props.legacyMode) return 'common-icon-document';

        let iconClass = 'document';
        if (isNotNull(this.props.document) && isNotNull(this.props.document.extension)) {
            let extension = this.props.document.extension.toLowerCase();
            if (/pdf/.test(extension)) iconClass = 'pdf';
            else if (/docx?/.test(extension)) iconClass = 'word';
            else if (/xlsx?/.test(extension)) iconClass = 'excel';
            else if (/pptx?/.test(extension)) iconClass = 'powerpoint';
            else if (imageExtensions.includes(extension)) return 'common-icon-image';
        }
        return 'common-icon-' + iconClass;
    };

    docIsImage = () => {
        const extension = this.props.document.extension;
        return imageExtensions.includes(extension);
    };

    getDocumentType = () => {
        if (this.props.legacyMode)
            return getDocByLabel(this.props.document.type).label;
        return getDocumentType(this.props.document.type.name).label;
    };

    renderExclamationMark = () => {
        let shouldRender = this.props.legacyMode ? this.props.document.type === "Geen type bekend" :
            this.props.document.type.label === 'Geen type bekend';
        if (shouldRender)
            return (
                <a className="unknownDocument"
                   title="Dit document heeft nog geen document type waardoor we hem niet kunnen categoriseren.
                               Wijzig het type document om hem makkelijker vindbaar te maken.">!</a>
            );
        return null;
    };

    render = () => {
        const {document} = this.props;
        return (
            <div ref={(button) => (this.button = button)} className='documentButton'>
                <div className='buttonContent'>
                    <div className="checkboxDiv" onClick={this.checkboxClick}>
                        <div className="checkbox">
                            <div ref={'check'} className="checkboxCheck">
                            </div>
                        </div>
                    </div>
                    <div className='documentContainer' onClick={() => {
                        this.props.onClick(this.props.document)
                    }}>
                        <div className='iconBlock'>
                            {this.docIsImage() ?
                                <div className='documentPreview' style={{backgroundImage: `url(${document.url})`}}/> :
                                <span className={this.getIconClass()}/>}
                        </div>
                        <div className='documentTitle'>
                            <p>{this.props.legacyMode ? this.props.document.name : this.props.document.label}</p>
                        </div>
                        <div className='documentType'>
                            <p>{this.getDocumentType()}</p>
                        </div>
                        {this.renderExclamationMark()}
                    </div>
                </div>
            </div>
        )
    }
}

export default DocumentButton;

DocumentButton.propTypes = {
    document: PropTypes.shape({
        type: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({
            label: PropTypes.string
        })]),
        name: PropTypes.string,
        label: PropTypes.string,
        extension: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
    }).isRequired,
    legacyMode: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    onCheckClick: PropTypes.func.isRequired,
};

