import {navigate} from '../misc/utils';
import utils, {isNotNull} from 'glob-common-js/lib/utils';
import {hasHover} from 'glob-common-react/lib/utils/reactUtils';
import {sendGaEvent} from "BdhCommon/js/ga";

import {store} from "../startup";
import * as actions from '../redux/actions';
import getModules from '../common/data/mappings/moduleMapping';

const mode = utils.getSetting('mode');
let linkDossiers, showTools, showHealthAnalysis, allowDeleteAccount;
switch (mode) {
    case 'prod':
        linkDossiers = true;
        showTools = false;
        showHealthAnalysis = true;
        allowDeleteAccount = true;
        break;
    case 'accept':
        linkDossiers = true;
        showTools = false;
        showHealthAnalysis = true;
        allowDeleteAccount = true;
        break;
    default:
        linkDossiers = true;
        showTools = false;
        showHealthAnalysis = true;
        allowDeleteAccount = true;
        break;
}

const moduleClickListener = (evt) => {
    let container = document.querySelector('div.selectableItemsContainer');
    let moduleButton = document.getElementById('navDashboard');
    let isHovered = hasHover(container) || hasHover(moduleButton);
    if (!isHovered) {
        let arrowDiv = document.querySelector('div.arrowDown');
        if (isNotNull(arrowDiv)) {
            arrowDiv.classList.remove('up');
        }
        if (isNotNull(container)) {
            container.classList.remove('show');
        }
        if (isNotNull(moduleButton)) {
            moduleButton.classList.remove('active');
        }
        window.removeEventListener('click', moduleClickListener);
    }
};

const appProperties = {
    headerButtons: [
        {
            type: 'modules',
            label: 'Dashboard',
            path: null,
            addArrow: true,
            selectableItems: getModules(),
            onSelectableClick: ({name, label}) => {
                sendGaEvent('Header', 'Header click', 'Selecteer module ' + label);
                store.dispatch(actions.selectModule(name));
                navigate('/dashboard');
            },
            toggleActiveButton: false,
            onClick: () => {
                sendGaEvent('Header', 'Header click', 'Dashboard');
                navigate('/dashboard');
                window.addEventListener('click', moduleClickListener);
            },
        },
        {
            type: 'navigation',
            label: 'Dossiers',
            path: '/dossier-overzicht',
            addArrow: false,
            selectableItems: [],
            onSelectableClick: null,
            toggleActiveButton: true,
            onClick: () => {
                sendGaEvent('Header', 'Header click', 'Dossiers');
                navigate('/dossier-overzicht');
                store.dispatch(actions.resetModule());
            },
        },
        {
            type: 'navigation',
            label: 'Documenten toevoegen',
            path: '/documenten-toevoegen',
            addArrow: false,
            selectableItems: [],
            onSelectableClick: null,
            toggleActiveButton: true,
            onClick: () => {
                sendGaEvent('Header', 'Header click', 'Documenten toevoegen');
                navigate('/documenten-toevoegen');
                store.dispatch(actions.resetModule());
            },
        },
        {
            type: 'navigation',
            label: 'Bibliotheek',
            path: '/bibliotheek',
            addArrow: false,
            selectableItems: [],
            onSelectableClick: null,
            toggleActiveButton: true,
            onClick: () => {
                sendGaEvent('Header', 'Header click', 'Bibliotheek');
                navigate('/bibliotheek');
                store.dispatch(actions.resetModule());
            },
        },
        {
            type: 'navigation',
            label: 'Financieel overzicht',
            path: '/financieel-overzicht',
            addArrow: false,
            selectableItems: [],
            onSelectableClick: null,
            toggleActiveButton: true,
            onClick: () => {
                sendGaEvent('Header', 'Header click', 'Financieel overzicht');
                navigate('/financieel-overzicht');
                store.dispatch(actions.resetModule());
            }
        }
    ],
    linkDossiers,
    showTools,
    showHealthAnlysis: showHealthAnalysis,
    allowDeleteAccount,
};

export const getRouteByPath = path => {
    for (let i = 0; i < appProperties.headerButtons.length; i++) {
        let button = appProperties.headerButtons[i];
        if (button.path === path) {
            return button;
        }
    }
};

export default appProperties;