import checklistTypes from "../../../components/checklist/checklistTypes";

export default {
    name: 'Begrafenis',
    icon: 'common-icon-document-2',
    route: '/begrafenis-checklist',
    type: checklistTypes.FUNERAL,
    GA_CATEGORY: 'Begrafenis checklist',
    categories: {
        direct: {
            name: 'direct',
            label: 'Direct regelen',
            icon: 'common-icon-document-2',
            boxes: [
                {
                    name: 'general_prac',
                    label: 'Informeer de huisarts',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'centre',
                    label: 'Meld het overlijden bij een uitvaartcentrum',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'home',
                    label: 'Geef aan of het een thuisopbaring of een opbaring in een uitvaartcentrum wordt',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'family',
                    label: 'Licht familie en vrienden in',
                    documentType: null,
                    link: null,
                },
            ],
        },
        administration: {
            name: 'administration',
            label: 'Administratieve zaken',
            icon: 'common-icon-document-2',
            boxes: [
                {
                    name: 'subscriptions',
                    label: 'Abonnementen op kranten en tijdschriften opzeggen',
                    documentType: 'miscellaneous_indoors',
                    link: null,
                },
                {
                    name: 'memberships',
                    label: 'Lidmaatschappen van verenigingen, clubs opzeggen',
                    documentType: 'miscellaneous_indoors',
                    link: null,
                },
                {
                    name: 'digital',
                    label: 'Digitaal nalatenschap (account bij facebook, twitter, etc.) opzeggen',
                    documentType: 'miscellaneous_indoors',
                    link: null,
                },
                {
                    name: 'saving_account',
                    label: 'Bankrekeningnummers van eigen (spaar)rekeningen uitzoeken',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'other_accounts',
                    label: 'Bankrekeningnummers van en/of rekeningen uitzoeken',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'other_save',
                    label: 'Rekeningnummers van spaarloon, levensloopsparen uitzoeken',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'insurances',
                    label: 'Lopende verzekeringen en de polisnummers uitzoeken',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'retirement',
                    label: 'Pensioenoverzicht uitzoeken',
                    documentType: null,
                    link: {
                        label: 'Pensioenoverzicht',
                        url: 'https://www.mijnpensioenoverzicht.nl/',
                    },
                },
                {
                    name: 'notary',
                    label: 'Naam van de notaris uitzoeken',
                    documentType: null,
                    link: null,
                    valueType: 'string',
                    valueLabel: 'Notaris',
                },
                {
                    name: 'donor',
                    label: 'Is er een donorcodicil?',
                    documentType: 'donor_card_care',
                    link: null,
                    valueType: 'bool',
                },
                {
                    name: 'wishes',
                    label: 'Is er een wensenboekje?',
                    documentType: 'miscellaneous_indoors',
                    link: null,
                },
                {
                    name: 'check_insurance',
                    label: 'Ga na of er een uitvaartverzekering is afgesloten?',
                    documentType: 'funeral_insurance_personal',
                    link: null,
                },
                {
                    name: 'addresses',
                    label: 'Adressenlijst uitzoeken',
                    documentType: 'miscellaneous_indoors',
                    link: null,
                },
            ],
        },
        manage: {
            name: 'manage',
            label: 'Uitvaart regelen',
            icon: 'common-icon-document-2',
            boxes: [
                {
                    name: 'cloths',
                    label: 'Uitzoeken van kleding',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'texts',
                    label: 'Tekst op linten voor de bloemen bedenken',
                    documentType: 'miscellaneous_indoors',
                    link: null,
                },
                {
                    name: 'pictures',
                    label: 'Teksten en foto’s voor rouwdrukwerk regelen',
                    documentType: 'miscellaneous_indoors',
                    link: null,
                },
                {
                    name: 'speech',
                    label: 'Schrijven van toespraak om voor te dragen tijdens de afscheidsbijeenkomst',
                    documentType: 'miscellaneous_indoors',
                    link: null,
                },
                {
                    name: 'music',
                    label: 'Muziek uitzoeken en overhandigen aan uitvaartverzorger',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'specific_wishes',
                    label: 'Andere specifieke wensen uitwerken',
                    documentType: null,
                    link: null,
                },
            ],
        },
        after: {
            name: 'after',
            label: 'Na de uitvaart',
            icon: 'common-icon-document-2',
            boxes: [
                {
                    name: 'gravestone',
                    label: 'Grafsteen laten maken',
                    documentType: 'sales_receipt',
                    link: null,
                },
                {
                    name: 'urn',
                    label: 'Urn of ander herinneringsproduct',
                    documentType: 'sales_receipt',
                    link: null,
                },
                {
                    name: 'will',
                    label: 'Testament',
                    documentType: 'will_personal',
                    link: null,
                },
                {
                    name: 'house',
                    label: 'Verkoop van huis/opzeggen van huur',
                    documentType: null,
                    link: null,
                },
            ],
        },
    }
};