import PropTypes from 'prop-types'
import React from 'react';

import IconButton from 'glob-common-react/lib/buttons/iconButton';

import FakeContentContainer from "../fakeContent/fakeContentContainer";
import DashboardInfoContainer from "../info/dashboardInfoContainer";
import {sendGaEvent} from "../../../common/js/ga";

import {navigate} from "../../../misc/utils";

export class DossierDashboardContainer extends React.Component {
    onClickNav = () => {
        navigate('/overzicht');
    };

    createDossierButtons = () => {
        const {dossiers, dosFetching} = this.props;
        if (!dosFetching) {
            const list = isNull(dossiers) ? [null] : dossiers;
            const max = Math.min(list.length, 4);
            return list.slice(0, max).map(this.createDossierButton);
        }
    };

    createDossierButton = (dossier, key) => {
        if (isNotNull(dossier)) {
            return (
                <li key={key}>
                    <IconButton name={dossier} text={dossier.name} extraCssName='storeButton dossierButton'
                                icon={dossier.type.icon} onClick={this.onDossierClick}/>
                </li>
            );
        }
    };

    onDossierClick = (dossier) => {
        this.props.setReduxDossier(dossier);
        navigate('/dossier-details');
        const action = 'Dashboard click' + (window.highlightsActive ? ' highlights' : '');
        sendGaEvent('Dashboard', action, 'Naar dossier detail');
    };

    onNavigateDossier = () => {
        const action = 'Dashboard click' + (window.highlightsActive ? ' highlights' : '');
        sendGaEvent('Dashboard', action, 'Naar dossier overzicht');
        navigate('/dossier-overzicht');
    };

    createWaitingContent = () => {
        if (this.props.dosFetching) {
            return (
                <p className='waitText'>Uw dossiers worden geladen</p>
            );
        } else {
            return (
                <div className='optionalHoverText'>Zo te zien heb jij nog geen dossiers aangemaakt.
                    Multomappen &
                    perforators kunnen de deur uit! Je hebt al jouw belangrijke papieren
                    overzichtelijk ByDeHand als je begint met dossiers aanmaken!
                </div>
            );
        }
    };

    componentDidMount = () => {
    };

    render = () => {
        const {dossiers, dosFetching, selectedModule, activeModules} = this.props;
        return (
            <div id='dashboardBoxDossiers'
                 className={'box' + (isNotNull(dossiers) && !dosFetching ? '' : ' hoverable')}>
                <header>
                    <label className='dashboardTitle clickable' onClick={this.onClickNav}>Jouw dossiers</label>
                    <DashboardInfoContainer
                        infoText={'Vind door middel van voor gedefinieerde categorieën gemakkelijk ' +
                        'en snel al je documenten terug in het daarvoor bestemde dossier. Mocht het dossier dat je aan wilt ' +
                        'maken er niet tussen zitten, dan kun je deze zelf aanmaken.'}/>
                    <button onClick={this.onNavigateDossier} className='common-icon-plus addDossierButton'/>
                </header>
                {this.createWaitingContent()}
                <ul className={'content' + (dosFetching ? ' hide' : '')}>
                    <div className='simpleButtonSet'>
                        {!dosFetching && this.createDossierButtons()}
                        {isNull(dossiers) && <FakeContentContainer selectedModule={selectedModule} fakeType='dossier'
                                                                   activeModules={activeModules}/>}
                    </div>
                </ul>
                <div className='textRight'>
                    <a onClick={this.onNavigateDossier}>
                        Bekijk alle dossiers
                    </a>
                </div>
            </div>
        );
    }
}

export default DossierDashboardContainer;

DossierDashboardContainer.propTypes = {
    dosFetching: PropTypes.bool.isRequired,
    dossiers: PropTypes.array.isRequired,
    setReduxDossier: PropTypes.func.isRequired,
    selectedModule: PropTypes.string,
    activeModules: PropTypes.arrayOf(PropTypes.string),
};