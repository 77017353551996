import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as Actions from '../../../../../redux/actions';

import {isNotNull} from 'glob-common-js/lib/utils';

import EvaluationRiskView from './evaluationRiskView';
import {getFromRegistry} from "../../misc/registry";
import {sendEvent} from "../../misc/healthAnalysisUtils";
import {sendPageView} from "BdhCommon/js/ga";

export class EvaluationRiskController extends React.Component {
    constructor(props) {
        super(props);
    }

    getRiskData = () => {
        let insurance = getFromRegistry('healthInsurance');
        let riskData = insurance.evaluation.risk;
        riskData.value = insurance.evaluation.basic.risk;
        return riskData;
    };

    setEvaluation = (decision) => {
        if (isNotNull(decision)) {
            sendEvent('Evaluatie Eigen Risico', decision);
            this.props.actions.setEvaluationRisk(decision);
        }
    };

    componentDidMount = () => {
        sendPageView('/zorganalyse/evaluatie/eigen-risico');
    };

    render = () => {
        return (
            <EvaluationRiskView risk={this.getRiskData()} setEvaluation={this.setEvaluation}
                                evaluationDecision={this.props.healthState.evaluation.risk}
                                activateFbShare={this.props.activateFbShare}/>
        )
    };
}

const mapStateToProps = (state) => ({
    healthState: state.healthInsuranceAnalysis,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(EvaluationRiskController);