import React from 'react';
import PropTypes from 'prop-types';

export default class DocumentCountView extends React.Component {
    constructor(props) {
        super(props);
    }

    render = () => {
        return (
            <div className='documentCount'>
                <label className='documentCountText'>Jouw bibliotheek bestaat uit</label>
                <label className='countText' onClick={this.props.onCountClick}>{this.props.documentCount}</label>
                <label className='documentCountText'>documenten</label>
            </div>
        );
    }
}

DocumentCountView.propTypes = {
    documentCount: PropTypes.number.isRequired,
    onCountClick: PropTypes.func.isRequired,
};