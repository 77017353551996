import React from 'react';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as Actions from '../../redux/actions';

import {cancelRequest} from "glob-common-js/lib/request";
import {deviceIsMobile} from "glob-common-js/lib/utils";

import {getKeyByLabel} from "BdhCommon/data/mappings/categoryMapping";
import DossierOverviewView from './dossierOverviewView';
import {sendGaEvent, sendPageView} from "BdhCommon/js/ga";
import ErrorBoundary from "../errorBoundary";
import {addCustomEventListener, dispatchCustomEvent, removeCustomEventListener} from "../../misc/eventDispatcher";
import {getOnboardingManager} from "../onboarding/manager/onboardingManagerHelper";
import {storeOnboardingSteps} from "../onboarding/manager/storeManager";

export class DossierOverviewController extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedModule: null,
            selectedMode: 'user',
            selectedCategory: 'all',
        }
    }

    changeState = (stateChange) => {
        if (this._isMounted) {
            this.setState(stateChange);
        }
    };

    onSelectCategory = selectedCategory => {
        this.changeState({selectedCategory});
    };

    getOnDossierClick = () => {
        let onClick = this.props.onDossierClick;
        if (typeof onClick === 'function') {
            return onClick;
        }
        return null;
    };

    onSelectModule = (moduleName, module) => {
        sendGaEvent('Dossier toevoegen', 'Subcategorie click', module.label);
        if (this.state.selectedModule === moduleName) {
            cancelRequest('fetchDossiers');
        } else {
            this.changeState({selectedModule: moduleName});
        }
    };

    onSelectMode = mode => {
        let menuItem = document.getElementById('navigationItem Dossiers');
        if (!deviceIsMobile() && isNotNull(menuItem) && !menuItem.classList.contains('activated')) {
            menuItem.classList.add('activated');
        }
        this.changeState({selectedMode: mode});
    };

    eventListener = () => {
        this.onSelectMode('new');
    };

    onboardingActivate = () => {
        this.changeState({selectedMode: 'new'});
    };

    checkOnboarding = () => {
        if (getOnboardingManager('store').isActive())
            dispatchCustomEvent('storeOnboardingFlow',
                {type: 'activate', step: storeOnboardingSteps.INTRO_STEP});
    };

    componentDidMount = () => {
        sendPageView('/dossier-overzicht');
        this._isMounted = true;
        this.checkOnboarding();
        addCustomEventListener('navigateAddDossier', this.eventListener);
        addCustomEventListener('onboardingReloadDossiers', this.onboardingActivate);

        const queryParams = new URLSearchParams(window.location.search);
        const category = queryParams.get('category');
        isNotNull(category) && this.onSelectCategory(category);
    };

    componentWillUnmount = () => {
        this._isMounted = false;
        removeCustomEventListener('navigateAddDossier', this.eventListener);
        removeCustomEventListener('onboardingReloadDossiers', this.onboardingActivate);
    };

    componentDidUpdate = (prevProps) => {
        this._isMounted = true;
        let reduxModule = this.props.moduleState.selectedModule, prevModule = prevProps.moduleState.selectedModule,
            stateModule = this.state.selectedModule;
        if (isNotNull(reduxModule) && reduxModule !== prevModule && reduxModule !== stateModule) {
            this.changeState({selectedModule: reduxModule});
        }
    };

    render = () => {
        return (
            <ErrorBoundary>
                <DossierOverviewView selectedModule={this.state.selectedModule} selectedMode={this.state.selectedMode}
                                     selectMode={this.onSelectMode} onSelectModule={this.onSelectModule}
                                     showUserDossiers={this.getOnDossierClick()}
                                     onSelectCategory={this.onSelectCategory}
                                     selectedCategory={this.state.selectedCategory}/>
            </ErrorBoundary>
        );
    }
}

const mapStateToProps = state => ({
    dossierState: state.dossier,
    moduleState: state.module,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(DossierOverviewController);