export const timeItemTypes = {
    deadline: {
        label: 'Belangrijke deadline',
        value: 0,
    },
    reminder: {
        label: 'Herinnering',
        value: 2,
    },
    meeting: {
        label: 'Afspraak',
        value: 3,
    },
    alert: {
        label: 'Alert',
        value: 4,
    },
    tip: {
        label: 'Tip',
        value: 5,
    }
};

export const timeItemFrequencies = {
    oneTime: {
        label: 'Eenmalig',
        value: 0,
    },
    daily: {
        label: 'Dagelijks',
        value: 1,
    },
    weekly: {
        label: 'Wekelijks',
        value: 2,
    },
    monthly: {
        label: 'Maandelijks',
        value: 3,
    },
    yearly: {
        label: 'Jaarlijks',
        value: 4,
    },
};

export const getAgendaItemTypes = () => {
    return [timeItemTypes.deadline, timeItemTypes.reminder, timeItemTypes.meeting];
};

export const getTimeItemFrequency = () => {
    return [timeItemFrequencies.oneTime, timeItemFrequencies.daily, timeItemFrequencies.weekly,
        timeItemFrequencies.monthly, timeItemFrequencies.yearly];
};

export const getTimeItemTypeByValue = value => {
    let keys = Object.keys(timeItemTypes);
    for (let i = 0; i < keys.length; i++) {
        let type = timeItemTypes[keys[i]];
        if (type.value === value) return type;
    }
    return timeItemTypes.meeting;
};