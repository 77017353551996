import {isNotNull} from "glob-common-js/lib/utils";

import {store} from '../../startup';
import * as actions from '../../redux/actions';
import {uploadFile} from "../../common/js/platform";
import {sendGaEvent} from "../../common/js/ga";

export const handleAppAction = actionConfig => {
    switch (actionConfig.subType) {
        case 'SELECT_FILE':
            let fileData = actionConfig.fileData;
            store.dispatch(actions.setFileToUpload(fileData));
            break;
        case 'UPLOAD_FILE':
            uploadNativeFileCallback(actionConfig);
            break;
    }
};

const uploadNativeFileCallback = (config) => {
    let saveButton = document.getElementById('uploadSave');
    let pictureSaveButton = document.getElementById('pictureSave');
    let callback = params => {
        sendGaEvent('Document toevoegen', 'Toevoegen', params.fileTypeLabel);

        if (isNotNull(saveButton))
            saveButton.disabled = false;
        if (isNotNull(pictureSaveButton))
            pictureSaveButton.disabled = false;

        store.dispatch(Actions.setUploadedDoc({
            label: params.label,
            name: params.fileTypeName,
            id: params.fileID,
            typeLabel: params.fileTypeLabel,
        }));
        let currentAddedDocs = store.getState().document.addedDocs;
        let newAddedDocs = [{
            id: params.fileID,
            name: params.label
        }];
        store.dispatch(actions.setAddedDocs(newAddedDocs.concat(currentAddedDocs)));
    };
    let params = {
        fileID: config.responseData.fileId,
        callback,
        label: config.fileData.name,
        fileType: config.fileData.fileType,
    };
    uploadFile(params);
};

export const remToPx = (remValue) => {
    let htmlElement = document.getElementsByTagName('html')[0];
    let fontSize = window.getComputedStyle(htmlElement, null).getPropertyValue('font-size');
    fontSize = parseInt(fontSize.slice(0, fontSize.length - 2), 10);
    return remValue * fontSize;
};