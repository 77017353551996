import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as Actions from '../../../../../redux/actions';

import EvaluationSummaryView from "./evaluationSummaryView";
import {sendPageView} from "BdhCommon/js/ga";

import request from 'glob-common-js/lib/request';
import {createRequestConfig} from 'BdhCommon/js/platform';
import {navigate, sendRequest} from "../../../../../misc/utils";
import {getFromRegistry, updateRegistry} from "../../misc/registry";
import {sendEvent} from "../../misc/healthAnalysisUtils";

import {isNotNull, scrollToTop} from 'glob-common-js/lib/utils';

export class EvaluationSummaryController extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userSatisfied: 'empty',
        }
    }

    onCompareClick = () => {
        let comparingCurrent = getFromRegistry(['healthInsurance', 'editInsurance', 'comparingCurrent']);
        if (comparingCurrent) {
            this.navigateTo('/zorganalyse/aanpassen/vergelijken');
        }
        else {
            let startTime = new Date().getTime();
            let timeOutTime = 300;
            let registry = getFromRegistry('healthInsurance');
            let data = registry.currentInsuranceRequest;
            sendRequest(createRequestConfig({
                url: '/healthinsurance/analysis',
                method: request.POST,
                data,
                apiVersion: 3,
            }), (response) => {
                this.updateAlternatives(response.data);

                let endTime = new Date().getTime();
                if (endTime - startTime < timeOutTime) {
                    window.setTimeout(() => {
                        navigate('/zorganalyse/aanpassen/vergelijken', true);
                        scrollToTop();
                    }, timeOutTime - (endTime - startTime))
                } else {
                    navigate('/zorganalyse/aanpassen/vergelijken', true);
                    scrollToTop();
                }
            });
        }
    };

    updateAlternatives = (data) => {
        let registry = getFromRegistry('healthInsurance');
        isNotNull(data) ? registry.editInsurance.alternatives = data.alternatives : null;
        updateRegistry('healthInsurance', registry);
    };

    navigateTo = (path) => {
        window.setTimeout(() => {
            navigate(path, true);
            scrollToTop();
        }, 300);
    };

    isUserSatisfied = (decissionsObj) => {
        let decisionsKeys = Object.keys(decissionsObj);
        let satisfied = true;

        for (let i = 0; i < decisionsKeys.length; i++) {
            let decisionKey = decisionsKeys[i];

            if (decissionsObj[decisionKey] !== 'Ja, dit past bij mij') {
                if (decissionsObj[decisionKey] === '') {
                    satisfied = 'empty';
                } else {
                    satisfied = false;
                }
                break;
            }
        }
        this.setState({userSatisfied: satisfied});
    };

    componentDidMount = () => {
        sendPageView('/zorganalyse/evaluatie/samenvatting');
        this.isUserSatisfied(this.props.healthState.evaluation);
    };

    setEvaluation = (decision) => {
        if (isNotNull(decision)) {
            sendEvent('Evaluatie Samenvatting', decision);
            this.props.actions.setEvaluationSummary(decision);
        }
    };

    render = () => {
        return (
            <EvaluationSummaryView userSatisfied={this.state.userSatisfied}
                                   evaluationObj={this.props.healthState.evaluation}
                                   onNavigationClick={this.navigateTo}
                                   onCompareClick={this.onCompareClick}
                                   setEvaluation={this.setEvaluation}
                                   evaluationSummaryClicked={this.props.healthState.evaluationSummary}

            />
        );
    }
}

const mapStateToProps = state => ({
    healthState: state.healthInsuranceAnalysis,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(EvaluationSummaryController);