import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import moment from 'moment';
import * as actions from '../../../redux/actions';

import LoginView from "./loginView";
import {createDossier, login} from "../../../misc/requestSender";
import {addCustomEventListener, dispatchCustomEvent, removeCustomEventListener} from "../../../misc/eventDispatcher";
import {checkOnboardingDecision, isUserLoggedIn, navigate, setReduxUser, setSentryUser, setPlatformVersion, getPlatformVersion} from "../../../misc/utils";
import {getAuthParameter} from "../authenticationHelper";
import {sendGaEvent} from "../../../common/js/ga";
import StateComponent from "../../misc/stateComponent";
import {getFromRegistry, updateRegistry} from "../../insurance/healthAnalysis/misc/registry";
import {NOW} from "../../misc/constants";
import getDossierType from "../../../common/data/mappings/dossierTypeMapping";
import {setJwt} from "../../../common/js/platform";


const GA_CATEGORY = 'Login pagina';

export class LoginController extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    onLogin = (values, onFail) => {
        sendGaEvent(GA_CATEGORY, 'Klik login', 'inloggen');
        this.changeState({loading: true});
        login({
            email: values.email,
            password: values.password,
            callback: (response) => {
                this.loginCallback(response, values, onFail);
            },
        });
    };

    onNewAccount = () => {
        sendGaEvent(GA_CATEGORY, 'Klik', 'Heb je nog geen account?');
        navigate('/registratie');
    };

    onForgotPassword = (email) => {
        sendGaEvent(GA_CATEGORY, 'Klik', 'Wachtwoord vergeten');
        this.props.actions.setUserEmail(email);
        navigate('/auth/wachtwoord-vergeten');
    };

    loginCallback = (response, values, onFail) => {
        this.changeState({loading: false});
        const status = response.data.status;
        if (status === 'success') {
            if (response.data.message === 'message(s) queued') {
                dispatchCustomEvent('2fa', {
                    submitCallback: this.twoFactorCallback,
                    email: values.email,
                    password: values.password,
                    mode: 'login'
                });
            } else {
                this.defaultLoginCallback(response);
            }
        } else
            onFail('Het inloggen is niet gelukt');
    };

    defaultLoginCallback = response => {
        let device = window.isAndroid ? 'Android' : window.isIos ? 'iOS' : 'Website';
        sendGaEvent(device, 'Inloggen', 'Completed');
        let user = response.data.user;
        setJwt(response.data.token);
        setSentryUser(user);
        this.userIsAuthenticated(user);
    };

    twoFactorCallback = (response) => {
        let device = window.isAndroid ? 'Android' : window.isIos ? 'iOS' : 'Website';
        sendGaEvent(device, 'Inloggen', 'Completed');
        dispatchCustomEvent('close2Fa');
        const user = response.data.user;
        setSentryUser(user);
        this.userIsAuthenticated(user);
    };

    navigateAfterLogin = (user) => {
        const target = getAuthParameter('target');
        const action = getAuthParameter('action');
        if (isNotNull(target)) navigate('/' + target, true);
        else if (isNotNull(action)) {
            if (action === 'full_health_analysis') {
                if (window.localStorage) {
                    const storage = window.localStorage;
                    let healthData = storage.getItem('bdhHealthAnalysis');
                    storage.removeItem('bdhHealthAnalysis');
                    if (isNotNull(healthData)) {
                        healthData = JSON.parse(healthData);
                        if (moment(healthData.expires).isSameOrAfter(NOW()))
                            this.navigateToHealthAnalysis(healthData);
                        else navigate('/zorganalyse');
                    } else navigate('/zorganalyse');
                } else navigate('/zorganalyse');
            } else if (action === 'dit-doen-anderen') {
                navigate('/dashboard');
                dispatchCustomEvent('openUploadTips');
            } else if (action === 'belastingjaar-toevoegen'){
                navigate('/dossier-overzicht');
                dispatchCustomEvent('addDossier', 'tax_year');
            }
        } else {
            log('Checking login onboarding decision', user);
            checkOnboardingDecision(user);
            navigate('/dashboard', true);
        }
    };

    navigateToHealthAnalysis = (healthData) => {
        delete healthData.expires;
        createDossier({
            data: {
                name: 'Zorgverzekeringsanalyse 2019',
                values: {},
                external_data: {
                    analysisData: healthData,
                },
                type: getDossierType('health_insurance_analysis').id,
            }
        });
        const registry = Object.assign({}, getFromRegistry('healthInsurance'), healthData);
        updateRegistry('healthInsurance', registry);
        navigate('/zorganalyse/evaluatie/basis-verzekering');
    };

    userIsAuthenticated = user => {
        setReduxUser(user, true);
        setPlatformVersion({argument: user,
          callback: response => {
            this.navigateAfterLogin(user);
          }
        });
        //this.navigateAfterLogin(user);
    };

    executeAutoLogin = () => {
        this.changeState({loading: true});
        isUserLoggedIn(user => {
            const device = window.isAndroid ? 'Android' : window.isIos ? 'iOS' : 'Website';
            sendGaEvent(device, 'Inloggen', 'Completed Auto');
            this.userIsAuthenticated(user);
        }, this.props.history.location.pathname, () => {
            this.changeState({loading: false})
        });
    };

    resetEventListener = () => {
        this.changeState({loading: false});
    };

    componentDidMount = () => {
        this.mount();
        this.executeAutoLogin();
        addCustomEventListener('resetLogin', this.resetEventListener);
    };

    componentWillUnmount = () => {
        this.unMount();
        removeCustomEventListener('resetLogin', this.resetEventListener);
    };

    render = () => {
        return (
            <LoginView onLogin={this.onLogin} onNewAccount={this.onNewAccount} onForgotPassword={this.onForgotPassword}
                       loading={this.state.loading}/>
        );
    };
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch),
});

export default connect(null, mapDispatchToProps)(withRouter(LoginController));
