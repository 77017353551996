import './style/bdhInput.scss';

import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {generateUUID, isFunction} from 'glob-common-js/lib/utils';
import BdhContainedInput from "./bdhContainedInput";
import StateComponent from "../../misc/stateComponent";

export default class BdhInput extends StateComponent {
    #keepProps = ['value', 'disabled', 'readOnly'];
    #value = '';

    constructor(props) {
        super(props);
        this.id = props.id || generateUUID();
        this.state = {
            isFocused: false,
        };
        this.input = null;
    }

    onFocus = evt => {
        const {onKeyDown, onFocus, disabled, readOnly} = this.props;
        this.changeState({isFocused: true});
        if (isFunction(onKeyDown))
            this.addKeyListener();
        if (isFunction(onFocus))
            onFocus(evt);
    };

    onBlur = evt => {
        const {onKeyDown, onBlur} = this.props;
        this.changeState({isFocused: false});
        if (isFunction(onKeyDown))
            this.removeKeyListener();
        if (isFunction(onBlur))
            onBlur(evt);
    };

    onChange = evt => {
        const {onChange} = this.props, value = evt.target.value;
        onChange(value, evt);
    };

    onClickOrnament = ornament => evt => {
        const {onClick, focusOnClick} = ornament;
        if (isFunction(onClick))
            onClick(evt);
        if (focusOnClick !== false)
            this.focus();
    };

    getRenderProps = () => {
        const props = _.merge({}, this.props);
        Object.keys(BdhInput.propTypes).forEach(key => {
            if (!this.#keepProps.includes(key))
                delete props[key];
        });
        return props;
    };

    getInput = () => {
        return this.input;
    };

    focus = () => {
        this.input.focus();
    };

    addKeyListener = () => {
        const {onKeyDown} = this.props;
        if (isFunction(onKeyDown)) {
            const input = this.input;
            if (isNotNull(input)) {
                const addEvent = input.addEventListener || input.attachEvent;
                addEvent('keydown', onKeyDown)
            }
        }
    };

    removeKeyListener = () => {
        const {onKeyDown} = this.props;
        if (isFunction(onKeyDown)) {
            const input = this.input;
            if (isNotNull(input)) {
                const removeEvent = input.removeEventListener || input.detachEvent;
                removeEvent('keydown', onKeyDown);
            }
        }
    };

    renderLabel = () => {
        const {label, labelClass} = this.props;
        if (isNotNull(label))
            return (
                <label className={classNames('bdhInputLabel', labelClass)}
                       htmlFor={this.id}>{label}</label>
            );
        return null;
    };

    renderInput = () => {
        const {inputClass, inputContainerClass, error, readOnly} = this.props;
        return (
            <div className={classNames('inputContainer', readOnly && 'readOnly', inputContainerClass)}>
                <input ref={ref => {
                    this.input = ref;
                }} id={this.id} className={classNames('bdhInput', inputClass)} onChange={this.onChange}
                       onFocus={this.onFocus} onBlur={this.onBlur} {...this.getRenderProps()}/>
                {this.renderOrnaments()}
                {isNotNull(error) && <label className='error'>{error}</label>}
            </div>
        )
    };

    renderOrnaments = () => {
        const {ornaments} = this.props;
        return ornaments.map((ornament, key) => {
            let {element, placement} = ornament;
            placement = isNull(placement) ? 'right' : placement;
            return <div key={key} className={classNames('ornament', placement)}
                        onClick={this.onClickOrnament(ornament)}>
                {element}
            </div>
        });
    };

    componentDidMount = () => {
        this.mount();
    };

    componentWillUnmount = () => {
        this.unMount();
        this.removeKeyListener();
    };

    render = () => {
        const {variant} = this.props;
        if (variant === 'contained') return <BdhContainedInput {...this.props}/>;
        const {isFocused} = this.state;
        const {containerClass, disabled, key, withLabelTransition, label, error, fullWidth, value} = this.props;
        const enableTransition = withLabelTransition && isNotNull(label);
        const hasError = isNotNull(error);
        const focus = isNotNull(value) || isFocused;
        const focusColor = isFocused;

        const extraProps = {};
        if (isNotNull(key)) extraProps[key] = key;
        return (
            <div className={classNames('bdhInputContainer', containerClass, disabled && 'disabled', focus && 'focus',
                focusColor && 'focusColor', enableTransition && 'labelTransition', hasError && 'error',
                fullWidth && 'maxWidth')} {...extraProps}>
                {this.renderLabel()}
                {this.renderInput()}
            </div>
        )
    };
}

BdhInput.propTypes = {
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onKeyDown: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    label: PropTypes.string,
    containerClass: PropTypes.string,
    labelClass: PropTypes.string,
    inputContainerClass: PropTypes.string,
    inputClass: PropTypes.string,
    id: PropTypes.string,
    key: PropTypes.string,
    withLabelTransition: PropTypes.bool,
    ornaments: PropTypes.arrayOf(PropTypes.shape({
        placement: PropTypes.oneOf(['left', 'right']),
        element: PropTypes.node.isRequired,
    })),
    variant: PropTypes.oneOf(['contained', 'explode']),
    error: PropTypes.string,
    fullWidth: PropTypes.bool,
};

BdhInput.defaultProps = {
    disabled: false,
    readOnly: false,
    containerClass: '',
    labelClass: '',
    inputContainerClass: '',
    inputClass: '',
    type: 'text',
    min: 0,
    max: 0,
    step: 0,
    withLabelTransition: true,
    ornaments: [],
    variant: 'explode',
    fullWidth: false,
};