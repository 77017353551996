import './style/analysisView.scss';

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import StateComponent from "../misc/stateComponent";
import MaterialFactory from "../material/materialFactory";
import {materialTypes} from "../material/materialTypes";
import BdhSearchInput from "../customControls/input/bdhSearchInput";

export const MODE_NEW = 0, MODE_EXISTING = 1;
export default class AnalysisView extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            selectedItems: [],
            mode: MODE_EXISTING,
        };
    }

    onClickHeaderExisting = () => {
        // TODO GA
        this.changeState({mode: MODE_EXISTING});
    };

    onClickHeaderNew = () => {
        //TODO GA
        this.changeState({mode: MODE_NEW});
    };

    onClickAnalysis = (analysis, type) => () => {
        if (type === MODE_NEW)
            this.props.onClickNewAnalysis(analysis);
        else this.props.onClickAnalysis(analysis);
    };

    onSelectAnalysis = (id) => evt => {
        // TODO GA
        evt.stopPropagation();
        const {selectedItems} = this.state;
        if (selectedItems.includes(id))
            selectedItems.splice(selectedItems.indexOf(id), 1);
        else selectedItems.push(id);
        this.changeState({selectedItems});
    };

    onDeleteSelected = () => {
        this.props.onDelete(this.state.selectedItems, () => {
            this.changeState({selectedItems: []})
        });
    };

    renderBodyNew = () => {
        const {analysisTypes} = this.props;
        return analysisTypes.map((type, key) => this.renderAnalysisBlock(MODE_NEW, type, key));
    };

    renderBodyExisting = () => {
        const {analysis, searchValue} = this.props;
        if (isNotNull(analysis))
            return analysis.map((analysis, key) => this.renderAnalysisBlock(MODE_EXISTING, analysis, key));
        return (
            <div className='noAnalysisContainer'>
                <label className='noAnalysis'>{isNotNull(searchValue) ? 'Geen analyse gevonden' :
                    'Je hebt nog geen analyses gestart'}</label>
                {isNull(searchValue) &&
                <MaterialFactory componentType={materialTypes.RAISED_BUTTON} onClick={this.onClickHeaderNew}>
                    Nieuwe analyse starten</MaterialFactory>}
            </div>
        );
    };

    renderAnalysisBlock = (type, analysis, key) => {
        const {selectedItems} = this.state;
        const {label, icon, id, implemented} = analysis, isImplemented = type === MODE_EXISTING || implemented === true;
        const isSelected = selectedItems.includes(id);
        return (
            <div key={key}
                 className={classNames('analysisBlock', type === MODE_EXISTING && 'existing', isSelected && 'selected',
                     !isImplemented && 'disabled')}
                 onClick={this.onClickAnalysis(analysis, type)}>
                {type === MODE_EXISTING &&
                <div className='analysisSelector' onClick={this.onSelectAnalysis(id)}>
                    <span className='common-icon-check analysisCheck'/>
                </div>}
                <div className='analysisInnerBlock'>
                    <span className={classNames(icon, 'analysisIcon', type === MODE_NEW && 'new')}/>
                    {type === MODE_NEW && <span className='common-icon-plus addIcon'/>}
                    {!isImplemented && <div className='notImplementedOverlay'>
                        <label className='notImplemented'>Binnenkort</label>
                    </div>}
                </div>
                <label className='analysisLabel' title={label}>{label}</label>
            </div>
        )
    };

    componentDidMount = () => {
        this.mount();
    };

    componentDidUpdate = (prevProps) => {
        const prevLoading = prevProps.loading, {loading, analysis} = this.props;
        if (prevLoading && !loading) {
            this.changeState({mode: isNotNull(analysis) ? MODE_EXISTING : MODE_NEW});
        }
    };

    componentWillUnmount = () => {
        this.unMount();
    };

    render = () => {
        const {searchValue, onSearch, onClearSearch} = this.props;
        const {mode, selectedItems} = this.state;
        return (
            <div className='analysisView'>
                <div className='analysisHeader'>
                    <MaterialFactory componentType={materialTypes.RAISED_BUTTON} onClick={this.onClickHeaderExisting}
                                     className={classNames('headerButton', mode === MODE_NEW && 'inactive')}>
                        Mijn analyses</MaterialFactory>
                    <MaterialFactory componentType={materialTypes.RAISED_BUTTON} onClick={this.onClickHeaderNew}
                                     className={classNames('headerButton', mode === MODE_EXISTING && 'inactive')}>
                        Nieuwe analyse</MaterialFactory>
                </div>
                <div className='analysisSubHeader'>
                    <label
                        className='subHeader'>{mode === MODE_NEW ? 'Nieuwe analyse starten' : 'Mijn analyses'}</label>
                    <BdhSearchInput onChange={onSearch} value={searchValue} onClearSearch={onClearSearch}
                                    label='Zoek een analyse...' containerClass='searchAnalysis'/>
                </div>
                {mode === MODE_EXISTING &&
                <MaterialFactory componentType={materialTypes.RAISED_BUTTON} onClick={this.onDeleteSelected}
                                 disabled={isNull(selectedItems)} className='deleteAnalysis'>
                    Verwijderen</MaterialFactory>}
                <div className='analysisBody'>
                    {mode === MODE_NEW ? this.renderBodyNew() : this.renderBodyExisting()}
                </div>
            </div>
        )
    };
}

AnalysisView.propTypes = {
    onClickAnalysis: PropTypes.func.isRequired,
    onClickNewAnalysis: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    analysis: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
    })).isRequired,
    analysisTypes: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
    })).isRequired,
    searchValue: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
};