import './style/mailingList.scss';

import React from 'react';
import PropTypes from 'prop-types';
import {KeyboardArrowDown} from "@material-ui/icons";
import classNames from 'classnames';

import {DaisyUser} from "../../models/daisyUser";
import BdhSelectController from "../customControls/select/bdhSelectController";
import GenericLoader from "../misc/genericLoader";
import MaterialFactory from "../material/materialFactory";
import {materialTypes} from "../material/materialTypes";
import CustomScrollbar from "../misc/customScrollbar";

export default class MailingList extends React.Component {
    renderFilterBar = () => {
        const {onSelectType, type, loading, onGenerateList} = this.props;
        return (
            <div className='filterBar'>
                <BdhSelectController onSelect={onSelectType} value={[type]} variant='contained' label='Accounttype'
                                     items={[{value: 'PAY', label: 'Betaald'}, {value: 'FREE', label: 'Gratis'},
                                         {value: 'all', label: 'Alle'}]} readOnly/>
                <MaterialFactory componentType={materialTypes.RAISED_BUTTON} onClick={onGenerateList}
                                 className='listButton' disabled={loading}>Genereer lijst</MaterialFactory>
            </div>
        )
    };

    renderUserList = () => {
        const {users, onGenerateExcel, loading} = this.props;
        return (
            <div className='userList'>
                <div className='statsBar'>
                    <label className='usersCount'>{users.length} gebruikers</label>
                    <MaterialFactory componentType={materialTypes.RAISED_BUTTON} onClick={onGenerateExcel}
                                     disabled={loading || isNull(users)}>Download Excel</MaterialFactory>
                </div>
                <div className='usersTable'>
                    <GenericLoader active={loading}/>
                    {this.renderTableHead()}
                    {this.renderTableBody()}
                </div>
            </div>
        )
    };

    renderTableHead = () => {
        return (
            <div className='row head'>
                {this.renderHeadCell('number', '#')}
                {this.renderHeadCell('id', 'ID')}
                {this.renderHeadCell('firstname', 'Voornaam')}
                {this.renderHeadCell('email', 'E-mailadres')}
                {this.renderHeadCell('type', 'Accounttype')}
                {this.renderHeadCell('status', 'Accountstatus')}
            </div>
        )
    };

    renderHeadCell = (name, value) => {
        const {order, orderDir, onOrder} = this.props, hasOrder = name === order;
        return (
            <div className={classNames('cell', name)} onClick={onOrder(name)}>
                <label className='cellValue'>{value}</label>
                {hasOrder && <KeyboardArrowDown className={classNames('orderArrow', orderDir)}/>}
            </div>
        )
    };

    renderCell = (name, value) => (
        <div className={classNames('cell', name)}>
            <label className='cellValue'>{value}</label>
        </div>
    );

    renderTableBody = () => {
        const {users} = this.props;
        return users.map((user, key) => (
            <div className='row'>
                {this.renderCell('number', key + 1)}
                {this.renderCell('id', user.id)}
                {this.renderCell('firstname', user.firstname)}
                {this.renderCell('email', user.email)}
                {this.renderCell('type', user.type)}
                {this.renderCell('status', user.status)}
            </div>
        ));
    };

    render = () => {
        return (
            <div className='mailingList'>
                {this.renderFilterBar()}
                {this.renderUserList()}
            </div>
        )
    };
}

MailingList.propTypes = {
    onSelectType: PropTypes.func.isRequired,
    onGenerateExcel: PropTypes.func.isRequired,
    onGenerateList: PropTypes.func.isRequired,
    onOrder: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['id', 'email', 'type', 'status']).isRequired,
    orderDir: PropTypes.oneOf(['asc', 'desc']).isRequired,
    type: PropTypes.oneOf(['FREE', 'PAY', 'all']).isRequired,
    users: PropTypes.arrayOf(PropTypes.instanceOf(DaisyUser)).isRequired,
    loading: PropTypes.bool.isRequired,
};