import './style/agendaDayView.scss';

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {timeFormats} from "../../../../common/components/datePicker";
import CustomScrollbar from "../../../misc/customScrollbar";
import MaterialFactory from "../../../material/materialFactory";
import {materialTypes} from "../../../material/materialTypes";

export default class AgendaDay extends React.Component {
    constructor(props) {
        super(props);
        this.timer = null;
    }

    onArrowClick = type => {
        let currentDate = moment(this.props.selectedDate);
        if (type === 'next')
            currentDate.add(1, 'days');
        else if (type === 'prev')
            currentDate.subtract(1, 'days');
        this.props.onSelectDate(currentDate.format('YYYY-MM-DD'));
    };

    setTime = () => {
        const timeLabel = document.getElementById('timeLabel');
        if (isNotNull(timeLabel)) {
            timeLabel.innerHTML = moment().format('HH:mm:ss');
        }
    };

    renderAgendaItems = () => {
        let agendaItems = this.props.agendaItems;
        return agendaItems.map((agendaItem) => {
            const startDate = moment(agendaItem.start_date_item, timeFormats);
            const endDate = isNotNull(agendaItem.end_date_item) ? moment(agendaItem.end_date_item, timeFormats) : null;
            const isSameDay = isNotNull(endDate) && startDate.isSame(endDate, 'day');
            const timeString = startDate.format('HH:mm') + (isSameDay ? ' - ' + endDate.format('HH:mm') : '');
            return (
                <div key={agendaItem.id} className='agendaDayItem' onClick={() => {
                    this.props.onClickAgendaItem(agendaItem);
                }}>
                    <label className='agendaItemName'>{agendaItem.name}</label>
                    <label className='agendaItemTime'>{timeString}</label>
                </div>
            );
        });
    };

    componentDidMount = () => {
        this._isMounted = true;
        this.timer = window.setInterval(this.setTime, 1000);
    };

    componentWillUnmount = () => {
        this._isMounted = false;
        window.clearInterval(this.timer);
    };

    render = () => {
        return (
            <div className='agendaDayView'>
                <div className='agendaDayViewHeader'>
                    <div className='dateContainer'>
                        <span className='common-icon-arrow-left arrow' onClick={() => {
                            this.onArrowClick('prev');
                        }}/>
                        <MaterialFactory componentType={materialTypes.DATE_PICKER} onChange={this.props.onSelectDate}
                                         value={this.props.selectedDate} className='agendaDayViewDate'
                                         format='dddd D MMMM' keyboard={false} autoOk={false}/>
                        <span className='common-icon-arrow-right arrow' onClick={() => {
                            this.onArrowClick('next');
                        }}/>
                    </div>
                    <label id='timeLabel' className='timeLabel'/>
                </div>
                <CustomScrollbar height='9.5rem'>
                    <div className='agendaDayItemsContainer'>
                        {this.renderAgendaItems()}
                    </div>
                </CustomScrollbar>
            </div>
        )
    };
}

AgendaDay.propTypes = {
    onAddItemClick: PropTypes.func.isRequired,
    onSelectDate: PropTypes.func.isRequired,
    onClickAgendaItem: PropTypes.func.isRequired,
    agendaItems: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        start_date_item: PropTypes.string.isRequired,
        end_date_item: PropTypes.string.isRequired,
    })).isRequired,
    selectedDate: PropTypes.string.isRequired,
};

