import React from 'react';
import {Route, Switch} from 'react-router-dom';

import EvaluationBasicController from "./basic/evaluationBasicController";
import EvaluationExtraController from "./additional/evaluationAdditionalController";
import EvaluationInsurerController from "./insurer/evaluationInsurerController";
import EvaluationSummaryController from "./summary/evaluationSummaryController";
import EvaluationRiskController from "./risk/evaluationRiskController";

export default class EvaluationView extends React.Component {
    constructor(props) {
        super(props);
    }

    render = () => {
        return (
            <Switch>
                <Route path='/zorganalyse/evaluatie/basis-verzekering' render={() => {
                    return <EvaluationBasicController/>
                }}/>
                <Route path='/zorganalyse/evaluatie/aanvullende-verzekering' render={() => {
                    return <EvaluationExtraController/>
                }}/>
                <Route path='/zorganalyse/evaluatie/eigen-risico' render={() => {
                    return <EvaluationRiskController/>
                }}/>
                <Route path='/zorganalyse/evaluatie/jouw-verzekeraar' render={() => {
                    return <EvaluationInsurerController/>
                }}/>
                <Route path='/zorganalyse/evaluatie/samenvatting' render={() => {
                    return <EvaluationSummaryController/>
                }}/>
            </Switch>
        )
    }
}