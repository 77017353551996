import buyCarMap from './buyCar';
import cvMap from './cv';
import divorceMap from './divorce';
import funeralMap from './funeral';
import childMap from './child';
import loanMap from './loan';
import travelMap from './travel';
import sellHouse from './sellHouse';
import lifeTestamentMap from './LifeTestament';

export default [
    buyCarMap,
    cvMap,
    divorceMap,
    funeralMap,
    childMap,
    loanMap,
    travelMap,
    sellHouse,
    lifeTestamentMap,
];
