import './style/menu.scss';

import React from 'react';
import PropTypes from 'prop-types';

import {getSetting, isNotNull} from 'glob-common-js/lib/utils';

import menuItems from './menuItems';
import {sendEvent} from "../misc/healthAnalysisUtils";


const urls = [
    '/huidige-verzekering',
    '/evaluatie',
    '/aanpassen',
    '/jouw-verzekering',
];

export default class MenuView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentLocation: '',
        }

    }

    clickMenuItem = (nextRoute, label, isMainItem = false) => {
        if (isNotNull(nextRoute)) {
            sendEvent('Klik op tab', label);
            // Deselect the item if the clicked item is a main item which is already active.
            if (isMainItem && this.props.activeRoute.indexOf(nextRoute) > -1) {
                document.getElementById('menuItem' + nextRoute).parentNode.classList.toggle('active');
            }
            this.setActiveClasses(nextRoute);
            this.props.onNavigate(nextRoute);
        }
    };

    setActiveClasses = (url) => {
        let urlIndex = urls.indexOf(url);
        // Finish previous steps
        if (urlIndex > 0) {
            for (let i = urlIndex - 1; i >= 0; i--) {
                let mainItem = document.getElementById('menuItem' + urls[i]);
                if (isNotNull(mainItem)) {
                    mainItem.classList.add('finished');
                }
            }
        }

        // Remove finished state for (possible) next steps
        if (urlIndex < urls.length - 1) {
            for (let i = urlIndex; i < urls.length; i++) {
                let mainItem = document.getElementById('menuItem' + urls[i]);
                if (isNotNull(mainItem)) {
                    mainItem.classList.remove('finished');
                }
            }
        }
    };

    createMenuItem = (menuItem, key) => {
        let url = menuItem.url;
        let isActive = this.props.activeRoute.indexOf(url) > -1;
        let css = 'menuItemContainer' + (isActive ? ' active' : '');
        let showTop = key !== 0; // Skip top line for first roadMap element
        let isLast = key === 3; // Skip bottom line for last roadMap element
        let onClick = () => {
            this.createMenuItem(menuItem, key)
        };
        let menuMainItem = "menuMainItem inactive";
        if (isActive) this.setActiveClasses(url);


        if (isNotNull(this.props.healthState.evaluationSummary) || menuItem.label === "Evaluatie"
            || menuItem.label === "Jouw zorgverzekering 2019") {
            onClick = () => {
                this.clickMenuItem(menuItem.url, menuItem.label, true)
            };
            menuMainItem = "menuMainItem" + (menuItem.label === "Jouw zorgverzekering 2019" ? ' curDef' : '');
        }

        //If developer remove limitations
        if (getSetting('mode') === "dev") {
            onClick = () => {
                this.clickMenuItem(menuItem.url, menuItem.label, true)
            };
            menuMainItem = "menuMainItem";
        }

        return (
            <div key={key} className={css}>
                <div id={'menuItem' + url} className={menuMainItem} onClick={onClick}>
                    <div className='roadMap'>
                        {showTop ? <div className="roadMapTop"/> : null}
                        <div className="roadMapCircle">
                            <i className="common-icon-check finishedIcon"/>
                        </div>
                        <div className={"roadMapBottom" + (isLast ? ' last' : '')}/>
                    </div>
                    <label className='menuMainName'>{menuItem.label}</label>
                </div>
                {isNotNull(menuItem.subItems) ?
                    <div className="subItemContainer">
                        {menuItem.subItems.map((subItem, key) => {
                            return this.createSubMenuItem(subItem, url, key)
                        })}
                    </div>
                    : null}
            </div>
        );
    };

    createSubMenuItem = (subMenuItem, mainUrl, key) => {
        let menuSubItemCss = 'menuSubItem' + (this.props.activeRoute.endsWith(subMenuItem.url) ? ' active' : '');
        let roadMapCircle = 'roadMapCircle';
        let evaluation = this.props.healthState.evaluation;
        let evaluationArray = Object.keys(this.props.healthState.evaluation);
        let index = evaluationArray.indexOf(subMenuItem.name);

        let onClick = () => {
            this.createSubMenuItem(subMenuItem, mainUrl, key)
        };

        //Evaluation items that are in the healthState
        if (index !== -1 && mainUrl === "/evaluatie") {
            //Evaluation items that are answered
            if (isNotNull(evaluation[evaluationArray[index]])) {
                onClick = () => {
                    this.clickMenuItem(mainUrl + subMenuItem.url, subMenuItem.label);
                };
                roadMapCircle = 'roadMapCircle finished';
            }
            //Evaluation items where the last Evaluatie item is answered
            else if (isNotNull(evaluation[evaluationArray[(index - 1)]])) {
                onClick = () => {
                    this.clickMenuItem(mainUrl + subMenuItem.url, subMenuItem.label);
                }
            }
            //Evaluation item basic should always be accesable
            else if (evaluationArray[index] === "basic") {
                onClick = () => {
                    this.clickMenuItem(mainUrl + subMenuItem.url, subMenuItem.label);
                }
            } else {
                menuSubItemCss = 'menuSubItem inactive';
            }
        }
        //Evaluation items that are not in the healthState evaluation
        else if (index === -1 && mainUrl === "/evaluatie") {
            //if evaluation item is "Samenvatting" check if all evaluation items are answered
            if (subMenuItem.label === "Samenvatting") {
                if (isNotNull(this.props.healthState.evaluationSummary)) {
                    onClick = () => {
                        this.clickMenuItem(mainUrl + subMenuItem.url, subMenuItem.label);
                    };
                    roadMapCircle = 'roadMapCircle finished';
                    menuSubItemCss = 'menuSubItem' + (this.props.activeRoute.endsWith(subMenuItem.url) ? ' active' : '');
                } else if (isNotNull(evaluation[evaluationArray[(evaluationArray.length - 1)]])) {
                    onClick = () => {
                        this.clickMenuItem(mainUrl + subMenuItem.url, subMenuItem.label);
                    };
                } else {
                    menuSubItemCss = 'menuSubItem inactive';
                }

            }
        }
        //Items that are not part of the evaluation process
        else if (mainUrl !== "/evaluatie") {
            onClick = () => {
                this.clickMenuItem(mainUrl + subMenuItem.url, subMenuItem.label);
            };
            roadMapCircle = 'roadMapCircle';
        }

        //If developer remove limitations
        if (getSetting('mode') === "dev") {
            onClick = () => {
                this.clickMenuItem(mainUrl + subMenuItem.url, subMenuItem.label);
            };
            menuSubItemCss = 'menuSubItem' + (this.props.activeRoute.endsWith(subMenuItem.url) ? ' active' : '');
        }

        return (
            <div key={key} className={menuSubItemCss} onClick={onClick}>
                <div className='roadMap'>
                    <div className="roadMapTop"/>
                    <div className={roadMapCircle}/>
                    <div className="roadMapBottom"/>
                </div>
                <label className='menuSubName'>{subMenuItem.label}</label>
            </div>
        );
    };

    render = () => {
        return (
            <div className='menuContainer'>
                <div className='menuHeader'>
                    <label className='menuHeaderTitle'>Stappenplan</label>
                </div>
                {menuItems.map(this.createMenuItem)}
            </div>
        )
    }
}

MenuView.propTypes = {
    activeRoute: PropTypes.string.isRequired,
    onNavigate: PropTypes.func.isRequired,
};
