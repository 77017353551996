import React from 'react';
import _ from 'lodash';
import {withRouter} from "react-router-dom";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../../../redux/actions';
import * as dosActions from '../../../common/data/mappings/dossierActions';

import {deleteDocument, downloadDocuments, getDocument, updateDocument} from "../../../misc/requestSender";
import getDocumentType from "../../../common/data/mappings/docTypeMapping";
import DossierDetailsView from "./dossierDetailsView";
import ErrorBoundary from "../../errorBoundary";
import {addCustomEventListener, dispatchCustomEvent, removeCustomEventListener} from "../../../misc/eventDispatcher";
import AddFromLibraryController from "./documents/addFromLibraryController";
import {navigate} from "../../../misc/utils";
import {sendGaEvent, sendPageView} from "../../../common/js/ga";
import StateComponent from "../../misc/stateComponent";
import {materialTypes} from "../../material/materialTypes";
import MaterialFactory from "../../material/materialFactory";
import {getOnboardingManager} from "../../onboarding/manager/onboardingManagerHelper";
import {storeOnboardingSteps} from "../../onboarding/manager/storeManager";
import Dossier from "../../../models/dossier";
import {addDataEventListener, DATA_TYPES, EVENT_TYPES, removeAllDataEventListeners} from "../../../misc/dataEvent";

const GA_CATEGORY = 'Dossier details';

export class DossierDetailsController extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            dossier: new Dossier(),
            loading: false,
            activeActions: {data: 'Gegevens', documents: 'Gekoppelde documenten'},
            selectedDocuments: [],
            dialog: {
                mode: 'unlink',
                text: '',
            },
        };
    }

    onSelectDocument = doc => {
        if (isNotNull(doc)) {
            const {dossier, selectedDocuments} = this.state;
            const id = doc.id;
            if (isNotNull(dossier)) {
                let documents = selectedDocuments.slice();
                if (documents.includes(id)) {
                    documents.splice(documents.indexOf(id), 1);
                    sendGaEvent(GA_CATEGORY, 'Selecteer document', 'Uit');
                } else {
                    documents.push(id);
                    sendGaEvent(GA_CATEGORY, 'Selecteer document', 'Aan');
                }
                this.changeState({selectedDocuments: documents});
            }
        }
    };

    onClickDocument = doc => {
        sendGaEvent(GA_CATEGORY, 'Open document', doc.type.label);
        dispatchCustomEvent('openDocumentDetails', doc);
    };

    onConfirmDelete = () => {
        const {selectedDocuments} = this.state, text = isNotNull(selectedDocuments) ? selectedDocuments.length === 1 ?
            'dit document' : 'deze documenten' : '';
        this.changeState({
            dialog: {
                mode: 'delete',
                text: 'Weet je zeker dat je ' + text + ' wilt verwijderen?',
            },
        });
    };

    onConfirmUnlink = () => {
        const {selectedDocuments} = this.state, text = isNotNull(selectedDocuments) ? selectedDocuments.length === 1 ?
            'dit document' : 'deze documenten' : '';
        this.changeState({
            dialog: {
                mode: 'unlink',
                text: 'Weet je zeker dat je ' + text + ' wilt ontkoppelen?',
            },
        });
    };

    onAcceptDialog = () => {
        const {mode} = this.state.dialog;
        this.onCloseDialog();
        if (mode === 'delete') this.onDeleteDocuments();
        else if (mode === 'unlink') this.onUnlinkDocuments();
    };

    onCloseDialog = () => {
        this.changeState({
            dialog: {
                mode: 'unlink',
                text: '',
            }
        });
    };

    onDeleteDocuments = () => {
        sendGaEvent(GA_CATEGORY, 'Documenten actie', 'Verwijderen');
        const {selectedDocuments} = this.state;
        if (isNotNull(selectedDocuments)) {
            this.changeState({loading: true});
            let deleteCounter = 0, toDelete = selectedDocuments.length;
            const docDeleted = () => {
                if (++deleteCounter >= toDelete) {
                    this.changeState({loading: false, selectedDocuments: []});
                    this.loadDossier();
                }
            };
            for (let i = 0; i < toDelete; i++) {
                let documentId = selectedDocuments[i];
                deleteDocument({
                    id: documentId,
                    callback: docDeleted,
                });
            }
        }
    };

    onUnlinkDocuments = () => {
        sendGaEvent(GA_CATEGORY, 'Documenten actie', 'Ontkoppelen');
        const {selectedDocuments} = this.state;
        if (isNotNull(selectedDocuments)) {
            this.changeState({loading: true});
            let docsUnlinked = 0, toUnlink = selectedDocuments.length;
            const docUnlinked = () => {
                if (++docsUnlinked >= toUnlink) {
                    this.changeState({loading: false, selectedDocuments: []});
                    this.loadDossier();
                }
            };
            for (let i = 0; i < toUnlink; i++) {
                getDocument({
                    id: selectedDocuments[i],
                    callback: response => {
                        let doc = response.data.vault;
                        doc.belonging_ids = (doc.belongings.map(dossier => dossier.id))
                            .filter(dossierId => dossierId !== this.state.dossier.id);
                        delete doc.belongings;
                        updateDocument({
                            id: doc.id,
                            data: doc,
                            callback: docUnlinked,
                        })
                    }
                })
            }
        }
    };

    onAddDocumentType = documentType => () => {
        if (String(documentType.id).substring(0, 10)==='REPLACE_ID'){
          documentType.id=documentType.replaceID
        }
        const {label, id} = documentType;
        const manager = getOnboardingManager('store');
        if (manager.isActive()) {
            manager.sendEvent('Klik', `Document keuze (${label})`);
            manager.removeHighlight('dossierDetailsDocuments');
            manager.setNextStep();
        } else
            sendGaEvent(GA_CATEGORY, 'Document toevoegen', label);
        dispatchCustomEvent('addDocument', {typeId: id, saveCallback: this.onLinkDocument});
    };

    onClickLibrary = () => {
        const {dossier} = this.state;
        dispatchCustomEvent('addFromLibrary', {
            dossierId: dossier.id, callback: this.loadDossier, dossierFileIds: dossier.files.map(file => file.id)
        });
    };

    onLinkDocument = doc => {
        const {dossier} = this.state;
        this.checkOnboardingFlow();
        doc.load(doc => {
            doc.addDossier(dossier);
            doc.update(() => {
                this.loadDossier(false);
            });
        });
    };

    onDownloadDocuments = () => {
        const {selectedDocuments, dossier} = this.state;
        downloadDocuments({
            file_ids: isNotNull(selectedDocuments) ? selectedDocuments : null,
            dossier_ids: isNotNull(selectedDocuments) ? null : [dossier.id],
        })
    };

    onShareDocuments = () => {
        const {selectedDocuments, dossier} = this.state;
        const data = {documents: selectedDocuments, dossiers: isNotNull(selectedDocuments) ? null : [dossier.id]};
        dispatchCustomEvent('shareDocuments', data);
    };

    onClickAction = (action) => {
        const type = action.type || 'data';
        sendGaEvent(GA_CATEGORY, 'Klik kolom tab (' + type + ')', action.label);
        let activeActions = Object.assign({}, this.state.activeActions);
        if (type === 'data')
            activeActions.data = action.label;
        else if (type === 'documents')
            activeActions.documents = action.label;
        this.changeState({activeActions: activeActions});
        action.action(this.state.dossier);
    };

    onUpdateEvent = dossier => {
        dossier.type = Object.assign({}, dossier.type, {
            actions: this.getDossierActions(dossier.type)
        });
        dossier.setField('documentTypes', this.loadDocumentTypes(dossier.type));
        const documentAction = isNotNull(dossier.documents) ? 'Gekoppelde documenten' : 'Nieuwe documenten';
        this.changeState({
            dossier, loading: false, activeActions: {
                ...this.state.activeActions, documents: documentAction,
            }
        });
    };

    loadDossier = (checkOnboarding = true, dossierId = null) => {
        this.changeState({loading: true});
        dossierId = isNotNull(dossierId) ? dossierId : this.props.dossierState.dossier;
        if (isNotNull(dossierId)) {
            if (dossierId instanceof Dossier) this.loadInstanceDossier(dossierId, checkOnboarding);
            if (typeof dossierId === 'string')
                Dossier.get(dossierId, dossier => {
                    this.loadInstanceDossier(dossier, checkOnboarding);
                });
        }
    };

    loadInstanceDossier = (dossier, onboarding) => {
        dossier.load(dossier => {
            const type = dossier.type;
            type.actions = [
                {label: 'Gegevens', action: dosActions.renderDossierDetails},
                {label: 'Notities', action: dosActions.renderNotes},
                {label: 'Contacten', action: dosActions.renderContacts},
            ];
            dossier.type = type;
            dossier.setField('documentTypes', this.loadDocumentTypes(dossier.type));
            const documentAction = isNotNull(dossier.documents) ? 'Gekoppelde documenten' : 'Nieuwe documenten';

            this.changeState({
                dossier, loading: false, activeActions: {
                    ...this.state.activeActions, documents: documentAction,
                }
            });

            this.props.actions.setDossier(dossier);
            if (onboarding)
                this.checkOnboardingFlow();
        });
    };

    loadDocumentTypes = (dossierType) => {
        let documentTypes = Object.values(getDocumentType());
        const filtered = documentTypes.filter((documentType) => (documentType.dosLinks.includes(dossierType.name)));
        return isNotNull(filtered) ? filtered : documentTypes;
    };

    getDossierActions = dossierType => {
        const {keepActions, saveActions} = dossierType;
        const actions = Object.values(keepActions).concat(Object.values(saveActions));
        if (_.size(actions) === 0) return [];
        return actions.filter(action => action.label !== 'Documenten');
    };

    checkOnboardingFlow = () => {
        const manager = getOnboardingManager('store');
        if (manager.isActive()) {
            if (manager.getActiveStep() === storeOnboardingSteps.CONFIRM_DOSSIER)
                manager.setStep(storeOnboardingSteps.SELECT_DOCUMENT);
            else if (manager.getActiveStep() === storeOnboardingSteps.ADD_DOCUMENT)
                manager.setStep(storeOnboardingSteps.CONFIRM_DOCUMENT);
        }
    };

    getDossierId = () => {
        return this.props.match.params.id;
    };

    renderDialogActions = () => ([
        {label: 'Nee', onClick: this.onCloseDialog},
        {label: 'Ja', onClick: this.onAcceptDialog},
    ]);

    loadWithId = (id) => {
        const {match, dossierState} = this.props, dossierId = id || match.params.id;
        if (isNotNull(dossierId) || isNotNull(dossierState.dossier)) {
            sendPageView('/dossier-details');
            this.loadDossier(true, dossierId);
        } else navigate('/dossier-overzicht');
    };

    componentDidMount = () => {
        this.mount();
        addDataEventListener(DATA_TYPES.DOSSIER, EVENT_TYPES.UPDATE, 'dossierDetailsController', this.onUpdateEvent);
        addCustomEventListener('dossierIdChanged', this.loadWithId);
        this.loadWithId();
    };

    componentWillUnmount = () => {
        this.unMount();
        removeAllDataEventListeners('dossierDetailsController', this.onUpdateEvent);
        removeCustomEventListener('dossierIdChanged', this.loadWithId);
    };

    render = () => {
        const {dossier, loading, dialog, activeActions, selectedDocuments} = this.state;
        return (
            <ErrorBoundary>
                <MaterialFactory componentType={materialTypes.DIALOG} title='Bevestig'
                                 open={isNotNull(dialog.text)} onClose={this.onCloseDialog}
                                 actions={this.renderDialogActions()} text={dialog.text}/>
                <DossierDetailsView dossier={dossier} activeActions={activeActions} loading={loading}
                                    clickAction={this.onClickAction} selectedDocuments={selectedDocuments}
                                    onClickDocument={this.onClickDocument} onDeleteDocuments={this.onConfirmDelete}
                                    onUnlinkDocuments={this.onConfirmUnlink} onClickLibrary={this.onClickLibrary}
                                    onAddDocumentType={this.onAddDocumentType} onSelectDocument={this.onSelectDocument}
                                    onDownloadDocuments={this.onDownloadDocuments}
                                    onShareDocuments={this.onShareDocuments}/>
                <AddFromLibraryController/>
            </ErrorBoundary>
        );
    };
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch),
});

const mapStateToProps = state => ({
    dossierState: state.dossier,
    userState: state.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DossierDetailsController));
