import React from 'react';
import {connect} from 'react-redux';

import AddFamilyMember from "./addFamilyMember";
import {addFamily, deleteFamily, updateFamily} from "../../../misc/requestSender";
import {dispatchCustomEvent} from "../../../misc/eventDispatcher";
import {materialTypes} from "../../material/materialTypes";
import MaterialFactory from "../../material/materialFactory";

export class AddFamilyController extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmDelete: false,
        };
        this._isMounted = false;
        this.deleteCallback = null;
        this.memberId = null;
    }

    changeState = stateChange => {
        this._isMounted && this.setState(stateChange);
    };

    onSubmit = (member, callback, isUpdate = false) => {
        member.phone_mobile = isNotNull(member.phone_mobile) ? member.phone_mobile : null;
        member.phone_number = isNotNull(member.phone_number) ? member.phone_number : null;
        const requestCallback = response => {
            callback(response);
            dispatchCustomEvent('updateFamily');
        };
        if (isUpdate) {
            updateFamily({
                id: member.id, data: member, callback: requestCallback,
            });
        } else
            addFamily({
                data: member,
                callback: requestCallback,
            })
    };

    onDelete = (member, callback) => {
        this.deleteCallback = callback;
        this.memberId = member.id;
        this.changeState({
            confirmDelete: true,
        });
    };

    onDeleteConfirmed = () => {
        this.onCloseDialog();
        deleteFamily({
            id: this.memberId, callback: (response) => {
                this.deleteCallback(response);
                dispatchCustomEvent('updateFamily');
                this.deleteCallback = null;
                this.memberId = null;
            }
        });
    };

    onCloseDialog = () => {
        this.changeState({
            confirmDelete: false,
        })
    };

    renderDeleteActions = () => ([
        {label: 'Annuleren', onClick: this.onCloseDialog},
        {label: 'Verwijderen', onClick: this.onDeleteConfirmed},
    ]);

    componentDidMount = () => {
        this._isMounted = true;
    };

    componentWillUnmount = () => {
        this._isMounted = false;
    };

    render = () => {
        let user = this.props.userState.user;
        let address = isNotNull(user) ? user.address : {
            street: '',
            house_number: '',
            house_number_suffix: '',
            zipcode: '',
            city: ''
        };
        return (
            <>
                <MaterialFactory componentType={materialTypes.DIALOG} title='Familielid verwijderen?'
                                 open={this.state.confirmDelete} onClose={this.onCloseDialog}
                                 actions={this.renderDeleteActions()} text='Weet je zeker dat je dit familielid wilt
                                 verwijderen?'/>
                <AddFamilyMember onSubmit={this.onSubmit} onDelete={this.onDelete} address={address}/>
            </>
        );
    }
}

const mapStateToProps = state => ({
    userState: state.user,
});

export default connect(mapStateToProps)(AddFamilyController);