import {deviceIsMobile, pxToRem, remToPx, scrollToBottom, scrollToY, scrollToTop} from 'glob-common-js/lib/utils';

import OnboardingManager, {getNextStep, getPrevStep} from "./onboardingManager";
import {dispatchCustomEvent} from "../../../misc/eventDispatcher";
import {sendGaEvent} from "../../../common/js/ga";

export const financialOnboardingSteps = {
    INTRO_STEP: 'INTRO_STEP',
    SELECT_MODE: 'SELECT_MODE',
    SELECT_ITEM: 'SELECT_ITEM',
    ADD_ITEM: 'ADD_ITEM',
    CONFIRM_ITEM: 'CONFIRM_ITEM',
    NOTIFY_HELP: 'NOTIFY_HELP',
};

const GA_CATEGORY = 'Onboarding financieel', eventName = 'financialOnboardingFlow';

export default class FinancialManager extends OnboardingManager {
    #type = 'financial';
    #step = financialOnboardingSteps.INTRO_STEP;
    #itemType = null;
    #hideItems = true;

    setItemType = (type) => {
        this.#itemType = type;
    };

    getItemType = () => this.#itemType;

    getType = () => this.#type;

    setStep = step => {
        if (this.isActive()) {
            const old = this.#step;
            this.#step = step;
            this.#onStepChange(old);
        } else this.warnNotActive();
    };

    setNextStep = (params) => {
        if (this.isActive()) {
            const oldStep = this.#step;
            this.#step = getNextStep(financialOnboardingSteps, this.#step);
            this.#onStepChange(oldStep, params);
        } else this.warnNotActive();
    };

    setPrevStep = () => {
        if (this.isActive()) {
            const oldStep = this.#step;
            this.#step = getPrevStep(financialOnboardingSteps, this.#step);
            this.#onStepChange(oldStep);
        } else this.warnNotActive();
    };

    getActiveStep = () => {
        return this.#step;
    };

    sendEvent = (action, label) => {
        const category = GA_CATEGORY + (deviceIsMobile() ? ' mobiel' : ' desktop');
        sendGaEvent(category, action, label);
    };

    shouldHideItems = () => this.#hideItems;

    #locateSelectItem = () => {
        if (deviceIsMobile()) {
            if (this.#itemType === 'in') this.#locateSelectInItem();
            else this.#locateSelectOutItem();
        }
    };

    #locateSelectInItem = () => {
        const id = 'financialCategoriesIn', element = document.getElementById(id),
            popup = document.getElementById('financialOnboardingPopup');
        if (areNotNull(element, popup)) {
            const {top, bottom} = element.getBoundingClientRect();
            const scrollTo = top - remToPx(6);
            if (scrollTo > 0)
                scrollToY(top - remToPx(6));
            else popup.style.top = bottom + remToPx(1) + 'px';
            this.addScrollListener(pxToRem(bottom) + 1, 'financialOnboardingPopup');
        }
    };

    #locateSelectOutItem = (toBottom = true) => {
        const id = 'financialCategoriesOut', element = document.getElementById(id),
            popup = document.getElementById('financialOnboardingPopup');
        if (areNotNull(element, popup)) {
            const {bottom, height} = element.getBoundingClientRect();
            if (toBottom)
                scrollToBottom(remToPx(20));
            popup.style.top = remToPx(4) + 'px';
        }
    };

    #locateConfirmItem = () => {
        if (deviceIsMobile()) {
            if (this.#itemType === 'out') {
                scrollToTop();
                this.#locateSelectOutItem(false);
            } else {
                this.#locateSelectInItem();
            }
        }
    };

    #onStepChange = (oldStep, params) => {
        this.#hideItems = true;
        this.clearHighlightIds();
        this.removeTopFromElement('financialOnboardingPopup');
        this.removeScrollListener();
        dispatchCustomEvent(eventName, {type: 'setStep', data: {step: this.#step}});
        const itemType = this.#itemType;
        const id = itemType === 'in' ? 'financialCategoriesIn' : 'financialCategoriesOut';
        this.removeDisabledClass(id);
        switch (this.#step) {
            case financialOnboardingSteps.SELECT_ITEM:
                this.highlightElement(id);
                this.#locateSelectItem();
                break;
            case financialOnboardingSteps.CONFIRM_ITEM:
                this.highlightElement(id);
                this.addDisabledClass(id);
                this.#locateConfirmItem();
                this.#hideItems = false;
                break;
            case false:
                dispatchCustomEvent(eventName, {type: 'deactivate'});
                this.cleanup();
                break;
        }
    };
}