import '../style/intro.scss';

import React from 'react';
import PropTypes from 'prop-types';
import MaterialFactory from "../../material/materialFactory";
import {materialTypes} from "../../material/materialTypes";

export default class Intro20 extends React.Component {
    constructor(props) {
        super(props);
    }

    render = () => (
        <div className='intro'>
            <div className='leftContent'>
                <label className='introTitle'>Welkom op ByDeHand</label>
                <p className='par'>
                    Tof dat je ervoor hebt gekozen om je administratie wat meer op orde te krijgen. Binnen een paar
                    minuten
                    heb je al een grote stap gemaakt en weet je precies hoeveel je studie je elke maand kost, wanneer je
                    collegegeld wordt afgeschreven en heb je al je studiedocumenten bij elkaar.
                </p>
                <p className='par'>
                    Klik hieronder op beginnen om een start te maken met de administratie van je studie.
                </p>
                <MaterialFactory componentType={materialTypes.RAISED_BUTTON} className='startButton'
                                 onClick={this.props.nextStep}>Beginnen!</MaterialFactory>
            </div>
            <div className='rightContent'>
                <span className='common-icon-student logo'/>
            </div>
        </div>
    );
}

Intro20.propTypes = {
    nextStep: PropTypes.func.isRequired,
};