import React from 'react';

import {capitalizeFirst, stringContains, stringStartsWith, getSetting} from 'glob-common-js/lib/utils';

import StateComponent from "../misc/stateComponent";
import AnalysisView from "./analysisView";
import {dispatchCustomEvent} from "../../misc/eventDispatcher";
import {deleteDossier, getAllDossiers} from "../../misc/requestSender";
import getDossierType, {getById} from "../../common/data/mappings/dossierTypeMapping";
import {getFromRegistry, updateRegistry} from "../insurance/healthAnalysis/misc/registry";
import {navigate} from "../../misc/utils";
import {getOnboardingManager} from "../onboarding/manager/onboardingManagerHelper";
import {getAnalysisSorted} from "../../common/data/mappings/analysisMapping";

export default class AnalysisController extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            analysis: [],
            analysisTypes: [],
            loading: false,
            searchValue: '',
        };
        this.allAnalysis = [];
        this.allAnalysisTypes = [];
        this.debounced = null;
    }

    onClickAnalysis = analysis => {
        const {type_id} = analysis;
        if (type_id === getDossierType('health_insurance_analysis').id)
            this.startHealthAnalysis(analysis);
    };

    onClickNewAnalysis = analysis => {
        const {name} = analysis;
        this.onClickOnboarding(analysis);
        const manager = getOnboardingManager('save');
        switch (name) {
            case 'health':
                if (manager.isActive())
                    window.open('/zorganalyse');
                else navigate('/zorganalyse');
                break;
            case 'travel':
                window.open('https://bydehand.com/heb-jij-een-goede-reisverzekering');
                break;
        }
    };

    onClickOnboarding = ({label}) => {
        const manager = getOnboardingManager('save');
        if (manager.isActive()) {
            manager.sendEvent('Start analyse', label);
            manager.setNextStep();
        }
    };

    onDelete = (selected, callback) => {
        const countName = 'analyse' + (selected.length > 1 ? 's' : '');
        dispatchCustomEvent('showAlert', {
            title: capitalizeFirst(countName) + ' verwijderen',
            text: `Weet je zeker dat je deze ${countName} wilt verwijderen?`,
            actions: [
                {label: 'Nee'},
                {label: 'Ja', onClick: this.deleteSelected(selected, callback)},
            ]
        });
    };

    onSearch = (value, evt) => {
        if (isNotNull(this.debounced))
            this.debounced.cancel();
        if (isNotNull(evt))
            evt.persist();
        this.changeState({
            searchValue: value
        });

        this.debounced = _.debounce(() => {
            this.changeState({
                analysis: this.filterAnalysis(this.allAnalysis, value),
                analysisTypes: this.filterAnalysis(this.allAnalysisTypes, value),
            })
        }, 300)();
    };

    onClearSearch = () => {
        this.onSearch('');
    };

    startHealthAnalysis = analysis => {
        const {external_data} = analysis;
        if (pathIsNotNull(external_data, 'analysisData')) {
            const data = external_data.analysisData;
            const registry = Object.assign({}, getFromRegistry('healthInsurance'), data);
            updateRegistry('healthInsurance', registry);
            navigate('/zorganalyse/evaluatie/basis-verzekering');
        }
    };

    deleteSelected = (selected, callback) => () => {
        if (isNotNull(selected))
            this.changeState({loading: true});

        const reload = () => {
            const countName = 'analyse' + (selected.length > 1 ? 's zijn' : ' is');
            dispatchCustomEvent('openSnackbar', {text: `De ${countName} verwijderd`});
            callback();
            this.loadAnalysis();
        };

        const amountToDelete = selected.length;
        let deleted = 0;
        forEach(selected, (id => {
            if (isNotNull(id)) {
                deleteDossier({
                    id, callback: () => {
                        if (++deleted >= amountToDelete) reload();
                    }
                });
            } else if (++deleted >= amountToDelete) reload();
        }))
    };

    loadAnalysis = () => {
        this.changeState({loading: true});
        const analysis = [];
        getAllDossiers({
            callback: dossiers => {
                forEach(dossiers, dossier => {
                    const dossierType = getById(dossier.type_id);
                    if (dossierType.config.isAnalysis)
                        analysis.push({
                            ...dossier,
                            label: dossier.name,
                            icon: dossierType.icon,
                        })
                });
                this.allAnalysis = analysis;
                this.changeState({analysis: analysis, loading: false});
            },
        });
    };

    loadAnalysisTypes = () => {
        this.allAnalysisTypes = getAnalysisSorted();
        this.changeState({analysisTypes: this.allAnalysisTypes});
    };

    filterAnalysis = (analysis, searchValue = null) => {
        searchValue = searchValue || this.state.searchValue;
        if (isNotNull(searchValue)) {
            return analysis.filter(item => stringStartsWith(item.label, searchValue)).concat(
                analysis.filter(
                    item => !stringStartsWith(item.label, searchValue) && stringContains(item.label, searchValue)));
        }
        return analysis;
    };

    checkOnboarding = () => {
        if (getOnboardingManager('save').isActive()) {
            dispatchCustomEvent('saveOnboardingFlow',
                {type: 'activate', step: saveOnboardingSteps.INTRO_STEP});
        }
    };

    componentDidMount = () => {
        this.mount();
        this.loadAnalysis();
        this.loadAnalysisTypes();
        this.checkOnboarding();
    };

    componentWillUnmount = () => {
        this.unMount();
    };

    render = () => {
        const {analysis, loading, analysisTypes, searchValue} = this.state;
        return <AnalysisView onClickAnalysis={this.onClickAnalysis} onClickNewAnalysis={this.onClickNewAnalysis}
                             onDelete={this.onDelete} analysis={analysis} analysisTypes={analysisTypes}
                             loading={loading} onSearch={this.onSearch} searchValue={searchValue}
                             onClearSearch={this.onClearSearch}/>
    }
}