import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../../../../redux/actions';
import PropTypes from 'prop-types';
import moment from 'moment';

import {getSetting, pathIsNotNull, removeCookie, setCookie} from 'glob-common-js/lib/utils';

import {
    activateAccount,
    addSubscription,
    deleteAccount, deleteSubscriptionUser,
    getAccount,
    getAllIssuers,
    getSubscriptionStatus, getUserAccess,
    login, responseIsSuccess,
    updateAccount,
    validateDaisyUser
} from "../../../../misc/requestSender";
import {steps} from "../../registrationConstants";
import {getTransactionInfo, navigate, setSentryUser, showMessage} from "../../../../misc/utils";
import {sendGaEvent} from "../../../../common/js/ga";
import {timeFormats} from "../../../../common/components/datePicker";
import ActivationStepView from "./activationStepView";
import {NOW} from "../../../misc/constants";
import MaterialFactory from "../../../material/materialFactory";
import {materialTypes} from "../../../material/materialTypes";
import {dispatchCustomEvent} from "../../../../misc/eventDispatcher";
import {ACTIVE, PAYMENT_PENDING, PAYMENT_WAITING} from "../../../../misc/authenticationConstants";
import {setJwt} from "../../../../common/js/platform";

const MAX_RETRIES = 60;
let retries = 0;

const GA_CATEGORY = 'Registratie (activatie)';

export class ActivationStepController extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            issuers: [],
            confirmDelete: false,
            password: '',
            deleteReason: '',
            deleteError: null,
            deleteAllowed: true,
        };
        this.twoStepDisabled = false;
    }

    changeState = stateChange => {
        this._isMounted && this.setState(stateChange);
    };

    setSubStep = subStep => {
        this.props.onSetStep(steps.ACTIVATION.index, subStep);
    };

    onConfirmDeleteAccount = () => {
        const user = this.props.user;
        if (isNotNull(user) && user.two_step_auth === 1) {
            this.twoStepDisabled = true;
            this.onSetTwoStep(false);
        }
        this.changeState({confirmDelete: true});
    };

    onResetConfirmDelete = () => {
        if (this.twoStepDisabled) {
            this.twoStepDisabled = false;
            this.onSetTwoStep(true);
        }
        this.changeState({password: '', deleteReason: '', confirmDelete: false});
    };

    onCloseConfirmDelete = () => {
        this.changeState({confirmDelete: false});
    };

    /**
     * Function to (temporary) disable the two step verification. We want to disable it when deleting the account,
     * since confirming the delete is only used to be sure the user really wants to delete the account, and not for
     * security reasons.
     */
    onSetTwoStep = isEnabled => {
        let user = Object.assign({}, this.props.user);
        if (pathIsNotNull(user, 'email')) {
            this.changeState({deleteAllowed: false});
            user.two_step_auth = isEnabled ? 1 : 0;
            updateAccount({
                id: user.id,
                data: user,
                callback: () => {
                    this.changeState({deleteAllowed: true});
                }
            });
        }
    };

    onDeleteAccount = () => {
        const {password, deleteReason} = this.state;
        const user = this.props.user;
        if (pathIsNotNull(user, 'email') && user.email !== 'demo@bydehand.eu' && isNotNull(password)) {
            this.changeState({deleteAllowed: false});
            login({
                keepJwt: true,
                email: user.email,
                password,
                callback: (response) => {
                    if (response.data.status === 'success')
                        deleteSubscriptionUser({
                            callback: response => {
                                if (responseIsSuccess(response))
                                    deleteAccount({
                                        id: user.id,
                                        data: {delete_reason: deleteReason},
                                        callback: (response) => {
                                            if (responseIsSuccess(response)) {
                                                this.changeState({deleteAllowed: true});
                                                sendGaEvent(GA_CATEGORY, 'Verwijderen', 'completed');
                                                dispatchCustomEvent('openSnackbar', {text: 'Je account is verwijderd'});
                                                this.afterDeleteNavigation();
                                            }
                                        }
                                    });
                                else showMessage('Het verwijderen van je account is niet gelukt. Probeer het later ' +
                                    'opnieuw of neem contact met ons op.', [{
                                    label: 'Oke',
                                    onClick: this.afterDeleteNavigation
                                }])
                            }
                        });
                    else {
                        this.changeState({deleteAllowed: true, deleteError: 'Onjuist wachtwoord'});
                    }
                },
            });
        } else this.changeState({deleteError: 'Vul je wachtwoord in'});
    };

    onKeyDown = evt => {
        const ENTER_CODE = 13;
        if (evt.keyCode === ENTER_CODE) {
            evt.preventDefault();
            this.onDeleteAccount();
        }
    };

    afterDeleteNavigation = () => {
        setJwt(null);
        this.props.actions.setUser({firstname: '', email: ''});
        navigate('/auth/login');
    };

    startPayment = issuer => {
        addSubscription({
            data: {
                issuer_id: issuer.id,
                issuer_name: issuer.name,
                user_name: this.props.user.email,
            },
            callback: (response) => {
                let url = response.data.payment_url || response.data.paymentUrl;
                if (isNotNull(url)) {
                    window.location = url;
                }
            },
        });
    };

    activateAccount = () => {
        const key = this.props.match.params.pathParam;
        activateAccount({
            activationKey: key,
            callback: (response) => {
                if (responseIsSuccess(response)) {
                    const {message, user, token} = response.data;
                    setJwt(token);
                    if (message !== 'Already activated')
                        validateDaisyUser({
                            callback: response => {
                                if (responseIsSuccess(response))
                                    this.checkActivationSubscription(user);
                            }
                        });
                    else this.checkActivationSubscription(user);

                } else
                    this.props.onError(<p>Account activatie is niet gelukt.</p>);
            }
        });
    };

    checkActivationSubscription = (user) => {
        getSubscriptionStatus({
            callback: (response) => {
                if (responseIsSuccess(response)) {
                    const subUser = response.data.user;
                    this.props.actions.setSubscription(subUser);
                    const isYounger = this.setUserDetails(user, subUser);
                    this.determineNextStep(subUser, isYounger);
                }
            }
        });
    };

    determineNextStep = (user, isYounger) => {
        const error = index => {
            this.props.onError(`Account activatie is niet gelukt. (${index})`);
        };
        if (pathIsNotNull(user, 'subscription') && isNotNull(user.status)) {
            const {subscription, status} = user;
            if (isNotNull(status)) {
                sendGaEvent('Betalen', 'Registreren', 'completed');
                const allowedStatuses = [ACTIVE, PAYMENT_PENDING, PAYMENT_WAITING];
                if (allowedStatuses.includes(status)) {
                    const period = subscription.period;
                    if (isNotNull(period)) {
                        const action = this.getActionFromPeriod(period);
                        if (action !== false) {
                            return action();
                        }
                    }
                    if (isYounger) {
                        this.props.onSetStep(steps.COMPLETION.index, steps.COMPLETION.FREE);
                        return;
                    }
                    error(3);
                }
                this.props.onSetStep(steps.ACTIVATION.index, steps.ACTIVATION.PAYMENT_INTRO);
            } else
                error(2);
        } else
            error(1);
    };

    getActionFromPeriod = (period) => {
        switch (period.details) {
            case 'free_period_first':
                return () => {
                    sendGaEvent('Betalen', 'Registreren', 'completed proefperiode');
                    this.props.onSetStep(steps.COMPLETION.index, steps.COMPLETION.FREE);
                };
            case 'free_period_birthday':
                return this.completeAsYounger;
            case 'free_action_code':
                return this.completeWithCode;
            default:
                return false;
        }
    };

    completeAsYounger = () => {
        sendGaEvent('Betalen', 'Registreren', 'completed jongeren');
        this.props.onSetStep(steps.COMPLETION.index, steps.COMPLETION.FREE);
    };

    completeWithCode = () => {
        sendGaEvent('Betalen', 'Registreren', 'completed action code');
        this.props.onSetStep(steps.COMPLETION.index, steps.COMPLETION.FREE);
    };

    setUserDetails = (user, userData) => {
        if (isNull(user.dob)) {
            user.dob = moment(userData.birthday, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ss')
        }
        setSentryUser(user);
        this.props.setUser(user);

        let dateOfBirth = moment(user.dob, timeFormats);
        let isYounger = Math.abs(moment(dateOfBirth, timeFormats).diff(NOW(), 'years')) <= 25;
        user.external_data = Object.assign({}, user.external_data, {
            targetGroup: isYounger ? 'younger' : 'older',
        });
        updateAccount({data: user, id: user.id});
        return isYounger;
    };

    loadIssuers = () => {
        getAllIssuers({
            callback: response => {
                switch (getSetting('mollieVersion')) {
                    case 1:
                        this.changeState({issuers: response.data.data});
                        break;
                    case 2:
                        if (responseIsSuccess(response)) {
                            const issuers = response.data.issuers;
                            this.changeState({issuers});
                        }
                        break;
                }
            }
        })
    };

    checkPayment = (sendPendingGa = true) => {
        getUserAccess({
            callback: response => {
                if (responseIsSuccess(response)) {
                    const {access, user} = response.data;
                    this.props.actions.setSubscription(user);
                    if ((access || user.status === PAYMENT_PENDING) && retries <= MAX_RETRIES) {
                        getAccount({
                            callback: response => {
                                const account = response.data;
                                const transactionInfo = getTransactionInfo(user);
                                this.props.setUser(account);
                                this.props.setTransactionInfo(transactionInfo);
                                if (user.status === PAYMENT_PENDING) {
                                    if (sendPendingGa) {
                                        sendGaEvent('Betalen', 'Betaling', 'pending');
                                    }
                                    this.handlePendingPayment();
                                } else {
                                    sendGaEvent('Betalen', 'Betaling', 'geslaagd');
                                    this.props.onSetStep((steps.COMPLETION.index), steps.COMPLETION.DEFAULT);
                                }
                            }
                        });
                    } else this.props.onError('De betaling is mislukt (1)', 'payment_failed');
                }
            }
        });
    };

    handlePendingPayment = () => {
        if (retries >= MAX_RETRIES) {
            this.props.onError('De betaling is mislukt (2)', 'payment_failed');
        } else
            window.setTimeout(() => {
                ++retries;
                this.checkPayment(false);
            }, 5000);
    };

    getSubscriptionInfo = () => {
        const subscription = this.props.subscriptionState;
        if (pathIsNotNull(subscription, 'subscription.period')) return subscription.subscription.period;
        return {details: null};
    };

    toggleEnterListener = (add = true) => {
        const node = document.getElementById('deletePassword');
        if (isNotNull(node)) {
            const listener = add ? node.addEventListener || node.attachEvent :
                node.removeEventListener || node.detachEvent;
            listener('keydown', this.onKeyDown);
        }
    };

    renderDeleteAccountActions = () => ([
        {
            label: 'Verwijderen',
            onClick: this.onDeleteAccount,
            disabled: isNull(this.state.password) || !this.state.deleteAllowed
        },
        {label: 'Annuleren', onClick: this.onResetConfirmDelete},
    ]);

    componentDidMount = () => {
        this._isMounted = true;
        this.toggleEnterListener();
        const subStep = this.props.subStep;
        if (subStep === steps.ACTIVATION.PAYMENT_INTRO)
            this.loadIssuers();
        else if (subStep === steps.ACTIVATION.CHECK_PAYMENT) {
            this.checkPayment();
        } else if (subStep === steps.ACTIVATION.ACTIVATING)
            this.activateAccount();
    };

    componentDidUpdate = prevProps => {
        const subStep = this.props.subStep, prevSubStep = prevProps.subStep;
        if (subStep !== prevSubStep) {
            if (subStep === steps.ACTIVATION.PAYMENT_INTRO) this.loadIssuers();
        }
    };

    componentWillUnmount = () => {
        this._isMounted = false;
        this.toggleEnterListener(false);
    };

    render = () => {
        return (
            <>
                <MaterialFactory componentType={materialTypes.DIALOG} title='Account verwijderen'
                                 open={this.state.confirmDelete} onClose={this.onCloseConfirmDelete}
                                 actions={this.renderDeleteAccountActions()} text='Weet je zeker dat je je account wil
                                 verwijderen? Hiermee gaan alle opgeslagen gegevens verloren. Bevestig het verwijderen
                                 met je wachtwoord.' content={
                    <form>
                        <MaterialFactory componentType={materialTypes.PASSWORD} value={this.state.password}
                                         label='Wachtwoord' autoComplete='new-password'
                                         error={isNotNull(this.state.deleteError)} helperText={this.state.deleteError}
                                         onChange={(evt) => {
                                             this.changeState({password: evt.target.value})
                                         }} onFocus={() => {
                            this.changeState({deleteError: null});
                        }} id='deletePassword'/>
                    </form>}/>
                <ActivationStepView setSubStep={this.setSubStep} startPayment={this.startPayment}
                                    onDeleteAccount={this.onConfirmDeleteAccount} subStep={this.props.subStep}
                                    user={this.props.user} issuers={this.state.issuers} flex={this.props.flex}
                                    subscriptionInfo={this.getSubscriptionInfo()}/>
            </>
        );
    }
}

ActivationStepController.propTypes = {
    setUser: PropTypes.func.isRequired,
    onSetStep: PropTypes.func.isRequired,
    setTransactionInfo: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    subStep: PropTypes.string.isRequired,
    user: PropTypes.object,
    flex: PropTypes.object,
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch),
});

const mapStateToProps = state => ({
    subscriptionState: state.user.subscription,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ActivationStepController));