import React from 'react';
import {Route, Switch} from 'react-router-dom';

import DossierController from "./dossier/dossierController";
import SummaryController from "./summary/summaryController";

export default class NextInsuranceView extends React.Component {
    constructor(props) {
        super(props);
    }

    render = () => {
        return (
            <Switch>
                <Route path='/zorganalyse/jouw-verzekering/dossier-inrichten' render={() => {
                    return <DossierController/>
                }}/>
                <Route path='/zorganalyse/jouw-verzekering/afsluiten' render={() => {
                    return <SummaryController/>
                }}/>
            </Switch>
        )
    }
}