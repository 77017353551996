import './style/mobileFinancialOnboarding.scss';

import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import classNames from 'classnames';

import {deactivateOnboarding, getOnboardingManager} from "../manager/onboardingManagerHelper";
import StateComponent from "../../misc/stateComponent";

const requireScreenShot = require.context('./mobileScreenShots', false);
const screenShots = [
    {
        img: 'screen1.png',
        alt: 'ByDeHand financieel uitleg',
        text: 'In het overzicht vind je al je inkomsten en uitgaven gesorteerd op categorie. Klik op de categorie ' +
            'waarmee je wilt beginnen.'
    },
    {
        img: 'screen2.png',
        alt: 'ByDeHand financieel uitleg',
        text: 'Elke categorie bevat voorgedefinieerde items. Klik op het item dat je toe wilt voegen aan je overzicht.'
    },
    {
        img: 'screen3.png',
        alt: 'ByDeHand financieel uitleg',
        text: 'Start met een omschrijving van het item dat je toe wilt voegen.'
    },
    {img: 'screen4.png', alt: 'ByDeHand financieel uitleg', text: 'Vul het bedrag in.'},
    {
        img: 'screen5.png',
        alt: 'ByDeHand financieel uitleg',
        text: 'Vul de frequentie in. Ontvang of betaal je dit item wekelijks of maandelijks en op een vaste datum?'
    },
    {
        img: 'screen6.png',
        alt: 'ByDeHand financieel uitleg',
        text: 'Bevestig het type en vink aan of je een dossier aan wilt maken.'
    },
    {
        img: 'screen7.png',
        alt: 'ByDeHand financieel uitleg',
        text: 'Je hebt je eerste financiële item toegevoegd! Ga nu aan de slag en voeg er nog meer toe, zodat je ' +
            'binnen een paar minuten weet wat je maandelijkse balans is.'
    },
];

let overflow = null;
export default class MobileFinancialOnboardingView extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            slide: 0,
        };
        this.slider = null;
    }

    onNextSlide = () => {
        const {slide} = this.state;
        if (slide === screenShots.length - 1) {
            getOnboardingManager('financial').sendEvent('Klik', 'Begrepen');
            this.onClose();
        } else if (isNotNull(this.slider))
            this.slider.slickNext();
    };

    onPrevSlide = () => {
        if (isNotNull(this.slider))
            this.slider.slickPrev();
    };

    onClose = () => {
        deactivateOnboarding();
        this.props.onClose();
    };

    onBeforeChange = (oldIndex, newIndex) => {
        const direction = oldIndex < newIndex ? 'Volgende slide' : 'Vorige slide';
        getOnboardingManager('financial').sendEvent(direction, `Slide ${oldIndex + 1}`);
        this.changeState({slide: newIndex});
    };

    renderSlider = () => {
        const sliderSettings = {
            arrows: false,
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            lazyLoad: false,
            dots: true,
            className: 'slider',
            beforeChange: this.onBeforeChange,
        };
        return (
            <div className='mobileFinancialOnboardingContainer' id='mobileFinancialOnboarding'>
                <Slider {...sliderSettings} ref={refName => {
                    this.slider = refName
                }}>
                    {this.renderScreenShotSlides()}
                </Slider>
            </div>
        )
    };

    renderPrevButton = () => {
        const {slide} = this.state;
        const disabled = slide === 0;
        return <span className={classNames('slideNavButton', 'prev', 'common-icon-arrow-left', disabled && 'disabled')}
                     onClick={this.onPrevSlide}/>
    };

    renderNextButton = () => {
        const {slide} = this.state;
        if (slide === screenShots.length - 1) return <label className='slideNavButton last'
                                                            onClick={this.onNextSlide}>Begrepen</label>;
        else return <span className={classNames('slideNavButton', 'common-icon-arrow-right')}
                          onClick={this.onNextSlide}/>;
    };

    renderScreenShotSlides = () => {
        const {slide} = this.state;
        return screenShots.map((screenShot, key) => (
            <div key={key} className='screenShotContainer'>
                <div style={{
                    height: '80vh',
                    width: '100%',
                    backgroundImage: `url('${requireScreenShot('./' + screenShot.img)}')`,
                    backgroundSize: 'cover',
                }}>
                    <div className='textContainer'>
                        <p className='sliderText'>{screenShot.text}</p>
                        <div className='sliderNavButtons'>
                            {this.renderPrevButton()}
                            {this.renderNextButton()}
                        </div>
                    </div>
                </div>
            </div>
        ))
    };

    componentDidMount = () => {
        this.mount();
    };

    componentWillUnmount = () => {
        this.unMount();
    };

    render = () => {
        return (
            <>
                <div className='onboardingBackground'/>
                {this.renderSlider()}
            </>
        )
    }
}

MobileFinancialOnboardingView.propTypes = {
    onClose: PropTypes.func.isRequired,
};