import PropTypes from 'prop-types';
import _ from 'lodash';

import {isFunction, toMoment} from 'glob-common-js/lib/utils';
import {BAD_REQUEST} from 'glob-common-js/lib/request';

import {
    createDossier,
    deleteDossier,
    getAllDossiers,
    getDocument,
    getDossier,
    responseIsSuccess,
    updateDossier
} from "../misc/requestSender";
import BdhDocument from "./document";
import getDossierType, {getById} from "../common/data/mappings/dossierTypeMapping";
import {dispatchDossierEvent, EVENT_TYPES} from "../misc/dataEvent";
import {showMessage} from "../misc/utils";

export default class Dossier extends Object {
    static get = (id, callback) => {
        getDossier({
            id, callback: (dossier) => {
                let dossierInstance = new Dossier(dossier);
                dossierInstance = Dossier.extendExternalValues(dossierInstance, dossier);
                callback(dossierInstance);
            }
        });
    };

    static getAll = (callback, filter = '') => {
        getAllDossiers({
            queryParams: filter,
            asInstance: true,
            callback,
        })
    };

    static extendExternalValues = (dossier, dossierData) => {
        if (dossier.type.name === 'car') {
            const values = isNull(dossier.externalData.values) ? {} : dossier.externalData.values[0];
            dossier.externalData.values = [Object.assign({}, values, dossierData.values)];
        }
        return dossier;
    };

    #fields = {
        id: '',
        name: '',
        createdAt: '',
        updatedAt: '',
        type: {},
        values: {},
        files: [],
        externalData: {},
        documents: [],
        history: {},
    };
    #documentsAreLoaded = false;
    #documentsLoaded = 0;

    constructor(props) {
        super(props);
        this.#initializeFields(props);
    };

    hasOwnProperty = name => {
        return isNotNull(this[name]) || this.#fields[name];
    };

    get id() {
        if (isNotNull(this.#fields.id))
            return this.#fields.id;
        return '';
    };

    set id(id) {
        this.#fields.id = id;
    };

    get name() {
        return isNotNull(this.#fields.name) ? this.#fields.name : '';
    };

    set name(name) {
        this.#fields.name = name;
    };

    get label() {
        return this.name;
    }

    set label(label) {
        this.name = label;
    }

    get title() {
        return this.name;
    }

    set title(title) {
        this.name = title;
    }

    get createdAt() {
        if (isNotNull(this.#fields.createdAt))
            return toMoment(this.#fields.createdAt);
        return null;
    }

    get updatedAt() {
        if (isNotNull(this.#fields.updatedAt))
            return toMoment(this.#fields.updatedAt);
        if (isNotNull(this.externalData.updated_at))
            return toMoment(this.externalData.updated_at);
        return null;
    }

    get type() {
        return this.#fields.type;
    };

    set type(type) {
        this.#fields.type = type;
    };

    get values() {
        if (isNotNull(this.externalData.values))
            return this.externalData.values[0];
        if (isNotNull(this.#fields.values))
            return this.#fields.values;
        return {};
    };

    set values(values) {
        this.#fields.values = values;
    };

    get files() {
        if (isNotNull(this.#fields.files))
            return this.#fields.files;
        return [];
    }

    set files(files) {
        this.#fields.files = files;
    }

    get externalData() {
        if (isNotNull(this.#fields.externalData))
            return this.#fields.externalData;
        return {};
    }

    set externalData(data) {
        this.#fields.externalData = data;
    }

    get fields() {
        if (pathIsNotNull(this.type, 'fields'))
            return this.type.fields;
        return [];
    }

    set fields(fields) {
        this.#fields.type.fields = fields;
    }

    get documentsLoaded() {
        return this.#documentsAreLoaded;
    }

    get documents() {
        if (isNotNull(this.#fields.documents))
            return this.#fields.documents;
        return [];
    }

    set documents(documents) {
        this.#fields.documents = documents;
    }

    get contacts() {
        if (isNotNull(this.externalData.dossierContacts))
            return this.externalData.dossierContacts;
        return [];
    }

    set contacts(contacts) {
        this.#fields.externalData.dossierContacts = contacts;
    }

    get notes() {
        if (isNotNull(this.externalData.dossierNotes))
            return this.externalData.dossierNotes;
        return '';
    }

    set notes(notes) {
        this.externalData.dossierNotes = notes;
    }

    get history() {
        return this.#fields.history;
    }

    save = (callback, onError = null) => {
        const data = this.#createRequestData();
        createDossier({
            data, callback: (dossier) => {
                dossier.type_id = dossier.type;
                delete dossier.type;
                this.#initializeFields(dossier);
                dispatchDossierEvent(EVENT_TYPES.CREATE, this);
                callback(this);
            }, errorCallback: error => {
                if (error.response.status === BAD_REQUEST || error.response.status === 500) {
                    const {message} = error.response.data;
                    if (isNotNull(message) && message === 'Ingevulde waardes voldoen niet aan de eisen') {
                        showMessage(message, null, 'Dossier mislukt');
                        if (isFunction(onError)) onError(error);
                    } else {
                        showMessage('Het ophalen van aanvullende gegevens is niet gelukt. Je auto dossier is ' +
                            'opgeslagen zonder extra gegevens.', null, 'Gegevens onbekend');
                        callback(this);
                    }
                } else throw new Error(error);
            },
        });
    };

    delete = callback => {
        deleteDossier({
            id: this.id, callback: response => {
                if (responseIsSuccess(response))
                    dispatchDossierEvent(EVENT_TYPES.DELETE, this);
                if (isFunction(callback)) callback(response, this);
            }
        });
    };

    update = callback => {
        const data = this.#createRequestData(true);
        updateDossier({
            data, id: this.id, callback: (dossier) => {
                this.#initializeFields(dossier);
                dispatchDossierEvent(EVENT_TYPES.UPDATE, this);
                if (isFunction(callback))
                    callback(this);
            }
        });
    };

    load = callback => {
        Dossier.get(this.id, callback);
    };

    setField = (name, value) => {
        this.#fields[name] = value;
    };

    getField = (name, expectedType) => {
        return isNotNull(this.#fields[name]) ? this.#fields[name] : expectedType;
    };

    loadDocuments = callback => {
        const files = this.files;
        if (files.length === 0 && isFunction(callback))
            callback();

        files.forEach(file => {
            this.#loadDocument(file.id, files.length, callback);
        });
    };

    #getExtendedType = type => Object.assign({}, type, getDossierType(type.name));

    #loadDocument = (id, countToLoad, callback) => {
        getDocument({
            id, callback: response => {
                if (responseIsSuccess(response)) {
                    const {vault} = response.data;
                    this.#fields.documents.push(new BdhDocument(vault));
                }
                if (++this.#documentsLoaded >= countToLoad && isFunction(callback)) {
                    this.#documentsAreLoaded = true;
                    callback();
                }
            }
        });
    };

    #createRequestData = (isUpdate = false) => {
        if (isUpdate) return this.#createUpdateRequestData();
        const excludeExternal = ['house', 'travel'].includes(this.type.name);
        const data = {
            external_data: this.externalData,
            fields: this.fields,
            name: this.name,
            type: this.type.id,
            values: this.values,
        };
        if (excludeExternal)
            delete data.external_data;
        return data;
    };

    #createUpdateRequestData = () => {
        return {
            external_data: this.externalData,
            title: this.name,
            type: {
                fields: [],
                name: this.type.name,
            },
        };
    };

    #initializeFields = props => {
        if (isNotNull(props)) {
            this.#initializeField('id', props.id);
            this.#initializeField('name', props.name);
            this.#initializeField('type', this.#initializeType(props));
            this.#initializeField('values', props.values);
            this.#initializeField('files', this.#initializeDocuments(props.files));
            this.#initializeField('externalData', props.external_data);
            this.#initializeField('history', props.history);
            this.#initializeDocuments(props.files);
        }
    };

    #initializeDocuments = files => {
        if (isNull(files)) return [];
        const documents = files.map(file => new BdhDocument(file));
        this.documents = documents;
        return documents;
    };

    #initializeType = props => {
        if (isNotNull(props.type) && isNotNull(Object.keys(props.type))) {
            log('Initializing by type', props.type);
            return this.#getExtendedType(props.type);
        }
        if (isNotNull(props.type_id))
            return getById(props.type_id);
        return getDossierType('default');
    };

    #initializeField = (name, value) => {
        if (isNotNull(value)) this.#fields[name] = value;
    };
}

Dossier.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        label: PropTypes.string,
        fields: PropTypes.array,
    }),
    values: PropTypes.object,
    files: PropTypes.array,
    external_data: PropTypes.object,
};