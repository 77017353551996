import PropTypes from 'prop-types'
import React from 'react';

import {pathIsNotNull} from 'glob-common-js/lib/utils';
import IconButton from 'glob-common-react/lib/buttons/iconButton';

import {sendGaEvent} from "BdhCommon/js/ga";
import {navigate} from "../../../misc/utils";

import FakeContentContainer from "../fakeContent/fakeContentContainer";
import {dispatchCustomEvent} from "../../../misc/eventDispatcher";
import ExplanationFrame from "../../document/explanationFrame";
import DashboardInfoContainer from "../info/dashboardInfoContainer";
import BdhDocument from "../../../models/document";

const GA_CATEGORY = 'Dashboard';

export class DocumentDashboardContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            frameActive: false,
        };
        this._isMounted = false;
    }

    onClickNav = () => {
        navigate('/bibliotheek');
    };

    changeState = stateChange => {
        this._isMounted && this.setState(stateChange);
    };

    onClickInfo = () => {
        sendGaEvent(GA_CATEGORY, 'Open filmpje', 'Documenten');
        this.changeState({frameActive: true});
    };

    onCloseInfo = () => {
        sendGaEvent(GA_CATEGORY, 'Sluit filmpje', 'Documenten');
        this.changeState({frameActive: false});
    };

    createDocumentButtons = () => {
        if (!this.props.docFetching) {
            let documents = this.props.documents;
            let list = [null];
            if (isNotNull(documents)) {
                list = documents;
            }
            let max = Math.min(list.length, 4);
            return list.slice(0, max).map(this.createDocButton);
        }
    };

    createDocButton = (doc, key) => {
        if (isNotNull(doc)) {
            return (
                <li key={key}>
                    <IconButton name={doc.id} text={doc.label} extraCssName='storeButton'
                                icon='common-icon-document' onClick={() => {
                        this.onDocClick(doc)
                    }}/>
                </li>
            );
        }
    };

    onDocClick = (doc) => {
        const label = pathIsNotNull(doc, 'type.label') ? doc.type.label : 'Onbekend';
        sendGaEvent(GA_CATEGORY, 'Open document', label);
        dispatchCustomEvent('openDocumentDetails', doc);
    };

    onLinkClick = (typeLink) => {
        if (typeLink === 'addDocument') {
            navigate('/documenten-toevoegen');
            let action = 'Dashboard click' + (window.highlightsActive ? ' highlights' : '');
            sendGaEvent(GA_CATEGORY, action, 'Naar toevoegen documenten');
        } else {
            navigate('/bibliotheek');
            let action = 'Dashboard click' + (window.highlightsActive ? ' highlights' : '');
            sendGaEvent(GA_CATEGORY, action, 'Naar bibliotheek');
        }
    };

    createWaitingContent = () => {
        if (this.props.docFetching) {
            return (
                <p className='waitText'>Uw documenten worden geladen</p>
            );
        } else {
            return (
                <div className='optionalHoverText'>
                    <b>Mail je documenten</b>
                    <br/>
                    Je kunt heel gemakkelijk documenten uploaden door deze te mailen naar je eigen ByDeHand-mail. Mail
                    naar <a href={'mailto:' + this.props.email} onClick={() => {
                    sendGaEvent(GA_CATEGORY, 'Klik email', 'Documenten');
                }}>{this.props.email}</a> en vindt het bestand terug in de
                    map documenten. Benieuwd naar andere manieren om documenten te uploaden? Klik dan op <span
                    className='common-icon-info infoIcon'/>
                </div>
            );
        }
    };

    componentDidMount = () => {
        this._isMounted = true;
    };

    componentWillUnmount = () => {
        this._isMounted = false;
    };

    render = () => {
        return (
            <div id='dashboardBoxDocuments'
                 className={'box' + (isNotNull(this.props.documents) && !this.props.docFetching ? '' : ' hoverable')}>
                <header>
                    <label className='dashboardTitle clickable' onClick={this.onClickNav}>Jouw documenten</label>
                    <div className='infoContainer'>
                        <DashboardInfoContainer infoText={'Klik hier'} onClick={this.onClickInfo}
                                                iconClass='dashboardDocumentInfo' messageClass='sm'/>
                        <ExplanationFrame onClose={this.onCloseInfo} active={this.state.frameActive}/>
                    </div>
                    <button onClick={() => {
                        this.onLinkClick('addDocument')
                    }} className='common-icon-plus addDocumentButton'/>
                </header>
                {this.createWaitingContent()}
                <ul className={'content' + (this.props.docFetching ? ' hide' : '')}>
                    <div className='simpleButtonSet'>
                        {this.props.docFetching ? null : this.createDocumentButtons()}
                        {isNotNull(this.props.documents) ? null :
                            <FakeContentContainer selectedModule={this.props.selectedModule} fakeType='document'
                                                  activeModules={this.props.activeModules}/>}
                    </div>
                </ul>
                <div className='textRight'>
                    <a onClick={() => {
                        this.onLinkClick('viewDocuments')
                    }}>
                        Bekijk alle documenten
                    </a>
                </div>
            </div>
        );
    }
}

DocumentDashboardContainer.propTypes = {
    docFetching: PropTypes.bool.isRequired,
    documents: PropTypes.arrayOf(PropTypes.instanceOf(BdhDocument)).isRequired,
    setReduxDocType: PropTypes.func.isRequired,
    selectedModule: PropTypes.string,
    activeModules: PropTypes.arrayOf(PropTypes.string),
    email: PropTypes.string.isRequired,
};

export default DocumentDashboardContainer;