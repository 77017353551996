import utils from 'glob-common-js/lib/utils';
import {navigate} from "../../../misc/utils";
import {actionConstants} from "../constants/smartActionConstants";
import dosMap from './dossierTypeMapping';

const MAIN = 'main', SUB = 'sub';

const categoryMapping = {
    modules: {
        type: MAIN,
        icon: 'common-icon-arrow_bothways',
        label: 'Modules',
        subCategories: [
            'free_time_sub', 'money_income', 'personal', 'care', 'study', 'valuable_possession', 'out_house',
        ],
        docTypes: [],
        dosTypes: [],
        gaAction: 'Hoofdcategorie click',
    },
    misc: {
        type: MAIN,
        icon: 'common-icon-plus',
        label: 'Overige',
        subCategories: [],
        docTypes: ['miscellaneous_indoors'],
        dosTypes: ['misc_gen', 'misc_family', 'misc_care', 'misc_house', 'misc_contents', 'misc_energy',
            'misc_transport_mean', 'misc_travel', 'misc_travel_vacation', 'misc_sport_games_hobby', 'misc_work',
            'misc_study', 'misc_company', 'misc_taxes', 'misc_insurance', 'misc_fixed_costs', 'misc_savings',
            'misc_purchases'],
        gaAction: 'Hoofdcategorie click',
    },
    personal_family_care: {
        type: MAIN,
        icon: 'common-icon-pasport',
        label: 'Persoonlijk, gezin en zorg',
        subCategories: ['personal', 'family', 'care', 'valuable_possession'],
        docTypes: [],
        dosTypes: [],
        gaAction: 'Hoofdcategorie click',
    },
    personal: {
        type: SUB,
        icon: 'icon common-icon-person',
        label: 'Persoonlijke gegevens',
        subCategories: [],
        docTypes: ['passport_personal', 'id_card_personal', 'drivers_licence_personal','miscellaneous_personal_LifeWill',
            'miscellaneous_personal_DeathCertificate','miscellaneous_personal_InheritenceCertificate','miscellaneous_personal_Other',
          'will_personal','prenuptial_agreement_family','cohabitation_contract_family'],
        dosTypes: ["personal_data", "misc_gen", 'passes','miscellaneous_care'],
        gaAction: "Subcategorie click",
        headerIcon: 'icon common-icon-person',
        tips: [
            'customer_cards', 'passport_expires',
        ],
        agendaItems: [
            {
                label: 'Paspoort verlopen',
                image: 'paspoorten.jpg',
                trigger: actionConstants.AGENDA_PASPOORT1,
            },
            {
                label: 'Rijbewijs verlopen',
                image: 'rijbewijs.jpg',
                trigger: actionConstants.AGENDA_RIJBEWIJS1,
            },
            {
                label: 'ID-kaart verlopen',
                image: 'identiteitskaart.jpg',
                trigger: actionConstants.AGENDA_ID1,
            },
            {
                label: 'OV-chipkaart verlopen',
                image: 'ovchipkaart.jpg',
                trigger: actionConstants.AGENDA_OV1,
            },
        ],
        dashboardLinks: {
            save: [{
                name: 'cjp_pass_check',
                type: 'link',
                icon: 'euroSign',
                label: 'Vraag je CJP pas aan!',
                url: 'https://www.cjp.nl/',
                description: 'Met de CJP-pas krijg je korting op film, theater, musea en nog veel meer! Vraag hem snel ' +
                    'aan!',
            },],
            decide: [
                {
                    type: 'boxTitle',
                    icon: null,
                    label: 'Regelen Persoonlijk',
                    url: null,
                },
                {
                    type: 'link',
                    icon: 'common-icon-check',
                    label: 'Vraag je Digid-id aan',
                    url: 'https://digid.nl/aanvragen',
                },
                {
                    type: 'link',
                    icon: 'common-icon-check',
                    label: 'Eindelijk 18, dit moet je allemaal regelen',
                    url: 'https://www.leuker.nl/blog/eindelijk-18/',
                },
            ]
        },
    },
    family: {
        type: SUB,
        icon: 'icon common-icon-person',
        label: 'Gezin',
        subCategories: [],
        docTypes: ["cohabitation_contract_family", "donor_card_care", "animal_passport_animals",
            "miscellaneous_indoors", "prenuptial_agreement_family", "id_card_personal", "passport_personal",
            "will_personal", "liability_insurance_health", "legal_insurance_health", "life_insurance_personal",
            "funeral_insurance_personal", "animal_insurance_animals"],
        dosTypes: ["health_insurance_care", "misc_family"],
        gaAction: "Subcategorie click",
        headerIcon: 'common-icon-health-insurance',
        dashboardLinks: {
            save: [
                {
                    type: 'boxTitle',
                    icon: null,
                    label: 'Financieel gezin',
                    url: null,
                },
                {
                    name: 'health_insurance_check',
                    type: 'link',
                    icon: 'common-icon-check',
                    label: 'Heb jij de goede zorgverzekering?',
                    url: () => {
                        navigate('/zorganalyse')
                    },
                    description: 'Waar ben je eigenlijk allemaal voor verzekerd? En kan het ergens anders misschien ' +
                        'goedkoper? Onderzoek wijst uit dat je gemiddeld €100 kunt besparen.',
                },
            ],
            decide: [
                {
                    type: 'boxTitle',
                    icon: null,
                    label: 'Regelen gezin',
                    url: null,
                },
            ]
        },
    },
    care: {
        type: SUB,
        icon: 'common-icon-health-insurance',
        label: 'Zorg',
        subCategories: [],
        docTypes: ["healthcare_analysis_insurance", "health_card", "vaccination_card_holiday", "donor_card_care",
            "health_insurance", "prescription_pharmacy_care", "research_results_care", "declaration_work", 'glasses',
            'hearing_aid', 'lenses', 'insurance_policy', 'insurance_conditions', 'insurance_invoice',],
        dosTypes: ["health_insurance_care", 'general_practitioner', 'dentist', 'specialist', 'medical_tools',
            "glasses_care"],
        gaAction: "Subcategorie click",
        headerIcon: 'common-icon-health-insurance',
        tips: [
            'check_health_insurance', 'check_zorgtoeslag', 'pay_periodically',
        ],
        agendaItems: [
            {
                label: 'Medische behandeling',
                image: 'medischebehandeling.jpg',
                trigger: actionConstants.AGENDA_MEDISCH1,
            },
            {
                label: 'Betaaltermijn zorgpremie',
                itemDesq: 'test123',
                image: 'zorgtoeslag.jpg',
                trigger: actionConstants.AGENDA_AFSCHRIJVING1,
            },
        ],
        dashboardLinks: {
            save: [
                {
                    type: 'boxTitle',
                    icon: null,
                    label: 'Financieel zorg',
                    url: null,
                }, {
                    name: 'health_insurance_check',
                    type: 'link',
                    icon: 'common-icon-check',
                    label: 'Heb jij de goede zorgverzekering?',
                    url: () => {
                        navigate('/zorganalyse')
                    },
                    description: 'Waar ben je eigenlijk allemaal voor verzekerd? En kan het ergens anders misschien ' +
                        'goedkoper? Onderzoek wijst uit dat je gemiddeld €100 kunt besparen.',
                },
            ],
            decide: [
                {
                    type: 'boxTitle',
                    icon: null,
                    label: 'Regelen zorg',
                    url: null,
                }, {
                    type: 'link',
                    icon: 'euroSign',
                    label: 'Vraag je zorgtoeslag aan',
                    url: 'https://www.leuker.nl/zorgtoeslag-aanvragen/',
                },
            ]
        },
    },
    valuable_possession: {
        type: SUB,
        icon: 'common-icon-safe',
        label: 'Bezittingen',
        subCategories: [],
        docTypes: ["sales_receipt", "fracture_property", "insurance_policy", 'insurance_conditions',
            'insurance_invoice',
            'manual'],
        dosTypes: ['misc_contents', 'kitchen_appliances', 'household_appliances', 'phone_electronics',
            'laptop_electronics',
            'television_electronics', 'music_system_electronics', 'electro_gen', 'tablet_electronics', 'furniture'],
        gaAction: "Subcategorie click",
        headerIcon: 'common-icon-safe',
        agendaItems: [
            {
                label: 'Garantietermijn',
                image: 'bon.jpg',
                trigger: actionConstants.AGENDA_GARANTIE1,
            },
            {
                label: 'Polistermijn',
                image: 'polis.jpg',
                trigger: actionConstants.AGENDA_POLISINBOEDEL1,
            },
            {
                label: 'Contract',
                image: 'contract.jpg',
                trigger: actionConstants.AGENDA_CONTRACT1,
            },
        ],
        dashboardLinks: {
            save: [
                {
                    type: 'boxTitle',
                    icon: null,
                    label: 'Financieel bezittingen',
                    url: null,
                }, {

                    name: 'subscribtion_check',
                    type: 'link',
                    icon: 'euroSign',
                    label: 'Vergelijk mobiele abonnementen',
                    url: 'https://www.pricewise.nl',
                    description: 'Een mooie telefoon, met een goedkoop abonnement. Waar vind je dat? Check en vergelijk ' +
                        'hier mobiele abonnementen van alle providers!',
                }, {
                    name: 'household_insurance_check',
                    type: 'link',
                    icon: 'euroSign',
                    label: 'Is jouw inboedel goed verzekerd?',
                    url: null,
                    description: 'Een ongeluk zit in een klein hoekje. Zijn jouw spullen verzekerd tegen brand of ' +
                        'diefstal? Een inboedelverzekering heb je al voor €4,-!',
                },
            ],
            decide: []
        },
    },
    living: {
        type: MAIN,
        icon: 'common-icon-home',
        label: 'Wonen',
        subCategories: ['house', 'contents', 'energy', 'out_house',],
        docTypes: [],
        dosTypes: [],
        gaAction: "Hoofdcategorie click",
    },
    house: {
        type: SUB,
        icon: 'common-icon-home',
        label: 'Wonen',
        subCategories: [],
        docTypes: ["bkr_personal", "sewage_and_water_taxes_property", "mortage_overview", "mortage",
            "completion_certificate", "alarm_agreement", "preliminary_contract", "maintenance_contract",
            "financing_agreement", "security_certificate", "rebuild_value_meter", "vve_notule_property",
            "miscellaneous_indoors", "contents_woz_value_property", "alarm_card", "house_insurance_policy",
            "taxation_report_house", "alarm_certificate", "meter_reading_property"],
        dosTypes: ["house", "furniture", 'rental_house', "misc_house",],
        gaAction: "Subcategorie click",
        headerIcon: 'common-icon-home',
        dashboardLinks: {
            save: [
                {

                    type: 'boxTitle',
                    icon: null,
                    label: 'Financieel woning',
                    url: null,
                },
                {
                    name: 'energy_check',
                    type: 'link',
                    icon: 'euroSign',
                    label: 'Check of je niet teveel betaalt voor je energie',
                    url: 'https://www.leuker.nl/energie/',
                    description: 'Word geen energie slaper! Overstappen kan zo maar €250,- per jaar schelen!',
                }, {
                    name: 'house_insurance_check',
                    type: 'link',
                    icon: 'euroSign',
                    label: 'Is jouw woning goed verzekerd?',
                    url: null,
                    description: 'Met een opstalverzekering verzeker je je woning tegen schade van bijvoorbeeld een ' +
                        'brand. Doe de analyse en check welke verzekering bij jou past!',
                }, {
                    name: 'household_insurance_check',
                    type: 'link',
                    icon: 'euroSign',
                    label: 'Is jouw inboedel goed verzekerd?',
                    url: null,
                    description: 'Een ongeluk zit in een klein hoekje. Zijn jouw spullen verzekerd tegen brand of ' +
                        'diefstal? Een inboedelverzekering heb je al voor €4,-!',
                },
            ],
            decide: [
                {
                    type: 'boxTitle',
                    icon: null,
                    label: 'Regelen Huurwoning',
                    url: null,
                }, {
                    type: 'link',
                    icon: 'common-icon-file-check',
                    label: 'Vergelijk alle Internet/TV abonnementen',
                    url: 'https://www.pricewise.nl/internet/internet-tv-vergelijken/',
                },
            ]
        },
    },
    contents: {
        type: SUB,
        icon: undefined,
        label: 'Bezittingen',
        subCategories: [],
        docTypes: ["fracture_property", "sales_receipt", "manual", "miscellaneous_indoors", "customer_card_sport",
            "contents_insurance_policy"],
        dosTypes: ["kitchen_appliances", "household_appliances", "phone_electronics", "laptop_electronics",
            "television_electronics", "music_system_electronics", "electro_gen", "tablet_electronics", "furniture",
            "misc_contents"],
        gaAction: "Subcategorie click",
    },
    energy: {
        type: SUB,
        icon: undefined,
        label: 'Energie en RTV-internet',
        subCategories: [],
        docTypes: ["subscription_details", "miscellaneous_indoors"],
        dosTypes: ["boiler_indoors", "misc_energy"],
        gaAction: "Subcategorie click",
    },
    out_house: {
        type: SUB,
        icon: 'common-icon-home',
        label: 'Huurwoning',
        subCategories: [],
        docTypes: ["lease_property", 'cross_list', 'energy_contract', 'annual_report', 'tv_contract',
            'water_contract'],
        dosTypes: [],
        gaAction: "Subcategorie click",
        headerIcon: 'common-icon-home',
        tips: [
            'check_huurtoeslag', 'op_kamers', 'docs_woning',
        ],
        agendaItems: [
            {
                label: 'Huistaak',
                image: 'huistaken.jpg',
                trigger: actionConstants.AGENDA_HUISTAAK1,
            },
        ],
        dashboardLinks: {
            save: [
                {
                    type: 'boxTitle',
                    icon: null,
                    label: 'Financieel Huurwoning',
                    url: null,
                },
                {
                    name: 'rent_check',
                    type: 'link',
                    icon: 'euroSign',
                    label: 'Check of je recht hebt op huurtoeslag',
                    url: 'https://www.belastingdienst.nl/wps/wcm/connect/bldcontentnl/belastingdienst/prive/toeslagen/' +
                        'huurtoeslag/',
                    description: 'Woon je op jezelf, maar verdien je nog niet zo veel? Misschien heb je recht op ' +
                        'huurtoeslag. Gemiddeld ontvangen studenten €173,- per maand!',
                },
                {
                    name: 'remission_check',
                    type: 'link',
                    icon: 'euroSign',
                    label: 'Check of je recht hebt op kwijtschelding',
                    url: 'https://www.leuker.nl/kwijtschelding/',
                    description: 'Als je nog niet zo veel verdient, hoef je niet alle belastingen te betalen. Check of ' +
                        'jij de gemeentelijke belastingen kunt laten kwijtschelden!',
                }, {
                    name: 'energy_check',
                    type: 'link',
                    icon: 'euroSign',
                    label: 'Check of je niet teveel betaalt voor je energie',
                    url: 'https://www.leuker.nl/energie/',
                    description: 'Word geen energie slaper! Overstappen kan zo maar €250,- per jaar schelen!',
                }, {
                    name: 'payment_check',
                    type: 'link',
                    icon: 'euroSign',
                    label: 'Wiebetaaltwat.nl',
                    url: 'https://wiebetaaltwat.nl/',
                    description: 'Altijd ruzie met je huisgenoten over de rekening van de boodschappen? Via deze site ' +
                        'verdeel je alles kosten altijd eerlijk!',
                }, {
                    name: 'household_insurance_check',
                    type: 'link',
                    icon: 'euroSign',
                    label: 'Is jouw inboedel goed verzekerd?',
                    url: null,
                    description: 'Een ongeluk zit in een klein hoekje. Zijn jouw spullen verzekerd tegen brand of ' +
                        'diefstal? Een inboedelverzekering heb je al voor €4,- per maand!',
                },
            ],
            decide: [
                {
                    type: 'boxTitle',
                    icon: null,
                    label: 'Regelen Huurwoning',
                    url: null,
                },
                {
                    type: 'link',
                    icon: 'common-icon-check',
                    label: 'Vraag je huurtoeslag aan',
                    url: 'https://www.leuker.nl/huurtoeslag/',
                },
                {
                    type: 'link',
                    icon: 'common-icon-check',
                    label: 'Zoek een kamer met DUWO',
                    url: 'https://www.duwo.nl/',
                }, {
                    type: 'link',
                    icon: 'common-icon-check',
                    label: 'Zoek een kamer met Studentenwoningweb',
                    url: 'https://www.studentenwoningweb.nl/',
                }, {
                    type: 'link',
                    icon: 'common-icon-check',
                    label: 'Zoek een kamer met Kamernet',
                    url: 'https://kamernet.nl/',
                }, {
                    type: 'link',
                    icon: 'common-icon-check',
                    label: 'Vergelijk alle Internet/TV abonnementen',
                    url: 'https://www.pricewise.nl/internet/internet-tv-vergelijken/',
                },
            ]
        },
    },
    transport: {
        type: MAIN,
        icon: 'common-icon-car',
        label: 'Vervoer',
        subCategories: ['transportation_mean', 'public_transport', 'travel'],
        docTypes: [],
        dosTypes: [],
        gaAction: "Hoofdcategorie click",
    },
    transportation_mean: {
        type: SUB,
        icon: 'common-icon-car',
        label: 'Vervoer',
        subCategories: [],
        docTypes: ["declaration_work", "miscellaneous_indoors", "fuel_pass", "green_card",
            "aid_card_leasing_company", "vehicle_registration", "roadside", "moped_license_personal",
            "drivers_licence_personal", "traffic_ticket_transport", "alarm_card", "taxation_report_car",
            "car_insurance_policy", "train_subscription_transport", "ov_chip_card_transport",
            "bus_train_ticket"],
        dosTypes: ["car", "cycle_transport", "moped_transport", "motorbike_transport", "boat_transport",
            "caravan_holiday", "scooter_transport", "misc_transport_mean", "misc_public_transport"],
        gaAction: "Subcategorie click",
        headerIcon: 'common-icon-car',
        dashboardLinks: {
            save: [
                {
                    type: 'boxTitle',
                    icon: null,
                    label: 'Financieel vrije tijd',
                    url: null,
                }, {
                    name: 'car_price_check',
                    type: 'link',
                    icon: 'euroSign',
                    label: 'Check de waarde van je auto',
                    url: () => {
                        navigate('/dossier-overzicht')
                    },
                    description: 'Wil je je auto verkopen, of ben je gewoon benieuwd? Check hier de waarde van je auto!',
                }, {
                    name: 'car_insurance_check',
                    type: 'link',
                    icon: 'euroSign',
                    label: 'Is jouw auto goed verzekerd?',
                    url: () => {
                        navigate('/dossier-overzicht')
                    },
                    description: 'Een autoverzekering is voor iedereen verplicht. Maar je wil natuurlijk niet te veel ' +
                        'betalen. Doe de analyse en check of jij kunt besparen!',
                }
            ],
            decide: [{
                type: 'link',
                icon: 'common-icon-file-check',
                label: 'Check tot wanneer jouw reisproduct geldig is',
                url: null,
            },]
        },
    },
    public_transport: {
        type: SUB,
        icon: undefined,
        label: 'Openbaar vervoer',
        subCategories: [],
        docTypes: ["declaration_work", "miscellaneous_indoors",
            "student_card_study", "bus_train_ticket"],
        dosTypes: ["misc_public_transport"],
        gaAction: "Subcategorie click",
    },
    travel: {
        type: SUB,
        icon: undefined,
        label: 'Op reis',
        subCategories: [],
        docTypes: ["miscellaneous_indoors", "drivers_licence_personal", "travel_visa", "id_card_personal",
            "passport_personal", "booking_accommodation", "reservation_rental_car_camper", "reservation_transfer_taxi",
            "bus_train_ticket", "airline_ticket", "cancellation_insurance", "travel_insurance"],
        dosTypes: ["camera_electronics", "caravan_holiday", "camping_equipment_holiday", "tent_holiday", "sports_gear",
            "travel", "misc_travel", 'checklist_travel'],
        gaAction: "Subcategorie click",
    },
    free_time_sub: {
        type: SUB,
        icon: 'common-icon-travel-beach',
        label: "Vrije tijd",
        subCategories: [],
        docTypes: ['miscellaneous_indoors', 'booking_accommodation', 'reservation_rental_car_camper',
            'reservation_transfer_taxi', 'bus_train_ticket', 'cinema_card_trips',
            'cjp_pass_trips', 'museum_card_trips', 'booking_confirmation_trips',
            'access_card_trips', 'membership_card_sport', 'sportpass_sport', 'fishing_pass_sport', 'golf_licence_sport',
            'passport_personal', 'id_card_personal', 'declaration_work', 'airline_ticket', 'travel_visa',
            'subscription_details',
            'camping_gear', 'sports_gear'],
        dosTypes: ["travel", "personal_hobby", 'sport', 'camping',],
        gaAction: "Subcategorie click",
        headerIcon: 'common-icon-travel-beach',
        tips: [
            'check_travel_insurance', 'overview_subscriptions',
        ],
        dashboardLinks: {
            save: [
                {
                    type: 'boxTitle',
                    icon: null,
                    label: 'Financieel vrije tijd',
                    url: null,
                }, {
                    name: 'travel_insurance_check',
                    type: 'link',
                    icon: 'euroSign',
                    label: 'Ga jij goed verzekerd op reis?',
                    url: 'https://www.bydehand.com/heb-jij-een-goede-reisverzekering/',
                    description: 'Waarschijnlijk ben je al voor ziektekosten gedekt met je zorgverzekering. Check het ' +
                        'nu en bespaar!',
                },
            ],
            decide: [
                {
                    type: 'link',
                    icon: 'common-icon-check',
                    label: 'Doe de reischecklist',
                    url: () => {
                        navigate('/reis-checklist');
                    },
                },
            ]
        },
    },
    free_time: {
        type: MAIN,
        icon: "common-icon-travel-beach",
        label: "Vrije tijd",
        subCategories: ['travel_vacation', 'sport_games_hobby', 'events'],
        docTypes: [],
        dosTypes: [],
        gaAction: "Hoofdcategorie click",
    },
    travel_vacation: {
        type: SUB,
        icon: undefined,
        label: 'Op reis, vakantie',
        subCategories: [],
        docTypes: ["miscellaneous_indoors", "travel_visa", "id_card_personal", "passport_personal",
            "booking_accommodation", "reservation_rental_car_camper", "reservation_transfer_taxi", "bus_train_ticket",
            "airline_ticket", "booking_confirmation_trips", "access_card_trips", "cancellation_insurance",
            "travel_insurance"],
        dosTypes: ["camera_electronics", "boat_transport", "caravan_holiday", "camping_equipment_holiday",
            "tent_holiday", "sports_gear", "travel", "misc_travel_vacation"],
        gaAction: "Subcategorie click",
    },
    sport_games_hobby: {
        type: SUB,
        icon: undefined,
        label: 'Sport, spel en hobby',
        subCategories: [],
        docTypes: ["miscellaneous_indoors", "access_card_work", "cinema_card_trips", "cjp_pass_trips",
            "museum_card_trips", "booking_confirmation_trips", "access_card_trips", "membership_card_sport",
            "sportpass_sport", "fishing_pass_sport", "golf_licence_sport", "boat_licence_personal"],
        dosTypes: ["laptop_electronics", "music_system_electronics", "camera_electronics", "cycle_transport",
            "motorbike_transport", "boat_transport", "sports_gear", "skis_sport", "sport_clothes_sport",
            "personal_hobby", "tablet_electronics", "snowboard_sport", "sport_shoes_sport", "misc_sport_games_hobby"],
        gaAction: "Subcategorie click",
    },
    events: {
        type: SUB,
        icon: undefined,
        label: 'Uitjes en evenementen',
        subCategories: [],
        docTypes: ["miscellaneous_indoors", "booking_accommodation", "reservation_rental_car_camper",
            "reservation_transfer_taxi", "bus_train_ticket", "cinema_card_trips",
            "cjp_pass_trips", "museum_card_trips", "booking_confirmation_trips", "access_card_trips",
            "membership_card_sport", "sportpass_sport", "fishing_pass_sport", "golf_licence_sport"],
        dosTypes: [],
        gaAction: "Subcategorie click",
    },
    work_financial_study: {
        type: MAIN,
        icon: "common-icon-suitcase",
        label: 'Werk, inkomen en studie',
        subCategories: ['work', 'study', 'company', 'money'],
        docTypes: [],
        dosTypes: [],
        gaAction: "Hoofdcategorie click",
    },
    work: {
        type: SUB,
        icon: undefined,
        label: 'Werk',
        subCategories: [],
        docTypes: ["bkr_personal", "annual_review_property", "declaration_work", "annual_statement_work",
            "train_subscription_transport", "labor_contract_work", "cao_work", "miscellaneous_indoors",
            "aid_card_leasing_company", "ov_chip_card_transport", "vog_personal", "access_card_work", "diploma_study"],
        dosTypes: ["phone_electronics", "laptop_electronics", "job", "tablet_electronics", "misc_work"],
        gaAction: "Subcategorie click",
    },
    study: {
        type: SUB,
        icon: 'common-icon-education-2',
        label: 'Studie',
        subCategories: [],
        docTypes: ["ov_chip_card_transport", "certificat_study", "internship_agreement", "proof_of_registration_study",
            "student_card_study", "diploma_study", "grade_list_study", "miscellaneous_indoors", 'curriculum_vitae'],
        dosTypes: ["study", 'intership', 'secondary_activity', "misc_study",],
        gaAction: "Subcategorie click",
        headerIcon: 'common-icon-education-2',
        tips: [
            'stop_ov', 'remind_inschrijven', 'organize_gradelists'
        ],
        agendaItems: [
            {
                label: 'Tentamen',
                image: 'tentamen.jpg',
                trigger: actionConstants.AGENDA_TENTAMEN1,
            },
            {
                label: 'Inschrijven vakken',
                image: 'tentamen.jpg',
                trigger: actionConstants.AGENDA_INSCHRIJVING1,
            },
            {
                label: 'OV-chipkaart verlopen',
                image: 'ovchipkaart.jpg',
                trigger: actionConstants.AGENDA_OV_STUDENT1,
            },
        ],
        dashboardLinks: {
            save: [
                {
                    type: 'boxTitle',
                    icon: null,
                    label: 'Financieel studie',
                    url: null,
                }, {
                    name: 'stop_ov_check',
                    type: 'link',
                    icon: 'euroSign',
                    label: 'Voorkom een boete, zet je OV chipkaart op tijd stop',
                    url: 'https://www.leuker.nl',
                    description: 'Let op! Je hebt geen recht meer vanaf het moment dat je afstudeert (afstudeerdatum). ' +
                        'Het kan zijn dat je pas later officieel uitgeschreven bent bij je school of universiteit.',
                }, {
                    name: 'knaek_pas_check',
                    type: 'link',
                    icon: 'euroSign',
                    label: 'Vraag je knaek-pas aan',
                    url: 'https://www.knaek.nl/',
                },
            ],
            decide: [
                {
                    type: 'boxTitle',
                    icon: null,
                    label: 'Regelen studie',
                    url: null,
                },
                {
                    type: 'link',
                    icon: 'common-icon-file-checklist',
                    label: 'Vraag je studiefinanciering aan bij DUO',
                    url: 'https://duo.nl/particulier/studiefinanciering-aanvragen.jsp',
                },
                {
                    type: 'link',
                    icon: 'common-icon-file-checklist',
                    label: 'Wijzig je studiefinanciering bij DUO',
                    url: 'https://duo.nl/particulier/studiefinanciering-aanvragen.jsp',
                },
                {
                    type: 'link',
                    icon: 'common-icon-file-checklist',
                    label: 'Vraag je collegegeldkrediet aan',
                    url: 'https://duo.nl/particulier/student-hbo-of-universiteit/studeren/collegegeld-betalen.jsp',
                },
                {
                    type: 'link',
                    icon: 'common-icon-file-checklist',
                    label: 'Alles over studiefinanciering',
                    url: () => {
                        let downloadUrl = require('BdhCommon/pdf/nibud_geldwijzer.pdf');
                        let linkId = 'docDownloadLink';

                        if (isNotNull(document.getElementById(linkId))) {
                            let elem = document.getElementById(linkId);
                            elem.parentNode.removeChild(elem);
                        }
                        let link = document.createElement('a');
                        link.id = linkId;
                        link.style.display = 'none';
                        link.href = downloadUrl;
                        link.download = 'nibud_geldwijzer';
                        document.body.appendChild(link);
                        link.click();
                    },
                    description: 'Is het een lening of is het een gift? Hoe zit het nou precies? Hier lees alles over ' +
                        'de studiefinanciering!',
                },
                {
                    type: 'link',
                    icon: 'common-icon-file-checklist',
                    label: 'Dit moet je regelen als je gaat studeren',
                    url: 'https://leuker.nl',
                },
                {
                    type: 'link',
                    icon: 'common-icon-file-checklist',
                    label: 'Dit moet je regelen als je klaar bent met studeren',
                    url: 'https://leuker.nl',
                },
            ]
        },
    },
    company: {
        type: SUB,
        icon: undefined,
        label: 'Bedrijf ZZP',
        subCategories: [],
        docTypes: ["bkr_personal", "annual_review_property", "declaration_work", "annual_statement_work",
            "train_subscription_transport", "labor_contract_work", "fracture_property", "sales_receipt", "cao_work",
            "miscellaneous_indoors", "aid_card_leasing_company", "vog_personal", "bank_card_personal",
            "credit_card_personal", "access_card_work"],
        dosTypes: ["laptop_electronics", "television_electronics", "tablet_electronics", "misc_company"],
        gaAction: "Subcategorie click",
    },
    money: {
        type: SUB,
        icon: "common-icon-banking-spending",
        label: 'Geld en inkomen',
        subCategories: [],
        docTypes: ["credit_card_personal", "labor_contract_work", "contents_insurance_policy",
            "liability_insurance_health", "car_insurance_policy", "travel_insurance", "health_insurance",
            "cancellation_insurance", "annual_statement_work", "health_card"],
        dosTypes: ["phone_electronics", "laptop_electronics", "job", "tablet_electronics", "television_electronics",
            "tax_year"],
        gaAction: "Subcategorie click",
        headerIcon: "common-icon-banking-spending",
    },
    financial: {
        type: MAIN,
        icon: "common-icon-banking-spending",
        label: "Financiële zaken",
        subCategories: ['taxes', 'income_retirement', 'insurance', 'fixed_costs', 'mortages', 'savings', 'purchases'],
        docTypes: [],
        dosTypes: [],
        gaAction: "Hoofdcategorie click",
    },
    taxes: {
        type: SUB,
        icon: undefined,
        label: 'Belastingen',
        subCategories: [],
        docTypes: ["annual_review_property", "declaration_work", "annual_statement_work", "sewage_and_water_taxes_property",
            "miscellaneous_indoors", "contents_woz_value_property", "prenuptial_agreement_family"],
        dosTypes: ["house", "tax_year", "misc_taxes"],
        gaAction: "Subcategorie click",
    },
    income_retirement: {
        type: SUB,
        icon: undefined,
        label: 'Inkomen en pensioen',
        subCategories: [],
        docTypes: ["bkr_personal", "annual_review_property", "declaration_work", "annual_statement_work",
            "labor_contract_work", "miscellaneous_indoors"],
        dosTypes: [],
        gaAction: "Subcategorie click",
    },
    insurance: {
        type: SUB,
        icon: undefined,
        label: 'Verzekering',
        subCategories: [],
        docTypes: ["miscellaneous_indoors", "insurance_policy", "health_card", "health_insurance",
            "healthcare_analysis_insurance", "car_insurance_policy", "disability_insurance_health",
            "contents_insurance_policy", "house_insurance_policy", "liability_insurance_health",
            "legal_insurance_health",
            "cancellation_insurance", "travel_insurance", "life_insurance_personal", "funeral_insurance_personal",
            "animal_insurance_animals"],
        dosTypes: ["health_insurance_care", "misc_insurance"],
        gaAction: "Subcategorie click",
    },
    fixed_costs: {
        type: SUB,
        icon: undefined,
        label: 'Vaste lasten, abonnementen',
        subCategories: [],
        docTypes: ["declaration_work", "sewage_and_water_taxes_property", "train_subscription_transport", "rent_agreement",
            "mortage_overview", "mortage", "maintenance_contract", "alarm_agreement", "lease_property",
            "subscription_details",
            "miscellaneous_indoors", "contents_woz_value_property", "traffic_ticket_transport", "bank_card_personal",
            "credit_card_personal", "bus_train_ticket", "cinema_card_trips", "museum_card_trips", "insurance_policy",
            "health_card", "health_insurance", "healthcare_analysis_insurance", "car_insurance_policy",
            "disability_insurance_health", "contents_insurance_policy", "house_insurance_policy",
            "liability_insurance_health", "legal_insurance_health", "cancellation_insurance", "travel_insurance",
            "life_insurance_personal", "funeral_insurance_personal", "animal_insurance_animals"],
        dosTypes: ["phone_electronics", "television_electronics", "car", "motorbike_transport", "boat_transport",
            "glasses_care", "boiler_indoors", "house", "misc_fixed_costs"],
        gaAction: "Subcategorie click",
    },
    mortages: {
        type: SUB,
        icon: undefined,
        label: "Leningen en hypotheek",
        subCategories: [],
        docTypes: ["bkr_personal", "mortage_overview", "mortage", "miscellaneous_indoors",
            "prenuptial_agreement_family"],
        dosTypes: [],
        gaAction: "Subcategorie click",
    },
    savings: {
        type: SUB,
        icon: undefined,
        label: "Sparen, beleggen en vermogen",
        subCategories: [],
        docTypes: ["miscellaneous_indoors"],
        dosTypes: ["job", "misc_savings"],
        gaAction: "Subcategorie click",
    },
    purchases: {
        type: SUB,
        icon: undefined,
        label: 'Aankopen en bezittingen',
        subCategories: [],
        docTypes: ["preliminary_contract", "subscription_details", "fracture_property", "sales_receipt", "manual",
            "miscellaneous_indoors", "alarm_card", "bank_card_personal", "credit_card_personal", "customer_card_sport"],
        dosTypes: ["furniture", "phone_electronics", "laptop_electronics", "television_electronics",
            "music_system_electronics", "camera_electronics", "electro_gen", "car", "cycle_transport",
            "moped_transport", "motorbike_transport", "boat_transport", "boiler_indoors", "kitchen_appliances",
            "household_appliances", "caravan_holiday", "camping_equipment_holiday", "tent_holiday", "sports_gear",
            "skis_sport", "sport_clothes_sport", "scooter_transport", "tablet_electronics", "snowboard_sport",
            "sport_shoes_sport", "misc_purchases"],
        gaAction: "Subcategorie click",
    },
    document_type: {
        type: MAIN,
        icon: "common-icon-document",
        label: "Soort document",
        subCategories: ['cards', 'certificates', 'contracts', 'juridical_documents', 'instantial_documents',
            'periodic_documents', 'receipts', 'tickets', 'specifications', 'included_documentation'],
        docTypes: [],
        dosTypes: [],
        gaAction: "Hoofdcategorie click",
        excludeFromDossiers: true,
    },
    cards: {
        type: SUB,
        icon: undefined,
        label: "Passen, kaarten en bewijzen",
        subCategories: [],
        docTypes: ["train_subscription_transport", "security_certificate", "donor_card_care", "animal_passport_animals",
            "miscellaneous_indoors", "pons_card_care", "vaccination_card_holiday", "proof_of_registration_study",
            "student_card_study", "fuel_pass", "green_card", "aid_card_leasing_company", "vehicle_registration",
            "roadside", "moped_license_personal", "drivers_licence_personal", "ov_chip_card_transport", "vog_personal",
            "alarm_card", "travel_visa", "bank_card_personal", "credit_card_personal", "id_card_personal",
            "passport_personal", "will_personal", "customer_card_sport", "access_card_work", "cinema_card_trips",
            "cjp_pass_trips", "museum_card_trips", "booking_confirmation_trips", "access_card_trips",
            "membership_card_sport", "sportpass_sport", "fishing_pass_sport", "golf_licence_sport", "diploma_study",
            "boat_licence_personal", "certificat_study", "health_card", "alarm_certificate"],
        dosTypes: [],
        gaAction: "Subcategorie click",
    },
    certificates: {
        type: SUB,
        icon: undefined,
        label: "Vaardigheidsbewijzen en diploma's",
        subCategories: [],
        docTypes: ["miscellaneous_indoors", "golf_licence_sport", "diploma_study", "boat_licence_personal",
            "certificat_study", "grade_list_study"],
        dosTypes: [],
        gaAction: "Subcategorie click",
    },
    contracts: {
        type: SUB,
        icon: undefined,
        label: "Contracten en overeenkomsten",
        subCategories: [],
        docTypes: ["cohabitation_contract_family", "labor_contract_work", "rent_agreement", "mortage_overview",
            "mortage",
            "completion_certificate", "maintenance_contract", "alarm_agreement", "preliminary_contract",
            "lease_property",
            "financing_agreement", "subscription_details", "miscellaneous_indoors", "prenuptial_agreement_family",
            "proof_of_registration_study", "roadside", "booking_accommodation", "reservation_rental_car_camper",
            "reservation_transfer_taxi", "bus_train_ticket", "airline_ticket", "health_insurance",
            "car_insurance_policy",
            "disability_insurance_health", "contents_insurance_policy", "house_insurance_policy",
            "liability_insurance_health", "legal_insurance_health", "cancellation_insurance", "travel_insurance",
            "life_insurance_personal", "funeral_insurance_personal", "animal_insurance_animals"],
        dosTypes: [],
        gaAction: "Subcategorie click",
    },
    juridical_documents: {
        type: SUB,
        icon: undefined,
        label: "Juridische en notariële documenten",
        subCategories: [],
        docTypes: ["cohabitation_contract_family", "mortage_overview", "mortage", "completion_certificate",
            "miscellaneous_indoors", "prenuptial_agreement_family", "green_card", "vehicle_registration",
            "moped_license_personal", "drivers_licence_personal"],
        dosTypes: [],
        gaAction: "Subcategorie click",
    },
    instantial_documents: {
        type: SUB,
        icon: undefined,
        label: "Uittreksels instanties",
        subCategories: [],
        docTypes: ["bkr_personal", "annual_review_property", "annual_statement_work", "donor_card_care",
            "miscellaneous_indoors", "contents_woz_value_property", "student_card_study", "green_card",
            "vehicle_registration", "roadside", "moped_license_personal", "drivers_licence_personal",
            "ov_chip_card_transport", "vog_personal", "travel_visa", "id_card_personal", "passport_personal",
            "will_personal", "taxation_report_car", "taxation_report_house"],
        dosTypes: [],
        gaAction: "Subcategorie click",
    },
    periodic_documents: {
        type: SUB,
        icon: undefined,
        label: "Periodieke overzichten",
        subCategories: [],
        docTypes: ["annual_review_property", "declaration_work", "annual_statement_work", "mortage_overview",
            "miscellaneous_indoors", "meter_reading_property"],
        dosTypes: [],
        gaAction: "Subcategorie click",
    },
    receipts: {
        type: SUB,
        icon: undefined,
        label: "Facturen, bonnen en aanslagen",
        subCategories: [],
        docTypes: ["declaration_work", "sewage_and_water_taxes_property", "fracture_property", "sales_receipt",
            "miscellaneous_indoors", "contents_woz_value_property", "traffic_ticket_transport", "access_card_trips",
            "membership_card_sport"],
        dosTypes: [],
        gaAction: "Subcategorie click",
    },
    tickets: {
        type: SUB,
        icon: undefined,
        label: "Tickets en reserveringen",
        subCategories: [],
        docTypes: ["train_subscription_transport", "miscellaneous_indoors", "booking_accommodation",
            "reservation_rental_car_camper", "reservation_transfer_taxi", "bus_train_ticket", "airline_ticket",
            "cinema_card_trips", "cjp_pass_trips", "museum_card_trips", "booking_confirmation_trips"],
        dosTypes: [],
        gaAction: "Subcategorie click",
    },
    specifications: {
        type: SUB,
        icon: undefined,
        label: "Specificaties en onderzoeksresultaten",
        subCategories: [],
        docTypes: ["research_results_care", "eye_ear_measuring_care", "rebuild_value_meter", "miscellaneous_indoors",
            "prescription_pharmacy_care", "referral_care", "vaccination_card_holiday", "taxation_report_car",
            "healthcare_analysis_insurance", "taxation_report_house"],
        dosTypes: [],
        gaAction: "Subcategorie click",
    },
    included_documentation: {
        type: SUB,
        icon: undefined,
        label: "Bijbehorende documentatie",
        subCategories: [],
        docTypes: ["leaflet_drug_care", "research_results_care", "cao_work", "vve_notule_property", "manual",
            "miscellaneous_indoors", "contents_woz_value_property", "pons_card_care", "prescription_pharmacy_care",
            "referral_care", "vaccination_card_holiday", "proof_of_registration_study", "alarm_card", "travel_visa",
            "booking_confirmation_trips", "access_card_trips", "membership_card_sport"],
        dosTypes: [],
        gaAction: "Subcategorie click",
    },
    money_income: {
        type: SUB,
        icon: 'common-icon-banking-spending',
        label: 'Geld & Inkomen',
        subCategories: [],
        docTypes: ['annual_review_property', 'declaration_work', 'annual_statement_work',
            'sewage_and_water_taxes_property',
            'miscellaneous_indoors', 'contents_woz_value_property', 'bkr_personal',
            'bank_card_personal', 'credit_card_personal', 'labor_contract_work', 'health_card', 'fracture_property',
            'curriculum_vitae', 'subscription_details', 'energy_contract', 'annual_report', 'tax_return', 'payroll',
            'insurance_policy', 'insurance_conditions', 'insurance_invoice'],
        dosTypes: ['job', 'health_insurance_care', 'tax_year', 'pay_pass', 'contents_insurance', 'liability_insurance',
            'car_insurance', 'travel_insurance', 'cancel_insurance', 'subscriptions', 'energy', 'water', 'misc_taxes',
            'misc_savings'],
        gaAction: 'Subcategorie click',
        headerIcon: 'common-icon-banking-spending',
        tips: [
            'check_kwijtschelding', 'prepare_job_interview', 'energy_water_provider', 'check_subscriptions',
            'check_incasso',
        ],
        agendaItems: [
            {
                label: 'Pinpas verlopen',
                image: 'pinpas.jpg',
                trigger: actionConstants.AGENDA_PINPAS1,
            },
            {
                label: 'Creditcard verlopen',
                image: 'creditcard.jpg',
                trigger: actionConstants.AGENDA_CREDITCARD1,
            },
            {
                label: 'Polistermijn',
                image: 'polis.jpg',
                trigger: actionConstants.AGENDA_POLISGELD1,
            },
            {
                label: 'Abonnement termijn',
                image: 'subscription.jpg',
                trigger: actionConstants.AGENDA_ABONNEMENT1,
            },
            {
                label: 'Energiecontract',
                image: 'contract.jpg',
                trigger: actionConstants.AGENDA_ENERGIECONTRACT1,
            },
        ],
        dashboardLinks: {
            save: [
                {
                    type: 'boxTitle',
                    icon: null,
                    label: 'Financieel geld & inkomen',
                    url: null,
                }, {
                    name: 'care_check',
                    type: 'link',
                    icon: 'common-icon-check',
                    label: 'Check of je recht hebt op zorgtoeslag',
                    url: 'https://www.leuker.nl/zorgtoeslag-aanvragen/',
                    description: 'De kans is groot dat jij recht hebt op zorgtoeslag. Studenten krijgen al snel €94,- ' +
                        'per maand!',
                }, {
                    name: 'rent_check',
                    type: 'link',
                    icon: 'common-icon-check',
                    label: 'Check of je recht hebt op huurtoeslag',
                    url: 'https://www.leuker.nl/huurtoeslag/',
                    description: 'Woon je op jezelf, maar verdien je nog niet zo veel? Misschien heb je recht op ' +
                        'huurtoeslag. Gemiddeld ontvangen studenten €173,- per maand!',
                }, {
                    name: 'pay_check',
                    type: 'link',
                    icon: 'common-icon-check',
                    label: 'Check of je belasting kunt laten kwijtschelden',
                    url: 'https://www.leuker.nl/kwijtschelding/',
                    description: 'Als je nog niet zo veel verdient, hoef je niet alle belastingen te betalen. Check of ' +
                        'jij de gemeentelijke belastingen kunt laten kwijtschelden! Dit scheelt snel €100 of meer per jaar!',
                }, {
                    name: 'tax_refund_check',
                    type: 'link',
                    icon: 'common-icon-check',
                    label: 'Check hoeveel belasting jij kunt terugvragen',
                    url: 'https://www.leuker.nl/belasting-terugvragen/',
                    description: 'Heb jij je jaaropgave al gekregen? Misschien kun je geld terugvragen van de ' +
                        'Belastingdienst. Gemiddeld krijgen studenten €396,- terug!',
                }, {
                    name: 'energy_check',
                    type: 'link',
                    icon: 'common-icon-check',
                    label: 'Check of je niet teveel betaalt voor je energie',
                    url: 'https://www.leuker.nl/energie/',
                    description: 'Word geen energie slaper! Overstappen kan zo maar €250,- per jaar schelen!',
                }, {
                    name: 'health_insurance_check',
                    type: 'link',
                    icon: 'common-icon-check',
                    label: 'Heb jij de goede zorgverzekering?',
                    url: () => {
                        navigate('/zorganalyse')
                    },
                    description: 'Waar ben je eigenlijk allemaal voor verzekerd? En kan het ergens anders misschien ' +
                        'goedkoper? Onderzoek wijst uit dat je gemiddeld €100 kunt besparen.',
                }, {
                    name: 'household_insurance_check',
                    type: 'link',
                    icon: 'common-icon-check',
                    label: 'Is jouw inboedel goed verzekerd?',
                    url: null, // TODO Determine url
                    description: 'Een ongeluk zit in een klein hoekje. Zijn jouw waardevolle spullen verzekerd tegen ' +
                        'een ongelukje of diefstal? Een inboedelverzekering heb je al voor €4,-!',
                }, {
                    name: 'travel_insurance_check',
                    type: 'link',
                    icon: 'common-icon-check',
                    label: 'Ga jij goed verzekerd op reis?',
                    url: '//www.bydehand.com/heb-jij-een-goede-reisverzekering',
                    description: 'Waarschijnlijk ben je al voor ziektekosten gedekt met je zorgverzekering. Check het ' +
                        'nu en bespaar!',
                }, {
                    name: 'car_insurance_check',
                    type: 'link',
                    icon: 'common-icon-check',
                    label: 'Is jouw auto goed verzekerd?',
                    url: null, // TODO Determine url
                    description: 'Een autoverzekering is voor iedereen verplicht. Maar je wil natuurlijk niet te veel ' +
                        'betalen. Doe de analyse en check of jij kunt besparen!',
                }, {
                    name: 'house_insurance_check',
                    type: 'link',
                    icon: 'common-icon-check',
                    label: 'Is jouw woning goed verzekerd?',
                    url: null, // TODO Determine url
                    description: 'Met een opstalverzekering verzeker je je woning tegen schade van bijvoorbeeld een ' +
                        'brand. Doe de analyse en check welke verzekering bij jou past!',
                },
            ],
            decide: [{
                type: 'link',
                icon: 'euroSign',
                label: 'Vraag je zorgtoeslag aan',
                url: 'https://www.leuker.nl/zorgtoeslag-aanvragen/',
            }, {
                type: 'link',
                icon: 'euroSign',
                label: 'Vraag je huurtoeslag aan',
                url: 'https://www.leuker.nl/huurtoeslag/',
            }, {
                type: 'link',
                icon: 'euroSign',
                label: 'Vraag je belasting terug',
                url: 'https://www.leuker.nl/belasting-terugvragen/',
            }, {
                type: 'link',
                icon: 'common-icon-check',
                label: 'Wijzig of zet je zorgtoeslag stop',
                url: 'https://www.belastingdienst.nl/wps/wcm/connect/bldcontentnl/belastingdienst/prive/toeslagen/zorgtoeslag/zorgtoeslag-2018/zorgtoeslag-2018',
            }, {
                type: 'link',
                icon: 'common-icon-check',
                label: 'Wijzig of zet je huurtoeslag stop',
                url: 'https://www.belastingdienst.nl/wps/wcm/connect/bldcontentnl/belastingdienst/prive/toeslagen/huurtoeslag/',
            },

            ]
        },
    }
};

// Map to support older uses of the categoryMapping, which may still use integers as identifiers instead of names.
// Using integers as identifiers will output a warning in the console, all usages of integers should be replaced
// by names.
const deprecationMap = {
    1: 'personal_family_care',
    2: 'personal',
    3: 'family',
    4: 'care',
    5: 'living',
    6: 'house',
    7: 'contents',
    8: 'energy',
    9: 'transport',
    10: 'transportation_mean',
    11: 'public_transport',
    12: 'travel',
    13: 'free_time',
    14: 'travel_vacation',
    15: 'sport_games_hobby',
    16: 'events',
    17: 'work_financial_study',
    18: 'work',
    19: 'study',
    20: 'company',
    21: 'financial',
    22: 'taxes',
    23: 'income_retirement',
    24: 'insurance',
    25: 'fixed_costs',
    26: 'mortages',
    27: 'savings',
    28: 'purchases',
    29: 'document_type',
    30: 'cards',
    31: 'certificates',
    32: 'contracts',
    33: 'juridical_documents',
    34: 'instantial_documents',
    35: 'periodic_documents',
    36: 'receipts',
    37: 'tickets',
    38: 'specifications',
    39: 'included_documentation',
};

export const getKeyByLabel = label => {
    let keys = Object.keys(getCategoryType());
    for (let i = 0; i < keys.length; i++) {
        if (getCategoryType(keys[i]).label === label) {
            return keys[i];
        }
    }
};

export const getValues = (names) => {
    let values = [];
    for (let i = 0; i < names.length; i++) {
        values.push(getCategoryType(names[i]));
    }
    return values;
};

export const getCatByLabel = label => {
    let keys = Object.keys(getCategoryType());
    for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        if (getCategoryType(key).label === label) {
            return getCategoryType(key);
        }
    }
    utils.warn('CategoryMapping does not contain an entry with label \'' + label + '\'.');
};

export const getCatBySubCat = label => {
    let keys = Object.keys(getCategoryType());
    for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        if (getCategoryType(key).subCategories.indexOf(label) !== -1) {
            return getCategoryType(key);
        }
    }
    utils.warn('CategoryMapping does not contain an entry with subcat \'' + label + '\'.');
};

export const getAllDosTypes = () => {
    let dosTypes = [];
    let misc = [];
    let keys = Object.keys(getCategoryType());
    for (let i = 0; i < keys.length; i++) {
        let types = getCategoryType(keys[i]).dosTypes;
        for (let j = 0; j < types.length; j++) {
            if (dosMap(types[j]).label.toLowerCase().includes('overige')) {
                if (!misc.includes(types[j]))
                    misc.push(types[j]);
            } else if (!dosTypes.includes(types[j]))
                dosTypes.push(types[j]);
        }
    }
    dosTypes.sort((typeA, typeB) => {
        typeA = dosMap(typeA);
        typeB = dosMap(typeB);
        const labelA = typeA.label.toLowerCase(), labelB = typeB.label.toLowerCase();
        const preferA = -1, preferB = 1, isEqual = 0;
        return labelA < labelB ? preferA : labelA > labelB ? preferB : isEqual;
    });
    return dosTypes.concat(misc);
};

const getCategoryType = name => {
    if (utils.isNotNull(name)) {
        if (typeof name === 'number') {
            utils.warn('Using numbers as categoryMapping ids is deprecated, use the category name instead.');
            name = deprecationMap[name];
        }
        let value = categoryMapping[name];
        if (utils.isNotNull(value)) {
            return value;
        }
    }
    return categoryMapping;
};

export default getCategoryType;
