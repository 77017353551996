import '../style/intro.scss';

import React from 'react';
import PropTypes from 'prop-types';
import MaterialFactory from "../../material/materialFactory";
import {materialTypes} from "../../material/materialTypes";

export default class Intro25 extends React.Component {
    constructor(props) {
        super(props);
    }

    render = () => (
        <div className='intro'>
            <div className='leftContent'>
                <label className='introTitle'>Welkom op ByDeHand</label>
                <p className='par'>
                    Tof dat je ervoor hebt gekozen om je administratie wat meer op orde te krijgen. Binnen een paar
                    minuten heb je al een grote stap gemaakt en weet je precies hoe hoog je maandelijkse vaste lasten
                    zijn, wanneer je gas/water/licht wordt afgeschreven en heb je al je belangrijke documenten bij
                    elkaar.
                </p>
                <p className='par'>
                    Klik hieronder op beginnen om een start te maken je administratie.
                </p>
                <MaterialFactory componentType={materialTypes.RAISED_BUTTON} className='startButton'
                                 onClick={this.props.nextStep}>Beginnen!</MaterialFactory>
            </div>
        </div>
    );
}

Intro25.propTypes = {
    nextStep: PropTypes.func.isRequired,
};