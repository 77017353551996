import './style/risk.scss';

import React from 'react';
import PropTypes from 'prop-types';

import {isNotNull, roundTo, scrollToTop, toMoney} from 'glob-common-js/lib/utils';

import evaluationConst from '../misc/constants';
import {navigate} from '../../../../../misc/utils';
import EvaluationButtonsController from '../misc/evaluationButtons/evaluationButtonsController';

export default class EvaluationRiskView extends React.Component {
    constructor(props) {
        super(props);
    }

    createRiskPriceBar = () => {
        let currentRisk = this.props.risk.value;
        let prices = [385, 485, 585, 685, 785, 885];
        let boxIndex = 1;
        return (
            <div className="priceBar">
                <label className="priceBarLabel">Eigen risico</label>
                <div className='priceBarBoxes'>
                    <div className="priceBarBox">
                        {prices.map((price, key) => {
                            let isCurrent = isNotNull(currentRisk) ? currentRisk === price : false;
                            let className = 'box' + boxIndex++;
                            return (
                                <div key={key} className="box">
                                    {isCurrent ?
                                        <div className="currentRiskIndicator">
                                            <label className='currentRiskLabel'>Je huidig eigen risico</label>
                                            <div className="common-icon-arrow-right arrowIcon"/>
                                        </div> : null}
                                    <div key={key} className={className}>
                                        <label className='priceLabel'>{toMoney(price)}</label>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        )
    };

    createDiscountPriceBar = () => {
        let firstBox = 6, lastBox = 1;
        let boxIndex = 7;
        let risk = this.props.risk;
        risk.change_value = roundTo(risk.change_value);
        let discounts = [0, risk['485'], risk['585'], risk['685'], risk['785'], risk['885']].map(number => (number));
        return (
            <div className="priceBar">
                <label className="priceBarLabel">Korting op jaarpremie</label>
                <div className='priceBarBoxes'>
                    <div className="priceBarBox">
                        {discounts.map((price, key) => {
                            let className = 'box' + --boxIndex + ' reversed';
                            return (
                                <div key={key} className="box">
                                    <div className={className}>
                                        <label className='priceLabel'>&euro; {roundTo(price)},-</label>
                                    </div>
                                    {boxIndex === firstBox ?
                                        this.createDiscountInfo('Voordelig wanneer zorgkosten hoger zijn dan \u20ac ' + (risk.change_value % 1 === 0 ? risk.change_value + ",-" : risk.change_value), "firstBox")
                                        : null}
                                    {boxIndex === lastBox ?
                                        this.createDiscountInfo('Voordelig wanneer zorgkosten lager zijn dan \u20ac ' + (risk.change_value % 1 === 0 ? risk.change_value + ",-" : risk.change_value), "lastBox")
                                        : null}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        )
    };

    createDiscountInfo = (text, className) => (
        <div className={"discountInfoContainer " + className}>
            <div className="common-icon-arrow-left arrowIcon"/>
            <div className="infoContainer">
                <label className='discountInfoText'>{text}</label>
            </div>
        </div>
    );

    showRiskInfo = () => {
        let dropdownArrow = document.getElementsByClassName('dropdownArrow')[0];
        let answerContainer = document.getElementsByClassName('riskAnswerContainer')[0];
        if (isNotNull(dropdownArrow)) {
            dropdownArrow.classList.toggle('up');
        }
        if (isNotNull(answerContainer)) {
            answerContainer.classList.toggle('active');
        }
    };

    navigateAfterDecision = () => {
        navigate('/zorganalyse/evaluatie/jouw-verzekeraar', true);
        scrollToTop();
    };

    render = () => {
        let evalButtons = [
            {
                buttonText: 'Ja, dit past bij mij',
                onClick: () => {
                    this.props.setEvaluation(evaluationConst.SATISFIED);
                    this.navigateAfterDecision();
                },
                defaultSelected: this.props.evaluationDecision === evaluationConst.SATISFIED,
            },
            {
                buttonText: 'Nee, dit past niet (meer) bij mij',
                onClick: () => {
                    this.props.setEvaluation(evaluationConst.DISSATISFIED);
                    this.navigateAfterDecision();
                },
                defaultSelected: this.props.evaluationDecision === evaluationConst.DISSATISFIED,
            },
            {
                buttonText: 'Dat weet ik (nog) niet',
                onClick: () => {
                    this.props.setEvaluation(evaluationConst.UNSURE);
                    this.navigateAfterDecision();
                },
                defaultSelected: this.props.evaluationDecision === evaluationConst.UNSURE,
            }
        ];
        return (
            <div className="evaluationRisk">
                <div className="headerContainer">
                    <p className='headerText'>
                        In 2020 ligt het verplicht eigen risico op &euro; 385,-. Je kan er voor kiezen om dit eigen
                        risico tot &euro; 885,- te verhogen: hoe hoger je eigen risico, des te meer korting krijg jij op
                        je maandpremie.
                    </p>
                </div>
                <div className="priceBarContainer">
                    {this.createRiskPriceBar()}
                    {this.createDiscountPriceBar()}
                </div>
                <div className="riskQuestionContainer" onClick={this.showRiskInfo}>
                    <label className="questionText">Wanneer is het verstandig om mijn eigen risico aan te
                        passen?</label>
                    <i className="dropdownArrow"/>
                </div>
                <div className='riskAnswerContainer'>
                    <p>
                        Het voordeel van een hoger eigen risico is dat de maandelijkse premie lager is.
                        Het nadeel is dat je meer kosten zelf moet betalen in geval je gebruik maakt van die zorg.
                        <br/>
                        <br/>
                        Dus in geval je verwacht veel zorg uit de basisverzekering nodig te hebben kun je beter een laag
                        eigen risico nemen.
                        Ook als het voor jou niet mogelijk is een hoog eigen risico in één keer te betalen is het
                        verstandig een lager eigen risico te nemen.
                        <br/>
                        <br/>
                        In geval je weinig zorg uit de basisverzekering verwacht nodig te hebben en het geen probleem is
                        een hoog eigen risico in één keer te betalen is het financieel aantrekkelijk een hoog eigen
                        risico te nemen.
                    </p>
                </div>
                <div className="evaluationButtonContainer">
                    <EvaluationButtonsController headerTitle='Past dit eigen risico nog bij jouw situatie?'
                                                 buttons={evalButtons}/>
                </div>
            </div>
        );
    };
}

EvaluationRiskView.propTypes = {
    risk: PropTypes.object.isRequired,
    setEvaluation: PropTypes.func.isRequired,
    evaluationDecision: PropTypes.string,
};
