import React from 'react';
import XLSX from 'xlsx';
import {toMoment} from 'glob-common-js/lib/utils';

import StateComponent from "../misc/stateComponent";
import MailingList from "./mailingList";
import {checkAdminUser, generateMailingList, responseIsSuccess} from "../../misc/requestSender";
import {navigate, showMessage} from "../../misc/utils";

export default class MailingListController extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            loading: true,
            type: 'all',
            order: 'id',
            orderDir: 'desc',
        };
        this.users = [];
    }

    onSelectType = type => {
        this.changeState({type});
    };

    onGenerateExcel = () => {
        this.changeState({loading: true});
        const workbook = XLSX.utils.book_new();
        const data = [
            ['id', 'email', 'voornaam']
        ];
        for (const user of this.users) {
            data.push([user.id, user.email, user.firstname]);
        }

        const sheet = XLSX.utils.aoa_to_sheet(data);
        XLSX.utils.book_append_sheet(workbook, sheet, 'Blad1');

        const date = toMoment().format('YYYY-MM-DD:HH:mm:ss');
        XLSX.writeFile(workbook, `mailing_users_${date}.xlsx`);
        this.changeState({loading: false});
    };

    onGenerateList = () => {
        this.changeState({loading: true});
        const {type} = this.state;
        const parameters = {
            mailchimp_unsubscribed: 0,
            status: 'ACTIVE',
        };

        if (type !== 'all')
            parameters.type = type;

        generateMailingList({
            parameters, callback: response => {
                this.changeState({loading: false});
                if (responseIsSuccess(response)) {
                    this.changeState({users: response.data.users});
                } else
                    showMessage('Het ophalen van de gebruikers is niet gelukt', null, 'Ophalen niet gelukt');
            }
        });
    };

    onOrder = name => () => {
        const {order, orderDir} = this.state;
        if (name === order) {
            if (orderDir === 'asc') this.changeState({orderDir: 'desc'});
            else this.changeState({orderDir: 'asc'});
        } else this.changeState({order: name, orderDir: 'desc'});
    };

    getUsers = () => {
        const users = this.state.users.slice(), {order, orderDir} = this.state;
        users.sort((userA, userB) => {
            const valueA = userA[order].toLowerCase(), valueB = userB[order].toLowerCase();
            const comparison = valueA.localeCompare(valueB);
            return orderDir === 'desc' ? comparison : comparison * -1;
        });
        this.users = users;
        return users;
    };

    componentDidMount = () => {
        this.mount();
        if (this.state.loading === false) this.changeState({loading: true});
        checkAdminUser({
            callback: response => {
                if (responseIsSuccess(response)) this.changeState({loading: false});
                else navigate('/dashboard');
            }
        })
    };

    componentWillUnmount = () => {
        this.unMount();
    };

    render = () => {
        const {order, orderDir, type, loading} = this.state;
        return <MailingList onSelectType={this.onSelectType} onGenerateExcel={this.onGenerateExcel}
                            onGenerateList={this.onGenerateList} onOrder={this.onOrder} order={order}
                            orderDir={orderDir} type={type} users={this.getUsers()} loading={loading}/>
    };
}