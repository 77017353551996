import React from 'react';

import {isFunction} from 'glob-common-js/lib/utils';

import StateComponent from "./stateComponent";
import MaterialFactory from "../material/materialFactory";
import {materialTypes} from "../material/materialTypes";
import {addCustomEventListener, removeCustomEventListener} from "../../misc/eventDispatcher";

export default class Alert extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            title: '',
            text: '',
            actions: [],
        };
        this.onCloseCallback = null;
    }

    onClose = () => {
        this.changeState({active: false});
        if (isFunction(this.onCloseCallback))
            this.onCloseCallback();
    };

    onActivate = (data) => {
        log('Activating alert', data);
        let {title, text, actions} = data || {};
        title = isNotNull(title) ? title : 'Bericht van ByDeHand';
        actions = actions || this.getActions();
        this.changeState({active: true, title, text, actions});
    };

    getTitle = () => {
        const {title} = this.state;
        return isNotNull(title) ? title : 'Bericht van ByDeHand';
    };

    getActions = () => {
        const {actions} = this.state;
        return isNotNull(actions) ? this.extendActions(actions) : [{label: 'Oke', onClick: this.onClose}];
    };

    extendActions = actions => {
        return actions.map(action => {
            const originalOnClick = action.onClick;
            action.onClick = () => {
                this.onClose();
                if (isFunction(originalOnClick))
                    originalOnClick();
            };
            return action;
        })
    };

    renderContent = () => <p>{this.state.text}</p>;

    componentDidMount = () => {
        this.mount();
        addCustomEventListener('showAlert', this.onActivate);
    };

    componentWillUnmount = () => {
        this.unMount();
        removeCustomEventListener('showAlert', this.onActivate);
    };

    render = () => {
        const {active} = this.state;
        return <MaterialFactory componentType={materialTypes.DIALOG} open={active} title={this.getTitle()}
                                actions={this.getActions()} onClose={this.onClose} content={this.renderContent()}/>
    }
}