import './style/currentInsuranceView_2018.scss';

import React from 'react';
import PropTypes from 'prop-types';
import CurrentInsuranceForm from "../currentInsuranceForm";

const logo = require('../../../../../common/images/logo_bydehand_trans.png');
export default class CurrentInsuranceView_2018 extends React.Component {
    constructor(props) {
        super(props);
    }

    renderExplanationContainer = () => (
        <div className='insuranceExplanationContainer'>
            <div className='introTextContainer'>
                <label className='explanationTitle'>De zorgverzekeringsanalyse</label>
                <p className='introText'>
                    Hieronder kun je direct beginnen met de zorgverzekeringsanalyse van ByDeHand. Binnen een
                    paar minuten weet je:
                </p>
                <label className='listItem'><b>1)</b> Wat voor jou de beste zorgverzekering en verzekeraar in 2020
                    is.</label>
                <label className='listItem'><b>2)</b> Wat voor jou de gewenste dekking moet zijn.</label>
                <label className='listItem'><b>3)</b> Wat de ideale hoogte van je eigen risico is.</label>
                <label className='listItem'><b>4)</b> Hoe de prijs van jouw verzekeraar zich verhoudt tot die van andere
                    verzekeraars.</label>
            </div>
            <div className='fieldsContainer'>
                <div className='formContainer'>
                    <CurrentInsuranceForm {...this.props} withLogin/>
                </div>
                <div className='pictureContainer'>
                    <label className='pictureLabel'>Adriaan (financieel analist)</label>
                    <img src='https://www.bydehand.com/wp-content/uploads/2016/10/IMG_6620-300x270.jpg'
                         className='picture'/>
                    <p className='quote'>
                        “Gemiddeld kan een gezin € 400,- per jaar besparen op verzekeringen. Aan de hand van onze
                        analyse weet je of je huidige verzekeraar nog bij je past, zie je wat jij kan besparen en helpen
                        we je met het maken van de juiste keuze.”
                    </p>
                </div>
            </div>
        </div>
    );

    render = () => {
        return (
            <div className='currentInsuranceView'>
                <div className='currentInsuranceHeader'>
                    <label className='headerText'>Zorgverzekeringsanalyse 2020</label>
                    <img src={logo} className='headerLogo'/>
                </div>
                <div className='currentInsuranceBody'>
                    {this.renderExplanationContainer()}
                </div>
            </div>
        )
    };
}

CurrentInsuranceView_2018.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onSelectInsurer: PropTypes.func.isRequired,
    onSelectBasic: PropTypes.func.isRequired,
    onSelectAdditional: PropTypes.func.isRequired,
    insurers: PropTypes.arrayOf(PropTypes.string).isRequired,
    basics: PropTypes.arrayOf(PropTypes.object).isRequired,
    additionals: PropTypes.arrayOf(PropTypes.object).isRequired,
    dentals: PropTypes.arrayOf(PropTypes.object).isRequired,
    initialSelected: PropTypes.object,
    isInitialLoaded: PropTypes.bool,
    userIsLoggedIn: PropTypes.bool.isRequired,
};
