import React from 'react';

import {getSetting} from 'glob-common-js/lib/utils';
// Pages
import DossierOverviewController from './dossier/dossierOverviewController'
import DashboardContainerController from './dashboard/dashboardContainerController';
import ForgotPasswordController from "./authentication/forgotPassword/forgotPasswordController";
import AddDocumentController from "./document/addDocument/addDocumentController";
import LibraryController from "./library/libraryController";
import FinancialOverviewController from "./financial/financialOverviewController";
import EditAccountController from "./account/edit/editAccountController";
import AccountDetailsController from "./account/accountDetailsController";
import CustomRedirect from "./misc/customRedirect";
import DossierDetailsController from "./dossier/details/dossierDetailsController";
import ChecklistController from './checklist/checklistController';
import AuthenticationController from "./authentication/authenticationController";
import AccountAccessController from "./account/access/accountAccessController";
import OverviewController from "./overview/overviewController";
import HealthAnalysisController from "./insurance/healthAnalysis/healthAnalysisController";
import AnalysisController from "./analysis/analysisController";
import TestMailController from "./temp/mail/testMailController";
import AddTaskController from "./temp/mail/task/addTaskController";
import Designer from "./develop/designer";
import ChecklistPageController from "./checklist/page/checklistPageController";
import ContactPageController from "./contact/page/contactPageController";
import ActionCodesController from "./actionCode/actionCodesController";
import MailingListController from "./mailing/mailingListController";

const mode = getSetting('mode');

const modeRestricted = (component, ...restrictions) => {
    if (restrictions.includes(mode)) return <CustomRedirect to={'/dashboard'}/>;
    return component;
};

const routes = [
    // Authentication
    {
        path: '/auth',
        component: <AuthenticationController/>,
        headerTitle: null,
    },
    {
        path: '/registratie/:pathParam?',
        component: <AuthenticationController/>,
        headerTitle: 'Registreren',
    },
    {
        path: '/login/:loginParam?',
        component: <CustomRedirect to={'/auth/login'}/>,
        headerTitle: 'Inloggen',
    },
    {
        path: '/wachtwoord-vergeten',
        component: <ForgotPasswordController/>,
        headerTitle: 'Wachtwoord vergeten?',
    },
    {
        path: '/wachtwoord-wijzigen/:passwordKey',
        component: <AuthenticationController/>,
        headerTitle: 'Wachtwoord wijzigen',
    },

    // General
    {
        path: '/dashboard',
        component: <DashboardContainerController/>,
        headerTitle: 'Dashboard',
    },
    {
        path: '/start',
        component: <CustomRedirect to={'/dashboard'}/>,
        headerTitle: 'Waar wil je mee beginnen?',
    },

    // Dossier
    {
        path: '/dossier-overzicht',
        component: <DossierOverviewController/>,
        headerTitle: 'Mijn gegevens - Dossiers',
    },
    {
        path: '/dossier-details/:id?',
        component: <DossierDetailsController/>,
        headerTitle: 'Dossier details',
    },
    {
        path: '/overzicht',
        component: <OverviewController/>,
        headerTitle: 'Mijn gegevens - Overzicht',
    },
    // Analysis
    {
        path: '/analyses',
        component: <AnalysisController/>,
        headerTitle: 'Mijn gegevens - Analyses',
    },
    // Documents
    {
        path: '/bibliotheek',
        component: <LibraryController/>,
        headerTitle: 'Mijn gegevens - Bibliotheek',
    },
    {
        path: '/documenten-toevoegen',
        component: <AddDocumentController/>,
        headerTitle: 'Documenten',
    },
    {
        path: '/document-toevoegen/:document_type',
        component: (props) => {
            return null;
        },
        headerTitle: 'Documenten toevoegen',
    },

    // Account
    {
        path: '/account-gegevens',
        component: <AccountDetailsController/>,
        headerTitle: 'Accountgegevens',
    },
    {
        path: '/account-aanpassen',
        component: <EditAccountController/>,
        headerTitle: 'Account aanpassen',
    },
    {
        path: '/machtigingen',
        component: <AccountAccessController/>,
        headerTitle: 'Machtigingen',
    },

    // Financial
    {
        path: '/financieel-overzicht',
        component: <FinancialOverviewController/>,
        headerTitle: 'Financieel overzicht',
    },

    // Misc
    {
        path: '/zorganalyse',
        component: <HealthAnalysisController/>,
        headerTitle: 'Analyse zorgverzekering',
    },
    {
        path: '/quickscanzorgverzekering',
        component: <HealthAnalysisController/>,
        headerTitle: 'Quick scan zorgverzekering',
    },
    {
        path: '/checklists',
        component: <ChecklistPageController/>,
        headerTitle: 'Checklists',
    },
    {
        path: '/reis-checklist',
        component: <ChecklistController mappingFileName='travel'/>,
        headerTitle: 'Reischecklist',
    },
    {
        path: '/auto-checklist',
        component: <ChecklistController mappingFileName='buyCar'/>,
        headerTitle: 'Autochecklist',
    },
    {
        path: '/kind-checklist',
        component: <ChecklistController mappingFileName='child'/>,
        headerTitle: 'Checklist kind krijgen',
    },
    {
        path: '/scheiden-checklist',
        component: <ChecklistController mappingFileName='divorce'/>,
        headerTitle: 'Scheiden',
    },
    {
        path: '/cv-checklist',
        component: <ChecklistController mappingFileName='cv'/>,
        headerTitle: 'Curriculum Vitae opstellen',
    },
    {
        path: '/begrafenis-checklist',
        component: <ChecklistController mappingFileName='funeral'/>,
        headerTitle: 'Begrafenis',
    },
    {
        path: '/lening-checklist',
        component: <ChecklistController mappingFileName='loan'/>,
        headerTitle: 'Lening afsluiten',
    },
    {
        path: '/huis-verkopen-checklist',
        component: <ChecklistController mappingFileName='sellHouse'/>,
        headerTitle: 'Huis verkopen',
    },
    {
        path: '/levenstestament-checklist',
        component: <ChecklistController mappingFileName='LifeTestament'/>,
        headerTitle: 'Levenstestament',
    },
    {
        path: '/mail-systeem',
        component: modeRestricted(<TestMailController/>, 'prod'),
        headerTitle: 'Mail systeem',
    },
    {
        path: '/mail-task',
        component: modeRestricted(<AddTaskController/>, 'prod'),
        headerTitle: 'Taak toevoegen',
    },
    {
        path: '/contacten',
        component: <ContactPageController/>,
        headerTitle: 'Mijn contacten',
    },
    {
        path: '/dev-design',
        component: modeRestricted(<Designer/>, 'prod', 'accept'),
        headerTitle: 'Development design',
    },
    {
        path: '/actie-codes',
        component: <ActionCodesController/>,
        headerTitle: 'Actie codes',
    },
    {
        path: '/mailing-list',
        component: <MailingListController/>,
        headerTitle: 'Mailing list',
    },
    {
        path: '',
        component: <CustomRedirect to={'/dashboard'}/>,
        headerTitle: 'Waar wil je mee beginnen?',
    },
];

export default routes;
