export const JSSCancellation = theme => ({
    container: {
        maxWidth: '30rem',
    },
    header: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
    },
    headerTitle: {
        fontWeight: 500,
        fontSize: '1.5rem',
    },
    closeIcon: {
        position: 'absolute',
        top: '.3rem',
        right: '.3rem',
        fontSize: '.8rem',
        cursor: 'pointer',
    },
    body: {
        padding: '1rem',
    },
    par: {
        marginBottom: '1rem',
    },
    extra: {
        marginTop: '2rem',
    },
    footer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});