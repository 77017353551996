import React from 'react';

import {isFunction, stringContains, stringStartsWith} from 'glob-common-js/lib/utils';

import {addCustomEventListener, removeCustomEventListener} from "../../../../misc/eventDispatcher";
import {getAllDocuments, getDocument, updateDocument} from "../../../../misc/requestSender";
import AddFromLibrary from "./addFromLibrary";
import {sendGaEvent} from "../../../../common/js/ga";

const GA_CATEGORY = 'Bibliotheek koppelen';
export default class AddFromLibraryController extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            queriedDocuments: [],
            selectedDocuments: [],
            loading: false,
            loaderLabel: null,
        };
        this._isMounted = false;
        this.dossierId = null;
        this.dossierFileIds = [];
        this.callback = null;
        this.documents = [];
        this.queryType = 'type';
        this.query = null;
    }

    changeState = stateChange => {
        this._isMounted && this.setState(stateChange);
    };

    activate = params => {
        sendGaEvent(GA_CATEGORY, 'Activeer', 'Open popup');
        const {dossierId, callback, dossierFileIds} = params;
        if (isNotNull(dossierId) && isFunction(callback)) {
            this.dossierId = dossierId;
            this.callback = callback;
            this.dossierFileIds = dossierFileIds;
            this.loadDocuments();
        }
    };

    deactivate = () => {
        sendGaEvent(GA_CATEGORY, 'Deactiveer', 'Popup sluiten');
        this.dossierId = null;
        this.callback = null;
        this.documents = [];
        this.queryType = null;
        this.query = null;
        this.changeState({
            active: false,
            queriedDocuments: [],
            selectedDocuments: [],
            loading: false,
            loaderLabel: null,
        });
    };

    onSelectDocument = documentId => {
        let selectedDocuments = this.state.selectedDocuments;
        if (selectedDocuments.includes(documentId))
            selectedDocuments = selectedDocuments.filter(id => id !== documentId);
        else selectedDocuments.push(documentId);
        this.changeState({selectedDocuments});
    };

    // onSelectDocument = selectedRows => {
    //     sendGaEvent(GA_CATEGORY, 'Selecteer document', 'Selecteer');
    //     let queriedDocuments = this.state.queriedDocuments;
    //     if (selectedRows === 'all') {
    //         this.changeState({selectedDocuments: queriedDocuments.map(doc => doc.id)});
    //     } else {
    //         let selectedDocuments = [];
    //         for (let i = 0; i < selectedRows.length; i++) {
    //             selectedDocuments.push(queriedDocuments[selectedRows[i]].id);
    //         }
    //         this.changeState({selectedDocuments});
    //     }
    //
    //     let input = document.getElementById('addFromLibInput');
    //     if (isNotNull(input)) input.focus();
    // };

    onSelectQueryType = evt => {
        let target = evt.target;
        this.queryType = target.options[target.selectedIndex].value;
        sendGaEvent(GA_CATEGORY, 'Selecteer zoek type', this.queryType);
        this.runQuery();

        let input = document.getElementById('addFromLibInput');
        if (isNotNull(input)) input.focus();
    };

    onEnterQuery = (evt) => {
        this.query = evt.target.value;
        this.runQuery();
    };

    runQuery = () => {
        let queryType = this.queryType;
        let query = this.query;
        let documents = this.documents.slice();
        if (isNotNull(query)) {
            let startsWith = documents.filter(doc => {
                if (queryType === 'type') return stringStartsWith(doc.type, query);
                else if (queryType === 'name') return stringStartsWith(doc.label, query);
            });
            let contains = documents.filter(doc => {
                if (queryType === 'type') return !stringStartsWith(doc.type, query) && stringContains(doc.type, query);
                if (queryType === 'name') return !stringStartsWith(doc.label, query) && stringContains(doc.label, query);
            });
            this.changeState({queriedDocuments: startsWith.concat(contains)});
        } else this.changeState({queriedDocuments: documents});
    };

    onAddDocuments = () => {
        sendGaEvent(GA_CATEGORY, 'Documenten koppelen', 'Opslaan');
        let selectedDocuments = this.state.selectedDocuments;
        if (isNotNull(selectedDocuments)) {
            let loaderLabel = 'Document' + (selectedDocuments.length > 1 ? 'en' : '') + ' koppelen';
            this.changeState({loading: true, loaderLabel});
            const toUpdate = selectedDocuments.length;
            let updated = 0;
            const docUpdated = () => {
                if (++updated === toUpdate) {
                    this.callback();
                    this.deactivate();
                }
            };
            for (let i = 0; i < selectedDocuments.length; i++) {
                getDocument({
                    id: selectedDocuments[i],
                    callback: (response) => {
                        let data = response.data.vault;
                        let dossierId = this.dossierId;
                        data.belonging_ids = data.belongings.map(dossier => dossier.id);
                        if (!data.belonging_ids.includes(dossierId)) {
                            data.belonging_ids.push(dossierId);
                            updateDocument({
                                id: data.id,
                                data,
                                callback: docUpdated,
                            })
                        }
                    }
                })
            }
        }
    };

    loadDocuments = () => {
        this.changeState({loading: true, loaderLabel: 'Documenten laden'});
        getAllDocuments({
            callback: (response) => {
                let documents = response.data.vault.map(doc => ({
                    id: doc.id,
                    label: doc.name,
                    type: doc.type.label,
                })).filter(doc => !this.dossierFileIds.includes(doc.id));
                this.documents = documents;
                this.changeState({active: true, queriedDocuments: documents, loading: false});
            }
        })
    };

    componentDidMount = () => {
        this._isMounted = true;
        addCustomEventListener('addFromLibrary', this.activate);
    };

    componentWillUnmount = () => {
        this._isMounted = false;
        removeCustomEventListener('addFromLibrary', this.activate);
    };

    render = () => {
        if (this.state.active)
            return (
                <AddFromLibrary onSelectDocument={this.onSelectDocument} documents={this.state.queriedDocuments}
                                onSelectQueryType={this.onSelectQueryType} onEnterQuery={this.onEnterQuery}
                                onAddDocuments={this.onAddDocuments} onClose={this.deactivate}
                                selectedDocuments={this.state.selectedDocuments} loading={this.state.loading}
                                loaderLabel={this.state.loaderLabel}/>);
        return null;
    };
}