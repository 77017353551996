import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {getSetting, isFunction} from 'glob-common-js/lib/utils';

import {getById} from '../../common/data/mappings/dossierTypeMapping';
import {deleteDossier} from "../../misc/requestSender";
import {dispatchCustomEvent} from "../../misc/eventDispatcher";

export class DossierButton extends React.Component {
    constructor(props) {
        super(props);
    }

    onClick = evt => {
        if (isFunction(this.props.onClick))
            this.props.onClick(this.props.dossier, evt);
    };

    checkIconDisplay = () => {
        let creatingIcon;
        if (!this.props.fromUser && isNotNull(this.props.dossier.type.id)) {
            creatingIcon = getById(this.props.dossier.type.id).creatingIcon;
        }
        return isNotNull(creatingIcon) ? creatingIcon : this.props.dossier.type.icon;
    };

    deleteDossier = () => {
        if (getSetting('mode') !== 'prod') {
            deleteDossier({
                id: this.props.dossier.id, callback: () => {
                    dispatchCustomEvent('updatePlatform')
                }
            });
        }
    };

    render = () => {
        const {fromUser, onClick} = this.props;
        const className = classNames('dossierButton', fromUser && 'fromUser', isFunction(onClick) && 'clickable');
        return (
            <div className={className} onClick={this.onClick}
                 onContextMenu={this.deleteDossier}>
                <div className='dossierInnerButton'>
                    <div className='iconBlock'>
                        <span className={this.checkIconDisplay() || 'common-icon-home'}/>
                    </div>
                    <div className='title'>
                        <h4>{this.props.dossier.label}</h4>
                    </div>
                </div>
            </div>
        )
    }
}

DossierButton.propTypes = {
    fromUser: PropTypes.bool.isRequired,
    dossier: PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        type: PropTypes.shape({
            icon: PropTypes.string,
        }).isRequired,
    }).isRequired,
    onClick: PropTypes.func,
};

export default DossierButton;