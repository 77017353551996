import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as Actions from '../../../redux/actions';

import {sendGaEvent} from "../../../common/js/ga";
import {sendTender} from "../../../misc/requestSender";
import RequestTenderView from './requestTenderView';
import StateComponent from "../../misc/stateComponent";
import {dispatchCustomEvent} from "../../../misc/eventDispatcher";

export class RequestTenderController extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            submitting: false,
        };
    }

    sendTender = tenderInfo => {
        sendGaEvent('Dossier pagina', 'Offerte button click', 'Versturen');
        this.changeState({submitting: true});
        let data = {
            type: 'tender',
            contact_method: "mail",
            metadata: tenderInfo.metadata,
            user: tenderInfo.user,
        };
        sendTender({
            dossierId: this.props.dossier.id,
            data,
            callback: () => {
                this.props.actions.resetTenderInfo();
                dispatchCustomEvent('openSnackbar', {text: 'De offerte aanvraag is verstuurd'});
                let selectedTabButton = document.querySelector('div.tabButton.selected');
                if (isNotNull(selectedTabButton)) {
                    selectedTabButton.classList.remove('selected');
                }
            }
        });
    };

    componentDidMount = () => {
        this.mount();
    };

    componentWillUnmount = () => {
        this.unMount();
    };

    render = () => (
        <RequestTenderView onSubmit={this.sendTender} tenderInfo={this.props.tenderInfo}
                           dossier={this.props.dossier} submitting={this.state.submitting}/>
    );
}

RequestTenderController.propTypes = {
    dossier: PropTypes.object.isRequired,
    tenderInfo: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});

export default connect(null, mapDispatchToProps)(RequestTenderController);