import './style/contactPage.scss';

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {Edit, Delete, MoreHoriz} from '@material-ui/icons';

import {Contact} from "../../../models/contact";
import BdhSelectController from "../../customControls/select/bdhSelectController";
import StateComponent from "../../misc/stateComponent";
import MaterialFactory from "../../material/materialFactory";
import {materialTypes} from "../../material/materialTypes";
import BdhSearchInput from "../../customControls/input/bdhSearchInput";
import CustomScrollbar from "../../misc/customScrollbar";

export default class ContactPageView extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            menuAnchor: null,
            openedByAction: false,
            menuOpen: false,
        };
        this.clickedContact = {};
    }

    onCloseMenu = () => {
        this.changeState({menuOpen: false});
    };

    onOpenMenu = contact => evt => {
        evt.stopPropagation();
        this.clickedContact = contact;
        this.changeState({menuAnchor: evt.currentTarget, menuOpen: true, openedByAction: contact === null});
    };

    onEdit = () => {
        this.onCloseMenu();
        const {selectedContacts, onEdit} = this.props;
        if (isNull(selectedContacts))
            onEdit(this.clickedContact.id);
        else if (selectedContacts.length === 1)
            onEdit(selectedContacts[0]);
    };

    onDelete = () => {
        this.onCloseMenu();
        const {selectedContacts, onDelete} = this.props;
        if (isNull(selectedContacts))
            onDelete(this.clickedContact.id);
        else onDelete(selectedContacts);
    };

    onSelectContact = contact => evt => {
        const {onSelectContact} = this.props;
        evt.stopPropagation();
        this.clickedContact = {};
        onSelectContact(contact);
    };

    onClickCheck = evt => {
        evt.stopPropagation();
        // evt.preventDefault();
    };

    onClearSearch = () => {
        this.props.onSearch('');
    };

    onClickContact = contact => () => {
        this.props.onEdit(contact.id);
    };

    getSortItems = () => ([
        {label: 'Accountgegevens', value: 'accounts'},
        {label: 'Datum toegevoegd', value: 'createdAt'},
        {label: 'Gekoppeld aan', value: 'dossiers'},
        {label: 'Naam', value: 'name'},
        {label: 'Telefoonnummer', value: 'phone'},
    ]);

    getContactValue = (contact, key) => {
        if (isNotNull(contact[key])) return contact[key];
        return '-';
    };

    getAccountCount = contact => {
        const count = contact.accounts.length;
        if (count === 0) return '-';
        if (count === 1) return '1 account';
        return `${count} accounts`;
    };

    getDossierCount = contact => {
        const count = contact.dossiers.length;
        if (count === 0) return '-';
        if (count === 1) return '1 dossier';
        return `${count} dossiers`;
    };

    renderCountRow = () => {
        const {contacts, onSort, order} = this.props, count = contacts.length,
            countText = `${count} ${count === 1 ? 'contact' : 'contacten'}`;
        return (
            <div className='countRow'>
                <label className='contactCount'>{countText}</label>
                <BdhSelectController onSelect={onSort} value={[order]} items={this.getSortItems()} variant='contained'
                                     label='Sorteren op' readOnly/>
            </div>
        )
    };

    renderActionRow = () => {
        const {selectedContacts, onNewContact, onSearch, searchValue} = this.props;
        return (
            <div className='actionRow'>
                <div className='actionButtons'>
                    <MaterialFactory componentType={materialTypes.RAISED_BUTTON} disabled={isNull(selectedContacts)}
                                     onClick={this.onOpenMenu(null)} className='actionButton'>Acties</MaterialFactory>
                    <MaterialFactory componentType={materialTypes.RAISED_BUTTON} onClick={onNewContact}>
                        Voeg contact toe</MaterialFactory>
                </div>
                <BdhSearchInput onChange={onSearch} value={searchValue} onClearSearch={this.onClearSearch}
                                label='Zoeken'/>
            </div>
        )
    };

    renderActionsMenu = () => {
        const {menuAnchor, menuOpen, openedByAction} = this.state, {selectedContacts} = this.props;
        const name = 'Contact' + (openedByAction && selectedContacts.length > 1 ? 'en' : '');
        return (
            <MaterialFactory componentType={materialTypes.MENU} open={menuOpen} anchorEl={menuAnchor}
                             onClose={this.onCloseMenu}>
                <MaterialFactory componentType={materialTypes.MENU_ITEM}
                                 onClick={this.onEdit} disabled={openedByAction && selectedContacts.length > 1}>
                    <ListItemIcon><Edit/></ListItemIcon>
                    <ListItemText inset>Contact aanpassen</ListItemText>
                </MaterialFactory>
                <MaterialFactory componentType={materialTypes.MENU_ITEM}
                                 onClick={this.onDelete}>
                    <ListItemIcon><Delete/></ListItemIcon>
                    <ListItemText inset>{name} verwijderen</ListItemText>
                </MaterialFactory>
            </MaterialFactory>
        )
    };

    renderContactsTable = () => {
        return (
            <div className='contactsTable'>
                {this.renderTableHeader()}
                {this.renderTableBody()}
            </div>
        )
    };

    renderTableHeader = () => (
        <div className='row header'>
            <div className='check column'/>
            <div className='name column'>
                <label className='cell'>Naam</label>
            </div>
            <div className='phone column'>
                <label className='cell'>Telefoonnummer</label>
            </div>
            <div className='created column'>
                <label className='cell'>Datum toegevoegd</label>
            </div>
            <div className='account column'>
                <label className='cell'>Accountgegevens</label>
            </div>
            <div className='dossier column'>
                <label className='cell'>Gekoppeld aan</label>
            </div>
            <div className='action column'/>
        </div>
    );

    renderTableBody = () => {
        const {contacts, selectedContacts, onClickAccount} = this.props;
        return (
            <CustomScrollbar height='25rem'>
                {contacts.map((contact, key) => (
                    <div key={key} className='row' onClick={this.onClickContact(contact)}>
                        <div className='check column'>
                            <MaterialFactory componentType={materialTypes.CHECKBOX}
                                             checked={selectedContacts.includes(contact.id)}
                                             onChange={this.onSelectContact(contact)} onClick={this.onClickCheck}/>
                        </div>
                        <div className='name column'>
                            <label className='cell'
                                   title={contact.name}>{this.getContactValue(contact, 'name')}</label>
                        </div>
                        <div className='phone column'>
                            <label className='cell'
                                   title={contact.phone}>{this.getContactValue(contact, 'phone')}</label>
                        </div>
                        <div className='created column'>
                            <label className='cell' title={contact.createdAt}>{contact.createdAt}</label>
                        </div>
                        <div className='account column' onClick={onClickAccount(contact)}>
                            <label className={classNames('cell', contact.accounts.length && 'hasAccounts')}
                                   title={this.getAccountCount(contact)}>{this.getAccountCount(contact)}</label>
                        </div>
                        <div className='dossier column'>
                            <label className='cell'
                                   title={this.getDossierCount(contact)}>{this.getDossierCount(contact)}</label>
                        </div>
                        <div className='action column'>
                            <MoreHoriz onClick={this.onOpenMenu(contact)} className='moreButton'/>
                        </div>
                    </div>
                ))}
            </CustomScrollbar>
        )
    };

    componentDidMount = () => {
        this.mount();
    };

    componentWillUnmount = () => {
        this.unMount();
    };

    render = () => {
        return (
            <>
                {this.renderActionsMenu()}
                <div className='contactPage'>
                    {this.renderCountRow()}
                    {this.renderActionRow()}
                    {this.renderContactsTable()}
                </div>
            </>
        )
    };
};

ContactPageView.propTypes = {
    onSort: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onNewContact: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    onSelectContact: PropTypes.func.isRequired,
    onClickAccount: PropTypes.func.isRequired,
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(Contact)).isRequired,
    selectedContacts: PropTypes.arrayOf(PropTypes.string).isRequired,
    order: PropTypes.oneOf(['name', 'phone', 'createdAt', 'accounts', 'dossiers']).isRequired,
    searchValue: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
};