import {warn, getSetting, debug} from 'glob-common-js/lib/utils';

const listeners = {
    DOSSIER: {
        CREATE: [],
        UPDATE: [],
        DELETE: [],
    },
    DOCUMENT: {
        CREATE: [],
        UPDATE: [],
        DELETE: [],
    },
    CONTACT: {
        CREATE: [],
        UPDATE: [],
        DELETE: [],
    },
    ACCOUNT: {
        CREATE: [],
        UPDATE: [],
        DELETE: [],
    },
    CODE: {
        CREATE: [],
        UPDATE: [],
        DELETE: [],
    }
};

export const DATA_TYPES = {
    DOSSIER: 'DOSSIER',
    DOCUMENT: 'DOCUMENT',
    CONTACT: 'CONTACT',
    ACCOUNT: 'ACCOUNT',
    CODE: 'CODE',
}, EVENT_TYPES = {
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
};

export const addDataEventListener = (dataTypes, eventTypes, name, listener) => {
    if (!Array.isArray(dataTypes)) dataTypes = [dataTypes];
    if (!Array.isArray(eventTypes)) eventTypes = [eventTypes];
    for (let dataType of dataTypes)
        for (let eventType of eventTypes)
            addInternalDataListener(dataType, eventType, name, listener);
};

const addInternalDataListener = (dataType, eventType, name, listener) => {
    if (listeners.hasOwnProperty(dataType)) {
        const dataListeners = listeners[dataType];
        if (dataListeners.hasOwnProperty(eventType)) {
            const eventListeners = dataListeners[eventType];
            for (let eventListener of eventListeners) {
                if (eventListener.listener === listener && eventListener.name === name) {
                    warn('Trying to add an already existing data event listener.', {
                        dataType,
                        eventType,
                        listener
                    });
                    return;
                }
            }
            eventListeners.push({name, listener});
        } else warn(`Trying to add a data event listener to a non-existing event type: ${eventType}.`);
    } else warn(`Trying to add a data event listener to a non-existing data type: ${dataType}.`);
};

export const removeDataEventListeners = (dataTypes, eventTypes, name, listener) => {
    for (let dataType of dataTypes)
        for (let eventType of eventTypes)
            removeDataEventListener(dataType, eventType, name, listener);
};

export const removeDataEventListener = (dataType, eventType, name, listener) => {
    if (listeners.hasOwnProperty(dataType)) {
        const dataListeners = listeners[dataType];
        if (dataListeners.hasOwnProperty(eventType)) {
            dataListeners[eventType] = dataListeners[eventType].filter(
                dataListener => dataListener.name !== name && dataListener.listener !== listener);
        } else warn(`Trying to remove a data event listener from a non-existing event type: ${eventType}.`);
    } else warn(`Trying to remove a data event listener from a non-existing data type: ${dataType}.`);
};

export const removeAllDataEventListeners = (name, listener) => {
    const dataKeys = Object.keys(DATA_TYPES), eventKeys = Object.keys(EVENT_TYPES);
    for (let dataKey of dataKeys)
        for (let eventKey of eventKeys)
            removeDataEventListener(dataKey, eventKey, name, listener);
};

export const dispatchDataEvent = (dataType, eventType, params) => {
    debug('Dispatching new data event', {dataType, eventType, params});
    if (listeners.hasOwnProperty(dataType) && listeners[dataType].hasOwnProperty(eventType))
        for (let dataListener of listeners[dataType][eventType])
            dataListener.listener(params, eventType, dataType);
    else warn('Trying to dispatch a data event with an invalid dataType or eventType.', {dataType, eventType});
};

export const dispatchDossierEvent = (eventType, dossier) => {
    dispatchDataEvent(DATA_TYPES.DOSSIER, eventType, dossier);
};

export const dispatchDocumentEvent = (eventType, doc) => {
    dispatchDataEvent(DATA_TYPES.DOCUMENT, eventType, doc);
};

export const dispatchContactEvent = (eventType, contact) => {
    dispatchDataEvent(DATA_TYPES.CONTACT, eventType, contact);
};

export const dispatchAccountEvent = (eventType, account) => {
    dispatchDataEvent(DATA_TYPES.ACCOUNT, eventType, account);
};

export const dispatchActionCodeEvent = (eventType, code) => {
    dispatchDataEvent(DATA_TYPES.CODE, eventType, code);
};

if (getSetting('mode') !== 'prod')
    window.logDataListeners = () => {
        const dataKeys = Object.keys(DATA_TYPES), eventKeys = Object.keys(EVENT_TYPES);
        const log = [];
        for (let dataKey of dataKeys)
            for (let eventKey of eventKeys)
                for (let listener of listeners[dataKey][eventKey])
                    log.push({dataKey, eventKey, listener: listener});
        return log;
    };