import './homeInsurance.scss'
import React from 'react';
import {connect} from "react-redux";
import PriceCompareView from "../../../../common/components/priceCompareView";


export class HomeInsurance extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedYear: null,
            graphData: null,
        };

    }

    renderOpstalFlow = (dossier) => {
        let linkedDocTypes = dossier.files.map((dossier) => (dossier.type.id));
        let opstalDocId = '9d6c2274-8332-4508-9c08-fe36132887fc';
        let user = this.props.userState.user;
        if (linkedDocTypes.includes(opstalDocId)) {
            if (isNotNull(user.dob)) {
                return (<p>ANALYZE FLOW</p>)
            } else {
                return (<p>Voer eerst uw geboortedatum in.</p>)
            }
        } else {
            return (<p>Upload eerst uw opstalverzekeringspolis</p>)
        }
    };

    render = () => {
        let price = 250;
        let prices = [297,222,321,276,285,301,248,238,341,346,243,257,155,165,229,209,248,238];
        return (
            <div className='dossierHomeInsuranceContainer'>
                {isNotNull(prices) ? <PriceCompareView compareName={'Verzekering'} prices={prices}
                                                       myPrice={price}/> : this.renderOpstalFlow(this.props.dossierState.dossier)}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    userState: state.user,
    dossierState: state.dossier,
});

export default connect(mapStateToProps)(HomeInsurance);
