import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as Actions from '../redux/actions';
import {MuiPickersUtilsProvider} from 'material-ui-pickers';
import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import moment from 'moment';

import {deviceIsMobile, getSetting, pathIsNotNull} from 'glob-common-js/lib/utils';

import {sendGaEvent} from "../common/js/ga";
import ErrorBoundary from './errorBoundary';
import routes from './routes';
import Navigator from './misc/navigator';
import * as daisyUtils from '../misc/utils';
import {browserIsSupported, checkOnboardingDecision, isGrantedLogin, navigate, setReduxUser} from '../misc/utils';
import appProperties from './appProperties';
import {handleAppAction} from "../misc/utils/appUtil";
import getCategoryType from "../common/data/mappings/categoryMapping";
import {navigationMapping} from "./sidemenu/navigationMapping";
import {dispatchCustomEvent} from "../misc/eventDispatcher";
import CustomRedirect from './misc/customRedirect';
import PopupController from "./misc/popupController";
import {steps} from "./subscription/registrationConstants";
import MaterialFactory from "./material/materialFactory";
import {materialTypes} from "./material/materialTypes";
import {NOW} from "./misc/constants";
import MaterialMenuController from "./material/menu/materialMenuController";
import {getDaisyUser, getGrantedUser, getUserAccess, responseIsSuccess} from "../misc/requestSender";
import {getJwt, getJwtToken} from "../common/js/platform";

// Ignore the login check when navigating to the following urls. Being logged in is not required for these pages
export const ignoreLoginCheckUrls = [
    '/login/:loginParam?',
    '/registratie/:pathParam?',
    '/zorganalyse',
    '/test',
    '/wachtwoord-wijzigen/:passwordKey',
    '/wachtwoord-vergeten',
    '/auth',
    '/quickscanzorgverzekering',
    '/mail-systeem',
    '/mail-task',
];

// export const freeBeforeDate = moment('2018-04-25', timeFormats);

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            headerButtons: appProperties.headerButtons,
            loggedIn: false,
            activateGroupSelection: false,
            pageTitle: 'ByDeHand',
        };
    }

    changeState = (stateChange) => {
        if (this._isMounted) {
            this.setState(stateChange);
        }
    };

    navigate = url => {
        isNotNull(this.router) ? this.router.history.push(url) : null;
    };

    createRoute = ({path, component, headerTitle, description, keywords}, key) => {
        return (
            <Route key={key} path={path} render={() => {
                if (ignoreLoginCheckUrls.indexOf(path) > -1 || isNotNull(getJwtToken())
                    || path.indexOf('/zorganalyse') > -1) {
                    this.setPageTitle(headerTitle);
                    return component;
                } else {
                    let stateUser = this.props.userState.user;
                    if (isNotNull(stateUser) && (stateUser.firstname !== '' || stateUser.email !== '')) {
                        this.props.actions.setUser(null);
                    }
                    let redirectPath = path.indexOf('/zorganalyse') > -1 && appProperties.showHealthAnlysis ?
                        path.indexOf('huidige-verzekering') > -1 ? path : '/zorganalyse' : '/auth/login';
                    return <CustomRedirect to={redirectPath}/>;
                }
            }}/>
        );
    };

    setPageTitle = (title) => {
        let element = document.getElementById('pageTitle');
        if (isNotNull(element)) {
            let pageTitle = title;
            let selectedModule = this.props.moduleState.selectedModule;
            if (isNotNull(selectedModule) && !deviceIsMobile()) {
                pageTitle = pageTitle + '\xa0\xa0\xa0 > \xa0\xa0\xa0 ' + getCategoryType(selectedModule).label;
            }
            element.innerHTML = pageTitle;

            let iconElement = document.querySelector('span.pageTitleIcon');
            if (isNotNull(iconElement)) {
                iconElement.className = 'pageTitleIcon ' + this.getPageIconClass(title);
            }
        }
    };

    getPageIconClass = (title) => {
        if (isNotNull(title)) {
            if (title === 'Accountgegevens' || title === 'Account aanpassen') title = 'Account';
            for (let i = 0; i < navigationMapping.length; i++) {
                if (navigationMapping[i].label === title) return navigationMapping[i].icon;
            }
        }
        return '';
    };

    tryAutoLogin = () => {
        let originalLocation = this.router.history.location.pathname;
        daisyUtils.isUserLoggedIn(user => {
            let device = window.isAndroid ? 'Android' : window.isIos ? 'iOS' : 'Website';
            sendGaEvent(device, 'Inloggen', 'Completed Auto');
            this.loadGrantedUser(() => {
                this.userIsValidated(originalLocation, user)
            });
        }, originalLocation);
    };

    checkSubscription = (platformUser) => {
        this.userHasBirthday(platformUser, () => {
            getUserAccess({
                callback: response => {
                    if (responseIsSuccess(response)) {
                        const {access, user} = response.data;
                        if (!access && getSetting('enableSubscription')) {
                            this.props.actions.setSubscription(user);
                            this.navigateToRegistration(steps.ACTIVATION.index, steps.ACTIVATION.PAYMENT_INTRO,
                                platformUser, {isDefault: true});
                        } else
                            this.props.actions.setSubscription(user);
                    }
                }
            });
        })
    };


    userHasBirthday = (platformUser, callback) => {
        getDaisyUser({
            callback: response => {
                if (responseIsSuccess(response)) {
                    const {user} = response.data;
                    if (pathIsNotNull(user, 'birthday')) callback(user);
                    else this.openBirthdayPopup(platformUser);
                }
            }
        });
    };

    openBirthdayPopup = (user) => {
        dispatchCustomEvent('addBirthday', {
            user,
            callback: (birthday) => {
                if (Math.abs(NOW().diff(birthday, 'years')) > 25) {
                    getUserAccess({
                        callback: response => {
                            if (responseIsSuccess(response)) {
                                const {access} = response.data;
                                let step, sub;
                                if (access) {
                                    step = steps.COMPLETION.index;
                                    sub = steps.COMPLETION.DEFAULT;
                                } else {
                                    step = steps.ACTIVATION.index;
                                    sub = steps.ACTIVATION.PAYMENT_INTRO;
                                }
                                this.navigateToRegistration(step, sub, user, {isDefault: true});
                            }
                        }
                    });
                } else {
                    this.navigateToRegistration(steps.COMPLETION.index, steps.COMPLETION.FREE, user, {birthday: true});
                }
            }
        })
    };

    navigateToRegistration = (step, subStep, user, flex) => {
        this.props.actions.setUser(null);
        navigate('/registratie');
        window.setTimeout(() => {
            dispatchCustomEvent('activateRegistration', {
                step,
                subStep,
                user,
                flex,
            });
        }, 50);
    };

    userSubscriptionIsValid = user => pathIsNotNull(user, 'subscription.period.end') &&
        NOW().isSameOrBefore(moment(user.subscription.period.end), 'day');

    userIsValidated = (originalLocation, user) => {
        setReduxUser(user, true);
        this.setState({loggedIn: true});
        if (['/registratie', '/'].includes(originalLocation) || originalLocation.includes('/login')) {
            // Only navigate to the dashboard when the user is not on another platform page
            this.navigate('/dashboard');
        }
        checkOnboardingDecision(user);
    };

    loadGrantedUser = (callback) => {
        if (isGrantedLogin()) {
            getGrantedUser({
                callback: response => {
                    const user = response.data;
                    this.props.actions.setGrantedUser(user);
                    callback();
                }
            });
        } else callback();
    };

    clickListener = evt => {
        const isRegistration = location.pathname.includes('registratie');
        const excludeElements = [
            document.getElementById('menuLogout'),
            document.getElementById('generalNavigator'),
        ];
        if (!excludeElements.includes(evt.target) && isNull(document.querySelector('div.addBirthday')) &&
            !isRegistration) {
            const user = this.props.userState.user;
            if (pathIsNotNull(user, 'id')) {
                if (isNull(this.props.userState.subscription)) {
                    this.checkSubscription(user);
                }
            }
        }
    };

    messageListener = evt => {
        let data = JSON.parse(evt.data);
        if (data.type === 'APP_ACTION') {
            handleAppAction(data);
        }
    };

    includeExternalLinkListener = () => {
        window.externalLink = url => {
            dispatchCustomEvent('externalLink', url);
        };
    };

    includeListeners = () => {
        this.includeExternalLinkListener();
        document.addEventListener('message', this.messageListener);
        document.addEventListener('click', this.clickListener);
    };

    removeListeners = () => {
        document.removeEventListener('message', this.messageListener);
        document.removeEventListener('click', this.clickListener);
    };

    componentDidUpdate = (prevProps) => {
        let prevUser = prevProps.userState.user || {};
        let user = this.props.userState.user || {};

        if (isNotNull(user.id) && prevUser.id !== user.id) {
            this.setState({loggedIn: true});
        }
    };

    componentDidMount = () => {
        this._isMounted = true;
        this.includeListeners();

        const pathName = window.location.pathname;
        if (!pathName.includes('auth') && !pathName.includes('registratie') && !pathName.includes('registreren'))
            this.tryAutoLogin();
        browserIsSupported();
    };

    componentWillUnmount = () => {
        this._isMounted = false;
        this.removeListeners();
    };

    render = () => {
        return (
            <BrowserRouter ref={(router) => (this.router = router)} basename={getSetting('appBaseUrl')}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <MaterialFactory componentType={materialTypes.THEME_PROVIDER}>
                        <Navigator/>
                        <MaterialMenuController>
                            <ErrorBoundary>
                                <Switch>
                                    {routes.map(this.createRoute)}
                                </Switch>
                            </ErrorBoundary>
                            <PopupController/>
                            <div className='buildNumber'>{window.versionDate}</div>
                        </MaterialMenuController>
                    </MaterialFactory>
                </MuiPickersUtilsProvider>
            </BrowserRouter>
        );
    };
}


const mapStateToProps = state => ({
    userState: state.user,
    moduleState: state.module,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
