import './style/storeOnboarding.scss';

import React from 'react';
import PropTypes from 'prop-types';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import StepLabel from '@material-ui/core/StepLabel';

import StateComponent from "../../misc/stateComponent";
import {storeOnboardingSteps} from "../manager/storeManager";
import MaterialFactory from "../../material/materialFactory";
import {materialTypes} from "../../material/materialTypes";
import getDossierType from "../../../common/data/mappings/dossierTypeMapping";
import DossierButton from "../../dossier/dossierButton";
import {navigate} from "../../../misc/utils";
import {getOnboardingManager} from "../manager/onboardingManagerHelper";
import {appear} from "../onboardingController_v2";

export default class StoreOnboardingView extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            dossierName: '',
            zipcode: '',
            houseNumber: '',
            license: '',
            zipError: null,
            numberError: null,
            licenseError: null,
            hidePopup: false,
            submitDisabled: false,
        };
    }

    onChangeDossierName = evt => {
        this.changeState({dossierName: evt.target.value});
    };

    onSubmit = () => {
        const {onSubmitDossier, dossierType} = this.props;
        const {dossierName, zipcode, houseNumber, license} = this.state;
        let validExtra = true;
        if (dossierType === 'house')
            validExtra = this.validateZipcode() && this.validateHouseNumber();
        else if (dossierType === 'car') {
            validExtra = this.validateLicense()
        }
        if (validExtra) {
            this.changeState({submitDisabled: true});
            getOnboardingManager('store').sendEvent('Klik', 'Dossier opslaan');
            onSubmitDossier(dossierName, zipcode, houseNumber, license);
        }
    };

    onChangeExtraField = name => evt => {
        this.changeState({[name]: evt.target.value});
    };

    onFocusExtraField = name => () => {
        if (isNotNull(this.state[name]))
            this.changeState({[name]: null});
    };

    onFocusNameField = () => {
        const {dossierName} = this.state;
        const {dossierType} = this.props;
        const dossierTypeObj = getDossierType(dossierType);
        const nameToMatch = dossierType === 'personal_hobby' ? 'Mijn hobby' : `Mijn ${dossierTypeObj.label.toLowerCase()}`;
        if (dossierName.toLowerCase() === nameToMatch.toLowerCase())
            this.changeState({dossierName: ''});
    };

    onBlurNameField = () => {
        const {dossierName} = this.state;
        const {dossierType} = this.props;
        if (isNull(dossierName)) {
            const dossierTypeObj = getDossierType(dossierType);
            const dossierName = dossierType === 'personal_hobby' ? 'Mijn hobby' : `Mijn ${dossierTypeObj.label.toLowerCase()}`;
            this.changeState({dossierName});
        }
    };

    onNextStepWithGa = (action, label, removeHighlights = false) => () => {
        getOnboardingManager('store').sendEvent(action, label);
        this.props.onNextStep(removeHighlights)();
    };

    onCloseAddDossier = () => {
        getOnboardingManager('store').setPrevStep();
    };

    onHide = () => {
        this.changeState({hidePopup: true});
    };

    onShow = () => {
        this.changeState({hidePopup: false});
    };

    onProceedDocuments = () => {
        this.onNextStepWithGa('Verder', 'Document toegevoegd',
            true)();
    };

    onSkipDocument = () => {
        const manager = getOnboardingManager('store');
        manager.removeAllHighlights();
        manager.setStep(storeOnboardingSteps.EXPLAIN_DETAILS);
    };

    validateZipcode = () => {
        let {zipcode} = this.state;
        zipcode = zipcode.trim().replace(/ /g, '');
        const valid = /^[1-9][0-9]{3}[\s]?[A-Za-z]{2}$/i.test(zipcode);
        if (!valid) this.changeState({zipError: 'Geen geldige postcode'});
        return valid;
    };

    validateHouseNumber = () => {
        const {houseNumber} = this.state;
        const valid = isNotNull(houseNumber);
        if (!valid) this.changeState({numberError: 'Vul je huisnummer in'});
        return valid;
    };

    validateLicense = () => {
        const {license} = this.state;
        const valid = isNotNull(license);
        if (!valid) this.changeState({licenseError: 'Vul je kenteken in'});
        return valid;
    };

    toDossier = () => {
        getOnboardingManager('store').sendEvent('Klik', 'Naar dossier');
        this.changeState({hidePopup: true});
        navigate('/dossier-details');
    };

    renderStep = () => {
        const {step} = this.props;
        switch (step) {
            case storeOnboardingSteps.INTRO_STEP:
            case storeOnboardingSteps.ADD_DOSSIER:
            case storeOnboardingSteps.CONFIRM_DOSSIER:
                return this.renderStepsPopup();
            case storeOnboardingSteps.SELECT_DOSSIER:
                return this.renderSelectDossier();
            case storeOnboardingSteps.SELECT_DOCUMENT:
                return this.renderSelectDocument();
            case storeOnboardingSteps.CONFIRM_DOCUMENT:
                return this.renderConfirmDocument();
            case storeOnboardingSteps.EXPLAIN_DETAILS:
                return this.renderDossierDetails();
            case storeOnboardingSteps.NOTIFY_HELP:
                return this.renderNotifyHelp();
            default:
                return null;
        }
    };

    renderStepsPopup = () => {
        if (this.state.hidePopup) return null;
        const {step} = this.props;
        const steps = {
            [storeOnboardingSteps.INTRO_STEP]: 0,
            [storeOnboardingSteps.ADD_DOSSIER]: 1,
            [storeOnboardingSteps.CONFIRM_DOSSIER]: 1
        };
        appear('storeOnboardingContainer');
        return (
            <div className='onboardingContent' id='storeOnboardingContainer'>
                <div className='storeOnboardingPopup fw' id='storeOnboardingPopup'>
                    <div className='onboardingHeader'>
                        <label className='headerTitle'>Overzichtelijk documenten bewaren</label>
                        {step === storeOnboardingSteps.ADD_DOSSIER && this.renderCloseButton()}
                    </div>
                    <div className='onboardingBody'>
                        <Stepper orientation='vertical' activeStep={steps[step]}>
                            <Step>
                                <StepLabel>Introductie</StepLabel>
                                <StepContent>
                                    <div className='onboardingIntroContainer'>
                                        <p>
                                            Dossiers zijn persoonlijke digitale mappen waar je alle belangrijke
                                            documenten
                                            in kunt bewaren.
                                        </p>
                                        <p>
                                            Klik op <i>Dossier maken</i> en wij nemen je de eerst keer graag even
                                            ByDeHand.
                                        </p>
                                        <MaterialFactory componentType={materialTypes.RAISED_BUTTON}
                                                         className='introBtn'
                                                         onClick={this.onNextStepWithGa('Klik', 'Dossier maken')}>
                                            Dossier maken</MaterialFactory>
                                    </div>
                                </StepContent>
                            </Step>
                            <Step>
                                <StepLabel>Toevoegen</StepLabel>
                                <StepContent>
                                    <div className='onboardingDossierContainer'>
                                        {step === storeOnboardingSteps.ADD_DOSSIER ? this.renderAddDossier() :
                                            this.renderConfirmDossier()}
                                    </div>
                                </StepContent>
                            </Step>
                            <Step>
                                <StepLabel>Klaar</StepLabel>
                            </Step>
                        </Stepper>
                    </div>
                </div>
            </div>
        )
    };

    renderCloseButton = () => {
        return (
            <span className='common-icon-cross closeButton' onClick={this.onCloseAddDossier}/>
        )
    };

    renderAddDossier = () => {
        const {dossierType} = this.props;
        const {dossierName, submitDisabled} = this.state;
        const dossierTypeObj = getDossierType(dossierType);
        const dossier = {
            id: dossierTypeObj.id,
            label: dossierTypeObj.label,
            type: dossierTypeObj,
        };
        return (
            <div className='onboardingAddDossier'>
                <label className='headerTitle'>Geef het dossier een herkenbare naam</label>
                <div className='onboardingAddDossierContainer'>
                    <div className='row'>
                        <div className='col'>
                            <label className='dossierTypeLabel'>Dossier {getDossierType(dossierType).label}</label>
                            <MaterialFactory componentType={materialTypes.TEXT} onChange={this.onChangeDossierName}
                                             label='Herkenbare dossier naam' value={dossierName}
                                             onFocus={this.onFocusNameField} onBlur={this.onBlurNameField}/>
                            {this.renderExtraFields()}
                        </div>
                        <div className='col sm onboardingDossierButton'>
                            <DossierButton fromUser={false} dossier={dossier}/>
                        </div>
                    </div>
                    <MaterialFactory componentType={materialTypes.RAISED_BUTTON} className='btn'
                                     disabled={isNull(dossierName) || submitDisabled}
                                     onClick={this.onSubmit}>Opslaan</MaterialFactory>
                </div>
            </div>
        )
    };

    renderExtraFields = () => {
        const {dossierType} = this.props;
        if (dossierType === 'house') {
            const {zipcode, houseNumber, zipError, numberError} = this.state;
            return (
                <>
                    <MaterialFactory componentType={materialTypes.TEXT} value={zipcode} label='Postcode'
                                     onFocus={this.onFocusExtraField('zipError')}
                                     onChange={this.onChangeExtraField('zipcode')}
                                     helperText={zipError} error={isNotNull(zipError)}/>
                    <MaterialFactory componentType={materialTypes.TEXT} type='number' value={houseNumber}
                                     label='Huisnummer' onFocus={this.onFocusExtraField('numberError')}
                                     onChange={this.onChangeExtraField('houseNumber')}
                                     helperText={numberError} error={isNotNull(numberError)}/>
                </>
            )
        } else if (dossierType === 'car') {
            const {license, licenseError} = this.state;
            return <MaterialFactory componentType={materialTypes.TEXT} value={license} label='Kenteken'
                                    onFocus={this.onFocusExtraField('licenseError')}
                                    onChange={this.onChangeExtraField('license')}
                                    helperText={licenseError} error={isNotNull(licenseError)}/>
        }
        return null;
    };

    renderConfirmDossier = () => {
        const {dossierName} = this.props;
        return (
            <div className='onboardingConfirmDossier'>
                <label className='headerTitle'>Jouw dossier</label>
                <label className='headerSubTitle'>Je dossier '{dossierName}' is aangemaakt!</label>
                <MaterialFactory componentType={materialTypes.RAISED_BUTTON} onClick={this.toDossier} size='small'>Naar
                    dossier</MaterialFactory>
            </div>
        )
    };

    renderSelectDossier = () => {
        return (
            <div className='onboardingContent'>
                <div className='storeOnboardingPopup absolute sbr' id='storeOnboardingPopup'>
                    <div className='onboardingHeader lp'>
                        <label className='headerTitle'>Dossiers</label>
                    </div>
                    <div className='onboardingBody padded'>
                        <p className='no_wrap'>Klik op het dossier dat je aan wilt maken</p>
                    </div>
                </div>
            </div>
        );
    };

    renderSelectDocument = () => {
        getOnboardingManager('store').highlightElement('dossierDetailsDocuments');
        appear('onboardingSelectDocuments');
        return (
            <div className='onboardingContent start' id='onboardingSelectDocuments'>
                <div className='storeOnboardingPopup selectDocument absolute sbr' id='storeOnboardingPopup'>
                    <div className='onboardingHeader lp'>
                        <label className='headerTitle'>Document toevoegen</label>
                    </div>
                    <div className='onboardingBody'>
                        <div className='onboardingSelectDocument'>
                            <p>In je dossier details kun je:</p>
                            <ul>
                                <li>De <b>gekoppelde documenten</b> van je dossier vinden</li>
                                <li><b>Nieuwe documenten</b> toevoegen</li>
                            </ul>
                            <label className='selectDocumentLabel'>Klik op <span
                                className='common-icon-plus plusIcon'/> om een document toe te voegen.</label>
                            <label className='onboardingSkipDocument' onClick={this.onSkipDocument}>Overslaan</label>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    renderConfirmDocument = () => {
        getOnboardingManager('store').highlightElement('dossierDetailsDocuments');
        appear('onboardingConfirmDocument');
        return (
            <div className='onboardingContent start' id='onboardingConfirmDocument'>
                <div className='storeOnboardingPopup selectDocument absolute sbr' id='storeOnboardingPopup'>
                    <div className='onboardingHeader lp'>
                        <label className='headerTitle'>Document toevoegen</label>
                    </div>
                    <div className='onboardingBody'>
                        <div className='onboardingConfirmDocument'>
                            <p className='confirmDocumentMessage'>Top, je hebt je eerste document aan dit dossier
                                toegevoegd!</p>
                            <MaterialFactory componentType={materialTypes.RAISED_BUTTON}
                                             onClick={this.onProceedDocuments}
                                             size='small'>Verder</MaterialFactory>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    renderDossierDetails = () => {
        getOnboardingManager('store').highlightElement('dossierDetailsData');
        return (
            <div className='onboardingContent start' id='onboardingDossierDetails'>
                <div className='storeOnboardingPopup dossierDetails absolute sbr' id='storeOnboardingPopup'>
                    <div className='onboardingHeader lp'>
                        <label className='headerTitle'>Dossier details</label>
                    </div>
                    <div className='onboardingBody'>
                        <div className='onboardingDossierDetails'>
                            <p>In je dossier details heb je de mogelijkheid om:</p>
                            <ul>
                                <li>Je <b>gegevens</b> aan te vullen</li>
                                <li><b>Notities</b> te maken</li>
                                <li><b>Contactgegevens</b> toe te voegen</li>
                            </ul>
                            <MaterialFactory componentType={materialTypes.RAISED_BUTTON} size='small'
                                             className='proceedBtn'
                                             onClick={this.onNextStepWithGa('Verder', 'Dossier details',
                                                 true)}>Verder</MaterialFactory>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    renderNotifyHelp = () => {
        const manager = getOnboardingManager('store');
        manager.highlightElement('menuPaper');
        manager.highlightElement('menuTutorial');
        appear('onboardingNotifyHelp');
        return (
            <div className='onboardingContent' id='onboardingNotifyHelp'>
                <div className='storeOnboardingPopup showTutorial absolute sbr' id='storeOnboardingPopup'>
                    <div className='onboardingHeader lp'>
                        <label className='headerTitle'>Neem me ByDeHand</label>
                    </div>
                    <div className='onboardingBody'>
                        <div className='onboardingConfirmDocument'>
                            <p className='confirmDocumentMessage'>Mocht je in de toekomst nog gebruiken willen maken van
                                deze hulp dan kun je deze guide altijd raadplegen via de knop <i>
                                    Neem me ByDeHand</i> in het menu.</p>
                            <MaterialFactory componentType={materialTypes.RAISED_BUTTON}
                                             onClick={this.onNextStepWithGa('Begrepen', 'Neem me ByDeHand',
                                                 true)}
                                             size='small'>Begrepen</MaterialFactory>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    componentDidMount = () => {
        this.mount();
    };

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.step !== this.props.step && this.state.hidePopup)
            this.changeState({hidePopup: false});
        if (isNotNull(nextProps.dossierType) && this.props.dossierType !== nextProps.dossierType) {
            let dossierName = '';
            if (nextProps.dossierType === 'personal_hobby')
                dossierName = 'Mijn hobby';
            else {
                const dossierTypeObj = getDossierType(nextProps.dossierType);
                dossierName = `Mijn ${dossierTypeObj.label.toLowerCase()}`;
            }
            this.changeState({dossierName, submitDisabled: false});
        }
    };

    componentWillUnmount = () => {
        this.unMount();
    };

    render = () => {
        return (
            <>
                <div className='onboardingBackground'/>
                {this.renderStep()}
            </>
        )
    };
};

StoreOnboardingView.propTypes = {
    onSubmitDossier: PropTypes.func.isRequired,
    onNextStep: PropTypes.func.isRequired,
    step: PropTypes.string.isRequired,
    dossierName: PropTypes.string.isRequired,
    dossierType: PropTypes.string.isRequired,
};