import React from 'react';

import {warn} from 'glob-common-js/lib/utils';
import {hasHover} from 'glob-common-react/lib/utils/reactUtils';

import StateComponent from "../../misc/stateComponent";
import DocumentDetailView from "./documentDetailView";
import getDocumentType from "../../../common/data/mappings/docTypeMapping";
import {addCustomEventListener, dispatchCustomEvent, removeCustomEventListener} from "../../../misc/eventDispatcher";
import {navigate, showMessage, showSnackbar} from "../../../misc/utils";
import {responseIsSuccess} from "../../../misc/requestSender";
import BdhDocument from "../../../models/document";
import {dispatchDocumentEvent, EVENT_TYPES} from "../../../misc/dataEvent";

const emptyDocument = new BdhDocument();
export default class DocumentDetailController extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            doc: emptyDocument,
            active: false,
            loading: false,
            urlLoading: false,
            hasChanges: false,
        };
    }

    onChange = name => value => {
        const {doc} = this.state;
        if (name === 'type')
            value = getDocumentType(value);
        doc[name] = value;
        this.changeState({doc, hasChanges: true});
    };

    onChangeField = (field) => value => {
        const {name, type} = field;
        const {doc} = this.state, fields = doc.fields;
        fields[name].value = value;
        doc.fields = fields;
        this.changeState({doc, hasChanges: true});
    };

    onClickLink = () => {
        this.onClose();
        dispatchCustomEvent('linkDossier', this.state.doc);
    };

    onClose = () => {
        this.changeState({
            doc: emptyDocument,
            active: false,
            loading: false,
            hasChanges: false,
            urlLoading: false,
        });
    };

    onClickBackground = () => {
        const view = document.querySelector('div.documentDetailView');
        if (isNull(view) || !hasHover(view))
            this.onClose();
    };

    onClickFile = () => {
        const {doc} = this.state;
        this.changeState({urlLoading: true});
        doc.loadUrl(doc => {
            this.changeState({urlLoading: false});
            window.open(doc.url, '_blank');
        }, true);
    };

    onSubmit = () => {
        const {doc} = this.state;
        this.changeState({loading: true});
        doc.update((doc, response) => {
            this.changeState({loading: false, doc});
            if (isNull(response.data.message)) {
                this.changeState({hasChanges: false});
                showSnackbar('Het document is aangepast.');
                dispatchDocumentEvent(EVENT_TYPES.UPDATE, doc);
            } else
                showMessage('Het aanpassen van het document is niet gelukt.', null, 'Aanpassen mislukt')
        });
    };

    onClickDelete = () => {
        showMessage('Weet je zeker dat je dit document wilt verwijderen?', [{label: 'Nee'},
            {label: 'Ja', onClick: this.onConfirmDelete}], 'Document verwijderen');
    };

    onConfirmDelete = () => {
        const {doc} = this.state;
        this.changeState({loading: true});
        doc.delete(response => {
            if (responseIsSuccess(response)) {
                this.onClose();
                showSnackbar('Je document is verwijderd');
                dispatchDocumentEvent(EVENT_TYPES.DELETE, doc);
            } else
                showMessage('Het verwijderen van je document is niet gelukt.', null, 'Verwijderen mislukt');
        });
    };

    onClickLinkedDossier = dossier => () => {
        this.onClose();
        navigate(`/dossier-details/${dossier.id}`);
    };

    activate = doc => {
        if (doc instanceof BdhDocument) {
            this.changeState({active: true, loading: true}, () => {
                doc.load((doc) => {
                    this.changeState({doc, loading: false});
                }, true);
            });
        } else warn('Specified document is not of type BdhDocument');
    };

    componentDidMount = () => {
        this.mount();
        addCustomEventListener('openDocumentDetails', this.activate);
    };

    componentWillUnmount = () => {
        this.unMount();
        removeCustomEventListener('openDocumentDetails', this.activate);
    };

    render = () => {
        const {doc, active, loading, hasChanges, urlLoading} = this.state;
        const canSave = hasChanges && isNotNull(doc.label);
        if (active)
            return <DocumentDetailView onChange={this.onChange} onChangeField={this.onChangeField}
                                       onClickLink={this.onClickLink} onClose={this.onClose}
                                       onClickFile={this.onClickFile} onSubmit={this.onSubmit}
                                       onClickDelete={this.onClickDelete} doc={doc} loading={loading}
                                       hasChanges={canSave} onClickLinkedDossier={this.onClickLinkedDossier}
                                       onClickBackground={this.onClickBackground} urlLoading={urlLoading}/>;
        return null;
    };
}