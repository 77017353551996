import './style/addDossierPopup_v2.scss';

import React from 'react';
import PropTypes from 'prop-types';

import MaterialFactory from "../../../../material/materialFactory";
import {materialTypes} from "../../../../material/materialTypes";
import CustomScrollbar from "../../../../misc/customScrollbar";
import PopupBuilder from "../../../../misc/popupBuilder/popupBuilder";
import GenericLoader from "../../../../misc/genericLoader";

export default class AddDossierPopup extends React.Component {
    constructor(props) {
        super(props);
    }

    onChangeDate = (type, name) => date => {
        const {onChange} = this.props;
        onChange(name, date);
    };

    getOptions = (options) => {
        if (Array.isArray(options)) return options.map(this.createOption);
        if (typeof options === 'string') return JSON.parse(options).map(this.createOption);
    };

    createOption = (option, key) => {
        let value = option.hasOwnProperty('value') ? option.value : option;
        let label = option.hasOwnProperty('label') ? option.label : option;
        return {value, label};
    };

    getFieldType = type => {
        const types = {
            'NUMERIC': 'number',
            'PRICE': 'number',
            'STRING': 'text',
            'default': 'text',
        };
        return types[type] || types.default;
    };

    renderField = (field, key) => {
        const {type, label, name, required, options, value, exclude} = field;
        if (exclude) return null;
        const {onChange, submitted, errors} = this.props;
        switch (type) {
            case 'DATE':
            case 'DATETIME':
                return <MaterialFactory componentType={materialTypes.DATE_PICKER} key={key} value={value}
                                        label={label} required={required || required === 1}
                                        onChange={this.onChangeDate(type, name)} disabled={submitted} fullWidth
                                        margin='dense'
                                        helperText={errors[name]}
                                        error={isNotNull(errors[name])}/>;
            default:
                if (isNotNull(options))
                    return <MaterialFactory componentType={materialTypes.SELECT} key={key} value={value} label={label}
                                            required={required || required === 1} onChange={evt => {
                        onChange(name, evt.target.value);
                    }} items={this.getOptions(options)} disabled={submitted} fullWidth margin='dense'
                                            helperText={errors[name]} error={isNotNull(errors[name])}/>;
                return <MaterialFactory componentType={materialTypes.TEXT} key={key} value={value} label={label}
                                        required={required || required === 1} onChange={evt => {
                    onChange(name, evt.target.value);
                }} disabled={submitted} fullWidth margin='dense' type={this.getFieldType(type)}
                                        helperText={errors[name]} error={isNotNull(errors[name])}/>
        }
    };

    renderHeader = () => {
        const {dossierType, onClose} = this.props;
        return (
            <div className='addDossierHeader'>
                <span className={dossierType.icon + ' typeIcon'}/>
                <label className='typeLabel'>{dossierType.label}</label>
                <span className='common-icon-cross closeIcon' onClick={onClose}/>
            </div>
        )
    };

    renderBody = () => {
        const {fields, submitting} = this.props;
        return (
            <CustomScrollbar height='20rem' autoHide={false}>
                <div className='addDossierBody'>
                    <GenericLoader active={submitting}/>
                    {fields.map(this.renderField)}
                </div>
            </CustomScrollbar>
        );
    };

    renderFooter = () => {
        const {submitted, onClickDossierDetails, onSubmit, dossierId} = this.props;
        return (
            <div className={'addDossierFooter' + (submitted ? ' submitted' : '')}>
                {submitted ?
                    <>
                        <MaterialFactory componentType={materialTypes.RAISED_BUTTON} disabled={isNull(dossierId)}
                                         onClick={onClickDossierDetails}>Naar dossier</MaterialFactory>
                        <MaterialFactory componentType={materialTypes.RAISED_BUTTON} onClick={this.props.onClose}>
                            Sluiten</MaterialFactory>
                    </> :
                    <MaterialFactory componentType={materialTypes.RAISED_BUTTON} onClick={onSubmit}>
                        Aanmaken</MaterialFactory>
                }
            </div>
        );
    };

    render = () => {
        const {active} = this.props;
        return (
            <PopupBuilder open={active} containerClass='addDossierContainer' content={{
                header: this.renderHeader(),
                body: this.renderBody(),
                footer: this.renderFooter(),
            }}/>
        )
    }
};

AddDossierPopup.propTypes = {
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onClickDossierDetails: PropTypes.func.isRequired,
    active: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    submitted: PropTypes.bool.isRequired,
    dossierType: PropTypes.shape({
        label: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
    }).isRequired,
    fields: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        required: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
        options: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        value: PropTypes.any,
        exclude: PropTypes.bool,
    })).isRequired,
    errors: PropTypes.object.isRequired,
    dossierId: PropTypes.string,
};