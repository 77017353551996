import utils, {getSetting, warn} from 'glob-common-js/lib/utils';
import * as _ from "lodash";

const possesionsDosLinks = ['kitchen_appliances', 'household_appliances', 'phone_electronics', 'laptop_electronics',
    'television_electronics', 'music_system_electronics', 'electro_gen', 'tablet_electronics',
    'furniture'];

const mapping = {
    access_card_trips: {
        id: '1886cada-7321-408a-ac2f-278d908e9996',
        name: 'access_card_trips',
        label: "Toegangskaart",
        dosLinks: ['personal_hobby'],
    },
    access_card_work: {
        id: '134bf562-8ee5-4ccd-8c83-9f2341b50d60',
        name: 'access_card_work',
        label: "Toegangspas",
        dosLinks: [ 'personal_hobby'],
    },
    aid_card_leasing_company: {
        id: 'c9b87eed-3362-44e0-b619-371c1b8d9219',
        name: 'aid_card_leasing_company',
        label: "Hulppas leasemaatschappij",
        dosLinks: ['car'],
    },
    airline_ticket: {
        id: 'a123d3de-3321-4b06-81de-3a6ef6db7571',
        name: 'airline_ticket',
        label: "Vliegticket",
        dosLinks: ['travel'],
        tools: {},
        fields: {
            airline_ticket_flight_number: {
                id: '96ee2dc4-4531-4e25-bd24-c6809cc0c56e',
                label: 'Vluchtnummer',
                options: null,
                required: false,
                type: 'NUMBER',
                name: 'airline_ticket_flight_number',
            },
            airline_ticket_airport_departure: {
                id: 'ea404130-21cd-4d37-9b67-10ecef10d325',
                label: 'Luchthaven vertrek',
                options: null,
                required: false,
                type: 'STRING',
                name: 'airline_ticket_airport_departure',
            },
            airline_ticket_airport_arrival: {
                id: 'da69d1aa-1cf0-41c3-a3bf-08e1d28263cb',
                label: 'Luchthaven aankomst',
                options: null,
                required: false,
                type: 'STRING',
                name: 'airline_ticket_airport_arrival',
            },

            airline_ticket_date_departure: {
                id: '17ca2043-0071-41b8-889f-051108a7a8a1',
                label: 'Datum/tijd vertrek',
                options: null,
                required: false,
                type: 'DATETIME',
                name: 'airline_ticket_date_departure',
            },
            airline_ticket_date_arrival: {
                id: '2244afa0-5135-4666-bbf6-2e67ab6fb9f0',
                label: 'Datum/tijd aankomst',
                options: null,
                required: false,
                type: 'DATETIME',
                name: 'airline_ticket_date_arrival',
            },
        }
    },
    alarm_agreement: {
        id: '844342a8-1d4f-470d-87fb-64bb86ee957c',
        name: 'alarm_agreement',
        label: "Overeenkomst alarmcentrale",
        dosLinks: ['car'],
    },
    alarm_card: {
        id: '6bdaa3ac-0576-405c-bcec-3595bba554b5',
        name: 'alarm_card',
        label: "Alarmpas",
        dosLinks: ['car'],
    },
    alarm_certificate: {
        id: 'f6ddef1c-93fd-4780-a80c-2d43eb48ab3a',
        name: 'alarm_certificate',
        label: "Alarmcertificaat",
        dosLinks: [],
    },
    animal_insurance_animals: {
        id: '7c4beb95-bbdf-4618-a2d3-48a1ba3bc18a',
        name: 'animal_insurance_animals',
        label: "Dierenverzekering",
        dosLinks: ['misc_insurance'],
    },
    animal_passport_animals: {
        id: '23ec008d-681a-4ee8-9fcf-0b313654cd7a',
        name: 'animal_passport_animals',
        label: "Dierenpaspoort",
        dosLinks: [],
    },
    annual_review_property: {
        id: '41c1465a-0600-4d36-8d04-dbbb22d2bf2f',
        name: 'annual_review_property',
        label: "Financieel jaaroverzicht",
        dosLinks: [ 'tax_year'],
    },
    annual_statement_work: {
        id: '8d14e11c-0b03-49b9-8f1d-f61b7d3b2235',
        name: 'annual_statement_work',
        label: "Jaaropgave werkgever",
        fields: {
            year: {
                id: 'f5a0f57b-3a51-4f30-8473-02bcd5c40ff4',
                label: 'Jaar',
                options: null,
                required: false,
                type: 'NUMBER',
                name: 'year',
            },
        },
        dosLinks: ['job', 'tax_year'],
    },
    bank_card_personal: {
        id: '68b18f15-851e-4ad9-bcc2-bb35097465ff',
        name: 'bank_card_personal',
        label: "Pinpas",
        dosLinks: ['pay_pass',],
        tools: {
            expireDate: {
                icon: "common-icon-alarm-clock",
                description: "Herindering als je pinpas verloopt",
                herinderingDescription: "Je pinpas _name verloopt op expire_date !",
                actions: {}
            }
        },
        fields: {
            bank_name: {
                id: '9a6a51e5-67a3-4116-8b12-74830b16d1c9',
                label: 'Bank',
                options: null,
                required: false,
                type: 'STRING',
                name: 'bank_name',
            },
            iban_number: {
                id: '73abef96-5e2c-4284-b199-191a735c328e',
                label: 'IBAN',
                options: null,
                required: false,
                type: 'STRING',
                name: 'iban_number',
            },
            valid_until: {
                id: '6eb0e078-601e-4504-91bc-3a3b77000dc9',
                label: 'Geldig tot',
                options: null,
                required: false,
                type: 'DATE',
                name: 'valid_until',
            },
        }
    },
    bkr_personal: {
        id: 'd2e1750a-9488-46e0-92ee-0da0c34081f0',
        name: 'bkr_personal',
        label: "Uittreksel BKR",
        dosLinks: [],
    },
    boat_licence_personal: {
        id: 'b1401105-d064-4903-8c38-84c6ffe97b1d',
        name: 'boat_licence_personal',
        label: "Vaarbewijs",
        dosLinks: ['personal_hobby'],
    },
    booking_accommodation: {
        id: 'b011f0bb-0abb-4c55-93c8-4bab89e6c7bd',
        name: 'booking_accommodation',
        label: "Reservering accommodatie",
        dosLinks: ['travel', 'camping',],
    },
    booking_confirmation_trips: {
        id: 'e457e66b-f460-4e6c-abe2-510b558ebe09',
        name: 'booking_confirmation_trips',
        label: "Reserveringsbevestiging",
        dosLinks: ['personal_hobby', 'travel', 'camping',],
    },
    bus_train_ticket: {
        id: '0697094e-9cde-49f8-a014-da648bc1b79d',
        name: 'bus_train_ticket',
        label: "Trein/bus-ticket",
        dosLinks: ['travel'],
    },
    cancellation_insurance: {
        id: '11f74d3a-17e4-4049-8c5f-e6ff3c4444ac',
        name: 'cancellation_insurance',
        label: "Annuleringsverzekering polis",
        dosLinks: ['travel','misc_insurance'],
    },
    cao_work: {
        id: '175fc398-1370-4738-9347-934efef5fa77',
        name: 'cao_work',
        label: "Personeelsgids / cao",
        dosLinks: ['job'],
    },
    // TODO Remove since we have a common insurance policy document
    car_insurance_policy: {
        id: '5e4a787c-9325-49da-a41b-c4f34a8fd4b2',
        name: 'car_insurance_policy',
        label: "Autoverzekering polis",
        dosLinks: ['car','misc_insurance'],
    },
    certificat_study: {
        id: 'f689db0c-2d8d-4290-855f-7aaa20abd11b',
        name: 'certificat_study',
        label: "Certificaat",
        dosLinks: ['study'],
        fields: {
            instelling: {
                id: '1b90d9b4-9b87-413b-99ba-90ec77110d55',
                label: 'Instelling',
                options: null,
                required: false,
                type: 'STRING',
                name: 'instelling',
            },
            date: {
                id: 'bf646f11-ac91-454d-876e-433df86421c1',
                label: 'Datum',
                options: null,
                required: false,
                type: 'DATE',
                name: 'date',
            },
        },
    },
    cinema_card_trips: {
        id: 'cdce7938-1d90-4ecd-94d2-1d35bde588b6',
        name: 'cinema_card_trips',
        label: "Bioscoop-pas",
        dosLinks: ['personal_hobby'],
    },
    cjp_pass_trips: {
        id: 'ada2bb19-8ae8-4a67-b7c1-4e4f20546656',
        name: 'cjp_pass_trips',
        label: "CJP/Knaek-pas",
        dosLinks: ['personal_hobby'],
    },
    cohabitation_contract_family: {
        id: 'c3e42701-677f-4fbe-8f75-e1bb651ec945',
        name: 'cohabitation_contract_family',
        label: "Samenlevingscontract",
        dosLinks: ['personal_data'],
    },
    completion_certificate: {
        id: '425d788e-37de-432b-aaa5-388876c680da',
        name: 'completion_certificate',
        label: "Leveringsakte",
        dosLinks: ['house'],
    },
    contents_insurance_policy: {
        id: '55367ceb-b587-4a06-9209-fe25f16d7c54',
        name: 'contents_insurance_policy',
        label: "Inboedelverzekering polis",
        dosLinks: ['misc_insurance'],
    },
    contents_woz_value_property: {
        id: 'd8fee3ef-f2cc-4e9f-8e6b-dc5e91f929b2',
        name: 'contents_woz_value_property',
        label: "Opgave WOZ-waarde",
        dosLinks: ['tax_year','house'],
    },
    credit_card_personal: {
        id: 'd4c79611-b815-4196-b1b5-6707d6c2318e',
        name: 'credit_card_personal',
        label: "Creditcard",
        dosLinks: ['pay_pass',],
        tools: {
            expireDate: {
                icon: "common-icon-alarm-clock",
                description: "Herindering als je creditcard verloopt",
                reminderDescription: "Je creditcard _name verloopt op expire_date !",
                action: {},
                inputs: {}
            }
        },
        fields: {
            creditcard_number: {
                id: '4ca7abb0-febe-4781-9584-b8d0998a269e',
                label: 'Kaartnummer',
                options: null,
                required: false,
                type: 'NUMBER',
                name: 'creditcard_number',
            },
            bank_name: {
                id: '9a6a51e5-67a3-4116-8b12-74830b16d1c9',
                label: 'Bank',
                options: null,
                required: false,
                type: 'STRING',
                name: 'bank_name',
            },
            valid_until: {
                id: '6eb0e078-601e-4504-91bc-3a3b77000dc9',
                label: 'Geldig tot',
                options: null,
                required: false,
                type: 'DATE',
                name: 'valid_until',
            },
        }
    },
    customer_card_sport: {
        id: '6c8d49fc-e17f-483f-9e96-9a1742117d8f',
        name: 'customer_card_sport',
        label: "Klantenkaart",
        dosLinks: ['passes',],
    },
    declaration_work: {
        id: 'f8dac144-98fa-4863-9e9c-099c148e5ce7',
        name: 'declaration_work',
        label: "Declaratie",
        dosLinks: ['travel', 'health_insurance_care',],
        fields: {
            price: {
                id: '915a310d-7d9c-4300-9e38-c4a9e87a42de',
                label: 'Bedrag',
                options: null,
                required: false,
                type: 'PRICE',
                name: 'price',
            },
        },
    },
    diploma_study: {
        id: 'e4fcc351-8ee0-4f8f-8957-cd41c853f62b',
        name: 'diploma_study',
        label: "Diploma",
        dosLinks: ['study'],
        fields: {
            year: {
                id: 'f5a0f57b-3a51-4f30-8473-02bcd5c40ff4',
                label: 'Jaar',
                options: null,
                required: false,
                type: 'NUMBER',
                name: 'year',
            },
            diploma_type: {
                id: '9cd7d73d-a0dc-4a34-af4e-8824fdd50b86',
                label: 'Diploma type',
                options: ['Master', 'Bachelor', 'MBO', 'Middelbare school'],
                required: false,
                type: 'SELECT',
                name: 'diploma_type',
            },
        },
    },
    disability_insurance_health: {
        id: '45bd7c78-8c48-4431-95d7-26d3833eeeca',
        name: 'disability_insurance_health',
        label: "Arbeidsongeschiktheidsverzekering",
        dosLinks: ['medical_data','misc_insurance'],
    },
    donor_card_care: {
        id: '2a9630d4-addf-4e73-b7d1-f67cac3455d7',
        name: 'donor_card_care',
        label: "Donorcodicil",
        dosLinks: ['medical_data', 'passes', 'health_insurance_care','miscellaneous_care'],
        fields: {
            registration_number: {
                id: 'e612427c-9929-4767-b6f3-6bd9f5f5ce78',
                label: 'Registratienummer',
                options: null,
                required: false,
                type: 'NUMBER',
                name: 'registration_number',
            },
        },
    },
    drivers_licence_personal: {
        id: '1c6ef8c8-deea-40b5-a7cf-e950113d49bf',
        name: 'drivers_licence_personal',
        label: "Rijbewijs",
        dosLinks: ['car', 'personal_data', 'passes',],
        fields: {
            valid_until: {
                id: '6eb0e078-601e-4504-91bc-3a3b77000dc9',
                label: 'Geldig tot',
                required: false,
                type: 'DATE',
                name: 'valid_until',
            },
            issue_date: {
                id: '6fa3c46d-2e32-4f5f-8fb0-5e86e0c239b8',
                label: 'Datum afgifte',
                required: false,
                type: 'DATE',
                name: 'issue_date',
            },
            bsn_number: {
                id: '8527e02a-fc92-420d-9eed-490fdcf1d899',
                label: 'BSN',
                required: false,
                type: 'NUMBER',
                name: 'bsn_number',
            },
            document_number: {
                id: '7b0dccef-4e48-441b-aa65-98588b535ae6',
                label: 'Documentnummer',
                required: false,
                type: 'STRING',
                name: 'document_number',
            },
        },
        tools: {
            expireDate: {
                //temp
                active: false,
                //
                icon: "common-icon-alarm-clock",
                description: "Herindering als je rijbewijs verloopt",
                herinderingDescription: "Let op je rijbewijs verloopt binnen een maand!",
                actions: {
                    navigateUrl: () => {
                        navigateUrl("https://www.rijksoverheid.nl/onderwerpen/rijbewijs")
                    },
                    addDocument: () => {
                        addDocument("rijbewijs")
                    }
                },
                inputs: {}
            },
            expireDate2: {
                //temp
                active: false,
                //
                icon: "common-icon-alarm-clock",
                description: "Herindering als je rijbewijs verloopt1",
                herinderingDescription: "Let op je rijbewijs verloopt binnen een maand!",
                actions: {
                    navigateUrl: () => {
                        navigateUrl("https://www.rijksoverheid.nl/onderwerpen/rijbewijs")
                    },
                    addDocument: () => {
                        addDocument("rijbewijs")
                    }
                },
                inputs: {}
            },
            expireDate3: {
                //temp
                active: true,
                //
                icon: "common-icon-alarm-clock",
                description: "Herindering als je rijbewijs verloopt2",
                herinderingDescription: "Let op je rijbewijs verloopt binnen een maand!",
                actions: {
                    navigateUrl: () => {
                        navigateUrl("https://www.rijksoverheid.nl/onderwerpen/rijbewijs")
                    },
                    addDocument: () => {
                        addDocument("rijbewijs")
                    }
                },
                inputs: {}
            },
        }
    },
    eye_ear_measuring_care: {
        id: 'db8359f8-5213-487e-90b9-2591644f1a42',
        name: 'eye_ear_measuring_care',
        label: "Oog/Oor-meting",
        dosLinks: ['medical_data'],
    },
    financing_agreement: {
        id: '2e8275df-9062-4813-b7e4-a69151d10e53',
        name: 'financing_agreement',
        label: "Financieringsovereenkomst",
        dosLinks: [ 'car', 'cycle_transport',],
    },
    fishing_pass_sport: {
        id: '2a963e9b-bb7a-4f03-9076-23bf175a9cee',
        name: 'fishing_pass_sport',
        label: "Vispas",
        dosLinks: ['personal_hobby'],
    },
    fracture_property: {
        id: '96282e7c-7d48-45c6-bcdf-2d51dc70ad74',
        name: 'fracture_property',
        label: "Factuur",
        dosLinks: [ 'misc_company', 'misc_fixed_costs', 'misc_purchases', 'energy', 'water',]
            .concat(possesionsDosLinks),
    },
    fuel_pass: {
        id: '27b5836b-1ef9-47e5-9509-8f3f4c859bbb',
        name: 'fuel_pass',
        label: "Brandstof-pas",
        dosLinks: ['car'],
    },
    funeral_insurance_personal: {
        id: '8e644456-27df-4227-a315-c00e12f735fc',
        name: 'funeral_insurance_personal',
        label: "Uitvaartverzekering",
        dosLinks: ['misc_insurance'],
    },
    golf_licence_sport: {
        id: '16e273b5-283f-4aae-8749-cdd0c8b23af7',
        name: 'golf_licence_sport',
        label: "Golf Vaardigheids Bewijs",
        dosLinks: ['personal_hobby'],
    },
    grade_list_study: {
        id: '3e90d65b-d341-48a5-abc7-bb4bee1dc97a',
        name: 'grade_list_study',
        label: "Cijferlijst",
        dosLinks: ['study'],
        fields: {
            instelling_type: {
                id: '724fab54-b8d3-47fb-b01d-81ba8e2d92bf',
                label: 'Instelling type',
                options: ['Univeristeit', 'Hogeschool', 'MBO', 'Middelbare school'],
                required: false,
                type: 'SELECT',
                name: 'instelling_type',
            },
        },
    },
    green_card: {
        id: 'e833136c-3ffc-4bc4-9b01-a1768725e982',
        name: 'green_card',
        label: "Groene kaart",
        dosLinks: ['car'],
    },
    health_card: {
        id: '00e91bf8-2161-4fd2-9749-a9d5c8cb432c',
        name: 'health_card',
        label: "Zorgpas",
        dosLinks: ['health_insurance_care', 'medical_data'],
        fields: {
            valid_until: {
                id: '5439813b-7946-45ad-bbd1-7b2479eb0190',
                label: 'Geldig tot',
                options: null,
                required: false,
                type: 'DATE',
                name: 'valid_until',
            },
        },
    },
    health_insurance: {
        id: '33f2d984-d843-4ae4-bd40-bb96acfcb79d',
        name: 'health_insurance',
        label: "Zorgverzekering polis",
        dosLinks: ['health_insurance_care', 'medical_data','misc_insurance'],
        tools: {
            expireDate: {
                icon: "common-icon-alarm-clock",
                description: "Herindering als je zorgverzekering verloopt",
                herinderingDescription: "",
                actions: {
                    addDocument: () => {
                        addDocument("zorgverzekeringspolis")
                    }
                },
                inputs: {}
            }
        },
    },
    healthcare_analysis_insurance: {
        id: '0be7b215-24e0-47e1-b6cd-9517b6442197',
        name: 'healthcare_analysis_insurance',
        label: "Analyse zorgverzekering",
        dosLinks: ['health_insurance_care', 'medical_data'],
    },
    // TODO Remove since we have a common insurance policy document
    house_insurance_policy: {
        id: '9d6c2274-8332-4508-9c08-fe36132887fc',
        name: 'house_insurance_policy',
        label: "Opstalverzekeringpolis",
        dosLinks: ['misc_insurance'],
    },
    id_card_personal: {
        id: 'e34703c0-a886-4bc9-9cc6-0cdade2b4fe0',
        name: 'id_card_personal',
        label: "ID-kaart",
        dosLinks: ['travel', 'personal_data', 'passes',],
        tools: {
            expireDate: {
                icon: "common-icon-alarm-clock",
                description: "Herindering als je identiteitsbewijs verloopt",
                herinderingDescription: "Je identiteitsbewijs _name verloopt op expire_date !",
                actions: {
                    addDocument: () => {
                        addDocument("identiteitsbewijs")
                    }
                },
                inputs: {}
            }
        },
        fields: {
            valid_until: {
                id: '6eb0e078-601e-4504-91bc-3a3b77000dc9',
                label: 'Geldig tot',
                options: null,
                required: false,
                type: 'DATE',
                name: 'valid_until',
            },
            bsn_number: {
                id: '8527e02a-fc92-420d-9eed-490fdcf1d899',
                label: 'Burgerservicenummer',
                options: null,
                required: false,
                type: 'NUMBER',
                name: 'bsn_number',
            },
            issue_date: {
                id: '6fa3c46d-2e32-4f5f-8fb0-5e86e0c239b8',
                label: 'Datum afgifte',
                options: null,
                required: false,
                type: 'DATE',
                name: 'issue_date',
            },
            document_number: {
                id: '7b0dccef-4e48-441b-aa65-98588b535ae6',
                label: 'Documentnummer',
                options: null,
                required: false,
                type: 'STRING',
                name: 'document_number',
            },
        }
    },
    // TODO Add insurance_end date field
    insurance_policy: {
        id: '2798dceb-4aad-4f03-bcbe-dbc97d2bedd6',
        name: 'insurance_policy',
        label: "Verzekeringspolis",
        dosLinks: ['contents_insurance', 'liability_insurance', 'car_insurance', 'travel_insurance',
            'health_insurance_care',
            'cancel_insurance', 'health_insurance_care', 'cycle_transport',],
    },
    insurance_policy_bike: {
        id: 'REPLACE_ID_Insurance_Bike',
        name: 'insurance_policy_bike',
        label: "Fiets / E-bike verzekering",
        dosLinks: ['misc_insurance'],
        replaceID: '2798dceb-4aad-4f03-bcbe-dbc97d2bedd6',
    },
    insurance_policy_scootmobile: {
        id: 'REPLACE_ID_Insurance_Scootmobile',
        name: 'insurance_policy_scootmobile',
        label: "Scootmobiel verzekering",
        dosLinks: ['misc_insurance'],
        replaceID: '2798dceb-4aad-4f03-bcbe-dbc97d2bedd6',
    },
    insurance_policy_pension: {
        id: 'REPLACE_ID_Insurance_Pension',
        name: 'insurance_policy_pension',
        label: "Pensioenverzekering",
        dosLinks: ['misc_insurance'],
        replaceID: '2798dceb-4aad-4f03-bcbe-dbc97d2bedd6',
    },
    labor_contract_work: {
        id: '135b80bc-fa80-4482-ac00-d75173eea93d',
        name: 'labor_contract_work',
        label: "Arbeidsovereenkomst",
        fields:
            {
                start_date: {
                    id: 'c58577ba-a7e0-4c1e-8573-e346c51e983b',
                    label: 'Begindatum',
                    options: null,
                    required: false,
                    type: 'DATE'
                },
                end_date: {
                    id: '95271388-8820-419b-9043-cfa241394c2f',
                    label: 'Einddatum',
                    options: null,
                    required: false,
                    type: 'DATE'
                },
                function: {
                    id: '898283ac-0fae-4d56-93ae-61c8ab919914',
                    label: 'Functie',
                    options: null,
                    required: false,
                    type: 'STRING'
                },
            },
        dosLinks: ['job'],
    },
    leaflet_drug_care: {
        id: 'e12f8759-1dc5-4476-a51b-f4fc831566ab',
        name: 'leaflet_drug_care',
        label: "Bijsluiter Geneesmiddel",
        dosLinks: ['medical_data'],
        fields: {}
    },
    lease_property: {
        id: '514d610e-2f48-441f-8e36-8cb5cb6b6e4f',
        name: 'lease_property',
        label: "Huurcontract",
        dosLinks: ['rental_house'],
        fields: {
            start_date: {
                id: 'c58577ba-a7e0-4c1e-8573-e346c51e983b',
                label: 'Begindatum',
                options: null,
                required: false,
                type: 'DATE',
                name: 'start_date',
            },
            end_date: {
                id: '95271388-8820-419b-9043-cfa241394c2f',
                label: 'Einddatum',
                options: null,
                required: false,
                type: 'DATE',
                name: 'end_date',
            },
            owner: {
                id: 'c58600d5-4829-4dac-bbac-1adc1d880f2e',
                label: 'Verhuurder',
                options: null,
                required: false,
                type: 'STRING',
                name: 'owner',
            },
            price: {
                id: '915a310d-7d9c-4300-9e38-c4a9e87a42de',
                label: 'Bedrag',
                options: null,
                required: false,
                type: 'PRICE',
                name: 'price',
            },
        },
    },
    legal_insurance_health: {
        id: '3792d3a6-873a-4427-a3ea-b3b0fa125d66',
        name: 'legal_insurance_health',
        label: "Rechtsbijstandverzekering",
        dosLinks: ['misc_insurance'],
        fields: {}
    },
    // TODO Remove since we have a common insurance policy document
    liability_insurance_health: {
        id: '7c1d311d-59df-4f6a-ade8-3b5c4b194de3',
        name: 'liability_insurance_health',
        label: "Aansprakelijkheidsverzekering polis",
        dosLinks: ['misc_insurance'],
        fields: {}
    },
    life_insurance_personal: {
        id: '44ec34f8-95cd-4c42-9874-539578826ef3',
        name: 'life_insurance_personal',
        label: "Overlijdensrisicoverzekering",
        dosLinks: ['misc_insurance'],
        fields: {}
    },
    maintenance_contract: {
        id: '32153ce4-cbf3-4dfd-af6f-0a310c8d3a7d',
        name: 'maintenance_contract',
        label: "Onderhoudscontract",
        dosLinks: [ 'car', 'boiler_indoors', 'cycle_transport',],
        fields: {}
    },
    manual: {
        id: 'ddba5415-f259-4287-bf6e-305ffa35ed44',
        name: 'manual',
        label: "Handleiding",
        dosLinks: [ 'misc_purchases', 'cycle_transport'].concat(possesionsDosLinks),
        fields: {}
    },
    membership_card_sport: {
        id: '855471d1-e322-4980-b166-17661e04b8a7',
        name: 'membership_card_sport',
        label: "Lidmaatschapskaart",
        dosLinks: ['personal_hobby'],
        fields: {}
    },
    meter_reading_property: {
        id: 'c3ee5599-c34d-4608-9983-44f1aa6c482b',
        name: 'meter_reading_property',
        label: "Meterstand",
        dosLinks: [],
        fields: {}
    },
    miscellaneous_indoors: {
        id: '5d54a11b-8ce1-405d-a6dd-a267d6a564ca',
        name: 'miscellaneous_indoors',
        label: "Overige",
        dosLinks: ['misc_contents', 'misc_house'].concat(possesionsDosLinks),
        fields: {}
    },
    moped_license_personal: {
        id: 'cdaf7a5d-e29f-4b89-a45c-c5f461fb3e4a',
        name: 'moped_license_personal',
        label: "Brommerrijbewijs",
        dosLinks: ['moped_transport'],
        fields: {}
    },
    mortage: {
        id: 'df8b9b7d-e83c-46e3-9a44-f20fd529dc4b',
        name: 'mortage',
        label: "Hypotheekakte",
        dosLinks: ['house'],
        fields: {}
    },
    mortage_overview: {
        id: 'b6bd93be-e118-4b14-9ef2-a5aabb31d0b0',
        name: 'mortage_overview',
        label: "Hypotheek overzicht",
        dosLinks: ['tax_year'],
        fields: {}
    },
    museum_card_trips: {
        id: '2cf634b1-b114-4611-8c57-a5d35c0e16af',
        name: 'museum_card_trips',
        label: "Museumjaarkaart",
        dosLinks: ['personal_hobby'],
        fields: {}
    },
    ov_chip_card_transport: {
        id: '2689ce79-f2c0-452a-9d82-4a0a8dfc47ec',
        name: 'ov_chip_card_transport',
        label: "OV chipkaart",
        dosLinks: ['study', 'passes',],
        tools: {
            expireDate: {
                icon: "common-icon-alarm-clock",
                description: "Herindering als je OV chipkaart verloopt",
                herinderingDescription: "Je OV chipkaart _name verloopt op expire_date !",
                action: {},
                inputs: {}
            }
        },
        fields: {
            card_nr: {
                id: '5f209348-1c78-4f8e-b6af-80366c1d6b13',
                label: 'Kaartnummer',
                options: null,
                required: false,
                type: 'STRING',
                name: 'card_nr',
            },
            valid_until: {
                id: '6eb0e078-601e-4504-91bc-3a3b77000dc9',
                label: 'Geldig tot',
                options: null,
                required: false,
                type: 'DATE',
                name: 'valid_until',
            },
        },
    },
    passport_personal: {
        id: 'c8448e63-bfa1-4dd9-af53-d1b5721b7e54',
        name: 'passport_personal',
        label: "Paspoort",
        dosLinks: ['travel', 'personal_data', 'passes',],
        tools: {
            expireDate: {
                icon: "common-icon-alarm-clock",
                description: "Herindering als je paspoort verloopt",
                herinderingDescription: "Je paspoort _name verloopt op expire_date !",
                actions: {
                    navigateUrl: () => {
                        navigateUrl("https://www.rijksoverheid.nl/onderwerpen/paspoort-en-identiteitskaart/vraag-en-antwoord")
                    },
                    addDocument: () => {
                        addDocument("paspoort")
                    }
                },
                inputs: {}
            }
        },
        fields: {
            valid_until: {
                id: '6eb0e078-601e-4504-91bc-3a3b77000dc9',
                label: 'Geldig tot',
                options: null,
                required: false,
                type: 'DATE',
                name: 'valid_until',
            },
            bsn_number: {
                id: '8527e02a-fc92-420d-9eed-490fdcf1d899',
                label: 'Burgerservicenummer',
                options: null,
                required: false,
                type: 'STRING',
                name: 'bsn_number',
            },
            issue_date: {
                id: '6fa3c46d-2e32-4f5f-8fb0-5e86e0c239b8',
                label: 'Datum afgifte',
                options: null,
                required: false,
                type: 'DATE',
                name: 'issue_date',
            },
            document_number: {
                id: '7b0dccef-4e48-441b-aa65-98588b535ae6',
                label: 'Documentnummer',
                options: null,
                required: false,
                type: 'STRING',
                name: 'document_number',
            },
        }
    },
    pons_card_care: {
        id: '2e662211-8ee4-4dca-a7fd-ff84a452d63b',
        name: 'pons_card_care',
        label: "Ponskaartje",
        dosLinks: ['medical_data'],
        fields: {}
    },
    preliminary_contract: {
        id: '8095b1a2-8acf-44dc-8bae-fa16c5c8a810',
        name: 'preliminary_contract',
        label: "Voorlopige koopovereenkomst",
        dosLinks: [ 'car'],
        fields: {}
    },
    miscellaneous_property_purchase_agreement: {
        id: 'REPLACE_ID_purchase_agreement',
        name: 'miscellaneous_property_purchase_agreement',
        label: "Koopovereenkomst",
        dosLinks: [ 'house'],
        fields: {},
        replaceID: 'd449bd2a-e753-4f75-b1f0-c7527f4b4b00',
    },
    miscellaneous_property_energylabel: {
        id: 'REPLACE_ID_energylabel',
        name: 'miscellaneous_property_energylabel',
        label: "Energielabel",
        dosLinks: [ 'house'],
        fields: {},
        replaceID: 'd449bd2a-e753-4f75-b1f0-c7527f4b4b00',
    },
    miscellaneous_property_other: {
        id: 'REPLACE_ID_misc_property_other',
        name: 'miscellaneous_property_other',
        label: "Overige",
        dosLinks: [ 'house'],
        fields: {},
        replaceID: 'd449bd2a-e753-4f75-b1f0-c7527f4b4b00',
    },
    miscellaneous_work_pension_other: {
        id: 'REPLACE_ID_misc_work_pension_other',
        name: 'miscellaneous_work_pension_other',
        label: "Overige",
        dosLinks: [ 'misc_savings'],
        fields: {},
        replaceID: '9e13bc74-9565-41cf-bc70-1d42faffc6ec',
    },
    miscellaneous_work_pension_mijnpensioen: {
        id: 'REPLACE_ID_misc_work_pension_mijnpensioen',
        name: 'miscellaneous_work_pension_mijnpensioen',
        label: "Mijn pensioenoverzicht",
        dosLinks: [ 'misc_savings'],
        fields: {},
        replaceID: '9e13bc74-9565-41cf-bc70-1d42faffc6ec',
    },
    miscellaneous_work_pension_overview: {
        id: 'REPLACE_ID_misc_work_pension_overview',
        name: 'miscellaneous_work_pension_overview',
        label: "Pensioenopgave",
        dosLinks: [ 'misc_savings'],
        fields: {},
        replaceID: '9e13bc74-9565-41cf-bc70-1d42faffc6ec',
    },
    miscellaneous_work_other: {
        id: 'REPLACE_ID_misc_work_other',
        name: 'miscellaneous_work_other',
        label: "Overige",
        dosLinks: [ 'job'],
        fields: {},
        replaceID: '9e13bc74-9565-41cf-bc70-1d42faffc6ec',
    },
    miscellaneous_Rental_Allowance: {
        id: 'REPLACE_ID_misc_Rental_Allowance',
        name: 'miscellaneous_Rental_Allowance',
        label: "Huurtoeslag",
        dosLinks: [ 'rental_house'],
        fields: {},
        replaceID: '77c3c8fe-2a6a-49e1-951d-483e0eb5f342',
    },
    miscellaneous_Rental_Other: {
        id: 'REPLACE_ID_misc_Rental_Other',
        name: 'miscellaneous_Rental_Other',
        label: "Overig",
        dosLinks: [ 'rental_house'],
        fields: {},
        replaceID: '77c3c8fe-2a6a-49e1-951d-483e0eb5f342',
    },
    prenuptial_agreement_family: {
        id: 'ea2b8f56-023e-46be-a413-eb18a30eca53',
        name: 'prenuptial_agreement_family',
        label: "Huwelijkse voorwaarden",
        dosLinks: ['personal_data'],
        fields: {}
    },
    prescription_pharmacy_care: {
        id: 'd8628700-8770-4ecb-b787-159c61e9f863',
        name: 'prescription_pharmacy_care',
        label: "Recept apotheek",
        dosLinks: ['medical_data', 'general_practitioner',],
        fields: {}
    },
    proof_of_registration_study: {
        id: 'f98a04ce-7522-4487-bcfe-f5cf66f759fd',
        name: 'proof_of_registration_study',
        label: "Bewijs van inschrijving",
        dosLinks: ['study'],
        fields: {
            education: {
                name: 'education',
                id: 'd45200aa-6dc8-41da-a548-b05cd5a88243',
                label: 'Opleiding',
                options: null,
                required: false,
                type: 'STRING'
            },
            instelling: {
                name: 'instelling',
                id: '1b90d9b4-9b87-413b-99ba-90ec77110d55',
                label: 'Instelling',
                options: null,
                required: false,
                type: 'STRING'
            },
        },
    },
    rebuild_value_meter: {
        id: 'fd1c4a4f-2bf7-49a9-87d5-6d865b49a069',
        name: 'rebuild_value_meter',
        label: "Herbouwwaardemeter",
        dosLinks: [],
        fields: {}
    },
    referral_care: {
        id: 'bf00aafe-db52-4270-a032-320600873633',
        name: 'referral_care',
        label: "Verwijsbrief",
        dosLinks: ['medical_data'],
        fields: {}
    },
    rent_agreement: {
        id: 'b9aeb675-ace0-41ef-b7d5-344fc6cc0e7c',
        name: 'rent_agreement',
        label: "Huurovereenkomst",
        dosLinks: [],
        fields: {}
    },
    research_results_care: {
        id: '6ad0f136-b6c7-4887-8ce4-2ce5e7ad88af',
        name: 'research_results_care',
        label: "Onderzoeksresultaat",
        dosLinks: ['medical_data', 'general_practitioner', 'dentist', 'specialist'],
        fields: {}
    },
    reservation_rental_car_camper: {
        id: '8716a453-ff33-4dd1-be44-2a4c74f1758b',
        name: 'reservation_rental_car_camper',
        label: "Reservering huurauto / camper",
        dosLinks: ['travel', 'camping',],
        fields: {}
    },
    reservation_transfer_taxi: {
        id: '88624521-919c-477b-a524-9cbbe46d88cb',
        name: 'reservation_transfer_taxi',
        label: "Reservering transfer/taxi",
        dosLinks: ['travel', 'camping',],
        fields: {}
    },
    roadside: {
        id: 'b48aeeea-810e-4756-9a4b-237228f709d9',
        name: 'roadside',
        label: "Wegenwacht",
        dosLinks: ['car'],
        fields: {}
    },
    sales_receipt: {
        id: '8f459509-1566-47fa-b2b4-6efe2ed2360c',
        name: 'sales_receipt',
        label: "Aankoopbon / Garantiebewijs",
        dosLinks: [ 'misc_purchases', 'cycle_transport'],
        fields: {}
    },
    security_certificate: {
        id: '19b47059-fc8e-4b09-b098-0045de1b9fcd',
        name: 'security_certificate',
        label: "Beveiligingscertificaat",
        dosLinks: [],
        fields: {}
    },
    sewage_and_water_taxes_property: {
        id: '161c621b-02af-41aa-85c4-bfc82501deb3',
        name: 'sewage_and_water_taxes_property',
        label: "Aanslag rioolheffing & waterschapsbelasting",
        dosLinks: [],
        fields: {}
    },
    sportpass_sport: {
        id: 'f8afc0b0-c3cd-4fc0-af6d-ac0dee7c5801',
        name: 'sportpass_sport',
        label: "Sportpas",
        dosLinks: ['personal_hobby'],
        fields: {}
    },
    student_card_study: {
        id: 'cedf9301-2737-4b14-8997-defa71141bee',
        name: 'student_card_study',
        label: "Studentenkaart",
        dosLinks: ['study', 'passes',],
        fields: {
            year: {
                id: 'f5a0f57b-3a51-4f30-8473-02bcd5c40ff4',
                label: 'Jaar',
                options: null,
                required: false,
                type: 'NUMBER',
                name: 'year',
            },
        },
    },
    subscription_details: {
        id: '65f84698-71fa-47b7-a5c9-28f800d00f5d',
        name: 'subscription_details',
        label: "Abonnementsgegevens",
        dosLinks: ['phone_electronics', 'television_electronics', 'subscriptions', 'personal_hobby',],
        fields: {}
    },
    taxation_report_car: {
        id: '2d1944aa-3e85-4e54-aa4d-4422f9f0884e',
        name: 'taxation_report_car',
        label: "Taxatierapport auto",
        dosLinks: ['car'],
        fields: {}
    },
    taxation_report_house: {
        id: '977deb62-fe5f-4b51-a59f-7980c0ec3371',
        name: 'taxation_report_house',
        label: "Taxatierapport woning",
        dosLinks: [],
        fields: {}
    },
    traffic_ticket_transport: {
        id: 'eecd8f41-c3d4-4c90-b28b-17a5cf24f576',
        name: 'traffic_ticket_transport',
        label: "Verkeersboetes",
        dosLinks: ['car'],
        tools: {
            expireDate: {
                icon: "common-icon-alarm-clock",
                description: "Herindering als je verkeersboete verloopt",
                herinderingDescription: "Je boete _name moet betaald zijn op expire_date !",
                actions: {},
                inputs: {}
            }
        },
        fields: {}
    },
    train_subscription_transport: {
        id: '85a0db31-1edb-48df-9be8-9f9c98762ecf',
        name: 'train_subscription_transport',
        label: "Trein/OV-abonnement",
        dosLinks: ['travel','subscriptions'],
        fields: {}
    },
    travel_directions_trips: {
        id: 'b492383e-d084-4e77-8506-5ffce87702ed',
        name: 'travel_directions_trips',
        label: "Route-beschrijving",
        dosLinks: ['travel'],
        fields: {}
    },
    // TODO Remove since we have a common insurance policy document
    travel_insurance: {
        id: '660531cd-1a67-4797-ac71-8ccf10412f6a',
        name: 'travel_insurance',
        label: "Reisverzekering polis",
        dosLinks: ['travel','misc_insurance'],
        fields: {}
    },
    travel_visa: {
        id: '7e7c0f61-5a8e-4e8d-829d-12b1cec320bb',
        name: 'travel_visa',
        label: "Visum",
        dosLinks: ['travel', 'camping',],
        fields: {
            country: {
                id: 'de48452c-40d7-41a3-8e73-423c45128305',
                label: 'Land van bestemming',
                options: null,
                required: false,
                type: 'STRING',
                name: 'country',
            },
            valid_from: {
                id: '5439813b-7946-45ad-bbd1-7b2479eb0190',
                label: 'Geldig vanaf',
                options: null,
                required: false,
                type: 'DATE',
                name: 'valid_from',
            },
            valid_until: {
                id: '5439813b-7946-45ad-bbd1-7b2479eb0190',
                label: 'Geldig tot',
                options: null,
                required: false,
                type: 'DATE',
                name: 'valid_until',
            },
            type: {
                id: '8beda3cc-8db2-4418-9fc0-7b90802028b4',
                label: 'Type',
                options: null,
                required: false,
                type: 'STRING',
                name: 'type',
            },
        },
    },
    vaccination_card_holiday: {
        id: '0d511f81-b3b1-4b5c-9188-495ce16d4090',
        name: 'vaccination_card_holiday',
        label: "Vaccinatiebewijs",
        dosLinks: ['medical_data', 'general_practitioner',],
        fields: {
            type: {
                id: '8beda3cc-8db2-4418-9fc0-7b90802028b4',
                label: 'Type',
                options: null,
                required: false,
                type: 'STRING',
                name: 'type',
            },
        },

    },
    vehicle_registration: {
        id: 'ad497f56-7e02-4c23-8d1b-1642c76883eb',
        name: 'vehicle_registration',
        label: "Kentekenbewijs",
        dosLinks: ['car'],
        fields: {}
    },
    vog_personal: {
        id: '71db6c6c-c63b-4646-94dc-02c9ec922846',
        name: 'vog_personal',
        label: "Verklaring Omtrent Gedrag (VOG)",
        dosLinks: [],
        fields: {}
    },
    vve_notule_property: {
        id: '4b295bb7-41c0-442c-8e68-91fcd62c80db',
        name: 'vve_notule_property',
        label: "VVE regelement / notulen",
        dosLinks: ['house','rental_house'],
        fields: {}
    },
    will_personal: {
        id: 'b97aa369-8c65-4fc9-b26a-7fe63887d219',
        name: 'will_personal',
        label: "Testament",
        dosLinks: ['personal_data'],
        fields: {}
    },
    internship_agreement: {
        id: '83d71a99-74d5-498a-ab0c-ba1b2d7fa3cb',
        name: 'internship_agreement',
        label: 'Stageovereenkomst',
        dosLinks: ['intership', 'study',],
        fields: {
            start_date: {
                id: 'c58577ba-a7e0-4c1e-8573-e346c51e983b',
                label: 'Begindatum',
                options: null,
                required: false,
                type: 'DATE',
                name: 'start_date',
            },
            end_date: {
                id: '95271388-8820-419b-9043-cfa241394c2f',
                label: 'Einddatum',
                options: null,
                required: false,
                type: 'DATE',
                name: 'end_date',
            },
        },
    },
    uncategorised: {
        id: 'cf022726-0b56-4244-b826-cc00c91ef004',
        name: 'uncategorised',
        label: 'Geen type bekend',
        dosLinks: [],
        fields: {},
    },
    miscellaneous_personal: {
        id: '8e84953c-4a13-4825-9182-c10ce9cbdcae',
        name: 'miscellaneous_personal',
        label: 'Persoonlijke documenten',
        dosLinks: [],
        fields: {},
    },
    miscellaneous_personal_LifeWill: {
        id: 'REPLACE_ID_LifeWill',
        name: 'miscellaneous_personal_LifeWill',
        label: 'Levenstestament',
        dosLinks: ['personal_data'],
        fields: {},
        replaceID: '8e84953c-4a13-4825-9182-c10ce9cbdcae',
    },
    miscellaneous_personal_DeathCertificate: {
        id: 'REPLACE_ID_DeathCertificate',
        name: 'miscellaneous_personal_DeathCertificate',
        label: 'Overlijdensakte',
        dosLinks: ['personal_data'],
        fields: {},
        replaceID: '8e84953c-4a13-4825-9182-c10ce9cbdcae',
    },
    miscellaneous_personal_InheritenceCertificate: {
        id: 'REPLACE_ID_InheritenceCertificate',
        name: 'miscellaneous_personal_InheritenceCertificate',
        label: 'Verklaring van erfrecht',
        dosLinks: ['personal_data'],
        fields: {},
        replaceID: '8e84953c-4a13-4825-9182-c10ce9cbdcae',
    },
    miscellaneous_personal_Other: {
        id: 'REPLACE_ID_Personal_Other',
        name: 'miscellaneous_personal_Other',
        label: 'Overige documenten',
        dosLinks: ['personal_data','misc_gen'],
        fields: {},
        replaceID: '8e84953c-4a13-4825-9182-c10ce9cbdcae',
    },
    miscellaneous_personal_NoTreat: {
        id: 'REPLACE_ID_NoTreat',
        name: 'miscellaneous_personal_NoTreat',
        label: 'Niet-behandelverklaring',
        dosLinks: ['miscellaneous_care'],
        fields: {},
        replaceID: '8e84953c-4a13-4825-9182-c10ce9cbdcae',
    },
    miscellaneous_personal_LifeWish: {
        id: 'REPLACE_ID_LifeWish',
        name: 'miscellaneous_personal_LifeWish',
        label: 'NPV-levenswensverklaring',
        dosLinks: ['miscellaneous_care'],
        fields: {},
        replaceID: '8e84953c-4a13-4825-9182-c10ce9cbdcae',
    },
    miscellaneous_personal_Euthanasia: {
        id: 'REPLACE_ID_Euthanasia',
        name: 'miscellaneous_personal_Euthanasia',
        label: 'Euthenasieverklaring',
        dosLinks: ['miscellaneous_care'],
        fields: {},
        replaceID: '8e84953c-4a13-4825-9182-c10ce9cbdcae',
    },
    miscellaneous_personal_Dementia: {
        id: 'REPLACE_ID_Dementia',
        name: 'miscellaneous_personal_Dementia',
        label: 'Dementieverklaring',
        dosLinks: ['miscellaneous_care'],
        fields: {},
        replaceID: '8e84953c-4a13-4825-9182-c10ce9cbdcae',
    },
    miscellaneous_personal_Lifewill_Other: {
        id: 'REPLACE_ID_Lifewill_Other',
        name: 'miscellaneous_personal_Lifewill_Other',
        label: 'Overige',
        dosLinks: ['miscellaneous_care'],
        fields: {},
        replaceID: '8e84953c-4a13-4825-9182-c10ce9cbdcae',
    },
    curriculum_vitae: {
        id: '5fa33a15-0fe2-49f0-a515-ef81545ec926',
        name: 'curriculum_vitae',
        label: 'CV',
        dosLinks: [ 'study',],
        fields: {},
    },
    tax_return: {
        id: '41f1978e-4b8e-446c-8f09-70a64e740336',
        name: 'tax_return',
        label: 'Belastingaangifte',
        dosLinks: ['tax_year',],
        fields: {}
    },
    tax_return_provisional: {
        id: 'REPLACE_ID_Tax_return_provisional',
        name: 'tax_return_provisional',
        label: 'Voorlopige aanslag',
        dosLinks: ['tax_year'],
        fields: {},
        replaceID: '41f1978e-4b8e-446c-8f09-70a64e740336',
    },
    tax_return_final: {
        id: 'REPLACE_ID_Tax_return_final',
        name: 'tax_return_final',
        label: 'Definitieve aanslag',
        dosLinks: ['tax_year'],
        fields: {},
        replaceID: '41f1978e-4b8e-446c-8f09-70a64e740336',
    },
    energy_contract: {
        id: 'cf68f298-789e-4361-b0bb-7da2663a4f02',
        name: 'energy_contract',
        label: 'Energiecontract',
        dosLinks: ['energy', 'rental_house','house','subscriptions'],
        fields: {
            start_date: {
                id: 'c58577ba-a7e0-4c1e-8573-e346c51e983b',
                label: 'Begindatum',
                options: null,
                required: false,
                type: 'DATE',
                name: 'start_date',
            },
            end_date: {
                id: '95271388-8820-419b-9043-cfa241394c2f',
                label: 'Einddatum',
                options: null,
                required: false,
                type: 'DATE',
                name: 'end_date',
            },
            supplier: {
                id: '56768b25-20f3-44b1-9b7d-4818a8ad70e5',
                label: 'Leverancier',
                options: null,
                required: false,
                type: 'STRING',
                name: 'supplier',
            },
            price: {
                id: '915a310d-7d9c-4300-9e38-c4a9e87a42de',
                label: 'Bedrag',
                options: null,
                required: false,
                type: 'PRICE',
                name: 'price',
            },
        },
    },
    insurance_invoice: {
        id: '2326c73a-1755-4163-88fd-e05c39dfb901',
        name: 'insurance_invoice',
        label: 'Factuur verzekering',
        dosLinks: ['contents_insurance', 'liability_insurance', 'car_insurance', 'travel_insurance',
            'health_insurance_care', 'cancel_insurance','misc_insurance'],
    },
    birth_certificate: {
        id: 'f2d7160d-4f17-486b-b753-370acbdd5593',
        name: 'birth_certificate',
        label: 'Geboorteakte',
        dosLinks: [],
    },
    annual_report: {
        id: '28a49d26-f5c2-4324-bb68-2f34733cc78a',
        name: 'annual_report',
        label: 'Jaarrekening',
        dosLinks: ['energy', 'water'],
        fields: {
            year: {
                id: 'f5a0f57b-3a51-4f30-8473-02bcd5c40ff4',
                label: 'Jaar',
                options: null,
                required: false,
                type: 'NUMBER',
                name: 'year',
            },
            price: {
                id: '915a310d-7d9c-4300-9e38-c4a9e87a42de',
                label: 'Bedrag',
                options: null,
                required: false,
                type: 'PRICE',
                name: 'price',
            },
        },
    },
    camping_gear: {
        id: '99aab618-8080-40d7-bd81-31cec44a5116',
        name: 'camping_gear',
        label: 'Kampeeruitrusting',
        dosLinks: ['camping',],
        fields: {
            purchase_value: {
                id: '6dfea088-eb8d-41c8-95a6-0b4e5d712854',
                label: 'Aankoopwaarde',
                options: null,
                required: false,
                type: 'PRICE',
                name: 'purchase_value',
            },
            purchase_date: {
                id: 'b7b3be68-9424-40f7-a8e7-9e797f800e84',
                label: 'Aankoopdatum',
                options: null,
                required: false,
                type: 'DATE',
                name: 'purchase_date',
            },
            warranty_period: {
                id: 'f7fc8f8a-2f24-429d-a200-f989556d56ea',
                label: 'Garantie tot',
                options: null,
                required: false,
                type: 'DATE',
                name: 'warranty_period',
            },
        },

    },
    payroll: {
        id: '8c0a0048-ee44-4d4c-ac03-9c85197580e6',
        name: 'payroll',
        label: 'Loonstrook',
        fields: {
            period: {
                id: 'b6bac494-2786-47d0-9fd9-8384eec6f0d7',
                label: 'Periode',
                options: null,
                required: false,
                type: 'STRING',
                name: 'period',
            },
        },
        dosLinks: [],
    },
    sports_gear: {
        id: 'd06a8a25-2bdd-47ef-a402-818d87347d23',
        name: 'sports_gear',
        label: 'Sportuitrusting',
        dosLinks: ['sport',],
        fields: {
            purchase_value: {
                id: '6dfea088-eb8d-41c8-95a6-0b4e5d712854',
                label: 'Aankoopwaarde',
                options: null,
                required: false,
                type: 'PRICE',
                name: 'purchase_value',
            },
            purchase_date: {
                id: 'b7b3be68-9424-40f7-a8e7-9e797f800e84',
                label: 'Aankoopdatum',
                options: null,
                required: false,
                type: 'DATE',
                name: 'purchase_date',
            },
            warranty_period: {
                id: 'f7fc8f8a-2f24-429d-a200-f989556d56ea',
                label: 'Garantie tot',
                options: null,
                required: false,
                type: 'DATE',
                name: 'warranty_period',
            },
        },
    },
    insurance_conditions: {
        id: '0d20d310-891c-4d7b-8d5c-d645cae85583',
        name: 'insurance_conditions',
        label: 'Verzekeringsvoorwaarden',
        dosLinks: ['contents_insurance', 'liability_insurance', 'car_insurance', 'travel_insurance',
            'health_insurance_care', 'cancel_insurance', 'health_insurance_care','misc_insurance'],
        fields: {}
    },
    cross_list: {
        id: '92a2413e-5737-499f-bbb3-12ae2ad51d7a',
        name: 'cjp_pass_trips',
        label: 'Streeplijst',
        dosLinks: [],
        fields: {}
    },
    tv_contract: {
        id: '31bef70a-a982-4777-89c5-7f5ab4facfd1',
        name: 'tv_contract',
        label: 'Tv-/internetcontract',
        dosLinks: ['subscriptions', 'television_electronics'],
        fields: {
            start_date: {
                id: 'c58577ba-a7e0-4c1e-8573-e346c51e983b',
                label: 'Begindatum',
                options: null,
                required: false,
                type: 'DATE',
                name: 'start_date',
            },
            end_date: {
                id: '95271388-8820-419b-9043-cfa241394c2f',
                label: 'Einddatum',
                options: null,
                required: false,
                type: 'DATE',
                name: 'end_date',
            },
            supplier: {
                id: '56768b25-20f3-44b1-9b7d-4818a8ad70e5',
                label: 'Leverancier',
                options: null,
                required: false,
                type: 'STRING',
                name: 'supplier',
            },
            price: {
                id: '915a310d-7d9c-4300-9e38-c4a9e87a42de',
                label: 'Bedrag',
                options: null,
                required: false,
                type: 'PRICE',
                name: 'price',
            },
        },
    },
    water_contract: {
        id: 'b9f6b4f7-4908-4350-b445-123a6705c366',
        name: 'water_contract',
        label: 'Watercontract',
        dosLinks: ['water','subscriptions'],
        fields: {
            start_date: {
                id: 'c58577ba-a7e0-4c1e-8573-e346c51e983b',
                label: 'Begindatum',
                options: null,
                required: false,
                type: 'DATE',
                name: 'start_date',
            },
            end_date: {
                id: '95271388-8820-419b-9043-cfa241394c2f',
                label: 'Einddatum',
                options: null,
                required: false,
                type: 'DATE',
                name: 'end_date',
            },
            supplier: {
                id: '56768b25-20f3-44b1-9b7d-4818a8ad70e5',
                label: 'Leverancier',
                options: null,
                required: false,
                type: 'STRING',
                name: 'supplier',
            },
            price: {
                id: '915a310d-7d9c-4300-9e38-c4a9e87a42de',
                label: 'Bedrag',
                options: null,
                required: false,
                type: 'PRICE',
                name: 'price',
            },
        },
    },
    glasses: {
        id: 'd6120f5e-c2c0-4745-89ac-ef0d962c3c42',
        name: 'glasses',
        label: 'Bril',
        dosLinks: ['medical_tools'],
    },
    hearing_aid: {
        id: '6fc11cf9-4b72-4dc0-af15-beeea4edce87',
        name: 'hearing_aid',
        label: 'Gehoorapparaat',
        dosLinks: ['medical_tools'],
    },
    lenses: {
        id: 'a9a4070c-03c3-4c06-9e11-f148a8a97666',
        name: 'lenses',
        label: 'Lenzen',
        dosLinks: ['medical_tools'],
    },
};

const navigateUrl = url => {
    window.externalLink(url);
};

const getDocumentType = name => {
    if (utils.isNotNull(name)) {
        let value = mapping[name];
        if (utils.isNotNull(value)) {
            return _.merge({}, value);
        }
        utils.warn('DocTypeMapping does not contain an entry with name \'' + name + '\'.');
        // Returning miscellaneous_indoors to support older types which we don't use anymore,
        // but may still exist in accounts.
        return _.merge({}, mapping['miscellaneous_indoors']);
    } else {
        return mapping;
    }
};

export const getDocTypeById = id => {
    let keys = Object.keys(getDocumentType());
    for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        let docType = getDocumentType(key);
        if (docType.id === id) return docType;
    }
    warn('DossierTypeMapping does not contain an entry with id \'' + id + '\'.');
    return getDocumentType('miscellaneous_indoors');
};

export const getDocByLabel = label => {
    let keys = Object.keys(getDocumentType());
    for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        if (getDocumentType(key).label === label) {
            return getDocumentType(key);
        }
    }
    utils.warn('DossierTypeMapping does not contain an entry with label \'' + label + '\'.');
    // Returning miscellaneous_indoors to support older types which we don't use anymore,
    // but may still exist in accounts.
    return getDocumentType('miscellaneous_indoors');
};

export const getKeyByLabel = label => {
    let keys = Object.keys(getDocumentType());
    for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        if (getDocumentType(key).label === label) {
            return key;
        }
    }
    utils.warn('DossierTypeMapping does not contain an entry with label \'' + label + '\'.');
    // Returning miscellaneous_indoors to support older types which we don't use anymore,
    // but may still exist in accounts.
    return 'miscellaneous_indoors';
};

export const initializeDocTypes = () => {
    const mode = getSetting('mode');
    if (mode !== 'prod') {
        const keys = Object.keys(mapping);
        forEach(keys, key => {
            let docType = mapping[key];
            if (!docType.hasOwnProperty('fields'))
                docType.fields = {};
            mapping[key] = docType;
        });
    }
};

export default getDocumentType;
