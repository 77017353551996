import './style/quickScan.scss';

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';

import {capitalizeFirst, toMoney} from 'glob-common-js/lib/utils';

import CurrentInsuranceForm from "../currentInsurance/currentInsuranceForm";
import {sendGaEvent} from "../../../../common/js/ga";
import MaterialFactory from "../../../material/materialFactory";
import {materialTypes} from "../../../material/materialTypes";
import {dispatchCustomEvent} from "../../../../misc/eventDispatcher";
import {QUICK_DATA, QUICK_RESULT, QUICK_RESULT_2} from "./quickScanController";
import {setAuthParameter} from "../../../authentication/authenticationHelper";

const logo = require('../../../../common/images/logo_bydehand.jpg');
const GA_CATEGORY = 'Quickscan zorg';

export class QuickScan extends React.Component {
    onSubmitEvent = () => {
        sendGaEvent(GA_CATEGORY, 'klik', 'Start quick scan');
    };

    onClickMore = () => {
        sendGaEvent(GA_CATEGORY, 'klik', 'Uitgebreide analyse');
        if (!window.localStorage)
            dispatchCustomEvent('showAlert', {
                text: 'Je browser ondersteunt niet het tijdelijk opslaan van data. ' +
                    'Hierdoor moet je na het inloggen/registereren opnieuw je verzekering selecteren.',
                actions: [{
                    label: 'Oke', onClick: () => {
                        setAuthParameter('action', 'full_health_analysis');
                        window.location = 'https://www.bydehand.com/zorgverzekeringsanalyse-hoewerkthet';
                    }
                }]
            });
        else {
            setAuthParameter('action', 'full_health_analysis');
            window.location = 'https://www.bydehand.com/zorgverzekeringsanalyse-hoewerkthet';
        }
    };

    getDifferenceAmount = () => {
        const {alternatives} = this.props.analysisData;
        for (let i = 0; i < alternatives.length; i++) {
            const alt = alternatives[i];
            if (alt.ranking === 1) {
                return Math.max(0, alt.text) + this.getRiskDiff();
            }
        }
        return this.getRiskDiff();
    };

    getSaveText = () => {
        const {alternatives} = this.props.analysisData;
        let text = toMoney(0);
        let diff = 0;
        for (let i = 0; i < alternatives.length; i++) {
            const alt = alternatives[i];
            if (alt.ranking === 1) {
                diff = Math.max(alt.text, 0);
                text = toMoney(Math.abs(diff));
                break;
            }
        }
        if (diff === 0)
            return 'Je hebt de goedkoopste verzekeringspremie in 2019';
        return `Je kan ${text} per jaar besparen`;
    };

    getSaveDescription = () => {
        const currentInsurer = this.getCurrentInsurer();
        const graphData = this.getGraphData();
        const diff = Math.max(this.getDifferenceAmount(), 0);
        if (diff === 0)
            return 'Wanneer je tevreden bent over de service van je verzekeraar is overstappen niet nodig.';
        return `Je premie in 2019 bedraag ${toMoney(currentInsurer.monthprice)}.
                    ${capitalizeFirst(graphData.betterText)}`
    };

    getTypeSaveText = () => {
        const currentInsurer = this.getCurrentInsurer(), type = currentInsurer.basic.type;
        switch (type) {
            case 'Budgetpolis':
                return {
                    sub: 'Je hebt een zeer beperkte vrije zorgkeuze',
                    desc: 'Met een budgetpolis zijn er maar een beperkt aantal gecontracteerde zorgverleners die je ' +
                        'kosten volledig vergoeden. Met een nature- of restitutiepolis heb een vrijere zorgkeuze.'
                };
            case 'Naturapolis':
            case 'Combinatiepolis':
                return {
                    sub: 'Je hebt een ruime vrije zorgkeuze',
                    desc: 'Met een naturapolis heb je een ruim aantal gecontracteerde zorgverleners die je kosten ' +
                        'volledig vergoeden. Met een restitutiepolis heb je een volledig vrije zorgkeuze.'
                };
            case 'Restitutiepolis':
                return {
                    sub: 'Je hebt een volledig vrije zorgkeuze',
                    desc: 'Met een restitutiepolis heb je een alle gecontracteerde zorgverleners die je kosten volledig ' +
                        'vergoeden. Budget- en naturapolissen zijn goedkoper.',
                }
        }
        return {sub: 'Polis onbekend', desc: ''};
    };

    getAdditionalText = () => {
        const additionals = this.getCoverages().length;
        if (additionals > 0)
            return {
                sub: 'Betaal niet langer voor zorg die je niet gebruikt',
                desc: `Je hebt dekking op ${additionals} verschillende soorten zorgkosten. Maak je hier wel gebruik van?`
            };
        return {
            sub: 'Let op: je hebt geen aanvullende dekking',
            desc: 'Je hebt geen dekking op fysiotherapie, brillen of lenzen of alternatieve zorg. Maak je hier in 2019 ' +
                'geen gebruik van?'
        };
    };

    getRiskSaveText = () => {
        const {risk, basic} = this.props.analysisData;
        const currentRisk = basic.risk, breakEven = risk.change_value;
        if (currentRisk === 885)
            return {
                sub: 'Verlaag je eigen risico en betaal niet onnodig extra',
                desc: `Als je je eigen risico naar € 385,- verlaagd kom je niet voor onnodig kosten te staan. Let op 
                dit is pas voordelig als je zorgkosten minder dan ${toMoney(breakEven)} bedragen.`
            };
        const diffRisk = this.getRiskDiff();
        return {
            sub: `Je kan ${toMoney(diffRisk)} besparen op je eigen risico`,
            desc: `Als je je eigen risico verhoogt naar ${toMoney(885)} kun je ${toMoney(diffRisk)} extra 
            besparen. Let op, dit is pas voordelig als je zorgkosten lager zijn dan ${toMoney(breakEven)}.`
        }
    };

    getRiskDiff = () => {
        const {risk, basic} = this.props.analysisData;
        const currentRisk = basic.risk, breakEven = risk.change_value;
        const maxRisk = risk['885'];

        if (currentRisk === 385) return maxRisk;
        return maxRisk - risk[currentRisk];
    };

    getCoverages = () => {
        const {additionals} = this.props.analysisData;
        const covered = [];
        forEach(additionals, additional => {
            const parts = additional.parts_covered;
            forEach(parts, part => {
                const label = part.part;
                if (!covered.includes(label))
                    covered.push(label);
            })
        });
        return covered;
    };

    getCurrentInsurer = () => {
        const {alternatives} = this.props.analysisData;
        for (let i = 0; i < alternatives.length; i++) {
            const alt = alternatives[i];
            if (alt.ranking === 0)
                return alt;
        }
        throw new Error('Current insurance not found');
    };

    getGraphData = () => {
        const {insurer} = this.props.analysisData;

        const graphWidth = 100;
        const currentPriceWidth = 15;
        const currentArrowWidth = 27;

        const stripeCount = Math.min(8, insurer.stripe_count);
        const gapSize = (Math.max(insurer.most_expensive, insurer.price) - insurer.cheapest) / (stripeCount - 3);
        const tdWidth = graphWidth / (stripeCount - 1);
        let betterStart = ((insurer.cheapest - (insurer.cheapest - gapSize)) / gapSize) * tdWidth;
        const betterDifference = insurer.price - insurer.cheapest;
        const betterLength = ((betterDifference / gapSize) * tdWidth) - (currentPriceWidth / 2);
        const worseDifference = insurer.most_expensive - insurer.price;
        let worseLength = Math.max(((worseDifference / gapSize) * tdWidth) - (currentPriceWidth / 2), 0);

        let currentArrowOffset = ((betterStart + betterLength + (currentPriceWidth / 2)) - (currentArrowWidth / 2));
        if (worseLength > (graphWidth - (currentPriceWidth + tdWidth + tdWidth))) {
            betterStart = (graphWidth - (currentPriceWidth + tdWidth + worseLength)) + 1;
            currentArrowOffset += 1;
            worseLength -= 1;
        }
        return {
            rulerAmount: stripeCount,
            gapSize,
            rulerStart: insurer.cheapest,
            tdWidth,
            betterStart,
            betterLength,
            worseLength,
            currentPriceWidth,
            currentPrice: insurer.price,
            betterText: insurer.text_green,
            worseText: insurer.text_red,
            saveAmountText: insurer.text_save_pig,
            currentArrowOffset,
            currentArrowWidth,
        }
    };

    setBodyPadding = (less = false) => {
        const body = document.querySelector('div.quickScanBody');
        if (isNotNull(body)) {
            if (less && !body.classList.contains('lessPad'))
                body.classList.add('lessPad');
            else if (!less)
                body.classList.remove('lessPad');
        }
    };

    renderDataStep = () => {
        this.setBodyPadding();
        return this.renderMainStructure(
            <>
                <div className='quickScanForm'>
                    <label className='quickScanTitle black'>Start de gratis quickscan en zie direct hoeveel geld jij in
                        2019 kunt besparen</label>
                    <CurrentInsuranceForm submitText='Verder'
                                          onSubmitEvent={this.onSubmitEvent} {...this.props}/>
                </div>
                <div className='quickScanQuote'>
                    <p className='quoteText'>"ByDeHand maakt consumenten wegwijs in het oerwoud aan
                        zorgverzekeringen. Krijg meteen inzichtelijk of je huidige verzekering voldoet of dat je
                        beter kan overstappen!"</p>
                    <label className='pictureTitle'>Adriaan (financieel analist)</label>
                    <img src='https://www.bydehand.com/wp-content/uploads/2016/10/IMG_6620-300x270.jpg'
                         className='quotePicture' alt='ByDeHand.com'/>
                </div>
            </>
        )
    };

    renderResultStep_v2 = () => {
        this.setBodyPadding();
        const {sub, desc} = this.getRiskSaveText(), typeTexts = this.getTypeSaveText(),
            additionTexts = this.getAdditionalText();
        return this.renderMainStructure(
            <div className='resultStepContainer_v2'>
                <label className='resultTitle'>Resultaat</label>
                {this.renderResultRow('Prijs', this.getSaveText(), this.getSaveDescription())}
                {this.renderResultRow('Type polis', typeTexts.sub, typeTexts.desc)}
                {this.renderResultRow('Eigen risico', sub, desc)}
                {this.renderResultRow('Aanvullende dekking', additionTexts.sub, additionTexts.desc)}
                {this.renderProgressBullets()}
                <MaterialFactory componentType={materialTypes.RAISED_BUTTON} className='proceedButton'
                                 onClick={this.props.onSetQuickStep(QUICK_RESULT_2)}>
                    Verder</MaterialFactory>
            </div>
        )
    };

    renderProgressBullets = () => {
        const {quickScanStep} = this.props;
        return (
            <div className='progressBulletsContainer'>
                <div className={classNames('progressBullet', quickScanStep === QUICK_RESULT && 'selected')}
                     onClick={this.props.onSetQuickStep(QUICK_RESULT)}/>
                <div className={classNames('progressBullet', quickScanStep === QUICK_RESULT_2 && 'selected')}
                     onClick={this.props.onSetQuickStep(QUICK_RESULT_2)}/>
            </div>
        );
    };

    renderResultRow = (title, subTitle, description) => (
        <div className='resultRow'>
            <label className='resultRowTitle'>{title}</label>
            <div className='resultRowSubContainer'>
                <label className='resultRowSubTitle'>{subTitle}</label>
                <p className='resultRowDescription'>{description}</p>
            </div>
        </div>
    );

    renderResultTwoStep = () => {
        this.setBodyPadding(true);
        const {classes} = this.props;
        return this.renderMainStructure(
            <div className='resultStepContainerTwo'>
                <label className='subTitle'>Niet tevreden met je huidige zorgverzekering?</label>
                <label className='title'>Doe de uitgebreide analyse en vind de zorgverzekering die bij jou
                    past.</label>
                <div className='potentialSaveContainer'>
                    <label className='potentialTitle'>Bespaarpotentie op je zorgverzekering in 2019:</label>
                    <label className='potentialAmount'>{toMoney(Math.max(this.getDifferenceAmount(), 0))}</label>
                </div>
                {this.renderProgressBullets()}
                <MaterialFactory componentType={materialTypes.RAISED_BUTTON} onClick={this.onClickMore}
                                 className='proceedButton'>
                    Verder</MaterialFactory>
            </div>
        )
    };

    renderMainStructure = body => (
        <div className='quickScan'>
            <div className='quickScanHeader'>
                <label className='headerTitle'>Quickscan zorgverzekering</label>
            </div>
            <div className='quickScanBody'>
                {body}
            </div>
        </div>
    );

    render = () => {
        const {quickScanStep} = this.props;
        switch (quickScanStep) {
            case QUICK_DATA:
                return this.renderDataStep();
            case QUICK_RESULT:
                return this.renderResultStep_v2();
            case QUICK_RESULT_2:
                return this.renderResultTwoStep();
            default:
                throw new Error(`Invalid quick scan step: ${quickScanStep}`);
        }
    };
}

const styles = () => ({
    iconRoot: {
        color: '#009fe3',
    },
    stepperLabel: {
        color: 'black',
        fontSize: '1rem',
    },
    stepConnector: {
        padding: 0,
    }
});

QuickScan.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onSelectInsurer: PropTypes.func.isRequired,
    onSelectBasic: PropTypes.func.isRequired,
    onSelectAdditional: PropTypes.func.isRequired,
    onSetQuickStep: PropTypes.func.isRequired,
    insurers: PropTypes.arrayOf(PropTypes.string).isRequired,
    basics: PropTypes.arrayOf(PropTypes.object).isRequired,
    additionals: PropTypes.arrayOf(PropTypes.object).isRequired,
    dentals: PropTypes.arrayOf(PropTypes.object).isRequired,
    initialSelected: PropTypes.object.isRequired,
    userIsLoggedIn: PropTypes.bool.isRequired,
    isInitialLoaded: PropTypes.bool.isRequired,
    quickScanStep: PropTypes.number.isRequired,
    analysisData: PropTypes.shape({
        alternatives: PropTypes.arrayOf(PropTypes.shape({
            ranking: PropTypes.number.isRequired,
            difference: PropTypes.string.isRequired,
            text: PropTypes.number.isRequired,
        })).isRequired,
    }).isRequired,
};

export default withStyles(styles)(QuickScan)