import React from 'react';
import {connect} from 'react-redux';

import ForgotPasswordView from "./forgotPasswordView";
import ErrorBoundary from "../../errorBoundary";
import {resetPassword} from "../../../misc/requestSender";
import {sendPageView} from "../../../common/js/ga";

export class ForgotPasswordController extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            requestConfirmed: false,
            containsError: false,
            loading: false,
        };
        this._isMounted = false;
    }

    changeState = stateChange => {
        this._isMounted && this.setState(stateChange);
    };

    onSubmit = (email) => {
        this.changeState({loading: true});
        resetPassword({
            email,
            callback: (response) => {
                this.changeState({loading: false});
                if (response.data.status === 'success') {
                    this.changeState({requestConfirmed: true});
                }
            }
        });
    };

    getDefaultEmail = () => {
        return this.props.miscState.emailInput;
    };

    componentDidMount = () => {
        this._isMounted = true;
        sendPageView('/wachtwoord-vergeten');
    };

    componentWillUnmount = () => {
        this._isMounted = false;
    };

    render = () => {
        return (
            <ErrorBoundary>
                <ForgotPasswordView requestConfirmed={this.state.requestConfirmed} loading={this.state.loading}
                                    containsError={this.state.containsError}
                                    onSubmit={this.onSubmit} defaultEmail={this.getDefaultEmail()}/>
            </ErrorBoundary>
        );
    }
}

const mapStateToProps = (state) => ({
    miscState: state.misc,
});

export default connect(mapStateToProps)(ForgotPasswordController);