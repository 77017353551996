import React from 'react';
import PropTypes from 'prop-types';

import {Route, Switch, withRouter} from 'react-router-dom';
//Components
import CompareController from "./compare/compareController";
import CoverageController from "./coverage/coverageController";

export class EditInsuranceView extends React.Component {
    constructor(props) {
        super(props);
    }

    render = () => {
        return (
            <Switch>
                <Route path='/zorganalyse/aanpassen/dekking' render={() => {
                    return <CoverageController/>
                }}/>
                <Route path='/zorganalyse/aanpassen/vergelijken' render={() => {
                    return <CompareController activateFbShare={this.props.activateFbShare}/>
                }}/>
            </Switch>
        )
    }
}

EditInsuranceView.propTypes = {
    activateFbShare: PropTypes.func.isRequired,
};

export default withRouter(EditInsuranceView);
