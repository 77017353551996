import './style/dataView.scss';

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {sendGaEvent} from "../../../common/js/ga";
import MaterialFactory from "../../material/materialFactory";
import {materialTypes} from "../../material/materialTypes";

const GA_CATEGORY = 'Start flow 18-25';
export default class DataView20 extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            values: {
                study: {
                    toggled: true,
                    value: '',
                },
                nonResident: {
                    toggled: true,
                },
                digid: {
                    toggled: true,
                },
                stufi: {
                    toggled: true,
                    value: 269.45,
                },
                careAllowance: {
                    toggled: true,
                    value: 99,
                },
                tuition: {
                    toggled: true,
                    value: 2060,
                    frequency: 'monthly',
                },
                internship: {
                    toggled: true,
                    value: '',
                },
                internshipCompensation: {
                    toggled: true,
                    value: '',
                },
                loan: {
                    toggled: true,
                    value: '',
                }
            },
            studyError: null,
            scroll: undefined,
        };
        this._isMounted = false;
    }

    changeState = stateChange => {
        this._isMounted && this.setState(stateChange);
    };

    onSubmit = () => {
        let values = this.state.values;
        this.props.onSubmit(values);
    };

    changeValue = (name, value) => {
        let values = Object.assign({}, this.state.values);
        values[name].value = value;
        this.changeState({values});
    };

    changeToggle = (name, isToggled) => {
        sendGaEvent(GA_CATEGORY, 'Toggle', name + ' ' + (isToggled ? 'Aan' : 'Uit'));
        let values = Object.assign({}, this.state.values);
        values[name].toggled = isToggled;
        this.changeState({values});
    };

    changeResident = isToggled => {
        this.changeState({
            values: _.merge({}, this.state.values, {
                stufi: {
                    value: isToggled ? 269.45 : 82.56,
                },
                nonResident: {
                    toggled: isToggled,
                }
            })
        });
    };

    changeInternship = isToggled => {
        let compensationToggled = this.state.values.internshipCompensation.toggled;
        if (!isToggled && compensationToggled) {
            compensationToggled = false;
        }
        this.changeState({
            values: _.merge({}, this.state.values, {
                internship: {
                    toggled: isToggled,
                },
                internshipCompensation: {
                    toggled: compensationToggled,
                }
            })
        });
    };

    enableLoader = () => {
        this.loader.enable();
    };

    disableLoader = (callback) => {
        this.loader.disable(callback);
    };

    renderToggle = (name) => (
        <MaterialFactory componentType={materialTypes.TOGGLE} className='toggle' tabIndex={-1}
                         label={this.state.values[name].toggled ? 'Ja' : 'Nee'}
                         checked={this.state.values[name].toggled} onChange={(evt) => {
            this.changeToggle(name, evt.target.checked);
        }}/>
    );

    renderTextField = (id, name, type = 'text', disabled = false, hintText = 'Vul in...') => (
        <MaterialFactory componentType={materialTypes.TEXT} id={id} placeholder={hintText} type={type}
                         disabled={disabled}
                         value={this.state.values[name].value} fullWidth onChange={(evt) => {
            this.changeValue(name, evt.target.value)
        }}/>
    );

    componentDidMount = () => {
        this._isMounted = true;
    };

    componentWillUnmount = () => {
        this._isMounted = false;
    };

    render = () => {
        const values = this.state.values;
        return (
            <div className='data_20'>
                <div className='description'>
                    <p className='descriptionText'>
                        Hieronder vind je een aantal vragen waarmee je de eerste stap zet naar een gestructureerd en
                        helder overzicht van je studie.
                    </p>
                </div>
                <div className='fieldsContainer'>
                    <div className='fieldContainer'>
                        <div className='titleContainer'>
                            <label className='fieldTitle'>Volg je een opleiding?</label>
                            {this.renderToggle('study')}
                        </div>
                        <MaterialFactory componentType={materialTypes.TEXT} id='study_20' placeholder='Opleiding'
                                         disabled={!values.study.toggled} fullWidth onChange={(evt) => {
                            this.changeState({studyError: null});
                            this.changeValue('study', evt.target.value);
                        }}/>
                    </div>
                    <div className='fieldContainer withBorder'>
                        <div className='titleContainer'>
                            <label className='fieldTitle'>Ben je uitwonend?</label>
                            <MaterialFactory componentType={materialTypes.TOGGLE}
                                             label={this.state.values.nonResident.toggled ? 'Ja' : 'Nee'}
                                             className='toggle' tabIndex={-1}
                                             checked={this.state.values.nonResident.toggled}
                                             onChange={(evt) => {
                                                 this.changeResident(evt.target.checked);
                                             }}/>
                        </div>
                    </div>
                    <div className='fieldContainer withBorder'>
                        <div className='titleContainer'>
                            <label className='fieldTitle'>Heb je je DigiD aangevraagd?</label>
                            {this.renderToggle('digid')}
                        </div>
                    </div>
                    <div className='fieldContainer'>
                        <div className='titleContainer'>
                            <label className='fieldTitle'>Krijg je studiefinanciering?</label>
                            {this.renderToggle('stufi')}
                        </div>
                        {this.renderTextField('stufi_20', 'stufi', 'number', !values.stufi.toggled)}
                    </div>
                    <div className='fieldContainer'>
                        <div className='titleContainer'>
                            <label className='fieldTitle'>Krijg je zorgtoeslag?</label>
                            {this.renderToggle('careAllowance')}
                        </div>
                        {this.renderTextField('careAllowance_20', 'careAllowance', 'number', !values.careAllowance.toggled)}
                    </div>
                    <div className='fieldContainer'>
                        <div className='titleContainer'>
                            <label className='fieldTitle'>Betaal je zelf collegeld?</label>
                            {this.renderToggle('tuition')}
                        </div>
                        {this.renderTextField('tuition_20', 'tuition', 'number', !values.tuition.toggled)}
                        {/* TODO include frequency*/}
                    </div>
                    <div className='fieldContainer'>
                        <div className='titleContainer'>
                            <label className='fieldTitle'>Loop je stage?</label>
                            <MaterialFactory componentType={materialTypes.TOGGLE}
                                             label={this.state.values.internship.toggled ? 'Ja' : 'Nee'}
                                             className='toggle' tabIndex={-1}
                                             checked={this.state.values.internship.toggled}
                                             onChange={(evt) => {
                                                 this.changeInternship(evt.target.checked);
                                             }}/>
                        </div>
                        {this.renderTextField('internship_20', 'internship', 'text', !values.internship.toggled,
                            'Stagebedrijf')}
                    </div>
                    <div className='fieldContainer'>
                        <div className='titleContainer'>
                            <label className='fieldTitle'>Krijg je stagevergoeding?</label>
                            {this.renderToggle('internshipCompensation')}
                        </div>
                        {this.renderTextField('internshipCompensation_20', 'internshipCompensation', 'number',
                            !values.internshipCompensation.toggled, '\u20ac 0,00')}
                    </div>
                    <div className='fieldContainer'>
                        <div className='titleContainer'>
                            <label className='fieldTitle'>Heb je een DUO lening?</label>
                            {this.renderToggle('loan')}
                        </div>
                        {this.renderTextField('loan_20', 'loan', 'number', !values.loan.toggled, '\u20ac 0,00')}
                    </div>
                    <MaterialFactory componentType={materialTypes.RAISED_BUTTON} onClick={this.onSubmit}
                                     disabled={this.props.submitting}>Opslaan</MaterialFactory>
                </div>
            </div>
        )
    }
}

DataView20.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
};