import './style/nextInsurance.scss';

import React from 'react';
import NextInsuranceView from "./nextInsuranceView";

export default class NextInsuranceController extends React.Component {
    constructor(props) {
        super(props);
    }

    render = () => {
        return (
            <NextInsuranceView/>
        )
    }
}