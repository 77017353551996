import './style/module.scss'

import React from 'react';
import PropTypes from 'prop-types';

import moduleConstants from 'BdhCommon/data/constants/moduleConstants';
import MobileContainer from "../document/addDocument/mobileContainer";
import CustomScrollbar from "../misc/customScrollbar";
import getCategories from "../../common/data/mappings/categoryMapping";
import getDocumentTypes from "../../common/data/mappings/docTypeMapping";

export class ModuleView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editMode: false
        };
        this.key = 0;
    }

    createModuleButton = (module) => {
        let moduleData = getCategories(module);
        let selected = module === this.props.selectedModule;
        let docTypes = this.props.withMobileContainer ? this.getDocTypesFromModule(module) : null;
        return (
            <div key={this.key++}
                 className={'iconButton active' + (selected ? ' selected' : '')}>
                <div className="moduleButton" onClick={() => {
                    this.props.onSelect(module, moduleData)
                }}>
                    <span className={"icon " + moduleData.icon}/>
                    {isNotNull(moduleData.label) && <h4>{moduleData.label}</h4>}
                </div>
                {/*<input id={'check' + module} type='checkbox' className='condition-checkbox' defaultChecked={active}*/}
                {/*onChange={() => {*/}
                {/*this.props.editModules(module, active);*/}
                {/*}}/>*/}
                <label htmlFor={'check' + module}/>
                {this.props.withMobileContainer &&
                <MobileContainer onAddClick={this.props.onAddDocumentClick} docTypes={docTypes}
                                 isSelected={selected} documentTypes={this.props.documentTypes}/>}
            </div>
        );
    };

    getDocTypesFromModule = module => {
        let docTypes = getCategories(module).docTypes;
        if (isNotNull(docTypes))
            return docTypes.map(docType => (getDocumentTypes(docType)));
        return [];
    };

    moduleButtons = () => {
        return this.props.allModules.map((module) => {
            let moduleName = moduleConstants[module];
            return this.createModuleButton(moduleName);
        });
    };

    render = () => {
        return (
            <div className='moduleView'>
                <div className="modulesHeader">
                    <h1 className="modulesTitle">Categorieën</h1>
                </div>
                <CustomScrollbar height='27rem'>
                    {this.moduleButtons()}
                </CustomScrollbar>
            </div>
        )
    }
}

ModuleView.propTypes = {
    activeModules: PropTypes.arrayOf(PropTypes.string),
    allModules: PropTypes.arrayOf(PropTypes.string).isRequired,
    selectedModule: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
    editModules: PropTypes.func.isRequired,
    activateAllModules: PropTypes.func.isRequired,
    withMobileContainer: PropTypes.bool,
    onAddDocumentClick: PropTypes.func,
};

ModuleView.defaultProps = {
    withMobileContainer: false,
};

export default ModuleView;