import './style/dossierContacts.scss';

import React from 'react';
import {connect} from 'react-redux';

import {generateUUID} from 'glob-common-js/lib/utils';

import DossierContactsView from "./dossierContactsView";
import getCategoryType from "../../../../common/data/mappings/categoryMapping";
import {sendGaEvent} from "../../../../common/js/ga";
import Loader from "../../../misc/loader";
import {dispatchCustomEvent} from "../../../../misc/eventDispatcher";
import StateComponent from "../../../misc/stateComponent";
import {Contact} from "../../../../models/contact";
import {responseIsSuccess} from "../../../../misc/requestSender";
import {
    addDataEventListener,
    DATA_TYPES,
    dispatchContactEvent,
    EVENT_TYPES,
    removeAllDataEventListeners
} from "../../../../misc/dataEvent";

export class DossierContactsController extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            contacts: [],
            contact: null,
            showEditPopup: false,
            showCreatePopup: false,
            selectedContacts: [],
        };
        this.clickedContacts = [];
    }

    onDataEvent = (contact, eventType) => {
        if (eventType === EVENT_TYPES.CREATE)
            this.onCreateEvent(contact);
        else if (eventType === EVENT_TYPES.UPDATE)
            this.onUpdateEvent(contact);
        else if (eventType === EVENT_TYPES.DELETE)
            this.onDeleteEvent(contact);
    };

    onCreateEvent = contact => {
        const {dossier} = this.props;
        if (contact.dossierIsLinked(dossier)) {
            const {contacts} = this.state;
            contacts.push(contact);
            this.changeState({contacts});
        }
    };

    onUpdateEvent = contact => {
        const {contacts} = this.state;
        if (this.contactIsLinked(contact)) {
            for (let i = 0; i < contacts.length; i++) {
                if (contacts[i].id === contact.id) {
                    contacts.splice(i, 1, contact);
                    this.changeState({contacts});
                    break;
                }
            }
        } else this.onDeleteEvent(contact);
    };

    onDeleteEvent = contact => {
        this.changeState({
            contacts: this.state.contacts.filter(con => con.id !== contact.id),
            selectedContacts: [],
        });
        this.clickedContacts = [];
    };

    onClickContact = contact => () => {
        dispatchCustomEvent('openContact', {contact});
    };

    contactIsLinked = contact => {
        const {dossier} = this.props;
        if (isNull(contact.dossiers)) return false;
        for (const doss of contact.dossiers) {
            if (contact.dossiersLoaded && doss.id === dossier.id) return true;
            if (!contact.dossiersLoaded && doss === dossier.id) return true;
        }
        return false;
    };

    hideCreatePopup = () => {
        this.changeState({
            showCreatePopup: false,
        });
        document.getElementById("createPopupForm").reset();
    };

    hideEditPopup = () => {
        this.changeState({
            contact: null,
        });
    };

    showEditPopup = () => {
        this.changeState({
            showEditPopup: true,
        })
    };

    showCreatePopup = () => {
        document.getElementById("createPopupForm").reset();
        this.changeState({
            showCreatePopup: true,
        })
    };

    editContact = (model, contact) => {
        const {dossier} = this.props;
        dossier.externalData.dossierContacts[dossier.externalData.dossierContacts.indexOf(contact)] = model;

        this.hideEditPopup();
        document.getElementById("editPopupForm").reset();
        this.updateDossier(dossier, () => {
            let module = this.props.moduleState.selectedModule;
            let moduleLabel = isNotNull(module) ? getCategoryType(module).label : 'Geen module bekend';
            sendGaEvent(moduleLabel, 'Contact aanpassen', dossier.type.label);
        });
    };

    changeContact = () => {
        if (this.clickedContacts.length === 1) {
            dispatchCustomEvent('openContact', {
                dossier: this.props.dossier, submitCallback: this.updateDossierCallback, data: this.clickedContacts[0],
            });
        }
    };

    updateDossierCallback = dossier => {
        this.onUpdateDossier(dossier.externalData.dossierContacts);
    };

    updateDossier = (dossier, gaEvent) => {
        dossier.update(() => {
            this.loader.disable(() => {
                gaEvent();
                let contacts = dossier.externalData.dossierContacts;
                this.changeState({
                    contacts,
                });
            });
        });
    };

    deleteContact = () => {
        const {dossier} = this.props;
        this.loader.enable();
        const clickedContacts = this.clickedContacts;
        let deleted = 0;
        for (const contact of clickedContacts) {
            contact.removeDossier(dossier.id);
            contact.update(() => {
                dispatchContactEvent(EVENT_TYPES.DELETE, contact);
                if (++deleted >= clickedContacts.length) {
                    this.loader.disable();
                    this.clickedContacts = [];
                    this.changeState({selectedContacts: []});
                    this.enableButtons();
                }
            });
        }
    };

    contactFieldClick = (contact) => () => {
        let clickedContacts = this.clickedContacts;
        let element = document.getElementById('contact ' + contact.id);
        if (isNotNull(element)) {
            if (element.classList.contains("selected")) {
                element.classList.remove("selected");
                clickedContacts.splice(clickedContacts.indexOf(contact), 1);
            } else {
                element.classList.add("selected");
                clickedContacts.push(contact);
            }
        }
        this.changeState({selectedContacts: clickedContacts});
        this.clickedContacts = clickedContacts;
        this.enableButtons();
    };

    enableButtons = () => {
        let deleteButton = document.getElementById('deleteContact');
        let editButton = document.getElementById('editContact');

        if (areNotNull(deleteButton, editButton)) {
            deleteButton.disabled = this.clickedContacts.length === 0;
            editButton.disabled = this.clickedContacts.length !== 1;
        }
    };

    onUpdateDossier = contacts => {
        this.deselectAll();
        this.clickedContacts = [];
        this.changeState({contacts, contact: null});
        this.enableButtons();
    };

    deselectAll = () => {
        let selected = document.querySelectorAll('div.contactDetails.selected');
        if (isNotNull(selected))
            for (let i = 0; i < selected.length; i++) {
                selected[i].classList.remove('selected');
            }
    };

    fetchContacts = () => {
        const {dossier} = this.props;
        let contacts = [];
        const externalData = dossier.externalData;
        if (isNotNull(externalData)) {
            if (isNotNull(externalData.dossierContacts)) {
                contacts = this.checkContactIds(dossier.externalData.dossierContacts);
                delete externalData.dossierContacts;
                dossier.externalData = externalData;
                dossier.update();
            }
        }
        this.loadContacts(contacts);
    };

    loadContacts = (externalContacts) => {
        const {dossier} = this.props;
        let contacts = [];
        Contact.getFromDossier(dossier.id, (contactList, response) => {
            if (responseIsSuccess(response))
                contacts = contactList;
            if (isNotNull(externalContacts))
                this.convertExternalContacts(externalContacts, contacts);
            else this.changeState({contacts});
        });
    };

    convertExternalContacts = (externalContacts, contacts) => {
        let contactsLoaded = 0;
        const {dossier} = this.props;
        externalContacts.forEach(contact => {
            const contactInstance = new Contact({
                name: contact.name,
                email: contact.email,
                phone: contact.phone,
                mobile: contact.phone,
                website: contact.website,
                street: contact.street,
                house_number: contact.houseNumber,
                house_number_suffix: contact.houseNumberSuffix,
                zip_code: contact.zipCode,
                city: contact.city,
                comment: contact.comment,
                dossiers: [dossier.id],
            });
            if (isNotNull(contact.loginName) || isNotNull(contact.password))
                contactInstance.accounts = [{username: contact.loginName, password: contact.password}];
            contactInstance.save((contact, response) => {
                if (responseIsSuccess(response))
                    contacts.push(contact);
                if (++contactsLoaded === externalContacts.length) {
                    this.changeState({contacts});
                }
            })
        });
    };

    /**
     * To support contacts made before we added id's, we must check every contact for an id. If the contact does not have
     * an id yet, we generate and add it.
     * @param contacts
     */
    checkContactIds = contacts => {
        if (isNotNull(contacts)) {
            for (let i = 0; i < contacts.length; i++) {
                if (isNull(contacts[i].id)) {
                    contacts[i].id = generateUUID();
                }
            }
        }
        return contacts;
    };

    componentDidMount = () => {
        this.mount();
        this.fetchContacts();
        addDataEventListener(DATA_TYPES.CONTACT, [EVENT_TYPES.CREATE, EVENT_TYPES.UPDATE, EVENT_TYPES.DELETE],
            'dossierContactsController', this.onDataEvent);
    };

    componentWillUnmount = () => {
        this.unMount();
        removeAllDataEventListeners('dossierContactsController', this.onDataEvent);
    };

    render = () => {
        const {moduleState, dossier} = this.props, {contacts, contact, selectedContacts} = this.state;
        return (
            <>
                <DossierContactsView editContact={this.editContact} changeContact={this.changeContact}
                                     deleteContact={this.deleteContact} contacts={contacts} contact={contact}
                                     hideCreatePopup={this.hideCreatePopup} hideEditPopup={this.hideEditPopup}
                                     showEditPopup={this.showEditPopup} showCreatePopup={this.showCreatePopup}
                                     dossier={dossier} selectedModule={moduleState.selectedModule}
                                     selectedContacts={selectedContacts} contactFieldClick={this.contactFieldClick}
                                     onUpdateDossier={this.onUpdateDossier} onClickContact={this.onClickContact}
                />
                <Loader ref={refName => {
                    this.loader = refName
                }} includeBackground={true} backgroundColor='white' textBackground='transparent'
                        text='Contact verwijderen...'/>
            </>
        )
    }
}

const mapStateToProps = state => ({
    moduleState: state.module,
});

export default connect(mapStateToProps)(DossierContactsController);
