import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as Actions from '../../../../redux/actions';

import {navigate} from "../../../../misc/utils";
import {sendGaEvent} from "BdhCommon/js/ga";
import DossierDataView from './dossierDataView';
import {materialTypes} from "../../../material/materialTypes";
import MaterialFactory from "../../../material/materialFactory";
import {getOnboardingManager} from "../../../onboarding/manager/onboardingManagerHelper";
import {addDataEventListener, DATA_TYPES, EVENT_TYPES, removeAllDataEventListeners} from "../../../../misc/dataEvent";

const GA_CATEGORY = 'Dossier details data';

export class DossierDataController extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dossier: null,
            editing: false,
            confirmDelete: false,
        }
    }

    changeState = (stateChange) => {
        if (this._isMounted) {
            this.setState(stateChange);
        }
    };

    onEdit = () => {
        this.changeState({editing: true});
    };

    onDelete = () => {
        this.changeState({confirmDelete: true});
    };

    onCancel = () => {
        this.changeState({editing: false});
    };

    deleteDossier = () => {
        const {dossier} = this.props;
        dossier.delete(() => {
            sendGaEvent(GA_CATEGORY, 'dossier verwijderd', dossier.type.label);
            this.onCloseDialog();
            this.props.actions.setDossier(null);
            navigate('/dossier-overzicht');
        });
    };

    onCloseDialog = () => {
        this.changeState({confirmDelete: false});
    };

    prepareDossier = (dossier = null) => {
        dossier = dossier || Object.assign(this.props.dossier);
        let values = dossier.values;
        let fields = dossier.fields;
        let extraDetails = dossier.type.extraDetails || [];
        for (let i = 0; i < fields.length; i++) {
            let field = fields[i], fieldName = field.name;
            if (fieldName === 'purchase_value' && isNotNull(values['value'])) {
                field.value = values['value'];
            } else {
                field.value = values[fieldName];
            }
        }
        if (isNotNull(dossier.values.value) && !this.containsValue(extraDetails) && !this.containsValue(fields)) {
            extraDetails.push({
                name: 'value',
                label: 'Waarde',
                type: 'PRICE',
            });
        }
        for (let i = 0; i < extraDetails.length; i++) {
            extraDetails[i].value = values[extraDetails[i].name];
        }
        dossier.fields = fields;
        dossier.type.extraDetails = extraDetails;
        this.changeState({dossier, editing: false});
        this.checkOnboarding();
    };

    containsValue = (fields) => {
        for (let i = 0; i < fields.length; i++) {
            if (fields[i].name === 'value' || fields[i].name === 'purchase_value') return true;
        }
        return false;
    };

    updateListener = (dossier) => {
        this.prepareDossier(dossier);
    };

    checkOnboarding = () => {
        if (getOnboardingManager('store').isActive())
            this.changeState({editing: true});
    };

    renderDialogActions = () => ([
        {label: 'Nee', onClick: this.onCloseDialog},
        {label: 'Ja', onClick: this.deleteDossier},
    ]);

    componentDidUpdate = () => {
        if (isNull(this.state.dossier.fields) && isNotNull(this.state.dossier.type.fields)) {
            this.prepareDossier();
        }
    };

    componentDidMount = () => {
        this._isMounted = true;
        this.prepareDossier();
        addDataEventListener(DATA_TYPES.DOSSIER, EVENT_TYPES.UPDATE, 'dossierDataController', this.updateListener);
    };

    componentWillUnmount = () => {
        this._isMounted = false;
        removeAllDataEventListeners('dossierDataController', this.updateListener);
    };

    render = () => {
        return (
            <>
                <MaterialFactory componentType={materialTypes.DIALOG} title='Dossier verwijderen'
                                 open={this.state.confirmDelete} onClose={this.onCloseDialog}
                                 actions={this.renderDialogActions()}
                                 text='Weet je zeker dat je dit dossier wilt verwijderen?'/>
                <DossierDataView dossier={isNotNull(this.state.dossier) ? this.state.dossier : null}
                                 editing={this.state.editing} onEdit={this.onEdit} onCancel={this.onCancel}
                                 store={this.props.store} onDelete={this.onDelete}/>
            </>
        );
    };
}

DossierDataController.propTypes = {
    dossier: PropTypes.shape({
        label: PropTypes.string.isRequired,
        values: PropTypes.object.isRequired,
        fields: PropTypes.arrayOf(PropTypes.object),
        type: PropTypes.shape({
            extraDetails: PropTypes.arrayOf(PropTypes.object),
        }).isRequired,
    }),
    store: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    userState: state.user,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(DossierDataController);