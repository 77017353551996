import React from 'react';
import {connect} from 'react-redux';

import {scrollToTop} from 'glob-common-js/lib/utils';
import {POST} from "glob-common-js/lib/request";
import {createRequestConfig} from "BdhCommon/js/platform";

import CoverageView from "./coverageView";
import {getFromRegistry, updateRegistry} from "../../misc/registry";
import {navigate, sendRequest} from "../../../../../misc/utils";
import {selectableOptions} from "./data/editPossibilieties";

export class CoverageController extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedData: this.createSelectedData(),
            requesting: false,
        };
        this.dataChanged = false;

        this.jsonObj = {};
    }

    changeState = (stateChange) => {
        if (this._isMounted) {
            this.setState(stateChange);
        }
    };

    selectOption = (index, name) => {
        let data = this.state.selectedData;
        data[name] = index;
        this.changeState({selectedData: data});

        let dataKeys = Object.keys(data);

        let registry = getFromRegistry('healthInsurance');

        registry.editInsurance = {
            basic: {
                label: 'type_verzekering',
                value: registry.editInsurance.basic.value,
                default_selected: data['policiy_type'],
                default_selected_contract: data['covered_no_contract'],
            },
            risk: {
                // label: 'basisverzekering',
                value: registry.editInsurance.risk.value,
                default_selected: data['risk'],
            },
            coverages: dataKeys.map((entry) => (entry !== 'policiy_type' || 'covered_no_contract' || 'risk' ? {
                part: entry,
                default_selected: data[entry]
            } : null)),
            alternatives: registry.editInsurance.alternatives,
            originalCoverages: registry.editInsurance.originalCoverages,
        };

        updateRegistry('healthInsurance', registry);

        this.dataChanged = true;
    };

    toCompare = () => {
        if (this.dataChanged) {
            // Send request to fetch alternatives
            this.changeState({requesting: true});
            let data = this.createCompareData();
            sendRequest(createRequestConfig({
                url: '/healthinsurance/compare',
                apiVersion: 3,
                method: POST,
                data
            }), response => {
                this.changeState({requesting: false});
                this.updateRegistry(response.data.alternatives);
                navigate('/zorganalyse/aanpassen/vergelijken', true);
                scrollToTop();
            });
        } else {
            navigate('/zorganalyse/aanpassen/vergelijken', true);
            scrollToTop();
        }
    };

    updateRegistry = (alternatives) => {
        let registry = getFromRegistry('healthInsurance');
        registry.editInsurance.alternatives = alternatives.sort((altA, altB) => {
            let rankA = altA.ranking;
            let rankB = altB.ranking;
            return rankA > rankB ? 1 : rankA < rankB ? -1 : 0;
        });
        updateRegistry('healthInsurance', registry);
    };

    createCompareData = () => {
        let registry = getFromRegistry(['healthInsurance', 'evaluation']);
        let selectedData = this.state.selectedData;
        selectedData.year_of_birth = parseInt(registry.year_of_birth);
        selectedData.risk = parseInt(selectableOptions.risk[selectedData.risk]);
        // Flip covered_no_contract index to match excel structure
        selectedData.covered_no_contract = 8 - selectedData.covered_no_contract;
        return selectedData;
    };

    createSelectedData = () => {
        let editInsurance = getFromRegistry(['healthInsurance', 'editInsurance']);
        let data = {};
        let coverages = editInsurance.coverages;
        for (let i = 0; i < coverages.length; i++) {
            let coverage = coverages[i];
            data[coverage.part] = coverage.default_selected;
        }
        data.covered_no_contract = 8 - data.covered_no_contract;
        return data;
    };

    resetSelectedData = () => {
        let selectedData = this.state.selectedData;
        let keys = Object.keys(selectedData);
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            // Skip policy type, risk and covered_no_contract
            if (key !== 'policy_type' && key !== 'risk' && key !== 'covered_no_contract') {
                selectedData[key] = 0;
            }
        }
        this.changeState({selectedData});
        this.resetRegistry(selectedData);
        this.dataChanged = true;
    };

    resetToInsurance = () => {
        let data = getFromRegistry(['healthInsurance', 'editInsurance', 'originalCoverages']);
        let selectedData = {};
        for (let i = 0; i < data.length; i++) {
            let key = data[i].part;
            selectedData[key] = data[i].default_selected;
        }
        this.changeState({selectedData});
        this.resetRegistry(selectedData);
        this.dataChanged = true;
    };

    resetRegistry = (data) => {
        let registry = getFromRegistry('healthInsurance');
        let dataKeys = Object.keys(data);
        registry.editInsurance = {
            basic: registry.editInsurance.basic,
            risk: registry.editInsurance.risk,
            coverages: dataKeys.map((entry) => (entry !== 'policiy_type' || 'covered_no_contract' || 'risk' ? {
                part: entry,
                default_selected: data[entry]
            } : null)),
            alternatives: registry.editInsurance.alternatives,
            originalCoverages: registry.editInsurance.originalCoverages,
        };
        updateRegistry('healthInsurance', registry);
    };

    componentDidMount = () => {
        this._isMounted = true;
    };

    componentWillUnmount = () => {
        this._isMounted = false;
    };

    render = () => {
        return (
            <div>
                <CoverageView selectedData={this.state.selectedData} selectOption={this.selectOption}
                              toCompare={this.toCompare} resetSelectedData={this.resetSelectedData}
                              resetToInsurance={this.resetToInsurance} requesting={this.state.requesting}/>
                {/*<RatingController showPopup={!this.props.healthState.ratingShown}/>*/}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    healthState: state.healthInsuranceAnalysis
});

export default connect(mapStateToProps)(CoverageController);