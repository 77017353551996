import './style/addBirthday.scss';

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {pad} from 'glob-common-js/lib/utils';

import StateComponent from "../../misc/stateComponent";
import GenericLoader from "../../misc/genericLoader";
import {sendGaEvent} from "../../../common/js/ga";
import MaterialFactory from "../../material/materialFactory";
import {materialTypes} from "../../material/materialTypes";
import {NOW} from "../../misc/constants";

const logo = require('../../../common/images/logo_bydehand_trans.png');

const GA_CATEGORY = 'Leeftijd opgeven';
export default class AddBirthday extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            day: null,
            month: null,
            year: '1990',
        }
    }

    onChange = (name, value) => {
        sendGaEvent(GA_CATEGORY, 'Kies datum', name);
        this.changeState({[name]: value});
    };

    onSubmit = () => {
        const {day, month, year} = this.state;
        if (areNotNull(day, month, year)) {
            const birthday = moment(pad(day) + '-' + pad(parseInt(month) + 1) + '-' + year, 'DD-MM-YYYY');
            this.props.onSubmit(birthday);
        }
    };

    createDaySelection = () => {
        let dob = moment(this.state.dob);
        const maxDays = dob.daysInMonth();
        const days = [];
        for (let day = 1; day <= maxDays; day++) {
            days.push({value: day.toString(), label: day.toString()});
        }
        return days;
    };

    createMonthSelection = () => (["Januari", "Februari", "Maart", "April", "Mei", "Juni", "Juli", "Augustus", "September",
            "Oktober", "November", "December"].map((month, key) => ({value: key.toString(), label: month}))
    );

    createYearSelection = () => {
        const currentYear = NOW().year();
        const minYear = 1900;
        const years = [];
        for (let year = currentYear; year >= minYear; year--) {
            years.push({value: year.toString(), label: year.toString()});
        }
        return years;
    };

    isSubmitDisabled = () => !areNotNull(this.state.day, this.state.month, this.state.year) || this.props.loading;

    componentDidMount = () => {
        this.mount();
    };

    componentWillUnmount = () => {
        this.unMount();
    };

    render = () => {
        const borderStyle = {borderColor: '#ccc', borderWidth: '1px'};
        const maxSelectHeight = 15;
        return (
            <>
                <div className='birthdayBackground'/>
                <div className='addBirthday'>
                    <GenericLoader active={this.props.loading}/>
                    <div className='addBirthdayHeader'>
                        <label className='headerTitle'>Accountgegevens</label>
                        <div className='logoContainer'>
                            <img className='headerLogo' src={logo} alt='ByDeHand.com'/>
                        </div>
                    </div>
                    <div className='addBirthdayBody'>
                        {/*<label className='bodyTitle'>Wat is je geboortedatum?</label>*/}
                        <p>Beste {this.props.user.firstname},<br/><br/>Om je account goed in te stellen, vragen we je om
                            enkele aanvullende gegevens.
                        </p>
                        <div>
                            <label className='fieldTitle'>Geboortedatum</label>
                            <div className='selectContainer'>
                                <MaterialFactory componentType={materialTypes.SELECT} className='dobSelector'
                                                 label='Dag' items={this.createDaySelection()}
                                                 value={isNotNull(this.state.day) ? this.state.day.toString() : ''}
                                                  onChange={(evt) => {
                                    this.onChange('day', evt.target.value);
                                }}/>
                                <MaterialFactory componentType={materialTypes.SELECT} className='dobSelector'
                                                 label='Maand' items={this.createMonthSelection()}
                                                 value={isNotNull(this.state.month) ? this.state.month.toString() : ''}
                                                 onChange={(evt) => {
                                                     this.onChange('month', evt.target.value);
                                                 }}/>
                                <MaterialFactory componentType={materialTypes.SELECT} className='dobSelector'
                                                 label='Jaar' value={this.state.year.toString()}
                                                 items={this.createYearSelection()} onChange={(evt) => {
                                    this.onChange('year', evt.target.value);
                                }}/>
                            </div>
                        </div>
                    </div>
                    <div className='addBirthdayFooter'>
                        <MaterialFactory componentType={materialTypes.RAISED_BUTTON}
                                         onClick={this.onSubmit} disabled={this.isSubmitDisabled()}>
                            Voeg leeftijd toe</MaterialFactory>
                    </div>
                </div>
            </>
        );
    }
}

AddBirthday.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    user: PropTypes.object,
};