import React from 'react';
import {connect} from 'react-redux';

import DashboardContainer from './dashboardContainer';
import DashboardContainerLifeWill from './DashboardContainerLifeWill';
import {sendPageView} from "BdhCommon/js/ga";
import ErrorBoundary from "../errorBoundary";
import {getOnboardingManager} from "../onboarding/manager/onboardingManagerHelper";
import {dispatchCustomEvent} from "../../misc/eventDispatcher";
import {agendaOnboardingSteps} from "../onboarding/manager/agendaManager";
import {navigate, getPlatformVersion} from "../../misc/utils";

export class DashboardContainerController extends React.Component {
    constructor(props) {
        super(props);

    }

    checkOnboarding = () => {
        if (getOnboardingManager('agenda').isActive())
            dispatchCustomEvent('agendaOnboardingFlow', {type: 'activate', step: agendaOnboardingSteps.INTRO_STEP});
    };

    onNavigate = url => () => {
        navigate(url);
    };

    onClickTutorial = () => {
        dispatchCustomEvent('startOnboarding');
    };

    renderDashboardVersion = (platformDisplayVersion) => {
      switch (platformDisplayVersion) {
        case 'standard':
          return (
            <DashboardContainer user={this.props.userState.user} onClickTutorial={this.onClickTutorial}
                                onNavigate={this.onNavigate}/>
          );
        case 'lifeWill':
          return (
            <DashboardContainerLifeWill user={this.props.userState.user} onClickTutorial={this.onClickTutorial}
                                onNavigate={this.onNavigate}/>
          );
        default:
          return (
            <DashboardContainer user={this.props.userState.user} onClickTutorial={this.onClickTutorial}
                                onNavigate={this.onNavigate}/>
          );
      }
    }

    componentDidMount = () => {
        this._isMounted = true;
        sendPageView('/dashboard');
        this.checkOnboarding();
    };

    componentWillUnmount = () => {
        this._isMounted = false;
    };

  render = () => {
    return (
        <ErrorBoundary>
          {this.renderDashboardVersion(getPlatformVersion())}
        </ErrorBoundary>
    );
  };

}

const mapStateToProps = state => ({
    userState: state.user,
});

export default connect(mapStateToProps)(DashboardContainerController);
