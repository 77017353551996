import React from 'react';
import PropTypes from 'prop-types';

import {capitalizeFirst, isNotNull, toMoney} from 'glob-common-js/lib/utils';
import {sendEvent} from "../../misc/healthAnalysisUtils";
import {navigate} from "../../../../../misc/utils";
import {dummyRegistry, getFromRegistry, updateRegistry} from "../../misc/registry";

export default class CompareView extends React.Component {
    constructor(props) {
        super(props);
        this.allInsurances = isNotNull(this.props.alternatives) ? this.props.alternatives : dummyRegistry.healthInsurance.editInsurance.alternatives;
        this.state = {
            showCount: 8,
            insurances: this.allInsurances.slice(0, 3).map(insurance => insurance.insurer),
        };
        this.selectedInsurance = null;
        this.coverages = ["alternatieve_zorg", "brillen_lenzen", "fysiotherapie", "kraamzorg",
            "spoedeisende_hulp_buitenland", "vaccinaties", "tandarts", "orthodontie_tot_18_jaar",
            "orthodontie_vanaf_18_jaar", "mondzorg_ongevallen", "spoedeisende_zorg_tanden", "acne_behandeling",
            "anticonceptie", "camouflagetherapie", "cursussen_en_preventie", "dieet_advisering", "geneesmiddelen",
            "herstellingsoord_zorghotel", "homeopatische_middelen", "hulpmiddelen_overig", "mantelzorg",
            "nazorg_bij_kanker", "ontharen_lasertherapie", "sportmedisch_onderzoek", "sterilisatie_man",
            "sterilisatie_vrouw", "steunzolen", "stottertherapie", "voetzorg_voetbehandeling"];
    }

    clickInsurer = (boxNr, insurance) => {
        if (boxNr === -1 && insurance === null) {
            sendEvent('Vergelijken Keuze', '-1 Ga door');
        } else {
            sendEvent('Vergelijken Keuze', boxNr + ' ' + insurance.insurer);
            let healthInsurance = getFromRegistry('healthInsurance');
            insurance.risk = isNotNull(healthInsurance.nextInsurance.risk) ? healthInsurance.nextInsurance.risk :
                healthInsurance.evaluation.basic.risk || 385;
            healthInsurance.nextInsurance = insurance;
            updateRegistry('healthInsurance', healthInsurance);
        }
        this.props.navigate();
    };

    clickEdit = () => {
        sendEvent('Vergelijken Dekking', 'Wijzig dekking');
        navigate('/zorganalyse/aanpassen/dekking', true);
    };

    clickChangeInsurance = (evt, insurance, index) => {
        this.selectedInsurance = insurance;
        let target = evt.target;

        let selected = document.querySelector('div.selectorAlternative.selected');
        if (isNotNull(selected)) {
            selected.classList.remove('selected');
        }

        if (isNotNull(target)) {
            if (target.tagName === 'LABEL') target = target.parentElement;
            target.classList.add('selected');
        }
        this.confirmInsuranceSelection(evt, index)
    };

    confirmInsuranceSelection = (evt, index) => {
        let insurance = this.selectedInsurance;
        if (isNotNull(insurance)) {
            sendEvent('Vergelijken Selectie', index + 1, insurance.ranking, insurance.insurer);

            let insurances = this.state.insurances;

            // Swap insurances add specified index
            let oldIndex = insurances.indexOf(insurance.insurer);
            if (oldIndex > -1) {
                let temp = insurances[index];
                insurances[index] = insurances[oldIndex];
                insurances[oldIndex] = temp;
            } else {
                insurances[index] = insurance.insurer;
            }

            this.setState({insurances});
        }
        this.closeSelection(index);
    };

    closeSelection = (index) => {
        let openSelector = document.getElementById('insuranceSelector' + index);
        if (isNotNull(openSelector)) {
            openSelector.classList.remove('active');
            document.removeEventListener("click", this.closeActiveSelector);
        }
    };

    activateInsuranceSelector = (evt) => {
        let target = evt.target;
        if (isNotNull(target)) {
            if (target.tagName === 'LABEL' || target.tagName === 'I') {
                target = target.parentElement;
            }

            let selector = target.getElementsByClassName('insuranceSelector')[0];
            if (isNotNull(selector)) {
                if (selector.classList.contains('active')) {
                    this.closeActiveSelector(evt);
                } else {
                    selector.classList.add('active');
                    document.addEventListener("click", this.closeActiveSelector);
                }

            }
        }
    };

    getInsuranceValue = (insurance, value, subObj = null) => {
        if (isNotNull(subObj)) return insurance[subObj][value];
        return insurance[value] || {};
    };

    createRow = (key, texts) => {
        if (key === null) {
            return (
                <div className="insuranceRow">
                    {texts.map((text, key) => (
                        <div key={key} onClick={this.createSelectorEventHandler} className="column">{text}</div>))}
                </div>
            );
        }
        return (
            <div key={key} className="insuranceRow">
                {texts.map((text, key) => (
                    <div key={key} onClick={this.createSelectorEventHandler} className="column">{text}</div>))}
            </div>
        )
    };

    createCoverages = () => {
        if (this.state.insurances.length > 0) {
            let max = this.state.showCount === -1 ? this.coverages.length : this.state.showCount;
            return this.coverages.slice(0, max).map((coverage, key) => {
                let currentBucket = this.getInsuranceValue(this.getInsurerByName(this.state.insurances[0]), coverage, 'coverages').bucket;
                return (
                    this.createRow(key, [
                        <div className='sidebar'>
                            <label>{capitalizeFirst(coverage.replace(/_/g, ' '))}</label>
                        </div>].concat(
                        this.state.insurances.map((insurance, key) => {
                            let color = 'gray';
                            let bucket = this.getInsuranceValue(this.getInsurerByName(insurance), coverage, 'coverages').bucket;
                            if (isNotNull(bucket)) {
                                color = bucket > currentBucket ? 'green' : bucket < currentBucket ? 'red' : 'gray';
                            }
                            return (
                                <div key={key} className={'insuranceValue ' + color}>
                                    <label>{this.getInsuranceValue(this.getInsurerByName(insurance), coverage, 'coverages').text}</label>
                                </div>
                            );
                        }))
                    )
                )
            });
        }
        return null;
    };

    getInsurerByName = name => {
        let keys = Object.keys(this.allInsurances);
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            if (this.allInsurances[key].insurer === name) return this.allInsurances[key];
        }
        return null;
    };

    createInsuranceSelector = (index) => {
        return (
            <div id={'insuranceSelector' + index} className='insuranceSelector'>
                <div className='selectorHeaderContainer'>
                    <label className='selectorHeader'>Kies een alternatief</label>
                </div>
                {this.allInsurances.map((insurance, key) => {
                    return (
                        <div key={key} className='selectorAlternative' onClick={(evt) => {
                            this.clickChangeInsurance(evt, insurance, index);
                        }}>
                            <label className='fontBold alternativeName'>{insurance.insurer}</label>
                            <label className='fontBold'>{toMoney(insurance.monthprice)}</label>
                        </div>
                    );
                })}
                <div className='selectorFooter'>
                    <label className='closeButton common-icon-cross' onClick={() => {
                        this.closeSelection(index)
                    }}/>
                </div>
            </div>
        )
    };


    closeActiveSelector = (evt) => {
        let activeSelector = document.querySelector('.insuranceSelector.active');
        if (evt.target !== activeSelector && isNotNull(activeSelector)) {
            activeSelector.classList.remove('active');
            document.removeEventListener("click", this.closeActiveSelector);
        }
    };

    componentDidMount = () => {
        let contentContainer = document.querySelector('div.contentContainer');
        if (isNotNull(contentContainer)) {
            contentContainer.classList.add('keepWidth');
        }
    };

    componentWillUnmount = () => {
        let contentContainer = document.querySelector('div.contentContainer.keepWidth');
        if (isNotNull(contentContainer)) {
            contentContainer.classList.remove('keepWidth');
        }
    };

    render = () => {
        let alternative = 1;
        return (
            <div className='compareView'>
                <div className="editCoverageContainer">
                    <i className="common-icon-note noteIcon"/>
                    <label className='editCoverageLink' onClick={this.clickEdit}>Wijzig je gewenste dekking!</label>
                </div>
                <div className="titleContainer">
                    {this.state.insurances.map((insurance, key) => {
                        insurance = this.getInsurerByName(insurance);

                        return (
                            <p key={key}
                               className="alternativeTitle">{insurance.ranking === 0 ? 'Huidige verzekering' : 'Alternatief '
                                + alternative++}
                            </p>
                        );
                    })}
                </div>
                <div className="body">
                    <div className="insuranceDetailsContainer">
                        {this.createRow(null,
                            [<div className='sidebar'>
                                <label className='fontBold'>Verzekering</label>
                            </div>].concat(
                                this.state.insurances.length > 0 ?
                                    this.state.insurances.map((insurance, key) => {
                                        insurance = this.getInsurerByName(insurance);

                                        return (
                                            <div key={key} className='insurerContainer'
                                                 onClick={this.activateInsuranceSelector}>
                                                <label className='insurerName'>{insurance.insurer}</label>
                                                <i className="common-icon-arrow_bothways insurerIcon"/>
                                                {this.createInsuranceSelector(key)}
                                            </div>
                                        );
                                    }) : <h2 className='noAlternatives'>Geen alternatieven</h2>)
                        )}
                        {this.createRow(null,
                            [<div className='sidebar'>
                                <label className='fontBold'>Prijs</label>
                            </div>].concat(
                                this.state.insurances.map((insurance, key) => {
                                    insurance = this.getInsurerByName(insurance);

                                    return (
                                        <div key={key} className='insuranceValue gray'>
                                            <label className='fontBold'>{toMoney(insurance.monthprice)}</label>
                                        </div>
                                    );
                                }))
                        )}
                        {this.createRow(null,
                            [<div className='sidebar'>
                                <label className='fontBold'>Jij bespaart</label>
                            </div>].concat(
                                this.state.insurances.map((insurance, key) => {
                                    insurance = this.getInsurerByName(insurance);
                                    return (
                                        <div key={key}
                                             className={insurance.ranking === 0 || insurance.difference === '' ? '' :
                                                 'insuranceValue ' + (insurance.difference.indexOf('duurder') > -1 ?
                                                 'red' : 'green')}>
                                            <label
                                                className={'diffText fontBold ' + (insurance.difference.indexOf('duurder') > -1 ?
                                                    'textRed' : 'textGreen')}>
                                                {insurance.ranking === 0 ? null : insurance.difference}
                                            </label>
                                        </div>
                                    )
                                }))
                        )}
                    </div>
                    <div className='sectionHeader'>
                        <label className='fontBold'>Vergelijk je dekking</label>
                    </div>
                    <div className='basicInsuranceDetails'>
                        <div className='sidebarBackground'/>
                        {this.createRow(null,
                            [<div className='sidebar'>
                                <label className='fontBold'>Basisverzekering</label>
                            </div>])}
                        {this.createRow(null,
                            [<div className='sidebar'>
                                <p>Type zorgpolis</p>
                            </div>].concat(
                                this.state.insurances.map((insurance, key) => (
                                    <div key={key} className='insuranceValue gray'>
                                        <label>{this.getInsurerByName(insurance).basic.type}</label>
                                    </div>
                                )))
                        )}
                        {this.createRow(null,
                            [<div className='sidebar'>
                                <p>Vergoeding niet-gecontracteerde zorgaanbieders</p>
                            </div>].concat(
                                this.state.insurances.map((insurance, key) => (
                                    <div key={key} className='insuranceValue gray'>
                                        <p className='fontSmaller'>{this.getInsurerByName(insurance).basic.covered_no_contract}</p>
                                    </div>
                                )))
                        )}
                    </div>
                    <div className='coverageDetails'>
                        <div className='sidebarBackground'/>
                        {this.createRow(null,
                            [<div className='sidebar'>
                                <label className='fontBold'>Aanvullende verzekering</label>
                            </div>])}
                        {this.createCoverages()}
                        {this.state.showCount === -1 ?
                            this.createRow(null,
                                [<div className='sidebar'>
                                    <label className='showMore' onClick={() => {
                                        this.setState({showCount: 8})
                                    }}>Minder...</label>
                                </div>]) :
                            this.createRow(null,
                                [<div className='sidebar'>
                                    <label className='showMore' onClick={() => {
                                        this.setState({showCount: -1})
                                    }}>Meer...</label>
                                </div>])}
                    </div>
                    <div className='decisionContainer'>
                        {isNotNull(this.state.insurances) ? this.state.insurances.map((insurance, key) => {
                                insurance = this.getInsurerByName(insurance);
                                return (
                                    <button key={key}
                                            className={insurance.ranking === 0 ? 'keepButton' : 'changeButton'}
                                            onClick={() => {
                                                this.clickInsurer(key + 1, insurance)
                                            }}>{insurance.ranking === 0 ? 'Ik blijf bij deze' : 'Ik wil deze'}
                                    </button>
                                )
                            }) :
                            <button
                                className='keepButton' onClick={() => {
                                this.clickInsurer(-1, null)
                            }}>Ga door
                            </button>}
                    </div>
                </div>
            </div>
        )
    }
}

CompareView.propTypes = {
    alternatives: PropTypes.arrayOf(PropTypes.shape({
        insurer: PropTypes.string.isRequired,
        monthprice: PropTypes.number.isRequired,
        difference: PropTypes.string.isRequired,
        basic: PropTypes.shape({
            name: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
        }).isRequired,
        coverages: PropTypes.object.isRequired,
    })).isRequired,
    navigate: PropTypes.func.isRequired,
};