import './style/materialFactory.scss';

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {MuiThemeProvider} from '@material-ui/core/styles'
import {DatePicker, TimePicker} from 'material-ui-pickers';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import Menu from '@material-ui/core/Menu';
import Popover from '@material-ui/core/Popover';
import Snackbar from '@material-ui/core/Snackbar';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import {toMoment, isFunction} from 'glob-common-js/lib/utils';

import {materialTypes} from "./materialTypes";
import MaterialPasswordField from "./materialPasswordField";
import {muiTheme} from "./muiTheme";

export default class MaterialFactory extends React.Component {
    onDateChange = (type, props) => date => {
        if (type === materialTypes.DATE_PICKER) date = date.format('YYYY-MM-DD');
        else if (type === materialTypes.TIME_PICKER) date = date.format('YYYY-MM-DDTHH:mm:ss');
        if (isFunction(props.onChange)) props.onChange(date);
    };

    getDateValue = (type, props) => isNotNull(props.value) ? toMoment(props.value) : null;

    renderComponent = () => {
        let props = _.merge({}, this.props);
        const componentType = props.componentType;
        delete props.componentType;
        let value = props.value;
        switch (componentType) {
            case materialTypes.THEME_PROVIDER:
                return <MuiThemeProvider theme={muiTheme}>{prepareChildren(props.children)}</MuiThemeProvider>;
            case materialTypes.RAISED_BUTTON:
                return <Button variant='contained'
                               color='primary' {...props}>{prepareChildren(props.children)}</Button>;
            case materialTypes.FLAT_BUTTON:
                return <Button {...props}>{prepareChildren(props.children)}</Button>;
            case materialTypes.DATE_PICKER:
                value = this.getDateValue(componentType, props);
                return <DatePicker keyboard disableOpenOnEnter format='DD-MM-YYYY' animateYearScrolling={false} autoOk
                                   cancelLabel='Annuleer' invalidDateMessage='Ongeldige datum' showTodayButton
                                   todayLabel='Vandaag' margin='dense' className='materialDatePicker' value={value}
                                   mask={maskValue => (maskValue ? [/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/,
                                           /\d/] :
                                       [])}{...props} onChange={this.onDateChange(componentType, props)}/>;
            case materialTypes.TIME_PICKER:
                value = this.getDateValue(componentType, props);
                return <TimePicker keyboard disableOpenOnEnter ampm={false} autoOk cancelLabel='Annuleer' format='HH:mm'
                                   invalidDateMessage='Ongeldige tijd' showTodayButton todayLabel='Nu' margin='dense'
                                   value={value}
                                   mask={maskValue => maskValue ? [/\d/, /\d/, ':', /\d/, /\d/] : []} {...props}
                                   onChange={this.onDateChange(componentType, props)}/>;
            case materialTypes.TEXT:
                return <TextField step={0.01} margin='dense' {...props}/>;
            case materialTypes.SELECT:
                const items = props.items;
                delete props.items;
                const SelectProps = {
                    MenuProps: {
                        PaperProps: {
                            style: {
                                maxHeight: '20rem',
                            },
                        },
                    },
                };
                return <TextField margin='dense' select SelectProps={SelectProps} {...props}>
                    {SelectProps.native && <option value='' style={{display: 'none'}}/>}
                    {items.map((item, key) => SelectProps.native ?
                        <option key={key} value={item.value}>{item.label}</option> :
                        <MenuItem key={key} value={item.value} {...item.props}>{item.label}</MenuItem>)}
                </TextField>;
            case materialTypes.CHECKBOX:
                if (isNotNull(props.label)) {
                    const indeterminate = props.indeterminate || false;
                    delete props.indeterminate;
                    return (
                        <FormControlLabel
                            control={<Checkbox color='primary' indeterminate={indeterminate}/>} {...props}/>
                    );
                }
                return <Checkbox color='primary' {...props}/>;
            case materialTypes.TOGGLE:
                if (isNotNull(props.label))
                    return <FormControlLabel control={<Switch color='primary' {...props}/>} {...props}/>;
                return <Switch color='primary' {...props}/>;
            case materialTypes.DIALOG:
                const {title, text, actions} = props;
                delete props.title;
                delete props.text;
                delete props.actions;
                return (
                    <Dialog {...props}>
                        {isNotNull(title) && <DialogTitle>{title}</DialogTitle>}
                        <DialogContent>
                            {isNotNull(text) && <DialogContentText>{text}</DialogContentText>}
                            {prepareChildren(props.content)}
                        </DialogContent>
                        <DialogActions>
                            {actions.map((action, key) => <Button key={key}
                                                                  color='primary' {...action}>{action.label}</Button>)}
                        </DialogActions>
                    </Dialog>
                );
            case materialTypes.PASSWORD:
                return <MaterialPasswordField margin='dense' {...props}/>;
            case materialTypes.CIRCULAR_PROGRESS:
                return <CircularProgress size={80} thickness={7} {...props}/>;
            case materialTypes.LINEAR_PROGRESS:
                return <LinearProgress {...props}/>;
            case materialTypes.MENU:
                return <Menu {...props}>{prepareChildren(props.children)}</Menu>;
            case materialTypes.MENU_ITEM:
                return <MenuItem {...props}>{prepareChildren(props.children)}</MenuItem>;
            case materialTypes.POPOVER:
                return <Popover {...props}>{prepareChildren(props.children)}</Popover>;
            case materialTypes.SNACKBAR:
                return <Snackbar autoHideDuration={4000} {...props}/>;
            case materialTypes.GRID_LIST:
                return <GridList {...props}>{prepareChildren(props.children)}</GridList>;
            case materialTypes.GRID_TILE:
                return <GridListTile {...props}>{prepareChildren(props.children)}</GridListTile>;
            case materialTypes.TABS:
                return <Tabs {...props}>{prepareChildren(props.children)}</Tabs>;
        }
    };

    render = () => {
        if (isNotNull(this.props.componentType))
            return this.renderComponent();
        return null;
    }
}

MaterialFactory.propTypes = {
    componentType: PropTypes.oneOf(Object.values(materialTypes)).isRequired,
    className: PropTypes.string,
    style: PropTypes.object,
    type: PropTypes.string,
    onClick: PropTypes.func,
    fullWidth: PropTypes.bool,
};

const prepareChildren = children => {
    if (Array.isArray(children) && children.length > 1)
        return children.map((child, key) => {
            if (child instanceof Object) {
                child = Object.assign({}, child, {key});
            }
            return child;
        });
    return children;
};

/**
 * The Material-UI Tab component needs a function to be created since the Tabs component can only receive component Tab
 * as children. Therefore we cannot use the factory component above.
 */
export const createTab = props => <Tab {...props}/>;

export const createCircularProgress = props => <CircularProgress size={80} thickness={7} {...props}/>;

export const createCheckbox = props => {
    if (isNotNull(props.label))
        return (
            <FormControlLabel control={<Checkbox color='primary'/>} {...props}/>
        );
    return <Checkbox color='primary' {...props}/>;
};

export const createTile = props => <GridListTile {...props}>{prepareChildren(props.children)}</GridListTile>;