import React from 'react';
import PropTypes from 'prop-types';
import AutoSuggestBar from "glob-common-react/lib/searchBar/autoSuggestBar";
import {isFunction} from "glob-common-js/lib/utils";
import ExplanationFrame from "../explanationFrame";
import {sendGaEvent} from "../../../common/js/ga";
import {getPlatformVersion} from "../../../misc/utils";

const GA_CATEGORY = 'Document toevoegen';
export default class SearchDocumentTypeView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            frameActive: false,
        };
        this._isMounted = false;
    }

    changeState = stateChange => {
        this._isMounted && this.setState(stateChange);
    };

    onOpenFrame = () => {
        sendGaEvent(GA_CATEGORY, 'Open filmpje', 'Documenten');
        this.changeState({frameActive: true});
    };

    onCloseFrame = () => {
        sendGaEvent(GA_CATEGORY, 'Sluit filmpje', 'Documenten');
        this.changeState({frameActive: false});
    };

    onInputChange = (evt) => {
        if (isFunction(this.props.onInputChange)) {
            this.props.onInputChange(evt);
        }
    };

    componentDidMount = () => {
        this._isMounted = true;
    };

    componentWillUnmount = () => {
        this._isMounted = false;
    };

//    <label className='searchDocumentDescription'>Kies een
//        type{window.matchMedia('(min-width: 600px)').matches ? ' links ' : ' '}
//        in de modules of zoek een document type in de balk
//        hieronder</label>


    render = () => {
        let subtext = window.matchMedia('(min-width: 600px)').matches ? ' links ' : ' '
        let searchText = "Kies een type" + subtext + "in de modules of zoek een document type in de balk hieronder"
        if (getPlatformVersion()==='lifeWill') {
          searchText = "Zoek een document type in de balk hieronder"
        }

        return (
            <div className='searchDocumentType'>
                <div className='headerContainer'>
                    <label className='searchDocumentHeader'>Wat voor een document wil je toevoegen?</label>
                    <div className='infoContainer'>
                        <span className='common-icon-info infoIcon' onClick={this.onOpenFrame}/>
                        <ExplanationFrame onClose={this.onCloseFrame} active={this.state.frameActive} placeRight/>
                    </div>
                </div>
                <label className='searchDocumentDescription'>{searchText}</label>
                <AutoSuggestBar onInputChange={this.onInputChange} collection={this.props.documentTypes}
                                onSuggestionClick={this.props.onSuggestionClick}
                                inputPlaceholder={'Zoek document type'} theme={{
                    suggestion: 'suggestion',
                    suggestionsContainer: 'documentSuggestionsContainer',
                    suggestionsContainerOpen: 'documentSuggestionsContainer open',
                    input: 'suggestionsInput',
                    suggestionsList: 'suggestionsList',
                    suggestionHighlighted: 'suggestionHighlighted',
                }}/>
            </div>
        );
    }
}

SearchDocumentTypeView.propTypes = {
    documentTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    onSuggestionClick: PropTypes.func.isRequired,
    onInputChange: PropTypes.func,
};
