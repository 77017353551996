import React from 'react';
import StateComponent from "../../misc/stateComponent";
import TestMail from "./testMail";
import {getMailList} from "../../../misc/requestSender";

export const GET_LIST = 'get_list', CREATE_TASK = 1;
export default class TestMailController extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            listResult: [],
        };
    }

    onSubmit = (task, filters, taskParams, filter_type) => {
        if (task === GET_LIST) {
            this.onGetList(filters, filter_type);
        }
    };

    onGetList = (filters, filter_type) => {
        this.changeState({loading: true});
        filters = filters.slice();
        filters.forEach(filter => {
            filter.op = filter.operator;
        });
        getMailList({
            filters, filter_type, callback: (response) => {
                this.changeState({loading: false});
                let {users} = response.data;
                users = users || [];
                this.changeState({listResult: users});
            }
        });
    };

    componentDidMount = () => {
        this.mount();
    };

    componentWillUnmount = () => {
        this.unMount();
    };

    render = () => {
        const {loading, listResult} = this.state;
        return <TestMail loading={loading} onSubmit={this.onSubmit} listResult={listResult}/>;
    }
};