import React from 'react';
import {withRouter} from 'react-router-dom';

export class Navigator extends React.Component {
    constructor(props) {
        super(props);
    }

    navigate = evt => {
        const navigator = evt.target;
        let url = navigator.dataset.url + (navigator.dataset.strip === '0' ? this.props.location.search : '');
        navigator.dataset.strip = '0';
        this.props.history.push(url);
    };

    render = () => (
        <button id='generalNavigator' className='navigationButton' data-url='' data-strip='0' onClick={this.navigate}/>
    );
}

export default withRouter(Navigator);