import {debug, getSetting, isFunction} from 'glob-common-js/lib/utils';

const eventListeners = [];
const eventParams = {};

export const addCustomEventListener = (name, callback) => {
    if (isNotNull(name) && isFunction(callback)) {
        eventListeners.push({name, callback});
    }
};

export const addCustomEventListenerInit = (name, callback) => () => {
    addCustomEventListener(name, callback);
};

export const addCustomEventParam = (name, value) => {
    debug('Adding custom event param: ', {name, value});
    eventParams[name] = value;
};

export const removeCustomEventParam = name => {
    if (eventParams.hasOwnProperty(name))
        delete eventParams[name];
};

export const removeCustomEventParamIf = (name, condition) => {
    if (condition(eventParams[name])) removeCustomEventParam(name);
};

export const getCustomEventParam = (name, remove = false) => {
    const value = eventParams[name];
    if (remove)
        removeCustomEventParam(name);
    return value;
};

export const dispatchCustomEvent = (name, params) => {
    if (isNotNull(name)) {
        debug('Dispatching custom event \'' + name + '\': ' + JSON.stringify(params));
        for (let i = 0; i < eventListeners.length; i++) {
            let listener = eventListeners[i];
            if (listener.name === name) {
                listener.callback(params);
            }
        }
    }
};

export const removeCustomEventListener = (name, callback) => {
    if (isNotNull(name) && isFunction(callback)) {
        for (let i = 0; i < eventListeners.length; i++) {
            let listener = eventListeners[i];
            if (listener.name === name && listener.callback === callback) {
                eventListeners.splice(i, 1);
                break;
            }
        }
    }
};

export const getCustomEventListeners = () => {
    return eventListeners;
};

window.logListeners = getSetting('mode') !== 'prod' ? () => {
    return getCustomEventListeners();
} : undefined;

window.dce = getSetting('mode') !== 'prod' ? (name, params) => {
    dispatchCustomEvent(name, params);
} : undefined;