import {toMoment} from 'glob-common-js/lib/utils';
import {nodeMatchesObjectProps} from "enzyme/src/RSTTraversal";
import {getAccount} from "../misc/requestSender";

export class DaisyUser extends Object {
    #fields = {
        id: '',
        birthday: '',
        subscription: {},
        payment_info: {},
        email: '',
        firstname: '',
        status: '',
        type: '',
        customer_id: '',
        tags: {},
        flex: {},
        manual_cancel: '',
        exited_at: '',
        mailchimp_unsubscribed: 0,
        action_code_id: '',
    };

    constructor(props) {
        super(props);
        this.#initializeFields(props);
    }

    hasOwnProperty = name => {
        return isNotNull(this[name]) || this.#fields[name];
    };

    get id() {
        return this.#getNullSafe('id');
    }

    set id(id) {
        this.#fields.id = id;
    }

    get birthday() {
        const birthday = this.#getNullSafe('birthday');
        if (isNotNull(birthday)) return toMoment(birthday);
        return null;
    }

    set birthday(birthday) {
        this.#fields.birthday = birthday;
    }

    get subscription() {
        return this.#getNullSafe('subscription', {});
    }

    set subscription(subscription) {
        this.#fields.subscription = subscription;
    }

    get paymentInfo() {
        return this.#getNullSafe('payment_info', {});
    }

    set paymentInfo(paymentInfo) {
        this.#fields.payment_info = paymentInfo;
    }

    get email() {
        return this.#getNullSafe('email');
    }

    set email(email) {
        this.#fields.email = email;
    }

    get firstname() {
        return this.#getNullSafe('firstname');
    }

    set firstname(firstname) {
        this.#fields.firstname = firstname;
    }

    get status() {
        return this.#getNullSafe('status');
    }

    set status(status) {
        this.#fields.status = status;
    }

    get type() {
        return this.#getNullSafe('type');
    }

    set type(type) {
        this.#fields.type = type;
    }

    get customerId() {
        return this.#getNullSafe('customer_id');
    }

    set customerId(customerId) {
        this.#fields.customer_id = customerId;
    }

    get tags() {
        return this.#getNullSafe('tags', {});
    }

    set tags(tags) {
        this.#fields.tags = tags;
    }

    get flex() {
        return this.#getNullSafe('flex', {});
    }

    set flex(flex) {
        this.#fields.flex = flex;
    }

    get manualCancel() {
        const cancel = this.#getNullSafe('manual_cancel');
        return isNotNull(cancel) ? toMoment(cancel) : null;
    }

    set manualCancel(manualCancel) {
        this.#fields.manual_cancel = manualCancel;
    }

    get exitedAt() {
        const exitedAt = this.#getNullSafe('exited_at');
        return isNotNull(exitedAt) ? toMoment(exitedAt) : null;
    }

    set exitedAt(exitedAt) {
        this.#fields.exited_at = exitedAt;
    }

    get mailchimpUnsubscribed() {
        return this.#getNullSafe('mailchimp_unsubscribed', 0);
    }

    set mailchimpUnsubscribed(value) {
        this.#fields.mailchimp_unsubscribed = value;
    }

    get actionCodeId() {
        return this.#getNullSafe('action_code_id');
    }

    set actionCodeId(id) {
        this.#fields.action_code_id = id;
    }

    get mailingListData() {
        return {
            id: this.id,
            email: this.email,
        }
    }

    #initializeFields = props => {
        this.#setField('id', props.id);
        this.#setField('birthday', props.birthday);
        this.#setField('subscription', props.subscription);
        this.#setField('payment_info', props.payment_info);
        this.#setField('email', props.email);
        this.#setField('firstname', props.firstname);
        this.#setField('status', props.status);
        this.#setField('type', props.type);
        this.#setField('customer_id', props.customer_id);
        this.#setField('tags', props.tags);
        this.#setField('flex', props.flex);
        this.#setField('manual_cancel', props.manual_cancel);
        this.#setField('exited_at', props.exited_at);
        this.#setField('mailchimp_unsubscribed', props.mailchimp_unsubscribed);
        this.#setField('action_code_id', props.action_code_id);
    };

    #setField = (key, value, allowNull = false) => {
        if (allowNull || isNotNull(value))
            this.#fields[key] = value;
    };

    #getNullSafe = (key, defaultValue = '') => isNotNull(this.#fields[key]) ? this.#fields[key] : defaultValue;
}