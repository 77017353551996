import './style/registrationStep.scss';

import React from 'react';
import PropTypes from 'prop-types';
import MailCheck from 'mailcheck';

import {getSetting, isValidEmail, toMoment} from 'glob-common-js/lib/utils';
import {hasHover} from 'glob-common-react/lib/utils/reactUtils';

import PasswordRestrictions from "../../../../common/react/platform/passwordRestrictions";
import {sendGaEvent, sendPageView} from "../../../../common/js/ga";
import MaterialFactory from "../../../material/materialFactory";
import {materialTypes} from "../../../material/materialTypes";
import BdhInput from "../../../customControls/input/bdhInput";
import BdhPasswordInput from "../../../customControls/input/password/bdhPasswordInput";

const passRules = [
    {label: '1. Minstens 1 nummer', test: password => (/\d/.test(password))},
    {label: '2. Minstens 1 letter', test: password => (/[A-Za-z]/.test(password))},
    {label: '3. Minstens 8 karakters', test: password => (password.length >= 8)},
    {label: '4. Maximaal 16 karakters', test: password => (password.length <= 16)}
];

const GA_CATEGORY = 'Registreren (Aanmelden)';
export default class RegistrationStep extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            dob: '1990-01-01',
            email: '',
            password: '',
            terms: true,
            errors: {
                firstName: null,
                dob: null,
                email: this.props.emailError,
                password: null,
                terms: null,
            },
            successes: {
                firstName: null,
                email: null,
                password: null,
            },
            daySelected: true,
            monthSelected: true,
            passRestrictions: false,
            suggestedMail: null,
            passwordVisible: false,
        };
        this._isMounted = false;
    }

    changeState = stateChange => {
        this._isMounted && this.setState(stateChange);
    };

    onTogglePasswordVisible = () => {
        this.changeState({
            passwordVisible: !this.state.passwordVisible,
        });
    };

    onBlur = (field, validator) => () => {
        const value = this.state[field];
        this.changeState({
            successes: Object.assign({}, this.state.successes, {
                [field]: validator(value),
            }),
        });
    };

    onFirstNameClick = () => {
        if (['dev', 'test'].includes(getSetting('mode'))) {
            this.changeState({
                firstName: 'Dave',
                dob: new Date('1990-10-06'),
                email: 'daveyvanrijn+' + new Date().getTime() + '@hotmail.com',
                password: 'devpassword123',
                terms: true,
                daySelected: true,
                monthSelected: true,
            });
        }
    };

    onClickRegister = () => {
        if (this.testValue('firstName') && this.testDob() && this.testEmail() && this.testPassword()
            && this.testTerms()) {
            this.props.onRegister(this.createRegisterValues());
            sendGaEvent(GA_CATEGORY, 'Klik', 'registreren');
        }
    };

    onChangeMail = (value) => {
        const email = value;
        window.clearTimeout(this.mailTimeOut);
        this.onChangeValue('email', email);
        this.mailTimeOut = window.setTimeout(() => {
            MailCheck.defaultDomains.push('xs4all.nl', 'kpnmail.nl', 'ziggo.nl', 'bydehand.eu', 'hotmail.com',
                'hotmail.nl');
            MailCheck.run({
                email: email,
                suggested: suggested => {
                    this.changeState({suggestedMail: suggested.full});
                },
                empty: () => {
                    if (isNotNull(this.state.suggestedMail))
                        this.changeState({suggestedMail: null});
                }
            })
        }, 300);
    };

    onClickEmailSuggestion = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();
        // TODO GA
        sendGaEvent(GA_CATEGORY, 'Klik', 'E-mailadres suggestie');
        const email = this.state.suggestedMail;
        this.changeState({suggestedMail: null});
        this.onChangeValue('email', email);
    };

    onChangeValue = (name, value) => {
        this.changeState({
            [name]: value,
        });
    };

    onChange = name => value => {
        this.changeState({[name]: value});
    };

    onRemoveError = (name) => {
        this.changeState({
            errors: {...this.state.errors, [name]: null},
            successes: {...this.state.successes, [name]: null}
        });
    };

    onFocusError = name => () => {
        this.changeState({
            errors: {...this.state.errors, [name]: null},
            successes: {...this.state.successes, [name]: null}
        });
    };

    onTogglePassRestrictions = (validatePassword = false) => () => {
        this.onRemoveError('password');
        this.changeState({passRestrictions: !this.state.passRestrictions});
        if (validatePassword)
            this.testPassword(true);
    };

    testValue = (name) => {
        const value = this.state[name];
        if (isNull(value)) {
            this.changeState({errors: {...this.state.errors, [name]: 'Vul dit veld in'}});
            return false;
        }
        return true;
    };

    testEmail = () => {
        if (this.testValue('email')) {
            const email = this.state.email;
            if (!isValidEmail(email)) {
                this.changeState({errors: {...this.state.errors, email: 'Vul een geldig e-mailadres in'}});
                return false;
            }
        }
        return true;
    };

    testTerms = () => {
        if (!this.state.terms) {
            this.changeState({errors: {...this.state.errors, terms: 'Accepteer de voorwaarden'}});
            return false;
        }
        return true;
    };

    testDob = () => {
        if (!this.state.daySelected || !this.state.monthSelected) {
            this.changeState({errors: {...this.state.errors, dob: 'Kies je geboortedatum'}});
            return false;
        }
        return true;
    };

    testPassword = (acceptNull = false) => {
        const password = this.state.password;
        const falsePassword = () => {
            this.changeState({errors: {...this.state.errors, password: 'Vul een geldig wachtwoord in'}});
            return false;
        };
        if (isNull(password)) {
            if (acceptNull) return true;
            return falsePassword();
        }
        for (let i = 0; i < passRules.length; i++) {
            let rule = passRules[i];
            if (!rule.test(password)) return falsePassword();
        }
        return true;
    };

    createRegisterValues = () => ({
        firstname: this.state.firstName,
        dob: toMoment(this.state.dob),
        email: this.state.email,
        password: this.state.password,
        terms: this.state.terms,
    });

    clickListener = () => {
        const mailSuggestion = document.querySelector('div.emailSuggestion');
        const mailInput = document.getElementById('registerEmail');
        if (areNotNull(mailSuggestion, mailInput)) {
            if (!hasHover(mailSuggestion) && !hasHover(mailInput) && isNotNull(this.state.suggestedMail))
                this.changeState({suggestedMail: null});
        }
    };

    componentDidMount = () => {
        ga('set', 'page', '/registratie/1-aanmelden');
        sendPageView();
        this._isMounted = true;
        window.addEventListener('click', this.clickListener);
    };

    componentWillUnmount = () => {
        this._isMounted = false;
        window.removeEventListener('click', this.clickListener);
    };

    render = () => {
        const {emailError} = this.props;
        const {firstName, errors, email, password, dob, passwordVisible} = this.state;
        return (
            <div className='registrationStepView'>
                {/*<label className='infoText'>Meld je in slechts 2 minuten aan om toegang te krijgen tot je persoonlijke
                    ByDeHand account.</label>
                */}
                <label className='infoText' style={{ color: 'red' }}>ByDeHand is gestopt. Je kunt je daarom niet meer aanmelden.</label>

                <BdhInput variant='contained' onChange={this.onChange('firstName')} value={firstName}
                          label='Voornaam *' error={errors.firstName} onDoubleClick={this.onFirstNameClick}
                          onFocus={this.onFocusError('firstName')} fullWidth
                          onBlur={this.onBlur('firstName', (value) => isNotNull(value))}/>
                <div className='dobContainer fieldContainer'>
                    <MaterialFactory componentType={materialTypes.DATE_PICKER} label='Geboortedatum' required
                                     fullWidth value={dob} variant='outlined' onChange={date => {
                        this.onChangeValue('dob', date);
                    }}/>
                </div>
                <div className='emailContainer fieldContainer'>
                    <BdhInput variant='contained' type='email' onChange={this.onChangeMail} value={email}
                              error={errors.email || emailError} label='E-mailadres *' fullWidth
                              onFocus={this.onFocusError('email')}
                              onBlur={this.onBlur('email', (value) => isValidEmail(value))}/>
                    <div className={'emailSuggestion' + (isNotNull(this.state.suggestedMail) ? ' active' : '')}>
                        <label>Bedoel je <span className='suggestedEmail'
                                               onClick={this.onClickEmailSuggestion}>{this.state.suggestedMail}</span>
                            ?</label>
                    </div>
                </div>
                <div className='passwordContainer fieldContainer'>
                    <BdhPasswordInput variant='contained' value={password} onChange={this.onChange('password')}
                                      label='Wachtwoord *' onFocus={this.onTogglePassRestrictions()}
                                      onBlur={this.onTogglePassRestrictions(true)} error={errors.password}
                                      autoComplete='new-password' valueVisible={passwordVisible}
                                      onToggleVisibility={this.onTogglePasswordVisible} fullWidth/>
                    <PasswordRestrictions password={this.state.password} passRules={passRules}
                                          restrictionsVisible={this.state.passRestrictions}/>
                </div>
                <label className='conditionsMessage'>Door je aan te melden ga je akkoord met de <a
                    className='conditionsLink' onClick={() => {
                    sendGaEvent(GA_CATEGORY, 'Klik', 'Algemene voorwaarden');
                }} href='//bydehand.com/algemene-voorwaarden/' target='_blank'>algemene voorwaarden</a>.</label>

                <div className='registrationFooter'>
                    <label className='loginLink' onClick={this.props.onClickLogin}>Heb je al een account?</label>
                    {/*
                    <MaterialFactory componentType={materialTypes.RAISED_BUTTON} className='registerButton'
                                     onClick={this.onClickRegister}>Aanmelden ></MaterialFactory>

                    */}
                </div>
            </div>
        )
    };
}

RegistrationStep.propTypes = {
    onClickLogin: PropTypes.func.isRequired,
    onRegister: PropTypes.func.isRequired,
    emailError: PropTypes.string,
};
