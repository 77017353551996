import './style/loader.scss';

import React from 'react';
import PropTypes from 'prop-types';
import {isFunction} from 'glob-common-js/lib/utils';

export default class Loader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            enabled: false,
            top: null,
            left: null,
        };
        this._isMounted = false;
        this.startTime = 0;
        this.timeout = null;
        this.elementIds = null;
    }

    changeState = stateChange => {
        if (this._isMounted) {
            this.setState(stateChange);
        }
    };

    disableElements = (setClassName) => {
        if (isNotNull(this.elementIds)) {
            for (let i = 0; i < this.elementIds.length; i++) {
                let id = this.elementIds[i];
                let element = document.getElementById(id);
                if (isNotNull(element)) {
                    element.disabled = true;
                    if (setClassName) {
                        element.classList.add('disabled');
                    }
                }
            }
        }
    };

    enableElements = () => {
        if (isNotNull(this.elementIds)) {
            for (let i = 0; i < this.elementIds.length; i++) {
                let id = this.elementIds[i];
                let element = document.getElementById(id);
                if (isNotNull(element)) {
                    element.disabled = false;
                    if (element.classList.contains('disabled')) {
                        element.classList.remove('disabled');
                    }
                }
            }
        }
    };

    enable = (idsToDisable, setClassName = false) => {
        this.elementIds = idsToDisable;
        this.disableElements(setClassName);
        this.startTime = new Date().getTime();
        this.changeState({
            enabled: true
        });
        this.timeout = window.setTimeout(() => {
            this.disable(true);
        }, this.props.maxTime);
    };

    disable = (callback = null, force = false) => {
        const disableChecked = () => {
            this.changeState({
                enabled: false
            });
            this.enableElements();
            if (isNotNull(this.timeout)) {
                window.clearTimeout(this.timeout);
                this.timeout = null;
            }
            if (isFunction(callback)) {
                callback();
            }
        };
        if (force) return disableChecked();

        let endTime = new Date().getTime();
        if (endTime - this.startTime < this.props.minimalTime) {
            let remainingTime = this.props.minimalTime - (endTime - this.startTime);
            window.setTimeout(() => {
                disableChecked();
            }, remainingTime)
        } else {
            disableChecked();
        }
    };

    componentDidMount = () => {
        this._isMounted = true;
    };

    componentWillUnmount = () => {
        this._isMounted = false;
    };

    render = () => {
        let backgroundClass = ['loaderBackground', this.props.backgroundColor, this.props.locate, this.props.backgroundClass].join(' ');
        let containerClass = ['loaderContainer', this.props.containerClass, this.props.locate].join(' ');
        let loaderClass = ['loader', this.props.loaderClass].join(' ');
        let textClass = ['loaderText', this.props.textClass, this.props.textBackground].join(' ');
        let display = this.state.enabled && this.props.showLoader ? null : 'none';

        return (
            <>
                {this.props.includeBackground &&
                <div className={backgroundClass} style={{display}}/>}
                <div ref={refName => {
                    this.container = refName
                }} className={containerClass} style={{display}}>
                    <div className={loaderClass} style={{display}}/>
                    <label className={textClass} style={{display}}>
                        {this.props.text}
                    </label>
                </div>
            </>
        );
    }
}

Loader.propTypes = {
    minimalTime: PropTypes.number,
    maxTime: PropTypes.number,
    text: PropTypes.string,
    textClass: PropTypes.string,
    textBackground: PropTypes.oneOf(['default', 'transparent']),
    showLoader: PropTypes.bool,
    includeBackground: PropTypes.bool,
    backgroundColor: PropTypes.oneOf(['transparent', 'white', 'gray', 'black']),
    containerClass: PropTypes.string,
    loaderClass: PropTypes.string,
    backgroundClass: PropTypes.string,
    locate: PropTypes.oneOf(['fixed', 'absolute', 'relative']),
};

Loader.defaultProps = {
    minimalTime: 0,
    maxTime: 8000,
    text: 'Even wachten...',
    textBackground: 'default',
    textClass: '',
    showLoader: true,
    includeBackground: false,
    backgroundColor: 'transparent',
    containerClass: '',
    loaderClass: '',
    backgroundClass: '',
    locate: 'fixed',
};