import React from 'react';

import {stringStartsWith, stringContains} from 'glob-common-js/lib/utils';

import StateComponent from "../../misc/stateComponent";
import ContactLinkView from "./contactLinkView";
import Dossier from "../../../models/dossier";
import {Contact} from "../../../models/contact";
import {addCustomEventListener, dispatchCustomEvent, removeCustomEventListener} from "../../../misc/eventDispatcher";
import {dispatchContactEvent, EVENT_TYPES} from "../../../misc/dataEvent";

export default class ContactLinkController extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            contact: new Contact(),
            active: false,
            loading: false,
            dossiers: [],
            searchValue: '',
        };
    }

    onActivate = contact => {
        dispatchCustomEvent('openLinkDossierContact');
        this.changeState({active: true, loading: true});
        contact.loadDossiers(contact => {
            this.loadDossiers(contact);
        });
    };

    onClose = () => {
        dispatchCustomEvent('closeLinkDossierContact');
        this.changeState({active: false, dossiers: [], searchValue: '', loading: false, contact: new Contact()});
    };

    onToggleDossier = dossier => () => {
        const {contact} = this.state;
        if (contact.dossierIsLinked(dossier)) {
            contact.removeDossier(dossier.id);
            this.toggleDossierCallback(contact);
        } else {
            const toAdd = isNull(contact.id) ? dossier.id : dossier;
            contact.addDossier(toAdd, this.toggleDossierCallback);
        }

    };

    toggleDossierCallback = (contact) => {
        this.changeState({loading: true});
        if (isNull(contact.id))
            contact.save(contact => {
                contact.loadDossiers(contact => {
                    this.changeState({contact, loading: false});
                    dispatchContactEvent(EVENT_TYPES.CREATE, contact);
                });
            });
        else
            contact.update(() => {
                this.changeState({contact, loading: false});
                dispatchContactEvent(EVENT_TYPES.UPDATE, contact);
            });
    };

    onClearSearch = () => {
        this.onSearch('');
    };

    onSearch = value => {
        this.changeState({searchValue: value});
    };

    getDossiers = () => {
        const {contact, dossiers} = this.state, loadedDossiers = [];
        for (const doss of dossiers) {
            doss.setField('isLinked', contact.dossierIsLinked(doss));
            if (isNotNull(this.dossierMatchesSearch(doss)))
                loadedDossiers.push(doss);
        }
        return this.sortDossiers(loadedDossiers);
    };

    sortDossiers = dossiers => {
        dossiers = dossiers.slice();
        let linked = [], notLinked = [];
        for (const doss of dossiers) {
            if (doss.getField('isLinked')) linked.push(doss);
            else notLinked.push(doss);
        }
        linked = this.sortAlphabetical(linked);
        notLinked = this.sortAlphabetical(notLinked);
        return notLinked.concat(linked);
    };

    sortAlphabetical = dossiers => {
        dossiers = dossiers.slice();
        dossiers.sort((dossierA, dossierB) => {
            const nameA = dossierA.name.toLowerCase(), nameB = dossierB.name.toLowerCase();
            return nameA.localeCompare(nameB);
        });
        return dossiers;
    };

    dossierMatchesSearch = dossier => {
        const {searchValue} = this.state;
        if (isNull(searchValue)) return true;
        const name = dossier.name, typeLabel = dossier.type.label;
        if (stringStartsWith(name, searchValue)) return 'nameStart';
        if (stringStartsWith(typeLabel, searchValue)) return 'typeStart';
        if (stringContains(name, searchValue)) return 'nameContains';
        if (stringContains(typeLabel, searchValue)) return 'typeContains';
        return null;
    };

    loadDossiers = contact => {
        Dossier.getAll(dossiers => {
            dossiers = dossiers.filter(dossier => {
                if (dossier.type.config.isAnalysis) return false;
                if (isNull(dossier.externalData) || isNull(dossier.externalData.isDossier)) return true;
                return dossier.externalData.isDossier && dossier.type.name !== 'financial_overview';
            });
            dossiers.sort((dossierA, dossierB) => {
                const labelA = dossierA.name.toLowerCase(), labelB = dossierB.name.toLowerCase();
                const preferA = -1, preferB = 1, isEqual = 0;
                return labelA < labelB ? preferA : labelA > labelB ? preferB : isEqual;
            });
            this.changeState({loading: false, dossiers, contact});
        });
    };

    componentDidMount = () => {
        this.mount();
        addCustomEventListener('linkContact', this.onActivate);
    };

    componentWillUnmount = () => {
        this.unMount();
        removeCustomEventListener('linkContact', this.onActivate);
    };

    render = () => {
        const {loading, active, contact, searchValue} = this.state;
        if (active)
            return <ContactLinkView onClose={this.onClose} onToggleDossier={this.onToggleDossier}
                                    dossiers={this.getDossiers()} loading={loading} onClearSearch={this.onClearSearch}
                                    contact={contact} onSearch={this.onSearch} searchValue={searchValue}/>;
        return null;
    };
}