import './style/twoFactor.scss';

import React from 'react';
import PropTypes from 'prop-types';

import {isFunction} from 'glob-common-js/lib/utils';

import {addCustomEventListener, removeCustomEventListener} from "../../../misc/eventDispatcher";
import {login, updateMobile} from "../../../misc/requestSender";
import StateComponent from "../../misc/stateComponent";
import MaterialFactory from "../../material/materialFactory";
import {materialTypes} from "../../material/materialTypes";
import PopupBuilder from "../../misc/popupBuilder/popupBuilder";
import GenericLoader from "../../misc/genericLoader";

export default class TwoFactor extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            code: [-1, -1, -1, -1, -1],
            active: false,
            error: null,
            resendTimeout: null,
        };
        this.submitCallback = null;
        this.phone_mobile = null;
        this.mode = null; // login || update
        this.password = null;
        this.email = null;
    }

    activate = params => {
        if (isFunction(params.submitCallback) && isNotNull(params.mode)) {
            this.submitCallback = params.submitCallback;
            this.phone_mobile = params.phone_mobile;
            this.mode = params.mode;
            this.password = params.password;
            this.email = params.email;
            this.changeState({active: true});
            this.focusBlock(0);
        }
    };

    focusBlock = (index) => {
        let codeBlocks = document.getElementsByName('2faCode-' + index);
        if (isNotNull(codeBlocks)) {
            codeBlocks[0].focus();
        }
    };

    resend = () => {
        if (this.state.resendTimeout === null) {
            this.changeState({
                resendTimeout: window.setTimeout(() => {
                    this.changeState({resendTimeout: null});
                }, 30000)
            });
            if (this.mode === 'update')
                updateMobile({
                    phone_mobile: this.phone_mobile, callback: () => {
                    }
                });
            else if (this.mode === 'login')
                login({
                    email: this.email, password: this.password, callback: () => {
                    }
                });
        }
    };

    deactivate = () => {
        this.submitCallback = null;
        this.phone_mobile = null;
        this.mode = null;
        this.password = null;
        this.email = null;
        this.changeState({active: false, code: [-1, -1, -1, -1, -1], error: null, resendTimeout: null});
    };

    onSubmit = () => {
        if (this.codeIsValid()) {
            this.props.onSubmit(this.state.code, this.submitCallback, this.phone_mobile, this.password, this.email,
                this.mode, this.onFail);
        } else this.onFail('Ongeldige code');
    };

    onFail = (message) => {
        this.changeState({error: message, code: [-1, -1, -1, -1, -1]});
        this.focusBlock(0);
    };

    onCancel = () => {
        this.changeState({
            active: false,
        })
    };

    codeIsValid = () => {
        let code = this.state.code;
        if (code.length !== 5 || code.includes(-1)) return false;
        for (let i = 0; i < code.length; i++) {
            if (isNaN(code[i]) || code[i] < 0 || code[i] > 9) return false;
        }
        return true;
    };

    onChangeCode = (index, value) => {
        this.changeState({error: null});
        if (isNotNull(value)) {
            if (!isNaN(value) && value < 10 && value >= 0) {
                let code = this.state.code.slice();
                code[index] = parseInt(value);
                this.changeState({code});
                this.onNext(index);
            }
        } else {
            let code = this.state.code.slice();
            code[index] = -1;
            this.changeState({code});
        }
    };

    onKeyDown = (evt) => {
        const target = evt.target;
        const index = parseInt(target.name.split('-')[1]);
        const keyCode = evt.keyCode;
        const ZERO = 48, NINE = 57, BACKSPACE = 8, NUM_ZERO = 96, NUM_NINE = 105, ENTER = 13, TAB = 9;
        if (keyCode !== TAB) {
            if (keyCode === ENTER) {
                evt.preventDefault();
                this.onSubmit();
            } else if (keyCode === BACKSPACE) {
                if (isNull(evt.target.value))
                    this.onPrevious(index);
            } else if ((keyCode < ZERO || keyCode > NINE) && (keyCode < NUM_ZERO || keyCode > NUM_NINE)) {
                evt.preventDefault();
            }
        }
    };

    onPrevious = index => {
        if (index === 0) return;
        let prevIndex = index - 1;
        this.focusBlock(prevIndex);
    };

    onNext = index => {
        if (index === 4) return;
        let nextIndex = index + 1;
        this.focusBlock(nextIndex);
    };

    renderCodeBlock = (index, key) => {
        let code = this.state.code;
        let value = code[index];
        value = value === -1 ? '' : value;
        return (
            <div className='codeBlock' key={key}>
                <input className='codeInput' value={value} name={'2faCode-' + index} type='text' onChange={(evt) => {
                    let value = evt.target.value;
                    this.onChangeCode(index, value);
                }}/>
            </div>
        )
    };

    toggleEnterListener = (add = true) => {
        const nodes = document.querySelectorAll('input.codeInput');
        if (isNotNull(nodes)) {
            forEach(nodes, node => {
                const toggleEvent = add ? node.addEventListener || node.attachEvent :
                    node.removeEventListener || node.detachEvent;
                toggleEvent('keydown', this.onKeyDown);
            });
        }
    };

    componentDidMount = () => {
        this.mount();
        this.toggleEnterListener();
        addCustomEventListener('2fa', this.activate);
        addCustomEventListener('close2Fa', this.deactivate);
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (!prevState.active && this.state.active)
            this.toggleEnterListener();
    };

    componentWillUnmount = () => {
        this.unMount();
        removeCustomEventListener('2fa', this.activate);
        removeCustomEventListener('close2Fa', this.deactivate);
    };

    render = () => {
        const {active, error} = this.state;
        return (
            <PopupBuilder open={active} content={{
                header: <label className='twoFactorHeaderText'>Er is zojuist een SMS verstuurd naar je mobiele
                    telefoonnummer met daarin een verificatie code. Vul de code hieronder in.</label>,
                body: <div className='twoFactorBody'>
                    <GenericLoader active={this.props.submitting}/>
                    {error && <div className='twoFactorErrorContainer'>
                        <p className='twoFactorError'>{error}</p>
                    </div>}
                    {[0, 1, 2, 3, 4].map(this.renderCodeBlock)}
                </div>,
                footer: <div className='twoFactorFooter'>
                    <MaterialFactory componentType={materialTypes.RAISED_BUTTON}
                                     className='footerButton cancelButton'
                                     onClick={this.onCancel}>Annuleer</MaterialFactory>
                    <div className='bottomButtons'>
                        <MaterialFactory componentType={materialTypes.RAISED_BUTTON}
                                         className='footerButton resendButton' onClick={this.resend}
                                         disabled={isNotNull(this.state.resendTimeout)}>Code opnieuw
                            sturen</MaterialFactory>
                        <MaterialFactory componentType={materialTypes.RAISED_BUTTON}
                                         className='footerButton sendButton'
                                         onClick={this.onSubmit}
                                         disabled={this.props.submitting}>Verstuur</MaterialFactory>
                    </div>
                </div>
            }}/>
        );
    };
}

TwoFactor.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    loggedIn: PropTypes.bool.isRequired,
};