import {scrollToBottom, deviceIsMobile, pxToRem, remToPx} from 'glob-common-js/lib/utils';

import OnboardingManager, {getPrevStep} from "./onboardingManager";
import {dispatchCustomEvent} from "../../../misc/eventDispatcher";
import {sendGaEvent} from "../../../common/js/ga";

export const agendaOnboardingSteps = {
    INTRO_STEP: 'INTRO_STEP',
    CHECKLIST_INTRO: 'CHECKLIST_INTRO',
    CHECKLIST_EXPLANATION: 'CHECKLIST_EXPLANATION',
    NOTIFY_HELP: 'NOTIFY_HELP',
    CLICK_ADD_AGENDA: 'CLICK_ADD_AGENDA',
    ADD_AGENDA: 'ADD_AGENDA',
    CONFIRM_AGENDA: 'CONFIRM_AGENDA',
};

const subSteps = {
    agenda: agendaOnboardingSteps.CLICK_ADD_AGENDA,
    checklist: agendaOnboardingSteps.CHECKLIST_INTRO,
};

const GA_CATEGORY = 'Onboarding agenda', eventName = 'agendaOnboardingFlow';

export default class AgendaManager extends OnboardingManager {
    #type = 'agenda';
    #step = agendaOnboardingSteps.INTRO_STEP;
    #flowType = null;

    constructor(props) {
        super(props);
        Object.freeze(this.#type);
    }

    getType = () => this.#type;

    setStep = step => {
        if (this.isActive()) {
            const old = this.#step;
            this.#step = step;
            this.#onStepChange(old);
        } else this.warnNotActive();
    };

    setNextStep = (params) => {
        if (this.isActive()) {
            const oldStep = this.#step;
            this.#step = this.#getNextStep(this.#step, params);
            this.#onStepChange(oldStep, params);
        } else this.warnNotActive();
    };

    setPrevStep = () => {
        if (this.isActive()) {
            const oldStep = this.#step;
            this.#step = getPrevStep(agendaOnboardingSteps, this.#step);
            this.#onStepChange(oldStep);
        } else this.warnNotActive();
    };

    getActiveStep = () => {
        return this.#step;
    };

    sendEvent = (action, label) => {
        sendGaEvent(GA_CATEGORY, action, label);
    };

    setFlowType = type => {
        this.#flowType = type;
    };

    #checkScrollPosition = (id, minTopRem = 17) => {
        const element = document.getElementById(id);
        if (isNotNull(element)) {
            const elementTop = element.getBoundingClientRect().top;
            if (elementTop < remToPx(minTopRem))
                window.scrollBy({top: (remToPx(minTopRem) - elementTop) * -1});
        }
    };

    #locateClickAddPopup = () => {
        const popup = document.getElementById('agendaOnboardingPopup');
        if (isNotNull(popup)) {
            popup.style.top = '12rem';
            popup.style.transform = 'translateX(5rem)';
        }
    };

    #locateClickAddMobile = () => {
        const container = document.getElementById('dashboardAgendaContainer');
        const popup = document.getElementById('agendaOnboardingPopup');
        if (areNotNull(container, popup)) {
            container.scrollIntoView({behavior: "instant", block: "center", inline: "nearest"});
            this.#checkScrollPosition('dashboardAgendaContainer');
            popup.style.top = pxToRem(container.getBoundingClientRect().top) - 8 + 'rem';
        }
    };

    #locateConfirmPopup = () => {
        if (deviceIsMobile()) this.#locateConfirmMobile();
        else {
            const popup = document.getElementById('agendaOnboardingPopup');
            if (isNotNull(popup))
                popup.style.transform = 'translateX(4.5rem)';
        }
    };

    #locateConfirmMobile = () => {
        const popup = document.getElementById('agendaOnboardingPopup');
        const container = document.getElementById('dashboardAgendaContainer');
        if (areNotNull(container, popup)) {
            container.scrollIntoView({behavior: "instant", block: "center", inline: "nearest"});
            this.#checkScrollPosition('dashboardAgendaContainer');
            popup.style.top = pxToRem(container.getBoundingClientRect().top) - 13 + 'rem';
        }
    };

    #removeTransformFromElement = id => {
        const element = document.getElementById(id);
        if (isNotNull(element))
            element.style.removeProperty('transform');
    };

    #getNextStep = (oldStep, params) => {
        switch (oldStep) {
            case agendaOnboardingSteps.INTRO_STEP:
                this.#flowType = params;
                return subSteps[this.#flowType];
            case agendaOnboardingSteps.CONFIRM_AGENDA:
            case agendaOnboardingSteps.CHECKLIST_EXPLANATION:
                return agendaOnboardingSteps.NOTIFY_HELP;
            case agendaOnboardingSteps.CLICK_ADD_AGENDA:
                return agendaOnboardingSteps.ADD_AGENDA;
            case agendaOnboardingSteps.ADD_AGENDA:
                return agendaOnboardingSteps.CONFIRM_AGENDA;
            case agendaOnboardingSteps.CHECKLIST_INTRO:
                return agendaOnboardingSteps.CHECKLIST_EXPLANATION;
            default:
                return false;
        }
    };

    #onStepChange = (oldStep, params) => {
        this.removePositionFromElement('agendaOnboardingPopup');
        this.#removeTransformFromElement('agendaOnboardingPopup');
        this.removeScrollListener();
        this.removeAllHighlights();
        dispatchCustomEvent(eventName, {type: 'setStep', data: {step: this.#step}});
        switch (this.#step) {
            case agendaOnboardingSteps.CLICK_ADD_AGENDA:
                this.highlightElement('dashboardAgendaContainer');
                this.highlightElement('addAgendaItemButton', true);
                if (deviceIsMobile()) this.#locateClickAddMobile();
                else {
                    scrollToBottom();
                    this.#locateClickAddPopup();
                }
                // this.togglePageScroll();
                break;
            case agendaOnboardingSteps.ADD_AGENDA:
                dispatchCustomEvent('openAgendaPopup');
                break;
            case agendaOnboardingSteps.CONFIRM_AGENDA:
                this.highlightElement('dashboardAgendaContainer');
                this.#locateConfirmPopup();
                if (!deviceIsMobile()) {
                    scrollToBottom();
                }
                // this.togglePageScroll();
                break;
            case false:
                dispatchCustomEvent(eventName, {type: 'deactivate'});
                this.#step = agendaOnboardingSteps.INTRO_STEP;
                this.cleanup();
                break;
        }
    };
}