import _ from 'lodash';

export const fieldTypes = {
    PRICE: 'PRICE',
    STRING: 'STRING',
    DATE: 'DATE',
    DATETIME: 'DATETIME',
    NUMERIC: 'NUMERIC',
};

export const fields = {
    frequency: {
        name: 'frequency',
        label: 'Betaalfrequentie',
        type: fieldTypes.STRING,
        options: [
            {
                value: 'one_time',
                label: 'Eenmalig',
            },
            {
                value: 'monthly',
                label: 'Maandelijks',
            },
            {
                value: 'quarterly',
                label: 'Ieder kwartaal',
            },
            {
                value: 'half_year',
                label: 'Ieder half jaar',
            },
            {
                value: 'yearly',
                label: 'Jaarlijks',
            }
        ],
        required: false,
    },
    start_date: {
        name: 'start_date',
        label: 'Startdatum',
        type: fieldTypes.DATE,
        options: null,
        required: false,
    },
    end_date: {
        name: 'end_date',
        label: 'Einddatum',
        type: fieldTypes.DATE,
        options: null,
        required: false,
    },
    company: {
        name: 'company',
        label: 'Bedrijf/persoon',
        type: fieldTypes.STRING,
        options: null,
        required: false,
    },
    zipcode: {
        name: 'zipcode',
        label: 'Postcode',
        type: fieldTypes.STRING,
        options: null,
        required: true,
    },
    house_number_suffix: {
        name: 'house_number_suffix',
        label: 'Toevoeging',
        type: fieldTypes.STRING,
        options: null,
        required: false,
    },
    house_number: {
        name: 'house_number',
        label: 'Huisnummer',
        type: fieldTypes.NUMERIC,
        options: null,
        required: true,
    },
    monthly_price: {
        name: 'monthly_price',
        label: 'Bedrag',
        type: fieldTypes.PRICE,
        options: null,
        required: false,
    },
    sports_club: {
        name: 'sports_club',
        label: 'Lid bij',
        type: fieldTypes.STRING,
        options: null,
        required: false,
    },
    type: {
        name: 'type',
        label: 'Type',
        type: fieldTypes.STRING,
        options: null,
        required: false,
    },
    discount_extra: {
        name: 'discount_extra',
        label: 'Korting aanvullende verzekering',
        type: fieldTypes.NUMERIC,
        options: null,
        required: false,
    },
    discount_dental: {
        name: 'discount_dental',
        label: 'Korting tandartsverzekering',
        type: fieldTypes.NUMERIC,
        options: null,
        required: false,
    },
    discount_basic: {
        name: 'discount_basic',
        label: 'Korting basisverzekering',
        type: fieldTypes.NUMERIC,
        options: null,
        required: false,
    },
    health_dentist: {
        name: 'health_dentist',
        label: 'Tandartsverzekering',
        type: fieldTypes.STRING,
        options: null,
        required: false,
    },
    health_supplementary: {
        name: 'health_supplementary',
        label: 'Aanvullende verzekering',
        type: fieldTypes.STRING,
        options: null,
        required: false,
    },
    health_insurance: {
        name: 'health_insurance',
        label: 'Basisverzekering',
        type: fieldTypes.STRING,
        options: null,
        required: true,
    },
    health_own_risk: {
        name: 'health_own_risk',
        label: 'Eigen risico',
        type: fieldTypes.PRICE,
        options: null,
        required: true,
    },
    health_insurer: {
        name: 'health_insurer',
        label: 'Verzekeraar',
        type: fieldTypes.STRING,
        options: null,
        required: false,
    },
    studie: {
        name: 'studie',
        label: 'Studie',
        type: fieldTypes.STRING,
        options: null,
        required: false,
    },
    profile: {
        name: 'profile',
        label: 'Profiel',
        type: fieldTypes.STRING,
        options: null,
        required: false,
    },
    instelling: {
        name: 'instelling',
        label: 'Instelling',
        type: fieldTypes.STRING,
        options: null,
        required: false,
    },
    exp_end_date: {
        name: 'exp_end_date',
        label: 'Verwachte einddatum',
        type: 'DATE',
        options: null,
        required: false,
    },
    level: {
        name: 'level',
        label: 'Niveau',
        type: fieldTypes.STRING,
        options: ["VMBO-t/k/b", "HAVO", "Atheneum", "Gymnasium", "MBO", "HBO", "Universiteit"],
        required: false,
    },
    doctor_gen: {
        name: 'doctor_gen',
        label: 'Huisarts',
        type: fieldTypes.STRING,
        options: null,
        required: false,
    },
    hospital: {
        name: 'hospital',
        label: 'Ziekenhuis',
        type: fieldTypes.STRING,
        options: null,
        required: false,
    },
    '"health_insurance"': {
        name: '"health_insurance"',
        label: 'Zorgverzekering',
        type: fieldTypes.STRING,
        options: null,
        required: false,
    },
    year: {
        name: 'year',
        label: 'Jaartal',
        type: fieldTypes.NUMERIC,
        options: null,
        required: false,
    },
    salary: {
        name: 'salary',
        label: 'Brutosalaris',
        type: fieldTypes.PRICE,
        options: null,
        required: false,
    },
    pay_day: {
        name: 'pay_day',
        label: 'Vaste betaaldag',
        type: fieldTypes.NUMERIC,
        options: (() => {
            let days = [];
            for (let i = 1; i <= 31; i++) days.push({label: i, value: i});
            return days;
        })(),
        required: false,
        value: 1,
    },
    description: {
        name: 'description',
        label: 'Omschrijving',
        type: fieldTypes.STRING,
        options: null,
        required: false,
    },
    purchase_date: {
        name: 'purchase_date',
        label: 'Aankoopdatum',
        type: fieldTypes.DATE,
        options: null,
        required: false,
    },
    brand: {
        name: 'brand',
        label: 'Merk',
        type: fieldTypes.STRING,
        options: null,
        required: false,
        editable: false,
    },
    value: {
        name: 'value',
        label: 'Waarde',
        type: fieldTypes.PRICE,
        options: null,
        required: false,
        editable: false,
    },
    body_type: {
        name: 'body_type',
        label: 'Type carrosserie',
        type: fieldTypes.STRING,
        options: null,
        required: false,
        editable: false,
    },
    model: {
        name: 'model',
        label: 'Model',
        type: fieldTypes.STRING,
        options: null,
        required: false,
        editable: false,
    },
    color: {
        name: 'color',
        label: 'Kleur',
        type: fieldTypes.STRING,
        options: null,
        required: false,
        editable: false,
    },
    build: {
        name: 'build',
        label: 'Bouwdatum',
        type: fieldTypes.DATE,
        options: null,
        required: false,
        editable: false,
    },
    purchase_value: {
        name: 'purchase_value',
        label: 'Aankoopwaarde',
        type: fieldTypes.PRICE,
        options: null,
        required: false,
    },
    warranty_period: {
        name: 'warranty_period',
        label: 'Garantie tot',
        type: fieldTypes.DATE,
        options: null,
        required: false,
    },
    licence_plate: {
        name: 'licence_plate',
        label: 'Kenteken',
        type: fieldTypes.STRING,
        options: null,
        required: true,
    },
    travel_departure_date: {
        name: 'travel_departure_date',
        label: 'Vertrekdatum',
        type: fieldTypes.DATE,
        options: null,
        required: false,
    },
    travel_return_date: {
        name: 'travel_return_date',
        label: 'Retourdatum',
        type: fieldTypes.DATE,
        options: null,
        required: false,
    },
    travel_transport: {
        name: 'travel_transport',
        label: 'Vervoersmiddel',
        type: fieldTypes.STRING,
        options: ['Auto', 'Vliegtuig', 'Trein', 'Bus', 'Fiets', 'Overig'],
        required: true,
    },
    travel_country_destination: {
        name: 'travel_country_destination',
        label: 'Land van bestemming',
        type: fieldTypes.STRING,
        options: ["Afghanistan", "Akrotiri en Dhekelia", "Åland", "Albanië", "Algerije", "Amerikaanse Maagdeneilanden",
            "Amerikaans-Samoa", "Andorra", "Angola", "Anguilla", "Antigua en Barbuda", "Argentinië", "Armenië", "Aruba",
            "Ashmore- en Cartiereilanden", "Australië", "Azerbeidzjan", "Azoren", "Bahama's", "Bahrein", "Baker",
            "Bangladesh", "Barbados", "België", "Belize", "Benin", "Bermuda", "Bhutan", "Bolivia", "Bonaire",
            "Bosnië en Herzegovina", "Botswana", "Bouvet", "Brazilië", "Brits Indische Oceaanterritorium",
            "Britse Maagdeneilanden", "Brunei", "Bulgarije", "Burkina Faso", "Burundi", "Cambodja", "Canada",
            "Canarische Eilanden", "Centraal-Afrikaanse Republiek", "Ceuta", "Chili", "China", "Christmaseiland",
            "Clipperton", "Cocoseilanden", "Colombia", "Comoren", "Congo-Brazzaville", "Congo-Kinshasa", "Cookeilanden",
            "Costa Rica", "Crozeteilanden", "Cuba", "Curaçao", "Cyprus", "Denemarken", "Djibouti", "Dominica",
            "Dominicaanse Republiek", "Duitsland", "Ecuador", "Egypte", "El Salvador", "Equatoriaal-Guinea", "Eritrea",
            "Estland", "Ethiopië", "Faeröer", "Falklandeilanden", "Fiji", "Filipijnen", "Finland", "Frankrijk",
            "Frans-Guyana", "Frans-Polynesië", "Gabon", "Gambia", "Georgië", "Ghana", "Gibraltar", "Grenada",
            "Griekenland", "Groenland", "Guadeloupe", "Guam", "Guatemala", "Guernsey", "Guinee", "Guinee-Bissau",
            "Guyana", "Haïti", "Hawaï", "Heard en McDonaldeilanden", "Honduras", "Hongarije", "Hongkong", "Howland",
            "Ierland", "IJsland", "India", "Indonesië", "Irak", "Iran", "Israël", "Italië", "Ivoorkust", "Jamaica",
            "Japan", "Jarvis", "Jemen", "Jersey", "Johnston", "Jordanië", "Kaaimaneilanden", "Kaapverdië", "Kameroen",
            "Kazachstan", "Kenia", "Kerguelen", "Kingman", "Kirgizië", "Kiribati", "Koeweit", "Kosovo", "Kroatië",
            "Laos", "Lesotho", "Letland", "Libanon", "Liberia", "Libië", "Liechtenstein", "Litouwen", "Luxemburg",
            "Macau", "Macedonië", "Madagaskar", "Madeira", "Malawi", "Maldiven", "Maleisië", "Mali", "Malta", "Man",
            "Marokko", "Marshalleilanden", "Martinique", "Mauritanië", "Mauritius", "Mayotte", "Melilla", "Mexico",
            "Micronesia", "Midway", "Moldavië", "Monaco", "Mongolië", "Montenegro", "Montserrat", "Mozambique",
            "Myanmar", "Namibië", "Nauru", "Navassa", "Nederland", "Nepal", "Nicaragua", "Nieuw-Caledonië",
            "Nieuw-Zeeland", "Niger", "Nigeria", "Niue", "Noordelijke Marianen", "Noord-Korea", "Noorwegen", "Norfolk",
            "Oeganda", "Oekraïne", "Oezbekistan", "Oman", "Oostenrijk", "Oost-Timor", "Pakistan", "Palau", "Palestina",
            "Palmyra", "Panama", "Panama", "Papoea-Nieuw-Guinea", "Paraguay", "Peru", "Pitcairneilanden", "Polen",
            "Portugal", "Puerto Rico", "Qatar", "Réunion", "Roemenië", "Rusland", "Rwanda", "Saba",
            "Saint Kitts en Nevis", "Saint Lucia", "Saint Vincent en de Grenadines", "Saint-Barthélemy",
            "Saint-Paul en Amsterdam", "Saint-Pierre en Miquelon", "Salomonseilanden", "Samoa", "San Marino",
            "Sao Tomé en Principe", "Saoedi-Arabië", "Senegal", "Servië", "Seychellen", "Sierra Leone", "Singapore",
            "Sint Maarten", "Sint-Eustatius", "Sint-Helena, Ascension en Tristan da Cunha", "Sint-Maarten", "Slovenië",
            "Slowakije", "Soedan", "Somalië", "Spanje", "Spitsbergen", "Sri Lanka", "Suriname", "Swaziland", "Syrië",
            "Tadzjikistan", "Taiwan", "Tanzania", "Thailand", "Togo", "Tokelau", "Tonga", "Trinidad en Tobago",
            "Tsjaad", "Tsjechië", "Tunesië", "Turkije", "Turkmenistan", "Turks- en Caicoseilanden", "Tuvalu", "Uruguay",
            "Vanuatu", "Vaticaanstad", "Venezuela", "Verenigd Koninkrijk", "Verenigde Arabische Emiraten",
            "Verenigde Staten", "Verspreide Eilanden in de Indische Oceaan", "Vietnam", "Wake", "Wallis en Futuna",
            "Westelijke Sahara", "Wit-Rusland", "Zambia", "Zimbabwe", "Zuid-Afrika",
            "Zuid-Georgia en de Zuidelijke Sandwicheilanden", "Zuid-Korea", "Zuid-Soedan", "Zweden", "Zwitserland"],
        required: true,
    },
    remark_description: {
        name: 'remark_description',
        label: 'Omschrijving',
        type: fieldTypes.STRING,
        options: null,
        required: false,
    },
    practice: {
        name: 'practice',
        label: 'Praktijk',
        type: fieldTypes.STRING,
        options: null,
        required: false,
    },
    tuition: {
        name: 'tuition',
        label: 'Collegegeld',
        type: fieldTypes.PRICE,
        options: null,
        required: false,
    },
    street: {
        name: 'street',
        label: 'Straat',
        type: fieldTypes.STRING,
        options: null,
        required: false,
    },
    city: {
        name: 'city',
        label: 'Woonplaats',
        type: fieldTypes.STRING,
        options: null,
        required: false,
    },
    name: {
        name: 'name',
        label: 'Naam',
        type: fieldTypes.STRING,
        options: null,
        required: true,
    },
};

const getField = name => {
    const field = fields[name];
    return isNull(field) ? {} : field;
};

export const getCustomizedField = (name, customization) => _.merge({}, getField(name), customization);