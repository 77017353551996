import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../../redux/actions';

import {scrollToTop, deviceIsMobile} from 'glob-common-js/lib/utils';

import StateComponent from "../misc/stateComponent";
import OnboardingView from "./onboardingView";
import {
    addCustomEventListener,
    dispatchCustomEvent,
    getCustomEventParam,
    removeCustomEventListener
} from "../../misc/eventDispatcher";
import {sendGaEvent} from "../../common/js/ga";
import {getUserFlex, responseIsSuccess, updateDaisyUser} from "../../misc/requestSender";
import {getOnboardingManager} from "./manager/onboardingManagerHelper";
import {navigate} from "../../misc/utils";
import {storeOnboardingSteps} from "./manager/storeManager";
import {financialOnboardingSteps} from "./manager/financialManager";
import {agendaOnboardingSteps} from "./manager/agendaManager";
import {saveOnboardingSteps} from "./manager/saveManager";

const GA_CATEGORY = 'Onboarding keuze';

class OnboardingController extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
    }

    onSubmit = (categoryName, sendEvent = true) => {
        if (sendEvent)
            sendGaEvent(GA_CATEGORY, 'Klik', 'Start flow');
        getUserFlex({
            callback: response => {
                if (responseIsSuccess(response)) {
                    const {flex} = response.data;
                    if (!pathIsNotNull(flex, 'startFlow'))
                        updateDaisyUser({
                            data: {
                                flex: {startFlow: categoryName},
                            },
                            callback: () => {
                                this.onClose(categoryName);
                            }
                        });
                    else this.onClose(categoryName);
                } else this.onClose(categoryName);
            }
        });
    };

    onSkip = () => {
        sendGaEvent(GA_CATEGORY, 'Klik', 'Sla start flow over');
        this.onSubmit('dashboard', false);
    };

    onClose = (category = null) => {
        this.changeState({open: false});
        if (isNotNull(category) && category !== 'dashboard')
            this.startOnboardingFlow(category);
    };

    onActivate = () => {
        if (!deviceIsMobile())
            scrollToTop();
        this.changeState({open: true});
    };

    startOnboardingFlow = category => {
        switch (category) {
            case 'bewaren':
                getOnboardingManager('store').activate();
                if (window.location.pathname.includes('/dossier-overzicht')) {
                    dispatchCustomEvent('onboardingReloadDossiers');
                    dispatchCustomEvent('storeOnboardingFlow',
                        {type: 'activate', step: storeOnboardingSteps.INTRO_STEP});
                } else navigate('/dossier-overzicht');
                break;
            case 'financieel':
                getOnboardingManager('financial').activate();
                if (window.location.pathname.includes('/financieel-overzicht'))
                    dispatchCustomEvent('financialOnboardingFlow',
                        {type: 'activate', step: financialOnboardingSteps.INTRO_STEP});
                else navigate('/financieel-overzicht');
                break;
            case 'agenda':
                getOnboardingManager('agenda').activate();
                if (window.location.pathname.includes('/dashboard'))
                    dispatchCustomEvent('agendaOnboardingFlow', {
                        type: 'activate',
                        step: agendaOnboardingSteps.INTRO_STEP
                    });
                else navigate('/dashboard');
                break;
            case 'besparen':
                navigate('/analyses');
                // getOnboardingManager('save').activate();
                // if (window.location.pathname.includes('/analyses')) {
                //     dispatchCustomEvent('saveOnboardingFlow',
                //         {type: 'activate', step: saveOnboardingSteps.INTRO_STEP});
                // } else navigate('/analyses');
                break;
        }
    };

    getFirstName = () => {
        const {userState} = this.props;
        if (pathIsNotNull(userState, 'user.firstname'))
            return userState.user.firstname;
        return '';
    };

    componentDidMount = () => {
        this.mount();
        addCustomEventListener('startOnboarding', this.onActivate);
    };

    componentWillUnmount = () => {
        this.unMount();
        removeCustomEventListener('startOnboarding', this.onActivate);
    };

    render = () => {
        const {open} = this.state;
        return <OnboardingView onSubmit={this.onSubmit} firstName={this.getFirstName()} onSkip={this.onSkip}
                               open={open}/>
    };
}

const mapStateToProps = state => ({
    userState: state.user,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingController);