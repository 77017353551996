import 'glob-common-js/lib/style/normalize.css';
import './js/startup.js';
import './style/custom.scss';
import 'BdhCommon/style/common-icons.scss';
import 'BdhCommon/style/common-custom.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {getSetting} from 'glob-common-js/lib/utils';

import App from './js/components/app';
import {store} from './js/startup';

const container = 'content';

// Create the Redux store with all components, export to use in Utils
const isProd = getSetting('mode') === 'prod';
const isDev = getSetting('mode') === 'dev';
if (isDev) {
    require('./js/validateMappings').testMappings();
}

if (isProd) {
    store.getState();
} else {
    console.debug(store.getState());
}

if (isNull(document.getElementById(container))) {
    let element = document.createElement("div");
    element.id = container;
    document.body.appendChild(element);
}

const basename = '/' + getSetting('appBaseUrl');
ReactDOM.render((
    <Provider store={store}>
        <App/>
    </Provider>
), document.getElementById(container));
