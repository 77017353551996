import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import './style/requestTender.scss';

import {isNotNull} from 'glob-common-js/lib/utils';
import {sendGaEvent} from "BdhCommon/js/ga";

import EditTenderData from './editTenderData';

export class RequestTenderView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            display: 'show',
            tenderInfo: this.props.tenderInfo,
        }
    }

    changeState = (stateChange) => {
        if (this._isMounted) {
            this.setState(stateChange);
        }
    };

    createFullName = () => {
        let user = this.props.tenderInfo.user;
        return user.firstname + (isNotNull(user.infix) ? ' ' + user.infix : '') + ' ' + user.lastname;
    };

    createDateOfBirth = () => {
        let date = new Date(this.props.tenderInfo.user.dob);
        return date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
    };

    onEditClick = () => {
        sendGaEvent(this.props.dossier.type.label + ' overzicht', 'Offerte button click', 'Wijzigen');
        this.changeState({display: 'edit'});
    };

    onEditSubmit = model => {
        let tenderInfo = _.merge({}, this.state.tenderInfo);
        let metadata = tenderInfo.metadata;
        for (let i = 0; i < metadata.length; i++) {
            metadata[i].value = model[metadata[i].name];
        }
        this.changeState({
            display: 'show',
            tenderInfo,
        });
    };

    createDetailField = (metadata, key) => {
        if (isNotNull(metadata.value)) {
            return (
                <div key={key} className="fieldGroup">
                    <div className='field'>
                        <label className="titleLabel">{metadata.label}</label>
                        <label className="valueLabel">{metadata.value}</label>
                    </div>
                </div>
            );
        }
    };

    onSubmit = () => {
        this.props.onSubmit(this.state.tenderInfo);
    };

    createDetailsContainer = () => {
        let metadata = this.state.tenderInfo.metadata;
        return (
            <div>
                <div className='fields'>
                    {metadata.map(this.createDetailField)}
                </div>
                <div className="buttonContainerTender">
                    <button className='simpleButton' onClick={this.onEditClick}>Wijzigen</button>
                    <button className='simpleButton' onClick={this.onSubmit}
                            disabled={this.props.submitting}>Versturen
                    </button>
                </div>
            </div>
        );
    };

    componentDidMount = () => {
        this._isMounted = true;
    };

    componentWillUnmount = () => {
        this._isMounted = false;
    };

    render = () => {
        let user = this.props.tenderInfo.user;
        return (
            <div className='requestTender'>
                <div className='header'>
                    <div className="headerTitle">
                        <h2>{this.props.tenderInfo.title}</h2>
                    </div>
                </div>
                <div className='message'>
                    <p>{this.props.tenderInfo.description}</p>
                </div>
                <div className="body">
                    <div className="userDetails">
                        <div className='fieldGroup'>
                            <div className="field">
                                <label className="titleLabel">Naam</label>
                                <label className="valueLabel">{this.createFullName()}</label>
                            </div>
                            <div className="field">
                                <label className="titleLabel">Geboortedatum</label>
                                <label className="valueLabel">{this.createDateOfBirth()}</label>
                            </div>
                        </div>
                        <div className="fieldGroup">
                            <div className="field">
                                <label className='titleLabel'>Adres</label>
                                <label className="valueLabel">{user.address.street}</label>
                            </div>
                            <div className="field">
                                <label className='titleLabel'>Huisnummer</label>
                                <label
                                    className="valueLabel">{user.address.house_number + user.address.house_number_suffix}
                                </label>
                            </div>
                        </div>
                        <div className="fieldGroup">
                            <div className="field">
                                <label className='titleLabel'>Postcode</label>
                                <label className="valueLabel">{user.address.zipcode}</label>
                            </div>
                            <div className="field">
                                <label className='titleLabel'>Stad</label>
                                <label
                                    className="valueLabel">{user.address.city}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="dossierDetailsTender">
                        {this.state.display === 'show' ? this.createDetailsContainer() :
                            <EditTenderData onSubmit={this.onEditSubmit} fieldData={this.state.tenderInfo.metadata}/>}
                    </div>
                </div>
            </div>
        );
    };
}

RequestTenderView.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    tenderInfo: PropTypes.object.isRequired,
    dossier: PropTypes.object.isRequired,
};

export default RequestTenderView;