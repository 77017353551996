import React from 'react';
import {connect} from 'react-redux';

import {toMoment} from 'glob-common-js/lib/utils';
import AgendaPopup from "./agendaPopup";
import {createTimeItem, deleteTimeItem, updateTimeItem} from "../../../misc/requestSender";
import {addCustomEventListener, dispatchCustomEvent, removeCustomEventListener} from "../../../misc/eventDispatcher";
import {sendGaEvent} from "../../../common/js/ga";
import StateComponent from "../../misc/stateComponent";
import {materialTypes} from "../../material/materialTypes";
import MaterialFactory from "../../material/materialFactory";
import {NOW} from "../../misc/constants";
import {getOnboardingManager} from "../../onboarding/manager/onboardingManagerHelper";

const GA_CATEGORY = 'Agenda popup';

export class AgendaPopupController extends StateComponent {
    constructor(props) {
        super(props);
        this.state = this.createInitialState();
    }

    createInitialState = () => {
        const startDate = NOW();
        let endDate = toMoment(startDate);
        endDate.add(1, 'hours');
        return {
            values: {
                id: null,
                name: '',
                type: 3,
                description: '',
                startDate: startDate.format('YYYY-MM-DDTHH:mm:ss'),
                endDate: endDate.format('YYYY-MM-DDTHH:mm:ss'),
                frequency: 0,
                location: '',
                reminder: {
                    toggled: false,
                    sms: {
                        toggled: false,
                        value: this.props.userState.user.phone_mobile || '',
                        time: '3600',
                    },
                    email: {
                        toggled: false,
                        value: this.props.userState.user.email || '',
                        time: '3600',
                    }
                },
            },
            errors: {
                nameError: null,
            },
            isDirty: false,
            active: false,
            type: 'new',
            loading: false,
            dialogText: '',
        }
    };

    /**
     * @param item Item to show detail/edit (optional)
     */
    activate = (item) => {
        if (isNotNull(item))
            this.activateWithItem(item);
        else {
            let values = this.state.values, reminder = values.reminder;
            reminder.sms.value = this.props.userState.user.phone_mobile || '';
            reminder.email.value = this.props.userState.user.email || '';
            this.changeState({active: true, values});
        }
    };

    activateWithItem = item => {
        this.changeState({
            values: {
                id: item.id,
                name: this.getItemValue(item, 'name'),
                type: this.getItemValue(item, 'type'),
                description: this.getItemValue(item, 'description'),
                startDate: this.getStartDateItem(item),
                endDate: this.getEndDateItem(item),
                frequency: this.getItemValue(item, 'frequency'),
                location: this.getItemValue(item, 'location'),
                reminder: this.getRemindersItem(item),
            },
            type: item.isNew ? 'new' : 'detail',
            active: true,
        })
    };

    onChangeValue = (name, value) => {
        const values = Object.assign({}, this.state.values, {
            [name]: value,
        });
        this.changeState({
            values: this.adjustEndDate(values),
            errors: {name: null},
            isDirty: true,
        });
    };

    onChangeReminderValue = (name, value) => {
        const reminder = Object.assign({}, this.state.values.reminder, {
            [name]: value,
        });
        this.changeState({
            values: Object.assign({}, this.state.values, {reminder}),
            error: {name: null},
            isDirty: true,
        });
    };

    onChangeReminderDetail = (type, name, value) => {
        const detail = Object.assign({}, this.state.values.reminder[type], {
            [name]: value,
        });
        this.changeState({
            values: Object.assign({}, this.state.values, {
                reminder: Object.assign({}, this.state.values.reminder, {
                    [type]: detail,
                })
            }),
            isDirty: true,
        });
    };

    onDelete = () => {
        const itemName = this.state.values.name;
        this.changeState({
            dialogText: 'Weet je zeker dat je \'' + itemName + '\' wilt verwijderen?',
        });
    };

    deleteTimeItem = () => {
        const id = this.state.values.id;
        if (isNotNull(id)) {
            deleteTimeItem({
                id,
                callback: () => {
                    sendGaEvent(GA_CATEGORY, 'Agenda verwijderen', 'Verwijderd');
                    dispatchCustomEvent('updateTimeItems');
                    this.onCancel(false);
                },
            })
        } else
            this.onCancel(false);
    };

    closeDialog = () => {
        this.changeState({dialogText: ''});
    };

    onCancel = (sendEvent = true) => {
        if (sendEvent)
            sendGaEvent(GA_CATEGORY, 'Agenda sluiten', 'Sluiten');
        this.changeState({...this.createInitialState()});
    };

    onSubmit = () => {
        const values = Object.assign({}, this.state.values);
        if (this.formIsValid(values)) {
            const type = this.state.type;
            if (type === 'new') {
                sendGaEvent(GA_CATEGORY, 'Klik opslaan', 'Nieuw');
                this.onSave(values);
            } else if (type === 'detail' && isNotNull(values.id)) {
                sendGaEvent(GA_CATEGORY, 'Klik opslaan', 'Wijzigen');
                this.onUpdate(values);
            }
        }
    };

    formIsValid = values => {
        if (isNull(values.name)) {
            this.changeState({errors: {...this.state.errors, name: 'Vul een naam in'}});
            return false;
        }
        return true;
    };

    onSave = values => {
        const data = this.createRequestData(values);
        createTimeItem({
            data,
            callback: () => {
                dispatchCustomEvent('updateTimeItems');
                this.onCancel(false);
                const manager = getOnboardingManager('agenda');
                if (manager.isActive()) {
                    manager.sendEvent('Klik', 'Agenda item opslaan');
                    manager.setNextStep();
                }
            },
        });
    };

    onUpdate = values => {
        const data = this.createRequestData(values);
        updateTimeItem({
            id: values.id,
            data,
            callback: () => {
                dispatchCustomEvent('updateTimeItems');
                this.onCancel(false);
            },
        });
    };

    onResetErrors = () => {
        this.changeState({
            errors: {
                name: null,
            }
        });
    };

    createRequestData = values => ({
        description: values.description,
        end_date_item: toMoment(values.endDate).format('YYYY-MM-DDTHH:mm'),
        flex: {
            communication: this.createCommunication(values.reminder),
            name: this.createUserName(),
        },
        frequency: values.frequency,
        location: values.location,
        name: values.name,
        start_date_item: toMoment(values.startDate).format('YYYY-MM-DDTHH:mm'),
        type: values.type,
    });

    createCommunication = reminder => {
        const {email, sms} = reminder;
        const communication = [];
        if (sms.toggled) {
            communication.push({
                remind_on: sms.value,
                enabled: reminder.toggled,
                remind_date: parseInt(sms.time) * -1,
                type: 'sms',
            });
        }
        if (email.toggled) {
            communication.push({
                remind_on: email.value,
                enabled: reminder.toggled,
                remind_date: parseInt(email.time) * -1,
                type: 'email',
            });
        }
        return communication;
    };

    createUserName = () => {
        const user = this.props.userState.user;
        if (isNotNull(user)) {
            const firstName = user.firstname, infix = user.infix, lastName = user.lastname;
            return firstName + (isNotNull(infix) ? ' ' + infix : '') + (isNotNull(lastName) ? ' ' + lastName : '');
        }
        return '';
    };

    getItemValue = (item, valueName) => {
        if (areNotNull(item, valueName)) {
            if (item.hasOwnProperty(valueName) && isNotNull(item[valueName])) return item[valueName];
            const initialState = this.createInitialState().values;
            return initialState[valueName];
        }
        return '';
    };

    getStartDateItem = item => {
        if (pathIsNotNull(item, 'start_date_item')) {
            let startDate = item.start_date_item;
            let momentInstance = toMoment(startDate);
            if (momentInstance.isValid()) return startDate;
        }
        return this.createInitialState().values.startDate;
    };

    getEndDateItem = item => {
        if (pathIsNotNull(item, 'end_date_item')) {
            let endDate = item.end_date_item;
            let momentInstance = toMoment(endDate);
            if (momentInstance.isValid()) return momentInstance;
        }
        return this.createInitialState().values['endDate'];
    };

    getRemindersItem = item => {
        const user = this.props.userState.user;
        const reminders = {
            toggled: false,
            sms: {
                value: user.phone_mobile || '',
                time: '3600',
                toggled: false,
            },
            email: {
                value: user.email || '',
                time: '3600',
                toggled: false,
            },
        };
        if (pathIsNotNull(item, 'flex.communication')) {
            const communication = item.flex.communication;
            for (let i = 0; i < communication.length; i++) {
                let reminder = communication[i];
                if (reminder.type === 'sms') {
                    reminders.sms = {
                        value: reminder.remind_on || user.phone_mobile || '',
                        time: Math.abs(reminder.remind_date).toString(),
                        toggled: reminder.enabled,
                    }
                } else if (reminder.type === 'email') {
                    reminders.email = {
                        value: reminder.remind_on || user.email || '',
                        time: Math.abs(reminder.remind_date).toString(),
                        toggled: reminder.enabled,
                    }
                }
            }
        }
        reminders.toggled = reminders.sms.toggled || reminders.email.toggled;
        return reminders;
    };

    adjustEndDate = values => {
        const startDate = toMoment(values.startDate);
        let endDate = toMoment(values.endDate);
        if (endDate.isBefore(startDate)) {
            endDate = toMoment(startDate);
            values.endDate = endDate;
        }
        return values;
    };

    renderDialogActions = () => ([
        {label: 'Annuleren', onClick: this.closeDialog},
        {label: 'Verwijderen', onClick: this.deleteTimeItem},
    ]);

    componentDidMount = () => {
        this.mount();
        addCustomEventListener('openAgendaPopup', this.activate);
    };

    componentWillUnmount = () => {
        this.unMount();
        removeCustomEventListener('openAgendaPopup', this.activate);
    };

    render = () => {
        return (
            <>
                <MaterialFactory componentType={materialTypes.DIALOG} title='Bevestig' onClose={this.closeDialog}
                                 open={isNotNull(this.state.dialogText)} actions={this.renderDialogActions()}
                                 text={this.state.dialogText}/>
                <AgendaPopup onChangeValue={this.onChangeValue} onChangeReminderValue={this.onChangeReminderValue}
                             type={this.state.type} isDirty={this.state.isDirty} values={this.state.values}
                             errors={this.state.errors} loading={this.state.loading} onSubmit={this.onSubmit}
                             onResetErrors={this.onResetErrors} onCancel={this.onCancel} onDelete={this.onDelete}
                             onChangeReminderDetail={this.onChangeReminderDetail} active={this.state.active}/>
            </>
        );
    };
}

const mapStateToProps = state => ({
    userState: state.user,
});

export default connect(mapStateToProps)(AgendaPopupController);