import React from 'react';

import {warn} from 'glob-common-js/lib/utils';

/**
 * Class used to identify and control the behaviour of React components which hold an internal state.
 * This class makes sure state updates are only fired when the component is mounted.
 *
 * When implementing this class, the inheriting class must implement the componentDidMount and componentWillUnmount
 * functions of React, and using the respective mount and unMount functions off this class.
 */
export default class StateComponent extends React.Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
    }

    changeState = (stateChange, callback) => {
        if (this._isMounted)
            this.setState(stateChange, callback);
        else
            warn('Component is not yet mounted, this probably means the inheriting component does not call this.mount()');
    };

    mount = () => {
        this._isMounted = true;
    };

    unMount = () => {
        this._isMounted = false;
    };
}