import './style/completion.scss';

import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {setReduxUser, setPlatformVersion} from "../../../../misc/utils";
import {addTask, sendWelcome} from "../../../../misc/requestSender";
import {timeItemTypes} from "../../../../common/data/constants/timeItemConstants";
import {steps} from "../../registrationConstants";
import CompletionFree from "./completionFree";
import CompletionDefault from "./completionDefault";
import {sendGaEvent} from "../../../../common/js/ga";
import {NOW} from "../../../misc/constants";

const GA_CATEGORY = 'Registreren (Aan de slag)';

export class CompletionController extends React.Component {
    constructor(props) {
        super(props);
    }

    onComplete = (destination) => {
        this.addTips(destination);
    };

    onClickFaq = () => {
        sendGaEvent(GA_CATEGORY, 'Klik', 'faq');
        window.open('https://bydehand.com/faq');
    };

    addTips = (destination) => {
        //console.log('completionController - addTips')
        const {user, onDeactivate} = this.props;
        this.sendWelcomeMail();
        setReduxUser(user);
        setPlatformVersion({argument: destination,
          callback: response => {
            onDeactivate(destination);
          }
        });
        //onDeactivate(destination);
    };

    sendWelcomeMail = () => {
        sendWelcome({firstname: this.props.user.firstname});
        // addTask({
        //     data: {
        //         name: 'welcome_mail',
        //         body: {
        //             template_name: 'welkomstmail-nieuw',
        //             subject: 'Welkom bij ByDeHand!',
        //             dry_run: false,
        //             merge_vars: [
        //                 {name: 'FNAME', 'content': this.props.user.firstname}
        //             ],
        //             recipients: [this.props.user.email],
        //         },
        //         activationDate: NOW().format('YYYY-MM-DDTHH:mm'),
        //         function: 'send_mail',
        //         frequencyType: 'relative',
        //         frequency: null,
        //     }
        // });
    };

    createTipData = name => ({
        name: name,
        type: timeItemTypes.tip.value,
        start_date_item: NOW().format('YYYY-MM-DDTHH:mm'),
        end_date_item: NOW().format('YYYY-MM-DDTHH:mm'),
        flex: {
            isRead: false,
            status: 'active',
            communication: [],
        }
    });

    render = () => {
        switch (this.props.subStep) {
            case steps.COMPLETION.FREE:
                return <CompletionFree onComplete={this.onComplete} onClickFaq={this.onClickFaq}
                                       flex={this.props.flex} firstName={this.props.user.firstname}/>;
            case steps.COMPLETION.DEFAULT:
            case steps.COMPLETION.TRIAL:
                return <CompletionDefault onComplete={this.onComplete} firstName={this.props.user.firstname}
                                          onClickFaq={this.onClickFaq} flex={this.props.flex}
                                          transactionInfo={this.props.transactionInfo} email={this.props.user.email}/>;
            default:
                return null;
        }
    };
}

CompletionController.propTypes = {
    onDeactivate: PropTypes.func.isRequired,
    subStep: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    flex: PropTypes.object,
    transactionInfo: PropTypes.object,
};

const mapStateToProps = state => ({
    subscriptionState: state.user.subscription,
});

export default connect(mapStateToProps)(CompletionController);
