import './style/accountDetails.scss';

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import SubscriptionDetails from "./subscriptionDetails";
import MaterialFactory from "../material/materialFactory";
import {materialTypes} from "../material/materialTypes";
import {dispatchCustomEvent} from "../../misc/eventDispatcher";
import {CANCEL_ACCOUNT, CANCEL_SUBSCRIPTION} from "../cancellation/cancellationController";
import StateComponent from "../misc/stateComponent";

export default class AccountDetails extends React.Component {

    openDeleteDialog = () => {
        dispatchCustomEvent('showCancellation', CANCEL_ACCOUNT);
    };

    getFamilyType = type => {
        return {
            'partner': 'Partner',
            'child': 'Kind',
            'parent': 'Ouder',
            'sibling': 'Broer/Zus',
            'grandchild': 'Kleinkind'
        }[type];
    };

    openCancelSubscription = () => {
        dispatchCustomEvent('showCancellation', CANCEL_SUBSCRIPTION);
    };

    renderName = (account) => {
        let name = account.firstname, infix = account.infix, lastname = account.lastname;
        return name + (isNotNull(infix) ? ' ' + infix : '') + (isNotNull(lastname) ? ' ' + lastname : '');
    };

    renderIfExists = (name, label) => {
        let value = this.props.account[name];
        if (isNotNull(value))
            return this.renderDetail(label, value);
        return null;
    };

    renderStreetRow = () => {
        const {street, house_number} = this.props.account.address;
        if (isNotNull(street)) {
            let value = street + (isNotNull(house_number) ? ' ' + house_number : '');
            return this.renderDetail('Adres', value);
        }
        return null;
    };

    renderZipCodeRow = () => {
        const {zipcode, city} = this.props.account.address;
        if (isNotNull(zipcode) || isNotNull(city)) {
            let value = (isNotNull(zipcode) ? zipcode + ' ' : '') + (isNotNull(city) ? city : '');
            return this.renderDetail(null, value);
        }
        return null;
    };

    renderDob = () => {
        let {dob} = this.props.account;
        if (isNotNull(dob)) {
            if (moment(dob, 'YYYY-MM-DDTHH:mm:ss').isValid()) {
                let dobString = moment(dob, 'YYYY-MM-DDTHH:mm:ss').format('D MMMM YYYY');
                return this.renderDetail('Geboortedatum', dobString);
            }
        }
        return null;
    };

    renderUsername = () => {
        let username = this.props.account.username + '@bydehand.com';
        return this.renderDetail('ByDeHand adres', username);
    };

    renderDetail = (label, value) => {
        return (
            <div className='accountDetail'>
                <label className='detailLabel'>{label}</label>
                <label className='detailValue'>{value}</label>
            </div>
        );
    };

    renderFamily = () => {
        const {family, onClickFamily} = this.props;
        if (isNotNull(family)) {
            return family.map((member, key) => (
                <div key={key} className='familyMember' onClick={() => {
                    onClickFamily(member)
                }}>
                    <span className='common-icon-profile profileIcon'/>
                    <label className='memberName'>{this.renderName(member)}</label>
                    <label className='memberRole'>{this.getFamilyType(member.family_type)}</label>
                </div>
            ));
        }
    };

    renderCancelButton = () => {
        const {hasSubscription} = this.props;
        if (hasSubscription) return (
            <MaterialFactory componentType={materialTypes.FLAT_BUTTON} onClick={this.openCancelSubscription}
                             className='cancelButton'>
                Opzeggen</MaterialFactory>
        );
        return null;
    };

    render = () => {
        const {account, onEdit, onAddFamily} = this.props;
        return (
            <>
                <div className='accountDetails'>
                    <div className='accountActionsContainer'>
                        <MaterialFactory componentType={materialTypes.RAISED_BUTTON}
                                         onClick={onEdit}>Aanpassen</MaterialFactory>
                        <MaterialFactory componentType={materialTypes.FLAT_BUTTON} color='primary'
                                         onClick={this.openDeleteDialog}>Verwijderen</MaterialFactory>
                    </div>
                    <div className='detailsContainer'>
                        <div className='detailsBlock'>
                            <div className='iconBlock'>
                                <label className='blockTitle'>{this.renderName(account)}</label>
                                {isNotNull(account.profile_image) ? <span
                                    style={{backgroundImage: `url(${account.profile_image})`}}
                                    className='profileImage'/> : <span className='common-icon-profile profileIcon'/>}
                            </div>
                            {this.renderIfExists('bsn', 'BSN')}
                            {this.renderDob()}
                            {this.renderUsername()}
                        </div>
                        <div className='detailsBlock'>
                            <label className='blockTitle'>Mijn contactgegevens</label>
                            {this.renderStreetRow()}
                            {this.renderZipCodeRow()}
                            {this.renderIfExists('phone_mobile', 'Mobiel nummer')}
                            {this.renderIfExists('phone_number', 'Telefoonnummer')}
                            {this.renderIfExists('email', 'E-mailadres')}
                        </div>
                    </div>
                    <div className='detailsContainer'>
                        <div className='detailsBlock fw'>
                            <label className='blockTitle'>Abonnementsgegevens</label>
                            <div className='detailsContainer'>
                                <SubscriptionDetails onStopSubscription={this.openCancelSubscription}/>
                                {this.renderCancelButton()}
                            </div>
                        </div>
                    </div>
                    <div className='familyContainer'>
                        <div className='familyHeader'>
                            <label className='familyTitle'>Familieleden</label>
                            <MaterialFactory componentType={materialTypes.RAISED_BUTTON}
                                             onClick={onAddFamily}>Familielid toevoegen</MaterialFactory>
                        </div>
                        <div className='familyBody'>
                            {this.renderFamily()}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

AccountDetails.propTypes = {
    onClickFamily: PropTypes.func.isRequired,
    onAddFamily: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onSetTwoStep: PropTypes.func.isRequired,
    onClickAccountAccess: PropTypes.func.isRequired,
    hasSubscription: PropTypes.bool.isRequired,
    deleteAllowed: PropTypes.bool.isRequired,
    account: PropTypes.shape({
        firstname: PropTypes.string.isRequired,
        infix: PropTypes.string,
        lastname: PropTypes.string,
        bsn: PropTypes.string,
        email: PropTypes.string.isRequired,
        dob: PropTypes.string,
        address: PropTypes.shape({
            street: PropTypes.string,
            house_number: PropTypes.string,
            house_number_suffix: PropTypes.string,
            zipcode: PropTypes.string,
            city: PropTypes.string,
        }),
        username: PropTypes.string.isRequired,
        phone_mobile: PropTypes.string,
        phone_number: PropTypes.string,
        profile_image: PropTypes.string,
    }),
    family: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        firstname: PropTypes.string.isRequired,
        infix: PropTypes.string,
        lastname: PropTypes.string,
        family_type: PropTypes.string,
    })).isRequired,
};