import checklistTypes from "../../../components/checklist/checklistTypes";

export default {
    name: 'Kind krijgen',
    icon: 'common-icon-pregnancy-care',
    route: '/kind-checklist',
    type: checklistTypes.GET_CHILD,
    GA_CATEGORY: 'Kind krijgen checklist',
    categories: {
        pre_pregnancy: {
            name: 'pre_pregnancy',
            label: 'Voor zwangerschap',
            icon: 'common-icon-pregnancy-care',
            boxes: [
                {
                    name: 'quit_smoking',
                    label: 'Stop met roken en alcohol',
                    documentType: null,
                    link: {
                        label: 'Hulp bij stoppen met roken',
                        url: 'https://www.rijksoverheid.nl/onderwerpen/roken/vraag-en-antwoord/kan-ik-hulp-krijgen-als-ik-wil-stoppen-met-roken',
                    },
                },
                {
                    name: 'folium',
                    label: 'Slik foliumzuur',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'diseases',
                    label: 'Informeer naar erfelijke/chronische ziekten',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'medicine',
                    label: 'Stem medicijngebruik af met huisarts',
                    documentType: null,
                    link: null,
                },
            ],
        },
        '1_12': {
            name: '1_12',
            label: '1 tot 12 weken',
            icon: 'common-icon-pregnancy-care',
            boxes: [
                {
                    name: 'pregnancy_test',
                    label: 'Zwangerschapstest',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'appointment',
                    label: 'Afspraak maken verloskundige',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'daycare',
                    label: 'Kinderopvang regelen',
                    documentType: null,
                    link: {
                        label: 'Check je recht op kinderopvangtoeslag',
                        url: 'https://www.belastingdienst.nl/wps/wcm/connect/bldcontentnl/belastingdienst/prive/toeslagen/kinderopvangtoeslag/',
                    },
                },
                {
                    name: 'maternity',
                    label: 'Kraamhulp regelen',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'work',
                    label: 'Werkgever inlichten',
                    documentType: null,
                    link: null,
                }
            ]
        },
        '12_23': {
            name: '12_23',
            label: '12 tot 23 weken',
            icon: 'common-icon-pregnancy-care',
            boxes: [
                {
                    name: 'health_insurance',
                    label: 'Meld je zwangerschap bij je zorgverzekeraar',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'proof_of_pregnancy',
                    label: 'Zwangerschapsverklaring aanvragen',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'packet',
                    label: 'Haal een kraampakket in huis',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'cloths',
                    label: 'Koop zwangerschapskleding',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'leave',
                    label: 'Zwangerschaps- en ouderschapsverlof regelen',
                    documentType: null,
                    link: {
                        label: 'Bereken verlof',
                        url: 'https://www.rijksoverheid.nl/onderwerpen/zwangerschapsverlof-en-bevallingsverlof/vraag-en-antwoord/hoe-lang-duurt-zwangerschapsverlof-en-bevallingsverlof',
                    },
                },

            ]
        },
        birth: {
            name: 'birth',
            label: '24 weken tot bevalling',
            icon: 'common-icon-pregnancy-care',
            boxes: [
                {
                    name: 'plan',
                    label: 'Geboorteplan schrijven',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'visit',
                    label: 'Nadenken over kraamvisite',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'cards',
                    label: 'Ontwerp geboortekaartjes',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'prepare',
                    label: 'Zet bed op klossen en zet vluchttas klaar',
                    documentType: null,
                    link: null,
                },
            ]
        },
        after_birth: {
            name: 'after_birth',
            label: 'Na geboorte',
            icon: 'common-icon-pregnancy-care',
            boxes: [
                {
                    name: 'details',
                    label: 'Geboortegegevens doorgeven aan kraambureau',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'birth_certificate',
                    label: 'Regel geboorteaangifte',
                    documentType: 'birth_certificate',
                    link: null,
                },
                {
                    name: 'baby_insurance',
                    label: 'Schrijf baby in bij zorgverzekering',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'allowance',
                    label: 'Regel kinderbijslag',
                    documentType: null,
                    link: {
                        label: 'Kinderbijslag aanvragen',
                        url: 'https://www.svb.nl/int/nl/kinderbijslag/aanvragen/hoe_kunt_u_kinderbijslag_aanvragen/',
                    }
                }
            ]
        }
    }
};