import './style/uploadTips.scss';

import React from 'react';
import PropTypes from 'prop-types';
import getDossierType from "../../../common/data/mappings/dossierTypeMapping";
import MaterialFactory from "../../material/materialFactory";
import {materialTypes} from "../../material/materialTypes";

export default class UploadTips extends React.Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
        this.tipCount = 0;
    }

    renderDocumentTypes = () => {
        return this.props.documentTypes.map((documentType, key) => {
                let count = ++this.tipCount;
                if (count > 10) this.tipCount = count = 1;
                return (
                    <div key={key} className='uploadTip'>
                        <span className='tipCount'>{count + '.'}</span>
                        <label className='typeLabel'>{documentType.label}</label>
                        <button className='uploadTipButton' onClick={() => {
                            this.props.onUploadDocument(documentType)
                        }}>
                            Document uploaden
                        </button>
                    </div>
                );
            }
        );
    };

    renderDossierTypes = () => {
        return this.props.dossierTypes.map((dossierType, key) => {
            let count = ++this.tipCount;
            if (count > 10) this.tipCount = count = 1;
            return (
                <div key={key} className='uploadTip'>
                    <span className='tipCount'>{count + '.'}</span>
                    <label className='typeLabel'>{dossierType.label}</label>
                    <button className='uploadTipButton' onClick={() => {
                        this.props.onUploadDossier(dossierType)
                    }}>
                        Dossier aanmaken
                    </button>
                </div>
            )
        });
    };

    renderDossierTypes_v2 = () => (
        <div className='dossierTypesContainer'>
            {this.renderDossierTypeBlock({
                name: 'house',
                label: 'Woning',
                description: 'Heb je een huis gekocht of huur je een huis? In beide gevallen komt er behoorlijk wat op ' +
                    'je pad. Zorg dus dat je een goed overzicht houdt op je administratie rondom je woning!',
                documents: ['Huurovereenkomst', 'Hypotheekakte', 'Meterstanden', 'Opstalverzekeringspolis',
                    'Koopovereenkomst'],
                financials: ['Aflossing hypotheek', 'Maandlasten hypotheek', 'Huur', 'GWL-kosten'],
            })}
            {this.renderDossierTypeBlock({
                name: 'personal_data',
                label: 'Persoonlijk',
                description: 'Documenten zoals je paspoort, identiteitsbewijs of diploma\'s zijn dermate belangrijk ' +
                    'dat je ze niet kwijt moet raken. Sla ze dus veilig op in je persoonlijke dossier!',
                documents: ['ID-kaart', 'Paspoort', 'Diploma', 'Rijbewijs', 'OV-chipkaart', 'Pinpas', 'Creditcard',
                    'Curriculum vitae', 'Cijferlijsten studie'],
                buttonText: 'Maak persoonlijk dossier aan',
            })}
            {this.renderDossierTypeBlock({
                name: 'job',
                label: 'Baan',
                description: 'Maak een dossier aan en voeg alle documenten toe die te maken hebben met je baan. ' +
                    'Zo houd je overzicht en vind je makkelijk je arbeidsovereenkomst of je jaaropgave terug!',
                documents: ['Arbeidsovereenkomst', 'Brandstof-pas', 'Declaraties', 'Loonstrook', 'Jaaropgave'],
                financials: ['Salaris', 'Bonussen', 'Vakantiegeld'],
            })}
        </div>
    );

    renderDossierTypeBlock = ({name, label, description, documents, financials, buttonText}) => {
        const {onUploadDossier} = this.props;
        const dossierType = getDossierType(name);
        const buttonLabel = buttonText || `Maak dossier '${label}' aan`;
        return (
            <div className='uploadDossierBlock'>
                <div className='dossierHeader'>
                    <label className='dossierLabel'>Dossier '{label}'</label>
                </div>
                <div className='dossierBody'>
                    <div className='descriptionContainer'>
                        <p className='dossierDescription'>{description}</p>
                    </div>
                    <div className='dossierDocumentsContainer'>
                        <label className='dossierItemsTitle'>Belangrijke documenten:</label>
                        {documents.map((doc, key) => <label key={key} className='itemLabel'>{doc}</label>)}
                    </div>
                    {isNotNull(financials) &&
                    <div className='dossierFinancialContainer'>
                        <label className='dossierItemsTitle'>Belangrijke kosten/uitgaven:</label>
                        {financials.map((financial, key) => <label key={key} className='itemLabel'>{financial}</label>)}
                    </div>}
                </div>
                <div className='dossierButtonContainer'>
                    <MaterialFactory componentType={materialTypes.RAISED_BUTTON} onClick={() => {
                        onUploadDossier(dossierType)
                    }}>{buttonLabel}</MaterialFactory>
                </div>
            </div>
        )
    };

    render = () => {
        return (
            <>
                <div className='blackBackground'/>
                <div className='uploadTips'>
                    <div className='uploadTipsHeader'>
                        <label className='headerLabel'>Dit doen anderen</label>
                        <span className='closeButton common-icon-cross' onClick={this.props.onClose}/>
                    </div>
                    <div className='uploadTipsBody'>
                        <p className='uploadTipsMessage'>
                            Je gehele administratie kan behoorlijk uitgebreid zijn. Begin vooral eerst me de
                            belangrijkste dingen. Bekijk hieronder welke dossiers het meest worden aangemaakt door
                            andere gebruikers.
                        </p>
                        {this.renderDossierTypes_v2()}
                        {/*<div className='uploadTipsColumns'>*/}
                        {/*<div className='uploadTipsColumn'>*/}
                        {/*{this.renderDocumentTypes()}*/}
                        {/*</div>*/}
                        {/*<div className='uploadTipsColumn'>*/}
                        {/*{this.renderDossierTypes()}*/}
                        {/*</div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </>
        )
    };
}

UploadTips.propTypes = {
    onUploadDocument: PropTypes.func.isRequired,
    onUploadDossier: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    documentTypes: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    })),
    dossierTypes: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    })),
};