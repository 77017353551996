import './style/genericLoader.scss';

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import MaterialFactory from "../material/materialFactory";
import {materialTypes} from "../material/materialTypes";

export default class GenericLoader extends React.Component {
    constructor(props) {
        super(props);
    }

    render = () => {
        if (this.props.active) {
            const backgroundClassName = classNames('genericLoaderBackground', this.props.backgroundClassName);
            const containerClassName = classNames('genericLoaderContainer', this.props.containerClassName);
            const textClassName = classNames('genericLoaderLabel', this.props.textClassName);
            return (
                <>
                    <div className={backgroundClassName}/>
                    <div className={containerClassName}>
                        <MaterialFactory componentType={materialTypes.CIRCULAR_PROGRESS} size={this.props.size}
                                         thickness={this.props.thickness}/>
                        {isNotNull(this.props.label) &&
                        <label className={textClassName}>{this.props.label}</label>}
                    </div>
                </>
            )
        }
        return null;
    };
}

GenericLoader.propTypes = {
    active: PropTypes.bool.isRequired,
    className: PropTypes.string,
    backgroundClassName: PropTypes.string,
    containerClassName: PropTypes.string,
    textClassName: PropTypes.string,
    size: PropTypes.number,
    thickness: PropTypes.number,
    label: PropTypes.string,
};

GenericLoader.defaultProps = {
    size: 80,
    thickness: 7,
    className: '',
    backgroundClassName: '',
    containerClassName: '',
    textClassName: '',
};