import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import EditContactView from "./editContactView";
import {sendGaEvent} from "../../../../common/js/ga";
import getCategoryType from "../../../../common/data/mappings/categoryMapping";
import {updateDossier} from "../../../../misc/requestSender";

export default class EditContactController extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            active: false,
        };
        this._isMounted = false;
    }

    changeState = stateChange => {
        if (this._isMounted)
            this.setState(stateChange);
    };

    activate = () => {
        this.changeState({active: true});
    };

    deactivate = () => {
        this.changeState({active: false});
    };

    onSubmit = model => {
        let dossier = this.props.dossier;
        if (this.props.type === 'add') {
            this.onSubmitAdd(model, dossier);
        } else if (this.props.type === 'edit') {
            this.onSubmitEdit(model, dossier);
        }
        this.updateDossier(dossier, () => {
            let module = this.props.selectedModule;
            let moduleLabel = isNotNull(module) ? getCategoryType(module).label : 'Geen module bekend';
            sendGaEvent(moduleLabel, 'Contact aanmaken', this.props.dossier.type.label);
        });
    };

    onSubmitAdd = (model, dossier) => {
        dossier.external_data = dossier.external_data || {};
        dossier.external_data.dossierContacts = dossier.external_data.dossierContacts || [];
        dossier.external_data.dossierContacts.push(model);
    };

    onSubmitEdit = (model, dossier) => {
        // No need for null check, since editing can only be triggered with an existing contact
        let dossierContacts = dossier.external_data.dossierContacts;
        for (let i = 0; i < dossierContacts.length; i++) {
            if (dossierContacts[i].id === model.id) {
                dossierContacts[i] = _.merge({}, dossierContacts[i], model);
                return;
            }
        }

        // When we can't find the id (for whatever reason) we just add the contact to the dossier. We must do this to
        // support contact which were made before this change.
        dossier.external_data.dossierContacts.push(model);
    };

    updateDossier = (dossier, gaEvent) => {
        let data = {
            title: dossier.title,
            external_data: dossier.external_data,
            type: {
                id: dossier.type.id,
                name: dossier.type.name,
                fields: [],
            }
        };
        updateDossier({
            id: dossier.id, data,
            callback: () => {
                this.view.disableLoader(() => {
                    gaEvent();
                    this.props.onUpdateDossier(dossier.external_data.dossierContacts);
                    this.deactivate();
                });
            }
        });
    };

    getHeaderTitle = () => {
        return 'Contact ' + (this.props.type === 'add' ? 'toevoegen' : 'aanpassen');
    };

    componentDidMount = () => {
        this._isMounted = true;
    };

    componentWillUnmount = () => {
        this._isMounted = false;
    };

    render = () => {
        if (this.state.active)
            return (
                <EditContactView ref={refName => {
                    this.view = refName
                }} headerTitle={this.getHeaderTitle()} onSubmit={this.onSubmit} onCancel={this.deactivate}
                                 contact={this.props.contact}/>
            );
        return null;
    };
}

EditContactController.propTypes = {
    type: PropTypes.oneOf(['add', 'edit']).isRequired,
    contact: PropTypes.shape({
        name: PropTypes.string.isRequired,
        website: PropTypes.string,
        phone: PropTypes.string,
        email: PropTypes.string,
        loginName: PropTypes.string,
        address: PropTypes.shape({
            street: PropTypes.string,
            houseNumber: PropTypes.string,
            houseNumberSuffix: PropTypes.string,
            zipCode: PropTypes.string,
            city: PropTypes.string,
        }),
        comment: PropTypes.string,
    }),
    dossier: PropTypes.object.isRequired,
    selectedModule: PropTypes.object,
    onUpdateDossier: PropTypes.func.isRequired,
};