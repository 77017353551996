import './style/externalLinkWarning.scss';

import React from 'react';

import {addCustomEventListener, removeCustomEventListener} from "../../misc/eventDispatcher";
import MaterialFactory from "../material/materialFactory";
import {materialTypes} from "../material/materialTypes";

export default class ExternalLinkWarning extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            link: null,
        };
        this._isMounted = false;
    }

    changeState = stateChange => {
        this._isMounted && this.setState(stateChange);
    };

    onNavigate = () => {
        window.open(this.state.link);
        this.deactivate();
    };

    activate = url => {
        this.changeState({link: url});
    };

    deactivate = () => {
        this.changeState({link: null});
    };

    renderActions = () => ([
        {label: 'Nee', onClick: this.deactivate},
        {label: 'Ja', onClick: this.onNavigate},
    ]);

    renderLink = () => {
        if (isNotNull(this.state.link)) {
            let link = this.state.link;
            if (!link.startsWith('http'))
                return 'https:' + link;
            return link;
        }
        return null;
    };

    componentDidMount = () => {
        this._isMounted = true;
        addCustomEventListener('externalLink', this.activate);
    };

    componentWillUnmount = () => {
        this._isMounted = false;
        removeCustomEventListener('externalLink', this.activate);
        this.deactivate();
    };

    render = () => {
        return (
            <MaterialFactory componentType={materialTypes.DIALOG} title='Navigatie' open={isNotNull(this.state.link)}
                             actions={this.renderActions()} onClose={this.deactivate} content={
                <>
                    <p className='externalLinkWarning'>Je verlaat nu het ByDeHand platform, wil je doorgaan?</p>
                    <p className='externalLink'>{this.renderLink()}</p>
                </>}>
            </MaterialFactory>
        );
    };
}