import React from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';

import {isFunction} from 'glob-common-js/lib/utils';

import StateComponent from "../../../misc/stateComponent";
import {deleteGrant, updateGrant} from "../../../../misc/requestSender";
import AddGrant from "./addGrant";
import {addCustomEventListener, dispatchCustomEvent, removeCustomEventListener} from "../../../../misc/eventDispatcher";
import {materialTypes} from "../../../material/materialTypes";
import MaterialFactory from "../../../material/materialFactory";
import {MISSING_CONNECTION} from "../../../../misc/constants";

export class UpdateGrantController extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            email: '',
            read: true,
            manage: true,
            share: false,
            error: null,
            loading: false,
            deleteOpen: false,
        };
        this.variant = null;
        this.grant = null;
        this.onSuccessCallback = null;
    }

    onSubmit = () => {
        this.changeState({loading: true});
        const {variant} = this.state;
        const rights = this.prepareRights();
        const granted_id = variant === 'to' ? this.grant.id : this.props.userState.user.id;
        updateGrant({
            data: {
                granted_id,
                rights,
                accepted: this.grant.accepted,
            },
            callback: (response) => {
                this.changeState({loading: false});
                const {status, message} = response.data;
                if (status === 'success') {
                    this.onClose();
                    dispatchCustomEvent('openSnackbar', {text: 'Machtiging is aangepast'});
                    if (isFunction(this.onSuccessCallback))
                        this.onSuccessCallback();
                } else {
                    let errorMessage = 'Aanpassen is niet gelukt';
                    if (message === MISSING_CONNECTION)
                        errorMessage = 'Geen machtiging gevonden';
                    this.changeState({error: errorMessage});
                }
            }
        })
    };

    onClose = () => {
        this.changeState({active: false, email: '', read: true, manage: true, share: true, error: null});
    };

    onResetError = () => {
        this.changeState({error: null});
    };

    onChangeRight = name => evt => {
        this.changeState({[name]: evt.target.checked});
    };

    onDeleteGrant = () => {
        this.changeState({deleteOpen: true});
    };

    onDelete = () => {
        this.onCloseDialog();
        this.changeState({loading: true});
        const userId = this.props.userState.user.id;
        if (isNotNull(userId)) {
            const {id} = this.grant;
            const {variant} = this.state;
            let grantTo = null, grantBy = null;
            if (variant === 'to') {
                grantBy = userId;
                grantTo = id;
            } else if (variant === 'from') {
                grantBy = id;
                grantTo = userId;
            }

            deleteGrant({
                granted_to: grantTo,
                granted_by: grantBy,
                callback: this.deleteCallback
            });
        } else {
            dispatchCustomEvent('openSnackbar', {text: 'Er is iets misgegaan bij het intrekken van de machtiging'});
        }
    };

    onCloseDialog = () => {
        this.changeState({deleteOpen: false});
    };

    deleteCallback = response => {
        this.changeState({loading: false});
        const {status} = response.data;
        if (status === 'success') {
            this.onClose();
            dispatchCustomEvent('openSnackbar', {text: 'Machtiging is ingetrokken'});
            if (isFunction(this.onSuccessCallback))
                this.onSuccessCallback();
        } else {
            dispatchCustomEvent('openSnackbar', {text: 'Er is iets misgegaan bij het intrekken van de machtiging'});
        }
    };

    activate = ({grant, variant, onSuccess}) => {
        this.onSuccessCallback = onSuccess;
        this.grant = grant;

        const rights = grant.rights, read = rights.includes('read'), manage = rights.includes('manage'),
            share = rights.includes('share');
        this.changeState({active: true, email: grant.email, read, manage, share, variant});
    };

    prepareRights = () => {
        const {read, manage, share} = this.state;
        const rights = [];
        if (read) rights.push('read');
        if (manage) rights.push('manage');
        if (share) rights.push('share');
        return rights;
    };

    valuesHaveChanged = () => {
        if (isNull(this.grant)) return false;
        const rights = this.grant.rights;
        const valueRights = this.prepareRights();
        if (rights.length !== valueRights.length) return true;
        return (isNotNull(_.difference(rights, valueRights)));
    };

    renderDeleteConfirmActions = () => ([
        {label: 'Ja', onClick: this.onDelete},
        {label: 'Nee', onClick: this.onCloseDialog}
    ]);

    componentDidMount = () => {
        this.mount();
        addCustomEventListener('updateGrant', this.activate);
    };

    componentWillUnmount = () => {
        this.unMount();
        removeCustomEventListener('updateGrant', this.activate);
    };

    render = () => {
        const {email, read, manage, share, active, variant, loading, deleteOpen} = this.state;
        return (
            <>
                <MaterialFactory componentType={materialTypes.DIALOG} title='Machtiging verwijderen'
                                 open={deleteOpen} onClose={this.onCloseDialog}
                                 actions={this.renderDeleteConfirmActions()}
                                 text='Weet je zeker dat je de machtiging van dit account wilt verwijderen?'/>
                <AddGrant onSubmit={this.onSubmit} onClose={this.onClose} onChangeMail={null} includeDelete mailDisabled
                          fieldsDisabled={variant === 'from'} onChangeRight={this.onChangeRight} loading={loading}
                          onResetError={this.onResetError} email={email} read={read} manage={manage} share={share}
                          hasChanged={this.valuesHaveChanged()} onDelete={this.onDeleteGrant}
                          active={active} actionText='Machtiging aanpassen'/>
            </>
        );
    };
}

const mapStateToProps = state => ({
    userState: state.user,
});

export default connect(mapStateToProps)(UpdateGrantController);