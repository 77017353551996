import PropTypes from 'prop-types';

import {isFunction} from 'glob-common-js/lib/utils';
import {BAD_REQUEST} from 'glob-common-js/lib/request';
import {
    createDocument,
    deleteDocument,
    getAllDocuments,
    getDocument,
    getDocumentUrl,
    getDossier,
    postFile,
    responseIsSuccess,
    updateDocument
} from "../misc/requestSender";
import getDocumentType from "../common/data/mappings/docTypeMapping";
import {toMoment} from "../misc/utils";
import Dossier from "./dossier";
import * as _ from "lodash";

export default class BdhDocument extends Object{
    static get = (id, callback, withUrl = false) => {
        getDocument({
            id, callback: response => {
                if (responseIsSuccess(response)) {
                    const doc = new BdhDocument(response.data.vault);
                    if (withUrl)
                        doc.loadUrl(() => {
                            callback(doc);
                        });
                } else callback(new BdhDocument());
            }
        });
    };

    static getAll = (query, callback) => {
        getAllDocuments({
            queryParams: query,
            callback: response => {
                const {vault} = response.data;
                callback(vault.map(doc => new BdhDocument(doc)), response);
            },
        });
    };

    #fields = {
        id: '',
        label: '',
        createdAt: '',
        updatedAt: '',
        extension: '',
        type: {},
        externalData: {},
        belongings: [],
        dossiers: [],
        url: '',
    };
    #dossiersAreLoaded = false;
    #dossiersLoaded = 0;
    #urlLoaded = false;

    constructor(props) {
        super(props);
        this.#initializeFields(props);
    }

    hasOwnProperty = name => {
        return isNotNull(this[name]) || this.#fields[name];
    };

    get id() {
        return isNotNull(this.#fields.id) ? this.#fields.id : '';
    }

    set id(id) {
        this.#fields.id = id;
    }

    get label() {
        return isNotNull(this.#fields.label) ? this.#fields.label : '';
    }

    set label(label) {
        this.#fields.label = label;
    }

    get name() {
        return this.label;
    }

    set name(label) {
        this.label = label;
    }

    get createdAt() {
        return isNotNull(this.#fields.createdAt) ? toMoment(this.#fields.createdAt) : null;
    }

    get updatedAt() {
        if (isNotNull(this.#fields.updatedAt))
            return toMoment(this.#fields.updatedAt);
        if (isNotNull(this.externalData.updated_at))
            return toMoment(this.externalData.updated_at);
        return null;
    }

    get extension() {
        return isNotNull(this.#fields.extension) ? this.#fields.extension : '';
    }

    set extension(extension) {
        this.#fields.extension = extension;
    }

    get type() {
        return isNotNull(this.#fields.type) ? this.#fields.type : {};
    }

    set type(type) {
        this.#fields.type = type;
    }

    get fields() {
        const externalData = this.externalData;
        if (pathIsNotNull(externalData, 'fields')) return externalData.fields;

        const type = this.type;
        if (isNotNull(type.fields)) return type.fields;
        return {};
    }

    set fields(fields) {
        this.externalData.fields = fields;
        this.type.fields = fields;
    }

    get externalData() {
        return isNotNull(this.#fields.externalData) ? this.#fields.externalData : {};
    }

    set externalData(data) {
        this.#fields.externalData = data;
    }

    get external_data() {
        // TODO Legacy support, remove when possible
        return this.externalData;
    }

    set external_data(data) {
        // TODO Legacy support, remove when possible
        this.externalData = data;
    }

    get dossiers() {
        return isNotNull(this.#fields.dossiers) ? this.#fields.dossiers : [];
    }

    set dossiers(dossiers) {
        this.#fields.dossiers = dossiers;
    }

    get dossiersLoaded() {
        return this.#dossiersAreLoaded;
    }

    get urlLoaded() {
        return this.#urlLoaded;
    }

    get url() {
        return isNotNull(this.#fields.url) ? this.#fields.url : '';
    }

    set url(url) {
        this.#fields.url = url;
    }

    get belongings() {
        return isNotNull(this.#fields.belongings) ? this.#fields.belongings : this.dossiers;
    }

    set belongings(belongings) {
        this.#fields.belongings = belongings;
        this.dossiers = belongings;
    }

    get fileIsImage() {
        return /(jpg|jpeg|png|gif)$/i.test(this.extension);
    }

    save = (file, callback) => {
        this.#verifyRequestFields();
        if (isNull(file)) throw new Error('No file specified to upload');
        postFile({
            type_id: this.type.id, file, callback: response => {
                if (responseIsSuccess(response)) {
                    const {file_id} = response.data;
                    this.id = file_id;
                    createDocument({
                            file_id,
                            type_id: this.type.id,
                            belonging_ids: this.dossiers.map(dossier => dossier.id),
                            external_data: this.externalData,
                            label: this.label,
                            callback: response => {
                                if (responseIsSuccess(response)) {
                                    const {url} = response.data;
                                    this.url = url;
                                    callback(true, this);
                                } else callback(false, null);
                            }
                        }
                    )
                } else callback(false, null);
            }
        });
    };

    update = callback => {
        this.#verifyRequestFields(true);
        const data = this.#createRequestData(true);
        updateDocument({
            data, id: this.id, callback: response => {
                if (isNull(response.data.message))
                    this.#initializeFields(response.data.vault);
                callback(this, response);
            }
        });
    };

    delete = callback => {
        deleteDocument({id: this.id, callback});
    };

    loadDossiers = callback => {
        const belongings = this.belongings;
        if (belongings.length === 0 && isFunction(callback))
            callback();

        const dossiersToLoad = belongings.length;
        belongings.forEach(belonging => {
            this.#loadDossier(belonging.id, dossiersToLoad, callback);
        });
        this.#dossiersAreLoaded = true;
    };

    addDossier = dossier => {
        if (isNotNull(dossier))
            this.#fields.dossiers.push(new Dossier(dossier));
    };

    removeDossier = dossier => {
        this.dossiers = this.dossiers.filter(doss => doss.id !== dossier.id);
    };

    load = (callback, withUrl = false) => {
        getDocument({
            id: this.id, callback: response => {
                this.#initializeFields(response.data.vault);
                if (withUrl)
                    this.loadUrl((doc, response) => {
                        callback(this, response);
                    });
                else
                    callback(this, response);
            }
        });
    };

    loadUrl = (callback, force = false) => {
        if (this.#urlLoaded && !force)
            callback(this, {data: {status: 'success'}});
        getDocumentUrl({
            id: this.id,
            callback: response => {
                this.url = response.data.url;
                this.#urlLoaded = true;
                if (isFunction(callback))
                    callback(this, response);
            },
            errorCallback: error => {
                const {response} = error;
                if (response.status === BAD_REQUEST) {
                    callback(this, response);
                } else throw new Error(error);
            },
        })
    };

    #verifyRequestFields = (isUpdate = false) => {
        this.#verifyField(this.type.id);
        this.#verifyField(this.label);
        if (isUpdate) this.#verifyUpdateFields();
    };

    #verifyUpdateFields = () => {
        this.#verifyField(this.type.name);
        this.#verifyField(this.type.label);
    };

    #verifyField = (value, validator = null) => {
        const valid = isFunction(validator) ? validator(value) : isNotNull(value);
        if (!valid) throw new Error(`Field validation failed for document value ${value}`);
    };

    #createRequestData = (isUpdate = false) => {
        if (isUpdate) return this.#createUpdateRequestData();
        return {
            belonging_ids: this.dossiers.map(dossier => dossier.id),
            external_data: this.externalData,
            families: [],
            fields: [],
            file_type_id: this.type.id,
            label: this.label,
        };
    };

    #createUpdateRequestData = () => {
        return {
            label: this.label,
            type: {
                id: this.type.id,
                name: this.type.name,
                image: this.type.name,
                label: this.type.label,
                fields: [],
            },
            belonging_ids: this.dossiers.map(dossier => dossier.id),
            external_data: this.externalData,
        };
    };

    #loadDossier = (id, countToLoad, callback) => {
        getDossier({
            id, callback: dossier => {
                this.#fields.dossiers.push(new Dossier(dossier));
                if (++this.#dossiersLoaded >= countToLoad)
                    callback();
            }
        });
    };

    #initializeFields = props => {
        if (isNotNull(props)) {
            this.#initializeField('id', props.id);
            this.#initializeField('label', props.name);
            this.#initializeField('label', props.label);
            this.#initializeField('createdAt', props.created_at);
            this.#initializeField('updatedAt', props.updated_at);
            this.#initializeField('extension', props.extension);
            this.#initializeField('type', this.#getExtendedType(props.type));
            this.#initializeField('externalData', props.external_data);
            this.#initializeField('belongings', this.#initializeBelongings(props.belongings), false);
            this.#initializeField('url', props.url);
        }
    };

    #initializeBelongings = belongings => {
        if (isNull(belongings)) belongings = [];
        const dossiers = belongings.map(belonging => new Dossier(belonging));
        this.dossiers = dossiers;
        return dossiers;
    };

    #initializeField = (name, value, nullCheck = true) => {
        if (isNotNull(value) || !nullCheck) this.#fields[name] = value;
    };

    #getExtendedType = type => {
        type = _.merge({}, type);
        const documentType = _.merge({}, getDocumentType(type.name)), docFields = documentType.fields;
        let fields = type.fields;
        if (isNotNull(fields)) {
            if (!Array.isArray(fields)) fields = Object.values(fields);
            fields.forEach(field => {
                if (pathIsNotNull(docFields, field.name))
                    docFields[field.name].value = field.value;
            });
        }
        type.fields = this.#insertEmptyValues(docFields);
        return type;
    };

    #insertEmptyValues = fields => {
        if (isNotNull(fields)) {
            const keys = Object.keys(fields);
            keys.forEach(key => {
                const field = fields[key];
                if (isNull(field.value)) {
                    if (['DATE', 'DATETIME'].includes(field.type))
                        field.value = null;
                    else field.value = '';
                }
                fields[key] = field;
            });
        }
        return fields;
    };
}

BdhDocument.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string,
    extension: PropTypes.string,
    type: PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    }),
    external_data: PropTypes.object,
    belongings: PropTypes.arrayOf(PropTypes.object).isRequired,
};