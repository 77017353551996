import {debug, warn} from 'glob-common-js/lib/utils';

let registry;

// List of observer function which will be executed when the registry is updated.
let observers = [];

export const dummyRegistry = {
    healthInsurance: {
        currentInsuranceRequest: {

        },
        currentInsurance: {
            insurer: 'Bewuzt',
            basic: {label: 'Bewuzt basisverzekering', discount: 0, discount_type: '',},
            additionals: [
                {
                    label: 'Bewuzt Tand goed',
                    discount: 0,
                },
                {
                    label: 'Bewuzt Fysio goed',
                    discount: 0,
                }
            ],
            additionalDiscountType: '',
            dental: null,
            yearOfBirth: 1980,
            risk: 385,
            isLoaded: false,
            defaultCoverages: {}
        },
        evaluation: {
            isUserData: false,
            insurer: {
                label: 'Bewuzt',
                cheapest: 94,
                mostExpensive: 129,
                price: 100.65,
                stepSize: 5,
                stripeCount: 9,
                textGreen: '2 vergelijkbare verzekeringen zijn goedkoper',
                textRed: '9 vergelijkbare verzekeringen zijn duurder',
                textSavePig: 'Bespaar tot \u20ac 77,- per jaar',
            },
            basic: {
                insurance: "Bewuzt basisverzekering",
                discount: 0,
                price_basic: 99.15,
                price_basic_incl_discount: 84.15,
                policy_type: "Naturapolis",
                covered_no_contract: "70% van de kosten wordt vergoed in geval er geen contract is.",
                risk: 885
            },
            additionals: [
                {
                    name: "Bewuzt Tand goed",
                    type: 'TAND',
                    price: 10,
                    discount: 0,
                    parts_covered: [
                        {
                            part: "tandarts",
                            text: "Je krijgt 75% van je tandartskosten vergoed, met een maximum van €250,-",
                            bucket: "1"
                        },
                        {
                            part: "mondzorg ongevallen",
                            text: "Je krijgt maximaal € 10.000,- vergoed voor tandheelkundige bij een ongeval",
                            bucket: "1"
                        }
                    ],
                    parts_not_covered: "alternatieve zorg, brillen / lenzen, fysiotherapie, kraamzorg, spoedeisende hulp buitenland, " +
                    "vaccinaties, orthodontie tot 18 jaar, orthodontie vanaf 18 jaar spoedeisende zorg tanden, acne behandeling, " +
                    "anticonceptie, camouflagetherapie, cursussen en preventie, dieet advisering, geneesmiddelen, herstellingsoord / zorghotel, " +
                    "homeopatische middelen, hulpmiddelen (overig), logeerkosten, mantelzorg, nazorg bij kanker, ontharen / lasertherapie, " +
                    "sportmedisch onderzoek, sterilisatie (man), sterilisatie (vrouw), steunzolen, stottertherapie, voetzorg/voetbehandeling"
                },
                {
                    name: 'Bewuzt Fysio goed',
                    type: 'AANV',
                    price: 6.50,
                    discount: 0,
                    parts_covered: [
                        {
                            part: "fysiotherapie",
                            text: "Je krijgt 9 behandelingen per jaar vergoed",
                            bucket: "1"
                        },
                    ],

                    parts_not_covered: "alternatieve zorg, brillen / lenzen, kraamzorg, spoedeisende hulp buitenland, " +
                    "vaccinaties, tandarts, orthodontie tot 18 jaar, orthodontie vanaf 18 jaar, spoedeisende zorg tanden, " +
                    "mondzorg ongevallen, acne behandeling, anticonceptie, camouflagetherapie, cursussen en preventie, " +
                    "dieet advisering, geneesmiddelen, herstellingsoord / zorghotel, homeopatische middelen, hulpmiddelen (overig), " +
                    "logeerkosten, mantelzorg, nazorg bij kanker, ontharen / lasertherapie, sportmedisch onderzoek, " +
                    "sterilisatie (man), sterilisatie (vrouw), steunzolen, stottertherapie, voetzorg/voetbehandeling"
                }
            ],
            risk: {
                485: 36,
                585: 72,
                685: 108,
                785: 144,
                885: 180,
                change_value: 565
            },
            year_of_birth: 1993,
        },
        nextInsurance: {
            insurer: 'FLKS',
            basic: {
                name: 'FLKS-basis',
                type: 'Naturapolis',
                covered_no_contract: '75% van de kosten wordt vergoed in geval er geen contract is.',
            },
            additionals: ['FLKS-aanvullend',],
            risk: 385,
            monthprice: 100,
        },
        editInsurance: {
            originalCoverages: [
                {
                    part: 'policy_type',
                    default_selected: 1,
                },
                {
                    part: 'risk',
                    default_selected: 2,
                },
                {
                    part: 'covered_no_contract',
                    default_selected: 6,
                },
                {
                    part: 'alternatieve_zorg',
                    default_selected: 0
                },
                {
                    part: 'brillen_lenzen',
                    default_selected: 0
                },
                {
                    part: "fysiotherapie",
                    default_selected: 1,
                },
                {
                    part: 'kraamzorg',
                    default_selected: 0
                },
                {
                    part: "spoedeisende_hulp_buitenland",
                    default_selected: 1,
                },
                {
                    part: "vaccinaties",
                    default_selected: 1,
                },
                {
                    part: "tandarts",
                    default_selected: 1,
                },
                {
                    part: 'orthodontie_tot_18_jaar',
                    default_selected: 0
                },
                {
                    part: 'orthodontie_vanaf_18_jaar',
                    default_selected: 0
                },
                {
                    part: "mondzorg_ongevallen",
                    default_selected: 1,
                },
                {
                    part: 'spoedeisende_zorg_tanden',
                    default_selected: 0
                },
                {
                    part: 'acne_behandeling',
                    default_selected: 0
                },
                {
                    part: "anticonceptie",
                    default_selected: 1,
                },
                {
                    part: 'camouflagetherapie',
                    default_selected: 0
                },
                {
                    part: "cursussen_en_preventie",
                    default_selected: 1,
                },
                {
                    part: 'dieet_advisering',
                    default_selected: 0
                },
                {
                    part: 'geneesmiddelen',
                    default_selected: 0
                },
                {
                    part: 'herstellingsoord_zorghotel',
                    default_selected: 0
                },
                {
                    part: 'homeopatische_middelen',
                    default_selected: 0
                },
                {
                    part: "hulpmiddelen_overig",
                    default_selected: 1,
                },
                {
                    part: 'logeerkosten',
                    default_selected: 0
                },
                {
                    part: 'mantelzorg',
                    default_selected: 0
                },
                {
                    part: 'nazorg_bij_kanker',
                    default_selected: 0
                },
                {
                    part: 'ontharen_lasertherapie',
                    default_selected: 0
                },
                {
                    part: "sportmedisch_onderzoek",
                    default_selected: 1,
                },
                {
                    part: 'sterilisatie_man',
                    default_selected: 0
                },
                {
                    part: 'sterilisatie_vrouw',
                    default_selected: 0
                },
                {
                    part: "steunzolen",
                    default_selected: 1,
                },
                {
                    part: "stottertherapie",
                    default_selected: 1,
                }, {
                    part: "voetzorg_voetbehandeling",
                    default_selected: 1,
                },
            ],
            comparingCurrent: true,
            basic: {
                label: 'type_verzekering',
                value: 'Naturapolis',
                default_selected: 1,
                default_selected_contract: 6,
            },
            risk: {
                // label: 'basisverzekering',
                value: '\u20ac 885',
                default_selected: 5,
            },
            coverages: [
                {
                    part: 'policy_type',
                    default_selected: 1,
                },
                {
                    part: 'risk',
                    default_selected: 2,
                },
                {
                    part: 'covered_no_contract',
                    default_selected: 6,
                },
                {
                    part: 'alternatieve_zorg',
                    default_selected: 0
                },
                {
                    part: 'brillen_lenzen',
                    default_selected: 0
                },
                {
                    part: "fysiotherapie",
                    default_selected: 1,
                },
                {
                    part: 'kraamzorg',
                    default_selected: 0
                },
                {
                    part: "spoedeisende_hulp_buitenland",
                    default_selected: 1,
                },
                {
                    part: "vaccinaties",
                    default_selected: 1,
                },
                {
                    part: "tandarts",
                    default_selected: 1,
                },
                {
                    part: 'orthodontie_tot_18_jaar',
                    default_selected: 0
                },
                {
                    part: 'orthodontie_vanaf_18_jaar',
                    default_selected: 0
                },
                {
                    part: "mondzorg_ongevallen",
                    default_selected: 1,
                },
                {
                    part: 'spoedeisende_zorg_tanden',
                    default_selected: 0
                },
                {
                    part: 'acne_behandeling',
                    default_selected: 0
                },
                {
                    part: "anticonceptie",
                    default_selected: 1,
                },
                {
                    part: 'camouflagetherapie',
                    default_selected: 0
                },
                {
                    part: "cursussen_en_preventie",
                    default_selected: 1,
                },
                {
                    part: 'dieet_advisering',
                    default_selected: 0
                },
                {
                    part: 'geneesmiddelen',
                    default_selected: 0
                },
                {
                    part: 'herstellingsoord_zorghotel',
                    default_selected: 0
                },
                {
                    part: 'homeopatische_middelen',
                    default_selected: 0
                },
                {
                    part: "hulpmiddelen_overig",
                    default_selected: 1,
                },
                {
                    part: 'logeerkosten',
                    default_selected: 0
                },
                {
                    part: 'mantelzorg',
                    default_selected: 0
                },
                {
                    part: 'nazorg_bij_kanker',
                    default_selected: 0
                },
                {
                    part: 'ontharen_lasertherapie',
                    default_selected: 0
                },
                {
                    part: "sportmedisch_onderzoek",
                    default_selected: 1,
                },
                {
                    part: 'sterilisatie_man',
                    default_selected: 0
                },
                {
                    part: 'sterilisatie_vrouw',
                    default_selected: 0
                },
                {
                    part: "steunzolen",
                    default_selected: 1,
                },
                {
                    part: "stottertherapie",
                    default_selected: 1,
                }, {
                    part: "voetzorg_voetbehandeling",
                    default_selected: 1,
                },
            ],
            alternatives: [
                {
                    insurer: 'Bewuzt',
                    monthprice: 100.65,
                    difference: '',
                    basic: {
                        name: 'Bewuzt basisverzekering',
                        type: 'Naturapolis',
                        covered_no_contract: '70% van de kosten wordt vergoed in geval er geen contract is.\n',
                    },
                    additionals: [
                        'Bewuzt Tand goed',
                        'Bewuzt Fysio goed',
                    ],
                    coverages: {
                        alternatieve_zorg: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        brillen_lenzen: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        fysiotherapie: {
                            text: "Je krijgt 9 behandelingen per jaar vergoed",
                            bucket: 3,
                        },
                        kraamzorg: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        spoedeisende_hulp_buitenland: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        vaccinaties: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        tandarts: {
                            text: "Je krijgt 75% van je tandartskosten vergoed, met een maximum van €250,-",
                            bucket: 3,
                        },
                        orthodontie_tot_18_jaar: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        orthodontie_vanaf_18_jaar: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        mondzorg_ongevallen: {
                            text: "Je krijgt maximaal € 10.000,- vergoed voor tandheelkundige bij een ongeval",
                            bucket: 7,
                        },
                        spoedeisende_zorg_tanden: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        acne_behandeling: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        anticonceptie: {
                            text: 'Geen dekking',
                            bucket: 0,
                        },
                        camouflagetherapie: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        cursussen_en_preventie: {
                            text: 'Geen dekking',
                            bucket: 0,
                        },
                        dieet_advisering: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        geneesmiddelen: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        herstellingsoord_zorghotel: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        homeopatische_middelen: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        hulpmiddelen_overig: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        logeerkosten: {
                            text: 'Geen dekking',
                            bucket: 0,
                        },
                        mantelzorg: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        nazorg_bij_kanker: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        ontharen_lasertherapie: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        sportmedisch_onderzoek: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        sterilisatie_man: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        sterilisatie_vrouw: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        steunzolen: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        stottertherapie: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        voetzorg_voetbehandeling: {
                            text: "Geen dekking",
                            bucket: 0,
                        }
                    },
                    ranking: 0,
                },
                {
                    insurer: 'PMA Menzis',
                    monthprice: 94.2,
                    difference: '\u20ac 77 per jaar goedkoper',
                    basic: {
                        name: 'PMA Menzis Basis Voordelig',
                        type: 'Naturapolis',
                        covered_no_contract: '65% van de kosten wordt vergoed in geval er geen contract is.',
                    },
                    additionals: [
                        'PMA Menzis Aanvullend'
                    ],
                    coverages: {
                        alternatieve_zorg: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        brillen_lenzen: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        fysiotherapie: {
                            text: "Je krijgt 9 behandelingen per jaar vergoed",
                            bucket: 3,
                        },
                        kraamzorg: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        spoedeisende_hulp_buitenland: {
                            text: "Je krijgt wereldwijde spoedeisende hulp vergoed",
                            bucket: 2,
                        },
                        vaccinaties: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        tandarts: {
                            text: "Je krijgt 100% voor tandartscontroles en 80% voor overige tandheelkunde vergoed, met een maximum van €250,-",
                            bucket: 3,
                        },
                        orthodontie_tot_18_jaar: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        orthodontie_vanaf_18_jaar: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        mondzorg_ongevallen: {
                            text: "Je krijgt maximaal € 10.000,- vergoed voor tandheelkundige bij een ongeval",
                            bucket: 7,
                        },
                        spoedeisende_zorg_tanden: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        acne_behandeling: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        anticonceptie: {
                            text: 'Geen dekking',
                            bucket: 0,
                        },
                        camouflagetherapie: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        cursussen_en_preventie: {
                            text: 'Geen dekking',
                            bucket: 0,
                        },
                        dieet_advisering: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        geneesmiddelen: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        herstellingsoord_zorghotel: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        homeopatische_middelen: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        hulpmiddelen_overig: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        logeerkosten: {
                            text: 'Geen dekking',
                            bucket: 0,
                        },
                        mantelzorg: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        nazorg_bij_kanker: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        ontharen_lasertherapie: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        sportmedisch_onderzoek: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        sterilisatie_man: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        sterilisatie_vrouw: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        steunzolen: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        stottertherapie: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        voetzorg_voetbehandeling: {
                            text: "Geen dekking",
                            bucket: 0,
                        }
                    },
                    ranking: 1,
                },
                {
                    insurer: 'Menzis',
                    monthprice: 96.95,
                    difference: '\u20ac 44 per jaar goedkoper',
                    basic: {
                        name: 'Menzis Basis Voordelig',
                        type: 'Naturapolis',
                        covered_no_contract: '65% van de kosten wordt vergoed in geval er geen contract is.',
                    },
                    additionals: [
                        'Menzis Aanvullend',
                    ],
                    coverages: {
                        alternatieve_zorg: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        brillen_lenzen: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        fysiotherapie: {
                            text: "Je krijgt 9 behandelingen per jaar vergoed",
                            bucket: 3,
                        },
                        kraamzorg: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        spoedeisende_hulp_buitenland: {
                            text: "Je krijgt wereldwijde spoedeisende hulp vergoed",
                            bucket: 2,
                        },
                        vaccinaties: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        tandarts: {
                            text: "Je krijgt 100% voor tandartscontroles en 80% voor overige tandheelkunde vergoed, met een maximum van €250,-",
                            bucket: 3,
                        },
                        orthodontie_tot_18_jaar: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        orthodontie_vanaf_18_jaar: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        mondzorg_ongevallen: {
                            text: "Je krijgt maximaal € 10.000,- vergoed voor tandheelkundige bij een ongeval",
                            bucket: 7,
                        },
                        spoedeisende_zorg_tanden: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        acne_behandeling: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        anticonceptie: {
                            text: 'Geen dekking',
                            bucket: 0,
                        },
                        camouflagetherapie: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        cursussen_en_preventie: {
                            text: 'Geen dekking',
                            bucket: 0,
                        },
                        dieet_advisering: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        geneesmiddelen: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        herstellingsoord_zorghotel: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        homeopatische_middelen: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        hulpmiddelen_overig: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        logeerkosten: {
                            text: 'Geen dekking',
                            bucket: 0,
                        },
                        mantelzorg: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        nazorg_bij_kanker: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        ontharen_lasertherapie: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        sportmedisch_onderzoek: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        sterilisatie_man: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        sterilisatie_vrouw: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        steunzolen: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        stottertherapie: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        voetzorg_voetbehandeling: {
                            text: "Geen dekking",
                            bucket: 0,
                        }
                    },
                    ranking: 2,
                },
                {
                    insurer: 'ZieZo',
                    monthprice: 102.95,
                    difference: '\u20ac -28 per jaar duurder',
                    basic: {
                        name: 'ZieZo Basis',
                        type: 'Naturapolis',
                        covered_no_contract: '75% van de kosten wordt vergoed in geval er geen contract is.',
                    },
                    additionals: [
                        'Ziezo Aanvullend 1',
                        'Ziezo Tand 2'
                    ],
                    coverages: {
                        alternatieve_zorg: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        brillen_lenzen: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        fysiotherapie: {
                            text: "Je krijgt 6 behandelingen per jaar vergoed",
                            bucket: 3,
                        },
                        kraamzorg: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        spoedeisende_hulp_buitenland: {
                            text: "Je krijgt spoedeisende hulp over de hele wereld tot de kostprijs vergoed",
                            bucket: 2,
                        },
                        vaccinaties: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        tandarts: {
                            text: "Je krijgt 100% voor tandartscontroles en 75% voor overige tandheelkunde vergoed, met een maximum van €500,-",
                            bucket: 5,
                        },
                        orthodontie_tot_18_jaar: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        orthodontie_vanaf_18_jaar: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        mondzorg_ongevallen: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        spoedeisende_zorg_tanden: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        acne_behandeling: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        anticonceptie: {
                            text: 'Je krijgt anticonceptie bij gecontracteerde apotheken volledig vergoed, bij niet-gecontracteerde apotheken betaal je de eigen bijdrage ',
                            bucket: 4,
                        },
                        camouflagetherapie: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        cursussen_en_preventie: {
                            text: 'Geen dekking',
                            bucket: 0,
                        },
                        dieet_advisering: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        geneesmiddelen: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        herstellingsoord_zorghotel: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        homeopatische_middelen: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        hulpmiddelen_overig: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        logeerkosten: {
                            text: 'Geen dekking',
                            bucket: 0,
                        },
                        mantelzorg: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        nazorg_bij_kanker: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        ontharen_lasertherapie: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        sportmedisch_onderzoek: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        sterilisatie_man: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        sterilisatie_vrouw: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        steunzolen: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        stottertherapie: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        voetzorg_voetbehandeling: {
                            text: "Geen dekking",
                            bucket: 0,
                        }
                    },
                    ranking: 3,
                },
                {
                    insurer: 'FBTO',
                    monthprice: 103.96,
                    difference: '\u20ac -40 per jaar duurder',
                    basic: {
                        name: 'FBTO Naturapolis',
                        type: 'Naturapolis',
                        covered_no_contract: '75% van de kosten wordt vergoed in geval er geen contract is.',
                    },
                    additionals: [
                        'FBTO Spieren & Gewrichten 350',
                        'FBTO Tand 250',
                        'FBTO Tandongevallen',
                    ],
                    coverages: {
                        alternatieve_zorg: {
                            text: "Je krijgt €500,- aan alternatieve zorg vergoed (inclusief geneesmiddelen), met een maximum van €35,- per dag",
                            bucket: 6,
                        },
                        brillen_lenzen: {
                            text: "Je krijgt €100,- per jaar aan brillen of lenzen vergoed",
                            bucket: 3,
                        },
                        fysiotherapie: {
                            text: "Je krijgt €350,- aan behandelingen per jaar vergoed",
                            bucket: 3,
                        },
                        kraamzorg: {
                            text: "Je krijgt €250,- vergoed voor eigen bijdrage kraamzorg, lactatiekundig advies en een medisch kraampakket",
                            bucket: 2,
                        },
                        spoedeisende_hulp_buitenland: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        vaccinaties: {
                            text: "Je krijgt €250,- aan vaccinaties vergoed",
                            bucket: 3,
                        },
                        tandarts: {
                            text: "Je krijgt 100% van je tandartskosten vergoed, met een maximum van €250,-",
                            bucket: 3,
                        },
                        orthodontie_tot_18_jaar: {
                            text: "Je krijgt tot 18 jaar €750,- vergoed",
                            bucket: 3,
                        },
                        orthodontie_vanaf_18_jaar: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        mondzorg_ongevallen: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        spoedeisende_zorg_tanden: {
                            text: "je krijgt €500,- vergoed",
                            bucket: 1,
                        },
                        acne_behandeling: {
                            text: "Valt binnen €250,- voor Gezichtszorg en Orthodontie",
                            bucket: 5,
                        },
                        anticonceptie: {
                            text: 'Je krijgt €250,- vergoed voor anticonceptie, sterilisatie, een EHBO-cursus en plaswekkers',
                            bucket: 3,
                        },
                        camouflagetherapie: {
                            text: "Valt binnen €250,- voor Gezichtszorg en Orthodontie",
                            bucket: 3,
                        },
                        cursussen_en_preventie: {
                            text: 'Je krijgt €250,- vergoed voor anticonceptie, sterilisatie, een EHBO-cursus en plaswekkers',
                            bucket: 1,
                        },
                        dieet_advisering: {
                            text: "Valt onder budget Conditie & Fitheid à €150,-",
                            bucket: 3,
                        },
                        geneesmiddelen: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        herstellingsoord_zorghotel: {
                            text: "Palliatieve Zorg valt onder budget voor Zorg & Herstel à €750,-",
                            bucket: 2,
                        },
                        homeopatische_middelen: {
                            text: "Je krijgt €500,- aan alternatieve zorg vergoed (inclusief geneesmiddelen), met een maximum van €35,- per dag",
                            bucket: 5,
                        },
                        hulpmiddelen_overig: {
                            text: "Valt binnen €250,- voor Gezichtszorg en Orthodontie",
                            bucket: 1,
                        },
                        logeerkosten: {
                            text: 'Valt onder budget voor Zorg & Herstel à €750,-',
                            bucket: 3,
                        },
                        mantelzorg: {
                            text: "Valt onder budget voor Zorg & Herstel à €750,-",
                            bucket: 4,
                        },
                        nazorg_bij_kanker: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        ontharen_lasertherapie: {
                            text: "Valt binnen €250,- voor Gezichtszorg en Orthodontie",
                            bucket: 2,
                        },
                        sportmedisch_onderzoek: {
                            text: "Valt onder budget Conditie & Fitheid à €150,-",
                            bucket: 1,
                        },
                        sterilisatie_man: {
                            text: "Je krijgt €250,- vergoed voor anticonceptie, sterilisatie, een EHBO-cursus en plaswekkers",
                            bucket: 2,
                        },
                        sterilisatie_vrouw: {
                            text: "Je krijgt €250,- vergoed voor anticonceptie, sterilisatie, een EHBO-cursus en plaswekkers",
                            bucket: 1,
                        },
                        steunzolen: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        stottertherapie: {
                            text: "Geen dekking",
                            bucket: 0,
                        },
                        voetzorg_voetbehandeling: {
                            text: "Geen dekking",
                            bucket: 0,
                        }
                    },
                    ranking: 3,
                },
            ],
        }
    }
};

export const registerObserver = updateFunction => {
    debug('Registering health observer');
    if (typeof updateFunction === 'function') {
        observers.push(updateFunction);
    } else {
        throw new Error('Registry observer must be an update function');
    }
};

/**
 * Get a value from the registry. If an array of keys is given, the value is retrieved recursively.
 */
export const getFromRegistry = (keys, obj = getRegistry()) => {
    if (areNotNull(keys, obj)) {
        if (Array.isArray(keys) && keys.length > 0) {
            if (keys.length === 1) {
                // Only 1 key remaining, so return the value of the first key
                return getFromRegistry(keys[0], obj);
            }
            // Still some keys remaining, so get the value for the next key.
            let nextKey = keys[0];
            if (areNotNull(nextKey, obj[nextKey])) {
                let nextObject = obj[nextKey];
                keys.splice(0, 1);
                return getFromRegistry(keys, nextObject);
            }
            return null;
        } else if (isNotNull(obj[keys])) {
            // Keys is just a key name, so return the value if the value exists.
            return obj[keys];
        }
        return null;
    }
    warn('Registry does not contain an entry with key \'' + key + '\'.');
};

/**
 * Add an item to the registry.
 */
export const addToRegistry = (key, item) => {
    let reg = getRegistry();
    if (reg.hasOwnProperty(key)) {
        warn('Registry already contains a item for key \'' + key + '\', use updateRegistry for updating the value.');
    } else {
        reg[key] = item;
    }
    updateObservers();
};

/**
 * Update an existing entry in the registry.
 */
export const updateRegistry = (key, item) => {
    let reg = getRegistry();
    if (reg.hasOwnProperty(key)) {
        reg[key] = item;
    } else {
        addToRegistry(key, item);
    }
    updateObservers();
};

/**
 * Delete an entry from the registry.
 */
export const deleteFromRegistry = key => {
    let reg = getRegistry();
    if (reg.hasOwnProperty(key)) {
        delete reg[key];
    } else {
        warn('Registry does not contain an entry with key \'' + key + '\'.');
    }
    updateObservers();
};

/**
 * Execute the registered observers, with the updated registry.
 */
const updateObservers = () => {
    for (let i = 0; i < observers.length; i++) {
        observers[i](registry);
    }
};

/**
 * Lazy initialization.
 */
const getRegistry = () => {
    if (isNull(registry)) {
        registry = dummyRegistry;
    }
    return registry;
};