import './style/evaluationSummary.scss';

import React from 'react';
import PropTypes from 'prop-types';

import {deviceIsMobile} from 'glob-common-js/lib/utils';

import evaluationDefaultValues from "../misc/constants";
import {sendEvent} from "../../misc/healthAnalysisUtils";
import MaterialFactory from "../../../../material/materialFactory";
import {materialTypes} from "../../../../material/materialTypes";

export class EvaluationSummaryView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showEvaluationPopup: false,
        };
    }

    createEvaluation = (evaluation, key) => {
        let evaluationLabel = 'gray';
        let evaluationColorClassName = '';
        switch (key) {
            case 'basic':
                evaluationLabel = 'Basisverzekering';
                break;
            case 'additional':
                evaluationLabel = 'Aanvullend';
                break;
            case 'insurer':
                evaluationLabel = 'Prijs/kwaliteit';
                break;
            case 'risk':
                evaluationLabel = 'Eigen risico';
                break;
        }
        switch (evaluation) {
            case evaluationDefaultValues.SATISFIED:
                evaluationColorClassName = 'green';
                break;
            case evaluationDefaultValues.DISSATISFIED:
                evaluationColorClassName = 'red';
                break;
            case evaluationDefaultValues.UNSURE:
                evaluationColorClassName = 'gray';
                break;
            default:
                evaluationColorClassName = 'gray';
                break;
        }
        return (
            <div key={key} className={'evaluation ' + evaluationColorClassName}>
                <div className='evaluationContent'>
                    <span className='evaluationLabel'>{evaluationLabel}</span>
                    {evaluation === evaluationDefaultValues.SATISFIED ?
                        <span className='evaluationValue green'>Past bij mij</span> : null}
                    {evaluation === evaluationDefaultValues.DISSATISFIED ?
                        <span className='evaluationValue red'>Past niet bij mij</span> : null}
                    {evaluation === evaluationDefaultValues.UNSURE ?
                        <span className='evaluationValue gray'>Weet ik nog niet</span> : null}
                </div>
            </div>
        );
    };

    createDecisionColumn = (decision, key) => {
        return (
            <div className={'decisionColumn'} key={key}>
                <label className='decisionColumnLabel'>{decision.labelText}</label>
                <MaterialFactory componentType={materialTypes.RAISED_BUTTON} onClick={decision.onClick}
                                 className='decisionButton'>
                    {decision.buttonText}
                </MaterialFactory>
            </div>
        );
    };

    render = () => {
        let evaluation = this.props.evaluationObj;
        let actionButtons = [
            {
                buttonText: deviceIsMobile() ? 'Behouden' : 'Huidige behouden',
                labelText: 'Ik ben tevreden en wil mijn huidige verzekering behouden',
                onClick: () => {
                    this.props.setEvaluation("Huidige behouden");
                    sendEvent('Evaluatie samenvatting', 'Mijn huidige verzekering houden');
                    this.props.onNavigationClick("/zorganalyse/jouw-verzekering/dossier-inrichten");
                }
            },
            // {
            //     buttonText: 'Huidige aanpassen',
            //     labelText: 'Ik wil bij mijn huidige verzekeraar blijven maar wel aanpassingen doen',
            //     onClick: () => {
            //         this.props.setEvaluation("Huidige aanpassen");
            //         sendEvent('Evaluatie samenvatting', 'Een andere verzekering selecteren');
            //         this.props.onNavigationClick("/zorganalyse/jouw-verzekering/afsluiten");
            //     }
            // },
            {
                buttonText: deviceIsMobile() ? 'Wijzigen' : 'Dekking wijzigen',
                labelText: 'Ik wil mijn gewenste dekking wijzigen',
                onClick: () => {
                    this.props.setEvaluation("Dekking wijzigen");
                    sendEvent('Evaluatie samenvatting', 'Mijn huidige verzekering wijzigen');
                    this.props.onNavigationClick("/zorganalyse/aanpassen/dekking");
                }
            },
            {
                buttonText: 'Vergelijken',
                labelText: 'Ik wil verzekeringen die qua dekking vergelijkbaar zijn met mijn huidige verzekering bekijken',
                onClick: () => {
                    this.props.setEvaluation("Vergelijken");
                    sendEvent('Evaluatie samenvatting', 'Alternatieven vergelijken');
                    this.props.onCompareClick();
                }
            },
        ];

        let decisionContainerLabel = 'Beslis hier wat je volgende stap is:';
        // switch (this.props.userSatisfied) {
        //     case 'empty':
        //         decisionContainerLabel = 'Vul de evaluatie in om jouw zorgverzekering te beoordelen , of ga verder';
        //         break;
        //     case true:
        //         decisionContainerLabel = 'Je bent tevreden over je zorgverzekering. Wat wil je nu doen?';
        //         break;
        //     case false:
        //         decisionContainerLabel = 'Niet ieder onderdeel van je zorgverzekering past bij je situatie. Wat wil nu je doen?';
        //         break;
        // }

        return (
            <div className='evaluationSummaryPage'>
                <label className='evaluationContainerTitle'>Jouw oordeel over jouw zorgverzekering is al volgt:</label>
                <div className='evaluationContainer'>
                    {Object.keys(evaluation).map((key) => (this.createEvaluation(evaluation[key], key)))}
                </div>
                <div className='decisionContainer'>
                    <label className='decisionContainerLabel'>{decisionContainerLabel}</label>
                    <div className='decisionColumns'>
                        {actionButtons.map(this.createDecisionColumn)}
                    </div>
                </div>
            </div>);
    }
}

export default EvaluationSummaryView;

EvaluationSummaryView.propTypes = {
    evaluationObj: PropTypes.object.isRequired,
    onNavigationClick: PropTypes.func.isRequired,
    onCompareClick: PropTypes.func.isRequired,
};