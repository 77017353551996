import '../style/dossier.scss';

import React from 'react';
import PropTypes from 'prop-types';
import MaterialFactory from "../../../../material/materialFactory";
import {materialTypes} from "../../../../material/materialTypes";

export default class DossierView extends React.Component {
    constructor(props) {
        super(props);
    }

    createInfoBox = (icon, title, text) => {
        return (
            <div className="dossierInfoBox">
                <span className={'dossierInfoIcon ' + icon}/>
                <div className="dossierInfoTextBox">
                    <label className="dossierInfoTitle">{title}</label>
                    <p className='dossierInfoText'>{text}</p>
                </div>
            </div>
        )
    };

    render = () => {
        return (
            <div className="yourDossierView">
                <p className='dossierHeaderText'>Met een zorgverzekeringsdossier van ByDeHand kun jij:</p>
                <div className="dossierInfoContainer">
                    {this.createInfoBox('common-icon-files', 'Overzicht over jouw documenten', 'ByDeHand geeft aan ' +
                        'welke documenten in jouw zorgverzekeringsdossier horen, zodat je administratie compleet is.')}
                    {this.createInfoBox('common-icon-euro', 'Financieel overzicht', 'Vindt de maandelijkse kosten van ' +
                        'je nieuwe zorgverzekering terug in jouw financiële overzicht en zie direct wat voor invloed ' +
                        'dit op je balans heeft.')}
                    {this.createInfoBox('common-icon-contact', 'Contactgegevens', 'Vindt alle informatie over je ' +
                        'zorgverzekeraar terug op ByDeHand, zodat je kunt benaderen indien dit nodig is.')}
                </div>
                <div className="dossierButtonContainer">
                    <MaterialFactory componentType={materialTypes.RAISED_BUTTON} onClick={this.props.onCreateClick}>Maak
                        dossier</MaterialFactory>
                </div>
            </div>
        )
    };
}

DossierView.propTypes = {
    onCreateClick: PropTypes.func.isRequired,
};
