import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../../../redux/actions';

import StateComponent from "../../misc/stateComponent";
import {updateAccount, updateDaisyUser} from "../../../misc/requestSender";
import {addCustomEventListener, removeCustomEventListener} from "../../../misc/eventDispatcher";
import AddBirthday from "./addBirthday";
import {sendGaEvent} from "../../../common/js/ga";

const GA_CATEGORY = 'Leeftijd opgeven';

export class AddBirthdayController extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            active: false,
            user: null,
        };
        this.user = null;
        this.callback = null;
    }

    activate = ({user, callback}) => {
        this.callback = callback;
        this.changeState({active: true, user})
    };

    onSubmit = (birthday) => {
        sendGaEvent(GA_CATEGORY, 'Klik', 'Opslaan');
        if (isNotNull(this.state.user)) {
            this.changeState({loading: true});
            let user = this.state.user;
            user.dob = birthday.format('YYYY-MM-DDTHH:mm:ss');
            updateAccount({
                id: user.id,
                data: user,
            });
            updateDaisyUser({
                data: {
                    user_name: this.state.user.email,
                    birthday: birthday.format('YYYY-MM-DDTHH:mm'),
                },
                callback: (response) => {
                    this.props.actions.setSubscription(response.data.user);
                    this.changeState({active: false, user: null, loading: false});
                    this.callback(birthday)
                },
            });
        }
    };

    componentDidMount = () => {
        this.mount();
        addCustomEventListener('addBirthday', this.activate);
    };

    componentWillUnmount = () => {
        this.unMount();
        removeCustomEventListener('addBirthday', this.activate);
    };

    render = () => {
        return this.state.active ?
            <AddBirthday loading={this.state.loading} onSubmit={this.onSubmit} user={this.state.user}/> : null;
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch),
});

export default connect(null, mapDispatchToProps)(AddBirthdayController);