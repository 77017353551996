import '../style/insurer.scss';

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as Actions from '../../../../../redux/actions';
import {isNotNull} from 'glob-common-js/lib/utils';

import EvaluationInsurerView from './evaluationInsurerView';
import {getFromRegistry} from "../../misc/registry";
import {sendEvent} from "../../misc/healthAnalysisUtils";
import {sendPageView} from "BdhCommon/js/ga";
import {navigate} from "../../../../../misc/utils";

export class EvaluationInsurerController extends React.Component {
    constructor(props) {
        super(props);
        const insurer = getFromRegistry(['healthInsurance', 'evaluation', 'insurer']);
        this.json = {
            start: insurer.cheapest,
            end: insurer.mostExpensive,
            amount: Math.min(insurer.stripeCount, 13),
            better: {
                lowest: insurer.cheapest,
            },
            current: {
                price: insurer.price,
            },
            worse: {
                highest: insurer.mostExpensive,
            }
        };
    }

    addEvalToRedux = (decision) => {
        if (isNotNull(decision)) {
            sendEvent('Evaluatie Verzekeraar', decision);
            this.props.actions.setEvaluationInsurer(decision);
        }
    };

    navigateTo = (path) => {
        navigate(path, true);
    };

    componentDidMount = () => {
        sendPageView('/zorganalyse/evaluatie/jouw-verzekeraar');
    };

    render = () => {
        const insurer = getFromRegistry(['healthInsurance', 'evaluation', 'insurer']);
        const input = this.json;

        let graphWidth = 100;
        let currentPriceWidth = 10;
        let insurancePopup = 27;

        if (window.matchMedia("(max-width: 375px)").matches) {
            input.amount -= 1;
            currentPriceWidth = 20;
        }
        if (input.better.lowest > input.current.price) {
            input.better.lowest = input.current.price;
            input.start = input.current.price;
        }
        if (input.worse.highest < input.current.price) {
            input.worse.highest = input.current.price;
            input.end = input.current.price;
        }

        const gapSize = (input.end - input.start) / (input.amount - 3);
        let tdWidth = graphWidth / (input.amount - 1);

        let betterStart = ((input.better.lowest - (input.start - gapSize)) / gapSize) * tdWidth;
        let betterDifference = input.current.price - input.better.lowest;
        let betterLength = ((betterDifference / gapSize) * tdWidth) - (currentPriceWidth / 2);

        let worseDifference = input.worse.highest - input.current.price;
        let worseLength = ((worseDifference / gapSize) * tdWidth) - (currentPriceWidth / 2);

        let popupOffset = ((betterStart + betterLength + (currentPriceWidth / 2)) - (insurancePopup / 2));

        if (worseLength > (graphWidth - (currentPriceWidth + tdWidth + tdWidth))) {
            betterStart = (graphWidth - (currentPriceWidth + tdWidth + worseLength)) + 1;
            popupOffset += 1;
            worseLength -= 1;
        }

        return (
            <EvaluationInsurerView popupOffset={popupOffset} betterStart={betterStart} tdWidth={tdWidth}
                                   rulerAmount={input.amount} rulerStart={input.start} gapSize={gapSize}
                                   betterLength={betterLength} insurancePopup={insurancePopup} worseLength={worseLength}
                                   currentPriceWidth={currentPriceWidth} currentPrice={input.current.price}
                                   betterText={insurer.textGreen} worseText={insurer.textRed}
                                   pigText={insurer.textSavePig} setEval={this.addEvalToRedux}
                                   navigateTo={this.navigateTo}
                                   evaluationDecision={this.props.healthState.evaluation.insurer}
            />
        )
    }
}

const mapStateToProps = state => ({
    healthState: state.healthInsuranceAnalysis,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EvaluationInsurerController));