import './style/bdhPasswordInput.scss';

import React from 'react';
import PropTypes from 'prop-types';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import BdhInput from "../bdhInput";
import BdhContainedInput from "../bdhContainedInput";

export default class BdhPasswordInput extends React.Component {
    getOrnament = () => {
        const {ornaments, valueVisible, onToggleVisibility} = this.props;
        let ornament;
        if (valueVisible) ornament = <Visibility className='visibilityIcon'/>;
        else ornament = <VisibilityOff className='visibilityIcon'/>;
        return ornaments.concat([{element: ornament, onClick: onToggleVisibility}]);
    };

    getRenderProps = () => {
        const {valueVisible} = this.props, type = valueVisible ? 'text' : 'password';
        const propsCopy = Object.assign({}, this.props, {
            ornaments: this.getOrnament(),
            type,
        });
        delete propsCopy.valueVisible;
        delete propsCopy.onToggleVisibility;
        return propsCopy;
    };

    render = () => {
        const props = this.getRenderProps();
        if (this.props.variant === 'contained') return <BdhContainedInput {...props}/>;
        return <BdhInput {...props}/>;
    };
}

BdhPasswordInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    onToggleVisibility: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onKeyDown: PropTypes.func,
    onConfirmPassword: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    valueVisible: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    label: PropTypes.string,
    containerClass: PropTypes.string,
    labelClass: PropTypes.string,
    inputContainerClass: PropTypes.string,
    inputClass: PropTypes.string,
    id: PropTypes.string,
    key: PropTypes.string,
    withLabelTransition: PropTypes.bool,
    ornaments: PropTypes.arrayOf(PropTypes.shape({
        placement: PropTypes.oneOf(['left', 'right']),
        element: PropTypes.node.isRequired,
    })),
    variant: PropTypes.oneOf(['contained', 'explode']),
    error: PropTypes.string,
    fullWidth: PropTypes.bool,
};

BdhPasswordInput.defaultProps = {
    disabled: false,
    readOnly: false,
    containerClass: '',
    labelClass: '',
    inputContainerClass: '',
    inputClass: '',
    type: 'password',
    min: 0,
    max: 0,
    step: 0,
    withLabelTransition: true,
    ornaments: [],
    variant: 'explode',
    fullWidth: false,
};