import './style/dashboardContainer.scss';

import React from 'react';
import PropTypes from 'prop-types'
import {withRouter} from 'react-router-dom';
//import moment from 'moment';
import DocumentDashboardContainerController from "./document/documentDashboardContainerController";
import DossierDashboardContainerController from "./dossier/dossierDashboardContainerController";
//import FinancialDashboardController from "../financial/dashboard/financialDashboardController";
//import StartHighlights from "../start/highlights/startHighlights";
//import {getAuthParameter} from "../authentication/authenticationHelper";
//import AgendaController from "./agenda/agendaController";
import StateComponent from "../misc/stateComponent";
//import {timeFormats} from "../../common/components/datePicker";
import MaterialFactory from "../material/materialFactory";
import {materialTypes} from "../material/materialTypes";
import {getAllDossiers} from "../../misc/requestSender";
import {navigate} from "../../misc/utils";
import getDossierType from "../../common/data/mappings/dossierTypeMapping";
import {getChecklistType, getAvailableChecklistOfType} from "../../data/checklists/checklists";


import {
    addActionCode, deleteActionCode,
    getActionCode,
    getAllActionCodes,
    responseIsSuccess,
    updateActionCode, getDaisyUser
} from "../../misc/requestSender";

export const EXISTING = 0, NEW = 1;
export class DashboardContainerLifeWill extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            view: NEW,
            checklistLifeWill: [],
            action_Code: [],
        };
        actionCode: [];
    }

    onClickChecklist = checklist => () => {
        const {id, type} = checklist;
        navigate(type.navigation + `?id=${id}`, true);
    };

    loadChecklistsLifeWill = () => {
        getAllDossiers({
            queryParams: 'filter=type_id&value=' + getDossierType('checklist_travel').id,
            callback: dossiers => {
                if (isNotNull(dossiers)) {
                    dossiers.forEach(checklist => {
                      if (checklist.external_data.checklist.type==='LIFETESTAMENT') {
                        checklist.type = this.getChecklistType(checklist);
                        this.changeState({checklistLifeWill: checklist, view: this.getView(checklist)});
                      }
                    });
                };
                if (this.state.view=== NEW) {
                  //const newChecklist = getAvailableChecklistOfType('LIFETESTAMENT')
                  this.changeState({checklistLifeWill: getAvailableChecklistOfType('LIFETESTAMENT'), view: NEW});
                }
            }
        })
    };

    getChecklistType = checklist => {
        let type = null;
        if (pathIsNotNull(checklist, 'external_data.checklist')) {
            const checklistData = checklist.external_data.checklist;
            if (isNotNull(checklistData.type)) type = checklistData.type;
        }
        return getChecklistType(type);
    };


    getView = (checklists) => {
        return isNotNull(checklists) ? EXISTING : NEW;
    };

    renderLifeWillBox = () => {
        const {onNavigate} = this.props;
        return (
            <div className='dashboardBox'>
                <div className='headerContainer'>
                    <label className='headerTitle'>Levenstestament</label>
                </div>
                <div className='content'>
                    <label className='contentTitle'>Regel jouw levenstestament goed!</label>
                    <p className='contentText'>Al jouw belangrijke beslissingen vastleggen voor later</p>
                    <span className='common-icon-file-checklist icon'/>
                    {this.state.view===NEW ?
                      <MaterialFactory componentType={materialTypes.RAISED_BUTTON} onClick={this.onClickChecklist(this.state.checklistLifeWill)}>start
                        levenstestament</MaterialFactory> :
                      <MaterialFactory componentType={materialTypes.RAISED_BUTTON} onClick={this.onClickChecklist(this.state.checklistLifeWill)}>bekijk
                        levenstestament</MaterialFactory>
                    }
                </div>
            </div>
        )
    };

    renderTutorialBox = () => {
        const {onClickTutorial} = this.props, logoCAV = require('../../common/images/logoCAV.jpg');
        return (
            <div className='dashboardBox'>
                <div className='headerContainer'>
                    <label className='headerTitle'>Support</label>
                </div>
                <div className='content'>
                    <label className='contentTitle'>Hulp nodig bij het opstellen van je levenstestament?</label>
                    <p className='contentText'>Laat je helpen door de professionals van stichting CAV.</p>
                    <img src={logoCAV} alt='stichting CAV' className='logo'/>
                    <p className='contentText'>Telefoon: 088 - 8 228 228</p>
                    <p className='contentText'>E-mail: info@stichting-cav.nl</p>
                </div>
            </div>
        )
    };

    componentDidMount = () => {
        this.mount();
        this.loadChecklistsLifeWill();

    };

    componentWillUnmount = () => {
        this.unMount();
    };

    render = () => {
        return (
            <>
                <div id='dashboardPage' className='dashboardPage'>
                    <div className='dashboardColumn storeSmarter'>
                        <DocumentDashboardContainerController/>
                        <DossierDashboardContainerController/>
                    </div>
                    <div className='dashboardColumn saveMore'>
                        <div className='box dashboardSaveBox'>
                            {this.renderLifeWillBox()}
                        </div>
                        <div className='box'>
                            {this.renderTutorialBox()}
                        </div>
                    </div>
                </div>
            </>
        );
    };

}

export default withRouter(DashboardContainerLifeWill);

DashboardContainerLifeWill.propTypes = {
    onNavigate: PropTypes.func.isRequired,
    onClickTutorial: PropTypes.func.isRequired,
    user: PropTypes.object,
};
