import './style/travelContact.scss';

import React from 'react';
import PropTypes from 'prop-types';
import {applyMiddleware, createStore} from 'redux';
import {actions, combineForms, Control, Form} from 'react-redux-form';
import thunk from 'redux-thunk';

export default class TravelContactView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contacts: [],
        };

        this.initialState = {
            message: '',
            includeInfo: true,
        };

        this.store = createStore(combineForms({travelInfo: this.initialState}), applyMiddleware(thunk));
        this._isMounted = false;
    }

    changeState = stateChange => {
        if (this._isMounted) this.setState(stateChange);
    };

    reset = () => {
        this.store.dispatch(actions.reset('travelInfo'));
        this.changeState({contacts: []});
    };

    onSend = (model) => {
        let correctedModel = Object.assign({}, model, {
            contacts: this.state.contacts,
        });
        this.props.onSend(correctedModel);
    };

    addContact = () => {
        let address = this.mailInput.value;
        if (isNotNull(address)) {
            let contacts = this.state.contacts.slice();
            if (!contacts.includes(address)) {
                contacts.push(address);
                this.changeState({contacts});
                this.mailInput.value = '';
            }
        }
    };

    removeContact = (contact) => {
        let contacts = this.state.contacts.slice();
        if (isNotNull(contacts) && contacts.includes(contact)) {
            contacts.splice(contacts.indexOf(contact), 1);
            this.changeState({contacts});
        }
    };

    renderMails = () => {
        let addresses = this.state.contacts;
        if (isNotNull(addresses)) {
            return addresses.map((address, key) => (
                <div className='emailContainer' key={key}>
                    <label className='emailAddress'>{address}</label>
                    <span className='removeAddress common-icon-cross' onClick={() => {
                        this.removeContact(address);
                    }}/>
                </div>
            ))
        }
        return null;
    };

    onMailKeyDown = evt => {
        let keyCode = evt.keyCode;
        const enterCode = 13;
        if (keyCode === enterCode) {
            evt.preventDefault();
            this.addContact();
        }
    };

    toggleEnterListener = (add = true) => {
        const node = document.getElementById('travelContactMail');
        if (isNotNull(node)) {
            const listener = add ? node.addEventListener || node.attachEvent :
                node.removeEventListener || node.detachEvent;
            listener('keydown', this.onMailKeyDown);
        }
    };

    componentDidMount = () => {
        this._isMounted = true;
        this.toggleEnterListener();
    };

    componentWillUnmount = () => {
        this._isMounted = false;
        this.toggleEnterListener(false);
    };

    render = () => {
        return (
            <Form className='travelContact' store={this.store} model='travelInfo' onSubmit={this.onSend}>
                <div className='formBlock'>
                    <label className='blockTitle'>E-mailadressen</label>
                    <div className='addContainer'>
                        <input ref={refName => {
                            this.mailInput = refName
                        }} type='email' className='mailInput' id='travelContactMail'/>
                        <span className='common-icon-plus addSign' onClick={this.addContact}/>
                        {/*<button type='button' className='addContactButton' onClick={this.addContact}>Toevoegen</button>*/}
                    </div>
                    <div className='mailContainer'>
                        {this.renderMails()}
                    </div>
                </div>
                <div className='formBlock'>
                    <label className='blockTitle'>Persoonlijk bericht (optioneel)</label>
                    <Control.textarea model='.message' className='messageArea'/>
                </div>
                <div className='formBlock row'>
                    <label className='blockTitle'>Contact informatie</label>
                    <div className='column'>
                        <Control.checkbox model='.includeInfo' defaultValue={this.initialState.includeInfo}/>
                    </div>
                    <div className='column'>
                        <label className='columnTitle'>E-mailadres</label>
                        <label className='columnValue'>{this.props.email}</label>
                    </div>
                    {isNotNull(this.props.mobile) && <div className='column'>
                        <label className='columnTitle'>Mobiel</label>
                        <label className='columnValue'>{this.props.mobile}</label>
                    </div>}
                </div>
                <div className='formBlock end'>
                    <button type='submit' className='travelSubmit'>Versturen</button>
                </div>
                {this.props.children}
            </Form>
        )
    };
}

TravelContactView.propTypes = {
    onSend: PropTypes.func.isRequired,
    email: PropTypes.string.isRequired,
    mobile: PropTypes.string,
};