export const userConstants = {
    SET_USER: 'SET_USER',
    SET_FAMILY_MEMBER_ID: 'SET_FAMILY_MEMBER_ID',
    SET_REGISTRATION_MAIL: 'SET_REGISTRATION_MAIL',
    SET_SUBSCRIPTION: 'SET_SUBSCRIPTION',
    SET_GRANTED_USER: 'SET_GRANTED_USER',
};

export const dossierConstants = {
    SET_DOSSIER_TYPE: 'SET_DOSSIER_TYPE',
    SET_DOSSIER_ID: 'SET_DOSSIER_ID',
    SET_EDIT_DOSSIER: 'SET_EDIT_DOSSIER',
    SET_ADDED_DOSSIERS: 'SET_ADDED_DOSSIERS',
    SET_DOSSIER: 'SET_DOSSIER',
    SELECT_CATEGORY: 'SELECT_DOSSIER_CATEGORY',
    SELECT_SUBCATEGORY: 'SELECT_DOSSIER_SUBCATEGORY',
    SET_DOSSIER_DOCUMENT_TYPE: 'SET_DOSSIER_DOCUMENT_TYPE',
};

export const documentConstants = {
    SET_DOC_IDS: 'SET_DOC_IDS',
    SET_DOCTYPE: 'SET_DOCTYPE',
    SET_DOC_DISPLAY_LABEL: 'SET_DOC_DISPLAY_LABEL',
    SET_UPLOADED_DOC: 'SET_UPLOADED_DOC',
    SET_ADDED_DOCS: 'SET_ADDED_DOCS',
    SET_DOCUMENT_DETAIL_ID: 'SET_DOCUMENT_DETAIL_ID',
    SET_DOCUMENT_TO_LINK: 'SET_DOCUMENT_TO_LINK',
};

export const tenderConstants = {
    SET_TENDER_INFO: 'SET_TENDER_INFO',
};

export const miscConstants = {
    SET_CONFIRM_INFO: 'SET_CONFIRM_INFO',
    SHOW_LOGIN_POPUP: 'SHOW_LOGIN_POPUP',
    SET_EMAIL: 'SET_EMAIL',
    START_FLOW: 'START_FLOW',
};

export const healthConstants = {
    SET_CURRENT_INSURANCE: 'SET_CURRENT_INSURANCE',
    SET_EVALUATION: 'SET_EVALUATION',
    SET_BASIC_EVALUATION: 'SET_BASIC_EVALUATION',
    SET_ADDITIONAL_EVALUATION: 'SET_ADDITIONAL_EVALUATION',
    SET_INSURER_EVALUATION: 'SET_INSURER_EVALUATION',
    SET_RISK_EVALUATION: 'SET_RISK_EVALUATION',
    SET_SUMMARY_EVALUATION: 'SET_SUMMARY_EVALUATION',
    SET_EXPLANINGPOPUP_SHOWN: 'SET_EXPLANINGPOPUP_SHOWN',
    SET_RATING_SHOWN: 'SET_RATING_SHOWN',
    SHOW_FB: 'SHOW_FB',
};

export const appConstants = {
    SET_FILE_TO_UPLOAD: 'SET_FILE_TO_UPLOAD',
};

export const moduleConstants = {
    ACTIVE_MODULES: 'ACTIVE_MODULES',
    SELECT_MODULE: 'SELECT_MODULE',
};

export const appCallbackConstants = {
    RESET_AGENDA_ITEM: 'RESET_AGENDA_ITEM',
    RESET_DOCUMENT: 'RESET_DOCUMENT',
    ADD_AGENDA_ITEM: 'ADD_AGENDA_ITEM',
    EDIT_AGENDA_ITEM: 'EDIT_AGENDA_ITEM',
    ADD_DOCUMENT: 'ADD_DOCUMENT',
    SET_AGENDA_ITEM_TO_ADD: 'SET_AGENDA_ITEM_TO_ADD',
};

export const introductionConstants = {
    ACTIVATE: 'ACTIVATE',
    SET_AGENDA_TAB: 'SET_AGENDA_TAB',
};

export const financialConstants = {
    SET_FINANCIAL_DOSSIERS: 'SET_FINANCIAL_DOSSIERS',
    ADD_FINANCIAL_DOSSIER: 'ADD_FINANCIAL_DOSSIER',
    UPDATE_FINANCIAL_DOSSIER: 'UPDATE_FINANCIAL_DOSSIER',
    DELETE_FINANCIAL_DOSSIER: 'DELETE_FINANCIAL_DOSSIER',
    RESET_FINANCIAL_DOSSIERS: 'RESET_FINANCIAL_DOSSIERS',
};
