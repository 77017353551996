import './style/addTask.scss';

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {getListObject, toMoment} from 'glob-common-js/lib/utils';

import StateComponent from "../../../misc/stateComponent";
import MaterialFactory from "../../../material/materialFactory";
import {materialTypes} from "../../../material/materialTypes";
import {dispatchCustomEvent} from "../../../../misc/eventDispatcher";

let filterId = new Date().getTime();
export default class AddTaskView extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            template: '',
            sendDate: '0', // Minutes from NOW() or 'custom' for absolute time
            sendDateDiff: moment().format('YYYY-MM-DDTHH:mm:ss'),
            mergeVars: [],
            dryRun: true,
            filters: [],
            filterParams: {
                key: 'created_at',
                value: '',
                operator: '=',
            },
            dryRunData: null,
        }
    }

    onSubmit = () => {
        const {filters, dryRun, template, mergeVars, sendDate, sendDateDiff} = this.state;
        this.props.onSubmit({filters, dryRun, template, mergeVars, sendDate, sendDateDiff}, this.submitCallback);
    };

    onChangeFilter = key => evt => {
        const value = evt.target.value;
        const {filterParams} = this.state;
        let operator = filterParams.operator, newValue = filterParams.value, newKey = filterParams.key;
        if (key === 'key') {
            newKey = value;
            operator = '=';
            newValue = '';
        }
        if (key === 'operator') {
            operator = value;
        }
        if (key === 'value')
            newValue = value;
        this.changeState({
            filterParams: Object.assign({}, this.state.filterParams, {key: newKey, operator, value: newValue}),
        });
        this.resetDryRun();
    };

    onAddFilter = () => {
        const filters = this.state.filters.slice();
        filters.push(Object.assign({}, this.state.filterParams, {id: filterId++}));
        this.changeState({filters, filterParams: {key: 'created_at', value: '', operator: '='}});
        this.resetDryRun();
    };

    onSelectTemplate = evt => {
        this.changeState({template: evt.target.value});
        this.resetDryRun();
    };

    onSelectSendDate = evt => {
        this.changeState({sendDate: evt.target.value});
        this.resetDryRun();
    };

    onChangeSendDateDiff = isDate => date => {
        date = toMoment(date);
        if (isDate) {
            const oldDate = toMoment(this.state.sendDateDiff);
            date.hours(oldDate.hours());
            date.minutes(oldDate.minutes());
        }
        this.changeState({sendDateDiff: date.format('YYYY-MM-DDTHH:mm:ss')});
        this.resetDryRun();
    };

    onRemoveFilter = id => () => {
        this.changeState({filters: this.state.filters.filter(filter => filter.id !== id)});
    };

    resetDryRun = () => {
        this.changeState({dryRun: true});
    };

    submitCallback = (data, dryRun) => {
        if (dryRun)
            this.changeState({dryRunData: data, dryRun: false});
        else dispatchCustomEvent('openSnackbar', 'Mail wordt verstuurd');
    };

    getFilters = () => ([
        {value: 'created_at', label: 'Datum geregistreerd'},
        {value: 'id', label: 'ID'},
        {value: 'email', label: 'E-mailadres'},
        {value: 'username', label: 'Gebruikersnaam'},
        {value: 'status', label: 'Status'},
        {value: 'registered', label: 'Account geactiveerd'},
        {value: 'mail_subscribed', label: 'Ontvangt mail'},
        {value: 'type', label: 'Type'},
    ]);

    getOperatorItems = () => {
        const {filterParams} = this.state, filter = filterParams.key;
        let operators = [{value: '=', label: 'Is gelijk aan'}, {value: '!=', label: 'Is niet gelijk aan'}];

        if (filter === 'created_at')
            operators = operators.concat([
                {value: '>', label: 'Na'},
                {value: '<', label: 'Voor'}
            ]);
        return operators;
    };

    getAllOperatorItems = () => [
        {value: '=', label: 'Is gelijk aan'}, {value: '!=', label: 'Is niet gelijk aan'}, {value: '>', label: 'Na'},
        {value: '<', label: 'Voor'}
    ];

    getStatusItems = () => ([
        {value: 'active', label: 'Actief'},
        {value: 'first_login', label: 'Niet actief'},
        {value: 'inactive', label: 'Inactief'},
        {value: 'deleted', label: 'Verwijderd'},
    ]);

    getTrueFalse = () => ([
        {value: 1, label: 'Ja'},
        {value: 0, label: 'Nee'},
    ]);

    getFilterValue = filter => {
        const {key, value} = filter;
        switch (key) {
            case 'registered':
            case 'mail_subscribed':
                return value === 1 ? 'Ja' : 'Nee';
            case 'status':
                return getListObject(this.getStatusItems(), value, 'value').label;
            default:
                return value;
        }
    };

    getTemplates = () => {
        const {templates} = this.props;
        return templates.map(template => ({
            value: template.slug, label: template.name,
        }))
    };

    getSendDateItems = () => ([
        {value: '0', label: 'Direct'},
        {value: '15', label: 'Over een kwartier'},
        {value: '30', label: 'Over een half uur'},
        {value: '60', label: 'Over een uur'},
        {value: 'custom', label: 'Ander tijdstip'},
    ]);

    toggleExtraSpace = (toggleOn = true) => {
        const element = document.getElementsByTagName('main')[0];
        element.classList.toggle('wider', toggleOn);
    };

    renderFilterContainer = () => {
        const {filterParams, template, sendDate} = this.state;
        if (isNull(template) || isNull(sendDate)) return null;
        return (
            <div className='filterContainer'>
                <label className='filterContainerTitle'>Filters toevoegen</label>
                <div className='addFilterContainer'>
                    <MaterialFactory componentType={materialTypes.SELECT} items={this.getFilters()} label='Filtertype'
                                     onChange={this.onChangeFilter('key')} value={filterParams.key}/>
                    <MaterialFactory componentType={materialTypes.SELECT} items={this.getOperatorItems()} label='Regel'
                                     onChange={this.onChangeFilter('operator')} value={filterParams.operator}/>
                    {this.renderFilterField()}
                    <MaterialFactory componentType={materialTypes.RAISED_BUTTON} disabled={isNull(filterParams.value)}
                                     onClick={this.onAddFilter}>Toevoegen</MaterialFactory>
                </div>
                {this.renderFilters()}
            </div>
        )
    };

    renderFilters = () => {
        const {filters} = this.state;
        if (isNull(filters)) return null;
        return (
            <div className='addedFilters'>
                <label className='addedFiltersLabel'>Toegevoegde filters</label>
                <div className='addedFilterContainer head'>
                    <label className='filterValue label'>Filtertype</label>
                    <label className='filterValue operator'>Regel</label>
                    <label className='filterValue value'>Waarde</label>
                </div>
                {filters.map(filter => (
                    <div key={filter.id} className='addedFilterContainer'>
                        <label className='label'>
                            {getListObject(this.getFilters(), filter.key, 'value').label}
                        </label>
                        <label
                            className='operator'>{getListObject(this.getAllOperatorItems(), filter.operator,
                            'value').label}</label>
                        <label className='value'>{this.getFilterValue(filter)}</label>
                        <span className='common-icon-trash removeFilter' onClick={this.onRemoveFilter(filter.id)}/>
                    </div>
                ))}
            </div>
        )
    };

    renderFilterField = () => {
        const {filterParams} = this.state, filter = filterParams.key, value = filterParams.value;
        switch (filter) {
            case 'id':
            case 'email':
            case 'username':
            case 'type':
                return <MaterialFactory componentType={materialTypes.TEXT} value={value}
                                        onChange={this.onChangeFilter('value')} label='Waarde'/>;
            case 'created_at':
                return this.renderDateTime();
            case 'status':
                return <MaterialFactory componentType={materialTypes.SELECT} items={this.getStatusItems()}
                                        value={value} onChange={this.onChangeFilter('value')} label='Waarde'/>;
            case 'registered':
            case 'mail_subscribed':
                return <MaterialFactory componentType={materialTypes.SELECT} items={this.getTrueFalse()} value={value}
                                        onChange={this.onChangeFilter('value')} label='Waarde'/>
        }
    };

    renderDateTime = () => {
        const {filterParams} = this.state, value = filterParams.value;

        const onChange = isDate => date => {
            date = toMoment(date);
            if (isDate) {
                const oldDate = toMoment(value);
                date.hours(oldDate.hours());
                date.minutes(oldDate.minutes());
            }
            this.changeState({
                filterParams: Object.assign({}, this.state.filterParams,
                    {value: date.format('YYYY-MM-DDTHH:mm:ss')})
            })
        };
        return (
            <div className='filterDateTimeContainer'>
                <MaterialFactory componentType={materialTypes.DATE_PICKER} value={value} onChange={onChange(true)}
                                 label='Datum'/>
                <MaterialFactory componentType={materialTypes.TIME_PICKER} value={value} onChange={onChange(false)}
                                 label='Tijd' className='time'/>
            </div>
        )
    };

    renderTemplateContainer = () => {
        const {template, sendDate, sendDateDiff} = this.state;
        const templates = this.getTemplates();
        const value = isNotNull(template) ? template : isNotNull(templates) ? templates[0].value : '';
        return (
            <div className='templateContainer'>
                <div className='templateStep'>
                    <label className='templateStepLabel'>Selecteer een template</label>
                    <MaterialFactory componentType={materialTypes.SELECT} items={this.getTemplates()} value={value}
                                     onChange={this.onSelectTemplate}/>
                </div>
                {isNotNull(template) && (
                    <div className='templateStep'>
                        <label className='templateStepLabel'>Wanneer moet de mail gestuurd worden?</label>
                        <div className='sendDateContainer'>
                            <MaterialFactory componentType={materialTypes.SELECT} items={this.getSendDateItems()}
                                             value={sendDate} onChange={this.onSelectSendDate}/>
                            {sendDate === 'custom' &&
                            <div className='timeContainer'>




                                <MaterialFactory componentType={materialTypes.DATE_PICKER}
                                                 value={sendDateDiff} label='Datum'
                                                 onChange={this.onChangeSendDateDiff(true)}/>





                                <MaterialFactory componentType={materialTypes.TIME_PICKER}
                                                 value={sendDateDiff} label='Tijdstip'
                                                 onChange={this.onChangeSendDateDiff(false)}/>
                            </div>}
                        </div>
                    </div>
                )}
            </div>
        )
    };

    renderDryRun = () => {

    };

    renderFooter = () => {
        const {dryRun} = this.state;
        return (
            <div className='addTaskFooter'>
                <MaterialFactory componentType={materialTypes.RAISED_BUTTON} onClick={this.onSubmit}>
                    {dryRun ? 'Controleer' : 'Verzend'}</MaterialFactory>
            </div>
        )
    };

    componentDidMount = () => {
        this.mount();
        this.toggleExtraSpace();
    };

    componentWillReceiveProps = nextProps => {
        const templates = this.props.templates, nextTemplates = nextProps.templates;
        if (isNull(templates) && isNotNull(nextTemplates))
            this.changeState({template: nextTemplates[0].slug});
    };

    componentWillUnmount = () => {
        this.unMount();
        this.toggleExtraSpace(false);
    };

    render = () => {
        return (
            <div className='addMailTask'>
                <div className='settingsContainer'>
                    {this.renderTemplateContainer()}
                    {this.renderFilterContainer()}
                </div>
                {this.renderDryRun()}
                {this.renderFooter()}
            </div>
        )
    };
}

AddTaskView.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    templates: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        mergeVars: PropTypes.arrayOf(PropTypes.string).isRequired,
    })).isRequired,
};