import {actionConstants, actionTypeConstants} from '../constants/smartActionConstants';
import {timeItemTypes} from '../constants/timeItemConstants';

import {store} from "../../../startup";
import * as actions from '../../../redux/actions';
import getDocumentType from "./docTypeMapping";
import {triggerAction} from "../../../components/smartAction/smartActionManager";
import {navigate} from "../../../misc/utils";
import {sendGaEvent} from "../../js/ga";
import {NOW} from "../../../components/misc/constants";

const sendGa = (action, label) => {
    let module = store.getState().module.selectedModule;
    let moduleLabel = isNotNull(module) ? getDocumentType(module).label : 'Geen module bekend';
    sendGaEvent(moduleLabel, action, label);
};

const alertMapping = {
    passport_expires: {
        id: 'passport_expires',
        label: 'Let op: Paspoort loopt binnen een maand af',
        image: 'paspoorten.jpg',
        description: null,
        followUps:
            [
                {
                    label: 'Paspoort toevoegen',
                    icon: 'common-icon-document',
                    callback: () => {
                        sendGa('Alert FU: Paspoort toevoegen', 'je paspoort verloopt');
                        let documentType = getDocumentType('passport_personal');
                        store.dispatch(actions.appAddDocument({
                            name: 'passport_personal',
                            ...documentType
                        }, () => {
                            store.dispatch(actions.resetAppDocument());
                        }))
                    }
                },
                {
                    label: 'Meer info',
                    icon: 'common-icon-info',
                    callback: () => {
                        sendGa('Alert FU: Meer info', 'je paspoort verloopt');
                        window.externalLink('https://www.rijksoverheid.nl/onderwerpen/paspoort-en-identiteitskaart/' +
                            'vraag-en-antwoord/duur-geldigheid-paspoort-of-nederlandse-identiteitskaart');
                    }
                },
            ]
    },
    drivers_licence_expires: {
        id: 'drivers_licence_expires',
        label: 'Let op: Rijbewijs loopt binnen een maand af',
        image: 'rijbewijs.jpg',
        description: null,
        followUps: [
            {
                label: 'Rijbewijs toevoegen',
                icon: 'common-icon-document',
                callback: () => {
                    sendGa('Alert FU: Rijbewijs toevoegen', 'je rijbewijs verloopt');
                    let name = 'drivers_licence_personal';
                    let documentType = getDocumentType(name);
                    store.dispatch(actions.appAddDocument({
                        name,
                        ...documentType
                    }, () => {
                        store.dispatch(actions.resetAppDocument());
                    }))
                }
            },
            {
                label: 'Meer info',
                icon: 'common-icon-document',
                callback: () => {
                    sendGa('Alert FU: Meer info', 'je rijbewijs verloopt');
                    window.externalLink('https://www.rdw.nl/nrd/het-rijbewijs/geldigheid-rijbewijs');
                }
            }
        ]
    },
    id_expires: {
        id: 'id_expires',
        label: 'Let op: ID-kaart loopt binnen een maand af',
        image: 'identiteitskaart.jpg',
        description: null,
        followUps: [
            {
                label: 'ID-kaart toevoegen',
                icon: 'common-icon-document',
                callback: () => {
                    sendGa('Alert FU: ID-kaart toevoegen', 'je ID-kaart verloopt');
                    let name = 'id_card_personal';
                    let documentType = getDocumentType(name);
                    store.dispatch(actions.appAddDocument({
                        name,
                        ...documentType
                    }, () => {
                        store.dispatch(actions.resetAppDocument());
                    }))
                }
            },
            {
                label: 'Meer info',
                icon: 'common-icon-info',
                callback: () => {
                    sendGa('Alert FU: Meer info', 'je ID-kaart verloopt');
                    window.externalLink('https://www.rijksoverheid.nl/onderwerpen/paspoort-en-identiteitskaart/' +
                        'vraag-en-antwoord/duur-geldigheid-paspoort-of-nederlandse-identiteitskaart');
                }
            }
        ]
    },
    ov_expires: {
        id: 'ov_expires',
        label: 'Let op: OV-chipkaart loopt binnen een maand af',
        image: 'ovchipkaart.jpg',
        description: null,
        followUps: [
            {
                label: 'OV-chipkaart toevoegen',
                icon: 'common-icon-document',
                callback: () => {
                    sendGa('Alert FU: OV-chipkaart toevoegen', 'je OV-chipkaart verloopt');
                    let name = 'ov_chip_card_transport';
                    let documentType = getDocumentType(name);
                    store.dispatch(actions.appAddDocument({
                        name,
                        ...documentType
                    }, () => {
                        store.dispatch(actions.resetAppDocument());
                    }))
                }
            },
        ]
    },
    polis_expires: {
        label: 'Let op: Polis loopt binnen een maand af',
        image: 'polis.jpg',
        description: null,
        followUps: null,
    },
    subscription_expires: {
        label: 'Let op: Abonnement loopt binnen een maand af',
        image: 'subscription.jpg',
        description: null,
        followUps: null,
    },
    energy_contract_expires: {
        label: 'Let op: Energiecontract loopt over 2 maanden af',
        image: 'contract.jpg',
        description: null,
        followUps: null,
    },
};

export const tipMapping = {
    customer_cards: {
        label: 'Al jouw klantenkaarten handig bij elkaar?',
        image: 'klantenkaarten.jpg',
        description: 'Bijna iedere winkel heeft tegenwoordig wel een klantenpas. Voorkom een onoverzichtelijke ' +
        'portemonnee en upload je pasjes op ByDeHand!',
        followUps: [
            {
                label: 'Uploaden',
                icon: 'common-icon-document',
                callback: () => {
                    sendGa('Tip FU: Uploaden', 'Al jouw klantenkaarten handig bij elkaar?');
                    let name = 'customer_card_sport';
                    let documentType = getDocumentType(name);
                    store.dispatch(actions.appAddDocument({
                        name,
                        ...documentType
                    }, () => {
                        store.dispatch(actions.resetAppDocument());
                    }))
                }
            },
        ],
        bdhFunctionality: false,
    },
    passport_expires: {
        label: 'Laat je herinneren voordat je paspoort/identiteitsbewijs verloopt!',
        image: 'paspoorten.jpg',
        description: 'Je dient altijd een geldig legitimatiebewijs te hebben. Zorg er dus voor dat je je nieuwe paspoort' +
        ' of identiteitsbewijs op tijd aanvraagt!',
        followUps: [
            {
                label: 'Herinnering paspoort',
                icon: 'common-icon-calender',
                callback: () => {
                    sendGa('Tip FU: Herinnering paspoort', 'Je paspoort verloopt?');
                    triggerAction(actionConstants.AGENDA_PASPOORT1);
                }
            },
            {
                label: 'Herinnering ID-kaart',
                icon: 'common-icon-calender',
                callback: () => {
                    triggerAction(actionConstants.AGENDA_ID1);
                    sendGa('Tip FU: Herinnering ID-kaart', 'Je ID-kaart verloopt?');
                }
            },
            {
                label: 'Paspoort toevoegen',
                icon: 'common-icon-document',
                callback: () => {
                    sendGa('Tip FU: Paspoort toevoegen', 'je paspoort verloopt');
                    let documentType = getDocumentType('passport_personal');
                    store.dispatch(actions.appAddDocument({
                        name: 'passport_personal',
                        ...documentType
                    }, () => {
                        store.dispatch(actions.resetAppDocument());
                    }))
                }
            },
            {
                label: 'ID-kaart toevoegen',
                icon: 'common-icon-document',
                callback: () => {
                    sendGa('Tip FU: ID-kaart toevoegen', 'je ID-kaart verloopt');
                    let name = 'id_card_personal';
                    let documentType = getDocumentType(name);
                    store.dispatch(actions.appAddDocument({
                        name,
                        ...documentType
                    }, () => {
                        store.dispatch(actions.resetAppDocument());
                    }))
                }
            },
        ],
        bdhFunctionality: false,
    },
    remind_inschrijven: {
        label: 'Wees op tijd voor je herinschrijving',
        image: 'studielink.png',
        description: 'Bij de meeste onderwijsinstellingen moet jij je ieder jaar opnieuw inschrijven. Mis deze deadline ' +
        'niet en laat je door ons herinnerd worden.',
        followUps: [
            {
                label: 'Herinnering herinschrijving',
                icon: 'common-icon-calender',
                callback: () => {
                    sendGa('Tip FU: Herinnering herinschrijving', 'Schrijf je op tijd weer in');
                    triggerAction(actionConstants.AGENDA_HERINSCHRIJVING1);
                }
            }
        ],
        bdhFunctionality: false,
    },
    stop_ov: {
        label: 'Zet je studentenreisproduct op tijd stop!',
        image: 'ovchipkaart.jpg',
        description: 'Wist je dat je hoge boetes kan krijgen als je jouw studentenreisproduct niet op tijd stop zet? Dit' +
        ' kan oplopen tot zelfs €194,- per maand!',
        followUps: [
            {
                label: 'Meer info',
                icon: 'common-icon-info',
                callback: () => {
                    sendGa('Tip FU: Meer info', 'Zet je studentenreisproduct op tijd stop');
                    window.externalLink('https://www.leuker.nl/studentenreisproduct/');
                }
            },
            {
                label: 'Herinnering OV-boete',
                icon: 'common-icon-calender',
                callback: () => {
                    sendGa('Tip FU: Herinnering OV-boete', 'Zet je studentenreisproduct op tijd stop');
                    triggerAction(actionConstants.AGENDA_OV_STUDENT1);
                }
            }
        ],
        bdhFunctionality: false,
    },
    organize_gradelists: {
        label: 'Heb jij al je diploma\'s en cijferlijsten bij elkaar?',
        image: 'diploma.jpg',
        description: 'Vaak heb je wanneer je gaat solliciteren of een vervolgstudie kiest je al behaalde diploma\'s en ' +
        'cijferlijsten nodig. Grote kans dat die ergens verdwaald in een kast liggen. Haal ze te voorschijn en upload ze' +
        ' op ByDeHand',
        followUps: [
            {
                label: 'Diploma toevoegen',
                icon: 'common-icon-document',
                callback: () => {
                    sendGa('Tip FU: Diploma toevoegen', 'Heb jij al je diploma\'s en cijferlijsten bij elkaar?');
                    let name = 'diploma_study';
                    let documentType = getDocumentType(name);
                    store.dispatch(actions.appAddDocument({
                        name,
                        ...documentType
                    }, () => {
                        store.dispatch(actions.resetAppDocument());
                    }))
                }
            },
            {
                label: 'Dossier maken',
                icon: 'common-icon-folder-open2',
                callback: () => {
                    sendGa('Tip FU: Dossier maken', 'Heb jij al je diploma\'s en cijferlijsten bij elkaar?');
                    store.dispatch(actions.selectModule('study'));
                    navigate('/dossier-overzicht');
                }
            }
        ],
        bdhFunctionality: false,
    },
    check_kwijtschelding: {
        label: 'Check kwijtschelding afvalstoffenheffing',
        image: 'prullenbak.jpg',
        description: 'Als je jong bent heb je vaak nog een wat lager salaris. Misschien maak je wel aanspraak op ' +
        'kwijtschelding van belastingen',
        followUps: [
            {
                label: 'Analyseer',
                icon: 'common-icon-bar-magnifier',
                callback: () => {
                    sendGa('Tip FU: Analyseer', 'Check kwijtschelding afvalstoffenheffing');
                    window.externalLink('https://www.leuker.nl/kwijtschelding/');
                }
            }
        ],
        bdhFunctionality: false,
    },
    prepare_job_interview: {
        label: 'Uitgenodigd voor een sollicitatiegesprek, en nu?',
        image: 'sollicitatiegesprek.jpg',
        description: 'Een sollicitatiegesprek kan heel spannend zijn, dus het is belangrijk dat je je goed voorbereidt!',
        followUps: [
            {
                label: 'Meer info',
                icon: 'common-icon-info',
                callback: () => {
                    sendGa('Tip FU: Meer info', 'Uitgenodigd voor een sollicitatiegesprek, en nu?');
                    window.externalLink('https://www.leuker.nl/blog/sollicitatiegesprek/');
                }
            }
        ],
        bdhFunctionality: false,
    },
    energy_water_provider: {
        label: 'Heb jij de meest voordelige energie/waterleverancier?',
        image: 'stopcontact.jpg',
        description: 'Als je op jezelf gaat wonen, is de kans groot dat je ook energie moet regelen. Het uitzoeken van ' +
        'deze contracten is veel werk en best ingewikkeld, daarom leggen we je hier helder uit waar je op moet letten. ' +
        'Maak meteen een dossier aan, en vergeet ook je waterrekeningen niet!',
        followUps: [
            {
                label: 'Meer info energie',
                icon: 'common-icon-info',
                callback: () => {
                    sendGa('Tip FU: Meer info', 'Heb jij de meest voordelige energie/waterleverancier?');
                    window.externalLink('https://www.leuker.nl/energie/#variabeltarief');
                }
            },
            {
                label: 'Dossier maken',
                icon: 'common-icon-folder-open2',
                callback: () => {
                    sendGa('Tip FU: Dossier maken', 'Heb jij de meest voordelige energie/waterleverancier?');
                    store.dispatch(actions.selectModule('out_house'));
                    navigate('/dossier-overzicht');
                }
            }
        ],
        bdhFunctionality: false,
    },
    check_subscriptions: {
        label: 'Weet jij tot wanneer je abonnementen en polissen lopen?',
        image: 'polis.jpg',
        description: 'Het is prettig om te weten hoe lang jij nog vastzit aan je abonnementen, of juist hoe lang je nog ' +
        'gedekt bent door een verzekeringspolis. Wij waarschuwen je als een abonnement of polis bijna afloopt!',
        followUps: [
            {
                label: 'Herinnering abonnement',
                icon: 'common-icon-calender',
                callback: () => {
                    sendGa('Tip FU: Herinnering abonnement', 'Weet jij tot wanneer je abonnementen en polissen lopen?');
                    triggerAction(actionConstants.AGENDA_ABONNEMENT1);
                }
            },
            {
                label: 'Herinnering verzekering',
                icon: 'common-icon-calender',
                callback: () => {
                    sendGa('Tip FU: Herinnering verzekering', 'Weet jij tot wanneer je abonnementen en polissen lopen?');
                    triggerAction(actionConstants.AGENDA_POLISGELD1);
                }
            },
        ],
        bdhFunctionality: false,
    },
    use_warranty: {
        label: 'Je nieuwe dure aankoop al binnen een jaar defect? Maak gebruik van je garantie!',
        image: 'bon.jpg',
        description: 'Vaak heb je een aankoopbon nodig om aanspraak te maken op garantie wanneer bijvoorbeeld jouw ' +
        'telefoon kapot is. Raak ze niet kwijt en upload je garantiebewijzen op ByDeHand.',
        followUps: [
            {
                label: 'Herinnering garantie',
                icon: 'common-icon-calender',
                callback: () => {
                    sendGa('Tip FU: Herinnering garantie', 'Je nieuwe dure aankoop al binnen een jaar kapot? Maak gebruik van je garantie!');
                    triggerAction(actionConstants.AGENDA_GARANTIE1);
                }
            },
            {
                label: 'Aankoopbon toevoegen',
                icon: 'common-icon-document',
                callback: () => {
                    sendGa('Tip FU: Aankoopbon toevoegen', 'Je nieuwe dure aankoop al binnen een jaar kapot? Maak gebruik van je garantie!');
                    let name = 'sales_receipt';
                    let documentType = getDocumentType(name);
                    store.dispatch(actions.appAddDocument({
                        name,
                        ...documentType
                    }, () => {
                        store.dispatch(actions.resetAppDocument());
                    }))
                }
            }
        ],
        bdhFunctionality: false,
    },
    check_incasso: {
        label: 'Nooit meer onverwachte incasso\'s',
        image: 'incasso.jpg',
        description: 'Je betaalt waarschijnlijk maandelijks je energie, kabel, en waterkosten. Hou jezelf op de hoogte ' +
        'over de data van deze afschrijvingen.',
        followUps: [
            {
                label: 'Herinnering betaling',
                icon: 'common-icon-calender',
                callback: () => {
                    sendGa('Tip FU: Herinnering betaling', 'Nooit meer onverwachte incasso\'s');
                    triggerAction(actionConstants.AGENDA_BETALING);
                }
            }
        ],
        bdhFunctionality: false,
    },
    check_huurtoeslag: {
        label: 'Check of je recht hebt op huurtoeslag',
        image: 'huurtoeslag.jpg',
        description: 'Het huren van een kamer of appartement is hartstikke duur. Gelukkig word je in sommige gevallen ' +
        'geholpen door de overheid!',
        followUps: [
            {
                label: 'Analyseer',
                icon: 'common-icon-bar-magnifier',
                callback: () => {
                    sendGa('Tip FU: Analyseer', 'Check of je recht hebt op huurtoeslag');
                    window.externalLink('https://www.leuker.nl/huurtoeslag');
                }
            }
        ],
        bdhFunctionality: false,
    },
    op_kamers: {
        label: 'Op kamers wonen? Dat kan aardig wat kosten.',
        image: 'studentenhuis.jpg',
        description: 'Je gaat het ouderlijk huis verlaten. Het begin van een hele leuke tijd, maar wel met grote ' +
        'financiële gevolgen.',
        followUps: [
            {
                label: 'Meer info',
                icon: 'common-icon-info',
                callback: () => {
                    sendGa('Tip FU: Meer info', 'Op kamers wonen? Dat kan aardig wat kosten.');
                    window.externalLink('https://www.leuker.nl/blog/uit-huis-besparen-maar/');
                }
            }
        ],
        bdhFunctionality: false,
    },
    docs_woning: {
        label: 'Al je documenten bij elkaar in je woningdossier',
        image: 'huurhuis.jpg',
        description: 'Het is handig om alle documenten die te maken hebben met jouw woning bij elkaar te hebben. ' +
        'Upload ze op ByDeHand!',
        followUps: [
            {
                label: 'Dossier aanmaken',
                icon: 'common-icon-folder-open2',
                callback: () => {
                    sendGa('Tip FU: Dossier aanmaken', 'Al je documenten bij elkaar in je woningdossier');
                    navigate('/dossier-overzicht');

                }
            }
        ],
        bdhFunctionality: false,
    },
    check_health_insurance: {
        label: 'Controleer jouw zorgverzekering op prijs en dekking',
        image: 'ziekenhuis.jpg',
        description: 'Voorkom dat je geld betaalt voor zorg die je helemaal niet nodig hebt. Doe de zorganalyse!',
        followUps: [
            // {
            //     label: 'Analyseer',
            //     icon: 'common-icon-bar-magnifier',
            //     callback: () => {
            //         sendGa('Tip FU: Analyseer', 'Controleer jouw zorgverzekering op prijs en dekking');
            //         navigate('/zorganalyse');
            //     }
            // }
        ],
        bdhFunctionality: true,
    },
    check_zorgtoeslag: {
        label: 'Heb jij je zorgtoeslag al aangevraagd?',
        image: 'zorgtoeslag.jpg',
        description: 'Zorgtoeslag is een maandelijkse financiële bijdrage van de overheid waarmee je je zorgverzekering ' +
        '(deels) kunt betalen. Check of jij aan de voorwaarden voldoet en vraag eenvoudig, snel en zonder DigiD je ' +
        'toeslag aan!',
        followUps: [
            {
                label: 'Analyseer',
                icon: 'common-icon-bar-magnifier',
                callback: () => {
                    sendGa('Tip FU: Analyseer', 'Heb jij je zorgtoeslag al aangevraagd?');
                    window.externalLink('https://www.leuker.nl/zorgtoeslag-aanvragen/');
                }
            }
        ],
        bdhFunctionality: false,
    },
    pay_periodically: {
        label: 'Zorgkosten gemaakt? Betaal in termijnen!',
        image: 'zorgkosten.jpg',
        description: 'Als je zorgkosten moet betalen kan dat nogal een aanslag op je portemonnee zijn. Gelukkig kan je ' +
        'bij de meeste zorgverzekeraars gespreid betalen!',
        followUps: null,
        bdhFunctionality: false,
    },
    check_travel_insurance: {
        label: 'Veilig en zonder zorgen op reis? Doe de reisverzekeringscheck!',
        image: 'vakantie.png',
        description: 'Wist je dat een reisverzekering vaak kosten dekt die je al hebt verzekerd? Zo kunnen je medische ' +
        'kosten in het buitenland al gedekt zijn door je zorgverzekering.',
        followUps: [
            {
                label: 'Analyseer',
                icon: 'common-icon-bar-magnifier',
                callback: () => {
                    sendGa('Tip FU: Analyseer', 'Veilig en zonder zorgen op reis? Doe de reisverzekeringscheck!');
                    window.open('https://www.bydehand.com/heb-jij-een-goede-reisverzekering/');
                }
            }
        ],
        bdhFunctionality: true,
    },
    overview_subscriptions: {
        label: 'Heb jij nog een overzicht van al je abonnementen en lidmaatschappen?',
        image: 'efteling.jpeg',
        description: 'Museumkaart, sportschoolpas, of je abonnement voor de Efteling? Behoud het overzicht en upload je ' +
        'pasjes op ByDeHand. Laat je ook door ons herinneren wanneer je abonnementen aflopen.',
        followUps: [
            {
                label: 'Document uploaden',
                icon: 'common-icon-document',
                callback: () => {
                    sendGa('Tip FU: Document uploaden', 'Heb jij nog een overzicht van al je abonnementen en lidmaatschappen?');
                    let name = 'subscription_details';
                    let documentType = getDocumentType(name);
                    store.dispatch(actions.appAddDocument({
                        name,
                        ...documentType
                    }, () => {
                        store.dispatch(actions.resetAppDocument());
                    }))
                }
            },
            {
                label: 'Herinnering abonnement',
                icon: 'common-icon-calender',
                callback: () => {
                    sendGa('Tip FU: Herinnering abonnement', 'Heb jij nog een overzicht van al je abonnementen en lidmaatschappen?');
                    triggerAction(actionConstants.AGENDA_ABONNEMENTVT1);
                }
            }
        ],
        bdhFunctionality: false,
    }
};

export const smartActionMapping = [
        {
            code: actionConstants.AGENDA_PASPOORT1,
            image: 'paspoorten.jpg',
            actions: [
                {
                    type: actionTypeConstants.ADD_AGENDAITEM,
                    config: {
                        label: 'Paspoort verlopen',
                        description: 'Op deze datum verloopt je paspoort',
                        type: timeItemTypes.reminder,
                        startDate: NOW(), // TODO use document info
                        endDate: NOW(), // TODO use document info
                        reminder: {
                            time: [1, 'months'],
                            email: true,
                            alerts: [
                                alertMapping.passport_expires,
                            ],
                        },
                    }
                }
            ]
        },
        {
            code: actionConstants.AGENDA_RIJBEWIJS1,
            image: 'rijbewijs.jpg',
            actions:
                [
                    {
                        type: actionTypeConstants.ADD_AGENDAITEM,
                        config: {
                            label: 'Rijbewijs verlopen',
                            description: 'Op deze datum verloopt je rijbewijs',
                            type: timeItemTypes.reminder,
                            startDate: NOW(), // TODO use document info
                            endDate: NOW(), // TODO use document info
                            reminder: {
                                time: [1, 'months'],
                                alerts: [
                                    alertMapping.drivers_licence_expires,
                                ],
                                email: true,
                            }
                        }
                    }
                ]
        },
        {
            code: actionConstants.AGENDA_ID1,
            image:
                'identiteitskaart.jpg',
            actions:
                [
                    {
                        type: actionTypeConstants.ADD_AGENDAITEM,
                        config: {
                            label: 'ID-kaart verlopen',
                            description: 'Op deze datum verloopt je ID-kaart',
                            type: timeItemTypes.reminder,
                            startDate: NOW(), // TODO use document info
                            endDate: NOW(), // TODO use document info
                            reminder: {
                                time: [1, 'months'],
                                alerts: [
                                    alertMapping.id_expires,
                                ],
                                email: true,
                            }
                        }
                    }
                ]
        },
        {
            code: actionConstants.AGENDA_OV1,
            image:
                'ovchipkaart.jpg',
            actions:
                [
                    {
                        type: actionTypeConstants.ADD_AGENDAITEM,
                        config: {
                            label: 'OV-chipkaart verlopen',
                            description: 'Op deze datum verloopt je OV-chipkaart',
                            type: timeItemTypes.reminder,
                            startDate: NOW(), // TODO use document info
                            endDate: NOW(), // TODO use document info
                            reminder: {
                                time: [1, 'months'],
                                alerts: [
                                    alertMapping.ov_expires,
                                ],
                                email: true,
                            }
                        }
                    }
                ]
        },
        {
            code: actionConstants.AGENDA_TENTAMEN1,
            image:
                'tentamen.jpg',
            actions:
                [
                    {
                        type: actionTypeConstants.ADD_AGENDAITEM,
                        config: {
                            label: 'Tentamen/deadline',
                            description: 'Op deze datum heb je een tentamen/deadline',
                            type: timeItemTypes.deadline,
                            startDate: NOW(),
                            endDate: NOW(),
                            reminder: null,
                        }
                    }
                ]
        },
        {
            code: actionConstants.AGENDA_INSCHRIJVING1,
            image:
                'tentamen.jpg',
            actions:
                [
                    {
                        type: actionTypeConstants.ADD_AGENDAITEM,
                        config: {
                            label: 'Inschrijving vakken',
                            description: 'Dit is de uiterste dag dat jij je nog kan inschrijven voor je vakken',
                            type: timeItemTypes.deadline,
                            startDate: NOW(),
                            endDate: NOW(),
                            reminder: null,
                        }
                    }
                ]
        },
        {
            code: actionConstants.AGENDA_HERINSCHRIJVING1,
            image:
                'tentamen.jpg',
            actions:
                [
                    {
                        type: actionTypeConstants.ADD_AGENDAITEM,
                        config: {
                            label: 'Herinschrijving school',
                            description: 'Dit is de uiterste dag dat jij je weer kan inschrijven voor je opleiding',
                            type: timeItemTypes.deadline,
                            startDate: NOW(),
                            endDate: NOW(),
                            reminder: null,
                        }
                    }
                ]
        },
        {
            code: actionConstants.AGENDA_PINPAS1,
            image:
                'pinpas.jpg',
            actions:
                [
                    {
                        type: actionTypeConstants.ADD_AGENDAITEM,
                        config: {
                            label: 'Pinpas verlopen',
                            description: 'Op deze datum verloopt je pinpas',
                            type: timeItemTypes.reminder,
                            startDate: NOW(), // TODO use document info
                            endDate: NOW(), // TODO use document info
                            reminder: null,
                        }
                    }
                ]
        },
        {
            code: actionConstants.AGENDA_CREDITCARD1,
            image:
                'creditcard.jpg',
            actions:
                [
                    {
                        type: actionTypeConstants.ADD_AGENDAITEM,
                        config: {
                            label: 'Creditcard verlopen',
                            description: 'Op deze datum verloopt je creditcard',
                            type: timeItemTypes.reminder,
                            startDate: NOW(), // TODO use document info
                            endDate: NOW(), // TODO use document info
                            reminder: null,
                        }
                    }
                ]
        },
        {
            code: actionConstants.AGENDA_MEDISCH1,
            image:
                'medischebehandeling.jpg',
            actions:
                [
                    {
                        type: actionTypeConstants.ADD_AGENDAITEM,
                        config: {
                            label: 'Medische behandeling',
                            description: 'Op deze datum heb je een medische behandeling bij...',
                            type: timeItemTypes.meeting,
                            startDate: NOW(),
                            endDate: NOW(),
                            reminder: null,
                        }
                    }
                ],
        },
        {
            code: actionConstants.AGENDA_AFSCHRIJVING1,
            image:
                'zorgtoeslag.jpg',
            actions:
                [
                    {
                        type: actionTypeConstants.ADD_AGENDAITEM,
                        config: {
                            label: 'Afschrijving zorgpremie',
                            description: 'Op deze datum wordt je zorgpremie afgeschreven',
                            type: timeItemTypes.reminder,
                            startDate: NOW(),
                            endDate: NOW(),
                            reminder: null,
                        }
                    }
                ]
        },
        {
            code: actionConstants.AGENDA_HUISTAAK1,
            image:
                'huistaken.jpg',
            actions:
                [
                    {
                        type: actionTypeConstants.ADD_AGENDAITEM,
                        config: {
                            label: 'Huistaak',
                            description: 'Op deze datum moet je je huistaak gedaan hebben',
                            type: timeItemTypes.reminder,
                            startDate: NOW(),
                            endDate: NOW(),
                            reminder: null,
                        }
                    }
                ]
        },
        {
            code: actionConstants.AGENDA_GARANTIE1,
            image:
                'bon.jpg',
            actions:
                [
                    {
                        type: actionTypeConstants.ADD_AGENDAITEM,
                        config: {
                            label: 'Garantie verloopt',
                            description: 'Op deze datum verloopt je garantie van...',
                            type: timeItemTypes.reminder,
                            startDate: NOW(), // TODO probably use document data
                            endDate: NOW(), // TODO probably use document data
                            reminder: null,
                        }
                    }
                ]
        },
        {
            code: actionConstants.AGENDA_OV_STUDENT1,
            image:
                'ovchipkaart.jpg',
            actions:
                [
                    {
                        type: actionTypeConstants.ADD_AGENDAITEM,
                        config: {
                            label: 'OV-studentenkaart stopzetten',
                            description: 'Dit is de laatste dag dat jij zonder boete je studentenreisproduct kan gebruiken',
                            type: timeItemTypes.deadline,
                            startDate: NOW(), // TODO use document info
                            endDate: NOW(), // TODO use document info
                            reminder: {
                                time: [1, 'months'],
                                alerts: [
                                    alertMapping.ov_expires,
                                ],
                                email: true,
                            }
                        }
                    }
                ]
        },
        {
            code: actionConstants.AGENDA_POLISGELD1,
            image:
                'polis.jpg',
            actions:
                [
                    {
                        type: actionTypeConstants.ADD_AGENDAITEM,
                        config: {
                            label: 'Polis afgelopen',
                            description: 'Op deze datum is je polis afgelopen van...',
                            type: timeItemTypes.reminder,
                            startDate: NOW(), // TODO use document info
                            endDate: NOW(), // TODO use document info
                            reminder: {
                                time: [1, 'months'],
                                alerts: [
                                    alertMapping.polis_expires,
                                ],
                                email: true,
                            }
                        }
                    }
                ]
        },
        {
            code: actionConstants.AGENDA_ABONNEMENT1,
            image: 'subscription.jpg',
            actions:
                [
                    {
                        type: actionTypeConstants.ADD_AGENDAITEM,
                        config: {
                            label: 'Abonnement verlopen',
                            description: 'Op deze datum verloopt je abonnement van...',
                            type: timeItemTypes.reminder,
                            startDate: NOW(), // TODO use document info
                            endDate: NOW(), // TODO use document info
                            reminder: {
                                time: [1, 'months'],
                                alerts: [
                                    alertMapping.subscription_expires,
                                ],
                                email: true,
                            }
                        }
                    }
                ]
        },
        {
            code: actionConstants.AGENDA_ENERGIECONTRACT1,
            image: 'contract.jpg',
            actions:
                [
                    {
                        type: actionTypeConstants.ADD_AGENDAITEM,
                        config: {
                            label: 'Energiecontract afgelopen',
                            description: 'Op deze datum verloopt je energiecontract',
                            type: timeItemTypes.reminder,
                            startDate: NOW(), // TODO use document info
                            endDate: NOW(), // TODO use document info
                            reminder: {
                                time: [-2, 'months'],
                                alerts: [
                                    alertMapping.energy_contract_expires,
                                ],
                                email: true,
                            }
                        }
                    }
                ]
        },
        {
            code: actionConstants.AGENDA_POLISINBOEDEL1,
            image: 'polis.jpg',
            actions:
                [
                    {
                        type: actionTypeConstants.ADD_AGENDAITEM,
                        config: {
                            label: 'Polis verloopt',
                            description: 'Op deze datum verloopt je polis van...',
                            type: timeItemTypes.reminder,
                            startDate: NOW(), // TODO use document info
                            endDate: NOW(), // TODO use document info
                            reminder: {
                                time: [1, 'months'],
                                alerts: [
                                    alertMapping.polis_expires,
                                ],
                                email: true,
                            }
                        }
                    }
                ]
        },
        {
            code: actionConstants.AGENDA_CONTRACT1,
            image: 'contract.jpg',
            actions:
                [
                    {
                        type: actionTypeConstants.ADD_AGENDAITEM,
                        config: {
                            label: 'Contract verloopt',
                            description: 'Op deze datum verloopt je contract met...',
                            type: timeItemTypes.reminder,
                            startDate: NOW(), // TODO use document info
                            endDate: NOW(), // TODO use document info
                            reminder: null,
                        }
                    }
                ]
        },
        {
            code: actionConstants.AGENDA_BETALING,
            image: 'bon.jpg',
            actions: [
                {
                    type: actionTypeConstants.ADD_AGENDAITEM,
                    config: {
                        label: 'Herinnering betaling',
                        description: 'Op deze datum moet je een betaling voldoen',
                        type: timeItemTypes.reminder,
                        startDate: NOW(), // TODO use document info
                        endDate: NOW(), // TODO use document info
                        reminder: {
                            time: [1, 'months'],
                            email: true,
                        }
                    }
                }
            ],
        },
        {
            code: actionConstants.AGENDA_ABONNEMENTVT1,
            image: 'subscription.jpg',
            actions:
                [
                    {
                        type: actionTypeConstants.ADD_AGENDAITEM,
                        config: {
                            label: 'Abonnement verlopen',
                            description: 'Op deze datum verloopt je abonnement van...',
                            type: timeItemTypes.reminder,
                            startDate: NOW(), // TODO use document info
                            endDate: NOW(), // TODO use document info
                            reminder: {
                                time: [1, 'months'],
                                alerts: [
                                    alertMapping.subscription_expires,
                                ]
                            }
                        }
                    }
                ]
        }
    ]
;

export const getSmartAction = code => {
    if (isNotNull(code)) {
        for (let i = 0; i < smartActionMapping.length; i++) {
            let action = smartActionMapping[i];
            if (action.code === code) return action;
        }
        throw new Error('Smart action not found. Code: \'' + code + '\'');
    }
    throw new Error('Cannot get smart action without code');
};

export const getAlertConfig = id => {
    if (alertMapping.hasOwnProperty(id)) return alertMapping[id];
    return null;
};

export const getTipConfig = id => {
    if (tipMapping.hasOwnProperty(id)) return tipMapping[id];
    return null;
};