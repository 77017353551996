import './style/editContact.scss';

import React from 'react';
import PropTypes from 'prop-types';
import {applyMiddleware, createStore} from 'redux';
import {combineForms, Control, Errors, Form} from 'react-redux-form';
import thunk from 'redux-thunk';

import {generateUUID} from 'glob-common-js/lib/utils';
import Loader from "../../../misc/loader";

export default class EditContactView extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = {
            name: this.getContactValue('name'),
            website: this.getContactValue('website'),
            phone: this.getContactValue('phone'),
            email: this.getContactValue('email'),
            loginName: this.getContactValue('loginName'),
            address: {
                street: this.getContactValue('address.street'),
                houseNumber: this.getContactValue('address.houseNumber'),
                houseNumberSuffix: this.getContactValue('address.houseNumberSuffix'),
                zipCode: this.getContactValue('address.zipCode'),
                city: this.getContactValue('address.city'),
            },
            comment: this.getContactValue('comment'),
        };
        this.store = createStore(combineForms({contact: this.initialState}), applyMiddleware(thunk));
    }

    getContactValue = (query, obj = this.props.contact) => {
        if (isNull(obj)) return '';
        query = query.includes('.') ? query.split('.') : query;
        if (Array.isArray(query)) {
            if (query.length === 1) {
                return isNotNull(obj[query[0]]) ? obj[query[0]] : '';
            }
            let queryPart = obj[query.splice(0, 1)];
            return this.getContactValue(query, queryPart);
        }
        return isNotNull(obj[query]) ? obj[query] : '';
    };

    onSubmit = model => {
        this.loader.enable();
        let id = isNotNull(this.props.contact) && isNotNull(this.props.contact.id) ? this.props.contact.id :
            generateUUID();
        let idIncludedModel = Object.assign({}, model, {id});
        this.props.onSubmit(idIncludedModel);
    };

    disableLoader = (callback) => {
        this.loader.disable(callback);
    };

    emailValidator = val => {
        return !val || val.length === 0 || /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(val)
    };

    renderControl = (model, type, label, defaultValue, validator = null, errorMessage = null, isRequired = false) => {
        let validators = {}, errors = {};
        if (areNotNull(validator && errorMessage)) {
            validators = {validator};
            errors = {validator: errorMessage};
        }
        if (isRequired) {
            validators.required = val => isNotNull(val);
            errors.required = 'Vul dit veld in';
        }
        return (
            <div className='editContactFieldGroup'>
                <label className='editContactFieldLabel'>{label + (isRequired ? '*' : '')}</label>
                {type !== 'textarea' &&
                <Control.input type={type} defaultValue={defaultValue} model={model}
                               validators={validators} className='editContactFieldInput'/>}
                {type === 'textarea' &&
                <Control.textarea defaultValue={defaultValue} model={model}
                                  validators={validators} className='editContactFieldInput'/>}
                {isNotNull(validators) &&
                <Errors model={model} className='editContactError' show='touched' messages={errors}/>}
            </div>
        )
    };

    render = () => {
        return (
            <>
                <div className='clearBackground'/>
                <div className='editContactView'>
                    <div className='editContactContentContainer'>
                        <div className='editContactHeader'>
                            <h2 className='headerTitle'>{this.props.headerTitle}</h2>
                            <span className='editContactClose common-icon-cross' onClick={this.props.onCancel}/>
                        </div>
                        <div className='editContactBody'>
                            <Form store={this.store} model='contact' onSubmit={this.onSubmit}>
                                <div className='formFields'>
                                    {this.renderControl('.name', 'text', 'Naam', this.initialState.name, null, null, true)}
                                    {this.renderControl('.email', 'email', 'E-mailadres', this.initialState.email, this.emailValidator,
                                        'Ongeldig e-mailadres')}
                                    {this.renderControl('.loginName', 'text', 'Login naam', this.initialState.loginName)}
                                    {this.renderControl('.phone', 'text', 'Telefoonnummer', this.initialState.phone)}
                                    {this.renderControl('.website', 'text', 'Website', this.initialState.website)}
                                    {this.renderControl('.address.street', 'text', 'Straat', this.initialState.address.street)}
                                    {this.renderControl('.address.houseNumber', 'text', 'Huisnummer',
                                        this.initialState.address.houseNumber)}
                                    {this.renderControl('.address.houseNumberSuffix', 'text', 'Huisnummer toevoeging',
                                        this.initialState.address.houseNumberSuffix)}
                                    {this.renderControl('.address.zipCode', 'text', 'Postcode', this.initialState.address.zipCode)}
                                    {this.renderControl('.address.city', 'text', 'Stad', this.initialState.address.city)}
                                    {this.renderControl('.comment', 'textarea', 'Opmerking', this.initialState.comment)}
                                </div>
                                <div className='editContactFooter'>
                                    <button type='submit' className='editContactButton save'>Opslaan</button>
                                    <button type='reset' className='editContactButton cancel'
                                            onClick={this.props.onCancel}>Annuleren
                                    </button>
                                </div>
                            </Form>
                        </div>
                        <Loader ref={refName => {
                            this.loader = refName
                        }} includeBackground={true} backgroundColor='white' textBackground='transparent'
                                text='Contact opslaan...' locate='absolute'/>
                    </div>
                </div>
            </>
        )
    }
}

EditContactView.propTypes = {
    contact: PropTypes.shape({
        name: PropTypes.string.isRequired,
        website: PropTypes.string,
        phone: PropTypes.string,
        email: PropTypes.string,
        address: PropTypes.shape({
            street: PropTypes.string,
            houseNumber: PropTypes.string,
            houseNumberSuffix: PropTypes.string,
            zipCode: PropTypes.string,
            city: PropTypes.string,
        }),
        comment: PropTypes.string,
    }),
    headerTitle: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};
