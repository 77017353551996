import React from 'react';
import PropTypes from 'prop-types';
import Scrollbars from 'react-custom-scrollbars';

export default class CustomScrollbar extends React.Component {
    constructor(props) {
        super(props);
    }

    getStyle = () => {
        let {minHeight, maxHeight, height} = this.props;
        let style = {};
        if (isNotNull(minHeight)) style.minHeight = minHeight;
        if (isNotNull(maxHeight)) style.maxHeight = maxHeight;
        if (isNull(maxHeight)) style.height = height;
        return style;
    };

    render = () => {
        const props = Object.assign({}, this.props);
        delete props.height;
        return (
            <Scrollbars style={this.getStyle()} autoHide autoHideDuration={500} {...props}>
                {this.props.children}
            </Scrollbars>
        );
    }
}

CustomScrollbar.propTypes = {
    height: PropTypes.string,
    minHeight: PropTypes.string,
    maxHeight: PropTypes.string,
};