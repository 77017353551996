import React from 'react';
import {connect} from 'react-redux';

import {filterListDuplicates, getObjectValues} from "glob-common-js/lib/utils";

import getDocumentType, {getDocByLabel, getKeyByLabel} from 'BdhCommon/data/mappings/docTypeMapping';
import AddDocumentView from "./addDocumentView";
import {sendGaEvent} from "BdhCommon/js/ga";
import ErrorBoundary from "../../errorBoundary";
import getCategoryType from "../../../common/data/mappings/categoryMapping";
import {getAllDocuments} from "../../../misc/requestSender";
import {sendPageView} from "../../../common/js/ga";
import {dispatchCustomEvent} from "../../../misc/eventDispatcher";
import {navigate} from "../../../misc/utils";
import StateComponent from "../../misc/stateComponent";
import {addDataEventListener, DATA_TYPES, EVENT_TYPES, removeAllDataEventListeners} from "../../../misc/dataEvent";

const GA_CATEGORY = 'Document toevoegen';

export class AddDocumentController extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            documentCount: 0,
            searchBarValue: null,
            selectedDocumentType: {name: 'none', label: 'none'},
            selectedModule: null,
            showMobileSuggestions: false,
        };
    }

    onCreateDocumentEvent = () => {
        this.changeState({documentCount: this.state.documentCount + 1});
    };

    getDocumentCount = () => {
        getAllDocuments({
            callback: (response) => {
                let documents = response.data;
                if (isNotNull(documents) && isNotNull(documents.vault)) {
                    this.changeState({documentCount: documents.vault.length});
                }
            }
        });
    };

    onDocumentCountClick = () => {
        sendGaEvent(GA_CATEGORY, 'Klik', 'Document teller');
        navigate('/bibliotheek');
    };

    getSearchBarTypes = () => {
        return getObjectValues(getDocumentType()).map(documentType => (documentType.label));
    };

    onSearchBarSuggestionClick = (selectedValue) => {
        sendGaEvent('Document toevoegen', 'Zoekresultaat click', selectedValue);
        this.changeState({
            searchBarValue: selectedValue,
            searchBarDocumentType: getDocByLabel(selectedValue),
            selectedModule: null,
        });
    };

    onSelectModule = (module) => {
        sendGaEvent(GA_CATEGORY, 'Selecteer module', module.label);
        this.changeState({
            selectedModule: module,
            searchBarDocumentType: null,
        });
    };

    activateMobileSuggestions = () => {
        this.changeState({
            showMobileSuggestions: true,
        });
    };

    showMobileSuggestions = () => {
        this.changeState({
            showMobileSuggestions: true,
        });
    };

    hideMobileSuggestions = () => {
        if (this.state.showMobileSuggestions) {
            this.changeState({
                showMobileSuggestions: false,
            });
        }
    };

    getSearchDocumentTypes = () => {
        let docLabels = this.getSearchBarTypes();
        return docLabels.map((docLabel) => (getDocByLabel(docLabel)));
    };

    getDocumentTypes = () => {
        let module = this.state.selectedModule;
        if (isNull(module) && isNull(this.state.searchBarValue)) return [];

        let documentTypes = [];
        if (isNotNull(module)) {
            documentTypes = getCategoryType(module).docTypes.map(docType => getDocumentType(docType));
        } else if (isNotNull(this.state.searchBarValue)) {
            documentTypes = [getDocByLabel(this.state.searchBarValue)];
        }
        return filterListDuplicates(documentTypes).sort((typeA, typeB) => {
            // Sort alphabetically but keep 'Overige' as last item
            let labelA = typeA.label;
            let labelB = typeB.label;
            return labelA === 'Overige' ? 1 : labelB === 'Overige' ? -1 : labelA > labelB ? 1 : labelA < labelB ? -1 : 0;
        });
    };

    getMobileSuggestionsDocs = () => {
        let searchBarValue = this.state.searchBarValue;
        let documentTypes = [];
        if (isNotNull(searchBarValue)) {
            documentTypes.push(getDocByLabel(searchBarValue));
        }
        return filterListDuplicates(documentTypes).sort((typeA, typeB) => {
            // Sort alphabetically but keep 'Overige' as last item
            let labelA = typeA.label;
            let labelB = typeB.label;
            return labelA === 'Overige' ? 1 : labelB === 'Overige' ? -1 : labelA > labelB ? 1 : labelA < labelB ? -1 : 0;
        });
    };

    onDocumentTypeClick = (documentType) => {
        sendGaEvent(GA_CATEGORY, 'Klik documenttype', documentType.label);
        let selectedDocumentType = {
            name: getKeyByLabel(documentType.label),
            label: documentType.label,
            fields: isNotNull(documentType.fields) ? documentType.fields : null,
        };
        this.changeState({selectedDocumentType});
        dispatchCustomEvent('addDocument', {typeId: documentType.id, saveCallback: this.triggerLinkDossier});
    };

    triggerLinkDossier = doc => {
        dispatchCustomEvent('linkDossier', doc);
    };

    toDashboard = () => {
        navigate('/dashboard');
    };

    defaultSelectModule = () => {
        let selectedModule = this.props.moduleState.selectedModule;
        this.changeState({
            selectedModule,
        });
    };

    onSave = (requestParams) => {
        let module = this.props.moduleState.selectedModule;
        let moduleLabel = isNotNull(module) ? getCategoryType(module).label : 'Geen module bekend';
        sendGaEvent(moduleLabel, 'document toegevoegd - toegewezen', this.state.selectedDocumentType.label);
        dispatchCustomEvent('linkDossier', {
            id: requestParams.responseData.file_id,
            label: requestParams.responseData.label,
            type: {id: requestParams.data.file_type_id},
        });
    };

    componentDidUpdate = (prevProps) => {
        if (prevProps.moduleState.selectedModule !== this.props.moduleState.selectedModule)
            this.defaultSelectModule();
    };

    componentDidMount = () => {
        this.mount();
        sendPageView('/documenten-toevoegen');
        addDataEventListener(DATA_TYPES.DOCUMENT, EVENT_TYPES.CREATE, 'addDocumentController', this.onCreateDocumentEvent);
        this.defaultSelectModule();
        // Load the document count
        this.getDocumentCount();
    };

    componentWillUnmount = () => {
        this.unMount();
        removeAllDataEventListeners('addDocumentController', this.onCreateDocumentEvent);
    };

    render = () => {
        const {documentCount, selectedModule, showMobileSuggestions} = this.state;
        return (
            <ErrorBoundary>
                <>
                    <AddDocumentView documentCount={documentCount} selectedModule={selectedModule}
                                     showMobileSuggestions={showMobileSuggestions}
                                     mobileSuggestionsDocs={this.getMobileSuggestionsDocs()}
                                     onDocumentCountClick={this.onDocumentCountClick}
                                     searchBarTypes={this.getSearchBarTypes()}
                                     searchBarDocTypes={this.getSearchDocumentTypes()}
                                     onSearchBarSuggestionClick={this.onSearchBarSuggestionClick}
                                     onSelectModule={this.onSelectModule} documentTypes={this.getDocumentTypes()}
                                     onDocumentTypeClick={this.onDocumentTypeClick} toDashboard={this.toDashboard}
                                     hideMobileSuggestions={this.hideMobileSuggestions}
                                     activateMobileSuggestions={this.activateMobileSuggestions}/>
                </>
            </ErrorBoundary>
        )
    };
}

const mapStateToProps = (state) => ({
    moduleState: state.module,
});

export default connect(mapStateToProps)(AddDocumentController);