
import moment from 'moment';
import * as Sentry from '@sentry/browser';

import request, {FORBIDDEN, UNAUTHORIZED} from 'glob-common-js/lib/request';
import {isFunction, pathIsNotNull, removeCookie, warn} from 'glob-common-js/lib/utils';

import appProperties from "../components/appProperties";
import {store} from "../startup";
import * as actions from '../redux/actions';
import {getAccount, getUserFlex, logoutGrant, responseIsSuccess} from "./requestSender";
import {addCustomEventParam, dispatchCustomEvent} from "./eventDispatcher";
import {ERROR, GRANT_NOT_ALLOWED, INSUFFICIENT_RIGHTS, MISSING_CONNECTION} from "./constants";
import {timeFormats} from "../common/components/datePicker";
import {getJwtToken, getParsedJwt, setJwt} from "../common/js/platform";

import {getDaisyUser, getActionCode} from "./requestSender";

export let tempRequestData = {repeatCount: 0};
const ignoreUrls = [
    {url: '/login', exact: false},
    {url: '/registratie', exact: false},
    {url: '/zorganalyse', exact: false},
    {url: '/quickscanzorgverzekering', exact: true},
    {url: '/mail-systeem', exact: true},
    {url: '/mail-task', exact: true},
    {url: '/test/', exact: false},
    {url: '/wachtwoord-vergeten', exact: true},
    {url: '/wachtwoord-wijzigen', exact: false},
    {url: '/auth', exact: false},
];

export const isUserLoggedIn = (onIsLoggedIn, navigateUrl, onNotLoggedIn) => {
    if (isNotNull(getJwtToken())) {
        getAccount({
            callback: (response) => {
                onIsLoggedIn(response.data);
            },
            unauthorizedCallback: onNotLoggedIn,
        });
    } else {
        if (isFunction(onNotLoggedIn))
            onNotLoggedIn();
        // Determine the redirect page
        if (isNotNull(navigateUrl)) {
            if (navigateUrl.indexOf('/zorganalyse') > -1 && !navigateUrl.endsWith('/zorganalyse') && appProperties.showHealthAnlysis) {
                if (navigateUrl.indexOf('huidige-verzekering') === -1) {
                    navigateUrl = '/quickscanzorgverzekering';
                }
            } else if (!ignoreUrl(navigateUrl, ignoreUrls)) {
                navigateUrl = '/auth/login';
            }
        } else {
            navigateUrl = '/auth/login';
        }
        navigate(navigateUrl);
    }
};

const ignoreUrl = (url, ignoreUrls) => {
    for (let i = 0; i < ignoreUrls.length; i++) {
        let ignoreUrl = ignoreUrls[i];
        if ((ignoreUrl.exact && ignoreUrl.url === url) || (!ignoreUrl.exact && url.indexOf(ignoreUrl.url) > -1)) return true;
    }
    return false;
};

export const sendRequest = (requestConfig, callback, errorCallback = null) => {
    requestConfig.onErrorCallback = requestErrorCallback(errorCallback);
    requestConfig.data = isNotNull(requestConfig.data) ? requestConfig.data : {};
    request.sendRequest(requestConfig, requestSuccessCallback(callback));

    let ignoredRepeatRequests = [
        'login',
        'smsRequest',
        'confirm2FARequest',
    ];
    if (ignoredRepeatRequests.indexOf(requestConfig.name) === -1) {
        tempRequestData = {requestConfig, callback, errorCallback, repeatCount: tempRequestData.repeatCount++};
    }
};

const requestSuccessCallback = (callback, errorCallback) => (response) => {
    const {status, message} = response.data;
    if (isNotNull(status) && status === ERROR) {
        const grantMessages = [
            GRANT_NOT_ALLOWED,
            MISSING_CONNECTION,
            INSUFFICIENT_RIGHTS,
        ];
        if (grantMessages.includes(message)) {
            grantNotAuthorizedCallback(message);
            return;
        }
    }
    if (isFunction(callback))
        callback(response)
};

export const grantNotAuthorizedCallback = (message) => {
    const actions = {
        [GRANT_NOT_ALLOWED]: {
            message: 'Deze actie is niet toegestaan',
            action: null,
        },
        [MISSING_CONNECTION]: {
            message: 'Je hebt geen toegang (meer) tot dit account.',
            action: {
                label: 'Uitloggen', onClick: () => {
                    logoutGrant({
                        callback: response => {
                            const {status, message, user} = response.data;
                            if (status === 'success') {
                                if (message === 'logged out')
                                    logoutNavigation();
                                else {
                                    setReduxUser(user);
                                    navigate('/auth/login');
                                }
                            } else logoutNavigation();
                        }
                    })
                }
            }
        },
        [INSUFFICIENT_RIGHTS]: {
            message: 'Je hebt niet genoeg rechten voor deze actie',
            action: null,
        },
    };
    dispatchCustomEvent('grantUnauthorized');
    dispatchCustomEvent('showAlert', {text: actions[message].message, actions: actions[message].action});
};

const logoutNavigation = () => {
    store.dispatch(actions.setUser(null));
    store.dispatch(actions.resetModule());
    store.dispatch(actions.resetFinancialDossiers());
    navigate('/auth/login');
};

const requestErrorCallback = (errorCallback) => (error) => {
    log(error);
    if (error.response && [FORBIDDEN, UNAUTHORIZED].includes(error.response.status)) {
        warn('Invalid jwt_token found, removing cookie.');
        removeCookie('JWT', {
            domain: '.bydehand.com',
            path: '/'
        });
        setJwt(null);
        let path = window.location.pathname;
        if (!ignoreUrl(path, ignoreUrls))
            navigate('/auth/login');
        else if (path.includes('auth/login'))
            dispatchCustomEvent('resetLogin');
    } else if (typeof errorCallback === 'function') {
        errorCallback(error);
    } else {
        throw error;
    }
};


export const repeatRequest = () => {
    if (tempRequestData.repeatCount < 4) {
        sendRequest(tempRequestData.requestConfig, tempRequestData.callback, tempRequestData.errorCallback);
    } else {
        navigate('/start');
    }
};

export const logToSentry = (ex, context) => {
    if (sentryEnabled)
        Sentry.withScope(scope => {
            scope.setExtra('extraContext', context);
            Sentry.captureException(ex)
        });
    window.console && console.error && console.error(ex);
};

export const setSentryUser = user => {
    const {id, email} = user;
    if (sentryEnabled)
        Sentry.configureScope(scope => {
            scope.setUser({id, email});
        })
};

export const setReduxUser = (userData, updateProfileImage = false) => {
    if (isNotNull(userData)) {
        const user = store.getState().user.user;
        let reduxData = {
            address: userData.address,
            bsn: userData.bsn,
            created_at: userData.created_at,
            dob: userData.dob,
            email: userData.email,
            external_data: userData.external_data,
            firstname: userData.firstname,
            gender: userData.gender,
            id: userData.id,
            infix: userData.infix,
            last_seen: userData.last_seen,
            lastname: userData.lastname,
            phone_mobile: userData.phone_mobile,
            phone_number: userData.phone_number,
            registration_referral: userData.registration_referral,
            updated_at: userData.updated_at,
            username: userData.username,
            two_step_auth: userData.two_step_auth,
            status: userData.status,
            profile_image: updateProfileImage ? userData.profile_image : isNotNull(user) ? user.profile_image : null,
        };
        store.dispatch(actions.setUser(reduxData));
        if (isNotNull(userData.external_data && isNotNull(userData.external_data.modules)))
            store.dispatch(actions.setActiveModules(userData.external_data.modules));
    } else {
        throw new Error("Missing user data!");
    }
};

export const navigate = (url, stripQuery = true) => {
    let navigator = document.getElementsByClassName('navigationButton')[0];
    if (isNotNull(navigator)) {
        navigator.dataset.url = url;
        if (stripQuery) {
            navigator.dataset.strip = '1';
        }
        navigator.click();
    }
};

export const getTransactionInfo = (user) => {
    let transactionInfo = {
        id: null,
        amount: null,
    };
    if (pathIsNotNull(user, 'payment_info.subscriptions')) {
        const subscription = user.payment_info.subscriptions[0];
        if (pathIsNotNull(subscription, 'amount') && subscription.external_id) {
            transactionInfo.id = subscription.external_id;
            transactionInfo.amount = subscription.amount;
        }
    }
    return transactionInfo;
};

navigator.browserSpecs = (function () {
    let ua = navigator.userAgent, tem,
        M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return {name: 'IE', version: (tem[1] || '')};
    }
    if (M[1] === 'Chrome') {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null) return {name: tem[1].replace('OPR', 'Opera'), version: tem[2]};
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    return {name: M[0], version: M[1]};
})();

export const browserIsSupported = () => {
    const supportedBrowsers = getSupportedBrowsers();
    const browserSpecs = navigator.browserSpecs;
    const browserName = browserSpecs.name.toLowerCase();
    const browserVersion = browserSpecs.version;
    let minVersion = Number.MAX_VALUE;

    for (let i = 0; i < supportedBrowsers.length; i++) {
        let supportedBrowser = getBrowserAlias(supportedBrowsers[i]).toLowerCase();
        if (supportedBrowser.includes(browserName)) {
            let supportedVersion = supportedBrowser.split(' ')[1];
            if (supportedVersion === 'all') {
                minVersion = 0;
                break;
            }
            if (isNaN(supportedVersion)) continue;
            if (parseFloat(supportedVersion) < minVersion) minVersion = supportedVersion;
        }
    }
    return !(isNaN(browserVersion) || minVersion > parseFloat(browserVersion)) || window.isAndroid
};

const getSupportedBrowsers = () => {
    const browserslist = require('browserslist');
    const supportedBrowsers = browserslist();
    for (let i = 0; i < supportedBrowsers.length; i++) {
        let browser = supportedBrowsers[i];
        let version = browser.split(' ')[1];
        if (version.includes('-')) {
            let name = browser.split(' ')[0];
            let versions = version.split('-');
            for (let j = 0; j < versions.length; j++) {
                supportedBrowsers.push(name + ' ' + versions[j]);
            }
            supportedBrowsers.splice(i, 1);
        }
    }
    return supportedBrowsers;
};

const getBrowserAlias = (name) => {
    let aliases = {
        ios_saf: 'Safari Mobile',
        ie_mob: 'IE',
        operamini: 'Opera Mini',
        and_chr: 'Chrome',
    };
    let browserName = name.split(' ')[0];
    browserName = aliases[browserName] || browserName;
    return browserName + ' ' + name.split(' ')[1];
};

export const isGrantedLogin = () => {
    const parsedToken = getParsedJwt();
    if (isNotNull(parsedToken)) {
        const grantId = parsedToken.grant_id;
        return isNotNull(grantId) && grantId !== 'none';
    }
    return false;
};

export const toMoment = (dateString, format = null) => {
    if (moment.isMoment(dateString))
        return moment(dateString);
    format = isNotNull(format) ? format : timeFormats;
    if (isNotNull(dateString))
        return moment(dateString, format);
    return moment();
};

const FLEX_MANDATORY_FROM = '2018-12-21';
export const checkOnboardingDecision = (user) => {
    getUserFlex({
        callback: (response) => {
            log('Checking onboarding decision for user', user);
            if (responseIsSuccess(response)) {
                const {flex} = response.data;
                if (!pathIsNotNull(flex, 'startFlow')) {
                    const {created_at} = user;
                    // Only trigger for 'new' users
                    if (toMoment(FLEX_MANDATORY_FROM).isSameOrBefore(toMoment(created_at))) {
                        dispatchCustomEvent('startOnboarding');
                    }
                }
            }
        }
    });
};

export const showMessage = (text, actions, title = null) => {
    dispatchCustomEvent('showAlert', {title, text, actions});
};

export const showSnackbar = message => {
    dispatchCustomEvent('openSnackbar', {text: message});
};


export const setPlatformVersion = params => {
    // action_code_id's levenstestament2020:
    // prod: "f0c83e64-12b8-4c8c-abf8-50e209f93f3d"
    // prod-CAV: "8b17641a-636a-45c3-8ae3-eac89e19613a"
    // test: '050c2dd2-109b-4908-a91e-902e8b6a00e2'
    var actionIds = ["f0c83e64-12b8-4c8c-abf8-50e209f93f3d", "050c2dd2-109b-4908-a91e-902e8b6a00e2","8b17641a-636a-45c3-8ae3-eac89e19613a"];
    getDaisyUser({
        callback: response => {
            if (responseIsSuccess(response)) {
                const {user} = response.data;
                const id = user.action_code_id;
                if (isNull(id)) {
                  //this.props.actions.setDisplayVersion('standard')
                  if (window.localStorage) {
                      window.localStorage.removeItem('PlatformDisplayVersion')
                      window.localStorage.setItem('PlatformDisplayVersion', 'standard');
                      if (isFunction(params.callback)) {
                          params.callback(params.argument);
                      }
                  }
                } else {
//                  getActionCode({id,
//                            callback: response => {
                                let displayVersion = 'standard';
                                //if (id ==='050c2dd2-109b-4908-a91e-902e8b6a00e2'){
                                if (actionIds.includes(id)) {
                                  displayVersion = 'lifeWill'
                                }
//                                if (responseIsSuccess(response)) {
//                                    const {action_code} = response.data;
//                                    switch(String(action_code.code)) {
//                                      case null:
//                                        displayVersion = 'standard';
//                                        break;
//                                      case 'levenstestament2020':
//                                        displayVersion = 'lifeWill';
//                                        break;
//                                      default:
//                                        displayVersion = 'standard';
//                                        break;
//                                    }
//                                } else displayVersion = 'standard';
                                if (window.localStorage) {
                                    window.localStorage.removeItem('PlatformDisplayVersion')
                                    window.localStorage.setItem('PlatformDisplayVersion', displayVersion);
                                    if (isFunction(params.callback)) {
                                        params.callback(params.user);
                                    }
                                }
                                //this.props.actions.setDisplayVersion(displayVersion)
//                            }
//                        });
              }
            }
        }
    });
};

export const getPlatformVersion = () => {
  let platformDisplayVersion='standard';
  if(typeof(Storage) !== "undefined") {
    platformDisplayVersion = window.localStorage.getItem('PlatformDisplayVersion');
    if (isNull(platformDisplayVersion)) {
      platformDisplayVersion='standard';
    }
  }
  return platformDisplayVersion;
}
