import React from 'react';
import _ from 'lodash';

import {capitalizeFirst, stringContains, stringStartsWith} from 'glob-common-js/lib/utils';

import StateComponent from "../../misc/stateComponent";
import {deleteDossier, getAllDossiers, updateDossier} from "../../../misc/requestSender";
import getDossierType from "../../../common/data/mappings/dossierTypeMapping";
import {addCustomEventListener, removeCustomEventListener} from "../../../misc/eventDispatcher";
import ChecklistPage from "./checklistPage";
import checklistTypes from "../checklistTypes";
import {navigate} from "../../../misc/utils";
import MaterialFactory from "../../material/materialFactory";
import {materialTypes} from "../../material/materialTypes";
import {getChecklistType, getSortedAvailableChecklists} from "../../../data/checklists/checklists";

export const EXISTING = 0, NEW = 1;
export default class ChecklistPageController extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            view: NEW,
            checklists: [],
            selectedChecklists: [],
            searchValue: '',
            checklistName: '',
            editingChecklist: null,
            deleteOpen: false,
            allChecklists: [],
        };
    }

    onClickChecklist = checklist => () => {
        const {id, type} = checklist;
        navigate(type.navigation + `?id=${id}`, true);
    };

    onSearch = value => {
        this.changeState({searchValue: value}, this.filterChecklists);
    };

    onClearSearch = () => {
        this.changeState({searchValue: ''}, this.filterChecklists);
    };

    onSelectChecklist = id => evt => {
        evt.stopPropagation();
        const checklists = this.state.selectedChecklists.slice();
        if (checklists.includes(id))
            checklists.splice(checklists.indexOf(id), 1);
        else
            checklists.push(id);
        this.changeState({selectedChecklists: checklists});
    };

    onDelete = () => {
        this.changeState({deleteOpen: true});
    };

    onConfirmDelete = () => {
        const {selectedChecklists} = this.state;
        this.removeChecklists(selectedChecklists);
        selectedChecklists.forEach(checklistId => {
            deleteDossier({id: checklistId});
        });
        this.onCloseDeleteDialog();
    };

    onChangeChecklistName = evt => {
        this.changeState({checklistName: evt.target.value});
    };

    onCloseNameDialog = () => {
        this.changeState({checklistName: '', editingChecklist: null});
    };

    onCloseDeleteDialog = () => {
        this.changeState({deleteOpen: false});
    };

    onSaveChecklistName = () => {
        // TODO
        const {checklistName, editingChecklist} = this.state;
        const dossierType = getDossierType('checklist_travel'), data = {
            title: checklistName, type: {
                id: dossierType.id,
                name: dossierType.name,
                fields: [],
            }
        };
        updateDossier({data, id: editingChecklist});
        const checklist = Object.assign(this.getChecklist(editingChecklist));
        checklist.name = checklistName;
        this.updateChecklist(checklist);
        this.onCloseNameDialog();
    };

    onUpdateChecklistName = () => {
        const {name, id} = this.getSelectedChecklist();
        this.changeState({
            checklistName: name,
            editingChecklist: id,
        });
    };

    onClickView = view => () => {
        const {allChecklists} = this.state;
        const checklists = view === EXISTING ? allChecklists : getSortedAvailableChecklists();
        this.changeState({view, checklists, searchValue: ''});
    };

    removeChecklists = ids => {
        const {view, allChecklists} = this.state;
        const processedChecklists = allChecklists.filter(checklist => !ids.includes(checklist.id));
        if (view === EXISTING) {
            const checklists = this.state.checklists.filter(checklist => !ids.includes(checklist.id));
            this.changeState({checklists, allChecklists: processedChecklists});
        }
    };

    getChecklist = id => {
        const {checklists} = this.state;
        for (let i = 0; i < checklists.length; i++) {
            if (checklists[i].id === id) return checklists[i];
        }
        return null;
    };

    getSelectedChecklist = () => {
        const {checklists, selectedChecklists} = this.state;
        const id = selectedChecklists[0];
        for (let i = 0; i < checklists.length; i++) {
            if (checklists[i].id === id) return checklists[i];
        }
        return {id: null, name: ''};
    };

    getView = (checklists) => {
        return isNotNull(checklists) ? EXISTING : NEW;
    };

    updateChecklist = checklist => {
        const checklists = this.state.checklists.slice();
        if (isNotNull(checklists) && checklist.hasOwnProperty('id')) {
            const {id} = checklist;
            for (let i = 0; i < checklists.length; i++) {
                if (checklists[i].id === id) {
                    checklists[i] = _.merge({}, checklists[i], checklist);
                    this.changeState({checklists});
                    return;
                }
            }
        }
    };

    loadChecklists = () => {
        getAllDossiers({
            queryParams: 'filter=type_id&value=' + getDossierType('checklist_travel').id,
            callback: dossiers => {
                if (isNotNull(dossiers)) {
                    dossiers.forEach(checklist => {
                        checklist.type = this.getChecklistType(checklist);
                    });
                    dossiers.sort(this.sortChecklists);
                    this.changeState({checklists: dossiers, view: this.getView(dossiers), allChecklists: dossiers});
                } else this.changeState({checklists: getSortedAvailableChecklists(), view: NEW, allChecklists: []});
            }
        })
    };

    getChecklistType = checklist => {
        let type = null;
        if (pathIsNotNull(checklist, 'external_data.checklist')) {
            const checklistData = checklist.external_data.checklist;
            if (isNotNull(checklistData.type)) type = checklistData.type;
            else if (this.checklistIsLegacyTravel(checklistData)) type = checklistTypes.TRAVEL;
        }
        return getChecklistType(type);
    };

    checklistIsLegacyTravel = checklistData => isNotNull(checklistData) && Array.isArray(checklistData.travel);

    sortChecklists = (checklistA, checklistB) => {
        const preferA = -1, preferB = 1, isEqual = 0, nameA = checklistA.name, nameB = checklistB.name;
        return nameA < nameB ? preferA : nameA > nameB ? preferB : isEqual;
    };

    filterChecklists = () => {
        const {searchValue, view, allChecklists} = this.state,
            checklists = view === EXISTING ? allChecklists.slice() : getSortedAvailableChecklists().slice();
        let filteredChecklists = [];
        if (isNull(searchValue))
            filteredChecklists = checklists;
        else {
            const startsWith = checklists.filter(checklist => stringStartsWith(checklist.name, searchValue)),
                contains = checklists.filter(
                    checklist => !stringStartsWith(checklist.name, searchValue)
                        && stringContains(checklist.name, searchValue));
            filteredChecklists = startsWith.concat(contains);
        }
        filteredChecklists.sort(this.sortChecklists);
        this.changeState({checklists: filteredChecklists});
    };

    renderNameDialog = () => {
        const {checklistName, editingChecklist} = this.state;
        return <MaterialFactory componentType={materialTypes.DIALOG} open={isNotNull(editingChecklist)}
                                onClose={this.onCloseNameDialog} title='Naam wijzigen'
                                actions={this.renderNameActions()}
                                content={<MaterialFactory componentType={materialTypes.TEXT} value={checklistName}
                                                          onChange={this.onChangeChecklistName}
                                                          label='Nieuwe naam'/>}/>

    };

    renderDeleteDialog = () => {
        const {selectedChecklists, deleteOpen} = this.state, amount = selectedChecklists.length,
            amountString = `checklist${amount > 1 ? 's' : ''}`;
        return <MaterialFactory componentType={materialTypes.DIALOG}
                                title={`${capitalizeFirst(amountString)} verwijderen`}
                                text={`Weet je zeker dat je deze ${amountString} wilt verwijderen?`}
                                actions={this.renderDeleteActions()} open={deleteOpen}/>
    };

    renderNameActions = () => ([
        {label: 'Annuleren', onClick: this.onCloseNameDialog},
        {label: 'Opslaan', onClick: this.onSaveChecklistName},
    ]);

    renderDeleteActions = () => ([
        {label: 'Nee', onClick: this.onCloseDeleteDialog},
        {label: 'Ja', onClick: this.onConfirmDelete},
    ]);

    componentDidMount = () => {
        this.mount();
        this.loadChecklists();
        addCustomEventListener('updateChecklists', this.loadChecklists);
        addCustomEventListener('updateChecklist', this.updateChecklist);
    };

    componentWillUnmount = () => {
        this.unMount();
        removeCustomEventListener('updateChecklists', this.loadChecklists);
        removeCustomEventListener('updateChecklist', this.updateChecklist);
    };

    render = () => {
        const {checklists, searchValue, selectedChecklists, view, allChecklists} = this.state;
        return (
            <>
                {this.renderNameDialog()}
                {this.renderDeleteDialog()}
                <ChecklistPage checklists={checklists} onChangeName={this.onUpdateChecklistName}
                               onClickChecklist={this.onClickChecklist} onClickDelete={this.onDelete}
                               onSearch={this.onSearch} onSelectChecklist={this.onSelectChecklist}
                               onClearSearch={this.onClearSearch} searchValue={searchValue}
                               selectedChecklists={selectedChecklists} onClickView={this.onClickView} view={view}
                               checklistCount={allChecklists.length}/>
            </>
        );
    };
}
