import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import TravelContactView from "./travelContactView";
import {sendTravelContactInfo} from "../../../misc/requestSender";

export class TravelContactController extends React.Component {
    constructor(props) {
        super(props);
    }

    onSend = model => {
        if (isNotNull(model.contacts)) {
            let data = this.createData(model);
            sendTravelContactInfo({
                id: this.props.dossierId, data, callback: (response) => {
                    this.view.reset();
                    this.toggleConfirm();
                }
            });
        }
    };

    createData = model => ({
        recipients: model.contacts,
        message: model.message,
        type: 'travel',
        blocks: [
            {
                content: [
                    {
                        email: this.getEmail(),
                        phone_mobile: this.getMobile(),
                        checked: model.includeInfo
                    }
                ],
                columns: this.createColumns(),
                label: 'Contact informatie'
            }
        ]
    });

    toggleConfirm = () => {
        this.popup.classList.toggle('active');
    };

    createColumns = () => {
        let columns = [{name: 'email', label: 'E-mail'}];
        if (isNotNull(this.getMobile()))
            columns.push({name: 'phone_mobile', label: 'Mobiel'});
        return columns;
    };

    getEmail = () => {
        let user = this.props.user;
        if (isNotNull(user)) return user.email;
        return '';
    };

    getMobile = () => {
        let user = this.props.user;
        if (isNotNull(user) && isNotNull(user.phone_mobile)) return user.phone_mobile;
        return '';
    };

    render = () => (
        <TravelContactView ref={refName => {
            this.view = refName
        }} onSend={this.onSend} email={this.getEmail()} mobile={this.getMobile()}>
            <div ref={refName => {
                this.popup = refName
            }} className='confirmSendPopup'>
                <label className='message'>De mail is verstuurd.</label>
                <button className='closeButton' onClick={this.toggleConfirm}>Sluiten</button>
            </div>
        </TravelContactView>
    )
}

const mapStateToProps = state => ({
    user: state.user.user,
});

export default connect(mapStateToProps)(TravelContactController);

TravelContactController.propTypes = {
    dossierId: PropTypes.string.isRequired,
    store: PropTypes.object.isRequired,
};