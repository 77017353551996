import React from 'react';
import {connect} from 'react-redux';

import {confirmLogin, confirmMobile} from "../../../misc/requestSender";
import TwoFactor from "./twoFactor";
import StateComponent from "../../misc/stateComponent";
import {ERROR} from "../../../misc/constants";

export class TwoFactorController extends StateComponent {
    constructor(props) {
        super(props);

        this.state = {
            submitting: false,
        };
    }

    onSubmit = (code, callback, phone_mobile, password, email, mode, onFail) => {
        if (isNotNull(code)) {
            this.changeState({submitting: true});
            let auth_token = code.join('');
            if (mode === 'update' && isNotNull(phone_mobile)) {
                let id = this.props.userState.user.id;
                confirmMobile({
                    id, auth_token, phone_mobile, callback: (response) => {
                        this.submitCallback(response, callback, onFail, phone_mobile);
                    }
                });
            } else if (mode === 'login' && areNotNull(email, password)) {
                confirmLogin({
                    email, password, auth_token, callback: (response) => {
                        this.submitCallback(response, callback, onFail);
                    }
                })
            }
        } else {
            onFail('Ongeldige code');
        }
    };

    submitCallback = (response, callback, onFail, phone_mobile) => {
        this.changeState({submitting: false});
        if (response.data.status === ERROR) {
            let message = response.data.message || 'Er is iets misgegaan, klik op Opnieuw sturen om het opnieuw te proberen.';
            onFail(message);
        } else {
            this.view.toggleEnterListener(false);
            callback(response, phone_mobile);
        }
    };

    isLoggedIn = () => pathIsNotNull(this.props.userState.user, 'email');

    componentDidMount = () => {
        this.mount();
    };

    componentWillUnmount = () => {
        this.unMount();
    };

    render = () => {
        return (
            <TwoFactor ref={refName => {
                this.view = refName
            }} onSubmit={this.onSubmit} submitting={this.state.submitting} loggedIn={this.isLoggedIn()}/>
        )
    };
}

const mapStateToProps = state => ({
    userState: state.user,
});

export default connect(mapStateToProps)(TwoFactorController);