import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../../redux/actions';

import {stringContains, stringStartsWith} from 'glob-common-js/lib/utils';

import {addCustomEventListener, dispatchCustomEvent, removeCustomEventListener} from "../../misc/eventDispatcher";
import {getAllDossiers} from "../../misc/requestSender";
import {navigate} from "../../misc/utils";

import LinkDossierView from "./linkDossierView";
import {getById} from "../../common/data/mappings/dossierTypeMapping";
import {dispatchDocumentEvent, EVENT_TYPES} from "../../misc/dataEvent";

export class LinkDossierController extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            documentToLink: null,
            selectedDossiers: [],
            dossiersToLink: [],
        };
        this.dossiers = [];
    }

    changeState = stateChange => {
        if (this._isMounted) this.setState(stateChange);
    };

    onClickLinked = (doc) => {
        this.setSelectedDossiers(doc);
        this.changeState({documentToLink: doc});
    };

    setSelectedDossiers = (doc) => {
        let belongings = doc.belongings || doc.dossiers;
        let allDossiers = this.dossiers;
        let selectedDossiers = this.state.selectedDossiers;
        if (isNotNull(belongings)) {
            for (let i = 0; i < belongings.length; i++) {
                let belonging = belongings[i];
                for (let j = 0; j < allDossiers.length; j++) {
                    let exsistingDossier = allDossiers[j];
                    if (belonging.id === exsistingDossier.id) {
                        selectedDossiers.push(exsistingDossier);
                    }
                }
            }
            this.changeState({selectedDossiers});
        }
    };

    getDossierToLink = () => {
        let dossiers = this.dossiers.slice();
        let linked = [];
        let toLink = [];
        let doc = this.state.documentToLink;
        if (isNotNull(doc)) {
            let documentDossiers = doc.belongings || doc.dossiers;
            let ids = isNotNull(documentDossiers) ? documentDossiers.map(dossier => dossier.id) : [];
            for (let i = 0; i < dossiers.length; i++) {
                let dossier = dossiers[i];
                dossier.label = dossier.name;
                dossier.type = getById(dossier.type_id);
                if (ids.includes(dossier.id)) linked.push(dossier);

                if (dossier.type.label.toLowerCase() !== 'financieel overzicht')
                    toLink.push(dossier);
            }
            this.changeState({dossiersToLink: toLink, selectedDossiers: linked});
        }
    };

    clearDocumentToLink = () => {
        this.changeState({documentToLink: null, selectedDossiers: []});
    };

    onLinkDossier = () => {
        const doc = this.state.documentToLink;
        doc.dossiers = this.state.selectedDossiers;
        this.linkDossierView.enableLoader();
        doc.update(() => {
            this.linkDossierView.disableLoader(() => {
                dispatchDocumentEvent(EVENT_TYPES.UPDATE, doc);
                this.clearDocumentToLink();
            });
        });
    };

    selectDossier = dossier => {
        let selectedDossiers = this.state.selectedDossiers;
        let ids = selectedDossiers.map(dossier => dossier.id);
        if (ids.includes(dossier.id)) {
            selectedDossiers.splice(ids.indexOf(dossier.id), 1);
        } else {
            selectedDossiers.push(dossier);
        }
        this.changeState({selectedDossiers});

        let element = document.getElementById('dossierButton ' + dossier.id);
        if (isNotNull(element)) {
            element.classList.toggle('selected');
        }
    };

    onNewDossier = () => {
        this.props.actions.setDocumentToLink(this.state.documentToLink);
        this.changeState({documentToLink: null});
        navigate('/dossier-overzicht');
        window.setTimeout(() => {
            dispatchCustomEvent('navigateAddDossier');
        }, 50);
    };

    fetchDossiers = () => {
        getAllDossiers({
            callback: (dossiers) => {
                this.dossiers = dossiers;
                this.getDossierToLink();
            }
        });
    };

    searchDossier = evt => {
        let query = evt.target.value;
        let dossiers = this.dossiers.slice();
        if (isNotNull(query)) {
            let starts = [];
            let contains = [];
            for (let i = 0; i < dossiers.length; i++) {
                let dossier = dossiers[i];
                let label = isNotNull(dossier.name) ? dossier.name : '';
                if (stringStartsWith(label, query)) starts.push(dossier);
                else if (stringContains(label, query)) contains.push(dossier);
            }
            dossiers = starts.concat(contains);
        }
        this.changeState({
            dossiersToLink: dossiers.map(dossier => {
                dossier.label = dossier.name;
                dossier.type = getById(dossier.type_id);
                return dossier;
            })
        });
    };

    onCancel = () => {
        this.changeState({documentToLink: null});
    };

    componentDidMount = () => {
        this._isMounted = true;
        if (isNotNull(this.props.userState.user)) {
            this.fetchDossiers();
        }
        addCustomEventListener('linkDossier', this.onClickLinked);
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (isNull(prevState.documentToLink) && isNotNull(this.state.documentToLink)) {
            this.fetchDossiers();
        }
    };

    componentWillUnmount = () => {
        this._isMounted = false;
        removeCustomEventListener('linkDossier', this.onClickLinked);
    };


    render = () => {
        if (isNotNull(this.state.documentToLink)) {
            return (
                <LinkDossierView ref={refName => {
                    this.linkDossierView = refName
                }} onNewDossier={this.onNewDossier} searchDossier={this.searchDossier}
                                 onSelectDossier={this.selectDossier} onCancel={this.onCancel}
                                 onSave={this.onLinkDossier} selectedDossiers={this.state.selectedDossiers}
                                 documentLabel={this.state.documentToLink.label}
                                 dossiersToLink={this.state.dossiersToLink}/>
            );
        }
        return null;
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch),
});

const mapStateToProps = state => ({
    userState: state.user,
});
export default connect(mapStateToProps, mapDispatchToProps)(LinkDossierController);