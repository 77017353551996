import './style/editDossier.scss';

import React from 'react';
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';

import StateComponent from "../../misc/stateComponent";
import MaterialFactory from "../../material/materialFactory";
import {materialTypes} from "../../material/materialTypes";
import {fieldTypes} from "../../../common/data/mappings/fieldMapping";
import GenericLoader from "../../misc/genericLoader";
import {getOnboardingManager} from "../../onboarding/manager/onboardingManagerHelper";

export default class EditDossierView extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            values: {name: ''},
            errors: {name: null},
        }
    }

    initializeState = () => {
        const {fields, dossierName} = this.props;
        const values = {name: dossierName}, errors = {name: null};
        forEach(fields, field => {
            if (![fieldTypes.DATETIME, fieldTypes.DATE].includes(field.type) && isNull(field.value)) field.value = '';
            values[field.name] = field.value;
            if (isNotNull(field.validator))
                errors[field.name] = null;
        });
        this.changeState({values, errors});
    };

    onChange = (name) => (evt) => {
        const values = Object.assign({}, this.state.values, {[name]: evt.target.value});
        this.changeState({values});
    };

    onSubmit = () => {
        if (this.validateValues()) {
            const {values} = this.state;
            const {onSubmit} = this.props;
            onSubmit(values);
        }
    };

    onCancel = () => {
        const {onCancel} = this.props;
        this.changeState({values: {name: ''}, errors: {name: null}});
        onCancel();
    };

    validateValues = () => {
        const {fields} = this.props;
        const {values} = this.state;
        const errors = {};
        if (isNull(values.name))
            errors.name = 'Geef dit dossier een naam';
        forEach(fields, field => {
            const {name, validator} = field;
            if (isNotNull(validator)) {
                errors[name] = validator(values[name]);
            }
        });
        this.changeState({errors});
        return !this.formHasErrors(errors);
    };

    formHasErrors = errors => {
        const errorValues = Object.values(errors);
        for (let i = 0; i < errorValues.length; i++) {
            if (isNotNull(errorValues[i])) return true;
        }
        return false;
    };

    getSelectItems = options => {
        if (Array.isArray(options)) return options.map(this.createOption);
        return JSON.parse(options).map(this.createOption);
    };

    createOption = (option) => {
        let value = option.hasOwnProperty('value') ? option.value : option;
        let label = option.hasOwnProperty('label') ? option.label : option;
        return {value, label};
    };

    getValue = name => {
        const {values} = this.state;
        return values[name] || '';
    };

    onboardingIsActive = () => getOnboardingManager('store').isActive();

    renderField = (field, key) => {
        const {type, options} = field;
        const renderMap = {
            [fieldTypes.PRICE]: this.renderTextField(),
            [fieldTypes.STRING]: this.renderTextField(),
            [fieldTypes.DATE]: this.renderDateField,
            [fieldTypes.DATETIME]: this.renderDateField,
            [fieldTypes.NUMERIC]: this.renderTextField('number'),
            default: this.renderTextField(),
        };
        if (isNotNull(options)) return this.renderSelectField(field, key);
        return renderMap[type](field, key);
    };

    renderSelectField = (field, key) => {
        const {name, label, options} = field;
        const {values, errors} = this.state;
        return <MaterialFactory componentType={materialTypes.SELECT} key={key} label={label} value={this.getValue(name)}
                                onChange={this.onChange(name)} items={this.getSelectItems(options)}
                                helperText={errors[name]} error={isNotNull(errors[name])}
                                disabled={this.onboardingIsActive()}/>
    };

    renderTextField = (type = 'text') => (field, key) => {
        const {name, label, adornment} = field;
        const {values, errors} = this.state;
        const props = {};
        if (isNotNull(adornment))
            props.InputProps = {
                startAdornment: <InputAdornment position="start">{adornment}</InputAdornment>,
            };
        return <MaterialFactory componentType={materialTypes.TEXT} key={key} type={type} value={this.getValue(name)}
                                label={label} helperText={errors[name]} error={isNotNull(errors[name])}
                                disabled={this.onboardingIsActive()} onChange={this.onChange(name)} {...props}/>
    };

    renderDateField = (field, key) => {
        const {name, label} = field;
        const {values} = this.state;
        let value = values[name];
        value = isNotNull(value) ? value : null;
        return <MaterialFactory componentType={materialTypes.DATE_PICKER} key={key} value={value}
                                label={label} onChange={(date) => {
            this.onChange(name)({target: {value: date}});
        }} disabled={this.onboardingIsActive()}/>
    };

    componentDidMount = () => {
        this.mount();
        this.initializeState();
    };

    componentDidUpdate = (prevProps) => {
        const prevFields = prevProps.fields, fields = this.props.fields;
        if (isNull(prevFields) && isNotNull(fields))
            this.initializeState();
    };

    componentWillUnmount = () => {
        this.unMount();
    };

    render = () => {
        const {values, errors} = this.state, {fields, submitting, onCancel} = this.props;
        const onboardingActive = getOnboardingManager('store').isActive();
        return (
            <div className='editDossier'>
                <GenericLoader active={submitting}/>
                <form className='formFields'>
                    <MaterialFactory componentType={materialTypes.TEXT} value={values.name} required
                                     disabled={this.onboardingIsActive()} onChange={this.onChange('name')}
                                     label='Dossier naam' helperText={errors.name} error={isNotNull(errors.name)}/>
                    {fields.map(this.renderField)}
                    <div className='editDossierFooter'>
                        <MaterialFactory componentType={materialTypes.FLAT_BUTTON}
                                         disabled={submitting || onboardingActive}
                                         onClick={onCancel}>Annuleren</MaterialFactory>
                        <MaterialFactory componentType={materialTypes.RAISED_BUTTON}
                                         disabled={submitting || onboardingActive}
                                         onClick={this.onSubmit}>Opslaan</MaterialFactory>
                    </div>
                </form>
            </div>
        )
    };
}

EditDossierView.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    fields: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        value: PropTypes.any,
        validator: PropTypes.func,
        options: PropTypes.array,
    })).isRequired,
    dossierName: PropTypes.string.isRequired,
    submitting: PropTypes.bool.isRequired,
};