import {isNotNull} from "glob-common-js/lib/utils";

let storage = {
    tips: {},
    alerts: {},
};

/**
 * Add an item to the storage
 * @param key
 * @param value
 */
const addToStorage = (key, value) => {
    if (isNotNull(key)) {
        if (Array.isArray(key)) {
            let tempStore = storage;
            for (let i = 0; i < key.length - 1; i++) {
                tempStore = tempStore[key[i]];
            }
            tempStore[key[key.length - 1]] = value;
        } else if (key.indexOf('.') > -1) {
            return addToStorage(key.split('.'), value);
        } else {
            storage[key] = value;
        }
        return storage;
    }
    throw new TypeError('Key is not defined');
};

const getFromStorage = (name, actionStore) => {
    actionStore = actionStore || storage;
    if (isNotNull(name)) {
        if (Array.isArray(name)) {
            // name is array of keys
            let storeObject = actionStore[name[0]];
            if (name.length === 1) {
                return storeObject;
            }
            return getFromStorage(name.slice(1), storeObject);
        }
        if (name.indexOf('.') > -1) {
            // name contains multiple keys, so should be split
            return getFromStorage(name.split('.'));
        }
        return actionStore[name];
    }
    // No name given, so return complete store
    return actionStore;
};

/**
 * Update store
 * @param key String or Array containing the key(s) which need to be updated,
 * updates the complete storage if no key is given
 * @param value
 */
const updateStorage = (key, value) => {
    if (isNotNull(key)) {
        if (Array.isArray(key)) {
            let tempStore = storage;
            for (let i = 0; i < key.length - 2; i++) {
                tempStore = tempStore[key[i]];
            }
            tempStore[key.length - 1] = value;
        } else if (key.indexOf('.') > -1) {
            updateStorage(key.split('.'), value);
        } else {
            storage[key] = value;
        }
    } else {
        storage = value;
    }
    return storage;
};

const removeFromStorage = key => {
    if (isNotNull(key)) {
        if (Array.isArray(key)) {
            let tempStorage = storage;
            for (let i = 0; i < key.length - 1; i++) {
                tempStorage = tempStorage[key[i]];
            }
            delete tempStorage[key[key.length - 1]];
        } else if (key.indexOf('.') > -1) {
            return removeFromStorage(key.split('.'));
        } else {
            delete storage[key];
        }
        return storage;
    }
    throw new TypeError('Key is not defined');
};

const store = {
    storage,
    getFromStorage,
    updateStorage,
    removeFromStorage,
    addToStorage,
};

export default store;