import './style/evaluation.scss';

import React from 'react';
import EvaluationView from "./evaluationView";

export default class EvaluationController extends React.Component {
    constructor(props) {
        super(props);
    }

    render = () => {
        return (
            <EvaluationView/>
        )
    }
}