import {debug, getCookie, pathIsNotNull, warn} from 'glob-common-js/lib/utils';

export const TEST_ACCOUNTS = [
    /^davevanrijn(\+.+)?@bydehand.eu$/,
    /^daveyvanrijn(\+.+)?@hotmail.com$/,
    /^jansnotjan(\+.+)?@hotmail.com$/,
    /^renevdijk78(\+.+)?@gmail.com$/,
    /^robinmartens(\+.+)@bydehand.eu$/,
    /^robintjuh.martens(\+.+)@gmail.com$/,
    /^paulkuijpers(\+.+)@bydehand.eu$/,
    /^info(\+.+)@bydehand.eu$/,
];

let sbjs;
let sourceBusterInitialized = false;
let sbjsData = {};

/**
 * Reads data from the sbjs cookies if present. If data is missing, sbjs is reinitialized and the missing data is added
 * from the new sbjs cookies.
 */
export const initSbjs = () => {
    let cookieCurrent = getCookie('sbjs_current');
    let cookieSession = getCookie('sbjs_session');
    addCookieCurrent(cookieCurrent);
    addCookieSession(cookieSession);
    addSourceBusterData(sbjsData, isNull(cookieCurrent), isNull(cookieSession));
    debug('Sbjs cookie loaded. Data: ' + JSON.stringify(sbjsData, null, '\t'));
};

const initSourceBuster = () => {
    if (!sourceBusterInitialized) {
        sbjs = require('sourcebuster');
        sbjs.init({lifetime: 0.000114155});
        sourceBusterInitialized = true;
    }
};

const addSourceBusterData = (data, addCurrent, addSession) => {
    if (addSession || addCurrent)
        initSourceBuster();
    if (addCurrent) {
        data.src = sbjs.get.current.src || 'cookie';
        data.mdm = sbjs.get.current.mdm || 'niet gevonden';
        data.trm = sbjs.get.current.trm || null;
        data.cmp = sbjs.get.current.cmp || null;
        data.cnt = sbjs.get.current.cnt || null;
    }
    if (addSession)
        data.cpg = sbjs.get.session.cpg || null;
    return data;
};

export const getSbjsData = (key, params = null) => {
    if (isTestAccount(params)) {
        if (key === 'src') return 'Test';
        if (key === 'mdm') return 'account';
    }
    if (sbjsData.hasOwnProperty(key))
        return sbjsData[key];
    warn('Sbjs data does not contain a value for key \'' + key + '\'');
    return '(none)';
};

const isTestAccount = params => {
    if (pathIsNotNull(params, 'isTest') && params.isTest) return true;
    if (pathIsNotNull(params, 'email'))
        for (let i = 0; i < TEST_ACCOUNTS.length; i++) {
            if (TEST_ACCOUNTS[i].test(params.email)) return true;
        }
    return false;
};

export const getSbjsLocation = () => {
    let location = getSbjsData('cpg');
    if (isNull(location)) return 'Algemeen';

    let locationDetail = location.substr(location.lastIndexOf('.com/') + '.com/'.length, location.length);
    // Remove trailing slashes
    locationDetail = locationDetail.replace(/\/+$/g, '');
    if (isNull(locationDetail) || locationDetail === '/') return 'Algemeen';
    return locationDetail;
};

const addCookieCurrent = cookie => {
    if (isNotNull(cookie)) {
        const key = 0, value = 1;
        let cookieParts = cookie.split('|||');
        if (isNotNull(cookieParts)) {
            for (let i = 0; i < cookieParts.length; i++) {
                let cookiePart = cookieParts[i];
                if (cookiePart.includes('=')) {
                    let keyValue = cookiePart.split('=');
                    sbjsData[keyValue[key]] = keyValue[value];
                }
            }
        }
    }
};

const addCookieSession = cookie => {
    if (isNotNull(cookie)) {
        const key = 0, value = 1;
        let cookieParts = cookie.split('|||');
        if (isNotNull(cookieParts)) {
            for (let i = 0; i < cookieParts.length; i++) {
                let cookiePart = cookieParts[i];
                if (cookiePart.includes('=')) {
                    let keyValue = cookiePart.split('=');
                    sbjsData[keyValue[key]] = keyValue[value];
                }
            }
        }
    }
};