import './style/contactLink.scss';

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {CheckCircle} from "@material-ui/icons";
import IconButton from '@material-ui/core/IconButton';

import {hasHover} from 'glob-common-react/lib/utils/reactUtils';

import {WithBackground} from "../../../misc/reactUtils";
import CustomScrollbar from "../../misc/customScrollbar";
import GenericLoader from "../../misc/genericLoader";
import MaterialFactory from "../../material/materialFactory";
import {materialTypes} from "../../material/materialTypes";
import Dossier from "../../../models/dossier";
import {Contact} from "../../../models/contact";
import BdhSearchInput from "../../customControls/input/bdhSearchInput";

export default class ContactLinkView extends React.Component {
    onClose = () => {
        const element = document.getElementById('contactLinkPopup');
        if (isNotNull(element) && !hasHover(element)) this.props.onClose();
    };

    onEscape = evt => {
        evt.stopPropagation();
        evt.preventDefault();
    };

    renderHeader = () => {
        const {onClose} = this.props;
        return (
            <div className='headerContainer'>
                <label className='headerTitle'>Contact koppelen aan dossiers</label>
                <span className='common-icon-cross closeButton' onClick={onClose}/>
            </div>
        )
    };

    renderBody = () => {
        const {dossiers, loading} = this.props;
        return (
            <div className='contactDossiers'>
                <GenericLoader active={loading}/>
                {this.renderSearchContainer()}
                <CustomScrollbar height='15rem'>
                    {dossiers.map(this.renderDossier)}
                </CustomScrollbar>
            </div>
        )
    };

    renderSearchContainer = () => {
        const {searchValue, onSearch, onClearSearch, contact} = this.props;
        return (
            <div className='searchContainer'>
                <BdhSearchInput onChange={onSearch} value={searchValue} onClearSearch={onClearSearch}
                                label='Zoek dossiers'/>
            </div>
        )
    };

    renderDossier = (dossier) => {
        const {id, label, type} = dossier;
        return (
            <div key={id} className='contactDossierContainer'>
                <div className='dossierIconContainer'>
                    <span className={classNames(type.icon, 'dossierIcon')}/>
                </div>
                <div className='dossierDataContainer'>
                    <label className='dossierName'>{label}</label>
                    <label className='dossierType'>{type.label}</label>
                </div>
                {this.renderDossierButton(dossier)}
            </div>
        )
    };

    renderDossierButton = dossier => {
        const {onToggleDossier} = this.props, isLinked = dossier.getField('isLinked'),
            text = isLinked ? 'Ontkoppelen' : 'Koppelen', title = isLinked ? 'Ontkoppelen' : ' Koppelen';
        if (!isLinked)
            return <label className='dossierLinkButton' onClick={onToggleDossier(dossier)}
                          title={title}>Koppelen</label>;
        else return <IconButton onClick={onToggleDossier(dossier)}><CheckCircle className='checkIcon'/></IconButton>;
    };

    renderFooter = () => {
        const {onClose} = this.props;
        return (
            <div className='contactLinkFooter'>
                <MaterialFactory componentType={materialTypes.RAISED_BUTTON} onClick={onClose}>Sluiten</MaterialFactory>
            </div>
        )
    };

    render = () => {
        const {onClose} = this.props;
        return (
            <WithBackground onClose={this.onClose} onEscape={this.onEscape} transparent>
                <div className='contactLinkPopup' id='contactLinkPopup'>
                    {this.renderHeader()}
                    {this.renderBody()}
                    {this.renderFooter()}
                </div>
            </WithBackground>
        )
    }
};

ContactLinkView.propTypes = {
    onClose: PropTypes.func.isRequired,
    onToggleDossier: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    onClearSearch: PropTypes.func.isRequired,
    contact: PropTypes.instanceOf(Contact).isRequired,
    dossiers: PropTypes.arrayOf(PropTypes.instanceOf(Dossier)).isRequired,
    loading: PropTypes.bool.isRequired,
    searchValue: PropTypes.string.isRequired,
};