import './style/menu.scss';

import React from 'react';
import {withRouter} from 'react-router-dom';

import {scrollToTop} from 'glob-common-js/lib/utils';

import MenuView from "./menuView";
import {navigate} from "../../../../misc/utils";

export class MenuController extends React.Component {
    constructor(props) {
        super(props);
        this.state = {activeRoute: this.props.location.pathname}
    }

    changeState = (stateChange) => {
        if (this._isMounted) {
            this.setState(stateChange);
        }
    };

    navigate = url => {
        navigate('/zorganalyse' + url, true);
        scrollToTop();
        this.forceUpdate();
    };

    routeChanged = () => {
        this.changeState({activeRoute: this.props.location.pathname});
    };

    componentDidUpdate = (prevProps) => {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.routeChanged();
        }
    };

    componentDidMount = () => {
        this._isMounted = true;
    };

    componentWillUnmount = () => {
        this._isMounted = false;
    };

    render = () => {
        return (
            <MenuView healthState={this.props.healthState} activeRoute={this.state.activeRoute} onNavigate={this.navigate}/>
        );
    }
}


export default (withRouter(MenuController));