import './style/mailSendView.scss';

import React from 'react';
import PropTypes from 'prop-types';
import {sendGaEvent, sendPageView} from "../../../../common/js/ga";
import MaterialFactory from "../../../material/materialFactory";
import {materialTypes} from "../../../material/materialTypes";

const GA_CATEGORY = 'Registreren (Activeren)';
export default class MailSendView extends React.Component {
    constructor(props) {
        super(props);
    }

    renderMailButtons = () => {
        const email = this.props.email;
        let domain = email.substr(email.indexOf('@') + 1, email.length);
        switch (domain) {
            case'gmail.com':
                return this.renderMailButton('Open Gmail', '//mail.google.com');
            case 'hotmail.com':
            case 'hotmail.nl':
                return this.renderMailButton('Open Hotmail', '//outlook.live.com');
            case 'outlook.com':
            case 'outlook.nl':
            case 'live.nl':
                return this.renderMailButton('Open Outlook', '//outlook.live.com');
            case 'bydehand.eu':
                return this.renderMailButton('Open TransIP', '//transip.email');
        }
        return (
            <>
                {this.renderMailButton('Open Gmail', '//mail.google.com')}
                {this.renderMailButton('Open Hotmail', '//outlook.live.com')}
                {this.renderMailButton('Open Outlook', '//outlook.live.com')}
            </>
        );
    };

    renderMailButton = (label, url) => (
        <MaterialFactory componentType={materialTypes.RAISED_BUTTON} className='mailButton'
                         onClick={() => {
                             sendGaEvent(GA_CATEGORY, 'Klik mail', label);
                             window.open(url);
                         }}>{label}</MaterialFactory>
    );

    componentDidMount = () => {
        ga('set', 'page', '/registratie/2-verificatie-gestuurd');
        sendPageView();
    };

    render = () => (
        <div className='mailSendView'>
            <div>
                <div className='par'>
                    <label className='parTitle'>Verificatie</label>
                    <p>Er is een e-mail naar <span className='email'>{this.props.email}</span> gestuurd om je
                        e-mailadres te bevestigen. Klik op de bijgevoegde link om de verificatie af te ronden en door te
                        gaan naar de volgende stap.
                    </p>
                </div>
                <div className='par'>
                    <label className='parTitle'>E-mail niet gekregen?</label>
                    <p>Het kan even duren voordat de mail aankomt. Controleer je spambox, misschien zit hij hierin.</p>
                </div>
            </div>
            {this.renderMailButtons()}
        </div>
    )
}

MailSendView.propTypes = {
    email: PropTypes.string.isRequired,
};