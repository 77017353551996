import React from 'react';
import PropTypes from 'prop-types';

import DossierButton from './dossierButton';
import {getOnboardingManager} from "../onboarding/manager/onboardingManagerHelper";
import CustomScrollbar from "../misc/customScrollbar";

export class DossierContainer extends React.Component {
    constructor(props) {
        super(props);
    }

    createDossierButton = (dossier, key) => (
        <DossierButton key={key} dossier={dossier} fromUser={this.props.fromUser} onClick={this.props.onDossierClick}/>
    );

    renderOnboardingToggle = () => {
        const manager = getOnboardingManager('store');
        if (manager.isActive()) {
            const label = manager.shouldShowAllDossiers() ? 'Toon alleen meest gebruikt' : 'Toon alles';
            return <label className='onboardingToggle' onClick={manager.toggleAllDossiers}>{label}</label>;
        }
        return null;
    };

    renderConditionalScrollBar = content => {
        const onboardingActive = getOnboardingManager('store').isActive();
        if (onboardingActive) return <CustomScrollbar height='27rem'>
            {content}
        </CustomScrollbar>;
        else return content;
    };

    render = () => {
        return (
            <>
                {this.renderConditionalScrollBar(
                    <div className='dossierButtonsContainer'>
                        {this.props.dossiers.map(this.createDossierButton)}
                    </div>
                )}
                {this.renderOnboardingToggle()}
            </>
        )
    };
}

DossierContainer.propTypes = {
    fromUser: PropTypes.bool.isRequired,
    dossiers: PropTypes.array.isRequired,
    onDossierClick: PropTypes.func.isRequired,
};

export default DossierContainer;