import './style/insurerGraph.scss';

import React from 'react';
import PropTypes from 'prop-types';

import {toMoney} from 'glob-common-js/lib/utils';

export default class InsurerGraph extends React.Component {
    generateRuler = () => {
        const {gapSize, rulerStart, rulerAmount, tdWidth} = this.props;
        const tdValue = () => {
            let value = rulerStart - gapSize;
            let td = [];
            for (let i = 0; i < rulerAmount; i++) {
                let price = Math.round(value);
                if (price < 0) {
                    price = 0;
                }
                td.push(
                    <td key={i} style={{
                        width: tdWidth + "%",
                        left: -(tdWidth / 2) + "%"
                    }}>{toMoney(price)}</td>
                );
                value = value + gapSize;
            }
            return td;
        };

        const tdStripe = () => {
            let td = [];
            for (let i = 0; i < rulerAmount; i++) {
                td.push(
                    <td key={i}>|</td>
                )
            }
            return td;
        };

        return (
            <table>
                <tbody>
                <tr className="firstRow">
                    {tdValue()}
                </tr>
                <tr className="lastRow">
                    {tdStripe()}
                </tr>
                </tbody>
            </table>
        )
    };

    renderArrow = () => {
        const {currentArrowWidth, currentArrowOffset, includeCurrentText} = this.props;
        return (
            <div className={'insurerPopup' + (includeCurrentText ? '' : ' borderless')}
                 style={{
                     marginLeft: currentArrowOffset + "%",
                     width: currentArrowWidth + "%"
                 }}>
                {includeCurrentText && <div className="insurerPopupBody">
                    <h1>Jouw verzekering</h1>
                </div>}
                <div>
                    <i className="insurerPopupArrow"/>
                </div>
            </div>
        );
    };

    renderSlider = () => {
        const {betterStart, betterLength, currentPriceWidth, currentPrice, worseLength} = this.props;
        return (
            <div className="sliderBody">
                {this.generateRuler()}
                <div className="insuranceSlider">
                    <div className="betterInsurance"
                         style={{
                             marginLeft: betterStart + "%",
                             width: betterLength + "%",
                             maxWidth: '100%',
                         }}/>
                    <div className="currentInsurance" style={{width: currentPriceWidth + "%"}}>
                        <p className="currentInsurancePrice">{toMoney(currentPrice)}</p>
                    </div>
                    <div className="worseInsurance" style={{width: worseLength + "%"}}/>
                </div>
            </div>
        );
    };

    renderBottomArrows = () => {
        const {betterText, saveAmountText, worseText} = this.props;
        return (
            <div className="insurancesAmount">
                <div className="insurancesBetter">
                    <i className="insurancesBetterArrow"/>
                    <p>{betterText}</p>
                </div>
                <div className='saveAmount'>
                    {saveAmountText}
                </div>
                <div className="insurancesWorse">
                    <i className="insurancesWorseArrow"/>
                    <p>{worseText}</p>
                </div>
            </div>
        );
    };

    render = () => (
        <div className='insurerGraph'>
            {this.renderArrow()}
            {this.renderSlider()}
            {this.renderBottomArrows()}
        </div>
    )
}

InsurerGraph.propTypes = {
    rulerAmount: PropTypes.number.isRequired,
    gapSize: PropTypes.number.isRequired,
    rulerStart: PropTypes.number.isRequired,
    tdWidth: PropTypes.number.isRequired,
    betterStart: PropTypes.number.isRequired,
    betterLength: PropTypes.number.isRequired,
    worseLength: PropTypes.number.isRequired,
    currentPriceWidth: PropTypes.number.isRequired,
    currentPrice: PropTypes.number.isRequired,
    betterText: PropTypes.string.isRequired,
    worseText: PropTypes.string.isRequired,
    saveAmountText: PropTypes.string.isRequired,
    currentArrowOffset: PropTypes.number.isRequired,
    currentArrowWidth: PropTypes.number.isRequired,
    includeCurrentText: PropTypes.bool.isRequired,
};

InsurerGraph.defaultProps = {
    includeCurrentText: true,
};