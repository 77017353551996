import React from 'react';
import StateComponent from "../../misc/stateComponent";
import {saveOnboardingSteps} from "../manager/saveManager";
import SaveOnboardingView from "./saveOnboardingView";
import {addCustomEventListener, removeCustomEventListener} from "../../../misc/eventDispatcher";

export default class SaveOnboardingController extends StateComponent {
    constructor(props) {
        super(props);
        this.state = {
            step: saveOnboardingSteps.INTRO_STEP,
            active: false,
            tabNextDisabled: true,
        };
        this.tabNextTimeout = null;
    }

    eventListener = (params) => {
        const {type, data} = params;
        switch (type) {
            case 'activate':
                this.changeState({active: true, step: saveOnboardingSteps.INTRO_STEP});
                break;
            case 'setStep':
                this.changeState({step: data.step});
                break;
            case 'enableTabNext':
                this.tabNextTimeout = setTimeout(() => {
                    this.changeState({tabNextDisabled: false});
                    this.tabNextTimeout = null;
                }, 3000);
                break;
            case 'deactivate':
                this.changeState({active: false});
                break;
        }
    };

    componentDidMount = () => {
        this.mount();
        addCustomEventListener('saveOnboardingFlow', this.eventListener);
    };

    componentWillUnmount = () => {
        this.unMount();
        removeCustomEventListener('saveOnboardingFlow', this.eventListener);
        if (isNotNull(this.tabNextTimeout)) {
            clearTimeout(this.tabNextTimeout);
            this.tabNextTimeout = null;
        }
    };

    render = () => {
        const {active, step, tabNextDisabled} = this.state;
        if (active)
            return <SaveOnboardingView step={step} tabNextDisabled={tabNextDisabled}/>;
        return null;
    };
}