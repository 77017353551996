export const uploadMapping = {
    student: {
        dossiers: [
            'study',
            'subscriptions',
            'travel',
            'personal_data',
            'sport',
        ],
        documents: [
            'sales_receipt',
            'ov_chip_card_transport',
            'student_card_study',
            'drivers_licence_personal',
            'sportpass_sport',
        ],
    },
    young_prof: {
        dossiers: [
            'job',
            'personal_data',
            'subscriptions',
            'car',
            'misc_travel_vacation',
        ],
        documents: [
            'sales_receipt',
            'diploma_study',
            'passport_personal',
            'drivers_licence_personal',
            'labor_contract_work',
        ],
    }
};