import checklistTypes from "../../../components/checklist/checklistTypes";

export default {
    name: 'Lening afsluiten',
    icon: 'common-icon-document-2',
    route: '/lening-checklist',
    type: checklistTypes.LOAN,
    GA_CATEGORY: 'Checklist lening',
    categories: {
        compare: {
            name: 'compare',
            label: 'Lening vergelijken',
            icon: 'common-icon-euro',
            boxes: [
                {
                    name: 'choose',
                    label: 'Maak een keuze tussen persoonlijke lening of doorlopend krediet',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'providers',
                    label: 'Vergelijk lening bij verschillende aanbieders',
                    documentType: 'miscellaneous_indoors',
                    link: null,
                },
                {
                    name: 'interest',
                    label: 'Vergelijk de rente en kosten bij verschillende aanbieders',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'condition',
                    label: 'Vergelijk de voorwaarden',
                    documentType: 'miscellaneous_indoors',
                    link: null,
                },
                {
                    name: 'provider',
                    label: 'Kies een aanbieder',
                    documentType: null,
                    link: null,
                },
            ]
        },
        check: {
            name: 'check',
            label: 'Controle',
            icon: 'common-icon-document-2',
            boxes: [
                {
                    name: 'costs',
                    label: 'Check of je de kosten kunt blijven betalen',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'bkr',
                    label: 'Heb je geen betalingsachterstanden (BKR-registratie)',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'estimate',
                    label: 'Ontvangst offerte, controle inhoud en akkoord gegaan',
                    documentType: null,
                    link: null,
                },
            ]
        },
        signing: {
            name: 'signing',
            label: 'Contract ondertekenen',
            icon: 'common-icon-document-2',
            boxes: [
                {
                    name: 'iban',
                    label: 'Vul IBAN-rekeningnummer in op het contract',
                    documentType: null,
                    link: null,
                },
                {
                    name: 'id',
                    label: 'Kopieer de voorkant en achterkant van je identiteitsbewijs',
                    documentType: 'id_card_personal',
                    link: null,
                },
                {
                    name: 'sign',
                    label: 'Contract ondertekenen en terugsturen',
                    documentType: 'mortage',
                    link: null,
                }
            ]
        },
        documents: {
            name: 'documents',
            label: 'Verzamel documenten',
            icon: 'common-icon-document-2',
            boxes: [
                {
                    name: 'copy_payroll',
                    label: 'Kopie van je loonstrook  (niet ouder dan 2 maanden)',
                    subLabel: 'Benodigde info: bruto loon, cumulatieven / totalen, datum van in diensttreding',
                    documentType: 'payroll',
                    link: null,
                },
                {
                    name: 'copy_bank',
                    label: 'Print of kopieer een rekeningafschrift (niet ouder dan 2 maanden)',
                    subLabel: 'Benodigde info: naam, adres en woonplaats, rekeningnummer, actuele saldo, eventuele bestedingslimiet ' +
                        '(roodstand), transacties van minimaal 4 aaneengesloten weken',
                    documentType: 'miscellaneous_indoors',
                    link: null,
                },
                {
                    name: 'copy_balance',
                    label: 'Print of kopieer een saldo-overzicht (niet ouder dan 2 maanden)',
                    subLabel: 'Benodigde info: actueel nog terug te betalen saldo, bestedingslimiet, contractnummer',
                    documentType: 'miscellaneous_indoors',
                    link: null,
                }
            ]
        }
    }
};