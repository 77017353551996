import mappings from './mappings';

export const getAvailableChecklists = () => {
    const checklists = [];
    mappings.forEach(mapping => {
        checklists.push({
            id: 'new',
            name: mapping.name,
            type: {
                label: mapping.name,
                icon: mapping.icon,
                navigation: mapping.route,
            }
        });
    });
    return checklists;
};

export const getSortedAvailableChecklists = () => {
    const checklists = getAvailableChecklists();
    checklists.sort((checkA, checkB) => {
        const nameA = checkA.name.toLowerCase(), nameB = checkB.name.toLowerCase(), preferA = -1, preferB = 1,
            isEqual = 0;
        return nameA < nameB ? preferA : nameA > nameB ? preferB : isEqual;
    });
    return checklists;
};

export const getChecklistType = typeName => {
    for (let i = 0; i < mappings.length; i++) {
        const checklist = mappings[i];
        if (checklist.type === typeName)
            return {
                label: checklist.name,
                icon: checklist.icon,
                navigation: checklist.route,
            };
    }
    return {};
};

export const getAvailableChecklistOfType = typeName => {
    for (let i = 0; i < mappings.length; i++) {
        const checklist = mappings[i];
        if (checklist.type === typeName) {
          return {
            id: 'new',
            name: checklist.name,
            type: {
                label: checklist.name,
                icon: checklist.icon,
                navigation: checklist.route,
            }
          };
        };
    };
    return {};
};
