import '../style/summary.scss';

import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

import {isNotNull} from 'glob-common-js/lib/utils';
import {sendEvent} from "../../misc/healthAnalysisUtils";
import {navigate} from "../../../../../misc/utils";
import MaterialFactory from "../../../../material/materialFactory";
import {materialTypes} from "../../../../material/materialTypes";

export class SummaryView extends React.Component {
    constructor(props) {
        super(props);
    }

    createDetail = (name, value) => (
        isNotNull(value) ? <div className='summaryDetail'>
            <div className='insuranceDetailName'>{name}</div>
            <div className="insuranceDetailValue">{value}</div>
        </div> : null
    );

    navigate = () => {
        sendEvent('Afsluiten', 'verder');
        window.setTimeout(() => {
            navigate('/zorganalyse/jouw-verzekering/dossier-inrichten', true);
        }, 300);
    };

    clickInsurerLink = () => {
        let insurer = this.props.insurance.insurer;
        sendEvent('Afsluiten Link', insurer);
    };

    populateAdditionals = (additionals) => {
        if (this.containsAdditionals(additionals)) {
            return additionals.filter(additional => isNotNull(additional) && additional !== 'geen').map(additional =>
                (typeof additional === 'string' ? additional : additional.name)).join(', ');
        }
        return 'Geen';
    };

    containsAdditionals = list => {
        if (isNull(list)) return false;
        for (let i = 0; i < list.length; i++) {
            if (isNotNull(list[i])) return true;
        }
        return false;
    };

    render = () => {
        let insurance = this.props.insurance;
        return (
            <div className="insuranceSummaryView">
                <p className='title'>Jij hebt gekozen voor verzekering:</p>
                <div className="insuranceDetailContainer">
                    {this.createDetail('Verzekeraar:', insurance.insurer)}
                    {this.createDetail('Basis verzekering:', insurance.basic.name)}
                    {isNotNull(insurance.additionals) && this.createDetail('Aanvullend: ',
                        this.populateAdditionals(insurance.additionals))}
                    {this.createDetail('Eigen risico:', '\u20ac' + (isNotNull(insurance.risk) ? insurance.risk : '385'))}
                </div>
                {isNotNull(insurance.url) ?
                    <p className="insurerUrl">Ga <a className='link' href={insurance.url}
                                                    target='_blank' onClick={this.clickInsurerLink}>hier</a> naar de
                        website van {insurance.insurer} voor het afsluiten van je verzekering.</p>
                    : null}
                {/*<p className='saveResultDescription'>*/}
                {/*Wil je deze informatie overzichtelijk en ten alle tijden kunnen raadplegen, klik dan hieronder op*/}
                {/*resultaten opslaan en wij maken automatisch een dossier voor je aan. Hier kun je alles over je*/}
                {/*zorgverzekering terugvinden en al je documenten in opslaan.*/}
                {/*</p>*/}
                <div className="insuranceSummaryFooter">
                    <MaterialFactory componentType={materialTypes.RAISED_BUTTON}
                                     onClick={this.navigate}>Verder</MaterialFactory>
                    {/*<MaterialFactory componentType={materialTypes.RAISED_BUTTON}*/}
                    {/*onClick={this.navigate}>Resultaten opslaan</MaterialFactory>*/}
                </div>
            </div>
        )
    };
}

SummaryView.propTypes = {
    insurance: PropTypes.shape({
        insurer: PropTypes.string.isRequired,
        basic: PropTypes.object.isRequired,
        additionals: PropTypes.arrayOf(PropTypes.object).isRequired,
        risk: PropTypes.number,
        url: PropTypes.string.isRequired,
    }).isRequired,
};

export default withRouter(SummaryView);