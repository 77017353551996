import './style/dataView.scss';

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {sendGaEvent} from "../../../common/js/ga";
import StateComponent from "../../misc/stateComponent";
import MaterialFactory from "../../material/materialFactory";
import {materialTypes} from "../../material/materialTypes";

const GA_CATEGORY = 'Start flow 25+';
export default class DataView25 extends StateComponent {
    constructor(props) {
        super(props);

        this.state = {
            values: {
                house: {
                    toggled: true,
                    value: '',
                },
                houseNumber: {
                    value: 1,
                },
                houseNumberSuffix: {
                    value: ''
                },
                rent: {
                    toggled: true,
                    value: '',
                },
                work: {
                    value: '',
                },
                salary: {
                    value: '',
                },
                car: {
                    toggled: true,
                    value: '',
                },
                energy: {
                    value: '',
                },
                energy_price: {
                    value: '',
                },
                energy_day: 1,
            },
        };
    }

    onSubmit = () => {
        this.props.onSubmit(this.state.values);
    };

    changeValue = (name, value) => {
        let values = Object.assign({}, this.state.values);
        values[name].value = value;
        this.changeState({values});
    };

    changeToggle = (name, isToggled) => {
        sendGaEvent(GA_CATEGORY, 'Toggle', name + ' ' + (isToggled ? 'Aan' : 'Uit'));
        let values = Object.assign({}, this.state.values);
        values[name].toggled = isToggled;
        this.changeState({values});
    };

    onSelectEnergyDay = (evt) => {
        this.changeState({
            values: _.merge({}, this.state.values, {
                energy_day: evt.target.value
            })
        })
    };

    enableLoader = () => {
        this.loader.enable();
    };

    disableLoader = (callback) => {
        this.loader.disable(callback);
    };

    getDays = () => {
        let days = [];
        for (let i = 1; i <= 31; i++) {
            days.push({value: i, label: i + 'e van de maand'});
        }
        return days;
    };

    renderToggle = (name, label) => (
        <MaterialFactory componentType={materialTypes.TOGGLE} label={label} className='toggle'
                         tabIndex={-1} checked={this.state.values[name].toggled} onChange={(evt) => {
            this.changeToggle(name, evt.target.checked);
        }}/>
    );

    renderTextField = (id, name, type = 'text', disabled = false, hintText = 'Vul in...') => {
        return <MaterialFactory componentType={materialTypes.TEXT} id={id} placeholder={hintText}
                                value={this.state.values[name].value} type={type} disabled={disabled}
                                fullWidth onChange={(evt) => {
            this.changeValue(name, evt.target.value);
        }}/>;
    };

    componentDidMount = () => {
        this.mount();
    };

    componentWillUnmount = () => {
        this.unMount();
    };

    render = () => {
        const values = this.state.values;
        return (
            <div className='data_25'>
                <div className='description'>
                    <p className='descriptionText'>
                        Hieronder vind je een aantal vragen waarmee je de eerste stap zet naar een gestructureerd en
                        heldere administratie.
                    </p>
                </div>
                {/*<CustomScroll>*/}
                <div className='fieldsContainer'>
                    <div className='fieldContainer'>
                        <div className='titleContainer'>
                            <label className='fieldTitle'>Mijn woningsituatie</label>
                            {this.renderToggle('house', values.house.toggled ? 'Huurwoning' : 'Koopwoning')}
                        </div>
                        {this.renderTextField('house_25', 'house', 'text', false, 'Postcode...')}
                    </div>
                    <div className='fieldContainer'>
                        <div className='titleContainer'>
                            <label className='fieldTitle'>Huisnummer</label>
                        </div>
                        {this.renderTextField('house_number_25', 'houseNumber', 'text', false, 'Huisnummer')}
                    </div>
                    <div className='fieldContainer'>
                        <div className='titleContainer'>
                            <label className='fieldTitle'>Toevoeging huisnummer</label>
                        </div>
                        {this.renderTextField('house_number_suffix_25', 'houseNumberSuffix', 'text', false, 'Toevoeging')}
                    </div>
                    <div className='fieldContainer'>
                        <div className='titleContainer'>
                            <label
                                className='fieldTitle'>{values.house.toggled ? 'Hoeveel huur betaal je?'
                                : 'Heb je een hypotheek?'}</label>
                            {!values.house.toggled && this.renderToggle('rent', values.rent.toggled ? 'Ja'
                                : 'Nee')}
                        </div>
                        {this.renderTextField('rent_25', 'rent', 'number', false,
                            '\u20ac 0,00')}
                    </div>
                    <div className='fieldContainer'>
                        <div className='titleContainer'>
                            <label className='fieldTitle'>Waar ben je werkzaam?</label>
                        </div>
                        {this.renderTextField('work_25', 'work')}
                    </div>
                    <div className='fieldContainer'>
                        <div className='titleContainer'>
                            <label className='fieldTitle'>Wat is je salaris?</label>
                        </div>
                        {this.renderTextField('salary_25', 'salary', 'number', false, '\u20ac 0,00')}
                    </div>
                    <div className='fieldContainer'>
                        <div className='titleContainer'>
                            <label className='fieldTitle'>Heb je een auto?</label>
                            {this.renderToggle('car', (values.car.toggled ? 'Ja' : 'Nee'))}
                        </div>
                        {this.renderTextField('car_25', 'car', 'text', !values.car.toggled, 'Kenteken...')}
                    </div>
                    <div className='fieldContainer'>
                        <div className='titleContainer'>
                            <label className='fieldTitle'>Welke energieleverancier heb je?</label>
                        </div>
                        {this.renderTextField('energy_25', 'energy')}
                    </div>
                    <div className='fieldContainer'>
                        <div className='titleContainer'>
                            <label className='fieldTitle'>Welk bedrag betaal je per maand voor energie?</label>
                        </div>
                        {this.renderTextField('energy_price_25', 'energy_price', 'number', false,
                            '\u20ac 0,00')}
                    </div>
                    <div className='fieldContainer'>
                        <div className='titleContainer'>
                            <label className='fieldTitle'>Wanneer wordt energie afgeschreven?</label>
                        </div>
                        <MaterialFactory componentType={materialTypes.SELECT} value={values.energy_day}
                                         items={this.getDays()}
                                         onChange={this.onSelectEnergyDay}/>
                    </div>
                    <MaterialFactory componentType={materialTypes.RAISED_BUTTON}
                                     onClick={this.onSubmit} disabled={this.props.submitting}>Opslaan</MaterialFactory>
                </div>
                {/*</CustomScroll>*/}
                {/*<Loader ref={refName => {*/}
                {/*this.loader = refName*/}
                {/*}} includeBackground={true} backgroundColor='white' textBackground='transparent'*/}
                {/*text='Gegevens opslaan...' maxTime={20000} locate='absolute'/>*/}
            </div>
        )
    }
}

DataView25.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
};