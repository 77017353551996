import React from 'react';

import getCategories from "../../../../common/data/mappings/categoryMapping";
import AgendaPlanning from "./agendaPlanning";
import {triggerAction} from "../../../smartAction/smartActionManager";
import {sendGaEvent} from "../../../../common/js/ga";

const GA_CATEGORY = 'Dashboard (Agenda - Inplannen)';
export default class AgendaPlanningController extends React.Component {
    constructor(props) {
        super(props);
    }

    onClickItem = (item) => {
        sendGaEvent(GA_CATEGORY, 'Klik item', 'details');
        triggerAction(item.trigger);
    };

    getPlanningItems = () => {
        const categoryKeys = Object.keys(getCategories());
        let planningItems = [];
        forEach(categoryKeys, categoryKey => {
            let category = getCategories(categoryKey);
            let agendaItems = category.agendaItems;
            if (isNotNull(agendaItems)) {
                planningItems = planningItems.concat(agendaItems);
            }
        });
        return this.dedupePlanningItems(planningItems);
    };

    dedupePlanningItems = (planningItems) => {
        const deduped = [];
        planningLoop:
            for (let i = 0; i < planningItems.length; i++) {
                let item = planningItems[i];
                for (let j = 0; j < deduped.length; j++) {
                    if (deduped[j].label === item.label) continue planningLoop;
                }
                deduped.push(item);
            }
        return deduped;
    };

    render = () => (
        <AgendaPlanning onClickItem={this.onClickItem} items={this.getPlanningItems()}/>
    )
}