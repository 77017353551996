import React from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import {parse} from '../../misc/queryParser';

import {setAuthParameter} from "./authenticationHelper";
import LoginController from "./login/loginController";
import ForgotPasswordController from "./forgotPassword/forgotPasswordController";
import ChangePasswordController from "./forgotPassword/changePasswordController";
import RegistrationController from "../subscription/registrationController";

export class AuthenticationController extends React.Component {
    constructor(props) {
        super(props);
    }

    processPathParameters = (pathParameters) => {
        // No need for yet
    };

    processQueryParameters = (queryParameters) => {
        if (this.parametersContain(queryParameters, 'target'))
            setAuthParameter('target', queryParameters.target);
        if (this.parametersContain(queryParameters, 'new'))
            setAuthParameter('new', queryParameters.new);
        if (this.parametersContain(queryParameters, 'action'))
            setAuthParameter('action', queryParameters.action);
        if (this.parametersContain(queryParameters, 'code'))
            setAuthParameter('action_code', queryParameters.code);
    };

    parametersContain = (parameters, key) => {
        const value = parameters[key];
        return value !== null && value !== undefined;
    };

    componentDidMount = () => {
        const pathParameters = this.props.match.params;
        const queryParameters = parse(this.props.location.search);
        if (queryParameters !== null && queryParameters !== undefined)
            this.processQueryParameters(queryParameters);

        if (isNotNull(pathParameters))
            this.processPathParameters(pathParameters);
    };

    componentWillUnmount = () => {
        document.body.style.overflowY = null;
    };

    render = () => (
        <Switch>
            <Route path='/auth/login' render={() => (<LoginController/>)}/>
            <Route path='/auth/wachtwoord-vergeten' render={() => (<ForgotPasswordController/>)}/>
            <Route path='/wachtwoord-wijzigen/:passwordKey' render={() => <ChangePasswordController/>}/>
            <Route path='/registratie/:pathParam?' render={() => <RegistrationController/>}/>
            <Route path='' render={() => (<LoginController/>)}/>
        </Switch>
    );
}

export default withRouter(AuthenticationController);