import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import {generateUUID} from 'glob-common-js/lib/utils';

import DataView20 from "./dataView20";
import {createDossier, updateAccount} from "../../../misc/requestSender";
import getDossierType from "../../../common/data/mappings/dossierTypeMapping";
import {setReduxUser} from "../../../misc/utils";
import {dispatchCustomEvent} from "../../../misc/eventDispatcher";
import {NOW} from "../../misc/constants";

export class DataController20 extends React.Component {
    constructor(props) {
        super(props);

        this.state = {submitting: false,};
        this._isMounted = false;
        this.saveActions = 0;
        this.saveActionsCompleted = 0;

        this.dossierActions = 0;
        this.financialActions = 0;
        this.decideActions = 0;
    }

    changeState = stateChange => {
        this._isMounted && this.setState(stateChange);
    };

    onSave = (data) => {
        this.props.onSave();
        this.changeState({submitting: true});
        this.determineSaveActions(data);
        this.saveDossiers(data);
        this.saveAccountItems(data);
        this.saveFinancialItems(data);
    };

    determineSaveActions = data => {
        let study = data.study;
        let internship = data.internship;
        let stufi = data.stufi;
        let digid = data.digid;
        let careAllowance = data.careAllowance;
        let loan = data.loan;
        let dossiersAdded = [];
        let financialAdded = false;
        let checked = false;

        // Dossiers
        if (study.toggled && isNotNull(study.value)) {
            this.saveActions++;
            this.dossierActions++;
            dossiersAdded.push('study');
            if (data.tuition.toggled && data.tuition.value > 0) {
                financialAdded = true;
            }
        }
        if (internship.toggled && isNotNull(internship.value)) {
            this.dossierActions++;
            this.saveActions++;
            dossiersAdded.push('intership');
            if (data.internshipCompensation.toggled && data.internshipCompensation.value > 0) {
                financialAdded = true;
            }
        }

        // Decide items
        if (stufi.toggled) this.decideActions++;
        if (digid.toggled) this.decideActions++;
        if (careAllowance.toggled) this.decideActions++;
        if (this.decideActions > 0) {
            checked = true;
            this.saveActions++;
        }

        // Financial items
        if (stufi.toggled && isNotNull(stufi.value)) {
            this.financialActions++;
            this.saveActions++;
            financialAdded = true;
        }

        if (careAllowance.toggled && isNotNull(careAllowance.value) || loan.toggled && isNotNull(loan.value)) {
            this.saveActions++;
            financialAdded = true;
            if (careAllowance.toggled && isNotNull(careAllowance.value)) {
                this.financialActions++;
            }
            if (loan.toggled && isNotNull(loan.value)) {
                this.financialActions++;
            }
        }
        dispatchCustomEvent('setHighlightParams', {dossiersAdded, financialAdded, checked});
    };

    saveDossiers = data => {
        // Save study, intership
        this.saveStudyDossier(data.study, data.tuition);
        this.saveInternshipDossier(data.internship, data.internshipCompensation);
    };

    saveStudyDossier = (study, tuition) => {
        if (study.toggled && isNotNull(study.value)) {
            let startDate = moment(NOW()).month(0).date(1);
            const type = getDossierType('study');
            createDossier({
                data: {
                    name: study.value,
                    type: type.id,
                    external_data: {
                        isDossier: true,
                        values: [
                            {
                                id: generateUUID(),
                                studie: study.value,
                                monthly_price: tuition.value || 0,
                                start_date: startDate.format('DD-MM-YYYY'),
                                end_date: null,
                                financialType: 'study',
                                description: 'Collegegeld',
                                label: 'Collegegeld',
                                frequency: 'one_time',
                            }
                        ]
                    },
                    values: {},
                }, callback: this.notifySaveAction,
            })
        } else {
            this.notifySaveAction();
        }
    };

    saveInternshipDossier = (internship, compensation) => {
        if (internship.toggled && isNotNull(internship.value)) {
            let startDate = moment(NOW()).month(0).date(1);
            const type = getDossierType('intership');
            createDossier({
                data: {
                    name: internship.value,
                    type: type.id,
                    external_data: {
                        isDossier: true,
                        values: [
                            {
                                id: generateUUID(),
                                company: internship.value,
                                monthly_price: compensation.value || 0,
                                financialType: 'intership',
                                start_date: startDate,
                                description: 'Stagevergoeding',
                                frequency: 'monthly',
                                label: 'Stagevergoeding',
                            }
                        ]
                    },
                    values: {},
                }, callback: this.notifySaveAction,
            })
        } else {
            this.notifySaveAction();
        }
    };

    saveAccountItems = data => {
        if (isNotNull(this.props.userState.user)) {
            let stufi = data.stufi, digid = data.digid, careAllowance = data.careAllowance, completedItems = [];
            if (stufi.toggled) completedItems.push('stufi');
            if (digid.toggled) completedItems.push('digid');
            if (careAllowance.toggled) completedItems.push('care_allowance');

            if (isNotNull(completedItems)) {
                let user = Object.assign({}, this.props.userState.user);
                user.external_data = {
                    ...user.external_data,
                    completedDecideItems: completedItems,
                    nonResident: data.nonResident.toggled,
                };
                updateAccount({
                    id: user.id, data: user, callback: (response) => {
                        setReduxUser(response.data);
                        this.notifySaveAction();
                    }
                });
            }
        } else {
            this.notifySaveAction();
        }
    };

    saveFinancialItems = data => {
        // Save stufi, care_allowance, tuition, loan
        let stufi = data.stufi, careAllowance = data.careAllowance, loan = data.loan;
        this.saveFinancialStufi(stufi);
        this.saveFinancialMisc(careAllowance, loan);
    };

    saveFinancialStufi = stufi => {
        if (stufi.toggled && isNotNull(stufi.value)) {
            let startDate = moment(NOW()).month(0).date(1);
            const type = getDossierType('misc_study');
            createDossier({
                data: {
                    name: 'Studiefinanciering',
                    type: type.id,
                    external_data: {
                        isDossier: false,
                        values: [
                            {
                                id: generateUUID(),
                                description: 'Studiefinanciering',
                                financialType: 'stufi',
                                start_date: startDate.format('DD-MM-YYYY'),
                                end_date: null,
                                monthly_price: stufi.value,
                                frequency: 'monthly',
                                label: 'Studiefinanciering',
                            }
                        ]
                    },
                    values: {},
                }, callback: this.notifySaveAction,
            });
        } else {
            this.notifySaveAction();
        }
    };

    saveFinancialMisc = (careAllowance, loan) => {
        let values = [];
        this.addCareAllowance(careAllowance, values);
        this.addLoan(loan, values);
        if (isNotNull(values)) {
            const type = getDossierType('financial_overview');
            createDossier({
                data: {
                    name: 'Financieel overzicht',
                    type: type.id,
                    external_data: {
                        isDossier: false,
                        values,
                    },
                    values: {},
                }, callback: this.notifySaveAction,
            });
        } else {
            this.notifySaveAction();
        }
    };

    addCareAllowance = (careAllowance, values) => {
        if (careAllowance.toggled && isNotNull(careAllowance.value)) {
            let startDate = moment(NOW()).month(0).date(1);
            values.push({
                id: generateUUID(),
                description: 'Zorgtoeslag',
                financialType: 'care_allowance',
                start_date: startDate.format('DD-MM-YYYY'),
                end_date: null,
                monthly_price: careAllowance.value,
                frequency: 'monthly',
                label: 'Zorgtoeslag',
            });
        }
        return values;
    };

    addLoan = (loan, values) => {
        if (loan.toggled && isNotNull(loan.value)) {
            let startDate = moment(NOW()).month(0).date(1);
            values.push({
                id: generateUUID(),
                description: 'Lening',
                financialType: 'loan',
                start_date: startDate.format('DD-MM-YYYY'),
                end_date: null,
                monthly_price: loan.value,
                frequency: 'monthly',
                label: 'Lening',
            });
        }
        return values;
    };

    notifySaveAction = () => {
        this.saveActionsCompleted++;
        if (this.saveActionsCompleted >= this.saveActions) {
            this.saveActionsCompleted = 0;
            this.saveActions = 0;
            this.dossierActions = 0;
            this.financialActions = 0;
            this.decideActions = 0;

            this.changeState({submitting: false});
            dispatchCustomEvent('updatePlatform');
            this.props.onStopLoading();
            this.props.nextStep();
        }
    };

    componentDidMount = () => {
        this._isMounted = true;
    };

    componentWillUnmount = () => {
        this._isMounted = false;
    };

    render = () => (
        <DataView20 ref={refName => {
            this.view = refName
        }} onSubmit={this.onSave} submitting={this.state.submitting}/>
    )
}

DataController20.propTypes = {
    nextStep: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onStopLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    userState: state.user,
});

export default connect(mapStateToProps)(DataController20);