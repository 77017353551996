import './style/activationStep.scss';

import React from 'react';
import PropTypes from 'prop-types';
import MailSendView from "./mailSendView";
import {steps} from "../../registrationConstants";
import SubscriptionIntroView from "./subscriptionIntroView";
import SubscriptionPaymentView from "./subscriptionPaymentView";
import LoadingView from "./loadingView";
import InsuranceScanController from "./insuranceScanController";

export default class ActivationStepView extends React.Component {
    constructor(props) {
        super(props);
    }

    renderContent = () => {
        const step = this.props.subStep;
        switch (step) {
            case steps.ACTIVATION.MAIL_SEND:
                return <MailSendView email={this.props.user.email}/>;
            case steps.ACTIVATION.ACTIVATING:
                return <LoadingView message='Je e-mailadres wordt bevestigd...'/>;
            case steps.ACTIVATION.INSURANCE_SCAN:
                return <InsuranceScanController user={this.props.user} setSubStep={this.props.setSubStep}/>;
            case steps.ACTIVATION.PAYMENT_INTRO:
                return <SubscriptionIntroView setSubStep={this.props.setSubStep} firstName={this.props.user.firstname}
                                              subscriptionInfo={this.props.subscriptionInfo}
                                              onDeleteAccount={this.props.onDeleteAccount}/>;
            case steps.ACTIVATION.CHECK_PAYMENT:
                return <LoadingView message='Je betaling wordt gecontroleerd...' showTimer/>;
            case steps.ACTIVATION.START_PAYMENT:
                return <SubscriptionPaymentView startPayment={this.props.startPayment} issuers={this.props.issuers}
                                                setSubStep={this.props.setSubStep} flex={this.props.flex}
                                                subscriptionInfo={this.props.subscriptionInfo}/>
        }
    };

    render = () => {
        return (
            <div className='activationStepView'>
                {this.renderContent()}
            </div>
        )
    }
}

ActivationStepView.propTypes = {
    onDeleteAccount: PropTypes.func.isRequired,
    setSubStep: PropTypes.func.isRequired,
    startPayment: PropTypes.func.isRequired,
    subStep: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    issuers: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
    })),
    subscriptionInfo: PropTypes.object,
    flex: PropTypes.object,
};
