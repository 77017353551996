import './style/bdhSearchInput.scss';

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Search from '@material-ui/icons/Search';
import Clear from '@material-ui/icons/Clear';

import {isFunction} from 'glob-common-js/lib/utils';
import BdhInput from "./bdhInput";
import {ESCAPE_KEY} from "../../../misc/keyMap";

export default class BdhSearchInput extends React.Component {
    getInputProps = () => {
        let props = Object.assign({}, this.props);
        delete props.onClearSearch;
        delete props.searchOrnamentClass;
        delete props.clearOrnamentClass;
        return props;
    };

    onKeyDown = evt => {
        const {onClearSearch, onKeyDown} = this.props;
        if (isFunction(onClearSearch) && evt.keyCode === ESCAPE_KEY)
            onClearSearch();
        if (isFunction(onKeyDown))
            onKeyDown(evt);
    };

    renderOrnament = () => {
        const {value, onClearSearch, searchOrnamentClass, clearOrnamentClass} = this.props;
        const element = isNull(value) || !isFunction(onClearSearch) ?
            <Search className={classNames('bdhSearchOrnament', searchOrnamentClass)}/> :
            <Clear className={classNames('bdhClearOrnament', clearOrnamentClass)} onClick={onClearSearch}/>;
        return [{element}];
    };

    render = () => {
        return <BdhInput ornaments={this.renderOrnament()} onKeyDown={this.onKeyDown} {...this.getInputProps()}/>
    }
}

BdhSearchInput.propTypes = {
    onClearSearch: PropTypes.func,
    searchOrnamentClass: PropTypes.string,
    clearOrnamentClass: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onKeyDown: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    label: PropTypes.string,
    containerClass: PropTypes.string,
    labelClass: PropTypes.string,
    inputContainerClass: PropTypes.string,
    inputClass: PropTypes.string,
    id: PropTypes.string,
    key: PropTypes.string,
    withLabelTransition: PropTypes.bool,
};

BdhSearchInput.defaultProps = {
    searchOrnamentClass: '',
    clearOrnamentClass: '',
};