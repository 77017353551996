// TODO Include GA tags
import {navigate} from "../../misc/utils";
import {sendGaEvent} from "../../common/js/ga";
import {dispatchCustomEvent} from "../../misc/eventDispatcher";

import {getSetting} from 'glob-common-js/lib/utils';

const gaCategory = 'Menu';

export const navigationMapping = [
    {
        label: 'Dashboard',
        icon: 'common-icon-car-speed-gauge',
        onClick: () => {
            sendGaEvent(gaCategory, 'Klik', 'Dashboard');
            navigate('/dashboard');
        },
        lockable: false,
    },
    {
        label: 'Mijn gegevens - Overzicht',
        icon: 'common-icon-folder-open2',
    },
    {
        label: 'Dossiers',
        icon: 'common-icon-folder-open2',
        onClick: () => {
            sendGaEvent(gaCategory, 'Klik', 'Dossier overzicht');
            navigate('/dossier-overzicht');
        },
        exclude: false,
        lockable: false,
    },
    {
        label: 'Documenten',
        icon: 'common-icon-files',
        onClick: () => {
            sendGaEvent(gaCategory, 'Klik', 'Documenten');
            navigate('/bibliotheek');
        },
        exclude: false,
        lockable: false,
    },
    {
        label: 'Financieel overzicht',
        icon: 'common-icon-euro',
        onClick: () => {
            sendGaEvent(gaCategory, 'Klik', 'Financieel overzicht');
            navigate('/financieel-overzicht');
        },
        exclude: false,
        lockable: false,
    },
    {
        label: 'Account',
        icon: 'common-icon-person',
        onClick: (evt) => {
            evt.preventDefault();
            // Just shows account options
        },
        exclude: false,
        lockable: false,
    },
    {
        label: 'Info',
        icon: 'common-icon-info',
        onClick: (evt) => {
            evt.preventDefault();
        },
        exclude: false,
        lockable: false,
    },
    {
        label: 'Meest gebruikt',
        icon: 'common-icon-list-numbered',
        onClick: () => {
            sendGaEvent(gaCategory, 'Klik', 'Meest gebruikt');
            dispatchCustomEvent('openUploadTips');
        },
        exclude: false,
        lockable: false,
    },
    {
        label: 'Changelog',
        icon: 'common-icon-file-checklist',
        onClick: () => {
            navigate('/changelog');
        },
        exclude: getSetting('mode') !== 'dev',
        lockable: false,
    }
];