import {getCookie, getScreenHeight} from 'glob-common-js/lib/utils';

import constants from '../../../../../style/JSSConstants';
import {getJwt, getJwtToken} from "../../../../common/js/platform";

export const JSSPopupBuilder = (theme) => ({
    backgroundRoot: {
        opacity: '.5',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: constants["z-index-normal"],
    },
    containerRoot: createContainerRoot(theme),
    headerRoot: {
        backgroundColor: constants["color-blue-1"],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '1rem',
        borderTopRightRadius: constants["default-border-radius"],
        borderTopLeftRadius: constants["default-border-radius"],
        color: 'white',
        [theme.breakpoints.down('sm')]: {
            borderRadius: '0',
        }
    },
    bodyRoot: {
        padding: '1rem',
    },
    footerRoot: {
        padding: '1rem',
        borderBottomRightRadius: constants["default-border-radius"],
        borderBottomLeftRadius: constants["default-border-radius"],
        [theme.breakpoints.down('sm')]: {
            borderRadius: '0',
        }
    },
    hide: {
        display: 'none',
    }
});

const createContainerRoot = (theme) => ({
    maxHeight: getScreenHeight() * .8,
    position: 'fixed',
    top: '5rem',
    overflowY: 'auto',
    zIndex: constants["z-index-high"],
    backgroundColor: 'white',
    borderRadius: constants["default-border-radius"],
    left: getLeft(),
    right: getRight(),
    transform: getTransform(),
    boxShadow: '0 0 1rem 0 rgba(0, 0, 0, .6)',
    [theme.breakpoints.down('sm')]: {
        width: '95%',
        zIndex: constants["z-index-popup-4"],
        left: '2.5%',
        right: 0,
        transform: 'none',
    }
});

const getLeft = (isMobile = false) => {
    if (isMobile && isNotNull(getJwtToken())) return 'unset';
    return '50%';
};

const getRight = (isMobile = false) => {
    if (isMobile && isNotNull(getJwtToken())) return '2%';
    return 'unset';
};

const getTransform = (isMobile = false) => {
    if (isMobile && isNotNull(getJwtToken())) return 'none';
    return 'translateX(-50%)';
};